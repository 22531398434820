<div class="popup__wrapper" *ngIf="documentsArr.length">
    <div class="popup__heading">Send Document Request</div>
    <!-- <div class="sub__heading">
        <app-svg [name]="'request-documents'" class='document_svg'></app-svg>
        Select Documents to request from Patient
    </div> -->
    <div class="days_wrapper">
            <div class="day__field" *ngFor="let task of documentsArr; let i = index">
                    <p-checkbox [value]="task.day" [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="task.isSelected" binary="true" (onChange)="handleDocuments($event, task)">
                    </p-checkbox><label>{{task.documentLabel}}</label>
            </div>
    </div>
    <div class="error-msg days"><span *ngIf="showSelectionError">Please select atleast one document</span></div>
    <!-- Text area starts-->
    <ng-container *ngIf="showOther || this.isPatient == true">
        <div class="form__field">
            <div class="sub__heading">Others Comments</div>
            <textarea  
                class="text-area input" type="textarea" [attr.maxLength]="350"
                value="" placeholder="Enter details here"
                (input)="textCounter($event)"
                [(ngModel)]="otherValue"></textarea>
                <input disabled
                    class="textarea-range-show" maxlength="3" size="3"
                    value="{{remainingValue}} remaining" id="remaining">
            <!--text area validation start-->
            <div class="error-msg days other"><span *ngIf="showOtherError">Please enter the details, if you specify others</span></div>
        </div>
    </ng-container>
    <!-- <div class="form__field comments">
        <div class="sub__heading">Comments</div>
        <textarea  
            class="text-area input" type="textarea" [attr.maxLength]="150"
            value="" placeholder="Enter details here (optional)"
            (input)="textCounter1($event)"
            [(ngModel)]="adminComments"></textarea>
            <input disabled
                class="textarea-range-show" maxlength="3" size="3"
                value="{{remainingValue1}} remaining" id="remaining">
    </div> -->
    <div class="form__field upload__field upload__field-wrapper">
        <p-fileUpload name="patient_uploads[]" #fileUpload   [auto]="true" chooseLabel="Choose files to Upload"  accept=".jpg, .png, .gif, .jpeg, .tiff, .psd, .pdf, .esp, .ai, .indd, .raw, .heic, .heif" maxFileSize="10000000" multiple="multiple" (onSelect)="onUpload($event,fileUpload)"  (onRemove)="filesRemoved($event,fileUpload)" (onClear)="allFilesCleared($event)" fileLimit="3">
            <ng-template pTemplate="content">
                <p class="content-message">Or Drag and Drop them here 
                    <span class="helpTxt">(maximum 3 allowed)</span>
                </p>
            </ng-template>
        </p-fileUpload>
        <div class="error-msg days upload-valid-msg" *ngIf="fileValidation == false && uploadedFiles && uploadedFiles.length != 0 && uploadedFiles.length <= 5"><span>Please upload valid document.</span></div>
        <div class="error-msg days upload-valid-msg" *ngIf="fileValidation == false && uploadedFiles && uploadedFiles.length > 5"><span>Maximum 5 documents are allowed to upload. Please remove extra documents</span></div>
    </div>
    <div class="save_button">
        <div class="cancel-btn" (click)="cancel()">Cancel</div>
        <button (click)="submit()" class="common-btn-style">Send</button>
    </div>
</div>