<div class="location-creation">
    <div class="background-image">
        <img src="assets/images/pngs/newpatientform-background.png" alt="bg image">
    </div>
    <div class="location-creation__content">
        <app-breadcrumb [breadCrumbData]="breadCrumbData"></app-breadcrumb>
        <div>
            <!--custom form start-->
            <app-create-location [isCreate]="isCreate">
            </app-create-location>
        </div>
    </div>
</div>