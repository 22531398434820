import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CognitoService } from '@app/common/services/cognito.service';

@Component({
  selector: 'app-user-landing-page',
  templateUrl: './user-landing-page.component.html',
  styleUrls: ['./user-landing-page.component.scss']
})
export class UserLandingPageComponent implements OnInit {
  name: any = 'John';
  userDetails: any;
  constructor(private router: Router, private cognitoService: CognitoService) { }

  ngOnInit() {
    this.cognitoService.getUser().then(user => {
      this.userDetails = user;
      this.name = `${this.userDetails.firstName} ${this.userDetails.lastName}`;
    });
    console.log('user landing page');
  }
  redirectingToBookAppointment() {
    this.router.navigate(['/appointment']);
  }

}
