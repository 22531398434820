import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IformDataI } from '@app/common/constants/typeInterfaces.constants';
import { CognitoService } from '@app/common/services/cognito.service';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { DataService } from '@app/common/services/data.service';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';

@Component({
  selector: 'app-subsentence-consent',
  templateUrl: './subsentence-consent.component.html',
  styleUrls: ['./subsentence-consent.component.scss']
})
export class SubsentenceConsentComponent implements OnInit {
  @Input() saveFormData;
  @Input() patientName;
  @Output() submitEvent: EventEmitter<any> = new EventEmitter();
  header:string = `Pain Treatment with Opioid Medications: Patient Agreement*`
  formData:IformDataI;
  hippaForm: any;
  hippaData = {
    description1: 'By signing this form, I understand the following;',
    list: [
      `I will keep (and be on time for) all my scheduled appointments with the doctor and other members of the treatment team.`,
      `I will participate in all other types of treatment that I am asked to participate in.`,
      `I will take my medication as instructed and not change the way I take it without first talking to the doctor or other member of the treatment team.`,
      `I will not call between appointments, or at night or on the weekends looking for refills. I understand that prescriptions will be filled only during scheduled office visits with the treatment team.`,
      `I will make sure I have an appointment for refills. If I am having trouble making an appointment, I will tell a member of the treatment team immediately.`,
      `I will treat the staff at the office respectfully at all times. I understand that if I am disrespectful to staff or disrupt the care of other patients my treatment will be stopped.`,
      `I will not sell this medicine or share it with others. I understand that if I do, my treatment will be stopped.`,
      `I will sign a release form to let the doctor speak to all other doctors or providers that I see.`,
      `I will tell the doctor all other medicines that I take, and let him/her know right away if I have a prescription for a new medicine.`,
      `I will not get any opioid pain medicines or other medicines that can be addictive such as benzodiazepines (klonopin, xanax, valium) or stimulants (ritalin, amphetamine) without telling a member of the treatment team before I fill that prescription. I understand that the only exception to this is if I need pain medicine for an emergency at night or on the weekends.`,
      `I will not use illegal drugs such as heroin, cocaine, marijuana, or amphetamines. I understand that if I do, my treatment may be stopped.`,
      `I will come in for drug testing and counting of my pills within 24 hours of being called. I understand that I must make sure the office has current contact information in order to reach me, and that any missed tests will be considered positive for drugs.`,
      `I will keep up to date with any bills from the office and tell the doctor or member of the treatment team immediately if I lose my insurance or can't pay for treatment anymore.`,
      `I understand that I may lose my right to treatment in this office if I break any part of this agreement.`,
     ],
  };
  substanceForm: any;
  patient: any;
  constructor(private route:ActivatedRoute, private formService:CustomFormHandlerService, private router:Router, private http:HttpService, private observableHelperService: ObservableHelperService, private cognitoService: CognitoService,private dataService: DataService) { }
  ngOnChanges(changes: SimpleChange){
    if(changes['patientName'].currentValue !== 'undefined'){
    this.patient = changes['patientName'].currentValue;
    console.log(changes['patientName'].currentValue)
    this.formService.subConsentFormConfig[0].value = this.patient;
    }

  }

  ngOnInit() {
    this.substanceForm = this.formService.initForm(this.formService.formTypes.SUB_CONSENT)
    this.hippaForm =  this.formService.initForm(this.formService.formTypes.HIPPA); 
    this.formService.subConsentFormConfig[0].value = this.patient;
  }
  getFormData(event) {
    if (event) {
      this.formService.hippaFormConfig = this.assignFormValues(event, this.formService.hippaFormConfig);
      this.dataService.setData(this.dataService.dataObject.HIPPA, this.formService.hippaFormConfig);
      this.submitEvent.emit({status: event, step: 2});
    }
  }
    assignFormValues(formData, formConfig) {
    let keys = Object.keys(formData.value);
    formConfig.forEach((each, index) => {
      if (keys.includes(each.key)) {
        each.value = formData.value[each.key];
      }
    });
    return formConfig;
  }
}
