import { Component, OnInit, Input } from '@angular/core';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';
import { IPutMethodPayload } from '@app/common/constants/typeInterfaces.constants';
import { API_URLS } from '@app/common/constants/api-urls.constant';
import { HTTPMethods, TOAST_STATUSES } from '@app/common/constants/util.constant';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-onboard-wrapper',
  templateUrl: './onboard-wrapper.component.html',
  styleUrls: ['./onboard-wrapper.component.scss']
})
export class OnboardWrapperComponent implements OnInit {

  form: any;
  isUnsubscribe: boolean = false;
  templateId: string = '';
  showUnsubscribePopUp: boolean = false;
  email: string = '';
  @Input() isAppointment;
  emailUnsubscribed: string = 'Email already Unsubscribed';
  constructor(
    private formService: CustomFormHandlerService,
    private observableHelperService: ObservableHelperService,
    private router: Router,
    private http: HttpService,) { }

  ngOnInit() {
    this.form = this.formService.initForm(this.formService.formTypes.SIGNUP);
    if(window.localStorage.getItem('unsub') == 'true'){
      this.email = window.localStorage.getItem('email') ?  window.localStorage.getItem('email') : '';
      this.templateId =  window.localStorage.getItem('templateId') ?  window.localStorage.getItem('templateId') : '';
      this.isUnsubscribe = true;
      this.showUnsubscribePopUp = true;
    }
  }

  unsubscribe(){
    let body = {
      templateId : this.templateId,
      email: this.email
    }
    let payload: IPutMethodPayload = {
      type: HTTPMethods.PUT,
      isAuthToken: true,
      url: API_URLS.UNSUBSCRIBE,
      isDeveloper: true,
      pathVariables: [],
      body: body
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res) && res) {
        if(res.data === this.emailUnsubscribed){
          this.observableHelperService.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.ALREADY_SUBSCRIBED);
        } else {
          this.observableHelperService.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.UNSUB_SUCCESS);
        }
      } else {
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.UNSUB_FAIL);
      }
      this.router.navigate([]);
      this.isUnsubscribe = false;
    });
  }

  /**
   * To hide/show the popup
   * @param status popup visibility
   */
  cancelPopup(status) {
    this.showUnsubscribePopUp = status.cancelpopup;
    this.router.navigate([]);
    window.localStorage.removeItem('unsub');
    window.localStorage.removeItem('email');
    window.localStorage.removeItem('templateId');
  }

  hidePopup(status){
    this.showUnsubscribePopUp = false;
    if(status){
      this.unsubscribe();
    } else {
      this.router.navigate([]);
    }
    window.localStorage.removeItem('unsub');
    window.localStorage.removeItem('email');
    window.localStorage.removeItem('templateId');
  }

}
