<section class="medication-consent__section">
    <div class="telemedicine-consent__block" *ngIf="apptType == 6 &&  apptState == 'MD' ">
        <p-tabView>
            <p-tabPanel header="{{tab.headerLabel}}" *ngFor="let tab of tabContent1">
                <div class="container" *ngFor="let data of tab.data">
                    <div *ngIf="data.description">
                        {{data.description}}
                    </div>
                    <div *ngIf="data.list">
                        <ul class="{{data.listtype}}">
                            <li *ngFor="let list of data.list">
                                {{list}}
                            </li>
                        </ul>
                    </div>
                    <div *ngIf="data.warning">
                      {{data.warning}}
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>
        <div class="consent--points" *ngFor="let points of consentPoints">
            {{points}}
        </div>
    </div>
    <div class="telemedicine-consent__block" *ngIf="apptType == 6 &&  apptState !== 'MD'">
        <p-tabView>
            <p-tabPanel header="{{tab.headerLabel}}" *ngFor="let tab of tabContent2">
                <div class="container" *ngFor="let data of tab.data">
                    <div *ngIf="data.description">
                        {{data.description}}
                    </div>
                    <div *ngIf="data.list">
                        <ul class="{{data.listtype}}">
                            <li *ngFor="let list of data.list">
                                {{list}}
                            </li>
                        </ul>
                    </div>
                    <div *ngIf="data.warning">
                      {{data.warning}}
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>
        <div class="consent--points" *ngFor="let points of consentPoints">
            {{points}}
        </div>
    </div>
    <div class="telemedicine-consent__block" *ngIf='apptType == 3'>
        <p-tabView>
            <p-tabPanel header="{{tab.headerLabel}}" *ngFor="let tab of tabContent">
                <div class="container" *ngFor="let data of tab.data">
                    <div *ngIf="data.header" class='header'>
                        {{data.header}}
                    </div>
                    <div *ngIf="data.description">
                        {{data.description}}
                    </div>
                    <div *ngIf="data.list">
                        <ul class="{{data.listtype}}">
                            <li *ngFor="let list of data.list">
                                {{list}}
                            </li>
                        </ul>
                    </div>
                    <div *ngIf="data.warning">
                      {{data.warning}}
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
    <div class="patient__info step"  *ngIf='apptType == 3 && emergencyContactDetails'>
        <div class="sub_headers">
            Emergency contact details
        </div>
        <div class="patient__details-block">
            <div>
                <div class="label">Name</div>
                <div class="value">{{emergencyContactDetails.name}}</div>
            </div>
            <div>
                <div class="label">Phone number/fax</div>
                <div class="value">{{emergencyContactDetails.number}}</div>
            </div>
            <div>
                <div class="label">Relationship</div>
                <div class="value">{{emergencyContactDetails.relation}}</div>
            </div>
            <div>
                <div class="label">Address</div>
                <div class="value">{{emergencyContactDetails.address}}</div>
            </div>
        </div>
    </div>
        <div>
            <app-custom-form 
            (submitEvent)="getFormData($event)" [formData]="autoForm" (sliderValue)="getdata($event)" *ngIf="autoFill"></app-custom-form>
            <app-custom-form [formData]="formData" [checkFormInfo]="saveFormData"
            (submitEvent)="getFormData($event)"></app-custom-form>
        </div>
</section>