<!-- privacy section start -->
<section class="privacy__section full-width">
    <!-- privacy container start -->
    <div class="privacy__container restrict-width padding-lr">
        <!-- privacy cards block start -->
        <div class="privacy__cards-block">
            <!-- privacy heading block start -->
            <div class="privacy__heading-block">
                <h2 class="privacy__heading">Privacy Policy</h2>
                <h3 class="privacy__sub-heading">Privacy Policies</h3>
                <div class="para__block">
                    <p class="para-text">Newteledoc Inc. ("<strong>we</strong>," "<strong>us</strong>," or "<strong>our</strong>") respects your privacy and knows that you care about protecting your personal information. This privacy policy identifies what information we collect from you when you use https://newteledoc.com (the "<strong>Site</strong>," including all subdomains) and the services made available on it (the "<strong>Services</strong>") and explains how we may use or share that information. We will only use and share your information as described in this privacy policy.</p>
                    <p class="para-text">This Site primarily operates as a(n) medical services – appointment scheduling website. This privacy policy applies to information we collect from you on the Site; through the Services; in email, text, and other electronic correspondence; and through any mobile or desktop application through which we may communicate. This privacy policy does not apply to information we collect offline or that any third party collects from you after you follow links on the Site, including any advertising and affiliate links.</p>
                    <p class="para-text">Your information will remain confidential and will not be shared with any third-party vendors. Newstressclinic will only send a text in response to a message you initiate to Newteledoc. To opt out of receiving texts, simply reply with "STOP."</p>
                    <p class="para-text">The SMS consent compliance and phone numbers will not be shared with 3rd parties and we will protect the information.</p>
                    <p class="para-text">PLEASE READ THIS PRIVACY POLICY AND OUR TERMS OF USE CAREFULLY. THE TERMS STATED IN THIS PRIVACY POLICY CONSTITUTE A BINDING LEGAL AGREEMENT BETWEEN YOU AND NEW STRESS CLINIC INC.. BY USING THIS SITE AND THE RELATED SERVICES, YOU UNCONDITIONALLY AGREE TO BE BOUND BY THE TERMS STATED IN THIS PRIVACY POLICY AND OUR TERMS OF USE, INCLUDING ALL EXCLUSIONS AND LIMITATIONS OF LIABILITY, AND WARRANT THAT YOU HAVE FULL AUTHORITY AND CAPACITY, LEGAL AND OTHERWISE, TO USE THE SERVICES. YOU MAY NOT ACCESS OR USE THIS SITE OR THE SERVICES IF YOU DO NOT AGREE TO ANY PART OF THESE TERMS. WE RESERVE THE RIGHT TO PERIODICALLY MAKE UPDATES TO THIS PRIVACY POLICY AS OUR PRACTICES CHANGE. YOUR CONTINUED USE OF THE SITE AFTER SUCH CHANGES CONSTITUTES YOUR ACCEPTANCE OF THE CHANGES, SO PLEASE CHECK BACK PERIODICALLY FOR UPDATES.</p>
                </div>
            </div>
            <!-- privacy heading block end -->
            <!-- privacy card block start -->
            <div class="privacy__card-block" *ngFor="let card of cardDetails" [ngClass]="card.expanded ? 'expanded' : ' '">
                <div class="privacy__title-block" (click)="onClick(card)">
                    <div class="icon__block">
                        <img src="assets/images/svgs/{{ iconPath.iconPlus }}" alt="accordian icon">
                        <img src="assets/images/svgs/{{ iconPath.iconMinus }}" alt="accordian icon">
                    </div>
                    <h3 class="privacy__title">{{ card.title }} </h3>
                </div>
                <div class="privacy__description-block">
                    <div *ngIf="card.listHeading.length">
                        <h3 *ngFor="let heading of card.listHeading" [innerHTML]="heading"></h3>
                    </div>
                    <ul *ngIf="card.listArray.length">
                        <li *ngFor="let list of card.listArray" [innerHTML]="list"></li>
                    </ul>
                    <div *ngIf="card.descriptionHeading.length">
                        <h3 *ngFor="let heading of card.descriptionHeading" [innerHTML]="heading" class="descriptionHeading"></h3>
                    </div>
                    <div *ngIf="card.description.length">
                        <p class="privacy__description" *ngFor="let description of card.description" [innerHTML]="description">
                        </p>
                    </div>
                </div>

            </div>
            <!-- privacy card block end -->
        </div>
        <!-- privacy cards block end -->
    </div>
    <!-- privacy container end -->
</section>
<!-- privacy section end -->
