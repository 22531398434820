import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms-wrapper',
  templateUrl: './terms-wrapper.component.html',
  styleUrls: ['./terms-wrapper.component.scss']
})
export class TermsWrapperComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
