
<div class="profile-component">
    <img class="onbaord-form-bg" alt="" src="assets/images/pngs/onboard-card-bg.png" />
    <div *ngIf="showScreen" class="profile-wrapper">
        <div class="profile-main-section">
           <!-- <div class="user-and-edit">
                <div *ngIf="name" class="account-user-name">{{name}}</div>
                <div *ngIf="!name" class="account-user-name">Profile</div>
            </div>
            <div class="user-mail-num" *ngIf="showUserData">
                <div class="user-mail">
                    <app-svg [name]="'mail'" class="email"></app-svg>{{email}}
                </div>
            </div>-->
            <div class="change-pwd-label">
                <app-svg [name]="'lock'" class="lock"></app-svg>Change Password
            </div>
            <div class="change-password-form">
                <app-custom-form [formData]="form" [checkFormInfo]="saveFormData ? resetSaveFormParity() : false"
                    (submitEvent)="getFormData($event)"></app-custom-form>
            </div>
            <div class="btn-actions">
                <div class="cancel-btn" (click)="navigateBack($event)">Cancel</div>
                <div class="common-btn-style btn--inline" (click)="saveForm()">Save</div>
            </div>
        </div>
    </div>
</div>