import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-faqs-wrapper',
  templateUrl: './faqs-wrapper.component.html',
  styleUrls: ['./faqs-wrapper.component.scss']
})
export class FaqsWrapperComponent implements OnInit {

  constructor( private meta: Meta,private titleService: Title) { }

  ngOnInit() {
    this.meta.addTag({ name: 'description', content: "Patients can access answers to commonly asked questions within this database."});
    this.meta.updateTag({ name: 'title', content: 'Patient FAQ - New TeleDoc Washington DC Psychiatrist' });
    this.titleService.setTitle('Patient FAQ - New TeleDoc Washington DC Psychiatrist');
  }

  ngAfterViewChecked(): void {
    // Called after every check of the component's view. Applies to components only.
    // Add 'implements AfterViewChecked' to the class.
    if (document.getElementById('faqs')) {
      document.getElementById('faqs').style.display = 'none';
    }
  }

  ngOnDestroy() {
    if (document.getElementById('faqs')) {
      document.getElementById('faqs').style.display = 'flex';
    }
  }

}
