import { Component, OnInit } from '@angular/core';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';

@Component({
  selector: 'app-blog-wrapper',
  templateUrl: './blog-wrapper.component.html',
  styleUrls: ['./blog-wrapper.component.scss']
})
export class BlogWrapperComponent implements OnInit {

  constructor(private observable: ObservableHelperService) {

  }

  ngOnInit() {
    this.observable.setCurrentPage('blog');
  }

}
