// This component is for document request list.

import { Component, OnInit } from '@angular/core';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { HttpService } from '@app/common/services/http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TOAST_STATUSES, DEFAULT_PAGINATION_LIMIT, HTTPMethods } from '@app/common/constants/util.constant';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { API_URLS } from '@app/common/constants/api-urls.constant';
import { IBreadCrumbI, IDocListFilter, IformConfigI, IPostMethodPayload, IGetMethodPayload } from '@app/common/constants/typeInterfaces.constants';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss']
})
export class DocumentListComponent implements OnInit {

  permissionsDone: Boolean = true;
  permissionsArr: Array<string> = [];
  adminPermissions = {
    APPOINTMENT_CREATE: true,
    APPOINTMENT_EDIT: true,
    PATIENT_EDIT: true,
    PATIENT_DETAILS: true,
    INVOICE_EDIT: true,
    INVOICE_DETAILS: true,
  };

  breadCrumbData: IBreadCrumbI = {
    link: '/admin',
    linkText: 'Document Request List'
  };
  pageNumber: number = 1;
  pageLimit: number = DEFAULT_PAGINATION_LIMIT;
  searchInputText: string = '';
  outsideScroll: Boolean = false;

  INITIAL_PAYLOAD: IDocListFilter = {
    pagination: {
      page: this.pageNumber,
      limit: this.pageLimit
    },
    search: this.searchInputText,
  };
  isShowScreen: Boolean = false;
  blockPatientApplyStatus: Boolean = false;
  docListCount: number = 0;
  docFilterData: Record<string,any>;
  treatmentsCount: number;
  paginationData: Record<string,any>;
  sideBarVisible: boolean = false;
  emptyAppointmentsListMessages = {
    className: 'appointmentsList',
    icon: 'documents',
    message: 'There are no document requests'
  };
   // Form inputs
   forminputs: IformConfigI[] = [
     {
      type: 'calendar',
      placeholder: 'Start Date',
      isTime: false,
      required: true,
      key: 'prescriptionStartDate',
      labelImage: '',
      value: '',
      controlType: 'calendar',
      editable: true,
      emptyValueMessage: 'Please select date',
      validators: [],
      className: 'side__by__side',
      focus: true,
      showontoggle: true,
    },
    {
      type: 'calendar',
      placeholder: 'End Date',
      isTime: false,
      required: false,
      key: 'prescriptionEndDate',
      labelImage: '',
      value: '',
      controlType: 'calendar',
      editable: true,
      className: 'side__by__side',
      // emptyValueMessage: 'Please select end date',
      validationValue: 'End date should be greater than start date',
      validators: [],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'header',
      label: 'Time',
      showontoggle: true,
      editable: true,
      key: 'time'
    },
    {
      type: 'calendar',
      placeholder: 'Start Time',
      required: false,
      key: 'startTime',
      labelImage: '',
      isTime: true,
      value: '',
      controlType: 'timer',
      editable: true,
      // emptyValueMessage: 'Please select Start Time',
      validators: [],
      className: 'side__by__side',
      focus: true,
      showontoggle: true,
    },
    {
      type: 'calendar',
      placeholder: 'End Time',
      isTime: true,
      required: false,
      key: 'endTime',
      labelImage: '',
      value: '',
      controlType: 'timer',
      editable: true,
      className: 'side__by__side',
      // emptyValueMessage: 'Please select End Time',
      validationValue: 'End date should be greater than start date',
      validators: [],
      focus: true,
      showontoggle: true,
    },
    {
      type: 'groupCheckbox',
      categoryLabel: 'Document Type',
      controlType: 'groupCheckbox',
      key: 'requestType',
      required: true,
      value: [],
      isIntermediate: false,
      options: [],
      validators: [],
      focus: true,
      editable: true,
      isSelected: false,
      showontoggle: true,
    },
    {
      type: 'header',
      controlType: 'hrLine',
      className: 'hrLine',
      showontoggle: true,
      editable: true,
    },
    {
      type: 'groupCheckbox',
      categoryLabel: 'Status',
      controlType: 'groupCheckbox',
      key: 'docStatus',
      required: true,
      value: [],
      isIntermediate: false,
      options: [],
      validators: [],
      focus: true,
      editable: true,
      isSelected: false,
      showontoggle: true,
    },
    {
      type: 'header',
      controlType: 'hrLine',
      className: 'hrLine',
      showontoggle: true,
      editable: true,
    },
    {
      type: 'submit',
      placeholder: '',
      required: true,
      text: 'Apply',
      key: '',
      value: '',
      controlType: 'submitButton',
      validators: [],
      callback: '',
      cencelBtn: 'Clear',
      showontoggle: true,
    }];
    documentList: Array<any> = [];
  getFilterData: Record<string,any>;
  appyliedFilterObj: IDocListFilter;
  searchPlaceHolderText = 'Search by Patient, Employee';
  rawFilterData: any;
  constructor(private observable: ObservableHelperService, 
    private http: HttpService,
    private router: Router) { 
  }

  ngOnInit() {
    // Seting outside scroll visible status
    this.checkingSidebarState(this.outsideScroll);
    this.INITIAL_PAYLOAD.search = this.searchInputText
    this.getDocList(this.INITIAL_PAYLOAD);
    this.getDocFilterData();
  }

  /**
   * Checking the Slide bar is open or if open hide outside scroll
   * @param slideBarFlag 
   */
  checkingSidebarState(slideBarFlag) {
    if (slideBarFlag) {
      document.querySelector('html').style.overflow = 'hidden';
      document.querySelector('body').style.overflow = 'hidden';
    } else {
      document.querySelector('html').style.overflow = 'auto';
      document.querySelector('body').style.overflow = 'auto';
    }
  }

  /**
   * getting permissions based on user id
   * @param stat 
   */
  getAdminPermissions(stat?) {
    const payloadData = {
      type: 'GET',
      url: 'getPermissions',
      isDeveloper: true
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        let tempPerms = ['APPOINTMENT_CREATE', 'APPOINTMENT_EDIT', 'PATIENT_EDIT', 'PATIENT_DETAILS', 'INVOICE_EDIT', 'INVOICE_DETAILS'];
        res.data.permissions.forEach(obj => {
            this.permissionsArr.push(obj.permissionName);
        });
        if (!this.permissionsArr.includes('DOCUMENT_LIST')) {
          this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.ACCESS_PERMISSOIN_DENIED_MSG);
          this.router.navigate(['/admin']);
        }
        tempPerms.forEach(perm => {
          if (!this.permissionsArr.includes(perm)) {
            this.adminPermissions[perm] = false;
          }
        });
      } else {
        // Error
        let message = (res.error) ? res.error.message : TOAST_MESSAGES.ACCESS_PERMISSION_ERROR_MSG;
        console.log(message);
      }
    });
    this.permissionsDone = true;
  }

   /**
    * Get document list API call
    * @param bodyData 
    */
   getDocList(bodyData) {
    this.isShowScreen = this.blockPatientApplyStatus ? true : false;
    const payloadData: IPostMethodPayload = {
      type: HTTPMethods.POST,
      url: API_URLS.REQDOC_LIS,
      isDeveloper: true,
      body: bodyData,
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.docListCount = res.data.pagination.totalItems[0].count;
        this.getTreatmentsDataLength(res);
      } else {
        // Error
        let message = TOAST_MESSAGES.DOCS_LIST_FAIL;
        message = (res.error) ? res.error.message : message;
        if (message === 'Insufficient Privilege') {
          this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.ACCESS_PERMISSOIN_DENIED_MSG);
          this.router.navigate(['/admin']);
        } else { this.observable.showToast(TOAST_STATUSES.ERROR, message); }
      }
    });
  }

  /**
   * Get All Treatments Data length
   */
  getDocFilterData() {
    const payloadData: IGetMethodPayload = {
      type: HTTPMethods.GET,
      url: API_URLS.DOC_FILTERS,
      isDeveloper: true,
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.rawFilterData = this.massageDocFilterData(res.data);
      } else {
        // Error
        let message = TOAST_MESSAGES.DOC_FILTER_FAIL;
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  /**
   * Get All Treatments Data length
   * @param patientData 
   */
  getTreatmentsDataLength(patientData) {
    this.isShowScreen = this.blockPatientApplyStatus ? true : false;
    const payloadData: IGetMethodPayload = {
      type: 'GET',
      url: 'invoiceFilter',
      isDeveloper: true,
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        // this.getFilterData = res.data;
        this.documentList = this.massasgeDocumentData(patientData.data.list);
        this.paginationData = this.massagePaginationData(patientData.data);
        this.checkingSidebarState(false);
      } else {
        // Error
        let message = TOAST_MESSAGES.DOCS_LIST_FAIL;
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
      this.isShowScreen = true;
    });
  }

  /**
   * Massage the filter data
   * @param data 
   */
  massageDocFilterData(data) {
    if (data && typeof (data) === 'object') {
      data.requestType = data.requestType.map(doc => {
        return {
          ...doc,
          name: doc.documentName,
          value: doc.documentId,
          isSelected: false,
          editable: true
        };
      });
      data.docStatus = data.status.map(doc => {
        return {
          name: this.getStatusLabel(doc),
          value: doc,
          isSelected: false,
          editable: true
        };
      });
      delete data.status;
      return data;
    } else {
      // Error
    }
  }

   /**
    * This will do massaging for pagination Data
    * @param data 
    */
   massagePaginationData(data) {
    if (!data && typeof (data) !== 'object') {
      // Error
    }
    return {
      limit: data.pagination.limit,
      currentPage: data.pagination.page,
      totalPages: Math.ceil(data.pagination.totalItems[0].count / data.pagination.limit),
      data: data.list
    };
  }

  /**
   * This function is for massaging the documents list.
   */
  massasgeDocumentData(data) {
    if (!data && !Array.isArray(data)) return [];
    return data.map(each => {
      return {
        ...each,
        creator: (each.createdBy !== 'ADMIN') ? each.empFirstName + ' ' + each.empLastName : each.createdBy,
        statusLabel: this.getStatusLabel(each.status),
        statusIcon: this.getStatusIcon(each.status),
        totalDocuments: this.getTotalDocuments(each.documents),
        patient: each.firstname + ' ' + each.lastname,
        patientId: each.patientId
      };
    });
  }

  /**
   * This function is for changing the status as Labels
   * @param status 
   */
  getStatusLabel(status) {
    if (status && status === 'P') {
      return 'Pending';
    } else if (status && (status === 'C')) {
      return 'Completed';
    } else if (status && status === 'D') {
      return 'Cancelled';
    }
  }

  /**
   * This function is for getting status based icons
   * @param status 
   */
  getStatusIcon(status) {
    if (status && status === 'P') {
      return 'inprogress';
    } else if (status && (status === 'C')) {
      return 'completed';
    } else if (status && status === 'D') {
      return 'canceled';
    }
  }

  /**
   * This function is for string concating all the documents name.
   * @param documents 
   */
  getTotalDocuments(documents) {
    var string = '';
    if (documents.length) {
      documents.forEach((doc, ind) => {
        string = string + `${doc.documentName}${(ind !== documents.length - 1) ? ',' : ''} `;
      });
      return string;
    }
  }

  /**
   * Search input
   * @param e 
   */
  searchFilter(e) {
    if(e.replace(/\s/g, '').length > 0 || e == ''){
      this.searchInputText = e;
      this.INITIAL_PAYLOAD.filter = this.getFilterData;
      this.INITIAL_PAYLOAD.search = this.searchInputText;
      this.blockPatientApplyStatus = false;
      this.getDocList(this.INITIAL_PAYLOAD);
    }

  }

  /**
   * This will do filter functionality
   */
  filterAppointments() {
    this.sideBarVisible = !this.sideBarVisible;
    this.checkingSidebarState(this.sideBarVisible);
    let filterSidePanel = document.querySelectorAll('.p-sidebar-width')[1];
    filterSidePanel.scrollTo(0, 0);
  }

  /**
   * Pagination output
   * @param data 
   */
  pageChangedEvent(data) {
    if (data) {
      this.pageNumber = data.currentPage;
      this.pageLimit = data.limit;
      let pageClickedData = {
        filter: this.getFilterData,
        pagination: {
          page: data.currentPage,
          limit: data.limit,
        },
        search: this.searchInputText,
      };
      this.blockPatientApplyStatus = false;
      this.getDocList(pageClickedData);
    }
  }


  /**
   * This emit filter form Data
   * @param data 
   */
  filterDataEvent(data) {
    this.getFilterData = data;
    this.appyliedFilterObj = {
      filter: data,
      search: this.searchInputText,
      pagination: {
        page: 1,
        limit: DEFAULT_PAGINATION_LIMIT
      },
    };
    this.getDocList(this.appyliedFilterObj);
  }

   /**
   * checking side bar status
   * @param data 
   */
  checkingSidebarSetStatus(data) {
    this.sideBarVisible = data;
    this.checkingSidebarState(data);
  }
}
