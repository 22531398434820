import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-services-card',
  templateUrl: './services-card.component.html',
  styleUrls: ['./services-card.component.scss']
})
export class ServicesCardComponent implements OnInit {

  showFullDesciption = false;

  moreText = {
    moreTextLink: 'Read more'
  };
  lessText = {
    moreTextLink: 'Read less'
  };

  @Input() serviceCard = {
    heading: 'medical consultations',
    imgPath: 'jpgs/services-medical.jpg',
    cardLabel: 'services-medicaled',
    description: 'A procedure whereby, upon request by one healthcare provider, another healthcare provider reviews a patient\'s medical history, examines the patient and makes recommendations as to care and treatment. At New Tele Doc the medical consultation is a multidimensional process, focused on the doctor-patient relationship but is also a pivotal piece to provide support regarding the health-disease, suffering, and uncertainty that a patient has when their physical integrity is affected -human and emotional.',
    showBackgroundImg: false,
    classData: 'blue'
  };

  constructor() { }

  ngOnInit() {
  }

  readMore(event) {
    this.showFullDesciption = !this.showFullDesciption;
  }

}
