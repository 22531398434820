<ng-template #termsandcondition>
    <div class="termsandcondition__section">
        <label>Acknowledgment</label>
        <div class="termsandcondition">
            <p>{{termsAndConditionData.description}}</p><br>
            <p>{{termsAndConditionData.listdescription}}</p><br>
            <ol>
                <li *ngFor="let data of termsAndConditionData.list">{{data}}</li>
            </ol>
        </div>
    </div>
</ng-template>


<section *ngIf="showScreen" class="new-pateint__section">
    <div class="step__indicator">
        <app-step-indicator [isHorizontal]="false" [stepDetails]="stepDetails"
            (stepIndexClicked)="stepIndicatorClicked($event)" (forwardClicked)="forwardClicked()"
            (backClicked)="backClicked()" [currentStepIndex]="currentFormIndex" (sendStepIndex)="getStepIndex($event)">
        </app-step-indicator>
    </div>
    <div class="form__container">
        <div class="close-icon">
            <app-svg [name]="'plus-circle'" (click)="navigateBack($event)"></app-svg>
        </div>
        <div *ngIf="!editable" class="floating-highlight-text"><app-svg [name]="'alert-color'"></app-svg> Please note that providing invalid/misleading information
            may result in appointment cancellation.</div>
        <div class="background-image">
            <img alt="Background-leaf" src="assets/images/pngs/newpatientform-background.png">
        </div>
        <div *ngIf="showForm" class="patient-form" (scroll)="onScroll($event)">
            <div class="form__field" *ngIf="!isEditAndView">
                <div class="custom__radio-button" *ngFor="let form of adminFormType" (change)="selectedRadio(form)">
                    <input type="radio" id="{{form.value}}" [value]="form.value" name="date" [(ngModel)]="typeOfForm">
                    <label for="{{form.value}}" class="radio-label"><span class="icon--styles">
                        </span>{{form.label}}</label>
                </div>
            </div>
            <div class="search-filter__block" *ngIf="formType ==='existingUser'">
                <app-svg [name]="'search'" class="inside-search"></app-svg>
                <p-autoComplete delay="1500" field="email" [suggestions]="emailSearchList" (onSelect)="selectedEmail($event)" (keyup)="search($event)">
                    <ng-template let-result pTemplate="item">
                        <div class="result-item">
                            <div>{{result.email}}</div>
                        </div>
                    </ng-template>
                </p-autoComplete>
            </div>
            <div class="valid--msg invalid-feedback" *ngIf="formType ==='existingUser' && !isValidEmail">Please enter valid email address</div>
            <div class="note--msg" *ngIf="(formType ==='existingUser' && !isUserExisting)">Note: No user found. A sign up invite will be sent to the above email id.</div>
            <ng-container *ngIf="formType ==='newUser'">
                <div *ngFor="let formData of newUser;let i = index" class="formdata">
                    <div *ngIf="(currentFormIndex == i) || !isMobile">
                        <div *ngIf="i == (newUser.length - 1)">
                            <ng-container *ngTemplateOutlet="termsandcondition">
                            </ng-container>
                        </div>
                        <app-custom-form [formData]="formData"
                            [checkFormInfo]="saveFormData ? resetSaveFormParity() : false"
                            (inValidSubmitEvent)="inValidSubmitEvent($event,i)" (submitEvent)="getFormData($event, i)"
                            (focusInput)="focusInput($event)" (focusOutInput)="focusOutInput($event)"
                            (sliderValue)="sliderValue($event)" (addNewClicked)="addNew($event)"
                            [adminTypeForm]="formType"
                            (isFileUploadPending)="isFileUploadPending($event)"
                            [editViewData]="editViewClicked? editViewData : ''"
                            (addNewPatient)="onDropdownChange($event)"
                            (showDocs)="showDocs($event)"
                            ></app-custom-form>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="formType ==='existingUser'">
                <div *ngFor="let formData of existingUser;let i = index" class="formdata">
                    <div *ngIf="(currentFormIndex == i) || !isMobile">
                        <div *ngIf="i == (existingUser.length - 1)">
                            <ng-container *ngTemplateOutlet="termsandcondition">
                            </ng-container>
                        </div>
                        <app-custom-form [formData]="formData"
                            [checkFormInfo]="saveFormData ? resetSaveFormParity() : false"
                            (inValidSubmitEvent)="inValidSubmitEvent($event,i)" (submitEvent)="getFormData($event, i)"
                            (focusInput)="focusInput($event)" (focusOutInput)="focusOutInput($event)"
                            (sliderValue)="sliderValue($event)" (addNewClicked)="addNew($event)"
                            [adminTypeForm]="formType"
                            (isFileUploadPending)="isFileUploadPending($event)"
                            [editViewData]="editViewClicked? editViewData : ''"
                            (addNewPatient)="onDropdownChange($event)"
                            (showDocs)="showDocs($event)"
                            ></app-custom-form>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="formType ==='offlinePatient'">
                <div *ngFor="let formData of form;let i = index" class="formdata">
                    <div *ngIf="(currentFormIndex == i) || !isMobile">
                        <div *ngIf="i == (form.length - 1)">
                            <ng-container *ngTemplateOutlet="termsandcondition">
                            </ng-container>
                        </div>
                        <app-custom-form [formData]="formData"
                            [checkFormInfo]="saveFormData ? resetSaveFormParity() : false"
                            (inValidSubmitEvent)="inValidSubmitEvent($event,i)" (submitEvent)="getFormData($event, i)"
                            (focusInput)="focusInput($event)" (focusOutInput)="focusOutInput($event)"
                            (sliderValue)="sliderValue($event)" (addNewClicked)="addNew($event)"
                            [adminTypeForm]="formType"
                            (isFileUploadPending)="isFileUploadPending($event)"
                            [editViewData]="editViewClicked? editViewData : ''"
                            (addNewPatient)="onDropdownChange($event)"
                            (showDocs)="showDocs($event)"
                            ></app-custom-form>
                    </div>
                </div>
            </ng-container>

            <div class="btn-actions" *ngIf="!this.editable">
                <div class="cancel-btn" (click)="navigateBack($event)">Cancel</div>
                <div class="common-btn-style" (click)="saveForm()">{{getActionText()}}</div>
                <div class="prev-btn-style" *ngIf="currentFormIndex >0"
                    [ngClass]="currentFormIndex + 1 == 1 ?'back': 'back--enable'" (click)="prevForm()">Prev</div>
            </div>
        </div>

    </div>
</section>
<app-common-popup (showHideCancelPopup)='cancelPopupShowHideStatusFun($event)' *ngIf="cancelPopupShowHide">
    <div class="popup-content-block">
        <div class="confirm-message">Are you sure there are no updates?</div>
        <div class="btn-actions">
            <div class="common-btn-style yes-btn" (click)="cancelPopupShowHideStatusFun($event)">No</div>
            <div class="common-btn-style" (click)="createPatient();cancelPopupShowHide = false">Yes</div>
        </div>
    </div>
</app-common-popup>
<app-common-popup (showHideCancelPopup)='closeConfirmTerms($event)' *ngIf="isMobile && showConfirmTerms && !editable">
    <div class="popup-content-block confirm-terms-popup">
        <div class="confirm-message">Please note that providing invalid/misleading information <br>may result in
            appointment cancellation.</div>
        <div class="btn-actions btn-actions-center">
            <div class="common-btn-style" (click)="showConfirmTerms = false">OK</div>
        </div>
    </div>
</app-common-popup>

<app-common-popup (showHideCancelPopup)='cancelPopup($event)' *ngIf="showPharmacyStatePopup">
    <div class="popup-content-block pharmacy-state">
        <div class="confirm-message">Medications will not be sent outside of the DMV area.</div>
        <div class="btn-actions">
            <div class="common-btn-style" (click)="showPharmacyStatePopup = false">Ok</div>
        </div>
    </div>
</app-common-popup>

<app-common-popup (showHideCancelPopup)='cancelDocListPopup($event)' *ngIf="showDocListPopup">
    <div class="popup-content-block doclist-popup">
        <div class="confirm-message">Uploaded Documents</div>
        <div class="documents">
            <div *ngFor="let task of docList" class="each_document">
                {{task}}
            </div>
        </div>
    </div>
</app-common-popup>