import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, Pipe } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { API_URLS } from '@app/common/constants/api-urls.constant';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';
import { IFormType, IGetMethodPayload, IPostMethodPayload, IStepperDataI } from '@app/common/constants/typeInterfaces.constants';
import { HTTPMethods, TOAST_STATUSES } from '@app/common/constants/util.constant';
import { CustomTimePipe } from '@app/common/pipes/custom-time.pipe';
import { DateFormatPipe } from '@app/common/pipes/date-format.pipe';
import { CognitoService } from '@app/common/services/cognito.service';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { DataService } from '@app/common/services/data.service';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-admin-ketamine-monitoring-form',
  templateUrl: './admin-ketamine-monitoring-form.component.html',
  styleUrls: ['./admin-ketamine-monitoring-form.component.scss']
})
export class AdminKetamineMonitoringFormComponent implements OnInit {
  // input for step indicator
  stepDetails: IStepperDataI[] = [
    {
      label: 'Patient Information',
      id: 'patient_information',
      img: 'content',
      visited: true,
      notVisisted: false,
      completed: false,
      onPage: true
    },
    {
      label: 'Concomitant Medication',
      id: 'concomitant_medication',
      img: 'heart',
      visited: false,
      notVisisted: true,
      completed: false,
      onPage: false
    },
    {
      label: 'Appointment Information',
      id: 'appointment_information',
      img: 'miscellaenous',
      visited: false,
      notVisisted: true,
      completed: false,
      onPage: false
    },
    {
      label: 'Submit',
      id: 'submit',
      img: 'submit',
      visited: false,
      notVisisted: true,
      completed: false,
      onPage: false
    }
  ];
  currentFormIndex: number = 0;
  isSave: boolean = false;
  isMobile: boolean = false;
  saveFormData: boolean = false;
  appointmentId: number;
  medicationFormData: IFormType;
  treatmentSessionFormData: IFormType;
  formPosArray: Array<any>;
  elementsHeight: Array<any>;
  getFormInfo = { // get the form data not checking
    patient_information: false,
    concomitant_medication: false,
    appointment_information: false,
    submit: false
  };
  cancelPopupShowHide: boolean = false;
  bodyData: Record<string, any>;
  appointmentDetails: Record<string, any>;
  dateFormatePipe = new DateFormatPipe();
  timeFormatePipe = new CustomTimePipe();
  ketamineSessionMinutes: number = 90;
  sessionStartTime: string;
  sessionEndTime: string;
  datePipe: DatePipe = new DatePipe('en-US');
  constructor(
    private formService: CustomFormHandlerService,
    private cd: ChangeDetectorRef,
    private elRef: ElementRef,
    private observableHelperService: ObservableHelperService,
    private route: ActivatedRoute,
    private http: HttpService,
    private router: Router,
    private dataService: DataService,
    private observable: ObservableHelperService,
    private cognitoService: CognitoService
  ) {
    this.route.params.subscribe(params => {
      this.appointmentId = params.id;
    });
  }

  ngOnInit() {
    this.medicationFormData = this.formService.initForm(this.formService.formTypes.KETAMINE_MEDICATION);
    this.treatmentSessionFormData = this.formService.initForm(this.formService.formTypes.KETAMINE_TREATMENT_SESSION);
    this.getAppointmentDetails();
  }

  ngAfterViewInit(): void {
    // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    // Add 'implements AfterViewInit' to the class.
    document.querySelector('html').style.overflow = 'hidden';
    document.querySelector('body').style.overflow = 'hidden';
    document.querySelector('.copy__right--section')['style'].display = 'none';
  }
  ngAfterContentChecked() {
    this.getFormElements();
  }

  /**
   * Get appointment details API call
   */
  getAppointmentDetails() {
    const payloadData: IGetMethodPayload = {
      type: HTTPMethods.GET,
      url: API_URLS.GET_APPOINTMENT_DETAILS,
      isDeveloper: true,
      pathVariables: [this.appointmentId]
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.appointmentDetails = res.data;
        this.setFormDefaultValues();
      } else {
        let message = (res.error) ? res.error.message : TOAST_MESSAGES.APPOINTMENT_DETAILS_ERROR_MSG;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  /**
   * This function is for setting the default values
   */
  setFormDefaultValues() {
    let monitoringData = this.appointmentDetails.appointmentData.ketamineMonitoring.AdminKetamineMonitoringFormData;
    this.medicationFormData.formInputs.forEach((each, ind) => {
      if (each.key === 'benzodiazepines' || each.key === 'nonBenzodiazepine' || each.key === 'psychostimulants' || each.key === 'monoamineOxidaseInhibitors') {
        each.value = (monitoringData && monitoringData.treatmentSessionInformation) ? !monitoringData.medicationDetails[each.key] : true;
      }
    });
    this.treatmentSessionFormData.formInputs.forEach((each, ind) => {
      if (each.key === 'appointmentDate' || each.key === 'date') {
        each.value = this.datePipe.transform(this.appointmentDetails.appointmentInfo.appointmentDate);
        this.treatmentSessionFormData.formGroup.controls[each.key].setValue(each.value);
      } else if (each.key === 'signature') {
        each.value = this.appointmentDetails.appointmentData.medicationConsent.consentSignature;
        this.treatmentSessionFormData.formGroup.controls[each.key].setValue(each.value);
      } else if (each.key === 'startTime' || each.key === 'startTimeSuffix') {
        const startTime = (monitoringData && monitoringData.treatmentSessionInformation) ? monitoringData.treatmentSessionInformation.startTime : this.appointmentDetails.appointmentInfo.timeSlotFrom;
        let startApptTime: string = this.timeFormatePipe.transform(startTime);
        let [time, timeSuffix]: string[] = startApptTime.split(' ');
        time = (parseInt(time.split(':')[0]) < 10) ? `0${time}` : time;
        if (each.key === 'startTime') {
          each.value = time;
          this.treatmentSessionFormData.formGroup.controls[each.key].setValue(each.value);
        } else {
          each.value = timeSuffix;
          this.treatmentSessionFormData.formGroup.controls[each.key].setValue(each.value);
        }
      } else if (each.key === 'endTime' || each.key === 'endTimeSuffix') {
        const endTime = (monitoringData && monitoringData.treatmentSessionInformation) ? monitoringData.treatmentSessionInformation.endTime : this.addMinutesToTime(this.appointmentDetails.appointmentInfo.timeSlotFrom, this.ketamineSessionMinutes);
        let endApptTime: string = this.timeFormatePipe.transform(endTime);
        let [time, timeSuffix]: string[] = endApptTime.split(' ');
        time = (parseInt(time.split(':')[0]) < 10) ? `0${time}` : time;
        if (each.key === 'endTime') {
          each.value = time;
          this.treatmentSessionFormData.formGroup.controls[each.key].setValue(each.value);
        } else {
          each.value = timeSuffix;
          this.treatmentSessionFormData.formGroup.controls[each.key].setValue(each.value);
        }
      } else if (each.key === 'dissociation') {
        each.value = (monitoringData && monitoringData.treatmentSessionInformation) ? !monitoringData.treatmentSessionInformation.dissociation : true;
      } else if (each.key === 'actualDoseAdministered') {
        each.value = (monitoringData && monitoringData.treatmentSessionInformation) ? monitoringData.treatmentSessionInformation.actualDoseAdministered : '50';
      } else if (each.key === 'bpPriorToAdministration' || each.key === 'bpPostAdministration' || each.key === 'bpReadyToLeave') {
        each.value = (monitoringData && monitoringData.treatmentSessionInformation) ? monitoringData.treatmentSessionInformation[each.key] : '120';
      } else if (each.key === 'pulseRatePriorToAdministration' || each.key === 'pulseRatePostAdministration' || each.key === 'pulseRateReadyToLeave') {
        each.value = (monitoringData && monitoringData.treatmentSessionInformation) ? monitoringData.treatmentSessionInformation[each.key] : '84';
      } else if (each.key === 'clinicallyReadyToLeave' || each.key === 'sedation' || each.key === 'confirmReadyToLeave' || each.key === 'confirmKetamineAdministration' || each.key === 'areMonitoredThroughoutSession') {
        each.value = (monitoringData && monitoringData.treatmentSessionInformation) ? !monitoringData.treatmentSessionInformation[each.key] : false;
      } else if (each.key === 'readyToLeave') {
        each.value = (monitoringData && monitoringData.treatmentSessionInformation) ? monitoringData.treatmentSessionInformation[each.key] : '90';
      }
    });
  }

  calculateTimeDifference() {
    this.sessionStartTime = this.convertTime12to24(this.treatmentSessionFormData.formGroup.value.startTime, this.treatmentSessionFormData.formGroup.value.startTimeSuffix);
    this.sessionEndTime = this.convertTime12to24(this.treatmentSessionFormData.formGroup.value.endTime, this.treatmentSessionFormData.formGroup.value.endTimeSuffix);
    let startTimeInMinutes: number = this.formService.convertHoursToMinutes(this.sessionStartTime);
    let endTimeInMinutes: number = this.formService.convertHoursToMinutes(this.sessionEndTime);
    return endTimeInMinutes - startTimeInMinutes;
  }



  /**
   * This function is fired when AM/PM dropdown changed
   * @param field :Form field
   */
  onChangeDropDown(field) {
    if (field) {
      this.treatmentSessionFormData.formInputs.forEach(each => {
        if (each.key === 'readyToLeave') {
          each.value = JSON.stringify(this.calculateTimeDifference());
          this.treatmentSessionFormData.formGroup.controls[each.key].setValue(JSON.stringify(this.calculateTimeDifference()));
        }
      });
    }
  }

  /**
   * This function is for fired when time input changed
   * @param field :Form field
   */
  onChangeMaskInput(field) {
    if (field) {
      this.treatmentSessionFormData.formInputs.forEach(each => {
        if (each.key === 'readyToLeave') {
          each.value = JSON.stringify(this.calculateTimeDifference());
          this.treatmentSessionFormData.formGroup.controls[each.key].setValue(JSON.stringify(this.calculateTimeDifference()));
        }
      });
    }
  }

  /**
   * This function is to add minutes to particular time
   * @param time :'06:00:00'
   * @param min :90
   * @returns :'07:30:00'
   */
  addMinutesToTime(time, min) {
    let times = time.split(':');
    // clear here more than 24 hours
    min = min % (24 * 60);
    times[0] = (parseInt(times[0])) + parseInt(JSON.stringify(min / 60));
    times[1] = parseInt(times[1]) + min % 60;
    // here control if hour and minutes reach max
    if (times[1] >= 60) { times[1] = 0; times[0]++; }
    times[0] >= 24 ? times[0] -= 24 : null;

    // here control if less than 10 then put 0 frond them
    times[0] < 10 ? times[0] = '0' + times[0] : null;
    times[1] < 10 ? times[1] = '0' + times[1] : null;

    return times.join(':');
  }


  /**
   * This is for stroing all stepper form heights
   */
  getFormElements() {
    let patientFormElementArray = document.querySelectorAll('.step');
    this.formPosArray = [];
    this.elementsHeight = [];
    patientFormElementArray.forEach((element: any) => {
      this.formPosArray.push(element.getBoundingClientRect());
    });
    let sum = 0;
    this.formPosArray.forEach((element, ind) => {
      if (ind === 0) {
        sum = sum + element.height + 40;
      } else if (ind === 1) {
        sum = sum + element.height + 35;
      } else {
        sum = sum + element.height;
      }
      this.elementsHeight.push(sum);
    });
  }


  /**
   *
   * @param event :scroll event
   * This function is for getting the scroll positon and updating stepper data
   */
  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    const scrollTop: number = event.target.scrollTop;
    if (scrollTop >= 0 && scrollTop < this.elementsHeight[0]) {
      this.setStepindicator(0);
    } else if (scrollTop >= this.elementsHeight[0] && scrollTop < this.elementsHeight[1]) {
      this.setStepindicator(1);
    } else if (scrollTop >= this.elementsHeight[1] && scrollTop < (this.elementsHeight[2] / 2) + 231) {
      this.setStepindicator(2);
    } else if (scrollTop >= (this.elementsHeight[2] / 2) + 231 && scrollTop < this.elementsHeight[3]) {
      this.setStepindicator(3);
    }
  }



  /**
   *
   * @param index :Stepper index
   * This is for reseting the stepper data
   */
  setStepindicator(index: number) {
    this.stepDetails.forEach((element, ind) => {
      element.onPage = false;
      this.getFormInfo[element.id] = false;
      if (ind <= index) {
        element.notVisisted = false;
      }
    });
    this.stepDetails[index].onPage = true;
    this.stepDetails[index].visited = true;
    this.getFormInfo[this.stepDetails[index].id] = true;
    var stepContainer = document.querySelector('.step__indicator');
    if (index === 0) {
      stepContainer.scrollTop = 0;
    } else {
      stepContainer.scrollTop = this.elementsHeight[index - 1];
    }
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    document.querySelector('html').style.overflow = 'auto';
    document.querySelector('body').style.overflow = 'auto';
    document.querySelector('.copy__right--section')['style'].display = 'flex';
  }


  /**
   *
   * @param index :Stepper index
   * This funciton will set the current stepper status
   */
  stepIndicatorClicked(index: number): void {
    this.stepDetails.forEach((element, eIndex) => {
      element.onPage = false;
      if (eIndex <= index) {
        element.visited = true;
      }
    });
    this.stepDetails[index].onPage = true;
    if (!this.isMobile) {
      var formContainer = document.querySelector('.ketamine-form');
      if (index === 0) {
        formContainer.scrollTop = 0;
      } else {
        formContainer.scrollTop = this.elementsHeight[index - 1];
      }
    }
  }


  resetSaveFormParity() {
    window.setTimeout(() => {
      this.saveFormData = false;
    }, 0);
    return true;
  }

  /**
   * This funciton is for to scroll top
   */
  scrollTop() {
    if (document.getElementsByClassName('new-pateint__section')[0]) {
      document.getElementsByClassName('new-pateint__section')[0].scrollTo(0, 0);
    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  /**
   * This will navigate to appointment details page
   */
  navigateBack() {
    this.router.navigate([ROUTE_PATHS.ADMIN, ROUTE_PATHS.APPOINTMENTS, this.appointmentId]);
  }

  /**
   * event:Form Group values
   * index:stepper index
   */
  getFormData(event, index) {
    this.stepDetails[index].completed = true;
    this.stepDetails[0].completed = true;
    this.stepDetails[3].completed = true;
    this.scrollTop();
    setTimeout(() => {
      if (document.getElementsByClassName('invalid-feedback') && document.getElementsByClassName('invalid-feedback')[0]) {
        document.getElementsByClassName('invalid-feedback')[0].scrollIntoView({ block: 'center' });
      }
    }, 2);
    var isBelowThreshold = (currentValue) => currentValue.completed === true;
    if (this.stepDetails.every(isBelowThreshold)) {
        this.cancelPopupShowHide = true;
        this.preparingBodyData();
        if (this.isSave) {
          this.submit();
        }
      }
  }

  /**
   * This function will fire is the form fields are invalide
   * @param event :From Group
   * @param index : Index of the form
   */
  inValidSubmitEvent(event, index) {
    if (this.stepDetails[index].completed) {
      this.stepDetails[index].completed = false;
    }
  }

  /**
   * This function is for to trigger the form submit button
   */
  saveForm() {
    this.saveFormData = true;
  }

  /**
   * @param status :Popup status
   * This function is for show/hide the popup
   */
  cancelPopupShowHideStatusFun(status) {
    this.cancelPopupShowHide = status.cancelpopup;
  }


  /**
   *
   * @param time :01:30 PM
   * @returns :'13:30:00'
   */
  convertTime12to24(time: string, modifier: string) {
    let [hours, minutes] = (time) ? time.split(':') : '00:00';
    if (hours === '12') {
      hours = '00';
    }
    if (modifier === 'PM') {
      hours = (parseInt(hours, 10) + 12) + '';
    }
    hours = (parseInt(hours)).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
    minutes = (parseInt(minutes)).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
    return `${hours}:${minutes}:00`;
  }

  /**
   * Preparing payload data
   */
  preparingBodyData() {
    this.bodyData = {
      medicationDetails: {
        benzodiazepines: !this.medicationFormData.formGroup.value.benzodiazepines,
        nonBenzodiazepine: !this.medicationFormData.formGroup.value.nonBenzodiazepine,
        psychostimulants: !this.medicationFormData.formGroup.value.psychostimulants,
        monoamineOxidaseInhibitors: !this.medicationFormData.formGroup.value.monoamineOxidaseInhibitors
      },
      treatmentSessionInformation: {
        actualDoseAdministered: parseInt(this.treatmentSessionFormData.formGroup.value.actualDoseAdministered),
        startTime: this.convertTime12to24(this.treatmentSessionFormData.formGroup.value.startTime, this.treatmentSessionFormData.formGroup.value.startTimeSuffix),
        endTime: this.convertTime12to24(this.treatmentSessionFormData.formGroup.value.endTime, this.treatmentSessionFormData.formGroup.value.endTimeSuffix),
        confirmKetamineAdministration: !this.treatmentSessionFormData.formGroup.value.confirmKetamineAdministration,
        confirmReadyToLeave: !this.treatmentSessionFormData.formGroup.value.confirmReadyToLeave,
        areMonitoredThroughoutSession: !this.treatmentSessionFormData.formGroup.value.areMonitoredThroughoutSession,
        bpPriorToAdministration: this.treatmentSessionFormData.formGroup.value.bpPriorToAdministration,
        bpPostAdministration: this.treatmentSessionFormData.formGroup.value.bpPostAdministration,
        bpReadyToLeave: this.treatmentSessionFormData.formGroup.value.bpReadyToLeave,
        pulseRatePriorToAdministration: this.treatmentSessionFormData.formGroup.value.pulseRatePriorToAdministration,
        pulseRatePostAdministration: this.treatmentSessionFormData.formGroup.value.pulseRatePostAdministration,
        pulseRateReadyToLeave: this.treatmentSessionFormData.formGroup.value.pulseRateReadyToLeave,
        // pulseRate: this.treatmentSessionFormData.formGroup.value.pulseRate,
        clinicallyReadyToLeave: !this.treatmentSessionFormData.formGroup.value.clinicallyReadyToLeave,
        readyToLeave: parseInt(this.treatmentSessionFormData.formGroup.value.readyToLeave),
        sedation: !this.treatmentSessionFormData.formGroup.value.sedation,
        dissociation: !this.treatmentSessionFormData.formGroup.value.dissociation
      }
    };
  }
  /**
   * Submitting the form data
   */
  submit() {
    const payload: IPostMethodPayload = {
      type: HTTPMethods.POST,
      url: API_URLS.ADMIN_KETAMINE_MONITORING,
      isDeveloper: true,
      body: this.bodyData,
      pathVariables: [this.appointmentId],
      params: (this.isSave) ? { isSave: true } : undefined
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.navigateBack();
        if (this.isSave) {
          this.observable.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.KETAMINE_MONITORING_SAVE_MSG);
        } else {
          this.observable.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.KETAMINE_MONITORING_SUBMIT_MSG);
        }
      } else {
        let message = (res.error) ? res.error.message : TOAST_MESSAGES.KETAMINE_MONITORING_ERROR_MSG;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
}
