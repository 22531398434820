<!-- home banner section start -->
<section class="home__banner-section full-width">
  <!-- home banner container start -->
  <div class="home__banner-container restrict-width padding-lr">
    <!-- home banner left block start -->
    <div class="home__banner-left-block">
      <div class="icon__block medium">
        <img
          class="background-leaf"
          src="assets/images/pngs/loginpage-leaf-1.webp"
          alt="leaf icon viagra online northwest dc"
          loading="lazy"
        />
      </div>
      <div class="icon__block small">
        <img class="background-leaf2" src="assets/images/pngs/leaf2.png" alt="leaf icon Most Insurances Accepted" loading="lazy"/>
      </div>
      <!-- home banner heading block start -->
      <h1 class="home__banner-heading-block">
        <span class="orange-heading">NEW AGE </span>
        <span class="blue-heading">INNOVATIVE </span>
        <span class="blue-heading">MENTAL </span>
        <span class="blue-heading">WELLNESS</span>
      </h1>
      <!-- home banner heading block end -->
      <div class="common-btn-style">
        <a target="_self" href="javascript:void(0);" (click)="navigateAboutPage($event)">Meet The Doctor</a>
      </div>
      <div class="icon__block big">
        <img
          class="background-leaf3"
          src="assets/images/pngs/leaf3.png"
          alt="leaf icon Medicare and Medicaid Provider"
          loading="lazy"
        />
      </div>
    </div>
    <!-- home banner left block end -->
    <!-- home banner right block start -->
    <div class="home__banner-right-block">
      <div class="image__block">
        <img
          class="background-leaf"
          src="assets/images/webp/home.webp"
          alt="banner image medical marijuana evaluations"
          loading="lazy"
        />
      </div>
      <div class="icon__block big">
        <img class="background-leaf3" src="assets/images/pngs/leaf3.png" alt="leaf icon School counseling" loading="lazy"/>
      </div>
    </div>
    <!-- home banner right block end -->
  </div>
  <!-- home banner container end -->
</section>
<!-- home banner section end -->
