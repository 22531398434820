import { Component, OnInit } from '@angular/core';
import { WPAPIService } from '@app/common/services/wpapi.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-blog-banner',
  templateUrl: './blog-banner.component.html',
  styleUrls: ['./blog-banner.component.scss']
})

export class BlogBannerComponent implements OnInit {

  moreText = {
    moreTextLink: 'Read more'
  };

  colorClass = [
    'blue',
    'yellow',
    'pink',
    'purple',
    'green'
  ];
  activeClass = [
    'active'
  ];

  twoWordsString: any;
  remainWordsString: any;
  posts: any = [];
  constructor(private wpapi: WPAPIService, private router: Router) { }

  ngOnInit() {
    this.wpapi.posts('per_page=1').subscribe(posts => {
      this.posts = posts.body;
      var titleText = this.posts[0].title.rendered;
      var remainWords = titleText.split(' ');
      var twoWords = remainWords.splice(0, 2);
      this.twoWordsString = twoWords.join(' ');
      this.remainWordsString = remainWords.join(' ');
    });
  }

  navigateDetailsPage(event, postid) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.router.navigate([`blog/details/${postid}`]);
  }

}
