import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-home-banner',
  templateUrl: './home-banner.component.html',
  styleUrls: ['./home-banner.component.scss']
})
export class HomeBannerComponent implements OnInit {

  constructor(private router: Router, private activeRoute: ActivatedRoute, private meta: Meta, private titleService: Title,private renderer: Renderer2, private elementRef: ElementRef) { }

  ngOnInit() {
    const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.setAttribute('href', 'https://newteledoc.com/');
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          canonicalLink.setAttribute('href', 'https://newteledoc.com/');
        }
      });
    }
    this.meta.addTag({ name: 'description', content: "An overview of Dr. Rama Prayaga’s 'New Tele Doc' clinic with information such as services provided, patient testimonials, and their mission statement."});
    this.meta.updateTag({ name: 'title', content: ' Welcome to our Clinic! - New TeleDoc Washington DC Psychiatrist' });
    this.meta.updateTag({ rel: 'canonical', href: 'https://staging.newteledoc.com/' });
    this.titleService.setTitle('Welcome to our Clinic! - New TeleDoc Washington DC Psychiatrist');
    const script = this.renderer.createElement('script');
    script.type = 'application/ld+json';
    script.text = `[{
      "@context": "http://schema.org",
      "@type": "LocalBusiness",
      "name": "New TeleDoc",
      "image": "/assets/images/pngs/logo.png",
      "telephone": "877-202-8202",
      "email": "officemanager@newstressclinic.com",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "1325 18th St NW Suite 210",
        "addressLocality": "Washington",
        "addressRegion": "DC",
        "addressCountry": "U.S.",
        "postalCode": "20036"
      },
      "openingHoursSpecification": {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday"
        ],
        "opens": "09:00",
        "closes": "19:00"
      },
      "url": "http://newteledoc.com/"
    }]`;
    this.renderer.appendChild(document.head, script);
  }

  navigateAboutPage(event) {
    this.router.navigate([`aboutus`], { queryParams: { scroll: true } });
  }
  removeJsonLdScript() {
    const head = this.elementRef.nativeElement.ownerDocument.head;
    const scripts = head.getElementsByTagName('script');
    for (let i = 0; i < scripts.length; i++) {
      const script = scripts[i];
      if (script.type === 'application/ld+json') {
        this.renderer.removeChild(head, script);
      }
    }
  }
  ngOnDestroy() {
    this.removeJsonLdScript();
  }
}
