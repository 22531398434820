import { Component,EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { DataService } from '@app/common/services/data.service';


@Component({
  selector: 'app-subsentence-treatment',
  templateUrl: './subsentence-treatment.component.html',
  styleUrls: ['./subsentence-treatment.component.scss']
})
export class SubsentenceTreatmentComponent implements OnInit {
  @Input() saveFormData;
  substanceForm: any;
  @Output() submitEvent: EventEmitter<any> = new EventEmitter();



  constructor(private formService:CustomFormHandlerService,private dataService: DataService) { }

  ngOnInit() {
    this.substanceForm = this.formService.initForm(this.formService.formTypes.SUBSENTANCE_AGREEMENT)
    this.formService.subSetenceAgreementConsentFormConfig[0].value = 'New Stress Clinic'
  }
  getFormData(event) {
    if (event) {
      this.formService.subSetenceAgreementConsentFormConfig = this.assignFormValues(event, this.formService.subSetenceAgreementConsentFormConfig);
      this.dataService.setData(this.dataService.dataObject.HIPPA, this.formService.subSetenceAgreementConsentFormConfig);// this.formService.signaturValues[0] = this.formService.subSetenceAgreementConsentFormConfig[this.formService.subSetenceAgreementConsentFormConfig.length - 1].value;
      this.submitEvent.emit({status: event});
    }
  }
  assignFormValues(formData, formConfig) {
    let keys = Object.keys(formData.value);
    formConfig.forEach((each, index) => {
      if (keys.includes(each.key)) {
        each.value = formData.value[each.key];
      }
    });
    return formConfig;
  }
}
