<!-- blog section start -->
<section class="blog__section full-width">
    <!-- bg image block start -->
    <div class="bg-image__block">
        <img src="assets/images/pngs/blog-bg.png" alt="bg image">
    </div>
    <!-- bg image block end -->
    <!-- blog container start -->
    <div class="blog__container restrict-width padding-lr">
        <!-- blog cards block start -->
        <div class="blog__cards-block" *ngIf="posts">
            <app-blog-banner></app-blog-banner>
            <!-- blog card block start -->
            <div class="blog__card-block {{ colorClass[i] }}" (click)="navigateDetailsPage($event, post.id)" *ngFor="let post of posts; let i = index">
                <!-- blog card left block start -->
                <div class="blog__card-left-block">
                    <div class="image__block">
                        <img *ngIf="post" [src]="post.featured_image_src" alt="blog image">
                    </div>
                    <div class="icon__block vsmall">
                        <img class="background-leaf2" src = "assets/images/pngs/leaf5.png" alt="leaf icon"/>
                    </div>
                </div>
                <!-- blog card left block end -->
                <!-- blog card right block start -->
                <div class="blog__card-right-block">
                    <div class="icon__block small">
                        <img class="background-leaf" src = "assets/images/pngs/loginpage-leaf-1.webp" alt="leaf icon" loading="lazy"/>
                    </div>
                    <h3 class="blog__heading">
                        <span class="blue-text">{{ remainWordsString }}</span>
                        <span class="post-title">{{ post.title.rendered }}</span> -->
                        {{ post.title.rendered }}
                    </h3>
                    <p class="blog__post-date">{{ post.date | date:'d MMMM, y' }}</p>
                    <div class="blog__description-block">
                        <p class="blog__description">
                            {{ post.excerpt.rendered }} 
                        </p>
                    </div>
                    <!-- know more btn end -->
                    <app-know-more (actionTriggered)="navigateDetailsPage($event, post.id)" [actionBased]="true" [moreText]="moreText" [show]="true"></app-know-more>
                </div>
                <!-- blog card right block end -->
            </div>
            <!-- blog card block end -->
        </div>
        <!-- blog cards block end -->

        <!-- pagination__block start -->
        <app-blog-pagination (updatedpagesValues)="updatedPages($event)"></app-blog-pagination>
        <!-- pagination__block end -->

    </div>
    <!-- blog container end -->
</section>
<!-- blog section end -->

