<div class="employee-creation">
    <div class="background-image">
        <img src="assets/images/pngs/newpatientform-background.png" alt="bg image">
    </div>
    <div class="employee-creation__content">
        <app-breadcrumb [breadCrumbData]="breadCrumbData"></app-breadcrumb>
        <div class="employee-creation__form">
            <!--custom form start-->
            <app-custom-form [formData]="formInputs" (submitEvent)="employeeFormSubmit($event)" (inValidSubmitEvent)="inValidSubmitEvent($event)" (cancelFormEvent)="cancelButtonEvent($event)" (employeeUploads)="employeeUploadsDocuments($event)" (detailsUploads)="uploads($event)">
            </app-custom-form>
        </div>
    </div>
</div>