<!-- Employee details -->
<div class="employee__page-wrapper" *ngIf="isShowScreen">
    <div class="background-image">
        <img src="assets/images/pngs/newpatientform-background.png" alt="">
    </div>
    <div class="employee__content">
        <!-- header part -->
        <div class="employee__header">
            <app-breadcrumb [breadCrumbData]="breadCrumbData"></app-breadcrumb>
            <!-- Actions list -->
            <div class="employee__actions">
                <ng-container *ngFor="let eachAction of employeeDetailsActions; let i=index">
                    <div class="employee__action" (click)="employeeActionsClick(eachAction.id, employeeData.employeeDetails.empId)">
                        <app-svg [ngClass]="eachAction.className" [name]="eachAction.icon"></app-svg>
                        <div class="employee__action-name">
                            {{eachAction.name}}
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <!-- Employee basic information -->
        <div class="employee__profile">
            <div class="employee__profile-pic">
                <img src={{employeeData.employeeProfileImagLink}} alt="Profile Pic" />
            </div>
            <div class="employee__profile-details">
                <div class="employee__profile-name profile-name">
                    {{employeeData.employeeName | titlecase}}
                </div>
                <div class="employee__profile-id-designation">
                    <!-- <div class="employee__profile-id">
                        {{employeeData.employeeDetails.empId}}
                    </div> -->
                    <div class="employee__profile-id">
                        {{employeeData.employeeDetails.designation | titlecase}}
                    </div>
                </div>
                <div class="employee__profile-contacts">
                    <div class="employee__profile-contact phone">
                        <app-svg class="contact-icon" [name]="'adminMobile'"></app-svg>
                        <p>{{employeeData.employeeDetails.mobile}}</p>
                    </div>
                    <div class="employee__profile-contact mail">
                        <app-svg class="contact-icon" [name]="'mail'"></app-svg>
                        <p>{{employeeData.employeeDetails.email}}</p>
                    </div>
                </div>

            </div>
        </div>
        <!-- Employee basic address  -->
        <div class="employee__addvanced-data">
            <div class="employee__advanced-content location">

                <div class="employee__advanced-content-title">
                    <p class="title-text">Working Location</p>
                    <p class="employee__advanced-content-data location">
                        <ng-container *ngFor="let location of employeeData.employeeDetails.workingLocation; let i=index">
                            <p class="employee__location-title"> {{location | titlecase }}</p>
                        </ng-container>
                    </p>
                </div>
            </div>
            <div class="employee__advanced-content resident">
                <div class="employee__advanced-content-title">
                    <p class="title-text">Resident</p>
                    <div class="employee__advanced-content-data">
                        {{employeeData.employeeDetails.country | uppercase}}
                    </div>
                </div>
            </div>
            <div class="employee__advanced-content address">
                <div class="employee__advanced-content-title">
                    <p class="title-text">Mailing Address</p>
                    <div class="employee__advanced-content-data">
                        {{employeeData.employeeDetails.address  | titlecase}}
                    </div>
                </div>
            </div>
            <div class="employee__advanced-content address">
                <div class="employee__advanced-content-title">
                    <p class="title-text">Date of Birth</p>
                    <div class="employee__advanced-content-data">
                        {{employeeData.employeeDOB | dateFormat}}
                    </div>
                </div>
            </div>
            <div class="employee__advanced-content security-number">
                <div class="employee__advanced-content-title">
                    <p class="title-text">{{employeeData.employeeSecurityName1}}</p>
                    <div class="employee__advanced-content-data">
                        {{employeeData.employeeSecurityNumber | ellipsis : 24}}
                    </div>
                </div>
            </div>
            <div class="employee__advanced-content driving-license">
                <div class="employee__advanced-content-title">
                    <p class="title-text">
                        {{employeeData.employeeSecurityName2}}</p>
                    <div class="employee__advanced-content-data">
                        {{employeeData.employeeDrivingLicenseNumbber | ellipsis : 24}}
                    </div>
                </div>
            </div>
            <div class="employee__advanced-content activity">
                <div class="employee__advanced-content-title">
                    <p class="title-text">Activity</p>
                    <div class="employee__advanced-content-data">
                        {{employeeData.employeeloginActivity}}
                    </div>
                </div>
            </div>
        </div>
        <!-- Employee bank  details -->
        <div class="employee__bank-details">
            <div class="employee__bank-title employee__profile-name">
                Bank Details
            </div>
            <div class="employee__addvanced-data">
                <div class="employee__advanced-content">
                    <div class="employee__advanced-content-title">
                        <p class="title-text">Account Number</p>
                        <div class="employee__advanced-content-data">
                            {{employeeData.employeeDetails.bankDetails.accNo}}
                        </div>
                    </div>
                </div>
                <div class="employee__advanced-content">
                    <div class="employee__advanced-content-title">
                        <p class="title-text">Account Holder</p>
                        <div class="employee__advanced-content-data">
                            {{employeeData.employeeDetails.bankDetails.accHolderName | titlecase}}
                        </div>
                    </div>
                </div>
                <div class="employee__advanced-content">
                    <div class="employee__advanced-content-title">
                        <p class="title-text">Bank Name</p>
                        <div class="employee__advanced-content-data">
                            {{employeeData.employeeDetails.bankDetails.bankName | titlecase}}
                        </div>
                    </div>
                </div>
                <div class="employee__advanced-content">
                    <div class="employee__advanced-content-title">
                        <p class="title-text">Branch</p>
                        <div class="employee__advanced-content-data">
                            {{employeeData.employeeDetails.bankDetails.branchName | titlecase}}
                        </div>
                    </div>
                </div>
                <div class="employee__advanced-content">
                    <div class="employee__advanced-content-title">
                        <p class="title-text">IFSC Code</p>
                        <div class="employee__advanced-content-data">
                            {{employeeData.employeeDetails.bankDetails.IFSC | uppercase}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="employee__id-proofs">
            <div class="employee__bank-title employee__block-sub-title title-text">
                Documents (ID proof)
            </div>
            <div class="employee__id-proofs-documents">
                <div class="employee__id-document internal-uploads"
                    *ngFor="let uploadsFiles of employeeData.employeeIDProfs; let i=index">
                    <div class="upload-document__block" *ngIf="uploadsFiles && uploadsFiles.file">
                        <p>{{uploadsFiles.file.name | ellipsis: 23}}</p>
                        <p>.{{uploadsFiles.file.extenstion}}</p>
                    </div>
                    <div class="upload-close-btn" *ngIf="employeeData.employeeIDProfs.length !==1">
                        <app-svg [name]="'cancel-appointment'" (click)="deleteFileUpload(uploadsFiles)">
                        </app-svg>
                    </div>
                    <div class="employee__id-document-actions">
                        <a [href]="uploadsFiles.docUrl" target="_blank" title="View" class="view-icon">
                            <app-svg [name]="'view-upload'"></app-svg>
                        </a>
                        <p class="down-arrow" (click)="donwloadFile(uploadsFiles.docUrl,uploadsFiles,false)"
                            title="Download">
                            <app-svg [name]="'down-arrow'"></app-svg>
                        </p>
                    </div>
                </div>
                <div class="employee__id-document">
                    <app-multiple-uploads [fileTypes]="uploadFilesData" (uploads)="uploadFunctionCall($event)">
                    </app-multiple-uploads>
                </div>
            </div>
    </div>
</div>