<app-common-popup (showHideCancelPopup)="showOrHideCancelPopup($event)">
    <form [formGroup]="formGroup.formGroup">
        <div class="popup__wrapper"  *ngIf="isShowScreen">
            <div class="popup__heading">Schedule service</div>
            <div class="sub__heading">
                <app-svg [name]="'calendar'" class='calender_svg'></app-svg>
                Select Days
            </div>
        <div class="days_wrapper">
                <div class="day__field" *ngFor="let task of weekArr; let i = index">
                        <p-checkbox [value]="task.day" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="task.isSelected" binary="true" (onChange)="handleDaysChange($event, i)">
                        </p-checkbox><label>{{task.day}}</label>
                    <!--<input class="checkbox" type="checkbox" [checked]="daysSelected.includes(i) ? true : false" (change)="handleDaysChange($event, i)"> <label>{{day}}</label>-->
                </div>
        </div>
        <div class="error-msg days"><span *ngIf="!(daysSelected.length)">Day(s) should be selected</span></div>
        <div class="sub__heading time">
            <app-svg [name]="'time'" class='time_svg'></app-svg>
            Select timing
        </div>
        <div class="time__wrapper">
            <div class="time__field" *ngFor="let field of formInputs; let i = index">
                <label class="time_Drop">{{field.label}}</label>
                <p-dropdown [options]="field.list"
                                    [placeholder]="field.placeholder" [required]="field.required"
                                    [formControlName]="field.key" [(ngModel)]="field.value" (onChange)="dropDown($event, field)">
                    <ng-template let-items pTemplate="item">
                        <div class="dropdown__items dropdown__header">
                            <div class="table__icon__header" *ngIf="items.icon">
                                <app-svg [name]="items.icon"></app-svg>
                            </div>
                            <div class="item__label">{{items.label}}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
            </div>
        </div>
        <div class="error-msg selection"><span *ngIf="showError">{{scheduleErrorMessage}}</span></div>
        <div class="save_button">
            <button (click)="saveSchedule()" class="common-btn-style">Save</button>
        </div>
    </div>
    </form>
</app-common-popup>