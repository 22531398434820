// This component is to send medication mail to miltiple patients.

import { Component, OnInit } from '@angular/core';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { HttpService } from '@app/common/services/http.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { IEmailSearchBody, IPostMethodPayload, IEmailSearchResult, IGetPermissionsPayloadI, IBreadCrumbI, IGetMethodPayload } from '@app/common/constants/typeInterfaces.constants';
import { HTTPMethods, TOAST_STATUSES } from '@app/common/constants/util.constant';
import { API_URLS } from '@app/common/constants/api-urls.constant';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-multiple-patient-email',
  templateUrl: './multiple-patient-email.component.html',
  styleUrls: ['./multiple-patient-email.component.scss']
})
export class MultiplePatientEmailComponent implements OnInit {

  inputChanged: Subject<string> = new Subject<string>();
  searchInput: string = '';
  emailSearchList: Array<string> = [];
  result: IEmailSearchResult = {};
  addedEmailsList: Array<Record<string, any>> = [];
  permissionsDone: boolean = false;
  cancelPopupShowHide: boolean = false;
  breadCrumbData: IBreadCrumbI = {
    link: '/admin/' + `${ROUTE_PATHS.EMPTY}`,
    linkText: 'Send Medication mail'
  };
  templateDetails: SafeHtml;
  MailId: number = 5;
  formSubmitAttempt: boolean = false;
  permissionList: Array<string> = ['PATIENT_CREATE', 'PATIENT_EDIT', 'APPOINTMENT_CREATE', 'APPOINTMENT_EDIT']
  mailType: string = 'MULTIPLE_MEDICATION_MAIL';

  constructor(
    private observableHelperService: ObservableHelperService,
    private http: HttpService,
    private router: Router,
    private sanitized: DomSanitizer,
    private route: ActivatedRoute,
  ) {
    this.inputChanged
      .pipe(debounceTime(1500))
      .pipe(distinctUntilChanged())
      .subscribe(newValue => {
        this.searchInput = newValue;
        this.getSearchEmails(this.searchInput);
      });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(queryParams => {
      if(queryParams.medication) {
        this.mailType = 'MULTIPLE_MEDICATION_MAIL';
        this.breadCrumbData.linkText = "Send Medication mail";
        this.MailId = 5;
      } else if(queryParams.followup) {
        this.mailType = 'TMS_KETAMINE_FOLLOWUP_MAIL';
        this.breadCrumbData.linkText = "Send TMS/Ketamine follow up mail";
        this.MailId = 2;
      }
    });
    this.getAdminPermissions();
    this.getEmailTemplateDetails(this.MailId);
  }

  /**
   * @param templateId :Email template id
   * This function is for to get particular email template details
   */
  getEmailTemplateDetails(templateId) {
    const payload: IGetMethodPayload = {
      type: HTTPMethods.GET,
      url: API_URLS.EMAIL_TEMPLATE_DETAILS,
      isDeveloper: true,
      pathVariables: [templateId],
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        /**
         * bypassSecurityTrustHtml:It will do santizer to HTML and will render with classes and styles
         * Refrence: https://stackoverflow.com/questions/39628007/angular2-innerhtml-binding-remove-style-attribute
         */
        this.templateDetails = this.sanitized.bypassSecurityTrustHtml(res.data.htmlTemplate);
      } else {
        let message = (res.error) ? res.error.message : TOAST_MESSAGES.EMAIL_TEMPLATE_DETAILS_ERROR_MSG;
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  /**
  * search email
  * @param e :enter string in search area
  */
  search(e) {
    if (e.query !== '') {
      this.inputChanged.next(e);
    }
  }

  /**
   * @param e :typed string
   * This function is for getting the email ids
   */
  getSearchEmails(e: any) {
    let bodyData = {
      search: e.query,
      notType: 'pending'
    };
    let payload: IPostMethodPayload = {
      type: HTTPMethods.POST,
      url: API_URLS.SEARCH_PATIENT,
      isDeveloper: true,
      body: bodyData,
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      this.emailSearchList = [];
      if (this.http.isSuccessfulResponse(res) && res) {
        var list = this.massagePatientList(res.data);
        this.emailSearchList = this.filterExcludedEmails(list);
      } else {
        let message = TOAST_MESSAGES.EMAILS_DATA_FETCH_ERROR_MSG;
        message = (res.error) ? res.error.message : message;
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  /**
  * To filter the excluded emails
  * @param emailList :list emails
  * @returns :filtering email list from excluded emails
  */
  filterExcludedEmails(emailList: any[]) {
    if (!emailList && !Array.isArray(emailList)) { return []; }
    return emailList.filter(emailData => !this.addedEmailsList.some(patient => patient.id === emailData.id));
  }

  // MassagePatientList  Data
  massagePatientList(data) {
    if (!data && !Array.isArray(data)) { return []; }
    return data.map(each => {
      return {
        nameOnly: (!each.firstName && !each.lastName) ? '-' :
          (each.firstName) + ' ' + (each.lastName),
        name: (!each.firstName && !each.lastName && !each.patientId) ? '-' :
          (each.firstName) + ' ' + (each.lastName) + '(' + each.patientId + ')',
        id: each.patientId,
        status: each.status,
      };
    });
  }

  /**
  * getting permissions based on user id
  */
  getAdminPermissions() {
    const payloadData: IGetPermissionsPayloadI = {
      type: HTTPMethods.GET,
      url: API_URLS.GET_PERMISSIONS,
      isDeveloper: true
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        let permArr: Array<string> = [];
        res.data.permissions.forEach(obj => {
          permArr.push(obj.permissionName);
        });
        this.permissionList.forEach(element => {
          if (!permArr.includes(element)) {
            this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.ACCESS_PERMISSOIN_DENIED_MSG);
            this.router.navigate([`${ROUTE_PATHS.ADMIN}`]);
          }
        });
       
      } else {
        // Error
        let message: string = (res.error) ? res.error.message : TOAST_MESSAGES.ACCESS_PERMISSION_ERROR_MSG;
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
      }
      this.permissionsDone = true;
    });
  }

  /**
    * @param emailId : patient email id
    * This method is for removing email ids
    */
  removeEmailId(emailId: string) {
    if (emailId) {
      this.addedEmailsList = this.addedEmailsList.filter(email => email.id !== emailId);
    }
    if (this.addedEmailsList.length <= 0 && this.cancelPopupShowHide) {
      this.cancelPopupShowHideStatusFun({ cancelpopup: false });
    }
  }

  /**
   * To hide the popup
   * @param status popup visibility
   */
  cancelPopupShowHideStatusFun(status: Record<string, any>) {
    this.cancelPopupShowHide = status.cancelpopup;
    this.toggleScroll(this.cancelPopupShowHide);
  }
  /**
   * TO hide/show scroll of the body
   * @param status scroll visibility
   */
  toggleScroll(status: boolean) {
    document.body.style.overflow = (status) ? 'hidden' : 'scroll';
  }

  /**
   * Show more excluded emails.
   */
  moreUsersClicked() {
    this.cancelPopupShowHide = true;
    this.toggleScroll(this.cancelPopupShowHide);
  }

  /**
  * Add selected email to excluded emails
  * @param e :selected email data
  */
  selectedEmail(e: Record<string, any>) {
    if(this.addedEmailsList.length >= 30){
      this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.MEDICATION_MAIL_LIMIT);
      this.result = {};
    }
    else if (e) {
      if (!this.addedEmailsList.some( ele => ele.id == e.id)) {
        this.addedEmailsList.unshift(e);
      } else {
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.EMAIL_ALREADY_EXCLUDED_ERROR_MSG);
      }
      this.result = {};
    }
  }

  /**
   * Form cancel action
   * @param event click event
   */
  cancel(): void {
    this.router.navigate([`${ROUTE_PATHS.EMPTY}`]);
  }

  /**
  * Form Submit event
  * @param event Click event
  */
  actionField(event?: Record<string, any>) {
    this.formSubmitAttempt = true;
    setTimeout(() => {
      if (document.getElementsByClassName('error-msg')[0]) {
        document.getElementsByClassName('error-msg')[0].scrollIntoView({ block: 'center' });
      }
    }, 2);
    if (this.addedEmailsList.length > 0) {
      this.sendMedicationMail();
    }
  }

  /**
   * send medication mail to selected patients
   */
  sendMedicationMail() {
    var patientIdList = [];
    this.addedEmailsList.forEach(ele => patientIdList.push(ele.id));
    let payload: IPostMethodPayload = {
      type: HTTPMethods.POST,
      url: API_URLS.PATIENT_MAILS,
      isDeveloper: true,
      body: {
        patientId: null,
        emailType: this.mailType,
        appointmentId: null,
        patientIdList: patientIdList
      }
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        let message = this.mailType === 'MULTIPLE_MEDICATION_MAIL' ? TOAST_MESSAGES.MEDS_NOTIFICATION_SUCCESS : TOAST_MESSAGES.TMS_NOTIFICATION_SUCCESS;
        this.observableHelperService.showToast(TOAST_STATUSES.SUCCESS, message);
        this.addedEmailsList = [];
        this.formSubmitAttempt = false;
      } else {
        let message = this.mailType === 'MULTIPLE_MEDICATION_MAIL' ? TOAST_MESSAGES.MEDS_NOTIFICATION_FAIL : TOAST_MESSAGES.TMS_NOTIFICATION_FAIL;
        message = (res.error) ? res.error.message : message;
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
}
