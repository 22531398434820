<section class="appointment__section" *ngIf="availabiltyData && availabiltyData.showScreen">
    <div class="welcome--message hello" *ngIf="userLogedIn && userName && !isReshedule">Hello {{userName}}! Let’s book
        an appointment for
        you,</div>
    <div class="welcome--message reschedule" *ngIf="userLogedIn && userName && isReshedule">Reschedule Appointment</div>
    <div class="message" *ngIf="userLogedIn && userName && isReshedule">*Note:{{message}}</div>
    <!-- <marquee direction = "right" class="marque_message" *ngIf="showScroll">We provide "Schedule A" letter and "Work from Home" documentation.</marquee> -->
    <!-- <br/> -->
    <marquee direction = "right" class="marque_message" *ngIf="showScroll"> We provide "Disability," "FMLA," and "ESA" paperwork along with "Schedule A" letters and "Work from Home" documentation.</marquee>
    <br/>
    <div class="message_wrapper"><span class="message" id="message">*{{noteMessage}}</span></div>

    <div class="message_wrapper_m">
        <div class="message-m">*{{noteMessage}}</div>
        <div class="message-text-m" id="message-m">*{{noteMessage}}</div>
    </div>
    <div class="office__details" *ngIf="!isReshedule && availabiltyData && availabiltyData.showScreen && userLogedIn">
        <app-custom-form [formData]="appointmentForForm" (addNewPatient)="navigateAddNewPatient($event)"
            [checkFormInfo]="saveFormData" (submitEvent)="getPatientData($event)" (selectedPatientID)="selectedPatientID($event)" ></app-custom-form>
    </div>
    <div class="office__details">
        <!-- <div class="blinkmessage" *ngIf="location.locationId==='TEL'"><span class="blink--txt">*Note: {{blinkMsg}}</span></div> -->
        <app-custom-form [formData]="typeAppointmentForm" [checkFormInfo]="saveFormData"
            (submitEvent)="getOfficeForm($event)" (clickedRadio)="clickedRadio($event)" (getDropValueList)="getDropValueList($event)"></app-custom-form>

    </div>
    <!-- <div *ngIf="this.requestDocumentDelay">
         <div class="message_wrapper-text"><span class="message expand" id="message">*{{notemessageDocs}}</span></div>
    </div>  -->
    <div>
        <app-appointment-booking-schedular [isReshedule]='isReshedule' [availabiltyData]="availabiltyData" (selectedCell)="getSheduleData($event)"
            (submitEvent)="getFinalSheduleData($event)" [location]="location" [typeofOffice]="typeofOffice"
            [time]='time' [timeRange]="timeRange" [checkFormInfo]="saveFormData" *ngIf="this.timeSlot == true"></app-appointment-booking-schedular>
    </div>
    <div class="login__notify__block" *ngIf="!userLogedIn">
        <div class="login--message">To continue your booking appointment Please login</div>
        <div class="login--btn common-btn-style" (click)="getLoginPopup($event)">Login</div>
        <div class="cancel-btn isMobile" (click)="cancel()">Cancel</div>
    </div>
    <div *ngIf="availabiltyData && availabiltyData.showScreen && userLogedIn">
        <div class="reschedule__details" *ngIf="isReshedule">
            <app-custom-form [formData]="rescheduleForm" [checkFormInfo]="saveFormData"
                (submitEvent)="getRescheduleData($event)"></app-custom-form>
        </div>
        <div class="report--block" *ngIf="isDetails && isAnnouncementbanner">
            <div class="infographic--icon">
                <img src="assets/images/svgs/infographic.svg" alt="">
            </div>
            <div class="details--block">
                <div class="details--header" *ngIf="!isReshedule">Your Appointment Details</div>
                <div class="details--header" *ngIf="isReshedule">Your Appointment Reschedule Details</div>
                <div class="details--section">
                    <div class="each--detail" *ngIf="isReshedule">
                        <div class="svg--icon">
                            <app-svg [name]="'user'"></app-svg>
                        </div>
                        <div class="icon--label">
                            <span> {{patientName}}</span>
                        </div>
                    </div>
                    <div class="each--detail" *ngFor="let detail of detailsData;let i=index">
                        <div class="svg--icon" *ngIf="detail.lable != 'Telemedicine'">
                            <app-svg [name]="detail.icon"></app-svg>
                        </div>
                        <div class="svg--icon" *ngIf="i==0 && detail.lable == 'Telemedicine'">
                            <app-svg [name]="'phone'"></app-svg>
                        </div>
                        <div class="icon--label">
                            <span *ngIf="detail.icon != 'calendar-color' && detail.icon != 'clock-color'">
                                {{detail.lable}}</span> <span *ngIf="detail.icon == 'calendar-color'">
                                {{detail.lable | customDate}}</span>
                            <span *ngIf="detail.icon == 'clock-color'">{{detail.lable |customTime}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="announcement-banner-container" [ngClass]="{'announcement-banner-resolution': locationId == null}">
            <img class="background-leaf-icon" alt="" src="../../../../assets/images/pngs/leaf-banner.png" />
            <div class="announcement-banner-content">
                <div class="announcement-banner-header">
                <img class="background-announcement-icon" alt="" src="../../../../assets/images/pngs/anouncement-sound.png" />
                <div class="announcement-banner-header-content"> Announcements</div>
                </div>
                <div class="announcement-banner-body" *ngIf="this.announcementBannerVal" [innerHtml]="this.announcementBannerVal"></div>
            </div>
        </div> 
    </div>
</section>

<app-common-popup (showHideCancelPopup)='doubleCheackPopupclose($event)' *ngIf="isDoubleCheck">
    <div class="popup-content-block">
        <div class="details--block">
            <div class="details--header" *ngIf="!isReshedule">Your Appointment Details</div>
            <div class="details--header" *ngIf="isReshedule">Your Appointment Reschedule Details</div>
            <div class="details--section">
                <div class="each--detail" *ngIf="isReshedule">
                    <div class="svg--icon">
                        <app-svg [name]="'user'"></app-svg>
                    </div>
                    <div class="icon--label">
                        <span> {{patientName}}</span>
                    </div>
                </div>
                <div class="each--detail">
                    <div class="svg--icon">
                        <app-svg [name]="'treatement-orange'"></app-svg>
                    </div>
                    <div class="icon--label">
                        <span> {{getTreatment()}} </span>
                    </div>
                </div>
                <div class="each--detail" *ngFor="let detail of detailsData;let i=index">
                    <div class="svg--icon" *ngIf="detail.lable != 'Telemedicine'">
                        <app-svg [name]="detail.icon"></app-svg>
                    </div>
                    <div class="svg--icon online_icon" *ngIf="i==0 && detail.lable == 'Telemedicine'">
                        <app-svg [name]="'online-video_icon'" class="icon_color"></app-svg>
                    </div>
                    <div class="icon--label">
                        <span *ngIf="detail.icon != 'calendar-color' && detail.icon != 'clock-color'">
                            {{detail.lable}}</span> <span *ngIf="detail.icon == 'calendar-color'">
                            {{detail.lable | customDate}}</span>
                        <span *ngIf="detail.icon == 'clock-color'">{{detail.lable |customTime}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="details--header-content book_now-overlay-text-content" *ngIf="this.locationType == 'offline'"><div class="content"><div class="svg--icon" > <img alt="" class="noteicon-img" src="../../../../assets/images/svgs/inform.svg" /></div><div>This is an In-person appointment. You are required to visit the clinic.</div></div></div>
        <div class="details--header-content book_now-overlay-text-content" *ngIf="this.locationType == 'online'"><div class="content"> <img alt="" class="noteicon-img" src="../../../../assets/images/svgs/inform.svg" />This is an online appointment. An appointment link will be emailed to you.</div></div>
        <div class="details--header">Do you want to proceed?</div>
        <div class="details--header book_now-overlay-text">Patient has to be a resident of VA, DC, MD or NY only</div>
        <div class="btn-actions">
            <div class="cancel-btn" (click)="doubleCheackPopupclose($event)">Cancel</div>
            <div class="common-btn-style" (click)="proceedNext();isDoubleCheck = false">Proceed</div>
        </div>
    </div>
</app-common-popup>