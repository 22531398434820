<!-- services card block start -->
<div class="services__card-block {{serviceCard.classData}}">
    <div class="icon__block medium m-d-hide" *ngIf="serviceCard.showBackgroundImg">
        <img class="background-leaf3" src = "assets/images/pngs/background-leaf-3.png" alt="leaf icon"/>
    </div>
    <h3 class="services__heading m-d-show">
        {{serviceCard.heading}}
    </h3>
    <!-- services image block start -->
    <div class="services__image-card-block">
        <div class="services__image-block">
            <img src="assets/images/{{serviceCard.imgPath}}" alt="{{serviceCard.cardLabel}}">
        </div>
    </div>
    <!-- services image block end -->
    <div class="services__description-block">
        <h3 class="services__heading m-d-hide">
            {{serviceCard.heading}}
        </h3>
        <p class="services__description" [ngClass]="showFullDesciption ? 'more' : 'less' ">
            {{serviceCard.description}} 
        </p>
        <app-know-more [moreText]="showFullDesciption ? lessText : moreText" [show]="true" class="m-d-show" [actionBased]="true" (actionTriggered)="readMore($event)"></app-know-more>
    </div>
</div>
<!-- services card block end -->