import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';

@Component({
  selector: 'app-aboutus-wrapper',
  templateUrl: './aboutus-wrapper.component.html',
  styleUrls: ['./aboutus-wrapper.component.scss']
})
export class AboutusWrapperComponent implements OnInit {

  bannerData = {
    bannerClass: 'aboutus__banner-section',
    orangeHeading: 'We are',
    blueHeading1: 'a new age ',
    blueHeading2: 'boutique ',
    blueHeading3: 'clinic',
    // bannerDescription: 'Our mission is to provide comprehensive, culturally competent, relationships based mental health services through a holistic approach for young children, adolescents and adults in a caring environment. Services are rendered by a group of well trained, dedicated and compassionate professionals through the provision of individual assessments, preventive measures, early identification , and research-based and proven treatment strategies.',
    bannerDescription: 'Our mission is to provide comprehensive, culturally competent, relationships based mental health services through a holistic approach for young children, adolescents and adults in a caring environment. Services are rendered by a group of well trained and compassionate professionals. Our staff assist in the rehabilitation of our patients’ psychological and physiological well-being through the provision of individual assessments, preventive measures, early identification, Medication Management and research-based treatment strategies. Dr. Prayaga is proud to be a Medicare and Medicaid Provider with Most insurances Accepted',
    bannerImg: 'jpgs/aboutus-banner.jpg'
  };

  constructor(private observable: ObservableHelperService, private meta: Meta,private titleService: Title,private router: Router) {

  }

  ngOnInit() {
    const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.setAttribute('href', 'https://newteledoc.com/aboutus');
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          canonicalLink.setAttribute('href', 'https://newteledoc.com/aboutus');
        }
      });
    }
    this.meta.addTag({ name: 'description', content: "A glimpse into Dr. Prayaga’s educational and professional background as well as insight into the history of the clinic. Patients will learn Dr. Prayaga is a Certified Washington D.C. Psychiatrist, Child Psychiatrist, Weight Management Doctor, Virginia Medical Marijuana Doctor, and Medicare and Medicaid Provider."});
    this.meta.updateTag({ name: 'title', content: 'About our Mental Health Facility- New TeleDoc Washington DC Psychiatrist' });
    this.titleService.setTitle('About our Mental Health Facility- New TeleDoc Washington DC Psychiatrist');
    this.observable.setCurrentPage('aboutus');
  }
}
