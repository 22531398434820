<section class="appointment-detail-card__section upcoming">
    <div class="card" [ngClass]="appointmentDetails.appointmentStatus == 'D' || appointmentDetails.appointmentStatus == 'R' ? 'past-detail-card' : '' ">
        <div class="date-info card-svg">
            <app-svg [name]="'calendar'"></app-svg>
            <div class="label">{{appointmentDetails.appointmentDate | dateFormat}} | {{appointmentDetails.appointmentTime | customTime}}</div>
        </div>
        <div class="user-info card-svg">
            <app-svg [name]="'user'"></app-svg>
            <div class="label">{{appointmentDetails.patient.firstName}} {{appointmentDetails.patient.lastName}}</div>
        </div>

        <div class="office-info card-svg">
            <app-svg *ngIf="appointmentDetails.location.locationName == 'Telemedicine'" [name]="'phone'"></app-svg>
            <app-svg *ngIf="appointmentDetails.location.locationName != 'Telemedicine'" [name]="'home'"></app-svg>
            <div class="label">{{appointmentDetails.location.locationName}}</div>
        </div>
        <div class="appointment--svg">
            <app-svg [name]="'treatement'"></app-svg>
            <div class="label">{{appointmentDetails.appointmentType.appointmentTypeName}}</div>
        </div>
        <div class="cancel-info card-svg" *ngIf="appointmentDetails.appointmentStatus == 'D'">
            <app-svg [name]="'cancel-appointment'"></app-svg>
            <div class="label" >Cancelled</div>
        </div>
        <div class="card-event" *ngIf="appointmentDetails.appointmentStatus == 'P'">
            <div class="reschedule-info confirm-info card-svg"
                (click)="bookingConfirm(appointmentDetails)">
                <app-svg [name]="'addAppointment'"></app-svg>
                <div class="label">Confirm</div>
            </div>

            <div class="cancel-appointment-info card-svg" (click)='cancelPopupShowHideFun(appointmentDetails)'>
                <app-svg [name]="'cancel-appointment'"></app-svg>
                <div class="label">Cancel</div>
            </div>
        </div>
        <div class="card-event" *ngIf="appointmentDetails.appointmentStatus == 'A'">
            <div class="reschedule-info card-svg" [ngClass]="isReshedule?'disable':''"
                (click)="resheduleData(appointmentDetails)">
                <app-svg [name]="'reschedule'"></app-svg>
                <div class="label">Reschedule</div>
            </div>

            <div class="cancel-appointment-info card-svg" (click)='cancelPopupShowHideFun(appointmentDetails)'>
                <app-svg [name]="'cancel-appointment'"></app-svg>
                <div class="label">Cancel</div>
            </div>
        </div>
    </div>
</section>

<app-cancel-booking [cancelData]="appointmentDetails" (showHideCancelPopup)='cancelPopupShowHideStatusFun($event)'
    *ngIf="cancelPopupShowHide"></app-cancel-booking>
<app-common-popup (showHideCancelPopup)='showPopupFun($event)' *ngIf="showPopup">
    <div class="popup-content-block" *ngIf="popUpAction == 'modify'">
        <div class="confirm-message">Update patient intake form to confirm this appointment. These updates<br/> are mandatory every 2 years after patient onboarding.</div>
        <div class="btn-actions">
            <div class="common-btn-style yes-btn" (click)="popUpClick(appointmentDetails.patient.patientId,false)">Cancel</div>
            <div class="common-btn-style" (click)="popUpClick(appointmentDetails.patient.patientId,true)">Modify</div>
        </div>
    </div>
    <div class="popup-content-block" *ngIf="popUpAction == 'update'">
        <div class="confirm-message">Update patient intake form to confirm this appointment. These updates are requested because there are no appointments booked in the past 8 months.</div>
        <div class="btn-actions">
            <div class="common-btn-style yes-btn" (click)="popUpClick(appointmentDetails.patient.patientId,false)">Cancel</div>
            <div class="common-btn-style" (click)="popUpClick(appointmentDetails.patient.patientId,true)">Modify</div>
        </div>
    </div>
</app-common-popup>