// This component is for the Ketamine monitoring form in the appointment booking flow.


import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { DataService } from '@app/common/services/data.service';
import { Router } from '@angular/router';
import { IFormEmitDataType } from '@app/common/constants/typeInterfaces.constants';

@Component({
  selector: 'app-ketamine-monitoring',
  templateUrl: './ketamine-monitoring.component.html',
  styleUrls: ['./ketamine-monitoring.component.scss']
})
export class KetamineMonitoringComponent implements OnInit {

  @Input() saveFormData: boolean;
  @Output() submitEvent: EventEmitter<IFormEmitDataType> = new EventEmitter();
  formData: Array<Record<string, unknown>>;

  constructor(
    private formService: CustomFormHandlerService, 
    private dataService: DataService, 
    private router: Router) 
    { }

  ngOnInit() {
    this.formData = this.formService.initForm(this.formService.formTypes.KETAMINEMONITORING);
  }

  /**
   * Form Submit event.
   * @param event form data
   */
  getFormData(event) {
    this.formService.KETAMINEMonitoringFormConfig = this.assignFormValues(event, this.formService.KETAMINEMonitoringFormConfig);
    this.dataService.setData(this.dataService.dataObject.KETAMINEMONITORING, this.formService.KETAMINEMonitoringFormConfig);
    this.submitEvent.emit({status: event,  step: 8});
  }

  /**
   * To set the previous values to the form.
   * @param formData formData
   * @param formConfig FormConfig
   */
  assignFormValues(formData, formConfig) {
    let keys = Object.keys(formData.value);
    formConfig.forEach((each, index) => {
      if (keys.includes(each.key)) {
        each.value = formData.value[each.key];
      }
    });
    return formConfig;
  }
}
