import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TOAST_STATUSES } from '@app/common/constants/util.constant';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';

@Component({
  selector: 'app-dashboard-block-slots-details',
  templateUrl: './dashboard-block-slots-details.component.html',
  styleUrls: ['./dashboard-block-slots-details.component.scss']
})
export class DashboardBlockSlotsDetailsComponent implements OnInit {
  actions = [{
    actionId: 1,
    name: 'edit',
    label: 'Edit'
  }, {
    actionId: 2,
    name: 'delete',
    label: 'Delete'
  }];
  @Output() actionsEmit = new EventEmitter<any>();
  @Input() blokedSlotInputData: any;

  constructor(private observable: ObservableHelperService) { }
  ngOnInit() {
    console.log(this.blokedSlotInputData);
  }
  actionsClicked(actionId, blockSlotData) {
      this.actionsEmit.emit({ actionId, blockSlotData });
    }
}
