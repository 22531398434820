import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { TOAST_STATUSES } from '@app/common/constants/util.constant';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';

@Component({
  selector: 'app-invoice-list-data',
  templateUrl: './invoice-list-data.component.html',
  styleUrls: ['../appointments-list/appointments-list.component.scss']

})
export class InvoiceListDataComponent implements OnInit {

  @Input() adminPermissions;
  // invoiceList data @input varible and initial data
  @Input() invoiceList: any = {
    patientName: '',
    billAmount: '',
  };
  @Output() invoiceListTrigger = new EventEmitter<any>();


  constructor(private router: Router, private observable: ObservableHelperService) { }

  ngOnInit() {
  }

  // EMployee Details redirection
  invoiceDetailsClick(invoice) {
    if (invoice.invoice_id) {
      this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.INVOICES}/${invoice.invoice_type}/${invoice.invoice_id}`]);
    } else {
      this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.INVOICES}/${ROUTE_PATHS.NOT_GENERATED}/appt_invoice/${invoice.appointmentId}`]);
    }
  }
  invoiceListEvent(data, event) {
    event.stopPropagation();
    this.invoiceListTrigger.emit(data);
  }
}
