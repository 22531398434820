<section class="popup-content-block">
    <div class="generate__invoice--header">Mark invoice as paid</div>
    <div class="amount__block">
        <form class="form__group" [formGroup]="makeAsPaidForm">
            <label for="paymentMode">
                <div class="input__lable">
                    Payment mode
                </div>
                <div class="input__value">
                    <select id="paymentMode" formControlName="paymentMode">
                        <option value="{{mode.value}}" *ngFor="let mode of paymentModes">{{mode.label}}</option>
                      </select>
                </div>
                <div class="invalid-msg" *ngIf="formSubmitAttempt && !makeAsPaidForm.controls.paymentMode.valid">Please select atleast one option</div>
            </label>
            <label>
                <div class="input__lable">
                    Reference ID
                </div>
                <div class="input__value">
                    <input  type="text" formControlName="referenceId">
                </div>
                <div class="invalid-msg" *ngIf="formSubmitAttempt && !makeAsPaidForm.controls.referenceId.valid">Please enter reference id</div>
            </label>
        </form>
    </div>
    <div class="btn-actions">
        <div class="cancel-btn" (click)="cancelPopUp($event)">Cancel</div>
        <div class="common-btn-style" (click)="actionField($event)">Submit</div>
    </div>
</section>