import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-location-details',
  templateUrl: './location-details.component.html',
  styleUrls: ['./location-details.component.scss']
})
export class LocationDetailsComponent implements OnInit {
  isShowScreen = false;
  locationDetailsData: any = {services: ''};
  locationWeekTimes: any;
  locationWeekendTimes: any;
  weekDays: any = [];
  weekendDays: any = [];
  @Input() locationDetails;
  @Input() set  locationIdData(value: any) {
    if (value) {
      value.services.forEach(obj => {
        // tslint:disable-next-line:no-shadowed-variable
        obj.timings.forEach(obj => {
          if (!this.weekDays.includes(obj.weekDay)) {
              this.weekDays.push(obj.weekDay);
          }
        });
      });
      let weekendAr = [0, 6];
      weekendAr.forEach(num => {
        // tslint:disable-next-line:no-unused-expression
        this.weekDays.includes(num) ? (this.weekendDays.push(num), this.weekDays.splice(this.weekDays.indexOf(num), 1)) : null;
      });
      this.weekDays = this.weekDays.map(i => i - 1).sort((a, b) => a - b);
      this.weekendDays = this.weekendDays.map(i => i === 0 ? 6 : 5).sort((a, b) => a - b);
      this.weekDays = [this.getDaysInString([...this.weekDays])];
      this.weekendDays = [this.getDaysInString([...this.weekendDays])];
      this.locationDetailsData = this.massageServices(value.services);
      this.locationWeekTimes = value.weekTimes;
      this.locationWeekendTimes = value.weekEndTimes;
      setTimeout(() => {this.isShowScreen = true; }, 1000);
    }
  }
  constructor() { }

  ngOnInit() {
    console.log(this.locationDetailsData);
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }


  // This function will add the svg according to appointment type
  massageServices(services) {
    if (!services && !Array.isArray(services)) { return []; }
    let arr = services;
    // mapping for sorting timing from least begin time and also for sorting days in that timing
    arr = arr.map(obj => {
      // tslint:disable-next-line:no-shadowed-variable
      let sortArr = obj.timings.map(obj => ({...obj, weekDay: obj.weekDay === 0 ? 6 : obj.weekDay - 1, isTaken: false})).sort((a, b) => a.beginTime.split(':')[0] - b.beginTime.split(':')[0]).sort((a, b) => { if (a.beginTime.split(':')[0] === b.beginTime.split(':')[0]) {
            return a.weekDay - b.weekDay;
        } }).sort((a, b) => { if (a.weekDay < b.weekDay) {
                return a.weekDay - b.weekDay;
              } });
      return {...obj, timings: sortArr};
      });
    // mapping to return the timing as 'weekdays (day-day)' in it
    // tslint:disable-next-line:one-variable-per-declaration
    let tempAr = [], finalArr = [];
    arr = arr.map(obj => {
      finalArr = [];
      let ar = obj.timings;
      ar.forEach((obA, i) => {
        if (!obA.isTaken) {
            tempAr.push(obA.weekDay);
            // tslint:disable-next-line:no-unused-expression
            ar.length > 1 && ar.forEach((obB, j) => {
                if (i < j && !obB.isTaken) {
                    if (obA.beginTime === obB.beginTime && obA.endTime === obB.endTime) {
                        obB.isTaken = true;
                        tempAr.push(obB.weekDay);
                    }
                }
            });
            let lastVal = tempAr[(tempAr.length - 1)];
            // tslint:disable-next-line:one-variable-per-declaration
            let newAr = [], same = [];
            // tslint:disable-next-line:no-shadowed-variable
            for (let i = 0; i <= lastVal; i++) {newAr.push(i); }
            newAr.forEach(eachVal => {
                if (tempAr.includes(eachVal)) {
                    same.push(eachVal);
                    if (eachVal === lastVal) {
                      finalArr.push({fromTime: obA.beginTime, toTime: obA.endTime, days: [...same], daysInString: this.getDaysInString([...same])});
                    }
                } else {
                    // tslint:disable-next-line:no-unused-expression
                    same.length > 0 ? finalArr.push({fromTime: obA.beginTime, toTime: obA.endTime, days: [...same], daysInString: this.getDaysInString([...same])}) : null;
                    same = [];
                }
            });
            tempAr = [], lastVal = '', newAr = [];
            obA.isTaken = true;
          }
      });
      return {...obj, timings: finalArr, svg: this.addSvg(obj)};
    });
    return arr;
  }

  // function for displaying days arr as day string
  getDaysInString(days) {
    const daysString = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    if (days.length && days.length > 1) {
      return `${daysString[days[0]]} - ${daysString[days[days.length - 1]]}`;
    } else {
      return daysString[days[0]];
    }
  }

  // function for adding svg
  addSvg(service) {
    if (service.appointmentTypeId === 1) {
      return 'medication-management-green';
    } else if (service.appointmentTypeId === 2) {
      return 'telemedicine-green';
    } else if (service.appointmentTypeId === 3) {
      return 'ketamine-treatment-green';
    } else if (service.appointmentTypeId === 4) {
      return 'adminTms-green';
    } else {
      return 'treatment-green';
    }
  }
}
