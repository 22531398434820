<div class="outer" *ngIf="documentDetails">
    <app-breadcrumb [breadCrumbData]="breadCrumbData"></app-breadcrumb>
    <!-- <div class="title">Document Request Details</div> -->
    <!-- status will be passed by the parent- doc-req -->
    <div class="wrapper-block">
        <div class="small" class="Pending" *ngIf="documentDetails.adminStatus !== 'Completed'">{{documentDetails.adminStatus}}</div>
        <div class="small" class='Completed' *ngIf="documentDetails.adminStatus == 'Completed'">{{documentDetails.adminStatus}}</div>

        <div class="hori">
            <div class="verti">
                <div class="pat">Patient Name</div>
                <div class="value">{{documentDetails.firstName | titlecase}} {{documentDetails.lastName |
                    titlecase}}</div>
            </div>
            <div class="verti">
                <div class="pat">Patient Id</div>
                <div class="value">{{documentDetails.patientId}}</div>
            </div>
            <div class="verti">
                <div class="pat">Requested Documents</div>
                <div class="value1">
                    <span *ngFor="let document of documentDetails.documents;let i=index">{{document.documentName}}<span *ngIf="i != documentDetails.documents.length-1">{{","}}&nbsp;</span>
                    </span></div>
            </div>
            <br>
        </div>
        <br>
        <br>
        <div class="hori">
            <div class="verti">
                <div class="pat" *ngIf="documentDetails.empFirstName">Employee Assigned</div>
                <div class="value">{{documentDetails.empFirstName | titlecase}}
                    {{documentDetails.empLastName | titlecase}}</div>
            </div>
            <div class="verti">
                <div class="pat">Other Comments</div>
                <div class="value">{{documentDetails.otherComments}}</div>
            </div>
            <div class="verti">
                <div class="pat">Patient Comments</div>
                <div class="value">{{documentDetails.patientComments}}</div>
            </div>
            <br>
        </div>
        <br>
        <br>
        <div class="hori">
        
            <div class="verti">
                <div class="pat">Created Date</div>
                <div class="value">{{documentDetails.createTime | dateFormat}}</div>
            </div>
            <div class="verti">
                <div class="pat">{{documentDetails.statusLabel}}</div>
                <div class="value">{{documentDetails.closureTime | dateFormat}}</div>
            </div>
            <br>
        </div>
        <br>
        <br>
        <!-- <div class="hori">
            <div class="verti">
                <div class="pat">Other Details</div>
                <div class="value1" *ngIf="documentDetails.otherComment">{{documentDetails.otherComment}}</div>
                <div class="value1" *ngIf="!documentDetails.otherComment">---</div>
            </div>
            <br>
        </div>
        <br>
        <br> -->
        <div class="hori" *ngIf="(documentDetails.adminStatus === 'Document Under Process'||documentDetails.status ==='Payment Pending' )&& !this.empId && documentDetails.access === true">
            <div class="verti">
                <div class="pat assign">Assign Employee</div>
                <div><div class="text_class" *ngIf="isAssign">Required</div></div>
                <app-custom-form [formData]="appointmentForForm" (getDropValueList)="getDropValueList($event)"></app-custom-form>
            </div>
        </div>
        <div class="hori documents_download-wrapper" *ngIf="documentDetails.documentURLs && documentDetails.documentURLs.length != 0">
            <div class="verti">
                <div class="pat">Patient Documents <span class="download" *ngIf="documentDetails.documentURLs.length != 0" (click)="downloadAll(documentDetails.documentURLs)">Download All</span></div>
                <div class="value" *ngIf="documentDetails.documentURLs.length == 0">No documents available</div>
                <div class="value documents_wrapper">
                    <span class="document-block each_document" *ngFor="let document of documentDetails.documentURLs">
                        <div class="each_document-name">
                            <p class="">{{document.fileName | ellipsis: 25}}</p>
                        </div>
                        <div class="each_document-actions">
                            <a [href]="document.url" target="_blank" title="View" class="view-icon">
                                <app-svg [name]="'view-upload'"></app-svg>
                            </a>
                            <p class="down-arrow" (click)="downloadImage(document.url)"
                                title="Download">
                                <app-svg [name]="'down-arrow'"></app-svg>
                            </p>
                        </div>
                    </span>
                </div>
            </div>
        </div>
        <div *ngIf="documentDetails.documentURLs && documentDetails.documentURLs.length != 0">
            <hr>
            <br>
        </div>
        <div class="document_download-wrapper" *ngIf="documentDetails.adminDocumentURLs && documentDetails.adminDocumentURLs.length != 0 && documentDetails.status != 'Completed'">
            <div class="verti">
                <div class="pat">Clinic Documents <span class="download" *ngIf="documentDetails.adminDocumentURLs.length != 0" (click)="downloadAll(documentDetails.adminDocumentURLs)">Download All</span></div>
                <div class="value" *ngIf="documentDetails.adminDocumentURLs.length == 0">No documents available</div>
                <div class="value documents_wrapper">
                    <span class="document-block each_document" *ngFor="let document of documentDetails.adminDocumentURLs">
                        <div class="each_document-name">
                            <p class="">{{document.fileName | ellipsis: 25}}</p>
                        </div>
                        <div class="each_document-actions">
                            <a [href]="document.url" target="_blank" title="View" class="view-icon">
                                <app-svg [name]="'view-upload'"></app-svg>
                            </a>
                            <p class="down-arrow" (click)="downloadImage(document.url)"
                                title="Download">
                                <app-svg [name]="'down-arrow'"></app-svg>
                            </p>
                        </div>
                    </span>
                </div>
            </div>
        </div>
        <div class="document_download-wrapper" *ngIf="documentDetails.signedAdminDocumentURLS && documentDetails.signedAdminDocumentURLS.length != 0 && documentDetails.status ==='Completed'">
            <div class="verti">
                <div class="pat">Clinic Documents <span class="download" *ngIf="documentDetails.signedAdminDocumentURLS.length != 0" (click)="downloadAll(documentDetails.signedAdminDocumentURLS)">Download All</span></div>
                <div class="value" *ngIf="documentDetails.signedAdminDocumentURLS.length == 0">No documents available</div>
                <div class="value documents_wrapper">
                    <span class="document-block each_document" *ngFor="let document of documentDetails.signedAdminDocumentURLS">
                        <div class="each_document-name">
                            <p class="">{{document.fileName | ellipsis: 25}}</p>
                        </div>
                        <div class="each_document-actions">
                            <a [href]="document.url" target="_blank" title="View" class="view-icon">
                                <app-svg [name]="'view-upload'"></app-svg>
                            </a>
                            <p class="down-arrow" (click)="downloadImage(document.url)"
                                title="Download">
                                <app-svg [name]="'down-arrow'"></app-svg>
                            </p>
                        </div>
                    </span>
                </div>
            </div>
        </div>
        <div class="document_download-wrapper" *ngIf="documentDetails.adminDocumentURLs && documentDetails.rejectedAdminDocumentURLS.length != 0 && documentDetails.status !=='Completed' && documentDetails.adminDocumentURLs.length === 0">
            <div class="verti">
                <div class="pat">Clinic Documents <span class="download" *ngIf="documentDetails.rejectedAdminDocumentURLS.length != 0" (click)="downloadAll(documentDetails.rejectedAdminDocumentURLS)">Download All</span></div>
                <div class="value" *ngIf="documentDetails.rejectedAdminDocumentURLS.length == 0">No documents available</div>
                <div class="value documents_wrapper">
                    <span class="document-block each_document" *ngFor="let document of documentDetails.rejectedAdminDocumentURLS">
                        <div class="each_document-name">
                            <p class="">{{document.fileName | ellipsis: 25}}</p>
                        </div>
                        <div class="each_document-actions">
                            <a [href]="document.url" target="_blank" title="View" class="view-icon">
                                <app-svg [name]="'view-upload'"></app-svg>
                            </a>
                            <p class="down-arrow" (click)="downloadImage(document.url)"
                                title="Download">
                                <app-svg [name]="'down-arrow'"></app-svg>
                            </p>
                        </div>
                    </span>
                </div>
            </div>
        </div>
        <div class="hori documents_download-wrapper" *ngIf="documentDetails.status == 'Patient Review' || documentDetails.status == 'Payment Completed' || documentDetails.status == 'Under Process' && this.empId">
            <div class="verti">
                <div class="documents__wrapper" id="reports">
                    <div class="appointments__id-proofs">
                   
                        <div class="appointments__id-proofs-title side__lable ">
                            Upload Documents
                        </div>
                        <div><div class="text_class" *ngIf="isDocs">Required</div></div>
                        <div class="appointments__id-proofs-documents">
                            <div class="appointments__id-document internal-uploads"
                                *ngFor="let uploadsFiles of detailPageUpload; let i=index">
                                <div class="upload-document__block">
                                    <p>{{uploadsFiles.file.name | ellipsis: 23}}</p>
                                    <p>.{{uploadsFiles.file.extenstion}}</p>
                                </div>
                                <div class="upload-close-btn">
                                    <app-svg [name]="'cancel-appointment'"
                                        (click)="deleteFileUpload(uploadsFiles)"></app-svg>
                                </div>
                                <div class="appointments__id-document-actions">
                                    <a [href]="uploadsFiles.docUrl" target="_blank" title="View" class="view-icon">
                                        <app-svg [name]="'view-upload'"></app-svg>
                                    </a>
                                    <p class="down-arrow" (click)="donwloadFile(uploadsFiles.docUrl,uploadsFiles,false)"
                                        title="Download">
                                        <app-svg [name]="'down-arrow'"></app-svg>
                                    </p>
                                </div>
                            </div>
                            <div class="appointments__id-document" >
                                <app-multiple-uploads [fileTypes]="uploadFilesData"
                                    (uploads)="uploadFunctionCall($event)"></app-multiple-uploads>
                            </div>
                        </div>
                    </div>
                    <!-- patient permissions -->
                </div>
            </div>
        </div>
        <div *ngIf="!this.empId && documentDetails.status != 'Rejected' && documentDetails.adminStatus ==='Review Pending' && ( documentDetails.patientComments == null || documentDetails.patientComments == '')">
            <div class="sts">Comments</div>
            <div class="text-area-block">
                <textarea  
                class="text-area input" type="textarea" [attr.maxLength]="350"
                value="" placeholder="Enter details here"
                (input)="textCounter($event)"
                [(ngModel)]="otherValue"></textarea>
                <input disabled
                    class="textarea-range-show" maxlength="3" size="3"
                    value="{{remainingValue}} remaining" id="remaining">
            </div>
        </div>
        <div class="hori" *ngIf="(documentDetails.adminStatus === 'Document Under Process'||documentDetails.status ==='Payment Pending' )&& !this.empId && documentDetails.access === false">
            <div class="text_class">Employee assigning access denied due to lack of permissions</div>
        </div>
        <div class="hori hori-test">
            <div class="actions__block actions__block_btn">
                <div class="each__action" (click)="appointmentsStatusActions()" *ngIf="documentDetails.status === 'Pending For Review' && documentDetails.adminStatus === 'Review Pending'">
                    <app-svg class="generate-invoice-color" name="generate-invoice-color"></app-svg>
                    <div class="actions__lable">Generate Invoice</div>
                   
                </div>
                <!-- <div class="each__action" (click)="resendInvoice()" *ngIf="documentDetails.status == 'Payment Pending'">
                    <app-svg class="generate-invoice-color" name="generate-invoice-color"></app-svg>
                    <div class="actions__lable">Resend Invoice</div>
                </div> -->
                <div class="each__action" (click)="rejectChanges()" *ngIf="documentDetails.status === 'Pending For Review' && documentDetails.adminStatus === 'Review Pending'">
                    <app-svg class="block-patient-color" name="block-patient-color"></app-svg>
                    <div class="actions__lable" (click)="rejectChanges()">Reject Request</div>
                </div>   
                <div><button class="common-btn-style" (click)="conform()" *ngIf="!this.empId && documentDetails.status != 'Rejected' && documentDetails.adminStatus ==='Review Pending' && ( documentDetails.patientComments == null || documentDetails.patientComments == '')">Send Comments</button></div>
                <div><button class="common-btn-style" (click)="uploadDocs()" *ngIf="(documentDetails.status == 'Patient Review' || documentDetails.status == 'Payment Completed' || documentDetails.status == 'Under Process' )&& this.empId">Upload Document</button></div>
                <div><button class="common-btn-style" (click)="markAsPaid()" *ngIf="documentDetails.status == 'Payment Pending'">Mark As Paid</button></div>
                <div><button class="common-btn-style" (click)="conformComments()" *ngIf="(documentDetails.adminStatus === 'Document Under Process'||documentDetails.status ==='Payment Pending' )&& !this.empId && documentDetails.access === true">Assign Employee</button></div>
            </div>
        </div>
    </div>
</div>
<app-common-popup (showHideCancelPopup)='generateInvoicePopupShowHideStatusFun($event)' *ngIf="generateInvoicePopupShowHide">
    <app-generate-invoice (totalInvoiceAmount)='generateInvoice($event);generateInvoicePopupShowHideStatusFun($event)'>
    </app-generate-invoice>
</app-common-popup>
<app-common-popup (showHideCancelPopup)='makeAsPaidPopupShowHideStatusFun($event)' *ngIf="makeAsPaidPopupShowHide">
    <app-make-as-paid (makeAsPaidData)="generateInvoice($event)" (hidePopUp)="makeAsPaidPopupShowHideStatusFun($event)"></app-make-as-paid>
</app-common-popup>
<app-common-popup (showHideCancelPopup)='makeAsPaidPopupShowHideStatus($event)' *ngIf="makeAsPaidPopupShow">
    <app-make-as-paid (makeAsPaidData)="markAsInvoice($event)" (hidePopUp)="makeAsPaidPopupShowHideStatus($event)"></app-make-as-paid>
</app-common-popup>
<app-common-popup (showHideCancelPopup)='showPopupFun()' *ngIf="showPopup" class="popup-content-block">
    <div class="popup-content-block">
    <div>
        <div class="sts">Comments</div>
        <div class="text-area-block">
            <textarea  
            class="text-area_pop input" type="textarea" [attr.maxLength]="350"
            value="" placeholder="Enter details here"
            (input)="textCounter($event)"
            [(ngModel)]="otherValue2"></textarea>
            <input disabled
                class="textarea-range-show" maxlength="3" size="3"
                value="{{remainingValue}} remaining" id="remaining">
                <div class="error-msg days other"><span *ngIf="showOtherError">Please enter the details, if you specify others</span></div>
        </div>
    </div>
    <div class="btn-actions">
        <div class="common-btn-style yes-btn" (click)="popUpClick()">Cancel</div>
        <div class="common-btn-style" (click)="rejectAction()">send</div>
    </div>
    </div>
</app-common-popup>
<section class="paymentPage" >
    <form method="POST"  ngNoForm [action]="paymentRedirectUrl" id="formAuthorizeNetPage" name="formAuthorizeNetTestPage">
        <input type="hidden" id = "paymentvalue" name="token" [value]="paymentToken" />
        <!-- Continue to Authorize.net to Payment Page:  -->
        <button id="btnContinue">Click here to make payment</button>
    </form> 
</section>