import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { API_URLS } from '@app/common/constants/api-urls.constant';
import { EMAIL_EDIT_KEYS } from '@app/common/constants/form-keys.constant';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';
import { IAdminPermissionsI, IGetPermissionsPayloadI, IPostMethodPayload } from '@app/common/constants/typeInterfaces.constants';
import { HTTPMethods, TOAST_STATUSES } from '@app/common/constants/util.constant';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';

@Component({
  selector: 'app-info-form-edit',
  templateUrl: './info-form-edit.component.html',
  styleUrls: ['./info-form-edit.component.scss']
})
export class InfoFormEditComponent implements OnInit {
  breadCrumbData = {
    link: `${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.EMAIL_TEMPLATES}`,
    linkText: 'INFN Req Form'
  };
  informationForm: any;
  templateId: any;
  permissionsDone: boolean = false;
  adminPermissions: IAdminPermissionsI = {
    TEMPLATE_EDIT: true,
  };
  saveFormData: boolean = false;
  isShowForm: boolean = false;
  formData: any;

  constructor(private formService: CustomFormHandlerService, private rotue: ActivatedRoute, private http: HttpService, private observable: ObservableHelperService, private sanitized: DomSanitizer, private router: Router) {
    this.rotue.params.subscribe(pathParams => {
      this.templateId = pathParams.id;
      this.getAdminPermissions();
    });
  }

  ngOnInit() {
  }

  /**
   * @param templateId :Email template id
   * This function is for to get particular email template details
   */
   getEmailTemplateDetails(templateId) {
    const payload = {
      type: HTTPMethods.GET,
      url: API_URLS.EMAIL_TEMPLATE_DETAILS,
      isDeveloper: true,
      pathVariables: [templateId],
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.informationForm = JSON.parse(res.data.htmlTemplate);
        let formFields = res.data;
        formFields = this.informationForm.map((eachField, ind) => {
          return {
            ...eachField,
            key: eachField.questionId,
            label: 'Question ' + ++ind,
            type: 'textarea',
            placeholder: '',
            required: true,
            isDelete: (this.informationForm.length > 1) ? true : false,
            value: eachField.questionLabel,
            controlType: 'textarea',
            emptyValueMessage: 'Please enter question label',
            validationValue: 'Please add valid description',
            validators: [Validators.required],
            className: '',
            editable: true,
            count: '150 remaining',
            showontoggle: true,
          };
        });
        formFields.push({
          formtag: 'INFORMATION_REQUEST',
          controlType: 'addnewField',
          labeltext: 'Add New',
          showontoggle: true,
          editable: true,
        })
        this.formService.informationRequestFormConfig = formFields;
        this.formData = this.formService.initForm(this.formService.formTypes.INFORMATION_REQUEST);
      } else {
        let message = (res.error) ? res.error.message : TOAST_MESSAGES.EMAIL_TEMPLATE_DETAILS_ERROR_MSG;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
      this.isShowForm = true;
    });
  }

  // this is for re-triggering the Input of feedback component
  resetSaveFormParity() {
    window.setTimeout(() => {
      this.saveFormData = false;
    }, 0);
    return true;
  }

   /**
  * getting permissions based on user id
  */
 getAdminPermissions() {
  const payloadData: IGetPermissionsPayloadI = {
    type: HTTPMethods.GET,
    url: API_URLS.GET_PERMISSIONS,
    isDeveloper: true
  };
  this.http.makeHttpRequest(payloadData).subscribe((res) => {
    if (this.http.isSuccessfulResponse(res)) {
      let permissions: Array<string> = ['TEMPLATE_EDIT'];
      let permArr: Array<string> = [];
      res.data.permissions.forEach(obj => {
        permArr.push(obj.permissionName);
      });
      if (!permArr.includes('TEMPLATE_EDIT')) {
        this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.ACCESS_PERMISSOIN_DENIED_MSG);
        this.router.navigate([ROUTE_PATHS.ADMIN, ROUTE_PATHS.EMAIL_TEMPLATES]);
      }
      permissions.forEach(perm => {
        if (!permArr.includes(perm)) {
          this.adminPermissions[perm] = false;
        }
      });
      this.getEmailTemplateDetails(this.templateId);
    } else {
      // Error
      let message: string = (res.error) ? res.error.message : TOAST_MESSAGES.ACCESS_PERMISSION_ERROR_MSG;
      this.observable.showToast(TOAST_STATUSES.ERROR, message);
    }
    this.permissionsDone = true;
  });
}

 /**
   * submit button clicked action
   */
  saveForm() {
    this.saveFormData = true;
    setTimeout(() => {
      if (document.getElementsByClassName('invalid-feedback')[0]) {
        document.getElementsByClassName('invalid-feedback')[0].scrollIntoView({ block: 'center' });
      }
    }, 2);
  }

    /**
   * This is for submitting the form data
   * @param event :Form Data
   */
     getFormData(event) {
      console.log(event);
      if (event) {
        const formData = event.value;
        let questions = this.formService.informationRequestFormConfig.filter((e, i) => i !== this.formService.informationRequestFormConfig.length - 1);
        questions = questions.map((q, i) => {
            return{
              questionId: q.questionId,
              questionLabel: formData[q.key]
            };
        });
        console.log(questions, 'ZZZZ');
        const payload: IPostMethodPayload = {
          type: HTTPMethods.PUT,
          url: API_URLS.EMAIL_TEMPLATE_DETAILS,
          pathVariables: [this.templateId],
          isDeveloper: true,
          body: {
            triggerCondition: 'Form',
            htmlTemplate: JSON.stringify(questions),
            subject: 'Form',
            repeatFrequency: null,
            frequency: null,
            sendingTime: null,
            excludedEmails: []
          }
        };
        this.http.makeHttpRequest(payload).subscribe((res) => {
          if (this.http.isSuccessfulResponse(res) && res) {
            this.observable.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.INFORMATION_FORM_SUCCESS_MSG);
            this.cancel();
          } else {
            let message = TOAST_MESSAGES.INFORMATION_REQUEST_ERROR_MSG;
            message = (res.error) ? res.error.message : message;
            this.observable.showToast(TOAST_STATUSES.ERROR, message);
          }
        });
      }
    }

  
/**
   * back to the previous page
   */
 cancel() {
  if (this.formData && this.formData.formGroup) {
    this.formData.formGroup.reset();
  }
  this.router.navigate([ROUTE_PATHS.ADMIN, ROUTE_PATHS.EMAIL_TEMPLATES]);
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
}
}
