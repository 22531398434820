<!-- bread crumb -->
<!-- Deactivating bread crumb for now -->
    <!-- <a class="breadcrumb__main-wrapper" href="{{breadCrumbData.link}}" >
        <img src="assets/images/admin/appointments/back-button.png" alt="{{breadCrumbData.linkText}}">
        <p class="breadcrumb__text">{{breadCrumbData.linkText}}</p>
    </a> -->

    <!-- Dummy bread crumb for now -->

    <div class="breadcrumb__main-wrapper" (click)="breadCrumbClick(breadCrumbData.link)" >
        <img src="assets/images/admin/appointments/back-button.png" alt="{{breadCrumbData.linkText}}">
        <p class="breadcrumb__text">{{breadCrumbData.linkText}}</p>
    </div>