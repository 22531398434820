// This component is for patient to fill the therapist information.

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IformDataI, IGetMethodPayload, IPostMethodPayload } from '@app/common/constants/typeInterfaces.constants';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { API_URLS } from '@app/common/constants/api-urls.constant';
import { HTTPMethods, TOAST_STATUSES } from '@app/common/constants/util.constant';
import { HttpService } from '@app/common/services/http.service';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { FileUpload } from 'primeng/fileupload';
import { CognitoService } from '@app/common/services/cognito.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-request-therapist-info',
  templateUrl: './request-therapist-info.component.html',
  styleUrls: ['./request-therapist-info.component.scss']
})
export class RequestTherapistInfoComponent implements OnInit {

  formData: IformDataI;
  header: string = `Therapist Information`
  requestId: string = '';
  documentDetails: Record<string, any>;
  isTherapist: boolean = false;
  patientHaveTherapistNotes: boolean = false;
  comments: string = '';
  therapistName: string = '';
  fileValidation: boolean;
  documentUrls: any = [];
  uploadedFiles: any;
  formSubmit: boolean = false;
  invalidTherapistName: boolean = false;
  therapistNameList: Array<string> = ['prayaga', 'rama', 'dr. prayaga', 'dr. rama', 'dr. rama prayaga', 'rama prayaga']
  nameRegexp: RegExp = /[!@#$%^&*()+\=\[\]{};:"\\|~`,.<>\/?]/;
  therapistNameValidation: string = 'Please enter therapist name.';
  patientId: string = '';
  patientsList: Array<any> = [];
  patientData: Array<any> = []; 
  therapistRequests: Array<any> = [];
  patientDetails: Record<string, any>;
  userId: string = '';
  userDetails: Record<string, any>;
  therapistDetails: boolean = false;
  therapyDate: string = '';
  therapistEmail: string = '';
  haveTherapist: boolean = true;
  therapistEmailValidation: string = "";
  sendTherapistEmail: boolean = false;
  todayDate = new Date();
  isTherapistEmail:boolean = false;
  constructor(private route: ActivatedRoute,
    private formService: CustomFormHandlerService,
    private router: Router,
    private http: HttpService,
    private observableHelperService: ObservableHelperService,
    private cognitoService: CognitoService, ) {
    this.route.queryParams.subscribe(queryParams => {
      this.cognitoService.getUser().then(user => {
        this.userId = user.userId;
        this.userDetails = user;
      });
      if (queryParams.requestId) {
        this.requestId = queryParams.requestId;
        this.getRequestedDocumentDetails();
      } else if (queryParams.patientId){
        this.patientId = queryParams.patientId;
        this.getRequestId();
      }
    })
  }

  ngOnInit() {
    this.formData = this.formService.initForm(this.formService.formTypes.REQUEST_THERAPIST_INFO);
  }

  /**
  * @param event :Form Group Data
  * This function is for on submiting the form
  */
  sumbitForm(event?) {
    this.formSubmit = true;
    let bodyData ={
      inTherapy: false,
      lastTherapyDate: null,
      therapistName: null,
      therapistMail: null,
      patientHaveTherapistNotes: null,
      patientConsent: null,
      therapistDocs: [],
      isTherapistEmailId:false
    }
    
    if(!this.isTherapist){
      this.validateTherapistName();
      if(!this.isTherapistEmail){
        this.validateTherapistEmail();
      }

      if(this.therapistName && this.therapistNameValidation == '' && ( (!this.isTherapistEmail && this.therapistEmail) || this.isTherapistEmail) && this.therapistEmailValidation == '' && this.therapyDate){
        this.therapyDate = formatDate(new Date(this.therapyDate), 'yyyy-MM-dd', 'en');
        bodyData ={
          inTherapy: !this.isTherapist,
          therapistName: this.therapistName,
          lastTherapyDate: this.therapyDate,
          therapistMail: !this.isTherapistEmail? this.therapistEmail : "",
          patientHaveTherapistNotes: !this.patientHaveTherapistNotes,
          patientConsent: false,
          therapistDocs: [],
          isTherapistEmailId:!this.isTherapistEmail
        }
      }
      if(!this.patientHaveTherapistNotes){
        this.validateTherapistName();
        if (this.uploadedFiles && this.uploadedFiles.length && this.documentUrls && this.documentUrls.length && this.fileValidation && this.therapistName && this.therapistNameValidation == '' && this.therapistEmail && this.therapistEmailValidation == '' && this.therapyDate) {
          bodyData = {
            inTherapy: !this.isTherapist,
            therapistName: this.therapistName,
            therapistMail: !this.isTherapistEmail? this.therapistEmail : "",
            lastTherapyDate: this.therapyDate,
            therapistDocs: this.documentUrls,
            patientHaveTherapistNotes: !this.patientHaveTherapistNotes,
            patientConsent: false,
            isTherapistEmailId:!this.isTherapistEmail
          }
          this.submitTherapistInfo(bodyData);
        } else {
          if (!this.uploadedFiles) {
            this.uploadedFiles = [];
          }
        }
      } else {
        if(this.sendTherapistEmail){
          bodyData = {
            inTherapy: !this.isTherapist,
            therapistName: this.therapistName,
            therapistMail:  !this.isTherapistEmail? this.therapistEmail : "",
            lastTherapyDate: this.therapyDate,
            therapistDocs: [],
            patientHaveTherapistNotes: !this.patientHaveTherapistNotes,
            patientConsent: true,
            isTherapistEmailId:!this.isTherapistEmail
          }
          this.submitTherapistInfo(bodyData);
        }
      }
    } else {
      this.submitTherapistInfo(bodyData);
    }
  }

  /**
   * Submit the therapist info details
   * @param bodyData 
   */
  submitTherapistInfo(bodyData) {
    const payload: IPostMethodPayload = {
      type: HTTPMethods.POST,
      url: API_URLS.THERAPIST_INFO,
      pathVariables: [this.documentDetails.patientId, this.requestId],
      isDeveloper: true,
      body: bodyData,
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res) && res) {
        this.observableHelperService.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.THERAPIST_INFO_SUBMIT_SUCCESS);
        this.back();
      } else {
        let message = TOAST_MESSAGES.THERAPIST_INFO_SUBMIT_FAIL;
        message = (res.error) ? res.error.message : message;
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
      }
    })
  }
  /**
   * Navigate to the previous page
   */
  back(event?: MouseEvent) {
    this.router.navigate([ROUTE_PATHS.ADD_MyACCOUNT]);
  }

  /**
   * This function is for getting the request document details.
   */
  getRequestedDocumentDetails() {
    const payload: IGetMethodPayload = {
      type: HTTPMethods.GET,
      url: API_URLS.REQUEST_DOCUMENT_DETAILS,
      isDeveloper: true,
      pathVariables: [this.requestId],
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.documentDetails = res.data;
        this.patientId = this.documentDetails.patientId;
        this.getPatientDetails();
      } else {
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.DOCUMENT_DATA_REQUEST_ERROR_MSG);
      }
    });
  }

  /**
   *
   * @param event uploaded files
   * @param remove flag for removing files
   * This funciton will do all the file validations like file extensions,file size,file name,maximum files
   *
   */
  onUpload(event, uploader: FileUpload, remove?) {
    let isRemove = remove || false;
    this.uploadedFiles = event.currentFiles;
    let extentions = ['jpg', 'png', 'gif', 'jpeg', 'tiff', 'psd', 'pdf', 'esp', 'ai', 'indd', 'raw', 'heic', 'heif'];
    let regex: any = /[!@#$%^&*()+\-=\[\]{};':"\\|,.~`<>\/?]+/;
    /**
     * event.currentFiles.length <= 5:-This if condition checks length of the files <= 5
     */
    if (event.currentFiles.length <= 5) {
      let extentionFlag = this.uploadedFiles.every(element => extentions.includes(element.name.includes('.') ? element.name.split('.').pop().toLowerCase() : 'apk'));
      /**
       * extentionFlag:This if condition will check whether uploaded file is valid extention or not
       */
      if (extentionFlag) {
        let isValidFileName = this.uploadedFiles.every(element => !regex.test((element.name.includes('.') && element.name.split('.').length === 2) ? element.name.split('.')[0] : element.name));
        /**
         * isValidFileName:This if condition will check whether uploaded file names has special characters or not except underscore(_)
         */
        if (isValidFileName || this.uploadedFiles.length === 0) {
          let fileSizeFlag = this.uploadedFiles.every(element => element.size <= 10000000);
          /**
           * fileSizeFlag: This if condition will check wheter uploaded file size less than 10MB or not
           */
          if (fileSizeFlag) {
            this.fileUpload();
          } else {
            this.fileValidation = false;
            if (!isRemove) {
              this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.MAX_FILE_SIZE_ERROR_MSG);
            }
          }
        } else {
          for (let index = event.currentFiles.length - 1; index >= 0; index--) {
            if (event.currentFiles.length && event.currentFiles[index] && event.currentFiles[index].name) {
              if (regex.test((event.currentFiles[index].name.includes('.') && event.currentFiles[index].name.split('.').length === 2) ? event.currentFiles[index].name.split('.')[0] : event.currentFiles[index].name)) {
                uploader.remove(event, index);
              }
            }
          }
          if (!isRemove) {
            this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.INVALID_FILENAME_ERROR_MSG);
          }
        }
      } else {
        this.fileValidation = false;
        if (!isRemove) {
          this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.INVALID_EXTENSION_ERROR_MSG);
        }
      }
    } else {
      for (let len = event.currentFiles.length; len > 5; len--) {
        uploader.remove(event, len - 1);
      }
      if (!isRemove) {
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.MAX_FILE_LIMIT_ERROR_MSG);
      }
    }
    if (!isRemove) {
      if (event.files.length) {
        let uploadedFiles = [];
        for (let file of event.files) {
          uploadedFiles.push(file);
        }
        let extentionFlag = uploadedFiles.every(element => extentions.includes(element.name.includes('.') ? element.name.split('.').pop().toLowerCase() : 'apk'));
        if (!extentionFlag) {
          this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.INVALID_EXTENSION_ERROR_MSG);
        } else if (!uploadedFiles.every(element => element.size <= 10000000)) {
          this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.MAX_FILE_SIZE_ERROR_MSG);
        }
      }
    }
  }

  /**
*
* @param event remove file
* This function will do removing of files which are added.
*/
  filesRemoved(event, uploader) {
    if (event.file) {
      this.uploadedFiles = this.uploadedFiles.filter(each => each.name !== event.file.name);
    }
    let fileEvent = {
      currentFiles: this.uploadedFiles
    };
    this.onUpload(fileEvent, uploader, true);
  }

  // This function will remove all files which are uploaded.
  allFilesCleared(event) {
    console.log(event);
  }

  // This funciton will upload the uploaded all files into s3 bucket
  fileUpload() {
    this.fileValidation = true;
    var getUploadUrls;
    var filesData = [];
    let isUpdatedFiles = this.uploadedFiles.every(element => this.documentUrls.some(url => url.indexOf(element.name)));
    if (this.uploadedFiles.length !== this.documentUrls.length || !isUpdatedFiles) {
      this.uploadedFiles.every(element => this.documentUrls.includes(element.name));
      this.uploadedFiles.forEach((element, ind) => {
        let obj = {
          key: 'patientRequestDoc' + ind,
          name: this.accentedtoNormalFun(element.name),
          contentType: element.type || element.name.split('.').pop().toLowerCase()
        };
        filesData.push(obj);
      });
      getUploadUrls = {
        type: 'POST',
        url: 'getUploadUrl',
        isDeveloper: true,
        body: {
          fileNames: filesData,
          userId: this.documentDetails.patientId,
          userName: 'temp-new-tele-doc/' + this.accentedtoNormalFun(this.userDetails.firstName) + this.accentedtoNormalFun(this.userDetails.lastName),
          patientName: this.accentedtoNormalFun(this.documentDetails.firstName) + this.accentedtoNormalFun(this.documentDetails.lastName) + '__' + this.documentDetails.dob
        },
      };
      this.http.makeHttpRequest(getUploadUrls).subscribe(res => {
        this.documentUrls = [];
        const urls = res;
        if (res && typeof (res) === 'object') {
          for (const key in res) {
            if (Object.prototype.hasOwnProperty.call(res, key)) {
              this.documentUrls.push(res[key]);
            }
          }
        } else {
          this.documentUrls = [];
        }
        filesData.forEach(eachFile => {
          let file = this.uploadedFiles.filter(each => this.accentedtoNormalFun(each.name) === eachFile.name);
          let uploadPayload = {
            type: 'PUT',
            url: urls[eachFile.key],
            body: file[0],
            isExternal: true,
            contentType: eachFile.contentType || eachFile.name.split('.').pop().toLowerCase()
          };
          this.http.makeHttpRequest(uploadPayload).subscribe(response => {
          });
        });
      });
    }
  }
  /* for accented letter change convention function */
  accentedtoNormalFun(name) {
    let originalText = name;
    let result = originalText.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    return result;
  }

  validateTherapistName() {
    let emptyValueMessage = 'Please enter therapist name';
    let invalidName = 'Please enter valid name';
    let otherName = 'Please enter therapist name other than Dr. Prayaga'
    if (this.therapistName.replace(/\s/g, '').length == 0 || this.therapistName == '') {
      this.therapistNameValidation = emptyValueMessage
    } else if (this.nameRegexp.test(this.therapistName)) {
      this.therapistNameValidation = invalidName
    } else if (this.therapistNameList.includes(this.therapistName.toLowerCase())) {
      this.therapistNameValidation = otherName
    } else {
      this.therapistNameValidation = '';
    }
  }

  /**
   * on field value change.
   * @param field 
   */
  onValueChange(field) {
    if(field == 'name'){
      this.validateTherapistName();
    } else if(field == 'email'){
      this.validateTherapistEmail();
    }
  }

  /**
   * get the request id from patient details.
   */
  getRequestId(){
    this.getPatientsList(this.userId);
  }

  /**
   * 
   * @param userId :User Id
   * This function is for getting list of patient
   */
  getPatientsList(userId){
    const payload:IGetMethodPayload = {
      type: HTTPMethods.GET,
      url: API_URLS.GET_PATIENT_LIST,
      isDeveloper: true,
      pathVariables: [userId]
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res) && res) {
        this.patientsList = res.data.patients;
        this.patientData = this.patientsList.filter(each => parseInt(each.patientId) === parseInt(this.patientId));
        if(this.patientData && this.patientData.length){
          this.therapistRequests = this.patientData[0].pendingDocumentRequest.filter(eachReq => eachReq.documentIds === '[9]');
          if(this.therapistRequests && this.therapistRequests.length){
            this.requestId = this.therapistRequests[0].requestId;
            this.getRequestedDocumentDetails();
          } else {
            this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.THERAPIST_INFO_NOT_FOUND);
            this.router.navigate([ROUTE_PATHS.ADD_MyACCOUNT]);
          }
        }
      }else{
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.PATIENT_LIST_ERROR_MSG);
      }
    });
  }

  /**
   * get patient details.
   */
  getPatientDetails(){
    let payload = {
      type: 'GET',
      url: 'getOnePatient',
      isDeveloper: true,
      pathVariables: [this.patientId, this.userId]
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res) && res) {
        this.therapistName = (res.data.medicalHistory && res.data.medicalHistory.therapistName) ? res.data.medicalHistory.therapistName : '';
        this.therapistEmail = (res.data.medicalHistory && res.data.medicalHistory.therapistMail) ? res.data.medicalHistory.therapistMail : '';
        this.therapyDate = (res.data.medicalHistory && res.data.medicalHistory.lastTherapyDate) ? this.calendarDateFormate(res.data.medicalHistory.lastTherapyDate) : '';
        this.therapistDetails = (res.data.medicalHistory && res.data.medicalHistory.inTherapy && res.data.medicalHistory.therapistMail);
      } else{
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.THERAPIST_INFO_FAIL);
      }
    });
  }

  /**
   * To validate the email field.
   */
  validateTherapistEmail(){
    let emptyValueMessage = 'Please enter email id';
    let invalidName = 'Please enter valid email id';
    if(this.therapistEmail == ""){
      this.therapistEmailValidation = emptyValueMessage;
    } else {
      var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.therapistEmail !== '' && this.therapistEmail !== undefined) {
        var found = regex.test(this.therapistEmail);
        this.therapistEmailValidation = found ? "" : invalidName;
      }
    }
  }

  /**
   * Format date
   * @param date 
   */
  calendarDateFormate(date) {
    if(date){
      let dates = date.split('-');
      return dates[1] + '/' + dates[2] + '/' + dates[0];
    } else {
      return '';
    }
  }
}
