<!-- home banner section start -->
<section class="home__banner-section page__banner-section {{ bannerData.bannerClass }} full-width">
  <div class="image__block banner-bg">
    <img class="background-leaf" src="assets/images/pngs/services-bg.png" alt="banner image telehealth psychiatrist" loading="lazy"/>
  </div>
  <!-- home banner container start -->
  <div class="home__banner-container page__banner-container restrict-width padding-lr">
    <!-- home banner left block start -->
    <div class="home__banner-left-block">
      <div class="icon__block medium">
        <img class="background-leaf" src="assets/images/pngs/loginpage-leaf-1.webp" alt="leaf icon sildenafil 20 mg" loading="lazy"/>
      </div>
      <!-- home banner heading block start -->
      <h1 class="home__banner-heading-block">
        <span class="orange-heading">{{ bannerData.orangeHeading }}</span>
        <span class="blue-heading">{{ bannerData.blueHeading1 }}</span>
        <span class="blue-heading">{{ bannerData.blueHeading2 }}</span>
        <span class="blue-heading">{{ bannerData.blueHeading3 }}</span>
      </h1>
      <!-- home banner heading block end -->
      <p class="banner__description m-d-hide">
        {{ bannerData.bannerDescription }}
      </p>
    </div>
    <!-- home banner left block end -->
    <!-- home banner right block start -->
    <div class="home__banner-right-block">
      <div class="image__block">
        <img
          class="background-leaf"
          src="assets/images/webp/banner.webp"
          alt="Weight Loss Focused Depression Treatment DC"
          loading="lazy"
        />
      </div>
      <div class="icon__block big">
        <img class="background-leaf3" src="assets/images/pngs/leaf3.png" alt="leaf icon generic cialis dc" loading="lazy"/>
      </div>
      <p class="banner__description m-d-show">
        {{ bannerData.bannerDescription }}
      </p>
    </div>
    <!-- home banner right block end -->
  </div>
  <!-- home banner container end -->
</section>
<!-- home banner section end -->
