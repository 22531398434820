// import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
// import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
// import { DataService } from '@app/common/services/data.service';
// import { Router } from '@angular/router';
// import { appTypeEnums } from '@app/common/constants/util.constant';

// @Component({
//   selector: 'app-feedback',
//   templateUrl: './feedback.component.html',
//   styleUrls: ['./feedback.component.scss']
// })
// export class FeedbackComponent implements OnInit {
//   @Input() saveFormData;
//   apptType: number = 1;
//   @Output() submitEvent: EventEmitter<any> = new EventEmitter();
//   formData: Array<Record<string, unknown>>;
//   constructor(private formService: CustomFormHandlerService, private dataService: DataService, private router: Router) { }

//   ngOnInit() {
//     this.apptType = (this.dataService.data.appointmentDetail) ? this.dataService.data.appointmentDetail.typeAppointmentData[0].value : this.apptType;
//     if (!this.apptType) {
//       this.apptType = appTypeEnums.medicationManagement;
//     }
//     if (this.apptType == appTypeEnums.medicationManagement) {
//       // Medication management feedback
//       this.formData = this.formService.initForm(this.formService.formTypes.FEEDBACK);
//     } else if (this.apptType == appTypeEnums.ktm) {
//       // Ketamine feedback
//       this.formData = this.formService.initForm(this.formService.formTypes.KETAMINEFEEDBACK);
//     } else if (this.apptType == appTypeEnums.tms) {
//       // TMS feedback
//       this.formData = this.formService.initForm(this.formService.formTypes.TMSFEEDBACK);
//     }
//     else if (this.apptType == appTypeEnums.therepy) {
//       // TMS feedback
//       this.formData = this.formService.initForm(this.formService.formTypes.TELETHERAPYFEEDBACK);
//     }
//    else {
//       // Medication management feedback
//       this.formData = this.formService.initForm(this.formService.formTypes.FEEDBACK);
//     }
//   }
//   getFormData(event) {
//     if (event) {
//       if (this.apptType == appTypeEnums.medicationManagement) {
//         this.formService.feedbackFormConfig = this.assignFormValues(event, this.formService.feedbackFormConfig);
//         this.dataService.setData(this.dataService.dataObject.FEEDBACK, this.formService.feedbackFormConfig);
//       } else if (this.apptType == appTypeEnums.ktm) {
//         this.formService.KETAMINEFeedbackFormConfig = this.assignFormValues(event, this.formService.KETAMINEFeedbackFormConfig);
//         this.dataService.setData(this.dataService.dataObject.KETAMINEFEEDBACK, this.formService.KETAMINEFeedbackFormConfig);
//       } else if (this.apptType == appTypeEnums.tms) {
//         this.formService.TMSFeedbackFormConfig = this.assignFormValues(event, this.formService.TMSFeedbackFormConfig);
//         this.dataService.setData(this.dataService.dataObject.TMSFEEDBACK, this.formService.TMSFeedbackFormConfig);
//       }else if(this.apptType == appTypeEnums.therepy){
//         this.formService.feedbackFormConfig = this.assignFormValues(event, this.formService.teleFeedbackFormConfig);
//         this.dataService.setData(this.dataService.dataObject.FEEDBACK, this.formService.teleFeedbackFormConfig);
//       }
//     else {
//         this.formService.feedbackFormConfig = this.assignFormValues(event, this.formService.teleFeedbackFormConfig);
//         this.dataService.setData(this.dataService.dataObject.FEEDBACK, this.formService.teleFeedbackFormConfig);
//       }
//       this.submitEvent.emit({status: event, step: 6});
//     }
//   }

//   assignFormValues(formData, formConfig) {
//     let keys = Object.keys(formData.value);
//     formConfig.forEach((each, index) => {
//       if (keys.includes(each.key)) {
//         each.value = formData.value[each.key];
//       }
//     });
//     return formConfig;
//   }
// }


import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { DataService } from '@app/common/services/data.service';
import { Router } from '@angular/router';
import { appTypeEnums } from '@app/common/constants/util.constant';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})

/* Summary of Changes in FeedbackComponent:

Form Initialization: Used a formTypeMap object to map appointment types to form types, reducing repetitive code.

Duplicate Code: Consolidated getFormData logic using formConfigMap.

Performance: Improved assignFormValues method by using object destructuring and map methods. */

export class FeedbackComponent implements OnInit {
  @Input() saveFormData;
  apptType: number = 1; // Default value
  @Output() submitEvent: EventEmitter<any> = new EventEmitter();
  formData: Array<Record<string, unknown>>;

  // Mapping of appointment types to form types
  private formTypeMap = {
    [appTypeEnums.medicationManagement]: this.formService.formTypes.FEEDBACK,
    [appTypeEnums.ktm]: this.formService.formTypes.KETAMINEFEEDBACK,
    [appTypeEnums.tms]: this.formService.formTypes.TMSFEEDBACK,
    [appTypeEnums.therepy]: this.formService.formTypes.TELETHERAPYFEEDBACK
  };

  constructor(
    private formService: CustomFormHandlerService,
    private dataService: DataService,
    private router: Router
  ) {}

  ngOnInit() {
    const appointmentDetail = this.dataService.data.appointmentDetail;
    this.apptType = appointmentDetail ? appointmentDetail.typeAppointmentData[0].value : this.apptType;
    this.formData = this.formService.initForm(this.formTypeMap[this.apptType] || this.formService.formTypes.FEEDBACK);
  }

  getFormData(event) {
    if (event) {
      const formConfigMap = {
        [appTypeEnums.medicationManagement]: this.formService.feedbackFormConfig,
        [appTypeEnums.ktm]: this.formService.KETAMINEFeedbackFormConfig,
        [appTypeEnums.tms]: this.formService.TMSFeedbackFormConfig,
        [appTypeEnums.therepy]: this.formService.teleFeedbackFormConfig
      };

      const formConfig = formConfigMap[this.apptType] || this.formService.teleFeedbackFormConfig;
      this.formService.feedbackFormConfig = this.assignFormValues(event, formConfig);
      this.dataService.setData(this.dataService.dataObject.FEEDBACK, this.formService.feedbackFormConfig);

      this.submitEvent.emit({status: event, step: 6});
    }
  }

  assignFormValues(formData, formConfig) {
    const formDataMap = formData.value;
    return formConfig.map(each => ({
      ...each,
      value: formDataMap[each.key] || each.value
    }));
  }
}
