<!-- Right side panel data -->
<section class="right__side--panel">
    <ng-container>
        <div class="welcome--msg">Welcome back Admin,</div>
        <div class="today--msg">{{appointmentsMetaData.selectedDate == undefined || appointmentsMetaData.selectedDate == appointmentsMetaData.todayDate ? 'Today' : 'On '+appointmentsMetaData.selectedDate}} you have,</div>
        <div class="location__wise--appointments">
            <div class="header__label">{{appointmentsMetaData.locationName | titlecase}}</div>
            <!-- Sumarry of the right side panel appointmnets-->
            <ng-container *ngIf="appointmentsMetaData.slotsData.length !== 0">
                <ng-container *ngFor="let appointment of appointmentsMetaData.slotsData; let i = index">
                    <div class="common__text">{{appointment.name}} - <div class="census" #target (mouseover)="censusDisplay(appointment, op, target)" (mouseout)="op.hide()">{{appointment.booked}} Booked</div>
                    <!--Overlay for census-count-->
                    <p-overlayPanel #op [appendTo]="target" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" [style]="{'width':'150px'}">
                       <div class="searchResultsDiv">
                           <!-- <div class="searchTextDiv">Scheduled: {{scheduledCount}}</div> -->
                           <div class="searchTextDiv">Pending: {{pendingCount}}</div>
                           <div class="searchTextDiv">Cancelled: {{cancelledCount}}</div>
                           <div class="searchTextDiv">New Patients: {{newPatientsCount}}</div>
                           <div class="searchTextDiv">Confirmed: {{confirmedCount}}</div>
                       </div>
                   </p-overlayPanel>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="appointmentsMetaData.slotsData.length === 0">
                <div class="common__text">There are no treatments
                </div>
            </ng-container>
            <!-- Sumarry of the right side panel appointmnets end -->
        </div>
    </ng-container>
    <!-- Sumarry of upcomming appointments  list -->
    <div class="upcoming--appointments" *ngIf="adminPermissions.APPOINTMENTS">
        <div class="header__label">Upcoming Appointments</div>
        <div class="box__blocks">
            <ng-container *ngIf="appointmentsMetaData.appoinmnetData.length === 0">
                <div class="empty_list">
                    {{appointmentsMetaData.selectedDate == undefined || appointmentsMetaData.selectedDate == appointmentsMetaData.todayDate ? "Today you don't have appointments" : "You don't have any appointments for this date"}}
                </div>
            </ng-container>
            <ng-container *ngFor="let appointment of appointmentsMetaData.appoinmnetData; let i = index">
                <div class="each__box" *ngIf="i<2" (click)="redirection(appointment.appointmentId)">
                    <div class="icon__block">
                        <app-svg [name]="getingAppointmentTypeSvgName(appointment.appointmentTypeName)"
                            [title]="appointment.appointmentTypeName"></app-svg>
                    </div>
                    <div class="patient__info">
                        <div class="patient__name" [title]="appointment.name | titlecase">{{appointment.name | titlecase}}
                        </div>
                        <div>{{appointment.appointmentTime }} - {{appointment.endDate }}</div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="more__text" *ngIf="appointmentsMetaData.appoinmnetData.length > 2 && adminPermissions.APPOINTMENTS" (click)="appointmentsRedirection()">
        +{{appointmentsMetaData.appoinmnetData.length-2}} more</div>
    <ng-container *ngIf="!removeLocation">

        <div class="header__label">Quick links</div>
        <div class="quick__links--block">
            <div class="each__quick-link {{!link.permission ? 'disable' : ''}}" *ngFor="let link of quickLinks" (click)="quickList(link)">
                <app-svg [ngClass]="link.className" [name]="link.icon"></app-svg>
                <div class="link__text">{{link.label}}</div>
            </div>
        </div>
    </ng-container>

</section>