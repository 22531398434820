<!-- success section start -->
<section class="success__section full-width">
    <!-- success container start -->
    <div class="success__container restrict-width padding-lr">
        <div class="success__header-block">
            <div class="icon__block medium">
                <img class="background-leaf3" src="assets/images/pngs/leaf6.png" alt="leaf icon" loading="lazy"/>
            </div>
            <app-section-header [headingData]="headingData"></app-section-header>
        </div>
        <!-- success cards block start -->
        <div class="success__cards-block">

            <div class="success__carousel-block">
                <ngx-slick-carousel class="carousel" [config]="slideConfig">
                    <div ngxSlickItem *ngFor="let slide of successCardData" class="slide">
                        <app-success-card [successCardData]="slide"></app-success-card>
                    </div>
                </ngx-slick-carousel>
            </div>
        </div>
        <!-- success cards block end -->
    </div>
    <!-- success container end -->
</section>
<!-- success section end -->