<section class="dashboard__wrapper--section">
    <div class="dashboard__dropdown--block">
        <app-dashboard-dropdown [removeLocation] ="isLocation" [locationAndProvidersData]="locationAndProvidersData" (calendarType)="calendarType($event)" (selectedDropdownValues)="selectedDropdownValues($event)"></app-dashboard-dropdown>
    </div>
    <div class="radio__btn--block">
        <div class="radio" *ngFor="let each of radiobtnsData" (change)="selectedRadio(each)">
            <input id="{{each.value}}" type="radio" [(ngModel)]="selectedRadioBtn" [value]="each.value" [name]="each.id">
            <label for="{{each.value}}" class="radio-label"><span
                    [ngStyle]="{'width': each.width+'px', 'height': each.height+'px'}" class="icon--styles"
                    *ngIf="each.img">
                    <app-svg [name]="each.img.toLowerCase()"></app-svg>
                </span>{{ each.label }}</label>
        </div>
    </div>
    <div class="dashboard__calendar--view" *ngIf="isShowScreen && permissionsDone">
        <ng-container *ngIf="treatments[0].treatments.length > 0 ">
            <div class="rightside__calendar--block">
                <app-dashboard-calendar [adminPermissions]="adminPermissions" [timestamp]="timestamp" (calendarSlotEvent)="calendarSlotClick($event)" [viewBy]='viewBy' [treatments]="treatments"></app-dashboard-calendar>
            </div>
        </ng-container>
        <ng-container *ngIf="treatments[0].treatments.length === 0">
            <div class="rightside__calendar--block empty_list">
                <app-empty-list-data [emptyMessageData]="emptyAppointmentsListMessages"></app-empty-list-data>
            </div>
        </ng-container>
        
        <div class="leftside__panel--block">
            <app-dash-board-right-panel [adminPermissions]="adminPermissions" [removeLocation] ="removeLocation" [appointmentsMetaData]="rightSidePanelFinalData" *ngIf="rightSidePanelFlag"></app-dash-board-right-panel>
        </div>
    </div>
    <!-- sideBar -->
    <div class="dashobard__side-bar">
        <p-sidebar [(visible)]="sideBarVisible" position="right" styleClass="p-sidebar-width" (onShow)="checkingSidebarState(true)" (onHide)="checkingSidebarState(false);hideTheSideBar();" showCloseIcon=false>
            <app-appointments-cards-list (emptySlotEvent) = "bookEmptySlot($event)" [bookButtonFlag]="addNewBookingButton" [gridType]="gridType" *ngIf="rightSidePanelUpcommingInfoShowHideStatus" (blockedAppointmentCardStatus)="blockedAppointmentCardClickEmiiter($event)" [upcomingAppointments]="rightsideAppointmentList"></app-appointments-cards-list>
            <app-dashboard-block-slots-details *ngIf="blockDetailsStaus" [blokedSlotInputData]="blockedSlotsObj" (actionsEmit)="blockedSlotEvent($event)"></app-dashboard-block-slots-details>
            <!-- Block status block -->
            <div class="dashboard__book-slot" *ngIf="formUI">
                <div class="dashboard__sidebar-title">
                    {{sideBarTitle}}
                </div>
                <ng-container *ngIf=reshedulePatientDataFlag>
                    <div class="patient__name--block">
                        <div class="patient__name">{{patientsDetails.details.patientBasicInfo.firstName | titlecase}} {{patientsDetails.details.patientBasicInfo.lastName | titlecase}}</div>
                    </div>
                    <!-- Appointment details starts here -->
                    <div class="each__block">
                        <div class="details__lable">
                            Patient ID
                        </div>
                        <div class="details__value">
                            {{patientsDetails.details.patientId}}
                        </div>
                    </div>
                </ng-container>
                <div class="dashboard__book-slot__form">
                    <app-custom-form [formData]="blockSlotInputs" (submitEvent)="appointmentFormSubmit($event)" (cancelFormEvent)="cancelButtonEvent($event);sideBarVisible=false"
                        (searchTextClick)="getPatientId($event)" (searchResult)="searchReslutEvent($event)" [filteredSearchList]="searchResultList" (addNewPatient)="getLocationAvailability($event)" (calenderSelectedValue)= "getDateAvailability($event);reScheduleDateChangeEvent($event)"  (timeDropDownValue)="reScheduTeimeChangeEvent($event)">
                    </app-custom-form>
                </div>
            </div>
            <!-- Block status block  end -->
        </p-sidebar>
    </div>
    <!-- <div (click)="appointmentDetails()">Reschedule</div> -->
    <!-- sideBar -->
</section>
<!-- Block patient details -->
<app-common-popup (showHideCancelPopup)='blockPatientPopupShowHideStatusFun($event)' *ngIf="blockPatientPopupShowHide">
    <app-block-patient (submitEvent)="blockPatientSubmit($event);blockPatientPopupShowHide=false"
        [filterInputs]="blockPatientForminputs" [filterData]="rawTreatmentFilterData"></app-block-patient>
</app-common-popup>

<!-- generate invoice -->
<app-common-popup (showHideCancelPopup)='generateInvoicePopupShowHideStatusFun($event)'
    *ngIf="generateInvoicePopupShowHide">
    <app-generate-invoice (totalInvoiceAmount)='generateInvoice($event);generateInvoicePopupShowHideStatusFun($event)'>
    </app-generate-invoice>
</app-common-popup>

<!-- Block patient Ui -->
<app-common-popup (showHideCancelPopup)='adminCancelPopupShowHideStatusFun($event)' *ngIf="adminBookSlotCancelPopupShowHide">
    <div class="popup-content-block" *ngIf="action ==='active'">
        <div class="confirm-message">This patient is currently archived. Please activate the patient before proceeding.</div>
        <div class="btn-actions">
            <div class="common-btn-style yes-btn" (click)="adminCancelPopupShowHideStatusFun({cancelpopup: false},false)">Cancel</div>
            <div class="common-btn-style" (click)="archiveAPICall();adminBookSlotCancelPopupShowHide  = false">Activate</div>
        </div>
    </div>
    <div class="popup-content-block"
        *ngIf="action === 'unblock' && blockedServices.includes(appointmentTypeName[0].name) && blockedServices.length !== treatmentsCount+1">
        <div class="confirm-message">
            <p>This patient has  been blocked  for {{appointmentTypeName[0].name}} previously. Do you want to proceed ?</p>
        </div>
        <div class="btn-actions">
            <div class="common-btn-style yes-btn" (click)="adminCancelPopupShowHideStatusFun({cancelpopup: false},false)">Cancel</div>
            <div class="common-btn-style"
                (click)="adminCancelPopupShowHideStatusFun({cancelpopup: false},true,'proceed')">
                Proceed</div>
        </div>
    </div>
    <div class="popup-content-block" *ngIf="action === 'unblock' && blockedServices.length === treatmentsCount+1">
        <div class="confirm-message">
            <p>This patient has been blocked for all the treatments. <br> Please unblock minimum 1 treatment to continue booking an appointment.</p>
        </div>
        <div class="btn-actions">
            <div class="common-btn-style yes-btn" (click)="adminCancelPopupShowHideStatusFun({cancelpopup: false},false)">Cancel</div>
            <div class="common-btn-style"
                (click)="adminCancelPopupShowHideStatusFun({cancelpopup: false},true,'unblock')">
                Unblock</div>
        </div>
    </div>
    <div class="popup-content-block"
        *ngIf="action === 'lastValid'">
        <div class="confirm-message">
            <p>{{lastValidMessage}}</p>
        </div>
        <div class="btn-actions">
            <div class="common-btn-style yes-btn" (click)="adminCancelPopupShowHideStatusFun({cancelpopup: false},false)">Cancel</div>
            <div class="common-btn-style"
                (click)="adminCancelPopupShowHideStatusFun({cancelpopup: false},true,'lastValid')">
                Proceed</div>
        </div>
    </div>
    <div class="popup-content-block"
        *ngIf="action === 'modifyShow'">
        <div class="confirm-message">
            <p>This patient need to modify the intake form as patient details have been submitted 2 years back.<br/> Do you want to proceed ?</p>
        </div>
        <div class="btn-actions">
            <div class="common-btn-style yes-btn" (click)="adminCancelPopupShowHideStatusFun({cancelpopup: false},false)">Cancel</div>
            <div class="common-btn-style"
                (click)="adminCancelPopupShowHideStatusFun({cancelpopup: false},true,'modifyShow')">
                Proceed</div>
        </div>
    </div>
    <div class="popup-content-block"
        *ngIf="action === 'profileUpdate'">
        <div class="confirm-message">
            <p>This patient need to modify the intake form as there were no appointments booked by this patient in the past 8 months.<br/> Do you want to proceed ?</p>
        </div>
        <div class="btn-actions">
            <div class="common-btn-style yes-btn" (click)="adminCancelPopupShowHideStatusFun({cancelpopup: false},false)">Cancel</div>
            <div class="common-btn-style"
                (click)="adminCancelPopupShowHideStatusFun({cancelpopup: false},true,'profileUpdate')">
                Proceed</div>
        </div>
    </div>
</app-common-popup>

<!-- Block patient Ui -->
<app-common-popup (showHideCancelPopup)='teleAppCancelPopupShowHideStatusFun($event)' *ngIf="teleAppCancelPopupShowHideStatusFlag">
    <div class="popup-content-block"
        *ngIf="patientsDetails.details.isOffline === 1 && offLinePatientStatus === true">
        <div class="confirm-message">
            <p>{{patientName}} is offline patient, you can't book the appointments in {{locationName}}, please select another location</p>
        </div>
    </div>
</app-common-popup>

<!-- generate invoice -->
<app-common-popup (showHideCancelPopup)='blockConformationPopUp($event)'
    *ngIf="blockSlotConformationPopup">
    <div class="popup-content-block">
    <div class="confirm-message">
        <p>Blocking this slot will not allow new bookings. If there are any active appointments, they remain the same</p>
    </div>
    <div class="btn-actions">
        <div class="common-btn-style yes-btn" (click)="blockSlotConformationPopup = false">Cancel</div>
        <div class="common-btn-style"
            (click)="blockStatusConform()">
            Proceed</div>
    </div>
</div>
</app-common-popup>

<!-- Cancel the appointment -->
<app-cancel-booking [cancelData]="cancelAppointmentData" (showHideCancelPopup)='cancelPopupShowHideStatusFun($event)'
    *ngIf="cancelPopupShowHide"></app-cancel-booking>