import { OnInit, AfterViewInit, Component, HostListener, Input } from '@angular/core';
import { HttpService } from '@app/common/services/http.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { BlogToHeader } from '@app/common/services/blog-to-header.service';
import { MmtKnowMore } from '@app/common/services/mmt-know-more.service';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { CognitoService } from '@app/common/services/cognito.service';
import { DataService } from '@app/common/services/data.service';
import { TOAST_STATUSES } from '@app/common/constants/util.constant';
import { Subject } from 'rxjs';
import {ScrollServiceService} from 'src/app/services/scroll-service.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-common-header',
  templateUrl: './common-header.component.html',
  styleUrls: ['./common-header.component.scss']
})
export class CommonHeaderComponent implements OnInit, AfterViewInit {
  constructor(
    private http: HttpService,
    private router: Router,
    private observable: ObservableHelperService,
    private route: ActivatedRoute,
    private dataService: DataService,
    private blogtoheader: BlogToHeader,
    private mmtknowmore: MmtKnowMore,
    private cognitoService: CognitoService,
    private scrollService: ScrollServiceService,
    private location: Location
  ) {
    this.permissionsDone = false;
    router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((e: any) => {
        window.scroll(0, 0);
        this.currentUrl = e.url;
        if (this.currentUrl === '/admin' && this.adminCount === 0) {
          this.permissionsDone = false;
          this.adminCount++;
        }
        if (this.currentUrl.includes('admin')) {
          this.isAdmin = true;
        } else {
          this.isAdmin = false;
        }
        this.isShowHeader = true;
        document.querySelector('body').style.overflow = 'auto';
        this.cognitoService.getUser().then(user => {
          this.userLogedIn = Boolean(user && user.email);
          if (user && user.email && !this.currentUrl.includes('login')) {
            this.headerNavItems = this.headerNavItems.filter(each => each.id !== 'login');
          } else {
            this.headerNavItems = this.tempHeaderNavs;
          }
          let url = this.currentUrl.split('/')[1];
          if (url.includes('?')) {
            url = url.split('?')[0];
          } else if (url === 'admin') {
            url = 'login';
          } else { url = url; }
          this.headerNavItems.forEach((element) => {
            if (this.currentUrl === '/' && !this.flag) {
              this.headerNavItems.forEach((each, ind) => {
                if (ind === 0) {
                  each.selected = true;
                } else {
                  each.selected = false;
                }
              });
            } else if (element.id === url) {
              element.selected = true;
            } else if (url === '' && element.id === 'contactus') {
              element.selected = true;
            } else {
              element.selected = false;
            }
          });
          this.setAdimHumbergSelection();
          this.clearServiceData(this.currentUrl);
        });
        /**
         * isAdmin:-checking user is admin or not
         * this.currentUrl.includes('login'):- checking whether page is login page or not
         * getting notifications count && profilePic data if above conditions are satisfy
         */
        if (this.isAdmin && !this.currentUrl.includes('login')) { this.notificationCount(), this.profilePic(); }

        // code for scroll poistion to display the border-bottom highlight for the header options
        window.addEventListener('scroll', (event) => {
          let url = this.currentUrl.split('/')[1];
          if (url.includes('?')) {
            url = url.split('?')[0];
          } else {
            url = url;
          }
          if (this.currentUrl === '/' || url === '') {
            let scroll = window.scrollY;
            let contactUsTop: number = $('#contactUs').offset().top;
            let contactUsHeight: number = $('#contactUs').outerHeight();
            let commonHeaderHeight: number = $('#contactUs').outerHeight();
            let startPos: number = (contactUsTop - commonHeaderHeight);
            let endPos: number = (contactUsTop + contactUsHeight);
            if (scroll >= startPos && scroll <= endPos) {
              this.headerNavItems.forEach((each, ind) => {
                if (ind === 5) {
                  each.selected = true;
                } else {
                  each.selected = false;
                }
              });
            } else {
              this.headerNavItems.forEach((each, ind) => {
                if (ind === 0) {
                  each.selected = true;
                } else {
                  each.selected = false;
                }
              });
            }
          }
        });
      });
    // for global_search
    this.inputChanged
      .pipe(debounceTime(1500))
      .pipe(distinctUntilChanged())
      .subscribe(newValue => {
        this.filterList(newValue);
      });
  }
  flag = false;
  isShowHeader = false;
  sideBarVisible = false;
  showMobileMenu = false;
  myAccountPopupShowHide = false;
  ListSearchComponent = false;
  // tslint:disable-next-line:ban-types
  date: String;

  @Input() showMyAccountPopup = false;
  popupAction = false;
  headerState = 'pre';
  currentUrl = '';
  isAdmin = false;


  headerShadow = 'hideShadow';
  headerImagePath = 'assets/images/pngs/logo.png';
  headerMobileImagePath = 'assets/images/svgs/new-tele-doc-mobile.svg';
  // Admin header nav items array
  adminHeaderItems = [
    {
      label: 'Search',
      id: 'search',
      selected: false,
      path: '',
      icon: 'search',
      isBadge: false,
      badgeCount: 0
    },
    {
      label: 'Alert',
      id: 'alert',
      selected: true,
      path: '',
      icon: 'alert',
      isBadge: true,
      badgeCount: 0
    },
    {
      label: 'Notifications',
      id: 'notifications',
      selected: false,
      path: '',
      icon: 'notification',
      isBadge: true,
      badgeCount: 0
    },
    {
      label: 'Profile',
      id: 'profile',
      selected: false,
      path: 'assets/images/pngs/empty-profile.png',
      icon: 'profile',
      isBadge: false,
      badgeCount: 0
    },
    {
      label: 'Menu',
      id: 'menu',
      selected: false,
      path: '',
      icon: 'menu',
      isBadge: false,
      badgeCount: 0
    },
  ];

  headerNavItems = [
    {
      label: 'Home',
      id: 'home',
      selected: true
    },
    {
      label: 'Services',
      id: 'services',
      selected: false
    },
    {
      label: 'Treatments',
      id: 'treatments',
      selected: false
    },
    {
      label: 'About us',
      id: 'aboutus',
      selected: false
    },
    {
      label: 'FAQS',
      id: 'faqs',
      selected: false
    },
    // {
    //   label: 'Blog',
    //   id: 'blog',
    //   selected: false
    // },
    {
      label: 'Contact Us',
      id: 'contactus',
      selected: false
    },
    {
      label: 'Login',
      id: 'login',
      selected: false
    }
  ];

  headerDropdownAction = [
    {
      label: 'My Patients',
      id: 'my_patient_form'
    },
    {
      label: 'Profile',
      id: 'edit_profile'
    },
    {
      label: 'Logout',
      id: 'logout'
    },
  ];
  permissionsDone = false;
  adminCount = 0;
  adminHumbergMenu = [
    {
      label: 'Appointments',
      id: 'adminAppointments',
      isSelected: true,
      path: 'appointments',
      permissionName: 'APPOINTMENTS',
      permission: true
    },
    {
      label: 'Patients',
      id: 'adminPatients',
      isSelected: false,
      path: 'patients',
      permissionName: 'PATIENTS',
      permission: true
    },
    {
      label: 'Employees',
      id: 'adminEmployees',
      isSelected: false,
      path: 'employee',
      permissionName: 'EMPLOYEES',
      permission: true
    },
    {
      label: 'Invoices',
      id: 'adminInvoices',
      isSelected: false,
      path: 'invoices',
      permissionName: 'INVOICES',
      permission: true
    },
    {
      label: 'Document Requests',
      id: 'documentRequests',
      isSelected: false,
      path: ROUTE_PATHS.REQUEST_DOCUMENTS,
      permissionName: 'REQUEST DOCUMENTS',
      permission: true
    },
    {
      label: 'Locations',
      id: 'adminLocations',
      isSelected: false,
      path: 'locations',
      permissionName: 'LOCATIONS',
      permission: true
    },
    {
      label: 'Email Templates',
      id: 'adminEmailTemplates',
      isSelected: false,
      path: 'email-templates',
      permissionName: 'EMAIL TEMPLATES',
      permission: true
    },
    {
      label: 'My Profile',
      id: 'adminMyProfile',
      isSelected: false,
      path: 'admin-profile',
      permissionName: 'MY_PROFILE',
      permission: true
    },
    {
      label: 'Logout',
      id: 'adminLogout',
      isSelected: false,
      path: 'logout',
      permissionName: 'LOGOUT',
      permission: true
    },
    {
      label: 'Patient Document Requests',
      id: 'patientDocumentList',
      isSelected: false,
      path: ROUTE_PATHS.PATIENT_REQU_DOCUMENTS,
      permissionName: 'PATIENT REQUEST DOCUMENTS',
      permission: true
    },
    //mednotes
    // {
    //   label: 'Mednotes',
    //   id: 'patientMednotesList',
    //   isSelected: false,
    //   // path: ROUTE_PATHS.PATIENT_REQU_DOCUMENTS,
    //   permissionName: 'PATIENT MEDNOTES LISTS',
    //   permission: true
    // }
  ];
  postHumbergMenu = [
    // {
    //  label: "BOOK APPOINTMENT",
    //  id: "book_appointment"
    // },
    {
      label: 'REQUESTED DOCUMENTS',
      id:'document_request'
    },
    {
      label: 'HOME',
      id: 'my_appointment'
    },
    // {
    //  label: "My Patients",
    //  id: "my_patient_form"
    // },
    {
      label: 'MY PROFILE',
      id: 'edit_profile'
    },
    {
      label: 'FAQS',
      id: 'faqs'
    },
    {
      label: 'LOGOUT',
      id: 'logout'
    },
  ];

  tempHeaderNavs = [...this.headerNavItems];
  userLogedIn = false;

  // global search

  // array for serached patients in global-search
  FilteredObj = '';
  // Input search model text
  searchInput: string;
  overlay: any; // for overlay params storing
  textEmpty = true; // checking whether search box is empty or not
  // change the value subject
  inputChanged: Subject<any> = new Subject<any>();
  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.isShadow();
  }

  @HostListener('window:resize', [])
  onResize() {
    this.isShadow();
  }
  clearServiceData(url) {
    let previousDataUrl = ['/appointment', '/new-patient', '/login'];
    if (url && (!previousDataUrl.includes(url))) {
      this.dataService.clearData();
      this.dataService.clearPreviousData();
    }
  }
  ngOnInit() {
    this.observable.checkRecentlyVisitedPage();
    this.cognitoService.getUser().then(user => {
      this.userLogedIn = Boolean(user && user.email);
      if (user && user.email && !this.currentUrl.includes('login')) {
        this.headerNavItems = this.headerNavItems.filter(each => each.id !== 'login');
      } else {
        this.headerNavItems = this.tempHeaderNavs;
      }
    });

    this.isShadow();

    this.blogtoheader.getMessage().subscribe(
      data => {
        if (data.text === 'blog') {
          this.navIconSelected(4);
        }
      }
    );
    this.mmtknowmore.getMessage().subscribe(
      data => {
        if (data.text === 'treatments') {
          this.navIconSelected(2);
        }
      }
    );
  }
  setAdimHumbergSelection() {
    this.adminHumbergMenu.forEach(list => {
      if (this.currentUrl.includes(list.path)) {
        list.isSelected = true;
      } else {
        list.isSelected = false;
      }
    });
  }
  ngAfterViewInit() {
  }


  
  isShadow() {
    // this.headerShadow = ((window.scrollY != 0) || (window.innerWidth < 1023)) ? 'showShadow' : 'hideShadow';
    this.headerShadow = 'showShadow';
  }

  // This will do sidebar open functionality
  adminHumbergMenuClicked() {
    if (!this.permissionsDone) {
      this.getAdminPermissions();
    }
    this.sideBarVisible = !this.sideBarVisible;
    // this.checkingSidebarState(this.sideBarVisible);
  }

  // Checking the Slide bar is open or if open hide outside scroll
  checkingSidebarState(slideBarFlag) {
    if (slideBarFlag) {
      document.querySelector('html').style.overflow = 'hidden';
      document.querySelector('body').style.overflow = 'hidden';
    } else {
      document.querySelector('html').style.overflow = 'auto';
      document.querySelector('body').style.overflow = 'auto';
    }
  }

  navIconSelected(i) {
    if (i < 5) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'auto'
      });
      // this.scrollService.scrollToSection("")
      this.headerNavItems.forEach((element) => {
        element.selected = false;
      });
      this.headerNavItems[i].selected = true;
    }
    this.closeMobileHeaderMenu();
    this.observable.setCurrentPage(this.headerNavItems[i].id);
    if (this.headerNavItems[i].id === 'home') {
      this.router.navigate(['']);
      // if(this.headerNavItems[i].id == 'home'){
      // }
      // else{
      //  window.open('https://newstressclinic.com/login/', '_blank');
      // }
    } else if (this.headerNavItems[i].id === 'services') {
      this.router.navigate(['services']);
    } else if (this.headerNavItems[i].id === 'treatments') {
      this.router.navigate(['treatments']);
    } else if (this.headerNavItems[i].id === 'aboutus') {
      this.router.navigate(['aboutus']);
    } else if (this.headerNavItems[i].id === 'blog') {
      this.router.navigate(['blog']);
    } else if (this.headerNavItems[i].id === 'faqs') {
      this.router.navigate(['faqs']);
    } else if (this.headerNavItems[i].id === 'contactus') {
      let commonHeight: number;
      let contactUsTop: number;
      let position: number;
      let url = this.currentUrl.split('/')[1];
      console.log(url,"url")
      if (url.includes('?') && url.split('?')[0] === '') {
        // commonHeight = $('.common__header--section').outerHeight();
        // contactUsTop = $('#contactUs').offset().top;
        // commonHeight < 100 ? position = (contactUsTop - (commonHeight + 20)) : position = contactUsTop;
        // $('html,body').animate({ scrollTop: position }, 100, () => $('html,body').stop(true, false));
        this.scrollService.scrollToSection('contactUs');
      }
      if (this.currentUrl === '/') {
        // commonHeight = $('.common__header--section').outerHeight();
        // contactUsTop = $('#contactUs').offset().top;
        // commonHeight < 100 ? position = (contactUsTop - (commonHeight + 20)) : position = contactUsTop;
        // $('html,body').animate({ scrollTop: position }, 2000, () => $('html,body').stop(true, false));
        this.scrollService.scrollToSection('contactUs');
      }
      this.router.navigate([''], );
      setTimeout(() => {
        this.scrollService.scrollToSection('contactUs');
      }, 2000)
    } else if (this.headerNavItems[i].id === 'login') {
      this.router.navigate(['login']);
    } else {
      this.router.navigate(['coming-soon']);
    }
  }

  headerPopupAction() {
    this.popupAction = !this.popupAction;
  }
  navigateToBooking(event) {
    if (event) {
      this.router.navigate([`${ROUTE_PATHS.APPOINTMENT}`]);
    }
  }

  selectedHumbergMenu(menu) {
    if (menu) {
      this.adminHumbergMenu.forEach(adminMenu => {
        if (this.currentUrl.includes(adminMenu.path)) {
          adminMenu.isSelected = true;
        } else {
          adminMenu.isSelected = false;
        }
      });
    }
    if (menu.id === 'adminLogout') {
      this.adminCount = 0;
      this.cognitoService.signOut(() => {
        window.localStorage.clear();
        this.router.navigate(['admin/login']);
      });
    } else if (menu.id === 'adminAppointments') {
      this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.APPOINTMENTS}`]);
    } else if (menu.id === 'adminPatients') {
      this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.PATIENTS}`]);
    } else if (menu.id === 'adminEmployees') {
      this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.EMPLOYEE}`]);
    } else if (menu.id === 'adminInvoices') {
      this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.INVOICES}`]);
    } else if (menu.id === 'adminLocations') {
      this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.LOCATIONS}`]);
    } else if (menu.id === 'adminMyProfile') {
      // this.observable.showToast(TOAST_STATUSES.SUCCESS, 'This feature is coming out soon..!!');
      this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.ADMIN_PROFILE}`]);
    } else if (menu.id === 'adminEmailTemplates') {
      this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.EMAIL_TEMPLATES}`]);
    } else if (menu.id === 'documentRequests') {
      this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.REQUEST_DOCUMENTS}`]);
    } else if(menu.id === 'patientDocumentList'){
      this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.PATIENT_REQU_DOCUMENTS}`]);
    }else {
      this.observable.showToast(TOAST_STATUSES.SUCCESS, 'This feature is coming out soon..!!');
    }
    this.sideBarVisible = !this.sideBarVisible;
  }

  // This is admin header actions function
  adminHeaderActions(item, e) {
    if (item) {
      if (item.id === 'menu') {
        this.adminHumbergMenuClicked();
      } else if (item.id === 'search') {
        e.stopPropagation();
        this.ListSearchComponent = true; // need to remove once after going to testing
        setTimeout(() => {
        document.getElementById('header-Search').focus();
        }, 2);
      } else if (item.id === 'profile') {
        this.router.navigate([ROUTE_PATHS.ADMIN + '/' + ROUTE_PATHS.ADMIN_PROFILE]);
      } else if (item.id === 'notifications') {
        setTimeout(() => {
          this.router.navigateByUrl(ROUTE_PATHS.ADMIN, { skipLocationChange: false }).then(() => this.router.navigate([ROUTE_PATHS.ADMIN + '/' + ROUTE_PATHS.NOTIFICATIONS], { queryParams: { type: 'all' } }));
        }, 100);
      } else if (item.id === 'alert') {
        setTimeout(() => {
          this.router.navigateByUrl(ROUTE_PATHS.ADMIN, { skipLocationChange: false }).then(() => this.router.navigate([ROUTE_PATHS.ADMIN + '/' + ROUTE_PATHS.NOTIFICATIONS], { queryParams: { type: 'critical' } }));
        }, 100);
      } else {
        this.observable.showToast(TOAST_STATUSES.SUCCESS, 'This feature is coming out soon..!!');
      }
    }
  }

  isInnerPage(): boolean {
    let currentUrl = this.location.path();
    let urlSearchParams = new URLSearchParams(currentUrl.split('?')[1]);
    let headingParam = urlSearchParams.get("heading");
    if(headingParam === "ADHD TREATMENT" || headingParam === "TESTOSTERONE TREATMENT" || headingParam === "MEDICAL MARIJUANA TREATMENT" || headingParam === "ANTI AGING TREATMENT" || headingParam === "KETAMINE THERAPY"){
      return true;
    }else {
      return false;
    }
  }

  dropdownActionClicked(currentValueId) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.closeMobileHeaderMenu();
    if (currentValueId === 'my_patient_form') {
      this.myAccountPopupShowHideFun();

    } else if (currentValueId === 'logout') {
      this.dataService.clearData();
      this.dataService.clearPreviousData();
      this.cognitoService.signOut(() => {
        window.localStorage.clear();
        this.router.navigate(['login']);
      });
    }else if(currentValueId == 'document_request'){
      this.router.navigate([`${ROUTE_PATHS.Document_request}`]);
    } else if (currentValueId === 'my_appointments') {
      this.router.navigate(['appointment/details']);
    } else if (currentValueId === 'book_appointment') {
      this.router.navigate([`${ROUTE_PATHS.APPOINTMENT}`]);
    } else if (currentValueId === 'my_appointment') {
      this.router.navigate([`${ROUTE_PATHS.ADD_MyACCOUNT}`]);
    } else if (currentValueId === 'faqs') {
      this.router.navigate([`${ROUTE_PATHS.FAQS}`]);
    } else if (currentValueId === 'edit_profile') {
      this.router.navigate([`${ROUTE_PATHS.PROFILE}`]);
    }
  }
  toggleScroll(status) {
    let url = this.currentUrl.split('?')[0];
    if (url !== '/new-patient') {
      document.body.style.overflow = (status) ? 'hidden' : 'scroll';
    } else if (url === '/new-patient') {
      document.body.style.overflow = 'hidden';
    }
  }
  myAccountPopupShowHideFun() {
    this.myAccountPopupShowHide = !this.myAccountPopupShowHide;
    this.toggleScroll(this.myAccountPopupShowHide);
  }
  myAccountPopupShowHideStatusFun(status) {
    this.myAccountPopupShowHide = status;
    this.toggleScroll(this.myAccountPopupShowHide);
  }

  showMobileHeaderMenu() {
    // document.querySelector('body').style.overflow = 'hidden';
    // window.scrollTo(0,0);
    this.showMobileMenu = true;
  }

  closeMobileHeaderMenu() {
    this.showMobileMenu = false;
    // document.querySelector('body').style.overflow = 'unset';
  }

  openLandingPage() {
    this.headerNavItems.forEach((element, ind) => {
      if (ind === 0) {
        element.selected = true;
      } else {
        element.selected = false;
      }
    });
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.cognitoService.getUser().then(user => {
      this.userLogedIn = Boolean(user && user.email);
      if (!(user && user.email)) {
        this.observable.setCurrentPage('home');
        this.router.navigate(['']);
      } else if (user && user.email && this.currentUrl.includes('admin')) {
        this.observable.setCurrentPage('admin');
        this.router.navigate(['admin']);
      } else {
        this.observable.setCurrentPage('myaccount');
        this.router.navigate(['myaccount']);
      }
    });

  }
  // if global search opened need to close function
  onClickedOutside(e, op) {
    op.hide();
    this.ListSearchComponent = false;
    this.searchInput = '';
    this.textEmpty = true;
    this.FilteredObj = '';
  }

  // if cross icon is clicked
  clear() {
    this.overlay.hide();
    this.searchInput = '';
    this.textEmpty = true;
    this.FilteredObj = '';
  }

  inputTextEnter(e: any, op: any) {
    const text = e.target.value; 
    this.textEmpty = text === ''; 
    this.overlay = op; // Assign the overlay reference to a class property
  
    if (e.key === 'Enter') {
      if (text !== '') {
        this.textEmpty = false; 
        this.searchInput = text; 
  
        let params = {
          searchText: this.searchInput,
          overlay: this.overlay
        };
  
        this.inputChanged.next(params); // Emit the search parameters for further handling
        this.overlay.show(); // Show the overlay
      } else {
        this.textEmpty = true; // Set textEmpty to true when input is empty
        this.FilteredObj = ''; 
        this.overlay.hide(); 
        this.searchInput = '';
      }
    }
    if(text === '') {
      this.overlay.hide();
    }
  }
  // filter patient list on global search
  filterList(data: any) {
    const payloadData = {
      type: 'GET',
      url: 'adminGlobalSearch',
      isDeveloper: true,
      pathVariables: [data.searchText],
    };
    if (!this.textEmpty) {
      this.http.makeHttpRequest(payloadData).subscribe((res) => {
        if (this.http.isSuccessfulResponse(res)) {
          this.FilteredObj = res.data;
          data.overlay.show('', 'target');
        } else {
          setTimeout(() => {
            data.overlay.hide();
            let message = 'Failed to fetch patients data. Please try again';
            message = (res.error) ? res.error.message : message;
            this.observable.showToast(TOAST_STATUSES.ERROR, message);
            this.router.navigate([this.currentUrl]);
          }, 1000);
        }
      });
    }
  }

  // API request for getting admin pic
  profilePic() {
    const payloadData = {
      type: 'GET',
      url: 'adminProfile',
      isDeveloper: true,
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        let profileImage = 'assets/images/pngs/empty-profile-picture.png';
        if (res.data.employeeDetails) {
          res.data.employeeDetails.extraUploads.forEach(obj => obj.uploadType === 'profile' ? profileImage = obj.docUrl : null);
          // tslint:disable-next-line:no-unused-expression
          profileImage != null ? this.adminHeaderItems.forEach(obj => obj.id === 'profile' && JSON.stringify(obj.path) !== JSON.stringify(profileImage) ? obj.path = profileImage : null) : null;
        }
      } else {
        let message = 'Failed to fetch profile picture. Please try again';
        message = (res.error) ? res.error.message : message;
        // this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  // API request for getting unread notifications count
  notificationCount() {
    // alert('entered')
    const payloadData = {
      type: 'GET',
      url: 'notificationsCount',
      isDeveloper: true,
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.adminHeaderItems.forEach(obj => {
          obj.id === 'alert' ? obj.badgeCount = res.data.criticalCount :
            // tslint:disable-next-line:no-unused-expression
            obj.id === 'notifications' ? obj.badgeCount = res.data.count : null;
        });
      } else {
        let message = 'Failed to fetch notification count. Please try again';
        message = (res.error) ? res.error.message : message;
        // this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  // getting permissions based on user id
  getAdminPermissions() {
    const payloadData = {
      type: 'GET',
      url: 'getPermissions',
      isDeveloper: true
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        // tslint:disable-next-line:one-variable-per-declaration
        let permCatArr = [];
        res.data.permissions.forEach(obj => {
          if (!permCatArr.includes(obj.permissionCategory)) {
            permCatArr.push(obj.permissionCategory);
          }
        });
        permCatArr.push('MY_PROFILE', 'LOGOUT');
        this.adminHumbergMenu = this.adminHumbergMenu.map(menu => {
          return { ...menu, permission: !permCatArr.includes(menu.permissionName) ? false : true };
        });
      } else {
        // Error
        let message = (res.error) ? res.error.message : 'Error in getting permissions';
      }
      this.permissionsDone = true;
    });
  }
}
