<!-- Appoinement List -->

<!-- Appoinement List -->
<div class="appointments__list invoice-list-data history-list" (click)="invoiceDetailsClick(patientList.info.appointmentId)">
    <!-- Appoinement List Date-->
    <!-- Appoinement List time-->
    <div class="appointments__list-item date date_time">
        <app-svg class="appointments__icon" [name]="'calendar'"></app-svg>
        <div class="appointments__list-text">
            <p class="time_data">
                {{patientList.info.appointmentDate | dateFormat}}
            </p>
            <p class="time_data">
                {{ patientList.info.appointmentTime | customeTimeAmPm}}
            </p>
        </div>
    </div>
    <div class="appointments__list-item  user patient_name">
        <app-svg class="appointments__icon" [name]="'user'"></app-svg>
        <p class="appointments__list-text" [title]="patientList.info.location.locationName | titlecase">
            {{patientList.info.location.locationName | titlecase | ellipsis:18}}</p>
    </div>
    <!-- Appoinement List User-->
    <div class="appointments__list-item type">
        <app-svg class="appointments__icon" [ngClass]="patientList.treatmentIcon" [name]="patientList.treatmentIcon">
        </app-svg>
        <p class="appointments__list-text" [title]="patientList.info.appointmentType.appointmentTypeName | titlecase">
            {{ patientList.info.appointmentType.appointmentTypeName | titlecase | ellipsis:23}}
        </p>
    </div>
    <div class="appointments__list-item bill_name" *ngIf="patientList.invoice">
        <app-svg class="appointments__icon" [name]="'bill-amount'"></app-svg>
        <div class="appointments__invoice-data">
            <p class="appointments__list-text amount" *ngIf="patientList.billAmount">{{patientList.billAmount}}</p>
            <p class="appointments__list-text status" *ngIf="patientList.invoice.status !== 'ungenerated' " [ngClass]="patientList.invoice.status"
                [title]="patientList.invoice.status | titlecase">
                {{patientList.invoice.status | titlecase | ellipsis:18}} </p>
            <!-- <p class="appointments__list-text" *ngIf="patientList.invoice.status === 'ungenerated' " [ngClass]="patientList.invoice.status"
                title="Not Generated">
                Not Generated </p> -->
        </div>
    </div>
    <div class="appointments__list-item bill_name" *ngIf="!patientList.invoice">
        <app-svg class="appointments__icon" [name]="'bill-amount'"></app-svg>
        <p class="appointments__list-text">---</p>
    </div>
    <!-- Appoinement List Icon -->
    <div class="icons__wrapper">
        <div class="appointments__list-item generate-icons" *ngIf="(patientList.actions.length <= 2)">
            <ng-container>
                <div class="invoice-actions-list reports"
                    (click)="patientEvent($event, patientList.info, patientList.actions[0].id, patientList.invoice)"
                    [title]="patientList.actions[0].name">
                    <app-svg class="appointments__icon" [ngClass]="patientList.actions[0].className? patientList.actions[0].className: ''"
                        [name]="patientList.actions[0].icon"></app-svg>
                </div>
                <div class="invoice-actions-list documents"
                    (click)="patientEvent($event, patientList.info, patientList.actions[1].id, patientList.invoice)"
                    [title]="patientList.actions[1].name">
                    <app-svg class="appointments__icon" [ngClass]="patientList.actions[1].className?patientList.actions[1].className:''"
                        [name]="patientList.actions[1].icon"></app-svg>
                </div>
            </ng-container>
        </div>
        <div class="appointments__list-item generate-icons" *ngIf="(patientList.actions.length > 2)">
            <div class="invoice-actions-list"
                (click)="patientEvent($event, patientList.info, patientList.actions[0].id, patientList.invoice)"
                [title]="patientList.actions[0].name">
                <app-svg class="appointments__icon" [ngClass]="patientList.actions[0].className?patientList.actions[0].className:''"
                    [name]="patientList.actions[0].icon"></app-svg>
            </div>
            <div class="more-icon" (clickOutside)="op.hide()" (click)="moreClick($event, op)">
                <app-svg class="appointments__icon" [name]=" 'list-more' "></app-svg>
            </div>
            <!-- This will be uncommented after export build -->
            <p-overlayPanel #op [showTransitionOptions]=" '100ms' " [hideTransitionOptions]=" '100ms' "
                [appendTo]=" 'body' ">
                <ng-template pTemplate>
                    <ng-container *ngFor="let eachAction of patientList.actions| slice:1 ; let i=index ">
                        <div class="line "></div>
                        <div class="more__option "
                            (click)="patientEvent($event, patientList.info, eachAction.id, patientList.invoice,op) ">
                            {{eachAction.name | titlecase}}
                        </div>
                    </ng-container>
                </ng-template>
            </p-overlayPanel>
        </div>
    </div>
</div>