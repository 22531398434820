<section class="forgot-password__section">
    <div class="registered-user" *ngIf="forgotPasswordPhase == 'post'">
        Verification link has been sent to <span class="text-bold">"{{userName}}"</span>. If you have not received it
        yet, recheck your email id or Please check your inbox/spam/promotions/social section for an email from <span
            class="text-bold">"{{adminMail}}"</span><br>
        Didn’t receive an email?
        <br>
        <br>
        <app-custom-form [formData]="form" (submitEvent)="resendCode($event)"></app-custom-form>
    </div>
    <div class="registered-user" *ngIf="forgotPasswordPhase == 'needVerify'">
        Your registered email <span class="text-bold">"{{userName}}"</span> is not yet verified.<br>
        Resend verification mail?

        <br>
        <br>
        <app-custom-form [formData]="form" (submitEvent)="resendVerificationCode($event)"></app-custom-form>
    </div>
    <div class="registered-user" *ngIf="forgotPasswordPhase == 'needAdminVerify'">
        Please check your email <span class="text-bold">"{{userName}}"</span> to activate your account<br>
        Resend activation mail?

        <br>
        <br>
        <app-custom-form [formData]="form" (submitEvent)="resendVerificationCode($event)"></app-custom-form>
    </div>
    <div class="registered-user forgot_password" *ngIf="forgotPasswordPhase == 'verify'">
        Create new password
        <br>
        <br>
        <app-custom-form [formData]="forgotPasswordForm" (submitEvent)="forgotPasswordSubmit($event)"></app-custom-form>
    </div>
    <div class="registered-user" *ngIf="forgotPasswordPhase == 'complete'">
        Successfully set a new password. Try login.
        <br>
        <br>
    </div>
    <app-custom-form [formData]="form" *ngIf="forgotPasswordPhase == 'pre' && forgotPasswordPath == 'fp'" (submitEvent)="btnClickEvent($event)">
    </app-custom-form>

    <app-custom-form class="forgot_password" [formData]="form" *ngIf="forgotPasswordPhase == 'pre' && forgotPasswordPath == 'dob'" (submitEvent)="submitDateofB($event)">
    </app-custom-form>
</section>