import { Component, OnInit, AfterViewInit, HostListener, ElementRef, ViewChild, ViewContainerRef, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { filter } from 'rxjs/operators';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { HttpService } from '@app/common/services/http.service';
import { CognitoService } from '@app/common/services/cognito.service';
import { HTTPMethods, TOAST_STATUSES, DEFAULT_TIME_ZONE_DIFF, convertDateToSpecifiedOffset, NEWSTRESS_EMAIL_IDS } from '@app/common/constants/util.constant';
import { CustomFormComponent } from '@app/common/components/custom-form/custom-form.component';
import { FormBuilder, Validators } from '@angular/forms';
import { DataService } from '@app/common/services/data.service';
import { input } from '@aws-amplify/ui';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';
import { API_URLS } from '@app/common/constants/api-urls.constant';
import { INTAKE_FORM_KEYS } from '@app/common/constants/form-keys.constant';
@Component({
  selector: 'app-new-patient-wrapper',
  templateUrl: './new-patient-wrapper.component.html',
  styleUrls: ['./new-patient-wrapper.component.scss'],
})
export class NewPatientWrapperComponent implements OnInit, AfterViewInit {
  formCompleted: any;
  addNewIndex: any = 0;

  formBuilder = new FormBuilder();
  @ViewChild('formElement', { static: true }) ele: ElementRef;
  childComponent = new CustomFormComponent(this.formService, this.formBuilder, this.http, this.cognitoService, this.observableHelperService, this.route, this.router);
  form: any = [];
  formTags: any = ['NEWUSER_BASICINFO', 'NEWUSER_INSURANCEDETAIL', 'NEWUSER_MEDICALHISTORY', 'NEWUSER_MISCELLAENOUS', 'NEWUSER_NEWPATIENTSUBMIT'];
  formPosArray: any = [];
  cancelPopupShowHide: boolean = false;
  stepindicatorArray: any = [];
  saveFormData: boolean = false;
  currentFormIndex: number = 0;
  isMobile: boolean = false;
  actionText = 'Save';
  userId: any;
  editable: boolean = false;
  userIdNumber: any;
  editViewClicked: boolean = false;
  editViewData: any;
  showPharmacyStatePopup: boolean = false;
  allowedPharmacyStates: Array<string> = ['MD','VA','DC']
  checkFormInfo = { // get data and check data is valid or not
    basic_information: false,
    insurance_details: false,
    medical_history: false,
    miscellaenous: false,
    submit: false
  };

  getFormInfo = { // get the form data not checking
    basic_information: false,
    insurance_details: false,
    medical_history: false,
    miscellaenous: false,
    submit: false
  };

  // terms and condition static data
  termsAndConditionData = {
    description: 'Dr. Prayaga provides the following services: Medication Management, Psychiatric Evaluations, Transcranial Magnetic Stimulation, and Telemedicine Services (current patient\'s only).  If you are seeking therapy, individual or marriage counseling, or substance abuse counseling, please reach out to your insurer for a list of physicians who provide such services. While Dr. Prayaga does not provide the above-mentioned services, he can coordinate care with your selected therapist or counselor in conjunction with medication management and/or Transcranial Magnetic Stimulation services.',
    listdescription: 'Please review the information listed below to assist you in preparing for your office visit.',
    list: [
      'Bring your identification card and insurance card.  We will make a copy of the documents for your file.',
      'Prescriptions renewals are conducted in person with the doctor every 28-30 days( i.e. no refills will be called in)',
      'Dr. Prayaga carefully reviews and monitors medications that are prescribed, therefore he avoids giving out 3-month supplies of medication.',
      'Please provide a courtesy 24-hour cancellation notification. It is the office’s policy to charge a $25.00 cancellation fee if the cancellation policy isn’t adhered to by patient\'s.',
      'For patient\'s requesting disability paperwork, the office is able to provide medical records or a discharge summary only.  The processing time for medical records or a discharge summary is 5 to 7 days upon receipt of the request.  All requests must be submitted to the office staff or receptionist.',
      // 'Only service animals are permitted in the office.  No other animals are allowed in the office.',
      'Only service animals with accompanying a person while pat is being treated.'
    ]
  };
  // input for step indicator
  stepDetails = [
    {
      label: 'Basic Information',
      id: 'basic_information',
      img: 'content',
      visited: true,
      notVisisted: false,
      completed: false,
      onPage: true
    },
    {
      label: 'Insurance Details',
      id: 'insurance_details',
      img: 'shield',
      visited: false,
      notVisisted: true,
      completed: false,
      onPage: false
    },
    {
      label: 'Medical History',
      id: 'medical_history',
      img: 'heart',
      visited: false,
      notVisisted: true,
      completed: false,
      onPage: false
    },
    {
      label: 'Miscellaneous',
      id: 'miscellaenous',
      img: 'miscellaenous',
      visited: false,
      notVisisted: true,
      completed: false,
      onPage: false
    },
    {
      label: 'Submit',
      id: 'submit',
      img: 'submit',
      visited: false,
      notVisisted: true,
      completed: false,
      onPage: false
    }
  ];
  formBackUp: any = [
    [],
    [],
    [],
    [],
    []
  ];
  copyStepDetails = [...this.stepDetails];
  elementsHeight: any[];
  currentUrl: any;
  formData: any = {};
  finalFormData: any = {};
  providers: any = {};
  pharmacy: any;
  userDetails: any;
  formSubmited: boolean = false;
  editData: any;
  showForm = false;
  mySubscription: any;
  isEditAndView: boolean = false;
  showScreen;
  editCopy: any;
  showConfirmTerms: boolean = true;
  updatePatientData: boolean = false;
  toModify: boolean = false;
  isCVSChange: boolean = false;
  isInsuranceChange: boolean = false;
  fileUploadProgress: number = 0;
  modifyProfile: boolean = false;
  updateProfile: boolean = false;
  autoScrollToPharmacy: boolean = false;
  docList: Array<string> = [];
  showDocListPopup: boolean = false;
  mailText = 'must be a valid email';
  updateFormIndex: any;
  stateList: any;
  
  constructor(private formService: CustomFormHandlerService,
    private cd: ChangeDetectorRef,
    private elRef: ElementRef,
    private observableHelperService: ObservableHelperService,
    private route: ActivatedRoute,
    private http: HttpService,
    private router: Router,
    private dataService: DataService,
    private observable: ObservableHelperService,
    private cognitoService: CognitoService) {
    router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((e: any) => {
        this.currentUrl = e.url;
        console.log(this.currentUrl);
      });
    /* component refresh */
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.router.navigated = false;
      }
    });
  }
  ngAfterViewInit(): void {

  }


  ngOnInit() {
    document.querySelector('html').style.overflow = 'hidden';
    this.initLoad();
  }
  initLoad() {
    this.cognitoService.getUser().then(user => {
      this.userDetails = user;
      this.route
        .queryParams
        .subscribe(params => {
          // console.log(params);
          this.userIdNumber = params.id;
          if (Object.keys(params).length === 0) {
            this.showForm = true;
          }
          // storing pharmacy change flag in isCVSChange variable
          if (params.isPharmacy) {
            this.isCVSChange = true;
            this.updateFormIndex = 3;
            this.stepDetails = this.stepDetails.filter((e, ind) =>  ind === 3);
          }
          if (params.isInsurance) {
            this.isInsuranceChange = true;
            this.updateFormIndex = 1;
            this.stepDetails = this.stepDetails.filter((e, ind) =>  ind === 1);
          }
          if (params.edit) {
            this.isEditAndView = true;
            this.editable = false;
          } else if (params.view) {
            this.isEditAndView = true;
            this.editable = true;
          } else if (params.update) {
            this.isEditAndView = true;
            this.editable = false;
            this.updatePatientData = true;
          } else if (params.newForm || params.modify || params.modifyProfile || params.updateProfile) {
            this.modifyProfile = (params.modifyProfile || params.updateProfile) ? true : false;
            this.updateProfile = params.updateProfile ? true : false;
            this.editable = false;
            this.toModify = params.modify ? true : false;
            /* resetting formInputs values for new patient form */
            this.formBackUp.map(element => {
              element.map(innerElement => {
                if (innerElement.type === 'formArray') {
                  innerElement.formInputs[0] = innerElement.formInputs[0].map(inputElement => {
                    // console.log(inputElement.value, 'form backup element', inputElement.type);
                    inputElement = this.getDefaultValue(inputElement);
                    return inputElement;
                  });
                }
                return innerElement;
              });
              return element;
            });
          }
          // console.log('this.isEditAndView', this.isEditAndView);
        });
    });
    this.getStateList();
    this.getProvidersAndPharmacyDetails();
    this.scrollTop();
    this.focusFirstFieldInSafari();
    this.isMobile = window.innerWidth < 1024 ? true : false;
    document.body.style.overflow = 'hidden';

    this.formTags.forEach((element) => {
      if (this.formService.formTypes[element].config === 'newUserMedicalHistoryFormConfig') {
        this.formService.newUserMedicalHistoryFormConfig.forEach(field => {
          if (field.key === 'medications' || field.key === 'benzodiazepines' || field.key === 'medicaladderal') {
            field.formInputs[0].forEach(form => {
              if (form.key === 'discontinuedReasion') {
                form.required = true;
                return false;
              }
            });
            field.formInputs[0].forEach(form => {
              if (form.key === 'dosage') {
                form.required = true;
                return false;
              }
            });
          }
        });
      }
      this.form.push(this.formService.initForm(this.formService.formTypes[element]));
    });

    if (this.form) {
      ///      this.formBackUp = [...this.form.map(element => element.formInputs.map(ele => ele))]
      this.form.forEach((element, index) => {
        element.formInputs.forEach(ele => {
          // console.log(ele);
          /* following code makes deep copy of the formInputs and formArrays to backup */
          let obj = { ...ele };
          for (let key in ele) {
            if (Array.isArray(ele[key])) {
              obj[key] = [...ele[key]];
            }
          }
          this.formBackUp[index].push({ ...obj });
        });
      });
      let beforeData = [...this.form];
      console.log('before edit', beforeData);
    }
    // console.log(this.formBackUp, 'Backup form Beofe');
    this.observableHelperService.getAddPatientClicked().subscribe((data) => {
      if (data) {
        this.saveFormData = false;
        this.form.forEach(eachForm => {
          eachForm.formGroup.reset();
          // tslint:disable-next-line:no-shadowed-variable
          eachForm.formInputs.forEach(input => {
            if (input.value && input.validators) {
              input.value = '';
              input.validators = [];
              input.editable = true;
              input.selected = false;
              input.showImage = true;
            }
          });
        });
      }
    });
    this.focusFirstFieldInSafari();
  }
  /**
 * Fetches patient details by user ID and processes the response.
 * 
 * Initializes `therapistEmailList` with a default set of email addresses.
 * Appends additional emails from the response to `therapistEmailList`.
 * 
 * Formats `dob` to a calendar date format if it is not an empty string; otherwise, sets it to an empty string.
 * 
 * Processes patient data and formats date fields.
 */
  getOldFieldsData() {
    let payload = {
      type: 'GET',
      url: 'getOnePatient',
      isDeveloper: true,
      pathVariables: [this.userIdNumber, this.userDetails.userId]
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      this.formService.therapistEmailList = NEWSTRESS_EMAIL_IDS;
      if (res.data.pointofContact && res.data.pointofContact.email) {
        this.formService.therapistEmailList = [...this.formService.therapistEmailList, res.data.pointofContact.email];
      }
      this.formService.therapistEmailList = [...this.formService.therapistEmailList, ...res.data.allUserPatientEmails];
      delete res.data.pointofContact;
      this.editData = this.massageEditData(res.data);
      this.editData['dob'] = (this.editData['dob'] !== '') ? this.calendarDateFormate(this.editData['dob']) : '';
      this.form.every((obj, objIndex) => {
        if (objIndex === 0) {
          obj.formInputs.every((form, i) => {
            if(this.toModify){
              if (i < 19) {
                if (form.key !== undefined) {
                  form.value = this.editData[form.key];
                  form.selected = form.value;
                  form.showImage = false;
                  obj.formGroup.controls[form.key].value = this.editData[form.key] ? this.editData[form.key] : '';
                  if(this.editData[form.key]){
                    obj.formGroup.controls[form.key].clearValidators();
                    obj.formGroup.controls[form.key].required = false;
                    form.editable = false;
                  } else {
                    form.showontoggle = true;
                  }
                  obj.formGroup.controls[form.key].updateValueAndValidity();
                }

                obj.formGroup.controls[form.key].updateValueAndValidity();
                return true;
              } else {
                // console.log(i);
                return false;
              }
            } else if(this.modifyProfile){
              if ( i > 0  && i < 6) {
                if (form.key !== undefined) {
                  form.value = this.editData[form.key];
                  form.selected = form.value;
                  form.showImage = false;
                  form.editable = false;
                  obj.formGroup.controls[form.key].value = this.editData[form.key];
                  obj.formGroup.controls[form.key].updateValueAndValidity();
                }
                return true;
              } else if (i == 0){
                return true;
              } else {
                return false;
              }
            }
          });
        } else if (objIndex === 3) {
          obj.formInputs.every(form => {
            if (form.key === 'knowDoctor') {
              form.value = this.editData[form.key];
              form.selected = form.value;
              form.showImage = false;
              form.showontoggle = false;
              obj.formGroup.controls[form.key].value = this.editData[form.key];
              obj.formGroup.controls[form.key].updateValueAndValidity();
              return false;
            }
            return true;
          });
          return false;
        }
        return true;
      });
      this.showForm = true;
      this.showScreen = true;
      this.executeAfterLoad();
    });
  }

  /**
   * 
   * Fetches patient details by user ID and processes the response.
   * 
   * If the response has point of contact email, append the same to therapistEmailList
   * Also append all allUserPatientEmails as well to therapistEmailList
   * 
   * If patient address state is not available then find the state name from statesList and append to stateList
   * 
   * If the response has data, invoke massageAddMedinceData method
   * 
   */

  getPatientDetails() {
    let payload = {
      type: 'GET',
      url: 'getOnePatient',
      isDeveloper: true,
      pathVariables: [this.userIdNumber, this.userDetails.userId]
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      this.observableHelperService.isLoading.next(true);
      if (this.http.isSuccessfulResponse(res) && res) {
        this.formService.therapistEmailList = NEWSTRESS_EMAIL_IDS;
        if (res.data.pointofContact && res.data.pointofContact.email) {
        this.formService.therapistEmailList = [...this.formService.therapistEmailList, res.data.pointofContact.email];
        }
        this.formService.therapistEmailList = [...this.formService.therapistEmailList, ...res.data.allUserPatientEmails];
        delete res.data.pointofContact;
        this.editCopy = this.massageEditCopy(res.data);
        this.editData = this.massageEditData(res.data);
        let state = this.stateList.find((ele: any) =>  ele.value == this.editCopy.patientBasicInfo.streetAddress.state )
        if(!state){
          let statename = this.formService.statesList.find((ele: any) => ele.value == this.editCopy.patientBasicInfo.streetAddress.state)
          this.stateList.push({value: res.data.patientBasicInfo.streetAddress.state, label: statename.label})
        }
        if (this.editData) {
          if (this.editData) {
            this.massageAddMedinceData();
          }
          try {
            this.showForm = false;
            this.form = this.editAndViewDataFn(this.form);
            console.log(this.form[0]);
            console.log(this.form, 'edit Data');
          } catch (err) {
            // console.log(err, 'Error');
          }
          setTimeout(() => {
            this.showForm = true;
            this.showScreen = true;
            this.observableHelperService.isLoading.next(false);
            this.executeAfterLoad();
          }, 0);
          this.focusFirstFieldInSafari();
          this.stepDetails.forEach(each => each.visited = true);
        }
      } else {
        let message = 'Failed to fetch patient data. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
        // If patient is deleted we need to redirect to home page
        if (message.includes('not found!')) {
          this.router.navigate(['myaccount']);
        }
      }
    });
  }


  /**
   * If the input data is not a valid object, the method returns undefined.
   * 
   * @param data patient data object
   * @returns An object with selected patient data fields, or undefined if the input is not a valid object.
   */
  massageEditCopy(data) {
    if (data && typeof (data) === 'object') {
      return {
        userId: data.userId,
        patientBasicInfo: data.patientBasicInfo,
        insuranceDetails: data.insuranceDetails,
        medicalHistory: data.medicalHistory,
        patientMiscInfo: data.patientMiscInfo,
        submitInfo: data.submitInfo
      };
    }

  }
  focusFirstFieldInSafari() {
  }
  /**
   * Processes and formats various fields in the `editData` object.
   * 
   * Converts date fields (`dob`, `policyHolderDOB`, `lastTherapyDate`, `patientHospitalizedDate`) to a standardized format.
   * 
   * Sets default values for undefined or empty fields.
   */

  massageAddMedinceData() {
    this.editData['dob'] = (this.editData['dob'] !== '') ? this.calendarDateFormate(this.editData['dob']) : '';
    this.editData['policyHolderDOB'] = (this.editData['policyHolderDOB'] !== '') ? this.calendarDateFormate(this.editData['policyHolderDOB']) : '';
    this.editData['lastTherapyDate'] = (this.editData['lastTherapyDate'] !== '') ? this.calendarDateFormate(this.editData['lastTherapyDate']) : '';
    this.editData['psychiatricSlider'] = !this.editData['psychiatric']['flag'],
      this.editData['medications'] = this.editData['psychiatric']['medications'],
      this.editData['benzodiazepinesSlider'] = !this.editData['benzodiazepines']['flag'],
      this.editData['benzodiazepines'] = this.editData['benzodiazepines']['medications'],
      this.editData['medicaladderallstimulants'] = !this.editData['medicalAdderallStimulants']['flag'],
      this.editData['medicaladderal'] = this.editData['medicalAdderallStimulants']['medications'],
      this.editData['disabilityBenefits'] = !this.editData['disabilityBenefits'],
      this.editData['drugAlcoholAbuse'] = !this.editData['drugAlcoholAbuse'],
      this.editData['alcoholDrugIn3Months'] = !this.editData['alcoholDrugIn3Months'],
      this.editData['selfMedicate'] = !this.editData['selfMedicate'],
      this.editData['legalIssues'] = !this.editData['legalIssues'],
      this.editData['inTherapy'] = !this.editData['inTherapy'],
      this.editData['isTherapistEmailId'] = !this.editData['isTherapistEmailId'],
      this.editData['miscellaenousKnownAllergies'] = this.editData['miscKnownAllergies1'],

      this.editData['anyMedicalDisorders'] = this.editData['anyMedicalDisorders'] !== undefined ? !this.editData['anyMedicalDisorders'] : false,
      this.editData['anyMedicalDisordersExplain'] = this.editData['anyMedicalDisordersExplain'] ? this.editData['anyMedicalDisordersExplain'] : '' ,
      this.editData['pastHistoryofAlchohol'] = this.editData['pastHistoryofAlchohol'] !== undefined ? !this.editData['pastHistoryofAlchohol'] : false,
      this.editData['pastHistoryofAlchoholExplain'] = this.editData['pastHistoryofAlchoholExplain'] ? this.editData['pastHistoryofAlchoholExplain']:'',
      this.editData['nonPsychMeds'] = this.editData['nonPsychMeds'] !== undefined ? !this.editData['nonPsychMeds'] : false,
      this.editData['nonPsychMedsExplain'] = this.editData['nonPsychMedsExplain'] ?  this.editData['nonPsychMedsExplain'] : '',
      this.editData['anyOpiods'] = this.editData['anyOpiods'] !== undefined ? !this.editData['anyOpiods'] : false,
      this.editData['anyOpiodsExplain'] = this.editData['anyOpiodsExplain'] ? this.editData['anyOpiodsExplain'] : '',
      this.editData['patientHospitalized'] = this.editData['patientHospitalized'] !== undefined ? !this.editData['patientHospitalized'] : false,
      this.editData['patientHospitalizedReason'] = this.editData['patientHospitalizedReason'] ? this.editData['patientHospitalizedReason'] : '',
      this.editData['nameOfHospital'] = this.editData['nameOfHospital'] ? this.editData['nameOfHospital'] : '',
      this.editData['patientHospitalizedDate'] =this.editData['patientHospitalizedDate'] ? this.calendarDateFormate(this.editData['patientHospitalizedDate']) : '',
      this.editData['previousPrescriber'] = this.editData['previousPrescriber'] !== undefined ? !this.editData['previousPrescriber'] : false,
      this.editData['prescriberName'] = this.editData['prescriberName'] ? this.editData['prescriberName'] : '',
      this.editData['prescriberAddress'] = this.editData['prescriberAddress'] ? this.editData['prescriberAddress'] : '',
      this.editData['prescriberEmail'] = this.editData['prescriberEmail'] ? this.editData['prescriberEmail'] : '',
      this.editData['prescriberPhone'] = this.editData['prescriberPhone'] ? this.editData['prescriberPhone'] : '',
      this.editData['sendTherapist'] = this.editData['patientHaveTherapistNotes'] === false ? true : false, 
      this.editData['sendPrescriber'] = this.editData['patientHavePrescriberNotes'] === false ? true : false, 
      this.editData['patientHaveTherapistNotes'] = !this.editData['inTherapy'] ? (this.editData['patientHaveTherapistNotes'] !== undefined ? !this.editData['patientHaveTherapistNotes'] : false) : false,
      this.editData['patientHavePrescriberNotes'] = !this.editData['previousPrescriber'] ? (this.editData['patientHavePrescriberNotes'] !== undefined ? !this.editData['patientHavePrescriberNotes'] : false) : false,
      this.editData['prescriberDocs'] = this.editData['prescriberDocs'] ? this.editData['prescriberDocs'] : [],
      this.editData['therapistDocs'] = this.editData['therapistDocs'] ? this.editData['therapistDocs'] : [], 

      // tslint:disable-next-line: radix
      this.editData['primaryInsuranceProvider'] = (this.editData['primaryInsuranceProvider'] === 'NaN' || this.editData['primaryInsuranceProvider'] === 'undefined') ? '' : parseInt(this.editData['primaryInsuranceProvider']),
      // tslint:disable-next-line: radix
      this.editData['secondaryInsuranceProvider'] = (this.editData['secondaryInsuranceProvider'] === 'NaN' || this.editData['secondaryInsuranceProvider'] === 'undefined') ? '' : parseInt(this.editData['secondaryInsuranceProvider']);
  }
  /**
   * Edits patient data, excluding psychiatric, benzodiazepines, and medicalAdderallStimulants details.
   * 
   * Handling for 'pharmacyAddress' and 'height' fields.
   */
  massageEditData(data) {
    let editData = {};
    for (let key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        let value = data[key];
        if (typeof (value) === 'object') {
          for (let innerkey in value) {
            if (Object.prototype.hasOwnProperty.call(value, innerkey)) {
              let innerValue = value[innerkey];
              // console.log('innerkey', innerkey);
              if ((typeof (innerValue) === 'object') && !(innerkey === 'psychiatric' || innerkey === 'benzodiazepines' || innerkey === 'medicalAdderallStimulants')) {
                if (innerkey === 'pharmacyAddress') {
                  editData['pharmacyAddress1'] = innerValue['streetAddress1'],
                    editData['pharmacyAddress2'] = innerValue['streetAddress2'],
                    editData['pharmacyCity'] = innerValue['city'],
                    editData['pharmacyState'] = innerValue['state'],
                    editData['pharmacyPhoneNo'] = innerValue['phoneNumber'],
                    editData['pharmacyZipcode'] = innerValue['zipCode'];
                } else {
                  for (let inner2Key in innerValue) {
                    if (Object.prototype.hasOwnProperty.call(innerValue, inner2Key)) {
                      editData[inner2Key] = innerValue[inner2Key];
                    }
                  }
                }
                if(innerkey === INTAKE_FORM_KEYS.THERAPIST_DOCS || innerkey === INTAKE_FORM_KEYS.PRESCRIBER_DOCS || innerkey === INTAKE_FORM_KEYS.LAB_WORKS_DOCS){
                  editData[innerkey] = innerValue;
                }
              } else if(innerkey == 'height') {
                var height = innerValue.split("'");
                editData['heightFt'] = height[0];
                editData['heightInch'] = height[1];
              } else {
                editData[innerkey] = innerValue;
              }
            }
          }
        } else {
          editData[key] = value;
        }
      }
    }
    console.log('massed Edit data', editData);
    return editData;
  }
  calendarDateFormate(date) {
    if(date){
      let dates = date.split('-');
      return dates[1] + '/' + dates[2] + '/' + dates[0];
    } else {
      return '';
    }
   
  }
  ngAfterContentChecked() {
    this.getFormElements();
    let stepPosition = document.querySelectorAll('.cell-container');
    stepPosition.forEach((element) => {
      this.stepindicatorArray.push(element.getBoundingClientRect());
    });
    window.scrollTo(0, 0);
  }

/**
 * Fetch the patients insurence and pharmacy details from the reference API.
 * 
 * Retrieves details based on keys such as `primaryInsuranceProvider`, `secondaryInsuranceProvider`, and `pharmacyName`. 
 * 
 */
  getProvidersAndPharmacyDetails() {
    let payload = {
      type: 'GET',
      url: 'reference',
      isDeveloper: true,
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res) && res) {
        // console.log(res);
        this.massageProvidersAndPharmacyDetails(res.data);
        this.formService.newUserInsuranceDetailFormConfig.forEach(each => {
          if (each.key === 'primaryInsuranceProvider') {
            each.list = this.providers.primaryInsurance;
          } else if (each.key === 'secondaryInsuranceProvider') {
            each.list = this.providers.secondaryInsurance.reverse(); // here the secondary insurance list is reversed 
          }
        });
        this.formService.newUserMiscellaenousFormConfig.forEach(each => {
          if (each.key === 'pharmacyName') {
            each.list = this.pharmacy;
          }
        });
      } else {
        let message = 'Failed to fetch an insurance data. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
      }
      if (this.userIdNumber && !(this.toModify || this.modifyProfile)) {
        this.getPatientDetails();
      } else if (this.toModify || this.modifyProfile) {
        this.getOldFieldsData();
      } else {
        this.showScreen = true;
        this.showForm = true;
      }
    });
  }

  /**
   * Get states list.
   */
  getStateList(){
    let payload = {
      type: 'GET',
      url: 'states',
      isDeveloper: true,
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res) && res) {
        this.stateList = res.data;
        let config = ''
        config = 'newUserBasicInformationFormConfig';
        
        // this.formService[config].find(each => {
        //   if (each.key === 'state') {
        //     each.list = this.stateList;
        //   }
        // });

      // Finds the object with key 'state' in formService[config] and updates its list property with stateList if found.
        const stateItem = this.formService[config].find(each => each.key === 'state');
          if (stateItem) {
            stateItem.list = this.stateList;
          }

      } else {
        let message = TOAST_MESSAGES.STATES_FAIL;
        message = (res.error) ? res.error.message : message;
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
  
  focusInput(event) {
    let isMobile = window.innerWidth < 1024 ? true : false;
    if (event && isMobile) {
      let inputField = event.target;
      if (document.querySelectorAll('.safari').length !== 0) {
        inputField.scrollIntoView();
        let container = document.getElementById('app__component--id');
        let newPatientContainer: any = document.querySelectorAll('.btn-actions')[0];
        newPatientContainer.style.paddingBottom = '270px';
      }
      // console.log('focusEvent', event);
    } else {
      // console.log('focusEvent', event);
    }
  }
  focusOutInput(event) {
    let isMobile = window.innerWidth < 1024 ? true : false;
    if (event && isMobile) {
      let inputField = event.target;
      if (document.querySelectorAll('.safari').length !== 0) {
        let container = document.getElementById('app__component--id');
        let newPatientContainer: any = document.querySelectorAll('.btn-actions')[0];
        newPatientContainer.style.paddingBottom = '50px';
      }
    }
  }
  setPlatformInfo() {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') !== -1) {
      if (ua.indexOf('chrome') > -1) {
        document.body.classList.add('chrome');
      } else {
        document.body.classList.add('safari');
      }
    }
  }
  formatDate(date) {
    if (!date || date === '' || date === null || date === undefined) {
      return '';
    } else {
      var d = new Date(date);
      var month = '' + (d.getMonth() + 1);
      var day = '' + d.getDate();
      var year = d.getFullYear();

      if (month.length < 2) {
        month = '0' + month;
      }
      if (day.length < 2) {
        day = '0' + day;
      }

      return [year, month, day].join('-');
    }
  }

  massageProvidersAndPharmacyDetails(data) {
    this.pharmacy = data.pharmacy.map(each => {
      return {
        ...each,
        label: each.pharmacyName,
        value: each.pharmacyName.toLowerCase(),
      };
    });
    let primary = [];
    let secondary = [];
    data.insurance.forEach(insurance => {
      let dropDown = {
        ...insurance,
        label: insurance.insuranceName,
        value: insurance.insuranceId,
      };
      if (insurance.primaryFlag === 1) {
        primary.push(dropDown);
      }
      if (insurance.secondaryFlag === 1) {
        secondary.push(dropDown);
      }
    });

    this.providers = {
      primaryInsurance: primary,
      secondaryInsurance: secondary,
    };
    // console.log(this.providers);
  }
  getFormElements() {
    let patientFormElementArray = document.querySelectorAll('.formdata');
    this.formPosArray = [];
    this.elementsHeight = [];
    patientFormElementArray.forEach((element: any) => {
      this.formPosArray.push(element.getBoundingClientRect());
    });
    let sum = 0;
    this.formPosArray.forEach(element => {
      sum = sum + element.height;
      this.elementsHeight.push(sum);
    });
  }
  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    let scrollTop = event.target.scrollTop;

    if (scrollTop >= 0 && scrollTop <= this.elementsHeight[0]) {
      this.setStepindicator(0);
    } else if (scrollTop > this.elementsHeight[0] && scrollTop <= this.elementsHeight[1]) {
      this.setStepindicator(1);
    } else if (scrollTop > this.elementsHeight[1] && scrollTop <= this.elementsHeight[2]) {
      this.setStepindicator(2);
    } else if (scrollTop > this.elementsHeight[2] && scrollTop <= this.elementsHeight[3]) {
      this.setStepindicator(3);
    } else if (scrollTop > this.elementsHeight[3] && scrollTop <= this.elementsHeight[4]) {
      this.setStepindicator(4);
    }
  }

  viewFullForm() {
    this.isCVSChange = false;
    this.isInsuranceChange = false;
    this.stepDetails = this.copyStepDetails;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobile = window.innerWidth < 1024 ? true : false;
  }
  sliderValue(event) {
    this.getFormElements();
    // console.log(this.formPosArray);
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    this.resetFormValues();
    document.body.style.overflow = 'scroll';
    document.querySelector('html').style.overflow = 'auto';
    /* component refresh */
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }

  setStepindicator(index: number): void {
    this.resetOnpageIndicator(index);
    var stepContainer = document.querySelector('.step__indicator');
    if (index === 0) {
      stepContainer.scrollTop = 0;
    } else {
      stepContainer.scrollTop = this.elementsHeight[index - 1];
    }
  }

/**
 * Handles the step indicator click event in the patient form.
 * Marks the clicked step as the current one and updates the visited states of previous steps.
 * Scrolls the form container to the corresponding step based on the index.
 * For non-mobile views, adjusts the scroll position of the `.patient-form` container to ensure
 * the selected step is in view.
 * @param index - The index of the step that was clicked.
 */
  stepIndicatorClicked(index: number): void {
    if (typeof(index) === 'number' && index < this.stepDetails.length) {
      this.stepDetails.forEach((element, eIndex) => {
        element.onPage = false;
        if (eIndex <= index) {
          element.visited = true;
        }
      });
      this.stepDetails[index].onPage = true;
      if (!this.isMobile) {
        var formContainer = document.querySelector('.patient-form');
        if (index === 0) {
          formContainer.scrollTop = 0;
        } else {
          formContainer.scrollTop = this.elementsHeight[index - 1] + 1;
        }
      }

    }

    // console.log(this.stepDetails);
  }

  resetOnpageIndicator(index) {
    this.stepDetails.forEach((element, ind) => {
      element.onPage = false;
      this.getFormInfo[element.id] = false;
    });
    if (typeof(index) === 'number' && index < this.stepDetails.length) {
      this.stepDetails[index].onPage = true;
      this.stepDetails[index].notVisisted = false;
      this.stepDetails[index].visited = true;
      this.getFormInfo[this.stepDetails[index].id] = true;
    }
  }

  inValidSubmitEvent(event, index) {
    // console.log(event, 'invalid Form');
    if (this.stepDetails[index] && this.stepDetails[index].completed) {
      this.stepDetails[index].completed = false;
    };
    this.viewFullForm();
  }

  getFormData(event, index) {
    // console.log(event, index);
    if (event && this.stepDetails[index] && !this.stepDetails[index].completed) {
      this.scrollTop();
      this.form[index].formGroup = event;
      this.stepDetails[index].completed = true;
      // console.log(this.form);
      this.focusFirstFieldInSafari();
    }
  }
  massageFormData(forms) {
    let uploadKeys = ['idProofUploadImage1', 'idProofUploadImage2', 'policyHoldersUploadFront', 'policyHoldersUploadBack', 'secondaryPolicyHoldersUploadFront', 'secondaryPolicyHoldersUploadBack'];
    this.form.forEach(element => {
      let values = element.formGroup.value;
      let inputValues = element.formInputs;
      for (let key in values) {
        if (uploadKeys.indexOf(key) === -1) {
          this.formData[key] = values[key];
        } else {
          let value;
          inputValues.some(field => {
            if (field.key === key) {
              value = field.value;
              return true;
            }
          });
          this.formData[key] = value;
        }

      }
    });
    // console.log('Form Data', this.formData);
    this.finalFormData = {
      userId: this.userDetails.userId,
      patientBasicInfo: {
        firstName: this.accentedtoNormalFun(this.formData.firstName),
        lastName: this.accentedtoNormalFun(this.formData.lastName),
        socialSecurityNumber: this.formData.socialSecurityNumber,
        dob: this.formatDate(this.formData.dob),
        gender: this.formData.gender,
        userRelationToPatient: this.formData.userRelationToPatient,
        email: this.formData.email,
        emergencyContactName: this.formData.emergencyContactName,
        emergencyContactNumber: this.formData.emergencyContactNumber,
        relationWithPatient: this.formData.relationWithPatient,
        emergencyContactAddress: this.formData.emergencyContactAddress,
        idProofUploadImage1: this.formData.idProofUploadImage1,
        idProofUploadImage2: this.formData.idProofUploadImage2,
        phoneNumber: this.formData.phoneNumber,
        streetAddress: {
          streetAddress1: this.formData.streetAddress1,
          streetAddress2: this.formData.streetAddress2,
          city: this.formData.city,
          zipCode: this.formData.zipCode,
          phoneNumber: this.formData.phoneNumber,
          state: this.formData.state
        },
        weight: this.formData.weight,
        height: this.formData.heightFt+ '\'' + this.formData.heightInch,
      },
      insuranceDetails: {
        sameAsPatient: this.formData.sameAsPatient != "" && this.formData.sameAsPatient != undefined ? this.formData.sameAsPatient : false,
        primaryInsuranceProvider: `${this.formData.primaryInsuranceProvider}`,
        secondaryInsuranceProvider: `${this.formData.secondaryInsuranceProvider}`,
        primaryHolderText: this.formData.primaryHolderText,
        secondaryHolderText: this.formData.secondaryHolderText,
        policyHolder:
        {
          policyHolderName: this.formData.policyHolderName,
          policyHolderDOB: this.formatDate(this.formData.policyHolderDOB),
          policyHolderMemberId: this.formData.policyHolderMemberId,
          secondaryPolicyHolderMemberId: this.formData.secondaryPolicyHolderMemberId,
          policyHoldersUploadFront: this.formData.policyHoldersUploadFront,
          policyHoldersUploadBack: this.formData.policyHoldersUploadBack,
          secondaryPolicyHoldersUploadFront: this.formData.secondaryPolicyHoldersUploadFront,
          secondaryPolicyHoldersUploadBack: this.formData.secondaryPolicyHoldersUploadBack,
          policyHolderGroupNumber: this.formData.policyHolderGroupNumber,
          policyHolderMentalHealthNumber: this.formData.policyHolderMentalHealthNumber,
          policyHolderEmployer: this.formData.policyHolderEmployer,
        }
      },
      medicalHistory: {
        inTherapy: !this.formData.inTherapy,
        isTherapistEmailId:!this.formData.inTherapy ? !this.formData.isTherapistEmailId: false,
        therapistName: !this.formData.inTherapy ? this.formData.therapistName : '',
        lastTherapyDate: !this.formData.inTherapy ?  this.formatDate(this.formData.lastTherapyDate) : '',
        therapistMail: !this.formData.inTherapy && !this.formData.isTherapistEmailId ? this.formData.therapistMail : '',
        patientHaveTherapistNotes: !this.formData.inTherapy ? !this.formData.patientHaveTherapistNotes : false,
        therapistDocs: !this.formData.patientHaveTherapistNotes ? ((this.formData.therapistDocs != "" && this.formData.therapistDocs != null) ? this.formData.therapistDocs : []) : [],
        psychiatric: {
          flag: !this.formData.psychiatricSlider,
          medications: (!this.formData.psychiatricSlider) ? this.formArrayCalendarMassage(this.formData.medications) : null
        },
        benzodiazepines: {
          flag: !this.formData.benzodiazepinesSlider,
          medications: (!this.formData.benzodiazepinesSlider) ? this.formArrayCalendarMassage(this.formData.benzodiazepines) : null,
        },
        medicalAdderallStimulants: {
          flag: !this.formData.medicaladderallstimulants,
          medications: (!this.formData.medicaladderallstimulants) ? this.formArrayCalendarMassage(this.formData.medicaladderal) : null
        },
        LabWorkDocsUpload: (this.formData.LabWorkDocsUpload) ? this.formData.LabWorkDocsUpload : [],
        knownAllergies: this.formData.knownAllergies,
        disabilityBenefits: !this.formData.disabilityBenefits,
        drugAlcoholAbuse: !this.formData.drugAlcoholAbuse,
        alcoholDrugIn3Months: !this.formData.alcoholDrugIn3Months,
        selfMedicate: !this.formData.selfMedicate,
        medicalKnownAllergies: this.formData.medicalKnownAllergies,

        anyMedicalDisorders: !this.formData.anyMedicalDisorders,
        anyMedicalDisordersExplain: this.formData.anyMedicalDisordersExplain,
        pastHistoryofAlchohol: !this.formData.pastHistoryofAlchohol,
        pastHistoryofAlchoholExplain: this.formData.pastHistoryofAlchoholExplain,
        nonPsychMeds: !this.formData.nonPsychMeds,
        nonPsychMedsExplain: this.formData.nonPsychMedsExplain,
        anyOpiods: !this.formData.anyOpiods,
        anyOpiodsExplain: this.formData.anyOpiodsExplain,
        patientHospitalized: !this.formData.patientHospitalized,
        patientHospitalizedReason: this.formData.patientHospitalizedReason,
        nameOfHospital: this.formData.nameOfHospital,
        patientHospitalizedDate: this.formatDate(this.formData.patientHospitalizedDate),
        previousPrescriber: !this.formData.previousPrescriber,
        prescriberName: !this.formData.previousPrescriber ? this.formData.prescriberName : '',
        prescriberAddress: !this.formData.previousPrescriber ? this.formData.prescriberAddress : '',
        prescriberEmail: !this.formData.previousPrescriber ? this.formData.prescriberEmail : '',
        prescriberPhone: !this.formData.previousPrescriber ? this.formData.prescriberPhone : '',
        patientHavePrescriberNotes: !this.formData.previousPrescriber ? !this.formData.patientHavePrescriberNotes : false,
        prescriberDocs: !this.formData.patientHavePrescriberNotes ? ((this.formData.prescriberDocs !="" && this.formData.prescriberDocs != null) ? this.formData.prescriberDocs :[]) : [],
      },
      patientMiscInfo: {
        legalIssues: !this.formData.legalIssues,
        pharmacyAddress: {
          streetAddress1: this.formData.pharmacyAddress1,
          streetAddress2: this.formData.pharmacyAddress2,
          city: this.formData.pharmacyCity,
          state: this.formData.pharmacyState,
          phoneNumber: this.formData.pharmacyPhoneNo,
          zipCode: this.formData.pharmacyZipcode
        },
        pharmacyData: {
          pharmacyName: this.formData.pharmacyName,
          pharmacyNameText: this.formData.pharmacyNameText,
          pharmacyEmailId: this.formData.pharmacyEmailId,
          pharmacyFaxNo: this.formData.pharmacyFaxNo,
        },
        miscKnownAllergies1: this.formData.miscellaenousKnownAllergies,
        knowDoctor: this.formData.knowDoctor,
      },
      submitInfo: {
        iAcknowledge: this.formData.iAcknowledge,
        iAccept: this.formData.iAccept,
        patientSign: this.formData.patientSign ? this.formData.patientSign : ''
      }
    };
  }

  formArrayCalendarMassage(data) {
    if (data && Array.isArray(data)) {
      data.forEach(each => {
        for (const key in each) {
          if (Object.prototype.hasOwnProperty.call(each, key)) {
            if (key === 'prescriptionStartDate' || key === 'prescriptionEndDate' || key === 'lastPrescriptionDate') {
              each[key] = this.formatDate(each[key]);
            }
          }
        }
      });
      return data;
    }
  }

  resetFormValues() {
    this.formTags.forEach((element, index) => {
      this.formService[this.formService.formTypes[element].config] = this.formBackUp[index];
    });
  }
  navigateBack(event) {
    if (event) {
      this.resetFormValues();
      this.router.navigate(['myaccount']);
    }
  }
  prevForm() {
    if (this.currentFormIndex === 0) {
      this.scrollTop();
      this.stepDetails[this.currentFormIndex].onPage = false;
      this.currentFormIndex = 0;
      this.setCurrentForm(this.currentFormIndex);
      this.focusFirstFieldInSafari();
    } else {
      this.stepDetails[this.currentFormIndex].onPage = false;
      this.currentFormIndex--;
      this.scrollTop();
      this.setCurrentForm(this.currentFormIndex);
      this.focusFirstFieldInSafari();
    }
  }
  setCurrentForm(index) {
    this.stepDetails[index].completed = false;
    this.stepDetails[index].onPage = true;
  }
  scrollTop() {
    if (document.getElementsByClassName('new-pateint__section')[0]) {
      document.getElementsByClassName('new-pateint__section')[0].scrollTo(0, 0);
    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  forwardClicked() {
    this.saveForm();
  }
  backClicked() {
    this.prevForm();
  }
  saveForm() {

    // console.log(this.stepDetails);

    if (!this.isMobile) {
      this.saveFormData = true;
    } else {
      this.saveFormData = true;
      if (this.currentFormIndex === (this.stepDetails.length - 1)) {
        this.scrollTop();
        this.focusFirstFieldInSafari();
      } else {
        window.setTimeout(() => {
          if (this.stepDetails[this.currentFormIndex].completed || window.location.href.split('&')[1] === 'view=true') {
            this.currentFormIndex++;
            this.stepDetails[this.currentFormIndex].onPage = true;
            this.stepDetails[this.currentFormIndex].notVisisted = false;
            this.stepDetails[this.currentFormIndex].visited = true;
            this.scrollTop();
            this.focusFirstFieldInSafari();
          }
        }, 5);
      }
    }
    setTimeout(() => {
      if (document.getElementsByClassName('invalid-feedback')[0]) {
        document.getElementsByClassName('invalid-feedback')[0].scrollIntoView({ block: 'center' });
      }
    }, 2);

    setTimeout(() => {
      var isBelowThreshold = (currentValue) => currentValue.completed === true;
      if (this.stepDetails.every(isBelowThreshold)) {
        // console.log(this.form);
        this.massageFormData(this.form);
        // console.log('final Body', this.finalFormData);
        this.uploadFiles(() => {
          if (this.isEditAndView) {
            if (JSON.stringify(this.finalFormData) === JSON.stringify(this.editCopy)) {
              this.cancelPopupShowHide = true;
            } else {
              this.createPatient();
            }
          } else {
            this.createPatient();
          }
        });
      }
    }, 10);
  }

  uploadFiles(callback) {
    let uploadFiles = {
      // LabWorkDocsUpload: this.finalFormData.medicalHistory.LabWorkDocsUpload,
      idProofUploadImage1: this.finalFormData.patientBasicInfo.idProofUploadImage1,
      idProofUploadImage2: this.finalFormData.patientBasicInfo.idProofUploadImage2,
      policyHoldersUploadFront: this.finalFormData.insuranceDetails.policyHolder.policyHoldersUploadFront,
      policyHoldersUploadBack: this.finalFormData.insuranceDetails.policyHolder.policyHoldersUploadBack,
      secondaryPolicyHoldersUploadFront: this.finalFormData.insuranceDetails.policyHolder.secondaryPolicyHoldersUploadFront,
      secondaryPolicyHoldersUploadBack: this.finalFormData.insuranceDetails.policyHolder.secondaryPolicyHoldersUploadBack
    };
    Object.keys(uploadFiles).forEach(key => {
      if (uploadFiles[key] && uploadFiles[key].name) {

      } else {
        delete uploadFiles[key];
      }
    });
    let fileNames = Object.keys(uploadFiles).map(key => {
      let type;
      if (!uploadFiles[key].type) {
        type = uploadFiles[key].name.split('.').pop().toLowerCase();
      }
      return { key, contentType: uploadFiles[key].type || type, name: uploadFiles[key].name };
    });
    if (fileNames && fileNames.length) {
      this.observableHelperService.toggleThrobber(true);

      let getUploadUrls = {
        type: 'POST',
        url: 'getUploadUrl',
        isDeveloper: true,
        donotShowLoader: true,
        body: {
          fileNames,
          userId: this.userDetails.userId,
          userName: 'temp-new-tele-doc/' + this.accentedtoNormalFun(this.userDetails.firstName) + this.accentedtoNormalFun(this.userDetails.lastName),
          patientName: this.accentedtoNormalFun(this.finalFormData.patientBasicInfo.firstName) + this.accentedtoNormalFun(this.finalFormData.patientBasicInfo.lastName) + '__' + this.finalFormData.patientBasicInfo.dob
        },
      };

      this.http.makeHttpRequest(getUploadUrls).subscribe(res => {
        let filesUploaded = 0;

        Object.keys(res).forEach(key => {
          let data;
          if (key === 'LabWorkDocsUpload') {
            data = this.finalFormData.medicalHistory.LabWorkDocsUpload;
          } else if (key === 'idProofUploadImage1' || key === 'idProofUploadImage2') {
            data = this.finalFormData.patientBasicInfo[key];
          } else {
            data = this.finalFormData.insuranceDetails.policyHolder[key];
          }
          if (data && data.name) {
            let uploadPayload = {

              type: 'PUT',
              url: res[key],
              body: data,
              isExternal: true,
              donotShowLoader: true,
              contentType: data.type || data.name.split('.').pop().toLowerCase()
            };
            this.http.makeHttpRequest(uploadPayload).subscribe(response => {
              if (response && response.status === false) {


                this.form.forEach(formSet => {
                  formSet.formInputs.forEach(fieldEle => {
                    if (key === fieldEle.key) {
                      let message = 'Failed to upload ' + fieldEle.readableLabel + '. Please try again';
                      this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
                      fieldEle.value = '';
                      formSet.formGroup.controls[fieldEle.key].value = '';
                      fieldEle.showImage = true;
                      return false;
                    }
                  });
                });

              } else {
                if (key === 'LabWorkDocsUpload') {
                  this.finalFormData.medicalHistory.LabWorkDocsUpload = res[key];
                } else if (key === 'idProofUploadImage1' || key === 'idProofUploadImage2') {
                  this.finalFormData.patientBasicInfo[key] = res[key];
                } else {
                  this.finalFormData.insuranceDetails.policyHolder[key] = res[key];
                }
                filesUploaded++;

                if (filesUploaded === fileNames.length) {
                  callback();
                }
              }


            });
          }
        });
      });
    } else {

      callback();
    }
  }

  getActionText() {
    if (this.isMobile) {
      return this.currentFormIndex < (this.stepDetails.length - 1) ? 'Next' : 'Save';
    } else {
      return 'Save';
    }
  }

  resetSaveFormParity() {
    window.setTimeout(() => {
      this.saveFormData = false;
    }, 0);

    return true;
  }


  addNew(data) {
    // console.log(this.formPosArray, 'adding height', this.elementsHeight);
  }
  getStepIndex(index) {
    if (index > this.currentFormIndex) {

      this.saveFormData = true;
      this.saveForm();
    } else {
      this.scrollTop();
      this.focusFirstFieldInSafari();
      this.stepDetails[this.currentFormIndex].onPage = false;
      this.currentFormIndex = index;
      this.stepDetails.forEach((each, ind) => {
        if (ind >= this.currentFormIndex) {
          each.completed = false;
        }
      });
      this.stepDetails[index].onPage = true;
    }

  }
  getRadioButtonSelectValue(formData, value) {

    formData.fields.map((data, inedx) => {
      data.checked = false;
      data.checked = data.value === value;
    });
  }

  editAndViewDataFn(data) {
    // let nonEditableFields = ['firstName', 'lastName', 'dob', 'gender', 'emergencyContactName', 'relationWithPatient', 'emergencyContactNumber', 'socialSecurityNumber', 'knowDoctor', 'idProofUploadImage1', 'idProofUploadImage2', 'iAcknowledge', 'iAccept'];
    let nonEditableFields = ['firstName', 'lastName', 'dob', 'gender', 'socialSecurityNumber', 'knowDoctor', 'idProofUploadImage1', 'idProofUploadImage2', 'iAcknowledge', 'iAccept'];
    data.map((value, index) => {
      let inputs = value.formInputs;
      let editKeys = Object.keys(this.editData);
      inputs.forEach((element, id) => {
        if (element.controlType === 'formArray') {
          if (this.editData[element.key] && Array.isArray(this.editData[element.key])) {
            if (this.editData[element.key].length > 1) {
              for (let i = 0; i < this.editData[element.key].length - 1; i++) {
                this.childComponent.addNew(inputs[id + 1], inputs[id + 1].formtag);
              }
            }
            element.formInputs.forEach((innerElement, ind) => {
              innerElement.forEach((innerObj, innerInd) => {
                if (innerObj.type === 'textarea' && this.editData[element.key][ind][innerObj.key].length > 0) {
                  // console.log(this.editData[element.key][ind][innerObj.key]);
                  let valuedata = this.editData[element.key][ind][innerObj.key];
                  let valueLength = valuedata.split('').length;
                  if (valueLength > innerObj.maxLength) {
                    valuedata = valuedata.substring(0, innerObj.maxLength);
                    return false;
                  } else {
                    innerObj.count = `${innerObj.maxLength - valueLength} remaining`;
                  }
                }
                if (innerObj.type === 'calendar') {
                  if (this.editData[element.key][ind][innerObj.key] !== '') {
                    innerObj.value = this.calendarDateFormate(this.editData[element.key][ind][innerObj.key]);
                  } else {
                    innerObj.value = '';
                  }
                } else {
                  innerObj.value = this.editData[element.key][ind][innerObj.key];
                }
                innerObj.editable = !this.editable;
                if (innerObj.key === 'prescriptionEndDate') {
                  let radioValue = innerElement[innerInd - 2]['value'];
                  if (radioValue.toLowerCase().includes('current')) {
                    innerObj.editable = false;
                  }
                }
              });
            });
          }
        } else {
          if(element.key){
            element.value = this.editData[element.key] ? this.editData[element.key] : '';
            element.selected = element.value;
            element.showImage = false;
            element.editable = (nonEditableFields.includes(element.key) && (this.editData[element.key] !== '' && this.editData[element.key] !== false)) ? false : !this.editable;
          }
        }
        if (element.key === 'firstName' || element.key === 'lastName') {
          element.validators = [];
          element.validators = [Validators.required];
          value.formGroup.controls[element.key].clearValidators();
          value.formGroup.controls[element.key].setValidators([Validators.required]);
          value.formGroup.controls[element.key].updateValueAndValidity();
        }
        if (element.type === 'dropdown') {
          this.childComponent.getDropValue(element, id, inputs, value.formGroup);
        }
        if (element.type === 'textarea' && element.showRange && this.editData[element.key].length > 0) {
          // console.log(this.editData[element.key]);
          let valuedata = this.editData[element.key];
          let valueLength = valuedata.split('').length;
          // let countfield = <HTMLInputElement>document.getElementById(element.key)
          element.count = `${element.maxLength - valueLength} remaining`;
          // countfield.value = `${element.maxLength - valueLength} remaining`;
        }
        if (element.isToggle) {
          if (element.value) {
            this.childComponent.getSliderValue(element, id, inputs, value.formGroup);
          }
        }
      });
      value.formInputs = inputs;
      let controls = value.formGroup.controls;
      for (let formData in controls) {
        if (formData === 'medications' || formData === 'benzodiazepines' || formData === 'medicaladderal') {
          /*if (this.editData[formData] != null) {
            controls[formData].setValue(this.editData[formData]);
            controls[formData].patchValue(this.editData[formData]);
          }*/
          return null;
        } else {
          controls[formData].setValue(this.editData[formData]);
          controls[formData].patchValue(this.editData[formData]);
        }
      }
      value.formGroup.controls = controls;


      // console.log(controls, 'afterEdit', data, this.form);
      return { ...value };
    });
    return data;
  }

  /**
  * Creates or updates patient details.
  * @param form
  */
  createPatient() {
    if (this.fileUploadProgress <= 0) {
      let type: string;
      let url: string;
      if (this.isEditAndView || this.toModify || this.modifyProfile) {
        type = HTTPMethods.PUT;
        url = API_URLS.PATIENT_EDIT;
      } else {
        url = API_URLS.PATIENT_CREATE;
        type = HTTPMethods.POST;
      }
      let currentDate: Array<string> = new Date(convertDateToSpecifiedOffset(DEFAULT_TIME_ZONE_DIFF,new Date())).toLocaleString().split(', ');
      this.finalFormData.timestampDate = currentDate[0];
      this.finalFormData.timestamp = currentDate[1].substring(0, currentDate[1].length - 6) + currentDate[1].slice(-3);
      this.finalFormData.status = (this.isEditAndView || this.toModify || this.modifyProfile) ? this.editData.status : undefined;
      this.finalFormData['longTimeUpdate'] = (this.toModify || this.updateProfile) ? true : this.isEditAndView ? false : false;
      this.finalFormData['inActiveUpdate'] = (this.updateProfile || this.modifyProfile) ? true : this.isEditAndView ? false : false;
      let payload: Record<string, unknown> = {
        type,
        url,
        body: this.finalFormData,
        isDeveloper: true,
        pathVariables: [this.userIdNumber]
      };
      this.http.makeHttpRequest(payload).subscribe((res) => {
        if (this.http.isSuccessfulResponse(res) && res) {
          // console.log('response', res);
          this.resetFormValues();
          if (payload.type === 'POST') {
            this.observableHelperService.showToast(TOAST_STATUSES.SUCCESS, `Successfully ${(this.toModify ||this.modifyProfile) ? 'modified the Patient data' : 'created a Patient'}`);
          } else if (payload.type === 'PUT') {
            this.observableHelperService.showToast(TOAST_STATUSES.SUCCESS, 'Successfully updated the Patient data');
          }
          let data = this.observableHelperService.getAppointmentAddPatient();
          if (data.value) {
            this.dataService.patientDetails = res.data.patientId;
            this.observableHelperService.setAppointmentAddPatient(false);
            this.router.navigate(['appointment']);
          } else {
            this.router.navigate(['myaccount']);
          }
        } else {
          let message = 'Patient Creation Failed';
          if (url === 'ediPatient') {
            message = 'Failed to update Patient data';
          }
          message = (res.error) ? res.error.message : message;
          if(res.error && res.error.message){
            if(res.error.message.includes(INTAKE_FORM_KEYS.THERAPIST_EMAIL) && document.querySelectorAll('.temail__scroll')[0]){
              document.querySelectorAll('.temail__scroll')[0].scrollIntoView({ block: 'center' });
              if(res.error.message.includes(this.mailText)) message = TOAST_MESSAGES.INVALID_THERAPIST_MAIL
            }
            else if(res.error.message.includes(INTAKE_FORM_KEYS.PREV_PRESCRIBER_EMAIL) && document.querySelectorAll('.pemail__scroll')[0]){
              document.querySelectorAll('.pemail__scroll')[0].scrollIntoView({ block: 'center' });
              if(res.error.message.includes(this.mailText)) message = TOAST_MESSAGES.INVALID_PRESCRIBER_MAIL
            }
            else if (res.error && document.querySelectorAll('.email__scroll')[0]) {
              document.querySelectorAll('.email__scroll')[0].scrollIntoView({ block: 'center' });
            }
          }
          this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
        }
      });
    } else {
      this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.FILE_UPLOAD_INPROGRESS_MSG);
    }
  }

  cancelPopupShowHideStatusFun(status) {
    this.cancelPopupShowHide = status.cancelpopup;
  }

  closeConfirmTerms(event) {
    this.showConfirmTerms = false;
  }

  // after all are loaded
  executeAfterLoad() {
    setTimeout(() => {
      let invalidInput = this.elRef.nativeElement.querySelector('form .ng-invalid');
      if (invalidInput) {
        invalidInput.focus();
      }
      this.setPlatformInfo();
      document.addEventListener('DOMContentLoaded', () => {
        this.setPlatformInfo();
        var inputBox = document.querySelector('.safari #inputBox');
        if (inputBox) {
          inputBox.addEventListener('focus', (e) => {
            document.body.classList.add('keyboard');
            setTimeout(() => {
              window.scrollTo(0, 0);
            }, 200);
          });
          inputBox.addEventListener('blur', (e) => {
            document.body.classList.remove('keyboard');
          });
        }
      });
    }, 0);
  }
  /* for accented letter change convention function */
  accentedtoNormalFun(name) {
    let originalText = name;
    let result = this.getNormalizedText(originalText);
    return result;
  }

  /**
   * File upload start or end.
   * @param event file upload start or finish value - true/false
   */
  isFileUploadPending(event){
    if(event)
    this.fileUploadProgress += 1;
    else this.fileUploadProgress -= 1;
  }

  /**
   * on dropdown value change
   * @param field dropdown field.
   */
  onDropdownChange(field){
    if(field.key == INTAKE_FORM_KEYS.PHARMACY_STATE && !this.allowedPharmacyStates.includes(field.value)){
      this.showPharmacyStatePopup = true;
    }
  }

  /**
   * To hide/show the popup
   * @param status popup visibility
   */
  cancelPopup(status) {
    this.showPharmacyStatePopup = status.cancelpopup;
  }

  /**
   * To show uploaded doc list
   * @param docList 
   */
  showDocs(docList: Array<string>){
    this.showDocListPopup = true;
    this.docList = docList;
  }

  /**
   * To show/hide uploaded doc list
   * @param status 
   */
  cancelDocListPopup(status) {
    this.showDocListPopup = status.cancelpopup;
  }

  getNormalizedText(originalText: string){
    return originalText ? originalText.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : "";
  }

  getDefaultValue(inputElement: Record<string, any>){
    switch (inputElement.type) {
      case 'text':
        inputElement.value = '';
        break;
      case 'textarea':
        inputElement.value = '';
        break;
      case 'radio':
        inputElement.value = '';
        break;
      case 'calendar':
        inputElement.value = '';
        break;
      case 'checkbox-slider':
        inputElement.value = false;
        break;
      default:
        break;
    }
    return inputElement;
  }
}
