<div class="send-email__wrapper" *ngIf="permissionsDone">
  <div class="send-email__navigation-menu">
    <app-breadcrumb [breadCrumbData]="breadCrumbData"></app-breadcrumb>
  </div>
  <div class="email__template__form--wrapper">
    <!-- Add user section starts -->
    <div class="add-patient--wrapper">
      <div class="popup__heading">Add Patients</div>
      <div class="search-filter__block">
        <app-svg [name]="'search'" class="inside-search"></app-svg>
        <p-autoComplete
          field="name"
          [(ngModel)]="result"
          [suggestions]="emailSearchList"
          (onSelect)="selectedEmail($event)"
          emptyMessage="No data found."
          (completeMethod)="search($event)"
        >
          <ng-template let-result pTemplate="item">
            <div class="result-item">
              <div>{{ result.name }}</div>
            </div>
          </ng-template>
        </p-autoComplete>
      </div>
      <!-- list section starts -->
      <div class="mail__content__wrapper d-flex" *ngIf="addedEmailsList.length > 0">
        <div class="each-email-id" *ngFor="let emailId of addedEmailsList.slice(0, 3)">
          <span class="email-id">{{ emailId.name }}</span>
          <app-svg [name]="'cancel-appointment'" (click)="removeEmailId(emailId.id)"></app-svg>
        </div>
        <div *ngIf="addedEmailsList.length > 3" class="mail-content__more" (click)="moreUsersClicked()">
          +{{ addedEmailsList.length - 3 }} More
        </div>
      </div>
      <div class="empty-text" *ngIf="addedEmailsList.length == 0 && !formSubmitAttempt">No Patients added</div>
      <div class="error-msg" *ngIf="addedEmailsList.length==0 && formSubmitAttempt">Please select patients.</div>
      <!-- list section ends -->
    </div>
    <!-- Exclude user section ends -->

    <div class="popup__heading">Email Template</div>
    <div class="content__preview" [innerHTML]="templateDetails"></div>

     <!-- action field starts -->
     <div class="form__action">
      <div class="primary__button__wrapper">
          <button class="cancel-btn" (click)="cancel()" [type]="'submit'">
              Cancel
          </button>
          <div class="cancel__button__wrapper">
              <button class="common-btn-style" (click)="actionField($event)" [type]="'submit'">
                  Submit
              </button>
          </div>

      </div>
  </div>
  <!-- action field ends -->
  </div>
</div>

<app-common-popup (showHideCancelPopup)='cancelPopupShowHideStatusFun($event)' *ngIf="cancelPopupShowHide">
    <div class="popup__wrapper add-patient--wrapper">
        <div class="popup__heading">Selected Patients</div>
        <div class="mail__content__wrapper" *ngIf="addedEmailsList.length>0">
            <div class="each-email-id"
                *ngFor="let patient of addedEmailsList">
                <span class="email-id">{{patient.name}}</span>
                <app-svg [name]="'cancel-appointment'" (click)="removeEmailId(patient.id)"></app-svg>
            </div>
        </div>

    </div>
</app-common-popup>