import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-generate-invoice',
  templateUrl: './generate-invoice.component.html',
  styleUrls: ['./generate-invoice.component.scss']
})
export class GenerateInvoiceComponent implements OnInit {

  generateForm = this.invoice.group({
    copayAmt: ['', Validators.required],
    dueAmt: ['', Validators.required],
    comments: ['']
  });
  @Input() amountGenerated: any;
  @Input() isMarkasPaid;
  copayAmount: number;
  dueAmount: number;
  totalAmount: number = 0;
  formSubmitAttempt = false;
  totalZero = false;
  @Output() totalInvoiceAmount: EventEmitter<any> = new EventEmitter();
  constructor(private invoice: FormBuilder) { }

  ngOnInit() {
    console.log(this.isMarkasPaid,'testing')
  }

  // for restricting two decimals after period
  onKeyPressMethod(event) {
    let RE = /^(\d*\.?\d{0,1})$/;
    let value: any = event.target.value;
    console.log(RE.test(value));
    if (!RE.test(value)) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
  }

  // for adding total
  onKeyUpMethod() {
    this.totalZero = false;
    let total: any;
    total = (this.copayAmount ? this.copayAmount : 0) + (this.dueAmount ? this.dueAmount : 0);
    total = total.toFixed(2);
    this.totalAmount = total;
  }

  // Generate Invoice actions funtionalties
  actionField(event, btnType) {
    this.formSubmitAttempt = true;
    if (this.generateForm.valid && this.totalAmount > 0) {
      this.totalZero = false;
      this.totalInvoiceAmount.emit({ btnType, values: this.generateForm.value });
      // --reset input field values
      // this.generateForm.reset();
      this.formSubmitAttempt = false;
    } else if (this.generateForm.valid && this.totalAmount <= 0) {
      this.totalZero = true;
      console.log(this.generateForm);
    }
  }
}
