<ng-template #treatmentCard let-link='link'>
    <!-- treatment card icon start -->
    <div class="treatment__card-icon">
        <div class="icon__block {{link.className}}">
            <app-svg [name]="link.img" [imageLabel]="link.imageLabel"></app-svg>
        </div>
    </div>
    <!-- treatment card icon end -->
    <h3 class="treatment__card-heading">
        {{link.heading}}
    </h3>
    <div class="tc-card">
    <p class="treatment__card-description">
        {{link.description}}
        <!-- You will sit in front of a camera where you will be able to see yourself and our doctor on the screen once your session begins. These appointments are also called virtual visits. We ask you questions and work with you to develop a plan of care, providing the same quality of care you would get in a face-to-face visit. You can start your Virtual Visit using: A computer & webcam, or A smartphone or tablet (mobile device) -->
    </p>
    <div class="read-more">
        <span>Read More</span>
       <span class="knowMoreImg"> <img height="21px" width="12px" src="assets/images/svgs/knowMore.svg"></span>
        <!-- <app-svg [name]="knowMore"></app-svg> -->
    </div>
    </div>
</ng-template>

<!-- treatment cards block start -->
<div class="treatment__cards-block" >
     
         <!-- treatment card block start -->
    <div class="treatment__card-block {{card.cardColor}}" *ngFor="let card of trearmentCardDetails" (click)="navigateTreatmentPage(card.id)">
        <ng-container 
             [ngTemplateOutlet]="treatmentCard" 
             [ngTemplateOutletContext]="{link:card}">
        </ng-container>
    </div>
    <!-- treatment card block end -->
    <div class="know-more">
        <app-know-more [moreText]="moreText"  (actionTriggered)="navigateBlogPage()" [actionBased]="true"  [show]="true"></app-know-more>
    </div>
</div>

<!-- treatment cards block end -->

 <!--for responsive mode-->
 <div class="treatment__carousel">
     <ngx-slick-carousel class="carousel" [config]="slideConfig">
        <div ngxSlickItem class="treatment__card-block {{card.cardColor}} slide" *ngFor="let card of trearmentCardDetails" (click)="navigateTreatmentPage(card.id)">
            <ng-container 
                 [ngTemplateOutlet]="treatmentCard" 
                 [ngTemplateOutletContext]="{link:card}">
            </ng-container>
        </div>
       
    </ngx-slick-carousel>
    <div class="know-more">
        <app-know-more [moreText]="moreText"  (actionTriggered)="navigateBlogPage()" [actionBased]="true"  [show]="true"></app-know-more>
    </div>
 </div>