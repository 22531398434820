import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CreatePwdTemplateRoutingModule } from './create-pwd-template-routing.module';
import { CreatePwdComponent } from './create-pwd/create-pwd.component';
import { CommonAppModule } from '@app/common-app.module';
import { TemplateFooterComponent } from './template-footer/template-footer.component';
import { AddingPatientComponent } from './adding-patient/adding-patient.component';
import { AppointmentScheduleComponent } from './appointment-schedule/appointment-schedule.component';
import { AppointmentCancelledComponent } from './appointment-cancelled/appointment-cancelled.component';
import { GMapModule } from 'primeng/gmap';
import { AppointmentPendingComponent } from './appointment-pending/appointment-pending.component';
import { WelcomeTemplateComponent } from './welcome-template/welcome-template.component';
import { MigrateUserComponent } from './migrate-user/migrate-user.component';
@NgModule({
  declarations: [CreatePwdComponent, TemplateFooterComponent, AddingPatientComponent, AppointmentScheduleComponent, AppointmentCancelledComponent, AppointmentPendingComponent, WelcomeTemplateComponent, MigrateUserComponent],
  imports: [
    CommonModule,
    CommonAppModule,
    GMapModule,
    CreatePwdTemplateRoutingModule
  ]
})
export class CreatePwdTemplateModule { }
