import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-treatments-card',
  templateUrl: './treatments-card.component.html',
  styleUrls: ['./treatments-card.component.scss']
})
export class TreatmentsCardComponent implements OnInit {

  showFullDesciption = false;

  moreText = {
    moreTextLink: 'Read more'
  };
  lessText = {
    moreTextLink: 'Read less'
  };

  @Input() cardData;
  @Input() set cardIndex(value: any) {
      if (value !== this.treatmentCardIndex) {
        this.showFullDesciption = false;
      }
  }

  @Input() treatmentCardIndex: number;

  constructor() { }

  ngOnInit() {
  }

  readMore(event) {
    this.showFullDesciption = !this.showFullDesciption;
  }

}
