<ng-container *ngIf="showScreen && patientsList.length == 0">
    <div class="account-component">
        <img class="onbaord-form-bg" alt="right-lear" src="assets/images/svgs/leaf-watermark.svg" />

        <div class="account-main-section">
            <div class="account-user-name">Hello {{name}}!</div>
            <ng-container *ngIf="patientsList.length == 0">
                <div class="account-des">
                    To continue booking an appointment we will need brief information about the patient. Click below to
                    proceed
                </div>
                <a class="account-patient-form common-btn-style" (click)="redirectingToAddPatientForm('/new-patient')">
                    + New patient form
                </a>
            </ng-container>

            <div class="account-notes-section">
                <div class="notes-title">
                    Note :
                </div>
                <ul>
                    <li>It’s mandatory to fill the new patient form before booking any appointment</li>
                    <li>The physician has the right to refuse services to patients who provide misleading and inaccurate
                        information on the patient intake form.</li>
                    <li>Any unanswered questions or not completing the form in a timely manner, may result in a
                        cancellation of your appointment.</li>
                </ul>
            </div>
        </div>
        <div class="account-help-button">
            <div class="common-btn-style account-patient-form" (click)="navigateFaq($event)">
                Help ?
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="showScreen && patientsList.length > 0">
    <app-appointment-detail [appointmentDataList]="appointmentList" [patientsList]='patientsList' [patientTotalLocations]='patientTotalLocations' (callPatientApi)="callPatientApi($event)"></app-appointment-detail>
</ng-container>

<app-common-popup (showHideCancelPopup)='cancelPopup($event)' *ngIf="showUnsubscribePopUp">
    <div class="popup-content-block">
        <div class="confirm-message">Are you sure you want to unsubscribe the email?</div>
        <div class="btn-actions">
            <div class="cancel-btn" (click)="hidePopup(false)">Cancel</div>
            <div class="common-btn-style" (click)="hidePopup(true)">Proceed</div>
        </div>
    </div>
</app-common-popup>