<section class="right__side--panel">
    <div class="upcoming--appointments" *ngIf="gridType==='day'">
        <div class="header__label">Booked Appointments List</div>
        <div class="box__blocks">
            <ng-container *ngFor="let appointment of upcomingAppointments.list; let i = index">
                <div class="each__box"  (click)="redirection(appointment.appointmentId)">
                    <div class="icon__block">
                        <app-svg [name]="getingAppointmentTypeSvgName(upcomingAppointments.appointmentName)"></app-svg>
                    </div>
                    <div class="patient__info">
                        <div class="patient__name" [title]="fullName(appointment) | titlecase">{{fullName(appointment) | titlecase}}</div>
                        <div>{{appointment.begin | customTime}} - {{appointment.end | customTime}}</div>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="action__buttons" *ngIf="bookButtonFlag && !upcomingAppointments.appId">
            <div class="common-btn-style"  (click)="bookSlot(upcomingAppointments)">
                Create New Appointment
            </div>
        </div>
        <div class="action__buttons" *ngIf="!bookButtonFlag && !upcomingAppointments.appId">
            <div class="common-btn-style"  (click)="bookSlot(upcomingAppointments)">
               Block This Slot
            </div>
        </div>
    </div>
    <ng-container *ngIf="gridType === 'week'">
        <div class="today--msg"> Booked appointments b/w {{upcomingAppointments.eachTimeLable}} - {{upcomingAppointments.eachTimeLableEnd}} on {{upcomingAppointments.date | customDate}} </div>
        <div class="location__wise--appointments">
            <div class="header__label"></div>
            <ng-container *ngFor="let appointment of upcomingAppointments.treatmentCount; let i = index">
                <div class="common__text">{{appointment.appointmentName}} - {{appointment.appointmentCount}}/{{appointment.bookedCount}} Booked</div>
            </ng-container>
        </div>
        <div class="action__buttons">
            <div class="common-btn-style"  (click)="createNewAppointment()">
                Create New Appointment
            </div>
            <div class="cancel-btn"  (click)="filterAppointments(upcomingAppointments)">
                View All Appointment
            </div>
        </div>
    </ng-container>
</section>