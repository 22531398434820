import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-preview-tms-notes',
  templateUrl: './preview-tms-notes.component.html',
  styleUrls: ['./preview-tms-notes.component.scss']
})
export class PreviewTmsNotesComponent implements OnInit {

  @Input() dataForPreview: any
  
  ApptDate:string 
  returnVisit: string
  tmsScoreSheet: string
  tmsTherapyVisitHeading: string
  ssn: string
  dob: string

  constructor() { }

  ngOnInit() {
    console.log("dataForPreview", this.dataForPreview);
    this.ApptDate = formatDate((this.dataForPreview.apptDate), 'MM-dd-yyyy', 'en').replace(/-/g, '/')
    this.dob = formatDate((this.dataForPreview.patientDob), 'MM-dd-yyyy', 'en').replace(/-/g, '/')
    //return vist checkboxes
    if(this.dataForPreview.returnVisit){
      let selectedReason = '';
      const validReasons = ['Yes', 'No'];
      for (let key of validReasons) {
        if(this.dataForPreview.returnVisit === key){
          selectedReason = selectedReason + `
          <div style="display: flex; column-gap: 5px;">
            <div><img class="accept-img" src="https://new-tele-doc-assets.s3.amazonaws.com/assets/checked1.png"></div>
            <div >${key}</div>
          </div>`
        }
        else{
          selectedReason = selectedReason + `
          <div style="display: flex; column-gap: 5px;">
            <div><img class="accept-img" src="https://new-tele-doc-assets.s3.amazonaws.com/assets/unchecked1.png"></div>
            <div >${key}</div>
          </div>`
        }
      }
      this.returnVisit = selectedReason;
    }
    //TMS THERAPY PATIENT SCORE REPORT SHEET
    if(this.dataForPreview && this.dataForPreview.hasOwnProperty('ScoryHistoryReport') && this.dataForPreview.ScoryHistoryReport.length !== 0){
      if ( this.dataForPreview.ScoryHistoryReport){
        let rows = ``
        this.dataForPreview.ScoryHistoryReport.forEach((element: { scoreEvaluationDate: any; evaluationName: any; score: any }) => {
          rows = rows + `<tr>
            <td class="axises_cell">${element.scoreEvaluationDate}</td>
            <td class="axises_cell">${element.evaluationName}</td>
            <td class="axises_cell">${element.score}</td>
          </tr>`
        })
  
        let table = `<table class="axises no-page-break field-group">
          <tr>
            <td class="axises_cell"> <span class="bold"> Date of Evaluation </span> </td>
            <td class="axises_cell"> <span class="bold"> Name of evaluation </span> </td>
            <td class="axises_cell"> <span class="bold"> Score </span> </td>
          </tr>
          ${rows}
        </table>`
  
        let scoreSheetSection = `
          <div class="tms-heading">TMS THERAPY PATIENT SCORE REPORT SHEET</div>
  
          <div class="basicInfo no-page-break">
            <div class="basicInfo-left">
              <div class="field-item">
                  <div class="field-item-header">NAME</div>
                  <div class="field-item-header-colon">:</div>
                  <div class="field-item-text">{{dataForPreview.PatientName}}</div>
              </div>
              <div class="field-item">
                  <div class="field-item-header">PT ID</div>
                  <div class="field-item-header-colon">:</div>
                  <div class="field-item-text">{{ssn}}</div>
              </div>
              <div class="field-item">
                  <div class="field-item-header">DOB</div>
                  <div class="field-item-header-colon">:</div>
                  <div class="field-item-text">{{dataForPreview.patientDob}}</div>
              </div>
            </div>
            <div class="basicInfo-right">
              <div class="field-item">
                <div class="field-item-header">Age</div>
                <div class="field-item-header-colon">:</div>
                <div class="field-item-text">{{dataForPreview.patientAge}}</div>
              </div>
              <div class="field-item">
                <div class="field-item-header">Gender</div>
                <div class="field-item-header-colon">:</div>
                <div class="field-item-text">{{dataForPreview.patientGender}}</div>
              </div>
            </div>
          </div>
  
          <div class="tms-heading">Score History</div>
  
          <div>${table}</div>
          <div class="Space"></div>
        `;

      this.tmsScoreSheet = scoreSheetSection;
      }

    } else {
      this.tmsScoreSheet = ''
    }
    //TMS THERAPY INITIAL / FOLLOW-UP VISIT
    if(this.dataForPreview && this.dataForPreview.AppointmentType){
      if(this.dataForPreview.AppointmentType === "Initial TMS Note" ){
        this.tmsTherapyVisitHeading = 'TMS THERAPY INITIAL VISIT'
      } else if (this.dataForPreview.AppointmentType === "Follow Up TMS Note" ){
        this.tmsTherapyVisitHeading = 'TMS THERAPY FOLLOW - UP VISIT'
      }else {
        this.tmsTherapyVisitHeading = 'FOLLOW UP TMS NOTE - THETA-BURST'
      }
    } else {
      this.tmsTherapyVisitHeading = 'TMS THERAPY VISIT'
    }
    //Social Security number
    if (this.dataForPreview && this.dataForPreview.socialSecurityNumber){
      let lastFourDigits = this.dataForPreview.socialSecurityNumber.slice(-4);
      let ssnValue = "XXX-XX-" + lastFourDigits
      this.ssn = ssnValue
    } else {
      this.ssn = ''
    }
  }

}
