<!-- Appoinement List -->
<ul>
<li class="appointments__list employee-list-data" (click)="employeeDetailsClick(employeeData.employeeNameId)">
    <!-- Appoinement List Date-->
    <div class="appointments__list-item employee-name">
        <div class="appointments__icon">
            <img  src={{employeeData.userProfileUrl}} alt={{employeeData.employeeName}}>
        </div>
        <p class="appointments__list-text" [title]="employeeData.employeeName | titlecase" >{{employeeData.employeeName | titlecase | ellipsis:18}}</p>
    </div>
    <!-- Appoinement List time-->
    <div class="appointments__list-item user">
        <app-svg class="appointments__icon" [name]="'identification'"></app-svg>
        <p class="appointments__list-text" [title]="employeeData.employeeDesignation | titlecase" >{{employeeData.employeeDesignation | titlecase | ellipsis:25}}</p>
    </div>
    <!-- Appoinement List User-->
    <div class="appointments__list-item work-location">
        <app-svg class="appointments__icon" [name]="'location-black'"></app-svg>
            <p class="appointments__list-text"> {{employeeData.employeeLocation[0] | titlecase | ellipsis:18}} <span *ngIf ="employeeData.employeeLocation.length>1">,  +{{employeeData.employeeLocation.length-1}} More</span>
            </p>
    </div>
    <!-- Appoinement List Type-->
    <div class="appointments__list-item mobileNumber">
        <app-svg class="appointments__icon" [name]="'adminMobile'"></app-svg>
        <p class="appointments__list-text">{{employeeData.employeePhoneNumber}} </p>
    </div>
    <!-- Appoinement List Icon -->
    <div class="appointments__list-item edit-icon" (click)="editClickEvent(employeeData.employeeNameId, $event)" *ngIf="employeeData.status === 'active' && adminPermissions.EMPLOYEE_EDIT">
        <app-svg class="appointments__icon" title="Edit" [name]="'edit'">Edit</app-svg>
    </div>
    <div class="appointments__list-item edit-icon" (click)="activateClickEvent(employeeData.employeeNameId, $event)" *ngIf="employeeData.status === 'archive' && adminPermissions.EMPLOYEE_EDIT">
        <app-svg class="appointments__icon" title="Activate" [name]="'active'" style="width: 24px !important;">Edit</app-svg>
    </div>
</li></ul>