<div class="appointments__list invoice-list-data history-list" (click)="requestListClick(requestData)">

    <div class="appointments__list-item date date_time">
        <app-svg class="appointments__icon" [name]="'calendar'"></app-svg>
        <div class="appointments__list-text">
            <p class="time_data">
                {{requestData.createDate | dateFormat}}
            </p>
            <p class="time_data">
                {{ requestData.createTime | customeTimeAmPm}}
            </p>
        </div>
    </div>
    <div class="appointments__list-item  user patient_name" *ngIf='requestData.patient && requestData.patientId'>
        <app-svg class="appointments__icon" [name]="'user'" ></app-svg>
        <p class="appointments__list-text" [title]="requestData.patient">
            {{requestData.patient}} ({{requestData.patientId}}) </p>
    </div>
    <div class="appointments__list-item  user patient_name" *ngIf="isPatient">
        <app-svg class="appointments__icon" [name]="'user'" ></app-svg>
        <p class="appointments__list-text" [title]="requestData.creator">
            {{requestData.creator | ellipsis:18}} </p>
    </div>
    <div class="appointments__list-item type request-documents">
        <app-svg class="appointments__icon" [name]="'detail'">
        </app-svg>
        <span class="appointments__list-text" [title]="requestData.totalDocuments">
            {{ requestData.totalDocuments | ellipsis:30}}
        </span>
    </div>
    <div class="appointments__list-item bill_name">
        <app-svg class="appointments__icon" [name]="requestData.statusIcon"></app-svg>
        <div class="appointments__invoice-data">
            <p class="appointments__list-text amount">{{requestData.statusLabel}}</p>
        </div>
    </div>
    
</div>