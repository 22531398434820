import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DEFAULT_PAGINATION_LIMIT, TOAST_STATUSES } from '@app/common/constants/util.constant';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { environment } from '@src/environments/environment';

// declare var stripe: any;

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss']
})
export class InvoiceListComponent implements OnInit {
  paymentRedirectUrl=environment.paymentRedirectUrl;
  // pagination data
  breadCrumbData = {
    link: '/admin',
    linkText: 'Invoice List'
  };
  searchInputText = ''; // Search result data
  isShowScreen = false; // While call api hiding the layout
  invoiceListPerPageCount: any;  // invoiceListPerPageCount
  paginationData: any; // Pagination data
  amountGenerated: any; // Amount of Copay
  invoiceId: any; // Invoice ID for mark as paid
  appointmentId: any;
  invoiceStatus: string;
  invoiceActualStatus: string;
  makeAsPaidPopupShowHide: boolean = false; // Hide the paid popup
  isBeforeActions: boolean = false; // Before actions

  // Initail  playload value
  appyliedFilterObj = {};
  isFilterData = false;
  isFilterClear = false;
  filterApplyFlag = false; // Cheking the filter is applying or not
  radioValue = false;
  sideBarVisible = false;
  generateInvoicePopupShowHide: boolean = false; // Generated intercation popup
  invoiceListCount = 0;
  // Form inputs
  forminputs = [
    {
      type: 'groupCheckbox',
      categoryLabel: 'Invoice Status',
      controlType: 'groupCheckbox',
      key: 'status',
      required: true,
      value: [],
      isIntermediate: false,
      options: [],
      validators: [],
      focus: true,
      editable: true,
      isSelected: false,
      showontoggle: true,
    },
    {
      type: 'header',
      controlType: 'hrLine',
      className: 'hrLine',
      showontoggle: true,
      editable: true,
    },
    {
      type: 'groupCheckbox',
      categoryLabel: 'Treatment',
      controlType: 'groupCheckbox',
      key: 'invTreatment',
      required: true,
      value: [],
      isIntermediate: false,
      options: [],
      validators: [],
      focus: true,
      editable: true,
      isSelected: false,
      showontoggle: true,
    },
    {
      type: 'header',
      controlType: 'hrLine',
      className: 'hrLine',
      showontoggle: true,
      editable: true,
    },
    {
      type: 'groupCheckbox',
      categoryLabel: 'Other',
      controlType: 'groupCheckbox',
      key: 'other',
      required: true,
      value: [],
      isIntermediate: false,
      options: [],
      validators: [],
      focus: true,
      editable: true,
      isSelected: false,
      showontoggle: true,
    },
    {
      type: 'header',
      controlType: 'hrLine',
      className: 'hrLine',
      showontoggle: true,
      editable: true,
    },
    {
      type: 'submit',
      placeholder: '',
      required: true,
      text: 'Apply',
      key: '',
      value: '',
      controlType: 'submitButton',
      validators: [],
      callback: '',
      cencelBtn: 'Clear',
      showontoggle: true,
    }];
  rawFilterData: any;
  invoiceListPayload: any; // getting payload for invoice at any end
  // Intial Payload
  INITIAL_PAYLOAD = {
    pagination: {
      page: 1,
      limit: DEFAULT_PAGINATION_LIMIT
    },
    search: this.searchInputText,
    filter: {
      status: [],
      treatment: [],
      patientInvoice: false
    }
  };
  inputPlaceHoderText = 'Search by Patient, Treatment';
  // Empty list Messages
  invoiveListEmptyData = {
    className: 'appointmentsList',
    icon: 'appointment-empty-data',
    message: 'There are no invoices data'
  };
  createInvoicePopupShowHide: boolean = false;
  patientInvoiceDetails: any;
  invoiceType: any;
  permissionsDone = false;
  adminPermissions = {
    INVOICE_CREATE: true,
    INVOICE_EDIT: true
  };
  searchData: string = ''
  requestId: any;
  paymentToken: string = ''
  isMarkasPaid= false;
  constructor(private observable: ObservableHelperService, private http: HttpService, private router: Router, private activeRoute: ActivatedRoute) {
    this.activeRoute.queryParams.subscribe(params => {
      if(params.search){
        this.searchInputText = params.search
      }
    });
  }

  ngOnInit() {
    this.getAdminPermissions();
    // Initail Payload
    this.checkingSidebarState(this.sideBarVisible);
    document.querySelector('html').style.overflow = 'auto';
    document.querySelector('body').style.overflow = 'auto';
    this.isFilterData = false;
    this.invoiceListPayload = this.INITIAL_PAYLOAD;
    this.INITIAL_PAYLOAD.search = this.searchInputText;
      this.getInvoiceList(this.invoiceListPayload);
    this.getFilterData();
  }
  // Admin Employee API call
  getInvoiceList(bodyData) {
    this.isShowScreen = false;
    const payload = {
      type: 'POST',
      url: 'invoiceList',
      isDeveloper: true,
      body: bodyData,
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        console.log('Response', res);
        this.invoiceListCount = res.data.pagination.totalItems;
        this.invoiceListPerPageCount = this.massageInvoiceData(res.data.list);
        this.paginationData = this.massagePaginationData(res.data);
        setTimeout(() => this.isShowScreen = true, 500);
      } else {
        if (res.error.message === 'Insufficient Privilege') {
          this.observable.showToast(TOAST_STATUSES.ERROR, 'Sorry! You do not have access to this page.');
          this.router.navigate(['/admin']);
        } else { this.observable.showToast(TOAST_STATUSES.ERROR, 'Error in getting invoice list'); }
      }
    });
  }

  // This will do massaging for pagination Data
  massagePaginationData(data) {
    if (!data && typeof (data) !== 'object') {
      // Error
    }
    return {
      limit: data.pagination.limit,
      currentPage: data.pagination.page,
      totalPages: Math.ceil(data.pagination.totalItems / data.pagination.limit),
      data: data.list

    };
  }

  // This is a massaging function for Employee Data
  massageInvoiceData(data) {
    if (!data && !Array.isArray(data)) { return []; }
    return data.map(each => {
      return {
        ...each,
        patientName: (!each.firstName && !each.lastName && !each.apptfirstName && !each.apptlastName && !each.rfirstName && !each.rlastName) ? '-' :
          (each.invoice_type === 'appt_invoice' ? each.apptfirstName : (each.invoice_type==='document_request_invoice') ? each.rfirstName: each.firstName) + ' ' + (each.invoice_type === 'appt_invoice' ? each.apptlastName: (each.invoice_type==='document_request_invoice') ?each.rlastName : each.lastName),
        billAmount: (!each.copay && !each.pay) ? '-' : '$  ' + (each.copay + each.pay).toFixed(2),
        billGeneratedStatus: ((each.status).toLowerCase() === 'ungenerated') ? true : false,
        treatmentIcon: this.treatmentTypeSvgName(each.treatment),
        time: (each.requestDate ?(each.requestDate.split('T')[1].split('.')[0]): null),
      };
    });
  }

  // Pagination Change Event
  pageChangedEvent(data) {
    console.log(data);
    this.isShowScreen = false;
    this.isFilterData = false;
    const payload = {
      pagination: {
        page: data.currentPage,
        limit: data.limit,
      },
      search: this.searchInputText,
      filter: this.filterApplyFlag ? this.appyliedFilterObj['filter'] : {
        status: [],
        treatment: [],
        patientInvoice: false
      },
    };
    this.invoiceListPayload = payload;
    this.getInvoiceList(this.invoiceListPayload);
    window.scroll(0, 0);
  }

  // This will navigate to create New Employee page
  createNew() {
    this.createInvoicePopupShowHide = true;
    this.invoiceStatus = 'ungenerated';
    // this.observable.showToast(TOAST_STATUSES.SUCCESS, 'This feature is coming out soon..!!');
    // this.router.navigate(['admin/new-employee']);
  }

  // Filter
  filterInvoice() {
    this.sideBarVisible = !this.sideBarVisible;
    this.checkingSidebarState(this.sideBarVisible);
    let filterSidePanel = document.querySelectorAll('.p-sidebar-width')[1];
    filterSidePanel.scrollTo(0, 0);
  }
  // Admin get filterData API call
  getFilterData() {
    this.isShowScreen = false;
    const payloadData = {
      type: 'GET',
      url: 'invoiceFilter',
      isDeveloper: true,
      // isLocal: true
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        console.log(res);
        this.rawFilterData = this.massageFilterData(res.data);
        // this.assignFilterData(this.rawFilterData);
        console.log(this.rawFilterData);
      } else {
        // Error
      }
      this.isShowScreen = true;
    });
  }

  // Massage the filter data
  massageFilterData(data) {
    if (data && typeof (data) === 'object') {
      data['invTreatment'] = data.treatment.map(doc => {
        return {
          ...doc,
          name: doc.appointmentTypeName,
          value: doc.appointmentTypeName,
          isSelected: false,
          editable: true
        };
      });
      data.status = data.status.map((doc, index) => {
        var singleObj = { isSelected: false };
        singleObj['name'] = doc;
        singleObj['value'] = doc;
        singleObj['editable'] = true;
        return singleObj;
      });
      data['other'] = [{appointmentTypeId: 999, appointmentTypeName: 'Free Style Invoice', name: 'Free Style Invoice', value: 'Free Style Invoice', isSelected: false, editable: true},{appointmentTypeId: 999, appointmentTypeName: 'Patient Document List', name: 'Patient Document List', value: 'Patient Document List', isSelected: false, editable: true}];
      console.log(data);
      return data;
    } else {
      // Error
    }
  }
  // View By filters
  viewByFilters(data) {
    setTimeout(() => {
      this.isFilterClear = false;
    }, 0);
    this.isFilterClear = true;
    this.filterApplyFlag = false;
    this.radioValue = data;
    this.isFilterData = false;
  }
  // This emit filter form Data
  filterDataEvent(data) {
    console.log('data', data);
    this.appyliedFilterObj = {
      filter: data,
      search: this.searchInputText,
      pagination: {
        page: 1,
        limit: DEFAULT_PAGINATION_LIMIT
      },
    };
    this.filterApplyFlag = true;
    this.isFilterData = true;
    this.invoiceListPayload = this.appyliedFilterObj;
    this.getInvoiceList(this.invoiceListPayload);
  }
  // Checking the Slide bar is open or if open hide outside scroll
  checkingSidebarState(slideBarFlag) {
    if (slideBarFlag) {
      document.querySelector('html').style.overflow = 'hidden';
      document.querySelector('body').style.overflow = 'hidden';
    } else {
      document.querySelector('html').style.overflow = 'auto';
      document.querySelector('body').style.overflow = 'auto';
    }
  }

  // Checcking side bar staus
  checkingSidebarSetStatus(data) {
    this.sideBarVisible = data;
    this.checkingSidebarState(data);
  }

  // Search filter api function
  searchFilter(data) {
    this.searchInputText =  data === '' ? '' : data;
    this.isFilterData = false;
    if (typeof data === 'string' && (data.replace(/\s/g, '').length > 0 || data == '')) {
      const searchPayload = {
        pagination: {
          page: 1,
          limit: DEFAULT_PAGINATION_LIMIT
        },
        search: this.searchInputText,
        filter: this.filterApplyFlag ? this.appyliedFilterObj['filter'] : {
          status: [],
          treatment: [],
          patientInvoice: false
        },
      };
      this.invoiceListPayload = searchPayload;
      this.getInvoiceList(this.invoiceListPayload);
      if(this.searchInputText == ''){
        this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.INVOICES}`]);
      } else {
        this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.INVOICES}`], { queryParams: { search: this.searchInputText } });
      }
    }
  }
  clearEventCall(data) {
    console.log(data);
  }
  // Switch case for the appointment type icons
  treatmentTypeSvgName(appointmentType) {
    let appointmentTypeIconName = '';
    switch (appointmentType) {
      case 'Medication Management':
        appointmentTypeIconName = 'medication-management';
        break;
      case 'Ketamine Treatment':
        appointmentTypeIconName = 'ketamine-treatment';
        break;
      case 'TMS':
        appointmentTypeIconName = 'adminTms';
        break;
      case 'Psychological Therapy':
        appointmentTypeIconName = 'psychological-therapy';
        break;
      default :
      appointmentTypeIconName = 'treatement';
    }
    return appointmentTypeIconName;
  }

  // Invoice List icon action catching click
  invoiceListClick(data) {
    console.log("invoiceListClick function~~~~~~~~~", data);
    if (data) {
      this.invoiceId = data.invId;
      this.invoiceActualStatus = data.invStatus;
      this.invoiceType = data.invType;
      this.requestId= data.refId;
      this.appointmentId = data.appId;
    }
    if (data.btnType === 'generate') {
      this.invoiceStatus = 'ungenerated';
      this.appointmentId = data.appId;
      this.generateInvoicePopupShowHide = true;
    } else if (data.btnType === 'resend') {
      this.generateInvoice({ btnType: 'resend' });
    } else if (data.btnType === 'markaspaid') {
      this.invoiceStatus = '';
      this.makeAsPaidPopupShowHide = true;
    }
  }

  // generateInvoice($event)
  generateInvoice(data) {
    // tslint:disable-next-line:one-variable-per-declaration
    let method, callUrl, bodyData, pathVariable, message; let callAPI = false;
    if (data && data.type === undefined) {
      data.values ? this.amountGenerated = {
        copay: data.values.copayAmt,
        due: data.values.dueAmt,
        pay: (data.values.copayAmt + data.values.dueAmt),
        comment: data.values.comments
      // tslint:disable-next-line:no-unused-expression
      } : null;
      if (data.btnType === 'sendCustomer' || (data.btnType === 'submit' && this.invoiceStatus && this.invoiceStatus === 'ungenerated')) {
        method = 'POST';
        callUrl = 'generateInvoice';
        data.btnType === 'sendCustomer' ?
          bodyData = {
            type: 'NEW',
            appointmentId: this.appointmentId,
            status: 'unpaid',
            copay: this.amountGenerated.copay,
            // pay: this.amountGenerated.pay
            pay: this.amountGenerated.due,
            invoiceType: 'appt_invoice',
            comment: this.amountGenerated.comment
          } : bodyData = {
            type: 'NEW_PAID',
            appointmentId: this.appointmentId,
            status: 'paid',
            copay: this.amountGenerated.copay,
            // pay: this.amountGenerated.pay,
            pay: this.amountGenerated.due,
            paymentMode: data.form.value.paymentMode,
            referenceId: data.form.value.referenceId,
            invoiceType: 'appt_invoice',
            comment: this.amountGenerated.comment
          };
        message = data.btnType === 'sendCustomer' ? 'Invoice has been sent to the customer successfully' : 'Invoice has been marked as paid successfully.';
        // tslint:disable-next-line:no-unused-expression
        data.btnType === 'submit' ? this.makeAsPaidPopupShowHide = false : null;
        callAPI = true;
      } else if (data.btnType === 'makePayment') {
        // this.observable.showToast(TOAST_STATUSES.SUCCESS, 'This feature is coming out soon..!!');
        let reqBody: any;
        if (this.invoiceActualStatus === 'unpaid') {
          reqBody = {
            id: this.invoiceId
          };
        } else {
          reqBody = {
            type: 'NEW',
            appointmentId: this.appointmentId,
            copay: this.amountGenerated.copay,
            pay: this.amountGenerated.due,
            invoiceType: 'appt_invoice'
          };
        }
        let payload = {
          type: 'POST',
          url: 'checkoutSession',
          isDeveloper: true,
          body: reqBody
        };
        this.http.makeHttpRequest(payload).subscribe((res) => {
          if (res.error) {
            alert(res.error.message);
            return;
          } else {
            this.paymentToken = res.result.response.token
            let inputElement = document.getElementById("paymentvalue") as HTMLInputElement;
            // Set the value of the input element
            inputElement.value = this.paymentToken;
            // Get the form element
            let form = document.getElementById('formAuthorizeNetPage') as HTMLFormElement;
            form.method = 'POST';
            form.submit();
          }
          // stripe.redirectToCheckout({ sessionId: res.id });
        });
      } else if (data.btnType === 'markAsPaid') {
        this.makeAsPaidPopupShowHide = true;
      } else if (data.btnType === 'resend') {
        method = 'GET';
        callUrl = 'invoiceResend';
        pathVariable = this.invoiceId;
        message = 'Invoice has been re-sent to the customer successfully.';
        callAPI = true;
      } else if (data.btnType === 'submit') {
        method = 'PUT';
        callUrl = 'invoiceMarkasPaid';
        if(this.invoiceType === 'document_request_invoice'){
        bodyData = {
          status: 'paid',
          paymentMode: data.form.value.paymentMode,
          referenceId: data.form.value.referenceId,
          invoiceType: this.invoiceType,
          requestId:this.requestId
        };
      }
      else{
        bodyData = {
          status: 'paid',
          paymentMode: data.form.value.paymentMode,
          referenceId: data.form.value.referenceId,
          invoiceType: this.invoiceType
        };
      }
        pathVariable = this.invoiceId;
        message = 'Invoice has been marked as paid successfully.!';
        this.makeAsPaidPopupShowHide = false;
        callAPI = true;
      }
      if (callAPI) {
        // API Call for all
        callAPI = false;
        const payload = {
          type: method,
          url: callUrl,
          isDeveloper: true,
          pathVariables: [pathVariable ? pathVariable : null],
          body: bodyData ? bodyData : null,
        };
        this.http.makeHttpRequest(payload).subscribe((res) => {
          if (this.http.isSuccessfulResponse(res)) {
            console.log('Response', res);
            this.getInvoiceList(this.invoiceListPayload);
            this.observable.showToast(TOAST_STATUSES.SUCCESS, message);
          } else {
            console.log('Response', res);
            this.observable.showToast(TOAST_STATUSES.ERROR, res.error.message);
          }
        });
      }
    } else if (data && data.type === 'patient_invoice') {
      if (data.btnType === 'sendCustomer' || (data.btnType === 'submit' && this.invoiceStatus === 'ungenerated')) {
        method = 'POST';
        callUrl = 'createInvoice';
        bodyData = data.btnType === 'sendCustomer'? {
            type: 'NEW',
            patientId: data.patientID,
            status: 'unpaid',
            pay: data.amount,
            service: data.service
          } : {
            type: 'NEW_PAID',
            patientId: data.patientDetails.patientID,
            status: 'unpaid',
            pay: data.patientDetails.amount,
            service: data.patientDetails.service,
            paymentMode: data.form.value.paymentMode,
            referenceId: data.form.value.referenceId
          };
        message = data.btnType === 'sendCustomer' ? 'Invoice has been sent to the patient successfully.' : 'Invoice has been marked as paid successfully.';
        callAPI = true;
      } else if (data.btnType === 'markAsPaid') {
        this.createInvoicePopupShowHide = false;
        this.makeAsPaidPopupShowHide = true;
        this.patientInvoiceDetails = {
          patientID: data.patientID,
          amount: data.amount,
          service: data.service,
          invoiceType: data.type
        };
      } else if (data.btnType === 'makePayment') {
        let reqBody: any;
        if (this.invoiceActualStatus === 'unpaid') {
          reqBody = {
            id: this.invoiceId
          };
        } else {
          reqBody = {
            type: 'NEW',
            patientId: data.patientID,
            status: 'unpaid',
            pay: data.amount,
            service: data.service,
            invoiceType: 'patient_invoice'
          };
        }
        let payload = {
          type: 'POST',
          url: 'checkoutSession',
          isDeveloper: true,
          body: reqBody
        };
        this.http.makeHttpRequest(payload).subscribe((res) => {
          if (res.error) {
            alert(res.error.message);
            return;
          } else {
            this.paymentToken = res.result.response.token
            let inputElement = document.getElementById("paymentvalue") as HTMLInputElement;
            // Set the value of the input element
            inputElement.value = this.paymentToken;
            // Get the form element
            let form = document.getElementById('formAuthorizeNetPage') as HTMLFormElement;
            form.method = 'POST';
            form.submit();
          }
          // stripe.redirectToCheckout({ sessionId: res.id });
        });
      }
      if (callAPI) {
        // API Call for all
        callAPI = false;
        const payload = {
          type: method,
          url: callUrl,
          isDeveloper: true,
          pathVariables: [pathVariable ? pathVariable : null],
          body: bodyData ? bodyData : null,
        };
        this.http.makeHttpRequest(payload).subscribe((res) => {
          if (this.http.isSuccessfulResponse(res)) {
            console.log('Response', res);
            this.getInvoiceList(this.invoiceListPayload);
            this.observable.showToast(TOAST_STATUSES.SUCCESS, message);
          } else {
            console.log('Response', res);
            this.observable.showToast(TOAST_STATUSES.ERROR, res.error.message);
          }
        });
      }
    }
  }

  // This is closing for generate invoice the popup
  generateInvoicePopupShowHideStatusFun(status) {
    this.generateInvoicePopupShowHide = status.cancelpopup;
  }

  // This is closing for make as paid popup
  makeAsPaidPopupShowHideStatusFun(status) {
    this.makeAsPaidPopupShowHide = status.cancelpopup;
  }

  // This is closing for create-invoice popup
  createInvoicePopupShowHideStatus(status) {
    this.createInvoicePopupShowHide = status.cancelpopup;
  }

  // getting permissions based on user id
  getAdminPermissions() {
    const payloadData = {
      type: 'GET',
      url: 'getPermissions',
      isDeveloper: true
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        let permissions = ['INVOICE_CREATE', 'INVOICE_EDIT'];
        let permArr = [];
        res.data.permissions.forEach(obj => {
          permArr.push(obj.permissionName);
        });
        if (permArr.includes('INVOICE_MARKASPAID')){
          this.isMarkasPaid = true;
        }
        else{
          this.isMarkasPaid = false
        }
        if (!permArr.includes('INVOICE_LIST')) {
          this.observable.showToast(TOAST_STATUSES.ERROR, 'Sorry! You do not have access to this page.');
          this.router.navigate(['/admin']);
        }
        permissions.forEach(perm => {
          if (!permArr.includes(perm)) {
            this.adminPermissions[perm] = false;
          }
        });
        console.log(this.adminPermissions);
      } else {
        // Error
        let message = (res.error) ? res.error.message : 'Error in getting permissions';
        console.log(message);
      }
      this.permissionsDone = true;
    });
  }

}
