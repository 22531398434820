<!-- measurements section start -->
<section class="measurements__section full-width">
    <!-- measurements container start -->
    <div class="measurements__container restrict-width padding-lr">
        <!-- measurements block start -->
        <div class="measurements__block">
            <div class="icon__text-block">
                <div class="icon__block award">
                    <app-svg [name]="'award'" [imageLabel]="'anxiety treatment washington dc'"></app-svg>
                </div>
                <div class="text__block">
                    <div class="number">25+</div>
                    <div class="text">Years Experience</div>
                </div>
            </div>
        </div>
        <!-- measurements block end -->
        <!-- measurements block start -->
        <div class="measurements__block">
            <div class="icon__text-block">
                <div class="icon__block treatment">
                    <app-svg [name]="'treatment'" [imageLabel]="'Ketamine Treatment DC'"></app-svg>
                </div>
                <div class="text__block">
                    <div class="number">250+</div>
                    <div class="text">Ketamine Treatment</div>
                </div>
            </div>
        </div>
        <!-- measurements block end -->
        <!-- measurements block start -->
        <div class="measurements__block">
            <div class="icon__text-block">
                <div class="icon__block tms">
                    <app-svg [name]="'tms'" [imageLabel]="'weight loss centers in Maryland'"></app-svg>
                </div>
                <div class="text__block">
                    <div class="number">1000+</div>
                    <div class="text">TMS</div>
                </div>
            </div>
        </div>
        <!-- measurements block end -->
        <!-- measurements block start -->
        <div class="measurements__block">
            <div class="icon__text-block">
                <div class="icon__block mm-icon">
                    <app-svg [name]="'mm-icon'"  [imageLabel]="'Medical Marijuana DC'"></app-svg>
                </div>
                <div class="text__block">
                    <div class="number">100+</div>
                    <div class="text">Medical Marijuana</div>
                </div>
            </div>
        </div>
        <!-- measurements block end -->
    </div>
    <!-- measurements container end -->
</section>
<!-- measurements section end -->