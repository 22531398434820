import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { HttpService } from '@app/common/services/http.service';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import {ScrollServiceService} from 'src/app/services/scroll-service.service';

@Component({
  selector: 'app-get-in-touch',
  templateUrl: './get-in-touch.component.html',
  styleUrls: ['./get-in-touch.component.scss']
})
export class GetInTouchComponent implements OnInit {

  // Heading data for the component
  headingData = {
    subHeading: 'Get in touch',
    mainHeading: 'with us today',
  };

  // Array to store location data
  locations: LocationData[] = [];
  // Flag to determine if the device is a desktop
  desktop: boolean;
  // Flag to check if the location list is loaded
  isLocationListLoaded: boolean = false;
  // Configuration for the slide component
  slideConfig = {
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: true,
    infinited: true,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true
  };

  constructor(
    private http: HttpService, 
    private router: Router, 
    private route: ActivatedRoute, 
    private scrollService: ScrollServiceService
  ) {
    // Subscribe to scroll events
    this.scrollService.getScrollSubject().subscribe((sectionId: string) => {
      console.log(sectionId, "section");
      if (sectionId === 'contactUs') {
        console.log("inside if");
        const targetSectionElement = document.getElementById(sectionId);
        if (targetSectionElement) {
          console.log("getInTouch component");
          targetSectionElement.scrollIntoView();
        }
      }
    });
  }

  commonHeight: number;
  contactUsTop: number;

  ngOnInit() {
    // Initialization logic
  }

  /**
   * This function is called after the view is initialized.
   * 
   * Initializes an IntersectionObserver to load the location list when the target enters the viewport.
   * Uses a flag to ensure the API call is made only once, preventing duplicate requests.
   */
  ngAfterViewInit() {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !this.isLocationListLoaded) {
        this.getLocationList();
        this.isLocationListLoaded = true; // Ensures it only runs once
      }
    });

    const target = document.querySelector('.provide__section');
    if (target) {
      observer.observe(target);
    }

    // Check for query parameters to handle scrolling
    this.route.queryParams.subscribe(params => {
      if (params['scroll']) {
        setTimeout(() => {
          this.commonHeight = $('.common__header--section').outerHeight();
          this.contactUsTop = $('#contactUs').offset().top;
          const canonicalLink = document.querySelector('link[rel="canonical"]');
          if (canonicalLink) {
            canonicalLink.setAttribute('href', 'https://newteledoc.com/?scroll=true');
            this.router.events.subscribe((event) => {
              if (event instanceof NavigationEnd) {
                canonicalLink.setAttribute('href', 'https://newteledoc.com/?scroll=true');
              }
            });
          }
          let position: number;
          this.commonHeight < 100 ? position = (this.contactUsTop - (this.commonHeight + 20)) : position = this.contactUsTop;
          $('html,body').animate({ scrollTop: position }, 2000);
        }, 1500);
      }
    });
  }

  /**
   * Navigate to the booking page.
   */
  navigateBookingpage() {
    this.scrollTop();
    // The below line was commented on Sept 5, 2023, because it was showing a toast message of error even after working correctly
    // this.router.navigate([`${ROUTE_PATHS.APPOINTMENT}`]);
    this.router.navigate(['login']);
  }

  /**
   * Scroll to the top of the page.
   */
  scrollTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  /**
   * To get location list API call.
   */
  getLocationList() {
    const payloadData = {
      type: 'GET',
      url: 'adminlocationsList',
      isDeveloper: true,
      isAuthToken: true,
      donotShowLoader: true
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        res.data.forEach(loc => {
          this.getLocationById(loc.locationId);
        });
      } else {
        console.log(res.error.message);
      }
    });
  }

  /**
   * Get location details by ID.
   * 
   * @param locId - The ID of the location to fetch details for.
   */
  getLocationById(locId: string) {
    const payloadData = {
      type: 'GET',
      url: 'getLocationDetails',
      pathVariables: [locId],
      isDeveloper: true,
      isAuthToken: true,
      donotShowLoader: true
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        let getWeekDays = [];
        let getWeekendDays = [];
        res.data.services.forEach(obj => {
          obj.timings.forEach(innerObj => {
            if (!getWeekDays.includes(innerObj.weekDay)) {
              getWeekDays.push(innerObj.weekDay);
            }
          });
        });
        let weekendAr = [0, 6];
        weekendAr.forEach(num => {
          getWeekDays.includes(num) ? (getWeekendDays.push(num), getWeekDays.splice(getWeekDays.indexOf(num), 1)) : null;
        });
        getWeekDays = getWeekDays.map(i => i - 1).sort((a, b) => a - b);
        getWeekendDays = getWeekendDays.map(i => i === 0 ? 6 : 5).sort((a, b) => a - b);
        getWeekDays = [this.getDaysInString([...getWeekDays])];
        getWeekendDays = [this.getDaysInString([...getWeekendDays])];
        let locationData = { ...res.data, weekDays: getWeekDays, weekendDays: getWeekendDays };
        this.locations.push(locationData);
      } else {
        console.log('Error in getting location details');
      }
    });
  }

  /**
   * Function for displaying days array as day string.
   * 
   * @param days - Array of day numbers.
   * @returns A string representing the range of days.
   */
  getDaysInString(days: number[]): string {
    const daysString = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    if (days.length && days.length > 1) {
      return `${daysString[days[0]]} - ${daysString[days[days.length - 1]]}`;
    } else {
      return daysString[days[0]];
    }
  }
}

// Interface to define the structure of location data
interface LocationData {
  locationId: string;
  services: {
    timings: {
      weekDay: number;
    }[];
  }[];
  weekDays?: string[];
  weekendDays?: string[];
}
