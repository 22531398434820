<section class="patient__main-wrapper email-template-wrapper" *ngIf="showScreen && permissionsDone">
    <!--Right side background leaf starts here -->
    <div class="background-image">
        <img src="assets/images/pngs/newpatientform-background.png" alt="bg image">
    </div>
    <!-- Right side background leaf ends here -->
    <!-- Main section starts here -->
    <div class="patient__wrapper content__wrapper">
        <!-- Breadcrumb section starts here -->
        <div class="patient__list-navigation-menu">
            <app-breadcrumb [breadCrumbData]="breadCrumbData"></app-breadcrumb>
            <!-- Primary action on Page -->
        </div>
        <!-- Breadcrumb section ends here -->
        <!-- Advanced filters -->
        <div class="patient__list-advanced-filters"> <!--{{adminPermissions.PATIENT_CREATE ? '' : 'margin'}}--> 
            <!-- <div class="patient__list-search-filter"> -->
                <!--  [isOpenSearch] = "isOpenSearch" -->
                <!-- <app-list-search [searchPlaceHolderText]="searchPlaceHolderText" (searchText)="searchFilter($event)"></app-list-search>
            </div>
            <div class="patient__list-date-filter" (click)="filterAppointments()">
                <app-svg [name]="'filter'"></app-svg>
            </div> -->
        </div>
        <div class="patient__list--block">
            <ng-container *ngIf="emailTemplateList.length !== 0">
                <div class="patient__list--wrapper">
                    <div class="each__patient" *ngFor="let templateDetails of emailTemplateList">
                        <app-email-templates-list [adminPermissions]="adminPermissions" [templateDetails]="templateDetails" (actionData)='cancelPopupShowHideStatusFun($event)'></app-email-templates-list>
                    </div>
                </div>
            </ng-container>
            
            <!-- Pagination component starts here -->
            <ng-container>
                <app-pagination [paginationRawData]="paginationData" (pageChanged)="pageChangedEvent($event)" *ngIf="emailTemplateListCount > 10"></app-pagination>
            </ng-container>
            
            <!-- Pagination component ends here -->
            <!-- Empty list Starts here -->
            <app-empty-list-data [emptyMessageData]="emptyAppointmentsListMessages" *ngIf="emailTemplateList.length === 0"></app-empty-list-data>
            <!-- Empty list ends here -->
        </div>
    </div>
    <!-- Main section ends here -->
</section>
<app-common-popup (showHideCancelPopup)='cancelPopupShowHideStatusFun($event)' *ngIf="cancelPopupShowHide">
    <div class="popup__wrapper exclude-patient--wrapper">
        <div class="popup__heading">Excluded Patients</div>
        <div class="search-filter__block">
            <app-svg [name]="'search'" class="inside-search"></app-svg>
            <p-autoComplete field="email" [(ngModel)]="result" [suggestions]="emailSearchList" (onSelect)="selectedEmail($event)" emptyMessage="No data found."(completeMethod)="search($event)">
                <ng-template let-result pTemplate="item">
                    <div class="result-item">
                        <div>{{result.email}}</div>
                    </div>
                </ng-template>
            </p-autoComplete>
        </div>
        <div class="mail__content__wrapper" *ngIf="excludedEmailsList.length>0">
            <div class="each-email-id" *ngFor="let emailId of excludedEmailsList">
                <span class="email-id">{{emailId}}</span>
                <app-svg [name]="'cancel-appointment'" (click)="removeEmailId(emailId)"></app-svg>
            </div>
        </div>
        <div class="empty-text" *ngIf="excludedEmailsList.length==0">No Patients added</div>
        <div class="save_button">
            <div class="cancel-btn" (click)="cancel()">Cancel</div>
            <button (click)="submit()" class="common-btn-style">Save</button>
        </div>
    </div>
</app-common-popup>