<div class="outer" *ngIf="documentDetails">
    <app-breadcrumb [breadCrumbData]="breadCrumbData"></app-breadcrumb>
    <!-- <div class="title">Document Request Details</div> -->
    <!-- status will be passed by the parent- doc-req -->
    <div class="wrapper-block">
        <div class="small" [ngClass]="documentDetails.status">{{documentDetails.status}}</div>

        <div class="hori">
            <div class="verti">
                <div class="pat">Patient Name</div>
                <div class="value">{{documentDetails.firstName | titlecase}} {{documentDetails.lastName |
                    titlecase}}</div>
            </div>
            <div class="verti">
                <div class="pat">Patient Id</div>
                <div class="value">{{documentDetails.patientId}}</div>
            </div>
            <br>
        </div>
        <br>
        <br>
        <div class="hori">
            <div class="verti">
                <div class="pat">Created By</div>
                <div class="value" *ngIf="documentDetails.createdBy !== 'ADMIN'">{{documentDetails.empfirstName | titlecase}}
                    {{documentDetails.emplastName | titlecase}}</div>
                    <div class="value" *ngIf="documentDetails.createdBy === 'ADMIN'">{{documentDetails.createdBy}}</div>
            </div>
            <div class="verti">
                <div class="pat">Created Date</div>
                <div class="value">{{documentDetails.createTime | dateFormat}}</div>
            </div>
            <div class="verti">
                <div class="pat">{{documentDetails.statusLabel}}</div>
                <div class="value">{{documentDetails.closureTime | dateFormat}}</div>
            </div>
            <br>
        </div>
        <br>
        <br>
        <div class="hori">
            <div class="verti">
                <div class="pat">Requested Details</div>
                <div class="value1">
                    <span *ngFor="let document of documentDetails.documents;let i=index">{{document.documentName.trim()}}<span *ngIf="i != documentDetails.documents.length-1">{{","}}&nbsp;</span>
                    </span></div>
            </div>
            <br>
        </div>
        <br>
        <br>
        <div class="hori">
            <div class="verti">
                <div class="pat">Other Details</div>
                <div class="value1" *ngIf="documentDetails.otherComment">{{documentDetails.otherComment}}</div>
                <div class="value1" *ngIf="!documentDetails.otherComment">---</div>
            </div>
            <br>
        </div>
        <br>
        <br>
        <div class="document_download-wrapper" *ngIf="documentDetails.adminDocumentURLs && documentDetails.adminDocumentURLs.length != 0">
            <div class="verti">
                <div class="pat">Clinic Documents <span class="download" *ngIf="documentDetails.adminDocumentURLs.length != 0" (click)="downloadAll(documentDetails.adminDocumentURLs)">Download All</span></div>
                <div class="value" *ngIf="documentDetails.adminDocumentURLs.length == 0">No documents available</div>
                <div class="value documents_wrapper">
                    <span class="document-block each_document" *ngFor="let document of documentDetails.adminDocumentURLs">
                        <div class="each_document-name">
                            <p class="">{{document.fileName | ellipsis: 25}}</p>
                        </div>
                        <div class="each_document-actions">
                            <a [href]="document.url" target="_blank" title="View" class="view-icon">
                                <app-svg [name]="'view-upload'"></app-svg>
                            </a>
                            <p class="down-arrow" (click)="downloadImage(document.url)"
                                title="Download">
                                <app-svg [name]="'down-arrow'"></app-svg>
                            </p>
                        </div>
                    </span>
                </div>
            </div>
        </div>
        <div *ngIf="documentDetails.documentURLs && documentDetails.documentURLs.length != 0">
            <hr>
            <br>
        </div>
        <div class="hori documents_download-wrapper" *ngIf="documentDetails.documentURLs && documentDetails.documentURLs.length != 0">
            <div class="verti">
                <div class="pat">Patient Documents <span class="download" *ngIf="documentDetails.documentURLs.length != 0" (click)="downloadAll(documentDetails.documentURLs)">Download All</span></div>
                <div class="value" *ngIf="documentDetails.documentURLs.length == 0">No documents available</div>
                <div class="value documents_wrapper">
                    <span class="document-block each_document" *ngFor="let document of documentDetails.documentURLs">
                        <div class="each_document-name">
                            <p class="">{{document.fileName | ellipsis: 25}}</p>
                        </div>
                        <div class="each_document-actions">
                            <a [href]="document.url" target="_blank" title="View" class="view-icon">
                                <app-svg [name]="'view-upload'"></app-svg>
                            </a>
                            <p class="down-arrow" (click)="downloadImage(document.url)"
                                title="Download">
                                <app-svg [name]="'down-arrow'"></app-svg>
                            </p>
                        </div>
                    </span>
                </div>
            </div>
        </div>
        <div *ngIf="documentDetails.status == 'Completed' || documentDetails.status == 'Cancelled'" class="">
            <div class="hori">
            <div class="verti" *ngIf="documentDetails.patientComments != null">
                <div class="pat">Patient Comments</div>
                <div class="value1" *ngIf="documentDetails.patientComments">{{documentDetails.patientComments}}</div>
                <div class="value1" *ngIf="!documentDetails.patientComments">---</div>
            </div>
            <div class="verti" *ngIf="documentDetails.patientComments == null">
                <div class="pat">General Comments</div>
                <div class="value1" *ngIf="documentDetails.adminComments">{{documentDetails.adminComments}}</div>
                <div class="value1" *ngIf="!documentDetails.adminComments">---</div>
            </div>
        </div>
            <br>
            <br>
        </div>
        <div class="btns__block" *ngIf="documentDetails && documentDetails.status == 'Pending'">
            <div class="hori">
                <button class="common-btn-style" (click)="sendRemainder()">Send Reminder</button>
                <button class="common-btn-style" (click)="goToMarkAsComplete()">Mark Complete</button>
                <button class="cncl-btn-style" (click)="cancelRequest()">Cancel Request</button>
            </div>
        </div>
    </div>
</div>
<app-common-popup (showHideCancelPopup)='documentsRequestPopupStatusFun($event)' *ngIf="displayMarkAsComplete">
    <app-patient-mark-as-complete [popupTitle]="popupData" (closePopup)="documentsRequestPopupStatusFun($event)"></app-patient-mark-as-complete>
</app-common-popup>
