import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TOAST_STATUSES } from '@app/common/constants/util.constant';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';

@Component({
  selector: 'app-cancel-booking',
  templateUrl: './cancel-booking.component.html',
  styleUrls: ['./cancel-booking.component.scss']
})
export class CancelBookingComponent implements OnInit {
  name: any = 'Jon';
  @Input() cancelData;
  @Input() isAdmin;
  @Input() callPatientAPI;
  @Output() showHideCancelPopup = new EventEmitter();
  @Output() cancelAppointmentSubmit = new EventEmitter();
  showHideCancelPopupStatus: boolean = true;
  radioForm: any;
  saveFormData: boolean = false;
  form: any;
  removeUser: boolean = false;
  cancelBody: {};

  constructor(private formService: CustomFormHandlerService, private http: HttpService, private observable: ObservableHelperService, ) { }

  ngOnInit() {
    console.log(this.cancelData);
    this.form = this.formService.initForm(this.formService.formTypes.CANCELFEEDBACK);
  }

  showHideCancelPopupStatusChange() {
    this.showHideCancelPopupStatus = !this.showHideCancelPopupStatus;
    this.showHideCancelPopup.emit({ cancelpopup: this.showHideCancelPopupStatus, removeUser: this.removeUser });
  }
  resetSaveFormParity() {
    window.setTimeout(() => {
      this.saveFormData = false;
    }, 0);
    return true;
  }
  saveForm() {
    this.saveFormData = true;
  }
  getFormData(data) {
    if (data.valid) {
      console.log(this.cancelData);
      let currentDate = new Date();
      let diffTime: any = (currentDate.getUTCHours() - currentDate.getHours()) + ':' + (currentDate.getUTCMinutes() - currentDate.getMinutes());
      this.cancelBody = {
        userId: this.cancelData.userId,
        locationType: this.isAdmin ? this.cancelData.locationType : this.cancelData.location.locationType,
        type: 'CANCEL',
        reason: {
          reason: data.value.reason,
          details: data.value.reasonDetails
        },
        timeDiff: diffTime
      };
      this.cancelAppointment(this.cancelBody);
      this.removeUser = true;
      console.log(this.cancelBody);
    }
  }
  cancelAppointment(data) {
    let payload = {
      type: 'PUT',
      url: (this.cancelData.type === 'adminappointments') ? 'appointmentDetails' : 'cancelAppointment',
      isDeveloper: true,
      pathVariables: [this.cancelData.appointmentId],
      body: data
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.showHideCancelPopupStatusChange();
        this.cancelAppointmentSubmit.emit(res);
        if (this.isAdmin) {
          this.observable.showToast(TOAST_STATUSES.SUCCESS, 'This appointment has been cancelled successfully');
        }
      } else {
        let message = 'Failed to cancel the appointment. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      this.form.formGroup.reset();
      this.form.formInputs.every(form => {
        if (form.key === 'reasonDetails') { form.count = '150 remaining'; this.callPatientAPI = true; return false; }
        return true;
      });
    });
  }
}
