import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { ComingSoonWrapperComponent } from './coming-soon-wrapper/coming-soon-wrapper.component';

const routes: Routes = [
  {
    path: ROUTE_PATHS.EMPTY,
    component: ComingSoonWrapperComponent
  },
  {
    path: '**',
    component: ComingSoonWrapperComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ComingSoonRoutingModule { }
