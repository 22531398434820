<div class="patient__page-wrapper user-details">
    <div class="background-image">
        <img src="assets/images/pngs/newpatientform-background.png" alt="">
    </div>
    <div class="patient__content" *ngIf="isShowScreen && permissionsDone">
        <!-- header part -->
        <div class="patient__header">
            <app-breadcrumb [breadCrumbData]="breadCrumbData"></app-breadcrumb>
            <!-- Actions list -->
            <div class="patient__actions" [ngClass]="!adminPermissions.USER_EDIT || userDetails.removedFlag?'breadcrumb_padding':''">
                <ng-container *ngIf="adminPermissions.USER_EDIT && !userDetails.removedFlag">
                    <ng-container *ngFor="let eachAction of userDetailsActions; let i=index ">
                        <div *ngIf="eachAction.show" class="patient__action" [ngClass]="eachAction.name | lowercase"
                           (click)="userDetailsActionsTrigger(eachAction,userDetails)">
                            <app-svg [ngClass]="eachAction.className" [name]="eachAction.icon"></app-svg>
                            <div class="patient__action-name">{{eachAction.name | titlecase}} </div>
                        </div>
                    </ng-container>
                </ng-container>
                
            </div>
        </div>
        <div class="patient__main-content">
            <div class="patient__details-block">
                <div class="patient__main-title">
                    {{userDetails.userName | titlecase}}
                </div>
                <div class="patient__offline-pending-status">
                    <p class="{{userDetails.status | lowercase}}">{{userDetails.status | titlecase}}</p>
                </div>
                <!-- patient bank  details -->
                <div class="patient__module-details">
                    <div class="patient__addvanced-data">
                        <div class="patient__advanced-content">
                            <div class="patient__advanced-content-title">
                                <p class="title-text">First Name</p>
                                <div class="patient__advanced-content-data">
                                    {{userDetails.firstName}}
                                </div>
                            </div>
                        </div>
                        <div class="patient__advanced-content">
                            <div class="patient__advanced-content-title">
                                <p class="title-text">Last Name</p>
                                <div class="patient__advanced-content-data">
                                    {{userDetails.lastName}}
                                </div>
                            </div>
                        </div>
                        <div class="patient__advanced-content">
                            <div class="patient__advanced-content-title">
                                <p class="title-text">Email ID</p>
                                <div class="patient__advanced-content-data">
                                    {{userDetails.email}}
                                </div>
                            </div>
                        </div>
                        <div class="patient__advanced-content">
                            <div class="patient__advanced-content-title">
                                <p class="title-text">Create Time</p>
                                <div class="patient__advanced-content-data">{{userDetails.createTime | dateFormat}}</div>
                                <!-- <div class="patient__advanced-content-data"  *ngIf='patientsDetails.patientHeight == "---"'> -- </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Appointment section starts -->
            <div class="patient__history-block">
                <div class="patient__main-title">
                <!-- Tabs Filter -->
                <app-tab-view [tabsData]='filtersTabText' (selectedTab)="selectedTab($event)"></app-tab-view>
                </div>
                <ng-container *ngIf="selectedTabIndex == 0">
                    <!-- Upcoming appointments starts here -->
                    <div class="appointment__list-block" *ngIf="patientsList.length !== 0">
                        <ng-container *ngFor="let patient of patientsList; let i=index">
                        <app-patient-list [adminPermissions]="adminPermissions" [dontShowActions]="true" [treatmentsCount]="treatmentsCount" [patientData]="patient" (moreOptionTextClick)="showDeletePatientPopUp($event)"></app-patient-list>
                        </ng-container>
                    </div>
                    <!-- Upcoming appointments ends here -->
                    <!-- Pagination component starts here -->
                    <app-pagination *ngIf="patientCount > 10" [paginationRawData]="paginationData"
                        (pageChanged)="pageChangedEvent($event)">
                    </app-pagination>
                    <!-- Pagination component ends here -->
                    <div class="empty__list--wrapper" *ngIf="patientsList.length === 0">
                        <app-empty-list-data [emptyMessageData]="emptyAppointmentsListMessages">
                        </app-empty-list-data>
                    </div>
                </ng-container>
            </div>
            <!-- Appointment section ends starts -->
        </div>
    </div>
</div>

<app-common-popup class="delete-popup" (showHideCancelPopup)='cancelPopupShowHideStatusFun($event)' *ngIf="popupShowHide">
    <div class="popup-content-block">
        <div class="confirm-message">{{popupMessage}}</div>
        <div class="btn-actions">
            <div class="common-btn-style yes-btn" (click)="cancelPopupShowHideStatusFun($event,false)">No</div>
            <div class="common-btn-style" (click)="cancelPopupShowHideStatusFun($event,true);popupShowHide = false">Yes</div>
        </div>
    </div>
</app-common-popup>