<section class="request__therapist__section">
    <div class="close__icon" (click)="back()">
        <app-svg name='plus-circle'></app-svg>
    </div>
    <div class="header__wrapper">
        {{header}}
    </div>
    <div class="form__wrapper custom-form">
        <div class="checkbox-slider form__field__wrapper" *ngIf="!therapistDetails">
            <div class="checkbox-slider">
                <!--slider UI start-->
                <div class="checkbox-label">Have you been in therapy before?</div>
    
                <div class="slidebox"><input id="'isTherapist'" type="checkbox" name="'isTherapist'" [(ngModel)]="isTherapist"><label for="'isTherapist'">
                        <div class="query">
                            <div class="query-yes">Yes</div>
                            <div class="query-no">No</div>
                        </div>
                    </label></div>
                <!--slider UI end-->
            </div>
        </div>
        <div class="text-area-block form__field__wrapper" *ngIf="!therapistDetails && !isTherapist">
            <div class="sts">Therapist Name</div>
            <Input class="text-area input" type="text" placeholder="Enter details here" value="" [(ngModel)]="therapistName" (input)="onValueChange('name')" ngDefaultControl />
            <div class="error--msg">
                <div *ngIf="(therapistNameValidation) && formSubmit" class="invalid-feedback">
                    <div>{{therapistNameValidation}}</div>
                </div>
            </div>
        </div>
        <div class="text-area-block form__field__wrapper" *ngIf="!therapistDetails && !isTherapist">
            <div class="checkbox-slider form__field__wrapper">
                <div class="checkbox-label">Do you have therapist email Id?</div>
                <div class="slidebox">
                    <input id="'isTherapistEmail'" type="checkbox" name="'isTherapistEmail'" [(ngModel)]="isTherapistEmail" > 
                    <label for="'isTherapistEmail'">
                    <div class="query">
                        <div class="query-yes">Yes</div>
                        <div class="query-no">No</div>
                    </div>
                    </label>
                </div>
            </div>
            <div class="req-therapist-email-block" *ngIf="!isTherapistEmail">
                <div class="sts">Therapist Email Id</div>
            </div>
            <div *ngIf="!isTherapistEmail">
                <Input class="text-area input" type="text" placeholder="Enter details here" value="" [(ngModel)]="therapistEmail" (input)="onValueChange('email')" ngDefaultControl *ngIf="!isTherapistEmail"/>
                <div class="error--msg">
                    <div *ngIf="(therapistEmailValidation) && formSubmit" class="invalid-feedback">
                        <div>{{therapistEmailValidation}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!therapistDetails && !isTherapist" class="form__field calendar form__field__wrapper">
            <div class="sts">Last therapy date</div>
            <p-calendar [readonlyInput]="true" dateFormat="mm/dd/yy"
                [monthNavigator]="true" [yearNavigator]="true"
                yearRange="{{todayDate.getFullYear()-90}}:{{todayDate.getFullYear()+90}}"
                [dataType]="'date'"
                placeholder="select date" [(ngModel)]="therapyDate"
                [minDate]=""
                [maxDate]="todayDate">
            </p-calendar>
            <div *ngIf="therapyDate=='' && formSubmit" class="invalid-feedback">
                        <div>Please select date</div>
                    </div>
        </div>
        <div class="checkbox-slider form__field__wrapper" *ngIf="!isTherapist">
            <div class="checkbox-slider">
                <!--slider UI start-->
                <div class="checkbox-label">Do you have the Therapist Notes/Documents with you?</div>
    
                <div class="slidebox"><input id="'patientHaveTherapistNotes'" type="checkbox" name="'patientHaveTherapistNotes'" [(ngModel)]="patientHaveTherapistNotes"><label for="'patientHaveTherapistNotes'">
                        <div class="query">
                            <div class="query-yes">Yes</div>
                            <div class="query-no">No</div>
                        </div>
                    </label></div>
                <!--slider UI end-->
            </div>
        </div>
        
        <div class="upload-section form__field__wrapper" *ngIf='!patientHaveTherapistNotes && !isTherapist'>
            <div class="sts">Therapist Notes/Documents</div>
            <div class="form__field multi_upload patient__upload__field">
                <p-fileUpload name="patient_uploads[]" #fileUpload [auto]="true" chooseLabel="Choose files to Upload"
                    accept=".jpg, .png, .gif, .jpeg, .tiff, .psd, .pdf, .esp, .ai, .indd, .raw, .heic, .heif"
                    maxFileSize="10000000" multiple="multiple" (onSelect)="onUpload($event,fileUpload)"
                    (onRemove)="filesRemoved($event,fileUpload)" (onClear)="allFilesCleared($event)" fileLimit="5">
                    <ng-template pTemplate="content">
                        <p class="content-message">Or Drag and Drop them here
                            <span class="helpTxt">(maximum 5 allowed)</span>
                        </p>
                    </ng-template>
                </p-fileUpload>
            </div>
            <div class="error-msg days" *ngIf="uploadedFiles && uploadedFiles.length == 0"><span>Please upload minimum 1
                    document</span></div>
            <div class="error-msg days"
                *ngIf="fileValidation == false && uploadedFiles && uploadedFiles.length != 0 && uploadedFiles.length <= '5'">
                <span>Please upload valid documents</span></div>
            <div class="error-msg days" *ngIf="fileValidation == false && uploadedFiles && uploadedFiles.length > '5'">
                <span>Maximum 5 documents are allowed to upload. Please remove extra documents</span></div>

        </div>

        <div *ngIf='patientHaveTherapistNotes && !isTherapist' class="form__field checkbox-tik">
            <div class="checkbox-flex">
                <div class="checkbox-tik">
                    <input type="checkbox" id="sendTherapist" [name]="sendTherapistEmail"
                        [(ngModel)]="sendTherapistEmail" [checked]="sendTherapistEmail" >
                    <label for="sendTherapist"></label>
                </div>
                <div>
                    <div class="label">I authorize the release of any and all personal medical information, health records, progress notes, laboratory, diagnostic and /or surgical procedures to Dr. Rama Prayaga at Fax - (866) 528 -6229. Please include all diagnostic studies, physician findings and/or diagnoses.</div>
                </div>
            </div>

            <div *ngIf="!sendTherapistEmail && formSubmit" class="invalid-feedback">
                Please accept this to proceed
            </div>
        </div>

        <div class="btn btn-section">
            <button class="cncl cancel-btn" (click)="back()">Cancel</button>
            <button class="common-btn-style" (click)="sumbitForm()"> Submit </button>
        </div>
    </div>
</section>