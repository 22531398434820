import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaqsRoutingModule } from '@app/pages/faqs/faqs-routing.module';
import {TabViewModule} from 'primeng/tabview';

import { CommonAppModule } from '@app/common-app.module';
import { FaqsWrapperComponent } from './faqs-wrapper/faqs-wrapper.component';

const DECLARATION_COMPONENTS = [
  FaqsWrapperComponent,
];

@NgModule({
  declarations: [
    ...DECLARATION_COMPONENTS,

  ],
  imports: [
    CommonModule,
    TabViewModule,
    CommonAppModule,
    FaqsRoutingModule
  ],
  exports: []
})
export class FaqsModule { }
