import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-aboutus-card',
  templateUrl: './aboutus-card.component.html',
  styleUrls: ['./aboutus-card.component.scss']
})
export class AboutusCardComponent implements OnInit {

  @Input() cardData = {
    ketamineClass: 'ketamine__card-block',
    ketamineHeading: 'Ketamine Treatment',
    ketamineDescription: 'A new groundbreaking therapy, found to be a rapid-acting antidepressant in depression & helps in decreasing suicidal ideation.',
    ketamineIcon: 'ketamine',
    imgPath: 'approach-treatment1.jpg',
    imgLabel: 'treatment'
  };

  constructor() { }

  ngOnInit() {
  }

}
