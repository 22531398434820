<div class="check-wrapper">
    <!--category start-->
    <div class="overlay-label" [ngClass]="{'check__intermediate': groupCheckBox.isIntermediate}">
        <p-checkbox [value]="groupCheckBox.value" [ngModelOptions]="{standalone: true}" [(ngModel)]="groupCheckBox.isSelected" label="{{groupCheckBox.categoryLabel}}" binary="true" (onChange)="categorySelect($event, formFieldIndex)" [disabled]="!groupCheckBox.editable">
        </p-checkbox>
    </div>
    <!--category end-->
    <!--tasks start-->
    <div class="task__block">
        <div class="in-review-checkbox" *ngFor="let task of groupCheckBox.options; let taskID = index">
            <p-checkbox [value]="task.permissionName" label="{{task.permissionName | titlecase}}" [ngModelOptions]="{standalone: true}" [(ngModel)]="task.isSelected" binary="true" (onChange)="optionSelect($event, taskID, formFieldIndex)" [disabled]="!task.editable">
            </p-checkbox>
        </div>
    </div>
    <!--tasks end-->
</div>