import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { API_URLS } from '@app/common/constants/api-urls.constant';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';
import { IBreadCrumbI, IformDataI, IGetMethodPayload, IPostMethodPayload } from '@app/common/constants/typeInterfaces.constants';
import { HTTPMethods, TOAST_STATUSES } from '@app/common/constants/util.constant';
import { CognitoService } from '@app/common/services/cognito.service';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';

@Component({
  selector: 'app-adhd-questionnaire',
  templateUrl: './adhd-questionnaire.component.html',
  styleUrls: ['./adhd-questionnaire.component.scss']
})
export class AdhdQuestionnaireComponent implements OnInit {
  header:string = `Adult ADHD Self Reporting Questionnaire`
  instructions: string = `Please answer the questions below, rating yourself on each of the criteria shown using the
  scale on the right side of the page. As you answer each question, place an X in the box that
  best describes how you have felt and conducted yourself over the past 6 months. Please give
  this completed checklist to your healthcare professional to discuss during today’s
  appointment`;
  currentDate:Date = new Date();
  formData:IformDataI;
  bodyData: Record<string, any>;
  documentDetails: Record<string, any>;
  requestId: string;
  patientId: any;
  patientsList: Array<any>;
  patientData: Array<any>;
  adhdRequests: Array<any>;
  constructor(private route:ActivatedRoute, private formService:CustomFormHandlerService, private router:Router, private http:HttpService, private observableHelperService: ObservableHelperService, private cognitoService: CognitoService) { 
    // this.route.params.subscribe(params => {
    //   this.requestId = params.id;
    //   this.getRequestedDocumentDetails();
    // });
    this.route.queryParams.subscribe(queryParams =>{
      if(queryParams.requestId){
        this.requestId = queryParams.requestId;
        this.getRequestedDocumentDetails();
      }else if (queryParams.patientId){
        this.patientId = queryParams.patientId;
        this.getRequestId();
      }else{
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR,TOAST_MESSAGES.ADHD_REQUEST_NOT_FOUND_MSG);
        this.back();
      }
    })
  }

  ngOnInit() {
    this.formData = this.formService.initForm(this.formService.formTypes.ADHD_QUESTIONNAIRE);
  }

  getRequestId(){
    this.cognitoService.getUser().then(user => {
      this.getPatientsList(user.userId);
    });
  }

  

  /**
   * 
   * @param userId :User Id
   * This function is for getting list of patient
   */
  getPatientsList(userId){
    const payload:IGetMethodPayload = {
      type: HTTPMethods.GET,
      url: API_URLS.GET_PATIENT_LIST,
      isDeveloper: true,
      pathVariables: [userId]
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res) && res) {
        this.patientsList = res.data.patients;
        this.patientData = this.patientsList.filter(each => parseInt(each.patientId) === parseInt(this.patientId));
        if(this.patientData && this.patientData.length){
          this.adhdRequests = this.patientData[0].pendingDocumentRequest.filter(eachReq => eachReq.documentIds === '[1]');
          if(this.adhdRequests && this.adhdRequests.length){
            this.requestId = this.adhdRequests[0].requestId;
            this.getRequestedDocumentDetails();
          } else {
            this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.ADHD_REQUEST_NOT_FOUND);
            this.router.navigate([ROUTE_PATHS.ADD_MyACCOUNT]);
          }
        }
        console.log(this.patientData);
      }else{
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.PATIENT_LIST_ERROR_MSG);
      }
    });
  }
  // This function is for getting the request document details
  getRequestedDocumentDetails() {
    const payload:IGetMethodPayload = {
      type: HTTPMethods.GET,
      url: API_URLS.REQUEST_DOCUMENT_DETAILS,
      isDeveloper: true,
      pathVariables: [this.requestId],
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.documentDetails = res.data;
      } else {
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.DOCUMENT_DATA_REQUEST_ERROR_MSG);
      }
    });
  }
  /**
   * @param event :Form Group Data
   * This function is for on submiting the form
   */
  getFormData(event?) {
    if (event) {
      this.bodyData = event.value;
      delete this.bodyData[''];
      const payload: IPostMethodPayload = {
        type: HTTPMethods.POST,
        url: API_URLS.ADHD_QUESTIONNAIRE,
        pathVariables:[this.documentDetails.patientId,this.requestId],
        isDeveloper: true,
        body: this.bodyData,
      };
      this.http.makeHttpRequest(payload).subscribe((res) => {
        if (this.http.isSuccessfulResponse(res) && res) {
          this.observableHelperService.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.ADHD_QUESTIONNAIRE_SUCCESS_MSG);
          this.back();
        }else{
          let message = TOAST_MESSAGES.ADHD_QUESTIONNAIRE_ERROR_MSG;
          message = (res.error) ? res.error.message : message;
          this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
        }
      })
    }
  }

  /**
   * Navigate to the previous page
   */
  back(event?:MouseEvent){
    if(this.formData && this.formData.formGroup){
      this.formData.formGroup.reset();
    }
    this.router.navigate([ROUTE_PATHS.ADD_MyACCOUNT]);
  }


}
