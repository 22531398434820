<div class="appointment__list-page">
    <div class="background-image">
        <img src="assets/images/pngs/newpatientform-background.png" alt="bg image">
    </div>
    <!-- Main Appointment List Page Wrapper -->
    <div class="appointment__list-main-wrapper padding-lr">
        <!-- Appointments List -->
        <div class="appointment__list-navigation-menu" *ngIf="permissionsDone">
            <app-breadcrumb [breadCrumbData]="breadCrumbData"></app-breadcrumb>
            <!-- Primary action on Page -->
            <div class="appointment__list-primary-actions">
<!-- // import and export dropdown styles -->
               
                <p type="text" pButton label="Import" (click)="op.toggle($event)" class="appointment-import-btn"></p>
                <p-overlayPanel #op [showTransitionOptions]="'100ms'" [hideTransitionOptions]="'100ms'" [appendTo]="'body'">
                   <ul class="p-overlay-list">
                       <li href="#" (click)="op.hide(); importFile(); setCheckElement('PMP')">
                        <label  class="import-labels">PMP Sheet</label>
                        
                       </li>
                       <li (click)="op.hide(); importFile(); setCheckElement('Verification')">
                        <label  class="import-labels">Verification Sheet</label>

                       </li>
                       <li (click)="op.hide(); importFile(); setCheckElement('TMS Authorization')">
                        <label  class="import-labels">TMS Authorization Sheet</label>
                        
                       </li>
                       <li >
                        <label for="medications-file-import" class="import-labels">Medications Sheet</label>
                        <input type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" value="Medications Sheet" class="import-medsheet" id="medications-file-import" (change)="addfile($event, 'medicationsExcelSheet')" [(ngModel)]="medicationsValue"> 
                       </li>
                    </ul>
                </p-overlayPanel>
                <p type="text" pButton label="Export" (click)="pop.toggle($event)" class="appointment-export-btn"></p>
                <p-overlayPanel #pop [showTransitionOptions]="'100ms'" [hideTransitionOptions]="'100ms'" [appendTo]="'body'">
                   <ul class="export-overlay-list">
                       <li (click)="exportAsXLSX()">
                        Appointment
                       </li>
                       <li *ngFor="let item of exportList"  (click)="clickExportItem(item)">
                        {{item.name}}
                       </li>
                    </ul>
                </p-overlayPanel>
                
                <!-- <div class="common-btn-style" (click)="exportAsXLSX()">
                    Export
                </div> -->
<!-- // import and export dropdown styles -->
                <div class="common-btn-style" (click)="createNew()" *ngIf="adminPermissions.APPOINTMENT_CREATE">
                    Create New
                </div>
            </div>
        </div>
        <!-- Filters section -->
        <div class="appointment__list-filetr-wrapper">
            <!-- Tabs Filter -->
            <app-tab-view [tabsData]='filtersTabText' (selectedTab)="selectedTab($event)"></app-tab-view>
            <!-- Advance filter view  -->
            <div class="appointment__list-filter-view">
                <div class="appointment__list-filter-view-buttons" [class.border]="appointmentsTotalListPerPage.length === 0" *ngIf="viewByFilterShowHide">
                    <!--radio-btn field start-->
                    <!-- Radio buttons -->
                    <p class="appointment__list-filter-view-title">View by :</p>

                    <div class="form__field">
                        <div class="custom__radio-button">
                            <input type="radio" id="today" value="today" name="date" (change)="viewByFilters('today')" [(ngModel)]="radioValue">
                            <label for="today" class="radio-label"><span class="icon--styles">
                                </span>Today</label>
                        </div>
                    </div>
                    <div class="form__field">
                        <div class="custom__radio-button">
                            <input type="radio" id="nextWeek" value="nextWeek" name="date" (change)="viewByFilters('nextWeek')" [(ngModel)]="radioValue">
                            <label for="nextWeek" class="radio-label"><span class="icon--styles">
                                </span>Next 7days</label>
                        </div>

                    </div>
                </div>
                <!-- Advanced filters -->
                <div class="appointment__list-advanced-filters">
                    <div class="appointment__list-search-filter">
                        <app-list-search (searchText)="searchFilter($event)"></app-list-search>
                    </div>
                    <div class="appointment__list-date-filter" (click)="filterAppointments()">
                        <app-svg [name]="'filter'"></app-svg>
                    </div>
                </div>
            </div>
            <!-- Advance filter view -->
        </div>
        <!-- Filter section end -->
        <ng-container>
            <!-- Upcoming appointments starts here -->
            <div class="appointment__list-block" *ngIf="appointmentsTotalListPerPage.length != 0 && isShowScreen && permissionsDone">
                <ng-container *ngFor="let appointment of appointmentsTotalListPerPage; let i=index">
                    <app-appointments-list [adminPermissions]="adminPermissions" [treatmentsCount]="treatmentsCount" (moreOptionTextClick)="getOptionsData($event)" [appointmentList]="appointment">
                    </app-appointments-list>
                </ng-container>
            </div>
            <!-- Upcoming appointments ends here -->
            <!-- Pagination component starts here -->
            <ng-container> 
                <app-pagination [paginationRawData]="paginationData" (pageChanged)="pageChangedEvent($event)" *ngIf="appointmentListCount >10 && isShowScreen"></app-pagination>
            </ng-container>
            <!-- Pagination component ends here -->
            <!-- Empty list Starts here -->
            <app-empty-list-data [emptyMessageData]="emptyAppointmentsListMessages" *ngIf="appointmentsTotalListPerPage.length === 0 && isShowScreen"></app-empty-list-data>
            <!-- Empty list ends here -->
        </ng-container>
    </div>
    <!-- sideBar -->
    <div class="appointment__list-side-bar">
        <p-sidebar [(visible)]="sideBarVisible" position="right" styleClass="p-sidebar-width" (onShow)="checkingSidebarState(true)" (onHide)="checkingSidebarState(false)" showCloseIcon=false>
            <app-filters [filterData]="rawFilterData" [filterType]="'appointments'" [isFilterClear]="isFilterClear" [isFilterPanelClosed]="sideBarVisible" (clearEvent)="clearEventCall($event)" (submitEvent)="filterDataEvent($event);checkingSidebarSetStatus(false)"></app-filters>
        </p-sidebar>
    </div>
    <!-- sideBar -->
</div>

<!-- Block patient Ui -->
<app-common-popup (showHideCancelPopup)='blockPatientPopupShowHideStatusFun($event)' *ngIf="blockPatientPopupShowHide">
    <app-block-patient (submitEvent)="blockPatientSubmit($event);blockPatientPopupShowHide=false" [filterInputs]="forminputs" [filterData]="rawTreatmentFilterData"></app-block-patient>
</app-common-popup>
<!-- End block patient end -->

<!-- sideBar -->
<app-common-popup (showHideCancelPopup)='generateInvoicePopupShowHideStatusFun($event)' *ngIf="generateInvoicePopupShowHide">
    <app-generate-invoice (totalInvoiceAmount)='generateInvoice($event);generateInvoicePopupShowHideStatusFun($event)'>
    </app-generate-invoice>
</app-common-popup>
<app-common-popup (showHideCancelPopup)='makeAsPaidPopupShowHideStatusFun($event)' *ngIf="makeAsPaidPopupShowHide">
    <app-make-as-paid (makeAsPaidData)="generateInvoice($event)" (hidePopUp)="makeAsPaidPopupShowHideStatusFun($event)"></app-make-as-paid>
</app-common-popup>
<!-- Sidebar end -->
<!-- Cancel the appointment -->
<app-cancel-booking [isAdmin]="isAdmin" [cancelData]="cancelAppointmentData" (showHideCancelPopup)='cancelPopupShowHideStatusFun($event)' *ngIf="cancelPopupShowHide"></app-cancel-booking>

<!--  -->
<app-common-popup (showHideCancelPopup)='missedColumnsPopup($event)' *ngIf="missedColumnsPopupFlag" class="excelMissedClmPopup">
    <div class="popup-content-block">
        <div class="confirm-message" >
            <div>Selected sheet doesn't have the following required columns: </div>
           <ul class="missed-column-names-list">
               <ng-container *ngFor="let column of missedColumns; let i = index">
                   <li *ngIf="column">
                    {{column}}
                   </li>
               </ng-container>
           </ul>
         </div>
        <div class="btn-actions">
            <div class="common-btn-style yes-btn" (click)="missedColumnsPopup($event,false)">Cancel</div>
        </div>
    </div>
</app-common-popup>
<!--  -->
<app-common-popup (showHideCancelPopup)='selectMedSheetData=false' *ngIf="selectMedSheetData" class="select-med-sheet-data-popup">
  <div class="popup-content-block">
    <div class="popup-box-heading">Export {{currentlySelectedExportItem.name}} For</div>
    <div class="radio-box-container">
        <div class="custom__radio-button">
          <input type="radio" id="todaysheet" value="todaysheet" name="day"
            [(ngModel)]="radioValueForMedicationSheet">
          <label for="todaysheet" class="radio-label"><span class="icon--styles">
            </span>Today</label>
        </div>
        <div class="custom__radio-button">
          <input type="radio" id="tomorrow" value="tomorrow" name="day"
            [(ngModel)]="radioValueForMedicationSheet">
          <label for="tomorrow"  class="radio-label"><span class="icon--styles">
            </span>Tomorrow</label>
        </div>
    </div>

    <div class="btn-actions">
      <div class="cancel-btn" (click)="selectMedSheetData=false; radioValueForMedicationSheet='';">Cancel</div>
      <div class="common-btn-style" (click)="popUpFunction()">Submit</div>
    </div>
  </div>
</app-common-popup>

<app-common-popup (showHideCancelPopup)='selectImportSheetData=false' *ngIf="selectImportSheetData"
  class="select-med-sheet-data-popup">
  <div class="popup-content-block">
    <div class="popup-box-heading">Import {{clickedElement}} Sheet For </div>
    <div class="radio-box-container">
      <div class="custom__radio-button">
        <input type="radio" id="todaysheet" value="todaysheet" name="today" [(ngModel)]="radioValueForMedicationSheet">
        <label for="todaysheet" class="radio-label"><span class="icon--styles">
          </span>Today</label>
      </div>
      <div class="custom__radio-button">
        <input type="radio" id="tomorrow" value="tomorrow" name="today" [(ngModel)]="radioValueForMedicationSheet">
        <label for="tomorrow" class="radio-label"><span class="icon--styles">
          </span>Tomorrow</label>
      </div>
    </div>
    <input *ngIf="clickedElement =='PMP'" type="file"  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" value="PMP Sheet" class="import-pmp" id="pmp-file-import" style="visibility: hidden;"   (change)="addfile($event, 'pmpExcelSheet')" [(ngModel)]="pmpValue"> 
    <input *ngIf="clickedElement =='Verification'" type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" value="Verification Sheet" class="import-copay" id="verification-file-import" (change)="addfile($event, 'verificationExcelSheet')" [(ngModel)]="verifValue"> 
    <input *ngIf="clickedElement =='TMS Authorization'" type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" value="TMS Authorization Sheet" class="import-verify" id="tms-auth-file-import" (change)="addfile($event, 'tmsAuthExcelSheet')" [(ngModel)]="tmsAuthValue"> 

    <div class="btn-actions">
      <div class="cancel-btn" (click)="selectImportSheetData=false; radioValueForMedicationSheet='';">
        Cancel</div>
      <div class="common-btn-style" (click)="openTypeFile()">Submit</div>
    </div>
  </div>
</app-common-popup>

<!--  pop up box to show pmp sheet data of the patient -->
<app-common-popup (showHideCancelPopup)='pmpPopupShowHideStatusFun($event)' *ngIf="pmpPopupShowHide">
    <div class="pmp-content-block"> 
        <div class="pmp-box-heading"> PMP sheet data </div>
        <div class="pmp-box-scroll">
            <div class="pmp-box-subheading" > Medications </div>
            <!-- <ul class="pmp-confirm-message" *ngIf="pmpMeds.length !== 0">
                <li *ngFor="let line of pmpMeds">{{line}}</li>
            </ul> -->
            <div  class="pmp-confirm-message" *ngIf="pmpMeds.length !== 0" >{{pmpMeds}}</div>
            <div class="pmp-confirm-message empty-message" *ngIf="pmpMeds.length === 0">No Medication Avaliable</div>
            
            <div class="pmp-box-subheading" > PMP </div>
            <!-- <ul class="pmp-confirm-message" *ngIf="pmpData.length !== 0">
                <li *ngFor="let line of pmpData">{{line}}</li>
            </ul> -->
            <div  class="pmp-confirm-message" *ngIf="pmpData.length !== 0" >{{pmpData}}</div>
            <div class="pmp-confirm-message empty-message" *ngIf="pmpData.length === 0"> No PMP Avaliable</div>
        </div>
        
    </div>
</app-common-popup>

<section class="paymentPage" >
    <form method="POST"  ngNoForm [action]="paymentRedirectUrl" id="formAuthorizeNetPage" name="formAuthorizeNetTestPage">
        <input type="hidden" id = "paymentvalue" name="token" [value]="paymentToken" />
        <!-- Continue to Authorize.net to Payment Page:  -->
        <button id="btnContinue">Click here to make payment</button>
    </form> 
</section>