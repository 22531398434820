import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { DataService } from '@app/common/services/data.service';

@Component({
  selector: 'app-gad-questionnaire',
  templateUrl: './gad-questionnaire.component.html',
  styleUrls: ['./gad-questionnaire.component.scss']
})
export class GadQuestionnaireComponent implements OnInit {

  @Input() saveFormData;
  @Input() filledTMSDetails;
  @Input() isReview;
  @Output() submitEvent: EventEmitter<any> = new EventEmitter();

  formData: any;

  constructor(private formService: CustomFormHandlerService, private dataService: DataService) { }

  ngOnInit() {
    console.log(this.formService.gadQuestionnaireFormConfig);
    this.formData = this.formService.initForm(this.formService.formTypes.GAD_QUESTIONNAIRE);
    this.formData.formInputs.forEach(form => {
      if (this.filledTMSDetails && this.filledTMSDetails.gadQuestionnaire && form.key) {
        Object.keys(this.filledTMSDetails.gadQuestionnaire).every(key => {
          if (key.toLowerCase() === form.key.toLowerCase()) {
            form.editable = this.isReview ? false : true;
            form.value = this.filledTMSDetails.gadQuestionnaire[key];
            return false;
          }
          return true;
        });
      }
    });
  }

  ngAfterViewInit(): void {

  }
  getSliderValue(event) {
    console.log(event);
    let slider = event.slider;
    let index  = event.index;
  }

  getFormData(event) {
    console.log(event);
    console.log(this.formData);
    if (event) {
      this.formService.gadQuestionnaireFormConfig = this.assignFormValues(event, this.formService.gadQuestionnaireFormConfig);
      this.dataService.setData(this.dataService.dataObject.GAD_QUESTIONNAIRE, this.formService.gadQuestionnaireFormConfig);
      this.submitEvent.emit(event);
    }
  }

  assignFormValues(formData, formConfig) {
    let keys = Object.keys(formData.value);
    formConfig.forEach((each, index) => {
      if (keys.includes(each.key)) {
        each.value = formData.value[each.key];
        each.validators = this.formData.formGroup.controls[each.key].validator;
      }
    });
    return formConfig;
  }

}
