<section class="patient__main-wrapper" *ngIf="isShowScreen && permissionsDone">
    <!--Right side background leaf starts here -->
    <div class="background-image">
        <img src="assets/images/pngs/newpatientform-background.png" alt="bg image">
    </div>
    <!-- Right side background leaf ends here -->
    <!-- Main section starts here -->
    <div class="patient__wrapper content__wrapper">
        <!-- Breadcrumb section starts here -->
        <div class="patient__list-navigation-menu">
            <app-breadcrumb [breadCrumbData]="breadCrumbData"></app-breadcrumb>
            <!-- Primary action on Page -->
            <div class="patient__list-primary-actions">
                <div class="common-btn-style manage-accounts" (click)="manageUserAccounts()" *ngIf="adminPermissions.USER_LIST">
                    Manage User Accounts
                </div>
                <div class="common-btn-style" *ngIf="adminPermissions.PATIENT_CREATE" (click)="createNew()">
                    Create New
                </div>
            </div>
        </div>
        <!-- Breadcrumb section ends here -->
        <!-- Advanced filters -->
        <div class="patient__list-advanced-filters {{adminPermissions.PATIENT_CREATE ? '' : 'margin'}}">
            <div class="patient__list-search-filter">
                <!--  [isOpenSearch] = "isOpenSearch" -->
                <app-list-search [defSearchValue]="searchInputText" [searchPlaceHolderText]="searchPlaceHolderText" (searchText)="searchFilter($event)"></app-list-search>
            </div>
            <div class="patient__list-date-filter" (click)="filterAppointments()">
                <app-svg [name]="'filter'"></app-svg>
            </div>
        </div>
        <div class="patient__list--block">
            <ng-container *ngIf="patientsList.length !== 0">
                <div class="patient__list--wrapper">
                    <div class="each__patient" *ngFor="let patient of patientsList">
                        <app-patient-list [adminPermissions]="adminPermissions" [dontShowActions]="patient.removedFlag" [treatmentsCount]="treatmentsCount" [patientData]="patient" (moreOptionTextClick)="patientListActions($event)" (deletPatient)="deletePatient($event)"></app-patient-list>
                    </div>
                </div>
            </ng-container>
            
            <!-- Pagination component starts here -->
            <ng-container>
                <app-pagination [paginationRawData]="paginationData" (pageChanged)="pageChangedEvent($event)" *ngIf="patientListCount > 10"></app-pagination>
            </ng-container>
            
            <!-- Pagination component ends here -->
            <!-- Empty list Starts here -->
            <app-empty-list-data [emptyMessageData]="emptyAppointmentsListMessages" *ngIf="patientsList.length === 0"></app-empty-list-data>
            <!-- Empty list ends here -->
        </div>
    </div>
    <!-- sideBar -->
    <div class="patient__list-side-bar">
        <p-sidebar [(visible)]="sideBarVisible" position="right" styleClass="p-sidebar-width" (onShow)="checkingSidebarState(true)" (onHide)="checkingSidebarState(false)" showCloseIcon=false>
            <app-filters [filterType]="'patient'" [filterData]="patientFilterData" [filterInputs]="patientFilterInputs" [isFilterPanelClosed]="sideBarVisible"  (submitEvent)="filterDataEvent($event);checkingSidebarSetStatus(false,'sidePanel')"></app-filters>
        </p-sidebar>
    </div>
    <!-- sideBar -->
    <!-- Main section ends here -->
</section>
<app-common-popup (showHideCancelPopup)='blockPatientPopupShowHideStatusFun($event)' *ngIf="blockPatientPopupShowHide" class="block-poc-popup">
    <div class="stepper__block">
        <app-step-indicator [stepDetails]="stepDetails" (stepIndexClicked)="stepIndicatorClicked($event)"
            (forwardClicked)="forwardClicked()" (backClicked)="backClicked()" (sendStepIndex)="getStepIndex($event)"
            [currentStepIndex]="currentFormIndex"></app-step-indicator>
    </div>
    <div class="form--block" [ngSwitch]="currentStep.id">
        <div class="common-stepper-inner" *ngSwitchCase="'selectedTreatment'" class="appointment__block">
            <ng-container>
                <app-block-patient (submitEvent)="blockPatientSubmited($event)" [filterInputs]="forminputs" [filterData]="rawFilterData" [saveFormData]="saveFormData "></app-block-patient>
            </ng-container>
        </div>
        <div class="common-stepper-inner" *ngSwitchCase="'selectedLocations'" class="appointment__block">
            <ng-container>
                <app-block-location (changeLocationEvent)="changedLocation($event)" [selectedTreatmentData]="selectTreatmentData">
                </app-block-location>
            </ng-container>
        </div>
    </div>
    <div class="common-stepper-inner" class="btn-actions">
        <div class="cancel-btn" (click)="cancel($event)">Cancel</div>
        <div class="common-btn-style btn--inline prev-btn" (click)="back()" *ngIf="currentFormIndex >0">Prev</div>
        <div class="common-btn-style btn--inline" (click)="saveForm()">{{getActionText()}}</div>
    </div>
</app-common-popup>
<app-common-popup (showHideCancelPopup)='documentsRequestPopupStatusFun($event)' *ngIf="displayDocumentsRequestPopup && documentsRequest.length">
    <app-patient-documents-request [patientId]="patientId" [documentsRequest]="documentsRequest"  (closePopup)="closeDocumentRequestPopup($event)"></app-patient-documents-request>
</app-common-popup>

<app-common-popup class="edit-poc-popup" (showHideCancelPopup)='editUserPopupStatusFun($event)' *ngIf="displayEditUserPopup">
    <app-user-profile-admin [data]="patientData.poc" (submitEvent)="submitUserProfie($event)" ></app-user-profile-admin>
</app-common-popup>

<app-common-popup (showHideCancelPopup)='labDocumentsRequestPopupStatusFun($event)' *ngIf="displayLabDocumentsRequestPopup && labDocumentsRequest.length">
    <app-patient-lab-documents-request [patientId]="patientId" [documentsRequest]="labDocumentsRequest"  (closePopup)="closeLabDocumentRequestPopup($event)"></app-patient-lab-documents-request>
</app-common-popup>