<!-- aboutus-approach card block start -->
<div class="aboutus-approach__card-block">
    <div class="aboutus-approach__image">
        <div class="image__block">
            <img src="assets/images/jpgs/{{cardData.imgPath}}" alt="{{cardData.imgLabel}} image">
        </div>
    </div>
    <div class="aboutus-approach__card">
        <app-ketamine-card [ketamineData]="cardData"></app-ketamine-card>
    </div>
</div>
<!-- aboutus-approach card block end -->