
export const environment = {
  production: false,
  // serverUrl: "http://localhost:4200/",
  serverUrl: 'https://mudmh1pdwd.execute-api.us-east-1.amazonaws.com/prod',
  paymentRedirectUrl:'https://accept.authorize.net/payment/payment',
  blogUrl: 'https://newteledoc.com/blog',
  // serverUrl: 'https://qdylahw8gg.execute-api.us-east-1.amazonaws.com/staging',
  authenticationNeeded: false,
  token: ''
};

// demo

// export const poolData = {
//   UserPoolId: 'us-east-1_BPO2a7NAa',
//   ClientId: '74e7j5b871g1fpsdvibv1dt8rk'
// };

// staging

// export const poolData = {
//   UserPoolId: 'us-east-1_adW6b9U1A',
//   ClientId: '3gp5j5doii1j6gsrdlg683ct1q'
// };

export const poolData = {
  UserPoolId: 'us-east-1_sXJEfDfSc',
  ClientId: '87in02st8tdoqkl1ii0d30sgc'
};


export const admin = {
  email: 'officemanager@newstressclinic.com'
};

