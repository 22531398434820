import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { mergeMap, catchError, map, tap } from 'rxjs/operators';
import { from as observableFrom, Observable, of } from 'rxjs';
import { ObservableHelperService } from './observable-helper.service';
import { environment } from '@src/environments/environment';
import { CognitoService } from '@app/common/services/cognito.service';
import { Auth } from 'aws-amplify';
import { DataService } from './data.service';
import { Router } from '@angular/router';
import { HTTPMethods, TOAST_STATUSES } from '../constants/util.constant';
import { API_URLS } from '../constants/api-urls.constant';
import { ROUTE_PATHS } from '../constants/routing.constant';
import { TOAST_MESSAGES } from '../constants/toast-messages.constant';


const APIList = {
  // ---Local APIs
  shedularData: 'shedularData',
  usersList: 'usersList',
  locationsList: 'locations',
  locationDetails: 'locationDetails',
  editEmployee: 'edit-employee',
  dashboardGrid: 'dashboardGrid',

  // adminData: 'adminData',
  // ---server APIs
  // user: '/user',
  createPatient: '/patient',
  reference: '/reference',
  patient: '/patient',
  getPatient: '/patient?userId={userId}',
  ediPatient: '/patient/{paitentId}',
  getOnePatient: '/patient/{paitentId}/?userId={userId}',
  appointment: '/appointment',
  availability: '/availability',
  getAppointment: '/appointment?userId={userId}',
  getSingleAppointment: '/appointment/{appointmentId}?userId={userId}',
  cancelAppointment: '/appointment/{appointmentId}',
  editAappointment: '/appointment/{appointmentId}',
  getUploadUrl: '/upload',
  editUser: '/edit-user?userId={userId}',
  createUser: '/user',
  completeTmsForms: '/completetmsforms/{patientId}',
  controlSubstance:'/controlsubstance/{patientIs}',
  // Admin calls starts here
  adminappointment: '/adminappointmentdatalist',
  employee: '/employee',
  editemployee: '/employee/{employeeId}',
  createEmployee: '/employee/add',
  getemployee: '/employee/{userId}',
  employeeLocations: '/employee/locations',
  empArchive: '/employee/{employeeId}?status={status}',
  filterData: '/adminappointment/filter',
  exportData: '/adminappointment/export-mail',
  permissions: '/employee/permissions',
  invoiceDetails: '/invoice/{invoiceId}?type={invType}',
  invoiceList: '/invoice',
  appointmentDetails: '/adminappointment/{appointmentId}',
  patientsDetails: '/adminpatient/{patientId}',
  createAppointment: '/adminappointment/add',
  historyAppointment: '/patient/{patientId}',
  invoiceFilter: '/invoice/filter',
  patientList: '/adminpatient',
  checkIsAdmin: '/admin-check',
  searchEmail: '/user-mail',
  adminPatientCreate: '/create-patient',
  adminEdiPatient: '/adminpatient/{paitentId}',
  patientActions: '/adminpatient/{patientId}',
  patientArchive: '/adminpatient/{patientId}?status={status}',
  uploadsFiles: '/file-upload',
  uploadFileDelete: '/file-delete',
  patientFilter: '/adminpatientlist-filter',
  deletePatient: '/delete-adminpatient',
  adminlocationsList: '/location',
  createLocation: '/location/create',
  editLocation: '/location/edit',
  getLocationDetails: '/location/{locationId}',
  adminGlobalSearch: '/adminappointment/globalsearch?searchKey={searchKey}',
  adminProfile: '/profile',
  adminProfileEdit: '/profile/edit',
  notifications: '/notifications',
  notificationsCount: '/notifications-count',
  admindashboardGrid: '/adminappointment-dashboard',
  adminReshedule: '/adminappointment/{appointmentId}',
  adminPatientSearch: '/adminpatient-search',
  adminAppointmentConfim: '/appointment/{appointmentId}/confirm',
  blockSlot: '/slot/block',
  getBlockSlotDetails: '/slot/{slotId}/block',
  deleteBlockSlot: '/slot/{slotId}',
  adminLastValid: '/appointment/last-valid',
  lastAppointmentData: '/appointment/last-appointment-data',
  generateInvoice: '/invoice/add',
  generateInvoiceRequest: '/invoice/document-request-add',
  uploadDocs: '/admin-upload-patient-doc-request',
  createInvoice: '/invoice/patient-add',
  invoiceResend: '/invoice/{invoiceId}/resend',
  invoiceMarkasPaid: '/invoice/{invoiceId}/mark-paid',
  invoiceDelete: '/invoice/{invoiceId}',
  defaultServices: '/all-treatments',
  invoiceValidate: '/invoice/{invoiceId}/validate',
  invoiceCancel: '/invoice-err/{invoiceId}',
  insuranceEmail: '/patient-mails',
  getPermissions: '/access',
  patienConform: '/patient-confirm-doc-request',
  patienConformComments:'/patient-confirm-doc-request',
  conformDocComments:'/confirm-patient-doc-request',
  patientSendChanges:'/changes-patient-doc-request',

  // Payment Gateway
  checkoutSession: '/create-checkout-session',
  checkoutSessionUser: '/create-checkout-session-user',
  lastActivity: '/activity',
  // getting TMS filled details
  pendingTMS: '/patient-updates/{patientId}',
  // resend tms forms
  resendTMS: '/resend-tms/{patientId}',
  // document request
  adminRequestDetails: '/admin-request-details/{requestId}',
  patientRequestDetails: '/patient-doc-requests/{requestId}',
  employeList: '/employee',
  rejectDoc: '/reject-patient-doc-request',
  conformDoc: '/confirm-patient-doc-request',
  addAdminRequest: '/add-admin-request',
  addPatientRequest: '/add-patient-doc-request',
  adminRequestDocuments: '/admin-request-documents?requestType={requestType}',
  patientRequestDocuments: '/patient-documents-list?requestType={requestType}',
  uploadAdminRequestDocument: '/upload-admin-request-documents',
  adminRequest: '/admin-request/{requestId}',
  documentList: '/admin-requests/{patientId}',
  sendReminder: '/admin-request-reminder/{requestId}',
  // Email Template Urls
  emailTemplateList: '/email',
  excludePatients: '/block-list',
  emailTemplateDetails: '/email/{id}',
  adhdQuestionnaire: '/adhd/{patientId}/request/{requestId}',
  phqQuestionnaire: '/phq9/{patientId}/request/{requestId}',
  substanceForm: '/controlsubstance/{patientId}/request/{requestId}',
  adminKetamineMonitoring: '/admin-ketamine-monitoring/{appointmentId}',
  reqDocList: '/doc-requests',
  requestDOcs: '/patient-doc-requests',
  requestDocsPatient: '/user-doc-requests',
  docFilters: '/docFilters',
  therapistInfoRequest: '/therapist-info/{patientId}/request/{requestId}',
  unsubscribe: '/unsubscribe',
  medicationFeedback: '/medication-feedback/{patientId}/request/{requestId}',
  userForgotPassword: '/user-mail/forgot-password',
  labDocsDetails: '/documentRequest/{labDocId}',
  updateLabDoc: '/edit-documentRequest',
  userList: '/user-list',
  userDetails: '/user/{userId}',
  deleteUser: '/user-remove/{userId}',
  resetUserPassword: '/admin-reset-password/{userId}',
  informationRequest: '/information-request/{patientId}/appointment/{appointmentId}/request/{requestId}',
  informationRequestDynamicForm: '/information-request-questions',
  deleteEachPatient: '/patient-remove/{patientId}',
  cognitoGetUser: '/cognito/user',
  cognitoUpdateAttributes: '/cognito/updateUser',
  cognitoGlobalSignOut: '/cognito/globalSignOut',
  cognitoAdminCreateUser: '/cognito/adminCreateUser',
  uploadFile: '/md-toolbox/upload',
  exportFile: '/md-toolbox/export',
  replaceFile: '/md-toolbox/replace-sheet',
  medicationExport: '/md-toolbox',
  editPoc: '/edit-poc',
  states: '/states',
  postGenerateDoc:'/generate-documents',
  medicalSummary: '/medical-summary',
  //mednotes
  getPatientMedNotes: '/getPatientMedNotes/{patientId}?appointmentId={appointmentId}&userId={userId}',
  addMedNotes: `/addMedNotes/{patientId}/{appointmentId}?userId={userId}&mednoteType={mednoteType}`,
  patientAppointmentsMednotes: '/patientAppointmentsMednotes/{patientId}/{apptType}',
  editMedNotes: '/editMedNotes/{patiendId}/{mednoteId}',
  getInvoiceDetails: '/get-invoice/{invoiceId}',
  getPaymentLink: '/add/transection',
  addtmsnotes: '/addtmsnotes/{userId}/{patientId}',
  editTmsNotes: '/edittmsnotes/{userId}/{patientId}/{tmsNoteId}',
  getalltmsnotes: '/getalltmsnotes/{patientId}',
  gettmsnotes: '/gettmsnotes/{userId}/{patientId}/{tmsnoteid}',
  deleteTmsNote:'/delete/tmsnote/{userId}/{patientId}/{tmsnoteid}',
  deleteMednote: '/delete/mednote/{userId}/{patientId}/{mednoteId}',
  getSignedUrl: '/unsignedurl_to_signedurl',
  excelToDataFormat: '/excel_to_dataFormat'
};
@Injectable({
  providedIn: 'root'
})
export class HttpService {

  url: string;
  serverUrl: string;
  localUrl: string;
  developerUrl: string;
  headers: any;
  loading = {};
  activeAPICalls = 0;
  tmsLoader: boolean = false;
  // urlsArr = ['adminappointment', 'invoiceList', 'adminGlobalSearch'];
  constructor(
    private http: HttpClient,
    private obsHelper: ObservableHelperService,
    private dataService: DataService,
    // tslint:disable-next-line:no-shadowed-variable
    private CognitoService: CognitoService,
    private router: Router
  ) {
    this.localUrl = 'assets/json-data/';
    this.serverUrl = '';
    this.developerUrl = environment.serverUrl;
  }

  /**
   *
   * @param options - object for Http Request
   * options={
   * type   : enum('GET','POST','PUT','DELETE'),
   * url    : string [httpUrl keyword],
   * isLocal: boolean [local API call or server call (optional)]
   * isDeveloper:boolean [developer API call or server call (optional)]
   * data   : json [data for post or put call]
   * }
   */
  makeHttpRequest(options) {
    // Showing loader
    if (!options.donotShowLoader) {
      this.showThrobber(options);
    }
    if (options.isExternal) {
      try {
        // this.getUserDetails();
        if (options.contentType) {
          options.headers = new HttpHeaders({
            'Content-Type': options.contentType
          });
        } else {
          options.headers = this.requestHeader(options);
        }
        console.log("options",options)
        return this.http
          .request(options.type, options.url, { body: options.body, headers: options.headers })
          .pipe(
            map(res => {
              this.checkForSessionError(res);
              delete this.loading[options.buildtUrl];
              let loadings = Object.keys(this.loading);
              if (!(loadings && loadings.length)) {
                if (!options.donotShowLoader) {
                  this.hideThrobber();
                }
              }
              let response: any = res;
              return response;
            })
          )
          .pipe(catchError(this.handleError.bind(this)));


      } catch (error) {
        console.log(error, 'at 110 line');
        delete this.loading[options.buildtUrl];
        let loadings = Object.keys(this.loading);
        if (!(loadings && loadings.length)) {

          if (!options.donotShowLoader) {
            this.hideThrobber();
          }
        }
      }
    } else {


      this.url = this.getApiUrl(options);
      options.buildtUrl = this.url;
      let authToken;
      if (options.isAuthToken) {
        this.headers = this.requestHeader(options);
        var data: any = {
          headers: this.headers,
          params: options.params ? options.params : null,
          body: options.body ? options.body : null
        };

        try {
          // this.loading[options.buildtUrl] = true;

          return this.http
            .request(options.type, options.buildtUrl, data)
            .pipe(
              map(res => {
                this.checkForSessionError(res);
                delete this.loading[options.buildtUrl];
                let loadings = Object.keys(this.loading);
                if (!(loadings && loadings.length)) {

                  if (!options.donotShowLoader) {
                    this.hideThrobber();
                  }
                }
                let response: any = res;
                return response;
              })
            )
            .pipe(catchError(this.handleError.bind(this)));


        } catch (error) {
          console.log(error, 'at 157 line');
          delete this.loading[options.buildtUrl];
          let loadings = Object.keys(this.loading);
          if (!(loadings && loadings.length)) {
            if (!options.donotShowLoader) {
              this.hideThrobber();
            }
            // this.utils.commonConfig.isLoader = false;
          }
        }
      } else {
        // this.getUserDetails();
        return observableFrom(Auth.currentSession()).pipe(
          mergeMap(session => {
            authToken = session.getIdToken().getJwtToken();

            this.headers = this.requestHeader(options, authToken);

            var headersData: any = {
              headers: this.headers,
              params: options.params ? options.params : null,
              body: options.body ? options.body : null
            };

            try {
              // this.utils.commonConfig.isLoader = true
              // this.loading[options.buildtUrl] = true;
              // this.showThrobber();

              return this.http
                .request(options.type, options.buildtUrl, headersData)
                .pipe(
                  map(res => {
                    this.checkForSessionError(res);
                    delete this.loading[options.buildtUrl];
                    let loadings = Object.keys(this.loading);
                    if (!(loadings && loadings.length)) {

                      if (!options.donotShowLoader) {
                        this.hideThrobber();
                      }
                      // this.utils.commonConfig.isLoader = false;
                    }
                    let response: any = res;
                    return response;
                  })
                )
                .pipe(catchError(this.handleError.bind(this)));


            } catch (error) {
              console.log(error, 'at 207 line');
              delete this.loading[options.buildtUrl];
              let loadings = Object.keys(this.loading);
              if (!(loadings && loadings.length)) {
                if (!options.donotShowLoader) {
                  this.hideThrobber();
                }
                // this.utils.commonConfig.isLoader = false;
              }
            }
          }), catchError(err => {
            this.hideThrobber();
            this.dataService.clearData();
            this.dataService.clearPreviousData();
            this.CognitoService.signOut(() => {
              window.localStorage.clear();
              this.router.navigate(['login']);
              this.obsHelper.showToast(TOAST_STATUSES.ERROR, 'Session expired. Please login again...');
            });
            return of(err);
          }));
      }
    }

  }

  /**
   *
   * @param options :-Object for InLine Http Request
  *options={
     * type   : enum('GET','POST','PUT','DELETE'),
     * url    : string [httpUrl keyword],
     * isLocal: boolean [local API call or server call (optional)]
     * isDeveloper:boolean [developer API call or server call (optional)]
     * data   : json [data for post or put call]
     * }
   */
  makeInLineHttpRequest(options) {
    this.url = this.developerUrl + options.url;
    this.headers = this.requestHeader(options);
    var data: any = {
      headers: this.headers,
      params: options.params ? options.params : null,
      body: options.body ? options.body : null
    };

    try {
      // this.utils.commonConfig.isLoader = true
      return this.http
        .request(options.type, this.url, data)
        .pipe(
          map(res => {
            this.checkForSessionError(res);
            delete this.loading[this.url];
            let loadings = Object.keys(this.loading);
            if (!(loadings && loadings.length)) {
              // this.utils.commonConfig.isLoader = false;
            }
            let response: any = res;
            return response;
          })
        )
        .pipe(catchError(this.handleError.bind(this)));
    } catch (error) {
      console.log(error, 'at 264 line');
      delete this.loading[options.buildtUrl];
      let loadings = Object.keys(this.loading);
      if (!(loadings && loadings.length)) {
        if (!options.donotShowLoader) {
          this.hideThrobber();
        }
        // this.utils.commonConfig.isLoader = false;
      }
    }
  }

  makeHttpRequestHelper(options) {
    if (!options.donotShowLoader) {
      this.showThrobber(options);
    }
    this.url = this.getApiUrl(options);
    this.headers = this.requestHeader(options);
    var data: any = {
      headers: this.headers,
      params: options.params ? options.params : null,
      body: options.body ? options.body : null
    };

    try {
      // this.utils.commonConfig.isLoader = true
      return this.http
        .request(options.type, this.url, data)
        .pipe(
          map(res => {
            this.checkForSessionError(res);
            delete this.loading[this.url];
            let loadings = Object.keys(this.loading);
            if (!(loadings && loadings.length)) {
              // this.utils.commonConfig.isLoader = false;
              if (!options.donotShowLoader) {
                this.hideThrobber();
              }
            }
            let response: any = res;
            return response;
          })
        )
        .pipe(catchError(this.handleError.bind(this)));
    } catch (error) {
      console.log(error, 'at 264 line');
      delete this.loading[options.buildtUrl];
      let loadings = Object.keys(this.loading);
      if (!(loadings && loadings.length)) {
        if (!options.donotShowLoader) {
          this.hideThrobber();
        }
        // this.utils.commonConfig.isLoader = false;
      }
    }
  }

  handleError(obj) {
    console.log(obj, 'handle error console log');
    delete this.loading[this.url];
    this.hideThrobber();
    return of({ status: false, message: 'Internal Server Error' });
  }

  /**
   *
   * @param options :{Object}
   * options={
   * type   : enum('GET','POST','PUT','DELETE'),
   * url    : string [httpUrl keyword],
   * isLocal: boolean [local API call or server call (optional)]
   * isDeveloper: boolean [development URL],
   * pathVariables:Array for path Variables
   * data   : json [data for post or put call]
   * }
   */
  getApiUrl(options) {
    // console.log('APIList',APIList)
    if (options.url) {
      let url;
      // local APi call
      if (APIList[options.url]) {
        if (options.isLocal !== undefined && options.isLocal === true) {
          url = this.localUrl + APIList[options.url] + '.json';
        }
        else if (options.isDeveloper !== undefined && options.isDeveloper === true) {
          url = this.developerUrl + APIList[options.url];
        }
        else {
          // server Call
          url = this.serverUrl + APIList[options.url];
        }
        if (!options.isLocal && options.pathVariables) {
          options.pathVariables.forEach(element => {
            url = url.replace(/\{.*?\}/, element);
            // url += '/' + element;
          });
        }
        return url;
      }
      else {
        return 'Path could not be found in the list';
      }
    }
    else {
      return 'Path not Defined';
    }
  }

  requestHeader(options, token?) {
    let header;
    var sessionId = '';
    if (token) {
      header = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      });
    } else {
      header = new HttpHeaders({
        'Content-Type': 'application/json',
      });
    }
    return header;
  }

  showThrobber(options) {
    this.obsHelper.toggleThrobber(true);
    // if (this.urlsArr.includes(options.url)) {
    //   this.obsHelper.toggleMessage(true);
    // }
    this.activeAPICalls++;
  }

  hideThrobber() {
    this.activeAPICalls--;
    if (this.activeAPICalls <= 0) {
      this.obsHelper.toggleThrobber(false);
    }
  }

  checkForSessionError(res) {
    if (/* User not logged in*/ true) {
      // logout user
    }
  }

  /**
   * To check the response from backend is successful
   * @param res response from backend
   */
  isSuccessfulResponse(res) {
    return (res && res.status === 'success');
  }

  async getUserDetails() {
    if ((this.CognitoService.user && this.CognitoService.user.admin == 0)) {
        let isAdmin = '0';
        let isVerified = 'true';
        let params = {
            Username: this.CognitoService.user.userId
        };
        let payload = {
            type: HTTPMethods.POST,
            url: API_URLS.COGNITO_GET_USER,
            isDeveloper: true,
            body: params
        };
        this.makeHttpRequestHelper(payload).subscribe(res => {
            if (this.isSuccessfulResponse(res)) {
              let userDetails = res.data.userDetails;
              userDetails.UserAttributes.forEach(x => {
                if (x.Name === 'custom:admin') {
                  isAdmin = x.Value;
                } else if (x.Name === 'email_verified') {
                  isVerified = x.Value;
                }
              });
              if ((isVerified === 'false' || !userDetails.Enabled) && isAdmin === '0') {
                this.CognitoService.signOut(() => {
                  window.localStorage.setItem('isLogedIn', JSON.stringify(false));
                  this.obsHelper.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.LOGIN_SESSION_EXP);
                  this.router.navigate([`${ROUTE_PATHS.ONBOARD}`]);
                });
              }
            } else {
                let message = 'Failed to fetch the user details. Please try again';
                message = (res.message) ? res.message : message;
                this.obsHelper.showToast(TOAST_STATUSES.ERROR, message);
            }
        });
    }
}

}
