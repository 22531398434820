import { NgModule } from '@angular/core';
import { CommonAppModule } from '@app/common-app.module';
import { CommonModule } from '@angular/common';
import { RequestDocumentRoutingModule } from './request-document-routing.module'
import { RequestDocumentComponent } from './request-document/request-document.component';



@NgModule({
  declarations: [RequestDocumentComponent],
  imports: [
    CommonAppModule,
    CommonModule,
    RequestDocumentRoutingModule  
  ]
})
export class RequestDocumentModule { }
