import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { TOAST_STATUSES } from '@app/common/constants/util.constant';
import { CognitoService } from '@app/common/services/cognito.service';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { DataService } from '@app/common/services/data.service';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-tms-forms-wrapper',
  templateUrl: './tms-forms-wrapper.component.html',
  styleUrls: ['./tms-forms-wrapper.component.scss']
})
export class TmsFormsWrapperComponent implements OnInit {
  currentStep = {
    label: 'TMS Questionnaire',
    id: 'tmsQuestionnaire',
    img: 'one',
    visited: true,
    notVisisted: false,
    completed: false,
    onPage: true
  };
  // input for step indicator
  stepDetails = [
    {
      label: 'TMS Questionnaire',
      id: 'tmsQuestionnaire',
      img: 'HIPPA Form',
      visited: true,
      notVisisted: false,
      completed: false,
      onPage: true
    },
    {
      label: 'HAM-D',
      id: 'hamQuestionnaire',
      img: 'HIPPA Form',
      visited: false,
      notVisisted: true,
      completed: false,
      onPage: false
    },
    {
      label: 'GAD-7',
      id: 'gadQuestionnaire',
      img: 'HIPPA Form',
      visited: false,
      notVisisted: true,
      completed: false,
      onPage: false
    },
    {
      label: 'PHQ-9',
      id: 'phQuestionnaire',
      img: 'HIPPA Form',
      visited: false,
      notVisisted: true,
      completed: false,
      onPage: false
    }
  ];
  currentFormIndex: number = 0;
  isMobile: any;
  saveFormData: boolean = false;
  hideSubmit: boolean = false;
  jsonData: any = [
    {},
    {},
    {},
    {}
  ];
  patientId: any;
  appointmentId: any;
  currentUrl: any;
  queryParams: any;
  appointmentDetails: any;
  appDetailsDisplay: boolean = false;
  isShowScreen: boolean = false;
  isAdmin: boolean = false;
  isReview: boolean = false;
  filledTMSDetails: any;
  loading: boolean = false;
  constructor(
    private router: Router,
    private observable: ObservableHelperService,
    private cognitoService: CognitoService,
    private http: HttpService,
    private dataService: DataService,
    private formService: CustomFormHandlerService,
    private route: ActivatedRoute,
    ) {
    // tslint:disable-next-line:variable-name
    router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((e: any) => {
        this.currentUrl = e.url;
        console.log(this.currentUrl);
        // for checking admin
        this.currentUrl.includes('admin') ? this.isAdmin = true : this.isAdmin = false;
        if (this.currentUrl.includes('pending') || this.currentUrl.includes('edit') || this.currentUrl.includes('review')) {
          this.loading = true;
        }
        // for retreiving patId based on url
        this.patientId = this.isAdmin ? this.currentUrl.split('/').pop().split('?')[0] : this.currentUrl.split('/').pop();
        this.queryParams = this.route.queryParams;
      });
    }

  ngOnInit() {// to get the appt details by checking whether urls includes admin
    if (this.isAdmin) {
      this.appointmentId = this.queryParams.value.appId;
      this.getAppointmentDetails();
    }
    // to get the filled details by checking whether it is a edit/review or redirect call
    if (this.loading) {
      this.getFilledDetails();
      this.currentUrl.includes('review') ? this.isReview = true : this.isReview = false;
    }
    window.localStorage.removeItem('patId');
    window.localStorage.removeItem('tms-redirect');
    if (!this.currentUrl.includes('pending') && !this.isAdmin) {
      this.isShowScreen =  true;
    }
  }
  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    this.dataService.clearData();
    this.resetServiceConfigData();
  }

  getActionText() {
    return this.currentFormIndex < (this.stepDetails.length - 1) ? 'Next' : this.isAdmin && !this.appointmentDetails.isOffline ? 'Send to Patient' : 'Submit';
  }
  getStepIndex(event) {
    this.stepIndicatorClicked(event);
  }

  stepIndicatorClicked(index: number): void {
    if (index < 3) { this.hideSubmit = false; }
    if (index > this.currentFormIndex) {
      this.saveFormData = true;
      this.saveForm();
    } else {
      this.scrollTop();
      this.stepDetails[this.currentFormIndex].onPage = false;
      this.currentFormIndex = index;
      this.currentStep = this.stepDetails[index];
      this.stepDetails.forEach((each, ind) => {
        if (ind >= this.currentFormIndex) {
          each.completed = false;
        }
      });
      this.stepDetails[index].onPage = true;
    }
  }

  forwardClicked() {
    this.saveForm();
  }
  backClicked() {
    this.back();
  }

  scrollTop() {
    window.scroll({
      top: 0,
      left: 0,
    });
  }
  resetSaveFormParity() {
    window.setTimeout(() => {
      this.saveFormData = false;
    }, 0);
    return true;
  }
  getFormData(event) {
    if (event && !this.stepDetails[this.currentFormIndex].completed) {
      console.log(event);
      this.jsonData[this.currentFormIndex] = event;
      this.stepDetails[this.currentFormIndex].completed = true;
      this.stepDetails[this.currentFormIndex].onPage = false;
      this.saveFormData = false;
    } else {
      if (this.stepDetails[this.currentFormIndex].completed && this.currentFormIndex !== this.stepDetails.length - 1) {
        this.stepDetails[this.currentFormIndex].completed = false;
      }
    }
  }

  saveForm() {
    this.saveFormData = true;
    if (this.currentFormIndex === (this.stepDetails.length - 1)) {
      this.currentStep = this.stepDetails[this.currentFormIndex];
      this.scrollTop();
      window.setTimeout(() => {
        this.navigateToDetails();
      }, 10);
    } else {
      window.setTimeout(() => {
        if (this.stepDetails[this.currentFormIndex].completed) {
          this.currentFormIndex++;
          this.currentStep = this.stepDetails[this.currentFormIndex];
          this.stepDetails[this.currentFormIndex].onPage = true;
          this.stepDetails[this.currentFormIndex].notVisisted = false;
          this.stepDetails[this.currentFormIndex].visited = true;
          this.scrollTop();
        }
      }, 5);
      if (this.currentFormIndex === 2 && this.isReview) { this.hideSubmit = true; }
    }
    setTimeout(() => {
      if (document.getElementsByClassName('invalid-feedback')[0]) {
        document.getElementsByClassName('invalid-feedback')[0].scrollIntoView({ block: 'center' });
      }
    }, 2);
  }

  navigateToDetails() {

    let isBelowThreshold = (currentValue) => currentValue.completed === true;
    if (this.stepDetails.every(isBelowThreshold)) {
      console.log(this.jsonData, 'Tms form Data');
      let finalData;
      this.jsonData.forEach(row => {
        finalData = Object.assign({}, finalData, row.value);
      });
      this.cognitoService.getUser().then(user => {
        finalData.userId = user.userId;
        finalData = this.postDataMassage(finalData);
        let payload = {
          type: 'PUT',
          url: 'completeTmsForms',
          pathVariables: [this.patientId],
          isDeveloper: true,
          body: finalData
        };
        this.http.makeHttpRequest(payload).subscribe((res) => {
          if (this.http.isSuccessfulResponse(res) && res) {
            console.log(res, 'Tms Response');
            this.dataService.clearData();
            this.resetServiceConfigData();
            if (this.isAdmin) {
              this.observable.showToast(TOAST_STATUSES.SUCCESS, `TMS Forms have been ${this.currentUrl.includes('edit') ? 'updated and ' : ''}sent to the patient successfully.`);
              this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.APPOINTMENTS}/${this.appointmentId}`]);
            } else {
              this.observable.showConfirmation('booked', 'Thank you! Your details have been submitted successfully.');
              this.router.navigate([`${ROUTE_PATHS.ADD_MyACCOUNT}`]);
              window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });
            }
          } else {
            let error = res.error.message;
            this.observable.showToast(TOAST_STATUSES.WARNING, 'Unable to submit the details. Please try again later.');
          }
        });
      });
    }
  }

  postDataMassage(data) {
    if (data && typeof (data) === 'object') {
      return {
        filledBy: this.isAdmin ? 'admin' : 'user',
        userId: data.userId,
        tmsQuestionnaire: {
          iAcceptTermsConditions: data.iAcceptTermsConditions,
          isCardiacPacemaker: !data.isCardiacPacemaker,
          isCochlearImplants: !data.isCochlearImplants,
          isEpilepsy: !data.isEpilepsy,
          isEverUndergoTMS: !data.isEverUndergoTMS,
          isExistingMedicalCondition: !data.isExistingMedicalCondition,
          isFaintingSpell: !data.isFaintingSpell,
          isFaintingSpell_description: data.isFaintingSpell_description,
          isHearingProblems: !data.isHearingProblems,
          isHeavyGoodsVehicle: !data.isHeavyGoodsVehicle,
          isMedicationInfusion: !data.isMedicationInfusion,
          isMetalBrain: !data.isMetalBrain,
          isNeurostimulator: !data.isNeurostimulator,
          isPregnant: !data.isPregnant,
          isPsychiatricOrNeuroactive: !data.isPsychiatricOrNeuroactive,
          isPsychiatric_Neuroactive_description: data.isPsychiatric_Neuroactive_description,
          isSevereTrauma: !data.isSevereTrauma,
          isTattoos: !data.isTattoos,
          isUndergoMRI: !data.isUndergoMRI
        },
        hamQuestionaire: {
          isAgitation: data.isAgitation,
          isAnxietyPsychic: data.isAnxietyPsychic,
          isAnxietySomatic: data.isAnxietySomatic,
          isDepersonalizationAndDerealization: data.isDepersonalizationAndDerealization,
          isDepressionMood: data.isDepressionMood,
          isDiurnalVariation: data.isDiurnalVariation,
          isFeelingGuilt: data.isFeelingGuilt,
          isGenitalSymptoms: data.isGenitalSymptoms,
          isInsight: data.isInsight,
          isHypochondriasis: data.isHypochondriasis,
          isInsomnia: data.isInsomnia,
          isInsomniaDelayed: data.isInsomniaDelayed,
          isInsomniaMiddle: data.isInsomniaMiddle,
          isObsessionalSymptoms: data.isObsessionalSymptoms,
          isParanoidSymptoms: data.isParanoidSymptoms,
          isRetardation: data.isRetardation,
          isSomaticSymptomsGastrointestinal: data.isSomaticSymptomsGastrointestinal,
          isSomaticSymptomsGeneral: data.isSomaticSymptomsGeneral,
          isSuicide: data.isSuicide,
          isWeightLoss: data.isWeightLoss,
          isWorkAndInterest: data.isWorkAndInterest
        },
        gadQuestionnaire: {
          isBecomingEasilyAnnoyed: data.isBecomingEasilyAnnoyed,
          isBeingSoRestless: data.isBeingSoRestless,
          isFeelingAfraid: data.isFeelingAfraid,
          isFeelingNervous: data.isFeelingNervous,
          isIfYouCheckedOff: data.isIfYouCheckedOff,
          isNotBeingAble: data.isNotBeingAble,
          isTroubleRelaxing: data.isTroubleRelaxing,
          isWorryingTooMuch: data.isWorryingTooMuch
        },
        phQuestionnaire: {
          isDeadThoughts: data.isDeadThoughts,
          isFeelingBad: data.isFeelingBad,
          isFeelingDown: data.isFeelingDown,
          isFeelingTired: data.isFeelingTired,
          isHowDifficult: data.isHowDifficult,
          isLittleInterest: data.isLittleInterest,
          isMovingOrSpeakingSoSlowly: data.isMovingOrSpeakingSoSlowly,
          isPoorAppetite: data.isPoorAppetite,
          isTroubleConcentrating: data.isTroubleConcentrating,
          isTroubleFalling: data.isTroubleFalling
        }
      };
    }
  }
  resetServiceConfigData() {
    let formTags = ['tmsQuestionnaireFromConfig', 'hamQuestionnaireFormConfig', 'gadQuestionnaireFormConfig', 'phQuestionnaireFormConfig'];
    formTags.forEach(each => {
      this.formService[each].forEach(element => {
        element.editable = true;
        if (element.type === 'checkbox') {
          element.value = false;
        } else if (element.type === 'checkbox-slider' ) {
          element.value = true;
        } else if (element.type === 'radio') {
          element.value = '';
        } else if (element.type === 'textarea') {
          element.value = '';
          element.validators = [];
          element.showontoggle = false;
          element.count = '150 remaining';
        }
      });
    });
  }
  setCurrentForm(index) {
    this.currentStep = this.stepDetails[index];
    this.stepDetails[index].completed = false;
    this.stepDetails[index].onPage = true;
  }
  cancel() {
    if (this.appDetailsDisplay) {
      this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.APPOINTMENTS}/${this.appointmentId}`]);
    } else {
      this.router.navigate([`${ROUTE_PATHS.ADD_MyACCOUNT}`]);
    }
    this.resetServiceConfigData();
  }
  back() {
    if (this.currentFormIndex === 0) {
      this.scrollTop();
      this.stepDetails[this.currentFormIndex].onPage = false;
      this.currentFormIndex = 0;
      this.setCurrentForm(this.currentFormIndex);
    } else {
      this.stepDetails[this.currentFormIndex].onPage = false;
      this.currentFormIndex--;
      this.scrollTop();
      this.setCurrentForm(this.currentFormIndex);
    }
    this.hideSubmit = false;
  }

  // getting Filled details if condition satisfies
  getFilledDetails() {
    const payloadData = {
      type: 'GET',
      url: 'pendingTMS',
      isDeveloper: true,
      pathVariables: [this.patientId]
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        let firstParse: any;
        firstParse = JSON.parse(res.data.patientUpdates[0].tmsInfo);
        let secondParse: any;
        secondParse = JSON.parse(firstParse.tmsFormData);
        console.log(secondParse);
        if (secondParse.tmsQuestionnaire.iAcceptTermsConditions) {
          this.observable.showToast(TOAST_STATUSES.WARNING, 'TMS details have been already filled for this patient');
          this.router.navigate([`${ROUTE_PATHS.ADD_MyACCOUNT}`]);
        } else {
          this.filledTMSDetails = secondParse;
        }
      } else {
        // let error = res.error.message;
        this.observable.showToast(TOAST_STATUSES.WARNING, 'Error in getting details. Please try again later.');
      }
      // tslint:disable-next-line:no-unused-expression
      this.isAdmin && !this.appDetailsDisplay ?  setTimeout(() =>  this.isShowScreen = true , 2000) : this.isShowScreen = true;
      // setTimeout(() => { clearInterval(showThrobber); this.observable.toggleThrobber(false); this.isShowScreen = true; }, 3000);
      this.loading = false;
    });
  }
  // Get appointment details API call
  getAppointmentDetails() {
    const payloadData = {
      type: 'GET',
      url: 'appointmentDetails',
      isDeveloper: true,
      pathVariables: [this.appointmentId]
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.appointmentDetails = {
          patientName: res.data.patientBasicInfo.firstName + ' ' + res.data.patientBasicInfo.lastName,
          patientId: res.data.patientId,
          appLocation: res.data.appointmentInfo.location,
          appName: res.data.appointmentInfo.treatment,
          isOffline: res.data.isOffline === 1 ? true : false
        };
      } else {
        // let error = res.error.message;
        this.observable.showToast(TOAST_STATUSES.WARNING, 'Error in getting appointment details');
      }
      this.appDetailsDisplay = true;
      this.isShowScreen = this.loading ? false : true;
    });
  }
}
