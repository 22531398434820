import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { DataService } from '@app/common/services/data.service';

@Component({
  selector: 'app-hippa-notice-form',
  templateUrl: './hippa-notice-form.component.html',
  styleUrls: ['./hippa-notice-form.component.scss']
})
export class HippaNoticeFormComponent implements OnInit {
  @Input() saveFormData;

  // HIPAA notice data, including header and descriptions, used for displaying the form content
  hippaData = {
    header: 'THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE USED AND DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS INFORMATION. PLEASE REVIEW CAREFULLY',
    description1: `I understand that as per the Health Insurance portability & Accountability Act 1996("HIPAA"), I have certain right to privacy regarding my personal Health Information ("PHI"). By signing this notice, I understand that my PHI will be used to:`,
    list: [
      `Conduct plan direct my treatment and following among the multiple health providers who may be involved in that treatment directly and indirectly`,
      `Obtain payment from third party payers`,
      `For specialized government functions(e.g., national security) (45 C.F.R. $ 164.512(K))`,
      `To report child abuse or neglect  (45 C.F.R. $ 164.512(b)(1)(ii))`,
      `To notify  persons responsible for a patient's care of the patient's location and health status (45 C.F.R. & 164.510(b)(1)(ii))`,
      `Incidental to a permissible use or disclosure (45 C.F.R. & 164.502(a)(1)(iii))`,
      `In response to a judicial or administrative order, subpoena, etc. (45 C.F.R. & 164.510(e))`,
    ],
    description2: `I have received, read and understood Notice of privacy Practices containing the description of the uses and disclosure of my PHI. A more detailed description of the users and disclosure of my Privacy Practices from time to time and that I may contact organization at any time at the address above to obtain a current copy of the Notice of Privacy Practices. I understand that I may request in writing that you restrict how my PHI is used and disclosed to carry out treatment, payment or health operation. I also understand you are not required to agree to my requested restrictions, but if you do agree then you are bound to such restrictions.`,
    description3: `No pets are allowed in the office.`
  };
  hippaForm: any;
  autoForm: any;
  @Output() submitEvent: EventEmitter<any> = new EventEmitter();
  autoFill: boolean;
  constructor(private formService: CustomFormHandlerService, private dataService: DataService) { }

    ngOnInit() {
      this.initForms();
      if(this.dataService.previousAppointmentData == null){
        this.autoFill = false;
      }
      else{
        this.autoFill = true;
      }
      // this.hippaForm.formInputs[0].value = true;
    } 

    // Initialize the forms used in the component by fetching their configurations from the form service
    initForms() {
      this.autoForm = this.formService.initForm(this.formService.formTypes.AUTOFILLDETAILS);
      this.hippaForm = this.formService.initForm(this.formService.formTypes.HIPPA);
    }

     // Handle changes in slider value and update the HIPAA form accordingly
    getdata(event){
      this.dataService.previousValue = event.slider.value;
      if(!event.slider.value === true){
        this.hippaForm.formInputs[0].value = true;
      }else{
        this.hippaForm.formInputs[0].value = false;
      }
    }

   // Emit the submit event after processing and saving the form data
    getFormData(event) {
      if (event) {
        this.updateFormService(event);
        this.formService.hippaFormConfig = this.assignFormValues(event, this.formService.hippaFormConfig);
        this.dataService.setData(this.dataService.dataObject.HIPPA, this.formService.hippaFormConfig);
        this.submitEvent.emit({status: event, step: 2});
        console.log(this.hippaForm);
      }
    }

    // Update the HIPAA form configuration with the provided form data values
    updateFormService(formData) {
      this.formService.hippaFormConfig = this.assignFormValues(formData, this.formService.hippaFormConfig);
    }
    
    // Assign values from form data to the corresponding fields in the form configuration
    assignFormValues(formData, formConfig) {
      let keys = Object.keys(formData.value);
      formConfig.forEach((each, index) => {
        if (keys.includes(each.key)) {
          each.value = formData.value[each.key];
        }
      });
      return formConfig;
    }
}
