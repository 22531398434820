import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TOAST_STATUSES } from '@app/common/constants/util.constant';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { formatDate } from '@angular/common';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { CognitoService } from '@app/common/services/cognito.service';

@Component({
  selector: 'app-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.scss']
})
export class AdminProfileComponent implements OnInit {
  // pagination data
  breadCrumbData = {
    link: '/admin',
    linkText: 'My Profile'
  };
  getEmpId: any; // Get Emp id form urs
  // Employee details obj
  employeeData: any = {};
  detailPageUpload = [];
  // Loader
  isShowScreen = false;
  popUpText: string;
  cancelPopupShowHide: boolean = false;
  archiveStatus: boolean = false;
  updatedFilesCount = 0;
  uploadFilesData = {
    patientName: '',
    patientDOB: '',
    updatedFilesCount: this.updatedFilesCount,
  };
  // Employee actions
  employeeDetailsActions: any = [
    {
      id: 0,
      name: 'Edit',
      icon: 'edit',
      className: 'edit',
    },
    {
      id: 1,
      name: 'Change Password',
      icon: 'lock',
      className: 'change-password',
    }
  ];
  uploadUrlBody: { uploadType: string; refId: any; docUrl: any; };
  constructor(private observable: ObservableHelperService, private http: HttpService, private route: ActivatedRoute, private router: Router, private formServices: CustomFormHandlerService) {

  }


  ngOnInit() {
    // tslint:disable-next-line: no-unused-expression
    this.employeeData;
    this.getEmpId = this.route.snapshot.params.id;
    this.getEmployeeData();
  }
  getEmployeeData() {
    const payload = {
      type: 'GET',
      url: 'adminProfile',
      isDeveloper: true,
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        res.data.employeeDetails.status === 'archive' ? this.archiveStatus = true : this.archiveStatus = false;
        this.archiveStatus ?
        this.employeeDetailsActions = this.employeeDetailsActions.map(obj => ({...obj, display: obj.id !== 3 ? false : true})) :
        this.employeeDetailsActions = this.employeeDetailsActions.map(obj => ({...obj, display: obj.id !== 3 ? true : false}));
        this.employeeData = this.massageEmployeeData(res.data);
        console.log(this.employeeData);
        this.uploadFilesData.patientName = this.employeeData.employeeName;
        this.uploadFilesData.patientDOB = this.employeeData.employeeDOB;
        this.uploadFilesData.updatedFilesCount = this.employeeData.employeeIDProfs.length;
        // console.log('Response', res);
        // console.log('this.archiveStatus', this.archiveStatus);
      } else {
        console.log('Response', res);
        this.observable.showToast(TOAST_STATUSES.ERROR, 'Error in getting employee data');
        setTimeout(() => {
          // this.router.navigate(['admin/employee']);
        }, 1000);
      }
      this.isShowScreen = true;
    });
  }
  // Massagging the Data
  massageEmployeeData(data) {
    let picture;
    if (!data && !Array.isArray(data)) { return []; } else {
      picture = data.employeeDetails.extraUploads.filter(obj => obj.uploadType === 'profile'); }
    return {
      ...data,
      employeeName: (!data.employeeDetails.firstName && !data.employeeDetails.lastName) ? '-' :
        (data.employeeDetails.firstName) + ' ' + (data.employeeDetails.lastName),
      employeeProfileImagLink: picture.length > 0 ? picture[0].docUrl : data.employeeDetails.picture,
      employeeSecurityNumber: ((data.employeeDetails.country).toLowerCase() === 'us') ? data.employeeDetails.ssn : data.employeeDetails.aadhar,
      employeeDrivingLicenseNumbber: ((data.employeeDetails.country).toLowerCase() === 'us') ? data.employeeDetails.drivingLicense :
        data.employeeDetails.pan,
      employeeSecurityName1: ((data.employeeDetails.country).toLowerCase() === 'us') ? 'Social Security Number' : 'Adhaar Card Number',
      employeeSecurityName2: ((data.employeeDetails.country).toLowerCase() === 'us') ? 'Driving License Number' : 'Pan Card Number',
      employeeIDProfs: this.messageUploads(data.employeeDetails.extraUploads.filter(obj => obj.uploadType === 'employee')),
      employeePermissions: this.filterPermissions(data.employeePermissions),
      employeeDOB: (data.employeeDetails.dob === null) ? '---' : data.employeeDetails.dob,
      employeeloginActivity: (data.employeeDetails.loginActivity === null) ? '---' : 'Last login: ' + formatDate(new Date(new Date(data.employeeDetails.loginActivity)), 'MMM d, y | h:mm a', 'en'),
    };
  }

  // filter the permisstions
  filterPermissions(data) {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        let category = data[key].permission_category.replace(/[^A-Z0-9]/ig, '_');
        data[key].permission_name = data[key].permission_name.replace(/_/g, ' ');
        data[key].permission_category = category;
      }
    }
    const result = data.reduce((r, a) => {
      r[a.permission_category] = r[a.permission_category] || [];
      r[a.permission_category].push(a);
      return r;
    }, Object.create(null));

    return result;
  }
   // Actions
 employeeActionsClick(id, empId) {
  console.log(empId);
  if (id === 0) {
  this.router.navigate([`${ROUTE_PATHS.ADMIN + '/' + ROUTE_PATHS.PROFILE_EDIT}`], { queryParams: { id: empId, edit: true } });
  } else if (id === 1) {
    this.router.navigate([`${ROUTE_PATHS.ADMIN + '/' + ROUTE_PATHS.ADMIN_CHANGE_PWD}`]);
    } else {
    // Error
  }
}

  // popUpCancel
  cancelPopupShowHideStatusFun(status) {
    this.cancelPopupShowHide = status.cancelpopup;
  }

  // popUpClick event
  popUpClick(event, status) {
    this.cancelPopupShowHide = false;
    // tslint:disable-next-line:no-unused-expression
    status ? this.archiveAPICall(this.popUpText) : null;
  }

  // Archive API call
  archiveAPICall(status) {
    const payloadData = {
      type: 'PUT',
      url: 'empArchive',
      isDeveloper: true,
      pathVariables: [this.getEmpId, status],
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.getEmployeeData();
        this.observable.showToast(TOAST_STATUSES.SUCCESS, `Successfully ${status === 'active' ? 'activated' : 'archived'} the employee.`);
      } else {
        let message = 'Failed to update employee archive status. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }


  // Download file
  donwloadFile(url, fileName, flag) {
    let fullFileName = '';
    if (flag === true) {
      fullFileName = fileName.name + '.' + fileName.extenstion;
    } else {
      fullFileName = fileName.file.name + '.' + fileName.file.extenstion;
    }
    fetch(url).then((t) => {
      return t.blob().then((b) => {
        var a = document.createElement('a');
        a.href = URL.createObjectURL(b);
        a.setAttribute('download', fullFileName);
        a.click();
      }
      );
    });
  }

  uploadFunctionCall(data) {
    this.uploadUrlBody = {
      uploadType: 'employee',
      refId: this.employeeData.employeeDetails.empId.toString(),
      docUrl: data
    };
    this.getUploadedFilesURL(data);
  }
  getUploadedFilesURL(filed) {
    let payloadData;
    payloadData = {
      type: 'POST',
      url: 'uploadsFiles',
      isDeveloper: true,
      body: this.uploadUrlBody,

    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.employeeData.employeeIDProfs.push(res.data);
        this.employeeData.employeeIDProfs = this.messageUploads(this.employeeData.employeeIDProfs);
        if (res.data.docUrl !== '') {
          this.observable.showToast(TOAST_STATUSES.SUCCESS, 'Successfully uploaded file');
        }
      } else {
        let message = 'Failed to update uploading files . Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
  deleteFileUpload(data) {
    let bodyData = {
      uploadType: 'employee',
      refId: this.employeeData.employeeDetails.empId.toString(),
      uploadId: data.uploadId
    };
    let payloadData = {
      type: 'DELETE',
      url: 'uploadFileDelete',
      isDeveloper: true,
      body: bodyData,
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.employeeData.employeeIDProfs = this.messageUploads(this.deleteUpload(data));
        this.observable.showToast(TOAST_STATUSES.SUCCESS, res.data);
      } else {
        let message = 'Failed to delete uploading files . Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
  deleteUpload(data) {
    let uploads = [];
    this.employeeData.employeeIDProfs.forEach((each, index) => {
      if (each.uploadId !== data.uploadId) {
        uploads.push(each);
      }
    });
    return uploads;
  }
  messageUploads(data) {
    // Get all uploads list form API
    let uploadsList = [];
    let fileName;
    data.forEach(eachupload => {
      if (eachupload !== '') {
        let docObj = { ...eachupload };
        fileName = eachupload.docUrl.substring(
          eachupload.docUrl.lastIndexOf('__name__') + 8,
          eachupload.docUrl.lastIndexOf('?')
        );
        docObj['file'] = this.splitFileName(fileName, eachupload.docUrl);
        uploadsList.push(docObj);
      }
    });
    return uploadsList;
  }
    // Split function for the file name
    splitFileName(file, docURL) {
      let fileObj = {};
      let splitFileName = file.split('.');
      fileObj['name'] = splitFileName[0];
      fileObj['extenstion'] = splitFileName[splitFileName.length - 1];
      fileObj['file'] = docURL;
      return fileObj;
    }
}
