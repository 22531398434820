import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ServicesRoutingModule } from '@app/pages/services/services-routing.module';
import {TabViewModule} from 'primeng/tabview';

import { CommonAppModule } from '@app/common-app.module';
import { ServicesWrapperComponent } from './services-wrapper/services-wrapper.component';
import { ServicesContentComponent } from './services-content/services-content.component';
import { ServicesCardComponent } from './services-card/services-card.component';

const DECLARATION_COMPONENTS = [
  ServicesWrapperComponent,
  ServicesContentComponent,
  ServicesCardComponent,
];

@NgModule({
  declarations: [
    ...DECLARATION_COMPONENTS,

  ],
  imports: [
    CommonModule,
    TabViewModule,
    CommonAppModule,
    ServicesRoutingModule,
  ],
  exports: []
})
export class ServicesModule { }
