import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { API_URLS } from '@app/common/constants/api-urls.constant';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';
import { IAdminPermissionsI, IGetPermissionsPayloadI } from '@app/common/constants/typeInterfaces.constants';
import { HTTPMethods, TOAST_STATUSES } from '@app/common/constants/util.constant';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';

@Component({
  selector: 'app-email-template-details',
  templateUrl: './email-template-details.component.html',
  styleUrls: ['./email-template-details.component.scss'],
})
export class EmailTemplateDetailsComponent implements OnInit {
  breadCrumbData = {
    link: `${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.EMAIL_TEMPLATES}`,
    linkText: ''
  };
  templateDetails: any;
  cancelPopupShowHide: boolean = false;
  templateId: any;
  permissionsDone: boolean = false;
  adminPermissions: IAdminPermissionsI = {
    TEMPLATE_EDIT: true,
  };
  informationForm: any;
  constructor(private rotue: ActivatedRoute, private http: HttpService, private observable: ObservableHelperService, private sanitized: DomSanitizer, private router: Router) {
    this.rotue.params.subscribe(pathParams => {
      this.templateId = pathParams.id;
      this.getEmailTemplateDetails(pathParams.id);
    });
  }

  ngOnInit() {
    this.getAdminPermissions();
  }

  /**
   * @param templateId :Email template id
   * This function is for to get particular email template details
   */
  getEmailTemplateDetails(templateId) {
    const payload = {
      type: HTTPMethods.GET,
      url: API_URLS.EMAIL_TEMPLATE_DETAILS,
      isDeveloper: true,
      pathVariables: [templateId],
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.templateDetails = this.massageTempalteDetails(res.data);
        this.breadCrumbData.linkText = this.templateDetails.emailName;
        /**
         * bypassSecurityTrustHtml:It will do santizer to HTML and will render with classes and styles
         * Refrence: https://stackoverflow.com/questions/39628007/angular2-innerhtml-binding-remove-style-attribute
         */
        this.templateDetails.htmlTemplate = this.sanitized.bypassSecurityTrustHtml(this.templateDetails.htmlTemplate);
        this.informationForm = JSON.parse(res.data.htmlTemplate);

      } else {
        let message = (res.error) ? res.error.message : TOAST_MESSAGES.EMAIL_TEMPLATE_DETAILS_ERROR_MSG;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  /**
   *
   * @param details :Email template details
   * This function is for massaging the email tempalate data
   */
  massageTempalteDetails(details: object) {
    if (!details && typeof(details) !== 'object') { return {}; }
    return {
      ...details,
      // sendingTime: this.formateTime(details['sendingTime'])
    };
  }

  /**
   *
   * @param sendingTime: 0-24 numbers
   * Example: if sendingTime = 20; then return 8PM
   */
  formateTime(sendingTime: number) {
    if (typeof(sendingTime) === 'number') {
      if (sendingTime < 12) {
        return `${sendingTime} AM`;
      } else if (sendingTime === 12) {
        return `${sendingTime} PM`;
      } else if (sendingTime === 24) {
        return `${sendingTime - 12} AM`;
      } else {
        return`${sendingTime - 12} PM`;
      }
    }
  }


  /**
   * This function is for to show the more popup
   */
  viewMore() {
      this.cancelPopupShowHide = true;
  }

  /**
   * @param status :Popup status
   * This function is for to show/hide the popup
   */
  cancelPopupShowHideStatusFun(status) {
    this.cancelPopupShowHide = status.cancelpopup;
  }

  /**
   * This is for to navigate edit template details screen
   */
  navigateEditScreen() {
    if (this.templateId == '13') {
      this.router.navigate([ROUTE_PATHS.ADMIN, ROUTE_PATHS.INFO_REQUEST_FORM, this.templateId], { queryParams: { edit: true  }});
    } else {
      this.router.navigate([ROUTE_PATHS.ADMIN, ROUTE_PATHS.EMAIL_TEMPLATES, this.templateId], { queryParams: { edit: true  }});
    }
  }


  /**
  * getting permissions based on user id
  */
 getAdminPermissions() {
  const payloadData: IGetPermissionsPayloadI = {
    type: HTTPMethods.GET,
    url: API_URLS.GET_PERMISSIONS,
    isDeveloper: true
  };
  this.http.makeHttpRequest(payloadData).subscribe((res) => {
    if (this.http.isSuccessfulResponse(res)) {
      let permissions: Array<string> = ['TEMPLATE_EDIT'];
      let permArr: Array<string> = [];
      res.data.permissions.forEach(obj => {
        permArr.push(obj.permissionName);
      });
      if (!permArr.includes('TEMPLATE_DETAILS')) {
        this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.ACCESS_PERMISSOIN_DENIED_MSG);
        this.router.navigate([`${ROUTE_PATHS.ADMIN}`]);
      }
      permissions.forEach(perm => {
        if (!permArr.includes(perm)) {
          this.adminPermissions[perm] = false;
        }
      });
    } else {
      // Error
      let message: string = (res.error) ? res.error.message : TOAST_MESSAGES.ACCESS_PERMISSION_ERROR_MSG;
      this.observable.showToast(TOAST_STATUSES.ERROR, message);
    }
    this.permissionsDone = true;
  });
}
}
