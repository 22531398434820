<div class="popup__wrapper" *ngIf="documentsArr.length">
    <div class="popup__heading">Send Lab Document Request</div>
    <div class="days_wrapper">
        <div class="day__field" *ngFor="let task of documentsArr; let i = index">
            <p-checkbox [ngModelOptions]="{ standalone: true }" [(ngModel)]="task.isSelected" binary="true"
                (onChange)="handleDocuments($event, task)">
            </p-checkbox><label>{{ task.documentLabel }}</label>
        </div>
    </div>
    <div class="error-msg days invalid-feedback" *ngIf="showSelectionError"><span>Please select atleast one document</span></div>
    <!-- Text area starts-->
    <ng-container *ngIf="showOther">
        <div class="form__field">
            <div class="sub__heading">Other Comments</div>
            <textarea class="text-area input" type="textarea" [attr.maxLength]="150" value=""
                placeholder="Enter details here" (input)="textCounter($event)" [(ngModel)]="otherValue"></textarea>
            <input disabled class="textarea-range-show" maxlength="3" size="3" value="{{ remainingValue }} remaining"
                id="remaining" />
            <!--text area validation start-->
            <div *ngIf="showOtherError" class="error-msg days other invalid-feedback">
                <span>Please enter the details, if you specify others</span>
            </div>
        </div>
    </ng-container>
    <div class="form__field upload__field upload__field-wrapper">
        <p-fileUpload name="patient_uploads[]" #fileUpload [auto]="true" chooseLabel="Choose files to Upload"
            accept=".jpg, .png, .gif, .jpeg, .tiff, .psd, .pdf, .esp, .ai, .indd, .raw, .heic, .heif"
            maxFileSize="10000000" multiple="multiple" (onSelect)="onUpload($event, fileUpload)"
            (onRemove)="filesRemoved($event, fileUpload)" (onClear)="allFilesCleared($event)" fileLimit="5">
            <ng-template pTemplate="content">
                <p class="content-message">
                    Or Drag and Drop them here
                    <span class="helpTxt">(maximum 5 allowed)</span>
                </p>
            </ng-template>
        </p-fileUpload>
        <div class="error-msg days upload-valid-msg invalid-feedback"
            *ngIf="fileValidation == false && uploadedFiles && uploadedFiles.length != 0 && uploadedFiles.length <= 5">
            <span>Please upload valid document.</span>
        </div>
        <div class="error-msg days upload-valid-msg invalid-feedback"
            *ngIf="fileValidation == false && uploadedFiles && uploadedFiles.length > 5">
            <span>Maximum 5 documents are allowed to upload. Please remove extra documents</span>
        </div>
    </div>
    <div class="document-section">
        <div *ngFor="let task of documentsArr">
            <div *ngIf="task.isSelected" class="document-view-section">
                <div class="patient__id-document" *ngFor="let uploadsFiles of task.uploads; let i = index">
                    <div class="upload-document__block">
                        <p>{{ uploadsFiles.name | ellipsis: 23 }}</p>
                        <p>.{{ uploadsFiles.extenstion }}</p>
                    </div>
                    <div class="patient__id-document-actions">
                        <a [href]="uploadsFiles.file" target="_blank" title="View" class="view-icon">
                            <app-svg [name]="'view-upload'"></app-svg>
                        </a>
                        <p class="down-arrow" (click)="donwloadFile(uploadsFiles.file, uploadsFiles, true)" title="Download">
                            <app-svg [name]="'down-arrow'"></app-svg>
                        </p>
                        <a class="edit-icon" (click)="editFile(task.id)" title="Edit">
                            <app-svg title="Edit" [name]="'edit-new'"></app-svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="note">Note: The above documents are for preview purpose only, Updated documents will be sent to the patient via email.</div> -->
    <div class="save_button">
        <div class="cancel-btn" (click)="cancel()">Cancel</div>
        <button (click)="submit()" class="common-btn-style">Send to Patient</button>
    </div>
</div>