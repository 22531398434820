import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { input } from 'aws-amplify';

@Component({
  selector: 'app-block-patient',
  templateUrl: './block-patient.component.html',
  styleUrls: ['./block-patient.component.scss']
})
export class BlockPatientComponent implements OnInit {

  // Dates validation message
  buttonDisable = true;
  datesValidationState = false;
  filterFormControls;
  doctorList: any;
  treatmentList: any;
  locationList: any;
  clearCheckBoxes = false;
  filterClear = false;
  setValuesToForm = [];
  intailTreatmentsList = [];
  checkedValues = [];
  @Output() submitEvent: EventEmitter<any> = new EventEmitter();
  @Input() filterInputs: any;
  @Input() saveFormData: any;
  @Input() set filterData(value: any) {
    if (value) {
      this.assignFilterData(value);
      this.initForm(this.filterInputs);
    }
  }
  formGroup: any;
  formInputs: any;
  formSubmitAttempt = false;
  @Input() set isFilterPanelClosed(value: any) {
    this.formSubmitAttempt = !value;
  }
  @Output() clearFilter;
  @Input() set isFilterClear(value: any) {
    if (value) {
      this.clearFormfields();
    }
  }
  constructor(private formBuilder: FormBuilder) { }

  // This will do form submission
  ngOnInit() {
  }


  // Assigning filter data to filterComponent
  assignFilterData(data) {
    if (data && typeof (data) === 'object') {
      this.filterInputs = this.filterInputs.map(inp => {
        return {
          ...inp,
          options: (inp.key === 'treatment') ? data[inp.key] : []
        };
      });
    } else {
      // Error
    }
    this.setCheckboxes();
  }

  initForm(formType) {
    // LoginForm
    this.formGroup = this.toReactiveForm(formType);
    this.formInputs = formType;
    this.formGroup.controls['treatment'].setValue(this.setValuesToForm);
    this.formGroup.controls['treatment'].patchValue(this.setValuesToForm);
  }
  /**
   * this function is used to check error for a given input control
   * @param control {FormControl} - form control to be validated
   * @param formSubmitted {boolean} - to validate the form on form submission
   */
  isInvalid(control, formSubmitted) {
    return ((control.invalid && formSubmitted && (control.touched || control.dirty)) ||
      (formSubmitted && control.invalid)
    );
  }

  /**
  * To convert form data to reactive form
  // tslint:disable-next-line: jsdoc-format
  * @param inputs  - array of input fields
  */
  toReactiveForm(inputs: Array<{}>) {
    let group = {};
    Array.prototype.forEach.call(inputs, ( input, i) => {
      if (input.type !== 'header') {
        if (input.controlType === 'formGroup') {
          group[input.key] = this.toReactiveForm(input.formInputs);
        } else if (input.controlType === 'submitButton') {
          // Don't add to form Group
        } else {
          group[input.key] = [(input.value === undefined || input.value === null) ? '' : input.value, input.validators];
        }
      }
    });
    return this.formBuilder.group(group);
  }
  // This will do form clear
  clearFormfields() {
    this.formGroup.reset();
    this.formSubmitAttempt = false;
    this.formInputs.forEach(each => {
      if (each.type !== 'header') {
        if (each.type === 'groupCheckbox') {
          each.isSelected = false;
          each.isIntermediate = false;
          this.formGroup.controls[each.key].value = [];
          each.value = [];
          each.options.forEach(opt => {
            opt.isSelected = false;
          });
        }
      }
    });

    for (const key in this.formGroup.controls) {
      if (Object.prototype.hasOwnProperty.call(this.formGroup.controls, key)) {
        this.formGroup.controls[key].clearValidators();
        this.formGroup.controls[key].updateValueAndValidity();
      }
    }
  }

  // This will do form sumbission call
  actionField(event?) {
    this.formSubmitAttempt = true;
    if (this.formGroup.valid) {
      this.sendingData(this.formGroup.controls);
      this.formSubmitAttempt = false;
    }
  }

  // Validation for Radio fields
  validateCheckBox(control: FormControl) {
    let found = control.value;
    return found ? null : { radio: true };
  }


  // This will do if category selected all sub options will be slected and vice-versa
  categorySelect(event, index) {
    let groupList = [];
    this.formInputs[index].isSelected = event.checked;
    if (this.formInputs[index].isSelected) {
      this.formInputs[index].isIntermediate = false;
    }
    this.formInputs[index].options = this.formInputs[index].options.map((each) => {
      return {
        ...each,
        isSelected: this.formInputs[index].isSelected,
      };
    });
    this.formGroup.controls[this.formInputs[index].key].setValue(this.setGroupcheck(this.formInputs[index]));
    this.checkingThePreviewsStatus();
  }

  // This set formGroup values which is selected
  setGroupcheck(config) {
    this.checkedValues = [];
    let checkedGroup = [];
    if (config && typeof (config) === 'object') {
      config.options.forEach(each => {
        if (each.isSelected === true) {
          this.checkedValues.push(each.name);
          checkedGroup.push({
            appointmentTypeId: each.value,
            appointmentTypeName: each.appointmentTypeName,
            locationId: each.locationId
          });
        }
      });
    }
    return checkedGroup;
  }

  // This will do if option selected or unselected category will be unselected
  optionSelect(event, taskId, categoryId) {
    this.formInputs[categoryId].options[taskId].isSelected = event.checked;
    let optionsCount = this.formInputs[categoryId].options.length;
    let count = 0;
    this.formInputs[categoryId].options.forEach((each) => {
      if (each.isSelected === true) {
        count++;
      }
    });
    if (count > 0 && count < optionsCount) {
      this.formInputs[categoryId].isIntermediate = true;
    } else {
      this.formInputs[categoryId].isIntermediate = false;
    }
    if (count === optionsCount) {
      this.formInputs[categoryId].isSelected = true;
      this.formInputs[categoryId].isIntermediate = false;
    } else {
      this.formInputs[categoryId].isSelected = false;
    }
    this.formGroup.controls[this.formInputs[categoryId].key].setValue(this.setGroupcheck(this.formInputs[categoryId]));
    this.checkingThePreviewsStatus();
  }

  checkingThePreviewsStatus() {

    if (this.intailTreatmentsList.sort().toString() === this.checkedValues.sort().toString()) {
      this.buttonDisable = true;
      this.actionField();
    } else {
      this.actionField();
      this.buttonDisable = false;
    }
  }
  // Formating request JSON
  sendingData(form) {
    var treatmentsList: any;

    treatmentsList = {
      treatment: form.treatment.value,
    };
    this.submitEvent.emit(treatmentsList);
  }
  setCheckboxes() {
    let count = 0;
    let optionsCount;
    this.filterInputs.forEach(field => {
      if (field.key === 'treatment') {
        optionsCount = field.options.length;
        field.options.forEach((each) => {
          if (each.isSelected === true) {
            count++;
            this.intailTreatmentsList.push(each.name);
            this.setValuesToForm.push({
              appointmentTypeId: each.appointmentTypeId, appointmentTypeName: each.appointmentTypeName
            });
          }
        });
        if (count > 0 && count < optionsCount) {
          field.isIntermediate = true;
        } else {
          field.isIntermediate = false;
        }
        if (count === optionsCount) {
          field.isSelected = true;
          field.isIntermediate = false;
        } else {
          field.isSelected = false;
        }
      }
    });
  }
}
