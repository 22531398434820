import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { HomeWrapperComponent } from './home-wrapper/home-wrapper.component';
import { WebsiteactiveGuard } from '@app/common/services/websiteactive.guard';
import { EachTreatmentComponent } from './each-treatment/each-treatment.component';

const routes: Routes = [
  {
    path: ROUTE_PATHS.EMPTY,
    component: HomeWrapperComponent,
    canActivate: [WebsiteactiveGuard]
  },
  {
    path: ROUTE_PATHS.EACH_TREATEMENT,
    component: EachTreatmentComponent,
    canActivate: [WebsiteactiveGuard]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
