import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { DataService } from '@app/common/services/data.service';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-metal-health-details',
  templateUrl: './metal-health-details.component.html',
  styleUrls: ['./metal-health-details.component.scss']
})
export class MetalHealthDetailsComponent implements OnInit {
  healthDetailsObj = {
    configData: null,
    legalIssuesText : null,
    sideEffectsText: null,
    allergiesText: null
  };
@Input() saveFormData;
@Output() submitEvent: EventEmitter<any> = new EventEmitter();

formData: any;
splicedObject: any;
  legalissueTextDom: HTMLElement;
  sideEffectsTextDom: HTMLElement;
  allergiesTextDom: HTMLElement;
  isSideEffectsTextDom: string;
  isLegalTextDom: string;
  isAllergiesTextDom: string;
  test_hippa: { anxiety: string; depression_derealization: string; feeling_guilt: string; feeling_paranoid: string; feeling_sucidal: string; insomnia: string; lack_concentration: string; legal_issue: boolean; legal_issue_description: string; libido: string; low_interest: string; medicines_side_effects: boolean; medicines_side_effects_description: string; outburst_allergies: boolean; outburst_allergies_description: string; sadness: boolean; weight_loss_gain: string; };
  dataInputs: any;
  autoForm: any;
  metalHealthData: any[];
  autoFill: boolean;
constructor(private formService: CustomFormHandlerService, private dataService: DataService) { }

ngOnInit() {
  this.autoForm = this.formService.initForm(this.formService.formTypes.AUTOFILLMETALDETAILS);
  this.formData = this.formService.initForm(this.formService.formTypes.MENTALHEALTHDETAIL);
  this.formData.formInputs.forEach(form => {
    let keys = ['legal_issue', 'medicines_side_effects', 'outbust_allergies'];
    if (keys.includes(form.key) && form.value) {
      this.removeValidators(form.key);
    }
  });
  if(this.dataService.previousAppointmentData == null){
    this.autoFill = false;
  }
  else{
    this.autoFill = true;
  }
}
getdata(event){
  this.dataService.previousValue = event.slider.value;
  if(!event.slider.value === true){
    // this.metalHealthData = [];
    this.metalHealthData = JSON.parse(JSON.stringify([...this.formData.formInputs]))
    this.formData.formInputs.forEach((element) => { 
      element.value = this.dataService.previousAppointmentData["healthDetails"][element.key];
    });
    this.formData.formInputs[0].value = !this.dataService.previousAppointmentData["healthDetails"]["sadness"];
    this.formData.formInputs[7].value = this.dataService.previousAppointmentData["healthDetails"]["depression_derealization"];
    this.formData.formInputs[10].value =  this.dataService.previousAppointmentData["healthDetails"]["weight_loss_gain"];
    this.formData.formInputs[11].value =  !this.dataService.previousAppointmentData["healthDetails"]["legal_issue"];
    this.formData.formInputs[12].value =  this.dataService.previousAppointmentData["healthDetails"]["legal_issue_description"];
    this.formData.formInputs[13].value =  !this.dataService.previousAppointmentData["healthDetails"]["medicines_side_effects"];
    this.formData.formInputs[14].value =  this.dataService.previousAppointmentData["healthDetails"]["medicines_side_effects_description"]; console.log(this.formData.formInputs[15].value,this.dataService.previousAppointmentData["healthDetails"]["outburst_allergies"]);
    this.formData.formInputs[15].value =  !this.dataService.previousAppointmentData["healthDetails"]["outburst_allergies"];
    this.formData.formInputs[16].value =  this.dataService.previousAppointmentData["healthDetails"]["outburst_allergies_description"];
      if (this.formData.formInputs[11].value) {
        this.removeValidators(this.formData.formInputs[11].key);
        this.isLegalTextDom = 'none';
        this.legalissueTextDom.style.display = this.isLegalTextDom;
      } else {
        this.isLegalTextDom = 'block';
        this.legalissueTextDom.style.display = this.isLegalTextDom;
        this.setValidators(this.formData.formInputs[11].key);
      }
      this.healthDetailsObj.legalIssuesText = this.isLegalTextDom;
      if (this.formData.formInputs[13].value) {
        this.removeValidators(this.formData.formInputs[13].key);
        this.isSideEffectsTextDom = 'none';
        this.sideEffectsTextDom.style.display = this.isSideEffectsTextDom;
      } else {
        this.isSideEffectsTextDom = 'block';
        this.sideEffectsTextDom.style.display = this.isSideEffectsTextDom;
        this.setValidators(this.formData.formInputs[13].key);
      }
      this.healthDetailsObj.sideEffectsText = this.isSideEffectsTextDom;
      if (this.formData.formInputs[15].value) {
        this.removeValidators(this.formData.formInputs[15].key);
        this.isAllergiesTextDom = 'none';
        this.allergiesTextDom.style.display = this.isAllergiesTextDom;
      } else {
        this.isAllergiesTextDom = 'block';
        this.allergiesTextDom.style.display = this.isAllergiesTextDom;
        this.setValidators(this.formData.formInputs[15].key);
      }
      this.healthDetailsObj.allergiesText = this.isAllergiesTextDom;
    
  }else{
    this.formData.formInputs[0].value = false;
    this.formData.formInputs[1].value = "";
    this.formData.formInputs[2].value = "";
    this.formData.formInputs[3].value = "";
    this.formData.formInputs[4].value = "";
    this.formData.formInputs[5].value = "";
    this.formData.formInputs[6].value = "";
    this.formData.formInputs[7].value = "";
    this.formData.formInputs[8].value = "";
    this.formData.formInputs[9].value = "";
    this.formData.formInputs[10].value =  "";
    this.formData.formInputs[11].value =  false;
    this.formData.formInputs[12].value = "";
    this.formData.formInputs[13].value =  false;
    this.formData.formInputs[14].value =  "";
    this.formData.formInputs[15].value =  false;
    this.formData.formInputs[16].value =  "";
    if (this.formData.formInputs[11].value) {
      this.removeValidators(this.formData.formInputs[11].key);
      this.isLegalTextDom = 'none';
      this.legalissueTextDom.style.display = this.isLegalTextDom;
    } else {
      this.isLegalTextDom = 'block';
      this.legalissueTextDom.style.display = this.isLegalTextDom;
      this.setValidators(this.formData.formInputs[11].key);
    }
    this.healthDetailsObj.legalIssuesText = this.isLegalTextDom;
    if (this.formData.formInputs[13].value) {
      this.removeValidators(this.formData.formInputs[13].key);
      this.isSideEffectsTextDom = 'none';
      this.sideEffectsTextDom.style.display = this.isSideEffectsTextDom;
    } else {
      this.isSideEffectsTextDom = 'block';
      this.sideEffectsTextDom.style.display = this.isSideEffectsTextDom;
      this.setValidators(this.formData.formInputs[13].key);
    }
    this.healthDetailsObj.sideEffectsText = this.isSideEffectsTextDom;
    if (this.formData.formInputs[15].value) {
      this.removeValidators(this.formData.formInputs[15].key);
      this.isAllergiesTextDom = 'none';
      this.allergiesTextDom.style.display = this.isAllergiesTextDom;
    } else {
      this.isAllergiesTextDom = 'block';
      this.allergiesTextDom.style.display = this.isAllergiesTextDom;
      this.setValidators(this.formData.formInputs[15].key);
    }
    this.healthDetailsObj.allergiesText = this.isAllergiesTextDom;
    
  }
}
ngAfterViewInit(): void {
  this.formService.metalHealthDetailFormConfig.forEach(each => {
    if (each.count) {
      let countfield = document.getElementById(each.key) as HTMLInputElement;
      countfield.value = each.count;
    }
  });
  let healthDetails = this.dataService.getData(this.dataService.dataObject.HEALTH_DETAILS);
  this.healthDetailsObj.allergiesText = (healthDetails !== undefined) ? healthDetails.allergiesText : null;
  this.healthDetailsObj.legalIssuesText = (healthDetails !== undefined) ? healthDetails.legalIssuesText : null;
  this.healthDetailsObj.sideEffectsText = (healthDetails !== undefined) ? healthDetails.sideEffectsText : null;
  this.legalissueTextDom = document.getElementById('legal_issue_description_parent');
  this.sideEffectsTextDom = document.getElementById('medicines_side_effects_description_parent');
  this.allergiesTextDom = document.getElementById('outbust_allergies_description_parent');
  this.isLegalTextDom = (healthDetails !== undefined) ? healthDetails.legalIssuesText : null;
  this.isSideEffectsTextDom = (healthDetails !== undefined) ? healthDetails.sideEffectsText : null;
  this.isAllergiesTextDom = (healthDetails !== undefined) ? healthDetails.allergiesText : null;
  if (healthDetails !== undefined) {
    this.legalissueTextDom.style.display = this.isLegalTextDom;
    this.sideEffectsTextDom.style.display = this.isSideEffectsTextDom;
    this.allergiesTextDom.style.display = this.isAllergiesTextDom;
  } else {
    this.legalissueTextDom.style.display = this.isLegalTextDom;
    this.sideEffectsTextDom.style.display = this.isSideEffectsTextDom;
    this.allergiesTextDom.style.display = this.isAllergiesTextDom;
  }
}
getSliderValue(event) {
  console.log(event);
  let slider = event.slider;
  let index  = event.index;
  if (slider.key === 'legal_issue') {
    if (slider.value) {
      this.removeValidators(slider.key);
      this.isLegalTextDom = 'none';
      this.legalissueTextDom.style.display = this.isLegalTextDom;
    } else {
      this.isLegalTextDom = 'block';
      this.legalissueTextDom.style.display = this.isLegalTextDom;
      this.setValidators(slider.key);
    }
    this.healthDetailsObj.legalIssuesText = this.isLegalTextDom;
  } else if (slider.key === 'medicines_side_effects') {
    if (slider.value) {
      this.removeValidators(slider.key);
      this.isSideEffectsTextDom = 'none';
      this.sideEffectsTextDom.style.display = this.isSideEffectsTextDom;
    } else {
      this.isSideEffectsTextDom = 'block';
      this.sideEffectsTextDom.style.display = this.isSideEffectsTextDom;
      this.setValidators(slider.key);
    }
    this.healthDetailsObj.sideEffectsText = this.isSideEffectsTextDom;
  } else if (slider.key === 'outbust_allergies') {
    if (slider.value) {
      this.removeValidators(slider.key);
      this.isAllergiesTextDom = 'none';
      this.allergiesTextDom.style.display = this.isAllergiesTextDom;
    } else {
      this.isAllergiesTextDom = 'block';
      this.allergiesTextDom.style.display = this.isAllergiesTextDom;
      this.setValidators(slider.key);
    }
    this.healthDetailsObj.allergiesText = this.isAllergiesTextDom;
  }
  console.log(this.formData);

  this.formService.metalHealthDetailFormConfig = this.formData.formInputs;
  console.log(this.formService.metalHealthDetailFormConfig);
  this.dataService.setData(this.dataService.dataObject.HEALTH_DETAILS, this.healthDetailsObj);
}

setValidators(key) {
  let fieldKey = `${key}_description`;
  this.formData.formGroup.controls[fieldKey].setValidators(Validators.required, Validators.minLength(1));
  this.formData.formGroup.controls[fieldKey].updateValueAndValidity();
  let countfield = document.getElementById(fieldKey) as HTMLInputElement;
  countfield.value = '150 remaining';
  this.formData.formInputs.forEach(element => {
    if (element.key === fieldKey) {
      element.validators = [Validators.required, Validators.minLength(1)];
    }
  });

}
removeValidators(key) {
  let fieldKey = `${key}_description`;
  this.formData.formGroup.controls[fieldKey].setValue('');
  this.formData.formGroup.controls[fieldKey].clearValidators();
  this.formData.formGroup.controls[fieldKey].updateValueAndValidity();
}
getFormData(event) {
  console.log(event);
  console.log(this.formData);
  if (event) {
    this.formService.metalHealthDetailFormConfig = this.assignFormValues(event, this.formService.metalHealthDetailFormConfig);
    this.healthDetailsObj.configData = this.formService.metalHealthDetailFormConfig;
    this.dataService.setData(this.dataService.dataObject.HEALTH_DETAILS, this.healthDetailsObj);
    this.submitEvent.emit({status: event,  step: 3});
  }
}

assignFormValues(formData, formConfig) {
  let keys = Object.keys(formData.value);
  formConfig.forEach((each, index) => {
    if (keys.includes(each.key)) {
      each.value = formData.value[each.key];
      each.validators = this.formData.formGroup.controls[each.key].validator;
      if (each.count) {
        let countfield = document.getElementById(each.key) as HTMLInputElement;
        each.count = countfield.value;
      }
    }
  });
  return formConfig;
}

ngOnDestroy(): void {
  // Called once, before the instance is destroyed.
  // Add 'implements OnDestroy' to the class.
  this.formData.formInputs.forEach(element => {
    if (element.count) {
      let countfield = document.getElementById(element.key) as HTMLInputElement;
      element.count = countfield.value;
    }
  });
  this.formService.metalHealthDetailFormConfig = this.formData.formInputs;
}
}
