import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-provide',
  templateUrl: './provide.component.html',
  styleUrls: ['./provide.component.scss']
})
export class ProvideComponent implements OnInit {

  ketamineData = {
    ketamineHeading: 'Ketamine Treatment',
    ketamineDescription: 'A new groundbreaking therapy, found to be a rapid-acting antidepressant in depression & helps in decreasing suicidal ideation.',
    ketamineIcon: 'ketamine'
  };

  constructor() { }

  ngOnInit() {
  }

}
