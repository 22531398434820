<!-- Appoinement List -->
<div class="appointments__list invoice-list-data" (click)="invoiceDetailsClick(invoiceList)">
    <!-- Appoinement List Date-->
    <div class="appointments__list-item  user patient_name">
        <app-svg class="appointments__icon" [name]="'user'"></app-svg>
        <p class="appointments__list-text" [title]="invoiceList.patientName | titlecase">
            {{invoiceList.patientName | titlecase | ellipsis:18}}</p>
    </div>
    <!-- Appoinement Create time-->
    <div class="appointments__list-item date date_time" *ngIf="(invoiceList.invoice_type == 'appt_invoice' || invoiceList.invoice_type == undefined) && (invoiceList.invoice_type !== 'document_request_invoice')">
        <app-svg class="appointments__icon" [name]="'calendar'"></app-svg>
        <div class="appointments__list-text">
            <p class="time_data">
                {{invoiceList.appointmentDate | dateFormat}}
            </p>
            <p class="time_data">
                {{ invoiceList.appointmentTime | customeTimeAmPm}}
            </p>
        </div>
    </div>
    <div class="appointments__list-item date date_time" *ngIf="(invoiceList.invoice_type === 'document_request_invoice')">
        <app-svg class="appointments__icon" [name]="'calendar'"></app-svg>
        <div class="appointments__list-text">
            <p class="time_data">
                {{invoiceList.requestDate | dateFormat}}
            </p>
            <p class="time_data">
                {{ invoiceList.time | customeTimeAmPm}}
            </p>
        </div>
    </div>
    <!-- Invoice Create time-->
    <div class="appointments__list-item date date_time" *ngIf="invoiceList.invoice_type == 'patient_invoice'">
        <app-svg [name]="'patientinvoice'" style="width:22px;"></app-svg>
        <div class="appointments__list-text">
            <p class="time_data">
                {{invoiceList.create_time | dateFormat}}
            </p>
            <p class="time_data">
                {{ invoiceList.create_time | date:'shortTime'}}
            </p>
        </div>
    </div>
    <!-- Treatment -->
    <div class="appointments__list-item type">
        <app-svg class="appointments__icon" [ngClass]="invoiceList.treatmentIcon" [name]="invoiceList.treatmentIcon">
        </app-svg>
        <p class="appointments__list-text" [title]="invoiceList.treatment | titlecase" *ngIf="invoiceList.invoice_type == 'appt_invoice' || invoiceList.invoice_type == undefined">
            {{ invoiceList.treatment | titlecase | ellipsis:25}}
        </p>
        <p class="appointments__list-text" [title]="invoiceList.service | titlecase" *ngIf="invoiceList.invoice_type == 'patient_invoice'">
            {{ invoiceList.service | titlecase | ellipsis:25}}
        </p>
        <p class="appointments__list-text" [title]="invoiceList.service | titlecase" *ngIf="invoiceList.invoice_type == 'document_request_invoice'">
            {{ invoiceList.documentName | titlecase | ellipsis:25}}
        </p>
    </div>
    <div class="appointments__list-item bill_name">
        <app-svg class="appointments__icon"  [name]="'bill-amount'"></app-svg>
        <p class="appointments__list-text">{{invoiceList.billAmount}} </p>
    </div>
    <div class="appointments__list-item invoice_status">
        <p class="appointments__list-text" *ngIf="invoiceList.status !== 'ungenerated' " [ngClass]="invoiceList.status" [title]="invoiceList.status | titlecase">
            {{invoiceList.status | titlecase | ellipsis:18}} </p>
            <p class="appointments__list-text" *ngIf="invoiceList.status === 'ungenerated' " [ngClass]="invoiceList.status" title="Not Generated">
                Not Generated </p>
    </div>
    <!-- Appoinement List Icon -->
    <div class="appointments__list-item generate-icons" *ngIf="adminPermissions.INVOICE_EDIT">
        <div class="invoice-actions-list" (click)="invoiceListEvent({appId:invoiceList.appointmentId, btnType:'generate'}, $event)"
            *ngIf="invoiceList.billGeneratedStatus && invoiceList.status!=='paid'">
            <app-svg class="appointments__icon generate" title="Generate" [name]="'admininvoice'"></app-svg>
        </div>
        <div class="invoice-actions-list" (click)="invoiceListEvent({invId:invoiceList.invoice_id, invStatus: invoiceList.status, btnType:'resend'}, $event)"
            *ngIf="!invoiceList.billGeneratedStatus && invoiceList.status!=='paid'">
            <app-svg class="appointments__icon resend" title="Resend" [name]="'cancel'"></app-svg>
        </div>
        <div class="invoice-actions-list" (click)="invoiceListEvent({refId:invoiceList.request_id,invId:invoiceList.invoice_id, invStatus: invoiceList.status, btnType:'markaspaid', invType:invoiceList.invoice_type}, $event)"
            *ngIf="!invoiceList.billGeneratedStatus && invoiceList.status!=='paid'">
            <app-svg class="appointments__icon paid" title="Mark as Paid" [name]="'mark-as-done'"></app-svg>
        </div>
    </div>
</div>