import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { TOAST_STATUSES } from '@app/common/constants/util.constant';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';

@Component({
  selector: 'app-employee-data-list',
  templateUrl: './employee-data-list.component.html',
  styleUrls: ['../appointments-list/appointments-list.component.scss']
})
export class EmployeeDataListComponent implements OnInit { 
  @Input() adminPermissions;
  // Appoinements data @input varible and initial data
  @Input() employeeData: any = {
    userProfileUrl: '',
    employeeNameId: '',
    employeeName: '',
    employeeDesignation: '',
    employeeLocation: '',
    employeePhoneNumber: '',
  };
  @Output() activateCall: EventEmitter<any> = new EventEmitter();
  constructor(private router: Router, private observable: ObservableHelperService) { }

  ngOnInit() {
  }
  // Edit event @TODO Anji
  editClickEvent(empId, event) {
    event.stopPropagation();
    this.router.navigate([`${ROUTE_PATHS.ADMIN + '/' + ROUTE_PATHS.EMPLOYEE_CREATION}`], { queryParams: { id: empId, edit: true } });
  }
  // EMployee Details redirection
  employeeDetailsClick(id) {
    this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.EMPLOYEE}/${id}`]);
  }
  activateClickEvent(empId, event){
    event.stopPropagation();
    this.activateCall.emit(empId);
  }
}
