<ng-container class="content1" *ngIf="permissionsDone && FilteredObj!=''&& (FilteredObj.patientCount>0 || FilteredObj.appointmentCount>0 || FilteredObj.invoices.length>0) else noResults">
    <div class="parentOverlay">
        <div class="mainOverlay">
            <div class="OverlayDiv1">
                <app-svg [name]="'patient-global-search'"></app-svg>
                <div class="label_global">Patient ({{FilteredObj.patientCount}})</div>
            </div>
            <div class="OverlayDiv2 {{FilteredObj.patientCount < 3 ? 'padding' : null}}  {{!permissions.includes('PATIENTS') ? 'disable' : ''}}">
                <div class="patient-list-names" *ngIf="FilteredObj.patientCount>0">
                    <tr *ngFor="let patient of FilteredObj.patients">
                        <td (click)="getById(patient.patientId,'patients',true,$event)">
                            <span class="p_name">{{patient.firstName+" "+patient.lastName | titlecase}}</span><br/>
                        </td>
                    </tr>
                    <div class="see_more" (click)="getAll('patients',$event)" *ngIf="FilteredObj.patientCount>3"><span><u>View All</u></span></div>
                </div>
                <div class="patient-list-names" *ngIf="FilteredObj.patientCount==0">
                    <tr><td><span class="p_details">There are no patients</span></td></tr>
                </div>
            </div>
        </div>
        <div class="mainOverlay">
            <div class="OverlayDiv1">
                <app-svg [name]="'calendar-color'"></app-svg>
                <div class="label_global">Appointments ({{FilteredObj.appointmentCount}})</div>
            </div>
            <div class="OverlayDiv2 {{FilteredObj.appointmentCount < 3 ? 'padding' : null}} {{!permissions.includes('APPOINTMENTS') ? 'disable' : ''}}">
                <div class="patient-list-names" *ngIf="FilteredObj.appointmentCount>0">
                    <tr *ngFor="let appointment of FilteredObj.appoinments">
                        <td (click)="getById(appointment.appointmentId,'appointments',true,$event)">
                            <span class="p_name">{{appointment.firstName+" "+appointment.lastName | titlecase}}</span><br/>
                            <span class="p_details">{{appointment.appointmentDate.split('T')[0]+" "+appointment.appointmentTime | date: 'hh:mm a, MMM d, y' }} | {{appointment.appointmentTypeName}} | {{appointment.locationName}}</span>
                        </td>
                    </tr>
                    <div class="see_more" (click)="getAll('appointments',$event)" *ngIf="FilteredObj.appointmentCount>3"><span><u>View All</u></span></div>
                </div>
                <div class="patient-list-names" *ngIf="FilteredObj.appointmentCount==0">
                    <tr><td><span class="p_details">There are no appointments</span></td></tr>
                </div>
            </div>
        </div>
        <div class="mainOverlay">
            <div class="OverlayDiv1">
                <app-svg [name]="'admininvoice-global'"></app-svg>
                <div class="label_global">Invoices</div>
            </div>
            <div class="OverlayDiv2 {{FilteredObj.invoices.length < 3 ? 'padding' : null}} {{!permissions.includes('INVOICES') ? 'disable' : ''}}">
                <div class="patient-list-names inv_Overlay" *ngIf="FilteredObj.invoices.length>0">
                    <tr *ngFor="let patient of FilteredObj.invoices">
                        <td (click)="getById(patient.invoice_id,'invoices',patient.invoice_type,$event)">
                            <span class="p_name" *ngIf="patient.invoice_type === 'patient_invoice'">{{patient.patientFirstname+" "+patient.patientLastname | titlecase}}</span><br/>
                            <span class="p_name" *ngIf="patient.invoice_type === 'appt_invoice'">{{patient.apptFirstname+" "+patient.apptLastname | titlecase}}</span><br/>
                            <span class="p_details">invoice {{patient.invoice_id}} | ${{patient.copay + patient.pay}} | {{patient.status}}</span>
                        </td>
                    </tr>
                    <div class="see_more" (click)="getAll('invoices',$event)" *ngIf="FilteredObj.invoices.length>0"><span><u>View All</u></span></div>
                </div>
                <div class="patient-list-names inv_Overlay" *ngIf="FilteredObj.invoices.length==0">
                    <tr><td><span class="p_details">There are no invoices</span></td></tr>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #noResults>
    <div class="content2">
        <span class="results_text">No results</span>
    </div>  
</ng-template>