<!-- main wrapper start -->
<main class="main__wrapper">
    <app-home-banner></app-home-banner>
    <app-measurements></app-measurements>
    <app-provide></app-provide>
    <app-mission></app-mission>
    <app-consultation></app-consultation>
    <app-success></app-success>
    <app-medical></app-medical>
    <app-partner></app-partner>
    <app-home-blog></app-home-blog>
    <app-get-in-touch></app-get-in-touch>
</main>
