<!-- mission section start -->
<section class="mission__section section-padding full-width">
    <!-- mission container start -->
    <div class="mission__container restrict-width padding-lr">
        <!-- mission left block start -->
        <div class="mission__left-block">
            <div class="image__block">
                <img class="mission-bg" src = "assets/images/webp/ManBeach.webp" alt="where can i get tested for adhd near me Virginia" loading="lazy"/>
            </div>
        </div>
        <!-- mission left block end -->
        <!-- mission right block start -->
        <div class="mission__right-block">
            <app-section-header [headingData]="headingData"></app-section-header>
            <div class="icon__block medium m-show">
                <img class="background-leaf3" src = "assets/images/pngs/leaf5.png" alt="leaf icon" loading="lazy"/>
            </div>
            <div class="image__block m-d-show">
                <img class="mission-bg" src = "assets/images/webp/ManBeach.webp" alt="adult adhd resources NY image" loading="lazy"/>
            </div>
            <!-- mission description block start -->
            <div class="mission__description-block">
                <div class="icon__block medium m-d-hide">
                    <img class="background-leaf3" src = "assets/images/pngs/leaf5.png" alt="leaf icon" loading="lazy"/>
                </div>
                <p class="mission__description">
                    Our mission is to provide comprehensive, culturally competent, relationships based mental health services through a holistic approach for   young children, adolescents and adults in a caring environment. Services are rendered by a group of well trained, dedicated and compassionate professionals through the provision of individual assessments, preventive measures, early identification , and research-based and proven treatment strategies.
                </p>
                <app-know-more [moreText]="moreText" [show]="false"></app-know-more>
            </div>
            <!-- mission description block end -->
        </div>
        <!-- mission right block end -->
    </div>
    <!-- mission container end -->
</section>
<!-- mission section end -->