import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { ROUTE_PATHS } from '@app/common/constants/routing.constant'
import { HTTPMethods, TOAST_STATUSES, convertDateToSpecifiedOffset, DEFAULT_TIME_ZONE_DIFF } from '@app/common/constants/util.constant'
import { HttpService } from '@app/common/services/http.service'
import { ObservableHelperService } from '@app/common/services/observable-helper.service'
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant'
import {
  IGetMethodPayload,
  IPostMethodPayload,
} from '@app/common/constants/typeInterfaces.constants'
import { API_URLS } from '@app/common/constants/api-urls.constant'
import { FormGroup, FormControl, Validators } from '@angular/forms'


@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.scss'],
})
export class PatientListComponent implements OnInit {
  showDatePriceFields = false
  showPdf = true
  btnSubmitText = 'Submit'
  generateInvoicePopupShowHide: boolean = false // Block patient popup intercation popup
  cancelPopupShowHide: boolean = false
  patienId: any
  popupMessage: string
  action: string
  optionvalue: any
  blockedServices: any
  displayDocumentsRequestPopup: boolean = false // for documents request
  // treatmentsCount: number;
  @ViewChild('op', { static: false }) overlay
  @ViewChild('overlay', { static: false }) editOverlay
  documentsRequest: any[]
  // 23/6/22
  selectImportSheetData: boolean
  generateList: any
  labRegisterDoc: any[]
  radioValueForMedicationSheet: any
  dateValue: any
  updatedDateValue: any;
  formSubmit: boolean = false;
  totalMedNotes: boolean;
  isMednoteAlreadyExists: boolean = false;
  isEvaluationAlreadyExists: boolean = false;

  //For Mednotes
  mednotesType: any[];
  diagonsisType: any[];
  mednoteTypePopup: string;
  selectedDiagonsis: string = ''
  selectedMednote: string = ''
  diagnosisErr: boolean = false
  durationErr: boolean = false
  evalTypeErr: boolean = false
  durationOfVisit: any[]
  selectedDuration: string
  initialMednoteFormData: any = {}
  initialTMSFormData: any = {}
  evalType: any[]
  apptType = 1
  apptArray: any[]
  apptArrayType: string
  apptRecordMsg: string
  popUpDuration: boolean = false;
  popUpEvalutionType: boolean = false
  selectedEvalType: string = ''
  MednoteRecordMsg: string
  previousMednoteList: any
  showMednoteList: boolean = false
  tmsNotes: any = []
  deleteNoteData: any = {}


  // 23/6/22
  constructor(private observable: ObservableHelperService, private router: Router, private http: HttpService) { }
  @Input() dontShowActions
  @Input() patientData
  @Input() treatmentsCount
  @Input() adminPermissions
  @Input() patientId
  @Output() moreOptionTextClick = new EventEmitter<any>()
  @Output() deletPatient = new EventEmitter<any>()
  ngOnInit() {
    // this.getTreatmentsDataLength();
  }
  paymentReciepts: any = new FormGroup({
    'date1': new FormControl('', [Validators.required]),
    'amount1': new FormControl('', [Validators.required]),
    'code1': new FormControl('', [])
  });
  @HostListener('window:scroll', ['$event'])
  scrollHandler(event) {
    if (this.overlay) {
      this.overlay.hide()
    }
    if (this.editOverlay) {
      this.editOverlay.hide()
    }
  }


  scheduleNavigate(event, patient) {
    if (event) {
      this.blockedServices = patient.blockedServices
      this.blockedServices = this.blockedServices.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj.appointmentTypeId).indexOf(obj.appointmentTypeId) === pos;
      })
      if (this.blockedServices.length === 0) {
        this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.NEWAPPOINTMENT}`], {
          queryParams: { patientId: patient.patientId, patientEdit: true },
        })
      } else {
        this.cancelPopupShowHide = true
        this.popupMessage = 'unblock'
        this.action = 'unblock'
      }
    }
  }

  moreOptionClick(event, optionValue, patientData) {

    event.stopPropagation()
    this.optionvalue = optionValue
    if (optionValue === 'Edit') {
      this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.CREAT_PATIENT}`], {
        queryParams: {
          id: patientData.patientId,
          edit: true,
          userId: patientData.userId,
          patientName: patientData.firstName,
          lastName: patientData.lastName,
          status: patientData.status,
          isOffile: patientData.isOffline,
        },
      })
    } else if (optionValue === 'Archive Patient') {
      this.popupMessage = 'archive'
      this.action = 'archive'
      this.cancelPopupShowHide = true
      this.optionvalue = optionValue
    } else {
      this.moreOptionTextClick.emit({ optionText: optionValue, patientData })
    }
    // 23/6/22
    if (optionValue == 'generateDocs') {
      this.cancelPopupShowHide = true
      this.popupMessage = 'generateDocs'
      this.getGenerateRequest()
    }
  

    //mednotes
    if (optionValue === 'appointmentListMM') {

      this.selectedDiagonsis = ''
      this.selectedMednote = ''
      this.selectedDuration = ''
      this.selectedEvalType = ''
      this.diagnosisErr = false
      this.durationErr = false
      this.initialMednoteFormData = {}
      this.action = 'appointmentListMM'
      let patienID = patientData.patientId
      if (patientData.lastVisit !== null) {
        this.apptRecordMsg = 'medication management '
      } else {
        this.apptRecordMsg = ''
      }
      Object.assign(this.initialMednoteFormData, { patienID: patientData.patientId, userId: patientData.userId })
      this.apptListMedNotes(patienID)
    }

    //TmsNotes
    if(optionValue === 'tmsNote'){
      this.getAllTmsNotes()
    }
    if (this.overlay) this.overlay.hide()
    if (this.editOverlay) this.editOverlay.hide()
  }
  //tmsNote
  getAllTmsNotes(){
    let patientId = this.patientData.patientId
    const payloadOne = {
      type: 'GET',
      url: API_URLS.ALL_TMS_NOTES,
      isDeveloper: true,
      pathVariables: [patientId],
    }
    this.http.makeHttpRequest(payloadOne).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        console.log("res", res);
        let length= res.data.tmsnots.length
        let currentNoteNumber = res.data.tmsnots.length + 1
        this.cancelPopupShowHide = true
        let showScoreFields: boolean = ( length === 0 || currentNoteNumber % 10 === 0 || currentNoteNumber % 20 === 0 || currentNoteNumber % 30 === 0 || currentNoteNumber % 36 === 0) ? true : false;
        console.log("currentNoteNumber", showScoreFields, currentNoteNumber);
        
        Object.assign(this.initialTMSFormData, { 
          // latestTmsApptDate: (res.data.hasOwnProperty("latesttmsappointement")) ? res.data.latesttmsappointement.appointmentDate : '',
          latestTmsApptDate: (res.data.latesttmsappointement && res.data.latesttmsappointement.appointmentDate) ? res.data.latesttmsappointement.appointmentDate : '',

          previousTmsNoteNumber: (length > 0)? res.data.tmsnots[length-1].IsTmsNumber :0, 
          currentNoteNumber: currentNoteNumber,
          PatientID: this.patientData.patientId, 
          userID: this.patientData.userId,
          showScoreSheetFields : showScoreFields,
          tmsType: (length === 0) ? 'Initial TMS Note' : 'Follow Up TMS Note',
        })

        this.tmsNotes = res.data.tmsnots.reverse()
        this.popupMessage = (length === 0) ? "noTmsNote" : "tmsNote"
        console.log("this.tmsNotes",this.tmsNotes);
      } else {
        let message = "Falied to get patient's TMS notes details. Please try again"
        message = res.error ? res.error.message : message
        this.observable.showToast(TOAST_STATUSES.ERROR, message)
        return
      }
    })
    
  }
  createNewTmsNote(){
    Object.assign(this.initialTMSFormData, { 
      OperationType: 'CreateNew'})
    console.log(this.initialTMSFormData, "query parama create tms");
    this.router.navigate(['/admin/tms-notes'], { queryParams: this.initialTMSFormData })
  }
  editTmsnote(note){
    Object.assign(this.initialTMSFormData, { 
      OperationType: 'EditExisting',
      tmsNoteID: note.tmsNotesId
    })
    const keysToDelete = ['showScoreSheetFields', 'latestTmsApptDate', 'previousTmsNoteNumber', 'tmsType'];
    for (const key of keysToDelete) {
        if (this.initialTMSFormData.hasOwnProperty(key)) {
            delete this.initialTMSFormData[key];
        }
    }
    console.log("query paramas edit tms", this.initialTMSFormData);
    this.router.navigate(['/admin/tms-notes'], { queryParams: this.initialTMSFormData })
  }
  showDeleteNotePopup(note){
    this.deleteNoteData = note
    console.log(note);
    this.popupMessage = "DeleteTmsNote"
  }
  deleteTmsnote(note){
    console.log("Detele patient TMS note", note);
    let userId = this.patientData.userId
    let patientId = note.patientId
    let tmsnoteid = note.tmsNotesId
    const payloadData = {
      type: HTTPMethods.DELETE,
      url: API_URLS.DELETE_TMS_NOTE,
      isDeveloper: true,
      pathVariables: [userId, patientId, tmsnoteid],
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        let message = `Patient's TMS note successfully deleted`;
        this.observable.showToast(TOAST_STATUSES.SUCCESS, message);
        this.cancelPopupShowHide = false
      } else {
        // Error
        let message = `Failed to delete patient's TMS note data. Please try again`;
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
  //mednotes (start)
  apptListMedNotes(patientID: any) {

    this.selectedDiagonsis = ''
    this.selectedMednote = ''
    this.selectedDuration = ''
    this.selectedEvalType = ''
    this.diagnosisErr = false
    this.durationErr = false
    const payloadOne = {
      type: 'GET',
      url: 'patientAppointmentsMednotes',
      isDeveloper: true,
      pathVariables: [patientID, this.apptType],
    }
    this.http.makeHttpRequest(payloadOne).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.cancelPopupShowHide = true
        this.popupMessage = 'appointmentListMM'

        this.apptArray = res.data.list
        if (typeof this.apptArray === 'object') {
          this.apptArrayType = 'object'
        }
        if (Array.isArray(this.apptArray)) {
          this.apptArrayType = 'array'
          this.apptArray.forEach( appt => {
            let status
            if (appt.appointmentStatus === "A"){
              status = "Active";
            } else if (appt.appointmentStatus === "B"){
              status = "Absent";
            } else if (appt.appointmentStatus === "C"){
              status = "Completed";
            } else if (appt.appointmentStatus === "D"){
              status = "Cancelled";
            } else if (appt.appointmentStatus === "I"){
              status = "Invoice Generated";
            } else if (appt.appointmentStatus === "N"){
              status = "Pending Payment";
            } else if (appt.appointmentStatus === "E"){
              status = "Archived";
            } else if (appt.appointmentStatus === "P"){
              status = "Pending";
            } 
            Object.assign(appt, {apptStatus: status})
          })
        }
      }
    })
  }
  
  mednote(Appt) {
    // this.notesList()
    this.selectedDiagonsis = ''
    this.selectedMednote = ''
    this.selectedDuration = ''
    this.selectedEvalType = ''
    this.diagnosisErr = false
    this.durationErr = false
    this.cancelPopupShowHide = true
    this.popupMessage = 'generateMednotes'
    this.mednotesType = [
      { name: 'Evaluation', id: 'evaluation', value: 'evaluation' },
      { name: 'Monthly Medication Management', id: 'monthlyMM', value: 'monthly_medication_management' }
    ]
    this.action = 'generateMednotes'
    Object.assign(this.initialMednoteFormData, {
      AppointmentId: Appt.appointmentId,
      AppointmentDate: Appt.appointmentDate,
      AppointmentTime: Appt.appointmentTime,
      AppointmentTypeName: Appt.appointmentType.appointmentTypeName,
      AppointmentTypeId: Appt.appointmentType.appointmentTypeId,
      ApptLocationName: Appt.location.locationName,
      ApptLocationID: Appt.location.locationId,
      ApptLocationType: Appt.location.locationType
    })


    //this.notesList()
    this.getMedNotesLists(Appt.appointmentId)

  }
  
  generateMednotes(mednote: any) {
    Object.assign(this.initialMednoteFormData, { noteType: mednote.name })
    this.selectedMednote = mednote.name


    if (mednote.name == 'Evaluation') {
      if (this.isEvaluationAlreadyExists) {
        this.totalMedNotes = true
      }
      else {
        this.totalMedNotes = false
      }
    }
    else {
      if (this.isMednoteAlreadyExists) { this.totalMedNotes = true }
      else {
        this.totalMedNotes = false
      }
    }


    let optionValue = mednote.value
    this.mednoteTypePopup = mednote.name
    this.popupMessage = 'typeOfDiagonsis'
    if (optionValue == 'evaluation') {
      this.diagonsisType = [
        { name: 'ADHD', id: 'adhd', code: 'F90.0' },
        { name: 'Anxiety', id: 'anxiety', code: 'F41.9' },
        { name: 'Depression', id: 'depression', code: 'F33.2' },
        { name: 'Adjustment Disorder', id: 'adjustment_disorder', code: 'F43.23' },
        { name: 'Bipolar', id: 'bi-polar', code: 'F3181' },
        { name: 'Oppositional Disorder', id: 'oppositional_disorder', code: 'F91.3' },
        { name: 'Schizophrenia', id: 'schizophrenia', code: 'F20.9' },
        { name: 'Eating Disorder', id: 'eating_disorder', code: 'F50.9' },
        { name: 'OCD', id: 'ocd', code: 'F42.9' },
        { name: 'Autism', id: 'autism', code: 'F84.0' },
      ]
      this.evalType = [
        { name: 'Annual', id: 'annual' }, 
        { name: 'New Eval', id: 're' },
      ]
      this.popUpDuration = false
      this.popUpEvalutionType = true
    }
    if (optionValue == 'monthly_medication_management') {
      this.diagonsisType = [
        { name: 'ADHD', id: 'adhd' },
        { name: 'Anxiety', id: 'anxiety' },
        { name: 'Adjustment Disorder', id:'adjustment_disorder'},
        { name: 'Depression', id: 'depression' },
        { name: 'Bipolar', id: 'bi-polar' },
        { name: 'Oppositional Disorder', id:'oppositional_disorder'},
        { name: 'Schizophrenia', id: 'schizophrenia' },
        { name: 'Eating Disorder', id:'eating_disorder'},
        { name:'OCD', id:'ocd_disorder'},
        { name: 'Autism', id:'autism'},
      ]
      this.durationOfVisit = [
        { name: '45 Minutes', id: '45mins', code: '99214' },
        { name: '25 Minutes', id: '25mins', code: '99213' },
      ]
      this.popUpEvalutionType = false
      this.popUpDuration = true

    }

  }
  
  getDiagonsis(obj: any) {
    this.selectedDiagonsis = obj.name
    if (this.selectedMednote == 'Evaluation') {
      Object.assign(this.initialMednoteFormData, { MednoteCode: obj.code })
    }
    Object.assign(this.initialMednoteFormData, { DiagonsisType: obj.name })
    if (this.selectedDiagonsis.trim() === '') {
      this.diagnosisErr = true
    } else {
      this.diagnosisErr = false
    }

  }
  
  getDuration(obj: any) {
    this.selectedDuration = obj.name
    if (this.selectedMednote == 'Monthly Medication Management') {
      Object.assign(this.initialMednoteFormData, { MednoteCode: obj.code })
    }
    Object.assign(this.initialMednoteFormData, { Duration: obj.name })
    if (this.selectedDuration.trim() === '') {
      this.durationErr = true
    } else {
      this.durationErr = false
    }

  }
  
  getEvalType(obj: any) {
    this.selectedEvalType = obj.name
    if (this.selectedMednote == 'Evaluation') {
      Object.assign(this.initialMednoteFormData, { EvalType: obj.id })
    }

    if (this.selectedEvalType.trim() === '') {
      this.evalTypeErr = true
    } else {
      this.evalTypeErr = false
    }
  }
  
  getMedNotesLists(appointmendId: any) {
    this.isMednoteAlreadyExists = false;
    this.isEvaluationAlreadyExists = false

    let patientID = this.initialMednoteFormData.patienID
    let appointmentId = appointmendId
    let userId = this.initialMednoteFormData.userId
    const payloadDataOne = {
      type: HTTPMethods.GET,
      url: API_URLS.GET_PATIENT_MEDNOTES,
      isDeveloper: true,
      pathVariables: [patientID, appointmentId, userId],
    };
    this.http.makeHttpRequest(payloadDataOne).subscribe((res) => {
      res.data.appointments[0].medNotes.map((med_note: any) => {
        if (med_note.mednoteType == 'MEDNOTE25' || med_note.mednoteType == 'MEDNOTE45') {
          this.isMednoteAlreadyExists = true
          return
        }
        else if (med_note.mednoteType == 'EVALUATION') {
          this.isEvaluationAlreadyExists = true
          return
        }
      })

    })


  }
  notesList() {
    let patientID = this.initialMednoteFormData.patienID
    let appointmentId = this.initialMednoteFormData.AppointmentId
    let userId = this.initialMednoteFormData.userId
    const payloadDataOne = {
      type: HTTPMethods.GET,
      url: API_URLS.GET_PATIENT_MEDNOTES,
      isDeveloper: true,
      pathVariables: [patientID, appointmentId, userId],
    };
    this.http.makeHttpRequest(payloadDataOne).subscribe((res) => {

      if (this.http.isSuccessfulResponse(res)) {

        this.popupMessage = 'MednoteList'
        this.previousMednoteList = res.data.appointments[0].medNotes;
       this.previousMednoteList= this.previousMednoteList.map((item: any) => {
          const date = new Date(item.updateTime);

          const day = String(date.getUTCDate()).padStart(2, '0');
          const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
          const month = monthNames[date.getUTCMonth()];
          const year = date.getUTCFullYear();
          const hours = date.getUTCHours();
          const ampm = hours >= 12 ? 'PM' : 'AM';
          const formattedDate = `${day} ${month} ${year}`
          return {
            ...item,
            createTime: formattedDate
          }

        })
        
        this.previousMednoteList = this.previousMednoteList.filter(item => item.mednoteType.toLowerCase() == this.mednoteTypePopup.toLowerCase() || (this.mednoteTypePopup ==='Monthly Medication Management' && (item.mednoteType =='MEDNOTE25'|| item.mednoteType =='MEDNOTE45')))

        if (this.previousMednoteList.length === 0) {
          this.MednoteRecordMsg = "There are no mednotes for this appointment"
          this.showMednoteList = false
        } else {
          this.showMednoteList = true
        }
      }
    })

  }
  
  editPerviousMednote(data) {
    let noteData = JSON.parse(data.medNotesData)
    this.popupMessage = 'MednoteList'
    if (data) {
      Object.assign(this.initialMednoteFormData, { 
        Operation: "EditMednote", 
        // MednoteData: data.questionAnswer, 
        MednoteId: data.medNotesId, 
        MednoteType: data.mednoteType })      
        this.initialMednoteFormData.DiagonsisType = data.diagnosis 
        if( data.mednoteType === "MEDNOTE45"){
          this.initialMednoteFormData.Duration = "45 Minutes"
        }
        if( data.mednoteType === "MEDNOTE25" ){
          this.initialMednoteFormData.Duration = "25 Minutes"
        }
        if(data.mednoteType === "EVALUATION"){
          if(noteData.evalutionType[0] === 'ANNUAL EVALUATION'){
            this.initialMednoteFormData.EvalType = 'annual'
          } else {
            this.initialMednoteFormData.EvalType = 're'
          }
        }
      this.router.navigate(['/admin/mednotes-form'], { queryParams: this.initialMednoteFormData })
    } else {
      if (this.selectedDiagonsis == '') {
        this.diagnosisErr = true
      } else {
        this.diagnosisErr = false
      }
      if (this.selectedDuration == '') {
        this.durationErr = true
      } else {
        this.durationErr = false
      }
    }

  }
  
  createNewMednote() {
    if (this.selectedDiagonsis && this.selectedMednote && this.selectedDuration) {
      Object.assign(this.initialMednoteFormData, { Operation: "CreateMednote" })
      console.log("this.initialMednoteFormData ", this.initialMednoteFormData );
      
      this.router.navigate(['/admin/mednotes-form'], { queryParams: this.initialMednoteFormData })
    } else {
      if (this.selectedDiagonsis == '') {
        this.diagnosisErr = true
      } else {
        this.diagnosisErr = false
      }
      if (this.selectedDuration == '') {
        this.durationErr = true
      } else {
        this.durationErr = false
      }

    }

    if (this.selectedDiagonsis && this.selectedMednote && this.selectedEvalType) {
      Object.assign(this.initialMednoteFormData, { Operation: "CreateMednote" })
      this.router.navigate(['/admin/mednotes-form'], { queryParams: this.initialMednoteFormData })
    } else {
      if (this.selectedDiagonsis == '') {
        this.diagnosisErr = true
      } else {
        this.diagnosisErr = false
      }
      if (this.selectedEvalType == '') {
        this.evalTypeErr = true
      } else {
        this.evalTypeErr = false
      }

    }


  }
  showDeleteMedNotePopup(note){
    this.deleteNoteData = note
    console.log(note);
    this.popupMessage = "DeleteMedNote"
  }
  deleteMednote(data){
    console.log("Detele patient Med note", data);
    let userId = data.userId
    let patientId = data.patientId
    let mednoteId = data.medNotesId
    const payloadData = {
      type: HTTPMethods.DELETE,
      url: API_URLS.DELETE_MEDNOTES,
      isDeveloper: true,
      pathVariables: [userId, patientId, mednoteId],
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        let message = (data.mednoteType === "MEDNOTE25" || data.mednoteType === "MEDNOTE45" ) ? `Patient's mednote successfully deleted` : `Patient's evaluation note successfully deleted`;
        this.observable.showToast(TOAST_STATUSES.SUCCESS, message);
        this.cancelPopupShowHide = false
      } else {
        // Error
        let message = (data.mednoteType === "MEDNOTE25" || data.mednoteType === "MEDNOTE45" ) ? `Failed to delete patient's mednote data. Please try again` : `Failed to delete patient's evluation data. Please try again`;
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
  //mednotes (end)
  // 23/6/22
  getGenerateRequest() {
    this.labRegisterDoc = []
    const payloadData: IGetMethodPayload = {
      type: HTTPMethods.GET,
      url: API_URLS.ADMIN_REQ_DOC,
      isDeveloper: true,
      pathVariables: ['GENERATE_DOCUMENT'],
    }
    this.http.makeHttpRequest(payloadData).subscribe(res => {
      if (res) {
        this.labRegisterDoc = res.data
        this.labRegisterDoc.forEach(element => {
          element.labRegisterId = element.documentId.toString()
        })
      }
    })
  }
  // 23/6/22
  postGenerateRequestId() {
    // route to generate html

    this.formSubmit = true;

    if (this.showDatePriceFields && !this.paymentReciepts.valid) {
      return;
    } else {
      this.cancelPopupShowHide = false;
      this.formSubmit = false;
    }

    if (this.btnSubmitText == 'Next') {
      setTimeout(() => {
        this.router.navigate(['/admin/generate-pdf-doc'], { queryParams: { id: this.patientData.patientId } })
      }, 100);
    } else {
      let data = this.labRegisterDoc.filter(value => {
        return value.isSelected
      })[0]
      this.labRegisterDoc = []
      const payload = {
        type: HTTPMethods.POST,
        url: API_URLS.POST_GENARTE_DOC,
        // cors
        isDeveloper: true,
        body: {
          documentId: data.documentId,
          patientId: this.patientData.patientId,
          documentHTML: data.PDFTemplate != null ? data.PDFTemplate : '<div>Document</div>',
        }
      }

      if (this.paymentReciepts.valid) {
        if (this.showDatePriceFields) {
          payload.body['data'] = { paymentRecepitsData: this.getPaymentRecepitsData() }
        }
      }

      this.http.makeHttpRequest(payload).subscribe(res => {
        this.paymentReciepts = new FormGroup({
          'date1': new FormControl('', []),
          'amount1': new FormControl('', []),
          'code1': new FormControl('', [])
        });
        if (this.http.isSuccessfulResponse(res)) {
          this.observable.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.PAYMENT_RECIEPTS_SUC)
        } else {
          let message = TOAST_MESSAGES.PAYMENT_RECIEPTS_FAIL
          message = res.error ? res.error.message : message
          this.observable.showToast(TOAST_STATUSES.ERROR, message)
        }
      })
    }
  }

  getPaymentRecepitsData() {
    let list = []
    let len = this.counter().length;
    for (let i = 1; i <= len; i++) {
      list.push({
        cpt: this.paymentReciepts.value['code' + (i)],
        date: this.formatDate(new Date(this.paymentReciepts.value['date' + (i)])),
        copay: this.paymentReciepts.value['amount' + (i)]
      })
    }
    return list;
  }

  /**
 * To format the current date.
 */
  formatDate(date: Date): string {
    var month: string = '' + (date.getMonth() + 1);
    var day: string = '' + date.getDate();
    var year: string = '' + date.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [month, day, year].join('/');
  }

  splitSelectedAttachment(eachgenerate) {
    const docName = eachgenerate.includes('__name__')
      ? eachgenerate.substring(eachgenerate.lastIndexOf('__name__') + 8, eachgenerate.lastIndexOf('?'))
      : eachgenerate.substring(eachgenerate.lastIndexOf('/') + 1, eachgenerate.lastIndexOf('?'))
    return docName
  }
  docUrl: any
  labGenerateSelectedValue(value) {
    this.showDatePriceFields = false;
    this.btnSubmitText = 'Submit'
    if (value.documentName === 'MEDICAL SUMMARY') {
      this.showPdf = false
      this.btnSubmitText = 'Next'
      this.findTheSelectedOption(value)
      // this.router.navigate(['/admin/generate-pdf-doc'])
    } else {
      this.showPdf = true
      this.btnSubmitText = 'Submit'
      this.findTheSelectedOption(value)
    }
    if (value.documentName == 'PAYMENT RECEIPTS') {
      this.showDatePriceFields = true;
      this.btnSubmitText = 'Submit'
    }
    else {
      this.showDatePriceFields = false;
    }
  }

  findTheSelectedOption(value) {
    this.labRegisterDoc.forEach(list => {
      if (list.documentId == value.documentId) {
        list.isSelected = true
        this.docUrl = list['attachments'][0]

        list['docslectedValue'] = this.splitSelectedAttachment(list['attachments'][0])
      } else {
        list.isSelected = false
      }
    })
  }
  donwloadGenFile(fileData) {
    let url = fileData['attachments'][0]
    let fullFileName = fileData['docslectedValue']
    fetch(url).then(t => {
      return t.blob().then(b => {
        var a = document.createElement('a')
        a.href = URL.createObjectURL(b)
        a.setAttribute('download', fullFileName)
        a.click()
      })
    })
  }
  /**
   * Edit document.
   * @param uploadFile
   * @param index
   */
  editFile(index) {
    document.querySelector('html').style.overflow = 'auto'
    // this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.LAB_DOC_EDIT}/${index}`]);
    window.open(`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.LAB_DOC_EDIT}/${index}`, '_blank')
  }
  // 23/6/22
  viewFile(index) {
    document.querySelector('html').style.overflow = 'auto'
    // this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.LAB_DOC_EDIT}/${index}`]);
    window.open(`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.LAB_DOC_EDIT}/${index}?isView=true`, '_blank')
  }

  dateformatter() {
    this.updatedDateValue = this.dateValue.toLocaleDateString();
  }
  archivePatient() {
    this.moreOptionTextClick.emit({ optionText: this.optionvalue, patientData: this.patientData })
  }

  activatePatientData(event, optionValue, patientData) {
    this.popupMessage = 'activate'
    this.action = 'activate'
    this.cancelPopupShowHide = true
    this.optionvalue = optionValue
  }
  activePatient() {
    this.moreOptionTextClick.emit({ optionText: this.optionvalue, patientData: this.patientData })
  }
  navigateDetailsPage(id) {
    this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.PATIENTS}/${id}`])
  }

  deletePatientData(event, patientData) {
    this.cancelPopupShowHide = true
    this.popupMessage = 'delete'
    this.action = 'delete'
    this.patienId = patientData.patientId
  }
  deletePatient() {
    this.deletPatient.emit({ patientId: this.patienId })
  }
  moreDisplay(e, op) {
    this.showDatePriceFields = false
    op.show(e)
  }
  cancelPopupShowHideStatusFun(status, isShow?) {
    this.cancelPopupShowHide = status.cancelpopup
    if (this.action == 'mednote') {
      this.selectedDiagonsis = ''
      this.selectedMednote = ''
      this.selectedDuration = ''
    }
    if (this.action && this.action === 'delete' && isShow) {
      this.deletePatient()
    } else if (this.action && this.action === 'activate' && isShow) {
      this.activePatient()
    } else if (this.action && this.action === 'archive' && isShow) {
      this.archivePatient()
    } else if (
      this.action &&
      this.action === 'unblock' &&
      isShow &&
      this.blockedServices.length === this.treatmentsCount + 1
    ) {
      this.moreOptionTextClick.emit({ optionText: this.patientData.blockedStatus, patientData: this.patientData })
    } else if (
      this.action &&
      this.action === 'unblock' &&
      isShow &&
      this.blockedServices.length !== this.treatmentsCount + 1
    ) {
      this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.NEWAPPOINTMENT}`], {
        queryParams: { patientId: this.patientData.patientId, patientEdit: true },
      })
    }
    //mednotes
    // if(this.action === 'generateMednotes'){}
  }

  // For displaying patient records popup
  documentsRequestPopupStatusFun(status) {
    this.displayDocumentsRequestPopup = status.cancelpopup
  }

  /**
   * Send mail to patient reg medicine.
   */
  sendMedicineInfoMail(patientData) {
    let payload: IPostMethodPayload = {
      type: 'POST',
      url: 'insuranceEmail',
      isDeveloper: true,
      body: {
        patientId: patientData.patientId,
        emailType: 'MEDICATION_MAIL',
        appointmentId: null,
        patientIdList: [],
      },
    }
    this.http.makeHttpRequest(payload).subscribe(res => {
      if (this.http.isSuccessfulResponse(res)) {
        this.observable.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.MEDS_NOTIFICATION_SUCCESS)
      } else {
        let message = TOAST_MESSAGES.MEDS_NOTIFICATION_FAIL
        message = res.error ? res.error.message : message
        this.observable.showToast(TOAST_STATUSES.ERROR, message)
      }
    })
  }

  patientDelete(patientData) {
    this.moreOptionTextClick.emit({ patientData })
  }

  counter() {
    return new Array(Object.keys(this.paymentReciepts.controls).length / 3);
  }
  addNew() {
    let id = Object.keys(this.paymentReciepts.controls).length / 3 + 1;
    this.paymentReciepts.addControl('date' + id, new FormControl('', [Validators.required]))
    this.paymentReciepts.addControl('amount' + id, new FormControl('', [Validators.required]))
    this.paymentReciepts.addControl('code' + id, new FormControl('', []))
  }

  remove(id: number) {
    this.paymentReciepts.removeControl('date' + id);
    this.paymentReciepts.removeControl('amount' + id);
    this.paymentReciepts.removeControl('code' + id);
    this.paymentReciepts.updateValueAndValidity();
  }
}
