<!-- home-blog section start -->
<section class="home-blog__section section-padding full-width">
    <!-- home-blog container start -->
    <div class="home-blog__container restrict-width padding-lr">
        <app-section-header [headingData]="headingData"></app-section-header>
        <!-- home-blog cards block start -->
        <div class="home-blog__cards-block" *ngIf="posts">
            <!-- home-blog card block start -->
            <div class="home-blog__card-block {{ colorClass[i] }}" (click)="navigateDetailsPage($event,post.id)" *ngFor="let post of posts; let i = index">
                <!-- home-blog image block start -->
                <div class="home-blog__image-block">
                    <img *ngIf="post" [src]="post.featured_image_src" alt="blog image" loading="lazy">
                </div>
                <!-- home-blog image block end -->
                <div class="home-blog__description-block">
                    <p class="blog__description">
                        {{ post.title.rendered }}
                    </p>
                    <h3 class="blog__date">
                        {{ post.date | date:'d MMMM, y' }}
                    </h3>
                </div>
                <div class="view-more__button-block">
                    <app-know-more (actionTriggered)="navigateBlogPage($event)" [actionBased]="true" [moreText]="moreText" [show]="true"></app-know-more>
                </div>
            </div>
            <!-- home-blog card block end -->
            <div class="view-more__button-block m-d-show">
                <app-know-more (actionTriggered)="navigateBlogPage($event)" [actionBased]="true" [moreText]="moreText" [show]="true"></app-know-more>
            </div>
        </div>
        <!-- home-blog cards block end -->
    </div>
    <!-- home-blog container end -->
</section>
<!-- home-blog section end -->