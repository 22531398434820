<!-- faqs section start -->
<section class="faqs__section full-width">
    <!-- faqs container start -->
    <div class="faqs__container restrict-width padding-lr">
        <!-- faqs cards block start -->
        <div class="faqs__cards-block">
            <!-- faqs heading block start -->
            <div class="faqs__heading-block">
                <h2 class="faqs__heading">What can we help you with?</h2>
                <h3 class="faqs__sub-heading">FAQ’S</h3>
            </div>
            <!-- faqs heading block end -->
            <!-- faqs card block start -->
            <div class="faqs__card-block" *ngFor="let card of cardDetails" [ngClass]="card.expanded ? 'expanded' : ' '">
                <div class="faqs__title-block" (click)="onClick(card)">
                    <div class="icon__block">
                        <img src="assets/images/svgs/{{ iconPath.iconPlus }}" alt="accordian icon">
                        <img src="assets/images/svgs/{{ iconPath.iconMinus }}" alt="accordian icon">
                    </div>
                    <h3 class="faqs__title">{{ card.title }} </h3>
                </div>
                <div class="faqs__description-block">
                    <p class="faqs__description" [innerHTML]="card.description" >
                    </p>
                    <div class="faqs-img" *ngIf="card.img">
                        <img src="{{card.img}}" alt="" srcset="">
                    </div>
                    <p *ngIf="card.description2" class="faqs__description" [innerHTML]="card.description2">
                    </p>
                    <div  class="faqs-img" *ngIf="card.img2">
                        <img src="{{card.img2}}" alt="" srcset="">
                    </div>
                    <p *ngIf="card.description3" class="faqs__description" [innerHTML]="card.description3">
                    </p>
                    <div  class="faqs-img" *ngIf="card.img3">
                        <img src="{{card.img3}}" alt="" srcset="">
                    </div>
                    <p *ngIf="card.description4" class="faqs__description" [innerHTML]="card.description4">
                    </p>
                    <div  class="faqs-img" *ngIf="card.img4">
                        <img src="{{card.img4}}" alt="" srcset="">
                    </div>
                    <p *ngIf="card.description5" class="faqs__description" [innerHTML]="card.description5">
                    </p>
                    <div  class="faqs-img" *ngIf="card.img5">
                        <img src="{{card.img5}}" alt="" srcset="">
                    </div>
                    <p *ngIf="card.description6" class="faqs__description" [innerHTML]="card.description6">
                    </p>
                    <div  class="faqs-img" *ngIf="card.img5">
                        <img src="{{card.img6}}" alt="" srcset="">
                    </div>
                    
                </div>

            </div>
            <!-- faqs card block end -->
        </div>
        <!-- faqs cards block end -->
    </div>
    <!-- faqs container end -->
</section>
<!-- faqs section end -->
