import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-success-card',
  templateUrl: './success-card.component.html',
  styleUrls: ['./success-card.component.scss']
})
export class SuccessCardComponent implements OnInit {

  @Input() successCardData = {
    profileImg: 'https://www.yelp.com/user_details?userid=m5aVIlTJVlCFXO7tLxif-g',
    comment: 'Dr. Prayaga is a very nice person and helped me through some rough times. Very empathetic!',
    profileName: 'Frank H'
  };

  constructor() { }

  ngOnInit() {
  }

}
