<section class="register-form__section">
    <div class="register-header">
        <span class="common-header">{{registrationData[registrationPhase].headerData1}}</span><br>
        {{registrationData[registrationPhase].headerData2}} <br><br>
        <span class="registered-user" [innerHtml]="registrationData[registrationPhase].headerData3"></span>
        <div>
            <div *ngIf="registrationPhase == 'post'" class="normal-registered-user">Verification link has been sent to
                <span class="text-bold">{{userMail}}</span>. If you have not received it yet, recheck your email id or
                Please check your inbox/spam/promotions/social section for an email from <span class="text-bold">
                    {{adminMail}}</span></div>
        </div>
    </div>
    <div class="registered-user" id="registered-user-id" [ngClass]="registrationPhase == 'post' ? 'color-change' : '' ">
        {{registrationData[registrationPhase].text1}}<br *ngIf="registrationPhase == 'post'"><span
            [ngClass]="registrationPhase != 'post' ? 'login' : '' "
            (click)="registrationPhase != 'post' ? existingUser() : 0">{{registrationData[registrationPhase].btnText}}</span>
    </div>
    <div class="register-form" id="register-form-id">
        <app-custom-form [formData]="form" (submitEvent)="btnClickEvent($event)"></app-custom-form>
    </div>
</section>