<!-- aboutus-approach section start -->
<section class="aboutus-approach__section full-width">
    <!-- aboutus-approach container start -->
    <div class="aboutus-approach__container restrict-width padding-lr">
        <div class="aboutus-approach__header-block">
            <app-section-header [headingData]="headingData"></app-section-header>
        </div>
        <!-- aboutus-approach cards block start -->
        <div class="aboutus-approach__cards-block">
            <div class="aboutus-approach__carousel-block">
                <ngx-slick-carousel class="carousel" [config]="slideConfig">
                    <div ngxSlickItem *ngFor="let card of aboutUsCards" class="slide">
                        <app-aboutus-card [cardData]="card"></app-aboutus-card>
                    </div>
                </ngx-slick-carousel>
            </div>
        </div>
        <!-- aboutus-approach cards block end -->
    </div>
    <!-- aboutus-approach container end -->
</section>
<!-- aboutus-approach section end -->