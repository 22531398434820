
<div class="email__template--section">
    <div class="email__template--info">
        <div>
            <img src="assets/images/pngs/logo.png" alt="Logo" class="email__template--logo" />
            <div class="email__template--main-info">
                <div class="email__template-sub-info">
                    <div class="email__template--highlight-text">Rescheduled Appointment</div>
                    <div class="email__template--name pb-10">Monday, July 6 at 2:30PM</div>
                    <div >Hi Vanka!</div>
                    <div class="need__help--content"> 
                        Your appointment with the office of <span class="strong-text">Dr. Seema Amin, OD</span>, has been rescheduled at your request for Monday, July 6 at 2:30PM. To cancel or reschedule this appointment, <a href="#">sign in</a> now.
                    </div>
                    <div class="appointment__details-section">
                        <div class="appointment__details-label">Appointment Details</div>
                        <div class="details-section">
                            <div class="appointment__details--picture">
                                <img alt="Doctor" src="assets/images/pngs/Doctor.png"/>
                            </div>
                            <div class="appointment-details">
                                <div class="appointment__details--name details-data">DR. SEEma Amin, OD</div>
                                <div class="appointment__details--date details-data details-flex">
                                    <div><app-svg [name]="'calendar-color'" class="calendar-icon details-icon"></app-svg></div>
                                    <div>06 March 2020</div>
                                </div>
                                <div class="appointment__details--time details-data details-flex">
                                    <div><app-svg [name]="'clock-color'" class="clock-icon details-icon"></app-svg></div>
                                    <div>07:30 AM</div>
                                </div>
                                <div class="appointment__details--place details-flex">
                                    <div><app-svg [name]="'office'" class="office-icon details-icon" ></app-svg></div>
                                    <div>Alexandria Office</div>
                                </div>
                            </div>
                            <div class="appointment__details--map">
                                <p-gmap #gmap [options]="options" [overlays]="overlays" [style]="{'width':'100%','height':'100%'}" class="google-map"></p-gmap>
                            </div>
                        </div>
                    </div>
                    <div class="inform-text">If the office cancels or reschedules this appointment, please <a>let us know</a>.</div>
                    <div>
                        <div class="pb-10">Have questions? Check out our<span><a href="#"> Knowledge Base</a></span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-template-footer></app-template-footer>
</div>

