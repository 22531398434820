<section class="email-template-details--section" *ngIf="templateDetails && permissionsDone">
    <div class="background-image">
        <img src="assets/images/pngs/newpatientform-background.png" alt="">
    </div>
    <div class="email-template-wrapper">
        <app-breadcrumb [breadCrumbData]="breadCrumbData"></app-breadcrumb>
        <div class="actions__wrapper">
            <div class="action__items" *ngIf="adminPermissions.TEMPLATE_EDIT" (click)="navigateEditScreen()">
                <app-svg [name]="'edit'"></app-svg>
                <div class="action__name">Edit</div>
            </div>
        </div>
        <div class="email-template-details--block" *ngIf="templateId != '13'" [ngClass]="!adminPermissions.TEMPLATE_EDIT?'removed-actions':''">
            <div class="each_block">
                <div class="label">Email Subject</div>
                <div class="value">{{templateDetails.subject}}</div>
            </div>
            <div class="each_block">
                <div class="label" >Trigger Condition</div>
                <div class="value">{{templateDetails.triggerCondition}}</div>
            </div>
            <div class="each_block inline-blocks">
                <div class="sending-time" *ngIf="templateDetails.sendingTime">
                    <div class="label">Email Sending Time</div>
                    <div class="value" *ngIf="templateDetails.sendingTime && templateDetails.id != '12'">{{templateDetails.sendingTime  | formatTime}}</div>
                    <div class="value" *ngIf="templateDetails.sendingTime && templateDetails.id == '12'">{{templateDetails.sendingTime}} Mins</div>
                </div>
                <div class="email-frequency" *ngIf="templateDetails.frequency">
                    <div class="label">Email Frequency</div>
                    <div class="value">{{templateDetails.frequency}} Days</div>
                </div>
                <div class="repeat-frequency" *ngIf="templateDetails.repeatFrequency">
                    <div class="label">Repeat Frequency</div>
                    <div class="value">{{templateDetails.repeatFrequency}} Days</div>
                </div>
            </div>
            <div class="each_block" *ngIf="!templateDetails.isEmailExcludable">
                <div class="label">Exclude Patients</div>
                <div class="mail__content__wrapper" *ngIf="templateDetails.excludedEmails.length>0">
                    <div class="each-email-id" *ngFor="let emailId of templateDetails.excludedEmails | slice:0:3">
                        <span class="email-id">{{emailId}}</span>
                    </div>
                    <div class="more" *ngIf="templateDetails.excludedEmails.length>3" (click)="viewMore()">+{{templateDetails.excludedEmails.length-3}} More</div>
                </div>
                <div class="value" *ngIf="templateDetails.excludedEmails.length==0">No Patients added</div>
            </div>
            <div class="content__preview" [innerHTML]="templateDetails.htmlTemplate"></div>
        </div>
        <div class="email-template-details--block" *ngIf="templateId == '13'" [ngClass]="!adminPermissions.TEMPLATE_EDIT?'removed-actions':''">
            <div class="each_block quesitons_block">
                <div class="label">Information Request Form Questions</div>
                <div class="value" *ngFor="let item of informationForm">
                    <div class="qns">{{item.questionLabel}}</div>
                    <div class="box"></div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-common-popup (showHideCancelPopup)='cancelPopupShowHideStatusFun($event)' *ngIf="cancelPopupShowHide">
    <div class="popup__wrapper exclude-patient--wrapper">
        <div class="popup__heading">Excluded Patients</div>
        <div class="mail__content__wrapper" *ngIf="templateDetails.excludedEmails.length>0">
            <div class="each-email-id" *ngFor="let emailId of templateDetails.excludedEmails">
                <span class="email-id">{{emailId}}</span>
            </div>
        </div>
    </div>
</app-common-popup>
