import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { MyAccountComponent } from './my-account/my-account.component';
import { ActivateGuard } from '@app/common/services/activate.guard';
import { WebFaqsComponent } from './web-faqs/web-faqs.component';



const routes: Routes = [
  {
    path: ROUTE_PATHS.EMPTY,
    component: MyAccountComponent,
    canActivate: [ActivateGuard]
  },
  {
    path: ROUTE_PATHS.FAQS,
    component: WebFaqsComponent,
    canActivate: [ActivateGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MyAccountRoutingModule { }
