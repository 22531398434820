<section class="info_from__edit--section information-request" *ngIf="isShowForm && permissionsDone">
    <div class="background-image">
        <img src="assets/images/pngs/newpatientform-background.png" alt="">
    </div>
    <div class="information_content">
        <app-breadcrumb [breadCrumbData]="breadCrumbData"></app-breadcrumb>
    </div>
    <div class="information__form">
        <app-custom-form [checkFormInfo]="saveFormData  ? resetSaveFormParity() : false" (submitEvent)="getFormData($event)" [formData]="formData">
        </app-custom-form>
    </div>
    <div class="common-stepper-inner" class="btn-actions info-req-btn-sec">
        <div class="cancel-btn" (click)="cancel()">Cancel</div>
        <div class="common-btn-style btn--inline" (click)="saveForm()">Submit</div>
    </div>
</section>