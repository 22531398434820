import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Console, log } from 'console';
import { HttpService } from '@app/common/services/http.service';
import { IGetMethodPayload, IformDataI } from '@app/common/constants/typeInterfaces.constants';
import { HTTPMethods } from '@app/common/constants/util.constant';
import { API_URLS } from '@app/common/constants/api-urls.constant';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { Validators } from '@angular/forms';


@Component({
  selector: 'app-infn-form',
  templateUrl: './infn-form.component.html',
  styleUrls: ['./infn-form.component.scss']
})
export class InfnFormComponent implements OnInit {
  formData: IformDataI;
  @Input() saveFormData;
  @Output() submitEvent: EventEmitter<any> = new EventEmitter();
  isShowForm: boolean = false;

  constructor(private http: HttpService,private formService: CustomFormHandlerService) {     
  }

  ngOnInit() {
    this.getrequest()    
  }

    // Method to make an HTTP GET request to fetch form data
  getrequest(){
    const payload: IGetMethodPayload = {
      type: HTTPMethods.GET,
      url: API_URLS.INFORMATION_REQUEST_DYNAMIC_FORM,
      isDeveloper: true,
    }    

    // Making the HTTP request to get the form data
    this.http.makeHttpRequest(payload).subscribe((res) => {
      console.log('Information_request', res);
      if(res.data){
        let formFields = res.data;
        // Transform the fetched form fields into the required format
        formFields = formFields.map(eachField =>{
          return {
            ...eachField,
            key: eachField.questionId,
            label: eachField.questionLabel,
            type: 'textarea',
            placeholder: '',
            maxLength: 250,
            required: true,
            value: '',
            controlType: 'textarea',
            emptyValueMessage: 'Please add description',
            validationValue: 'Please add valid description',
            validators: [Validators.required],
            className: '',
            editable: true,
            count: '250 remaining',
            showontoggle: true,
            showRange: true,
          };
        });
        // Update form configuration in the form service
        this.formService.informationRequestFormConfig = formFields;
        this.formData = this.formService.initForm(this.formService.formTypes.INFORMATION_REQUEST);
        this.isShowForm = true;
        console.log(formFields);
      }
      
    })

  }

  // Method to handle form data submission
  getFormData(event) {
    console.log(" infn form comp - getFormData()", event);
    if (event) {
      this.submitEvent.emit({status: event, step: 6});
    }
  }


}