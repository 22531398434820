<section class="schedular__section">
    <div class="tab-date__container">
        <div class="empty-cell container_size"></div>
        <div *ngFor="let date of tempDateArray;let i = index" class="tab-date">
            <div class="date-cell container_size"
                [ngClass]="selectedDate && date.getDate() == selectedDate.getDate() && (date.getMonth() == selectedDate.getMonth())?'selected--date':''"
                >
                {{date | date:'mediumDate'}}</div>
        </div>
        <div class="btn-action date-action">
            <div [ngClass]=" showCurrentdateIndex == 0 || isConfirm ?'back': 'back--enable'" (click)="changeDateData(false)">
                <app-svg [name]="'left-arrow'"></app-svg>
            </div>
            <div [ngClass]="showCurrentdateIndex == maxDateIndex || isConfirm ?'forward--disabled': 'forward'"
                (click)="changeDateData(true,this.rangeIndexLimitor.dateIndexLimit)">
                <app-svg [name]="'right-arrow'"></app-svg>
            </div>
        </div>
    </div>
    <div class="time-grid__container">
        <div class="time-tab">
            <div *ngFor="let timedata of timeArray;let i = index" class="time-htab container_size"
                [ngStyle]="{'display' : checkTimeInRange(i, 9) ? 'flex' : 'none'}">
                <div *ngIf="checkTimeInRange(i, dataRange.timeDataRange)">{{timedata}}</div>
            </div>
            <div class="btn-action time-action">
                <div [ngClass]=" showCurrenttimeRangeIndex == 0 || isConfirm ?'back': 'back--enable'"
                    (click)="schedularAction('showCurrenttimeRangeIndex')">
                    <app-svg [name]="'left-arrow'"></app-svg>
                </div>
                <div [ngClass]=" showCurrenttimeRangeIndex == this.rangeIndexLimitor.timeIndexLimit || isConfirm ?'forward--disabled': 'forward'"
                    (click)="schedularAction('showCurrenttimeRangeIndex', this.rangeIndexLimitor.timeIndexLimit, 'inc')">
                    <app-svg [name]="'right-arrow'"></app-svg>
                </div>
            </div>
        </div>
        <div class="grid-tab">
            <div class="grid__container" [ngClass]="isConfirm?'calendar-disable':''" *ngFor="let grid of cellArray;let i = index">
                <div class="grid-line">
                    <div class="cell container_size" *ngFor="let cell of grid;let j = index" (click)="cellClicked(cell)"
                        [ngClass]="cell.status">
                    </div>
                </div>
            </div>
            <div class="cell-state-ui isDesktop">
                <div class="booked-state state-info">
                    <div class="circle"></div>
                    <div>Booked</div>
                </div>
                <div class="available-state state-info">
                    <div class="circle"></div>
                    <div>Available</div>
                </div>
                <div class="selected-state state-info">
                    <div class="circle"></div>
                    <div>Selected</div>
                </div>
            </div>
            <div class="selected--msg isDesktop" *ngIf="selectedSlot.time">
                You have selected an appointment for {{selectedSlot.time | customTime}} on
                {{selectedSlot.date | customDate}}
            </div>
            <div class="invalid-feedback selected--msg isDesktop" *ngIf="selectedSlot.time == undefined">
                Please select your appointment time
            </div>
        </div>
    </div>
</section>
<ng-container>
    <div class="cell-state-ui isMobile">
        <div class="booked-state state-info">
            <div class="circle"></div>
            <div>Booked</div>
        </div>
        <div class="available-state state-info">
            <div class="circle"></div>
            <div>Available</div>
        </div>
        <div class="selected-state state-info">
            <div class="circle"></div>
            <div>Selected</div>
        </div>
        <div class="weekend-state state-info">
            <div class="circle"></div>
            <div>Weekend</div>
        </div>
    </div>
    <div class="selected--msg isMobile" *ngIf="selectedSlot.time">
        You have selected an appointment for {{selectedSlot.time | lowercase}} on {{selectedSlot.date | customDate}}
    </div>
    <div class="invalid-feedback selected--msg isMobile" *ngIf="selectedSlot.time == undefined">
        Please select your appointment time
    </div>    
</ng-container>