<ng-template #know>
    <span (click)="navigateBack()">{{moreText.moreTextLink}}</span>
    <div (click)="navigateBack()" class="icon__block more">
        <app-svg [name]="'more'"></app-svg>
    </div>
</ng-template>

<!-- know more btn start -->
<section *ngIf="show">
    <div *ngIf="actionBased; else link" (click)="actionClicked()" class="know-more-btn link__arrow-anim--right">
        <ng-container *ngTemplateOutlet="know">
        </ng-container>
    </div>
    <ng-template #link>
        <a  (click)="actionClicked()" class="know-more-btn link__arrow-anim--right" >
            <ng-container *ngTemplateOutlet="know">
            </ng-container>
        </a>
    </ng-template>
</section>
<!-- know more btn end -->