<section class="appointment__page__wrapper" *ngIf="availabiltyData">
    <div *ngIf="availabiltyData && availabiltyData.showScreen && userLogedIn" class="close-icon">
        <app-svg [name]="'plus-circle'" (click)="cancel()"></app-svg>
    </div>
    <div *ngIf="availabiltyData && availabiltyData.showScreen" class="stepper__block">
        <app-step-indicator [stepDetails]="stepDetails" (stepIndexClicked)="stepIndicatorClicked($event)"
            (forwardClicked)="forwardClicked()" (backClicked)="backClicked()" (sendStepIndex)="getStepIndex($event)"
            [currentStepIndex]="currentFormIndex"></app-step-indicator>
    </div>
    <div class="form--block" [ngSwitch]="currentStep.id">
        <div class="common-stepper-inner" *ngSwitchCase="'appointment'" class="appointment__block">
            <ng-container>
                <app-appointment-page *ngIf="iseditForm" (patientDetails)="patientDetails($event)" [patientDataList]="patientDataList" [availabiltyData]="availabiltyData" (typeOfTreatment)="changeStepper($event)"
                    (submitEvent)="getFormData($event)" [saveFormData]="saveFormData  ? resetSaveFormParity() : false">
                </app-appointment-page>
            </ng-container>

        </div>
        <div class="common-stepper-inner" *ngSwitchCase="'hippa_notice'" class="telemedicine__consent__block">
            <app-hippa-notice-form (submitEvent)="getFormData($event)"
                [saveFormData]="saveFormData  ? resetSaveFormParity() : false"></app-hippa-notice-form>
        </div>
        <div class="common-stepper-inner" *ngSwitchCase="'health_details'" class="health__details__block">
            <app-metal-health-details (submitEvent)="getFormData($event)"
                [saveFormData]="saveFormData  ? resetSaveFormParity() : false"></app-metal-health-details>
        </div>
        <div class="common-stepper-inner" *ngSwitchCase="'medication_consent'" class="health__details__block">
            <app-medication-consent (submitEvent)="getFormData($event)"  [patientDataList]="patientDataList"
                [saveFormData]="saveFormData  ? resetSaveFormParity() : false"></app-medication-consent>
        </div>
        <div class="common-stepper-inner" *ngSwitchCase="'telemedicine_consent'" class="telemedicine__consent__block">
            <app-telemedicine-consent (submitEvent)="getFormData($event)" [userName] = "this.user" [patientDataList]="patientDataList"
                [saveFormData]="saveFormData  ? resetSaveFormParity() : false"></app-telemedicine-consent>
        </div>
        <div class="common-stepper-inner" *ngSwitchCase="'ketamine_enrollment'" class="ketamine__enrollment__block">
            <app-ketamine-enrollment (submitEvent)="getFormData($event)"
                [saveFormData]="saveFormData  ? resetSaveFormParity() : false"></app-ketamine-enrollment>
        </div>
        <div class="common-stepper-inner" *ngSwitchCase="'ketamine_monitoring'" class="ketamine__monitoring__block">
            <app-ketamine-monitoring (submitEvent)="getFormData($event)"
                [saveFormData]="saveFormData  ? resetSaveFormParity() : false"></app-ketamine-monitoring>
        </div>
        <div class="common-stepper-inner" *ngSwitchCase="'substance_consent'" class="telemedicine__consent__block">
                        <app-substance-consent (submitEvent)="getFormData($event)" [patientDataList]="patientDataList"
            [saveFormData]="saveFormData  ? resetSaveFormParity() : false"></app-substance-consent>
        </div>
        <div class="common-stepper-inner" *ngSwitchCase="'feedback'" class="feedback__block">
            <app-feedback (submitEvent)="getFormData($event)"
                [saveFormData]="saveFormData  ? resetSaveFormParity() : false"></app-feedback>
        </div>
        <div class="common-stepper-inner" *ngSwitchCase="'infn_form'" class="infn__block">
            <app-infn-form (submitEvent)="getFormData($event)" [saveFormData]="saveFormData  ? resetSaveFormParity() : false" ></app-infn-form>
        </div>
    </div>
    <div class="common-stepper-inner" class="btn-actions"
        *ngIf="availabiltyData && availabiltyData.showScreen && userLogedIn">
        <div class="cancel-btn" (click)="cancel()">Cancel</div>
        <div class="common-btn-style btn--inline" (click)="back()" *ngIf="currentFormIndex >0">Prev</div>
        <div class="common-btn-style btn--inline" (click)="saveForm()">{{getActionText()}}</div>
    </div>
</section>