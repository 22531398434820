<div class="account-component">
    <img class="onbaord-form-bg" alt="background-leaf" src="assets/images/pngs/onboard-card-bg.png" loading="lazy"/>

    <div class="account-main-section">
        <div class="account-user-name">Hello {{name}}!</div>
        <div class="account-des">
            Thank you for all the details. We will notify you once your patient form is processed.
        </div>
        <div class="account-des-next">
            Meanwhile you can go ahead and book your appointment.<span class="appointment-link"><a (click)="redirectingToBookAppointment()">Click here</a></span>
        </div>
    </div>
</div>