<!--medical card template start-->
<ng-template #medicalCard let-link='link'>
    <!-- card bg block start -->
    <div class="medical__card-bg">
        <app-svg [name]="link.img"></app-svg>
    </div>
    <!-- card bg block end -->
    <h3 class="medical__card-heading">
        {{link.data.heading}}
    </h3>
    <p class="medical__card-description">
        {{link.data.description}}
    </p>
</ng-template>
<!--medical card template end-->

<!-- medical section start -->
<section class="medical__section section-padding full-width">
    <!-- bg image image block start -->
    <div class="medical__mobile-bg m-d-show">
        <img class="" src="assets/images/pngs/medical-bg.png" alt="leaf icon" loading="lazy"/>
    </div>
    <!-- bg image image block end -->
    <!-- medical container start -->
    <div class="medical__container restrict-width padding-lr">
        <div class="medical__header-block">
            <div class="icon__block medium">
                <img class="background-leaf3" src="assets/images/pngs/leaf7.png" alt="leaf icon" loading="lazy"/>
            </div>
            <app-section-header [headingData]="headingData"></app-section-header>
        </div>
        <!-- medical content block start -->
        <div class="medical__content-block" *ngIf="currentShowingIndexData">
            <!-- medical left block start -->
            <div class="medical__left-block">
                <div class="icon__block medical-treatment">
                    <app-svg [name]="'medical-treatment'" [imageLabel]="'adhd diagnosis adults near me DC'"></app-svg>
                </div>
                <div class="sub-heading">{{currentShowingIndexData.heading}}</div>
                <p class="medical__description">
                    {{currentShowingIndexData.description}}
                </p>
                <app-know-more [moreText]="moreText" [show]="false"></app-know-more>
            </div>
            <!-- medical left block end -->
            <!-- medical right block start -->
            <div class="medical__right-block">
                <div class="medical__cards-block">

                    <!-- medical card block start -->
                    <div *ngFor="let card of medicalDetailConfig; let i = index"
                        class="medical__card-block {{card.class}}" (click)="medicalCardClicked(i)">
                        <ng-container [ngTemplateOutlet]="medicalCard" [ngTemplateOutletContext]="{link:card}">
                        </ng-container>
                    </div>
                    <!-- medical card block end -->
                    <app-know-more [moreText]="moreText" [show]="false"></app-know-more>
                </div>

                <div class="medical__cards-carousel">
                    <ngx-slick-carousel class="carousel" [config]="slideConfig">
                        <!-- medical card block start -->
                        <div ngxSlickItem *ngFor="let card of medicalDetailConfig; let i = index"
                            class="medical__card-block {{card.class}} slide" (click)="medicalCardClicked(i)">
                            <ng-container [ngTemplateOutlet]="medicalCard" [ngTemplateOutletContext]="{link:card}">
                            </ng-container>
                            <app-know-more [moreText]="moreText" [show]="false"></app-know-more>
                        </div>
                        <!-- medical card block end -->
                    </ngx-slick-carousel>
                </div>

            </div>
            <!-- medical right block end -->
        </div>
        <!-- medical content block end -->
    </div>
    <!-- medical container end -->
</section>
<!-- medical section end -->