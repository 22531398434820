<div class="outer" *ngIf="documentDetails">
    <div class="close" (click)="closePage()">
        <app-svg [name]="'cancel-appointment'" ></app-svg>
    </div>
    <div class="inner">
        <div class="title">Document Request Details</div>
        
        <div class="stsi" [ngClass]="documentDetails.status">{{documentDetails.status}}</div>
        <br>
        <br>
        <div class="text">Hello {{documentDetails.firstName | titlecase}} {{documentDetails.lastName |
            titlecase}}, please upload the documents mentioned below. These
            help us to serve you better and provide you a more effective treatment. </div>
        <br>
        <br>
        <div class="sts">Requested Details</div>
        <ul>
            <li class="text" *ngFor="let document of documentDetails.documents;let i=index">{{document.documentName}}</li>
        </ul>
        <br>
        <br>
        <div class="sts">Other Details</div>
        <div class="text" *ngIf="documentDetails.otherComment">{{documentDetails.otherComment}}</div>
        <div class="text" *ngIf="!documentDetails.otherComment">---</div>
        <br>
        <br>
        <div class="">
            <div class="verti documents_download-wrapper">
                <div class="sts">Clinic Documents <span class="download noselect" *ngIf="documentDetails.adminDocumentURLs.length != 0" (click)="downloadAll()">Download All</span></div>
                <div class="value" *ngIf="documentDetails.adminDocumentURLs.length == 0">No documents available</div>
                <div class="value documents_wrapper">
                    <span class="each_document" *ngFor="let document of documentDetails.adminDocumentURLs">
                        <div class="each_document-name">
                            <p class="noselect">{{document.fileName | ellipsis: 25}}</p>
                        </div>
                        <div class="each_document-actions">
                            <a [href]="document.url" target="_blank" title="View" class="view-icon">
                                <app-svg [name]="'view-upload'"></app-svg>
                            </a>
                            <p class="down-arrow" (click)="downloadImage(document.url)"
                                title="Download">
                                <app-svg [name]="'down-arrow'"></app-svg>
                            </p>
                        </div>
                        <p class="down-arrow-mobile" (click)="downloadImage(document.url)"
                                title="Download">
                                <app-svg [name]="'down-arrow'"></app-svg>
                        </p>
                    </span>
                </div>
            </div>
            <br>
            <br>
        </div>
        <div class="sts">Upload Documents</div>
        <div class="form__field multi_upload patient__upload__field">
            <p-fileUpload name="patient_uploads[]" #fileUpload   [auto]="true" chooseLabel="Choose files to Upload"  accept=".jpg, .png, .gif, .jpeg, .tiff, .psd, .pdf, .esp, .ai, .indd, .raw, .heic, .heif" maxFileSize="10000000" multiple="multiple" (onSelect)="onUpload($event,fileUpload)"  (onRemove)="filesRemoved($event,fileUpload)" (onClear)="allFilesCleared($event)" fileLimit="5">
                <ng-template pTemplate="content">
                    <p class="content-message">Or Drag and Drop them here 
                        <span class="helpTxt">(maximum 5 allowed)</span>
                    </p>
                </ng-template>
            </p-fileUpload>
        </div>
        <div class="error-msg days" *ngIf="uploadedFiles && uploadedFiles.length == 0"><span>Please upload minimum 1 document</span></div>
        <div class="error-msg days" *ngIf="fileValidation == false && uploadedFiles && uploadedFiles.length != 0 && uploadedFiles.length <= '5'"><span>Please upload valid documents</span></div>
        <div class="error-msg days" *ngIf="fileValidation == false && uploadedFiles && uploadedFiles.length > '5'"><span>Maximum 5 documents are allowed to upload. Please remove extra documents</span></div>
        <br>
        <br>
        <div class="sts">Comments(optional)</div>
        <div class="text-area-block">
            <textarea  
            class="text-area input" type="textarea" [attr.maxLength]="150"
            value="" placeholder="Enter details here"
            (input)="textCounter($event)"
            [(ngModel)]="otherValue"></textarea>
            <input disabled
                class="textarea-range-show" maxlength="3" size="3"
                value="{{remainingValue}} remaining" id="remaining">
        </div>
        
        <br>
        <br>
        
    </div>
    <div class="btn btn-section patient-doc-footer">
        <button class="cncl cancel-btn" (click)="closePage()">Cancel</button>
        <button class="common-btn-style" (click)="sumbitForm()"> Submit </button>
    </div>
</div>