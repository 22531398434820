<section class="new-pateint__section admin__ketamine__monitoring" *ngIf="appointmentDetails">
    <div class="step__indicator">
        <app-step-indicator [isHorizontal]="false" [stepDetails]="stepDetails"
            (stepIndexClicked)="stepIndicatorClicked($event)" [currentStepIndex]="currentFormIndex">
        </app-step-indicator>
    </div>
    <div class="form__container">
        <div class="close-icon">
            <app-svg [name]="'plus-circle'" (click)="navigateBack()"></app-svg>
        </div>
        <div class="ketamine-form" (scroll)="onScroll($event)">
            <div class="patient__info step">
                <div class="sub_headers">
                    Patient Details
                </div>
                <div class="patient__details-block">
                    <div>
                        <div class="label">First Name</div>
                        <div class="value">{{appointmentDetails.patientBasicInfo.firstName  | titlecase}}</div>
                    </div>
                    <div>
                        <div class="label">Last Name</div>
                        <div class="value">{{appointmentDetails.patientBasicInfo.lastName  | titlecase}}</div>
                    </div>
                    <div>
                        <div class="label">Patient’s Date of Birth</div>
                        <div class="value">{{appointmentDetails.patientBasicInfo.dob  | date}}</div>
                    </div>
                    <div>
                        <div class="label">Gender</div>
                        <div class="value">{{appointmentDetails.patientBasicInfo.gender  | titlecase}}</div>
                    </div>
                </div>
            </div>
            <div class="step">
                <div class="medication_info">
                    <div class="sub_headers">
                        Medication Details
                    </div>
                    <div class="label medicaiton--questionnaire">Is the patient currently taking any of the following concomitant medication(s) that may cause sedation or blood pressure changes?</div>
                    <div class="medicaiton__details-block">
                        <app-custom-form [checkFormInfo]="saveFormData ? resetSaveFormParity() : false" [formData]="medicationFormData" (submitEvent)="getFormData($event, 1)" (inValidSubmitEvent)="inValidSubmitEvent($event,1)"></app-custom-form>
                    </div>
                </div>
                <div class="health__setting_info">
                    <div class="sub_headers">Healthcare Setting Information</div>
                    <div class="health__setting__details-block">
                        <div>
                            <div class="label">First Name</div>
                            <div class="value">{{appointmentDetails.healthCareInfo.doctorFirstName}}</div>
                        </div>
                        <div>
                            <div class="label">Last Name</div>
                            <div class="value">{{appointmentDetails.healthCareInfo.doctorLastName}}</div>
                        </div>
                        <div>
                            <div class="label">Telephone</div>
                            <div class="value">{{appointmentDetails.healthCareInfo.healthCareTelephone}}</div>
                        </div>
                        <div>
                            <div class="label">Healthcare Setting Name</div>
                            <div class="value">{{appointmentDetails.healthCareInfo.healthCareSettingName}}</div>
                        </div>
                        <div>
                            <div class="label">Healthcare Setting Address</div>
                            <div class="value">{{appointmentDetails.healthCareInfo.healthCareAddress}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="appointment_info step">
                <div class="sub_headers">Treatment Session Information</div>
                <div class="treatment__monitoring--form">
                    <app-custom-form [checkFormInfo]="saveFormData ? resetSaveFormParity() : false" [formData]="treatmentSessionFormData" (submitEvent)="getFormData($event, 2)" (pMaskOutput)="onChangeMaskInput($event)" (addNewPatient)="onChangeDropDown($event)" (inValidSubmitEvent)="inValidSubmitEvent($event,2)"></app-custom-form>
                </div>
            </div>
            <div  class="action__btns" (scroll)="onScroll($event)">
                <div class="btn-actions">
                    <div class="cancel-btn" (click)="navigateBack()">Cancel</div>
                    <div class="common-btn-style gap" (click)="isSave = true;saveForm();">Save</div>
                    <div class="common-btn-style" (click)="isSave = false;saveForm()">Submit</div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-common-popup (showHideCancelPopup)='cancelPopupShowHideStatusFun($event)' *ngIf="cancelPopupShowHide && !isSave">
    <div class="popup-content-block">
        <div class="popup-header">Patient Monitoring form</div>
        <div class="confirm-message">Once the form is submitted, you can't edit again. Do you want to continue?</div>
        <div class="btn-actions">
            <div class="common-btn-style yes-btn" (click)="cancelPopupShowHideStatusFun($event)">No</div>
            <div class="common-btn-style" (click)="submit();cancelPopupShowHide = false">Yes</div>
        </div>
    </div>
</app-common-popup>