// This component is for the Ketamine enrollment form in the appointment booking flow.

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { DataService } from '@app/common/services/data.service';
import { Router } from '@angular/router';
import { KETAMINE_ENROLLMENT_KEYS } from '@app/common/constants/form-keys.constant';
import { IFormEmitDataType } from '@app/common/constants/typeInterfaces.constants';
import { convertDateToSpecifiedOffset, DEFAULT_TIME_ZONE_DIFF } from '@app/common/constants/util.constant';

@Component({
  selector: 'app-ketamine-enrollment',
  templateUrl: './ketamine-enrollment.component.html',
  styleUrls: ['./ketamine-enrollment.component.scss']
})
export class KetamineEnrollmentComponent implements OnInit {

  @Input() saveFormData: boolean;
  @Output() submitEvent: EventEmitter<IFormEmitDataType> = new EventEmitter();
  formData: Array<Record<string, unknown>>;

  constructor(
    private formService: CustomFormHandlerService, 
    private dataService: DataService, 
    private router: Router) 
    { }

  ngOnInit() {

    // set current date
    this.formService.KETAMINEEnrollmentFormConfig.forEach(element => {
      if(element.key == KETAMINE_ENROLLMENT_KEYS.KETAMINE_ENR_DATE){
        element.value = this.formatDate(new Date(convertDateToSpecifiedOffset(DEFAULT_TIME_ZONE_DIFF,new Date())));
      }
    });
    this.formData = this.formService.initForm(this.formService.formTypes.KETAMINEENROLLMENT);
  }

  /**
   * Form Submit event.
   * @param event form data
   */
  getFormData(event) {
    this.formService.feedbackFormConfig = this.assignFormValues(event, this.formService.KETAMINEEnrollmentFormConfig);
    this.dataService.setData(this.dataService.dataObject.KETAMINEENROLLMENT, this.formService.KETAMINEEnrollmentFormConfig);
    this.submitEvent.emit({status: event,  step: 7});
  }

  /**
   * To set the previous values to the form.
   * @param formData formData
   * @param formConfig FormConfig
   */
  assignFormValues(formData, formConfig) {
    let keys = Object.keys(formData.value);
    formConfig.forEach((each, index) => {
      if (keys.includes(each.key)) {
        each.value = formData.value[each.key];
      }
      if(each.key == KETAMINE_ENROLLMENT_KEYS.KETAMINE_ENR_MEDICATION){
        each.formInputs.forEach((element, index) => {
          element.forEach(element2 => {
            element2.value = formData.value[each.key][index][element2.key]
          });
        });
      }
    });
    return formConfig;
  }

  /**
   * To format the current date.
   */
  formatDate(date: Date): string {
    var month: string = '' + (date.getMonth() + 1);
    var day: string = '' + date.getDate();
    var year: string = '' + date.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [month, day, year].join('/');
  }
}
