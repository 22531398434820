<!-- ketamine card block start -->
<div class="ketamine__card-block {{ ketamineData.ketamineClass }}">
    <!-- ketamine icon block start -->
    <div class="ketamine__card-icon-block">
        <div class="icon__block ketamine">
            <img src="assets/images/svgs/{{ ketamineData.ketamineIcon }}.svg" alt="{{ ketamineData.ketamineIcon }} icon">
        </div>
        <h3 class="ketamine__card-heading">
            {{ ketamineData.ketamineHeading }}
        </h3>
    </div>
    <!-- ketamine icon block end -->
    <p class="ketamine__card-description">
        <span class="ketamine__card-heading">
            {{ ketamineData.ketamineHeading }}
        </span>
        {{ ketamineData.ketamineDescription }}
    </p>
</div>
<!-- ketamine card block end -->