import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonAppModule } from '@app/common-app.module';
import { newPatientRoutingModule } from '@app/pages/new-patient/newPatient-routing.module';
import { NewPatientWrapperComponent } from './new-patient-wrapper/new-patient-wrapper.component';
import { FormsModule } from '@angular/forms';
import {CalendarModule} from 'primeng/calendar';
const DECLARATION_COMPONENTS = [
  NewPatientWrapperComponent,
];

@NgModule({
  declarations: [
    ...DECLARATION_COMPONENTS,
  ],
  imports: [
    CommonModule,
    CommonAppModule,
    newPatientRoutingModule,
    FormsModule,
    CalendarModule
  ],
  exports: []
})
// tslint:disable-next-line: class-name
export class newPatientModule { }
