<section class="appointment__page__wrapper" *ngIf="isShowScreen">
    <!-- Appointment details starts here -->
    <div class="patient__details--block" *ngIf="appDetailsDisplay">
        <div class="each__block">
            <div class="details__lable">Patient Name</div>
            <div class="details__value">{{appointmentDetails.patientName}}</div>
        </div>
        <div class="each__block">
            <div class="details__lable">Patient ID</div>
            <div class="details__value">{{appointmentDetails.patientId}}</div>
        </div>
        <div class="each__block">
            <div class="details__lable">Location</div>
            <div class="details__value">{{appointmentDetails.appLocation}}</div>
        </div>
        <div class="each__block">
            <div class="details__lable">Treatment</div>
            <div class="details__value">{{appointmentDetails.appName}}</div>
        </div>
    </div>
    <div class="close-icon">
        <app-svg [name]="'plus-circle'" (click)="cancel()"></app-svg>
    </div>
    <div class="stepper__block">
        <app-step-indicator [stepDetails]="stepDetails" (stepIndexClicked)="stepIndicatorClicked($event)"
            (forwardClicked)="forwardClicked()" (backClicked)="backClicked()" (sendStepIndex)="getStepIndex($event)"
            [currentStepIndex]="currentFormIndex"></app-step-indicator>
    </div>
    <div class="form--block" [ngSwitch]="currentStep.id">
        <div class="common-stepper-inner" *ngSwitchCase="'tmsQuestionnaire'" class="appointment__block">
            <ng-container>
                <app-tms-questionnaire (submitEvent)="getFormData($event)"
                [saveFormData]="saveFormData  ? resetSaveFormParity() : false" [isOffline]="appointmentDetails ? appointmentDetails.isOffline : true" [filledTMSDetails]="filledTMSDetails" [isReview]="isReview">
                </app-tms-questionnaire>
            </ng-container>
        </div>
        <div class="common-stepper-inner" *ngSwitchCase="'hamQuestionnaire'" class="appointment__block">
            <ng-container>
                <app-ham-questionnaire (submitEvent)="getFormData($event)"
                [saveFormData]="saveFormData  ? resetSaveFormParity() : false" [filledTMSDetails]="filledTMSDetails" [isReview]="isReview">
                </app-ham-questionnaire>
            </ng-container>
        </div>
        <div class="common-stepper-inner" *ngSwitchCase="'gadQuestionnaire'" class="appointment__block">
            <ng-container>
                <app-gad-questionnaire (submitEvent)="getFormData($event)"
                [saveFormData]="saveFormData  ? resetSaveFormParity() : false" [filledTMSDetails]="filledTMSDetails" [isReview]="isReview">
                </app-gad-questionnaire>
            </ng-container>
        </div>
        <div class="common-stepper-inner" *ngSwitchCase="'phQuestionnaire'" class="appointment__block">
            <ng-container>
                <app-phq-questionnaire (submitEvent)="getFormData($event)"
                [saveFormData]="saveFormData  ? resetSaveFormParity() : false" [filledTMSDetails]="filledTMSDetails" [isReview]="isReview">
                </app-phq-questionnaire>
            </ng-container>
        </div>
    </div>
    <div class="common-stepper-inner" class="btn-actions">
        <div class="cancel-btn" (click)="cancel()">Cancel</div>
        <div class="common-btn-style btn--inline" (click)="back()" *ngIf="currentFormIndex >0">Prev</div>
        <div class="common-btn-style btn--inline" (click)="saveForm()" *ngIf="!hideSubmit">{{getActionText()}}</div>
    </div>
</section>