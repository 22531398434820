import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';

@Component({
  selector: 'app-location-schedule-popup',
  templateUrl: './location-schedule-popup.component.html',
  styleUrls: ['./location-schedule-popup.component.scss']
})
export class LocationSchedulePopupComponent implements OnInit {
  @Input() schedules: any;
  @Input() sessionDuration: number;
  @Input() isEditType: any;
  @Input() scheduleDetails: any;
  @Input() popIndex: any;
  @Output() showHideCancelPopup = new EventEmitter();
  @Output() sendScheduleDetails = new EventEmitter();
  @Input()  timeSlots: any;
  daysSelected = [];
  fromTimeSelected: any = null;
  toTimeSelected: any = null;
  showError: boolean = false;
  weekArr: any = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  checkedArr: any;
  scheduleErrorMessage: any = '';
  fromList: any = [];
  toList: any = [];
  formGroup: any;
  formInputs: any;
  isShowScreen: boolean = false;


  constructor(private formServices: CustomFormHandlerService) {
    this.formGroup = this.formServices.initForm(this.formServices.formTypes.LOCATIONCREATIONPOPUP);
    this.formInputs = this.formGroup.formInputs;
   }

  ngOnInit() {
    if (this.scheduleDetails && this.scheduleDetails.fromTime) {
      console.log(this.scheduleDetails);
      this.getDropValue(this.sessionDuration, this.scheduleDetails);
      this.daysSelected = [...this.scheduleDetails.days];
      this.weekArr = this.weekArr.map((Day, i) => {
         return { day: Day, isSelected: this.daysSelected.includes(i) ? true : false };
       });
       // console.log(this.weekArr);
     }
  }
  handleDaysChange(event, index) {
    if (event.checked) {
      this.daysSelected.push(index);
      this.daysSelected.sort();
    } else {
      this.daysSelected.forEach((val, i) => val === index ? this.daysSelected.splice(i, 1) : null);
    }
    // console.log(this.daysSelected);
  }

  showOrHideCancelPopup(status) {
    this.showHideCancelPopup.emit(status.cancelpopup);
  }
  saveSchedule() {
    this.showError = false;
    if (this.daysSelected.length && this.fromTimeSelected < this.toTimeSelected) {
      // console.log(this.schedules," -schedules,",this.schedules.length)
      if (this.schedules.length > 1 || (!this.isEditType && this.schedules.length > 0)) {
        this.schedules.forEach((obj, index) => {
          if (this.popIndex !== index) {
              obj.days.forEach(day => {
                if (this.daysSelected.includes(day)) {
                  let objFromTime = obj.fromTime.toString().split(' ')[4];
                  let objToTime = obj.toTime.toString().split(' ')[4];
                  let curFromTime = this.fromTimeSelected.toString().split(' ')[4];
                  let curToTime = this.toTimeSelected.toString().split(' ')[4];
                  if (objFromTime === curFromTime && objToTime === curToTime) {
                    this.showError = true;
                    return false;
                  }
                }
              });
          }
          if (this.showError) { this.scheduleErrorMessage = 'Selected timing was already scheduled for some/one of the day(s). Please choose a different selection.'; return false; }
        });
      }
      if (!this.showError) {
        // console.log(this.daysSelected, this.fromTimeSelected, this.toTimeSelected);
        this.sendScheduleDetails.emit({ days: this.daysSelected, fromTime: this.fromTimeSelected, toTime: this.toTimeSelected });
        this.showOrHideCancelPopup({ cancelpopup: false });
      }
    } else if (this.fromTimeSelected >= this.toTimeSelected) {
      this.showError = true;
      this.scheduleErrorMessage = 'To time should be greater than From time';
    }
  }

  getDropValue(duration, schedules) {
    // tslint:disable-next-line:radix
    duration = parseInt(duration);
    // tslint:disable-next-line:one-variable-per-declaration
    let fromTime = new Date();
    fromTime.setHours(6);
    fromTime.setMinutes(30);
    fromTime.setSeconds(0);
    let toTime = new Date();
    toTime.setHours(23);
    toTime.setMinutes(30);
    toTime.setSeconds(0);
    let fromlist = [];
    while (fromTime <= toTime) {
      fromlist.push(new Date(fromTime));
      fromTime.setMinutes(fromTime.getMinutes() + duration);
    }
    fromlist = fromlist.map((time, i) => ({label: time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }), value: i + 1}));
    this.toList = [...fromlist];
    this.toList.splice(0, 1);
    this.fromList = [...fromlist];
    this.fromList.splice(-1, 1);
    let from = schedules.fromTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    let to = schedules.toTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    // tslint:disable-next-line:one-variable-per-declaration
    let flag1, flag2 = false;
    this.formInputs.forEach(form => {
      if (form.key === 'fromDate') {
        form.list = [...this.fromList];
        this.fromList.forEach(timeObj => {
          if (timeObj.label === from) {
            flag1 = true;
            this.fromTimeSelected = new Date(this.getDate(timeObj.label));
            form.value = timeObj.value;
          }
        });
        if (!flag1) {
          this.fromTimeSelected = new Date(this.getDate(this.fromList[0].label));
          form.value = this.fromList[0].value;
        }
      }
      if (form.key === 'toDate') {
        form.list = [...this.toList];
        let flag = false;
        this.toList.forEach(timeObj => {
          if (timeObj.label === to) {
            flag2 = true;
            this.toTimeSelected = new Date(this.getDate(timeObj.label));
            form.value = timeObj.value;
          }
        });
        if (!flag2) {
          this.toTimeSelected = new Date(this.getDate(this.toList[this.toList.length - 1].label));
          form.value = this.toList[this.toList.length - 1].value;
        }
      }
    });
    this.isShowScreen = true;
  }
  dropDown(event, field) {
    console.log(event);
    this.showError = false;
    if (field.key === 'fromDate') {
      this.fromList.forEach(timeObj => {
        if (timeObj.value === event.value) {
          this.fromTimeSelected = new Date(this.getDate(timeObj.label));
        }
      });
    }
    if (field.key === 'toDate') {
      this.toList.forEach(timeObj => {
        if (timeObj.value === event.value) {
          this.toTimeSelected = new Date(this.getDate(timeObj.label));
        }
      });
    }
    console.log(this.fromTimeSelected, this.toTimeSelected);
    if (this.fromTimeSelected >= this.toTimeSelected) {
      this.showError = true;
      this.scheduleErrorMessage = 'To time should be greater than From time';
    }
  }

  getDate(timeLabel) {
    // tslint:disable-next-line:one-variable-per-declaration
    let getAmPm, getHour, getMins, date;
    getAmPm = timeLabel.split(' ')[1];
    getHour = timeLabel.split(' ')[0].split(':')[0];
    getHour = getAmPm === 'PM' && getHour === '12' ? 0 : getAmPm === 'AM' && getHour === '12' ? 24 : getHour;
    // tslint:disable-next-line:radix
    getHour = getAmPm === 'PM' ? parseInt(getHour) + 12 : parseInt(getHour);
    getMins = timeLabel.split(' ')[0].split(':')[1];
    date = new Date();
    date.setHours(getHour, getMins, 0, 0);
    return date;
  }

}
