export const adminAppointmentsTabs = [
  {
    label: 'Upcoming',
    id: 0,
    selected: true,
    routingLabel: 'upcoming',
  },
  {
    label: 'History',
    id: 1,
    selected: false,
    routingLabel: 'history',
  },
  {
    label: 'All',
    id: 2,
    selected: false,
    routingLabel: 'all',
  }
];
export const notificationsTabs = [
  {
    label: 'All',
    id: 0,
    selected: true,
    routingLabel: 'all',
  },
  {
    label: 'Critical',
    id: 1,
    selected: false,
    routingLabel: 'critical',
  }
];
export const locationDetails = [
  {
    label: 'Details',
    id: 0,
    selected: true,
    routingLabel: 'details',
  },
  {
    label: 'Calendar',
    id: 1,
    selected: false,
    routingLabel: 'calendar',
  }
];
