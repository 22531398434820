import { HttpClient } from '@angular/common/http'
import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service'
import { ActivatedRoute, Router } from '@angular/router'
import { HttpService } from '@app/common/services/http.service'
import { ObservableHelperService } from '@app/common/services/observable-helper.service'
import { FormBuilder, Validators } from '@angular/forms'
import { CognitoService } from '@app/common/services/cognito.service'
import { API_URLS } from '@app/common/constants/api-urls.constant'
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant'
import { ROUTE_PATHS } from '@app/common/constants/routing.constant'
import { DEFAULT_PAGINATION_LIMIT, HTTPMethods, TOAST_STATUSES } from '@app/common/constants/util.constant'
import { IGetMethodPayload } from '@app/common/constants/typeInterfaces.constants'
import { formatDate } from '@angular/common'

// import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-mednotes-form',
  templateUrl: './mednotes-form.component.html',
  styleUrls: ['./mednotes-form.component.scss'],
})
export class MednotesFormComponent implements OnInit {
  
  // formData: any;
  saveFormData: boolean = false
  patientId: any
  apptID: any
  userID: any
  mednoteType: string
  appointMentdata: any
  userdata: any
  formattedCurrentDate: string
  infnData: any
  providers: any = {}
  pharmacy: any
  Follow_Up_25Mins_Form_Tags: any = ['MEDNOTES_FOLLOW_UP_TWENTY_FIVE']
  Follow_Up_45Mins_Form_Tags: any = ['MEDNOTES_FOLLOW_UP_FORTY_FIVE']
  Eval_Form_Tags: any = ['MEDNOTES_EVAL']
  form: any = []
  MednoteData: any = {}
  doctorNotes: any = []
  INITIAL_PAYLOAD = {
    pagination: {
      page: 1,
      limit: DEFAULT_PAGINATION_LIMIT,
    },
  }
  MednoteID: any
  documentDetails: any
  siblingFlag: boolean = false
  employerFlag: boolean = false
  patientMednotes: any
  infnData_emailTemplates: any
  PreviewData: any
  selectedButton: string
  showPreview: boolean = false
  showNotesForm: boolean = true
  previousNotesData: any
  prefill25Flag: boolean = false //Previous notes prefill flag mednote 25
  prefill45Flag: boolean = false //Previous notes prefill flag mednote 45
  prefillEvalFlag: boolean = false //Previous notes prefill flag Eval
  OldNoteData: any = {}

  cancelPopupShowHide: boolean = false
  notePopup: any = {}
  submitNoteData: any = {}
  notePath: any = {}
  

  constructor(
    private formService: CustomFormHandlerService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpService,
    private observable: ObservableHelperService,
    private httpClient: HttpClient,
    private cd: ChangeDetectorRef
  ) {
    route.queryParams.subscribe(res => {
      this.patientId = res.patienID
      this.MednoteData = res

    })
  }

  ngOnInit() {
    document.querySelector('html').style.overflow = 'hidden'
    //today's Date
    let currentDate = new Date()
    let day = currentDate.getDate()
    let month = currentDate.getMonth() + 1
    let year = currentDate.getFullYear()
    let formattedDay = day < 10 ? `0${day}` : `${day}`
    let formattedMonth = month < 10 ? `0${month}` : `${month}`
    this.formattedCurrentDate = `${formattedMonth}/${formattedDay}/${year}`
    const payload: IGetMethodPayload = {
      type: HTTPMethods.GET,
      url: API_URLS.INFORMATION_REQUEST_DYNAMIC_FORM,
      isDeveloper: true,
    }
    this.http.makeHttpRequest(payload).subscribe(res => {
      if (this.http.isSuccessfulResponse(res)) {
        this.infnData_emailTemplates = res.data.map(ele => ({
          questionId: ele.questionId,
          question: ele.questionLabel,
          answer: '',
        }))
      }
    })
    this.fetchDataAndProcessData()
    this.getProvidersAndPharmacyDetails()
  }

  async fetchDataAndProcessData() {
    const payloadData = {
      type: HTTPMethods.POST,
      url: API_URLS.GET_EMAIL_TEMPLATE_LIST,
      isDeveloper: true,
      body: this.INITIAL_PAYLOAD,
    }
    try {
      const res = await this.http.makeHttpRequest(payloadData).toPromise()
      if (this.http.isSuccessfulResponse(res)) {
        for (const ele of res.data.list) {
          if (ele.emailName === 'Doctor Notes') {
            const parser = new DOMParser()
            const doc = parser.parseFromString(ele.htmlTemplate, 'text/html')
            const divElements = doc.querySelectorAll('div.editable')
            let Notes = []
            divElements.forEach((divElement, index) => {
              let content = divElement.textContent
              Notes.push(content)
            })
            let elementToRemove = " Dear Dr. Prayaga's Office"
            this.doctorNotes = Notes.filter(item => item !== elementToRemove)
          }
        }
        this.getPatientDetails()
      }
    } catch (error) {
      console.error('Error fetching data ', error)
    }
  }

  getPatientDetails() {
    let patientID = this.MednoteData.patienID
    let appointmentId = this.MednoteData.AppointmentId
    let userId = this.MednoteData.userId
    const payloadDataOne = {
      type: HTTPMethods.GET,
      url: API_URLS.GET_PATIENT_MEDNOTES,
      isDeveloper: true,
      pathVariables: [patientID, appointmentId, userId],
    }
    this.http.makeHttpRequest(payloadDataOne).subscribe(res => {
      if (this.http.isSuccessfulResponse(res)) {
        this.documentDetails = this.massageDocumentUrls(res.data.signedEmailPdf[0])
        this.userdata = res.data.appointments[0].patient // patient's basic information (Intake)
        this.appointMentdata = res.data.appointments[0].appointmentData // appointment booking information for selected appt.
        this.patientMednotes = res.data.appointments[0].medNotes //mednote of the selected appointmnet
        this.previousNotesData = res.data.previousMedNotes // notes of previous appointmnet
        console.log('res', res);
        console.log("this.userdata", this.userdata);
        console.log("this.appointMentdata", this.appointMentdata);
        let hospitalinfo = (this.userdata.medicalHistory.patientHospitalized) 
        ?
          `Hospital Name - ${this.userdata.medicalHistory.nameOfHospital}, Date of hospitalization - ${this.userdata.medicalHistory.patientHospitalizedDate}, Reason for hospitalization - ${this.userdata.medicalHistory.patientHospitalizedReason} `
        :
          'none' ;
          hospitalinfo = ( this.appointMentdata != null && this.appointMentdata.hasOwnProperty("medicationConsent") && this.appointMentdata.medicationConsent != null && this.appointMentdata.medicationConsent.hospitalizedPastMonth) ? hospitalinfo + `Hospital Name - ${this.appointMentdata.medicationConsent.hospitalName}, Reason for hospitalization - ${this.appointMentdata.medicationConsent.hospitalizedReason}` : hospitalinfo + ''
        let text = `Therapist Name: ${this.userdata.medicalHistory.therapistName} Last Therapy Date: ${this.userdata.medicalHistory.lastTherapyDate} \n${this.userdata.medicalHistory.medicalKnownAllergies} \nMedicalDisorders: ${(this.userdata.medicalHistory.anyMedicalDisorders) ? this.userdata.medicalHistory.anyMedicalDisordersExplain : 'none'} \nPast History of (alcohol / marijuana/ cocaine): ${(this.userdata.medicalHistory.pastHistoryofAlchohol) ? this.userdata.medicalHistory.pastHistoryofAlchoholExplain : 'none'} \nNon-Psych Meds: ${(this.userdata.medicalHistory.nonPsychMeds) ? this.userdata.medicalHistory.nonPsychMedsExplain : 'none'} \nOpiods: ${(this.userdata.medicalHistory.anyOpiods) ? this.userdata.medicalHistory.anyOpiodsExplain : 'none'} \nPrevious Hospital Records: ${hospitalinfo} \nLegal Issues: ${(this.userdata.medicalHistory.miscKnownAllergies1) ? this.userdata.medicalHistory.miscKnownAllergies1 : 'none'} `
        let apptdatatext = `Libido: ${( this.appointMentdata != null && this.appointMentdata.healthDetails != null) ? this.appointMentdata.healthDetails.libido:""} \nFeeling Suicidal: ${( this.appointMentdata != null && this.appointMentdata.healthDetails != null) ? this.appointMentdata.healthDetails.feeling_sucidal:""} \nSide Effects: ${(( this.appointMentdata != null && this.appointMentdata.healthDetails != null) && this.appointMentdata.healthDetails.medicines_side_effects) ? this.appointMentdata.healthDetails.medicines_side_effects_description : 'none'} \nAllergies: ${(( this.appointMentdata != null && this.appointMentdata.healthDetails != null) && this.appointMentdata.healthDetails.outburst_allergies) ? this.appointMentdata.healthDetails.outburst_allergies_description : 'none'}`
          
        console.log( text, 'text');
        console.log( apptdatatext, 'apptdatatext');

        //Creating notes
        if (this.MednoteData.Operation === 'CreateMednote') {
          //Extrating Infn question and answers
          if (this.appointMentdata === null) {
            // this if condition is for offline labeled patients
            if (res.data.latestInformationResquestForm.hasOwnProperty('latestInformationResquestForm')) {
              this.infnData = this.infnData_emailTemplates
            } else {
              if (res.data.latestInformationResquestForm.hasOwnProperty('questions')) {
                this.infnData = res.data.latestInformationResquestForm.questions
              }
            }
          } else {
            // this else condition is for online(active labeled patients)
            if (this.appointMentdata && this.appointMentdata.hasOwnProperty('InformationResquestForm')) {
              this.infnData = this.appointMentdata.InformationResquestForm.questions
            } else {
              if (
                res.data.latestInformationResquestForm !== null &&
                res.data.latestInformationResquestForm.hasOwnProperty('questions')
              ) {
                this.infnData = res.data.latestInformationResquestForm.questions
              } else {
                this.infnData = this.infnData_emailTemplates
              }
            }
          }

          // formatting infn questions i.e. seperating question & answers into individual textareas for createMode
          let infnFormFields2 = []
          for (let i = 0; i < this.infnData.length; i++) {
            let question = {
              key: `Question ${i + 1}`,
              label: `Question ${i + 1}`,
              type: 'textarea',
              placeholder: '',
              maxLength: 1000,
              required: true,
              value: this.infnData[i].question,
              controlType: 'textarea',
              emptyValueMessage: 'Please add description',
              validationValue: 'Please add valid description',
              validators: [Validators.required, Validators.minLength(1)],
              isDelete: true,
              className: '',
              editable: true,
              count: '1000 remaining',
              showontoggle: true,
              showRange: true,
              editAccessKey: 'informationRequestQuestion',
            }
            let answer = {
              key: `Answer ${i + 1}`,
              label: `Answer ${i + 1}`,
              type: 'textarea',
              placeholder: '',
              maxLength: 1000,
              required: false,
              value: this.infnData[i].answer,
              controlType: 'textarea',
              emptyValueMessage: 'Please add description',
              validationValue: 'Please add valid description',
              validators: [],
              className: '',
              editable: true,
              count: '1000 remaining',
              showontoggle: true,
              showRange: true,
              editAccessKey: 'informationRequestAnswer',
            }
            infnFormFields2 = [...infnFormFields2, question, answer]
          }

          //intake medications
          let medicationTypes = [
            { key: 'psychiatric', label: 'Psychiatric' },
            { key: 'benzodiazepines', label: 'Benzodiazepines' },
            { key: 'medicalAdderallStimulants', label: 'Medical Adderall Stimulants' },
          ]
          let intakeMeds = []
          medicationTypes.forEach((medType, index) => {
            if (this.userdata.medicalHistory[medType.key].flag === true) {
              this.userdata.medicalHistory[medType.key].medications.map(med => {
                intakeMeds.push({ medicine: `${med.medicine}`, dosage: `${med.dosage}` })
              })
            }
          })
          if (intakeMeds.length === 0) {
            intakeMeds.push({ medicine: ``, dosage: `` })
          }
          //25 mintues form
          if (
            this.MednoteData.Duration === '25 Minutes' &&
            this.MednoteData.noteType === 'Monthly Medication Management'
          ) {
            infnFormFields2 = infnFormFields2.map(obj => ({
              ...obj,
              FormType: 'Mednote25',
            }))
            this.formService.mednoteFollowUpTwentyFiveConfig[7].formInputs = []
            this.formService.mednoteFollowUpTwentyFiveConfig[7].formInputs.push(infnFormFields2)

            if (res.data.previousMedNotes.length !== 0) {
              let templateData = true
              this.previousNotesData.forEach(note => {
                if (note.mednoteType === 'MEDNOTE45' || note.mednoteType === 'MEDNOTE25') {
                  this.notePath = JSON.parse(note.S3)
                  this.prefill25Flag = true
                  templateData = false
                  let flattenedObj = this.flattenObject(JSON.parse(note.medNotes_data))
                  //medication
                  let medFields = this.massageMedicationData(flattenedObj['Plan.Medications'])
                  let medicationIndex = this.formService.mednoteFollowUpTwentyFiveConfig.findIndex(
                    obj => obj['key'] === 'diagnosisMedications2'
                  )
                  this.formService.mednoteFollowUpTwentyFiveConfig[medicationIndex].formInputs = []
                  for (let i = medFields.length - 1; i >= 0; i--) {
                    this.formService.mednoteFollowUpTwentyFiveConfig[medicationIndex].formInputs.unshift(medFields[i])
                  }
                  this.Follow_Up_25Mins_Form_Tags.forEach(element => {
                    this.form.push(this.formService.initForm(this.formService.formTypes[element]))
                  })
                  this.assignEditData(flattenedObj)
                  //evaluation date
                  let index = this.formService.mednoteFollowUpTwentyFiveConfig.findIndex(
                    obj => obj['key'] === 'followUp25_Date'
                  )
                  let evalDate = this.MednoteData.AppointmentDate.split('-')
                  this.formService.mednoteFollowUpTwentyFiveConfig[
                    index
                  ].value = `${evalDate[1]}/${evalDate[2]}/${evalDate[0]}` //MM-DD-YYYY format
                  //chief complain
                  let chiefComplains = {
                    'Adjustment Disorder': 'Adjustment Disorder',
                    'Oppositional Disorder': 'OPPOSITIONAL DISORDER',
                    ADHD: 'ADHD',
                    Anxiety: 'ANXIETY',
                    Depression: 'MAJOR DEPRESSION',
                    Bipolar: 'BI-POLAR II',
                    Schizophrenia: 'Schizoaffective disorder',
                    Autism: 'AUTISM',
                    'Eating Disorder': 'EATING DISORDER',
                    OCD: 'OCD (Obsessive-compulsive disorder)',
                  }
                  for (let key in chiefComplains) {
                    let value = chiefComplains[key]
                    if (chiefComplains.hasOwnProperty(key) && key === this.MednoteData.DiagonsisType) {
                      this.formService.mednoteFollowUpTwentyFiveConfig[5].value = value
                    }
                  }
                  //doctor's note
                  let DNindex = this.formService.mednoteFollowUpTwentyFiveConfig.findIndex(
                    obj => obj['key'] === 'followUp25_DoctorNotes'
                  )
                  this.doctorNotes = this.doctorNotes.filter(item => item !== " Dear Dr. Prayaga's Office,")
                  let notes = this.doctorNotes.join('\n ')
                  this.formService.mednoteFollowUpTwentyFiveConfig[DNindex].value =
                    notes.replace(/[ \t\n]+$/, '') +
                    '\n\n' +
                    this.formService.mednoteFollowUpTwentyFiveConfig[DNindex].value.replace(/[ \t\n]+$/, '')
                  //note code
                  this.formService.mednoteFollowUpTwentyFiveConfig[3].value = this.MednoteData.MednoteCode
                  //duration of visit
                  this.formService.mednoteFollowUpTwentyFiveConfig[2].value = this.durationOfTherapy(this.MednoteData.Duration)
                  //axis-I
                  let axisI_KeyValues = {
                    'Adjustment Disorder': 'AdjustmentDisorder25_axisI',
                    'Oppositional Disorder': 'OppositionalDisorder25_axisI',
                    ADHD: 'ADHD25_axisI',
                    Anxiety: 'Anxiety25_axisI',
                    Depression: 'Depression25_axisI',
                    Bipolar: 'Bipolar25_axisI',
                    Schizophrenia: 'Schizophrenia25_axisI',
                    Autism: 'Autism25_axisI',
                    'Eating Disorder': 'EatingDisorder25_axisI',
                    OCD: 'OcdDisorder25_axisI',
                  }
                  let axis_I_Secondary = [
                    'AdjustmentDisorder25_axisI_SD',
                    'Schizophrenia25_axisI_SD',
                    'OppositionalDisorder25_axisI_SD',
                    'ADHD25_axisI_SD',
                    'Depression25_axisI_SD',
                    'Bipolar25_axisI_SD',
                    'Anxiety25_axisI_SD',
                    'Autism25_axisI_SD',
                    'EatingDisorder25_axisI_SD',
                  ]
                  this.formService.mednoteFollowUpTwentyFiveConfig.forEach(element => {
                    if (element.key === 'Diagnosis') {
                      element.editable = false
                      element.value = this.MednoteData.DiagonsisType
                    }
                    for (let key in axisI_KeyValues) {
                      let value = axisI_KeyValues[key]
                      if (axisI_KeyValues.hasOwnProperty(key) && key === this.MednoteData.DiagonsisType) {
                        if (element.key === value) {
                          element.value = true
                        }
                      } else {
                        if (element.key === value) {
                          element.value = false
                        }
                      }
                    }
                    for (let key in axis_I_Secondary) {
                      let value = axis_I_Secondary[key]
                      if (element.key === value) {
                        element.value = false
                      }
                    }
                    if(note.create_time < this.userdata.updateTime){                      
                      if (element.key === 'followUp25_HospitalInfo' ) {
                          let hospitalinfo = (this.userdata.medicalHistory.nameOfHospital) 
                        ?
                          `Hospital Name - ${this.userdata.medicalHistory.nameOfHospital}, Date of hospitalization - ${this.userdata.medicalHistory.patientHospitalizedDate}, Reason for hospitalization - ${this.userdata.medicalHistory.patientHospitalizedReason} `
                        :
                          '' ;

                        element.value = (hospitalinfo) ? hospitalinfo : 'none'
                      }
                      if(element.key === 'followUp25_therapyDetails'){
                        if(this.userdata.medicalHistory.therapistName) {
                          let tDate = (this.userdata.medicalHistory.lastTherapyDate) ? `, Last Therapy Date: ${formatDate((this.userdata.medicalHistory.lastTherapyDate), 'MM-dd-yyy', 'en')}`: ', Last Therapy Date: none'
                            element.value = `Therapist Name: ${this.userdata.medicalHistory.therapistName}` + tDate
                          } else {
                            element.value = `none`
                          }
                      }
                      if(element.key === 'followUp25_pertinentInfo'){
                        element.value = (this.userdata.medicalHistory.medicalKnownAllergies) ? this.userdata.medicalHistory.medicalKnownAllergies : 'none'
                      }
                      if(element.key === 'followUp25_medicalDisorder'){
                        element.value = (this.userdata.medicalHistory.anyMedicalDisordersExplain) ? this.userdata.medicalHistory.anyMedicalDisordersExplain : 'none'
                      }
                      if(element.key === 'followUp25_nonPsychMeds'){
                        element.value = (this.userdata.medicalHistory.nonPsychMedsExplain) ? this.userdata.medicalHistory.nonPsychMedsExplain : 'none'
                      }
                      if(element.key === 'followUp25_pastHistory'){
                        element.value = (this.userdata.medicalHistory.pastHistoryofAlchoholExplain) ? this.userdata.medicalHistory.pastHistoryofAlchoholExplain : 'none'
                      }
                      if(element.key === 'followUp25_legalIssue'){
                        let legalText = (this.userdata.patientMiscInfo.miscKnownAllergies1) ? this.userdata.patientMiscInfo.miscKnownAllergies1 : ''
                        // legalText = (this.appointMentdata.healthDetails.legal_issue_description) ? legalText + ` ${this.appointMentdata.healthDetails.legal_issue_description}` : legalText + ' none'
                        element.value = (legalText) ? legalText : 'none'
                      }
                      
                    }
                    if(element.key === 'followUp25_sucidal'){
                      element.value = (this.appointMentdata) ? this.appointMentdata.healthDetails.feeling_sucidal : 'none'
                    }
                    if(element.key === 'followUp25_paranoid'){
                      element.value =  (this.appointMentdata) ? this.appointMentdata.healthDetails.feeling_paranoid: 'none'
                    }
                    if(element.key === 'followUp25_libibo'){
                      element.value =  (this.appointMentdata) ? this.appointMentdata.healthDetails.libido :'none'
                    }
                    if(element.key === 'followUp25_Insomina'){
                      element.value =  (this.appointMentdata) ? this.appointMentdata.healthDetails.insomnia : 'none'
                    }
                  })
                }
              })
              if (templateData) {
                let medFields = this.massageMedicationData(intakeMeds)
                let medicationIndex = this.formService.mednoteFollowUpTwentyFiveConfig.findIndex(
                  obj => obj['key'] === 'diagnosisMedications2'
                )
                this.formService.mednoteFollowUpTwentyFiveConfig[medicationIndex].formInputs = []
                for (let i = medFields.length - 1; i >= 0; i--) {
                  this.formService.mednoteFollowUpTwentyFiveConfig[medicationIndex].formInputs.unshift(medFields[i])
                }
                this.Follow_Up_25Mins_Form_Tags.forEach(element => {
                  this.form.push(this.formService.initForm(this.formService.formTypes[element]))
                })
                this.assignData()
              } // if previous appointment notes dosen't have  notes then the form will prefill from particular diagnosis template
            } else {
              let medFields = this.massageMedicationData(intakeMeds)
              let medicationIndex = this.formService.mednoteFollowUpTwentyFiveConfig.findIndex(
                obj => obj['key'] === 'diagnosisMedications2'
              )
              this.formService.mednoteFollowUpTwentyFiveConfig[medicationIndex].formInputs = []
              for (let i = medFields.length - 1; i >= 0; i--) {
                this.formService.mednoteFollowUpTwentyFiveConfig[medicationIndex].formInputs.unshift(medFields[i])
              }
              this.Follow_Up_25Mins_Form_Tags.forEach(element => {
                this.form.push(this.formService.initForm(this.formService.formTypes[element]))
              })
              this.assignData()
            }
            let insuranceDropDownPosition = this.formService.mednoteFollowUpTwentyFiveConfig.length - 1
            this.formService.mednoteFollowUpTwentyFiveConfig[insuranceDropDownPosition].list =
              this.providers.primaryInsurance
          }
          //45 mintues form
          if (
            this.MednoteData.Duration === '45 Minutes' &&
            this.MednoteData.noteType === 'Monthly Medication Management'
          ) {
            infnFormFields2 = infnFormFields2.map(obj => ({
              ...obj,
              FormType: 'Mednote45',
            }))
            this.formService.mednoteFollowUpFortyFiveConfig[7].formInputs = []
            this.formService.mednoteFollowUpFortyFiveConfig[7].formInputs.push(infnFormFields2)

            if (res.data.previousMedNotes.length !== 0) {
              let templateData = true
              this.previousNotesData.forEach(note => {
                if (note.mednoteType === 'MEDNOTE45' || note.mednoteType === 'MEDNOTE25') {
                  this.notePath = JSON.parse(note.S3)
                  this.prefill45Flag = true
                  templateData = false
                  let flattenedObj = this.flattenObject(JSON.parse(note.medNotes_data))
                  //medication
                  let medFields = this.massageMedicationData(flattenedObj['Plan.Medications'])
                  let medicationIndex = this.formService.mednoteFollowUpFortyFiveConfig.findIndex(
                    obj => obj['key'] === 'diagnosisMedications2'
                  )
                  this.formService.mednoteFollowUpFortyFiveConfig[medicationIndex].formInputs = []
                  for (let i = medFields.length - 1; i >= 0; i--) {
                    this.formService.mednoteFollowUpFortyFiveConfig[medicationIndex].formInputs.unshift(medFields[i])
                  }
                  this.Follow_Up_45Mins_Form_Tags.forEach(element => {
                    this.form.push(this.formService.initForm(this.formService.formTypes[element]))
                  })
                  this.assignEditData(flattenedObj)
                  //evaluation date
                  let index = this.formService.mednoteFollowUpFortyFiveConfig.findIndex(
                    obj => obj['key'] === 'followUp45_Date'
                  )
                  let evalDate = this.MednoteData.AppointmentDate.split('-')
                  this.formService.mednoteFollowUpFortyFiveConfig[
                    index
                  ].value = `${evalDate[1]}/${evalDate[2]}/${evalDate[0]}` //MM-DD-YYYY format
                  //chief complain
                  let chiefComplains = {
                    'Adjustment Disorder': 'ADJUSTMENT DISORDER',
                    'Oppositional Disorder': 'OPPOSITIONAL DISORDER',
                    ADHD: 'ADHD',
                    Anxiety: 'Anxiety Disorder',
                    Depression: 'MAJOR DEPRESSION',
                    Bipolar: 'BI-POLAR II',
                    Schizophrenia: 'SCHIZOPHRENIFORM DISORDER',
                    Autism: 'AUTISM',
                    'Eating disorder': 'EATING DISORDER',
                    OCD: 'OCD (Obsessive-compulsive disorder)',
                  }
                  for (let key in chiefComplains) {
                    let value = chiefComplains[key]
                    if (chiefComplains.hasOwnProperty(key) && key === this.MednoteData.DiagonsisType) {
                      this.formService.mednoteFollowUpFortyFiveConfig[5].value = value
                    }
                  }
                  //doctor's note
                  let DNindex = this.formService.mednoteFollowUpFortyFiveConfig.findIndex(
                    obj => obj['key'] === 'followUp45_DoctorNote'
                  )
                  this.doctorNotes = this.doctorNotes.filter(item => item !== " Dear Dr. Prayaga's Office,")
                  let notes = this.doctorNotes.join('\n ')
                  this.formService.mednoteFollowUpFortyFiveConfig[DNindex].value =
                    notes.replace(/[ \t\n]+$/, '') +
                    '\n\n' +
                    this.formService.mednoteFollowUpFortyFiveConfig[DNindex].value.replace(/[ \t\n]+$/, '')
                  //note code
                  this.formService.mednoteFollowUpFortyFiveConfig[3].value = this.MednoteData.MednoteCode
                  //duration of visit
                  this.formService.mednoteFollowUpFortyFiveConfig[2].value =this.durationOfTherapy(this.MednoteData.Duration)
                  //axis-i
                  let axisI_KeyValues = {
                    'Adjustment Disorder': 'AdjustmentDisorder45_axisI',
                    'Oppositional Disorder': 'OppositionalDisorder45_axisI',
                    ADHD: 'ADHD45_axisI',
                    Anxiety: 'Anxiety45_axisI',
                    Depression: 'Depression45_axisI',
                    Bipolar: 'Bipolar45_axisI',
                    Schizophrenia: 'Schizophrenia45_axisI',
                    Autism: 'Autism45_axisI',
                    'Eating Disorder': 'EatingDisorder45_axisI',
                    OCD: 'Ocd45_axisI',
                  }
                  let axis_I_Secondary = [
                    'AdjustmentDisorder45_axisI_SD',
                    'Schizophrenia45_axisI_SD',
                    'OppositionalDisorder45_axisI_SD',
                    'ADHD45_axisI_SD',
                    'Depression45_axisI_SD',
                    'Bipolar45_axisI_SD',
                    'Anxiety45_axisI_SD',
                    'Autism45_axisI_SD',
                    'EatingDisorder45_axisI_SD',
                    'Ocd45_axisI_SD',
                  ]
                  this.formService.mednoteFollowUpFortyFiveConfig.forEach(element => {
                    if (element.key === 'Diagnosis') {
                      element.editable = false
                      element.value = this.MednoteData.DiagonsisType
                    }
                    for (let key in axisI_KeyValues) {
                      let value = axisI_KeyValues[key]
                      if (axisI_KeyValues.hasOwnProperty(key) && key === this.MednoteData.DiagonsisType) {
                        if (element.key === value) {
                          element.value = true
                        }
                      } else {
                        if (element.key === value) {
                          element.value = false
                        }
                      }
                    }
                    for (let key in axis_I_Secondary) {
                      let value = axis_I_Secondary[key]
                      if (element.key === value) {
                        element.value = false
                      }
                    }
                    if (note.mednoteType === 'MEDNOTE25' && element.key === 'followUp45_typeOfVisit') {
                      element.value =
                        'CPT 99214: Established outpatient, office visit, moderate complexity, Detailed history, and physical exam.'
                    }
                    if (note.mednoteType === 'MEDNOTE25' && element.key === 'followUp45_potentialSideEffect') {
                      element.value =
                        'The patient has been informed of the potential side effects the medication can give, and instructed to contact us immediately if conditions get worse.\nPatient was instructed to contact the office or myself if any problems should arise.'
                    }
                    if(note.create_time < this.userdata.updateTime){
                      console.log("inside____note.create_time < this.userdata.updateTime", note.create_time < this.userdata.updateTime);
                      
                      if (element.key === 'followUp45_HospitalInfo' ) {
                          let hospitalinfo = (this.userdata.medicalHistory.nameOfHospital) 
                        ?
                          `Hospital Name - ${this.userdata.medicalHistory.nameOfHospital}, Date of hospitalization - ${this.userdata.medicalHistory.patientHospitalizedDate}, Reason for hospitalization - ${this.userdata.medicalHistory.patientHospitalizedReason} `
                        :
                          '' ;
                   

                        element.value = (hospitalinfo) ? hospitalinfo : 'none'
                      }
                      if(element.key === 'followUp45_therapyDetails'){
                        if(this.userdata.medicalHistory.therapistName) {
                        let tDate = (this.userdata.medicalHistory.lastTherapyDate) ? `, Last Therapy Date: ${formatDate((this.userdata.medicalHistory.lastTherapyDate), 'MM-dd-yyy', 'en')}`: ', Last Therapy Date: none'
                        element.value = `Therapist Name: ${this.userdata.medicalHistory.therapistName}` + tDate
                      } else {
                        element.value = `none`
                      }
                      }
                      if(element.key === 'followUp45_pertinentInfo'){
                        element.value = (this.userdata.medicalHistory.medicalKnownAllergies) ? this.userdata.medicalHistory.medicalKnownAllergies : 'none'
                      }
                      if(element.key === 'followUp45_medicalDisorder'){
                        element.value = (this.userdata.medicalHistory.anyMedicalDisordersExplain) ? this.userdata.medicalHistory.anyMedicalDisordersExplain : 'none'
                      }
                      if(element.key === 'followUp45_nonPsychMeds'){
                        element.value = (this.userdata.medicalHistory.nonPsychMedsExplain) ? this.userdata.medicalHistory.nonPsychMedsExplain : 'none'
                      }
                      if(element.key === 'followUp45_pastHistory'){
                        element.value = (this.userdata.medicalHistory.pastHistoryofAlchoholExplain) ? this.userdata.medicalHistory.pastHistoryofAlchoholExplain : 'none'
                      }
                      if(element.key === 'followUp45_legalIssue'){
                        let legalText = (this.userdata.patientMiscInfo.miscKnownAllergies1) ? this.userdata.patientMiscInfo.miscKnownAllergies1 : ''
                        // legalText = (this.appointMentdata.healthDetails.legal_issue_description) ? legalText + ` ${this.appointMentdata.healthDetails.legal_issue_description}` : legalText + ' none'
                        element.value = (legalText) ? legalText : 'none'
                      }
                      
                    }
                    if(element.key === 'followUp45_sucidal'){
                      element.value =  (this.appointMentdata) ? this.appointMentdata.healthDetails.feeling_sucidal: 'none'
                    }
                    if(element.key === 'followUp45_paranoid'){
                      element.value =  (this.appointMentdata) ? this.appointMentdata.healthDetails.feeling_paranoid : 'none'
                    }
                    if(element.key === 'followUp45_libibo'){
                      element.value =  (this.appointMentdata) ? this.appointMentdata.healthDetails.libido : 'none'
                    }
                    if(element.key === 'followUp45_Insomina'){
                      element.value =  (this.appointMentdata) ? this.appointMentdata.healthDetails.insomnia : 'none'
                    }
                  })
                }
              })
              if (templateData) {
                let medFields = this.massageMedicationData(intakeMeds)
                let medicationIndex = this.formService.mednoteFollowUpFortyFiveConfig.findIndex(
                  obj => obj['key'] === 'diagnosisMedications2'
                )
                this.formService.mednoteFollowUpFortyFiveConfig[medicationIndex].formInputs = []
                for (let i = medFields.length - 1; i >= 0; i--) {
                  this.formService.mednoteFollowUpFortyFiveConfig[medicationIndex].formInputs.unshift(medFields[i])
                }
                this.Follow_Up_45Mins_Form_Tags.forEach(element => {
                  this.form.push(this.formService.initForm(this.formService.formTypes[element]))
                })
                this.assignData()
              } // if previous appointment notes dosen't have  notes then the form will prefill from particular diagnosis template
            } else {
              let medFields = this.massageMedicationData(intakeMeds)
              let medicationIndex = this.formService.mednoteFollowUpFortyFiveConfig.findIndex(
                obj => obj['key'] === 'diagnosisMedications2'
              )
              this.formService.mednoteFollowUpFortyFiveConfig[medicationIndex].formInputs = []
              for (let i = medFields.length - 1; i >= 0; i--) {
                this.formService.mednoteFollowUpFortyFiveConfig[medicationIndex].formInputs.unshift(medFields[i])
              }
              this.Follow_Up_45Mins_Form_Tags.forEach(element => {
                this.form.push(this.formService.initForm(this.formService.formTypes[element]))
              })
              this.assignData()
            }
            let insuranceDropDownPosition = this.formService.mednoteFollowUpFortyFiveConfig.length - 1
            this.formService.mednoteFollowUpFortyFiveConfig[insuranceDropDownPosition].list =
              this.providers.primaryInsurance
          }
          //evaluation form
          if (this.MednoteData.noteType === 'Evaluation') {
            //employer
            if (this.userdata.patientBasicInfo.age <= 18) {
              let employerIndex = this.formService.mednotesEvalConfig.findIndex(obj => obj['key'] === 'employer')
              // index 10 in mednotesEvalConfig array of object is the index of employer form field
              if (employerIndex > 0) {
                this.formService.mednotesEvalConfig.splice(employerIndex, 1)
              }
            } else {
              this.formService.mednotesEvalConfig.forEach(ele => {
                if (ele.key === 'employer') {
                  this.employerFlag = true
                }
              })
              if (!this.employerFlag) {
                let employerField = {
                  type: 'text',
                  placeholder: 'Please enter employer details',
                  label: 'Employer',
                  required: true,
                  key: 'employer',
                  labelImage: '',
                  value: '',
                  controlType: 'inputText',
                  emptyValueMessage: 'Please enter employer details',
                  validators: [],
                  focus: true,
                  editable: true,
                  showontoggle: true,
                  editAccessKey: 'PatientInfo.Employer',
                }
                let codeIndex = this.formService.mednotesEvalConfig.findIndex(obj => obj['key'] === 'mednote_Code')
                this.formService.mednotesEvalConfig.splice(codeIndex + 1, 0, employerField)
              }
            }
            //sibling
            if (this.MednoteData.EvalType == 'annual') {
              let siblingIndex = this.formService.mednotesEvalConfig.findIndex(obj => obj['key'] === 'sibling')
              if (siblingIndex > 0) {
                this.formService.mednotesEvalConfig.splice(siblingIndex, 1)
              }
            } else {
              this.formService.mednotesEvalConfig.forEach(ele => {
                if (ele.key === 'sibling') {
                  this.siblingFlag = true
                }
              })
              if (!this.siblingFlag) {
                let siblingField = {
                  type: 'text',
                  placeholder: 'Please enter sibling details',
                  label: 'Sibling',
                  required: true,
                  key: 'sibling',
                  labelImage: '',
                  value: '',
                  controlType: 'inputText',
                  emptyValueMessage: 'Please enter sibling details',
                  validators: [],
                  focus: true,
                  editable: true,
                  showontoggle: true,
                  editAccessKey: 'sibling',
                }
                let educationIndex = this.formService.mednotesEvalConfig.findIndex(obj => obj['key'] === 'education')
                this.formService.mednotesEvalConfig.splice(educationIndex + 1, 0, siblingField)
              }
            }
            //infn
            this.formService.mednotesEvalConfig.forEach(element => {
              if (element.key === 'informationRequestQuestions') {
                infnFormFields2 = infnFormFields2.map(obj => ({
                  ...obj,
                  FormType: 'Eval',
                }))
                element.formInputs = []
                element.formInputs.push(infnFormFields2)
              }
            })

            if (res.data.previousMedNotes.length !== 0) {
              let templateData = true
              this.previousNotesData.forEach(note => {
                if (note.mednoteType === 'EVALUATION') {
                  this.notePath = JSON.parse(note.S3)
                  this.prefillEvalFlag = true
                  templateData = false
                  let flattenedObj = this.flattenObject(JSON.parse(note.medNotes_data))
                  //medication
                  let medFields = this.massageMedicationData(flattenedObj['Medications'])
                  let medicationIndex = this.formService.mednotesEvalConfig.findIndex(
                    obj => obj['key'] === 'diagnosisMedications2'
                  )
                  this.formService.mednotesEvalConfig[medicationIndex].formInputs = []
                  for (let i = medFields.length - 1; i >= 0; i--) {
                    this.formService.mednotesEvalConfig[medicationIndex].formInputs.unshift(medFields[i])
                  }
                  this.Eval_Form_Tags.forEach(element => {
                    this.form.push(this.formService.initForm(this.formService.formTypes[element]))
                  })
                  this.assignEditData(flattenedObj)
                  //evaluation date
                  let evalDate = this.MednoteData.AppointmentDate.split('-')
                  this.formService.mednotesEvalConfig[0].value = `${evalDate[1]}/${evalDate[2]}/${evalDate[0]}` //MM-DD-YYYY format
                  //doctor's note
                  let DNindex = this.formService.mednotesEvalConfig.findIndex(obj => obj['key'] === 'DoctorNote')
                  this.doctorNotes = this.doctorNotes.filter(item => item !== " Dear Dr. Prayaga's Office,")
                  let notes = this.doctorNotes.join('\n ')
                  this.formService.mednotesEvalConfig[DNindex].value =
                    notes.replace(/[ \t\n]+$/, '') +
                    '\n\n' +
                    this.formService.mednotesEvalConfig[DNindex].value.replace(/[ \t\n]+$/, '')
                  //note code
                  let codeIndex = this.formService.mednotesEvalConfig.findIndex(obj => obj['key'] === 'mednote_Code')
                  this.formService.mednotesEvalConfig[codeIndex].value =
                    this.userdata.patientBasicInfo.age <= 18
                      ? this.MednoteData.MednoteCode + '( ' + this.MednoteData.DiagonsisType + ' Under 18 )'
                      : this.MednoteData.MednoteCode + ' (' + this.MednoteData.DiagonsisType + ')'
                  //axis-i
                  let axisI_KeyValues = {
                    'Adjustment Disorder': 'AdjustmentDisorder_axisI',
                    'Oppositional Disorder': 'OppositionalDisorder_axisI',
                    ADHD: 'ADHD_axisI',
                    Anxiety: 'Anxiety_axisI',
                    Depression: 'Depression_axisI',
                    Bipolar: 'Bipolar_axisI',
                    Schizophrenia: 'Schizophrenia_axisI',
                    Autism: 'Autism_axisI',
                    'Eating Disorder': 'EatingDisorder_axisI',
                    OCD: 'OCD_axisI',
                  }
                  let axis_I_Secondary = [
                    'AdjustmentDisorder_axisI_SD',
                    'Schizophrenia_axisI_SD',
                    'OppositionalDisorder_axisI_SD',
                    'ADHD_axisI_SD',
                    'Depression_axisI_SD',
                    'Bipolar_axisI_SD',
                    'Autism_axisI_SD',
                    'Anxiety_axisI_SD',
                    'EatingDisorder_axisI_SD',
                    'OCD_axisI_SD',
                  ]
                  this.formService.mednotesEvalConfig.forEach(element => {
                    for (let key in axisI_KeyValues) {
                      let value = axisI_KeyValues[key]
                      if (axisI_KeyValues.hasOwnProperty(key) && key === this.MednoteData.DiagonsisType) {
                        if (element.key === value) {
                          element.value = true
                        }
                      } else {
                        if (element.key === value) {
                          element.value = false
                        }
                      }
                    }
                    for (let key in axis_I_Secondary) {
                      let value = axis_I_Secondary[key]
                      if (element.key === value) {
                        element.value = false
                      }
                    }
                    //ReasonsForVisit
                    if (element.key === 'medicationReview') {
                      element.value = false
                    }
                    if (element.key === 'diagnosticAssessment') {
                      element.value = false
                    }
                    if (element.key === 'chartReview') {
                      element.value = false
                    }
                    if (element.key === 'hospitalRecordReview') {
                      element.value = false
                    }
                    if (element.key === 'labReview') {
                      element.value = false
                    }
                    if (element.key == 'annualPsychiatricEvaluation') {
                      element.value = this.MednoteData.EvalType == 're' ? false : true
                    }
                    if (element.key == 'psychiatricEvaluation') {
                      //intial psychiatric Evaluation
                      element.value = this.MednoteData.EvalType == 're' ? true : false
                    }
                    if (element.key === 'familyInterview') {
                      element.value = this.userdata.patientBasicInfo.age <= 18 ? true : false
                    }
                    if (element.key === 'Diagnosis') {
                      element.editable = false
                      element.value = this.MednoteData.DiagonsisType
                    }
                  })
                }
              })
              if (templateData) {
                // if previous appointment notes dosen't have eval notes then the evaluation form will prefill from particular diagnosis template
                let medFields = this.massageMedicationData(intakeMeds)
                let medicationIndex = this.formService.mednotesEvalConfig.findIndex(
                  obj => obj['key'] === 'diagnosisMedications2'
                )
                this.formService.mednotesEvalConfig[medicationIndex].formInputs = []
                for (let i = medFields.length - 1; i >= 0; i--) {
                  this.formService.mednotesEvalConfig[medicationIndex].formInputs.unshift(medFields[i])
                }
                this.Eval_Form_Tags.forEach(element => {
                  this.form.push(this.formService.initForm(this.formService.formTypes[element]))
                })
                this.assignData()
              }
            } else {
              let medFields = this.massageMedicationData(intakeMeds)
              let medicationIndex = this.formService.mednotesEvalConfig.findIndex(
                obj => obj['key'] === 'diagnosisMedications2'
              )
              this.formService.mednotesEvalConfig[medicationIndex].formInputs = []
              for (let i = medFields.length - 1; i >= 0; i--) {
                this.formService.mednotesEvalConfig[medicationIndex].formInputs.unshift(medFields[i])
              }
              this.Eval_Form_Tags.forEach(element => {
                this.form.push(this.formService.initForm(this.formService.formTypes[element]))
              })
              this.assignData()
            }
            let insuranceDropDownPosition = this.formService.mednotesEvalConfig.length - 1
            this.formService.mednotesEvalConfig[insuranceDropDownPosition].list = this.providers.primaryInsurance
          }
        }
        //Editing notes
        if (this.MednoteData.Operation === 'EditMednote') {
          let NoteData
          this.patientMednotes.forEach(ele => {
            if (ele.medNotesId == this.MednoteData.MednoteId) {
              NoteData = ele.medNotesData
              this.notePath = JSON.parse(ele.S3)
            }
          })
          let editMednoteData = JSON.parse(NoteData)
          let flattenedObj = this.flattenObject(editMednoteData)
          if (this.previousNotesData.length !== 0) {
            this.previousNotesData.forEach(note => {
              if (
                (flattenedObj['mednoteType'] === 'MEDNOTE25' || flattenedObj['mednoteType'] === 'MEDNOTE45') &&
                (note.mednoteType === 'MEDNOTE45' || note.mednoteType === 'MEDNOTE25')
              ) {
                this.OldNoteData = this.flattenObject(JSON.parse(note.medNotes_data))
              } else if (flattenedObj['mednoteType'] === 'EVALUATION' && note.mednoteType === 'EVALUATION') {
                this.OldNoteData = this.flattenObject(JSON.parse(note.medNotes_data))
              }
            })
          } else {
            this.OldNoteData = {}
          }
          let medFields =
            this.MednoteData.noteType === 'Monthly Medication Management'
              ? this.massageMedicationData(flattenedObj['Plan.Medications'])
              : this.massageMedicationData(flattenedObj['Medications']) //medications

          // formatting infn questions i.e. seperating question & answers into individual textareas for edit flow
          let infnFormFields2 = []
          let infnEditData = []

          if (flattenedObj.hasOwnProperty(`ChiefComplaintDescription.InformationResquestForm.questions`) === true) {
            infnEditData = flattenedObj['ChiefComplaintDescription.InformationResquestForm.questions']
            for (let i = 0; i < infnEditData.length; i++) {
              let question = {
                key: `Question ${i + 1}`,
                label: `Question ${i + 1}`,
                type: 'textarea',
                placeholder: '',
                maxLength: 1000,
                required: true,
                value: infnEditData[i].question,
                controlType: 'textarea',
                emptyValueMessage: 'Please add description',
                validationValue: 'Please add valid description',
                validators: [Validators.required, Validators.minLength(1)],
                isDelete: true,
                className: '',
                editable: true,
                count: '1000 remaining',
                showontoggle: true,
                showRange: true,
                editAccessKey: 'informationRequestQuestion',
              }
              let answer = {
                key: `Answer ${i + 1}`,
                label: `Answer ${i + 1}`,
                type: 'textarea',
                placeholder: '',
                maxLength: 1000,
                required: false,
                value: infnEditData[i].answer,
                controlType: 'textarea',
                emptyValueMessage: 'Please add description',
                validationValue: 'Please add valid description',
                validators: [],
                className: '',
                editable: true,
                count: '1000 remaining',
                showontoggle: true,
                showRange: true,
                editAccessKey: 'informationRequestAnswer',
              }
              infnFormFields2 = [...infnFormFields2, question, answer]
            }
          }

          if (flattenedObj['mednoteType'] === 'MEDNOTE25') {
            this.formService.mednoteFollowUpTwentyFiveConfig[7].formInputs = []
            if (infnFormFields2.length !== 0) {
              infnFormFields2 = infnFormFields2.map(obj => ({
                ...obj,
                FormType: 'Mednote25',
              }))
              this.formService.mednoteFollowUpTwentyFiveConfig[7].formInputs.push(infnFormFields2)
            } else {
              infnFormFields2 = [
                {
                  type: 'subheader',
                  label: 'No INFN questions found',
                  showontoggle: true,
                  editable: false,
                  className: 'unavaliablity_message',
                },
              ]
              this.formService.mednoteFollowUpTwentyFiveConfig[7].formInputs.push(infnFormFields2)
            }
            //medications
            let medicationIndex = this.formService.mednoteFollowUpTwentyFiveConfig.findIndex(
              obj => obj['key'] === 'diagnosisMedications2'
            )
            this.formService.mednoteFollowUpTwentyFiveConfig[medicationIndex].formInputs = []
            for (let i = medFields.length - 1; i >= 0; i--) {
              this.formService.mednoteFollowUpTwentyFiveConfig[medicationIndex].formInputs.unshift(medFields[i])
            }
            this.Follow_Up_25Mins_Form_Tags.forEach(element => {
              this.form.push(this.formService.initForm(this.formService.formTypes[element]))
            })
          }

          if (flattenedObj['mednoteType'] === 'MEDNOTE45') {
            this.formService.mednoteFollowUpFortyFiveConfig[7].formInputs = []
            if (infnFormFields2.length !== 0) {
              infnFormFields2 = infnFormFields2.map(obj => ({
                ...obj,
                FormType: 'Mednote45',
              }))
              this.formService.mednoteFollowUpFortyFiveConfig[7].formInputs.push(infnFormFields2)
            } else {
              infnFormFields2 = [
                {
                  type: 'subheader',
                  label: 'No INFN questions found',
                  showontoggle: true,
                  editable: false,
                  className: 'unavaliablity_message',
                },
              ]
              this.formService.mednoteFollowUpFortyFiveConfig[7].formInputs.push(infnFormFields2)
            }
            //medications
            let medicationIndex = this.formService.mednoteFollowUpFortyFiveConfig.findIndex(
              obj => obj['key'] === 'diagnosisMedications2'
            )
            this.formService.mednoteFollowUpFortyFiveConfig[medicationIndex].formInputs = []
            for (let i = medFields.length - 1; i >= 0; i--) {
              this.formService.mednoteFollowUpFortyFiveConfig[medicationIndex].formInputs.unshift(medFields[i])
            }
            this.Follow_Up_45Mins_Form_Tags.forEach(element => {
              this.form.push(this.formService.initForm(this.formService.formTypes[element]))
            })
          }

          if (flattenedObj['mednoteType'] === 'EVALUATION') {
            //employer
            if (this.userdata.patientBasicInfo.age <= 18) {
              let employerIndex = this.formService.mednotesEvalConfig.findIndex(obj => obj['key'] === 'employer')
              if (employerIndex > 0) {
                this.formService.mednotesEvalConfig.splice(employerIndex, 1)
              }
            } else {
              this.formService.mednotesEvalConfig.forEach(ele => {
                if (ele.key === 'employer') {
                  this.employerFlag = true
                }
              })
              if (!this.employerFlag) {
                let employerField = {
                  type: 'text',
                  placeholder: 'Please enter employer details',
                  label: 'Employer',
                  required: true,
                  key: 'employer',
                  labelImage: '',
                  value: '',
                  controlType: 'inputText',
                  emptyValueMessage: 'Please enter employer details',
                  validators: [],
                  focus: true,
                  editable: true,
                  showontoggle: true,
                  editAccessKey: 'PatientInfo.Employer',
                }
                let codeIndex = this.formService.mednotesEvalConfig.findIndex(obj => obj['key'] === 'mednote_Code')
                this.formService.mednotesEvalConfig.splice(codeIndex + 1, 0, employerField)
              }
            }
            //sibling
            if (this.MednoteData.EvalType == 'annual') {
              let siblingIndex = this.formService.mednotesEvalConfig.findIndex(obj => obj['key'] === 'sibling')
              if (siblingIndex > 0) {
                this.formService.mednotesEvalConfig.splice(siblingIndex, 1)
              }
            } else {
              this.formService.mednotesEvalConfig.forEach(ele => {
                if (ele.key === 'sibling') {
                  this.siblingFlag = true
                }
              })
              if (!this.siblingFlag) {
                let siblingField = {
                  type: 'text',
                  placeholder: 'Please enter sibling details',
                  label: 'Sibling',
                  required: true,
                  key: 'sibling',
                  labelImage: '',
                  value: '',
                  controlType: 'inputText',
                  emptyValueMessage: 'Please enter sibling details',
                  validators: [],
                  focus: true,
                  editable: true,
                  showontoggle: true,
                  editAccessKey: 'sibling',
                }
                let educationIndex = this.formService.mednotesEvalConfig.findIndex(obj => obj['key'] === 'education')
                this.formService.mednotesEvalConfig.splice(educationIndex + 1, 0, siblingField)
              }
            }
            //infn
            if (infnFormFields2.length !== 0) {
              infnFormFields2 = infnFormFields2.map(obj => ({
                ...obj,
                FormType: 'Eval',
              }))
              let infnindex = this.formService.mednotesEvalConfig.findIndex(
                obj => obj['key'] === 'informationRequestQuestions'
              )
              this.formService.mednotesEvalConfig[infnindex].formInputs = []
              this.formService.mednotesEvalConfig[infnindex].formInputs.push(infnFormFields2)
            } else {
              infnFormFields2 = [
                {
                  type: 'subheader',
                  label: 'No INFN questions found',
                  showontoggle: true,
                  editable: false,
                  className: 'unavaliablity_message',
                },
              ]
              let infnindex = this.formService.mednotesEvalConfig.findIndex(
                obj => obj['key'] === 'informationRequestQuestions'
              )
              this.formService.mednotesEvalConfig[infnindex].formInputs = []
              this.formService.mednotesEvalConfig[infnindex].formInputs.push(infnFormFields2)
            }
            //medications
            let medicationIndex = this.formService.mednotesEvalConfig.findIndex(
              obj => obj['key'] === 'diagnosisMedications2'
            )
            this.formService.mednotesEvalConfig[medicationIndex].formInputs = []
            for (let i = medFields.length - 1; i >= 0; i--) {
              this.formService.mednotesEvalConfig[medicationIndex].formInputs.unshift(medFields[i])
            }
            this.Eval_Form_Tags.forEach(element => {
              this.form.push(this.formService.initForm(this.formService.formTypes[element]))
            })
            let insuranceDropDownPosition = this.formService.mednotesEvalConfig.length - 1
            this.formService.mednotesEvalConfig[insuranceDropDownPosition].list = this.providers.primaryInsurance
          }
          this.assignEditData(flattenedObj)
        }
      } else {
        let message = 'Failed to fetch patient mednotes details. Please try again'
        message = res.error ? res.error.message : message
        this.observable.showToast(TOAST_STATUSES.ERROR, message)
      }
    })
  }

  massageMedicationData(medsData) {
    let medFields = []
    let listOFMedications = medsData
    let singleGroupField = []

    for (let i = 0; i < listOFMedications.length; i++) {
      let icon = {
        type: 'close-icon',
        showontoggle: true,
        showClose: true,
        editable: i === 0 ? false : true,
      }
      let meds = {
        type: 'text',
        placeholder: 'Medicine name',
        label: 'Medicine name',
        required: true,
        key: `medicine${i + 1}`,
        value: listOFMedications[i].medicine,
        controlType: 'inputText',
        editable: true,
        className: 'side__by__side placeholder_right',
        emptyValueMessage: 'Please enter medicine name',
        validationValue: 'Please enter valid medicine name',
        validators: [Validators.required],
        focus: true,
        showontoggle: true,
      }
      let dosage = {
        type: 'text',
        placeholder: 'Dosage and Direction',
        label: 'Dosage and Direction',
        required: true,
        key: `dosage${i + 1}`,
        value: listOFMedications[i].dosage,
        controlType: 'inputText',
        emptyValueMessage: 'Please add dosage and direction',
        validationValue: 'Please add valid dosage and direction',
        validators: [Validators.required],
        className: 'side__by__side placeholder_right',
        editable: true,
        showontoggle: true,
      }
      singleGroupField = [icon, meds, dosage]

      medFields = [...medFields, singleGroupField]
    }

    return medFields
  }

  flattenObject(ob) {
    let result = {}
    for (let key in ob) {
      if (typeof ob[key] === 'object' && ob[key] !== null && !Array.isArray(ob[key])) {
        let temp = this.flattenObject(ob[key])
        for (let key2 in temp) {
          result[key + '.' + key2] = temp[key2]
        }
      } else {
        result[key] = ob[key]
        if (ob[key] === null) {
          result[key] = null
        }
      }
    }
    return result
  }
  // for edit and prefill flow
  assignEditData(data) {
    console.log('prefill edit data', data)
    let regex = /\/([^/]+)\//
    let extract = regex.exec(this.notePath.folderPath)
    let noteInsurance = extract ? extract[1] : null
    console.log('noteInsurance', noteInsurance)

    let height
    if (data.mednoteType === 'MEDNOTE25' || data.mednoteType == 'MEDNOTE45') {
      height = data['Objective.Vitals.Height'] ? data['Objective.Vitals.Height'].split(`'`) : []
    } else {
      height = data['Observations.height'] ? data['Observations.height'].split(`'`) : []
    }
    if ((this.MednoteData.Operation === 'EditMednote' && data.mednoteType === 'MEDNOTE25') || this.prefill25Flag) {
      this.formService.mednoteFollowUpTwentyFiveConfig.forEach((element: any) => {
        if (data.hasOwnProperty(element.editAccessKey)) {
          element.value = data[element.editAccessKey]
        } else {
          console.log("hello222", element.editAccessKey);
          if (element.key === 'followUp25_HospitalInfo' ) {
              let hospitalinfo = (this.userdata.medicalHistory.nameOfHospital) 
            ?
              `Hospital Name - ${this.userdata.medicalHistory.nameOfHospital}, Date of hospitalization - ${this.userdata.medicalHistory.patientHospitalizedDate}, Reason for hospitalization - ${this.userdata.medicalHistory.patientHospitalizedReason} `
            :
              '' ;
            // hospitalinfo = (this.appointMentdata.medicationConsent.hospitalName) 
            // ? 
            //   hospitalinfo + `Hospital Name - ${this.appointMentdata.medicationConsent.hospitalName}, Reason for hospitalization - ${this.appointMentdata.medicationConsent.hospitalizedReason}` 
            // : 
            //   hospitalinfo + ''

            element.value = (hospitalinfo) ? hospitalinfo : 'none'
          }
          if(element.key === 'followUp25_therapyDetails'){
            if(this.userdata.medicalHistory.therapistName) {
              let tDate = (this.userdata.medicalHistory.lastTherapyDate) ? `, Last Therapy Date: ${formatDate((this.userdata.medicalHistory.lastTherapyDate), 'MM-dd-yyy', 'en')}`: ', Last Therapy Date: none'              
              element.value = `Therapist Name: ${this.userdata.medicalHistory.therapistName}` + tDate
            } else {
              element.value = `none`
            }
          }
          if(element.key === 'followUp25_pertinentInfo'){
            element.value = (this.userdata.medicalHistory.medicalKnownAllergies) ? this.userdata.medicalHistory.medicalKnownAllergies : 'none'
          }
          if(element.key === 'followUp25_medicalDisorder'){
            element.value = (this.userdata.medicalHistory.anyMedicalDisordersExplain) ? this.userdata.medicalHistory.anyMedicalDisordersExplain : 'none'
          }
          if(element.key === 'followUp25_nonPsychMeds'){
            element.value = (this.userdata.medicalHistory.nonPsychMedsExplain) ? this.userdata.medicalHistory.nonPsychMedsExplain : 'none'
          }
          if(element.key === 'followUp25_pastHistory'){
            element.value = (this.userdata.medicalHistory.pastHistoryofAlchoholExplain) ? this.userdata.medicalHistory.pastHistoryofAlchoholExplain : 'none'
          }
          if(element.key === 'followUp25_legalIssue'){
            let legalText = (this.userdata.patientMiscInfo.miscKnownAllergies1) ? this.userdata.patientMiscInfo.miscKnownAllergies1 : ''
            // legalText = (this.appointMentdata.healthDetails.legal_issue_description) ? legalText + ` ${this.appointMentdata.healthDetails.legal_issue_description}` : legalText + ' none'
            element.value = legalText
          }
          if(element.key === 'followUp25_sucidal'){
            element.value =  (this.appointMentdata) ? this.appointMentdata.healthDetails.feeling_sucidal : 'none'
          }
          if(element.key === 'followUp25_paranoid'){
            element.value =  (this.appointMentdata) ? this.appointMentdata.healthDetails.feeling_paranoid : 'none'
          }
          if(element.key === 'followUp25_libibo'){
            element.value = (this.appointMentdata) ? this.appointMentdata.healthDetails.libido : 'none'
          }
          if(element.key === 'followUp25_Insomina'){
            element.value =  (this.appointMentdata) ? this.appointMentdata.healthDetails.insomnia : 'none'
          }
        }
        if (element.key === 'Diagnosis') {
          element.value = data[element.editAccessKey] ? data[element.editAccessKey] : this.MednoteData.DiagonsisType
          element.editable = true
        }
        if (element.key === 'primaryInsuranceProvider') {
          if (this.MednoteData.Operation === 'CreateMednote') {
            element.value = parseInt(this.userdata.insuranceDetails['primaryInsuranceProvider'])
          } else if (this.MednoteData.Operation === 'EditMednote') {
            this.providers.primaryInsurance.forEach(insurance => {
              if (insurance.insuranceName === noteInsurance) {
                element.value = parseInt(insurance.insuranceId)
              }
            })
          } else {
            element.value = parseInt(this.userdata.insuranceDetails['primaryInsuranceProvider'])
          }
        }
        if (element.key === 'followUp25_dob') {
          let dob = this.userdata.dob.split('-')
          element.value = `${dob[1]}/${dob[2]}/${dob[0]}` //MM-DD-YYYY format
        }
        if (element.key === 'followUp25_Date') {
          let evalDate = data.evaluationDate.split('-')
          element.value = `${evalDate[1]}/${evalDate[2]}/${evalDate[0]}` //MM-DD-YYYY format
        }
        if(element.key === 'followUp25_DurationOfVisit'){
          element.value = this.durationOfTherapy(data.DurationOfVisit)
        }
    
        if (element.key === 'followUp25_heightFt') {
          element.value = height.length !== 0 ? height[0] : ''
        }
        if (element.key === 'followUp25_heightInch') {
          element.value = height.length !== 0 ? height[1] : ''
        }

        // if( element.key === 'followUp25_Hallucinations'){ element.value = data['MentalStatusExam.Thought Content.Hallucinations'] ? true : false }
        // if( element.key === 'followUp25_Date'){ element.value = this.formattedCurrentDate}
        if (element.key === 'followUp25_durationMedication') {
          element.value = data['Plan.Duration']
        }
        if (element.key === 'followUp25_medication') {
          element.value = data['Plan.Medications'][0]
        }
        if (element.key === 'followUp25_advice') {
          element.value = data['OutcomeAndAdvices'][0]
        }
      })
    } else if (
      (this.MednoteData.Operation === 'EditMednote' && data.mednoteType === 'MEDNOTE45') ||
      this.prefill45Flag
    ) {
      this.formService.mednoteFollowUpFortyFiveConfig.forEach((element: any) => {
        if (data.hasOwnProperty(element.editAccessKey)) {
          element.value = data[element.editAccessKey]
        } else {
          console.log("hello222 45", element.editAccessKey);
          if (element.key === 'followUp45_HospitalInfo' ) {
              let hospitalinfo = (this.userdata.medicalHistory.nameOfHospital) 
            ?
              `Hospital Name - ${this.userdata.medicalHistory.nameOfHospital}, Date of hospitalization - ${this.userdata.medicalHistory.patientHospitalizedDate}, Reason for hospitalization - ${this.userdata.medicalHistory.patientHospitalizedReason} `
            :
              '' ;
            // hospitalinfo = (this.appointMentdata.medicationConsent.hospitalName) 
            // ? 
            //   hospitalinfo + `Hospital Name - ${this.appointMentdata.medicationConsent.hospitalName}, Reason for hospitalization - ${this.appointMentdata.medicationConsent.hospitalizedReason}` 
            // : 
            //   hospitalinfo + ''

            element.value = (hospitalinfo) ? hospitalinfo : 'none'
          }
          if(element.key === 'followUp45_therapyDetails'){
            if(this.userdata.medicalHistory.therapistName) {
              let tDate = (this.userdata.medicalHistory.lastTherapyDate) ? `, Last Therapy Date: ${formatDate((this.userdata.medicalHistory.lastTherapyDate), 'MM-dd-yyy', 'en')}`: ', Last Therapy Date: none'
              element.value = `Therapist Name: ${this.userdata.medicalHistory.therapistName}` + tDate
            } else {
              element.value = `none`
            }
          }
          if(element.key === 'followUp45_pertinentInfo'){
            element.value = (this.userdata.medicalHistory.medicalKnownAllergies) ? this.userdata.medicalHistory.medicalKnownAllergies : 'none'
          }
          if(element.key === 'followUp45_medicalDisorder'){
            element.value = (this.userdata.medicalHistory.anyMedicalDisordersExplain) ? this.userdata.medicalHistory.anyMedicalDisordersExplain : 'none'
          }
          if(element.key === 'followUp45_nonPsychMeds'){
            element.value = (this.userdata.medicalHistory.nonPsychMedsExplain) ? this.userdata.medicalHistory.nonPsychMedsExplain : 'none'
          }
          if(element.key === 'followUp45_pastHistory'){
            element.value = (this.userdata.medicalHistory.pastHistoryofAlchoholExplain) ? this.userdata.medicalHistory.pastHistoryofAlchoholExplain : 'none'
          }
          if(element.key === 'followUp45_legalIssue'){
            let legalText = (this.userdata.patientMiscInfo.miscKnownAllergies1) ? this.userdata.patientMiscInfo.miscKnownAllergies1 : ''
            // legalText = (this.appointMentdata.healthDetails.legal_issue_description) ? legalText + ` ${this.appointMentdata.healthDetails.legal_issue_description}` : legalText + ' none'
            element.value = legalText
          }
          if(element.key === 'followUp45_sucidal'){
            element.value = (this.appointMentdata) ? this.appointMentdata.healthDetails.feeling_sucidal : 'none'
          }
          if(element.key === 'followUp45_paranoid'){
            element.value = (this.appointMentdata) ? this.appointMentdata.healthDetails.feeling_paranoid :  'none'
          }
          if(element.key === 'followUp45_libibo'){
            element.value = (this.appointMentdata) ? this.appointMentdata.healthDetails.libido : 'none'
          }
          if(element.key === 'followUp45_Insomina'){
            element.value =  (this.appointMentdata) ? this.appointMentdata.healthDetails.insomnia : 'none'
          }
        }
        if (element.key === 'Diagnosis') {
          element.value = data[element.editAccessKey] ? data[element.editAccessKey] : this.MednoteData.DiagonsisType
          element.editable = true
        }
        if (element.key === 'primaryInsuranceProvider') {
          if (this.MednoteData.Operation === 'CreateMednote') {
            element.value = parseInt(this.userdata.insuranceDetails['primaryInsuranceProvider'])
          } else if (this.MednoteData.Operation === 'EditMednote') {
            this.providers.primaryInsurance.forEach(insurance => {
              if (insurance.insuranceName === noteInsurance) {
                element.value = parseInt(insurance.insuranceId)
              }
            })
          } else {
            element.value = parseInt(this.userdata.insuranceDetails['primaryInsuranceProvider'])
          }
        }
        if (element.key === 'followUp45_dob') {

          let dob = this.userdata.dob.split('-')
          element.value = `${dob[1]}/${dob[2]}/${dob[0]}` //MM-DD-YYYY format
        }
        if (element.key === 'followUp45_Date') {
          let evalDate = data.evaluationDate.split('-')
          element.value = `${evalDate[1]}/${evalDate[2]}/${evalDate[0]}` //MM-DD-YYYY format
        }
        if(element.key === 'followUp45_DurationOfVisit'){
          element.value = this.durationOfTherapy(data.DurationOfVisit)
        }
     
        if (element.key === 'followUp45_heightFt') {
          element.value = height.length !== 0 ? height[0] : ''
        }
        if (element.key === 'followUp45_heightInch') {
          element.value = height.length !== 0 ? height[1] : ''
        }
        // if( element.key === 'followUp45_Hallucinations'){ element.value = data['MentalStatusExam.Thought Content.Hallucinations'] ? true : false }
        // if( element.key === 'followUp45_Date'){ element.value = this.formattedCurrentDate}
        if (element.key === 'followUp45_durationOfMedication') {
          element.value = data['Plan.Duration']
        }
        if (element.key === 'followUp45_medication') {
          element.value = data['Plan.Medications'][0]
        }
        if (element.key === 'followUp45_advice') {
          element.value = data['OutcomeAndAdvices'][0]
        }
        // if( element.key === 'followUp45_SIorHI'){ element.value = ( data['MentalStatusExam.SI/HI'] === true) ? 'Yes' : 'No'}
      })
    } else if (
      (this.MednoteData.Operation === 'EditMednote' && data.mednoteType === 'EVALUATION') ||
      this.prefillEvalFlag
    ) {
      this.formService.mednotesEvalConfig.forEach((element: any) => {
        if (data.hasOwnProperty(element.editAccessKey)) {
          element.value = data[element.editAccessKey]
        }
        if (element.key === 'primaryInsuranceProvider') {
          if (this.MednoteData.Operation === 'CreateMednote') {
            element.value = parseInt(this.userdata.insuranceDetails['primaryInsuranceProvider'])
          } else if (this.MednoteData.Operation === 'EditMednote') {
            this.providers.primaryInsurance.forEach(insurance => {
              if (insurance.insuranceName === noteInsurance) {
                element.value = parseInt(insurance.insuranceId)
              }
            })
          } else {
            element.value = parseInt(this.userdata.insuranceDetails['primaryInsuranceProvider'])
          }
        }
        if (element.key === 'sideEffects') {
          element.value = data.sideEffects
        }
        if (element.key === 'sideEffects_description') {
          if (data.sideEffects) {
            element.value = data.sideEffects_description
          }
        }
        if (element.key === 'Diagnosis') {
          element.value = data[element.editAccessKey] ? data[element.editAccessKey] : this.MednoteData.DiagonsisType
          element.editable = true
        }
        if (element.editAccessKey === 'Observations.heightFT') {
          element.value = height.length !== 0 ? height[0] : ''
        }
        if (element.editAccessKey === 'Observations.heightInch') {
          element.value = height.length !== 0 ? height[1] : ''
        }
        if (element.key === 'dob') {
          let dob = data['PatientInfo.dob'].split('-')
          element.value = `${dob[1]}/${dob[2]}/${dob[0]}` //MM-DD-YYYY format
        }
        if (element.key === 'evaluationDate') {
          let evalDate = data.evaluationDate.split('-')
          element.value = `${evalDate[1]}/${evalDate[2]}/${evalDate[0]}` //MM-DD-YYYY format
        }

        if (element.key === 'diagnosisMedications') {
          element.value = data.Medications[0]
        }
        if (element.key === 'medications') {
          element.value = data.pastMedications[0]
        }
     
      })
    }
  }
  // for create
  durationOfTherapy(duration: string) {
    const match = duration.match(/^(\d+)/);
    if (match) {
        return parseInt(match[1], 10);
    }
    return null;
  }
  assignData() {
    let height = this.userdata.patientBasicInfo.height ? this.userdata.patientBasicInfo.height.split(`'`) : []
    //prefillings for Mednote 25 mintues
    if (this.MednoteData.Duration === '25 Minutes' && this.MednoteData.noteType === 'Monthly Medication Management') {
      this.formService.mednoteFollowUpTwentyFiveConfig[0].value = this.userdata.firstName + ' ' + this.userdata.lastName
      let dob = this.userdata.dob.split('-')
      this.formService.mednoteFollowUpTwentyFiveConfig[1].value = `${dob[1]}/${dob[2]}/${dob[0]}` //MM-DD-YYYY format
      this.formService.mednoteFollowUpTwentyFiveConfig[2].value = this.durationOfTherapy(this.MednoteData.Duration)
      this.formService.mednoteFollowUpTwentyFiveConfig[3].value = this.MednoteData.MednoteCode
      let insuranceDropDownPosition = this.formService.mednoteFollowUpTwentyFiveConfig.length - 1
      this.formService.mednoteFollowUpTwentyFiveConfig[insuranceDropDownPosition].value = parseInt(
        this.userdata.insuranceDetails['primaryInsuranceProvider']
      )
      let index = this.formService.mednoteFollowUpTwentyFiveConfig.findIndex(obj => obj['key'] === 'followUp25_Date')
      // this.formService.mednoteFollowUpTwentyFiveConfig[index].value = this.formattedCurrentDate;
      let evalDate = this.MednoteData.AppointmentDate.split('-')
      this.formService.mednoteFollowUpTwentyFiveConfig[index].value = `${evalDate[1]}/${evalDate[2]}/${evalDate[0]}` //MM-DD-YYYY format
      
      this.formService.mednoteFollowUpTwentyFiveConfig.forEach((element: any, id: any) => {
        if (element.editAccessKey == 'Plan.Duration') {
          element.value = 'One month supply of the following medications'
        }
        if (element.editAccessKey == 'Plan.Plan') {
          element.value =
            'Client was also advised, time management strategies and digital fasting. \nClient was encouraged to email if any side effects or new onset symptoms are observed \nClient is encouraged also to seek holistic approach like walking, running, meditation. Client got support, guidance, and reassurance after the visit. Client diagnosis was reviewed and discussed medication rationale.'
        }
        // if(element.editAccessKey=='Plan.Medications') { element.value='' }
        // if(element.key=='followUp25_advice'){ element.value='' }
        if (element.key === 'Diagnosis') {
          element.editable = false
          element.value = this.MednoteData.DiagonsisType
        }
        if (element.key == 'followUp25_heightFt') {
          element.value = height.length !== 0 ? height[0] : ''
        }
        if (element.key == 'followUp25_heightInch') {
          element.value = height.length !== 0 ? height[1] : ''
        }
        if (element.key == 'followUp25_weight') {
          element.value = this.userdata.patientBasicInfo.weight ? this.userdata.patientBasicInfo.weight : ''
        }
        if (element.key == 'followUp25_Code') {
          element.value = '99213'
        }
        if (element.key == 'followUp25_assessment') {
          element.value = 'DSM-V'
        }
        if (element.key == 'followUp25_DoctorNotes') {
          let elementToRemove = " Dear Dr. Prayaga's Office,"
          this.doctorNotes = this.doctorNotes.filter(item => item !== elementToRemove)
          let notes = this.doctorNotes.join('\n ')
          element.value = notes
        }
        if (element.key == 'followUp25_allergies_description') {
          element.value =
            this.appointMentdata !== null
              ? this.userdata.medicalHistory.knownAllergies +
                '\n' +
                this.userdata.medicalHistory.medicalKnownAllergies +
                '\n' +
                this.appointMentdata.healthDetails.outburst_allergies_description
              : this.userdata.medicalHistory.knownAllergies + '\n' + this.userdata.medicalHistory.medicalKnownAllergies
          // element.value =
        }
        
        if (element.key === 'followUp25_HospitalInfo' ) {
            let hospitalinfo = (this.userdata.medicalHistory.nameOfHospital) 
          ?
            `Hospital Name - ${this.userdata.medicalHistory.nameOfHospital}, Date of hospitalization - ${this.userdata.medicalHistory.patientHospitalizedDate}, Reason for hospitalization - ${this.userdata.medicalHistory.patientHospitalizedReason} `
          :
            '' ;
  

          element.value = (hospitalinfo) ? hospitalinfo : 'none'
        }
        if(element.key === 'followUp25_therapyDetails'){
          if(this.userdata.medicalHistory.therapistName) {
            let tDate = (this.userdata.medicalHistory.lastTherapyDate) ? `, Last Therapy Date: ${formatDate((this.userdata.medicalHistory.lastTherapyDate), 'MM-dd-yyy', 'en')}`: ', Last Therapy Date: none'
            element.value = `Therapist Name: ${this.userdata.medicalHistory.therapistName}` + tDate
          } else {
            element.value = `none`
          }
        }
        if(element.key === 'followUp25_pertinentInfo'){
          element.value = (this.userdata.medicalHistory.medicalKnownAllergies) ? this.userdata.medicalHistory.medicalKnownAllergies : 'none'
        }
        if(element.key === 'followUp25_medicalDisorder'){
          element.value = (this.userdata.medicalHistory.anyMedicalDisordersExplain) ? this.userdata.medicalHistory.anyMedicalDisordersExplain : 'none'
        }
        if(element.key === 'followUp25_nonPsychMeds'){
          element.value = (this.userdata.medicalHistory.nonPsychMedsExplain) ? this.userdata.medicalHistory.nonPsychMedsExplain : 'none'
        }
        if(element.key === 'followUp25_pastHistory'){
          element.value = (this.userdata.medicalHistory.pastHistoryofAlchoholExplain) ? this.userdata.medicalHistory.pastHistoryofAlchoholExplain : 'none'
        }
        if(element.key === 'followUp25_legalIssue'){
          let legalText = (this.userdata.patientMiscInfo.miscKnownAllergies1) ? this.userdata.patientMiscInfo.miscKnownAllergies1 : ''
          // legalText = (this.appointMentdata.healthDetails.legal_issue_description) ? legalText + ` ${this.appointMentdata.healthDetails.legal_issue_description}` : legalText + ' none'
          element.value = (legalText) ? legalText : 'none' 
        }
        if(element.key === 'followUp25_sucidal'){
          element.value = (this.appointMentdata) ? this.appointMentdata.healthDetails.feeling_sucidal : 'none'
        }
        if(element.key === 'followUp25_paranoid'){
          element.value = (this.appointMentdata) ? this.appointMentdata.healthDetails.feeling_paranoid : 'none'
        }
        if(element.key === 'followUp25_libibo'){
          element.value = (this.appointMentdata) ? this.appointMentdata.healthDetails.libido : 'none'
        }
        if(element.key === 'followUp25_Insomina'){
          element.value = (this.appointMentdata) ? this.appointMentdata.healthDetails.insomnia  : 'none'
        }

        // if (element.key == "sideEffects") {
        //   element.value = false
        // }
        if (element.key == 'sideEffectsDetails') {
          if (this.appointMentdata !== null) {
            element.value = this.appointMentdata.healthDetails.medicines_side_effects_description
              ? this.appointMentdata.healthDetails.medicines_side_effects_description
              : 'No side-effects noticed.'
          } else {
            element.value = 'No side-effects noticed.'
          }
        }
        // if(element.editAccessKey=='sideEffects') { element.value=false }
      })
      if (this.MednoteData.DiagonsisType == 'Adjustment Disorder') {
        this.formService.mednoteFollowUpTwentyFiveConfig.forEach((element: any, id: any) => {
          if (element.key == 'followUp25_ChiefComplain') {
            element.value = 'Adjustment Disorder'
          }
          if (element.key == 'AdjustmentDisorder25_axisI') {
            element.value = true
          }
          if (element.key == 'followUp25_WellGroomed') {
            element.value = true
          }
          if (element.key == 'followUp25_Clean') {
            element.value = true
          }
          if (element.key == 'followUp25_Person') {
            element.value = true
          }
          if (element.key == 'followUp25_Place') {
            element.value = true
          }
          if (element.key == 'followUp25_Time') {
            element.value = true
          }
          if (element.key == 'followUp25_Purpose') {
            element.value = true
          }
          if (element.key == 'followUp25_Normal') {
            element.value = true
          }
          if (element.key == 'followUp25_Cooperative') {
            element.value = true
          }
          if (element.key == 'followUp25_Friendly') {
            element.value = true
          }
          if (element.key == 'followUp25_Ambulatory') {
            element.value = true
          }
          if (element.key == 'followUp25_Anxious') {
            element.value = true
          }
          // if(element.key == 'followUp25_Euthymic'){ element.value = true }
          if (element.key == 'followUp25_Full') {
            element.value = true
          }
          if (element.key == 'followUp25_GoalDirectedAndLogical') {
            element.value = true
          }
          if (element.key == 'followUp25_Appropriate_TC') {
            element.value = true
          }
          if (element.key == 'followUp25_Appropriate_TP') {
            element.value = true
          }
          if (element.key == 'followUp25_ShortTerm') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp25_LongTerm') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp25_Insight') {
            element.value = 'Fair'
          }
          if (element.key == 'followUp25_Judgment') {
            element.value = 'Fair'
          }
          if (element.key == 'followUp25_axisTwo') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisFour') {
            element.value = 'Moderate'
          }
          if (element.key == 'followUp25_axisFive') {
            element.value = '70'
          }
        })
      }
      if (this.MednoteData.DiagonsisType == 'Oppositional Disorder') {
        this.formService.mednoteFollowUpTwentyFiveConfig.forEach((element: any, id: any) => {
          if (element.key == 'followUp25_ChiefComplain') {
            element.value = 'OPPOSITIONAL DISORDER'
          }
          if (element.key == 'OppositionalDisorder25_axisI') {
            element.value = true
          }
          if (element.key == 'followUp25_WellGroomed') {
            element.value = true
          }
          if (element.key == 'followUp25_Clean') {
            element.value = true
          }
          if (element.key == 'followUp25_Person') {
            element.value = true
          }
          if (element.key == 'followUp25_Place') {
            element.value = true
          }
          if (element.key == 'followUp25_Time') {
            element.value = true
          }
          if (element.key == 'followUp25_Purpose') {
            element.value = true
          }
          if (element.key == 'followUp25_Normal') {
            element.value = true
          }
          if (element.key == 'followUp25_Cooperative') {
            element.value = true
          }
          if (element.key == 'followUp25_Friendly') {
            element.value = true
          }
          if (element.key == 'followUp25_Ambulatory') {
            element.value = true
          }
          if (element.key == 'followUp25_Anxious') {
            element.value = true
          }
          if (element.key == 'followUp25_Euthymic') {
            element.value = true
          }
          if (element.key == 'followUp25_Full') {
            element.value = true
          }
          if (element.key == 'followUp25_GoalDirectedAndLogical') {
            element.value = true
          }
          if (element.key == 'followUp25_Appropriate_TC') {
            element.value = true
          }
          if (element.key == 'followUp25_Appropriate_TP') {
            element.value = true
          }
          if (element.key == 'followUp25_ShortTerm') {
            element.value = 'Impaired'
          }
          if (element.key == 'followUp25_LongTerm') {
            element.value = 'Impaired'
          }
          if (element.key == 'followUp25_Insight') {
            element.value = 'Fair'
          }
          if (element.key == 'followUp25_Judgment') {
            element.value = 'Fair'
          }
          if (element.key == 'followUp25_axisTwo') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisFour') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisFive') {
            element.value = '70'
          }
        })
      }
      if (this.MednoteData.DiagonsisType == 'ADHD') {
        this.formService.mednoteFollowUpTwentyFiveConfig.forEach((element: any, id: any) => {
          if (element.key == 'followUp25_ChiefComplain') {
            element.value = 'ADHD'
          }
          if (element.key == 'ADHD25_axisI') {
            element.value = true
          }
          if (element.key == 'followUp25_WellGroomed') {
            element.value = true
          }
          if (element.key == 'followUp25_Clean') {
            element.value = true
          }
          if (element.key == 'followUp25_Person') {
            element.value = true
          }
          if (element.key == 'followUp25_Place') {
            element.value = true
          }
          if (element.key == 'followUp25_Time') {
            element.value = true
          }
          if (element.key == 'followUp25_Purpose') {
            element.value = true
          }
          if (element.key == 'followUp25_Normal') {
            element.value = true
          }
          if (element.key == 'followUp25_Cooperative') {
            element.value = true
          }
          if (element.key == 'followUp25_Friendly') {
            element.value = true
          }
          if (element.key == 'followUp25_Ambulatory') {
            element.value = true
          }
          if (element.key == 'followUp25_Anxious') {
            element.value = true
          }
          if (element.key == 'followUp25_Euthymic') {
            element.value = true
          }
          if (element.key == 'followUp25_Full') {
            element.value = true
          }
          if (element.key == 'followUp25_GoalDirectedAndLogical') {
            element.value = true
          }
          if (element.key == 'followUp25_Appropriate_TC') {
            element.value = true
          }
          if (element.key == 'followUp25_Appropriate_TP') {
            element.value = true
          }
          if (element.key == 'followUp25_ShortTerm') {
            element.value = 'Impaired'
          }
          if (element.key == 'followUp25_LongTerm') {
            element.value = 'Impaired'
          }
          if (element.key == 'followUp25_Insight') {
            element.value = 'Fair'
          }
          if (element.key == 'followUp25_Judgment') {
            element.value = 'Fair'
          }
          if (element.key == 'followUp25_axisTwo') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisFour') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisFive') {
            element.value = '70'
          }
        })
      }
      if (this.MednoteData.DiagonsisType == 'Anxiety') {
        this.formService.mednoteFollowUpTwentyFiveConfig.forEach((element: any) => {
          if (element.key == 'followUp25_ChiefComplain') {
            element.value = 'ANXIETY'
          }
          if (element.key == 'Anxiety25_axisI') {
            element.value = true
          }
          if (element.key == 'followUp25_WellGroomed') {
            element.value = true
          }
          if (element.key == 'followUp25_Clean') {
            element.value = true
          }
          if (element.key == 'followUp25_Person') {
            element.value = true
          }
          if (element.key == 'followUp25_Place') {
            element.value = true
          }
          if (element.key == 'followUp25_Time') {
            element.value = true
          }
          if (element.key == 'followUp25_Purpose') {
            element.value = true
          }
          if (element.key == 'followUp25_Normal') {
            element.value = true
          }
          if (element.key == 'followUp25_Cooperative') {
            element.value = true
          }
          if (element.key == 'followUp25_Friendly') {
            element.value = true
          }
          if (element.key == 'followUp25_Ambulatory') {
            element.value = true
          }
          if (element.key == 'followUp25_Anxious') {
            element.value = true
          }
          if (element.key == 'followUp25_Full') {
            element.value = true
          }
          if (element.key == 'followUp25_GoalDirectedAndLogical') {
            element.value = true
          }
          if (element.key == 'followUp25_Appropriate_TP') {
            element.value = true
          }
          if (element.key == 'followUp25_Appropriate_TC') {
            element.value = true
          }
          if (element.key == 'followUp25_ShortTerm') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp25_LongTerm') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp25_Insight') {
            element.value = 'Fair'
          }
          if (element.key == 'followUp25_Judgment') {
            element.value = 'Fair'
          }
          if (element.key == 'followUp25_axisTwo') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisFour') {
            element.value = 'Moderate'
          }
          if (element.key == 'followUp25_axisFive') {
            element.value = '70'
          }
        
        })
      }
      if (this.MednoteData.DiagonsisType == 'Depression') {
        this.formService.mednoteFollowUpTwentyFiveConfig.forEach((element: any, id: any) => {
          if (element.key == 'followUp25_ChiefComplain') {
            element.value = 'MAJOR DEPRESSION'
          }
          if (element.key == 'Depression25_axisI') {
            element.value = true
          }
          // if (element.key == 'Depression25_axisI_I') {
          //   element.value = true
          // }
          if (element.key == 'followUp25_WellGroomed') {
            element.value = true
          }
          if (element.key == 'followUp25_Clean') {
            element.value = true
          }
          if (element.key == 'followUp25_Person') {
            element.labeltext = 'Normal Person'
            element.value = true
          }
          if (element.key == 'followUp25_Place') {
            element.value = true
          }
          if (element.key == 'followUp25_Time') {
            element.value = true
          }
          if (element.key == 'followUp25_Purpose') {
            element.value = true
          }
          if (element.key == 'followUp25_Normal') {
            element.labeltext = 'Normal/ Slow'
            element.value = true
          }
          if (element.key == 'followUp25_Cooperative') {
            element.value = true
          }
          if (element.key == 'followUp25_Friendly') {
            element.value = true
          }
          if (element.key == 'followUp25_Ambulatory') {
            element.value = true
          }
          // if(element.key == 'followUp25_WheelChair'){
          //   element.labeltext = 'Wheelchair minimal '
          //   element.value = true
          // }
          if (element.key == 'followUp25_Depressed') {
            element.value = true
          }
          if (element.key == 'followUp25_Flat') {
            element.value = true
          }
          if (element.key == 'followUp25_PreOccupation_TC') {
            element.value = true
          }
          if (element.key == 'followUp25_GoalDirectedAndLogical') {
            element.value = true
          }
          if (element.key == 'followUp25_Appropriate_TP') {
            element.value = true
          }
          if (element.key == 'followUp25_ShortTerm') {
            element.value = 'Impaired'
          }
          if (element.key == 'followUp25_LongTerm') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp25_Insight') {
            element.value = 'Fair'
          }
          if (element.key == 'followUp25_Judgment') {
            element.value = 'Fair'
          }
          if (element.key == 'followUp25_axisTwo') {
            element.value = 'Deffered'
          }
          if (element.key == 'followUp25_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisFour') {
            element.value = 'Moderate'
          }
          if (element.key == 'followUp25_axisFive') {
            element.value = '70'
          }
        })
      }
      if (this.MednoteData.DiagonsisType == 'Bipolar') {
        this.formService.mednoteFollowUpTwentyFiveConfig.forEach((element: any, id: any) => {
          if (element.key == 'followUp25_ChiefComplain') {
            element.value = 'BI-POLAR II'
          }
          if (element.key == 'Bipolar25_axisI') {
            element.value = true
          }
          if (element.key == 'followUp25_WellGroomed') {
            element.value = true
          }
          if (element.key == 'followUp25_Clean') {
            element.value = true
          }
          if (element.key == 'followUp25_Person') {
            element.value = true
          }
          if (element.key == 'followUp25_Place') {
            element.value = true
          }
          if (element.key == 'followUp25_Time') {
            element.value = true
          }
          if (element.key == 'followUp25_Purpose') {
            element.value = true
          }
          if (element.key == 'followUp25_Pressured') {
            element.value = true
          }
          if (element.key == 'followUp25_Cooperative') {
            element.value = true
          }
          if (element.key == 'followUp25_Friendly') {
            element.value = true
          }
          if (element.key == 'followUp25_Ambulatory') {
            element.value = true
          }
          if (element.key == 'followUp25_Dysphoric') {
            element.value = true
          }
          if (element.key == 'followUp25_Full') {
            element.value = true
          }
          if (element.key == 'followUp25_Labile') {
            element.value = true
          }
          if (element.key == 'followUp25_GoalDirectedAndLogical') {
            element.value = true
          }
          if (element.key == 'followUp25_Appropriate_TP') {
            element.value = true
          }
          if (element.key == 'followUp25_Tangential') {
            element.value = true
          }
          if (element.key == 'followUp25_PreOccupation_TC') {
            element.value = true
          }
          if (element.key == 'followUp25_ShortTerm') {
            element.value = 'Impaired'
          }
          if (element.key == 'followUp25_LongTerm') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp25_Insight') {
            element.value = 'Fair to Poor'
          }
          if (element.key == 'followUp25_Judgment') {
            element.value = 'Fair to Poor'
          }
          if (element.key == 'followUp25_axisTwo') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisFour') {
            element.value = 'Moderate'
          }
          if (element.key == 'followUp25_axisFive') {
            element.value = '70'
          }
        })
      }
      if (this.MednoteData.DiagonsisType == 'Schizophrenia') {
        this.formService.mednoteFollowUpTwentyFiveConfig.forEach((element: any, id: any) => {
          if (element.key == 'followUp25_ChiefComplain') {
            element.value = 'Schizoaffective disorder'
          }
          if (element.key == 'Schizophrenia25_axisI') {
            element.value = true
          }
          if (element.key == 'followUp25_WellGroomed') {
            element.value = true
          }
          if (element.key == 'followUp25_Clean') {
            element.value = true
          }
          if (element.key == 'followUp25_Person') {
            element.value = true
          }
          if (element.key == 'followUp25_Place') {
            element.value = true
          }
          if (element.key == 'followUp25_Time') {
            element.value = true
          }
          if (element.key == 'followUp25_Purpose') {
            element.value = true
          }
          if (element.key == 'followUp25_Normal') {
            element.value = true
          }
          if (element.key == 'followUp25_Guarded') {
            element.value = true
          }
          if (element.key == 'followUp25_Friendly') {
            element.value = true
          }
          if (element.key == 'followUp25_Ambulatory') {
            element.value = true
          }
          if (element.key == 'followUp25_Anxious') {
            element.value = true
          }
          if (element.key == 'followUp25_Irritable') {
            element.value = true
          }
          if (element.key == 'followUp25_Flat') {
            element.value = true
          }
          if (element.key == 'followUp25_Tangential') {
            element.value = true
          }
          if (element.key == 'followUp25_Hallucinations') {
            element.value = true
          }
          if (element.key == 'followUp25_PersecutoryDelusions') {
            element.value = true
          }
          if (element.key == 'followUp25_ShortTerm') {
            element.value = true
          }
          if (element.key == 'followUp25_ShortTerm') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp25_LongTerm') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp25_Insight') {
            element.value = 'Fair to Poor'
          }
          if (element.key == 'followUp25_Judgment') {
            element.value = 'Fair to Poor'
          }
          if (element.key == 'followUp25_axisTwo') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisFour') {
            element.value = 'Moderate'
          }
          if (element.key == 'followUp25_axisFive') {
            element.value = '70'
          }
          
        })
      }
      if (this.MednoteData.DiagonsisType == 'Autism') {
        this.formService.mednoteFollowUpTwentyFiveConfig.forEach((element: any, id: any) => {
          if (element.key == 'followUp25_ChiefComplain') {
            element.value = 'AUTISM'
          }
          if (element.key == 'followUp25_WellGroomed') {
            element.value = true
          }
          if (element.key == 'followUp25_Clean') {
            element.value = true
          }
          if (element.key == 'followUp25_Person') {
            element.value = true
          }
          if (element.key == 'followUp25_Place') {
            element.value = true
          }
          if (element.key == 'followUp25_Time') {
            element.value = true
          }
          if (element.key == 'followUp25_Purpose') {
            element.value = true
          }
          if (element.key == 'followUp25_Disorganized_speech') {
            element.value = true
          }
          if (element.key == 'followUp25_Cooperative') {
            element.value = true
          }
          if (element.key == 'followUp25_Friendly') {
            element.value = true
          }
          if (element.key == 'followUp25_Anxious') {
            element.value = true
          }
          if (element.key == 'followUp25_Appropriate_TP') {
            element.value = true
          }
          if (element.key == 'followUp25_Disorganized_TP') {
            element.value = true
          }
          if (element.key == 'followUp25_Full') {
            element.value = true
          }
          if (element.key == 'followUp25_Ambulatory') {
            element.value = true
          }
          if (element.key == 'followUp25_ShortTerm') {
            element.value = 'Impaired'
          }
          if (element.key == 'followUp25_LongTerm') {
            element.value = 'Impaired'
          }
          if (element.key == 'followUp25_Insight') {
            element.value = 'Poor'
          }
          if (element.key == 'followUp25_Judgment') {
            element.value = 'Poor'
          }
          if (element.key === 'Autism25_axisI') {
            element.value = true
          }
          if (element.key == 'followUp25_axisTwo') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisFour') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisFive') {
            element.value = '70'
          }
        })
      }
      if (this.MednoteData.DiagonsisType == 'OCD') {
        this.formService.mednoteFollowUpTwentyFiveConfig.forEach((element: any, id: any) => {
          if (element.key == 'followUp25_ChiefComplain') {
            element.value = 'OCD (Obsessive-compulsive disorder)'
          }
          if (element.key == 'OcdDisorder25_axisI') {
            element.value = true
          }
          if (element.key == 'followUp25_WellGroomed') {
            element.value = true
          }
          if (element.key == 'followUp25_Clean') {
            element.value = true
          }
          if (element.key == 'followUp25_Person') {
            element.value = true
          }
          if (element.key == 'followUp25_Place') {
            element.value = true
          }
          if (element.key == 'followUp25_Time') {
            element.value = true
          }
          if (element.key == 'followUp25_Purpose') {
            element.value = true
          }
          if (element.key == 'followUp25_Normal') {
            element.value = true
          }
          if (element.key == 'followUp25_Cooperative') {
            element.value = true
          }
          if (element.key == 'followUp25_Friendly') {
            element.value = true
          }
          if (element.key == 'followUp25_Ambulatory') {
            element.value = true
          }
          if (element.key == 'followUp25_Anxious') {
            element.value = true
          }
          if (element.key == 'followUp25_Full') {
            element.value = true
          }
          if (element.key == 'followUp25_Appropriate_TP') {
            element.value = true
          }
          if (element.key == 'followUp25_GoalDirectedAndLogical') {
            element.value = true
          }
          if (element.key == 'followUp25_Appropriate_TC') {
            element.value = true
          }
          if (element.key == 'followUp25_ShortTerm') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp25_LongTerm') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp25_Insight') {
            element.value = 'Fair'
          }
          if (element.key == 'followUp25_Judgment') {
            element.value = 'Fair'
          }
          if (element.key == 'followUp25_axisTwo') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisFour') {
            element.value = 'Moderate'
          }
          if (element.key == 'followUp25_axisFive') {
            element.value = '70'
          }
        })
      }
      if (this.MednoteData.DiagonsisType == 'Eating Disorder') {
        this.formService.mednoteFollowUpTwentyFiveConfig.forEach((element: any, id: any) => {
          if (element.key == 'followUp25_ChiefComplain') {
            element.value = 'EATING DISORDER'
          }
          if (element.key == 'followUp25_WellGroomed') {
            element.value = true
          }
          if (element.key == 'followUp25_Clean') {
            element.value = true
          }
          if (element.key == 'followUp25_Person') {
            element.value = true
          }
          if (element.key == 'followUp25_Place') {
            element.value = true
          }
          if (element.key == 'followUp25_Time') {
            element.value = true
          }
          if (element.key == 'followUp25_Purpose') {
            element.value = true
          }
          if (element.key == 'followUp25_Normal') {
            element.value = true
          }
          if (element.key == 'followUp25_Cooperative') {
            element.value = true
          }
          if (element.key == 'followUp25_Friendly') {
            element.value = true
          }
          if (element.key == 'followUp25_Anxious') {
            element.value = true
          }
          if (element.key == 'followUp25_Euthymic') {
            element.value = true
          }
          if (element.key == 'followUp25_Appropriate_TP') {
            element.value = true
          }
          if (element.key == 'followUp25_GoalDirectedAndLogical') {
            element.value = true
          }
          if (element.key == 'followUp25_Appropriate_TC') {
            element.value = true
          }
          if (element.key == 'followUp25_Full') {
            element.value = true
          }
          if (element.key == 'followUp25_Ambulatory') {
            element.value = true
          }
          if (element.key == 'followUp25_ShortTerm') {
            element.value = 'Impaired'
          }
          if (element.key == 'followUp25_LongTerm') {
            element.value = 'Impaired'
          }
          if (element.key == 'followUp25_Insight') {
            element.value = 'Fair'
          }
          if (element.key == 'followUp25_Judgment') {
            element.value = 'Fair'
          }
          if (element.key === 'EatingDisorder25_axisI') {
            element.value = true
          }
          if (element.key == 'followUp25_axisTwo') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisFour') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisFive') {
            element.value = '70'
          }
          
        })
      }
    }
    //prefillings for Mednote 45 mintues
    if (this.MednoteData.Duration === '45 Minutes' && this.MednoteData.noteType === 'Monthly Medication Management') {
      this.formService.mednoteFollowUpFortyFiveConfig[0].value = this.userdata.firstName + ' ' + this.userdata.lastName
      let dob = this.userdata.dob.split('-')
      this.formService.mednoteFollowUpFortyFiveConfig[1].value = `${dob[1]}/${dob[2]}/${dob[0]}` //MM-DD-YYYY format
      this.formService.mednoteFollowUpFortyFiveConfig[2].value = this.durationOfTherapy(this.MednoteData.Duration)
      this.formService.mednoteFollowUpFortyFiveConfig[3].value = this.MednoteData.MednoteCode
      this.formService.mednoteFollowUpFortyFiveConfig[4].value = this.MednoteData.DiagonsisType
      let index = this.formService.mednoteFollowUpFortyFiveConfig.findIndex(obj => obj['key'] === 'followUp45_Date')
      let evalDate = this.MednoteData.AppointmentDate.split('-')
      this.formService.mednoteFollowUpFortyFiveConfig[index].value = `${evalDate[1]}/${evalDate[2]}/${evalDate[0]}` //MM-DD-YYYY format
      let insuranceDropDownPosition = this.formService.mednoteFollowUpFortyFiveConfig.length - 1
      this.formService.mednoteFollowUpFortyFiveConfig[insuranceDropDownPosition].value = parseInt(
        this.userdata.insuranceDetails['primaryInsuranceProvider']
      )

      this.formService.mednoteFollowUpFortyFiveConfig.forEach((element: any, id: any) => {
        if (element.key === 'Diagnosis') {
          element.editable = false
          element.value = this.MednoteData.DiagonsisType
        }
        if (element.key == 'followUp45_assessment') {
          element.value = 'DSM-V'
        }
        if (element.editAccessKey == 'Procedure/Type of Visit') {
          element.value =
            'CPT 99214: Established outpatient, office visit, moderate complexity, Detailed history, and physical exam'
        }

        if (element.key == 'followUp45_potentialSideEffect') {
          element.value =
            'The patient has been informed of the potential side effects the medication can give and instructed to contact us immediately if conditions get worse. \nPatient was instructed to contact the office or myself if any problems should arise.'
        }
      
        if (element.key == 'sideEffectsDetails') {
          if (this.appointMentdata !== null) {
            element.value =
              this.appointMentdata.healthDetails.medicines_side_effects &&
              this.appointMentdata.healthDetails.medicines_side_effects_description
                ? this.appointMentdata.healthDetails.medicines_side_effects_description
                : 'No side-effects noticed.'
          } else {
            element.value = 'No side-effects noticed.'
          }
        }

        if (element.key === 'followUp45_Allergies') {
          const allergies =
            this.appointMentdata !== null
              ? [
                  this.userdata.medicalHistory.knownAllergies,
                  this.userdata.medicalHistory.medicalKnownAllergies,
                  this.appointMentdata.healthDetails.outburst_allergies_description,
                ]
              : [this.userdata.medicalHistory.knownAllergies, this.userdata.medicalHistory.medicalKnownAllergies]
          const filteredAllergies = allergies.filter(allergy => !!allergy)
          element.value = filteredAllergies.length > 0 ? filteredAllergies.join('\n') : 'None'
        }

        if (element.editAccessKey == 'Plan.Duration') {
          element.value = 'One month supply of the following medications'
        }
        if (element.editAccessKey == 'Plan.Plan') {
          element.value =
            'Client got support, guidance, and reassurance after the visit. Client diagnosis was reviewed and discussed medication rationale. Client was also advised, time management strategies and digital fasting. \nClient was encouraged to email if any side effects or new onset symptoms are observed \nClient is encouraged also to seek holistic approach like walking, running, meditation. '
        }

        if (element.key == 'followUp45_DoctorNote') {
          let elementToRemove = " Dear Dr. Prayaga's Office,"
          this.doctorNotes = this.doctorNotes.filter(item => item !== elementToRemove)
          let notes = this.doctorNotes.join('\n ')
          element.value = notes
        }

        if (element.key == 'followUp45_heightFt') {
          element.value = height.length !== 0 ? height[0] : ''
        }
        if (element.key == 'followUp45_heightInch') {
          element.value = height.length !== 0 ? height[1] : ''
        }
        if (element.key == 'followUp45_weight') {
          element.value = this.userdata.patientBasicInfo.weight ? this.userdata.patientBasicInfo.weight : ''
        }
        if (element.key === 'followUp45_HospitalInfo' ) {
          let hospitalinfo = (this.userdata.medicalHistory.nameOfHospital) 
        ?
          `Hospital Name - ${this.userdata.medicalHistory.nameOfHospital}, Date of hospitalization - ${this.userdata.medicalHistory.patientHospitalizedDate}, Reason for hospitalization - ${this.userdata.medicalHistory.patientHospitalizedReason} `
        :
          '' ;

        element.value = (hospitalinfo) ? hospitalinfo : 'none'
      }
      if(element.key === 'followUp45_therapyDetails'){
        if(this.userdata.medicalHistory.therapistName) {
          let tDate = (this.userdata.medicalHistory.lastTherapyDate) ? `, Last Therapy Date: ${formatDate((this.userdata.medicalHistory.lastTherapyDate), 'MM-dd-yyy', 'en')}`: ', Last Therapy Date: none'
          element.value = `Therapist Name: ${this.userdata.medicalHistory.therapistName}` + tDate
        } else {
          element.value = `none`
        }      
      }
      if(element.key === 'followUp45_pertinentInfo'){
        element.value = (this.userdata.medicalHistory.medicalKnownAllergies) ? this.userdata.medicalHistory.medicalKnownAllergies : 'none'
      }
      if(element.key === 'followUp45_medicalDisorder'){
        element.value = (this.userdata.medicalHistory.anyMedicalDisordersExplain) ? this.userdata.medicalHistory.anyMedicalDisordersExplain : 'none'
      }
      if(element.key === 'followUp45_nonPsychMeds'){
        element.value = (this.userdata.medicalHistory.nonPsychMedsExplain) ? this.userdata.medicalHistory.nonPsychMedsExplain : 'none'
      }
      if(element.key === 'followUp45_pastHistory'){
        element.value = (this.userdata.medicalHistory.pastHistoryofAlchoholExplain) ? this.userdata.medicalHistory.pastHistoryofAlchoholExplain : 'none'
      }
      if(element.key === 'followUp45_legalIssue'){
        let legalText = (this.userdata.patientMiscInfo.miscKnownAllergies1) ? this.userdata.patientMiscInfo.miscKnownAllergies1 : ''
        // legalText = (this.appointMentdata.healthDetails.legal_issue_description) ? legalText + ` ${this.appointMentdata.healthDetails.legal_issue_description}` : legalText + ' none'
        element.value = (legalText) ? legalText : 'none' 
      }
      if(element.key === 'followUp45_sucidal'){
        element.value = (this.appointMentdata) ? this.appointMentdata.healthDetails.feeling_sucidal : 'none'
      }
      if(element.key === 'followUp45_paranoid'){
        element.value = (this.appointMentdata) ? this.appointMentdata.healthDetails.feeling_paranoid : 'none'
      }
      if(element.key === 'followUp45_libibo'){
        element.value = (this.appointMentdata) ? this.appointMentdata.healthDetails.libido : 'none'
      }
      if(element.key === 'followUp45_Insomina'){
        element.value = (this.appointMentdata) ?  this.appointMentdata.healthDetails.insomnia : 'none'
      }

      })

      if (this.MednoteData.DiagonsisType == 'ADHD') {
        this.formService.mednoteFollowUpFortyFiveConfig.forEach((element: any, id: any) => {
          if (element.editAccessKey == 'Procedure/Type of Visit') {
            element.value =
              'CPT 99214: Established outpatient, office visit, moderate complexity, Detailed history, and physical exam'
          }

          if (element.key == 'followUp45_potentialSideEffect') {
            element.value =
              'The patient has been informed of the potential side effects the medication can give and instructed to contact us immediately if conditions get worse. \nPatient was instructed to contact the office or myself if any problems should arise.'
          }

          if (element.key == 'followUp45_Full') {
            element.value = false
          }
          
          // add above
          if (element.key == 'ADHD45_axisI') {
            element.value = true
          }
          if (element.key == 'followUp45_ChiefComplain') {
            element.value = 'ADHD'
          }
          if (element.key == 'followUp45_statedAge') {
            element.value = true
          }
          if (element.key == 'followUp45_Person') {
            element.value = true
          }
          if (element.key == 'followUp45_Place') {
            element.value = true
          }
          if (element.key == 'followUp45_Purpose') {
            element.value = true
          }
          if (element.key == 'followUp45_Euthymic') {
            element.value = true
          }
          if (element.key == 'followUp45_NormalMovements') {
            element.value = true
          }
          if (element.key == 'followUp45_Normal') {
            element.value = true
          }
          if (element.key == 'followUp45_Cooperative') {
            element.value = true
          }
          if (element.key == 'followUp45_PreoccupationWithIllness') {
            element.value = true
          }
          if (element.key == 'followUp45_CooperativeBehaviour') {
            element.value = true
          }
          if (element.key == 'followUp45_LogicalandCoherent') {
            element.value = true
          }

          if (element.key == 'followUp45_RecentMemory') {
            element.value = 'Normal'
          }
          if (element.key == 'followUp45_CognitiveFunction') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp45_Insight') {
            element.value = 'Good'
          }
          if (element.key == 'followUp45_Judgment') {
            element.value = 'Good'
          }
          //  if(element.key == 'followUp45_Alertness'){ element.value = "Inattentive" }
          if (element.key == 'followUp45_IntellectualLevel') {
            element.value = 'Average'
          }
          if (element.key == 'followUp45_ImpulseControl') {
            element.value = 'Good'
          }
          if (element.key == 'followUp45_SIorHI') {
            element.value = 'No'
          }
          if (element.key == 'followUp45_AttentationorConcentration') {
            element.value = 'Partially impaired'
          }
          if (element.key == 'followUp45_axisTwo') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisFour') {
            element.value = 'Moderate'
          }
          if (element.key == 'followUp45_axisFive') {
            element.value = '70'
          }
        })
      }

      if (this.MednoteData.DiagonsisType == 'Anxiety') {
        this.formService.mednoteFollowUpFortyFiveConfig.forEach((element: any, id: any) => {
          if (element.key == 'Anxiety45_axisI') {
            element.value = true
          }
          if (element.key == 'followUp45_ChiefComplain') {
            element.value = 'Anxiety Disorder'
          }
          if (element.key == 'followUp45_statedAge') {
            element.value = true
          }
          if (element.key == 'followUp45_Person') {
            element.value = true
          }
          if (element.key == 'followUp45_Place') {
            element.value = true
          }
          if (element.key == 'followUp45_Purpose') {
            element.value = true
          }
          if (element.key == 'followUp45_Anxious') {
            element.value = true
          }
          if (element.key == 'followUp45_NormalMovements') {
            element.value = true
          }
          if (element.key == 'followUp45_Normal') {
            element.value = true
          }
          if (element.key == 'followUp45_Cooperative') {
            element.value = true
          }
          if (element.key == 'followUp45_PreoccupationWithIllness') {
            element.value = true
          }
          if (element.key == 'followUp45_CooperativeBehaviour') {
            element.value = true
          }
          if (element.key == 'followUp45_LogicalandCoherent') {
            element.value = true
          }

          if (element.key == 'followUp45_AttentationorConcentration') {
            element.value = 'Partially impaired'
          }
          if (element.key == 'followUp45_RecentMemory') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp45_CognitiveFunction') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp45_Insight') {
            element.value = 'Good'
          }
          if (element.key == 'followUp45_Judgment') {
            element.value = 'Good'
          }
          if (element.key == 'followUp45_Alertness') {
            element.value = ''
          }
          if (element.key == 'followUp45_IntellectualLevel') {
            element.value = 'Average'
          }
          if (element.key == 'followUp45_ImpulseControl') {
            element.value = 'Good'
          }
          if (element.key == 'followUp45_SIorHI') {
            element.value = 'No'
          }
          //   if(element.key == 'followUp45_AttentationorConcentration'){ element.value = "" }
          if (element.key == 'followUp45_RecentMemory') {
            element.value = 'Normal'
          }
          if (element.key == 'followUp45_axisTwo') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisFour') {
            element.value = 'Moderate'
          }
          if (element.key == 'followUp45_axisFive') {
            element.value = '70'
          }
        })
      }

      if (this.MednoteData.DiagonsisType == 'Depression') {
        this.formService.mednoteFollowUpFortyFiveConfig.forEach((element: any, id: any) => {
          if (element.key == 'followUp45_ChiefComplain') {
            element.value = 'MAJOR DEPRESSION'
          }
          if (element.key == 'Depression45_axisI') {
            element.value = true
          }
          // if (element.key == 'Depression45_axisI_I') {
          //   element.value = true
          // }
          if (element.key == 'followUp45_statedAge') {
            element.value = true
          }
          if (element.key == 'followUp45_Person') {
            element.value = true
          }
          if (element.key == 'followUp45_Place') {
            element.value = true
          }
          if (element.key == 'followUp45_Purpose') {
            element.value = true
          }
          if (element.key == 'followUp45_Time') {
            element.value = true
          }
          if (element.key == 'followUp45_Depressed') {
            element.value = true
          }
          if (element.key == 'followUp45_Normal') {
            element.value = true
          }
          if (element.key == 'followUp45_Normal') {
            element.value = true
          }
          if (element.key == 'followUp45_CooperativeBehaviour') {
            element.value = true
          }
          if (element.key == 'followUp45_Cooperative') {
            element.value = true
          }
          if (element.key == 'followUp45_PreoccupationWithIllness') {
            element.value = true
          }
          if (element.key == 'followUp45_LogicalandCoherent') {
            element.value = true
          }

          if (element.key == 'followUp45_Alertness') {
            element.value = 'Inattentive'
          }
          if (element.key == 'followUp45_IntellectualLevel') {
            element.value = 'Average'
          }

          if (element.key == 'followUp45_CognitiveFunction') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp45_AttentationorConcentration') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp45_RecentMemory') {
            element.value = 'Normal'
          }
          if (element.key == 'followUp45_Insight') {
            element.value = 'Good'
          }

          if (element.key == 'followUp45_ImpulseControl') {
            element.value = 'Good'
          }
          if (element.key == 'followUp45_SIorHI') {
            element.value = 'No'
          }

          if (element.key == 'followUp45_Judgment') {
            element.value = 'Good'
          }
          if (element.key == 'followUp45_axisTwo') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisFour') {
            element.value = 'Moderate'
          }
          if (element.key == 'followUp45_axisFive') {
            element.value = '70'
          }
        })
      }

      if (this.MednoteData.DiagonsisType == 'Bipolar') {
        this.formService.mednoteFollowUpFortyFiveConfig.forEach((element: any, id: any) => {
          if (element.key == 'followUp45_Judgment') {
            element.value = 'Good'
          }
          if (element.key == 'followUp45_Alertness') {
            element.value = 'Inattentive'
          }
          if (element.key == 'followUp45_ChiefComplain') {
            element.value = 'BI-POLAR II'
          }
          if (element.key == 'Bipolar45_axisI') {
            element.value = true
          }
          if (element.key == 'followUp45_statedAge') {
            element.value = true
          }
          if (element.key == 'followUp45_Person') {
            element.value = true
          }
          if (element.key == 'followUp45_Place') {
            element.value = true
          }
          if (element.key == 'followUp45_Purpose') {
            element.value = true
          }
          if (element.key == 'followUp45_Time') {
            element.value = true
          }
          if (element.key == 'followUp45_Irritable') {
            element.value = true
          }
          if (element.key == 'followUp45_Irritable') {
            element.value = true
          }
          if (element.key == 'followUp45_Pressured') {
            element.labeltext = 'Slightly pressured'
            element.value = true
          }
          if (element.key == 'followUp45_CooperativeBehaviour') {
            element.value = true
          }
          if (element.key == 'followUp45_Cooperative') {
            element.value = true
          }
          if (element.key == 'followUp45_PreoccupationWithIllness') {
            element.value = true
          }
          if (element.key == 'followUp45_LogicalandCoherent') {
            element.value = true
          }

          if (element.key == 'followUp45_IntellectualLevel') {
            element.value = 'Average'
          }

          if (element.key == 'followUp45_CognitiveFunction') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp45_AttentationorConcentration') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp45_RecentMemory') {
            element.value = 'Normal'
          }
          if (element.key == 'followUp45_Insight') {
            element.value = 'Good'
          }

          if (element.key == 'followUp45_ImpulseControl') {
            element.value = 'Good'
          }
          if (element.key == 'followUp45_SIorHI') {
            element.value = 'No'
          }

          if (element.key == 'followUp45_Judgment') {
            element.value == 'Fair'
          }
          if (element.key == 'followUp45_axisTwo') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisFour') {
            element.value = 'Moderate'
          }
          if (element.key == 'followUp45_axisFive') {
            element.value = '70'
          }
        })
      }

      if (this.MednoteData.DiagonsisType == 'Schizophrenia') {
        this.formService.mednoteFollowUpFortyFiveConfig.forEach((element: any, id: any) => {
          if (element.key == 'followUp45_ChiefComplain') {
            element.value = 'SCHIZOPHRENIFORM DISORDER'
          }
          if (element.key == 'Schizophrenia45_axisI') {
            element.value = true
          }
          if (element.key == 'followUp45_statedAge') {
            element.value = true
          }
          if (element.key == 'followUp45_Person') {
            element.value = true
          }
          if (element.key == 'followUp45_Place') {
            element.value = true
          }
          if (element.key == 'followUp45_Time') {
            element.value = true
          }
          if (element.key == 'followUp45_Normal') {
            element.value = true
          }
          if (element.key == 'followUp45_Irritable') {
            element.value = true
          }
          if (element.key == 'followUp45_Cooperative') {
            element.value = true
          }
          if (element.key == 'followUp45_DelusionsOnEX') {
            element.value = true
          }
          if (element.key == 'followUp45_GoodEyeContact') {
            element.value = true
          }
          if (element.key == 'followUp45_Flat') {
            element.value = true
          }
          if (element.key == 'followUp45_LogicalandCoherent') {
            element.value = true
          }

          if (element.key == 'followUp45_AttentationorConcentration') {
            element.value = 'Partially impaired'
          }
          if (element.key == 'followUp45_RecentMemory') {
            element.value = 'Normal'
          }

          if (element.key == 'followUp45_ImpulseControl') {
            element.value = 'Fair'
          }
          if (element.key == 'followUp45_SIorHI') {
            element.value = 'No'
          }

          if (element.key == 'followUp45_Judgment') {
            element.value = 'Good'
          }
          if (element.key == 'followUp45_axisTwo') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisFour') {
            element.value = 'Moderate'
          }
          if (element.key == 'followUp45_axisFive') {
            element.value = '70'
          }
        })
      }
      if (this.MednoteData.DiagonsisType == 'OCD') {
        this.formService.mednoteFollowUpFortyFiveConfig.forEach((element: any, id: any) => {
          if (element.key == 'followUp45_ChiefComplain') {
            element.value = 'OCD(Obsessive-compulsive disorder)'
          }
          if (element.key == 'Ocd45_axisI') {
            element.value = true
          }
          if (element.key == 'followUp45_WellGroomed') {
            element.value = true
          }
          if (element.key == 'followUp45_Clean') {
            element.value = true
          }
          if (element.key == 'followUp45_Person') {
            element.value = true
          }
          if (element.key == 'followUp45_Place') {
            element.value = true
          }
          if (element.key == 'followUp45_Time') {
            element.value = true
          }
          if (element.key == 'followUp45_Purpose') {
            element.value = true
          }
          if (element.key == 'followUp45_Normal') {
            element.value = true
          }
          if (element.key == 'followUp45_Friendly') {
            element.value = true
          }
          if (element.key == 'followUp45_Cooperative') {
            element.value = true
          }
          if (element.key == 'followUp45_Full') {
            element.value = true
          }
          if (element.key == 'followUp45_Ambulatory') {
            element.value = true
          }
          if (element.key == 'followUp45_Anxious') {
            element.value = true
          }
          if (element.key == 'followUp45_Appropriate_TP') {
            element.value = true
          }
          if (element.key == 'followUp45_GoalDirectedAndLogical') {
            element.value = true
          }
          if (element.key == 'followUp45_Appropriate_TC') {
            element.value = true
          }
          if (element.key == 'followUp45_ShortTMemory') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp45_LongTMemory') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp45_Insight') {
            element.value = 'Fair'
          }
          if (element.key == 'followUp45_Judgment') {
            element.value = 'Fair'
          }
          if (element.key == 'followUp45_axisTwo') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisFour') {
            element.value = 'Moderate'
          }
          if (element.key == 'followUp45_axisFive') {
            element.value = '70'
          }
        })
      }
      if (this.MednoteData.DiagonsisType == 'Adjustment Disorder') {
        this.formService.mednoteFollowUpFortyFiveConfig.forEach((element: any, id: any) => {
          if (element.key == 'AdjustmentDisorder45_axisI') {
            element.value = true
          }
          if (element.key == 'followUp45_ChiefComplain') {
            element.value = 'ADJUSTMENT DISORDER'
          }
          if (element.key == 'followUp45_WellGroomed') {
            element.value = true
          }
          if (element.key == 'followUp45_Clean') {
            element.value = true
          }
          if (element.key == 'followUp45_Person') {
            element.value = true
          }
          if (element.key == 'followUp45_Place') {
            element.value = true
          }
          if (element.key == 'followUp45_Purpose') {
            element.value = true
          }
          if (element.key == 'followUp45_Time') {
            element.value = true
          }
          if (element.key == 'followUp45_Normal') {
            element.value = true
          }
          if (element.key == 'followUp45_Cooperative') {
            element.value = true
          }
          if (element.key == 'followUp45_Friendly') {
            element.value = true
          }
          if (element.key == 'followUp45_Ambulatory') {
            element.value = true
          }
          if (element.key == 'followUp45_Anxious') {
            element.value = true
          }
          if (element.key == 'followUp45_Full') {
            element.value = true
          }
          if (element.key == 'followUp45_GoalDirectedAndLogical') {
            element.value = true
          }
          if (element.key == 'followUp45_Appropriate_TP') {
            element.value = true
          }
          if (element.key == 'followUp45_Appropriate_TC') {
            element.value = true
          }
          if (element.key == 'followUp45_ShortTMemory') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp45_LongTMemory') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp45_Insight') {
            element.value = 'Fair'
          }
          if (element.key == 'followUp45_Judgment') {
            element.value = 'Fair'
          }
          if (element.key == 'followUp45_axisTwo') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisFour') {
            element.value = 'Moderate'
          }
          if (element.key == 'followUp45_axisFive') {
            element.value = '70'
          }
        })
      }
      if (this.MednoteData.DiagonsisType == 'Oppositional Disorder') {
        this.formService.mednoteFollowUpFortyFiveConfig.forEach((element: any, id: any) => {
          if (element.key == 'OppositionalDisorder45_axisI') {
            element.value = true
          }
          if (element.key == 'followUp45_ChiefComplain') {
            element.value = 'OPPOSITIONAL DISORDER'
          }
          if (element.key == 'followUp45_WellGroomed') {
            element.value = true
          }
          if (element.key == 'followUp45_Clean') {
            element.value = true
          }
          if (element.key == 'followUp45_Person') {
            element.value = true
          }
          if (element.key == 'followUp45_Place') {
            element.value = true
          }
          if (element.key == 'followUp45_Purpose') {
            element.value = true
          }
          if (element.key == 'followUp45_Time') {
            element.value = true
          }
          if (element.key == 'followUp45_Normal') {
            element.value = true
          }
          if (element.key == 'followUp45_Cooperative') {
            element.value = true
          }
          if (element.key == 'followUp45_Friendly') {
            element.value = true
          }
          if (element.key == 'followUp45_Ambulatory') {
            element.value = true
          }
          if (element.key == 'followUp45_Euthymic') {
            element.value = true
          }
          if (element.key == 'followUp45_Anxious') {
            element.value = true
          }
          if (element.key == 'followUp45_Full') {
            element.value = true
          }
          if (element.key == 'followUp45_GoalDirectedAndLogical') {
            element.value = true
          }
          if (element.key == 'followUp45_Appropriate_TP') {
            element.value = true
          }
          if (element.key == 'followUp45_Appropriate_TC') {
            element.value = true
          }
          if (element.key == 'followUp45_ShortTMemory') {
            element.value = 'Impaired'
          }
          if (element.key == 'followUp45_LongTMemory') {
            element.value = 'Impaired'
          }
          if (element.key == 'followUp45_Insight') {
            element.value = 'Fair'
          }
          if (element.key == 'followUp45_Judgment') {
            element.value = 'Fair'
          }
          if (element.key == 'followUp45_axisTwo') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisFour') {
            element.value = 'Moderate'
          }
          if (element.key == 'followUp45_axisFive') {
            element.value = '70'
          }
        })
      }
      if (this.MednoteData.DiagonsisType == 'Autism') {
        this.formService.mednoteFollowUpFortyFiveConfig.forEach((element: any, id: any) => {
          if (element.key == 'followUp45_ChiefComplain') {
            element.value = 'AUTISM'
          }
          if (element.key == 'followUp45_WellGroomed') {
            element.value = true
          }
          if (element.key == 'followUp45_Clean') {
            element.value = true
          }
          if (element.key == 'followUp45_Person') {
            element.value = true
          }
          if (element.key == 'followUp45_Place') {
            element.value = true
          }
          if (element.key == 'followUp45_Purpose') {
            element.value = true
          }
          if (element.key == 'followUp45_Time') {
            element.value = true
          }
          if (element.key == 'followUp45_Disorganized_speech') {
            element.value = true
          }
          if (element.key == 'followUp45_Cooperative') {
            element.value = true
          }
          if (element.key == 'followUp45_Friendly') {
            element.value = true
          }
          if (element.key == 'followUp45_Ambulatory') {
            element.value = true
          }
          if (element.key == 'followUp45_Anxious') {
            element.value = true
          }
          if (element.key == 'followUp45_Full') {
            element.value = true
          }
          if (element.key == 'followUp45_Appropriate_TP') {
            element.value = true
          }
          if (element.key == 'followUp45_Disorganized_TP') {
            element.value = true
          }
          if (element.key == 'followUp45_ShortTMemory') {
            element.value = 'Impaired'
          }
          if (element.key == 'followUp45_LongTMemory') {
            element.value = 'Impaired'
          }
          if (element.key == 'followUp45_Insight') {
            element.value = 'Poor'
          }
          if (element.key == 'followUp45_Judgment') {
            element.value = 'Poor'
          }
          if (element.key == 'Autism45_axisI') {
            element.value = true
          }
          if (element.key == 'followUp45_axisTwo') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisFour') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisFive') {
            element.value = '70'
          }
        })
      }
      if (this.MednoteData.DiagonsisType == 'Eating Disorder') {
        this.formService.mednoteFollowUpFortyFiveConfig.forEach((element: any, id: any) => {
          if (element.key == 'followUp45_ChiefComplain') {
            element.value = 'EATING DISORDER'
          }
          if (element.key == 'followUp45_WellGroomed') {
            element.value = true
          }
          if (element.key == 'followUp45_Clean') {
            element.value = true
          }
          if (element.key == 'followUp45_Person') {
            element.value = true
          }
          if (element.key == 'followUp45_Place') {
            element.value = true
          }
          if (element.key == 'followUp45_Time') {
            element.value = true
          }
          if (element.key == 'followUp45_Purpose') {
            element.value = true
          }
          if (element.key == 'followUp45_Normal') {
            element.value = true
          }
          if (element.key == 'followUp45_Cooperative') {
            element.value = true
          }
          if (element.key == 'followUp45_Friendly') {
            element.value = true
          }
          if (element.key == 'followUp45_Anxious') {
            element.value = true
          }
          if (element.key == 'followUp45_Euthymic') {
            element.value = true
          }
          if (element.key == 'followUp45_Appropriate_TP') {
            element.value = true
          }
          if (element.key == 'followUp45_GoalDirectedAndLogical') {
            element.value = true
          }
          if (element.key == 'followUp45_Appropriate_TC') {
            element.value = true
          }
          if (element.key == 'followUp45_Full') {
            element.value = true
          }
          if (element.key == 'followUp45_Ambulatory') {
            element.value = true
          }
          if (element.key == 'followUp45_ShortTMemory') {
            element.value = 'Impaired'
          }
          if (element.key == 'followUp45_LongTMemory') {
            element.value = 'Impaired'
          }
          if (element.key == 'followUp45_Insight') {
            element.value = 'Fair'
          }
          if (element.key == 'followUp45_Judgment') {
            element.value = 'Fair'
          }
          if (element.key === 'EatingDisorder45_axisI') {
            element.value = true
          }
          if (element.key == 'followUp45_axisTwo') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisFour') {
            element.value = 'Moderate'
          }
          if (element.key == 'followUp45_axisFive') {
            element.value = '70'
          }
        })
      }
    }
    //prefillings for eval
    if (this.MednoteData.noteType === 'Evaluation') {
      let evalDate = this.MednoteData.AppointmentDate.split('-')
      this.formService.mednotesEvalConfig[0].value = `${evalDate[1]}/${evalDate[2]}/${evalDate[0]}` //MM-DD-YYYY format
      this.formService.mednotesEvalConfig[2].value = this.userdata.lastName
      this.formService.mednotesEvalConfig[3].value = this.userdata.firstName
      this.formService.mednotesEvalConfig[5].value = this.userdata.patientBasicInfo.age
      let dob = this.userdata.patientBasicInfo.dob.split('-')
      this.formService.mednotesEvalConfig[6].value = `${dob[1]}/${dob[2]}/${dob[0]}` //MM-DD-YYYY format
      this.formService.mednotesEvalConfig[8].value =
        this.userdata.gender.charAt(0).toUpperCase() + this.userdata.gender.slice(1)
      let insuranceDropDownPosition = this.formService.mednotesEvalConfig.length - 1
      this.formService.mednotesEvalConfig[insuranceDropDownPosition].value = parseInt(
        this.userdata.insuranceDetails['primaryInsuranceProvider']
      )
      //common prefilling for all diagnosis
      this.formService.mednotesEvalConfig.forEach((element: any) => {
        if (element.key === 'Diagnosis') {
          element.editable = false
          element.value = this.MednoteData.DiagonsisType
        }
        if (element.key == 'heightFt') {
          element.value = height.length !== 0 ? height[0] : ''
        }
        if (element.key == 'locName') {
          element.value = 'NEW STRESS CLINIC'
        }
        if (element.key == 'heightInch') {
          element.value = height.length !== 0 ? height[1] : ''
        }
        if (element.key == 'weight') {
          element.value = this.userdata.patientBasicInfo.weight ? this.userdata.patientBasicInfo.weight : ''
        }
        if (element.key == 'employer') {
          element.value = this.userdata.insuranceDetails.policyHolder.policyHolderEmployer
        }
        if (element.key == 'DoctorNote') {
          let elementToRemove = " Dear Dr. Prayaga's Office,"
          this.doctorNotes = this.doctorNotes.filter(item => item !== elementToRemove)
          let notes = this.doctorNotes.join('\n ')
          element.value = notes
        }
        if (element.key == 'annualPsychiatricEvaluation') {
          element.value = this.MednoteData.EvalType == 're' ? false : true
        }
        if (element.key === 'allergies') {
          const allergies =
            this.appointMentdata !== null
              ? [
                  this.userdata.medicalHistory.knownAllergies,
                  this.userdata.medicalHistory.medicalKnownAllergies,
                  this.appointMentdata.healthDetails.outburst_allergies_description,
                ]
              : [this.userdata.medicalHistory.knownAllergies, this.userdata.medicalHistory.medicalKnownAllergies]
          const filteredAllergies = allergies.filter(allergy => !!allergy)
          element.value = filteredAllergies.length > 0 ? filteredAllergies.join('\n') : 'None'
        }
        if (element.key == 'mednote_Code') {
          if (this.userdata.patientBasicInfo.age <= 18) {
            element.value = this.MednoteData.MednoteCode + '( ' + this.MednoteData.DiagonsisType + ' Under 18 )'
          } else {
            element.value = this.MednoteData.MednoteCode + ' (' + this.MednoteData.DiagonsisType + ')'
          }
        }
        if (element.key == 'psychiatricEvaluation') {
          //intial psychiatric Evaluation
          element.value = this.MednoteData.EvalType == 're' ? true : false
        }
        if (element.key === 'familyInterview') {
          if (this.userdata.patientBasicInfo.age <= 18) {
            element.value = true
          }
        }
        if (element.key == 'pastMedical') {
          let medical = []
          if (this.userdata.medicalHistory.anyMedicalDisorders === true) {
            medical[0] = this.userdata.medicalHistory.anyMedicalDisordersExplain
          }
          if (medical.length !== 0) {
            element.value = medical.join('\n')
          } else {
            element.value = 'None'
          }
        }

        if (element.key === 'medications') {
          let medicationTypes = [
            { key: 'benzodiazepines', label: 'Benzodiazepines' },
            { key: 'medicalAdderallStimulants', label: 'Medical Adderall Stimulants' },
            { key: 'psychiatric', label: 'Psychiatric' },
          ]
          let intakeMeds = []
          medicationTypes.forEach((medType, index) => {
            if (this.userdata.medicalHistory[medType.key].flag === true) {
              const meds = this.userdata.medicalHistory[medType.key].medications.map(med => {
                return `Medicine: ${med.medicine}, Dosage: ${med.dosage}`
              })
              intakeMeds[index] = meds.join('\n')
            }
          })
          if (this.userdata.medicalHistory.nonPsychMeds === true) {
            intakeMeds[3] = this.userdata.medicalHistory.nonPsychMedsExplain
          }
          const medicationsValue = intakeMeds.filter(Boolean) // Remove any empty or undefined entries
          element.value = medicationsValue.length > 0 ? medicationsValue.join('\n') : 'None'
        }

        if (element.key === 'pastSurg') {
          let Surg = []
          if (this.userdata.medicalHistory.patientHospitalized === true) {
            Surg.push(
              `Patient Hospitalized Date - ${this.userdata.medicalHistory.patientHospitalizedDate}, Patient Hospitalized Reason - ${this.userdata.medicalHistory.patientHospitalizedReason}`
            )
          }
          if (this.appointMentdata !== null && this.appointMentdata.medicationConsent.hospitalizedPastMonth === true) {
            Surg.push(
              `Hospital Name: ${this.appointMentdata.medicationConsent.hospitalName}, Hospitalized Reason: ${this.appointMentdata.medicationConsent.hospitalizedReason}`
            )
          }
          element.value = Surg.length > 0 ? Surg.join('\n') : 'None'
        }

        if (element.key === 'substanceAbuse') {
          let medical = []
          if (this.userdata.medicalHistory.anyOpiods === true) {
            medical.push(this.userdata.medicalHistory.anyOpiodsExplain)
          }
          if (this.userdata.medicalHistory.drugAlcoholAbuse === true) {
            medical.push('Have done alcohol or drugs')
          }
          if (this.userdata.medicalHistory.pastHistoryofAlchohol === true) {
            medical.push(this.userdata.medicalHistory.pastHistoryofAlchoholExplain)
          }
          element.value = medical.length > 0 ? medical.join('\n') : 'None'
        }

        if (element.key === 'legal') {
          let legal = []
          if (this.userdata.patientMiscInfo.legalIssues === true) {
            legal.push(this.userdata.patientMiscInfo.miscKnownAllergies1)
          }
          if (this.appointMentdata !== null && this.appointMentdata.healthDetails.legal_issue === true) {
            legal.push(this.appointMentdata.healthDetails.legal_issue_description)
          }
          element.value = legal.length > 0 ? legal.join('\n') : 'None'
        }
        if (element.key === 'alertness_obs') {
          element.value = 'Alert and oriented to person, place, time, and purpose'
        }
        if (element.key === 'ambulatory_obs') {
          element.value = 'Ambulatory, Appears In No Acute Distress.'
        }
        if (element.key === 'socalEmployed') {
          element.value = this.userdata.insuranceDetails.policyHolder.policyHolderEmployer
        }
      })
      //prefilling diagnosis wise
      if (this.MednoteData.DiagonsisType === 'ADHD') {
        this.formService.mednotesEvalConfig.forEach((element: any) => {
          if (element.key == 'chiefComplain') {
            if (this.userdata.patientBasicInfo.age <= 18) {
              element.value =
                'Client is having difficulty with concentration, low energy and motivation. Client struggles with completing tasks and assignments due to his low energy and motivation levels. Stress is dominating all aspects in clients life. Family reports client has difficulties in school and I reviewed information available from family and other sources'
            } else {
              element.value =
                'Client is having difficulty with concentration, low energy and motivation. Client struggles with completing tasks and assignments due to his low energy and motivation levels. The stress is dominating all aspects in his life. Client is interested in trying medication to address these symptoms and to improve his quality of life.'
            }
          }
          if (element.key == 'purposeOfVisit') {
            element.value =
              'This is a ' +
              this.userdata.patientBasicInfo.age +
              '-year-old ' +
              this.userdata.gender +
              ' who presents as a patient for a psychiatric evaluation. The Client presents with symptoms of low focus, lack of concentration, procrastination, and distraction issues. '
          }
          //obs
          if (element.key === 'mood_obs') {
            element.value = `Mood: Normal, Affect – Congruent to mod. No EPS.`
          }
          if (element.key === 'addObservations') {
            if (this.userdata.patientBasicInfo.age <= 18) {
              element.value =
                'Client has been experiencing these symptoms from young age. However, lately the studies has created more difficulties completing his/her assignments in a timely manner. His/ her school is very busy and has to spend extra amounts of time completing tasks. His/Her sleep appetite is erratic.  '
            } else {
              element.value =
                'The client has been experiencing these symptoms from a young age. However, lately the work / studies have created more difficulties completing his/her assignments in a timely manner. His/ her job/ school is very busy and has to spend extra amounts of time completing tasks. Client was requesting an assessment and whether he/ she might benefit from treatment for ADHD. Client denies any acute symptoms of depression or psychosis. His/Her sleep and appetite are erratic.'
            }
          }
          //symptoms
          if (element.key === 'sleep_DS') {
            element.value = true
          }
          if (element.key === 'decreasedConcentration_DS') {
            element.value = true
          }
          if (element.key === 'decreasedMemory_DS') {
            element.value = true
          }
          if (element.key === 'none_MS') {
            element.value = true
          }
          if (element.key === 'none_PS') {
            element.value = true
          }
          if (element.key === 'none_PTSD') {
            element.value = true
          }
          if (element.key === 'poorImpulseControl_PTSD') {
            element.value = true
          }
          if (element.key === 'muscleTension_AS') {
            element.value = true
          }
          if (element.key === 'failureToPayCloseAttention_ADS') {
            element.value = true
          }
          if (element.key === 'difficultySustainingAttentionInTasks_ADS') {
            element.value = true
          }
          if (element.key === 'fidgets_ADS') {
            element.value = true
          }
          if (element.key === 'forgetfulOfDailyActivities_ADS') {
            element.value = true
          }
          if (element.key === 'procrastination_ADS') {
            element.value = true
          }
          if (element.key === 'easilyDistracted_ADS') {
            element.value = true
          }
          if (element.key === 'makesCarelessMistakesOften_ADS') {
            element.value = true
          }
          //mental status exam
          if (element.key === 'wellGroomed_Appearance') {
            element.value = true
          }
          if (element.key === 'Neat_Appearance') {
            element.value = true
          }
          if (element.key === 'person_Orientation') {
            element.value = true
          }
          if (element.key === 'place_Orientation') {
            element.value = true
          }
          if (element.key === 'time_Orientation') {
            element.value = true
          }
          if (element.key === 'purpose_Orientation') {
            element.value = true
          }
          if (element.key === 'normal_Speech') {
            element.value = true
          }
          if (element.key === 'cooperative_Attitude') {
            element.value = true
          }
          if (element.key === 'friendly_Attitude') {
            element.value = true
          }
          if (element.key === 'ambulatory_PA') {
            element.value = true
          }
          if (element.key === 'anxious_Mood') {
            element.value = true
          }
          if (element.key === 'full_Affect') {
            element.value = true
          }
          if (element.key === 'goalDirectedAndLogical_TP') {
            element.value = true
          }
          if (element.key === 'appropriate_TP') {
            element.value = true
          }
          if (element.key === 'appropriate_TC') {
            element.value = true
          }
          if (element.key === 'ShortTerm') {
            element.value = 'Impaired'
          }
          if (element.key === 'LongTerm') {
            element.value = 'Impaired'
          }
          if (element.key === 'Insight') {
            element.value = 'Fair'
          }
          if (element.key === 'Judgment') {
            element.value = 'Fair'
          }
          if (element.key === 'ADHD_axisI') {
            element.value = true
          }
          if (element.key === 'plan') {
            element.value =
              'Encouraged to take medication as prescribed and eat a healthy diet. Client is also encouraged to seek holistic approaches like walking, running, and meditating. Medication benefits and potential side effects have been discussed with patient. \n I explained the algorithm of these medicines for getting the optimal response with these medicines'
          }
          if (element.key === 'axisTwo') {
            element.value = 'None'
          }
          if (element.key === 'axisThree') {
            element.value = 'None'
          }
          if(element.key === 'axisFour'){
            if (this.MednoteData.EvalType == 're') {
              element.value = 'Moderate'
            }
            if(this.userdata.patientBasicInfo.age <= 18){
              element.value='None'
            }else{
              element.value='Moderate'
            }
          }
          if (element.key === 'axisFive') {
            element.value = '70'
          }

        
          // if(element.key === "diagnosisMedications"){element.value = ""}
        })
      }

      if (this.MednoteData.DiagonsisType === 'Anxiety') {
        this.formService.mednotesEvalConfig.forEach((element: any) => {
          if (element.key === 'chiefComplain') {
            element.value =
              this.userdata.firstName +
              ' is struggling with low mood, low energy, constant worrying, unable to relax and low motivation in addition to the inability to concentrate. Client mood is noticeably anxious and the client reports feeling fatigue. Client has intense anxiety worries on trivial things and client dwells on issues/situations a lot. The anxiety has become overwhelming on a frequent basis. Client sleep and appetite are erratic.'
          }
          if (element.key === 'purposeOfVisit') {
            element.value =
              'This is a ' +
              this.userdata.patientBasicInfo.age +
              ' yr old ' +
              this.userdata.gender +
              ' who presents as a patient for psychiatric evaluation. Client is dealing with a lot of symptoms of Anxiety and stress. Client is feeling at times hopeless, helpless and experiencing low motivation and lack of interest in things etc. Client has denied any suicidal thoughts or plans. Client is also unable to relax and worries.'
          }
          if (element.key === 'mood_obs') {
            element.value = ` Mood: Anxious, Affect - Congruent to mood. No EPS. `
          }
          // symptoms
          if (element.key === 'sleep_DS') {
            element.value = true
          }
          if (element.key === 'depressedMood_DS') {
            element.value = true
          }
          if (element.key === 'decreasedConcentration_DS') {
            element.value = true
          }
          if (element.key === 'decreasedMemory_DS') {
            element.value = true
          }
          if (element.key === 'none_MS') {
            element.value = true
          }
          if (element.key === 'none_PS') {
            element.value = true
          }
          if (element.key === 'poorImpulseControl_PTSD') {
            element.value = true
          }
          if (element.key === 'sweating_AS') {
            element.value = true
          }
          if (element.key === 'excessiveWorrying_AS') {
            element.value = true
          }
          if (element.key === 'chestPain_AS') {
            element.value = true
          }
          if (element.key === 'failureToPayCloseAttention_ADS') {
            element.value = true
          }
          if (element.key === 'makesCarelessMistakesOften_ADS') {
            element.value = true
          }
          if (element.key === 'easilyDistracted_ADS') {
            element.value = true
          }
          if (element.key === 'difficultySustainingAttentionInTasks_ADS') {
            element.value = true
          }
          if (element.key === 'fidgets_ADS') {
            element.value = true
          }
          if (element.key === 'forgetfulOfDailyActivities_ADS') {
            element.value = true
          }
          if (element.key === 'procrastination_ADS') {
            element.value = true
          }
          //mental status exam
          if (element.key === 'wellGroomed_Appearance') {
            element.value = true
          }
          if (element.key === 'Neat_Appearance') {
            element.value = true
          }
          if (element.key === 'person_Orientation') {
            element.value = true
          }
          if (element.key === 'place_Orientation') {
            element.value = true
          }
          if (element.key === 'time_Orientation') {
            element.value = true
          }
          if (element.key === 'purpose_Orientation') {
            element.value = true
          }
          if (element.key === 'normal_Speech') {
            element.value = true
          }
          if (element.key === 'cooperative_Attitude') {
            element.value = true
          }
          if (element.key === 'friendly_Attitude') {
            element.value = true
          }
          if (element.key === 'ambulatory_PA') {
            element.value = true
          }
          if (element.key === 'anxious_Mood') {
            element.value = true
          }
          if (element.key === 'full_Affect') {
            element.value = true
          }
          if (element.key === 'goalDirectedAndLogical_TP') {
            element.value = true
          }
          if (element.key === 'appropriate_TC') {
            element.value = true
          }
          if (element.key === 'appropriate_TP') {
            element.value = true
          }
          if (element.key === 'ShortTerm') {
            element.value = 'Intact'
          }
          if (element.key === 'LongTerm') {
            element.value = 'Intact'
          }
          if (element.key === 'Insight') {
            element.value = 'Fair'
          }
          if (element.key === 'Judgment') {
            element.value = 'Fair'
          }
          if (element.key === 'Anxiety_axisI') {
            element.value = true
          }
          if (element.key === 'plan') {
            element.value =
              'Client is recommended for medication management and counseling.Client was encouraged to see a therapist and explained the benefits of meditation, breathing techniques to reduce Anxiety symptoms. I explained the algorithm of medicines for getting the optimal response.'
          }
          if (element.key === 'axisTwo') {
            element.value = 'None'
          }
          if (element.key === 'axisThree') {
            element.value = 'None'
          }
          if(element.key === 'axisFour'){
              element.value='None'
          }
          if (element.key === 'axisFive') {
            element.value = '70'
          }

          // if(element.key === 'diagnosisMedications'){ element.value = ' '}
        })
      }

      if (this.MednoteData.DiagonsisType === 'Depression') {
        this.formService.mednotesEvalConfig.forEach((element: any) => {
          if (element.key === 'chiefComplain') {
            if (this.userdata.patientBasicInfo.age <= 18) {
              element.value =
                "Client has been having difficulty with mood. Client cannot concentrate. Client cannot get motivated. Don't have energy. Client feeling stressed and worry a lot. Stress is taking over. Family reports clients have difficulties in school and I reviewed information available from family and other sources."
            } else {
              element.value = `Client is been having difficulty with mood. Client cannot concentrate. Client cannot get motivated. Don't have energy. Client feeling stressed and worry a lot. Stress is taking over. `
            }
          }
          if (element.key === 'purposeOfVisit') {
            if (this.userdata.patientBasicInfo.age <= 18) {
              element.value =
                'This is a ' +
                this.userdata.patientBasicInfo.age +
                ' yr old ' +
                this.userdata.gender +
                ' who presents as a patient for psychiatric evaluation. Client is dealing with a lot of symptoms of depression. Client is feeling at times hopeless, helpless, experiencing low motivation and lack of interest in things etc. Client has denied any suicidal thoughts or plans. Clients came with caregivers. Client reports depressive symptoms for a while. Client has been thinking negatively and has anhedonia.              '
            } else {
              element.value =
                'This is a ' +
                this.userdata.patientBasicInfo.age +
                ' yr old ' +
                this.userdata.gender +
                ' who presents as a patient for psychiatric evaluation. Client is dealing with a lot of symptoms of depression. Client is feeling at times hopeless, helpless and experiencing low motivation and lack of interest in things etc. Client has denied any suicidal thoughts or plans. Client sleep and appetite are erratic.'
            }
          }
          if (element.key === 'mood_obs') {
            element.value = 'Mood: Sad, Affect - Congruent to mod. No EPS.'
          }

          if (element.key === 'addObservations') {
            if (this.userdata.patientBasicInfo.age >= 18) {
              element.value =
                'Client reports depressive symptoms for a while. Client has been thinking negatively and has anhedonia.'
            }
          }
          if (element.key === 'diagnosticAssessment') {
            if (this.MednoteData.EvalType == 're') {
              element.value = true
            }
          } // according to reeval dep template it was checked
          // symptoms
          if (element.key === 'sleep_DS') {
            element.value = true
          }
          if (element.key === 'depressedMood_DS') {
            element.value = true
          }
          if (element.key === 'decreasedConcentration_DS') {
            element.value = true
          }
          if (element.key === 'decreasedMemory_DS') {
            element.value = true
          }
          if (element.key === 'distractibility_MS') {
            element.value = true
          }
          if (element.key === 'none_PS') {
            element.value = true
          }
          if (element.key === 'none_PTSD') {
            element.value = true
          }
          if (element.key === 'poorImpulseControl_PTSD') {
            element.value = true
          }
          // if(element.key === "sweating_AS"){ element.value = true }
          if (element.key === 'excessiveWorrying_AS') {
            element.value = true
          }
          // if(element.key === "chestPain_AS"){ element.value = true }
          if (element.key === 'failureToPayCloseAttention_ADS') {
            element.value = true
          }
          if (element.key === 'makesCarelessMistakesOften_ADS') {
            element.value = true
          }
          if (element.key === 'easilyDistracted_ADS') {
            element.value = true
          }
          if (element.key === 'difficultySustainingAttentionInTasks_ADS') {
            element.value = true
          }
          if (element.key === 'fidgets_ADS') {
            element.value = true
          }
          if (element.key === 'forgetfulOfDailyActivities_ADS') {
            element.value = true
          }
          if (element.key === 'procrastination_ADS') {
            element.value = true
          }

          //mental status exam
          if (element.key === 'wellGroomed_Appearance') {
            element.value = true
          }
          if (element.key === 'Neat_Appearance') {
            element.value = true
          }
          if (element.key === 'person_Orientation') {
            element.value = true
            element.labeltext = 'Normal Person'
          }
          if (element.key === 'place_Orientation') {
            element.value = true
          }
          if (element.key === 'time_Orientation') {
            element.value = true
          }
          if (element.key === 'purpose_Orientation') {
            element.value = true
          }
          if (element.key === 'normal_Speech') {
            element.value = true
            element.labeltext = 'Normal/ Slow '
          }
          if (element.key === 'cooperative_Attitude') {
            element.value = true
          }
          if (element.key === 'friendly_Attitude') {
            element.value = true
          }
          if (element.key === 'ambulatory_PA') {
            element.value = true
          }
          // if(element.key === "wheelChair_PA"){ element.value = true }
          if (element.key === 'depressed_Mood') {
            element.value = true
          }
          if (element.key === 'flat_Affect') {
            element.value = true
          }
          if (element.key === 'goalDirectedAndLogical_TP') {
            element.value = true
          }
          if (element.key === 'appropriate_TP') {
            element.value = true
          }
          if (element.key === 'disorganized_TP') {
            element.value = true
          }
          if (element.key === 'illness_TC') {
            element.value = true
          }
          if (element.key === 'ShortTerm') {
            element.value = 'Impaired'
          }
          if (element.key === 'LongTerm') {
            element.value = 'Intact'
          }
          if (element.key === 'Insight') {
            element.value = 'Fair'
          }
          if (element.key === 'Judgment') {
            element.value = 'Fair'
          }
          if (element.key === 'Depression_axisI') {
            element.value = true
          }
          if (element.key === 'axisTwo') {
            element.value = 'None'
          }
          if (element.key === 'axisThree') {
            if (this.MednoteData.EvalType == 're') {
              element.value = 'High blood pressure'
            }else{
              element.value = 'None'
            }
            
          }
          if(element.key === 'axisFour'){
            if (this.MednoteData.EvalType == 're') {
              element.value = 'Moderate'
            }else{
              element.value = 'None'
            }
          }
          if (element.key === 'axisFive') {
            if (this.MednoteData.EvalType == 're') {
              element.value = '60'
            }
            else{
              element.value = '70'
            }
          }
          // if (element.key === 'Depression_axisI_I') {
          //   element.value = true
          // }
          if (element.key === 'plan') {
            element.value =
              'Client is recommended for medication management, counseling, and careful consideration of treatments like TMS and Ketamine. Encouraged to take medication as prescribed and eat a healthy diet. Client was encouraged to take medications to assist him. Client is also encouraged to seek holistic approaches like meditating, walking and exercise. Medication benefits and potential side effects have been discussed with patient. \nI explained the algorithm medicines for getting the optimal response with these medicines.'
          }
        
          // if(element.key === "diagnosisMedications"){element.value = ""}
        })
      }

      if (this.MednoteData.DiagonsisType === 'Bipolar') {
        this.formService.mednotesEvalConfig.forEach((element: any) => {
          if (element.key === 'chiefComplain') {
            element.value = `Client is struggling with low mood, irritability, low energy, and low motivation in addition to focus and concentration. Client mood is noticeably going through upheavals and client reports feeling fatigue. Client has intense stress, and client dwells on issues/situations a lot. The stress has become overwhelming for the client. The client has issues with sleep on a regular basis. Client also has mood swings daily. `
          }

          if (element.key === 'purposeOfVisit') {
            element.value =
              'This is a ' +
              this.userdata.patientBasicInfo.age +
              ' yr old ' +
              this.userdata.gender +
              ' who presents as a patient for psychiatric evaluation. Client is dealing with a lot of symptoms of mood swings, irritability and Depression. Client has denied any suicidal thoughts or plans.'
          }

          if (element.key === 'mood_obs') {
            element.value = 'Mood: Irritable, Affect - Congruent to mod. No EPS.  '
          }

          // symptoms
          if (element.key === 'sleep_DS') {
            element.value = true
          }
          if (element.key === 'depressedMood_DS') {
            element.value = true
          }
          if (element.key === 'decreasedConcentration_DS') {
            element.value = true
          }
          if (element.key === 'decreasedMemory_DS') {
            element.value = true
          }
          if (element.key === 'flightOfIdeas_MS') {
            element.value = true
          }
          if (element.key === 'needOfSleep_MS') {
            element.value = true
          }
          if (element.key === 'selfEsteem_MS') {
            element.value = true
          }
          if (element.key === 'distractibility_MS') {
            element.value = true
          }
          if (element.key === 'elevatedMood_MS') {
            element.value = true
          }
          if (element.key === 'irritableMood_MS') {
            element.value = true
          }
          if (element.key === 'none_PS') {
            element.value = true
          }
          if (element.key === 'none_PTSD') {
            element.value = true
          }
          if (element.key === 'poorImpulseControl_PTSD') {
            element.value = true
          }
          if (element.key === 'muscleTension_AS') {
            element.value = true
          }
          if (element.key === 'failureToPayCloseAttention_ADS') {
            element.value = true
          }
          if (element.key === 'makesCarelessMistakesOften_ADS') {
            element.value = true
          }
          if (element.key === 'easilyDistracted_ADS') {
            element.value = true
          }
          if (element.key === 'difficultySustainingAttentionInTasks_ADS') {
            element.value = true
          }
          if (element.key === 'fidgets_ADS') {
            element.value = true
          }
          if (element.key === 'forgetfulOfDailyActivities_ADS') {
            element.value = true
          }
          if (element.key === 'procrastination_ADS') {
            element.value = true
          }

          //mental status exam
          if (element.key === 'wellGroomed_Appearance') {
            element.value = true
          }
          if (element.key === 'Neat_Appearance') {
            element.value = true
          }
          if (element.key === 'person_Orientation') {
            element.value = true
          }
          if (element.key === 'place_Orientation') {
            element.value = true
          }
          if (element.key === 'time_Orientation') {
            element.value = true
          }
          if (element.key === 'purpose_Orientation') {
            element.value = true
          }
          if (element.key === 'pressured_Speech') {
            element.value = true
          }
          if (element.key === 'cooperative_Attitude') {
            element.value = true
          }
          if (element.key === 'friendly_Attitude') {
            element.value = true
          }
          if (element.key === 'ambulatory_PA') {
            element.value = true
          }
          if (element.key === 'dysphoric_Mood') {
            element.value = true
          }
          if (element.key === 'full_Affect') {
            element.value = true
          }
          if (element.key === 'goalDirectedAndLogical_TP') {
            element.value = true
          }
          if (element.key === 'appropriate_TP') {
            element.value = true
          }
          if (element.key === 'tangential_TP') {
            element.value = true
          }
          if (element.key === 'illness_TC') {
            element.value = true
          }
          if (element.key === 'ShortTerm') {
            element.value = 'Impaired'
          }
          if (element.key === 'LongTerm') {
            element.value = 'Intact'
          }
          if (element.key === 'Insight') {
            element.value = 'Fair to Poor'
          }
          if (element.key === 'Judgment') {
            element.value = 'Fair to Poor'
          }
          if (element.key === 'Bipolar_axisI') {
            element.value = true
          }
          if (element.key === 'axisTwo') {
            element.value = 'None'
          }
          if (element.key === 'axisThree') {
            element.value = 'None'
          }
          if(element.key === 'axisFour'){
            element.value = 'None'
          }
          if (element.key === 'axisFive') {
            element.value = '70'
          }
          if (element.key === 'plan') {
            element.value =
              'Client is recommended for medication management and counseling. Client is recommended to take medication on time and eat a healthy diet. Client is encouraged to continue take medications to assist. Medication benefits and potential side effects have been explained to the patient. I explained the algorithm medicines for getting the optimal response with these medicines.'
          }
          // if(element.key === "diagnosisMedications"){element.value = "  "}
        })
      }

      if (this.MednoteData.DiagonsisType === 'Schizophrenia') {
        this.formService.mednotesEvalConfig.forEach((element: any) => {
          // if(element.key === 'diagnosticAssessment'){ element.value = true}
          if (element.key === 'chiefComplain') {
            element.value = `Client has mood irritability, restless and paranoid ideations and delusions. `
          }

          if (element.key === 'purposeOfVisit') {
            element.value =
              'This is a ' +
              this.userdata.patientBasicInfo.age +
              ' yr old ' +
              this.userdata.gender +
              ' who presents as a patient for a psychiatric evaluation. Client came with his/her family. Client has a history of schizophrenia and is in treatment with medications.  Client has a history of paranoid delusions, mood swings, insomnia, and hallucinations. '
          }

          if (element.key === 'mood_obs') {
            element.value = 'Mood: Normal, Affect - Congruent to mod. No EPS.  '
          }

          if (element.key === 'addObservations') {
            element.value = `Client's sleep appetite is erratic. Client have support from family and social agencies. Client needs further intensive treatment including medications.`
          }

          //symptoms
          if (element.key === 'sleep_DS') {
            element.value = true
          }
          if (element.key === 'decreasedConcentration_DS') {
            element.value = true
          }
          if (element.key === 'decreasedMemory_DS') {
            element.value = true
          }
          if (element.key === 'none_DS') {
            element.value = true
          }
          if (element.key === 'depressedMood_DS') {
            element.value = true
          }
          if (element.key === 'none_MS') {
            element.value = true
          }
          if (element.key === 'delusionsBizarre_PS') {
            element.value = true
          }
          if (element.key === 'hallucinations_PS') {
            element.value = true
          }
          if (element.key === 'disorganizedBehavior_PS') {
            element.value = true
          }
          if (element.key === 'sixMonths_PS') {
            element.value = true
          }
          if (element.key === 'none_PTSD') {
            element.value = true
          }
          if (element.key === 'poorImpulseControl_PTSD') {
            element.value = true
          }
          if (element.key === 'muscleTension_AS') {
            element.value = true
          }
          if (element.key === 'failureToPayCloseAttention_ADS') {
            element.value = true
          }
          if (element.key === 'difficultySustainingAttentionInTasks_ADS') {
            element.value = true
          }
          if (element.key === 'forgetfulOfDailyActivities_ADS') {
            element.value = true
          }
          if (element.key === 'procrastination_ADS') {
            element.value = true
          }
          if (element.key === 'makesCarelessMistakesOften_ADS') {
            element.value = true
          }
          if (element.key === 'easilyDistracted_ADS') {
            element.value = true
          }

          //mental status exam
          if (element.key === 'wellGroomed_Appearance') {
            element.value = true
          }
          if (element.key === 'Neat_Appearance') {
            element.value = true
          }
          if (element.key === 'person_Orientation') {
            element.value = true
          }
          if (element.key === 'place_Orientation') {
            element.value = true
          }
          if (element.key === 'time_Orientation') {
            element.value = true
          }
          if (element.key === 'purpose_Orientation') {
            element.value = true
          }
          if (element.key === 'normal_Speech') {
            element.value = true
          }
          if (element.key === 'guarded_Attitude') {
            element.value = true
          }
          if (element.key === 'friendly_Attitude') {
            element.value = true
          }
          if (element.key === 'ambulatory_PA') {
            element.value = true
          }
          if (element.key === 'anxious_Mood') {
            element.value = true
          }
          if (element.key === 'irritable_Mood') {
            element.value = true
          }
          if (element.key === 'flat_Affect') {
            element.value = true
          }
          if (element.key === 'tangential_TP') {
            element.value = true
          }
          if (element.key === 'persecutoryDelusions_TC') {
            element.value = true
          }
          if (element.key === 'hallucination_TC') {
            element.value = true
          }
          if (element.key === 'ShortTerm') {
            element.value = 'Intact'
          }
          if (element.key === 'LongTerm') {
            element.value = 'Intact'
          }
          if (element.key === 'Insight') {
            element.value = 'Fair to Poor'
          }
          if (element.key === 'Judgment') {
            element.value = 'Fair to Poor'
          }

          if (element.key === 'Schizophrenia_axisI') {
            element.value = true
          }
          if (element.key === 'axisTwo') {
            element.value = 'None'
          }
          if (element.key === 'axisThree') {
            element.value = 'None'
          }
          if(element.key === 'axisFour'){
            element.value = 'None'
          }
          if (element.key === 'axisFive') {
            element.value = '65'
          }
          if (element.key === 'plan') {
            element.value =
              'Encouraged to take medication as prescribed and eat a healthy diet. Client was encouraged to continue taking the medications to assist him. Medication benefits and potential side effects have been discussed with patient. \n I explained the algorithm of these medicines for getting the maximal response with these medicines.'
          }
         
        })
      }

      if (this.MednoteData.DiagonsisType === 'Adjustment Disorder') {
        this.formService.mednotesEvalConfig.forEach((element: any) => {
          if (element.key === 'chiefComplain') {
            element.value = `Client has been having difficulty with mood. Client cannot concentrate. The Client cannot get motivated. Don’t have energy. The Client is feeling stressed and worrying a lot. Stress is taking over.  `
          }

          if (element.key === 'purposeOfVisit') {
            element.value =
              'This is a ' +
              this.userdata.patientBasicInfo.age +
              ' yr old ' +
              this.userdata.gender +
              ' who presents as a patient for a psychiatric evaluation. \nClient is dealing with a lot of symptoms of depression due to the current incident (symptoms where precipitated from it). Client is feeling hopeless, helpless and experiencing low motivation and lack of interest in things etc which Client used to be interested in before. Client has denied any suicidal thoughts or plans. Client sleep and appetite are erratic. '
          }

          if (element.key === 'mood_obs') {
            element.value = 'Mood: Sad, Affect - Congruent to mod. No EPS.  '
          }

          // symptoms
          if (element.key === 'sleep_DS') {
            element.value = true
          }
          if (element.key === 'depressedMood_DS') {
            element.value = true
          }
          if (element.key === 'decreasedConcentration_DS') {
            element.value = true
          }
          if (element.key === 'decreasedMemory_DS') {
            element.value = true
          }
          if (element.key === 'distractibility_MS') {
            element.value = true
          }
          if (element.key === 'none_PS') {
            element.value = true
          }
          if (element.key === 'none_PTSD') {
            element.value = true
          }
          if (element.key === 'poorImpulseControl_PTSD') {
            element.value = true
          }
          if (element.key === 'excessiveWorrying_AS') {
            element.value = true
          }
          if (element.key === 'failureToPayCloseAttention_ADS') {
            element.value = true
          }
          if (element.key === 'makesCarelessMistakesOften_ADS') {
            element.value = true
          }
          if (element.key === 'easilyDistracted_ADS') {
            element.value = true
          }
          if (element.key === 'difficultySustainingAttentionInTasks_ADS') {
            element.value = true
          }
          if (element.key === 'fidgets_ADS') {
            element.value = true
          }
          if (element.key === 'forgetfulOfDailyActivities_ADS') {
            element.value = true
          }
          if (element.key === 'procrastination_ADS') {
            element.value = true
          }

          //mental status exam
          if (element.key === 'wellGroomed_Appearance') {
            element.value = true
          }
          if (element.key === 'Neat_Appearance') {
            element.value = true
          }
          if (element.key === 'person_Orientation') {
            element.value = true
            element.labeltext = 'Normal Person'
          }
          if (element.key === 'place_Orientation') {
            element.value = true
          }
          if (element.key === 'time_Orientation') {
            element.value = true
          }
          if (element.key === 'purpose_Orientation') {
            element.value = true
          }
          if (element.key === 'normal_Speech') {
            element.value = true
            element.labeltext = 'Normal/ Slow '
          }
          if (element.key === 'cooperative_Attitude') {
            element.value = true
          }
          if (element.key === 'friendly_Attitude') {
            element.value = true
          }
          if (element.key === 'ambulatory_PA') {
            element.value = true
          }
          // if(element.key === "euthymic_Mood"){ element.value = true }
          if (element.key === 'flat_Affect') {
            element.value = true
          }
          if (element.key === 'goalDirectedAndLogical_TP') {
            element.value = true
          }
          if (element.key === 'appropriate_TP') {
            element.value = true
          }
          if (element.key === 'illness_TC') {
            element.value = true
          }
          if (element.key === 'ShortTerm') {
            element.value = 'Impaired'
          }
          if (element.key === 'LongTerm') {
            element.value = 'Intact'
          }
          if (element.key === 'Insight') {
            element.value = 'Fair'
          }
          if (element.key === 'Judgment') {
            element.value = 'Fair'
          }

          if (element.key === 'AdjustmentDisorder_axisI') {
            element.value = true
          }
          if (element.key === 'axisTwo') {
            element.value = 'None'
          }
          if (element.key === 'axisThree') {
            element.value = 'None'
          }
          if(element.key === 'axisFour'){
            element.value = 'None'
          }
          if (element.key === 'axisFive') {
            element.value = '70'
          }

          if (element.key === 'plan') {
            element.value =
              'Client is recommended for careful consideration of medications and counseling. Client is also encouraged to seek holistic approaches like meditating, walking and excercise. Medication benefits and potential side effects have been discussed with patient.'
          }
          // if(element.key === "diagnosisMedications"){element.value = " "}
        })
      }

      if (this.MednoteData.DiagonsisType === 'Oppositional Disorder') {
        this.formService.mednotesEvalConfig.forEach((element: any) => {
          if (element.key === 'chiefComplain') {
            element.value =
              this.userdata.firstName +
              ` is having difficulty with defiant, oppositional and argumentative and concentration problems. Client is struggling with completing tasks and assignments in spite of repeated reminders from parents and teachers.`
          }
          if (element.key === 'purposeOfVisit') {
            element.value =
              'This is a ' +
              this.userdata.patientBasicInfo.age +
              ' yr old ' +
              this.userdata.gender +
              ' who presents as a patient for a psychiatric evaluation. \nClient presents with symptoms of mood swings defiance, Oppositional, lack of concentration, procrastination, and distraction issues. '
          }
          if (element.key === 'mood_obs') {
            element.value = 'Mood: Irritable, Affect - Congruent to mod. No EPS.'
          }
          if (element.key === 'addObservations') {
            element.value =
              'The Client has been experiencing these symptoms from a young age. However, lately the studies have created more difficulties completing his/her assignments in a timely manner. Family and school both are concerned with ODD symptoms. Client school work is busy and has to spend extra amounts of time completing tasks. Client denies any acute symptoms of depression or psychosis. Client sleep and appetite are erratic.'
          }

          // symptoms
          if (element.key === 'sleep_DS') {
            element.value = true
          }
          if (element.key === 'decreasedConcentration_DS') {
            element.value = true
          }
          if (element.key === 'decreasedMemory_DS') {
            element.value = true
          }
          if (element.key === 'irritableMood_MS') {
            element.value = true
          }
          if (element.key === 'none_PS') {
            element.value = true
          }
          if (element.key === 'none_PTSD') {
            element.value = true
          }
          if (element.key === 'poorImpulseControl_PTSD') {
            element.value = true
          }
          if (element.key === 'muscleTension_AS') {
            element.value = true
          }
          if (element.key === 'failureToPayCloseAttention_ADS') {
            element.value = true
          }
          if (element.key === 'makesCarelessMistakesOften_ADS') {
            element.value = true
          }
          if (element.key === 'easilyDistracted_ADS') {
            element.value = true
          }
          if (element.key === 'difficultySustainingAttentionInTasks_ADS') {
            element.value = true
          }
          if (element.key === 'fidgets_ADS') {
            element.value = true
          }
          if (element.key === 'forgetfulOfDailyActivities_ADS') {
            element.value = true
          }
          if (element.key === 'procrastination_ADS') {
            element.value = true
          }

          //mental status exam
          if (element.key === 'wellGroomed_Appearance') {
            element.value = true
          }
          if (element.key === 'Neat_Appearance') {
            element.value = true
          }
          if (element.key === 'person_Orientation') {
            element.value = true
            element.labeltext = 'Normal Person'
          }
          if (element.key === 'place_Orientation') {
            element.value = true
          }
          if (element.key === 'time_Orientation') {
            element.value = true
          }
          if (element.key === 'purpose_Orientation') {
            element.value = true
          }
          if (element.key === 'normal_Speech') {
            element.value = true
            element.labeltext = 'Normal/ Slow '
          }
          if (element.key === 'uncooperative_Attitude') {
            element.value = true
          }
          if (element.key === 'ambulatory_PA') {
            element.value = true
          }
          if (element.key === 'irritable_Mood') {
            element.value = true
          }
          if (element.key === 'angry_Mood') {
            element.value = true
          }
          if (element.key === 'full_Affect') {
            element.value = true
          }
          if (element.key === 'goalDirectedAndLogical_TP') {
            element.value = true
          }
          if (element.key === 'appropriate_TP') {
            element.value = true
          }
          if (element.key === 'appropriate_TC') {
            element.value = true
          }
          if (element.key === 'ShortTerm') {
            element.value = 'Impaired'
          }
          if (element.key === 'LongTerm') {
            element.value = 'Impaired'
          }
          if (element.key === 'Insight') {
            element.value = 'Fair to Poor'
          }
          if (element.key === 'Judgment') {
            element.value = 'Fair to Poor'
          }

          if (element.key === 'OppositionalDisorder_axisI') {
            element.value = true
          }
          if (element.key === 'axisTwo') {
            element.value = 'None'
          }
          if (element.key === 'axisThree') {
            element.value = 'None'
          }
          if(element.key === 'axisFour'){
            element.value = 'None'
          }
          if (element.key === 'axisFive') {
            element.value = '70'
          }
          if (element.key === 'plan') {
            element.value =
              'Encouraged to take medication as prescribed and eat a healthy diet. Client is also encouraged to seek holistic approaches like walking, running, and meditating. Medication benefits and potential side effects have been discussed with patient. \nClient would need structured school and recommend IEP meeting based on 504 B plan. \nClient would also benefit from after school program. \nClient can also benefit from psychoeducation testing for appropriate services in school. \nI explained the algorithm of medicines for getting the optimal response.'
          }
          // if(element.key === "diagnosisMedications"){
          //   element.value = ""
          // }
        })
      }

      if (this.MednoteData.DiagonsisType === 'Autism') {
        this.formService.mednotesEvalConfig.forEach((element: any) => {
          if (element.key === 'chiefComplain') {
            element.value = `Client is having difficulty with concentration, social skills and rule out pervasive developmental disorder. Client struggles with comprehension, lack of age-appropriate social skills and interpersonal skills according to family. This stress is taking a toll on the family.  I reviewed the information provided by the caregiver, family and social agencies. `
          }
          if (element.key === 'purposeOfVisit') {
            element.value =
              'This is a ' +
              this.userdata.patientBasicInfo.age +
              ' yr old ' +
              this.userdata.gender +
              ' who presents as a patient for a psychiatric evaluation. \n Client presents with symptoms of lack of focus, lack of concentration, limited social skills. '
          }
          if (element.key === 'mood_obs') {
            element.value = 'Mood: Normal, Affect - Congruent to mod. No EPS.'
          }
          if (element.key === 'development') {
            element.value =
              'Client came in with the family. Client is reported to be in structured program in school. Client has delays in social skills, speech and cognitive skills for his/her age.'
          }
          if (element.key === 'addObservations') {
            element.value =
              'The Client has been experiencing these symptoms from a young age. There is no evidence of neglect or care by the family. His sleep and appetite are erratic. \nSelf Skills: Need assistance from family.'
          }

          // symptoms
          if (element.key === 'none_DS') {
            element.value = true
          }
          if (element.key === 'decreasedConcentration_DS') {
            element.value = true
          }
          if (element.key === 'none_MS') {
            element.value = true
          }
          if (element.key === 'none_PS') {
            element.value = true
          }
          if (element.key === 'none_PTSD') {
            element.value = true
          }
          if (element.key === 'poorImpulseControl_PTSD') {
            element.value = true
          }
          if (element.key === 'none_AS') {
            element.value = true
          }
          if (element.key === 'failureToPayCloseAttention_ADS') {
            element.value = true
          }
          if (element.key === 'makesCarelessMistakesOften_ADS') {
            element.value = true
          }
          if (element.key === 'easilyDistracted_ADS') {
            element.value = true
          }
          if (element.key === 'difficultySustainingAttentionInTasks_ADS') {
            element.value = true
          }
          if (element.key === 'fidgets_ADS') {
            element.value = true
          }
          if (element.key === 'forgetfulOfDailyActivities_ADS') {
            element.value = true
          }
          if (element.key === 'procrastination_ADS') {
            element.value = true
          }

          //mental status exam
          if (element.key === 'wellGroomed_Appearance') {
            element.value = true
          }
          if (element.key === 'Neat_Appearance') {
            element.value = true
          }
          if (element.key === 'person_Orientation') {
            element.value = true
            element.labeltext = 'Normal Person'
          }
          if (element.key === 'place_Orientation') {
            element.value = true
          }
          if (element.key === 'time_Orientation') {
            element.value = true
          }
          if (element.key === 'purpose_Orientation') {
            element.value = true
          }
          if (element.key === 'disorganized_Speech') {
            element.value = true
          }
          if (element.key === 'cooperative_Attitude') {
            element.value = true
          }
          if (element.key === 'friendly_Attitude') {
            element.value = true
          }
          if (element.key === 'ambulatory_PA') {
            element.value = true
          }
          if (element.key === 'anxious_Mood') {
            element.value = true
          }
          if (element.key === 'full_Affect') {
            element.value = true
          }
          if (element.key === 'disorganized_TP') {
            element.value = true
          }
          if (element.key === 'appropriate_TP') {
            element.value = true
          }
          if (element.key === 'ShortTerm') {
            element.value = 'Impaired'
          }
          if (element.key === 'LongTerm') {
            element.value = 'Impaired'
          }
          if (element.key === 'Insight') {
            element.value = 'Poor'
          }
          if (element.key === 'Judgment') {
            element.value = 'Poor'
          }

          if (element.key === 'Autism_axisI') {
            element.value = true
          }
          if (element.key === 'axisTwo') {
            element.value = 'None'
          }
          if (element.key === 'axisThree') {
            element.value = 'None'
          }
          if(element.key === 'axisFour'){
            element.value = 'None'
          }
          if (element.key === 'axisFive') {
            element.value = '60'
          }
          if (element.key === 'plan') {
            element.value =
              'Client meets criteria for PDD NOS. Client will need IEP meeting to address his/ her needs after psychoeducational testing is done. Client will need structured program to minimize external stimuli. \nClient will benefit from ABA treatment. \nClient would need structured school and recommend IEP meeting based on 504 B plan. \nMedications: Careful considerations of medications. \nI explained the algorithm medicines for getting the optimal response with the above explained recommendations.'
          }
          // if(element.key === "diagnosisMedications"){
          //   element.value = ""
          // }
        })
      }
      if (this.MednoteData.DiagonsisType === 'Eating Disorder') {
        this.formService.mednotesEvalConfig.forEach((element: any) => {
          if (element.key === 'chiefComplain') {
            element.value = `Client is having difficulty with concentration, low energy and motivation. Client struggles with completing tasks and assignments due to his low energy and motivation levels. The stress is dominating all aspects in his life. He is interested in trying medication to address these symptoms and to improve his quality of life.`
          }
          if (element.key === 'purposeOfVisit') {
            element.value =
              'This is a ' +
              this.userdata.patientBasicInfo.age +
              ' yr old ' +
              this.userdata.gender +
              ' who presents as a patient for a psychiatric evaluation. \nClient presents with symptoms of low focus, lack of concentration, procrastination, and distraction issues.'
          }
          if (element.key === 'mood_obs') {
            element.value = 'Mood: Normal, Affect - Congruent to mod. No EPS.'
          }
          if (element.key === 'addObservations') {
            element.value =
              'Client has been experiencing these symptoms from young age. However, lately the work / studies have created more difficulties completing his/her assignments in a timely manner. His/ her job/ school is very busy and has to spend extra amounts of time completing tasks. Client was requesting an assessment and whether he/ she might benefit from treatment for ADHD. Client denies any acute symptoms of depression or psychosis. His sleep and appetite are erratic.'
          }

          // symptoms
          if (element.key === 'sleep_DS') {
            element.value = true
          }
          if (element.key === 'decreasedConcentration_DS') {
            element.value = true
          }
          if (element.key === 'decreasedMemory_DS') {
            element.value = true
          }
          if (element.key === 'none_MS') {
            element.value = true
          }
          if (element.key === 'none_PS') {
            element.value = true
          }
          if (element.key === 'none_PTSD') {
            element.value = true
          }
          if (element.key === 'poorImpulseControl_PTSD') {
            element.value = true
          }
          if (element.key === 'muscleTension_AS') {
            element.value = true
          }
          if (element.key === 'failureToPayCloseAttention_ADS') {
            element.value = true
          }
          if (element.key === 'makesCarelessMistakesOften_ADS') {
            element.value = true
          }
          if (element.key === 'easilyDistracted_ADS') {
            element.value = true
          }
          if (element.key === 'difficultySustainingAttentionInTasks_ADS') {
            element.value = true
          }
          if (element.key === 'fidgets_ADS') {
            element.value = true
          }
          if (element.key === 'forgetfulOfDailyActivities_ADS') {
            element.value = true
          }
          if (element.key === 'procrastination_ADS') {
            element.value = true
          }

          //mental status exam
          if (element.key === 'wellGroomed_Appearance') {
            element.value = true
          }
          if (element.key === 'Neat_Appearance') {
            element.value = true
          }
          if (element.key === 'person_Orientation') {
            element.value = true
            element.labeltext = 'Normal Person'
          }
          if (element.key === 'place_Orientation') {
            element.value = true
          }
          if (element.key === 'time_Orientation') {
            element.value = true
          }
          if (element.key === 'purpose_Orientation') {
            element.value = true
          }
          if (element.key === 'normal_Speech') {
            element.value = true
          }
          if (element.key === 'cooperative_Attitude') {
            element.value = true
          }
          if (element.key === 'friendly_Attitude') {
            element.value = true
          }
          if (element.key === 'ambulatory_PA') {
            element.value = true
          }
          if (element.key === 'anxious_Mood') {
            element.value = true
          }
          if (element.key === 'full_Affect') {
            element.value = true
          }
          if (element.key === 'goalDirectedAndLogical_TP') {
            element.value = true
          }
          if (element.key === 'appropriate_TP') {
            element.value = true
          }
          if (element.key === 'appropriate_TC') {
            element.value = true
          }
          if (element.key === 'ShortTerm') {
            element.value = 'Impaired'
          }
          if (element.key === 'LongTerm') {
            element.value = 'Impaired'
          }
          if (element.key === 'Insight') {
            element.value = 'Fair'
          }
          if (element.key === 'Judgment') {
            element.value = 'Fair'
          }

          if (element.key === 'EatingDisorder_axisI') {
            element.value = true
          }
          if (element.key === 'axisTwo') {
            element.value = 'None'
          }
          if (element.key === 'axisThree') {
            element.value = 'None'
          }
          if(element.key === 'axisFour'){
            element.value = 'Moderate'
          }
          if (element.key === 'axisFive') {
            element.value = '70'
          }
          if (element.key === 'plan') {
            element.value =
              'Encouraged to take medication as prescribed and eat a healthy diet. Client is also encouraged to seek holistic approaches like walking, running, and meditating. Medication benefits and potential side effects have been discussed with patient. I explained the algorithm of these medicines for getting the optimal response with these medicines.'
          }
          // if(element.key === "diagnosisMedications"){
          //   element.value = ""
          // }
        })
      }
      if (this.MednoteData.DiagonsisType === 'OCD') {
        this.formService.mednotesEvalConfig.forEach((element: any) => {
          if (element.key === 'chiefComplain') {
            element.value =
              this.userdata.firstName +
              ` is struggling with low mood, low energy, constant worrying, unable to relax and low motivation in addition to the inability to concentrate. Client mood is noticeably anxious and client reports feeling fatigue. Client has intense anxiety worries on trivial things and client dwells on issues/situations a lot. The anxiety has become overwhelming on a frequent basis. Client sleep and appetite are erratic.`
          }
          if (element.key === 'purposeOfVisit') {
            element.value =
              'This is a ' +
              this.userdata.patientBasicInfo.age +
              ' yr old ' +
              this.userdata.gender +
              ' who presents as a patient for psychiatric evaluation. Client is dealing with a lot of symptoms of Anxiety and stress. Client is feeling at times hopeless, helpless and experiencing low motivation and lack of interest in things etc. Client has denied any suicidal thoughts or plans. Client is also unable to relax and worries.'
          }
          if (element.key === 'mood_obs') {
            element.value = 'Mood: Anxious, Affect - Congruent to mod. No EPS.'
          }

          // symptoms
          if (element.key === 'sleep_DS') {
            element.value = true
          }
          if (element.key === 'decreasedConcentration_DS') {
            element.value = true
          }
          if (element.key === 'decreasedMemory_DS') {
            element.value = true
          }
          if (element.key === 'depressedMood_DS') {
            element.value = true
          }
          if (element.key === 'none_MS') {
            element.value = true
          }
          if (element.key === 'none_PS') {
            element.value = true
          }
          if (element.key === 'sweating_AS') {
            element.value = true
          }
          if (element.key === 'poorImpulseControl_PTSD') {
            element.value = true
          }
          if (element.key === 'excessiveWorrying_AS') {
            element.value = true
          }
          if (element.key === 'chestPain_AS') {
            element.value = true
          }
          if (element.key === 'failureToPayCloseAttention_ADS') {
            element.value = true
          }
          if (element.key === 'makesCarelessMistakesOften_ADS') {
            element.value = true
          }
          if (element.key === 'easilyDistracted_ADS') {
            element.value = true
          }
          if (element.key === 'difficultySustainingAttentionInTasks_ADS') {
            element.value = true
          }
          if (element.key === 'fidgets_ADS') {
            element.value = true
          }
          if (element.key === 'forgetfulOfDailyActivities_ADS') {
            element.value = true
          }
          if (element.key === 'procrastination_ADS') {
            element.value = true
          }

          //mental status exam
          if (element.key === 'wellGroomed_Appearance') {
            element.value = true
          }
          if (element.key === 'Neat_Appearance') {
            element.value = true
          }
          if (element.key === 'person_Orientation') {
            element.value = true
            element.labeltext = 'Normal Person'
          }
          if (element.key === 'place_Orientation') {
            element.value = true
          }
          if (element.key === 'time_Orientation') {
            element.value = true
          }
          if (element.key === 'purpose_Orientation') {
            element.value = true
          }
          if (element.key === 'normal_Speech') {
            element.value = true
          }
          if (element.key === 'cooperative_Attitude') {
            element.value = true
          }
          if (element.key === 'friendly_Attitude') {
            element.value = true
          }
          if (element.key === 'ambulatory_PA') {
            element.value = true
          }
          if (element.key === 'anxious_Mood') {
            element.value = true
          }
          if (element.key === 'full_Affect') {
            element.value = true
          }
          if (element.key === 'goalDirectedAndLogical_TP') {
            element.value = true
          }
          if (element.key === 'appropriate_TP') {
            element.value = true
          }
          if (element.key === 'appropriate_TC') {
            element.value = true
          }
          if (element.key === 'ShortTerm') {
            element.value = 'Intact'
          }
          if (element.key === 'LongTerm') {
            element.value = 'Intact'
          }
          if (element.key === 'Insight') {
            element.value = 'Fair'
          }
          if (element.key === 'Judgment') {
            element.value = 'Fair'
          }

          if (element.key === 'OCD_axisI') {
            element.value = true
          }
          if (element.key === 'axisTwo') {
            element.value = 'None'
          }
          if (element.key === 'axisThree') {
            element.value = 'None'
          }
          if(element.key === 'axisFour'){
            element.value = 'None'
          }
          if (element.key === 'axisFive') {
            element.value = '70'
          }
          if (element.key === 'plan') {
            element.value =
              'Client is recommended for medication management and counseling. Client was encouraged to see a therapist and explained the benefits of meditation, breathing techniques to reduce Anxiety symptoms. I explained the algorithm of medicines for getting the optimal response.'
          }
          // if(element.key === "diagnosisMedications"){
          //   element.value = ""
          // }
        })
      }
    }
  }

  //on submitting the form this function get triggered
  getFormData(event) {
    let notes = this.doctorNotes.join('/n')
    let mednoteData = event.value

    //25MednoteForm
    if (
      (this.MednoteData.MednoteType === 'MEDNOTE25' || this.MednoteData.Duration === '25 Minutes') &&
      this.MednoteData.noteType === 'Monthly Medication Management'
    ) {
      let formattedData25 = {
        mednoteType: 'MEDNOTE25',
        evaluationDate: formatDate((mednoteData.followUp25_Date), 'yyyy-MM-dd', 'en'),
        PatientName: mednoteData.followUp25_PatientName,
        dob: formatDate((this.userdata.dob), 'yyyy-MM-dd', 'en'),
        DurationOfVisit: mednoteData.followUp25_DurationOfVisit + ' Minutes',
        Code: mednoteData.followUp25_Code,
        Date: formatDate((mednoteData.followUp25_Date), 'yyyy-MM-dd', 'en'),
        Diagnosis: mednoteData.Diagnosis,
        ChiefComplaintTitle: mednoteData.Diagnosis, // changes
        ChiefComplainDetails: mednoteData.followUp25_ChiefComplain, // added this new key: value pair
        ChiefComplaintDescription: {
          InformationResquestForm: {},
          DoctorNotes: mednoteData.followUp25_DoctorNotes,
        },
        AdditionalInfo: {
          therapy_details : mednoteData.followUp25_therapyDetails,
          pertinent_information : mednoteData.followUp25_pertinentInfo,
          medical_disorder: mednoteData.followUp25_medicalDisorder,
          non_psych_meds: mednoteData.followUp25_nonPsychMeds,
          past_historyOf_alcohol: mednoteData.followUp25_pastHistory,
          hospitalization_info: mednoteData.followUp25_HospitalInfo,
          legal_issue: mednoteData.followUp25_legalIssue,
          sucidal: mednoteData.followUp25_sucidal,
          paranoid: mednoteData.followUp25_paranoid,
          libido: mednoteData.followUp25_libibo,
          lack_of_sleep: mednoteData.followUp25_Insomina,
        },
        Objective: {
          Vitals: {
            Height:
              mednoteData.followUp25_heightFt !== '' || mednoteData.followUp25_heightInch !== ''
                ? (mednoteData.followUp25_heightFt ? mednoteData.followUp25_heightFt : '0') +
                  `'` +
                  (mednoteData.followUp25_heightInch ? mednoteData.followUp25_heightInch : '0')
                : '',
            Weight: mednoteData.followUp25_weight,
            BP: mednoteData.followUp25_BP ? mednoteData.followUp25_BP : '',
            // "PR": mednoteData.followUp25_PR ? mednoteData.followUp25_PR : '',
            Pulse: mednoteData.followUp25_Pulse ? mednoteData.followUp25_Pulse : '',
          },
        },
        MentalStatusExam: {
          Appearance: {
            'Well Groomed': mednoteData.followUp25_WellGroomed ? true : false,
            Clean: mednoteData.followUp25_Clean ? true : false,
            Disheveled: mednoteData.followUp25_Disheveled ? true : false,
            'Poor Hygiene': mednoteData.followUp25_PoorHygiene_Appearance ? true : false,
            Other: mednoteData.followUp25_Other ? true : false,
          },
          Orientation: {
            Person: mednoteData.followUp25_Person ? true : false,
            Place: mednoteData.followUp25_Place ? true : false,
            Time: mednoteData.followUp25_Time ? true : false,
            Purpose: mednoteData.followUp25_Purpose ? true : false,
          },
          Speech: {
            Normal: mednoteData.followUp25_Normal ? true : false,
            Disorganized: mednoteData.followUp25_Disorganized_speech ? true : false,
            Pressured: mednoteData.followUp25_Pressured ? true : false,
            Slurred: mednoteData.followUp25_Slurred ? true : false,
          },
          Attitude: {
            Cooperative: mednoteData.followUp25_Cooperative ? true : false,
            Uncooperative: mednoteData.followUp25_Uncooperative ? true : false,
            Guarded: mednoteData.followUp25_Guarded ? true : false,
            Friendly: mednoteData.followUp25_Friendly ? true : false,
            Threatening: mednoteData.followUp25_Threatening ? true : false,
            Angry: mednoteData.followUp25_Angry_Attitude ? true : false,
          },
          'Psychomotor Activity': {
            Ambulatory: mednoteData.followUp25_Ambulatory ? true : false,
            WheelChair: mednoteData.followUp25_WheelChair ? true : false,
            BedRidden: mednoteData.followUp25_BedRidden ? true : false,
          },
          Mood: {
            Euthymic: mednoteData.followUp25_Euthymic ? true : false,
            Euphoric: mednoteData.followUp25_Euphoric ? true : false,
            Dysphoric: mednoteData.followUp25_Dysphoric ? true : false,
            Anxious: mednoteData.followUp25_Anxious ? true : false,
            Angry: mednoteData.followUp25_Angry_mood ? true : false,
            Irritable: mednoteData.followUp25_Irritable ? true : false,
            'Depressed/Sad': mednoteData.followUp25_Depressed ? true : false,
          },
          Affect: {
            Full: mednoteData.followUp25_Full ? true : false,
            Constricted: mednoteData.followUp25_Constricted ? true : false,
            Labile: mednoteData.followUp25_Labile ? true : false,
            Blunted: mednoteData.followUp25_Blunted ? true : false,
            Flat: mednoteData.followUp25_Flat ? true : false,
          },
          'Thought Process': {
            'Goal Directed and Logical': mednoteData.followUp25_GoalDirectedAndLogical ? true : false,
            Appropriate: mednoteData.followUp25_Appropriate_TP ? true : false,
            Tangential: mednoteData.followUp25_Tangential ? true : false,
            'Flight of Ideas': mednoteData.followUp25_FlightOfIdeas ? true : false,
            Circumstantial: mednoteData.followUp25_Circumstantial ? true : false,
            Disorganized: mednoteData.followUp25_Disorganized_TP ? true : false,
            Blocking: mednoteData.followUp25_Blocking ? true : false,
            'Loose Associations': mednoteData.followUp25_LooseAssociations ? true : false,
            Perseveration: mednoteData.followUp25_Perseveration ? true : false,
            'Ideas of Reference': mednoteData.followUp25_IdeasOfReference ? true : false,
            'Poverty of Thought': mednoteData.followUp25_PovertyOfThought ? true : false,
          },
          'Thought Content': {
            Appropriate: mednoteData.followUp25_Appropriate_TC ? true : false,
            'Homicidal Ideation': mednoteData.followUp25_HomicidalIdeation ? true : false,
            'Suicidal Ideation': mednoteData.followUp25_SuicidalIdeation ? true : false,
            // "Flight of Ideas": mednoteData.followUp25_FlightOfIdeas ? true : false,
            // "Circumstantial": mednoteData.followUp25_Circumstantial ? true : false,
            'Hallucinations: Auditory, Visual, Olfactory, Command, Tactile': mednoteData.followUp25_Hallucinations
              ? true
              : false,
            'Persecutory Delusions': mednoteData.followUp25_PersecutoryDelusions ? true : false,
            'Preoccupation with illness': mednoteData.followUp25_PreOccupation_TC
              ? mednoteData.followUp25_PreOccupation_TC
              : false,
          },
          ShortTerm: mednoteData.followUp25_ShortTerm,
          LongTerm: mednoteData.followUp25_LongTerm,
          Insight: mednoteData.followUp25_Insight,
          Judgment: mednoteData.followUp25_Judgment,
        },
        RadioButtonValue: {
          'Short Term Memory': {
            Intact: mednoteData.followUp25_ShortTerm === 'Intact' ? true : false,
            Fair: mednoteData.followUp25_ShortTerm === 'Fair' ? true : false,
            Impaired: mednoteData.followUp25_ShortTerm === 'Impaired' ? true : false,
          },
          'Long Term Memory': {
            Intact: mednoteData.followUp25_LongTerm === 'Intact' ? true : false,
            Fair: mednoteData.followUp25_LongTerm === 'Fair' ? true : false,
            Impaired: mednoteData.followUp25_LongTerm === 'Impaired' ? true : false,
          },
          Insight: {
            Good: mednoteData.followUp25_Insight === 'Good' ? true : false,
            Fair: mednoteData.followUp25_Insight === 'Fair' ? true : false,
            'Fair to Poor': mednoteData.followUp25_Insight === 'Fair to Poor' ? true : false,
            Poor: mednoteData.followUp25_Insight === 'Poor' ? true : false,
          },
          Judgment: {
            Good: mednoteData.followUp25_Judgment === 'Good' ? true : false,
            Fair: mednoteData.followUp25_Judgment === 'Fair' ? true : false,
            Poor: mednoteData.followUp25_Judgment === 'Poor' ? true : false,
            'Fair to Poor': mednoteData.followUp25_Judgment === 'Fair to Poor' ? true : false,
          },
        },
        ConsentSigned: true,
        sideEffects: false,
        sideEffects_description: '',
        allergies: false,
        allergies_description: '',
        Assessment: mednoteData.followUp25_assessment,
        // "Axis_I": mednoteData.followUp25_axisOne,
        Axis_I: {
          'Anxiety Disorder (F41.9)': mednoteData.Anxiety25_axisI ? mednoteData.Anxiety25_axisI : false,
          'Schizoaffective disorder (F25.9)': mednoteData.Schizophrenia25_axisI
            ? mednoteData.Schizophrenia25_axisI
            : false,
          'BI-POLAR II (F31.81)': mednoteData.Bipolar25_axisI ? mednoteData.Bipolar25_axisI : false,
          'MDD (F33.9)': mednoteData.Depression25_axisI ? mednoteData.Depression25_axisI : false,
          'MDD (F33.2)': mednoteData.Depression25_axisI_I ? mednoteData.Depression25_axisI_I : false,
          'ADHD (F90.0)': mednoteData.ADHD25_axisI ? mednoteData.ADHD25_axisI : false,
          'ADJUSTMENT DISORDER (F43.20)': mednoteData.AdjustmentDisorder25_axisI
            ? mednoteData.AdjustmentDisorder25_axisI
            : false,
          'OPPOSITIONAL DISORDER (F91.3)': mednoteData.OppositionalDisorder25_axisI
            ? mednoteData.OppositionalDisorder25_axisI
            : false,
          'AUTISM (F84.0)': mednoteData.Autism25_axisI ? mednoteData.Autism25_axisI : false,
          'EATING DISORDER (F50.9)': mednoteData.EatingDisorder25_axisI ? mednoteData.EatingDisorder25_axisI : false,
          'OCD (F42.9)': mednoteData.OcdDisorder25_axisI ? mednoteData.OcdDisorder25_axisI : false,
        },
        Axis_I_Secondary: {
          'Anxiety Disorder (F41.9)': mednoteData.Anxiety25_axisI_SD ? mednoteData.Anxiety25_axisI_SD : false,
          'Schizoaffective disorder (F25.9)': mednoteData.Schizophrenia25_axisI_SD
            ? mednoteData.Schizophrenia25_axisI_SD
            : false,
          'BI-POLAR II (F31.81)': mednoteData.Bipolar25_axisI_SD ? mednoteData.Bipolar25_axisI_SD : false,
          'MDD (F33.9)': mednoteData.Depression25_axisI_SD ? mednoteData.Depression25_axisI_SD : false,
          'MDD (F33.2)': mednoteData.Depression25_axisI_SD_I ? mednoteData.Depression25_axisI_SD_I : false,
          'ADHD (F90.0)': mednoteData.ADHD25_axisI_SD ? mednoteData.ADHD25_axisI_SD : false,
          'ADJUSTMENT DISORDER (F43.20)': mednoteData.AdjustmentDisorder25_axisI_SD
            ? mednoteData.AdjustmentDisorder25_axisI_SD
            : false,
          'OPPOSITIONAL DISORDER (F91.3)': mednoteData.OppositionalDisorder25_axisI_SD
            ? mednoteData.OppositionalDisorder25_axisI_SD
            : false,
          'AUTISM (F84.0)': mednoteData.Autism25_axisI_SD ? mednoteData.Autism25_axisI_SD : false,
          'EATING DISORDER (F50.9)': mednoteData.EatingDisorder25_axisI_SD
            ? mednoteData.EatingDisorder25_axisI_SD
            : false,
          'OCD (F42.9)': mednoteData.OcdDisorder25_axisI_SD ? mednoteData.OcdDisorder25_axisI_SD : false,
        },
        Axis_II: mednoteData.followUp25_axisTwo,
        Axis_III: mednoteData.followUp25_axisThree,
        Axis_IV: mednoteData.followUp25_axisFour ? mednoteData.followUp25_axisFour : '',
        Axis_V: mednoteData.followUp25_axisFive ? mednoteData.followUp25_axisFive : '',
        Plan: {
          Plan: mednoteData.followUp25_plan,
          Duration: mednoteData.followUp25_durationMedication,
          Medications: [],
        },
        primaryInsuranceProvider: mednoteData.primaryInsuranceProvider,
        //PmpAttachments: []
        // "OutcomeAndAdvices": [mednoteData.followUp25_advice]
      }

      console.log(this.providers.primaryInsurance, 'testing testing')
      this.providers.primaryInsurance.forEach(element => {
        if (element.insuranceId == mednoteData.primaryInsuranceProvider) {
          // formattedData25.primaryInsuranceProvider = element.insuranceName
          Object.assign(this.notePopup, { Insurance: element.insuranceName })
        }
      })
      this.mednoteType = 'MEDNOTE25'
      //Infn
      let infQuestionArray = mednoteData.informationRequestQuestions.flatMap(Obj => {
        const Array = []
        for (const key in Obj) {
          if (key.startsWith('Question')) {
            const questionNumber = key.replace('Question', '').trim()
            Array.push({
              question: Obj[key],
              answer: Obj[`Answer ${questionNumber}`],
            })
          }
        }
        return Array
      })
      if (infQuestionArray.length !== 0) {
        formattedData25.ChiefComplaintDescription.InformationResquestForm = {
          questions: infQuestionArray,
        }
      } else {
        delete formattedData25.ChiefComplaintDescription.InformationResquestForm
      }
      let PayloadMeds = mednoteData.diagnosisMedications2.flatMap(Obj => {
        const Array = []
        for (const key in Obj) {
          if (key.startsWith('medicine')) {
            const medicineNumber = key.replace('medicine', '').trim()
            Array.push({
              medicine: Obj[key],
              dosage: Obj[`dosage${medicineNumber}`],
            })
          }
        }
        return Array
      })

      if (this.MednoteData.Operation === 'EditMednote' || this.prefill25Flag) {
        formattedData25.Plan.Medications = PayloadMeds
      } else {
        formattedData25.Plan.Medications = PayloadMeds
      }
      //SideEffects
      if (mednoteData.sideEffectsDetails !== '') {
        formattedData25.sideEffects = true
        formattedData25.sideEffects_description = mednoteData.sideEffectsDetails
      } else {
        formattedData25.sideEffects = false
        formattedData25.sideEffects_description = ''
      }
      //allergies
      if (mednoteData.followUp25_allergies_description !== '') {
        formattedData25.allergies = true
        formattedData25.allergies_description = mednoteData.followUp25_allergies_description
      } else {
        formattedData25.allergies = false
        formattedData25.allergies_description = ''
      }
      Object.assign(this.notePopup, {
        EvaluationDate: formatDate((formattedData25.evaluationDate), 'MM-dd-yyyy', 'en'),
        Diagnosis: formattedData25.ChiefComplaintTitle,
      })
      if (this.selectedButton === 'Preview') {
        this.showPreview = true
        this.showNotesForm = !this.showPreview
        this.PreviewData = formattedData25
      } else {
        if (this.selectedButton === 'GeneratePDF') {
          this.submitNoteData = { ...formattedData25 }
          this.cancelPopupShowHide = true
          console.log('getFormData() this.submitNoteData', this.submitNoteData)
        }
      }
    }
    //45MednoteForm
    if (
      (this.MednoteData.MednoteType === 'MEDNOTE45' || this.MednoteData.Duration === '45 Minutes') &&
      this.MednoteData.noteType === 'Monthly Medication Management'
    ) {
      let formattedData45 = {
        mednoteType: 'MEDNOTE45',
        PatientName: mednoteData.followUp45_PatientName,
        evaluationDate: formatDate((mednoteData.followUp45_Date), 'yyyy-MM-dd', 'en'),
        // 'Date': '',
        dob: formatDate((this.userdata.dob), 'yyyy-MM-dd', 'en'),
        DurationOfVisit: mednoteData.followUp45_DurationOfVisit + ' Minutes',
        Code: mednoteData.followUp45_Code,
        Diagnosis: mednoteData.Diagnosis,
        ChiefComplaintTitle: mednoteData.Diagnosis,
        ChiefComplainDetails: mednoteData.followUp45_ChiefComplain,
        ChiefComplaintDescription: {
          InformationResquestForm: {},
          DoctorNotes: mednoteData.followUp45_DoctorNote,
        },
        AdditionalInfo: {
          therapy_details : mednoteData.followUp45_therapyDetails,
          pertinent_information : mednoteData.followUp45_pertinentInfo,
          medical_disorder: mednoteData.followUp45_medicalDisorder,
          non_psych_meds: mednoteData.followUp45_nonPsychMeds,
          past_historyOf_alcohol: mednoteData.followUp45_pastHistory,
          hospitalization_info: mednoteData.followUp45_HospitalInfo,
          legal_issue: mednoteData.followUp45_legalIssue,
          sucidal: mednoteData.followUp45_sucidal,
          paranoid: mednoteData.followUp45_paranoid,
          libido: mednoteData.followUp45_libibo,
          lack_of_sleep: mednoteData.followUp45_Insomina,
        },
        Objective: {
          Vitals: {
            Height:
              mednoteData.followUp45_heightFt !== '' || mednoteData.followUp45_heightInch !== ''
                ? (mednoteData.followUp45_heightFt ? mednoteData.followUp45_heightFt : '0') +
                  `'` +
                  (mednoteData.followUp45_heightInch ? mednoteData.followUp45_heightInch : '0')
                : '',
            Weight: mednoteData.followUp45_weight,
            BP: mednoteData.followUp45_BP !== '' ? mednoteData.followUp45_BP : '',
            // "PR": mednoteData.followUp45_PR !== ''?  mednoteData.followUp45_PR : '',
            Pulse: mednoteData.followUp45_Pulse ? mednoteData.followUp45_Pulse : '',
          },
        },
        MentalStatusExam: {
          Appearance: {
            'Well Groomed': mednoteData.followUp45_WellGroomed ? mednoteData.followUp45_WellGroomed : false,
            Clean: mednoteData.followUp45_Clean ? mednoteData.followUp45_Clean : false,
            Disheveled: mednoteData.followUp45_Disheveled ? mednoteData.followUp45_Disheveled : false,
            'Poor Hygiene': mednoteData.followUp45_PoorHygiene_Appearance
              ? mednoteData.followUp45_PoorHygiene_Appearance
              : false,
            'Appears stated age': mednoteData.followUp45_statedAge ? mednoteData.followUp45_statedAge : false,
            Other: mednoteData.followUp45_Other ? mednoteData.followUp45_Other : false,
          },

          Orientation: {
            Person: mednoteData.followUp45_Person ? mednoteData.followUp45_Person : false,
            Place: mednoteData.followUp45_Place ? mednoteData.followUp45_Place : false,
            Time: mednoteData.followUp45_Time ? mednoteData.followUp45_Time : false,
            Purpose: mednoteData.followUp45_Purpose ? mednoteData.followUp45_Purpose : false,
          },

          Speech: {
            Normal: mednoteData.followUp45_Normal ? mednoteData.followUp45_Normal : false,
            Disorganized: mednoteData.followUp45_Disorganized_speech
              ? mednoteData.followUp45_Disorganized_speech
              : false,
            Pressured: mednoteData.followUp45_Pressured ? mednoteData.followUp45_Pressured : false,
            Slurred: mednoteData.followUp45_Slurred ? mednoteData.followUp45_Slurred : false,
          },

          Attitude: {
            Cooperative: mednoteData.followUp45_Cooperative ? mednoteData.followUp45_Cooperative : false,
            Uncooperative: mednoteData.followUp45_Uncooperative ? mednoteData.followUp45_Uncooperative : false,
            Guarded: mednoteData.followUp45_Guarded ? mednoteData.followUp45_Guarded : false,
            Friendly: mednoteData.followUp45_Friendly ? mednoteData.followUp45_Friendly : false,
            Threatening: mednoteData.followUp45_Threatening ? mednoteData.followUp45_Threatening : false,
            Angry: mednoteData.followUp45_Angry_Attitude ? mednoteData.followUp45_Angry_Attitude : false,
          },

          'Psychomotor Activity': {
            'Normal movements': mednoteData.followUp45_NormalMovements ? mednoteData.followUp45_NormalMovements : false,
            Ambulatory: mednoteData.followUp45_Ambulatory ? mednoteData.followUp45_Ambulatory : false,
            WheelChair: mednoteData.followUp45_WheelChair ? mednoteData.followUp45_WheelChair : false,
            BedRidden: mednoteData.followUp45_BedRidden ? mednoteData.followUp45_BedRidden : false,
          },

          Mood: {
            Euthymic: mednoteData.followUp45_Euthymic ? mednoteData.followUp45_Euthymic : false,
            Euphoric: mednoteData.followUp45_Euphoric ? mednoteData.followUp45_Euphoric : false,
            Dysphoric: mednoteData.followUp45_Dysphoric ? mednoteData.followUp45_Dysphoric : false,
            Anxious: mednoteData.followUp45_Anxious ? mednoteData.followUp45_Anxious : false,
            Angry: mednoteData.followUp45_Angry_mood ? mednoteData.followUp45_Angry_mood : false,
            Irritable: mednoteData.followUp45_Irritable ? mednoteData.followUp45_Irritable : false,
            'Depressed/sad': mednoteData.followUp45_Depressed ? mednoteData.followUp45_Depressed : false,
          },

          Affect: {
            Full: mednoteData.followUp45_Full ? mednoteData.followUp45_Full : false,
            Constricted: mednoteData.followUp45_Constricted ? mednoteData.followUp45_Constricted : false,
            Labile: mednoteData.followUp45_Labile ? mednoteData.followUp45_Labile : false,
            Blunted: mednoteData.followUp45_Blunted ? mednoteData.followUp45_Blunted : false,
            Flat: mednoteData.followUp45_Flat ? mednoteData.followUp45_Flat : false,
          },

          'Thought Process': {
            'Goal Directed and Logical': mednoteData.followUp45_GoalDirectedAndLogical
              ? mednoteData.followUp45_GoalDirectedAndLogical
              : false,
            Appropriate: mednoteData.followUp45_Appropriate_TP ? mednoteData.followUp45_Appropriate_TP : false,
            Tangential: mednoteData.followUp45_Tangential ? mednoteData.followUp45_Tangential : false,
            'Flight of Ideas': mednoteData.followUp45_FlightOfIdeas ? mednoteData.followUp45_FlightOfIdeas : false,
            Circumstantial: mednoteData.followUp45_Circumstantial ? mednoteData.followUp45_Circumstantial : false,
            Disorganized: mednoteData.followUp45_Disorganized_TP ? mednoteData.followUp45_Disorganized_TP : false,
            Blocking: mednoteData.followUp45_Blocking ? mednoteData.followUp45_Blocking : false,
            'Loose Associations': mednoteData.followUp45_LooseAssociations
              ? mednoteData.followUp45_LooseAssociations
              : false,
            Perseveration: mednoteData.followUp45_Perseveration ? mednoteData.followUp45_Perseveration : false,
            'Ideas of Reference': mednoteData.followUp45_IdeasOfReference
              ? mednoteData.followUp45_IdeasOfReference
              : false,
            'Poverty of Thought': mednoteData.followUp45_PovertyOfThought
              ? mednoteData.followUp45_PovertyOfThought
              : false,
            'Logical and coherent': mednoteData.followUp45_LogicalandCoherent
              ? mednoteData.followUp45_LogicalandCoherent
              : false,
          },
          'Thought Content': {
            Appropriate: mednoteData.followUp45_Appropriate_TC ? mednoteData.followUp45_Appropriate_TC : false,
            'Homicidal Ideation': mednoteData.followUp45_HomicidalIdeation
              ? mednoteData.followUp45_HomicidalIdeation
              : false,
            'Suicidal Ideation': mednoteData.followUp45_SuicidalIdeation
              ? mednoteData.followUp45_SuicidalIdeation
              : false,
            // "Flight of Ideas": mednoteData.followUp45_FlightOfIdeas?mednoteData.followUp45_FlightOfIdeas:false,
            // "Circumstantial": mednoteData.followUp45_Circumstantial?mednoteData.followUp45_Circumstantial: false,
            'Hallucinations: Auditory, Visual, Olfactory, Command, Tactile': mednoteData.followUp45_Hallucinations
              ? mednoteData.followUp45_Hallucinations
              : false,
            'Persecutory Delusions': mednoteData.followUp45_PersecutoryDelusions
              ? mednoteData.followUp45_PersecutoryDelusions
              : false,
            'Delusions on EX': mednoteData.followUp45_DelusionsOnEX ? mednoteData.followUp45_DelusionsOnEX : false,
            'Preoccupation with illness': mednoteData.followUp45_PreoccupationWithIllness
              ? mednoteData.followUp45_PreoccupationWithIllness
              : false,
          },
          Behavior: {
            Cooperative: mednoteData.followUp45_CooperativeBehaviour
              ? mednoteData.followUp45_CooperativeBehaviour
              : false,
            'Good eye contact': mednoteData.followUp45_GoodEyeContact ? mednoteData.followUp45_GoodEyeContact : false,
          },
          ShortTerm: mednoteData.followUp45_ShortTMemory,
          LongTerm: mednoteData.followUp45_LongTMemory,
          'Recent memory': mednoteData.followUp45_RecentMemory,
          Judgment: mednoteData.followUp45_Judgment,
          'Impulse Control': mednoteData.followUp45_ImpulseControl,
          'Attention/ Concentration': mednoteData.followUp45_AttentationorConcentration,
          'SI/HI': mednoteData.followUp45_SIorHI,
          Insight: mednoteData.followUp45_Insight,
          'Intellectual level': mednoteData.followUp45_IntellectualLevel,
          'Cognitive function': mednoteData.followUp45_CognitiveFunction,
          Alertness: mednoteData.followUp45_Alertness,
        },

        RadioButtonValue: {
          'SI/HI': {
            Yes: mednoteData.followUp45_SIorHI === 'Yes' ? true : false,
            No: mednoteData.followUp45_SIorHI === 'No' ? true : false,
          },
          'Short Term Memory': {
            Intact: mednoteData.followUp45_ShortTMemory === 'Intact' ? true : false,
            Fair: mednoteData.followUp45_ShortTMemory === 'Fair' ? true : false,
            Impaired: mednoteData.followUp45_ShortTMemory === 'Impaired' ? true : false,
          },
          'Long Term Memory': {
            Intact: mednoteData.followUp45_LongTMemory === 'Intact' ? true : false,
            Fair: mednoteData.followUp45_LongTMemory === 'Fair' ? true : false,
            Impaired: mednoteData.followUp45_LongTMemory === 'Impaired' ? true : false,
          },
          'Cognitive function': {
            Intact: mednoteData.followUp45_CognitiveFunction === 'Intact' ? true : false,
            Fair: mednoteData.followUp45_CognitiveFunction === 'Fair' ? true : false,
            Impaired: mednoteData.followUp45_CognitiveFunction === 'Impaired' ? true : false,
          },
          'Intellectual level': {
            Good: mednoteData.followUp45_IntellectualLevel === 'Good' ? true : false,
            Average: mednoteData.followUp45_IntellectualLevel === 'Average' ? true : false,
            Poor: mednoteData.followUp45_IntellectualLevel === 'Poor' ? true : false,
          },
          Alertness: {
            Attentive: mednoteData.followUp45_Alertness === 'Attentive' ? true : false,
            Inattentive: mednoteData.followUp45_Alertness === 'Inattentive' ? true : false,
          },
          'Recent memory': {
            Good: mednoteData.followUp45_RecentMemory === 'Good' ? true : false,
            Normal: mednoteData.followUp45_RecentMemory === 'Normal' ? true : false,
            Average: mednoteData.followUp45_RecentMemory === 'Average' ? true : false,
            Poor: mednoteData.followUp45_RecentMemory === 'Poor' ? true : false,
          },
          Insight: {
            Good: mednoteData.followUp45_Insight === 'Good' ? true : false,
            Fair: mednoteData.followUp45_Insight === 'Fair' ? true : false,
            'Fair to Poor': mednoteData.followUp45_Insight === 'Fair to Poor' ? true : false,
            Poor: mednoteData.followUp45_Insight === 'Poor' ? true : false,
          },
          Judgment: {
            Good: mednoteData.followUp45_Judgment === 'Good' ? true : false,
            Fair: mednoteData.followUp45_Judgment === 'Fair' ? true : false,
            Poor: mednoteData.followUp45_Judgment === 'Poor' ? true : false,
            'Fair to Poor': mednoteData.followUp45_Judgment === 'Fair to Poor' ? true : false,
          },
          'Impulse Control': {
            Good: mednoteData.followUp45_ImpulseControl === 'Good' ? true : false,
            Fair: mednoteData.followUp45_ImpulseControl === 'Fair' ? true : false,
            Poor: mednoteData.followUp45_ImpulseControl === 'Poor' ? true : false,
            'Fair to Poor': mednoteData.followUp45_ImpulseControl === 'Fair to Poor' ? true : false,
          },
          'Attention/ Concentration': {
            Intact: mednoteData.followUp45_AttentationorConcentration === 'Intact' ? true : false,
            Fair: mednoteData.followUp45_AttentationorConcentration === 'Fair' ? true : false,
            Impaired: mednoteData.followUp45_AttentationorConcentration === 'Impaired' ? true : false,
            'Partially impaired':
              mednoteData.followUp45_AttentationorConcentration === 'Partially impaired' ? true : false,
          },
        },
        // 'ConsentSigned': true,
        sideEffects: false,
        sideEffects_description: '',
        allergies: false,
        allergies_description: '',
        Assessment: mednoteData.followUp45_assessment,
        // "Axis_I": mednoteData.followUp45_axisOne,
        Axis_I: {
          'Anxiety Disorder (F41.9)': mednoteData.Anxiety45_axisI ? mednoteData.Anxiety45_axisI : false,
          'SCHIZOPHRENIFORM DISORDER (F20.81)': mednoteData.Schizophrenia45_axisI
            ? mednoteData.Schizophrenia45_axisI
            : false,
          'BI-POLAR II (F31.81)': mednoteData.Bipolar45_axisI ? mednoteData.Bipolar45_axisI : false,
          'MDD (F33.9)': mednoteData.Depression45_axisI ? mednoteData.Depression45_axisI : false,
          'MDD (F33.2)': mednoteData.Depression45_axisI_I ? mednoteData.Depression45_axisI_I : false,
          'ADHD (F90.0)': mednoteData.ADHD45_axisI ? mednoteData.ADHD45_axisI : false,
          'ADJUSTMENT DISORDER (F43.20)': mednoteData.AdjustmentDisorder45_axisI
            ? mednoteData.AdjustmentDisorder45_axisI
            : false,
          'OPPOSITIONAL DISORDER (F91.3)': mednoteData.OppositionalDisorder45_axisI
            ? mednoteData.OppositionalDisorder45_axisI
            : false,
          'AUTISM (F84.0)': mednoteData.Autism45_axisI ? mednoteData.Autism45_axisI : false,
          'EATING DISORDER (F50.9)': mednoteData.EatingDisorder45_axisI ? mednoteData.EatingDisorder45_axisI : false,
          'OCD (F42.9)': mednoteData.Ocd45_axisI ? mednoteData.Ocd45_axisI : false,
        },
        Axis_I_Secondary: {
          'Anxiety Disorder (F41.9)': mednoteData.Anxiety45_axisI_SD ? mednoteData.Anxiety45_axisI_SD : false,
          'SCHIZOPHRENIFORM DISORDER (F20.81)': mednoteData.Schizophrenia45_axisI_SD
            ? mednoteData.Schizophrenia45_axisI_SD
            : false,
          'BI-POLAR II (F31.81)': mednoteData.Bipolar45_axisI_SD ? mednoteData.Bipolar45_axisI_SD : false,
          'MDD (F33.9)': mednoteData.Depression45_axisI_SD ? mednoteData.Depression45_axisI_SD : false,
          'MDD (F33.2)': mednoteData.Depression45_axisI_SD_I ? mednoteData.Depression45_axisI_SD_I : false,
          'ADHD (F90.0)': mednoteData.ADHD45_axisI_SD ? mednoteData.ADHD45_axisI_SD : false,
          'ADJUSTMENT DISORDER (F43.20)': mednoteData.AdjustmentDisorder45_axisI_SD
            ? mednoteData.AdjustmentDisorder45_axisI_SD
            : false,
          'OPPOSITIONAL DISORDER (F91.3)': mednoteData.OppositionalDisorder45_axisI_SD
            ? mednoteData.OppositionalDisorder45_axisI_SD
            : false,
          'AUTISM (F84.0)': mednoteData.Autism45_axisI_SD ? mednoteData.Autism45_axisI_SD : false,
          'EATING DISORDER (F50.9)': mednoteData.EatingDisorder45_axisI_SD
            ? mednoteData.EatingDisorder45_axisI_SD
            : false,
          'OCD (F42.9)': mednoteData.Ocd45_axisI_SD ? mednoteData.Ocd45_axisI_SD : false,
        },
        Axis_II: mednoteData.followUp45_axisTwo,
        Axis_III: mednoteData.followUp45_axisThree,
        Axis_IV: mednoteData.followUp45_axisFour,
        Axis_V: mednoteData.followUp45_axisFive,
        'Procedure/Type of Visit': mednoteData.followUp45_typeOfVisit,
        'Potential Side Effects': mednoteData.followUp45_potentialSideEffect,
        Plan: {
          Coordination: mednoteData.followUp45_coordinationOfCare,
          Plan: mednoteData.followUp45_plan,
          Duration: mednoteData.followUp45_durationOfMedication,
          Medications: [],
        },
        primaryInsuranceProvider: mednoteData.primaryInsuranceProvider,

      }
     
      this.mednoteType = 'MEDNOTE45'
      this.providers.primaryInsurance.forEach(element => {
        if (element.insuranceId == mednoteData.primaryInsuranceProvider) {
          // formattedData45.primaryInsuranceProvider = element.insuranceName
          Object.assign(this.notePopup, { Insurance: element.insuranceName })
        }
      })
      //Infn
      let infQuestionArray = mednoteData.informationRequestQuestions.flatMap(Obj => {
        const Array = []
        for (const key in Obj) {
          if (key.startsWith('Question')) {
            const questionNumber = key.replace('Question', '').trim()
            Array.push({
              question: Obj[key],
              answer: Obj[`Answer ${questionNumber}`],
            })
          }
        }
        return Array
      })
      if (infQuestionArray.length !== 0) {
        formattedData45.ChiefComplaintDescription.InformationResquestForm = {
          questions: infQuestionArray,
        }
      } else {
        delete formattedData45.ChiefComplaintDescription.InformationResquestForm
      }

      let PayloadMeds = mednoteData.diagnosisMedications2.flatMap(Obj => {
        const Array = []
        for (const key in Obj) {
          if (key.startsWith('medicine')) {
            const medicineNumber = key.replace('medicine', '').trim()
            Array.push({
              medicine: Obj[key],
              dosage: Obj[`dosage${medicineNumber}`],
            })
          }
        }
        return Array
      })

      if (this.MednoteData.Operation === 'EditMednote' || this.prefill45Flag) {
        formattedData45.Plan.Medications = PayloadMeds
      } else {
        formattedData45.Plan.Medications = PayloadMeds
      }
      //SideEffects
      if (mednoteData.sideEffectsDetails !== '') {
        formattedData45.sideEffects = true
        formattedData45.sideEffects_description = mednoteData.sideEffectsDetails
      } else {
        formattedData45.sideEffects = false
        formattedData45.sideEffects_description = ''
      }
      //allergies
      if (mednoteData.followUp45_Allergies !== '') {
        formattedData45.allergies = true
        formattedData45.allergies_description = mednoteData.followUp45_Allergies
      } else {
        formattedData45.allergies = false
        formattedData45.allergies_description = ''
      }
      Object.assign(this.notePopup, {
        EvaluationDate: formatDate((formattedData45.evaluationDate), 'MM-dd-yyyy', 'en'),
        Diagnosis: formattedData45.ChiefComplaintTitle,
      })
      if (this.selectedButton === 'Preview') {
        this.showPreview = true
        this.showNotesForm = !this.showPreview
        this.PreviewData = formattedData45
      } else {
        if (this.selectedButton === 'GeneratePDF') {
          this.submitNoteData = { ...formattedData45 }
          this.cancelPopupShowHide = true
          console.log('getFormData() this.submitNoteData', this.submitNoteData)
        }
      }

      return
    }
    //Evaluation
    if (this.MednoteData.noteType === 'Evaluation') {
      let strContainer = this.MednoteData.EvalType == 're' ? ' - ' : ' '
      let formattedDataEval = {
        PatientInfo: {
          lastName: mednoteData.lastName,
          firstName: mednoteData.firstName,
          MI: mednoteData.mi,
          age: mednoteData.age,
          dob: formatDate((mednoteData.dob), 'yyyy-MM-dd', 'en'),
          race: mednoteData.race,
          sex: mednoteData.Gender,
          Employer: mednoteData.employer ? mednoteData.employer : '',
          Education: mednoteData.education,
        },
        sibling: this.MednoteData.EvalType == 'annual' ? '' : mednoteData.sibling,
        evaluationDate: formatDate((mednoteData.evaluationDate), 'yyyy-MM-dd', 'en'),
        location: mednoteData.locName,
        mednoteType: 'EVALUATION',
        evalutionType: [
          this.MednoteData.EvalType.toUpperCase() + strContainer + this.MednoteData.noteType.toUpperCase(),
        ],
        ReasonForVisit: {
          'Medication Review': mednoteData.medicationReview ? mednoteData.medicationReview : false,
          'Initial Psychiatric Evaluation': mednoteData.psychiatricEvaluation
            ? mednoteData.psychiatricEvaluation
            : false,
          'Diagnostic Assessment': mednoteData.diagnosticAssessment ? mednoteData.diagnosticAssessment : false,
          'Chart Review': mednoteData.chartReview ? mednoteData.chartReview : false,
          'Family Interview': mednoteData.familyInterview ? mednoteData.familyInterview : false,
          'Hospital Record Review': mednoteData.hospitalRecordReview ? mednoteData.hospitalRecordReview : false,
          'Lab Review': mednoteData.labReview ? mednoteData.labReview : false,
          'Annual Psychiatric Evaluation': mednoteData.annualPsychiatricEvaluation
            ? mednoteData.annualPsychiatricEvaluation
            : false,
        },
        Code: mednoteData.mednote_Code,
        chiefComplaint: mednoteData.chiefComplain,
        Diagnosis: mednoteData.Diagnosis,
        ChiefComplaintTitle: mednoteData.Diagnosis,
        ChiefComplaintDescription: {
          InformationResquestForm: {},
          DoctorNotes: mednoteData.DoctorNote,
        },
        PurposeOfVisit: mednoteData.purposeOfVisit,
        Observations: {
          weight: mednoteData.weight,
          height:
            mednoteData.heightFt !== '' || mednoteData.heightInch !== ''
              ? (mednoteData.heightFt ? mednoteData.heightFt : '0') +
                `'` +
                (mednoteData.heightInch ? mednoteData.heightInch : '0')
              : '',
          BP: mednoteData.bloodPressure ? mednoteData.bloodPressure : '',
          Pulse: mednoteData.pulseEval ? mednoteData.pulseEval : '',
          Alertness: mednoteData.alertness_obs,
          Ambulatory: mednoteData.ambulatory_obs,
          //mednoteData.alertness_obs and mednoteData.ambulatory_obs
          mood: mednoteData.mood_obs,
          addObservations: mednoteData.addObservations,
        },
        symptoms: {
          'Depressive Symptoms': {
            None: mednoteData.none_DS ? mednoteData.none_DS : false,
            'Sleep Increase or Decrease': mednoteData.sleep_DS ? mednoteData.sleep_DS : false,
            'Depressed Mood': mednoteData.depressedMood_DS ? mednoteData.depressedMood_DS : false,
            'Decreased Concentration': mednoteData.decreasedConcentration_DS
              ? mednoteData.decreasedConcentration_DS
              : false,
            'Decreased Memory': mednoteData.decreasedMemory_DS ? mednoteData.decreasedMemory_DS : false,
            Anhedonia: mednoteData.anhedonia_DS ? mednoteData.anhedonia_DS : false,
            Hopelessness: mednoteData.hopelessness_DS ? mednoteData.hopelessness_DS : false,
            'Sucidal Plan': mednoteData.suicidal_DS ? mednoteData.suicidal_DS : false,
            'Ability to Carry-out Plan': mednoteData.carryOutplan_DS ? mednoteData.carryOutplan_DS : false,
            'Sucidal Ideation or Thoughts of Death': mednoteData.suicidalIdea_DS ? mednoteData.suicidalIdea_DS : false,
            'Psychomotor Agitation/Retardation': mednoteData.psychomotor_DS ? mednoteData.psychomotor_DS : false,
            Guilt: mednoteData.guilt_DS ? mednoteData.guilt_DS : false,
            'Appetite Increase or Decrease': mednoteData.appetite_DS ? mednoteData.appetite_DS : false,
            'Suicidal Gesture': mednoteData.suicidalGesture_DS ? mednoteData.suicidalGesture_DS : false,
            '> 2 Weeks Duration': mednoteData.twoWeeks_DS ? mednoteData.twoWeeks_DS : false,
          },
          'Manic Symptoms': {
            None: mednoteData.none_MS ? mednoteData.none_MS : false,
            'Decreased and/or Need for sleep': mednoteData.needOfSleep_MS ? mednoteData.needOfSleep_MS : false,
            'Inflated Self Esteem and/or Grandiosity': mednoteData.selfEsteem_MS ? mednoteData.selfEsteem_MS : false,
            'Flight of Ideas or racing Thoughts': mednoteData.flightOfIdeas_MS ? mednoteData.flightOfIdeas_MS : false,
            'Pressured speech': mednoteData.speech_MS ? mednoteData.speech_MS : false,
            'Increased Goal Directed Activity': mednoteData.increasedGoal_MS ? mednoteData.increasedGoal_MS : false,
            'Excessive Pleasurable Activity with Painful Consequences': mednoteData.pleasurableActivity_MS
              ? mednoteData.pleasurableActivity_MS
              : false,
            'Elevated or Expansive Mood': mednoteData.elevatedMood_MS ? mednoteData.elevatedMood_MS : false,
            Distractibility: mednoteData.distractibility_MS ? mednoteData.distractibility_MS : false,
            'Severe Decrease in Functioning': mednoteData.Functioning_MS ? mednoteData.Functioning_MS : false,
            'Irritable Mood': mednoteData.irritableMood_MS ? mednoteData.irritableMood_MS : false,
            '> 7 Days Duration': mednoteData.sevenDays_MS ? mednoteData.sevenDays_MS : false,
          },
          'Psychotic Symptoms': {
            None: mednoteData.none_PS ? mednoteData.none_PS : false,
            'Negative Symptoms': mednoteData.negativeSymptoms_PS ? mednoteData.negativeSymptoms_PS : false,
            'Disorganized Speech': mednoteData.disorganizedSpeech_PS ? mednoteData.disorganizedSpeech_PS : false,
            // "Delusions": {
            'Delusions: bizarre': mednoteData.delusionsBizarre_PS ? true : false,
            'Delusions: nonBizarre': mednoteData.delusionsNonBizarre_PS ? true : false,
            // },
            'Hallucinations: Auditory, Visual, Olfactory, Command, Tactile': mednoteData.hallucinations_PS
              ? true
              : false,
            'Disorganized Behavior': mednoteData.disorganizedBehavior_PS ? mednoteData.disorganizedBehavior_PS : false,
            'Thought Disorder': mednoteData.thoughtDisorder_PS ? mednoteData.thoughtDisorder_PS : false,
            '> 30 Days Duration': mednoteData.thirtyDays_PS ? mednoteData.thirtyDays_PS : false,
            '> 1 Month and < 6 Months Duration': mednoteData.oneMonthandSixMonth_PS
              ? mednoteData.oneMonthandSixMonth_PS
              : false,
            '> 6 Months Duration': mednoteData.sixMonths_PS ? mednoteData.sixMonths_PS : false,
          },
          'PTSD Symptoms': {
            None: mednoteData.none_PTSD ? mednoteData.none_PTSD : false,
            Nightmares: mednoteData.nightmares_PTSD ? mednoteData.nightmares_PTSD : false,
            'Hyper Vigilance': mednoteData.hyperVigilance_PTSD ? mednoteData.hyperVigilance_PTSD : false,
            'Avoidance Stimuli': mednoteData.avoidanceStimuli_PTSD ? mednoteData.avoidanceStimuli_PTSD : false,
            Flashbacks: mednoteData.flashbacks_PTSD ? mednoteData.flashbacks_PTSD : false,
            'Exaggerated startle response': mednoteData.exaggeratedStartleResponse_PTSD
              ? mednoteData.exaggeratedStartleResponse_PTSD
              : false,
            'Feelings of Detachment': mednoteData.feelingsOfDetachment_PTSD
              ? mednoteData.feelingsOfDetachment_PTSD
              : false,
            'Poor Impulse Control': mednoteData.poorImpulseControl_PTSD ? mednoteData.poorImpulseControl_PTSD : false,
            'Feelings of Aggression': mednoteData.feelingsOfAggression_PTSD
              ? mednoteData.feelingsOfAggression_PTSD
              : false,
          },
          'Anxiety Symptoms': {
            None: mednoteData.none_AS ? mednoteData.none_AS : false,
            Palpitations: mednoteData.palpitations_AS ? mednoteData.palpitations_AS : false,
            Sweating: mednoteData.sweating_AS ? mednoteData.sweating_AS : false,
            'Excessive Worrying': mednoteData.excessiveWorrying_AS ? mednoteData.excessiveWorrying_AS : false,
            'Muscle tension or muscle aches': mednoteData.muscleTension_AS ? mednoteData.muscleTension_AS : false,
            'Chest Pain or Discomfort': mednoteData.chestPain_AS ? mednoteData.chestPain_AS : false,
            'Fear of Dying': mednoteData.fearOfDying_AS ? mednoteData.fearOfDying_AS : false,
          },
          'Attention Deficit Symptoms': {
            None: mednoteData.none_ADS ? mednoteData.none_ADS : false,
            'Failure to pay close attention': mednoteData.failureToPayCloseAttention_ADS
              ? mednoteData.failureToPayCloseAttention_ADS
              : false,
            'Makes careless mistakes often': mednoteData.makesCarelessMistakesOften_ADS
              ? mednoteData.makesCarelessMistakesOften_ADS
              : false,
            'Easily Distracted': mednoteData.easilyDistracted_ADS ? mednoteData.easilyDistracted_ADS : false,
            'Difficulty sustaining attention in tasks': mednoteData.difficultySustainingAttentionInTasks_ADS
              ? mednoteData.difficultySustainingAttentionInTasks_ADS
              : false,
            'Fidgets/Squirm': mednoteData.fidgets_ADS ? mednoteData.fidgets_ADS : false,
            'Forgetful of daily activities': mednoteData.forgetfulOfDailyActivities_ADS
              ? mednoteData.forgetfulOfDailyActivities_ADS
              : false,
            'Procrastination/Last Minute starts on tasks': mednoteData.procrastination_ADS
              ? mednoteData.procrastination_ADS
              : false,
            'Excessive talking': mednoteData.excessiveTalking_ADS ? mednoteData.excessiveTalking_ADS : false,
            Demanding: mednoteData.demanding_ADS ? mednoteData.demanding_ADS : false,
            Frustration: mednoteData.frustration_ADS ? mednoteData.frustration_ADS : false,
          },
        },
        MentalStatusExam: {
          Appearance: {
            'Well Groomed': mednoteData.wellGroomed_Appearance ? mednoteData.wellGroomed_Appearance : false,
            Clean: mednoteData.Neat_Appearance ? mednoteData.Neat_Appearance : false,
            Disheveled: mednoteData.disheveled_Appearance ? mednoteData.disheveled_Appearance : false,
            'Poor Hygiene': mednoteData.poorHygiene_Appearance ? mednoteData.poorHygiene_Appearance : false,
            Other: mednoteData.other_Appearance ? mednoteData.other_Appearance : false,
          },
          Orientation: {
            Person: mednoteData.person_Orientation ? mednoteData.person_Orientation : false,
            Place: mednoteData.place_Orientation ? mednoteData.place_Orientation : false,
            Time: mednoteData.time_Orientation ? mednoteData.time_Orientation : false,
            Purpose: mednoteData.purpose_Orientation ? mednoteData.purpose_Orientation : false,
          },
          Speech: {
            Normal: mednoteData.normal_Speech ? mednoteData.normal_Speech : false,
            Disorganized: mednoteData.disorganized_Speech ? mednoteData.disorganized_Speech : false,
            Pressured: mednoteData.pressured_Speech ? mednoteData.pressured_Speech : false,
            Slurred: mednoteData.slurred_Speech ? mednoteData.slurred_Speech : false,
          },
          Attitude: {
            Cooperative: mednoteData.cooperative_Attitude ? mednoteData.cooperative_Attitude : false,
            Uncooperative: mednoteData.uncooperative_Attitude ? mednoteData.uncooperative_Attitude : false,
            Guarded: mednoteData.guarded_Attitude ? mednoteData.guarded_Attitude : false,
            Friendly: mednoteData.friendly_Attitude ? mednoteData.friendly_Attitude : false,
            Threatening: mednoteData.threatening_Attitude ? mednoteData.threatening_Attitude : false,
            Angry: mednoteData.angry_Attitude ? mednoteData.angry_Attitude : false,
          },
          'Psychomotor Activity': {
            Ambulatory: mednoteData.ambulatory_PA ? mednoteData.ambulatory_PA : false,
            WheelChair: mednoteData.wheelChair_PA ? mednoteData.wheelChair_PA : false,
            BedRidden: mednoteData.bedRidden_PA ? mednoteData.bedRidden_PA : false,
          },
          Mood: {
            Euthymic: mednoteData.euthymic_Mood ? mednoteData.euthymic_Mood : false,
            Euphoric: mednoteData.euphoric_Mood ? mednoteData.euphoric_Mood : false,
            Dysphoric: mednoteData.dysphoric_Mood ? mednoteData.dysphoric_Mood : false,
            Anxious: mednoteData.anxious_Mood ? mednoteData.anxious_Mood : false,
            Angry: mednoteData.angry_Mood ? mednoteData.angry_Mood : false,
            Irritable: mednoteData.irritable_Mood ? mednoteData.irritable_Mood : false,
            Depressed: mednoteData.depressed_Mood ? mednoteData.depressed_Mood : false,
          },
          Affect: {
            Full: mednoteData.full_Affect ? mednoteData.full_Affect : false,
            Constricted: mednoteData.constricted_Affect ? mednoteData.constricted_Affect : false,
            Labile: mednoteData.labile_Affect ? mednoteData.labile_Affect : false,
            Blunted: mednoteData.blunted_Affect ? mednoteData.blunted_Affect : false,
            Flat: mednoteData.flat_Affect ? mednoteData.flat_Affect : false,
          },
          'Thought Process': {
            'Goal Directed and Logical': mednoteData.goalDirectedAndLogical_TP
              ? mednoteData.goalDirectedAndLogical_TP
              : false,
            Appropriate: mednoteData.appropriate_TP ? mednoteData.appropriate_TP : false,
            Tangential: mednoteData.tangential_TP ? mednoteData.tangential_TP : false,
            'Flight of Ideas': mednoteData.flightOfIdeas_TP ? mednoteData.flightOfIdeas_TP : false,
            Circumstantial: mednoteData.circumstantial_TP ? mednoteData.circumstantial_TP : false,
            Disorganized: mednoteData.disorganized_TP ? mednoteData.disorganized_TP : false,
            Blocking: mednoteData.blocking_TP ? mednoteData.blocking_TP : false,
            'Loose Associations': mednoteData.looseAssociations_TP ? mednoteData.looseAssociations_TP : false,
            Perseveration: mednoteData.perseveration_TP ? mednoteData.perseveration_TP : false,
            'Ideas of Reference': mednoteData.ideasOfReference_TP ? mednoteData.ideasOfReference_TP : false,
            'Poverty of Thought': mednoteData.povertyOfThought_TP ? mednoteData.povertyOfThought_TP : false,
          },
          'Thought Content': {
            Appropriate: mednoteData.appropriate_TC ? mednoteData.appropriate_TC : false,
            'Homicidal Ideation': mednoteData.homicidalIdeation_TC ? mednoteData.homicidalIdeation_TC : false,
            'Suicidal Ideation': mednoteData.suicidalIdeation_TC ? mednoteData.suicidalIdeation_TC : false,
            'Hallucinations: Auditory, Visual, Olfactory, Command, Tactile': mednoteData.hallucination_TC
              ? true
              : false,
            'Persecutory Delusions': mednoteData.persecutoryDelusions_TC ? mednoteData.persecutoryDelusions_TC : false,
            'PreOccupation With Illness': mednoteData.illness_TC ? mednoteData.illness_TC : false,
          },
          'Short Term Memory': mednoteData.ShortTerm,
          'Long Term Memory': mednoteData.LongTerm,
          Insight: mednoteData.Insight,
          Judgment: mednoteData.Judgment,
        },

        RadioButtonValue: {
          'Short Term Memory': {
            Intact: mednoteData.ShortTerm === 'Intact' ? true : false,
            Fair: mednoteData.ShortTerm === 'Fair' ? true : false,
            Impaired: mednoteData.ShortTerm === 'Impaired' ? true : false,
          },
          'Long Term Memory': {
            Intact: mednoteData.LongTerm === 'Intact' ? true : false,
            Fair: mednoteData.LongTerm === 'Fair' ? true : false,
            Impaired: mednoteData.LongTerm === 'Impaired' ? true : false,
          },
          Insight: {
            Good: mednoteData.Insight === 'Good' ? true : false,
            Fair: mednoteData.Insight === 'Fair' ? true : false,
            'Fair to Poor': mednoteData.Insight === 'Fair to Poor' ? true : false,
            Poor: mednoteData.Insight === 'Poor' ? true : false,
          },
          Judgment: {
            Good: mednoteData.Judgment === 'Good' ? true : false,
            Fair: mednoteData.Judgment === 'Fair' ? true : false,
            'Fair to Poor': mednoteData.Judgment === 'Fair to Poor' ? true : false,
            Poor: mednoteData.Judgment === 'Poor' ? true : false,
          },
        },
        allergies: mednoteData.allergies,
        PastPsychiatricHx: mednoteData.pastPsychiatric,
        emotionalAbuse: mednoteData.emotionalAbuse,
        physicalAbuse: mednoteData.physicalAbuse,
        suicideAttempts: mednoteData.suicideAbuse,
        pastMedicalHx: mednoteData.pastMedical,
        pastMedications: [mednoteData.medications],
        pastSurgHx: mednoteData.pastSurg,
        substanceAbsueHx: mednoteData.substanceAbuse,
        familyHx: mednoteData.familyPsych,
        Education_soicalHx: mednoteData.socalEducation, // for soical hx
        Siblings: mednoteData.socalSiblings, // for soical hx
        Employed: mednoteData.socalEmployed, // for soical hx
        TobaccoUse: mednoteData.socalTobaccoUse, // for soical hx
        DevelopmentHx: mednoteData.development,
        MilitaryHx: mednoteData.military,
        legalHx: mednoteData.legal,
        // "Axis_I": mednoteData.axisOne,
        Axis_I: {
          'Anxiety Disorder (F41.9)': mednoteData.Anxiety_axisI ? mednoteData.Anxiety_axisI : false,
          'Autism Spectrum Disorder (F84.0)': mednoteData.Autism_axisI ? mednoteData.Autism_axisI : false,
          'BI-POLAR (F31.81)': mednoteData.Bipolar_axisI ? mednoteData.Bipolar_axisI : false,
          'MDD (F33.2)': mednoteData.Depression_axisI ? mednoteData.Depression_axisI : false,
          'MDD (F33.9)': mednoteData.Depression_axisI_I ? mednoteData.Depression_axisI_I : false,
          'ADHD (F90.0)': mednoteData.ADHD_axisI ? mednoteData.ADHD_axisI : false,
          'ODD (F91.3)': mednoteData.OppositionalDisorder_axisI ? mednoteData.OppositionalDisorder_axisI : false,
          'Schizophrenia (F20.9)': mednoteData.Schizophrenia_axisI ? mednoteData.Schizophrenia_axisI : false,
          'Adjustment Disorder (F43.23)': mednoteData.AdjustmentDisorder_axisI
            ? mednoteData.AdjustmentDisorder_axisI
            : false,
          'EATING DISORDER (F50.9)': mednoteData.EatingDisorder_axisI ? mednoteData.EatingDisorder_axisI : false,
          'OCD (F42.9)': mednoteData.OCD_axisI ? mednoteData.OCD_axisI : false,
        },
        Axis_I_Secondary: {
          'Anxiety Disorder (F41.9)': mednoteData.Anxiety_axisI_SD ? mednoteData.Anxiety_axisI_SD : false,
          'Autism Spectrum Disorder (F84.0)': mednoteData.Autism_axisI_SD ? mednoteData.Autism_axisI_SD : false,
          'BI-POLAR (F31.81)': mednoteData.Bipolar_axisI_SD ? mednoteData.Bipolar_axisI_SD : false,
          'MDD (F33.2)': mednoteData.Depression_axisI_SD ? mednoteData.Depression_axisI_SD : false,
          'MDD (F33.9)': mednoteData.Depression_axisI_SD_I ? mednoteData.Depression_axisI_SD_I : false,
          'ADHD (F90.0)': mednoteData.ADHD_axisI_SD ? mednoteData.ADHD_axisI_SD : false,
          'ODD (F91.3)': mednoteData.OppositionalDisorder_axisI_SD ? mednoteData.OppositionalDisorder_axisI_SD : false,
          'Schizophrenia (F20.9)': mednoteData.Schizophrenia_axisI_SD ? mednoteData.Schizophrenia_axisI_SD : false,
          'Adjustment Disorder (F43.23)': mednoteData.AdjustmentDisorder_axisI_SD
            ? mednoteData.AdjustmentDisorder_axisI_SD
            : false,
          'EATING DISORDER (F50.9)': mednoteData.EatingDisorder_axisI_SD ? mednoteData.EatingDisorder_axisI_SD : false,
          'OCD (F42.9)': mednoteData.OCD_axisI_SD ? mednoteData.OCD_axisI_SD : false,
        },
        Axis_II: mednoteData.axisTwo,
        Axis_III: mednoteData.axisThree,
        Axis_IV: mednoteData.axisFour,
        Axis_V: mednoteData.axisFive,
        Plan: mednoteData.plan,
        Medications: [],
        primaryInsuranceProvider: mednoteData.primaryInsuranceProvider,
        //PmpAttachments: [],
        // "Medications": [mednoteData.diagnosisMedications],
        // 'EmailCorrespondence' : mednoteData.emailCorrespondence
      }
      this.mednoteType = 'EVALUATION'
      this.providers.primaryInsurance.forEach(element => {
        if (element.insuranceId == mednoteData.primaryInsuranceProvider) {
          // formattedDataEval.primaryInsuranceProvider = element.insuranceName
          Object.assign(this.notePopup, { Insurance: element.insuranceName })
        }
      })
      //Infn
      let infQuestionArray = mednoteData.informationRequestQuestions.flatMap(Obj => {
        const Array = []
        for (const key in Obj) {
          if (key.startsWith('Question')) {
            const questionNumber = key.replace('Question', '').trim()
            Array.push({
              question: Obj[key],
              answer: Obj[`Answer ${questionNumber}`],
            })
          }
        }
        return Array
      })

      if (infQuestionArray.length !== 0) {
        formattedDataEval.ChiefComplaintDescription.InformationResquestForm = {
          questions: infQuestionArray,
        }
      } else {
        delete formattedDataEval.ChiefComplaintDescription.InformationResquestForm
      }
      //medications
      let PayloadMeds = mednoteData.diagnosisMedications2.flatMap(Obj => {
        const Array = []
        for (const key in Obj) {
          if (key.startsWith('medicine')) {
            const medicineNumber = key.replace('medicine', '').trim()
            Array.push({
              medicine: Obj[key],
              dosage: Obj[`dosage${medicineNumber}`],
            })
          }
        }
        return Array
      })

      if (this.MednoteData.Operation === 'EditMednote' || this.prefillEvalFlag) {
        formattedDataEval.Medications = PayloadMeds
      } else {
        formattedDataEval.Medications = PayloadMeds
      }
      if (this.userdata.patientBasicInfo.age <= 18) {
        delete formattedDataEval.PatientInfo.Employer
      }
      Object.assign(this.notePopup, {
        EvaluationDate:  formatDate((formattedDataEval.evaluationDate), 'MM-dd-yyyy', 'en'),
        Diagnosis: formattedDataEval.ChiefComplaintTitle,
      })
      if (this.selectedButton === 'Preview') {
        this.showPreview = true
        this.showNotesForm = !this.showPreview
        this.PreviewData = formattedDataEval
      } else {
        if (this.selectedButton === 'GeneratePDF') {
          this.submitNoteData = { ...formattedDataEval }
          this.cancelPopupShowHide = true
          console.log('getFormData() this.submitNoteData', this.submitNoteData)
        }
      }
    }
  }
  //FOR EMAIL CORSPONDENCE
  massageDocumentUrls(docUrls) {
    if (!docUrls && !Array.isArray(docUrls)) {
      return []
    }
    return docUrls.map(eachUrl => {
      return {
        url: eachUrl,
        fileName: eachUrl.includes('__name__')
          ? decodeURIComponent(eachUrl.substring(eachUrl.lastIndexOf('__name__') + 8, eachUrl.indexOf('?')))
          : decodeURIComponent(eachUrl.substring(eachUrl.lastIndexOf('/') + 1, eachUrl.lastIndexOf('?'))),
      }
    })
  }
  downloadImage(img) {
    const imgUrl = img
    const imgName = imgUrl.includes('__name__')
      ? imgUrl.substring(imgUrl.lastIndexOf('__name__') + 8, imgUrl.indexOf('?'))
      : imgUrl.substring(imgUrl.lastIndexOf('/') + 1, imgUrl.lastIndexOf('?'))
    this.httpClient.get(imgUrl, { responseType: 'blob' as 'json' }).subscribe((res: any) => {
      const file = new Blob([res], { type: res.type })

      const blob = window.URL.createObjectURL(file)
      const link = document.createElement('a')
      link.href = blob
      link.download = imgName
      // Version link.click() to work at firefox
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      )

      setTimeout(() => {
        // firefox
        window.URL.revokeObjectURL(blob)
        link.remove()
      }, 100)
    })
  }

  addMednote(data) {
    this.apptID = this.MednoteData.AppointmentId
    this.userID = this.MednoteData.userId
    this.MednoteID = this.MednoteData.MednoteId
    const Payload = {
      type: this.MednoteData.Operation === 'CreateMednote' ? 'POST' : 'PUT',
      url: this.MednoteData.Operation === 'CreateMednote' ? API_URLS.ADDMEDNOTES : API_URLS.EDITMEDNOTES,
      isDeveloper: true,
      // isAuthToken: true,
      pathVariables:
        this.MednoteData.Operation === 'CreateMednote'
          ? [this.patientId, this.apptID, this.userID, this.mednoteType]
          : [this.patientId, this.MednoteID],
      body: data,
    }
    console.log(data, 'datadatadata', 'Payload', Payload)
    this.http.makeHttpRequest(Payload).subscribe(res => {
      if (this.http.isSuccessfulResponse(res)) {
        this.observable.showToast(
          TOAST_STATUSES.SUCCESS,
          this.MednoteData.noteType === 'Monthly Medication Management'
            ? TOAST_MESSAGES.MEDNOTE_GENERATED
            : TOAST_MESSAGES.EVALUATTION_GENERATED
        )
        this.back()
        return
      } else {
        let message =
          this.MednoteData.noteType === 'Monthly Medication Management'
            ? "Falied to generate patient's mednote. Please try again"
            : "Falied to generate patient's evaluation note. Please try again"
        message = res.error ? res.error.message : message
        this.observable.showToast(TOAST_STATUSES.ERROR, message)
        return
      }
    })
  }

  cancelPopupShowHideStatusFun(status) {
    this.cancelPopupShowHide = status.cancelpopup
  }
  cancelPopup() {
    this.cancelPopupShowHide = false
  }
  submitNote() {
    console.log('submitNote() this.submitNoteData', this.submitNoteData)
    this.addMednote(this.submitNoteData)
    this.submitNoteData = {}
  }
  previewForm() {
    this.selectedButton = 'Preview'
    this.saveFormData = true
    this.getInvalidFormElements()
  }

  resetSaveFormParity() {
    window.setTimeout(() => {
      this.saveFormData = false
    }, 0)
    return true
  }

  back() {
    this.form[0].formGroup.reset()
    this.cd.detectChanges()
    this.router.navigate([ROUTE_PATHS.ADMIN, ROUTE_PATHS.PATIENTS])
  }

  saveForm() {
    this.selectedButton = 'GeneratePDF'
    this.saveFormData = true
    this.getInvalidFormElements()
  }

  navigateBackToForm() {
    this.showPreview = false
    this.showNotesForm = !this.showPreview
  }

  navigateBack(event) {
    this.form[0].formGroup.reset()
    this.cd.detectChanges()
    if (event) {
      this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.PATIENTS}`])
    }
  }
  getProvidersAndPharmacyDetails() {
    let payload = {
      type: 'GET',
      url: 'reference',
      isDeveloper: true,
    }
    this.http.makeHttpRequest(payload).subscribe(res => {
      if (this.http.isSuccessfulResponse(res) && res) {
        // console.log(res);
        this.massageProvidersAndPharmacyDetails(res.data)
        if (
          this.MednoteData.Duration === '25 Minutes' &&
          this.MednoteData.noteType === 'Monthly Medication Management'
        ) {
          this.formService.mednoteFollowUpTwentyFiveConfig.forEach(each => {
            if (each.key === 'primaryInsuranceProvider') {
              each.list = this.providers.primaryInsurance
            } else if (each.key === 'secondaryInsuranceProvider') {
              each.list = this.providers.secondaryInsurance
            }
          })
        } else if (
          this.MednoteData.Duration === '45 Minutes' &&
          this.MednoteData.noteType === 'Monthly Medication Management'
        ) {
          this.formService.mednoteFollowUpFortyFiveConfig.forEach(each => {
            if (each.key === 'primaryInsuranceProvider') {
              each.list = this.providers.primaryInsurance
            } else if (each.key === 'secondaryInsuranceProvider') {
              each.list = this.providers.secondaryInsurance
            }
          })
        } else if (this.MednoteData.noteType === 'Evaluation') {
          this.formService.mednotesEvalConfig.forEach(each => {
            if (each.key === 'primaryInsuranceProvider') {
              each.list = this.providers.primaryInsurance
              console.log('this.providers.primaryInsurance', this.providers.primaryInsurance)
              console.log('eacheacheach', each, each.list)
            } else if (each.key === 'secondaryInsuranceProvider') {
              each.list = this.providers.secondaryInsurance
            }
          })
        }
        // console.log(this.providers.primaryInsurance,'trefasef');
      } else {
        let message = 'Failed to fetch an insurance data. Please try again'
        message = res.error ? res.error.message : message
        // this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
      }
    })
  }
  massageProvidersAndPharmacyDetails(data) {
    this.pharmacy = data.pharmacy.map(each => {
      return {
        ...each,
        label: each.pharmacyName,
        value: each.pharmacyName.toLowerCase(),
      }
    })
    let primary = []
    let secondary = []
    data.insurance.forEach(insurance => {
      let dropDown = {
        ...insurance,
        label: insurance.insuranceName,
        value: insurance.insuranceId,
      }
      if (insurance.primaryFlag === 1) {
        primary.push(dropDown)
      }
      if (insurance.secondaryFlag === 1) {
        secondary.push(dropDown)
      }
    })

    this.providers = {
      primaryInsurance: primary,
      secondaryInsurance: secondary,
    }
    // console.log(this.providers);
  }
  getInvalidFormElements() {
    let invalidFormElementArray = document.querySelectorAll('.invalid-feedback')
    if (invalidFormElementArray.length !== 0) {
      invalidFormElementArray[0].scrollIntoView({ block: 'center' })
    }
  }

  dropValue(event) {
    let newDiagnosis = event.value
    this.diagnosisChangeAssignData(newDiagnosis)
  }

  diagnosisChangeAssignData(changedDiagnosis: string) {
    if (this.MednoteData.Duration === '25 Minutes' && this.MednoteData.noteType === 'Monthly Medication Management') {
      this.formService.mednoteFollowUpTwentyFiveConfig.forEach(element => {
        if (element.controlType === 'checkbox-tik' || element.controlType === 'checkbox-tik-one') {
          element.value = false
        }
        if (element.controlType === 'custom-radio') {
          element.value = ''
        }
        if (element.key == 'followUp25_assessment') {
          element.value = 'DSM-V'
        }
        if (element.key === 'followUp25_Date') {
          let evalDate = this.MednoteData.AppointmentDate.split('-')
          element.value = `${evalDate[1]}/${evalDate[2]}/${evalDate[0]}`
        }
        if (element.key === 'diagnosisMedications2') {
          let medicationTypes = [
            { key: 'psychiatric', label: 'Psychiatric' },
            { key: 'benzodiazepines', label: 'Benzodiazepines' },
            { key: 'medicalAdderallStimulants', label: 'Medical Adderall Stimulants' },
          ]
          let intakeMeds = []
          medicationTypes.forEach((medType, index) => {
            if (this.userdata.medicalHistory[medType.key].flag === true) {
              this.userdata.medicalHistory[medType.key].medications.map(med => {
                intakeMeds.push({ medicine: `${med.medicine}`, dosage: `${med.dosage}` })
              })
            }
          })
          if (intakeMeds.length === 0) {
            intakeMeds.push({ medicine: ``, dosage: `` })
          }
          let medFields = this.massageMedicationData(intakeMeds)
          element.formInputs = []
          for (let i = medFields.length - 1; i >= 0; i--) {
            element.formInputs.unshift(medFields[i])
          }
        }
        if (element.key == 'followUp25_allergies_description') {
          element.value =
            this.appointMentdata !== null
              ? this.userdata.medicalHistory.knownAllergies +
                '\n' +
                this.userdata.medicalHistory.medicalKnownAllergies +
                '\n' +
                this.appointMentdata.healthDetails.outburst_allergies_description
              : this.userdata.medicalHistory.knownAllergies + '\n' + this.userdata.medicalHistory.medicalKnownAllergies
        }
        // if (element.key == "sideEffects") { element.value = false }
        if (element.key == 'sideEffectsDetails') {
          // element.showontoggle = true
          if (this.appointMentdata !== null) {
            element.value = this.appointMentdata.healthDetails.medicines_side_effects_description
              ? this.appointMentdata.healthDetails.medicines_side_effects_description
              : 'No side-effects noticed.'
          } else {
            element.value = 'No side-effects noticed.'
          }
        }
        if (
          element.key === 'followUp25_axisTwo' ||
          element.key === 'followUp25_axisThree' ||
          element.key === 'followUp25_axisFour' ||
          element.key == 'followUp25_axisFive'
        ) {
          element.value = ''
        }
        if (element.key == 'followUp25_durationMedication') {
          element.value = 'One month supply of the following medications'
        }
        if (element.key == 'followUp25_plan') {
          element.value =
            'Client was also advised, time management strategies and digital fasting. \nClient was encouraged to email if any side effects or new onset symptoms are observed \nClient is encouraged also to seek holistic approach like walking, running, meditation. Client got support, guidance, and reassurance after the visit. Client diagnosis was reviewed and discussed medication rationale.'
        }
        if (element.key == 'followUp25_DoctorNotes') {
          let elementToRemove = " Dear Dr. Prayaga's Office,"
          this.doctorNotes = this.doctorNotes.filter(item => item !== elementToRemove)
          let notes = this.doctorNotes.join('\n ')
          element.value =
            Object.keys(this.OldNoteData).length !== 0
              ? notes.replace(/[ \t\n]+$/, '') +
                '\n\n' +
                this.OldNoteData['ChiefComplaintDescription.DoctorNotes'].replace(/[ \t\n]+$/, '')
              : notes.replace(/[ \t\n]+$/, '')
        }
        if (element.key === 'followUp25_DurationOfVisit') {
          element.value = 25
        }
        if (element.key === 'followUp25_Code') {
          element.value = '99213'
        }
      })
      if (changedDiagnosis == 'Adjustment Disorder') {
        this.formService.mednoteFollowUpTwentyFiveConfig.forEach((element: any, id: any) => {
          if (element.key == 'followUp25_ChiefComplain') {
            element.value = 'Adjustment Disorder'
          }
          if (element.key == 'AdjustmentDisorder25_axisI') {
            element.value = true
          }
          if (element.key == 'followUp25_axisTwo') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisFour') {
            element.value = 'Moderate'
          }
          if (element.key == 'followUp25_axisFive') {
            element.value = '70'
          }
          if (element.key == 'followUp25_WellGroomed') {
            element.value = true
          }
          if (element.key == 'followUp25_Clean') {
            element.value = true
          }
          if (element.key == 'followUp25_Person') {
            element.value = true
          }
          if (element.key == 'followUp25_Place') {
            element.value = true
          }
          if (element.key == 'followUp25_Time') {
            element.value = true
          }
          if (element.key == 'followUp25_Purpose') {
            element.value = true
          }
          if (element.key == 'followUp25_Normal') {
            element.value = true
          }
          if (element.key == 'followUp25_Cooperative') {
            element.value = true
          }
          if (element.key == 'followUp25_Friendly') {
            element.value = true
          }
          if (element.key == 'followUp25_Ambulatory') {
            element.value = true
          }
          if (element.key == 'followUp25_Anxious') {
            element.value = true
          }
          // if(element.key == 'followUp25_Euthymic'){ element.value = true }
          if (element.key == 'followUp25_Full') {
            element.value = true
          }
          if (element.key == 'followUp25_GoalDirectedAndLogical') {
            element.value = true
          }
          if (element.key == 'followUp25_Appropriate_TC') {
            element.value = true
          }
          if (element.key == 'followUp25_Appropriate_TP') {
            element.value = true
          }
          if (element.key == 'followUp25_ShortTerm') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp25_LongTerm') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp25_Insight') {
            element.value = 'Fair'
          }
          if (element.key == 'followUp25_Judgment') {
            element.value = 'Fair'
          }
        })
      } else if (changedDiagnosis == 'Oppositional Disorder') {
        console.log('hello hello Oppositional Disorder')

        this.formService.mednoteFollowUpTwentyFiveConfig.forEach((element: any, id: any) => {
          if (element.key == 'followUp25_ChiefComplain') {
            element.value = 'OPPOSITIONAL DISORDER'
          }
          if (element.key == 'OppositionalDisorder25_axisI') {
            element.value = true
          }
          if (element.key == 'followUp25_axisTwo') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisFour') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisFive') {
            element.value = '70'
          }
          if (element.key == 'followUp25_WellGroomed') {
            element.value = true
          }
          if (element.key == 'followUp25_Clean') {
            element.value = true
          }
          if (element.key == 'followUp25_Person') {
            element.value = true
          }
          if (element.key == 'followUp25_Place') {
            element.value = true
          }
          if (element.key == 'followUp25_Time') {
            element.value = true
          }
          if (element.key == 'followUp25_Purpose') {
            element.value = true
          }
          if (element.key == 'followUp25_Normal') {
            element.value = true
          }
          if (element.key == 'followUp25_Cooperative') {
            element.value = true
          }
          if (element.key == 'followUp25_Friendly') {
            element.value = true
          }
          if (element.key == 'followUp25_Ambulatory') {
            element.value = true
          }
          if (element.key == 'followUp25_Anxious') {
            element.value = true
          }
          if (element.key == 'followUp25_Euthymic') {
            element.value = true
          }
          if (element.key == 'followUp25_Full') {
            element.value = true
          }
          if (element.key == 'followUp25_GoalDirectedAndLogical') {
            element.value = true
          }
          if (element.key == 'followUp25_Appropriate_TC') {
            element.value = true
          }
          if (element.key == 'followUp25_Appropriate_TP') {
            element.value = true
          }
          if (element.key == 'followUp25_ShortTerm') {
            element.value = 'Impaired'
          }
          if (element.key == 'followUp25_LongTerm') {
            element.value = 'Impaired'
          }
          if (element.key == 'followUp25_Insight') {
            element.value = 'Fair'
          }
          if (element.key == 'followUp25_Judgment') {
            element.value = 'Fair'
          }
        })
      } else if (changedDiagnosis == 'ADHD') {
        this.formService.mednoteFollowUpTwentyFiveConfig.forEach((element: any, id: any) => {
          if (element.key == 'followUp25_ChiefComplain') {
            element.value = 'ADHD'
          }
          if (element.key == 'ADHD25_axisI') {
            element.value = true
          }
          if (element.key == 'followUp25_axisTwo') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisFour') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisFive') {
            element.value = '70'
          }
          if (element.key == 'followUp25_WellGroomed') {
            element.value = true
          }
          if (element.key == 'followUp25_Clean') {
            element.value = true
          }
          if (element.key == 'followUp25_Person') {
            element.value = true
          }
          if (element.key == 'followUp25_Place') {
            element.value = true
          }
          if (element.key == 'followUp25_Time') {
            element.value = true
          }
          if (element.key == 'followUp25_Purpose') {
            element.value = true
          }
          if (element.key == 'followUp25_Normal') {
            element.value = true
          }
          if (element.key == 'followUp25_Cooperative') {
            element.value = true
          }
          if (element.key == 'followUp25_Friendly') {
            element.value = true
          }
          if (element.key == 'followUp25_Ambulatory') {
            element.value = true
          }
          if (element.key == 'followUp25_Anxious') {
            element.value = true
          }
          if (element.key == 'followUp25_Euthymic') {
            element.value = true
          }
          if (element.key == 'followUp25_Full') {
            element.value = true
          }
          if (element.key == 'followUp25_GoalDirectedAndLogical') {
            element.value = true
          }
          if (element.key == 'followUp25_Appropriate_TC') {
            element.value = true
          }
          if (element.key == 'followUp25_Appropriate_TP') {
            element.value = true
          }
          if (element.key == 'followUp25_ShortTerm') {
            element.value = 'Impaired'
          }
          if (element.key == 'followUp25_LongTerm') {
            element.value = 'Impaired'
          }
          if (element.key == 'followUp25_Insight') {
            element.value = 'Fair'
          }
          if (element.key == 'followUp25_Judgment') {
            element.value = 'Fair'
          }
        })
      } else if (changedDiagnosis == 'Anxiety') {
        this.formService.mednoteFollowUpTwentyFiveConfig.forEach((element: any) => {
          if (element.key == 'followUp25_ChiefComplain') {
            element.value = 'ANXIETY'
          }
          if (element.key == 'Anxiety25_axisI') {
            element.value = true
          }
          if (element.key == 'followUp25_axisTwo') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisFour') {
            element.value = 'Moderate'
          }
          if (element.key == 'followUp25_axisFive') {
            element.value = '70'
          }
          if (element.key == 'followUp25_WellGroomed') {
            element.value = true
          }
          if (element.key == 'followUp25_Clean') {
            element.value = true
          }
          if (element.key == 'followUp25_Person') {
            element.value = true
          }
          if (element.key == 'followUp25_Place') {
            element.value = true
          }
          if (element.key == 'followUp25_Time') {
            element.value = true
          }
          if (element.key == 'followUp25_Purpose') {
            element.value = true
          }
          if (element.key == 'followUp25_Normal') {
            element.value = true
          }
          if (element.key == 'followUp25_Cooperative') {
            element.value = true
          }
          if (element.key == 'followUp25_Friendly') {
            element.value = true
          }
          if (element.key == 'followUp25_Ambulatory') {
            element.value = true
          }
          if (element.key == 'followUp25_Anxious') {
            element.value = true
          }
          if (element.key == 'followUp25_Full') {
            element.value = true
          }
          if (element.key == 'followUp25_GoalDirectedAndLogical') {
            element.value = true
          }
          if (element.key == 'followUp25_Appropriate_TP') {
            element.value = true
          }
          if (element.key == 'followUp25_Appropriate_TC') {
            element.value = true
          }
          if (element.key == 'followUp25_ShortTerm') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp25_LongTerm') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp25_Insight') {
            element.value = 'Fair'
          }
          if (element.key == 'followUp25_Judgment') {
            element.value = 'Fair'
          }
        })
      } else if (changedDiagnosis == 'Depression') {
        this.formService.mednoteFollowUpTwentyFiveConfig.forEach((element: any, id: any) => {
          if (element.key == 'followUp25_ChiefComplain') {
            element.value = 'MAJOR DEPRESSION'
          }
          if (element.key == 'Depression25_axisI') {
            element.value = true
          }
          if (element.key == 'followUp25_axisTwo') {
            element.value = 'Deffered'
          }
          if (element.key == 'followUp25_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisFour') {
            element.value = 'Moderate'
          }
          if (element.key == 'followUp25_axisFive') {
            element.value = '70'
          }
          // if (element.key == 'Depression25_axisI_I') {
          //   element.value = true
          // }
          if (element.key == 'followUp25_WellGroomed') {
            element.value = true
          }
          if (element.key == 'followUp25_Clean') {
            element.value = true
          }
          if (element.key == 'followUp25_Person') {
            element.labeltext = 'Normal Person'
            element.value = true
          }
          if (element.key == 'followUp25_Place') {
            element.value = true
          }
          if (element.key == 'followUp25_Time') {
            element.value = true
          }
          if (element.key == 'followUp25_Purpose') {
            element.value = true
          }
          if (element.key == 'followUp25_Normal') {
            element.labeltext = 'Normal/ Slow'
            element.value = true
          }
          if (element.key == 'followUp25_Cooperative') {
            element.value = true
          }
          if (element.key == 'followUp25_Friendly') {
            element.value = true
          }
          if (element.key == 'followUp25_Ambulatory') {
            element.value = true
          }
          // if(element.key == 'followUp25_WheelChair'){
          //   element.labeltext = 'Wheelchair minimal '
          //   element.value = true
          // }
          if (element.key == 'followUp25_Depressed') {
            element.value = true
          }
          if (element.key == 'followUp25_Flat') {
            element.value = true
          }
          if (element.key == 'followUp25_PreOccupation_TC') {
            element.value = true
          }
          if (element.key == 'followUp25_GoalDirectedAndLogical') {
            element.value = true
          }
          if (element.key == 'followUp25_Appropriate_TP') {
            element.value = true
          }
          if (element.key == 'followUp25_ShortTerm') {
            element.value = 'Impaired'
          }
          if (element.key == 'followUp25_LongTerm') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp25_Insight') {
            element.value = 'Fair'
          }
          if (element.key == 'followUp25_Judgment') {
            element.value = 'Fair'
          }
        })
      } else if (changedDiagnosis == 'Bipolar') {
        this.formService.mednoteFollowUpTwentyFiveConfig.forEach((element: any, id: any) => {
          if (element.key == 'followUp25_ChiefComplain') {
            element.value = 'BI-POLAR II'
          }
          if (element.key == 'Bipolar25_axisI') {
            element.value = true
          }
          if (element.key == 'followUp25_axisTwo') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisFour') {
            element.value = 'Moderate'
          }
          if (element.key == 'followUp25_axisFive') {
            element.value = '70'
          }
          if (element.key == 'followUp25_WellGroomed') {
            element.value = true
          }
          if (element.key == 'followUp25_Clean') {
            element.value = true
          }
          if (element.key == 'followUp25_Person') {
            element.value = true
          }
          if (element.key == 'followUp25_Place') {
            element.value = true
          }
          if (element.key == 'followUp25_Time') {
            element.value = true
          }
          if (element.key == 'followUp25_Purpose') {
            element.value = true
          }
          if (element.key == 'followUp25_Pressured') {
            element.value = true
          }
          if (element.key == 'followUp25_Cooperative') {
            element.value = true
          }
          if (element.key == 'followUp25_Friendly') {
            element.value = true
          }
          if (element.key == 'followUp25_Ambulatory') {
            element.value = true
          }
          if (element.key == 'followUp25_Dysphoric') {
            element.value = true
          }
          if (element.key == 'followUp25_Full') {
            element.value = true
          }
          if (element.key == 'followUp25_Labile') {
            element.value = true
          }
          if (element.key == 'followUp25_GoalDirectedAndLogical') {
            element.value = true
          }
          if (element.key == 'followUp25_Appropriate_TP') {
            element.value = true
          }
          if (element.key == 'followUp25_Tangential') {
            element.value = true
          }
          if (element.key == 'followUp25_PreOccupation_TC') {
            element.value = true
          }
          if (element.key == 'followUp25_ShortTerm') {
            element.value = 'Impaired'
          }
          if (element.key == 'followUp25_LongTerm') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp25_Insight') {
            element.value = 'Fair to Poor'
          }
          if (element.key == 'followUp25_Judgment') {
            element.value = 'Fair to Poor'
          }
          
        })
      } else if (changedDiagnosis == 'Schizophrenia') {
        this.formService.mednoteFollowUpTwentyFiveConfig.forEach((element: any, id: any) => {
          if (element.key == 'followUp25_ChiefComplain') {
            element.value = 'Schizoaffective disorder'
          }
          if (element.key == 'Schizophrenia25_axisI') {
            element.value = true
          }
          if (element.key == 'followUp25_axisTwo') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisFour') {
            element.value = 'Moderate'
          }
          if (element.key == 'followUp25_axisFive') {
            element.value = '70'
          }
          if (element.key == 'followUp25_WellGroomed') {
            element.value = true
          }
          if (element.key == 'followUp25_Clean') {
            element.value = true
          }

          if (element.key == 'followUp25_Person') {
            element.value = true
          }
          if (element.key == 'followUp25_Place') {
            element.value = true
          }
          if (element.key == 'followUp25_Time') {
            element.value = true
          }
          if (element.key == 'followUp25_Purpose') {
            element.value = true
          }
          if (element.key == 'followUp25_Normal') {
            element.value = true
          }
          if (element.key == 'followUp25_Guarded') {
            element.value = true
          }
          if (element.key == 'followUp25_Friendly') {
            element.value = true
          }
          if (element.key == 'followUp25_Ambulatory') {
            element.value = true
          }
          if (element.key == 'followUp25_Anxious') {
            element.value = true
          }
          if (element.key == 'followUp25_Irritable') {
            element.value = true
          }
          if (element.key == 'followUp25_Flat') {
            element.value = true
          }
          if (element.key == 'followUp25_Tangential') {
            element.value = true
          }
          if (element.key == 'followUp25_Hallucinations') {
            element.value = true
          }
          if (element.key == 'followUp25_PersecutoryDelusions') {
            element.value = true
          }
          if (element.key == 'followUp25_ShortTerm') {
            element.value = true
          }
          if (element.key == 'followUp25_ShortTerm') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp25_LongTerm') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp25_Insight') {
            element.value = 'Fair to Poor'
          }
          if (element.key == 'followUp25_Judgment') {
            element.value = 'Fair to Poor'
          }
        })
      } else if (changedDiagnosis == 'Autism') {
        this.formService.mednoteFollowUpTwentyFiveConfig.forEach((element: any, id: any) => {
          if (element.key == 'followUp25_ChiefComplain') {
            element.value = 'AUTISM'
          }
          if (element.key == 'followUp25_WellGroomed') {
            element.value = true
          }
          if (element.key == 'followUp25_Clean') {
            element.value = true
          }
          if (element.key == 'followUp25_Person') {
            element.value = true
          }
          if (element.key == 'followUp25_Place') {
            element.value = true
          }
          if (element.key == 'followUp25_Time') {
            element.value = true
          }
          if (element.key == 'followUp25_Purpose') {
            element.value = true
          }
          if (element.key == 'followUp25_Disorganized_speech') {
            element.value = true
          }
          if (element.key == 'followUp25_Cooperative') {
            element.value = true
          }
          if (element.key == 'followUp25_Friendly') {
            element.value = true
          }
          if (element.key == 'followUp25_Anxious') {
            element.value = true
          }
          if (element.key == 'followUp25_Appropriate_TP') {
            element.value = true
          }
          if (element.key == 'followUp25_Disorganized_TP') {
            element.value = true
          }
          if (element.key == 'followUp25_Full') {
            element.value = true
          }
          if (element.key == 'followUp25_Ambulatory') {
            element.value = true
          }
          if (element.key == 'followUp25_ShortTerm') {
            element.value = 'Impaired'
          }
          if (element.key == 'followUp25_LongTerm') {
            element.value = 'Impaired'
          }
          if (element.key == 'followUp25_Insight') {
            element.value = 'Poor'
          }
          if (element.key == 'followUp25_Judgment') {
            element.value = 'Poor'
          }
          if (element.key === 'Autism25_axisI') {
            element.value = true
          }
          if (element.key == 'followUp25_axisTwo') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisFour') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisFive') {
            element.value = '70'
          }
        })
      } else if (changedDiagnosis == 'Eating Disorder') {
        this.formService.mednoteFollowUpTwentyFiveConfig.forEach((element: any, id: any) => {
          if (element.key == 'followUp25_ChiefComplain') {
            element.value = 'EATING DISORDER'
          }
          if (element.key == 'followUp25_WellGroomed') {
            element.value = true
          }
          if (element.key == 'followUp25_Clean') {
            element.value = true
          }
          if (element.key == 'followUp25_Person') {
            element.value = true
          }
          if (element.key == 'followUp25_Place') {
            element.value = true
          }
          if (element.key == 'followUp25_Time') {
            element.value = true
          }
          if (element.key == 'followUp25_Purpose') {
            element.value = true
          }
          if (element.key == 'followUp25_Normal') {
            element.value = true
          }
          if (element.key == 'followUp25_Cooperative') {
            element.value = true
          }
          if (element.key == 'followUp25_Friendly') {
            element.value = true
          }
          if (element.key == 'followUp25_Anxious') {
            element.value = true
          }
          if (element.key == 'followUp25_Euthymic') {
            element.value = true
          }
          if (element.key == 'followUp25_Appropriate_TP') {
            element.value = true
          }
          if (element.key == 'followUp25_GoalDirectedAndLogical') {
            element.value = true
          }
          if (element.key == 'followUp25_Appropriate_TC') {
            element.value = true
          }
          if (element.key == 'followUp25_Full') {
            element.value = true
          }
          if (element.key == 'followUp25_Ambulatory') {
            element.value = true
          }
          if (element.key == 'followUp25_ShortTerm') {
            element.value = 'Impaired'
          }
          if (element.key == 'followUp25_LongTerm') {
            element.value = 'Impaired'
          }
          if (element.key == 'followUp25_Insight') {
            element.value = 'Fair'
          }
          if (element.key == 'followUp25_Judgment') {
            element.value = 'Fair'
          }
          if (element.key === 'EatingDisorder25_axisI') {
            element.value = true
          }
          if (element.key == 'followUp25_axisTwo') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisFour') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisFive') {
            element.value = '70'
          }
        })
      } else if (changedDiagnosis == 'OCD') {
        this.formService.mednoteFollowUpTwentyFiveConfig.forEach((element: any, id: any) => {
          if (element.key == 'followUp25_ChiefComplain') {
            element.value = 'OCD (Obsessive-compulsive disorder)'
          }
          if (element.key == 'OcdDisorder25_axisI') {
            element.value = true
          }
          if (element.key == 'followUp25_axisTwo') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp25_axisFour') {
            element.value = 'Moderate'
          }
          if (element.key == 'followUp25_axisFive') {
            element.value = '70'
          }
          if (element.key == 'followUp25_WellGroomed') {
            element.value = true
          }
          if (element.key == 'followUp25_Clean') {
            element.value = true
          }
          if (element.key == 'followUp25_Person') {
            element.value = true
          }
          if (element.key == 'followUp25_Place') {
            element.value = true
          }
          if (element.key == 'followUp25_Time') {
            element.value = true
          }
          if (element.key == 'followUp25_Purpose') {
            element.value = true
          }
          if (element.key == 'followUp25_Normal') {
            element.value = true
          }
          if (element.key == 'followUp25_Cooperative') {
            element.value = true
          }
          if (element.key == 'followUp25_Friendly') {
            element.value = true
          }
          if (element.key == 'followUp25_Ambulatory') {
            element.value = true
          }
          if (element.key == 'followUp25_Anxious') {
            element.value = true
          }
          if (element.key == 'followUp25_Full') {
            element.value = true
          }
          if (element.key == 'followUp25_Appropriate_TP') {
            element.value = true
          }
          if (element.key == 'followUp25_GoalDirectedAndLogical') {
            element.value = true
          }
          if (element.key == 'followUp25_Appropriate_TC') {
            element.value = true
          }
          if (element.key == 'followUp25_ShortTerm') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp25_LongTerm') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp25_Insight') {
            element.value = 'Fair'
          }
          if (element.key == 'followUp25_Judgment') {
            element.value = 'Fair'
          }
        })
      }
    }

    if (this.MednoteData.Duration === '45 Minutes' && this.MednoteData.noteType === 'Monthly Medication Management') {
      this.formService.mednoteFollowUpFortyFiveConfig.forEach(element => {
        if (element.controlType === 'checkbox-tik' || element.controlType === 'checkbox-tik-one') {
          element.value = false
        }
        if (element.controlType === 'custom-radio') {
          element.value = ''
        }
        if (
          element.key === 'followUp45_coordinationOfCare' ||
          element.key === 'followUp45_axisTwo' ||
          element.key === 'followUp45_axisThree' ||
          element.key === 'followUp45_axisFour' ||
          element.key === 'followUp45_axisFive'
        ) {
          element.value = ''
        }
        if (element.key == 'followUp45_assessment') {
          element.value = 'DSM-V'
        }
        if (element.key === 'diagnosisMedications2') {
          let medicationTypes = [
            { key: 'psychiatric', label: 'Psychiatric' },
            { key: 'benzodiazepines', label: 'Benzodiazepines' },
            { key: 'medicalAdderallStimulants', label: 'Medical Adderall Stimulants' },
          ]
          let intakeMeds = []
          medicationTypes.forEach((medType, index) => {
            if (this.userdata.medicalHistory[medType.key].flag === true) {
              this.userdata.medicalHistory[medType.key].medications.map(med => {
                intakeMeds.push({ medicine: `${med.medicine}`, dosage: `${med.dosage}` })
              })
            }
          })
          if (intakeMeds.length === 0) {
            intakeMeds.push({ medicine: ``, dosage: `` })
          }
          let medFields = this.massageMedicationData(intakeMeds)
          element.formInputs = []
          for (let i = medFields.length - 1; i >= 0; i--) {
            element.formInputs.unshift(medFields[i])
          }
        }
        if (element.editAccessKey == 'Procedure/Type of Visit') {
          element.value =
            'CPT 99214: Established outpatient, office visit, moderate complexity, Detailed history, and physical exam'
        }
        if (element.key == 'followUp45_potentialSideEffect') {
          element.value =
            'The patient has been informed of the potential side effects the medication can give and instructed to contact us immediately if conditions get worse. \nPatient was instructed to contact the office or myself if any problems should arise.'
        }
        // if (element.key == 'sideEffects') {
        //   element.value = false
        // }
        if (element.key == 'sideEffectsDetails') {
          // element.showontoggle = true
          if (this.appointMentdata !== null) {
            element.value =
              this.appointMentdata.healthDetails.medicines_side_effects &&
              this.appointMentdata.healthDetails.medicines_side_effects_description
                ? this.appointMentdata.healthDetails.medicines_side_effects_description
                : 'No side-effects noticed.'
          } else {
            element.value = 'No side-effects noticed.'
          }
        }
        if (element.key === 'followUp45_Allergies') {
          const allergies =
            this.appointMentdata !== null
              ? [
                  this.userdata.medicalHistory.knownAllergies,
                  this.userdata.medicalHistory.medicalKnownAllergies,
                  this.appointMentdata.healthDetails.outburst_allergies_description,
                ]
              : [this.userdata.medicalHistory.knownAllergies, this.userdata.medicalHistory.medicalKnownAllergies]
          const filteredAllergies = allergies.filter(allergy => !!allergy)
          element.value = filteredAllergies.length > 0 ? filteredAllergies.join('\n') : 'None'
        }
        if (element.key == 'followUp45_durationOfMedication') {
          element.value = 'One month supply of the following medications'
        }
        if (element.key == 'followUp45_plan') {
          element.value =
            'Client got support, guidance, and reassurance after the visit. Client diagnosis was reviewed and discussed medication rationale. Client was also advised, time management strategies and digital fasting. \nClient was encouraged to email if any side effects or new onset symptoms are observed \nClient is encouraged also to seek holistic approach like walking, running, meditation. '
        }
        if (element.key == 'followUp45_DoctorNote') {
          let elementToRemove = " Dear Dr. Prayaga's Office,"
          this.doctorNotes = this.doctorNotes.filter(item => item !== elementToRemove)
          let notes = this.doctorNotes.join('\n ')
          element.value =
            Object.keys(this.OldNoteData).length !== 0
              ? notes.replace(/[ \t\n]+$/, '') +
                '\n\n' +
                this.OldNoteData['ChiefComplaintDescription.DoctorNotes'].replace(/[ \t\n]+$/, '')
              : notes.replace(/[ \t\n]+$/, '')
        }
        if (element.key === 'followUp45_DurationOfVisit') {
          element.value = 45
        }
        if (element.key === 'followUp45_Code') {
          element.value = '99214'
        }
        if (element.key === 'followUp45_Date') {
          let evalDate = this.MednoteData.AppointmentDate.split('-')
          element.value = `${evalDate[1]}/${evalDate[2]}/${evalDate[0]}`
        }
      })
      if (changedDiagnosis == 'ADHD') {
        this.formService.mednoteFollowUpFortyFiveConfig.forEach((element: any, id: any) => {
          if (element.key == 'ADHD45_axisI') {
            element.value = true
          }
          if (element.key == 'followUp45_axisTwo') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisFour') {
            element.value = 'Moderate'
          }
          if (element.key == 'followUp45_axisFive') {
            element.value = '70'
          }
          if (element.key == 'followUp45_Full') {
            element.value = false
          }
          if (element.key == 'followUp45_ChiefComplain') {
            element.value = 'ADHD'
          }
          if (element.key == 'followUp45_statedAge') {
            element.value = true
          }
          if (element.key == 'followUp45_Person') {
            element.value = true
          }
          if (element.key == 'followUp45_Place') {
            element.value = true
          }
          if (element.key == 'followUp45_Purpose') {
            element.value = true
          }
          if (element.key == 'followUp45_Euthymic') {
            element.value = true
          }
          if (element.key == 'followUp45_NormalMovements') {
            element.value = true
          }
          if (element.key == 'followUp45_Normal') {
            element.value = true
          }
          if (element.key == 'followUp45_Cooperative') {
            element.value = true
          }
          if (element.key == 'followUp45_PreoccupationWithIllness') {
            element.value = true
          }
          if (element.key == 'followUp45_CooperativeBehaviour') {
            element.value = true
          }
          if (element.key == 'followUp45_LogicalandCoherent') {
            element.value = true
          }
          if (element.key == 'followUp45_RecentMemory') {
            element.value = 'Normal'
          }
          if (element.key == 'followUp45_CognitiveFunction') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp45_Insight') {
            element.value = 'Good'
          }
          if (element.key == 'followUp45_Judgment') {
            element.value = 'Good'
          }
          //  if(element.key == 'followUp45_Alertness'){ element.value = "Inattentive" }
          if (element.key == 'followUp45_IntellectualLevel') {
            element.value = 'Average'
          }
          if (element.key == 'followUp45_ImpulseControl') {
            element.value = 'Good'
          }
          if (element.key == 'followUp45_SIorHI') {
            element.value = 'No'
          }
          if (element.key == 'followUp45_AttentationorConcentration') {
            element.value = 'Partially impaired'
          }
        })
      } else if (changedDiagnosis == 'Anxiety') {
        this.formService.mednoteFollowUpFortyFiveConfig.forEach((element: any, id: any) => {
          if (element.key == 'Anxiety45_axisI') {
            element.value = true
          }
          if (element.key == 'followUp45_axisTwo') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisFour') {
            element.value = 'Moderate'
          }
          if (element.key == 'followUp45_axisFive') {
            element.value = '70'
          }
          if (element.key == 'followUp45_ChiefComplain') {
            element.value = 'Anxiety Disorder'
          }
          if (element.key == 'followUp45_statedAge') {
            element.value = true
          }
          if (element.key == 'followUp45_Person') {
            element.value = true
          }
          if (element.key == 'followUp45_Place') {
            element.value = true
          }
          if (element.key == 'followUp45_Purpose') {
            element.value = true
          }
          if (element.key == 'followUp45_Anxious') {
            element.value = true
          }
          if (element.key == 'followUp45_NormalMovements') {
            element.value = true
          }
          if (element.key == 'followUp45_Normal') {
            element.value = true
          }
          if (element.key == 'followUp45_Cooperative') {
            element.value = true
          }
          if (element.key == 'followUp45_PreoccupationWithIllness') {
            element.value = true
          }
          if (element.key == 'followUp45_CooperativeBehaviour') {
            element.value = true
          }
          if (element.key == 'followUp45_LogicalandCoherent') {
            element.value = true
          }

          if (element.key == 'followUp45_AttentationorConcentration') {
            element.value = 'Partially impaired'
          }
          if (element.key == 'followUp45_RecentMemory') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp45_CognitiveFunction') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp45_Insight') {
            element.value = 'Good'
          }
          if (element.key == 'followUp45_Judgment') {
            element.value = 'Good'
          }
          if (element.key == 'followUp45_Alertness') {
            element.value = ''
          }
          if (element.key == 'followUp45_IntellectualLevel') {
            element.value = 'Average'
          }
          if (element.key == 'followUp45_ImpulseControl') {
            element.value = 'Good'
          }
          if (element.key == 'followUp45_SIorHI') {
            element.value = 'No'
          }
          //   if(element.key == 'followUp45_AttentationorConcentration'){ element.value = "" }
          if (element.key == 'followUp45_RecentMemory') {
            element.value = 'Normal'
          }
        })
      } else if (changedDiagnosis == 'Depression') {
        this.formService.mednoteFollowUpFortyFiveConfig.forEach((element: any, id: any) => {
          if (element.key == 'followUp45_ChiefComplain') {
            element.value = 'MAJOR DEPRESSION'
          }
          if (element.key == 'Depression45_axisI') {
            element.value = true
          }
          if (element.key == 'followUp45_axisTwo') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisFour') {
            element.value = 'Moderate'
          }
          if (element.key == 'followUp45_axisFive') {
            element.value = '70'
          }
          // if (element.key == 'Depression45_axisI_I') {
          //   element.value = true
          // }
          if (element.key == 'followUp45_statedAge') {
            element.value = true
          }
          if (element.key == 'followUp45_Person') {
            element.value = true
          }
          if (element.key == 'followUp45_Place') {
            element.value = true
          }
          if (element.key == 'followUp45_Purpose') {
            element.value = true
          }
          if (element.key == 'followUp45_Time') {
            element.value = true
          }
          if (element.key == 'followUp45_Depressed') {
            element.value = true
          }
          if (element.key == 'followUp45_Normal') {
            element.value = true
          }
          if (element.key == 'followUp45_Normal') {
            element.value = true
          }
          if (element.key == 'followUp45_CooperativeBehaviour') {
            element.value = true
          }
          if (element.key == 'followUp45_Cooperative') {
            element.value = true
          }
          if (element.key == 'followUp45_PreoccupationWithIllness') {
            element.value = true
          }
          if (element.key == 'followUp45_LogicalandCoherent') {
            element.value = true
          }

          if (element.key == 'followUp45_Alertness') {
            element.value = 'Inattentive'
          }
          if (element.key == 'followUp45_IntellectualLevel') {
            element.value = 'Average'
          }

          if (element.key == 'followUp45_CognitiveFunction') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp45_AttentationorConcentration') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp45_RecentMemory') {
            element.value = 'Normal'
          }
          if (element.key == 'followUp45_Insight') {
            element.value = 'Good'
          }

          if (element.key == 'followUp45_ImpulseControl') {
            element.value = 'Good'
          }
          if (element.key == 'followUp45_SIorHI') {
            element.value = 'No'
          }

          if (element.key == 'followUp45_Judgment') {
            element.value = 'Good'
          }
        })
      } else if (changedDiagnosis == 'Bipolar') {
        this.formService.mednoteFollowUpFortyFiveConfig.forEach((element: any, id: any) => {
          if (element.key == 'followUp45_Judgment') {
            element.value = 'Good'
          }
          if (element.key == 'followUp45_Alertness') {
            element.value = 'Inattentive'
          }
          if (element.key == 'followUp45_ChiefComplain') {
            element.value = 'BI-POLAR II'
          }
          if (element.key == 'Bipolar45_axisI') {
            element.value = true
          }
          if (element.key == 'followUp45_axisTwo') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisFour') {
            element.value = 'Moderate'
          }
          if (element.key == 'followUp45_axisFive') {
            element.value = '70'
          }
          if (element.key == 'followUp45_statedAge') {
            element.value = true
          }
          if (element.key == 'followUp45_Person') {
            element.value = true
          }
          if (element.key == 'followUp45_Place') {
            element.value = true
          }
          if (element.key == 'followUp45_Purpose') {
            element.value = true
          }
          if (element.key == 'followUp45_Time') {
            element.value = true
          }
          if (element.key == 'followUp45_Irritable') {
            element.value = true
          }
          if (element.key == 'followUp45_Irritable') {
            element.value = true
          }
          if (element.key == 'followUp45_Pressured') {
            element.labeltext = 'Slightly pressured'
            element.value = true
          }
          if (element.key == 'followUp45_CooperativeBehaviour') {
            element.value = true
          }
          if (element.key == 'followUp45_Cooperative') {
            element.value = true
          }
          if (element.key == 'followUp45_PreoccupationWithIllness') {
            element.value = true
          }
          if (element.key == 'followUp45_LogicalandCoherent') {
            element.value = true
          }

          if (element.key == 'followUp45_IntellectualLevel') {
            element.value = 'Average'
          }

          if (element.key == 'followUp45_CognitiveFunction') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp45_AttentationorConcentration') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp45_RecentMemory') {
            element.value = 'Normal'
          }
          if (element.key == 'followUp45_Insight') {
            element.value = 'Good'
          }

          if (element.key == 'followUp45_ImpulseControl') {
            element.value = 'Good'
          }
          if (element.key == 'followUp45_SIorHI') {
            element.value = 'No'
          }

          if (element.key == 'followUp45_Judgment') {
            element.value == 'Fair'
          }
        })
      } else if (changedDiagnosis == 'Schizophrenia') {
        this.formService.mednoteFollowUpFortyFiveConfig.forEach((element: any, id: any) => {
          if (element.key == 'followUp45_ChiefComplain') {
            element.value = 'SCHIZOPHRENIFORM DISORDER'
          }
          if (element.key == 'Schizophrenia45_axisI') {
            element.value = true
          }
          if (element.key == 'followUp45_axisTwo') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisFour') {
            element.value = 'Moderate'
          }
          if (element.key == 'followUp45_axisFive') {
            element.value = '70'
          }
          if (element.key == 'followUp45_statedAge') {
            element.value = true
          }
          if (element.key == 'followUp45_Person') {
            element.value = true
          }
          if (element.key == 'followUp45_Place') {
            element.value = true
          }
          if (element.key == 'followUp45_Time') {
            element.value = true
          }
          if (element.key == 'followUp45_Normal') {
            element.value = true
          }
          if (element.key == 'followUp45_Irritable') {
            element.value = true
          }
          if (element.key == 'followUp45_Cooperative') {
            element.value = true
          }
          if (element.key == 'followUp45_DelusionsOnEX') {
            element.value = true
          }
          if (element.key == 'followUp45_GoodEyeContact') {
            element.value = true
          }
          if (element.key == 'followUp45_Flat') {
            element.value = true
          }
          if (element.key == 'followUp45_LogicalandCoherent') {
            element.value = true
          }

          if (element.key == 'followUp45_AttentationorConcentration') {
            element.value = 'Partially impaired'
          }
          if (element.key == 'followUp45_RecentMemory') {
            element.value = 'Normal'
          }

          if (element.key == 'followUp45_ImpulseControl') {
            element.value = 'Fair'
          }
          if (element.key == 'followUp45_SIorHI') {
            element.value = 'No'
          }

          if (element.key == 'followUp45_Judgment') {
            element.value = 'Good'
          }
        })
      } else if (changedDiagnosis == 'Adjustment Disorder') {
        this.formService.mednoteFollowUpFortyFiveConfig.forEach((element: any, id: any) => {
          if (element.key == 'AdjustmentDisorder45_axisI') {
            element.value = true
          }
          if (element.key == 'followUp45_axisTwo') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisFour') {
            element.value = 'Moderate'
          }
          if (element.key == 'followUp45_axisFive') {
            element.value = '70'
          }
          if (element.key == 'followUp45_ChiefComplain') {
            element.value = 'ADJUSTMENT DISORDER'
          }
          if (element.key == 'followUp45_WellGroomed') {
            element.value = true
          }
          if (element.key == 'followUp45_Clean') {
            element.value = true
          }
          if (element.key == 'followUp45_Person') {
            element.value = true
          }
          if (element.key == 'followUp45_Place') {
            element.value = true
          }
          if (element.key == 'followUp45_Purpose') {
            element.value = true
          }
          if (element.key == 'followUp45_Time') {
            element.value = true
          }
          if (element.key == 'followUp45_Normal') {
            element.value = true
          }
          if (element.key == 'followUp45_Cooperative') {
            element.value = true
          }
          if (element.key == 'followUp45_Friendly') {
            element.value = true
          }
          if (element.key == 'followUp45_Ambulatory') {
            element.value = true
          }
          if (element.key == 'followUp45_Anxious') {
            element.value = true
          }
          if (element.key == 'followUp45_Full') {
            element.value = true
          }
          if (element.key == 'followUp45_GoalDirectedAndLogical') {
            element.value = true
          }
          if (element.key == 'followUp45_Appropriate_TP') {
            element.value = true
          }
          if (element.key == 'followUp45_Appropriate_TC') {
            element.value = true
          }
          if (element.key == 'followUp45_ShortTMemory') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp45_LongTMemory') {
            element.value = 'Intact'
          }
          if (element.key == 'followUp45_Insight') {
            element.value = 'Fair'
          }
          if (element.key == 'followUp45_Judgment') {
            element.value = 'Fair'
          }
        })
      } else if (changedDiagnosis == 'Oppositional Disorder') {
        this.formService.mednoteFollowUpFortyFiveConfig.forEach((element: any, id: any) => {
          if (element.key == 'OppositionalDisorder45_axisI') {
            element.value = true
          }
          if (element.key == 'followUp45_axisTwo') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisFour') {
            element.value = 'Moderate'
          }
          if (element.key == 'followUp45_axisFive') {
            element.value = '70'
          }
          if (element.key == 'followUp45_ChiefComplain') {
            element.value = 'OPPOSITIONAL DISORDER'
          }
          if (element.key == 'followUp45_WellGroomed') {
            element.value = true
          }
          if (element.key == 'followUp45_Clean') {
            element.value = true
          }
          if (element.key == 'followUp45_Person') {
            element.value = true
          }
          if (element.key == 'followUp45_Place') {
            element.value = true
          }
          if (element.key == 'followUp45_Purpose') {
            element.value = true
          }
          if (element.key == 'followUp45_Time') {
            element.value = true
          }
          if (element.key == 'followUp45_Normal') {
            element.value = true
          }
          if (element.key == 'followUp45_Cooperative') {
            element.value = true
          }
          if (element.key == 'followUp45_Friendly') {
            element.value = true
          }
          if (element.key == 'followUp45_Ambulatory') {
            element.value = true
          }
          if (element.key == 'followUp45_Euthymic') {
            element.value = true
          }
          if (element.key == 'followUp45_Anxious') {
            element.value = true
          }
          if (element.key == 'followUp45_Full') {
            element.value = true
          }
          if (element.key == 'followUp45_GoalDirectedAndLogical') {
            element.value = true
          }
          if (element.key == 'followUp45_Appropriate_TP') {
            element.value = true
          }
          if (element.key == 'followUp45_Appropriate_TC') {
            element.value = true
          }
          if (element.key == 'followUp45_ShortTMemory') {
            element.value = 'Impaired'
          }
          if (element.key == 'followUp45_LongTMemory') {
            element.value = 'Impaired'
          }
          if (element.key == 'followUp45_Insight') {
            element.value = 'Fair'
          }
          if (element.key == 'followUp45_Judgment') {
            element.value = 'Fair'
          }
        })
      } else if (changedDiagnosis == 'Autism') {
        this.formService.mednoteFollowUpFortyFiveConfig.forEach((element: any, id: any) => {
          if (element.key == 'followUp45_ChiefComplain') {
            element.value = 'AUTISM'
          }
          if (element.key == 'followUp45_WellGroomed') {
            element.value = true
          }
          if (element.key == 'followUp45_Clean') {
            element.value = true
          }
          if (element.key == 'followUp45_Person') {
            element.value = true
          }
          if (element.key == 'followUp45_Place') {
            element.value = true
          }
          if (element.key == 'followUp45_Purpose') {
            element.value = true
          }
          if (element.key == 'followUp45_Time') {
            element.value = true
          }
          if (element.key == 'followUp45_Disorganized_speech') {
            element.value = true
          }
          if (element.key == 'followUp45_Cooperative') {
            element.value = true
          }
          if (element.key == 'followUp45_Friendly') {
            element.value = true
          }
          if (element.key == 'followUp45_Ambulatory') {
            element.value = true
          }
          if (element.key == 'followUp45_Anxious') {
            element.value = true
          }
          if (element.key == 'followUp45_Full') {
            element.value = true
          }
          if (element.key == 'followUp45_Appropriate_TP') {
            element.value = true
          }
          if (element.key == 'followUp45_Disorganized_TP') {
            element.value = true
          }
          if (element.key == 'followUp45_ShortTMemory') {
            element.value = 'Impaired'
          }
          if (element.key == 'followUp45_LongTMemory') {
            element.value = 'Impaired'
          }
          if (element.key == 'followUp45_Insight') {
            element.value = 'Poor'
          }
          if (element.key == 'followUp45_Judgment') {
            element.value = 'Poor'
          }
          if (element.key == 'Autism45_axisI') {
            element.value = true
          }
          if (element.key == 'followUp45_axisTwo') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisFour') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisFive') {
            element.value = '70'
          }
        })
      } else if (changedDiagnosis == 'Eating Disorder') {
        this.formService.mednoteFollowUpFortyFiveConfig.forEach((element: any, id: any) => {
          if (element.key == 'followUp45_ChiefComplain') {
            element.value = 'EATING DISORDER'
          }
          if (element.key == 'followUp45_WellGroomed') {
            element.value = true
          }
          if (element.key == 'followUp45_Clean') {
            element.value = true
          }
          if (element.key == 'followUp45_Person') {
            element.value = true
          }
          if (element.key == 'followUp45_Place') {
            element.value = true
          }
          if (element.key == 'followUp45_Time') {
            element.value = true
          }
          if (element.key == 'followUp45_Purpose') {
            element.value = true
          }
          if (element.key == 'followUp45_Normal') {
            element.value = true
          }
          if (element.key == 'followUp45_Cooperative') {
            element.value = true
          }
          if (element.key == 'followUp45_Friendly') {
            element.value = true
          }
          if (element.key == 'followUp45_Anxious') {
            element.value = true
          }
          if (element.key == 'followUp45_Euthymic') {
            element.value = true
          }
          if (element.key == 'followUp45_Appropriate_TP') {
            element.value = true
          }
          if (element.key == 'followUp45_GoalDirectedAndLogical') {
            element.value = true
          }
          if (element.key == 'followUp45_Appropriate_TC') {
            element.value = true
          }
          if (element.key == 'followUp45_Full') {
            element.value = true
          }
          if (element.key == 'followUp45_Ambulatory') {
            element.value = true
          }
          if (element.key == 'followUp45_ShortTMemory') {
            element.value = 'Impaired'
          }
          if (element.key == 'followUp45_LongTMemory') {
            element.value = 'Impaired'
          }
          if (element.key == 'followUp45_Insight') {
            element.value = 'Fair'
          }
          if (element.key == 'followUp45_Judgment') {
            element.value = 'Fair'
          }
          if (element.key === 'EatingDisorder45_axisI') {
            element.value = true
          }
          if (element.key == 'followUp45_axisTwo') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisFour') {
            element.value = 'Moderate'
          }
          if (element.key == 'followUp45_axisFive') {
            element.value = '70'
          }
        })
      } else if (changedDiagnosis == 'OCD') {
        this.formService.mednoteFollowUpFortyFiveConfig.forEach((element: any, id: any) => {
          if (element.key == 'followUp45_ChiefComplain') {
            element.value = 'OCD(Obsessive-compulsive disorder)'
          }
          if (element.key == 'Ocd45_axisI') {
            element.value = true
          }
          if (element.key == 'followUp45_axisTwo') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisThree') {
            element.value = 'None'
          }
          if (element.key == 'followUp45_axisFour') {
            element.value = 'Moderate'
          }
          if (element.key == 'followUp45_axisFive') {
            element.value = '70'
          }
          if (element.key == 'followUp45_WellGroomed') {
            element.value = true
          }
          if (element.key == 'followUp45_Clean') {
            element.value = true
          }
          if (element.key == 'followUp45_Person') {
            element.value = true
          }
          if (element.key == 'followUp45_Place') {
            element.value = true
          }
          if (element.key == 'followUp45_Time') {
            element.value = true
          }
          if (element.key == 'followUp45_Purpose') {
            element.value = true
          }
          if (element.key == 'followUp45_Normal') {
            element.value = true
          }
          if (element.key == 'followUp45_Friendly') {
            element.value = true
          }
          if (element.key == 'followUp45_Cooperative') {
            element.value = true
          }
          if (element.key == 'followUp45_Full') {
            element.value = true
          }
          if (element.key == 'followUp45_Ambulatory') {
            element.value = true
          }
          if (element.key == 'followUp45_Anxious') {
            element.value = true
          }
          if (element.key == 'followUp45_Appropriate_TP') {
            element.value = true
          }
          if (element.key == 'followUp45_GoalDirectedAndLogical') {
            element.value = true
          }
          if (element.key == 'followUp45_Appropriate_TC') {
            element.value = true
          }
          if (element.key == 'followUp45_ShortTMemory') {
            element.value = 'Impaired'
          }
          if (element.key == 'followUp45_LongTMemory') {
            element.value = 'Impaired'
          }
          if (element.key == 'followUp45_Insight') {
            element.value = 'Fair'
          }
          if (element.key == 'followUp45_Judgment') {
            element.value = 'Fair'
          }
        })
      }
    }

    if (this.MednoteData.noteType === 'Evaluation') {
      let dia
      let evalDiagnosisCode = [
        { name: 'ADHD', id: 'adhd', code: 'F90.0' },
        { name: 'Anxiety', id: 'anxiety', code: 'F41.9' },
        { name: 'Depression', id: 'depression', code: 'F33.2' },
        { name: 'Adjustment Disorder', id: 'adjustment_disorder', code: 'F43.23' },
        { name: 'Bipolar', id: 'bi-polar', code: 'F3181' },
        { name: 'Oppositional Disorder', id: 'oppositional_disorder', code: 'F91.3' },
        { name: 'Schizophrenia', id: 'schizophrenia', code: 'F20.9' },
        { name: 'Autism', id: 'autism', code: 'F84.0' },
        { name: 'Eating Disorder', id: 'eating_disorder', code: 'F50.9' },
        { name: 'OCD', id: 'ocd', code: 'F42.9' },
      ]
      evalDiagnosisCode.forEach(element => {
        if (element.name === changedDiagnosis) {
          dia = element
        }
      })
      this.formService.mednotesEvalConfig.forEach(element => {
        if (element.controlType === 'checkbox-tik' || element.controlType === 'checkbox-tik-one') {
          // all fields having control type as checkbox-tik and checkbox-tik-one will be made value : false
          element.value = false
        }
        if (element.controlType === 'custom-radio') {
          // all fields having control type as custom-radio will be made value : ''
          element.value = ''
        }
        if (element.key == 'annualPsychiatricEvaluation') {
          element.value = this.MednoteData.EvalType == 're' ? false : true
        }
        if (element.key == 'psychiatricEvaluation') {
          element.value = this.MednoteData.EvalType == 're' ? true : false
        }
        if (element.key === 'familyInterview') {
          if (this.userdata.patientBasicInfo.age <= 18) {
            element.value = true
          }
        }
        if (element.key == 'employer' || element.key === 'socalEmployed') {
          element.value = this.userdata.insuranceDetails.policyHolder.policyHolderEmployer
        }
        if (element.key == 'mednote_Code') {
          if (this.userdata.patientBasicInfo.age <= 18) {
            element.value = dia.code + '( ' + dia.name + ' Under 18 )'
          } else {
            element.value = dia.code + ' (' + dia.name + ')'
          }
        }
        if (element.key === 'diagnosisMedications2') {
          let medicationTypes = [
            { key: 'psychiatric', label: 'Psychiatric' },
            { key: 'benzodiazepines', label: 'Benzodiazepines' },
            { key: 'medicalAdderallStimulants', label: 'Medical Adderall Stimulants' },
          ]
          let intakeMeds = []
          medicationTypes.forEach((medType, index) => {
            if (this.userdata.medicalHistory[medType.key].flag === true) {
              this.userdata.medicalHistory[medType.key].medications.map(med => {
                intakeMeds.push({ medicine: `${med.medicine}`, dosage: `${med.dosage}` })
              })
            }
          })
          if (intakeMeds.length === 0) {
            intakeMeds.push({ medicine: ``, dosage: `` })
          }
          let medFields = this.massageMedicationData(intakeMeds)
          element.formInputs = []
          for (let i = medFields.length - 1; i >= 0; i--) {
            element.formInputs.unshift(medFields[i])
          }
        }
        if (element.key == 'DoctorNote') {
          let elementToRemove = " Dear Dr. Prayaga's Office,"
          this.doctorNotes = this.doctorNotes.filter(item => item !== elementToRemove)
          let notes = this.doctorNotes.join('\n ')
          element.value =
            Object.keys(this.OldNoteData).length !== 0
              ? notes.replace(/[ \t\n]+$/, '') +
                '\n\n' +
                this.OldNoteData['ChiefComplaintDescription.DoctorNotes'].replace(/[ \t\n]+$/, '')
              : notes.replace(/[ \t\n]+$/, '')
        }
        if (
          element.key === 'axisTwo' ||
          element.key === 'axisThree' ||
          element.key === 'axisFour' ||
          element.key === 'axisFive' ||
          element.key === 'pastPsychiatric' ||
          element.key === 'emotionalAbuse' ||
          element.key === 'physicalAbuse' ||
          element.key === 'suicideAbuse' ||
          element.key === 'familyPsych' ||
          element.key === 'socalEducation' ||
          element.key === 'socalSiblings' ||
          element.key === 'socalTobaccoUse' ||
          element.key === 'development' ||
          element.key === 'military' ||
          element.key === ''
        ) {
          element.value = ''
        }
        if (element.key === 'allergies') {
          const allergies =
            this.appointMentdata !== null
              ? [
                  this.userdata.medicalHistory.knownAllergies,
                  this.userdata.medicalHistory.medicalKnownAllergies,
                  this.appointMentdata.healthDetails.outburst_allergies_description,
                ]
              : [this.userdata.medicalHistory.knownAllergies, this.userdata.medicalHistory.medicalKnownAllergies]
          const filteredAllergies = allergies.filter(allergy => !!allergy)
          element.value = filteredAllergies.length > 0 ? filteredAllergies.join('\n') : 'None'
        }
        if (element.key == 'pastMedical') {
          let medical = []
          if (this.userdata.medicalHistory.anyMedicalDisorders === true) {
            medical[0] = this.userdata.medicalHistory.anyMedicalDisordersExplain
          }
          if (medical.length !== 0) {
            element.value = medical.join('\n')
          } else {
            element.value = 'None'
          }
        }
        if (element.key === 'medications') {
          let medicationTypes = [
            { key: 'benzodiazepines', label: 'Benzodiazepines' },
            { key: 'medicalAdderallStimulants', label: 'Medical Adderall Stimulants' },
            { key: 'psychiatric', label: 'Psychiatric' },
          ]
          let intakeMeds = []
          medicationTypes.forEach((medType, index) => {
            if (this.userdata.medicalHistory[medType.key].flag === true) {
              const meds = this.userdata.medicalHistory[medType.key].medications.map(med => {
                return `Medicine: ${med.medicine}, Dosage: ${med.dosage}`
              })
              intakeMeds[index] = meds.join('\n')
            }
          })
          if (this.userdata.medicalHistory.nonPsychMeds === true) {
            intakeMeds[3] = this.userdata.medicalHistory.nonPsychMedsExplain
          }
          const medicationsValue = intakeMeds.filter(Boolean) // Remove any empty or undefined entries
          element.value = medicationsValue.length > 0 ? medicationsValue.join('\n') : 'None'
        }
        if (element.key === 'pastSurg') {
          let Surg = []
          if (this.userdata.medicalHistory.patientHospitalized === true) {
            Surg.push(
              `Patient Hospitalized Date - ${this.userdata.medicalHistory.patientHospitalizedDate}, Patient Hospitalized Reason - ${this.userdata.medicalHistory.patientHospitalizedReason}`
            )
          }
          if (this.appointMentdata !== null && this.appointMentdata.medicationConsent.hospitalizedPastMonth === true) {
            Surg.push(
              `Hospital Name: ${this.appointMentdata.medicationConsent.hospitalName}, Hospitalized Reason: ${this.appointMentdata.medicationConsent.hospitalizedReason}`
            )
          }
          element.value = Surg.length > 0 ? Surg.join('\n') : 'None'
        }
        if (element.key === 'substanceAbuse') {
          let medical = []
          if (this.userdata.medicalHistory.anyOpiods === true) {
            medical.push(this.userdata.medicalHistory.anyOpiodsExplain)
          }
          if (this.userdata.medicalHistory.drugAlcoholAbuse === true) {
            medical.push('Have done alcohol or drugs')
          }
          if (this.userdata.medicalHistory.pastHistoryofAlchohol === true) {
            medical.push(this.userdata.medicalHistory.pastHistoryofAlchoholExplain)
          }
          element.value = medical.length > 0 ? medical.join('\n') : 'None'
        }
        if (element.key === 'legal') {
          let legal = []
          if (this.userdata.patientMiscInfo.legalIssues === true) {
            legal.push(this.userdata.patientMiscInfo.miscKnownAllergies1)
          }
          if (this.appointMentdata !== null && this.appointMentdata.healthDetails.legal_issue === true) {
            legal.push(this.appointMentdata.healthDetails.legal_issue_description)
          }
          element.value = legal.length > 0 ? legal.join('\n') : 'None'
        }
        if (element.key === 'alertness_obs') {
          element.value = 'Alert and oriented to person, place, time, and purpose'
        }
        if (element.key === 'ambulatory_obs') {
          element.value = 'Ambulatory, Appears In No Acute Distress.'
        }
        if (element.key === 'evaluationDate') {
          let evalDate = this.MednoteData.AppointmentDate.split('-')
          element.value = `${evalDate[1]}/${evalDate[2]}/${evalDate[0]}`
        }
      })
      //prefilling diagnosis wise
      if (changedDiagnosis === 'ADHD') {
        this.formService.mednotesEvalConfig.forEach((element: any) => {
          if (element.key == 'chiefComplain') {
            if (this.userdata.patientBasicInfo.age <= 18) {
              element.value =
                'Client is having difficulty with concentration, low energy and motivation. Client struggles with completing tasks and assignments due to his low energy and motivation levels. Stress is dominating all aspects in clients life. Family reports client has difficulties in school and I reviewed information available from family and other sources'
            } else {
              element.value =
                'Client is having difficulty with concentration, low energy and motivation. Client struggles with completing tasks and assignments due to his low energy and motivation levels. The stress is dominating all aspects in his life. Client is interested in trying medication to address these symptoms and to improve his quality of life.'
            }
          }
          if (element.key == 'purposeOfVisit') {
            element.value =
              'This is a ' +
              this.userdata.patientBasicInfo.age +
              '-year-old ' +
              this.userdata.gender +
              ' who presents as a patient for a psychiatric evaluation. The Client presents with symptoms of low focus, lack of concentration, procrastination, and distraction issues. '
          }
          //obs
          if (element.key === 'mood_obs') {
            element.value = `Mood: Normal, Affect – Congruent to mod. No EPS.`
          }
          if (element.key === 'addObservations') {
            if (this.userdata.patientBasicInfo.age <= 18) {
              element.value =
                'Client has been experiencing these symptoms from young age. However, lately the studies has created more difficulties completing his/her assignments in a timely manner. His/ her school is very busy and has to spend extra amounts of time completing tasks. His/Her sleep appetite is erratic.  '
            } else {
              element.value =
                'The client has been experiencing these symptoms from a young age. However, lately the work / studies have created more difficulties completing his/her assignments in a timely manner. His/ her job/ school is very busy and has to spend extra amounts of time completing tasks. Client was requesting an assessment and whether he/ she might benefit from treatment for ADHD. Client denies any acute symptoms of depression or psychosis. His/Her sleep and appetite are erratic.'
            }
          }
          if (element.key === 'plan') {
            element.value =
              'Encouraged to take medication as prescribed and eat a healthy diet. Client is also encouraged to seek holistic approaches like walking, running, and meditating. Medication benefits and potential side effects have been discussed with patient. \n I explained the algorithm of these medicines for getting the optimal response with these medicines'
          }
          //symptoms
          if (element.key === 'sleep_DS') {
            element.value = true
          }
          if (element.key === 'decreasedConcentration_DS') {
            element.value = true
          }
          if (element.key === 'decreasedMemory_DS') {
            element.value = true
          }
          if (element.key === 'none_MS') {
            element.value = true
          }
          if (element.key === 'none_PS') {
            element.value = true
          }
          if (element.key === 'none_PTSD') {
            element.value = true
          }
          if (element.key === 'poorImpulseControl_PTSD') {
            element.value = true
          }
          if (element.key === 'muscleTension_AS') {
            element.value = true
          }
          if (element.key === 'failureToPayCloseAttention_ADS') {
            element.value = true
          }
          if (element.key === 'difficultySustainingAttentionInTasks_ADS') {
            element.value = true
          }
          if (element.key === 'fidgets_ADS') {
            element.value = true
          }
          if (element.key === 'forgetfulOfDailyActivities_ADS') {
            element.value = true
          }
          if (element.key === 'procrastination_ADS') {
            element.value = true
          }
          if (element.key === 'easilyDistracted_ADS') {
            element.value = true
          }
          if (element.key === 'makesCarelessMistakesOften_ADS') {
            element.value = true
          }
          //mental status exam
          if (element.key === 'wellGroomed_Appearance') {
            element.value = true
          }
          if (element.key === 'Neat_Appearance') {
            element.value = true
          }
          if (element.key === 'person_Orientation') {
            element.value = true
          }
          if (element.key === 'place_Orientation') {
            element.value = true
          }
          if (element.key === 'time_Orientation') {
            element.value = true
          }
          if (element.key === 'purpose_Orientation') {
            element.value = true
          }
          if (element.key === 'normal_Speech') {
            element.value = true
          }
          if (element.key === 'cooperative_Attitude') {
            element.value = true
          }
          if (element.key === 'friendly_Attitude') {
            element.value = true
          }
          if (element.key === 'ambulatory_PA') {
            element.value = true
          }
          if (element.key === 'anxious_Mood') {
            element.value = true
          }
          if (element.key === 'full_Affect') {
            element.value = true
          }
          if (element.key === 'goalDirectedAndLogical_TP') {
            element.value = true
          }
          if (element.key === 'appropriate_TP') {
            element.value = true
          }
          if (element.key === 'appropriate_TC') {
            element.value = true
          }
          if (element.key === 'ShortTerm') {
            element.value = 'Impaired'
          }
          if (element.key === 'LongTerm') {
            element.value = 'Impaired'
          }
          if (element.key === 'Insight') {
            element.value = 'Fair'
          }
          if (element.key === 'Judgment') {
            element.value = 'Fair'
          }
          if (element.key === 'ADHD_axisI') {
            element.value = true
          }
          if (element.key === 'axisTwo') {
            element.value = 'None'
          }
          if (element.key === 'axisThree') {
            element.value = 'None'
          }
          if(element.key === 'axisFour'){
            if (this.MednoteData.EvalType == 're') {
              element.value = 'Moderate'
            }
            if(this.userdata.patientBasicInfo.age <= 18){
              element.value='None'
            }else{
              element.value='Moderate'
            }
          }
          if (element.key === 'axisFive') {
            element.value = '70'
          }
        })
      } else if (changedDiagnosis === 'Anxiety') {
        this.formService.mednotesEvalConfig.forEach((element: any) => {
          if (element.key === 'chiefComplain') {
            element.value =
              this.userdata.firstName +
              ' is struggling with low mood, low energy, constant worrying, unable to relax and low motivation in addition to the inability to concentrate. Client mood is noticeably anxious and the client reports feeling fatigue. Client has intense anxiety worries on trivial things and client dwells on issues/situations a lot. The anxiety has become overwhelming on a frequent basis. Client sleep and appetite are erratic.'
          }
          if (element.key === 'purposeOfVisit') {
            element.value =
              'This is a ' +
              this.userdata.patientBasicInfo.age +
              ' yr old ' +
              this.userdata.gender +
              ' who presents as a patient for psychiatric evaluation. Client is dealing with a lot of symptoms of Anxiety and stress. Client is feeling at times hopeless, helpless and experiencing low motivation and lack of interest in things etc. Client has denied any suicidal thoughts or plans. Client is also unable to relax and worries.'
          }
          if (element.key === 'mood_obs') {
            element.value = ` Mood: Anxious, Affect - Congruent to mood. No EPS. `
          }
          if (element.key === 'plan') {
            element.value =
              'Client is recommended for medication management and counseling.Client was encouraged to see a therapist and explained the benefits of meditation, breathing techniques to reduce Anxiety symptoms. I explained the algorithm of medicines for getting the optimal response.'
          }
          // symptoms
          if (element.key === 'sleep_DS') {
            element.value = true
          }
          if (element.key === 'depressedMood_DS') {
            element.value = true
          }
          if (element.key === 'decreasedConcentration_DS') {
            element.value = true
          }
          if (element.key === 'decreasedMemory_DS') {
            element.value = true
          }
          if (element.key === 'none_MS') {
            element.value = true
          }
          if (element.key === 'none_PS') {
            element.value = true
          }
          if (element.key === 'poorImpulseControl_PTSD') {
            element.value = true
          }
          if (element.key === 'sweating_AS') {
            element.value = true
          }
          if (element.key === 'excessiveWorrying_AS') {
            element.value = true
          }
          if (element.key === 'chestPain_AS') {
            element.value = true
          }
          if (element.key === 'failureToPayCloseAttention_ADS') {
            element.value = true
          }
          if (element.key === 'makesCarelessMistakesOften_ADS') {
            element.value = true
          }
          if (element.key === 'easilyDistracted_ADS') {
            element.value = true
          }
          if (element.key === 'difficultySustainingAttentionInTasks_ADS') {
            element.value = true
          }
          if (element.key === 'fidgets_ADS') {
            element.value = true
          }
          if (element.key === 'forgetfulOfDailyActivities_ADS') {
            element.value = true
          }
          if (element.key === 'procrastination_ADS') {
            element.value = true
          }
          //mental status exam
          if (element.key === 'wellGroomed_Appearance') {
            element.value = true
          }
          if (element.key === 'Neat_Appearance') {
            element.value = true
          }
          if (element.key === 'person_Orientation') {
            element.value = true
          }
          if (element.key === 'place_Orientation') {
            element.value = true
          }
          if (element.key === 'time_Orientation') {
            element.value = true
          }
          if (element.key === 'purpose_Orientation') {
            element.value = true
          }
          if (element.key === 'normal_Speech') {
            element.value = true
          }
          if (element.key === 'cooperative_Attitude') {
            element.value = true
          }
          if (element.key === 'friendly_Attitude') {
            element.value = true
          }
          if (element.key === 'ambulatory_PA') {
            element.value = true
          }
          if (element.key === 'anxious_Mood') {
            element.value = true
          }
          if (element.key === 'full_Affect') {
            element.value = true
          }
          if (element.key === 'goalDirectedAndLogical_TP') {
            element.value = true
          }
          if (element.key === 'appropriate_TC') {
            element.value = true
          }
          if (element.key === 'appropriate_TP') {
            element.value = true
          }
          if (element.key === 'ShortTerm') {
            element.value = 'Intact'
          }
          if (element.key === 'LongTerm') {
            element.value = 'Intact'
          }
          if (element.key === 'Insight') {
            element.value = 'Fair'
          }
          if (element.key === 'Judgment') {
            element.value = 'Fair'
          }
          if (element.key === 'Anxiety_axisI') {
            element.value = true
          }
          if (element.key === 'axisTwo') {
            element.value = 'None'
          }
          if (element.key === 'axisThree') {
            element.value = 'None'
          }
          if(element.key === 'axisFour'){
              element.value='None'
          }
          if (element.key === 'axisFive') {
            element.value = '70'
          }
        })
      } else if (changedDiagnosis === 'Depression') {
        this.formService.mednotesEvalConfig.forEach((element: any) => {
          if (element.key === 'chiefComplain') {
            if (this.userdata.patientBasicInfo.age <= 18) {
              element.value =
                "Client has been having difficulty with mood. Client cannot concentrate. Client cannot get motivated. Don't have energy. Client feeling stressed and worry a lot. Stress is taking over. Family reports clients have difficulties in school and I reviewed information available from family and other sources."
            } else {
              element.value = `Client is been having difficulty with mood. Client cannot concentrate. Client cannot get motivated. Don't have energy. Client feeling stressed and worry a lot. Stress is taking over. `
            }
          }
          if (element.key === 'purposeOfVisit') {
            if (this.userdata.patientBasicInfo.age <= 18) {
              element.value =
                'This is a ' +
                this.userdata.patientBasicInfo.age +
                ' yr old ' +
                this.userdata.gender +
                ' who presents as a patient for psychiatric evaluation. Client is dealing with a lot of symptoms of depression. Client is feeling at times hopeless, helpless, experiencing low motivation and lack of interest in things etc. Client has denied any suicidal thoughts or plans. Clients came with caregivers. Client reports depressive symptoms for a while. Client has been thinking negatively and has anhedonia.              '
            } else {
              element.value =
                'This is a ' +
                this.userdata.patientBasicInfo.age +
                ' yr old ' +
                this.userdata.gender +
                ' who presents as a patient for psychiatric evaluation. Client is dealing with a lot of symptoms of depression. Client is feeling at times hopeless, helpless and experiencing low motivation and lack of interest in things etc. Client has denied any suicidal thoughts or plans. Client sleep and appetite are erratic.'
            }
          }
          if (element.key === 'mood_obs') {
            element.value = 'Mood: Sad, Affect - Congruent to mod. No EPS.'
          }

          if (element.key === 'addObservations') {
            if (this.userdata.patientBasicInfo.age >= 18) {
              element.value =
                'Client reports depressive symptoms for a while. Client has been thinking negatively and has anhedonia.'
            }
          }
          if (element.key === 'diagnosticAssessment') {
            if (this.MednoteData.EvalType == 're') {
              element.value = true
            }
          } // according to reeval dep template it was checked
          if (element.key === 'plan') {
            element.value =
              'Client is recommended for medication management, counseling, and careful consideration of treatments like TMS and Ketamine. Encouraged to take medication as prescribed and eat a healthy diet. Client was encouraged to take medications to assist him. Client is also encouraged to seek holistic approaches like meditating, walking and exercise. Medication benefits and potential side effects have been discussed with patient. \nI explained the algorithm medicines for getting the optimal response with these medicines.'
          }
          // symptoms
          if (element.key === 'sleep_DS') {
            element.value = true
          }
          if (element.key === 'depressedMood_DS') {
            element.value = true
          }
          if (element.key === 'decreasedConcentration_DS') {
            element.value = true
          }
          if (element.key === 'decreasedMemory_DS') {
            element.value = true
          }
          if (element.key === 'distractibility_MS') {
            element.value = true
          }
          if (element.key === 'none_PS') {
            element.value = true
          }
          if (element.key === 'none_PTSD') {
            element.value = true
          }
          if (element.key === 'poorImpulseControl_PTSD') {
            element.value = true
          }
          // if(element.key === "sweating_AS"){ element.value = true }
          if (element.key === 'excessiveWorrying_AS') {
            element.value = true
          }
          // if(element.key === "chestPain_AS"){ element.value = true }
          if (element.key === 'failureToPayCloseAttention_ADS') {
            element.value = true
          }
          if (element.key === 'makesCarelessMistakesOften_ADS') {
            element.value = true
          }
          if (element.key === 'easilyDistracted_ADS') {
            element.value = true
          }
          if (element.key === 'difficultySustainingAttentionInTasks_ADS') {
            element.value = true
          }
          if (element.key === 'fidgets_ADS') {
            element.value = true
          }
          if (element.key === 'forgetfulOfDailyActivities_ADS') {
            element.value = true
          }
          if (element.key === 'procrastination_ADS') {
            element.value = true
          }

          //mental status exam
          if (element.key === 'wellGroomed_Appearance') {
            element.value = true
          }
          if (element.key === 'Neat_Appearance') {
            element.value = true
          }
          if (element.key === 'person_Orientation') {
            element.value = true
            element.labeltext = 'Normal Person'
          }
          if (element.key === 'place_Orientation') {
            element.value = true
          }
          if (element.key === 'time_Orientation') {
            element.value = true
          }
          if (element.key === 'purpose_Orientation') {
            element.value = true
          }
          if (element.key === 'normal_Speech') {
            element.value = true
            element.labeltext = 'Normal/ Slow '
          }
          if (element.key === 'cooperative_Attitude') {
            element.value = true
          }
          if (element.key === 'friendly_Attitude') {
            element.value = true
          }
          if (element.key === 'ambulatory_PA') {
            element.value = true
          }
          // if(element.key === "wheelChair_PA"){ element.value = true }
          if (element.key === 'depressed_Mood') {
            element.value = true
          }
          if (element.key === 'flat_Affect') {
            element.value = true
          }
          if (element.key === 'goalDirectedAndLogical_TP') {
            element.value = true
          }
          if (element.key === 'appropriate_TP') {
            element.value = true
          }
          if (element.key === 'disorganized_TP') {
            element.value = true
          }
          if (element.key === 'illness_TC') {
            element.value = true
          }
          if (element.key === 'ShortTerm') {
            element.value = 'Impaired'
          }
          if (element.key === 'LongTerm') {
            element.value = 'Intact'
          }
          if (element.key === 'Insight') {
            element.value = 'Fair'
          }
          if (element.key === 'Judgment') {
            element.value = 'Fair'
          }
          if (element.key === 'Depression_axisI') {
            element.value = true
          }
          if (element.key === 'axisTwo') {
            element.value = 'None'
          }
          if (element.key === 'axisThree') {
            if (this.MednoteData.EvalType == 're') {
              element.value = 'High blood pressure'
            }else{
              element.value = 'None'
            }
            
          }
          if(element.key === 'axisFour'){
            if (this.MednoteData.EvalType == 're') {
              element.value = 'Moderate'
            }
            if(this.userdata.patientBasicInfo.age <= 18){
              element.value='None'
            }else{
              element.value='None'
            }
          }
          if (element.key === 'axisFive') {
            if (this.MednoteData.EvalType == 're') {
              element.value = '60'
            }
            else{
              element.value = '70'
            }
          }
          // if (element.key === 'Depression_axisI_I') {
          //   element.value = true
          // }
        })
      } else if (changedDiagnosis === 'Bipolar') {
        this.formService.mednotesEvalConfig.forEach((element: any) => {
          if (element.key === 'chiefComplain') {
            element.value = `Client is struggling with low mood, irritability, low energy, and low motivation in addition to focus and concentration. Client mood is noticeably going through upheavals and client reports feeling fatigue. Client has intense stress, and client dwells on issues/situations a lot. The stress has become overwhelming for the client. The client has issues with sleep on a regular basis. Client also has mood swings daily. `
          }

          if (element.key === 'purposeOfVisit') {
            element.value =
              'This is a ' +
              this.userdata.patientBasicInfo.age +
              ' yr old ' +
              this.userdata.gender +
              ' who presents as a patient for psychiatric evaluation. Client is dealing with a lot of symptoms of mood swings, irritability and Depression. Client has denied any suicidal thoughts or plans.'
          }

          if (element.key === 'mood_obs') {
            element.value = 'Mood: Irritable, Affect - Congruent to mod. No EPS.  '
          }
          if (element.key === 'plan') {
            element.value =
              'Client is recommended for medication management and counseling. Client is recommended to take medication on time and eat a healthy diet. Client is encouraged to continue take medications to assist. Medication benefits and potential side effects have been explained to the patient. I explained the algorithm medicines for getting the optimal response with these medicines.'
          }
          // symptoms
          if (element.key === 'sleep_DS') {
            element.value = true
          }
          if (element.key === 'depressedMood_DS') {
            element.value = true
          }
          if (element.key === 'decreasedConcentration_DS') {
            element.value = true
          }
          if (element.key === 'decreasedMemory_DS') {
            element.value = true
          }
          if (element.key === 'flightOfIdeas_MS') {
            element.value = true
          }
          if (element.key === 'needOfSleep_MS') {
            element.value = true
          }
          if (element.key === 'selfEsteem_MS') {
            element.value = true
          }
          if (element.key === 'distractibility_MS') {
            element.value = true
          }
          if (element.key === 'elevatedMood_MS') {
            element.value = true
          }
          if (element.key === 'irritableMood_MS') {
            element.value = true
          }
          if (element.key === 'none_PS') {
            element.value = true
          }
          if (element.key === 'none_PTSD') {
            element.value = true
          }
          if (element.key === 'poorImpulseControl_PTSD') {
            element.value = true
          }
          if (element.key === 'muscleTension_AS') {
            element.value = true
          }
          if (element.key === 'failureToPayCloseAttention_ADS') {
            element.value = true
          }
          if (element.key === 'makesCarelessMistakesOften_ADS') {
            element.value = true
          }
          if (element.key === 'easilyDistracted_ADS') {
            element.value = true
          }
          if (element.key === 'difficultySustainingAttentionInTasks_ADS') {
            element.value = true
          }
          if (element.key === 'fidgets_ADS') {
            element.value = true
          }
          if (element.key === 'forgetfulOfDailyActivities_ADS') {
            element.value = true
          }
          if (element.key === 'procrastination_ADS') {
            element.value = true
          }

          //mental status exam
          if (element.key === 'wellGroomed_Appearance') {
            element.value = true
          }
          if (element.key === 'Neat_Appearance') {
            element.value = true
          }
          if (element.key === 'person_Orientation') {
            element.value = true
          }
          if (element.key === 'place_Orientation') {
            element.value = true
          }
          if (element.key === 'time_Orientation') {
            element.value = true
          }
          if (element.key === 'purpose_Orientation') {
            element.value = true
          }
          if (element.key === 'pressured_Speech') {
            element.value = true
          }
          if (element.key === 'cooperative_Attitude') {
            element.value = true
          }
          if (element.key === 'friendly_Attitude') {
            element.value = true
          }
          if (element.key === 'ambulatory_PA') {
            element.value = true
          }
          if (element.key === 'dysphoric_Mood') {
            element.value = true
          }
          if (element.key === 'full_Affect') {
            element.value = true
          }
          if (element.key === 'goalDirectedAndLogical_TP') {
            element.value = true
          }
          if (element.key === 'appropriate_TP') {
            element.value = true
          }
          if (element.key === 'tangential_TP') {
            element.value = true
          }
          if (element.key === 'illness_TC') {
            element.value = true
          }
          if (element.key === 'ShortTerm') {
            element.value = 'Impaired'
          }
          if (element.key === 'LongTerm') {
            element.value = 'Intact'
          }
          if (element.key === 'Insight') {
            element.value = 'Fair to Poor'
          }
          if (element.key === 'Judgment') {
            element.value = 'Fair to Poor'
          }
          if (element.key === 'Bipolar_axisI') {
            element.value = true
          }
          if (element.key === 'axisTwo') {
            element.value = 'None'
          }
          if (element.key === 'axisThree') {
            element.value = 'None'
          }
          if(element.key === 'axisFour'){
            element.value = 'None'
          }
          if (element.key === 'axisFive') {
            element.value = '70'
          }
        })
      } else if (changedDiagnosis === 'Schizophrenia') {
        this.formService.mednotesEvalConfig.forEach((element: any) => {
          if (element.key === 'chiefComplain') {
            element.value = `Client has mood irritability, restless and paranoid ideations and delusions. `
          }

          if (element.key === 'purposeOfVisit') {
            element.value =
              'This is a ' +
              this.userdata.patientBasicInfo.age +
              ' yr old ' +
              this.userdata.gender +
              ' who presents as a patient for a psychiatric evaluation. Client came with his/her family. Client has a history of schizophrenia and is in treatment with medications.  Client has a history of paranoid delusions, mood swings, insomnia, and hallucinations. '
          }

          if (element.key === 'mood_obs') {
            element.value = 'Mood: Normal, Affect - Congruent to mod. No EPS.  '
          }

          if (element.key === 'addObservations') {
            element.value = `Client's sleep appetite is erratic. Client have support from family and social agencies. Client needs further intensive treatment including medications.`
          }
          if (element.key === 'plan') {
            element.value =
              'Encouraged to take medication as prescribed and eat a healthy diet. Client was encouraged to continue taking the medications to assist him. Medication benefits and potential side effects have been discussed with patient. \n I explained the algorithm of these medicines for getting the maximal response with these medicines.'
          }
          //symptoms
          if (element.key === 'sleep_DS') {
            element.value = true
          }
          if (element.key === 'decreasedConcentration_DS') {
            element.value = true
          }
          if (element.key === 'decreasedMemory_DS') {
            element.value = true
          }
          if (element.key === 'none_DS') {
            element.value = true
          }
          if (element.key === 'depressedMood_DS') {
            element.value = true
          }
          if (element.key === 'none_MS') {
            element.value = true
          }
          if (element.key === 'delusionsBizarre_PS') {
            element.value = true
          }
          if (element.key === 'hallucinations_PS') {
            element.value = true
          }
          if (element.key === 'disorganizedBehavior_PS') {
            element.value = true
          }
          if (element.key === 'sixMonths_PS') {
            element.value = true
          }
          if (element.key === 'none_PTSD') {
            element.value = true
          }
          if (element.key === 'poorImpulseControl_PTSD') {
            element.value = true
          }
          if (element.key === 'muscleTension_AS') {
            element.value = true
          }
          if (element.key === 'failureToPayCloseAttention_ADS') {
            element.value = true
          }
          if (element.key === 'difficultySustainingAttentionInTasks_ADS') {
            element.value = true
          }
          if (element.key === 'forgetfulOfDailyActivities_ADS') {
            element.value = true
          }
          if (element.key === 'procrastination_ADS') {
            element.value = true
          }
          if (element.key === 'makesCarelessMistakesOften_ADS') {
            element.value = true
          }
          if (element.key === 'easilyDistracted_ADS') {
            element.value = true
          }

          //mental status exam
          if (element.key === 'wellGroomed_Appearance') {
            element.value = true
          }
          if (element.key === 'Neat_Appearance') {
            element.value = true
          }
          if (element.key === 'person_Orientation') {
            element.value = true
          }
          if (element.key === 'place_Orientation') {
            element.value = true
          }
          if (element.key === 'time_Orientation') {
            element.value = true
          }
          if (element.key === 'purpose_Orientation') {
            element.value = true
          }
          if (element.key === 'normal_Speech') {
            element.value = true
          }
          if (element.key === 'guarded_Attitude') {
            element.value = true
          }
          if (element.key === 'friendly_Attitude') {
            element.value = true
          }
          if (element.key === 'ambulatory_PA') {
            element.value = true
          }
          if (element.key === 'anxious_Mood') {
            element.value = true
          }
          if (element.key === 'irritable_Mood') {
            element.value = true
          }
          if (element.key === 'flat_Affect') {
            element.value = true
          }
          if (element.key === 'tangential_TP') {
            element.value = true
          }
          if (element.key === 'persecutoryDelusions_TC') {
            element.value = true
          }
          if (element.key === 'hallucination_TC') {
            element.value = true
          }
          if (element.key === 'ShortTerm') {
            element.value = 'Intact'
          }
          if (element.key === 'LongTerm') {
            element.value = 'Intact'
          }
          if (element.key === 'Insight') {
            element.value = 'Fair to Poor'
          }
          if (element.key === 'Judgment') {
            element.value = 'Fair to Poor'
          }
          if (element.key === 'Schizophrenia_axisI') {
            element.value = true
          }
          if (element.key === 'axisTwo') {
            element.value = 'None'
          }
          if (element.key === 'axisThree') {
            element.value = 'None'
          }
          if(element.key === 'axisFour'){
            element.value = 'None'
          }
          if (element.key === 'axisFive') {
            element.value = '65'
          }
        })
      } else if (changedDiagnosis === 'Adjustment Disorder') {
        this.formService.mednotesEvalConfig.forEach((element: any) => {
          if (element.key === 'chiefComplain') {
            element.value = `Client has been having difficulty with mood. Client cannot concentrate. The Client cannot get motivated. Don’t have energy. The Client is feeling stressed and worrying a lot. Stress is taking over.  `
          }

          if (element.key === 'purposeOfVisit') {
            element.value =
              'This is a ' +
              this.userdata.patientBasicInfo.age +
              ' yr old ' +
              this.userdata.gender +
              ' who presents as a patient for a psychiatric evaluation. \nClient is dealing with a lot of symptoms of depression due to the current incident (symptoms where precipitated from it). Client is feeling hopeless, helpless and experiencing low motivation and lack of interest in things etc which Client used to be interested in before. Client has denied any suicidal thoughts or plans. Client sleep and appetite are erratic. '
          }

          if (element.key === 'mood_obs') {
            element.value = 'Mood: Sad, Affect - Congruent to mod. No EPS.  '
          }
          if (element.key === 'plan') {
            element.value =
              'Client is recommended for careful consideration of medications and counseling. Client is also encouraged to seek holistic approaches like meditating, walking and excercise. Medication benefits and potential side effects have been discussed with patient.'
          }
          // symptoms
          if (element.key === 'sleep_DS') {
            element.value = true
          }
          if (element.key === 'depressedMood_DS') {
            element.value = true
          }
          if (element.key === 'decreasedConcentration_DS') {
            element.value = true
          }
          if (element.key === 'decreasedMemory_DS') {
            element.value = true
          }
          if (element.key === 'distractibility_MS') {
            element.value = true
          }
          if (element.key === 'none_PS') {
            element.value = true
          }
          if (element.key === 'none_PTSD') {
            element.value = true
          }
          if (element.key === 'poorImpulseControl_PTSD') {
            element.value = true
          }
          if (element.key === 'excessiveWorrying_AS') {
            element.value = true
          }
          if (element.key === 'failureToPayCloseAttention_ADS') {
            element.value = true
          }
          if (element.key === 'makesCarelessMistakesOften_ADS') {
            element.value = true
          }
          if (element.key === 'easilyDistracted_ADS') {
            element.value = true
          }
          if (element.key === 'difficultySustainingAttentionInTasks_ADS') {
            element.value = true
          }
          if (element.key === 'fidgets_ADS') {
            element.value = true
          }
          if (element.key === 'forgetfulOfDailyActivities_ADS') {
            element.value = true
          }
          if (element.key === 'procrastination_ADS') {
            element.value = true
          }

          //mental status exam
          if (element.key === 'wellGroomed_Appearance') {
            element.value = true
          }
          if (element.key === 'Neat_Appearance') {
            element.value = true
          }
          if (element.key === 'person_Orientation') {
            element.value = true
            element.labeltext = 'Normal Person'
          }
          if (element.key === 'place_Orientation') {
            element.value = true
          }
          if (element.key === 'time_Orientation') {
            element.value = true
          }
          if (element.key === 'purpose_Orientation') {
            element.value = true
          }
          if (element.key === 'normal_Speech') {
            element.value = true
            element.labeltext = 'Normal/ Slow '
          }
          if (element.key === 'cooperative_Attitude') {
            element.value = true
          }
          if (element.key === 'friendly_Attitude') {
            element.value = true
          }
          if (element.key === 'ambulatory_PA') {
            element.value = true
          }
          // if(element.key === "euthymic_Mood"){ element.value = true }
          if (element.key === 'flat_Affect') {
            element.value = true
          }
          if (element.key === 'goalDirectedAndLogical_TP') {
            element.value = true
          }
          if (element.key === 'appropriate_TP') {
            element.value = true
          }
          if (element.key === 'illness_TC') {
            element.value = true
          }
          if (element.key === 'ShortTerm') {
            element.value = 'Impaired'
          }
          if (element.key === 'LongTerm') {
            element.value = 'Intact'
          }
          if (element.key === 'Insight') {
            element.value = 'Fair'
          }
          if (element.key === 'Judgment') {
            element.value = 'Fair'
          }
          if (element.key === 'AdjustmentDisorder_axisI') {
            element.value = true
          }
          if (element.key === 'axisTwo') {
            element.value = 'None'
          }
          if (element.key === 'axisThree') {
            element.value = 'None'
          }
          if(element.key === 'axisFour'){
            element.value = 'None'
          }
          if (element.key === 'axisFive') {
            element.value = '70'
          }
        })
      } else if (changedDiagnosis === 'Oppositional Disorder') {
        this.formService.mednotesEvalConfig.forEach((element: any) => {
          if (element.key === 'chiefComplain') {
            element.value =
              this.userdata.firstName +
              ` is having difficulty with defiant, oppositional and argumentative and concentration problems. Client is struggling with completing tasks and assignments in spite of repeated reminders from parents and teachers.`
          }
          if (element.key === 'purposeOfVisit') {
            element.value =
              'This is a ' +
              this.userdata.patientBasicInfo.age +
              ' yr old ' +
              this.userdata.gender +
              ' who presents as a patient for a psychiatric evaluation. \nClient presents with symptoms of mood swings defiance, Oppositional, lack of concentration, procrastination, and distraction issues. '
          }
          if (element.key === 'mood_obs') {
            element.value = 'Mood: Irritable, Affect - Congruent to mod. No EPS.'
          }
          if (element.key === 'addObservations') {
            element.value =
              'The Client has been experiencing these symptoms from a young age. However, lately the studies have created more difficulties completing his/her assignments in a timely manner. Family and school both are concerned with ODD symptoms. Client school work is busy and has to spend extra amounts of time completing tasks. Client denies any acute symptoms of depression or psychosis. Client sleep and appetite are erratic.'
          }
          if (element.key === 'plan') {
            element.value =
              'Encouraged to take medication as prescribed and eat a healthy diet. Client is also encouraged to seek holistic approaches like walking, running, and meditating. Medication benefits and potential side effects have been discussed with patient. \nClient would need structured school and recommend IEP meeting based on 504 B plan. \nClient would also benefit from after school program. \nClient can also benefit from psychoeducation testing for appropriate services in school. \nI explained the algorithm of medicines for getting the optimal response.'
          }
          // symptoms
          if (element.key === 'sleep_DS') {
            element.value = true
          }
          if (element.key === 'decreasedConcentration_DS') {
            element.value = true
          }
          if (element.key === 'decreasedMemory_DS') {
            element.value = true
          }
          if (element.key === 'irritableMood_MS') {
            element.value = true
          }
          if (element.key === 'none_PS') {
            element.value = true
          }
          if (element.key === 'none_PTSD') {
            element.value = true
          }
          if (element.key === 'poorImpulseControl_PTSD') {
            element.value = true
          }
          if (element.key === 'muscleTension_AS') {
            element.value = true
          }
          if (element.key === 'failureToPayCloseAttention_ADS') {
            element.value = true
          }
          if (element.key === 'makesCarelessMistakesOften_ADS') {
            element.value = true
          }
          if (element.key === 'easilyDistracted_ADS') {
            element.value = true
          }
          if (element.key === 'difficultySustainingAttentionInTasks_ADS') {
            element.value = true
          }
          if (element.key === 'fidgets_ADS') {
            element.value = true
          }
          if (element.key === 'forgetfulOfDailyActivities_ADS') {
            element.value = true
          }
          if (element.key === 'procrastination_ADS') {
            element.value = true
          }

          //mental status exam
          if (element.key === 'wellGroomed_Appearance') {
            element.value = true
          }
          if (element.key === 'Neat_Appearance') {
            element.value = true
          }
          if (element.key === 'person_Orientation') {
            element.value = true
            element.labeltext = 'Normal Person'
          }
          if (element.key === 'place_Orientation') {
            element.value = true
          }
          if (element.key === 'time_Orientation') {
            element.value = true
          }
          if (element.key === 'purpose_Orientation') {
            element.value = true
          }
          if (element.key === 'normal_Speech') {
            element.value = true
            element.labeltext = 'Normal/ Slow '
          }
          if (element.key === 'uncooperative_Attitude') {
            element.value = true
          }
          if (element.key === 'ambulatory_PA') {
            element.value = true
          }
          if (element.key === 'irritable_Mood') {
            element.value = true
          }
          if (element.key === 'angry_Mood') {
            element.value = true
          }
          if (element.key === 'full_Affect') {
            element.value = true
          }
          if (element.key === 'goalDirectedAndLogical_TP') {
            element.value = true
          }
          if (element.key === 'appropriate_TP') {
            element.value = true
          }
          if (element.key === 'appropriate_TC') {
            element.value = true
          }
          if (element.key === 'ShortTerm') {
            element.value = 'Impaired'
          }
          if (element.key === 'LongTerm') {
            element.value = 'Impaired'
          }
          if (element.key === 'Insight') {
            element.value = 'Fair to Poor'
          }
          if (element.key === 'Judgment') {
            element.value = 'Fair to Poor'
          }

          if (element.key === 'OppositionalDisorder_axisI') {
            element.value = true
          }
          if (element.key === 'axisTwo') {
            element.value = 'None'
          }
          if (element.key === 'axisThree') {
            element.value = 'None'
          }
          if(element.key === 'axisFour'){
            element.value = 'None'
          }
          if (element.key === 'axisFive') {
            element.value = '70'
          }
        })
      } else if (changedDiagnosis === 'Autism') {
        this.formService.mednotesEvalConfig.forEach((element: any) => {
          if (element.key === 'chiefComplain') {
            element.value = `Client is having difficulty with concentration, social skills and rule out pervasive developmental disorder. Client struggles with comprehension, lack of age-appropriate social skills and interpersonal skills according to family. This stress is taking a toll on the family.  I reviewed the information provided by the caregiver, family and social agencies. `
          }
          if (element.key === 'purposeOfVisit') {
            element.value =
              'This is a ' +
              this.userdata.patientBasicInfo.age +
              ' yr old ' +
              this.userdata.gender +
              ' who presents as a patient for a psychiatric evaluation. \n Client presents with symptoms of lack of focus, lack of concentration, limited social skills. '
          }
          if (element.key === 'mood_obs') {
            element.value = 'Mood: Normal, Affect - Congruent to mod. No EPS.'
          }
          if (element.key === 'development') {
            element.value =
              'Client came in with the family. Client is reported to be in structured program in school. Client has delays in social skills, speech and cognitive skills for his/her age.'
          }
          if (element.key === 'addObservations') {
            element.value =
              'The Client has been experiencing these symptoms from a young age. There is no evidence of neglect or care by the family. His sleep and appetite are erratic. \nSelf Skills: Need assistance from family.'
          }
          if (element.key === 'plan') {
            element.value =
              'Client meets criteria for PDD NOS. Client will need IEP meeting to address his/ her needs after psychoeducational testing is done. Client will need structured program to minimize external stimuli. \nClient will benefit from ABA treatment. \nClient would need structured school and recommend IEP meeting based on 504 B plan. \nMedications: Careful considerations of medications. \nI explained the algorithm medicines for getting the optimal response with the above explained recommendations.'
          }

          // symptoms
          if (element.key === 'none_DS') {
            element.value = true
          }
          if (element.key === 'decreasedConcentration_DS') {
            element.value = true
          }
          if (element.key === 'none_MS') {
            element.value = true
          }
          if (element.key === 'none_PS') {
            element.value = true
          }
          if (element.key === 'none_PTSD') {
            element.value = true
          }
          if (element.key === 'poorImpulseControl_PTSD') {
            element.value = true
          }
          if (element.key === 'none_AS') {
            element.value = true
          }
          if (element.key === 'failureToPayCloseAttention_ADS') {
            element.value = true
          }
          if (element.key === 'makesCarelessMistakesOften_ADS') {
            element.value = true
          }
          if (element.key === 'easilyDistracted_ADS') {
            element.value = true
          }
          if (element.key === 'difficultySustainingAttentionInTasks_ADS') {
            element.value = true
          }
          if (element.key === 'fidgets_ADS') {
            element.value = true
          }
          if (element.key === 'forgetfulOfDailyActivities_ADS') {
            element.value = true
          }
          if (element.key === 'procrastination_ADS') {
            element.value = true
          }

          //mental status exam
          if (element.key === 'wellGroomed_Appearance') {
            element.value = true
          }
          if (element.key === 'Neat_Appearance') {
            element.value = true
          }
          if (element.key === 'person_Orientation') {
            element.value = true
            element.labeltext = 'Normal Person'
          }
          if (element.key === 'place_Orientation') {
            element.value = true
          }
          if (element.key === 'time_Orientation') {
            element.value = true
          }
          if (element.key === 'purpose_Orientation') {
            element.value = true
          }
          if (element.key === 'disorganized_Speech') {
            element.value = true
          }
          if (element.key === 'cooperative_Attitude') {
            element.value = true
          }
          if (element.key === 'friendly_Attitude') {
            element.value = true
          }
          if (element.key === 'ambulatory_PA') {
            element.value = true
          }
          if (element.key === 'anxious_Mood') {
            element.value = true
          }
          if (element.key === 'full_Affect') {
            element.value = true
          }
          if (element.key === 'disorganized_TP') {
            element.value = true
          }
          if (element.key === 'appropriate_TP') {
            element.value = true
          }
          if (element.key === 'ShortTerm') {
            element.value = 'Impaired'
          }
          if (element.key === 'LongTerm') {
            element.value = 'Impaired'
          }
          if (element.key === 'Insight') {
            element.value = 'Poor'
          }
          if (element.key === 'Judgment') {
            element.value = 'Poor'
          }

          if (element.key === 'Autism_axisI') {
            element.value = true
          }
          if (element.key === 'axisTwo') {
            element.value = 'None'
          }
          if (element.key === 'axisThree') {
            element.value = 'None'
          }
          if(element.key === 'axisFour'){
            element.value = 'None'
          }
          if (element.key === 'axisFive') {
            element.value = '60'
          }
        })
      } else if (changedDiagnosis === 'Eating Disorder') {
        this.formService.mednotesEvalConfig.forEach((element: any) => {
          if (element.key === 'chiefComplain') {
            element.value = `Client is having difficulty with concentration, low energy and motivation. Client struggles with completing tasks and assignments due to his low energy and motivation levels. The stress is dominating all aspects in his life. He is interested in trying medication to address these symptoms and to improve his quality of life.`
          }
          if (element.key === 'purposeOfVisit') {
            element.value =
              'This is a ' +
              this.userdata.patientBasicInfo.age +
              ' yr old ' +
              this.userdata.gender +
              ' who presents as a patient for a psychiatric evaluation. \nClient presents with symptoms of low focus, lack of concentration, procrastination, and distraction issues.'
          }
          if (element.key === 'mood_obs') {
            element.value = 'Mood: Normal, Affect - Congruent to mod. No EPS.'
          }
          if (element.key === 'addObservations') {
            element.value =
              'Client has been experiencing these symptoms from young age. However, lately the work / studies have created more difficulties completing his/her assignments in a timely manner. His/ her job/ school is very busy and has to spend extra amounts of time completing tasks. Client was requesting an assessment and whether he/ she might benefit from treatment for ADHD. Client denies any acute symptoms of depression or psychosis. His sleep and appetite are erratic.'
          }

          // symptoms
          if (element.key === 'sleep_DS') {
            element.value = true
          }
          if (element.key === 'decreasedConcentration_DS') {
            element.value = true
          }
          if (element.key === 'decreasedMemory_DS') {
            element.value = true
          }
          if (element.key === 'none_MS') {
            element.value = true
          }
          if (element.key === 'none_PS') {
            element.value = true
          }
          if (element.key === 'none_PTSD') {
            element.value = true
          }
          if (element.key === 'poorImpulseControl_PTSD') {
            element.value = true
          }
          if (element.key === 'muscleTension_AS') {
            element.value = true
          }
          if (element.key === 'failureToPayCloseAttention_ADS') {
            element.value = true
          }
          if (element.key === 'makesCarelessMistakesOften_ADS') {
            element.value = true
          }
          if (element.key === 'easilyDistracted_ADS') {
            element.value = true
          }
          if (element.key === 'difficultySustainingAttentionInTasks_ADS') {
            element.value = true
          }
          if (element.key === 'fidgets_ADS') {
            element.value = true
          }
          if (element.key === 'forgetfulOfDailyActivities_ADS') {
            element.value = true
          }
          if (element.key === 'procrastination_ADS') {
            element.value = true
          }

          //mental status exam
          if (element.key === 'wellGroomed_Appearance') {
            element.value = true
          }
          if (element.key === 'Neat_Appearance') {
            element.value = true
          }
          if (element.key === 'person_Orientation') {
            element.value = true
            element.labeltext = 'Normal Person'
          }
          if (element.key === 'place_Orientation') {
            element.value = true
          }
          if (element.key === 'time_Orientation') {
            element.value = true
          }
          if (element.key === 'purpose_Orientation') {
            element.value = true
          }
          if (element.key === 'normal_Speech') {
            element.value = true
          }
          if (element.key === 'cooperative_Attitude') {
            element.value = true
          }
          if (element.key === 'friendly_Attitude') {
            element.value = true
          }
          if (element.key === 'ambulatory_PA') {
            element.value = true
          }
          if (element.key === 'anxious_Mood') {
            element.value = true
          }
          if (element.key === 'full_Affect') {
            element.value = true
          }
          if (element.key === 'goalDirectedAndLogical_TP') {
            element.value = true
          }
          if (element.key === 'appropriate_TP') {
            element.value = true
          }
          if (element.key === 'appropriate_TC') {
            element.value = true
          }
          if (element.key === 'ShortTerm') {
            element.value = 'Impaired'
          }
          if (element.key === 'LongTerm') {
            element.value = 'Impaired'
          }
          if (element.key === 'Insight') {
            element.value = 'Fair'
          }
          if (element.key === 'Judgment') {
            element.value = 'Fair'
          }

          if (element.key === 'EatingDisorder_axisI') {
            element.value = true
          }
          if (element.key === 'axisTwo') {
            element.value = 'None'
          }
          if (element.key === 'axisThree') {
            element.value = 'None'
          }
          if(element.key === 'axisFour'){
            element.value = 'Moderate'
          }
          if (element.key === 'axisFive') {
            element.value = '70'
          }
          if (element.key === 'plan') {
            element.value =
              'Encouraged to take medication as prescribed and eat a healthy diet. Client is also encouraged to seek holistic approaches like walking, running, and meditating. Medication benefits and potential side effects have been discussed with patient. I explained the algorithm of these medicines for getting the optimal response with these medicines.'
          }
          // if(element.key === "diagnosisMedications"){
          //   element.value = ""
          // }
        })
      } else if (changedDiagnosis === 'OCD') {
        this.formService.mednotesEvalConfig.forEach((element: any) => {
          if (element.key === 'chiefComplain') {
            console.log('chiefComplain', element)
            element.value =
              this.userdata.firstName +
              ` is struggling with low mood, low energy, constant worrying, unable to relax and low motivation in addition to the inability to concentrate. Client mood is noticeably anxious and client reports feeling fatigue. Client has intense anxiety worries on trivial things and client dwells on issues/situations a lot. The anxiety has become overwhelming on a frequent basis. Client sleep and appetite are erratic.`
          }
          if (element.key === 'purposeOfVisit') {
            element.value =
              'This is a ' +
              this.userdata.patientBasicInfo.age +
              ' yr old ' +
              this.userdata.gender +
              ' who presents as a patient for psychiatric evaluation. Client is dealing with a lot of symptoms of Anxiety and stress. Client is feeling at times hopeless, helpless and experiencing low motivation and lack of interest in things etc. Client has denied any suicidal thoughts or plans. Client is also unable to relax and worries.'
          }
          if (element.key === 'mood_obs') {
            element.value = 'Mood: Anxious, Affect - Congruent to mod. No EPS.'
          }
          if (element.key === 'addObservations') {
            element.value = ''
          }
          // symptoms
          if (element.key === 'sleep_DS') {
            element.value = true
          }
          if (element.key === 'decreasedConcentration_DS') {
            element.value = true
          }
          if (element.key === 'decreasedMemory_DS') {
            element.value = true
          }
          if (element.key === 'depressedMood_DS') {
            element.value = true
          }
          if (element.key === 'none_MS') {
            element.value = true
          }
          if (element.key === 'none_PS') {
            element.value = true
          }
          if (element.key === 'sweating_AS') {
            element.value = true
          }
          if (element.key === 'poorImpulseControl_PTSD') {
            element.value = true
          }
          if (element.key === 'excessiveWorrying_AS') {
            element.value = true
          }
          if (element.key === 'chestPain_AS') {
            element.value = true
          }
          if (element.key === 'failureToPayCloseAttention_ADS') {
            element.value = true
          }
          if (element.key === 'makesCarelessMistakesOften_ADS') {
            element.value = true
          }
          if (element.key === 'easilyDistracted_ADS') {
            element.value = true
          }
          if (element.key === 'difficultySustainingAttentionInTasks_ADS') {
            element.value = true
          }
          if (element.key === 'fidgets_ADS') {
            element.value = true
          }
          if (element.key === 'forgetfulOfDailyActivities_ADS') {
            element.value = true
          }
          if (element.key === 'procrastination_ADS') {
            element.value = true
          }

          //mental status exam
          if (element.key === 'wellGroomed_Appearance') {
            element.value = true
          }
          if (element.key === 'Neat_Appearance') {
            element.value = true
          }
          if (element.key === 'person_Orientation') {
            element.value = true
            element.labeltext = 'Normal Person'
          }
          if (element.key === 'place_Orientation') {
            element.value = true
          }
          if (element.key === 'time_Orientation') {
            element.value = true
          }
          if (element.key === 'purpose_Orientation') {
            element.value = true
          }
          if (element.key === 'normal_Speech') {
            element.value = true
          }
          if (element.key === 'cooperative_Attitude') {
            element.value = true
          }
          if (element.key === 'friendly_Attitude') {
            element.value = true
          }
          if (element.key === 'ambulatory_PA') {
            element.value = true
          }
          if (element.key === 'anxious_Mood') {
            element.value = true
          }
          if (element.key === 'full_Affect') {
            element.value = true
          }
          if (element.key === 'goalDirectedAndLogical_TP') {
            element.value = true
          }
          if (element.key === 'appropriate_TP') {
            element.value = true
          }
          if (element.key === 'appropriate_TC') {
            element.value = true
          }
          if (element.key === 'ShortTerm') {
            element.value = 'Intact'
          }
          if (element.key === 'LongTerm') {
            element.value = 'Intact'
          }
          if (element.key === 'Insight') {
            element.value = 'Fair'
          }
          if (element.key === 'Judgment') {
            element.value = 'Fair'
          }

          if (element.key === 'OCD_axisI') {
            element.value = true
          }
          if (element.key === 'axisTwo') {
            element.value = 'None'
          }
          if (element.key === 'axisThree') {
            element.value = 'None'
          }
          if(element.key === 'axisFour'){
            element.value = 'None'
          }
          if (element.key === 'axisFive') {
            element.value = '70'
          }
          if (element.key === 'plan') {
            element.value =
              'Client is recommended for medication management and counseling. Client was encouraged to see a therapist and explained the benefits of meditation, breathing techniques to reduce Anxiety symptoms. I explained the algorithm of medicines for getting the optimal response.'
          }
          // if(element.key === "diagnosisMedications"){
          //   element.value = ""
          // }
        })
      }
    }
  }
}
