<section class="dashboard__calender--section" *ngIf="isShow">
    <ng-container *ngIf="viewByRadioBtn == 'day'">
        <div class="appointment__type--header" id="dayWiselabel">
            <div class="each__appointment">
            </div>
            <div class="each__appointment" *ngFor="let appointment of appointmentsType[0].treatments" title="{{appointment.appointmentName}}">
                {{ (appointmentsType[0].treatments.length>6)? (appointment.appointmentName.split('').length>5)?(appointment.appointmentName | slice:0:5)+'..':(appointment.appointmentName):(appointment.appointmentName) }}
            </div>
        </div>
        <div class="calendar__block" id="dayWiseCalendar">
            <div class="time__labels">
                <div class="time__slot" id="{{label.id}}" *ngFor="let label of timeLabels">
                    <div>{{label.formatTime}}</div>
                    <div class="icon--block">
                        <app-svg [name] = "label.img.toLowerCase()"></app-svg>
                    </div>
                </div>
            </div>
            <div id="daywise__timeline" class="current__time--line">
                <div class="line__value">{{currentTime}}</div>
            </div>
            <div class="each__box" *ngFor="let columnData of appointmentsType[0].treatments">
                <div *ngFor="let rowData of columnData.slots" class="slot__box {{rowData.isClass}} {{!rowData.available && rowData.list.length>0 ? 'UnAvailSlot':''}}" [ngStyle]="{'height': rowData.slotHeight,'width':rowData.slotWidth}"
                    [ngClass]="(((rowData.available === true) && (rowData.maxApptCount === rowData.list.length)) || ((rowData.available === false) && (rowData.list.length === 0)) || (rowData.available === false)) || (rowData.maxApptCount < rowData.list.length)?'bookedClass':'avalabilityClass'" 
                    (click)="slotClicked(rowData)">
                    <ng-container *ngIf="(rowData.available === true)">{{rowData.list.length}} of {{getBookedValue(rowData)}} Booked</ng-container>
                    <ng-container *ngIf="(rowData.available === false && rowData.unavailabilityGrpId !=='' && rowData.list.length === 0)">  Blocked  slot</ng-container>
                    <ng-container *ngIf="(rowData.available === false && rowData.unavailabilityGrpId !=='' && rowData.list.length !== 0)">{{rowData.list.length}} of {{rowData.maxApptCount}} booked and blocked slot </ng-container>
                    <ng-container *ngIf="(rowData.available === false && rowData.unavailabilityGrpId ==='' && rowData.maxApptCount !== '' && rowData.maxApptCount >0)">  {{rowData.list.length}} of {{getBookedValue(rowData)}} Booked</ng-container>
                    <ng-container *ngIf="rowData.available === false && rowData.appId">{{rowData.list.length}} Booked</ng-container>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="viewByRadioBtn == 'week'">
        <div class="date__headers" id="weekWiseLabel">
            <div class="each__appointment">
            </div>
            <div class="each__appointment" *ngFor="let appointment of weekWiseGridData">
                {{appointment.date | customDate}}
            </div>
        </div>
        <div class="week__calendar--block" id="weekWiseCalendar">
            <div class="time__labels">
                <div class="week__time--slot" [ngStyle]="{'height': label.slotHeight,'width':label.slotWidth}" id="{{label.id}}" *ngFor="let label of weektimeLabels">
                    <div>{{label.formatTime}}</div>
                    <div class="icon--block">
                        <app-svg [name] = "label.img.toLowerCase()"></app-svg>
                    </div>
                </div>
            </div>
            <div id="weekwise__timeline" class="week__current__time--line">
                <div class="line__value">{{currentTime}}</div>
            </div>
            <div class="each__week--box" *ngFor="let columnData of weekWiseGridData">
                <div class="slot__box" [ngStyle]="{'height': rowData.slotHeight,'width':rowData.slotWidth}"
                    *ngFor="let rowData of columnData.slots[0]"  [ngClass]="(rowData.available !== true) ||  (rowData.available === true && weekSlotStatus(rowData)) ? 'weekBookedSlots': !adminPermissions.APPOINTMENT_CREATE ? 'disable':'avalabilityClass'" (click)="slotClicked(rowData)">
                    <ng-container *ngIf="rowData.available">
                        <div class="text" *ngFor="let cellData of rowData.treatmentCount; let i=index">
                            <span *ngIf="i<3">{{cellData.appointmentName}}({{cellData.appointmentCount}})</span>
                            <span *ngIf="i==3">...</span>
                        </div>
                    </ng-container>
                    
                </div>
            </div>
        </div>
    </ng-container>

</section>