import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { OnboardWrapperComponent } from '@app/pages/onboard/onboard-wrapper/onboard-wrapper.component';
import { WebsiteactiveGuard } from '@app/common/services/websiteactive.guard';

const routes: Routes = [
  {
    path: ROUTE_PATHS.EMPTY,
    component: OnboardWrapperComponent,
    canActivate: [WebsiteactiveGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OnboardRoutingModule { }
