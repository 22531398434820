<section class="onboard-form-container">
    <div class="onboard-form">
    <!--login form-->
        <img class="onbaord-form-bg" alt="background-leaf" src="assets/images/svgs/leaf-watermark.svg" />
        
        <div  class="current-form-state">

            <div class="login-register-switcher" *ngIf="formScreenAnalyser == 'login' || formScreenAnalyser == 'register' ">
                <div class="login state" [ngClass]="formScreenAnalyser == 'login' ? 'highlight' : '' " (click)="loginRegisterSwitcher('login')">LOGIN</div>
                <div class="register state" *ngIf="!isAdmin" [ngClass]="formScreenAnalyser == 'register' ? 'highlight' : '' " (click)="loginRegisterSwitcher('register')">REGISTER</div>
            </div>

            <div class="forgot-password" *ngIf="formScreenAnalyser == 'forgot_password' ">
                <div class="back" (click)="getBacktoLogin()">
                    <app-svg [name]="'back'"></app-svg>
                </div>
                <div class="state forgot highlight">FORGOT PASSWORD</div>
            </div>

            <div class="reset-password state highlight" *ngIf="formScreenAnalyser == 'reset_password' ">
                RESET PASSWORD
            </div>

        </div>

        <div [ngSwitch]="formScreenAnalyser" class="onboard-form-contoller">
            <div *ngSwitchCase="'login'">
                <app-login [isAppointment]="isAppointment" (state)="setFormType($event)"></app-login>
            </div>
            <div *ngSwitchCase="'register'">
                <app-register (state)="formScreenAnalyser = $event"></app-register>
            </div>
            <div *ngSwitchCase="'forgot_password'" >
                <app-forgot-password (state)="formScreenAnalyser = $event" [forgotPasswordPath]='forgotPasswordPath'></app-forgot-password>
            </div>
            <div *ngSwitchCase="'reset_password'">
                <app-reset-password (state)="formScreenAnalyser = $event"></app-reset-password>
            </div>
        </div>
    </div>
</section>