<section class="popup-content-block">
    <div class="generate__invoice--header">Create Invoice</div>
    <form class="form__group" [formGroup]="generateForm">
        <div class="amount__block">
            <label>
                <div class="input__lable">
                    Patient Name
                </div>
                <div class="input__value patient" #target (clickOutside)="op.render && onClickedOutside($event,op);">
                    <input [(ngModel)]="patientName" (keyup)='patientInput($event,op);' placeholder="Search for patient" type="text" formControlName="patientName" style="width:310px">
                    <app-svg [name]="'search'" class="inside-search" (click)="iconClick($event,op)"></app-svg>
                </div>
                <!--Overlay for global-search-patients-list-->
                <p-overlayPanel #op [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" [style]="{'width':'310px'}">
                    <div class="searchResultsDiv {{patientsList.length<5 ? 'noScroll' : ''}}" *ngIf="patientsList.length>0">
                        <div class="searchTextDiv" *ngFor="let patient of patientsList" (click)="patientClick($event, patient)">{{patient.firstName+' '+patient.lastName+'('+patient.patientId+')'}}</div>
                    </div>
                    <div class="searchResultsDiv noScroll" *ngIf="patientsList.length==0">
                        <div class="searchTextDiv noResults">No Results</div>
                    </div>
                </p-overlayPanel>
                <div class="invalid-msg" *ngIf="formSubmitAttempt && !generateForm.controls.patientName.valid">Please enter the patient name</div>
                <div class="invalid-msg" *ngIf="formSubmitAttempt && generateForm.controls.patientName.valid && (patientID == '' || patientID == undefined)">Please select the patient name from the list</div>
            </label>
            <label>
                <div class="input__lable">
                    Amount
                </div>
                <div class="input__value amount">
                    <input [(ngModel)]="amount" placeholder="0" type="number" (keypress)="onKeyPressMethod($event)" (keyup)='amountInput($event);' formControlName="amount" style="width:310px;padding-left:15px;">
                </div>
                <div class="invalid-msg" *ngIf="formSubmitAttempt && (amount == undefined || amount == '')">Please enter amount</div>
                <div class="invalid-msg" *ngIf="formSubmitAttempt && amount != '' && amount==0">Please enter amount greater than 0</div>
            </label>
        </div>
        <div class="amount__block">
            <label>
                <div class="input__lable">
                    Service/Reason
                </div>
                <div class="input__value service">
                    <input [(ngModel)]="service" placeholder="Please enter service" type="text" formControlName="service" style="width:100%">
                </div>
                <div class="invalid-msg" *ngIf="formSubmitAttempt && !generateForm.controls.service.valid">Please enter the service name</div>
            </label>
        </div>
    </form>
    <div class="btn-actions">
        <div class="common-btn-style" (click)="actionField($event,'sendCustomer')">Send to Customer</div>
        <div class="common-btn-style" (click)="actionField($event,'makePayment')">Make Payment</div>
        <div class="common-btn-style" (click)="actionField($event,'markAsPaid')">Mark as Paid</div>
    </div>
</section>