<section class="pagination__container">
    <div class="pagination__drop-down-container">
        <div class="pagination__drop-down-title">
            Records Per Page:
        </div>
        <!-- Pagination DropDown UI -->
        <p-dropdown
            [options]="PAGE_LIMITS"
            [(ngModel)]="limit"
            (onChange)="dropDownOnChange($event)"
            styleClass="pagination__dropdown"
            *ngIf="paginationDropDownDisplay">
            <ng-template let-item pTemplate="selectedItem">
                {{item.label}}
            </ng-template>
            <ng-template let-option pTemplate="item">
                    {{option.value}}
            </ng-template>
        </p-dropdown>
    </div>
    <!-- <div class="" *ngIf="paginationControls.length > 1"> -->
  <div>
        <div class="pagination__btns__container" *ngIf="paginationData">

            <!-- Previous button section starts -->
            <div class="each__page__number" [ngClass]="{'disabled': (currentPage <= 1)}">
                <div (click)="previousPage()" class="d-flex">
                    <img class="pagination__button previews" src="assets/images/admin/appointments/back-button.png" alt="Pre Button">
                    <!-- <app-svg class="prev__next__btn" name="prev"></app-svg> -->
                </div>
            </div>
            <!-- Previous button section starts -->

            <!-- Actual nav buttons section starts -->
            <div *ngFor="let pagntn of paginationControls" class="each__page__number">
                <div *ngIf="pagntn != '.....'" (click)="changePage(pagntn)" class=""
                    [ngClass]="currentPage == pagntn ? 'page__active' : ''">{{pagntn}}</div>
                <div *ngIf="pagntn == '.....'" class="">
                    {{pagntn}}
                </div>
            </div>
            <!-- Actual nav buttons section starts -->

            <!-- Next button section starts -->
            <div class="each__page__number" [ngClass]="{'disabled': totalPages == currentPage}">
                <div (click)="nextPage()" class="d-flex">
                    <img class="pagination__button next" src="assets/images/admin/appointments/back-button.png" alt="Next Button">
                    <!-- <app-svg class="prev__next__btn next" name="prev"></app-svg> -->
                </div>
            </div>
            <!-- Next button section ends -->

        </div>
    </div>

</section>
