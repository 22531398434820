import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { DataService } from '@app/common/services/data.service';

@Component({
  selector: 'app-subsentence-agreement',
  templateUrl: './subsentence-agreement.component.html',
  styleUrls: ['./subsentence-agreement.component.scss']
})
export class SubsentenceAgreementComponent implements OnInit {
  @Input() saveFormData;
  substanceForm: any;
  @Output() submitEvent: EventEmitter<any> = new EventEmitter();
  hippaData = {
    description1: 'I have been told that:',
    list: [
      `If I drink alcohol or use street drugs, I may not be able to think clearly and I could become sleepy and risk personal injury.`,
      `I may get addicted to this medicine.`,
      `If I or anyone in my family has a history of drug or alcohol problems, there is a higher chance of addiction`,
      `If I need to stop this medicine, I must do it slowly or I may get very sick.`,
     ],
     description2: 'I agree to the following',
     list1: [
      `I am responsible for my medicines. I will not share, sell, or trade my medicine. I will not take anyone else’s medicine.`,
       `I will not increase my medicine until I speak with my doctor or nurse.`,
       `My medicine may not be replaced if it is lost, stolen, or used up sooner than prescribed.`,
       `I will keep all appointments set up by my doctor (e.g., primary care, physical therapy, mental health, substance abuse treatment, pain management) `,
       `I will bring the pill bottles with any remaining pills of this medicine to each clinic visit. `,
       `I agree to give a blood or urine sample, if asked, to test for drug use. `
      ],
      description3: 'Refills',
      list2: [
       `Refills will be made only during regular office hours—Monday through Friday, 8:00AM-4:30 PM.
       No refills on nights, holidays, or weekends. I must call at least three (3) working days ahead
       (M-F) to ask for a refill of my medicine. No exceptions will be made. I will not come to
       Primary Care for my refill until I am called by the nurse.`,
        `I must keep track of my medications. No early or emergency refills may be made.`,
       ],
       description4: 'Prescriptions from Other Doctors',
       list3: [
        `If I see another doctor who gives me a controlled substance medicine (for example, a
          dentist, a doctor from the Emergency Room or another hospital, etc.) I must bring this
          medicine to Primary Care in the original bottle, even if there are no pills left.`,
        ],
        description5: 'Privacy',
        list4: [
         `If I see another doctor who gives me a controlled substance medicine (for example, a
           dentist, a doctor from the Emergency Room or another hospital, etc.) I must bring this
           medicine to Primary Care in the original bottle, even if there are no pills left.`,
         ],
         description6: 'Termination of Agreement',
         list5: [
          `If I break any of the rules, or if my doctor decides that this medicine is hurting me more than
          helping me, this medicine may be stopped by my doctor in a safe way. left.`,
          `I have talked about this agreement with my doctor and I understand the above rules. `
          ],
           description7: 'Provider Responsibilities',
           list6: [
            `As your doctor, I agree to perform regular checks to see how well the medicine is working.`,
            `I agree to provide primary care for you even if you are no longer getting controlled medicines
            from me. `
            ],
  };

  constructor(private formService:CustomFormHandlerService,private dataService: DataService) { }

  ngOnInit() {
    this.substanceForm = this.formService.initForm(this.formService.formTypes.SUBSENTANCE_TREATEMENT);
  }
  getFormData(event) {
    if (event) {
      this.formService.subSentenceTreatmentConsentFormConfig = this.assignFormValues(event, this.formService.subSentenceTreatmentConsentFormConfig);
      this.dataService.setData(this.dataService.dataObject.HIPPA, this.formService.subSentenceTreatmentConsentFormConfig);
      this.formService.signaturValues[0] = this.formService.subSentenceTreatmentConsentFormConfig[0].value;
      this.submitEvent.emit({status: event});
    }
  }
  assignFormValues(formData, formConfig) {
    let keys = Object.keys(formData.value);
    formConfig.forEach((each, index) => {
      if (keys.includes(each.key)) {
        each.value = formData.value[each.key];
      }
    });
    return formConfig;
  }

}
