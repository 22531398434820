<!-- Search filter layout -->
<div class="search-filter" [class.show-search]="searchInputHideShow" (clickOutside)="onClickedOutside($event)">
     <div class="search-filter__icon" (click)="searchInputHideShow=!searchInputHideShow">
          <app-svg [name]="'search'"></app-svg>
     </div>
     <div class="search-filter__block" *ngIf="searchInputHideShow">
          <app-svg [name]="'search'" class="inside-search"></app-svg>
          <input type="text" autocomplete="off" name="searchValue"  [(ngModel)]='searchInput' (keyup)='inputValueChange($event)'
               class="input__search" placeholder="{{searchPlaceHolderText}}">
          <app-svg [name]="'erase'" class="close__icon" (click)="clear()" *ngIf="!isEmpty"></app-svg>
     </div>
</div>