import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from '@app/pages/home/home-routing.module';
import {TabViewModule} from 'primeng/tabview';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { CommonAppModule } from '@app/common-app.module';
import { HomeWrapperComponent } from './home-wrapper/home-wrapper.component';
import { HomeBannerComponent } from './home-banner/home-banner.component';
import { MeasurementsComponent } from './measurements/measurements.component';
import { ProvideComponent } from './provide/provide.component';
import { TreatmentCardComponent } from './treatment-card/treatment-card.component';
import { SuccessComponent } from './success/success.component';
import { MissionComponent } from './mission/mission.component';
import { ConsultationComponent } from './consultation/consultation.component';
import { SuccessCardComponent } from './success-card/success-card.component';
import { MedicalComponent } from './medical/medical.component';
import { MedicalCardComponent } from './medical-card/medical-card.component';
import { PartnerComponent } from './partner/partner.component';
import { HomeBlogComponent } from './home-blog/home-blog.component';
import { GetInTouchComponent } from './get-in-touch/get-in-touch.component';
import { EachTreatmentComponent } from './each-treatment/each-treatment.component';

const DECLARATION_COMPONENTS = [
  HomeWrapperComponent,
  HomeBannerComponent,
  MeasurementsComponent,
  ProvideComponent,
  TreatmentCardComponent,
  SuccessComponent,
  MissionComponent,
];

@NgModule({
  declarations: [
    ...DECLARATION_COMPONENTS,
    ConsultationComponent,
    SuccessCardComponent,
    MedicalComponent,
    MedicalCardComponent,
    PartnerComponent,
    HomeBlogComponent,
    GetInTouchComponent,
    EachTreatmentComponent,

  ],
  imports: [
    CommonModule,
    TabViewModule,
    CommonAppModule,
    HomeRoutingModule,
    SlickCarouselModule,
  ],
  exports: []
})
export class HomeModule { }
