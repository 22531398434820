import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ActivateGuard } from '@app/common/services/activate.guard';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { CreatePwdComponent } from './create-pwd/create-pwd.component';
import { AddingPatientComponent } from './adding-patient/adding-patient.component';
import { AppointmentScheduleComponent } from './appointment-schedule/appointment-schedule.component';
import { AppointmentCancelledComponent } from './appointment-cancelled/appointment-cancelled.component';
import { AppointmentPendingComponent } from './appointment-pending/appointment-pending.component';
import { WelcomeTemplateComponent } from './welcome-template/welcome-template.component';
import { MigrateUserComponent } from '../create-pwd-template/migrate-user/migrate-user.component';


const routes: Routes = [
  {
    path: ROUTE_PATHS.CREATE_PASSWORD,
    component: CreatePwdComponent,
    canActivate: [ActivateGuard]
  },
  {
    path: ROUTE_PATHS.WELCOME_TEMPLATE,
    component: WelcomeTemplateComponent,
    canActivate: [ActivateGuard]
  },
  {
    path: ROUTE_PATHS.ADDING_PATIENT,
    component: AddingPatientComponent,
    canActivate: [ActivateGuard]
  },
  {
    path: ROUTE_PATHS.APPOINTMENT_SCHEDULE,
    component: AppointmentScheduleComponent,
    canActivate: [ActivateGuard]
  },
  {
    path: ROUTE_PATHS.APPOINTMENT_CANCELLED,
    component: AppointmentCancelledComponent,
    canActivate: [ActivateGuard]
  },
  {
    path: ROUTE_PATHS.APPOINTMENT_PENDING,
    component: AppointmentPendingComponent,
    canActivate: [ActivateGuard]
  },
  {
    path: ROUTE_PATHS.UPLOADMIGRATE,
    component: MigrateUserComponent,
    canActivate: [ActivateGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CreatePwdTemplateRoutingModule { }
