import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserLandingPageComponent } from './user-landing-page/user-landing-page.component';
import { CommonAppModule } from '@app/common-app.module';
import { UserLandingPageRoutingModule } from './user-landing-page-routing.module';



@NgModule({
  declarations: [UserLandingPageComponent],
  imports: [
    CommonModule,
    CommonAppModule,
    UserLandingPageRoutingModule
  ]
})
export class UserLandingPageModule { }
