import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {


  headingData = {
    subHeading: 'OUR PATIENTS',
    mainHeading: 'SUCCESS STORY',
  };

  successCardData = [
    {
      profileImg: 'https://www.yelp.com/user_details?userid=9Fw069Pt4F7rpY51SE1atw',
      comment: 'Very satisfied. I have been coming for years and he has always acted very professional and reliable. I am happy with Dr. Prayaga! One of the best in the DC area!',
      profileName: 'Collin J.'
    },
    {
      profileImg: 'https://www.yelp.com/user_details?userid=WEl6hJAvP2gG5Pj8nuyuMA',
      comment: 'I\'ve been with Dr. Prayaga for four years and he\'s the best! He\'s efficient with understanding your problems and needs and gives you options on how to address it customized to your needs and standards. Not mention the incredible flexibility to fit your schedule. It\'s all about you here!!',
      profileName: 'Genesis S.'
    },
    {
      profileImg: 'https://www.yelp.com/user_details?userid=m5aVIlTJVlCFXO7tLxif-g',
      comment: 'Dr. Prayaga is a very nice person and helped me through some rough times. Very empathetic!',
      profileName: 'Frank H'
    },
    {
      profileImg: 'https://www.yelp.com/user_details?userid=qPObqKlZEP9VDVW8hLbUFQ',
      comment: 'Extremely nice and highly accommodating schedule. Actually listens which is a rarity with psychiatrists. Takes the time to discuss medications and side effects. Really thorough. I don\'t understand the bad reviews…',
      profileName: 'Tania B.'
    },
    {
      profileImg: 'https://www.yelp.com/user_details?userid=EBedxWpNt_R2O_cVLQzd1Q',
      comment: 'I\'ve been going to Dr. Prayaga for years now and he\'s one of my favorite doctors. He truly cares about his patients, doesn\'t waste their time with long waits for appointments, and even has telemedicine appointments to make patient care as convenient for them as possible. Thanks, Dr. Prayaga!',
      profileName: 'M Alana W.'
    }
  ];

  slideConfig = {
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: true,
    infinited: true,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 1,
          centerMode: true
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          centerMode: false
        }
      }
    ]
  };

  constructor() { }

  ngOnInit() {
  }

}
