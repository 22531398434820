import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { DataService } from '@app/common/services/data.service';
import { CognitoService } from '@app/common/services/cognito.service';
import { HttpService } from '@app/common/services/http.service';
import { appTypeEnums, TOAST_STATUSES } from '@app/common/constants/util.constant';
import { IPatientEmergencyInfo } from '@app/common/constants/typeInterfaces.constants';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-medication-consent',
  templateUrl: './medication-consent.component.html',
  styleUrls: ['./medication-consent.component.scss']
})
export class MedicationConsentComponent implements OnInit {
  faRate: any;
  submitFormData: boolean;
  @Input() saveFormData;
  @Input() patientDataList;


  formData: any;
  @Output() submitEvent: EventEmitter<any> = new EventEmitter();
  name: string;
  userDetails: any;
  patientList: any;
  patientId: void;
  patientName: string;
  apptType: number = 1;
  apptLocation: string;
  apptState: string;
  tabContent = [
    {
      headerLabel: 'Introduction',
      data: [
        {
          description: 'Ketamine is a drug that calms and relaxes the body. It is approved by the FDA is for use in adults and for anaesthesia and as a pain reliever during medical procedures. It generally does not impact your breathing. Ketamine’s use for treatment of depression or other mental illnesses is off-label and has not been approved by the FDA.'
        },
        {
          header: 'Why Is Ketamine Being Recommended for Me?',
          description: 'Clinical experience shows that ketamine may be used to treat depression in a helpful manner. A number of studies have shown giving ketamine can improve depression. When administered by vein over a period of about 120 minutes, ketamine may help depression improve rather quickly but it may last only a few days. A series of sessions are used so that the improvement last much longer. While the goal is improvement of depression, results cannot be guaranteed.',
        },
        {
          header: 'What Will Be Done?',
          description: 'I will be receiving ketamine by IV Infusion/Injection. This means an IV will be inserted into a vein of my hand or elbow and fluid will be dripped into the vein over roughly 45 minutes. This fluid will contain a ketamine at a concentration of 0.5 mg/kg of my body weight. (By comparison, when ketamine is used for anaesthesia, the dose is 2 to 9 times higher, and this dose is given over one minute, not 45 minutes). After the treatment I will need a bit of time to recover and may take some sips of fluid if I feel like doing so. I understand that I will be recommended to schedule 6 treatments over about two weeks as a treatment episode. Additional maintenance treatments may or may not be suggested, occurring about once a month or less frequently as recommended by Dr. RAMA PRAYAGA based on how I do during the initial 6 sessions and in the time period after that.',
          list: [
            'Improved access to medical care by enabling a patient to remain in his/her physician’s office (or at remote site) while the physician obtains test results and consults from healthcare practitioners at distant/other sites',
            'More efficient medical evaluation and management',
            'Obtaining expertise of a distant specialist'
          ]
        },
        {
          description: 'I would be given oral Ketamine or liquid solution as an alternate. Oral Ketamine would be given if I have any discomfort with the injection or any side effects like clotting. Dr. Rama Prayaga provides in-house Ketamine liquid to be taken no more than 100 MG over a time period of 120 minutes. Whether Oral or Injection, my vitals like Blood Pressure, breathing etc will be monitored every 30 minutes.'
        }
      ]
    },
    {
      headerLabel: 'Precautions & Side Effects',
      data: [
        {
          header: 'What Safety Precautions Must I Take?',
          list: [
            'I may not eat or drink after midnight before each of the infusions.',
            'I may NOT drive a car, operate hazardous equipment, or engage in hazardous activities for 24 hours after each treatment as reflexes may be slow or impaired. Another adult will need to drive me home.',
            'I must refrain from alcohol or other substances prior to treatment infusions.',
            'I must tell the clinic about all medications I am taking, especially narcotic pain relievers or barbiturates.',
            'In order to qualify to receive ketamine therapy, I will require medical clearance and must share with my ketamine provider the contact information for the doctor or doctors who are treating my depression or anxiety or other psychiatric symptoms.',
            'If I experience a side effect while I am at home, I should contact the doctor who is providing me ketamine (Dr. RAMA PRAYAGA (703) 820-1900), my primary care doctor, call 911 or go to my local emergency room.',
          ],
        },
        {
          header: 'What Are the Side Effects of Ketamine?',
          description: 'When Ketamine is used as an anaesthetic agent the following are listed as side effects:',
          list: [
            'fast, irregular, or low heart beats',
            'increased saliva or thirst',
            'increased or decreased blood pressure',
            'lack of appetite',
            'dreams that may seem real',
            'headaches',
            'confusion',
            'metallic taste',
            'irritation or excitement when waking up',
            'constipation',
            'floating sensation ("out-of-body")',
            'blurry or double vision',
            'breathing problems',
            'nausea or vomiting',
            'twitching, muscle jerks, and muscle tension',
            'risk of drug addiction or dependence'
          ]
        },
        {
          header: 'Rare side effects of ketamine are:',
          list: [
            'allergic reactions',
            'hallucinations',
            'pain at site of injection',
            'euphoria',
            'increase in pressure inside the eye',
            'involuntary eye movements',
            'ulcerations and inflammation in the bladder',
            'low mood or suicidal thoughts',

          ],
        },
        {
          header: 'Side effects of receiving an IV are:',
          list: [
            'mild discomfort at the site of placement',
            'dizziness',
            'bruising',
            'fainting',
            'bleeding',
            'infection',
          ]
        }
      ]
    },
    {
      headerLabel: 'Terms & Conditions',
      data: [
        {
          header: 'Important Notices and Agreements:',
          list: [
            'KETAMINE THERAPY IS NOT A COMPREHENSIVE TREATMENT FOR DEPRESSION, ANXIETY OR ANY PSYCHIATRIC SYMPTOMS Your ketamine infusions are meant to augment (add on to, not be used in place of) comprehensive psychiatric treatment. We advise you to be (and I agree to be) under the care of a qualified mental health professional (or an internal medicine or family physician with experience and skill in treating psychiatric illnesses) while receiving ketamine infusions, and for the duration of your psychiatric symptoms. Unless otherwise agreed to, Dr. RAMA PRAYAGA will not be the provider of these services. Follow up medications may be suggested but these will be the responsibility of my treating physician.',
            'SPECIAL NOTE ON SUICIDAL IDEATION Psychiatric illnesses (especially, depression) carry the risk of suicidal ideation (thoughts of ending one’s life). Any such thoughts you may have now, at any time during the weeks of your ketamine infusions, or at any point in the future, which cannot immediately be addressed by visiting with a mental health professional should prompt you to seek emergency care at an ER or to call 911.',
            'Ketamine use during pregnancy is not generally recommended.',
          ]
        },
        {
          header: 'My Consent for Ketamine Treatment is Voluntary:',
          description: 'My request for Dr. RAMA PRAYAGA to conduct ketamine treatments as described is entirely voluntary and I have not been offered any inducement to consent. I understand that I may refuse ketamine treatments and that my regular treatments for depression would continue. I have been advised that I can seek a second opinion from another doctor before agreeing to have ketamine treatment and am choosing to proceed at this time with or without this second opinion.',
        },
        {
          header: 'Statement of Person Giving Informed Consent ',
          description: 'I will be receiving ketamine by IV Infusion/Injection. This means an IV will be inserted into a vein of my hand or elbow and fluid will be dripped into the vein over roughly 45 minutes. This fluid will contain a ketamine at a concentration of 0.5 mg/kg of my body weight. (By comparison, when ketamine is used for anaesthesia, the dose is 2 to 9 times higher, and this dose is given over one minute, not 45 minutes). After the treatment I will need a bit of time to recover and may take some sips of fluid if I feel like doing so. I understand that I will be recommended to schedule 6 treatments over about two weeks as a treatment episode. Additional maintenance treatments may or may not be suggested, occurring about once a month or less frequently as recommended by Dr. RAMA PRAYAGA based on how I do during the initial 6 sessions and in the time period after that.',
          list: [
            'I have read this consent form and understand the information contained in it.',
            'I have had the opportunity to ask questions about this procedure and consent and wish for Dr. RAMA PRAYAGA and his staff to administer ketamine infusion treatment.'
          ]
        },
        {
          description: 'I would be given oral Ketamine or liquid solution as an alternate. Oral Ketamine would be given if I have any discomfort with the injection or any side effects like clotting. Dr. Rama Prayaga provides in-house Ketamine liquid to be taken no more than 100 MG over a time period of 120 minutes. Whether Oral or Injection, my vitals like Blood Pressure, breathing etc will be monitored every 30 minutes.'
        }
      ]
    },
  ]
  tabContent1 = [
    {
      headerLabel: 'Introduction',
      data: [
        {
          description: 'Teletherapy involves the use of electronic communications to enable health care providers at different locations to share individual patient medical information for the purpose of improving patient care. Providers may include primary care practitioners, specialists, and/or subspecialists. The information may be used for diagnosis, therapy, follow-up and/or education, and may include any of the following:',
          list: [
            'Patient Medical Records',
            'Medical Images',
            'Live two-way audio and video',
            'Output data from medical devices and sound and video files'
          ]
        },
        {
          description: 'Electronic systems used will incorporate network and software security protocols to protect the confidentiality of patient identification and imaging data and will include measures to safeguard the data and to ensure its integrity against intentional or unintentional corruption. Expected Benefits:',
          list: [
            'Improved access to medical care by enabling a patient to remain in his/her physician’s office (or at remote site) while the physician obtains test results and consults from healthcare practitioners at distant/other sites',
            'More efficient medical evaluation and management',
            'Obtaining expertise of a distant specialist'
          ]
        }
      ]
    },
    {
      headerLabel: 'Possible Risks',
      data: [
        {
          description: 'As with any medical procedure, there are potential risks associated with the use of Teletherapy. These risks include, but may not be limited to:',
          list: [
            'In rare cases, information transmitted may not be sufficient (e.g. poor resolution of images) to allow for appropriate medical decision making by the physician and consultant(s)',
            'Delays in medical evaluation and treatment could occur due to deficiencies or failures of the equipment',
            'In very rare instances, security protocols could fail, causing a breach of privacy of personal medical information'
          ]
        },
        {
          description: 'In rare cases, a lack of access to complete medical records may result in adverse drug interactions or allergic reactions or other judgement errors',
        }
      ]
    },
    {
      headerLabel: 'State Laws',
      data: [
        {
          description: 'On October 1, 2012, Maryland became the 13th state to require private sector insurance companies to pay for teletherapy services in mandating that private payers cover teletherapy services that are considered medically necessary and would otherwise be covered when provided face-to-face.'
        },
        {
          description: 'The law requires that health insurers and managed care organizations (MCOs) provide coverage for health care services provided appropriately using teletherapy technology, and that coverage cannot be denied because services were provided through teletherapy rather than inperson. Insurers are not required to cover teletherapy services if the health services would not be a covered benefit even if provided in person, or if the provider is out-of-network.'
        },
        {
          description: `“Teletherepy means the use of telecommunications and information technologies for the exchange of
          information from one site to another, for the provision of health care to an individual from a provider
          through hardwire or Internet connection.”`
        },
        {
          description: `(Source: MD Health Occupations Annotated Sec. 2-101)`
        },
        {
          description: `Teletherepy means, as it relates to the delivery of health care services, the use of interactive audio,
          video, or other telecommunications or electronic technology:`
        },
        {
          description: `By a health care provider to deliver a health care service that is within the scope of practice of the
          health care provider at a site other than the site at which the patient is located; and That enables the
          patient to see and interact with the health care provider at the time the health care service is
          provided to the patient.`
        },
        {
          description: `(Source: Health General Code 15-105.2)`
        },
        {
          description: `Services by electronic means, including but not limited to telephone communication, the Internet,
          facsimile machines, and e-mail is considered teletherapy by the State of Maryland (see above).
          Thus, teletherapy is broadly defined as the use of information technology to deliver medical
          services and information from one location to another. If you and I chose to use information
          technology for some or all of your treatment, you need to understand that:`,
          list: [
            `You retain the option to withhold or withdraw consent at any time without affecting the right
            to future care or treatment or risking the loss or withdrawal of any program benefits to
            which you would otherwise be entitled.`,
            `All existing confidentiality protections are equally applicable.`,
            `Your access to all medical information transmitted during a teletherapy consultation is
            guaranteed, and copies of this information are available for a reasonable fee.`,
            `Dissemination of any of your identifiable images or information from the teletherapy
            interaction to researchers or other entities shall not occur without your consent.`,
            `There are potential risks, consequences, and benefits of teletherapy. Potential benefits
            include, but are not limited to improved communication capabilities, providing convenient
            access to up-to-date information, consultations, support, reduced costs, improved quality,
            change in the conditions of practice, improved access to therapy, better continuity of care,
            and reduction of lost work time and travel costs. Effective therapy is often facilitated when
            the therapist gathers within a session or a series of sessions, a multitude of observations,
            information, and experiences about the client. Therapists may make clinical assessments,
            diagnosis, and interventions based not only on direct verbal or auditory communications,
            written reports, and third person consultations, but also from direct visual and olfactory
            observations, information, and experiences.`

          ]
        },
        {
          description: `Risks may include, but are not limited to:`,
          list: [
            `Lack of reimbursement by your insurance company.`,
            `The technology dropping due to internet connections.`,
            `Delays due to connections or other technologies.`,
            `A breach of information that is beyond our control.`
          ]
        },
        {
          description: `Clinical risks will be discussed in more detail when we speak about teletherapy, but may include:`,
          list: [
            `Discomfort with virtual face-to-face versus in-person treatment,`,
            `Difficulties interpreting non-verbal communication, and`,
            `Limited access to immediate resources if risk of self-harm or harm to others becomes
            apparent.`
          ]
        },
        {
          description: `Again, I will discuss the specifics of teletherapy with you prior to proceeding with using technology as
          a resource and format for your psychotherapy.`
        }
      ]
    },
    {
      headerLabel: 'Appointment Process',
      data: [
        {
          listtype: 'numeric',
          list: [
            'Make an appointment.',
            'Consent forms received and signed',
            'Make payment for your appointment (You will receive a call the day prior to your appointment or the day of your appointment for payment)',
            'Meet via video with New Stress Clinic',
          ]
        },
        {
          description: 'Please keep in mind that you are financially responsible to pay for your teletherapy appointment. If all of the 5 steps have been completed and you still are experiencing issues, please contact the office manager at officemanager@newstressclinic.com'
        },
        {
          warning: 'Cancellation Policy: If you fail to provide a 24 – hour notification for cancellation or fail to connect for your appointment, you will be assessed a $25.00 cancellation fee/no show fee. If a client fails to show for 2 consecutive teletherapy appointments, then any further teletherapy appointments will not be honored and will be rejected by the office.'
        },
      ]
    },
    {
      headerLabel: 'Terms and conditions',
      data: [
        {
          description: 'By signing this form, I understand the following;',
          list: [
            'I understand that the laws that protect privacy and the confidentiality of medical information also apply to Teletherapy, and that no information obtained in the use of Teletherapy which identifies me will be disclosed to researchers or other entities without my consent',
            'I understand that I have the right to withhold or withdraw my consent to the use of Teletherapy in the course of my care at any time, without affecting my right to future care or treatment',
            'I understand that I have the right to inspect all information obtained and recorded in the course of Teletherapy interaction, and may receive copies of this information for a reasonable fee',
            'I understand Teletherapy sessions must be conducted in a safe and secure environment. The physician will not conduct sessions with individuals while they are operating a motor vehicle or other situations which the physician deems unsafe or dangerous. Due to confidentiality, we do ask that you select a private area for sessions.',
            'I understand that a variety of alternative methods of medical care may be available to me, and that I may choose one or more of these at any time. My physician has explained the alternatives to my satisfaction.',
            'I understand that Teletherapy may involve electronic communication of my personal medical information to other medical practitioners who may be located in other areas, including out of state.',
            'I understand that it is my duty to inform my physician of electronic interactions regarding my care that I may have with other healthcare providers.',
            'I understand that I may expect the anticipated benefits from the use of Teletherapy in my care, but that no results can be guaranteed or assured.',
            'I understand that the information about change in therapy consultation must be done only during office visit, not through Teletherapy appointment.',
            'In order to protect patients’ privacy and the integrity of the Teletherapy appointments, video and/or audio recording by either patients and/or New stress clinic is prohibited during Teletherapy sessions.',
            'If you experience technical glitches (unable to connect, poor connection, audio failure, etc.),same day office appointments will be honored at no additional cost.',
            'I understand that in order to protect patients’ privacy and the integrity of the Teletherapyappointments, video and/or audio recording by either patients and/or New stress clinic is prohibited during Teletherapy sessions.',
            'I understand that I will email to officemanager@newstressclinic.com if any concerns or experience technical glitches (unable to connect, poor connection, audio failure, etc.) during my Teletherapy appointment.',
            'I agree to pay all administrative/processing fees including to and but not limited cancellations fees and documentation/medical records processing fees, etc.',
            'I understand that three month supply of medications will not be provided. Medications will be filled once monthly only after Appointment.',
            'It is mandatory to inform the clinic if you received medications after teletherapy appointment via email to drprayaga@newstressclinic.com (reason we request some pharmacies will have out-of-stock, auth is needed, pharmacy will not be able to find in the system in rare cases).',
            'I agree to pay $5 administrative fee if my pharmacy is out of stock or if there is any pharmacy change once meds have been sent.',
            'I acknowledge that medications are received via Email after the appointment.',
            'No refills will be issued during teletherapy.',
            'Please note teletherapy is still cumbersome process for the provider due to careful documentation regarding what pharmacy to send checking PMP, pre-authorization for some medicines, request for 3 months medicine due to insurance requirements etc.so it\'s important for consumer to note video session could be very brief and clients can call office if teletherapyvisit is unsatisfactory.',
            'teletherapy is convenient tool for consumer at no extra cost, but clients are expected to acknowledge their visit, medicine they received by simple email to our office.',
            'Please note that it may take up to 48-96 hrs to get the medicines filled after the teletherapy session. It is also important to inform New Stress Clinic if medications are not received within 96 hrs via Email.',
            'No pets are allowed in the office.',
            'New Stress Clinic are not responsible for pharmacy changes. Pharmacy has to be updated at least 2 hours before the appointment.',
            'For any reason the pharmacy is not able to fill the prescription, the amount for the visit will not be refunded.',
            'New Stress Clinic requests drug screening at CLIA certified lab center. If any guidance is required office will assist.',
            'New Stress Clinic requests to work with newteledoc for coordination of Care with PCP,therapist, or any other providers.'
          ]
        }
      ]
    }
  ];
  tabContent2 = [
    {
      headerLabel: 'Introduction',
      data: [
        {
          description: 'Teletherapy involves the use of electronic communications to enable health care providers at different locations to share individual patient medical information for the purpose of improving patient care. Providers may include primary care practitioners, specialists, and/or subspecialists. The information may be used for diagnosis, therapy, follow-up and/or education, and may include any of the following:',
          list: [
            'Patient Medical Records',
            'Medical Images',
            'Live two-way audio and video',
            'Output data from medical devices and sound and video files'
          ]
        },
        {
          description: 'Electronic systems used will incorporate network and software security protocols to protect the confidentiality of patient identification and imaging data and will include measures to safeguard the data and to ensure its integrity against intentional or unintentional corruption. Expected Benefits:',
          list: [
            'Improved access to medical care by enabling a patient to remain in his/her physician’s office (or at remote site) while the physician obtains test results and consults from healthcare practitioners at distant/other sites',
            'More efficient medical evaluation and management',
            'Obtaining expertise of a distant specialist'
          ]
        }
      ]
    },
    {
      headerLabel: 'Possible Risks',
      data: [
        {
          description: 'As with any medical procedure, there are potential risks associated with the use of telemedicine. These risks include, but may not be limited to:',
          list: [
            'In rare cases, information transmitted may not be sufficient (e.g. poor resolution of images) to allow for appropriate medical decision making by the physician and consultant(s)',
            'Delays in medical evaluation and treatment could occur due to deficiencies or failures of the equipment',
            'In very rare instances, security protocols could fail, causing a breach of privacy of personal medical information'
          ]
        },
        {
          description: 'In rare cases, a lack of access to complete medical records may result in adverse drug interactions or allergic reactions or other judgement errors',
        }
      ]
    },
    {
      headerLabel: 'State Laws',
      data: [
        {
          description: 'VA-DC law defines teletherapy (or teletherapy) as “interactive audio, video or other telecommunications or electronic technology... to deliver a health care service.” As such, the law does not apply to audio-only phone conversations, email messages or faxes between providers and patients.'
        },
        {
          description: 'The law requires that health insurers and managed care organizations (MCOs) provide coverage for health care services provided appropriately using teletherapy technology, and that coverage cannot be denied because services were provided through teletherapy rather than inperson. Insurers are not required to cover teletherapy services if the health services would not be a covered benefit even if provided in person, or if the provider is out-of-network.'
        },
        {
          description: `“Teletherapy means the use of telecommunications and information technologies for the exchange of
          information from one site to another, for the provision of health care to an individual from a provider
          through hardwire or Internet connection.”`
        },
        {
          description: `Teletherepy means, as it relates to the delivery of health care services, the use of interactive audio,
          video, or other telecommunications or electronic technology:`
        },
        {
          description: `By a health care provider to deliver a health care service that is within the scope of practice of the
          health care provider at a site other than the site at which the patient is located; and That enables the
          patient to see and interact with the health care provider at the time the health care service is
          provided to the patient.`
        },
        {
          description: `Services by electronic means, including but not limited to telephone communication, the Internet,
          facsimile machines, and e-mail is considered teletherapy by the State of  VA-DC (see above).
          Thus, teletherapy is broadly defined as the use of information technology to deliver medical
          services and information from one location to another. If you and I chose to use information
          technology for some or all of your treatment, you need to understand that:`,
          list: [
            `You retain the option to withhold or withdraw consent at any time without affecting the right
            to future care or treatment or risking the loss or withdrawal of any program benefits to
            which you would otherwise be entitled.`,
            `All existing confidentiality protections are equally applicable.`,
            `Your access to all medical information transmitted during a teletherapy consultation is
            guaranteed, and copies of this information are available for a reasonable fee.`,
            `Dissemination of any of your identifiable images or information from the teletherapy
            interaction to researchers or other entities shall not occur without your consent.`,
            `There are potential risks, consequences, and benefits of teletherapy. Potential benefits
            include, but are not limited to improved communication capabilities, providing convenient
            access to up-to-date information, consultations, support, reduced costs, improved quality,
            change in the conditions of practice, improved access to therapy, better continuity of care,
            and reduction of lost work time and travel costs. Effective therapy is often facilitated when
            the therapist gathers within a session or a series of sessions, a multitude of observations,
            information, and experiences about the client. Therapists may make clinical assessments,
            diagnosis, and interventions based not only on direct verbal or auditory communications,
            written reports, and third person consultations, but also from direct visual and olfactory
            observations, information, and experiences.`

          ]
        },
        {
          description: `Risks may include, but are not limited to:`,
          list: [
            `Lack of reimbursement by your insurance company.`,
            `The technology dropping due to internet connections.`,
            `Delays due to connections or other technologies.`,
            `A breach of information that is beyond our control.`
          ]
        },
        {
          description: `Clinical risks will be discussed in more detail when we speak about teletherapy, but may include:`,
          list: [
            `Discomfort with virtual face-to-face versus in-person treatment,`,
            `Difficulties interpreting non-verbal communication, and`,
            `Limited access to immediate resources if risk of self-harm or harm to others becomes
            apparent.`
          ]
        },
        {
          description: `Again, I will discuss the specifics of teletherapy with you prior to proceeding with using technology as
          a resource and format for your psychotherapy.`
        }
      ]
    },
    {
      headerLabel: 'Appointment Process',
      data: [
        {
          listtype: 'numeric',
          list: [
            'Make an appointment.',
            'Consent forms received and signed',
            'Make payment for your appointment (You will receive a call the day prior to your appointment or the day of your appointment for payment)',
            'Meet via video with New Stress Clinic',
            'Validation of medication and medication will be electronically submitted to the pharmacy.'
          ]
        },
        {
          description: 'If any of the above 5 steps are not completed, there will be a delay in your appointment as well as delay in electronically sending your prescription to your pharmacy.',
        },
        {
          description: 'Please keep in mind that you are financially responsible to pay for your teletherapy appointment. If all of the 5 steps have been completed and you still are experiencing issues, please contact the office manager at officemanager@newstressclinic.com'
        },
        {
          warning: 'Cancellation Policy: If you fail to provide a 24 – hour notification for cancellation or fail to connect for your appointment, you will be assessed a $25.00 cancellation fee/no show fee. If a client fails to show for 2 consecutive teletherapy appointments, then any further teletherapy appointments will not be honored and will be rejected by the office.'
        },
      ]
    },
    {
      headerLabel: 'Terms and conditions',
      data: [
        {
          description: 'By signing this form, I understand the following;',
          list: [
            'I understand that the laws that protect privacy and the confidentiality of medical information also apply to Teletherapy, and that no information obtained in the use of Teletherapy which identifies me will be disclosed to researchers or other entities without my consent',
            'I understand that I have the right to withhold or withdraw my consent to the use of Teletherapy in the course of my care at any time, without affecting my right to future care or treatment',
            'I understand that I have the right to inspect all information obtained and recorded in the course of Teletherapy interaction, and may receive copies of this information for a reasonable fee',
            'I understand Teletherapy sessions must be conducted in a safe and secure environment. The physician will not conduct sessions with individuals while they are operating a motor vehicle or other situations which the physician deems unsafe or dangerous. Due to confidentiality, we do ask that you select a private area for sessions.',
            'I understand that a variety of alternative methods of medical care may be available to me, and that I may choose one or more of these at any time. My physician has explained the alternatives to my satisfaction.',
            'I understand that Teletherapy may involve electronic communication of my personal medical information to other medical practitioners who may be located in other areas, including out of state.',
            'I understand that it is my duty to inform my physician of electronic interactions regarding my care that I may have with other healthcare providers.',
            'I understand that I may expect the anticipated benefits from the use of Teletherapy in my care, but that no results can be guaranteed or assured.',
            'I understand that the information about change in medication consultation must be done only during office visit, not through Teletherapy appointment.',
            'In order to protect patients’ privacy and the integrity of the Teletherapy appointments, video and/or audio recording by either patients and/or New stress clinic is prohibited during Teletherapy sessions.',
            'If you experience technical glitches (unable to connect, poor connection, audio failure, etc.),same day office appointments will be honored at no additional cost.',
            'I understand that in order to protect patients’ privacy and the integrity of the Teletherapyappointments, video and/or audio recording by either patients and/or New stress clinic is prohibited during Teletherapy sessions.',
            'I understand that I will email to officemanager@newstressclinic.com if any concerns or experience technical glitches (unable to connect, poor connection, audio failure, etc.) during my Teletherapy appointment.',
            'Teletherapy is for consumer convenience but not for the provider.',
            'I understand that three month supply of medications will not be provided. Medications will be filled once monthly only after Appointment.',
            'I agree to pay all administrative/processing fees including to and but not limited cancellations fees and documentation/medical records processing fees, etc',
            'It is mandatory to inform the clinic if you received medications after teletherapy appointment via email to drprayaga@newstressclinic.com (reason we request some pharmacies will have out-of-stock, auth is needed, pharmacy will not be able to find in the system in rare cases).',
            'I agree to pay $5 administrative fee if my pharmacy is out of stock or if there is any pharmacy change once meds have been sent.',
            'I acknowledge that medications are received via Email after the appointment.',
            'No refills will be issued during teletherapy.',
            'Please note teletherapy is still cumbersome process for the provider due to careful documentation regarding what pharmacy to send checking PMP, pre-authorization for some medicines, request for 3 months medicine due to insurance requirements etc.so it\'s important for consumer to note video session could be very brief and clients can call office if teletherapyvisit is unsatisfactory.',
            'teletherapy is convenient tool for consumer at no extra cost, but clients are expected to acknowledge their visit, medicine they received by simple email to our office.',
            'Please note that it may take up to 48-96 hrs to get the medicines filled after the teletherapy session. It is also important to inform New Stress Clinic if medications are not received within 96 hrs via Email.',
            'No pets are allowed in the office.',
            'New Stress Clinic are not responsible for pharmacy changes. Pharmacy has to be updated at least 2 hours before the appointment.',
            'For any reason the pharmacy is not able to fill the prescription, the amount for the visit will not be refunded.',
            'New Stress Clinic requests drug screening at CLIA certified lab center. If any guidance is required office will assist.',
            'New Stress Clinic requests to work with newteledoc for coordination of Care with PCP,therapist, or any other providers.'
          ]
        }
      ]
    }
  ];
  consentPoints = [
    `If the prescription/medication is lost or stolen due to any circumstances, you need to submit a
    police report and then schedule a follow up appointment. Until then medication will not be
    called in.`
  ];
  emergencyContactDetails: IPatientEmergencyInfo;
  autoForm: any;
  autoFill: boolean;
  indexValue: any;
  constructor(private http: HttpService, private cognitoService: CognitoService, private formService: CustomFormHandlerService, private dataService: DataService, private observable: ObservableHelperService, ) { }
  ngOnInit() {
    this.formService.signaturValues = [];
    this.cognitoService.getUser().then(user => {
      this.userDetails = user;
      this.name = `${this.userDetails.firstName} ${this.userDetails.lastName}`;
  
      // this.getPateint();
      let appointmentFormData = this.dataService.getData(this.dataService.dataObject.APPOINTMENT_DETAIL);
   
     // present code
      // Find the specific configuration by key and update its value
      const config = this.formService.medicationConsentFormConfig.find(each => each.key === "feel_satisfied");
      if (config) {
          config.value = appointmentFormData ? appointmentFormData.appoinmentData[0].value : '';
      }

      // this.formData = this.formService.initForm(this.formService.formTypes.MEDICATION);
      this.apptLocation =this.dataService.data.appointmentDetail.typeAppointmentData[1].value;
      this.apptType = this.dataService.data.appointmentDetail.typeAppointmentData[0].value;
      if (!this.apptType) {
        this.apptType = appTypeEnums.medicationManagement;
      }
      // this.getPateint(this.userDetails.userId);
          this.getPateint();
      this.autoForm = this.formService.initForm(this.formService.formTypes.AUTOFILLMEDICATIONDETAILS);
      if (this.apptType == appTypeEnums.medicationManagement) {
        // Medication management feedback
        this.formData = this.formService.initForm(this.formService.formTypes.MEDICATION);
        // this.formData.formInputs[22].value = true;
        console.log( this.formData);
      } else if (this.apptType == appTypeEnums.ktm) {
        // Ketamine feedback
        this.formData = this.formService.initForm(this.formService.formTypes.KETAMINECONSENT);
      } else if (this.apptType == appTypeEnums.tms) {
        // TMS feedback
        this.formData = this.formService.initForm(this.formService.formTypes.TMSCONSENT);
      }
      else if (this.apptType == appTypeEnums.therepy) {
        // TMS feedback
        this.formData =  this.formService.initForm(this.formService.formTypes.TELETHERAPY);
      }
      else {
        // Medication management feedback
        this.formData = this.formService.initForm(this.formService.formTypes.MEDICATION);
      }
    });
    if(this.dataService.previousAppointmentData == null){
      this.autoFill = false;
    }
    else{
      this.autoFill = true;
    }

  }
  getdata(event){
    if(this.apptType == appTypeEnums.therepy){
      if(!event.slider.value === true){
        this.formData.formInputs[0].value= true;
        this.formData.formInputs[1].value= true;
        this.formData.formInputs[2].value= true;
        this.formData.formInputs[3].value= true;
      }
      else{
        this.formData.formInputs[0].value= false;
        this.formData.formInputs[1].value= false;
        this.formData.formInputs[2].value= false;
        this.formData.formInputs[3].value= false;
      }

    }
    else if(this.apptType == appTypeEnums.tms){
      this.formData.formInputs.forEach((each, index) => {
        if (each.key === "tmshospitalizedPastMonth") {
           this.indexValue = index;
        }
      });
      if(!event.slider.value === true){
      this.formData.formInputs[this.indexValue].value = true;
      let sliderIndex = this.indexValue;
      let sliderObjValue = this.dataService.previousAppointmentData["medicationConsent"].hospitalizedPastMonth;
      for (let i = sliderIndex + 1; ; i++) {
        if (this.formData.formInputs[i].key !== "tmstmsConsentDate") {
          if (sliderObjValue === false) {
            this.formData.formInputs[i].showontoggle = false;
            this.formData.formInputs[i].value = '';
            if (this.formData.formInputs[i].validators) {
              this.formData.formGroup.controls[this.formData.formInputs[i].key].clearValidators();
              this.formData.formGroup.controls[this.formData.formInputs[i].key].updateValueAndValidity();
              this.formData.formInputs[i].validators = [];
            }
          } else {
            this.formData.formInputs[this.indexValue].showontoggle = true;
            this.formData.formInputs[this.indexValue+1].value = this.dataService.previousAppointmentData["medicationConsent"].hospitalName;
            this.formData.formInputs[this.indexValue+2].value = this.dataService.previousAppointmentData["medicationConsent"].hospitalizedReason;
            // if (this.formData.formInputs[i].validators) {
            //   this.formData.formGroup.controls[this.formData.formInputs[i].key].setValidators([Validators.required, Validators.minLength(1), this.valideTextArea]);
            // }
          }
        } else {
          break;
        }
      }
    }
    else{
        this.formData.formInputs[this.indexValue].value = false;
        let sliderIndex = this.indexValue;
        let sliderObjValue = true;
        for (let i = sliderIndex + 1; ; i++) {
          if (this.formData.formInputs[i].key !== "tmstmsConsentDate") {
            if (!sliderObjValue === true) {
              this.formData.formInputs[i].showontoggle = false;
              this.formData.formInputs[i].value = '';
              if (this.formData.formInputs[i].validators) {
                this.formData.formGroup.controls[this.formData.formInputs[i].key].clearValidators();
                this.formData.formGroup.controls[this.formData.formInputs[i].key].updateValueAndValidity();
                this.formData.formInputs[i].validators = [];
              }
            } else {
              this.formData.formInputs[i].showontoggle = true;
              this.formData.formInputs[this.indexValue].showontoggle = true;
              this.formData.formInputs[this.indexValue+1].value = "";
              this.formData.formInputs[this.indexValue+2].value = "";
              // if (this.formData.formInputs[i].validators) {
              //   this.formData.formGroup.controls[this.formData.formInputs[i].key].setValidators([Validators.required, Validators.minLength(1), this.valideTextArea]);
              // }
            }
          } else {
            break;
          }
        }
    }
    }
    else if(this.apptType == appTypeEnums.ktm){
      this.formData.formInputs.forEach((each, index) => {
        if (each.key === "ktmhospitalizedPastMonth") {
           this.indexValue = index;
        }
      });
      if(!event.slider.value === true){
      this.formData.formInputs[this.indexValue].value = true;
      let sliderIndex = this.indexValue;
      let  sliderObjValue  = this.dataService.previousAppointmentData["medicationConsent"].hospitalizedPastMonth;
      for (let i = sliderIndex + 1; ; i++) {
        if (this.formData.formInputs[i].key !== "ktmketamineConsentDate") {
          if (sliderObjValue === false) {
            this.formData.formInputs[i].showontoggle = false;
            this.formData.formInputs[i].value = '';
            if (this.formData.formInputs[i].validators) {
              this.formData.formGroup.controls[this.formData.formInputs[i].key].clearValidators();
              this.formData.formGroup.controls[this.formData.formInputs[i].key].updateValueAndValidity();
              this.formData.formInputs[i].validators = [];
            }
          } else {
            // this.formData.formInputs[i].showontoggle = true;
            this.formData.formInputs[this.indexValue].value = false;
            this.formData.formInputs[this.indexValue + 1].value = this.dataService.previousAppointmentData["medicationConsent"].hospitalName;
            this.formData.formInputs[this.indexValue + 2].value = this.dataService.previousAppointmentData["medicationConsent"].hospitalizedReason;
            // if (this.formData.formInputs[i].validators) {
            //   this.formData.formGroup.controls[this.formData.formInputs[i].key].setValidators([Validators.required, Validators.minLength(1), this.valideTextArea]);
            // }
          }
        } else {
          break;
        }
      }
    }
    else{
      this.formData.formInputs[this.indexValue].value = false;
      let sliderIndex = this.indexValue;
      let  sliderObjValue  = true;
      for (let i = sliderIndex + 1; ; i++) {
        if (this.formData.formInputs[i].key !== "ktmketamineConsentDate") {
          if (!sliderObjValue === true) {
            this.formData.formInputs[i].showontoggle = false;
            this.formData.formInputs[i].value = '';
            if (this.formData.formInputs[i].validators) {
              this.formData.formGroup.controls[this.formData.formInputs[i].key].clearValidators();
              this.formData.formGroup.controls[this.formData.formInputs[i].key].updateValueAndValidity();
              this.formData.formInputs[i].validators = [];
            }
          } else {          
            this.formData.formInputs[this.indexValue].showontoggle = true;
            this.formData.formInputs[this.indexValue+1].value = "";
            this.formData.formInputs[this.indexValue+2].value = "";
          }
        } else {
          break;
        }
      }

    }
  }
  else if(this.apptType == appTypeEnums.medicationManagement){
    this.dataService.previousValue = event.slider.value;
    this.formData.formInputs.forEach((each, index) => {
      if (each.key === "mmhospitalizedPastMonth") {
         this.indexValue = index;
      }
    });
    if(!event.slider.value === true){
      this.formData.formInputs[this.indexValue].value = !this.dataService.previousAppointmentData["medicationConsent"].hospitalizedPastMonth;
    let sliderIndex = this.indexValue;
    let  sliderObjValue  =  this.dataService.previousAppointmentData["medicationConsent"].hospitalizedPastMonth;
    for (let i = sliderIndex + 1; ; i++) {
      if (this.formData.formInputs[i].key !== 'medication_signature') {
        if (sliderObjValue === false) {
          this.formData.formInputs[i].showontoggle = false;
          this.formData.formInputs[i].value = '';
          if (this.formData.formInputs[i].validators) {
            this.formData.formGroup.controls[this.formData.formInputs[i].key].clearValidators();
            this.formData.formGroup.controls[this.formData.formInputs[i].key].updateValueAndValidity();
            this.formData.formInputs[i].validators = [];
          }
        } else {
          this.formData.formInputs[this.indexValue].showontoggle = true;
          this.formData.formInputs[this.indexValue+1].value = this.dataService.previousAppointmentData["medicationConsent"].hospitalName;
          this.formData.formInputs[this.indexValue+2].value = this.dataService.previousAppointmentData["medicationConsent"].hospitalizedReason;
          // if (this.formData.formInputs[i].validators) {
          //   this.formData.formGroup.controls[this.formData.formInputs[i].key].setValidators([Validators.required, Validators.minLength(1), this.valideTextArea]);
          // }
        }
      } else {
        break;
      }
    }
    }else{
      this.formData.formInputs.forEach((each, index) => {
        if (each.key === "mmhospitalizedPastMonth") {
           this.indexValue = index;
        }
      });
      this.formData.formInputs[this.indexValue].value = false;
      let sliderIndex =this.indexValue;
      let  sliderObjValue  = true;
      for (let i = sliderIndex + 1; ; i++) {
        if (this.formData.formInputs[i].key !== 'medication_signature') {
          if (!sliderObjValue === true) {
            this.formData.formInputs[i].showontoggle = false;
            this.formData.formInputs[i].value = '';
            if (this.formData.formInputs[i].validators) {
              this.formData.formGroup.controls[this.formData.formInputs[i].key].clearValidators();
              this.formData.formGroup.controls[this.formData.formInputs[i].key].updateValueAndValidity();
              this.formData.formInputs[i].validators = [];
            }
          } else {
            this.formData.formInputs[i].showontoggle = true;
            this.formData.formInputs[this.indexValue].showontoggle = true;
            this.formData.formInputs[this.indexValue+1].value = "";
            this.formData.formInputs[this.indexValue+2].value = "";
            // if (this.formData.formInputs[i].validators) {
            //   this.formData.formGroup.controls[this.formData.formInputs[i].key].setValidators([Validators.required, Validators.minLength(1), this.valideTextArea]);
            // }
          }
        } else {
          break;
        }
      }
    }
  }
  }
 

  /**
 * Fetch the patient name only if the patient ID from the appointment data matches the patient ID in the patient data list 
 */
    getPateint() {
    if (this.patientDataList) {
        // Use the correct method name
        console.log(this.patientName, 'patientName');
        console.log('patientDataList', this.patientDataList);

        let appointmentFormData = this.dataService.getData(this.dataService.dataObject.APPOINTMENT_DETAIL);
        this.patientId = (appointmentFormData) ? appointmentFormData.appoinmentData[1].value : '';

        // Find patient state using Array.find for better performance
        const patient = this.patientDataList.patients.find(p => p.patientId === this.patientId);
        if (patient) {
            this.apptState = JSON.parse(patient.patientBasicInfo).streetAddress.state;
        }
        this.patientName = this.getPatienName(this.patientDataList.patients);
        console.log('patientName000', this.patientName);


        // Use a switch statement for better readability and performance
        switch (this.apptType) {
            case appTypeEnums.medicationManagement:
                this.formService.medicationConsentFormConfig[0].value = this.patientName;
                break;
            case appTypeEnums.tms:
                this.formService.tmsConsentConsentFormConfig[0].value = this.patientName;
                break;
            case appTypeEnums.therepy:
                this.formService.telemedicineConsentFormConfig[0].value = this.patientName;
                break;
            case appTypeEnums.ktm:
                this.formService.ketamineConsentConsentFormConfig[0].value = this.patientName;
                break;
            default:
                this.formService.medicationConsentFormConfig[0].value = this.patientName;
                break;
        }
    }
          if (this.apptType === appTypeEnums.ktm) {
            this.getPatientDetails();
        }
}
  valideTextArea(control: FormControl): any {
    if (control.value !== '' && control.value !== undefined && control.value !== null) {
      var regex = /^[^.\s]/;
      var found = control.value.length > 0 && regex.test(control.value);
      return found ? null : { name: true };
    } else {
      return null;
    }
  }
  /**
   * Retrieve the patient's name from the patient list and return the full name for that specific patient based on the patient id.
   * 
   * @param patientList 
   * @returns 
   */
  getPatienName(patientList) {
    let patient = patientList.find(each => each.patientId === this.patientId);
    return `${patient.firstName} ${patient.lastName}`;
  }
  getRatingValue(event) {
    console.log(event);
    console.log('rating', this.faRate);
  }
  getFormData(event) {
    if (event) {
      if (this.apptType == appTypeEnums.medicationManagement) {
        this.formService.medicationConsentFormConfig = this.assignFormValues(event, this.formService.medicationConsentFormConfig);
        this.dataService.setData(this.dataService.dataObject.MEDICATION_CONSENT, this.formService.medicationConsentFormConfig);
        this.formService.signaturValues[0] = this.formService.medicationConsentFormConfig[this.formService.medicationConsentFormConfig.length - 1].value;
      } else if (this.apptType == appTypeEnums.tms) {
        this.formService.tmsConsentConsentFormConfig = this.assignFormValues(event, this.formService.tmsConsentConsentFormConfig);
        this.dataService.setData(this.dataService.dataObject.MEDICATION_CONSENT, this.formService.tmsConsentConsentFormConfig);
        this.formService.signaturValues[0] = this.formService.tmsConsentConsentFormConfig[this.formService.tmsConsentConsentFormConfig.length - 1].value;
      }  else if (this.apptType == appTypeEnums.therepy) {
        this.formService.telemedicineConsentFormConfig = this.assignFormValues(event, this.formService.teletherapyConsentFormConfig);
        this.dataService.setData(this.dataService.dataObject.TELEMEDICINE_CONSENT, this.formService.teletherapyConsentFormConfig);
        // this.formService.signaturValues[0] = this.formService.tmsConsentConsentFormConfig[this.formService.tmsConsentConsentFormConfig.length - 1].value;
      }
      else if (this.apptType == appTypeEnums.ktm) {
        this.formService.ketamineConsentConsentFormConfig = this.assignFormValues(event, this.formService.ketamineConsentConsentFormConfig);
        this.dataService.setData(this.dataService.dataObject.MEDICATION_CONSENT, this.formService.ketamineConsentConsentFormConfig);
        this.formService.signaturValues[0] = this.formService.ketamineConsentConsentFormConfig[this.formService.ketamineConsentConsentFormConfig.length - 1].value;
      }

      this.submitEvent.emit({ status: event, step: 4 });
    }
  }

  assignFormValues(formData, formConfig) {
    let keys = Object.keys(formData.value);
    formConfig.forEach((each, index) => {
      if (keys.includes(each.key)) {
        each.value = formData.value[each.key];
      }
    });
    return formConfig;
  }

  /**
   * get patient complete details
   */
  getPatientDetails() {
    let payload = {
      type: 'GET',
      url: 'getOnePatient',
      isDeveloper: true,
      pathVariables: [this.patientId, this.userDetails.userId]
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      console.log(res, 'getPatientDetails121212121');
      if (this.http.isSuccessfulResponse(res) && res) {
        this.emergencyContactDetails = {
          name: res.data.patientBasicInfo.emergencyContactName ? res.data.patientBasicInfo.emergencyContactName : '---',
          number: res.data.patientBasicInfo.emergencyContactNumber ? res.data.patientBasicInfo.emergencyContactNumber : '---',
          relation: res.data.patientBasicInfo.relationWithPatient ? res.data.patientBasicInfo.relationWithPatient : '---',
          address: res.data.patientBasicInfo.emergencyContactAddress ? res.data.patientBasicInfo.emergencyContactAddress : '---',
        }
      } else {
        let message = TOAST_MESSAGES.USER_INFO_FAIL;
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
}
