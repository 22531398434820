import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TOAST_STATUSES } from '@app/common/constants/util.constant';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';

@Component({
  selector: 'app-home-wrapper',
  templateUrl: './home-wrapper.component.html',
  styleUrls: ['./home-wrapper.component.scss']
})


export class HomeWrapperComponent implements OnInit {


  audio: any;
  path: any = 'assets/audio/homePageMusic.mp3';

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event) {
    this.bgSound();
  }


  constructor(private observable: ObservableHelperService, private eleRef: ElementRef, private router: Router, private route: ActivatedRoute) {
    this.audio = new Audio(this.path);
    this.audio.volume = 0.20;
    this.audio.loop = true;
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length > 0 && params.paymentDone) {
        this.observable.showToast(TOAST_STATUSES.SUCCESS, 'Payment is successfully done.');
        const removedParams = {...params};
        delete removedParams.paymentDone;
        this.router.navigate([], { queryParams: removedParams });
      }
    });
  }

  ngOnInit() {
    this.observable.enableScrollToTop();
    window.addEventListener('beforeunload', (event) => {
      // Cancel the event as stated by the standard.
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    });

  }
  ngAfterViewInit(): void {
    // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    // Add 'implements AfterViewInit' to the class.
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
  bgSound() {
    let playing = false;
    if (document.body.scrollTop !== 0 || document.documentElement.scrollTop !== 0) {
      try {
        this.audio.play();
        playing = true;
      } catch (err) {
        console.log(err);
      }
    } else {
      this.audio.pause();
      playing = false;
    }
  }
  ngOnDestroy() {
    this.bgSound();
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.audio.pause();
  }




}
