import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { convertDateToSpecifiedOffset, DEFAULT_TIME_ZONE_DIFF, TOAST_STATUSES } from '@app/common/constants/util.constant';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';

@Component({
  selector: 'app-patient-history-list',
  templateUrl: './patient-history-list.component.html',
  styleUrls: ['../appointments-list/appointments-list.component.scss']
})
export class PatientHistoryListComponent implements OnInit {

  @Input() adminPermissions;
  // patient data @input varible and initial data
  @Input() patientList: any = {
    patientName: '',
    billAmount: '',
  };
  @Output() patientTrigger = new EventEmitter<any>();
  @ViewChild('op', { static: false }) overlay;


  constructor(private router: Router, private observable: ObservableHelperService) { }

  ngOnInit() {
    console.log(this.patientList);
    let duration = this.patientList.info.appointmentType.duration;
    let invoiceStatus = this.patientList.invoice.status;
    let appointmentStartDate: any = `${this.patientList.info.appointmentDate}T${this.patientList.info.appointmentTime}`;
    let appointmentEndDate: any = new Date(new Date(appointmentStartDate).getTime() + duration * 60000);
    appointmentStartDate = new Date(appointmentStartDate).valueOf();
    appointmentEndDate = new Date(appointmentEndDate).valueOf();
    let currentDate = new Date(convertDateToSpecifiedOffset(DEFAULT_TIME_ZONE_DIFF, this.patientList.timestamp)).valueOf();
    if (this.patientList.info.appointmentStatus === 'A') {
      let removableActions = ['send_reminder', 'reschedule_appointment', 'cancel_appointment'];
      if (currentDate < appointmentStartDate) {
        if (invoiceStatus === 'ungenerated') {
          let generateInvoice = {
            name: 'Generate Invoice',
            icon: 'admininvoice',
            className: 'generate',
            label: 'Generate Invoice',
            iconName: 'admininvoice',
            id: 'generate_invoice',
            colorIconName: 'generate-invoice-color'
          };
          if (this.adminPermissions.INVOICE_EDIT) { this.patientList.actions.unshift(generateInvoice); }
          return 'Invoice pending';
        }
        if (invoiceStatus === 'paid') {
          let activePaidActions = [
            {
              name: 'Mark Absent',
              icon: 'mark-absent',
              className: 'mark-absent',
              label: 'Mark Absent',
              iconName: 'mark-absent',
              id: 'mark_absent',
              colorIconName: 'mark-absent-color'
            },
            {
              name: 'Cancel Appointment',
              icon: 'cancel-appointment-normal',
              className: 'cancel-appointment-normal',
              label: 'Cancel Appointment',
              iconName: 'cancel-appointment-normal',
              id: 'cancel_appointment',
              colorIconName: 'cancel-appointment-color'
            },
            {
              name: 'Reschedule Appointment',
              icon: '',
              className: '',
              label: 'Reschedule Appointment',
              iconName: '',
              id: 'reschedule_appointment',
              colorIconName: 'mixedReschedule'
            },
            {
              name: 'Send Reminder',
              icon: 'remainder',
              className: 'remainder',
              label: 'Send Reminder',
              iconName: 'remainder',
              id: 'send_reminder',
              colorIconName: 'sendReminder'
            }
          ];
          if (this.adminPermissions.APPOINTMENT_EDIT) {
            activePaidActions.forEach(eachAction => {
              this.patientList.actions.unshift(eachAction);
            });
          }
        }
        return 'Active';
      } else if (currentDate >= appointmentStartDate && currentDate <= appointmentEndDate) {
        return 'Inprogress';
      } else if (invoiceStatus === 'ungenerated') {
        let generateInvoice = {
          name: 'Generate Invoice',
          icon: 'admininvoice',
          className: 'generate',
          label: 'Generate Invoice',
          iconName: 'admininvoice',
          id: 'generate_invoice',
          colorIconName: 'generate-invoice-color'
        };
        this.patientList.actions = this.patientList.actions.filter(action => !removableActions.includes(action.id));
        if (this.adminPermissions.INVOICE_EDIT) { this.patientList.actions.unshift(generateInvoice); }
        return 'Invoice pending';
      } else {
        this.patientList.actions = this.patientList.actions.filter(action => !removableActions.includes(action.id));
        return 'Payment pending';
      }
    } else if (this.patientList.info.appointmentStatus === 'B') {
      if (invoiceStatus === 'unpaid') {
        let invoiceDetails = {
          name: 'Invoice Details',
          icon: 'Invoice-slick',
          className: 'generate',
          label: 'Invoice Details',
          id: 'invoice_details',
          iconName: 'Invoice-slick',
          colorIconName: 'Invoice-color'
        };
        this.patientList.actions = this.patientList.actions.filter(action => action.id !== 'generate_invoice');
        if (this.adminPermissions.INVOICE_DETAILS) { this.patientList.actions.unshift(invoiceDetails); }
      } else if (invoiceStatus === 'paid') {
        this.patientList.actions = this.patientList.actions.filter(action => action.id !== 'generate_invoice');
      }
    }
    console.log(this.patientList);
  }
  @HostListener('window:scroll', ['$event'])
  scrollHandler(event) {
    if (this.overlay) {
      this.overlay.hide();
    }
  }

  // EMployee Details redirection
  invoiceDetailsClick(id) {
    this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.APPOINTMENTS}/${id}`]);
  }
  patientEvent(e, patientData, actionID, invoice, op?) {
    e.stopPropagation();
    if(op) { op.hide(e); }
    this.patientTrigger.emit({ patientData, actionID, invoice });
  }
  moreClick(e, op) {
    e.stopPropagation();
    op.show(e);
  }
}
