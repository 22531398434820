<!-- services section start -->
<section class="services__section full-width">
    <!-- services container start -->
    <div class="services__container restrict-width padding-lr">
        <app-section-header [headingData]="headingData"></app-section-header>
        <!-- services cards block start -->
        <div class="services__cards-block">
            <div *ngFor="let card of serviceCardData">
                <app-services-card [serviceCard]="card"></app-services-card>
            </div>
        </div>
        <!-- services cards block end -->
        <!-- book now block start -->
        <div class="book__now-block">
            <div class="orange-heading">Dr. Prayaga, as part of his holistic approach coordinates care with local hospital, community service boards and therapists.</div>
            <div class="common-btn-style">
                <div (click)="navigateBookingpage()">Book an Appointment</div>
            </div>
        </div>
        <!-- book now block end -->
    </div>
    <!-- services container end -->
</section>
<!-- services section end -->
