import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-location-service-card',
  templateUrl: './location-service-card.component.html',
  styleUrls: ['./location-service-card.component.scss']
})
export class LocationServiceCardComponent implements OnInit {

  @Input() formGroup: any;
  @Input() formInputs: any;
  @Input() schedulesCount: any;
  @Input() schedules: any;
  @Input() serviceForms: any;
  @Input() allServices: any;
  @Input() formSubmitAttempt: any;
  @Input() physiciansList: any;
  @Output() sendTimeScheduleDetails = new EventEmitter();
  @Output() prePopulate = new EventEmitter();
  @Output() deleteLocationService = new EventEmitter();
  @Output() addClass = new EventEmitter();

  constructor() {
  }

  addNewIndex: any = 0;
  formTags: any = [];
  isOpenTiming: boolean = false;
  isEditType = false;
  editTimeIndex = -1;
  defaultTimeSchedule = {};
  cancelPopupShowHide: boolean = false;
  treatmentIds: any = [];
  filteredArr: any = [];
  overlay: any;
  searchValue: any;
  opTarget: any;
  sessionDurationFlag: boolean = false;
  deletePopup: boolean = false;
  sessionDuration: number;

  ngOnInit() {
    let fromTime = new Date();
    fromTime.setHours(6);
    fromTime.setMinutes(30);
    fromTime.setSeconds(0);
    let toTime = new Date();
    toTime.setHours(23);
    toTime.setMinutes(30);
    toTime.setSeconds(0);
    this.defaultTimeSchedule = { days: [0, 1, 2, 3, 4], daysInString: 'Mon - Fri', fromTime, toTime };
    // console.log(this.schedules);
  }

  /**
 * this function is used to check error for a given input control
 * @param control {FormControl} - form control to be validated
 * @param formSubmitted {boolean} - to validate the form on form submission
 */
  isInvalid(control: { invalid: any; touched: any; dirty: any; }, formSubmitted: any) {
    return ((control.invalid && formSubmitted && (control.touched || control.dirty)) ||
      (formSubmitted && control.invalid)
    );
  }
  isNaN(val: string) {
    let patt = /\D/g;
    return patt.test(val);
  }
  addNewSchedule() {
    if (this.formGroup.value.sessionDuration && this.formGroup.value.sessionDuration > 0) {
      this.sessionDurationFlag = false;
      this.isEditType = false;
      this.isOpenTiming = true;
      this.sessionDuration = this.formGroup.value.sessionDuration;
    } else {
      this.cancelPopupShowHide = true;
      this.sessionDurationFlag = true;
      this.addClass.emit(true);
    }
  }
  editSchedule(index: number) {
    if (this.formGroup.value.sessionDuration && this.formGroup.value.sessionDuration > 0) {
      this.sessionDurationFlag = false;
      this.editTimeIndex = index;
      this.isEditType = true;
      this.isOpenTiming = true;
      this.sessionDuration = this.formGroup.value.sessionDuration;
    } else {
      this.cancelPopupShowHide = true;
      this.sessionDurationFlag = true;
      this.addClass.emit(true);
    }
}
  deleteSchedule(index: any) {
    this.schedules.splice(index, 1);
  }

  showHideCancelPopup(event: boolean) {
    this.isOpenTiming = event;
    if (!event) {
      this.editTimeIndex = -1;
      this.isEditType = false;
      // console.log(event)
    }
  }
  sendScheduleDetails(details: {}) {
    this.sendTimeScheduleDetails.emit({ ...details, editTimeIndex: this.editTimeIndex });
    this.showHideCancelPopup(false);
  }
  // deleting servicde show popup prior
  deleteService() {
    this.cancelPopupShowHide = true;
    this.deletePopup = true;
  }
  // popup yes/no buttons
  popUpClick(event: any, status: boolean) {
    this.cancelPopupShowHide = false;
    this.addClass.emit(true);
    this.deletePopup = false;
    // tslint:disable-next-line:no-unused-expression
    status === true ? this.deleteLocationService.emit() : null;
  }
  // popUpCancel
  cancelPopupShowHideStatusFun(status: { cancelpopup: boolean; }) {
    this.deletePopup = false;
    this.sessionDurationFlag = false;
    this.cancelPopupShowHide = status.cancelpopup;
  }

  // searchText on keyup event
  searchText(event: any, field: any, op: any, target: any) {
    if (field.key === 'treatment' && field.editable) {
      // this.prePopulate.emit();
      this.searchValue = event.target.value.trim();
      this.overlay = op;
      this.opTarget = target;
      this.prePopulate.emit({click: false});
      this.overlay.hide();
      let finalArr = [];
      this.treatmentIds = [];
      if (this.searchValue !== '') {
        this.serviceForms.forEach(obj => obj.formGroup.value.defaultTreatmentId !== '' ? this.treatmentIds.push(obj.formGroup.value.defaultTreatmentId) : null);
        finalArr = [...this.allServices];
        finalArr = finalArr.filter(service => !this.treatmentIds.includes(service.appointmentTypeId));
        this.filteredArr = [...finalArr];
        this.filteredArr = this.filteredArr.filter(service => service.appointmentTypeName.toLowerCase().includes(this.searchValue.toLowerCase()));
        // tslint:disable-next-line:no-non-null-assertion
        // tslint:disable-next-line:no-unused-expression
        this.filteredArr.length > 0 ? this.overlay.show(this.opTarget) : op.hide();
      } else if (this.searchValue === '') {
        op.hide();
        this.filteredArr = [];
        // console.log(this.overlay);
      }
    }
  }

  // serviceText Click event
  serviceClick(event: any, ser: any) {
    this.overlay.hide();
    this.prePopulate.emit({service: ser, click: true});
  }

  // outside click
  onClickedOutside(event, overlay) {
    if (overlay.render) { overlay.hide(); }
  }
}
