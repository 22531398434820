<div class="appointment__list-page">
    <div class="background-image">
        <img src="assets/images/pngs/newpatientform-background.png" alt="bg image">
    </div>
    <!-- Main Appointment List Page Wrapper -->
    <div class="appointment__list-main-wrapper padding-lr">
        <!-- Appointments List -->
        <div class="appointment__list-navigation-menu" [ngClass]="adminPermissions.EMPLOYEE_CREATE ? '':'padding-btm'">
            <app-breadcrumb [breadCrumbData]="breadCrumbData"></app-breadcrumb>
            <!-- Primary action on Page -->
            <div class="appointment__list-primary-actions" *ngIf="adminPermissions.EMPLOYEE_CREATE">
                <div class="common-btn-style" (click)="createNew()">
                    Create New
                </div>
            </div>
        </div>
        <ng-container *ngIf="isShowScreen">
            <!-- Upcoming appointments starts here -->
            <ng-container *ngIf="employeeListPerPageCount.length !== 0">
            <ul class="appointment__list-block">
                <ng-container *ngFor="let appointment of employeeListPerPageCount; let i=index">
                    <app-employee-data-list [adminPermissions]="adminPermissions" [employeeData]="appointment" (activateCall)='displayPopup($event)'></app-employee-data-list>
                </ng-container>
            </ul>
            <!-- Upcoming appointments ends here -->
             <!-- Empty list Starts here -->
             <!-- Empty list ends here -->
             <!-- Pagination component starts here -->
             <app-pagination [paginationRawData]="paginationData" *ngIf="employeeListCount>10" (pageChanged)="pageChangedEvent($event)"
             ></app-pagination>
             <!-- Pagination component ends here -->
            </ng-container>
            <app-empty-list-data [emptyMessageData]="employeeListEmptyData" *ngIf="employeeListPerPageCount.length === 0"></app-empty-list-data>
        </ng-container>
    </div>
</div>
<app-common-popup (showHideCancelPopup)='cancelPopupShowHideStatusFun($event)' *ngIf="cancelPopupShowHide">
    <div class="popup-content-block">
        <div class="confirm-message">Are you want to activate this employee?</div>
        <div class="btn-actions">
            <div class="common-btn-style yes-btn" (click)="popUpClick($event,false)">No</div>
            <div class="common-btn-style"
                (click)="popUpClick($event,true)">Yes</div>
        </div>
    </div>
</app-common-popup>


