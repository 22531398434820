import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { API_URLS } from '@app/common/constants/api-urls.constant';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';
import { IGetMethodPayload, IGetPermissionsPayloadI, IPostMethodPayload, IPutMethodPayload } from '@app/common/constants/typeInterfaces.constants';
import { DEFAULT_PAGINATION_LIMIT, HTTPMethods, TOAST_STATUSES } from '@app/common/constants/util.constant';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';

@Component({
  selector: 'app-user-list-wrapper',
  templateUrl: './user-list-wrapper.component.html',
  styleUrls: ['./user-list-wrapper.component.scss']
})
export class UserListWrapperComponent implements OnInit {
  userListCount: number = 0;
  isShowScreen: boolean = false;
  searchPlaceHolderText = 'Search by User name and email';
  pageNumber: any = 1;
  pageLimit: any = DEFAULT_PAGINATION_LIMIT;
  searchInputText: string = ''; // Search input text
  // Bread crumb status
  breadCrumbData = {
    link: '/admin/patients',
    linkText: 'User List'
  };

  action: string;
  userId: any;
  popupMessage: string;
  popupShowHide: boolean = false;

  // Initial payload data
  INITIAL_PAYLOAD = {
    pagination: {
      page: this.pageNumber,
      limit: this.pageLimit
    },
    search: this.searchInputText,
  };
  userList: any;
  paginationData: { limit: any; currentPage: any; totalPages: number; data: any; };

  // Empty List data
  emptyAppointmentsListMessages = {
    className: 'appointmentsList',
    icon: 'patient',
    message: 'There are no user accounts'
  };
  adminPermissions = {
    USER_EDIT: true,
  };
  permissionsDone: boolean = false;
  constructor(private http: HttpService, private observable: ObservableHelperService, private router: Router, private activeRoute: ActivatedRoute) {
    this.activeRoute.queryParams.subscribe(params => {
      if (params.search) {
        this.searchInputText = params.search;
        this.INITIAL_PAYLOAD.search = params.search;
      }
    });
   }

  ngOnInit() {
    this.getUserList(this.INITIAL_PAYLOAD);
  }

/**
 * This will trigger when list level actions are executed
 * @param event :child component emit event
 */
  userActionEmit(event) {
    if (event && event.action === 'reset') {
      this.popupShowHide = true;
      this.popupMessage = 'Do you want to send reset password email for this user?';
      this.action = 'reset';
      this.userId = event.userData.userId;
    } else if (event && event.action === 'delete'){
      this.popupShowHide = true;
      this.popupMessage = 'On deleting user, the patients, appointments and document requests of this account will get deleted. Do you want to continue?';
      this.action = 'delete';
      this.userId = event.userData.userId;
    }
  }

    // Get User list API call
    getUserList(bodyData) {
      const payloadData: IPostMethodPayload = {
        type: HTTPMethods.POST,
        url: API_URLS.USERS_LIST,
        isDeveloper: true,
        body: bodyData,
      };
      this.http.makeHttpRequest(payloadData).subscribe((res) => {
        if (this.http.isSuccessfulResponse(res)) {
          this.userList = this.massageUserlist(res.data.list);
          this.paginationData = this.massagePaginationData(res.data);
          this.userListCount = res.data.pagination.totalItems;
          this.isShowScreen = true;
          this.getAdminPermissions();
        } else {
          // Error
          let message = 'Failed to fetch users list. Please try again';
          message = (res.error) ? res.error.message : message;
          if (message === 'Insufficient Privilege') {
            this.observable.showToast(TOAST_STATUSES.ERROR, 'Sorry! You do not have access to this page.');
            this.router.navigate(['/admin']);
          } else { this.observable.showToast(TOAST_STATUSES.ERROR, message); }
        }
      });
    }

  // This will do massaging for pagination Data
  massagePaginationData(data) {
    if (!data && typeof (data) !== 'object') {
      // Error
    }
    return {
      limit: data.pagination.limit,
      currentPage: data.pagination.page,
      totalPages: Math.ceil(data.pagination.totalItems / data.pagination.limit),
      data: data.list
    };
  }

  /**
   * This is for pagination, When we click on next/pagination-number it will trigger
   * @param data : Pagination data
   */
  pageChangedEvent(data) {
    if (data) {
      this.pageNumber = data.currentPage;
      this.pageLimit = data.limit;
      let pageClickedData = {
        pagination: {
          page: data.currentPage,
          limit: data.limit,
        },
        search: this.searchInputText,
      };
      this.getUserList(pageClickedData);
    }
  }

  massageUserlist(userList) {
    if (!userList && !Array.isArray(userList)) { return []; }
    return userList.map(user => {
      return {
        ...user,
        userName: (!user.firstName && !user.lastName) ? '-' :
          (user.firstName) + ' ' + (user.lastName),
        status: user.removedFlag ? 'Deleted' : 'Active'
      };
    });
  }

    // Search filter
  searchFilter(e) {
    if (e.replace(/\s/g, '').length > 0 || e === '') {
      this.searchInputText = e;
      this.INITIAL_PAYLOAD.search = this.searchInputText;
      this.getUserList(this.INITIAL_PAYLOAD);
      if (this.searchInputText === '') {
        this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.USER_LIST}`]);
      } else {
        this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.USER_LIST}`], { queryParams: { search: this.searchInputText } });
      }
    }
  }


  cancelPopupShowHideStatusFun(status, isShow?) {
    this.popupShowHide = status.cancelpopup;
    if (this.action && this.action === 'delete' && isShow) {
      this.deleteUser();
    } else if (this.action && this.action === 'reset' && isShow) {
      this.resetPasswordAPI();
    }
  }


    /**
   * This is for to sent reset verification link to the consumer
   */
     resetPasswordAPI() {
      const payloadData: IGetMethodPayload = {
        type: HTTPMethods.GET,
        url: API_URLS.RESET_USER_PASSWORD,
        pathVariables: [this.userId],
        isDeveloper: true,
      };
      this.http.makeHttpRequest(payloadData).subscribe((res) => {
        if (this.http.isSuccessfulResponse(res)) {
          this.observable.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.RESET_USER_PASSWORD_SUCCESS_MSG);
          this.getUserList(this.INITIAL_PAYLOAD);
        } else {
          let message = TOAST_MESSAGES.RESET_USER_PASSWORD_ERROR_MSG;
          message = (res.error) ? res.error.message : message;
          this.observable.showToast(TOAST_STATUSES.ERROR, message);
        }
      });
    }
     /**
      * This is for deleting the user account
      */
     deleteUser() {
      const payloadData: IPutMethodPayload = {
        type: HTTPMethods.PUT,
        url: API_URLS.DELETE_USER,
        pathVariables: [this.userId],
        isDeveloper: true,
        body: {}
      };
      this.http.makeHttpRequest(payloadData).subscribe((res) => {
        if (this.http.isSuccessfulResponse(res)) {
          this.observable.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.DELETE_USER_SUCCESS_MSG);
          this.getUserList(this.INITIAL_PAYLOAD);
        } else {
          let message = TOAST_MESSAGES.DELETE_USER_ERROR_MSG;
          message = (res.error) ? res.error.message : message;
          this.observable.showToast(TOAST_STATUSES.ERROR, message);
        }
      });
    }

      // getting permissions based on user id
      getAdminPermissions() {
        const payloadData: IGetPermissionsPayloadI = {
          type: HTTPMethods.GET,
          url: API_URLS.GET_PERMISSIONS,
          isDeveloper: true
        };
        this.http.makeHttpRequest(payloadData).subscribe((res) => {
          if (this.http.isSuccessfulResponse(res)) {
            let permissions = ['USER_EDIT'];
            let permArr = [];
            res.data.permissions.forEach(obj => {
              permArr.push(obj.permissionName);
            });
            if (!permArr.includes('USER_LIST')) {
              this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.ACCESS_PERMISSOIN_DENIED_MSG);
              this.router.navigate([`${ROUTE_PATHS.ADMIN}`]);
            }
            permissions.forEach(perm => {
              if (!permArr.includes(perm)) {
                this.adminPermissions[perm] = false;
              }
            });
            console.log(this.adminPermissions);
          } else {
            // Error
            let message = (res.error) ? res.error.message : TOAST_MESSAGES.ACCESS_PERMISSION_ERROR_MSG;
            console.log(message);
          }
          this.permissionsDone = true;
        });
      }
}
