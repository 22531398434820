<section class="hippa__notice__form">
    <div class="container">
        <div>        
            <app-custom-form 
            (submitEvent)="getFormData($event)" [formData]="autoForm" (sliderValue)="getdata($event)" *ngIf="autoFill"></app-custom-form>
        </div>
        <div class="common-header hippa__header">
            {{hippaData.header}}
        </div>
        <div class="hippa__description">
            {{hippaData.description1}}
        </div>
        <div>
            <ul class="">
                <li *ngFor="let list of hippaData.list">
                    {{list}}
                </li>
            </ul>
        </div>
        <div class="hippa__description">
            {{hippaData.description2}}
        </div>
        <div class="hippa__description">
            {{hippaData.description3}}
        </div>
    </div>
    <div class="form__fields__block">
        <app-custom-form [checkFormInfo]="saveFormData"
        (submitEvent)="getFormData($event)" [formData]="hippaForm"></app-custom-form>
    </div>
</section>