export const API_URLS = {
    SEARCH_MAIL_ADDRESS: 'searchEmail',
    GET_EMAIL_TEMPLATE_LIST: 'emailTemplateList',
    EXCLUDE_PATIENTS: 'excludePatients',
    EDITUSER: 'editUser',
    PATIENT_DETAILS: 'patientsDetails',
    CHECK_LAST_VISIT: 'adminLastValid',
    LAST_APPOINTMENT_DATA: 'lastAppointmentData',
    ADMIN_CREATE_PATIENT: 'adminPatientCreate',
    ADMIN_EDIT_PATIENT: 'adminEdiPatient',
    PATIENT_EDIT: 'ediPatient',
    PATIENT_CREATE: 'createPatient',
    APPOINTMENT: 'appointment',
    ADMIN_APPOINTMENT_CONFIRM: 'adminAppointmentConfim',
    EMAIL_TEMPLATE_DETAILS: 'emailTemplateDetails',
    GET_PERMISSIONS: 'getPermissions',
    REQUEST_DOCUMENT_DETAILS: 'adminRequestDetails',
    PATIENT_REQUEST_DOCUMENTS: 'patientRequestDetails',
    INVOICE_FILTERS: 'invoiceFilter',
    ADD_PATIENT_REQUEST:'addPatientRequest',
    UPLOADS_FILES: 'uploadsFiles',
    SEND_REMINDER: 'sendReminder',
    EMPLOYE_LIST: 'employeList',
    PATIENT_CONFORM:'patienConform',
    PATIENT_CONFORM_COMMENTS:'patienConformComments',
    PATIENT_SEND_CHANGES: 'patientSendChanges',
    INVOICE_MARK_AS_PAID:'invoiceMarkasPaid',
    UPLAOD_FILE_DELETE:'uploadFileDelete',
    INVOICE_RESEND:'invoiceResend',
    CHECKOUT_SESSION:'checkoutSession',
    UPLOAD_DOCS:'uploadDocs',
    GENERATE_INVOICE_REQUEST:'generateInvoiceRequest',
    REJECT_DOC: 'rejectDoc',
    CONFORM_DOC: 'conformDoc',
    CONFORM_DOC_COMMENTS: 'conformDocComments',
    ADHD_QUESTIONNAIRE: 'adhdQuestionnaire',
    PHQ_QUESTIONNAIRE: 'phqQuestionnaire',
    ADMIN_KETAMINE_MONITORING: 'adminKetamineMonitoring',
    GET_APPOINTMENT_DETAILS: 'appointmentDetails',
    GET_PATIENT_LIST: 'getPatient',
    REQDOC_LIS: 'reqDocList',
    REQDOCS_LIST: 'requestDOcs',
    REQDOCS_PATIENT_LIST: 'requestDocsPatient',
    DOC_FILTERS: 'docFilters',
    THERAPIST_INFO: 'therapistInfoRequest',
    SEARCH_PATIENT: 'adminPatientSearch',
    PATIENT_MAILS: 'insuranceEmail',
    ADD_ADMIN_REQUEST:'addAdminRequest',
    GET_UPLOAD_URL:'getUploadUrl',
    ADMIN_REQ_DOC:'adminRequestDocuments',
    UNSUBSCRIBE: 'unsubscribe',
    MEDICATION_FEEDBACK: 'medicationFeedback',
    USER_FORGOT_PASSWORD: 'userForgotPassword',
    LAB_DOC_DETAILS: 'labDocsDetails',
    UPDATE_LAB_DOC: 'updateLabDoc',
    USERS_LIST: 'userList',
    USER_DETAILS: 'userDetails',
    PATIENT_LIST: 'patientList',
    Pateint_Doca_list: 'patient-documents-list',
    Pateint_Request_Docs: 'adminRequestDocuments',
    DELETE_USER: 'deleteUser',
    RESET_USER_PASSWORD: 'resetUserPassword',
    INFORMATION_REQUEST: 'informationRequest',
    INFORMATION_REQUEST_DYNAMIC_FORM: 'informationRequestDynamicForm',
    DELETE_EACH_PATIENT: 'deleteEachPatient',
    COGNITO_GET_USER: 'cognitoGetUser',
    COGNITO_UPDATE_ATTRIBUTES: 'cognitoUpdateAttributes',
    COGNITO_GLOBAL_SIGNOUT: 'cognitoGlobalSignOut',
    COGNITO_CREATE_USER: 'cognitoAdminCreateUser',
    UPLOAD_FILES: 'uploadFile',
    EXPORT_FILE: 'exportFile',
    REPLACE_FILE: 'replaceFile',
    MEDICATION_EXPORT: 'medicationExport',
    EDIT_POC: 'editPoc',
    POST_GENARTE_DOC:'postGenerateDoc',
    MEDICAL_SUMMARY: 'medicalSummary',
    //mednotes
    FOLLOWUP_MEDNOTES: 'followupMednotes',
    GET_PATIENT_MEDNOTES: 'getPatientMedNotes',
    ADDMEDNOTES: 'addMedNotes',
    EDITMEDNOTES: 'editMedNotes',
    ADD_TMSNOTE: 'addtmsnotes',
    EDIT_TMSNOTE: 'editTmsNotes',
    ALL_TMS_NOTES: 'getalltmsnotes',
    TMS_NOTE_DETAILS: 'gettmsnotes',
    DELETE_TMS_NOTE: 'deleteTmsNote',
    DELETE_MEDNOTES: 'deleteMednote',
    GET_SIGNED_URL: 'getSignedUrl',
    Excel_To_DataFormat: 'excelToDataFormat'
    
};