<!--stepper section UI start-->
<section class="stepper__section horizontal-{{isHorizontal}}">

    <div class="stepper-container">
        <div class="stepdetail__mobile" *ngIf="isMobile">
            <div class="action">
                <div class="count">
                    <div class="current">{{stepIndicatorMobileIndex + 1}}</div>
                    <div class="total">of {{stepDetails.length}}</div>
                </div>
                <div class="btn-action" *ngIf="userLogedIn">
                    <div  [ngClass]="stepIndicatorMobileIndex + 1 == 1 ?'back': 'back--enable'" (click)="back()">
                        <app-svg [name]="'left-arrow'"></app-svg>
                    </div>
                    <div  [ngClass]="stepIndicatorMobileIndex + 1 == stepDetails.length ?'forward--disabled': 'forward'" (click)="forward()">
                        <app-svg [name]="'right-arrow'"></app-svg>
                    </div>
                </div>
            </div>
            <div class="action-line">
                <div class="filled" [ngStyle]="{'width' : getProgressWidth()}"></div>
            </div>
        </div>
        <div *ngFor="let step of stepDetails;let i = index" class="cell-container {{setStepState(i)}}"
            [ngClass]="setStepState(i) == 'not_visited' ? 'line-not__visted' : 'line-visted'">
            <div [ngClass]="stepDetails.length==7?'adjust-padding':''" class="step-{{i}} cells" (click)="stepClicked(i)" *ngIf="(stepIndicatorMobileIndex == i) || !isMobile">
                <div class="step-indicator">
                    <div class="image-container">
                        <app-svg class="{{step.img}}-icon" [name]="step.img"></app-svg>
                    </div>
                    <!--image-->
                </div>
                <div class="step-line left" *ngIf="i != 0"></div>
                <div class="step-line right" *ngIf="i != (stepDetails.length - 1)"></div>
            </div>
            <div class="cell-label" *ngIf="(stepIndicatorMobileIndex == i) || !isMobile">{{step.label}}</div>
        </div>
    </div>


</section>
<!--stepper section UI start-->