import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';

@Component({
  selector: 'app-treatment-card',
  templateUrl: './treatment-card.component.html',
  styleUrls: ['./treatment-card.component.scss']
})
export class TreatmentCardComponent implements OnInit {
  moreText = {
    moreTextLink: 'Know more'
  };

  trearmentCardDetails = [
    {
      id:0,
      cardColor: 'purple',
      className: 'kt',
      img: 'at',
      heading: 'ADHD Treatment',
      imageLabel: 'Ketamine-Focused Depression Treatment',
      description: 'Our holistic approach to ADHD Treatment efficiently tackles symptoms of hyperactivity, inattentiveness, and impulsivity'
    },
    {
      id:1,
      cardColor: 'pink',
      className: 'anti-aging',
      img: 'tt',
      heading: 'Testosterone Treatment',
      imageLabel: 'Anti Aging Treatment',
      description: 'Restore your youth and vitality with our proprietary hormonal therapies'
    },
    {
      id:2,
      cardColor: 'blue',
      className: 'telemedicine',
      img: 'medica',
      heading: 'Medical Marijuana',
      imageLabel: 'Telemedicine Treatment',
      description: 'Embrace new-age therapies through our simple Patient Medical Marijuana certification process'
    },
    {
      id:3,
      cardColor: 'yellow',
      className: 'tms-yellow',
      img: 'kt2',
      heading: 'Ketamine Treatment',
      imageLabel: 
      'Ketamine Treatment',
      description: 'Alleviate the burden of PTSD, Depression, Anxiety, and more with our novel and revolutionary ketamine treatment solution'
    },
    // {
    //   cardColor: 'green',
    //   className: 'medical',
    //   img: 'medical',
    //   heading: 'Medicinal Marijuana',
    //   imageLabel: 'Medical Marijuana DC',
    //   description: 'We are licenced to provide medical marijuana as an effective treatment for Chronic pain management, Patients with Cancer, PTSD, AIDs, CHRON’s disease, GIAVCOMA,...'
    // },
    // {
    //   cardColor: 'green',
    //   className: 'tms-yellow',
    //   img: 'medical',
    //   heading: 'Medicinal Marijuana',
    //   description: 'We are licenced to provide medical marijuana as an effective treatment for Chronic pain management, Patients with Cancer, PTSD, AIDs, CHRON’s disease, GIAVCOMA,...'
    // },
  ];
  treatmentCardMoreInfo=[
    {
      id:0,
      img:"assets/images/pngs/adhd.png",
      heading:"ADHD TREATMENT",
      color:"#DCB001",
      description:"ADHD Is a neurodevelopmental disorder that affects people across all age groups. Symptoms of ADHD can be bucketed into 3 categories: inattention, hyperactivity, and impulsivity. These symptoms cause complications in focus, prioritization, thoughtfulness, and timeliness. Resultantly, many individuals with ADHD may find it difficult to maintain productivity at work, school, etc. However, individuals can seek treatment to alleviate the burden of these symptoms. ",
    },
    {
      id:1,
      img:"assets/images/pngs/testosterone.png",
      heading:"TESTOSTERONE TREATMENT",
      color:"#EE7A49",
      description:"",
    },
    {
      id:2,
      img:"assets/images/pngs/marijuana.png",
      heading:"MEDICAL MARIJUANA TREATMENT",
      color:"#D56784",
      description:"",
    },
    {
      id:3,
      img:"assets/images/pngs/ketamine.png",
      heading:"KETAMINE THERAPY",
      color:"#D56784",
      description:"",
    },
  ]
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    // infinited: true,
    // autoplay: true,
    // autoplaySpeed: 1500
  };

  constructor(private route: ActivatedRoute, private router: Router) {  }

 ngOnInit() {

 }
 navigateTreatmentPage(index){
  // debugger;
  console.log(this.treatmentCardMoreInfo[index])
  this.router.navigate([`${ROUTE_PATHS.EACH_TREATEMENT}`],{ queryParams: { heading: this.treatmentCardMoreInfo[index].heading,image:this.treatmentCardMoreInfo[index].img,color:this.treatmentCardMoreInfo[index].color,id:this.treatmentCardMoreInfo[index].id} });

 }

navigateBlogPage(){
  this.router.navigate([`${ROUTE_PATHS.TREATMENTS}`])
 }


}
