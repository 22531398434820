<section *ngIf='showScreen'>
    <div class="lab_document_wrapper">
        <div class="navigation-menu">
            <app-breadcrumb [breadCrumbData]="breadCrumbData"></app-breadcrumb>
        </div>
        <div class="lab_document_section">
            <!-- Content edit starts here -->
            <div class="editable_content-label" *ngIf="!isViewDoc">Click below to edit content</div>
            <div class="content__preview" data-gramm="false" data-gramm_editor="false" data-enable-grammarly="false" id="content-edit" [innerHTML]="templateHtlm"></div>
            <!-- Content edit ends here -->
        </div>
        <div class="btn-actions" >
            <div class="cancel-btn" (click)="navigateBack()">Cancel</div>
            <div class="common-btn-style" (click)="saveForm()" *ngIf="!isViewDoc">Save</div>
        </div>
    </div>
</section>