import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aboutus-approach',
  templateUrl: './aboutus-approach.component.html',
  styleUrls: ['./aboutus-approach.component.scss']
})
export class AboutusApproachComponent implements OnInit {

  headingData = {
    subHeading: 'The goals of a',
    mainHeading: 'multifaceted approach are:',
  };

  aboutUsCards = [
    {
      ketamineClass: 'ketamine__card-block',
      ketamineHeading: 'Ketamine Treatment',
      ketamineDescription: 'A new groundbreaking therapy, found to be a rapid-acting antidepressant in depression & helps in decreasing suicidal ideation.',
      ketamineIcon: 'ketamine',
      imgPath: 'ketamine-treatment.jpg',
      imgLabel: 'treatment'
    },
    {
      ketamineClass: 'tms__card-block',
      ketamineHeading: 'TMS',
      ketamineDescription: 'A noninvasive procedure that uses magnetic fields to stimulate nerve cells in the brain to improve symptoms of depression.',
      ketamineIcon: 'tms',
      imgPath: 'tms-treatment.jpg',
      imgLabel: 'treatment'
    },
    {
      ketamineClass: 'medcinal__card-block',
      ketamineHeading: 'Medicinal Marijuana',
      ketamineDescription: 'Medical marijuana uses the marijuana plant or chemicals in the treatment of mental health conditions such as schizophrenia and posttraumatic stress disorder (PTSD).',
      ketamineIcon: 'medical',
      imgPath: 'medicinal-treatment.jpg',
      imgLabel: 'treatment'
    }
  ];

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    infinited: true,
    autoplay: false,
    autoplaySpeed: 3000,
    pauseOnHover: true,
  };

  constructor() { }

  ngOnInit() {
  }

}
