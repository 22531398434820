import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-privacy-content',
  templateUrl: './privacy-content.component.html',
  styleUrls: ['./privacy-content.component.scss']
})
export class PrivacyContentComponent implements OnInit {

  @Input() iconPath = {
    iconMinus: 'minus-circle.svg',
    iconPlus: 'plus-circle.svg',
  };

  @Input() cardDetails = [
    {
      expanded: false,
      title: '1. Information We Collect',
      listHeading: [
        `We may collect and use the following types of information from those who use the Site and the Services:`,
      ],
      listArray: [
        `Information by which you may be personally identified, such as your name, address, email address, phone number, and other information that may not be publicly available ("<b>personal information</b>").`,
        `Information about you that does not identify you personally, including, but not limited to, your personal interests, online interactions, viewing data, requests for Services, communications with us and third parties, advertisement interactions, and any other activities when using the Site or the Services.`,
        `Your first name, last name and email are collected to create an individual profile for you. This information is also used to cross-reference your profile with the insurance information you provide on your “new patient forms” and may be used for insurance verification and billing purposes.`,
        `Information you provide when you register with the Site and the Services, when adding or updating account preferences, and when subscribing for any Services.`,
        `Information about your transactions using the Site and the Services, including, but not limited to, your purchases and order history.`,
        `Information you provide when submitting information to be posted or otherwise displayed on public areas of the Site or transmitted to other visitors or third parties on the internet (collectively, "<b>User Contributions</b>"). All User Contributions are submitted at your own risk. Although we always seek to protect your information, we cannot guarantee that our security over User Contributions is impenetrable. We also cannot control how other visitors and third parties that gain access to User Contributions will use such information.`,
      ],
      descriptionHeading: [

      ],
      description: [
        `We may combine information you provide us with other information about you that we obtain from your past use of the Site and Services, from our business partners, and from other companies. We may access other information about you collected from third parties, such as social media and marketing companies. We will treat any non-personal information that is combined with personal information as if it were all personal information.`
      ],
    },
    {
      expanded: false,
      title: '1.1 Automatic Data Collection',
      listHeading: [
        `We collect the following types of information from you as you use the Site through automatic data collection technologies:`,
      ],
      listArray: [
        `Log file information commonly collected by host servers when you visit websites, including internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamps, referring/exit pages, domain names, landing pages, pages viewed, click counts, and similar information. This information is not connected to personal information and is used for purposes of administering the Site, analyzing trends, tracking visitor activity, and collecting demographic data.`,
        `Other details regarding your activities on the Site and Services, including your equipment, operating system, software, traffic information, and location data.`,
      ],
      descriptionHeading: [
        `Our automatic data collection includes the use of the following technologies:`
      ],
      description: [
        `<b>Cookies.</b> A "cookie" is a small piece of data that is stored on a visitor’s hard drive in order to store information about visitor preferences and activities on the Site. We use this information to optimize the experiences of our visitors. For instance, a cookie can help us identify you when you return to the Site in order to save you time. In the event that our business partners, affiliates, or advertisers on the Site use cookies, we will not have access or control over such cookies. However, any use of cookies will not relate to any personally identifiable information about you. You may also choose to refuse to accept cookies by configuring the appropriate setting on your browser. However, doing so may prevent you from being able to access parts of the Site or Services and may prevent the Site from functioning properly.`,
        `<b>Google Analytics.</b> We may use Google Analytics or similar services to collect non-personal information from you regarding your use of the Site. Google Analytics is a web analytics service offered by Google, Inc. that mainly uses cookies to report on your interactions on the Site and analyzes how you use the Site. For more information on Google Analytics, including how to set up privacy controls on how Google Analytics collects your information, please see the help article, <a href="https://marketingplatform.google.com/about/" target="_blank">Safeguarding your data</a>.`,
      ],
    },
    {
      expanded: false,
      title: '1.2 How We Respond to Do Not Track Signals',
      listHeading: [

      ],
      listArray: [

      ],
      descriptionHeading: [

      ],
      description: [
        `We do not track visitors’ activities over time or across third-party websites and therefore do not respond to Do Not Track (DNT) signals. However, many third-party websites track your browsing activities in order to tailor advertisements and other content to your interests. If you do not wish to be tracked in this manner, then you should configure your browser settings appropriately.`,
      ],
    },
    {
      expanded: false,
      title: '2. How We Use Your Information',
      listHeading: [
        `We are the sole owners of information collected from visitors on this Site. We will not sell or rent your information to third parties. We use information collected from you or about you, including personal information, for the following purposes:`
      ],
      listArray: [
       `To administer the Site and enforce our rules and policies.`,
       `To provide access to Site content and Services to you.`,
       `To customize Site content and Services according to your preferences.`,
       `To improve the Site and Services.`,
       `To research and analyze the usage data, preferences, and trends of our visitors.`,
       `To diagnose technical or Service-related problems.`,
       `To maintain security over your information, the Site, and its contents.`,
       `To fulfill any requests for information, products, or Services.`,
       `To fulfill any requests for information, products, or Services.`,
       `To facilitate transactions you make on the Site or Services, which may include sending payment statements or receipts, or assisting with payment collection.`,
       `To display your User Contributions.`,
       `To contact you regarding Services we provide you.`,
       `To contact you regarding your account or profile.`,
       `To contact you regarding changes to this privacy policy.`,
       `To carry out our responsibilities and enforce any contracts between you and us.`,
       `For any other use as we may indicate at the time you provide the information.`,
       `To fulfill any other purpose with your consent.`,
      ],
      descriptionHeading: [

      ],
      description: [
        `All information collected from visitors of the Site belongs to us, whether or not such information is personal information, information you volunteer, or information passively collected about you. As such, such information may be subject to transfer to a new owner in the event of our bankruptcy, sale, or liquidation.`,
      ],
    },
    {
      expanded: false,
      title: '3. How We Share Your Information',
      listHeading: [
        `We may share personal information and other information that we collect as follows:`
      ],
      listArray: [
       `With our affiliates, including any subsidiaries we now own or later acquire.`,
       `With our trusted employees, contractors, business partners, service providers, suppliers, vendors, and other third parties that support us, which may include sending you emails on our behalf; analyzing your activities and interactions on the Site in order to make improvements to the Site, Services, and experiences of our visitors; and providing support to the Site, the Services, our operations, or fulfilling your requests. These third parties may only use personal information in order to provide such support and are contractually required to maintain the confidentiality of your information. For example, we may share personal information with credit card processing companies in order to bill visitors for goods or services.`,
       `With a buyer, successor, or other third party due to a merger, restructuring, reorganization, or other transfer or disposition of Newteledoc Inc. or our operations, including, but not limited to, in the event of a sale, bankruptcy, dissolution, or similar proceeding. In such event, we will provide you with prior notification if your information will become subject to a different privacy policy.`,
       `In response to court orders, subpoenas, or legal processes, such as requests from government regulatory or law enforcement agencies; to defend, pursue, or participate in legal actions or proceedings or exercise a legal right; to take precautions against actual or potential liabilities; to investigate or respond to potential or suspected illegal activities, fraud, or threats to the Site, its visitors, or the property or physical safety of any person; to enforce or investigate violations of our rules or terms of use; or as otherwise permitted or required by law.`,
       `With those that we so indicate at the time you provide the information.`,
       `To third parties when we have your consent or when we have parental consent if the visitor is a minor.`,
      ],
      descriptionHeading: [

      ],
      description: [
        `We may store personal information in locations outside of our direct control; for instance, on servers or databases where the hosting provider is located. Additionally, we may share or sell aggregate information about our visitors that does not personally identify individuals with our partners, affiliates, advertisers, and other third parties without restriction. This information may include personal information that we have changed so that individuals are no longer personally identifiable. We may also share this information with third parties in order to analyze visitor data, activities, and usage of the Site or Services in order to make improvements to the Site, Services, and experiences of our visitors.`,
        `We display targeted advertisements using third-party ad servers, also known as ad networks. Targeted advertisements are ads that specifically target consumers that fall into certain demographics or that otherwise display characteristics that make them desirable for advertisers. Ad servers automatically communicate with your browser and receive your IP address. They may also use other technologies, including cookies and web beacons, to collect data and analyze the performance of their advertisements. This allows the ads we display to be more relevant and interesting to you. We do not provide personal information to advertisers when you view, click, or interact with a targeted ad. However, if you view, click, or interact with a targeted ad, the responsible advertisers and ad servers may assume that you have certain characteristics that match their intended audience. By viewing, clicking, or interacting with a targeted ad, you acknowledge and consent to ad servers recording such assumptions about you. Our privacy policy does not apply to the ad servers that display advertisements on our Site. Please consult their respective privacy policies for more information.`,
        `If you choose to participate in any promotions or similar events through the Site or Services, the rules or terms and conditions for such events may change the way we share your information with third parties. Please review the applicable rules or terms and conditions for all such events in which you participate.`,
      ],
    },
    {
      expanded: false,
      title: '4. Links to Other Websites',
      listHeading: [
      ],
      listArray: [

      ],
      descriptionHeading: [

      ],
      description: [
        `This Site may contain links to or from other websites. This privacy policy only applies to information collected on this Site, and we are not responsible for other website’s privacy practices. Please be aware of when you exit our Site using such links. We encourage you to review the privacy practices of all other websites you reach through links on our Site.`,
      ],
    },
    {
      expanded: false,
      title: '5. Third-Party Tracking on Our Site',
      listHeading: [
      ],
      listArray: [

      ],
      descriptionHeading: [

      ],
      description: [
        `We may place content, including advertisements, on our site from advertisers, ad servers, and other third parties. These third parties may use cookies, web beacons, or other tracking technologies associated with their content on the Site to collect information, but not personal information, about your activities over time and across different websites. They may send you targeted ads or other content based on this information. We have no control over how these third parties use your information, and you should contact the responsible party with any related questions or concerns. For more information about how to opt out of this tracking, see Opt-Out Procedures below.`,
      ],
    },
    {
      expanded: false,
      title: '6. Opt-Out Procedures',
      listHeading: [
        `As your privacy is important to us, we provide you with the following procedure(s) for opting out of future communications from us:`
      ],
      listArray: [
        `<b>Email.</b> Send an email to <a href="mailto:'officemanager@newstressclinic.com'">officemanager@newstressclinic.com</a> explaining the specific communications or privacy practices you want to opt out of. You may also opt out by clicking “unsubscribe,” or a similar button, at the bottom of any emails we may send you and then following the online instructions. However, please note that it may not be possible to opt out of certain emails (for example, confirmation emails related to services you have requested).`,
        `<b>Phone.</b> Call <a href="tel:'877-202-8202'">877-202-8202</a> to receive help from our customer support team to opt out.`,
      ],
      descriptionHeading: [

      ],
      description: [
        `<b>Targeted Advertising.</b> You may use the procedure(s) above to opt out of us using your information to provide you with targeted ads using third-party ad servers.`,
        `<b>Third-Party Tracking.</b> We cannot control the privacy practices of third parties that use cookies, web beacons, or other tracking technologies associated with their content on the Site to collect information about your activities over time and across different websites. These third parties are subject to their own privacy policies, not this one. However, these third parties may provide procedures for opting out. Please review their privacy policies for more information. Additionally, you may opt out of tracking from members of the Network Advertising Initiative (NAI) by visiting its <a href="https://optout.networkadvertising.org/?c=1" target="_blank">Consumer Opt-Out Page</a>.`,
        `<b>Cookies.</b> In order to opt out of cookies and other tracking technologies, you can configure or disable cookies in your browser settings. However, please note that doing so may cause parts of the Site to function improperly.`,
        `<b>Third-Party Accounts.</b> In the event that you have chosen to connect your account to another account you have on a third-party website, YOU ACKNOWLEDGE AND AGREE THAT PERSONAL INFORMATION MAY BE PROVIDED TO THE RELEVANT THIRD-PARTY WEBSITES HOSTING UNTIL SUCH TIME AS YOU DISCONNECT YOUR TWO ACCOUNTS. You acknowledge that such third-party websites are not subject to this privacy policy. Do not connect your accounts if you do not want personal information shared in this manner. You may disconnect your accounts by either 1) signing in, visiting your account page, and configuring the relevant settings, if available, or 2) emailing us at <a href="mailto:'officemanager@newstressclinic.com'">officemanager@newstressclinic.com</a> with your request.`,
      ],
    },
    {
      expanded: false,
      title: '7. How to Access and Change Your Information',
      listHeading: [
      ],
      listArray: [
      ],
      descriptionHeading: [

      ],
      description: [
        `You are responsible for keeping any personal information you provide on the Site current. Please email us at <a href="mailto:'officemanager@newstressclinic.com'">officemanager@newstressclinic.com</a> to request access to view, correct, or delete information you have provided to us. We will make reasonable efforts to comply with your requests, if possible. If you provided personal information in connection with a specific Service we provide, you may have to update or delete that information by returning to that Service page. Any requests to delete your information are subject to our internal reporting and retention policies as well as any legal obligations that we may have. You can also update or delete some or all of your personal information in your account by signing in, visiting your account page, and configuring the relevant settings. Note that deleting your User Contributions on the Site will not delete copies of your User Contributions that may exist in cached or archived pages or copies that may be stored by other visitors.`,
      ],
    },
    {
      expanded: false,
      title: '8. Notice of California Privacy Rights',
      listHeading: [
      ],
      listArray: [
      ],
      descriptionHeading: [

      ],
      description: [
        `Pursuant to California Civil Code Section 1798.83, California residents who use our Site may request certain information regarding any disclosure of personal information to third parties for their direct marketing purposes. To make this request, please email us at <a href="mailto:'officemanager@newstressclinic.com'">officemanager@newstressclinic.com</a> or use the contact information provided below at the end of this policy. Should you choose to email us, please include in the subject line or body of your email the phrase “California Customer Choice Privacy Notice” and specify the personal information you do not wish to be shared with third parties for their direct marketing purposes. Please allow up to thirty (30) days for a response.`,
      ],
    },
    {
      expanded: false,
      title: '9. Security',
      listHeading: [
      ],
      listArray: [
      ],
      descriptionHeading: [

      ],
      description: [
        `We take the security of your information seriously and have electronic, physical, and administrative safeguards in place that comply with federal regulations for your protection. These security measures seek to protect your information both online and offline from disclosure, alteration, or unauthorized use. However, please keep in mind that no transmission of data over the internet is guaranteed to be completely secure. Third parties may be able to access private communications illegally; for instance, through the use of hacking and viruses. As such, all information you transmit on the Site or Services is at your own risk. We are not responsible for instances where third parties circumvent our security measures without authorization, illegally or otherwise.`,
        `The information you submit to us is encrypted using Secure Sockets Layer (SSL) data encryption technology and is transmitted securely. You may verify this by locating “https” at the beginning of the address of the webpage or an icon of a closed lock in your browser. The computers and servers we use are also kept in a secure environment behind firewalls. We limit access to your information to those people that need to view it to perform necessary support tasks, including fulfilling your requests. We also require you to create a unique password to help secure your account. It is your responsibility to maintain the secrecy of your password and other login information. Please be aware of when you are submitting User Contributions in public spaces that may be viewed by others. It is your responsibility to avoid submitting information you wish to keep confidential. We will promptly notify you in the event that personal information becomes compromised according to our notification procedures outlined below or as otherwise required by applicable law.`,
      ],
    },
    {
      expanded: false,
      title: '10. Children\'s Privacy',
      listHeading: [
      ],
      listArray: [
      ],
      descriptionHeading: [

      ],
      description: [
        `If you are under the age of 13, you must ask your parent or legal guardian for permission to use this Site. This Site knowingly collects personal information from children under 13 years of age. We collect this information for the following reason(s): The first name, last name and email address are collected for the purposes of creating a profile for the patient and allow them to schedule and manage appointments. This information is also used for Insurance Verification and billing.`,
        `The information we collect and use from children under 13 years of age may include, but is not limited to, name, address, email address, phone number, gender, birth date, device identifiers, geolocation information, IP address, and any other information the child shares with us. We collect this information directly when your child submits it on the Site. We will not require your child to disclose more information than is reasonably necessary to use a Service or engage in an activity on the Site. We also may collect information from your child automatically using cookies, web beacons, and other tracking technology.`,
        `We encourage you to monitor your child’s use of the Site. Your child may have access to all our Services. However, we will not contact your child with promotions or marketing communications without a parent’s or legal guardian’s permission. Our collection, use, and sharing of information collected from visitors, including your child, is discussed throughout this privacy policy. If you do not agree to such collection, use, or sharing, please do not let your child use this Site. Additionally, to view, correct, or delete your child’s personal information, see “Opt-Out Procedures” above. You may need to sign in to your child’s account to make such changes. Please inform us if you want us to stop any further collection or use of your child’s information.`,
      ],
    },
    {
      expanded: false,
      title: '11. Changes to This Privacy Policy',
      listHeading: [
      ],
      listArray: [
      ],
      descriptionHeading: [

      ],
      description: [
        `We may update this privacy policy at any time. We will post any changes in our privacy practices on this page with the date of the most recent revision indicated next to “Last updated” near the top of the page. If we make significant changes to the way we manage our visitors’ personal information, we will notify you by email or by posting prominent notice on our Site. It is your responsibility to ensure we have your current email address and to periodically check this page for any updates.`,
      ],
    },
    {
      expanded: false,
      title: '12. Notification Procedures',
      listHeading: [
      ],
      listArray: [
      ],
      descriptionHeading: [

      ],
      description: [
        `We reserve the right to determine the most appropriate means of providing you with any notice required or advisable, in our sole discretion, under the terms of this privacy policy or as required by law. We may choose to provide notification by email, physical written notice, posting prominently on the Site, or through other conspicuous means.`,
      ],
    },
    {
      expanded: false,
      title: '13. Cross-Border Data Transfers',
      listHeading: [
      ],
      listArray: [
      ],
      descriptionHeading: [

      ],
      description: [
        `Your personal information may be processed and transferred to countries other than your own, including, but not limited to, any country in which we operate. Some of these countries may have different laws and practices regarding data protection than your country. By using the Site, you agree to such cross-border transfers of your personal information.`,
      ],
    },
    {
      expanded: false,
      title: '14. Contact Information',
      listHeading: [

      ],
      listArray: [
      ],
      descriptionHeading: [
        `Your feedback is important to us. To send us your questions, suggestions, or complaints, please contact us as follows:`
      ],
      description: [
        `Newteledoc Inc.<br>
        5194 Dawes Avenue<br>
        Alexandria, Virginia 22311<br>
        Telephone: <a href="tel:'877-202-8202'">877-202-8202</a><br>
        Email: <a href="mailto:'officemanager@newstressclinic.com'">officemanager@newstressclinic.com</a>`
      ],
    },
  ];
  constructor() { }

  ngOnInit() {
  }

  onClick(e) {
    e.expanded = !e.expanded;
  }

}
