<main class="preview">
    <div class="body-container">

        <ng-container>
            <section class="eval-header">
              <h1 class="header-title"> NEW STRESS CLINIC</h1>
      
              <div class="header-address">
                <div class="header-address-details">
                  <p>1325 18TH Street NW DC SUITE 210</p>
                  <p>WASHINGTON, D.C. 20036</p>
                </div>
                <div class="header-address-details">
                  <p>5194 DAWES AVE</p>
                  <p>ALEXANDRIA, VA 22311</p>
                </div>
              </div>
      
              <div class="header-contacts">
                <div>Phone: (703) 820-1900</div>
                <div>Fax: (866) 528-6229</div>
              </div>
      
            </section>
          </ng-container>

          <div class="Space"></div>
        <div class="Space"></div><div class="Space"></div>
        <div class="Space"></div>
        <div class="Space"></div>
        <div class="tms-heading underlined" style="padding-top:30px">TMS CONSULT FORM</div>

        <div class="field-item">
            <div class="tmsField-item-header">Date of Appointment : {{ApptDate}} </div>
            <!-- <div class="field-item-header-colon">:</div>
            <div class="field-item-text">{{doa}}</div> -->
        </div>

        <div class="section-header underlined">Patient's Basic Information</div>
        <section class="basicInfo">
            <div class="basicInfo-left">
                <div class="field-item">
                    <div class="tmsField-item-header">Consumer Name </div>
                    <div class="field-item-header-colon">:</div>
                    <div class="field-item-text">{{dataForPreview.PatientName}}</div>
                </div>
                <div class="field-item">
                    <div class="tmsField-item-header">Date of Birth </div>
                    <div class="field-item-header-colon">:</div>
                    <div class="field-item-text">{{dob}}</div>
                </div>
                <div class="field-item">
                    <div class="tmsField-item-header">SSN</div>
                    <div class="field-item-header-colon">:</div>
                    <div class="field-item-text">{{ssn}}</div>
                </div>
                <div class="field-item">
                    <div class="tmsField-item-header">Address</div>
                    <div class="field-item-header-colon">:</div>
                    <div class="field-item-text">{{dataForPreview.address}}</div>
                </div>
                <div class="field-item">
                    <div class="tmsField-item-header">Phone </div>
                    <div class="field-item-header-colon">:</div>
                    <div class="field-item-text">{{dataForPreview.phoneNumber}}</div>
                </div>
            </div>
            <div class="basicInfo-right">
                <div class="field-item">
                    <div class="tmsField-item-header">Type of Appointment </div>
                    <div class="field-item-header-colon">:</div>
                    <div class="field-item-text">{{dataForPreview.AppointmentType}}</div>
                </div>
                <div class="field-item">
                    <div class="tmsField-item-header">Diagnosis </div>
                    <div class="field-item-header-colon">:</div>
                    <div class="field-item-text">{{(dataForPreview.Diagnosis) ? dataForPreview.Diagnosis : '-'}}</div>
                </div>
                <div class="field-item">
                    <div class="tmsField-item-header">Primary Insurance </div>
                    <div class="field-item-header-colon">:</div>
                    <div class="field-item-text">{{(dataForPreview.primaryInsuranceName) ? dataForPreview.primaryInsuranceName : '-'}}</div>
                </div>
                <div class="field-item">
                    <div class="tmsField-item-header">Primary Insurance ID </div>
                    <div class="field-item-header-colon">:</div>
                    <div class="field-item-text">{{(dataForPreview.primaryInsuranceMemberId) ? dataForPreview.primaryInsuranceMemberId : '-'}}</div>
                </div>
                <div class="field-item">
                    <div class="tmsField-item-header">Allergies </div>
                    <div class="field-item-header-colon">:</div>
                    <div class="field-item-text">{{(dataForPreview.knownAllergies) ? dataForPreview.knownAllergies : '-'}}</div>
                </div>

            </div>
        </section>

        <div class="field-group">
            <div class="section-header underlined">Physician's Information</div>

            <div class="field-item">
                <div class="field-item-header"><b>Name of Physician/Consultant</b></div>
                <div class="field-item-header-colon">:</div>
                <div class="field-item-text">{{(dataForPreview.PhysicianName) ? dataForPreview.PhysicianName : '-'}}</div>
            </div>

            <div class="field-item">
                <div class="field-item-header"><b>NPI</b></div>
                <div class="field-item-header-colon">:</div>
                <div class="field-item-text">{{(dataForPreview.NPI) ? dataForPreview.NPI : '-'}}</div>
            </div>

            <div class="field-item">
                <div class="field-item-header"><b>Address</b></div>
                <div class="field-item-header-colon">:</div>
                <div class="field-item-text">{{(dataForPreview.PhysicianAddress) ? dataForPreview.PhysicianAddress : '-'}}</div>
            </div>

            <div class="field-item">
                <div class="field-item-header"><b>Phone</b></div>
                <div class="field-item-header-colon">:</div>
                <div class="field-item-text">{{(dataForPreview.PhysicianPhoneNumber) ? dataForPreview.PhysicianPhoneNumber : '-'}} </div>
            </div>

        </div>


        <div class="Space"></div>
        <div class="Space"></div>

        <div class="tms-heading underlined">{{tmsTherapyVisitHeading}}</div>

        <div class="section-header underlined">Purpose of Visit:</div>
        <p class="field-group">{{dataForPreview.purposeOfVisit}}</p>

        <div class="field-group">
            <div class="section-header underlined">Findings/Observations:</div>
            <div class="field-item">
                <div class="field-item-header">Weight </div>
                <div class="field-item-header-colon">:</div>
                <div class="field-item-text">{{dataForPreview.Observations.weight}}</div>
            </div>
            <div class="field-item">
                <div class="field-item-header">Height </div>
                <div class="field-item-header-colon">:</div>
                <div class="field-item-text">{{dataForPreview.Observations.height + '"'}}</div>
            </div>
            <div class="field-item">
                <div class="field-item-header">BP </div>
                <div class="field-item-header-colon">:</div>
                <div class="field-item-text">{{(dataForPreview.Observations.BP) ? dataForPreview.Observations.BP : '-'}}
                </div>
            </div>
            <div class="field-item">
                <div class="field-item-header">Pulse </div>
                <div class="field-item-header-colon">:</div>
                <div class="field-item-text">{{(dataForPreview.Observations.Pulse )? dataForPreview.Observations.Pulse :
                    '-'}}</div>
            </div>
            <div class="field-item" style="page-break-inside: avoid;">
                <div class="field-item-header">Additional Observations</div>
                <div class="field-item-header-colon">:</div>
                <div class="field-item-text">{{dataForPreview.addObservations}}</div>
            </div>
        </div>

        <div class="no-page-break">
            <div class="section-header margin-top underlined">TMS Therapy Procedure:</div>
            <ng-container>
                <ul class="medication_bullets" *ngFor="let med of dataForPreview.TMSTherapyProcedureObject">
                  <li>{{med + '.'}}</li>
                </ul>
              </ng-container>
        </div>


        <div class="field-group no-page-break">
            <div class="section-header underlined">Patient's Measurements:</div>
            <!-- <div class="field-item">
                <div class="field-item-header">Bridge of nose to Mask is </div>
                <div class="field-item-header-colon">:</div>
                <div class="field-item-text">{{noseToMask}} cm.</div>
            </div>
                <div class="field-item">
                <div class="field-item-header">Bridge of nose to Inion</div>
                <div class="field-item-header-colon">:</div>
                <div class="field-item-text">{{noseToInion}} cm. Mid: {{mid}} cm.</div>
                </div>
                <div class="field-item">
                    <div class="field-item-header">Ear to ear </div>
                    <div class="field-item-header-colon">:</div>
                    <div class="field-item-text">{{earToEar}} cm (Tragus to Tragus). CZ: {{cz}}.</div>
                </div>
                <div class="field-item">
                <div class="field-item-header">Mapping was successful at </div>
                <div class="field-item-header-colon">:</div>
                <div class="field-item-text">{{mapping}} % power.</div>
                </div>
                <div class="field-item">
                <div class="field-item-header">TMS Begun: Run at: </div>
                <div class="field-item-header-colon">:</div>
                <div class="field-item-text">{{tmsBegun}} power at 10hz.</div>
                </div> -->

            <ul class="medication_bullets" style="line-height:1.5">
                <li>Bridge of nose to Mask is: {{dataForPreview.patientMeasurements.bridgeToMask}}cm.</li>
                <li>Bridge of nose to Inion: {{dataForPreview.patientMeasurements.bridgeToInoin}}cm. Mid:
                    {{dataForPreview.patientMeasurements.mid}}cm.</li>
                <li>Ear to ear {{dataForPreview.patientMeasurements.earToEar}}cm (Tragus to Tragus). CZ: {{dataForPreview.patientMeasurements.cz}}.</li>
                <li>Mapping was successful at {{dataForPreview.patientMeasurements.mapping}}% power  @ {{dataForPreview.patientMeasurements.mappingHertz}}Hz.</li>
                <li>TMS Begun run at: {{dataForPreview.patientMeasurements.tmsBegun}}% power at {{dataForPreview.patientMeasurements.tmsBegunHz}}.</li>
            </ul>
        </div>
        <div class="no-page-break field-group">
            <div class="section-header underlined">RECOMMENDATIONS:</div>
            <ng-container>
                <ul class="medication_bullets" *ngFor="let med of dataForPreview.RecommendationsObject">
                  <li>{{med + '.'}}</li>
                </ul>
              </ng-container>
        </div>

        <div class="field-group no-page-break">
            <div class="field-item">
                <div class="field-item-header"><b>Duration of therapy</b></div>
                <div class="field-item-header-colon">:</div>
                <div class="field-item-text">{{dataForPreview.durationOfTherapy}} </div>
            </div>
            <!-- Signature and date -->
            <img class="sign" src="https://new-tele-doc-assets.s3.amazonaws.com/assets/signature.jpeg" alt="sign">
            <div class="signature">
            <p>Rama S. Prayaga, M.D.</p>
            <p>Date: {{ApptDate}}</p>
            </div>
            <!-- <div style="display:flex; flex-direction: row; justify-content:space-between; align-content: center;">
                <div>
                    {{dataForPreview.PhysicianName}}
                </div>
                <div>
                    Date : {{ApptDate}}
                </div>
            </div> -->
            <div class="Space"></div>
            <div class="Space"></div>

            <div style="display:flex; flex-direction: row; justify-content:space-between; align-content: center;">
                <div style="display:flex; flex-direction: row; align-content: center;">
                    Return Visit :
                    <div style="display: flex; column-gap: 5px; margin-left: 10px;">
                        <div>
                            <img *ngIf="(dataForPreview.returnVisit === 'Yes')" class="accept-img"
                                src="https://new-tele-doc-assets.s3.amazonaws.com/assets/checked1.png">
                            <img *ngIf="(dataForPreview.returnVisit !== 'Yes')" class="accept-img"
                                src="https://new-tele-doc-assets.s3.amazonaws.com/assets/unchecked1.png">
                        </div>
                        <div>Yes</div>
                    </div>
                    <div style="display: flex; column-gap: 5px; margin-left: 10px;">
                        <div>
                            <img *ngIf="(dataForPreview.returnVisit === 'No')" class="accept-img"
                                src="https://new-tele-doc-assets.s3.amazonaws.com/assets/checked1.png">
                            <img *ngIf="(dataForPreview.returnVisit !== 'No')" class="accept-img"
                                src="https://new-tele-doc-assets.s3.amazonaws.com/assets/unchecked1.png">
                        </div>
                        <div>No</div>
                    </div>
                </div>
                <div style="max-width: 30%">
                    When : {{dataForPreview.when}}
                </div>
            </div>

        </div>

        <div class="no-page-break field-group">
            <div class="tms-heading underlined">Left Dorso-Lateral Prefrontal Cortex (LDLPFC)</div>
            <!-- initial -->
            <div *ngIf="dataForPreview.AppointmentType === 'Initial TMS Note'" class="tms-img">
                <div><img src="https://new-tele-doc-assets.s3.amazonaws.com/assets/initialLdlpfc.png"></div>
                <!-- <div><img src="https://new-tele-doc-assets.s3.amazonaws.com/assets/intialBrain.jpg"></div>
                <div><img src="https://new-tele-doc-assets.s3.amazonaws.com/assets/initialScore.png"></div> -->
            </div>
            <!-- follow -->
            <div *ngIf="dataForPreview.AppointmentType === 'Follow Up TMS Note' || dataForPreview.AppointmentType === 'Follow Up TMS Note - Theta-Burst'" class="tms-img">
                <div><img src="https://new-tele-doc-assets.s3.amazonaws.com/assets/followUpLdlpfc.png"></div>
                <!-- <div><img src="https://new-tele-doc-assets.s3.amazonaws.com/assets/followUpBrain.jpg"></div>
                <div><img src="https://new-tele-doc-assets.s3.amazonaws.com/assets/followUpScore.png"></div> -->
            </div>
            <div class="Space"></div>
            <p class="tms-img-caption">Using the figure-8 (butterfly) coil, you should place it over the left hemisphere
                in order to stimulate the right arm, more or less 1cm anterior and 5cm lateral from the vertex, with a
                45° orientation.</p>
        </div>

        <div class="Space"></div>

        <!-- TMS THERAPY PATIENT SCORE REPORT SHEET -->
        <ng-container *ngIf="(dataForPreview.hasOwnProperty('ScoryHistoryReport') && dataForPreview.ScoryHistoryReport.length !== 0)">
            <div class="tms-heading underlined">TMS THERAPY PATIENT SCORE REPORT SHEET</div>
            <div class="basicInfo no-page-break">
                <div class="basicInfo-left">
                    <div class="field-item">
                        <div class="field-item-header">NAME</div>
                        <div class="field-item-header-colon">:</div>
                        <div class="field-item-text">{{dataForPreview.PatientName}}</div>
                    </div>
                    <div class="field-item">
                        <div class="field-item-header">SSN</div>
                        <div class="field-item-header-colon">:</div>
                        <div class="field-item-text">{{ssn}}</div>
                    </div>
                    <div class="field-item">
                        <div class="field-item-header">DOB</div>
                        <div class="field-item-header-colon">:</div>
                        <div class="field-item-text">{{dob}}</div>
                    </div>
                </div>
                <div class="basicInfo-right">
                    <div class="field-item">
                        <div class="field-item-header">Age</div>
                        <div class="field-item-header-colon">:</div>
                        <div class="field-item-text">{{dataForPreview.patientAge}}</div>
                    </div>
                    <div class="field-item">
                        <div class="field-item-header">Gender</div>
                        <div class="field-item-header-colon">:</div>
                        <div class="field-item-text">{{dataForPreview.patientGender}}</div>
                    </div>
                </div>
            </div>
            <div class="tms-heading underlined">Score History</div>
            <div>
                <table class="axises no-page-break field-group">
                    <tr>
                        <th class="axises_cell"> <span class="bold"> Date of Evaluation </span> </th>
                        <th class="axises_cell"> <span class="bold"> Name of evaluation </span> </th>
                        <th class="axises_cell"> <span class="bold"> Score </span> </th>
                    </tr>
                    <ng-container *ngFor="let row of dataForPreview.ScoryHistoryReport">
                        <tr>
                            <td class="axises_cell">{{row.scoreEvaluationDate}}</td>
                            <td class="axises_cell">{{row.evaluationName}}</td>
                            <td class="axises_cell">{{row.score}}</td>
                        </tr>
                    </ng-container>
                    
                </table>
            </div>
            <div>Pt acknowledged the significance of the PHQ-9 score as a tool in measuring response to the TMS Therapy.  Pt is doing well. Notes that client is hopeful to see benefits.  Client’s Initial {{dataForPreview.ScoryHistoryReport[0].evaluationName}} score on  {{dataForPreview.ScoryHistoryReport[0].scoreEvaluationDate}} is {{dataForPreview.ScoryHistoryReport[0].score}}. </div>
            <div class="Space"></div>
        </ng-container>
        

        <div class="no-page-break">
            <div *ngIf="dataForPreview.AppointmentType === 'Initial TMS Note'" class="tms-heading underlined">FIG. 1193</div>
            <div *ngIf="dataForPreview.AppointmentType === 'Follow Up TMS Note' || dataForPreview.AppointmentType === 'Follow Up TMS Note - Theta-Burst'" class="tms-heading underlined">FIG. 1138</div>
            <div class="tms-img"><img src="https://new-tele-doc-assets.s3.amazonaws.com/assets/scall.png"></div>
            <p class="tms-img-caption">Side view of head, showing surface relations of bones.</p>
        </div>

        <div class="tms-section-header center underlined">Placement of the MAGSTIM FIGURE OF 8 TMS COIL.</div>

        <ul class="center" style="list-style-type: none;">
            <li>Nasion to Inion {{dataForPreview.patientMeasurementsMagstismFigure.nasionToInoin}}cm. CZ:
                {{dataForPreview.patientMeasurementsMagstismFigure.cz}}cm</li>
            <li>Tragus to Tragus {{dataForPreview.patientMeasurementsMagstismFigure.tragusToTragus}}cm. C3:
                {{dataForPreview.patientMeasurementsMagstismFigure.cthree}}cm</li>
            <li>MT {{dataForPreview.patientMeasurementsMagstismFigure.mt}}%</li>
            <li>BEGIN THERAPY AT {{dataForPreview.patientMeasurementsMagstismFigure.tmsBegun}}% power at {{dataForPreview.patientMeasurements.mappingHertz}}Hz AT {{dataForPreview.patientMeasurementsMagstismFigure.tmsBegunProtocol}}%
                Protocol</li>
        </ul>



        <div class="tms-footer field-group">
            <p>{{dataForPreview.PatientName}}</p>
            <p>DOB: {{dob}}</p>
            <p>AGE: {{dataForPreview.patientAge}}</p>
        </div>

        
    </div>

</main>