import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-make-as-paid',
  templateUrl: './make-as-paid.component.html',
  styleUrls: ['./make-as-paid.component.scss']
})
export class MakeAsPaidComponent implements OnInit {
  makeAsPaidForm = this.makeAsPaid.group({
    paymentMode: ['', Validators.required],
    referenceId: ['', Validators.required],
  });
  paymentModes = [
    {
      label: 'Online',
      value: 'online'
    },
    {
      label: 'Offline',
      value: 'offline'
    }
  ];
  formSubmitAttempt: boolean = false;
  @Output() makeAsPaidData: EventEmitter<any> = new EventEmitter();
  @Output() hidePopUp: EventEmitter<any> = new EventEmitter();
  @Input() patientDetails: any;
  constructor(private makeAsPaid: FormBuilder) { }

  ngOnInit() {
  }


  // Make as Paid actions funtionalties
  actionField(event) {
    this.formSubmitAttempt = true;
    if (this.makeAsPaidForm.valid) {
      console.log(this.makeAsPaidForm);
      console.log(this.makeAsPaidForm.value);
      // this.makeAsPaidData.emit({total:this.totalAmount,btnType:btnType});
      if (this.patientDetails && this.patientDetails.invoiceType === 'patient_invoice') {
        this.makeAsPaidData.emit({form: this.makeAsPaidForm, btnType: 'submit', patientDetails: this.patientDetails, type: 'patient_invoice'});
      } else {
        this.makeAsPaidData.emit({form: this.makeAsPaidForm, btnType: 'submit'});
      }
      // --reset input field values
      // this.generateForm.reset();
      this.formSubmitAttempt = false;
    } else {
      console.log(this.makeAsPaidForm);
    }
  }

  cancelPopUp(event) {
    if (event) {
      this.hidePopUp.emit({cancelpopup: false});
    }
  }
}
