<div class="cancel-popup">
    <div class="cancel-main-wrapper">
        <div class="cancel-popup-main">
            <div class="close">
                <app-svg [name]="'cancel-appointment'" (click)="showHideCancelPopupStatusChange()"></app-svg>
            </div>
            <div class="cancel-popup-content">
                <ng-content ></ng-content>
            </div>
            
        </div>
    </div>
</div>