<!-- partner section start -->
<section class="partner__section section-padding full-width">
    <!-- partner container start -->
    <div class="partner__container restrict-width padding-lr">
        <h2 class="main-heading">
            <span>to bring you highly effective treatment, we have partnered </span>
            <span>with the countries leading insurance providers</span>
        </h2>
        <!-- partner carousel block start -->
        <div class="partner__carousel-block">
            <ngx-slick-carousel class="carousel" [config]="slideConfig">
            <!-- partner logo block start -->
            <div ngxSlickItem class="slide" *ngFor="let partner of partnerDetail">
                <div class="partner__logo-block">
                    <div class="logo__block {{partner.class}}">
                        <img src="assets/images/pngs/{{partner.img}}.png" alt="{{partner.img}} logo" loading="lazy">
                    </div>
                </div>
            </div>
            <!-- partner logo block end -->
        </ngx-slick-carousel>
        
        </div>
        <!-- partner carousel block end -->
        <!-- advertisement block start -->
        <div class="advertisement__block">
            <div class="image__block">
                <img src="assets/images/jpgs/mm-advertisement.jpg" alt="medical marijuana doctor virginia image" loading="lazy">
            </div>
            <p class="content__block">
                Medicinal Marijuana Treatment has proven results in reliving PTSD, Chronic Pain, Anxiety & many more diseases.
            </p>
            <app-know-more (actionTriggered)="navigateBlogPage($event)" [actionBased]="true" [moreText]="moreText" [show]="true"></app-know-more>
        </div>
        <!-- advertisement block end -->
    </div>
    <!-- partner container end -->
</section>
<!-- partner section end -->