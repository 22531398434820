<section class="login-form__section">
    <div class="login-header">
        <span class="common-header">Hello! Welcome back,</span><br>
        To manage your appointment
    </div>

    <div class="new-user" *ngIf="!isAdminURL">
        Do you a New User?<span class="register" (click)="registerNewUser()">Register</span>
    </div>

    <div class="login-form">
        <app-custom-form *ngIf="!askPassword" [formData]="form" (forgotPasswordClicked)="forgotPasswordClicked($event)"
            (submitEvent)="btnClickEvent($event)"></app-custom-form>
        <app-custom-form *ngIf="askPassword" (forgotPasswordClicked)="forgotPasswordClicked($event)" [formData]="passwordForm" (submitEvent)="submitPassword($event)">
        </app-custom-form>
    </div>
</section>