import { Component, OnInit } from '@angular/core';
import * as AWS from 'aws-sdk';
import { poolData } from '@src/environments/environment';
AWS.config.update({
  accessKeyId: 'AKIAWKXDGW32RYCYNSUY',
  secretAccessKey: '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',
  region: 'us-east-1' // change region if required
});
var CognitoIdentityServiceProvider = AWS.CognitoIdentityServiceProvider;
var client = new CognitoIdentityServiceProvider({
  apiVersion: '2016-04-19',
  region: 'us-east-1'
});
@Component({
  selector: 'app-migrate-user',
  templateUrl: './migrate-user.component.html',
  styleUrls: ['./migrate-user.component.scss']
})
export class MigrateUserComponent implements OnInit {

  constructor() { }

  ngOnInit() {


  }

  async getList() {
    let dataUser: any = {

      UserPoolId: poolData.UserPoolId
    };
    let users: any = [];
    let token;
    for (let i = 0; i < 1; i++) {
      if (token) {
        dataUser.PaginationToken = token;
      }
      let call = client.listUsers(dataUser).promise();
      let res: any;
      res = await Promise.resolve(call);
      token = res.PaginationToken;
      let filteredUsers = res.Users.filter(user => {
        let email;
        user.Attributes.some(attribute => {
          if (attribute.Name === 'email') {
            email = attribute.Value;
            return true;
          }
        });
        if (email.toLowerCase() !== email) {
          return true;
        }
      });
      users = users.concat(filteredUsers);
    }
    let confirmedUsers = users.filter(x => x.UserStatus === 'CONFIRMED');
    let unconfirmedUsers = users.filter(x => x.UserStatus !== 'CONFIRMED');
    confirmedUsers.forEach(async (user) => {
      let userDetails: any = { user_id: user.Username };
      let email;
      user.Attributes.some(attribute => {
        if (attribute.Name === 'custom:firstname') {
          userDetails.firstName = attribute.Value;
        }
        if (attribute.Name === 'custom:lastname') {
          userDetails.lastName = attribute.Value;
        }
        if (attribute.Name === 'email') {
          userDetails.email = attribute.Value;
          email = attribute.Value;
          return true;
        }
      });
      let data = {
        ClientMetadata: {
        },
        UserAttributes: [
          {
            Name: 'email',
            Value: email.toLowerCase()
          }, {
            Name: 'email_verified',
            Value: 'true'
          }
        ],
        Username: email,
        UserPoolId: poolData.UserPoolId
      };
      let call = client.adminUpdateUserAttributes(data).promise();
      let res: any;
      res = await Promise.resolve(call);
      if (res) {
        console.log('user updated');
      }



    });
  }
  imageUpload(event) {
    if (event.target.files && event.target.files[0]) {
      const reader: any = new FileReader();
      reader.readAsText(event.target.files[0]);
      reader.onload = () => {
        var lines = reader.result.split('\n');

        var result = [];

        var headers = lines[0].split(',');
        headers.forEach(x => x = x.trim());

        for (var i = 1; i < lines.length; i++) {

          var obj = {};
          var currentline = lines[i].split(',');

          for (var j = 0; j < headers.length; j++) {
            obj[headers[j].trim()] = currentline[j];
          }

          result.push(obj);

        }
        console.log(result, 'json');
        let usersCreated = 0;
        result.forEach(async (user, index) => {
          let time = new Date().getTime();
          while (new Date().getTime() - time < 200) {
            console.log('waited');
          }
          user.email = user.Email;
          if (user && user.email) {
            user.email = user.email.toLowerCase().trim();
            let call = client.adminGetUser({ Username: user.email, UserPoolId: poolData.UserPoolId }).promise();
            let res: any;
            try {
              res = await Promise.resolve(call);
              console.log('user not created', user.email);
            } catch (err) {
              if (err.code === 'UserNotFoundException') {
                let dataUser = {
                  ClientMetadata: {
                  },
                  DesiredDeliveryMediums: ['EMAIL'],
                  ForceAliasCreation: false,
                  TemporaryPassword: this.getPassword(),
                  UserAttributes: [
                    {
                      Name: 'email',
                      Value: user.email.trim(),
                    }, {
                      Name: 'email_verified',
                      Value: 'true',
                    }
                  ],
                  Username: user.email.trim(),
                  UserPoolId: poolData.UserPoolId
                };
                let createUserPromise = client.adminCreateUser(dataUser).promise();
                try {

                  let response = await Promise.resolve(createUserPromise);
                  if (response) {
                    usersCreated++;
                    console.log('user created', usersCreated);
                  }
                } catch (err) {
                  console.log('falied to create user', user.email, err);
                }

              }
            }

          }

          console.log(index);

        });
      };
    }

  }
  getPassword() {
    var pass = '';
    var str = 'abcdefghijklmnopqrstuvwxyz';
    var cap = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var num = '0123456789';
    var splchar = '@*$';
    let i;
    for (i = 1; i <= 5; i++) {
      var char = Math.floor(Math.random()
        * str.length);

      pass += str.charAt(char);
    }
    char = Math.floor(Math.random()
      * cap.length);
    pass += cap.charAt(char);
    char = Math.floor(Math.random()
      * num.length);
    pass += num.charAt(char);
    char = Math.floor(Math.random()
      * splchar.length);
    pass += splchar.charAt(char);

    return pass;
  }
}
