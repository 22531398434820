import { Component, OnInit } from '@angular/core';
import { Meta,Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';

@Component({
  selector: 'app-services-wrapper',
  templateUrl: './services-wrapper.component.html',
  styleUrls: ['./services-wrapper.component.scss']
})
export class ServicesWrapperComponent implements OnInit {

  bannerData = {
    bannerClass: 'services__banner-section',
    orangeHeading: 'We offer',
    blueHeading1: 'a spectrum of',
    blueHeading2: 'world class',
    blueHeading3: 'services',
    bannerDescription: 'We are proud to offer a spectrum of world class mental health services New Tele Doc has a range of worldclass psychiatric services that are highly curated & are focused on development of a patients well being. We take pride in treating each and every patient with atmost care & focus. All our services are approved by medical association & FDA. We also partner with the best possible providers in the industry to bring the latest advancements & medications.',
    bannerImg: 'jpgs/services-banner.jpg'
  };

  constructor(private observable: ObservableHelperService, private meta: Meta, private titleService: Title, private router: Router) { }

  ngOnInit() {
    const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.setAttribute('href', 'https://newteledoc.com/services');
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          canonicalLink.setAttribute('href', 'https://newteledoc.com/services');
        }
      });
    }
    this.meta.addTag({ name: 'description', content: "An outline of the services that the clinic offers including Medication Management DC,Virginia Medical Marijuana Evaluations, Virginia Marijuana Certification, School Counseling,ADHD Testing DC, ADHD Testing for Adults"});
    this.meta.updateTag({ name: 'title', content: 'Services - New TeleDoc Washington DC Psychiatrist' });
    this.titleService.setTitle('Services - New TeleDoc Washington DC Psychiatrist');
    this.observable.setCurrentPage('services');
  }

}
