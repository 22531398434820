import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { convertDateToSpecifiedOffset, DEFAULT_TIME_ZONE_DIFF, TELEMEDICINE_MEDICATION, TOAST_STATUSES } from '@app/common/constants/util.constant';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { getActionsList } from '../common/constants/appointment-actions';
import { IFileDetails } from '@app/common/constants/typeInterfaces.constants';
import { environment } from '@src/environments/environment';

//declare var stripe: any;
@Component({
  selector: 'app-appointment-details-wrapper',
  templateUrl: './appointment-details-wrapper.component.html',
  styleUrls: ['./appointment-details-wrapper.component.scss']
})
export class AppointmentDetailsWrapperComponent implements OnInit {
  paymentRedirectUrl=environment.paymentRedirectUrl;
  breadCrumbData = {
    link: 'admin/appointments',
    linkText: 'Appointment Details'
  };
  domScrollid: any;
  BlockedServices: any;
  appointmentDetailsActions: any;
  showAPILoader = true;
  blockPatientPopupShowHide = false; // Block UI status flag
  blockPatientApplyStatus = false; // Block Status Flag
  isShowScreen = true;
  appointmentDetails: any;
  cancelPopupShowHide: boolean = false;
  cancelAppointmentData: any;
  copayAmount: any;
  dueAmount: any;
  totalAmout: any;
  makeAsPaidPopupShowHide: boolean = false;
  isBeforeActions: boolean = false;
  generateInvoicePopupShowHide: boolean = false;
  active: boolean;
  moreActions: { id: number; name: string; icon: string; }[];
  showActions: { id: number; name: string; icon: string; }[];
  appointmentId: any;
  appointmentDetailsUploads: any[];
  updatedFilesCount = 0;
  uploadFilesData = {
    patientName: '',
    patientDOB: '',
    updatedFilesCount: this.updatedFilesCount,
  };
  reportsDocFilesData = {
    patientName: '',
    patientDOB: '',
    updatedFilesCount: this.updatedFilesCount,
  };
  detailPageUpload: any;
  outsideScroll = false;
  uploadUrlBody: any;
  patientNameId: any;
  rawFilterData: any;
  treatmentsCount: any; // getting the all treatments count
  getFilterData: any;
  patientId: number;
  blockStatusBody: any;
  gereneinvoiceData: any;
  // Form inputs
  forminputs: any = [
    {
      type: 'header',
      label: 'text',
      label1: 'text',
      showontoggle: true,
      editable: true,
      key: 'patient_name_id',
      className: 'patient-id',
    },

    {
      type: 'groupCheckbox',
      categoryLabel: 'Treatment',
      controlType: 'groupCheckbox',
      key: 'treatment',
      required: true,
      value: [],
      isIntermediate: false,
      options: [
        {
          name: 'Medmanagement',
          value: 'Medmanagement',
          isSelected: false
        },
        {
          name: 'Tele Medicine',
          value: 'Tele Medicine',
          isSelected: false
        },
        {
          name: 'TMS',
          value: 'TMS',
          isSelected: false
        },
        {
          name: 'Therapy',
          value: 'Therapy',
          isSelected: false
        }
      ],
      validators: [],
      focus: true,
      valueable: true,
      isSelected: false,
      showontoggle: true,
    },
    {
      type: 'submit',
      placeholder: '',
      required: true,
      text: 'Save',
      key: '',
      value: '',
      controlType: 'submitButton',
      validators: [],
      callback: '',
      cencelBtn: '',
      showontoggle: true,
      disabled: true
    }
  ];
  reportsDocFiles: any[];
  amountGenerated: any;
  invoiceStatus: any;
  isAdmin: boolean = true;
  adminPermissions = {
    APPOINTMENT_EDIT: true,
    PATIENT_EDIT: true,
    PATIENT_DETAILS: true,
    INVOICE_EDIT: true,
    INVOICE_DETAILS: true,
  };
  permissionsArr = [];
  addTMSClass = false;
  pendingTMS = false;
  ketamineFormSubmit: string = 'noShow';
  ketamineMonitoringFormFile: IFileDetails = {
    docUrl: '',
    file: { name: '', extenstion: '' }
  };
  paymentToken: string;
  isMarkasPaid= false;
  constructor(private http: HttpService, private observable: ObservableHelperService, private router: Router, private route: ActivatedRoute) {
    this.appointmentId = router.url.split('/').pop();
    this.appointmentId = this.appointmentId.includes('?') ? this.appointmentId.split('?')[0] : this.appointmentId;
  }
  ngOnInit() {
    this.getAdminPermissions();
  }
  // Checking the Slide bar is open or if open hide outside scroll
  checkingSidebarState(slideBarFlag) {
    if (slideBarFlag) {
      document.querySelector('html').style.overflow = 'hidden';
      document.querySelector('body').style.overflow = 'hidden';
    } else {
      document.querySelector('html').style.overflow = 'auto';
      document.querySelector('body').style.overflow = 'auto';
    }
  }
  // Checcking side bar staus
  checkingSidebarSetStatus(data) {
    this.outsideScroll = data;
    this.checkingSidebarState(data);
  }
  scrollAction() {
    this.route
      .queryParams
      .subscribe(params => {
        this.domScrollid = params['id'];
        if (params['scroll']) {
          setTimeout(() => {
            this.scroll(document.getElementById(params['id']));
          }, 2000);
        } else {
        }
      });

  }
  scroll(el: HTMLElement) {
    window.scroll(0, (this.findPos(document.getElementById(this.domScrollid)) - 130));
  }
  findPos(obj: any) {
    var curtop = 0;
    if (obj.offsetParent) {
      do { curtop += obj.offsetTop; }
      while (obj === obj.offsetParent);
      return curtop;
    }
  }


  // Get appointment details API call
  getAppointmentDetails() {
    this.isShowScreen = this.showAPILoader ? true : false;
    const payloadData = {
      type: 'GET',
      url: 'appointmentDetails',
      isDeveloper: true,
      pathVariables: [this.appointmentId]
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        let apptInfo = res.data.appointmentInfo;
        if ((apptInfo.status === 'A' || apptInfo.status === 'P') && apptInfo.appointmenttypeId === 4 && res.data.isTMSfill !== undefined && res.data.isTMSfill && !res.data.pendingTMS) { this.addTMSClass = true; }
        if ((apptInfo.status === 'A' || apptInfo.status === 'P') && apptInfo.appointmenttypeId === 4 && res.data.pendingTMS) { this.pendingTMS = true; }
        this.getTreatmentsDataLength(res.data);
      } else {
        if (res.error.message === 'Insufficient Privilege') {
          this.observable.showToast(TOAST_STATUSES.ERROR, 'Sorry! You do not have access to this page.');
          this.router.navigate(['/admin']);
        }
      }
      this.isShowScreen = true;
      this.scrollAction();
    });
  }
  // Get All Treatments Data length
  getTreatmentsDataLength(appointmentDate) {
    this.isShowScreen = this.blockPatientApplyStatus ? true : false;
    const payloadData = {
      type: 'GET',
      url: 'invoiceFilter',
      isDeveloper: true,
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.treatmentsCount = Object.keys(res.data.treatment).length;
        this.getFilterData = res.data;
        this.getFilterData.treatment.unshift(TELEMEDICINE_MEDICATION);
        this.appointmentDetails = appointmentDate;
        this.appointmentDetails.patientInfo.blockStatus = this.appointmentDetails.patientInfo.blockStatus.map(each =>{
          return{
            ...each,
            name : (each.appointmentTypeId === TELEMEDICINE_MEDICATION.appointmentTypeId && each.locationId === TELEMEDICINE_MEDICATION.locationId) ? `${each.appointmentTypeName} Online` : (each.appointmentTypeId === TELEMEDICINE_MEDICATION.appointmentTypeId && each.locationId !== TELEMEDICINE_MEDICATION.locationId) ? `${each.appointmentTypeName}` : each.appointmentTypeName,
          };
        });
        this.BlockedServices = this.appointmentDetails.patientInfo.blockStatus;
        this.BlockedServices = this.BlockedServices.filter((obj, pos, arr) => {
          return arr.map(mapObj => mapObj.appointmentTypeId).indexOf(obj.appointmentTypeId) === pos;
        })
        this.appointmentDetails.appointmentInfo.status = this.massageAppointmentStatus(this.appointmentDetails.appointmentInfo, this.appointmentDetails.timestamp, this.appointmentDetails.patientInfo);
        this.appointmentDetails.appointmentInfo.doctor = this.massageDoctorsData(this.appointmentDetails.appointmentInfo.doctor);
        this.appointmentDetailsUploads = this.getAllUploadsList(appointmentDate);
        this.uploadFilesData.patientName = this.appointmentDetails.patientBasicInfo.firstName + ' ' + this.appointmentDetails.patientBasicInfo.lastName;
        this.uploadFilesData.patientDOB = this.appointmentDetails.patientBasicInfo.dob;
        this.detailPageUpload = this.massageUploads(this.appointmentDetails.extraUploads, 'appointment_doc'),
          this.uploadFilesData.updatedFilesCount = this.massageUploads(this.appointmentDetails.extraUploads, 'appointment_doc').length,
          this.reportsDocFiles = this.massageUploads(this.appointmentDetails.extraUploads, 'appointment_report'),
          this.reportsDocFilesData.updatedFilesCount = this.massageUploads(this.appointmentDetails.extraUploads, 'appointment_report').length,
          this.reportsDocFilesData.patientName = this.appointmentDetails.patientBasicInfo.firstName + ' ' + this.appointmentDetails.patientBasicInfo.lastName;
        this.reportsDocFilesData.patientDOB = this.appointmentDetails.patientBasicInfo.dob;
        this.checkingSidebarSetStatus(false);
        this.ketamineFormSubmit = this.activateMonitoringFrom(appointmentDate)?(appointmentDate.appointmentData ? (appointmentDate.appointmentData.ketamineMonitoring ? (appointmentDate.appointmentData.ketamineMonitoring.adminMonitoringFormFill ? "filled" : "notFilled") : "noShow") : "noShow"):"noShow";
        console.log(this.appointmentDetails);
      } else {
        let message = 'Failed to get treatments details. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
      this.isShowScreen = true;
    });
  }

  activateMonitoringFrom(appointmentDate) {
    if (!appointmentDate && !appointmentDate.appointmentInfo) {
      return false;
    } else {
      let appointmentInfo = appointmentDate.appointmentInfo;
      let appointmentEndTime = new Date(appointmentInfo.appointmentDate + ' ' + appointmentInfo.timeSlotFrom);
      console.log('appointmentDate', appointmentEndTime);
      let currentDate = new Date(convertDateToSpecifiedOffset(DEFAULT_TIME_ZONE_DIFF, new Date()));
      console.log('currentDate', currentDate);

      if (appointmentEndTime.valueOf() <= currentDate.valueOf()) {
        return true;
      } else {
        return false;
      }
    }
  }
  // Get all uploads list form API
  getAllUploadsList(data) {
    let uploadsList = [];
    let fileName;
    let uploadsKeysList = [data.patientBasicInfo.idProofUploadImage1, data.patientBasicInfo.idProofUploadImage2, data.policyHolder.policyHoldersUploadBack, data.policyHolder.policyHoldersUploadFront, data.policyHolder.secondaryPolicyHoldersUploadBack, data.policyHolder.secondaryPolicyHoldersUploadFront];
    uploadsKeysList.forEach(eachupload => {
      if (eachupload !== '') {
        fileName = eachupload.includes('__name__') ? eachupload.substring(
          eachupload.lastIndexOf('__name__') + 8,
          eachupload.lastIndexOf('?')) : 
          eachupload.substring(eachupload.lastIndexOf('/') + 1, eachupload.lastIndexOf('?'))

        uploadsList.push(this.splitFileName(fileName, eachupload));
      }
    });
    return uploadsList;
  }

  // Split function for the file name
  splitFileName(file, docURL) {
    let fileObj = {};
    let splitFileName = file.split('.');
    fileObj['name'] = splitFileName[0];
    fileObj['extenstion'] = splitFileName[splitFileName.length - 1];
    fileObj['file'] = docURL;
    return fileObj;
  }
  // This will massage Doctors data
  massageDoctorsData(doctor) {
    if (doctor) {
      return {
        ...doctor,
        status: '',
        isSelected: true,
      };
    }
  }

  massageActionList(appointmentTypeName, appointmentStatus, invoiceStatus, page, appointment) {
    let actions;
    actions = getActionsList(appointmentTypeName, appointmentStatus, invoiceStatus, page),
      actions.forEach(eachAction => {
        if (eachAction.id === 'block_patient') {
          if (appointment.blockStatus.length === this.treatmentsCount + 1) {
            eachAction.label = 'Unblock';
          } else {
            eachAction.label = 'Block Patient';
          }
        }
      });
    return actions;
  }

  // This will massage the appointment status
  massageAppointmentStatus(data, timestamp, patient) {
    if (data) {
      this.appointmentDetailsActions = this.massageActionList(data.treatment, data.status, data.invoiceStatus, 'appointment_details', patient);
      this.appointmentDetailsActions = this.appointmentDetailsActions.filter(icon => this.permissionsArr.includes(icon.permissionName));
      let busyInsurance = {
        label: '3 Way Call Insurance',
        id: 'busy_insurance',
        colorIconName: '3_way_call_insurance_updtd'
      };
      let termInsurance = {
        label: 'Terminate Insurance',
        id: 'terminated_insurance',
        colorIconName: 'term_insurance_stroke'
      };
      let medicationSent = {
        label: 'Medication Sent',
        id: 'medication_mail',
        colorIconName: 'medical-prescription-email'
      };
      const coordinationOfBenefits = {
        label: 'Coordination Of Benefits',
        id: 'coordination_benefits',
        colorIconName: 'mail'
      };
      const pharmacyDiscrepancy = {
        label: 'Pharmacy Discrepancy',
        id: 'pharmacy_discrepancy',
        colorIconName: 'mail'
      };
      if (this.adminPermissions.APPOINTMENT_EDIT) { this.appointmentDetailsActions.push(busyInsurance, termInsurance, medicationSent, coordinationOfBenefits, pharmacyDiscrepancy); }
      let appStatus = ['A', 'N', 'C', 'I'];
      let insType = ['busy_insurance', 'terminated_insurance', 'medication_mail', 'coordination_benefits', 'pharmacy_discrepancy'];
      if (!appStatus.includes(data.status)) {
        this.appointmentDetailsActions = this.appointmentDetailsActions.filter(obj => !insType.includes(obj.id));
      }
      this.showActions = this.appointmentDetailsActions.slice(0, 4);
      this.moreActions = this.appointmentDetailsActions.slice(4);
      let status = data.status;
      let invoiceStatus = data.invoiceStatus;
      // "2020-11-19T00:00:00.000Z"
      let appointmentStartDate: any = `${data.appointmentDate}T${data.timeSlotFrom}`;
      let appointmentEndDate: any = `${data.appointmentDate}T${data.timeSlotTo}`;
      appointmentStartDate = new Date(appointmentStartDate).valueOf();
      appointmentEndDate = new Date(appointmentEndDate).valueOf();
      // let currentDate: any = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}T00:00:00:000Z`;
      let currentDate = new Date(convertDateToSpecifiedOffset(DEFAULT_TIME_ZONE_DIFF, timestamp)).valueOf();
      if (status === 'A') {
        let removableActions = ['send_reminder', 'reschedule_appointment', 'cancel_appointment'];
        if (currentDate < appointmentStartDate) {
          if (invoiceStatus === 'ungenerated') {
            let generateInvoice = {
              label: 'Generate Invoice',
              iconName: 'admininvoice',
              id: 'generate_invoice',
              colorIconName: 'generate-invoice-color'
            };
            if (this.adminPermissions.INVOICE_EDIT) { this.appointmentDetailsActions.unshift(generateInvoice); }
            this.showActions = this.appointmentDetailsActions.slice(0, 4);
            this.moreActions = this.appointmentDetailsActions.slice(4);
            return 'Invoice pending';
          } else if (invoiceStatus === 'unpaid') {
            return 'Payment pending';
          } else if (invoiceStatus === 'paid') {
            let activePaidActions = [
              {
                label: 'Mark Absent',
                iconName: 'mark-absent',
                id: 'mark_absent',
                colorIconName: 'mark-absent-color'
              },
              {
                label: 'Cancel Appointment',
                iconName: 'cancel-appointment-normal',
                id: 'cancel_appointment',
                colorIconName: 'cancel-appointment-color'
              },
              {
                label: 'Reschedule Appointment',
                iconName: '',
                id: 'reschedule_appointment',
                colorIconName: 'mixedReschedule'
              },
              {
                label: 'Send Reminder',
                iconName: 'remainder',
                id: 'send_reminder',
                colorIconName: 'sendReminder'
              }
            ];
            if (this.adminPermissions.APPOINTMENT_EDIT) {
              activePaidActions.forEach(eachAction => {
                this.appointmentDetailsActions.unshift(eachAction);
              });
            }
            this.showActions = this.appointmentDetailsActions.slice(0, 4);
            this.moreActions = this.appointmentDetailsActions.slice(4);
            return 'Active';
          } else {
            return 'Active';
          }
        } else if (invoiceStatus === 'ungenerated') {
          let generateInvoice = {
            label: 'Generate Invoice',
            iconName: 'admininvoice',
            id: 'generate_invoice',
            colorIconName: 'generate-invoice-color'
          };
          this.appointmentDetailsActions = this.appointmentDetailsActions.filter(action => !removableActions.includes(action.id));
          if (this.adminPermissions.INVOICE_EDIT) { this.appointmentDetailsActions.unshift(generateInvoice); }
          this.showActions = this.appointmentDetailsActions.slice(0, 4);
          this.moreActions = this.appointmentDetailsActions.slice(4);
          if (currentDate >= appointmentStartDate && currentDate <= appointmentEndDate) {
            return 'Inprogress';
          } else {
            return 'Invoice pending';
          }
        } else if (invoiceStatus === 'unpaid') {
          this.appointmentDetailsActions = this.appointmentDetailsActions.filter(action => !removableActions.includes(action.id));
          this.showActions = this.appointmentDetailsActions.slice(0, 4);
          this.moreActions = this.appointmentDetailsActions.slice(4);
          return 'Payment pending';
        } else if (invoiceStatus === 'paid') {
          this.appointmentDetailsActions = this.appointmentDetailsActions.filter(action => !removableActions.includes(action.id));
          this.showActions = this.appointmentDetailsActions.slice(0, 4);
          this.moreActions = this.appointmentDetailsActions.slice(4);
          return 'Completed';
        }
      } else if (status === 'B') {
        if (invoiceStatus === 'ungenerated') {
          return 'Mark as absent';
        } else if (invoiceStatus === 'unpaid') {
          let invoiceDetails = {
            label: 'Invoice Details',
            id: 'invoice_details',
            iconName: 'Invoice-slick',
            colorIconName: 'Invoice-color'
          };
          this.appointmentDetailsActions = this.appointmentDetailsActions.filter(action => action.id !== 'generate_invoice');
          if (this.adminPermissions.INVOICE_EDIT) { this.appointmentDetailsActions.unshift(invoiceDetails); }
          this.showActions = this.appointmentDetailsActions.slice(0, 4);
          this.moreActions = this.appointmentDetailsActions.slice(4);
          return 'Mark as absent';
        } else if (invoiceStatus === 'paid') {
          this.appointmentDetailsActions = this.appointmentDetailsActions.filter(action => action.id !== 'generate_invoice');
          this.showActions = this.appointmentDetailsActions.slice(0, 4);
          this.moreActions = this.appointmentDetailsActions.slice(4);
          return 'Mark as absent';
        }
      } else {
        return this.getAppointmentStatus(status, invoiceStatus);
      }
    }
  }
  getAppointmentStatus(appoinmnetStatus, invoiceStatus) {
    let status = '';
    if (appoinmnetStatus === 'P' && (invoiceStatus === 'ungenerated' || invoiceStatus === 'paid')) {
      status = 'Pending';
    } else if (appoinmnetStatus === 'P' && invoiceStatus === 'unpaid') {
      status = 'Pending Payment';
    } else if (appoinmnetStatus === 'A' && invoiceStatus === 'ungenerated') {
      status = 'Invoice Pending';
    } else if (appoinmnetStatus === 'A' && invoiceStatus === 'unpaid') {
      status = 'Pending Payment';
    } else if (appoinmnetStatus === 'A' && invoiceStatus === 'paid') {
      status = 'Completed';
    } else if (appoinmnetStatus === 'B') {
      status = 'Mark as absent';
    } else if (appoinmnetStatus === 'D') {
      status = 'Cancelled';
    }
    return status;
  }

  // Get getPatientDetails API call
  getPatientDetails() {
    const payloadData = {
      type: 'GET',
      url: 'patientsDetails',
      isDeveloper: true,
      pathVariables: [this.patientId],
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.getTreatmentsData(res.data.details.blockStatus);
      } else {
        let message = 'Failed to fetch patient details. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
  // Appointment status action
  appointmentsStatusActions(status) {
    if (status.id === 'patient_details') {
      this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.PATIENTS}/${this.appointmentDetails.appointmentInfo.patientId}`]);
    } else if (status.id === 'invoice_details') {
      try {
        const invoiceId = this.appointmentDetails.appointmentInfo.invoiceId;
        this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.INVOICES}/appt_invoice/${invoiceId}`]);
      } catch (err) {
        this.observable.showToast(TOAST_STATUSES.SUCCESS, 'No invoice found for this appointment');
      }
    } else if (status.id === 'block_patient') {
      this.patientId = this.appointmentDetails.patientId;
      this.forminputs.forEach(each => {
        if (each.key === 'patient_name_id') {
          each.label = `${this.appointmentDetails.patientBasicInfo.firstName} ${this.appointmentDetails.patientBasicInfo.lastName}`;
          each.label1 = this.appointmentDetails.appointmentInfo.patientId;
        }
      });
      this.getPatientDetails();
    } else if (status.id === 'generate_invoice') {
      this.invoiceStatus = 'ungenerated';
      this.generateInvoicePopupShowHide = true;
    } else if (status.id === 'send_reminder') {
      // TODO@Sai please confirm the above with Samad/Sravan
      this.sendReminder(this.appointmentDetails);
    } else if (status.id === 'resend_invoice') {
      this.resendInvoice(this.appointmentDetails);
    } else if (status.id === 'mark_absent') {
      this.absentAppointmnet(this.appointmentDetails);
    } else if (status.id === 'cancel_appointment') {
      this.cancelAppointmentData = {
        patient: this.appointmentDetails.patientBasicInfo,
        type: 'adminappointments',
        appointmentId: this.appointmentDetails.appointmentInfo.appointmentId,
        locationType: this.appointmentDetails.appointmentInfo.locationType,
        userId: this.appointmentDetails.userId
      };
      this.cancelPopupShowHide = true;
    } else if (status.id === 'reschedule_appointment') {
      this.router.navigate([`${ROUTE_PATHS.ADMIN}`], { queryParams: { appointmentId: this.appointmentDetails.appointmentInfo.appointmentId, patientId: this.appointmentDetails.appointmentInfo.patientId, appointmentDate: this.appointmentDetails.appointmentInfo.appointmentDate, appointmentTime: this.appointmentDetails.appointmentInfo.timeSlotFrom, appointmentTypeId: this.appointmentDetails.appointmentInfo.appointmenttypeId, locationId: this.appointmentDetails.appointmentInfo.locationId, isReshedule: true, offline: this.appointmentDetails.isOffline } });
    } else if (status.id === 'busy_insurance' || status.id === 'terminated_insurance' || status.id === 'medication_mail' || status.id === 'coordination_benefits' || status.id === 'pharmacy_discrepancy') {
      this.insuranceEmail(status.id, this.appointmentDetails);
    } else {
      this.observable.showToast(TOAST_STATUSES.SUCCESS, 'This feature is coming out soon..!!');
    }
  }

  resendInvoice(appoinmnetData) {
    let message = 'Invoice has been re-sent to the customer successfully.';
    let payload = {
      type: 'GET',
      url: 'invoiceResend',
      isDeveloper: true,
      pathVariables: [appoinmnetData.appointmentInfo.invoiceId],
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        console.log('Response', res);
        this.observable.showToast(TOAST_STATUSES.SUCCESS, message);
      } else {
        message = 'Failed to resend invoice the appointment. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
  uploadClicked(event) {
    if (event) {
      this.observable.showToast(TOAST_STATUSES.SUCCESS, 'This feature is coming out soon..!!');
    }
  }



  // Download file
  donwloadFile(url, fileName, flag) {
    let fullFileName = '';
    if (flag === true) {
      fullFileName = fileName.name + '.' + fileName.extenstion;
    } else {
      fullFileName = fileName.file.name + '.' + fileName.file.extenstion;
    }
    // tslint:disable-next-line:only-arrow-functions
    fetch(url).then(function(t) {
      return t.blob().then((b) => {
        var a = document.createElement('a');
        a.href = URL.createObjectURL(b);
        a.setAttribute('download', fullFileName);
        a.click();
      }
      );
    });
  }
  // Upload reports
  uploadReportsDoc(data) {
    this.uploadFunctionCall(data, 'appointment_report');
  }

  // Upload doc
  uploadDoc(data) {
    this.uploadFunctionCall(data, 'appointment_doc');
  }
  uploadFunctionCall(data, docType) {
    this.uploadUrlBody = {
      uploadType: docType,
      refId: this.appointmentDetails.appointmentInfo.appointmentId.toString(),
      docUrl: data
    };
    this.getUploadedFilesURL(data);
  }
  getUploadedFilesURL(filed) {
    let payloadData;
    payloadData = {
      type: 'POST',
      url: 'uploadsFiles',
      isDeveloper: true,
      body: this.uploadUrlBody,
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        if (this.uploadUrlBody.uploadType === 'appointment_report') {
          this.reportsDocFiles.push(res.data);
          this.reportsDocFiles = this.massageUploads(this.reportsDocFiles, this.uploadUrlBody.uploadType);
        } else {
          this.detailPageUpload.push(res.data);
          this.detailPageUpload = this.massageUploads(this.detailPageUpload, this.uploadUrlBody.uploadType);
        }
        this.observable.showToast(TOAST_STATUSES.SUCCESS, 'File has been successfully uploaded.');
      } else {
        let message = 'Failed to update uploading files . Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
  deleteFileUpload(data, docType) {
    this.checkingSidebarSetStatus(true);
    let bodyData = {
      uploadType: docType,
      refId: this.appointmentDetails.appointmentInfo.appointmentId.toString(),
      uploadId: data.uploadId
    };
    let payloadData = {
      type: 'DELETE',
      url: 'uploadFileDelete',
      isDeveloper: true,
      body: bodyData,
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        if (docType === 'appointment_report') {
          this.reportsDocFiles = this.massageUploads(this.deleteUpload(data, docType), docType);
        } else {
          this.detailPageUpload = this.massageUploads(this.deleteUpload(data, docType), docType);
        }
        this.observable.showToast(TOAST_STATUSES.SUCCESS, res.data);
        this.checkingSidebarSetStatus(false);
      } else {
        let message = 'Failed to delete uploading files . Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
  deleteUpload(data, doc) {
    let uploads = [];
    let finalArr = doc === 'appointment_report' ? this.reportsDocFiles : this.detailPageUpload;
    finalArr.forEach((each, index) => {
      if (each.uploadId !== data.uploadId) {
        uploads.push(each);
      }
    });
    return uploads;
  }
  massageUploads(data, docType) {
    // Get all uploads list form API
    let uploadsList = [];
    let fileName;
    data.forEach(eachupload => {
      if (eachupload !== '') {
        if(docType == 'appointment_doc' && eachupload.docUrl.includes('KETAMINE_MONITORING_FORM')){
          this.ketamineMonitoringFormFile['docUrl'] = eachupload.docUrl;
          this.ketamineMonitoringFormFile['file']['name'] = "KETAMINE_MONITORING_FORM";
          this.ketamineMonitoringFormFile['file']['extenstion'] = "pdf";
        }
        else {
          let docObj = { ...eachupload };
          fileName = eachupload.docUrl.includes('__name__') ? eachupload.docUrl.substring(
            eachupload.docUrl.lastIndexOf('__name__') + 8,
            eachupload.docUrl.lastIndexOf('?')) : eachupload.docUrl.substring(eachupload.docUrl.lastIndexOf('/') + 1, eachupload.docUrl.lastIndexOf('?'))
          docObj['file'] = this.splitFileName(fileName, eachupload.docUrl);
          uploadsList.push(docObj);
        }
      }
    });
    uploadsList = uploadsList.filter(eachDoc => eachDoc.uploadType === docType);
    return uploadsList;
   
  }

  // This is closing for block patient the popup
  blockPatientPopupShowHideStatusFun(status) {
    this.blockPatientPopupShowHide = status.cancelpopup;
    this.checkingSidebarSetStatus(false);
  }
  // Block patient Save action
  blockPatientSubmit(data) {
    // Block API call
    this.blockTheUserAPI(data);
  }
  // Block API PUT Call
  blockTheUserAPI(bodyData) {
    const payloadData = {
      type: 'PUT',
      url: 'patientActions',
      isDeveloper: true,
      body: bodyData,
      pathVariables: [this.patientId],
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        if (Number(res.data.patientId) === this.patientId) {
          this.blockPatientApplyStatus = true;
          this.blockPatientPopupShowHide = false;
          this.getAppointmentDetails();
          this.observable.showToast(TOAST_STATUSES.SUCCESS, 'Updated the patient blocked status');
        }
      } else {
        // Error
        let message = 'Failed to uplodate patient block status. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
  // Admin get filterData API call
  getTreatmentsData(blockstatus) {
    this.rawFilterData = this.massageFilterData(this.getFilterData, blockstatus);
    this.blockPatientPopupShowHide = true;
    // this.checkingSidebarSetStatus(true);
  }
  // Massage the filter data
  massageFilterData(data, blockstatus) {
    if (data && typeof (data) === 'object') {
      data.treatment = data.treatment.map(doc => {
        doc['isSelected'] = false;
        doc['locationId'] = (doc.locationId) ? doc.locationId : null;
        doc['name'] = (doc.appointmentTypeId === TELEMEDICINE_MEDICATION.appointmentTypeId && doc.locationId === TELEMEDICINE_MEDICATION.locationId) ? `${doc.appointmentTypeName} Online` : (doc.appointmentTypeId === TELEMEDICINE_MEDICATION.appointmentTypeId && doc.locationId !== TELEMEDICINE_MEDICATION.locationId) ? `${doc.appointmentTypeName} In Office` : doc.appointmentTypeName;
        blockstatus.forEach(each => {
          if (each.appointmentTypeId === doc.appointmentTypeId && each.locationId === doc.locationId) {
            doc['isSelected'] = true;
          }
        });
        return {
          ...doc,
          value: doc.appointmentTypeId,
        };
      });
      return data;
    } else {
      // Error
    }
  }

  // generate invoice fun
  // This is closing for generate invoice the popup
  generateInvoicePopupShowHideStatusFun(status) {
    this.generateInvoicePopupShowHide = status.cancelpopup;
    this.checkingSidebarSetStatus(false);
  }
  // generateInvoice($event)
  generateInvoice(data) {
    if (data) {
      // tslint:disable-next-line:one-variable-per-declaration
      let method, callUrl, bodyData, pathVariable, message; let callAPI = false;
      data.values ? this.amountGenerated = {
        copay: data.values.copayAmt,
        due: data.values.dueAmt,
        pay: (data.values.copayAmt + data.values.dueAmt),
        comment: data.values.comments
      // tslint:disable-next-line:no-unused-expression
      } : null;

      if (data.btnType === 'sendCustomer' || (data.btnType === 'submit' && this.invoiceStatus && this.invoiceStatus === 'ungenerated')) {
        method = 'POST';
        callUrl = 'generateInvoice';
        data.btnType === 'sendCustomer' ?
          bodyData = {
            type: 'NEW',
            appointmentId: this.appointmentId,
            status: 'unpaid',
            copay: this.amountGenerated.copay,
            // pay: this.amountGenerated.pay
            pay: this.amountGenerated.due,
            invoiceType: 'appt_invoice',
            comment: this.amountGenerated.comment
          } : bodyData = {
            type: 'NEW_PAID',
            appointmentId: this.appointmentId,
            status: 'paid',
            copay: this.amountGenerated.copay,
            // pay: this.amountGenerated.pay,
            pay: this.amountGenerated.due,
            paymentMode: data.form.value.paymentMode,
            referenceId: data.form.value.referenceId,
            invoiceType: 'appt_invoice',
            comment: this.amountGenerated.comment
          };
        message = data.btnType === 'sendCustomer' ? 'Invoice has been sent to the customer successfully' : 'Invoice has been marked as paid successfully.';
        // tslint:disable-next-line:no-unused-expression
        data.btnType === 'submit' ? this.makeAsPaidPopupShowHide = false : null;
        callAPI = true;
      } else if (data.btnType === 'makePayment') {
        // this.observable.showToast(TOAST_STATUSES.SUCCESS, 'This feature is coming out soon..!!');
        let payload = {
          type: 'POST',
          url: 'checkoutSession',
          isDeveloper: true,
          body: {
            type: 'NEW',
            appointmentId: this.appointmentId,
            copay: this.amountGenerated.copay,
            pay: this.amountGenerated.due,
            invoiceType: 'appt_invoice'
          }
        };
        this.http.makeHttpRequest(payload).subscribe((res) => {
          if (res.error) {
            alert(res.error.message);
            return;
          } else {
            this.paymentToken = res.result.response.token
            let inputElement = document.getElementById("paymentvalue") as HTMLInputElement;
            // Set the value of the input element
            inputElement.value = this.paymentToken;
            // Get the form element
            let form = document.getElementById('formAuthorizeNetPage') as HTMLFormElement;
            form.method = 'POST';
            form.submit();
          }
          // stripe.redirectToCheckout({ sessionId: res.id });
        });
        this.checkingSidebarSetStatus(false);
      } else if (data.btnType === 'markAsPaid') {
        this.generateInvoicePopupShowHide = false;
        this.makeAsPaidPopupShowHide = true;
      }

      if (callAPI) {
        // API Call for all
        callAPI = false;
        const payload = {
          type: method,
          url: callUrl,
          isDeveloper: true,
          pathVariables: [pathVariable ? pathVariable : null],
          body: bodyData ? bodyData : null,
        };
        this.http.makeHttpRequest(payload).subscribe((res) => {
          if (this.http.isSuccessfulResponse(res)) {
            console.log('Response', res);
            this.getAppointmentDetails();
            this.observable.showToast(TOAST_STATUSES.SUCCESS, message);
          } else {
            console.log('Response', res);
            this.observable.showToast(TOAST_STATUSES.ERROR, res.error.message);
          }
        });
      }
    }
  }

  // This is closing for make as paid popup
  makeAsPaidPopupShowHideStatusFun(status) {
    this.makeAsPaidPopupShowHide = status.cancelpopup;
  }

  sendReminder(appoinmnetData) {
    let payload = {
      type: 'GET',
      url: 'appointmentDetails',
      isDeveloper: true,
      pathVariables: [appoinmnetData.appointmentInfo.appointmentId + '/reminder'],
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.showAPILoader = true;
        this.getAppointmentDetails();
        this.observable.showToast(TOAST_STATUSES.SUCCESS, 'Appointment schedule reminder email has been sent to your registered email id. Please check');
      } else {
        let message = 'Failed to absent the appointment. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  // Absent appointment details

  absentAppointmnet(appoinmnetData) {
    let currentDate = new Date();
    let diffTime: any = (currentDate.getUTCHours() - currentDate.getHours()) + ':' + (currentDate.getUTCMinutes() - currentDate.getMinutes());
    let absentBody = {
      userId: appoinmnetData.userId,
      locationType: appoinmnetData.appointmentInfo.locationType,
      type: 'ABSENT',
      timeDiff: diffTime
    };
    let payload = {
      type: 'PUT',
      url: 'appointmentDetails',
      isDeveloper: true,
      pathVariables: [appoinmnetData.appointmentInfo.appointmentId],
      body: absentBody
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.showAPILoader = true;
        this.getAppointmentDetails();
        this.observable.showToast(TOAST_STATUSES.SUCCESS, 'This appointment has been marked as absent');
      } else {
        let message = 'Failed to absent the appointment. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  getLastVist(lastVisit) {
    if (lastVisit && Object.keys(lastVisit).length > 0) {
      return true;
    } else {
      return false;
    }
  }
  cancelPopupShowHideStatusFun(status) {
    this.cancelPopupShowHide = status.cancelpopup;
    this.checkingSidebarState(this.cancelPopupShowHide);
    this.showAPILoader = true;
    this.getAppointmentDetails();
  }

   // insurance temaplates
   insuranceEmail(emailType, appData) {
    let payload = {
      type: 'POST',
      url: 'insuranceEmail',
      isDeveloper: true,
      body: {
        appointmentId: (emailType !== 'medication_mail') ? appData.appointmentInfo.appointmentId : null,
        patientId: (emailType === 'medication_mail') ? appData.patientId : null,
        emailType: emailType === 'busy_insurance' ? 'BUSY_INSURANCE' : (emailType === 'terminated_insurance') ? 'TERMINATED_INSURANCE' : (emailType === 'coordination_benefits') ? 'COORDINATION_OF_BENEFITS' : (emailType === 'pharmacy_discrepancy') ? 'PHARMACY_DISCREPANCY' : 'MEDICATION_MAIL',
        patientIdList: []
      }
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        console.log('Response', res);
        this.observable.showToast(TOAST_STATUSES.SUCCESS, 'Email has been sent to the patient successfully.');
      } else {
        let message = 'Failed to send the email. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  // getting permissions based on user id
  getAdminPermissions() {
    const payloadData = {
      type: 'GET',
      url: 'getPermissions',
      isDeveloper: true
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        let tempPerms = ['APPOINTMENT_EDIT', 'PATIENT_EDIT', 'PATIENT_DETAILS', 'INVOICE_EDIT', 'INVOICE_DETAILS'];
        res.data.permissions.forEach(obj => {
            this.permissionsArr.push(obj.permissionName);
        });
        if (this.permissionsArr.includes('INVOICE_MARKASPAID')){
          this.isMarkasPaid = true;
        }
        else{
          this.isMarkasPaid = false
        }
        if (!this.permissionsArr.includes('APPOINTMENT_DETAILS')) {
          this.observable.showToast(TOAST_STATUSES.ERROR, 'Sorry! You do not have access to this page.');
          this.router.navigate(['/admin']);
        }
        tempPerms.forEach(perm => {
          if (!this.permissionsArr.includes(perm)) {
            this.adminPermissions[perm] = false;
          }
        });
      } else {
        // Error
        let message = (res.error) ? res.error.message : 'Error in getting permissions';
        console.log(message);
      }
      this.getAppointmentDetails();
    });
  }

  // for navigating to TMS Forms
  navigateTmsForms(patId) {
    this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.TMS_FORMS}/${patId}`], { queryParams: { appId: this.appointmentId } });
  }

  // for TMS review/edit/resend mail
  tmsClick(patId, status) {
    if (status === 'edit' || status === 'review') {
      this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.TMS_FORMS}/${status}/${patId}`], { queryParams: { appId: this.appointmentId } });
    } else {
      let payload = {
        type: 'GET',
        url: 'resendTMS',
        isDeveloper: true,
        pathVariables: [patId]
      };
      this.http.makeHttpRequest(payload).subscribe((res) => {
        if (this.http.isSuccessfulResponse(res)) {
          // console.log('Response', res);
          this.observable.showToast(TOAST_STATUSES.SUCCESS, 'Email has been sent to the patient successfully.');
        } else {
          let message = 'Failed to send the email. Please try again';
          message = (res.error) ? res.error.message : message;
          this.observable.showToast(TOAST_STATUSES.ERROR, message);
        }
      });
    }
  }

  /**
   * Navigate to Ketamine Monitoring form
   */
  openKetamineMonitoringForms(){
    this.router.navigate([ROUTE_PATHS.ADMIN,ROUTE_PATHS.ADMIN_KETAMINE_MONITORING_FORM,this.appointmentId])
  }
}
