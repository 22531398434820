import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-tab-view',
  templateUrl: './tab-view.component.html',
  styleUrls: ['./tab-view.component.scss']
})
export class TabViewComponent implements OnInit {
  @Input() tabsData;
  @Output() selectedTab: EventEmitter<any> = new EventEmitter();

  // @Input() tabsData we need to give as belowe mentioned.



  constructor(private router: Router) { }

  ngOnInit() {
  }

  // This will emit the selected tab data and set the selected tab as true remaining as false
  onSelectedTab(tab) {
    console.log(this.tabsData, 'Tabs Data');
    if (tab && typeof (tab) === 'object') {
      this.selectedTab.emit(tab);
    }
  }

}
