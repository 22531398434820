<section class="patient__list--section email-templating-section">
    <div class="patient__list-item user" (click)="navigateDetailsPage(templateDetails)">
        <app-svg class="patients__icon" [name]="'detail'"></app-svg>
        <div class="patients__list-text" *ngIf="templateDetails.emailName">{{templateDetails.emailName}}</div>
        <div class="patients__list-text" *ngIf="!templateDetails.emailName">---</div>
    </div>
    <div class="patient__list-item patient-id" (click)="navigateDetailsPage(templateDetails)">
        <ng-container *ngIf="templateDetails.id != '13'">
            <app-svg class="patients__icon" [name]="'email_frequency'"></app-svg>
        <div class="patients__list-text" *ngIf="templateDetails.frequency">{{templateDetails.frequency}} Days</div>
        <div class="patients__list-text" *ngIf="!templateDetails.frequency">---</div>
        </ng-container>
        
    </div>
    <div class="patient__list-item mobile" (click)="navigateDetailsPage(templateDetails)">
        <ng-container *ngIf="templateDetails.id != '13'">
            <app-svg class="patients__icon" [name]="'repeat-frequency'"></app-svg>
            <div class="patients__list-text" *ngIf="templateDetails.repeatFrequency">{{templateDetails.repeatFrequency}} Days</div>
            <div class="patients__list-text" *ngIf="!templateDetails.repeatFrequency">---</div>
        </ng-container>
    </div>
    <div class="patient__list-item email" (click)="navigateDetailsPage(templateDetails)">
        <ng-container *ngIf="templateDetails.id != '13'">
            <app-svg class="patients__icon" [name]="'limit_duration'"></app-svg>
            <div class="patients__list-text" *ngIf="templateDetails.sendingTime && templateDetails.id != '12'">{{ templateDetails.sendingTime | formatTime }}</div>
            <div class="patients__list-text" *ngIf="templateDetails.sendingTime && templateDetails.id == '12'">{{ templateDetails.sendingTime}} Mins</div>
            <div class="patients__list-text" *ngIf="!templateDetails.sendingTime">---</div>
        </ng-container>
    </div>
    <div class="patient__list-item email" (click)="navigateDetailsPage(templateDetails)">
        <ng-container *ngIf="templateDetails.id != '13'">
            <app-svg class="patients__icon" [name]="'exclude_patient_black'"></app-svg>
            <div class="patients__list-text">{{templateDetails.excludedEmails.length}}</div>
        </ng-container>        
    </div>
    
    <div class="patient__list-item icons">
        <!-- Exclude patients icon starts here -->
        <div class="edit" *ngIf="adminPermissions.TEMPLATE_EDIT" (click)=moreOptionClick($event,templateDetails.editAction,templateDetails)>
            <app-svg class="patients__icon edit" title="Edit" [name]="'edit'"></app-svg>
        </div>
        <div class="edit" *ngIf="adminPermissions.TEMPLATE_EDIT && !templateDetails.isEmailExcludable" (click)=moreOptionClick($event,templateDetails.excludeAction,templateDetails)>
            <app-svg class="patients__icon" title="Exclude Patients" [name]="'exclude_patient_action'"></app-svg>
        </div>
        <!-- Exclude patients icon Schedule ends here -->
    </div>
    
</section>

