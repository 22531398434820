import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TermsRoutingModule } from '@app/pages/terms/terms-routing.module';
import {TabViewModule} from 'primeng/tabview';

import { CommonAppModule } from '@app/common-app.module';
import { TermsWrapperComponent } from './terms-wrapper/terms-wrapper.component';
import { TermsContentComponent } from './terms-content/terms-content.component';

const DECLARATION_COMPONENTS = [
  TermsWrapperComponent,
  TermsContentComponent,
];

@NgModule({
  declarations: [
    ...DECLARATION_COMPONENTS,

  ],
  imports: [
    CommonModule,
    TabViewModule,
    CommonAppModule,
    TermsRoutingModule
  ],
  exports: []
})
export class TermsModule { }
