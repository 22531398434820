<section class="reschedule__wrapper">
    <div>
        <app-appointment-page *ngIf="availabiltyData" [rescheduleFormData]="rescheduleFormData"
            [availabiltyData]="availabiltyData" [isReshedule]="isReshedule" (typeOfTreatment)="changeStepper($event)"
            (rescheduleData)="getFormData($event)" [saveFormData]="saveFormData  ? resetSaveFormParity() : false">
        </app-appointment-page>
        <div *ngIf="availabiltyData && availabiltyData.showScreen" class="common-stepper-inner" class="btn-actions">
            <div class="cancel-btn" (click)="cancel()">Cancel</div>
            <div class="common-btn-style btn--inline" (click)="saveForm()">Submit</div>
        </div>
    </div>
</section>