import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BlogRoutingModule } from '@app/pages/blog/blog-routing.module';
import {TabViewModule} from 'primeng/tabview';

import { CommonAppModule } from '@app/common-app.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { BlogWrapperComponent } from './blog-wrapper/blog-wrapper.component';
import { BlogContentComponent } from './blog-content/blog-content.component';
import { BlogDetailsComponent } from './blog-details/blog-details.component';
import { BlogPaginationComponent } from './blog-pagination/blog-pagination.component';
import { BlogBannerComponent } from './blog-banner/blog-banner.component';

// for social media icons
import { ShareButtonsModule } from '@ngx-share/buttons';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Platform } from '@angular/cdk/platform';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';

const DECLARATION_COMPONENTS = [
  BlogWrapperComponent,
  BlogContentComponent,
  BlogDetailsComponent,
  BlogPaginationComponent,
  BlogBannerComponent,
];

@NgModule({
  declarations: [
    ...DECLARATION_COMPONENTS,

  ],
  imports: [
    CommonModule,
    TabViewModule,
    CommonAppModule,
    BlogRoutingModule,
    SlickCarouselModule,
    HttpClientModule,
    FontAwesomeModule,
    ShareButtonsModule
  ],
  exports: [],
  providers: [Platform, FaIconLibrary],
})
export class BlogModule { }
