import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { API_URLS } from '@app/common/constants/api-urls.constant';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';
import { IGetMethodPayload, IPostMethodPayload } from '@app/common/constants/typeInterfaces.constants';
import { HTTPMethods, TOAST_STATUSES } from '@app/common/constants/util.constant';
import { CognitoService } from '@app/common/services/cognito.service';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { DataService } from '@app/common/services/data.service';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';

@Component({
  selector: 'app-phq-questionnaire',
  templateUrl: './phq-questionnaire.component.html',
  styleUrls: ['./phq-questionnaire.component.scss']
})
export class PhqQuestionnaireComponent implements OnInit {
  [x: string]: any;
  header:string = `PHQ-9 Questionnaire`
  @Input() saveFormData;
  @Input() filledTMSDetails;
  @Input() isReview;
  @Output() submitEvent: EventEmitter<any> = new EventEmitter();
  formData: any;
  requestId : '';
  patientId : any;
  patientsList: Array<any>;
  patientData: Array<any>;
  adhdRequests: Array<any>;
  documentDetails: Record<string, any>;
  bodyData: Record<string, any>;

  constructor(private formService: CustomFormHandlerService, private dataService: DataService, private router:Router, private route: ActivatedRoute,private http:HttpService,private observableHelperService: ObservableHelperService,private cognitoService: CognitoService) { 
    this.route.queryParams.subscribe(queryParams =>{
      if(queryParams.requestId){
        this.requestId = queryParams.requestId;
        this.getRequestedDocumentDetails();
       }else if (queryParams.patientId){
        this.patientId = queryParams.patientId;
        this.getRequestId();
      }
    })
  }

  ngOnInit() {
     this.formData = this.formService.initForm(this.formService.formTypes.PH_QUESTIONNAIRE);
    this.formData.formInputs.forEach(form => {
      if (this.filledTMSDetails && this.filledTMSDetails.phQuestionnaire && form.key) {
        Object.keys(this.filledTMSDetails.phQuestionnaire).every(key => {
          if (key.toLowerCase() === form.key.toLowerCase()) {
            form.editable = this.isReview ? false : true;
            form.value = this.filledTMSDetails.phQuestionnaire[key];
            return false;
          }
          return true;
        });
      }
    });
  }
  getRequestId(){
    this.cognitoService.getUser().then(user => {
      this.getPatientsList(user.userId);
    });
  }
  ngAfterViewInit(): void {

  }
  getSliderValue(event) {
    console.log(event);
    let slider = event.slider;
    let index  = event.index;
  }

  getFormData(event) {
  
    if (event) {
      this.formService.phQuestionnaireFormConfig = this.assignFormValues(event, this.formService.phQuestionnaireFormConfig);
      this.dataService.setData(this.dataService.dataObject.PH_QUESTIONNAIRE, this.formService.phQuestionnaireFormConfig);
      if(this.requestId){
        this.submitPhqForm(event);
        return;
      }
      this.submitEvent.emit(event);
    }
  }
    /**
   * 
   * @param userId :User Id
   * This function is for getting list of patient
   */
     getPatientsList(userId){
      const payload:IGetMethodPayload = {
        type: HTTPMethods.GET,
        url: API_URLS.GET_PATIENT_LIST,
        isDeveloper: true,
        pathVariables: [userId]
      };
      this.http.makeHttpRequest(payload).subscribe((res) => {
        if (this.http.isSuccessfulResponse(res) && res) {
          this.patientsList = res.data.patients;
          this.patientData = this.patientsList.filter(each => parseInt(each.patientId) === parseInt(this.patientId));
          if(this.patientData && this.patientData.length){
            this.adhdRequests = this.patientData[0].pendingDocumentRequest.filter(eachReq => eachReq.documentIds === '[16]');
            if(this.adhdRequests && this.adhdRequests.length){
              this.requestId = this.adhdRequests[0].requestId;
              this.getRequestedDocumentDetails();
            } else {
              this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.PHQ_REQUEST_NOT_FOUND_MSG);
              this.router.navigate([ROUTE_PATHS.ADD_MyACCOUNT]);
            }
          }
          console.log(this.patientData);
        }else{
          this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.PATIENT_LIST_ERROR_MSG);
        }
      });
    }
  submitPhqForm(data){
    if (data) {
      this.bodyData = data.value;
      delete this.bodyData[''];
      const payload: IPostMethodPayload = {
        type: HTTPMethods.POST,
        url: API_URLS.PHQ_QUESTIONNAIRE,
        pathVariables:[this.documentDetails.patientId,this.requestId],
        isDeveloper: true,
        body: this.bodyData,
      };
      this.http.makeHttpRequest(payload).subscribe((res) => {
        if (this.http.isSuccessfulResponse(res) && res) {
          this.observableHelperService.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.PHQ_QUESTIONNAIRE_SUCCESS_MSG);
          this.back();
        }else{
          let message = TOAST_MESSAGES.PHQ_QUESTIONNAIRE_ERROR_MSG;
          message = (res.error) ? res.error.message : message;
          this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
        }
      })
    }
  }
    // This function is for getting the request document details
    getRequestedDocumentDetails() {
      const payload:IGetMethodPayload = {
        type: HTTPMethods.GET,
        url: API_URLS.REQUEST_DOCUMENT_DETAILS,
        isDeveloper: true,
        pathVariables: [this.requestId],
      };
      this.http.makeHttpRequest(payload).subscribe((res) => {
        if (this.http.isSuccessfulResponse(res)) {
          this.documentDetails = res.data;
        } else {
          this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.DOCUMENT_DATA_REQUEST_ERROR_MSG);
        }
      });
    }
  assignFormValues(formData, formConfig) {
    let keys = Object.keys(formData.value);
    formConfig.forEach((each, index) => {
      if (keys.includes(each.key)) {
        each.value = formData.value[each.key];
        each.validators = this.formData.formGroup.controls[each.key].validator;
      }
    });
    return formConfig;
  }
  back(event?:MouseEvent){
    if(this.formData && this.formData.formGroup){
      this.formData.formGroup.reset();
    }
    this.router.navigate([ROUTE_PATHS.ADD_MyACCOUNT]);
  }
  saveForm(){
    this.saveFormData = true;
  }
}
