<!-- Notifications card-->
<div class="notification__card {{notificationData.readStatus}} {{notificationData.alert}} {{((notType === 'invoice' && !permissions.INVOICES) || (notType === 'appointment' && !permissions.APPOINTMENTS) || (notType === 'patient' && !permissions.PATIENTS)) ? 'disable' : ''}}" (click)="notificationRedirection(notificationData)">
    <div class="notification__card-icons-section">
        <div class="notification__card-alert-icon">
            <app-svg [name]="'alert'"></app-svg>
        </div>
    </div>
    <!-- <div class="notification__card-icons-section" [ngClass]="notifications.readStatus">
        <div class="notification__alert-icon" [ngClass]="(notificationData.type === 'cretical')">
            <app-svg [name]="'alert'"></app-svg>
        </div>
    </div> -->
    <div class="notification__card-dis">
        <div class="notification__card-title" *ngIf="notificationData.notificationType !=='invoice'">
            <span *ngIf="notificationData.notificationType==='patient'">{{notificationData.notificationAction === 'block_patient' ? '' : 'New '}}Patient </span>{{ notificationData.notificationsFullName | titlecase }}{{notificationData.notificationsSub}}
        </div>
        <div class="notification__card-title" *ngIf="notificationData.notificationType ==='invoice'">
            {{notificationData.notificationsSub}}
        </div>
        <div class="notification__card-basic-data" *ngIf="notificationData.notificationType==='appointment'">
            <p *ngIf='notificationData.locationInfo !== undefined'>{{notificationData.locationInfo.locationName | titlecase }}</p>
            <p *ngIf='notificationData.locationInfo === undefined'>---</p>
            <p> {{notificationData.appointmentTypeInfo.appointmentTypeName}}</p>
            <p>{{notificationData.apptDate | dateFormat}}</p>
            <p>{{notificationData.apptTime.split(':')[0] > 12 ? notificationData.apptTime.split(':')[0]-12+":"+notificationData.apptTime.split(':')[1] : notificationData.apptTime | slice:0:-3}} {{notificationData.apptTime.split(':')[0] >= 12 ? 'PM' : 'AM'}}</p>
        </div>
        <div class="notification__card-basic-data" *ngIf="notificationData.notificationType==='patient'">
            <p>{{notificationData.createTime | dateFormat}}</p>
            <p>{{notificationData.createTime | date:'shortTime'}}</p>
        </div>
        <div class="notification__card-basic-data" *ngIf="notificationData.notificationType==='invoice'">
            <p>{{notificationData.notificationTime | dateFormat}}</p>
            <p>{{notificationData.notificationTime | date:'shortTime'}}</p>
        </div>
    </div>
    <div class="notification__card-date">
        <p>{{notificationData.notificationTime | dateFormat}}</p>
        <p>{{notificationData.notificationTime | date:'shortTime'}}</p>
    </div>
</div>