import { Component, EventEmitter, HostListener, Input, NgZone, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { API_URLS } from '@app/common/constants/api-urls.constant';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';
import { convertDateToSpecifiedOffset, DEFAULT_TIME_ZONE_DIFF, HTTPMethods, TOAST_STATUSES } from '@app/common/constants/util.constant';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { DataService } from '@app/common/services/data.service';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { parse } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-appointment-booking-schedular',
  templateUrl: './appointment-booking-schedular.component.html',
  styleUrls: ['./appointment-booking-schedular.component.scss']
})
export class AppointmentBookingSchedularComponent implements OnInit {
  selectedSlot: any = {
    time: null,
    date: null,
    status: null
  };
  // ---Elem Outputs
  @Output() selectedCell: EventEmitter<any> = new EventEmitter();
  // ---Elem Inputs
  /**
   * set availbale weekend in schedular
   */
  @Input() setWeekendinDateArray = {
    saturday: true,
    sunday: false
  };
  @Input() timeRange;
  Location: any = 'ALX';
  shedularData: any;
  TypeofOffice: any;
  boldIndex: any;
  selectedDate: any = new Date('JUN 03 2020');
  calendarData: any;
  @Input() isReshedule;
  params: {};
  isConfirm: boolean = false;
  observableHelperService: any;
  @Input() set availabiltyData(value: any) {
    if (value) {
      this.calendarData = value;
      this.populateCellData();
    }
  }
  @Input() set checkFormInfo(value: any) {
    if (value) {
      this.actionField();
    }
  }
  @Input() set typeofOffice(type: any) {
    if (type) {
      this.TypeofOffice = type;
    }

  }
  @Input() time;
  @Input() set location(loc: any) {
    if (loc) {
      this.Location = loc.value;
    }
  }
  dateArray: Date[] = [];
  timeArray: string[] = [];
  currentDate: Date = new Date();
  today: Date = new Date();
  maxDateIndex = 4;
  showCurrentdateIndex = 0;
  showCurrenttimeRangeIndex = 0;

  rangeIndexLimitor = {
    dateIndexLimit: null,
    timeIndexLimit: null
  };

  dataRange = {
    dateDataRange: 8,
    timeDataRange: 9
  };
  selectedtabIndex = {
    i: null,
    j: null
  };
  cellArray = [];
  selectedDateIndex: any;
  announcementBannerVal: any;
  tempDateArray = [];

  selectedTimeIndex: number;
  bookingData: any;
  @Output() submitEvent: EventEmitter<any> = new EventEmitter();

  constructor(private dataService: DataService, private http: HttpService, private observable: ObservableHelperService, private route: ActivatedRoute) {
    this.params = this.route.snapshot.queryParams;
    if (Object.keys(this.params).length > 0) {
      this.isConfirm = true;
    }
  }

  ngOnInit() {
    let preData = this.dataService.getData(this.dataService.dataObject.PRE_APPOINTMENT);
    this.selectedSlot = (preData !== undefined) ? preData.selectedSlot : this.selectedSlot;
    this.showCurrenttimeRangeIndex = (preData !== undefined) ? preData.timeIndex : this.showCurrenttimeRangeIndex;
    this.showCurrentdateIndex = (preData !== undefined && preData.dateIndex) ? preData.dateIndex : this.showCurrentdateIndex;
    this.maxDateIndex = Math.floor(this.calendarData.availability.calendar.length / this.dataRange.dateDataRange);
    let data = this.observable.getAppointmentAddPatient();
    let bookingData = (data.value) ? this.dataService.getPreviousData(this.dataService.dataObject.APPOINTMENT_DETAIL) : this.dataService.getData(this.dataService.dataObject.APPOINTMENT_DETAIL);
    this.selectedSlot = (bookingData !== undefined) ? bookingData.shedularData.selectedSlot : this.selectedSlot;
    this.selectedDate = (bookingData !== undefined) ? bookingData.shedularData.selectedSlot.date : this.selectedDate;
    this.timeRange = (bookingData !== undefined && bookingData.timeRange) ? bookingData.timeRange : this.timeRange;
    this.showCurrenttimeRangeIndex = (bookingData !== undefined) ? bookingData.shedularData.timeIndex : this.showCurrenttimeRangeIndex;
    this.showCurrentdateIndex = (bookingData !== undefined) ? bookingData.shedularData.dateIndex : this.showCurrentdateIndex;
    this.timeArray = this.getTimeRange();
    if (bookingData !== undefined) {
      let startingData = bookingData.shedularData.dateArray;
      this.updateDateData(new Date(startingData), true);
    } else if (preData !== undefined && preData.startDate) {
      this.updateDateData(new Date(preData.startDate), true);
    } else {
      this.updateDateData(new Date(convertDateToSpecifiedOffset(DEFAULT_TIME_ZONE_DIFF, this.calendarData.timestamp)), true);
    }

    this.customizeSchedular(window.innerWidth);
    this.populateCellData();

    this.rangeIndexLimitor.timeIndexLimit = (this.timeArray.length % this.dataRange.timeDataRange) === 0 ? (Math.floor(this.timeArray.length / this.dataRange.timeDataRange) - 1 ) : Math.floor(this.timeArray.length / this.dataRange.timeDataRange);

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.customizeSchedular(event.target.innerWidth);
  }
  customizeSchedular(innerWidth) {
    let width = innerWidth;
    if (width <= 1024 && width > 768) {
      this.dataRange.dateDataRange = 6;
      this.maxDateIndex = (this.calendarData.availability.calendar.length === 40) ? Math.floor(this.calendarData.availability.calendar.length / this.dataRange.dateDataRange) + 1 : Math.floor(this.calendarData.availability.calendar.length / this.dataRange.dateDataRange) + 2;
    } else if (width <= 786 && width > 550) {
      this.dataRange.dateDataRange = 5;
      this.maxDateIndex = (this.calendarData.availability.calendar.length === 40) ? Math.floor(this.calendarData.availability.calendar.length / this.dataRange.dateDataRange) + 1 : Math.floor(this.calendarData.availability.calendar.length / this.dataRange.dateDataRange) + 3;
    } else if (width <= 550) {
      this.dataRange.dateDataRange = 4;
      this.maxDateIndex = (this.calendarData.availability.calendar.length === 40) ? Math.floor(this.calendarData.availability.calendar.length / this.dataRange.dateDataRange) + 2 : Math.floor(this.calendarData.availability.calendar.length / this.dataRange.dateDataRange) + 4;
    }
    this.populateCellData();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.maxDateIndex = (this.calendarData.availability.calendar.length === 40) ? Math.floor(this.calendarData.availability.calendar.length / this.dataRange.dateDataRange) : Math.floor(this.calendarData.availability.calendar.length / this.dataRange.dateDataRange);
    this.customizeSchedular(window.innerWidth);
    this.showCurrentdateIndex = 0;
    this.showCurrenttimeRangeIndex = 0;
    this.selectedDate = new Date('JUN 03 2020');
    let data = this.observable.getAppointmentAddPatient();
    let bookingData = (data.value) ? this.dataService.getPreviousData(this.dataService.dataObject.APPOINTMENT_DETAIL) : this.dataService.getData(this.dataService.dataObject.APPOINTMENT_DETAIL);
    this.selectedSlot = (bookingData !== undefined && bookingData.shedularData != null) ? bookingData.shedularData.selectedSlot : this.selectedSlot;
    this.showCurrenttimeRangeIndex = (bookingData !== undefined && bookingData.shedularData !== null) ? bookingData.shedularData.timeIndex : 0;
    this.showCurrentdateIndex = (bookingData !== undefined && bookingData.shedularData !== null) ? bookingData.shedularData.dateIndex : 0;
    this.timeArray = this.getTimeRange();
    this.rangeIndexLimitor.timeIndexLimit = (this.timeArray.length % this.dataRange.timeDataRange) === 0 ? (Math.floor(this.timeArray.length / this.dataRange.timeDataRange) - 1 ) : Math.floor(this.timeArray.length / this.dataRange.timeDataRange);
    if (changes.availabiltyData) {
      if (bookingData !== undefined && bookingData.typeAppointmentData != null) {
        if (this.TypeofOffice.value === bookingData.typeAppointmentData[0].value && this.Location === bookingData.typeAppointmentData[1].value && this.time.value === bookingData.typeAppointmentData[2].value) {
          this.selectedSlot = (bookingData !== undefined && bookingData.shedularData != null) ? bookingData.shedularData.selectedSlot : this.selectedSlot;
        }
      } else {
        this.selectedSlot = {
          time: null,
          date: null,
          status: null
        };
      }
    }
    if (bookingData !== undefined && bookingData.shedularData !== null) {
      let startingData = bookingData.shedularData.dateArray;
      this.updateDateData(new Date(startingData), true);
    } else {
      this.updateDateData(new Date(convertDateToSpecifiedOffset(DEFAULT_TIME_ZONE_DIFF, this.calendarData.timestamp)), true);
    }

    this.populateCellData();
  }

  fetchDateInParticularRange(array, range, stepper) {
    let content = [];
    array.forEach((element, index) => {
      if (index >= stepper * range && index < (range * (stepper + 1))) {
        let time = element.split(' ');
        let d = time[0].split(':');
        if (time[1] === 'AM' || d[0] === '12') {
          let c = time[0].split(':');
          // tslint:disable-next-line: radix
          if (parseInt(c[0]) < 10) {
            c = `0${c[0].toString()}:${c[1].toString()}:00`;
          } else {
            c = `${c[0].toString()}:${c[1].toString()}:00`;
          }
          element = c;
        } else if (time[1] === 'PM') {
          let c = time[0].split(':');
          // tslint:disable-next-line: radix
          c[0] = parseInt(c[0]) + 12;
          c = `${c[0].toString()}:${c[1].toString()}:00`;
          element = c;
        }
        content.push(element);
      }
    });
    return content;
  }

  populateCellData() {
    let tempdateArray = this.tempDateArray;
    let temptimeArray = this.fetchDateInParticularRange(this.timeArray, this.dataRange.timeDataRange, this.showCurrenttimeRangeIndex);
    this.cellArray = [];
    for (var i = 0; i < temptimeArray.length; i++) {
      let cellLine = [];
      for (var j = 0; j < tempdateArray.length; j++) {
        cellLine.push({
          time: temptimeArray[i],
          date: tempdateArray[j],
          status: this.getStatus(temptimeArray[i], tempdateArray[j], i, j)
        });
      }
      this.cellArray.push(cellLine);
    }
    this.getSelectedStatus();
  }

  formatDate(date) {
    var d = new Date(date);
    var month = '' + (d.getMonth() + 1);
    var day = '' + d.getDate();
    var year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('-');
  }

  getStatus(time, date, row, col) {
    let dateString: any = `${this.formatDate(date)}T${time}`;
    dateString = dateString.split(/[^0-9]/);
    let cDate = new Date(dateString[0], dateString[1] - 1, dateString[2], dateString[3], dateString[4], dateString[5]);
    date = this.formatDate(date);
    let days = this.calendarData.availability.calendar;
    let status;
    days = days.filter(day => day.date === date);
    if (days.length > 0) {
      days = days[0].slots.filter(t => t.time === time);
      status = days[0].available;
    } else {
      status = false;
    }
    let currentDate = new Date(convertDateToSpecifiedOffset(DEFAULT_TIME_ZONE_DIFF, this.calendarData.timestamp));
    let buffer = (this.isReshedule) ? 1 : 1;
    currentDate = new Date(currentDate.setHours(currentDate.getHours() + buffer));
    if (cDate.valueOf() > currentDate.valueOf()) {
      if (status) {
        return 'available';
      } else {
        return 'booked';
      }
    } else {
      return 'booked';
    }
  }
  getSelectedStatus() {
    let selectedDateData = { ...this.selectedSlot };
    let newDate: any = new Date(selectedDateData.date);
    selectedDateData.date = newDate;
    if (selectedDateData.date) {
      this.cellArray.forEach(each => {
        each.forEach(e => {
          if (e.date.getDate() === selectedDateData.date.getDate() && e.date.getMonth() === selectedDateData.date.getMonth() && e.time === selectedDateData.time) {
            e.status = selectedDateData.status;
          }
        });
      });
    }
  }

  cellClicked(cell) {
    this.cellArray.forEach(each => {
      each.forEach(e => {
        if (e.status !== 'booked') {
          if (e.date.getDate() === cell.date.getDate() && e.time === cell.time) {
            e.status = 'selected';
          } else {
            e.status = 'available';
          }
        }
      });
    });
    this.selectedSlot = { ...cell };
    this.selectedDate = this.selectedSlot.date;
    if (this.selectedSlot.time != null && this.selectedSlot.date != null && this.selectedSlot.status != null) {
      this.selectedCell.emit({ ...this.selectedSlot });
    }
  }

  getDaysInMonthUTC(month, year) {
    var date = new Date(Date.UTC(year, month, 1));
    var days = [];
    while (date.getUTCMonth() === month) {
      days.push(new Date(date));
      date.setUTCDate(date.getUTCDate() + 1);
    }
    return days;
  }

  /**
   *
   * @param data represents the last or intial date
   * @param action shows the action (forward and backward)
   */
  updateDateData(date: Date, isIncrement) {
    let days = [];
    var i = 0;
    while (i < this.dataRange.dateDataRange && this.showCurrentdateIndex <= this.maxDateIndex) {
      if (!isIncrement) {
        if (((date.getMonth() === this.currentDate.getMonth()) && (date.getDate() === this.currentDate.getDate())) && (i === 0)) {
          break;
        }
      }
      days.push(new Date(date));
      date.setUTCDate(isIncrement ? date.getUTCDate() + 1 : date.getUTCDate() - 1);
      i++;
    }

    if (days.length > 0) {
      if (!isIncrement) {
        days.reverse();
      }
      this.tempDateArray = days;
    }
  }

  checkDateInRange(index, range) {
    return index >= (this.showCurrentdateIndex * range) && index < range * (this.showCurrentdateIndex + 1) ? true : false;
  }

  checkTimeInRange(index, range) {
    return index >= (this.showCurrenttimeRangeIndex * range) && index < range * (this.showCurrenttimeRangeIndex + 1) ? true : false;
  }

  getTimeRange() {
    let timeStart: any = this.timeRange.start + '';
    let timeEnd: any = this.timeRange.end + '';
    var start = new Date(); // get a date object
    var end = new Date();
    var interval = this.TypeofOffice.duration;
    start.setHours(timeStart.split(':')[0], timeStart.split(':')[1], 0, 0);
    let tempArr = []; let actualStart; let flag = true; let tempStart = new Date(); let tempEnd = new Date();
    tempStart.setHours(6, 30, 0, 0); tempEnd.setHours(23, 30, 0, 0);
    // tslint:disable-next-line:radix
    let tempDuration = parseInt(interval);
    while (tempStart <= tempEnd) {
      tempArr.push(new Date(tempStart));
      tempStart.setMinutes(tempStart.getMinutes() + tempDuration);
    }
    // tempArr = tempArr.map(time => time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric'}));
    tempArr.forEach(time => {
      if (time >= start && flag) {
        flag = false;
        actualStart = time;
      }
    });
    console.log(actualStart);
    end.setHours(timeEnd.split(':')[0], timeEnd.split(':')[1], 0, 0); // reassign it to today's midnight
    var date = actualStart.getDate();
    var timeArr = [];
    while (actualStart < end) {
      var hours: any = actualStart.getHours();
      var minute: any = actualStart.getMinutes();
      hours = hours === 0 ? 12 : hours; // if it is 0, then make it 12
      var ampm = 'AM';
      ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours > 12 ? hours - 12 : hours; // if more than 12, reduce 12 and set am/pm flag
      hours = ('' + hours).slice(-2); // pad with 0
      minute = ('0' + actualStart.getMinutes()).slice(-2); // pad with 0
      timeArr.push(hours + ':' + minute + ' ' + ampm);
      actualStart.setMinutes(actualStart.getMinutes() + interval); // increment by 5 minutes
    }
    return timeArr;
  }
  setTwelveTime(start) {
    return start > 12 ? start - 12 : start;
  }
  setTime(min) {
    return min < 10 ? '0' + min : min;
  }

  schedularAction(indexRef, limiter?, schedularAction?) {
    if (schedularAction) {
      this.showCurrenttimeRangeIndex = (this.showCurrenttimeRangeIndex === limiter) ? this.rangeIndexLimitor.timeIndexLimit : this.showCurrenttimeRangeIndex + 1;
    } else {
      this.showCurrenttimeRangeIndex = (this.showCurrenttimeRangeIndex === 0) ? 0 : this.showCurrenttimeRangeIndex - 1;
    }
    this.populateCellData();
  }

  changeDateData(isIncrement, limiter?) {
    if (isIncrement) {
      if (this.showCurrentdateIndex <= this.maxDateIndex - 1) {
        this.updateDateData(new Date(this.tempDateArray[this.tempDateArray.length - 1]), true);
        this.showCurrentdateIndex = this.showCurrentdateIndex + 1;
      }
    } else {
      this.showCurrentdateIndex = (this.showCurrentdateIndex === 0) ? 0 : this.showCurrentdateIndex - 1;
      if (this.showCurrentdateIndex <= this.maxDateIndex) {
        this.updateDateData(new Date(this.tempDateArray[0]), false);
      }
    }
    this.populateCellData();
  }
  actionField() {
    this.bookingData = {
      selectedSlot: this.selectedSlot,
      timeIndex: this.showCurrenttimeRangeIndex,
      dateIndex: this.showCurrentdateIndex,
      dateArray: this.tempDateArray[0]
    };
    this.submitEvent.emit(this.bookingData);
  }
}
