
    <div class="email__template--section">
        <div class="email__template--info">
            <div>
                <img src="assets/images/pngs/logo.png" alt="Logo" class="email__template--logo">
                <div class="email__template--main-info">
                    <div class="email__template-sub-info">
                        <div class="email__template--highlight-text">Welcome to</div>
                        <div class="email__template--name pb-10">New Tele Doc!</div>
                        <div class="pb-10">To activate your account, click on the link below</div>
                        <div><a href="http://www.newteledoc.com/activateaccount">http://www.newteledoc.com/activateaccount
                            </a></div>
                    </div>
                    <div class="email__template--right-side-image"><img src="assets/images/pngs/mobile.png" alt=""
                            class="email__template--mobile-image" /></div>
                </div>
            </div>

        </div>
        <app-template-footer></app-template-footer>
    </div>


