import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PrivacyRoutingModule } from '@app/pages/privacy/privacy-routing.module';
import {TabViewModule} from 'primeng/tabview';

import { CommonAppModule } from '@app/common-app.module';
import { PrivacyWrapperComponent } from './privacy-wrapper/privacy-wrapper.component';
import { PrivacyContentComponent } from './privacy-content/privacy-content.component';

const DECLARATION_COMPONENTS = [
  PrivacyWrapperComponent,
  PrivacyContentComponent,
];

@NgModule({
  declarations: [
    ...DECLARATION_COMPONENTS,

  ],
  imports: [
    CommonModule,
    TabViewModule,
    CommonAppModule,
    PrivacyRoutingModule
  ],
  exports: []
})
export class PrivacyModule { }
