<div class="outer" *ngIf="documentDetails">
    <app-breadcrumb [breadCrumbData]="breadCrumbData"></app-breadcrumb>
    <!-- <div class="title">Document Request Details</div> -->
    <!-- status will be passed by the parent- doc-req -->
    <div class="wrapper-block">
        <div class="small" class="Pending" *ngIf="documentDetails.status !== 'Completed'">{{documentDetails.status}}</div>
        <div class="small" class='Completed' *ngIf="documentDetails.status == 'Completed'">{{documentDetails.status}}</div>

        <div class="hori">
            <div class="verti">
                <div class="pat">Patient Name</div>
                <div class="value">{{documentDetails.firstName | titlecase}} {{documentDetails.lastName |
                    titlecase}}</div>
            </div>
            <div class="verti">
                <div class="pat">Patient Id</div>
                <div class="value">{{documentDetails.patientId}}</div>
            </div>
            <div class="verti">
                <div class="pat">Created Date</div>
                <div class="value">{{documentDetails.createTime | dateFormat}}</div>
            </div>
            <br>
        </div>
        <br>
        <br>
        <div class="hori">
            <!-- <div class="verti">
                <div class="pat">Assigner Name</div>
                <div class="value" *ngIf="documentDetails.createdBy !== 'ADMIN'">{{documentDetails.empfirstName | titlecase}}
                    {{documentDetails.emplastName | titlecase}}</div>
                    <div class="value" *ngIf="documentDetails.createdBy === 'ADMIN'">{{documentDetails.createdBy}}</div>
            </div> -->
            <div class="verti">
                <div class="pat">{{documentDetails.statusLabel}}</div>
                <div class="value">{{documentDetails.closureTime | dateFormat}}</div>
            </div>
            <div class="verti">
                <div class="pat">Requested Documents</div>
                <div class="value1">
                    <span *ngFor="let document of documentDetails.documents;let i=index">{{document.documentName.trim()}}<span *ngIf="i != documentDetails.documents.length-1">{{","}}&nbsp;</span>
                    </span></div>
            </div>
            <br>
        </div>
        <br>
        <br>
        <div class="hori">
            <div class="verti">
                <div class="pat">Admin Comments</div>
                <div class="value1" *ngIf="documentDetails.adminComments">{{documentDetails.adminComments}}</div>
                <div class="value1" *ngIf="!documentDetails.adminComments">---</div>
            </div>
            <!-- <div class="verti" *ngIf="documentDetails.patientComments != null">
                <div class="pat">Patient Comments</div>
                <div class="value1" *ngIf="documentDetails.patientComments">{{documentDetails.patientComments}}</div>
                <div class="value1" *ngIf="!documentDetails.patientComments">---</div>
            </div> -->
            <br>   
   
        </div>
        <br>
        <br>
        <div>
        <div class="hori documents_download-wrapper" *ngIf="documentDetails.documentURLs && documentDetails.documentURLs.length != 0">
            <div class="verti">
                <div class="pat">Patient Documents <span class="download" *ngIf="documentDetails.documentURLs.length != 0" (click)="downloadAll(documentDetails.documentURLs)">Download All</span></div>
                <div class="value" *ngIf="documentDetails.documentURLs.length == 0">No documents available</div>
                <div class="value documents_wrapper">
                    <span class="document-block each_document" *ngFor="let document of documentDetails.documentURLs">
                        <div class="each_document-name">
                            <p class="">{{document.fileName | ellipsis: 25}}</p>
                        </div>
                        <div class="each_document-actions">
                            <a [href]="document.url" target="_blank" title="View" class="view-icon">
                                <app-svg [name]="'view-upload'"></app-svg>
                            </a>
                            <p class="down-arrow" (click)="downloadImage(document.url)"
                                title="Download">
                                <app-svg [name]="'down-arrow'"></app-svg>
                            </p>
                        </div>
                    </span>
                </div>
            </div>
        </div>
        <div *ngIf="documentDetails.documentURLs && documentDetails.documentURLs.length != 0">
            <hr>
            <br>
        </div>
        <div class="document_download-wrapper" *ngIf="documentDetails.adminDocumentURLs && documentDetails.adminDocumentURLs.length != 0 && documentDetails.status !=='Completed'">
            <div class="verti">
                <div class="pat">Clinic Documents <span class="download" *ngIf="documentDetails.adminDocumentURLs.length != 0" (click)="downloadAll(documentDetails.adminDocumentURLs)">Download All</span></div>
                <div class="value" *ngIf="documentDetails.adminDocumentURLs.length == 0">No documents available</div>
                <div class="value documents_wrapper">
                    <span class="document-block each_document" *ngFor="let document of documentDetails.adminDocumentURLs">
                        <div class="each_document-name">
                            <p class="">{{document.fileName | ellipsis: 25}}</p>
                        </div>
                        <div class="each_document-actions">
                            <a [href]="document.url" target="_blank" title="View" class="view-icon">
                                <app-svg [name]="'view-upload'"></app-svg>
                            </a>
                            <p class="down-arrow" (click)="downloadImage(document.url)"
                                title="Download">
                                <app-svg [name]="'down-arrow'"></app-svg>
                            </p>
                        </div>
                    </span>
                </div>
            </div>
        </div>
        <div class="document_download-wrapper" *ngIf="documentDetails.signedDocuments && documentDetails.signedDocuments.length != 0 && documentDetails.status ==='Completed'">
            <div class="verti">
                <div class="pat">Clinic Documents <span class="download" *ngIf="documentDetails.signedDocuments.length != 0" (click)="downloadAll(documentDetails.signedDocuments)">Download All</span></div>
                <div class="value" *ngIf="documentDetails.adminDocumentURLs.length == 0">No documents available</div>
                <div class="value documents_wrapper">
                    <span class="document-block each_document" *ngFor="let document of documentDetails.signedDocuments">
                        <div class="each_document-name">
                            <p class="">{{document.fileName | ellipsis: 25}}</p>
                        </div>
                        <div class="each_document-actions">
                            <a [href]="document.url" target="_blank" title="View" class="view-icon">
                                <app-svg [name]="'view-upload'"></app-svg>
                            </a>
                            <p class="down-arrow" (click)="downloadImage(document.url)"
                                title="Download">
                                <app-svg [name]="'down-arrow'"></app-svg>
                            </p>
                        </div>
                    </span>
                </div>
            </div>
        </div>
        <div class="document_download-wrapper" *ngIf="documentDetails.finalDocuments && documentDetails.finalDocuments.length != 0 && documentDetails.status !== 'Completed' && documentDetails.adminDocumentURLs.length === 0">
            <div class="verti">
                <div class="pat">Clinic Documents <span class="download" *ngIf="documentDetails.finalDocuments.length != 0" (click)="downloadAll(documentDetails.finalDocuments)">Download All</span></div>
                <div class="value" *ngIf="documentDetails.finalDocuments.length == 0">No documents available</div>
                <div class="value documents_wrapper">
                    <span class="document-block each_document" *ngFor="let document of documentDetails.finalDocuments">
                        <div class="each_document-name">
                            <p class="">{{document.fileName | ellipsis: 25}}</p>
                        </div>
                        <div class="each_document-actions">
                            <a [href]="document.url" target="_blank" title="View" class="view-icon">
                                <app-svg [name]="'view-upload'"></app-svg>
                            </a>
                            <p class="down-arrow" (click)="downloadImage(document.url)"
                                title="Download">
                                <app-svg [name]="'down-arrow'"></app-svg>
                            </p>
                        </div>
                    </span>
                </div>
            </div>
        </div>
        <div class="verti" *ngIf="documentDetails.status === 'Response Pending For Admin Comments'"> 
            <div class="sts_respose">Response To Admin Comments</div>
            <div class="text-area-block">
                <textarea  
                class="text-area input" type="textarea" [attr.maxLength]="150"
                value="" placeholder="Enter details here"
                (input)="textCounter($event)"
                [(ngModel)]="otherValued"></textarea>
                <input disabled
                    class="textarea-range-show" maxlength="3" size="3"
                    value="{{remainingValue}} remaining" id="remaining">
                    <div class="error-msg days other"><span *ngIf="showOtherError">Please enter the details, if you specify others</span></div>
            </div>
            </div>
        </div>
        <div class="hori" *ngIf="documentDetails.status === 'Document Under Review Pending For Approval'">
            <div class="text_class">If any changes required click on the changes button, If not click on confirm to proceed for the document</div>
        </div>
        <div class="btns__block">
            <div class="hori">
                <button class="common-btn-style" (click)="conformComments()" *ngIf="documentDetails.status === 'Response Pending For Admin Comments'">Send Response</button>
                <button class="common-btn-style" (click)="conformRequest()" *ngIf="documentDetails.status === 'Document Under Review Pending For Approval'">Confirm</button>
                <button class="common-btn-style" (click)="sendChanges()" *ngIf="documentDetails.status === 'Document Under Review Pending For Approval'">Changes</button>
            </div>
        </div>
    </div>
</div>
<app-common-popup (showHideCancelPopup)='showPopupFun()' *ngIf="showPopup" class="popup-content-block">
    <div class="popup-content-block">
    <div>
        <div class="sts">Comments</div>
        <div class="text-area-block">
            <textarea  
            class="text-area input" type="textarea" [attr.maxLength]="350"
            value="" placeholder="Enter details here"
            (input)="textCounter($event)"
            [(ngModel)]="otherValue"></textarea>
            <input disabled
                class="textarea-range-show" maxlength="3" size="3"
                value="{{remainingValue}} remaining" id="remaining">
                <div class="error-msg days other"><span *ngIf="showOtherError">Please enter the details, if you specify others</span></div>
        </div>
    </div>
    <div class="btn-actions">
        <div class="common-btn-style yes-btn" (click)="popUpClick()">Cancel</div>
        <div class="common-btn-style" (click)="sendChangesData()">send</div>
    </div>
    </div>
</app-common-popup>
