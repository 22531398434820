import { Component, OnInit } from '@angular/core';
import { google } from 'google-maps';
@Component({
  selector: 'app-appointment-schedule',
  templateUrl: './appointment-schedule.component.html',
  styleUrls: ['./appointment-schedule.component.scss']
})
export class AppointmentScheduleComponent implements OnInit {

  constructor() { }
  options: any;
  overlays: any[];
  google: google;
  ngOnInit() {
    this.options = {
      center: { lat: 36.890257, lng: 30.707417 },
      zoom: 10
    };

    this.overlays = [
      new google.maps.Marker({ position: { lat: 36.879466, lng: 30.667648 }, title: 'Alexandria office' }),
    ];
  }

}
