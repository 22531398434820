<section class="appointment-detail-card__section" *ngIf="patientDetails">
    <div class="card">
        <div class="user-info card-svg">
            <app-svg [name]="'user'"></app-svg>
            <div class="label">{{patientDetails.firstName | titlecase}} {{patientDetails.lastName | titlecase}}</div>
        </div>
        <div class="date-info last-action-svg">
            <app-svg [name]="'calendar'" *ngIf="patientDetails.lastAction.includes('Appt.')"></app-svg>
            <app-svg [name]="'detail'" *ngIf="!patientDetails.lastAction.includes('Appt.')"></app-svg>
            <div class="label">{{patientDetails.lastAction}}</div>
        </div>
        <div class="office-info appointment--svg" *ngIf="patientDetails.lastAppointment else elseBlock">
            <app-svg *ngIf="patientDetails.lastAppointment == 'Telemedicine'" [name]="'phone'"></app-svg>
            <app-svg *ngIf="patientDetails.lastAppointment != 'Telemedicine'" [name]="'treatement'"></app-svg>
            <div class="label">{{patientDetails.lastAppointment}}</div>
        </div>
        <ng-template #elseBlock>
            <div class="label empty">--</div>
        </ng-template>
        
        <div class="card-event" *ngIf="patientDetails.status === 'active'">
            <div class="cancel-appointment-info view-svg" [ngClass]="(patientDetails.isInactive || patientDetails.longTimeUpdate) ? 'disable book' : ''" (click)="viewOrEditMode(patientDetails.patientId,'view')">
                <app-svg [name]="'view-icon'"></app-svg>
                <div class="label">View</div>
            </div>
            <div class="cancel-appointment-info book-svg" [ngClass]="patientDetails.disable?'disable': (patientDetails.longTimeUpdate || patientDetails.isInactive || blockedAllServices || blockBookNow) ? 'disable book' : ''" (click)="bookAppointment(patientDetails)">
                <app-svg [name]="'addAppointment'"></app-svg>
                <div class="label">Book Now</div>
            </div>
            <div class="reschedule-info edit-svg" (click)="viewOrEditMode(patientDetails.patientId,'edit')" *ngIf="!(!patientDetails.disable && (!patientDetails.longTimeUpdate && !patientDetails.isInactive))">
                <app-svg [name]="'edit'"></app-svg>
                <div class="label" *ngIf="!patientDetails.disable"><span>{{!patientDetails.disable && (!patientDetails.longTimeUpdate && !patientDetails.isInactive) ? 'Edit' : (patientDetails.longTimeUpdate || patientDetails.isInactive) ? 'Modify' : 'Update'}}</span></div>
            </div>
            <div class="reschedule-info edit-svg more-img"  (clickOutside)="op.hide()" (click)="moreDisplay($event, op)" *ngIf="!patientDetails.disable && (!patientDetails.longTimeUpdate && !patientDetails.isInactive)">
                <app-svg class="more-icon" title="More" [name]="'list-more'"></app-svg>
                <!-- This will be uncommented after export build -->
                <p-overlayPanel #op [showTransitionOptions]="'100ms'" [hideTransitionOptions]="'100ms'" [appendTo]="'body'">
                    <div class="line"></div>
                    <div class="more__option" (click)="viewOrEditMode(patientDetails.patientId,'edit')">{{!patientDetails.disable && (!patientDetails.longTimeUpdate && !patientDetails.isInactive) ? 'Edit' : (patientDetails.longTimeUpdate || patientDetails.isInactive) ? 'Modify' : 'Update'}}</div>
                
                    <div class="line"></div>

                    <div class="more__option" (click)="navigatePatientForm(patientDetails, 'insurance')">Update Insurance Details</div>

                    <div class="line"></div>

                    <div class="more__option"  (click)="navigatePatientForm(patientDetails, 'pharmacy')">Update Pharmacy Details</div>

                    <div class="line"></div>

                    <div class="more__option" (click)="docuementRequest()">Request Docs</div>

                </p-overlayPanel>
            </div>
        </div>
        <div class="update__card" *ngIf="patientDetails.status === 'pending'">
            <div class="reschedule-info edit-svg" (click)="viewOrEditMode(patientDetails.patientId,'update')">
                <app-svg [name]="'edit'"></app-svg>
                <div class="label">Update</div>
            </div>
        </div>
    </div>
    <div class="warning--block pendging-svg" *ngIf="patientDetails.isInactive">
        <app-svg [name]="'pending-alert'"></app-svg>
        <span>
            <span class="warning">Pending!&nbsp;</span>Please update your patient details to proceed with appointment bookings. These updates are requested because there are no appointments booked in the past 8 months.
        </span>
    </div>
    <div class="warning--block pendging-svg" *ngIf="patientDetails.longTimeUpdate && !patientDetails.isInactive">
        <app-svg [name]="'pending-alert'"></app-svg>
        <span>
            <span class="warning">Pending!&nbsp;</span>Please update your patient details to proceed with appointment bookings. These updates are requested every 2 years from the date of your onboarding.<!--&nbsp;<span class="tms--click" (click)="viewOrEditMode(patientDetails.patientId,'edit')">Yes</span>&nbsp;<span class="tms--click" (click)="patientUpdateDetails()">No</span> -->
        </span>
    </div>
    <div class="warning--block pendging-svg tms" *ngIf="patientDetails.tmsFill || patientDetails.pendingTMS">
        <app-svg [name]="'pending-alert'"></app-svg>
        <span>
            <span class="warning">Pending!&nbsp;</span>Please provide your health & medication details before your TMS Appointment Date.&nbsp;<span class="tms--click" (click)="navigateTmsForms(patientDetails.pendingTMS)">Click here</span> 
        </span>
    </div>
    <div class="warning--block pendging-svg tms" *ngIf="patientDetails.cvsChange">
        <app-svg [name]="'pending-alert'"></app-svg>
        <span>
            <span class="warning">Pending!&nbsp;</span>Please update your pharmacy, as Dr.Prayaga is not sending prescriptions to CVS and Walmart pharmacies.&nbsp;<span class="tms--click" (click)="navigatePatientForm(patientDetails, 'pharmacy')">Click here</span> 
        </span>
    </div>
    <div class="warning--blocks pendging-svg tms" *ngIf="patientDetails.pendingDocumentRequest.length">
        <div class="warning--block pendging-svg tms" > 
            <app-svg [name]="'pending-alert'"></app-svg>
            <span>
                <span *ngIf="patientDetails.pendingDocumentRequest[0].documentIds != '[1]' && patientDetails.pendingDocumentRequest[0].documentIds != '[9]' && patientDetails.pendingDocumentRequest[0].documentIds != '[15]' && patientDetails.pendingDocumentRequest[0].documentIds != '[16]'&& patientDetails.pendingDocumentRequest[0].documentIds != '[23]'  && patientDetails.pendingDocumentRequest[0].requestType==='DOCUMENT_REQUEST' "><span class="warning">Pending!&nbsp;</span>To view & submit the requested general documents.&nbsp;<span class="tms--click"  (click)="navigateDocumentReqestPage(patientDetails)">Click here</span></span>
                <span *ngIf="patientDetails.pendingDocumentRequest[0].requestType==='LAB_REQUEST'"><span class="warning">Pending!&nbsp;</span>To view & submit the requested lab documents.&nbsp;<span class="tms--click"  (click)="navigateDocumentReqestPage(patientDetails)">Click here</span></span>
                <span *ngIf="patientDetails.pendingDocumentRequest[0].documentIds == '[1]' && patientDetails.pendingDocumentRequest[0].requestType==='DOCUMENT_REQUEST'"><span class="warning">Pending!&nbsp;</span>To view & submit the ADHD questionnaire. &nbsp;<span class="tms--click" (click)="navigateADHDQuestionnaire(patientDetails)">Click here</span> </span>
                <span *ngIf="patientDetails.pendingDocumentRequest[0].documentIds == '[9]' && patientDetails.pendingDocumentRequest[0].requestType==='DOCUMENT_REQUEST'"><span class="warning">Pending!&nbsp;</span>To view & submit the therapist information request. &nbsp;<span class="tms--click" (click)="navigateToTherapistInfoRequest(patientDetails)">Click here</span> </span>
                <span *ngIf="patientDetails.pendingDocumentRequest[0].documentIds == '[15]' && patientDetails.pendingDocumentRequest[0].requestType==='DOCUMENT_REQUEST'"><span class="warning">Pending!&nbsp;</span>To view & submit the INFN Req. &nbsp;<span class="tms--click" (click)="navigateToINFNAPPT(patientDetails)">Click here</span> </span>
                <span *ngIf="patientDetails.pendingDocumentRequest[0].documentIds == '[16]' && patientDetails.pendingDocumentRequest[0].requestType==='DOCUMENT_REQUEST'"><span class="warning">Pending!&nbsp;</span>To view & submit the PHQ questionnaire. &nbsp;<span class="tms--click" (click)="navigatePHQQuestionnaire(patientDetails)">Click here</span> </span>
            </span>
            <br>
        </div>
        <div class="tms--click more-documets" *ngIf="patientDetails.pendingDocumentRequest.length > 1" (click)="viewMoreRequestPopup()">+{{patientDetails.pendingDocumentRequest.length-1}} More Requests</div> 
    </div>
</section>
<!-- <app-common-popup (showHideCancelPopup)='makeAsPaidPopupShowHideStatusFun($event)' *ngIf="makeAsPaidPopupShowHide">
    <div class="popup-content-block">
        <div class="confirm-message">Are you sure you don't want to update the details?</div>
        <div class="btn-actions">
            <div class="common-btn-style yes-btn" (click)="popUpClick(patientDetails.patientId,false)">No</div>
            <div class="common-btn-style" (click)="popUpClick(patientDetails.patientId,true)">Yes</div>
        </div>
    </div>
</app-common-popup> -->
<app-common-popup (showHideCancelPopup)='documentsRequestPopupStatusFun($event)' *ngIf="displayDocumentsRequest && documentsRequest.length">
    <app-patient-documents-request [patientId]="patientId" [isPatient]="true" [documentsRequest]="documentsRequest" (closePopup)="closeDocumentRequestPopup($event)"></app-patient-documents-request>
</app-common-popup>
<app-multiple-document-request-view [requestDocumentsList]="patientDetails.pendingDocumentRequest" (showHideMyAccountPopup)='multipleDocumentRequestPopup($event)'
*ngIf="displayDocumentsRequestPopup"></app-multiple-document-request-view>