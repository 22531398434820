<section class="location__details--wrapper" *ngIf="isShowScreen && permissionsDone">
    <!--Right side background leaf starts here -->
    <div class="background-image">
        <img src="assets/images/pngs/newpatientform-background.png" alt="bg image">
    </div>
    <!-- Right side background leaf ends here -->

    <!-- Main section starts here -->
    <div class="location__details--main-wrapper content__wrapper">
        <!-- Breadcrumb section starts here -->
        <div class="location__details-navigation-menu">
            <app-breadcrumb [breadCrumbData]="breadCrumbData"></app-breadcrumb>
            <div class="location__details-primary-actions" *ngIf="adminPermissions.LOCATION_EDIT">
                <div class="common-btn-style" (click)="editLocation($event)">
                    Edit
                </div>
            </div>
        </div>
        <!-- Breadcrumb section ends here -->

        <!-- Tab view starts here -->
        <div class="location__tab-view">
            <app-tab-view [tabsData]='tabsData' (selectedTab)="selectedTab($event)"></app-tab-view>
        </div>
        <!-- Tab view ends here -->

        <!-- Details block starts here -->
        <div class="location__details-view" *ngIf="tabType === 'details'">
            <app-location-details [locationDetails]="locationDetails" [locationIdData]="locationIdData"></app-location-details>
        </div>
        <!-- Detaisl block ends here -->

        <!-- Calendar view starts here -->
        <div class="location__calendar--view" *ngIf="tabType !== 'details'">
            <app-admin [removeLocation]="isLocation"></app-admin>
        </div>
    </div>
</section>