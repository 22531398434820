import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { TOAST_STATUSES } from '@app/common/constants/util.constant';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { FormatTimePipe }  from '@app/common/pipes/format-time.pipe';

@Component({
  selector: 'app-email-templates-list',
  templateUrl: './email-templates-list.component.html',
  styleUrls: ['./email-templates-list.component.scss']
})
export class EmailTemplatesListComponent implements OnInit {
  @Input() templateDetails;
  @Input() adminPermissions;
  @Output() actionData = new EventEmitter<any>();
  constructor(private http: HttpService, private observableHelperService: ObservableHelperService, private router: Router) {
  }

  ngOnInit() {
  }

  // On clicking each list it will redirect to details page
  navigateDetailsPage(templateDetails) {
    this.router.navigate([ROUTE_PATHS.ADMIN, ROUTE_PATHS.EMAIL_TEMPLATES, ROUTE_PATHS.DETAILS, templateDetails.id]);
  }


  /**
   * @param action :action name
   * @param templateDetails :email details
   */
  moreOptionClick(event, action, templateDetails) {
    event.stopPropagation();
    if (action === 'exclude') {
      this.actionData.emit({ action: 'cancelpopup',cancelpopup: true, selectedTemplate: templateDetails });
    } else if(action == 'edit'){
      this.actionData.emit({ action: 'edit', selectedTemplate: templateDetails });
    }
  }

}
