import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnboardRoutingModule } from '@app/pages/onboard/onboard-routing.module';
import { OnboardWrapperComponent } from '@app/pages/onboard/onboard-wrapper/onboard-wrapper.component';
import { CommonAppModule } from '@app/common-app.module';

const DECLARATION_COMPONENTS = [
  OnboardWrapperComponent,
];

@NgModule({
  declarations: [
    ...DECLARATION_COMPONENTS,

  ],
  imports: [
    CommonModule,
    CommonAppModule,
    OnboardRoutingModule
  ],
  exports: []
})
export class OnboardModule { }
