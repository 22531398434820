import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { API_URLS } from '@app/common/constants/api-urls.constant';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';
import { IformDataI, IGetMethodPayload, IPostMethodPayload } from '@app/common/constants/typeInterfaces.constants';
import { HTTPMethods, TOAST_STATUSES } from '@app/common/constants/util.constant';
import { CognitoService } from '@app/common/services/cognito.service';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { each } from 'jquery';

@Component({
  selector: 'app-information-request',
  templateUrl: './information-request.component.html',
  styleUrls: ['./information-request.component.scss']
})
export class InformationRequestComponent implements OnInit {
  saveFormData: boolean = false;
  formData: IformDataI;
  patientId: any;
  requestId: any;
  documentDetails: any;
  patientsList: any;
  patientData: any;
  informationRequest: any;
  appointmentId: any;
  isShowForm: boolean = false;
  constructor(private cognitoService: CognitoService, private route: ActivatedRoute, private formService: CustomFormHandlerService, private router: Router, private observableHelperService: ObservableHelperService, private http: HttpService) {
    this.route.queryParams.subscribe(queryParams => {
      if (queryParams.requestId) {
        this.requestId = queryParams.requestId;
        this.getRequestedDocumentDetails();
      } else if (queryParams.patientId) {
        this.patientId = queryParams.patientId;
        this.appointmentId = queryParams.appointmentId;
        this.getRequestId();
      } else {
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.INFORMATION_REQUEST_NOT_FOUND_MSG);
        this.cancel();
      }
    });
   }

  ngOnInit() {
  }

  /**
   * fetch user Id
   */
   getRequestId() {
    this.cognitoService.getUser().then(user => {
      this.getPatientsList(user.userId);
    });
  }

  /**
   * @param userId :User Id
   * This function is for getting list of patient
   */
   getPatientsList(userId) {
    const payload: IGetMethodPayload = {
      type: HTTPMethods.GET,
      url: API_URLS.GET_PATIENT_LIST,
      isDeveloper: true,
      pathVariables: [userId]
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res) && res) {
        this.patientsList = res.data.patients;
        this.patientData = this.patientsList.filter(each => parseInt(each.patientId, 10) === parseInt(this.patientId, 10));
        if (this.patientData && this.patientData.length) {
          this.informationRequest = this.patientData[0].pendingDocumentRequest.filter(eachReq => eachReq.documentIds === '[15]');
          if (this.informationRequest && this.informationRequest.length) {
            this.requestId = this.informationRequest[0].requestId;
            this.getRequestedDocumentDetails();
          } else {
            this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.INFORMATION_REQUEST_NOT_FOUND_MSG);
            this.router.navigate([ROUTE_PATHS.ADD_MyACCOUNT]);
          }
        } else {
          this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.INFORMATION_REQUEST_NOT_FOUND_MSG);
          this.cancel();
        }
        console.log(this.patientData);
      } else {
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.PATIENT_LIST_ERROR_MSG);
      }
    });
  }

  // This function is for getting the request document details
  getRequestedDocumentDetails() {
    const payload: IGetMethodPayload = {
      type: HTTPMethods.GET,
      url: API_URLS.REQUEST_DOCUMENT_DETAILS,
      isDeveloper: true,
      pathVariables: [this.requestId],
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.documentDetails = res.data;
        this.appointmentId = JSON.parse(res.data.requestMetaData).appointmentId;
        let resData = JSON.parse(res.data.requestMetaData)
        if(resData.infnQuestions){
          let formFields = resData.infnQuestions;
          formFields = formFields.map(eachField =>{
            return {
              ...eachField,
              key: eachField.questionId,
              label: eachField.questionLabel,
              type: 'textarea',
              placeholder: '',
              maxLength: 500,
              required: true,
              value: '',
              controlType: 'textarea',
              emptyValueMessage: 'Please add description',
              validationValue: 'Please add valid description',
              validators: [],
              className: '',
              editable: true,
              count: '500 remaining',
              showontoggle: true,
              showRange: true,
            };
          });
          this.formService.informationRequestFormConfig = formFields;
          this.formData = this.formService.initForm(this.formService.formTypes.INFORMATION_REQUEST);
          this.isShowForm = true;
          console.log(formFields);
        }
        else{
          this.getDynamicFormFields();
        }
      } else {
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.INFORMATION_REQUEST_FAILED_ERROR_MSG);
      }
    });
  }

  /**
   * Get dynamic information request form fields
   */
  getDynamicFormFields() {
    const payload: IGetMethodPayload = {
      type: HTTPMethods.GET,
      url: API_URLS.INFORMATION_REQUEST_DYNAMIC_FORM,
      isDeveloper: true,
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        let formFields = res.data;
        formFields = formFields.map(eachField =>{
          return {
            ...eachField,
            key: eachField.questionId,
            label: eachField.questionLabel,
            type: 'textarea',
            placeholder: '',
            maxLength: 500,
            required: true,
            value: '',
            controlType: 'textarea',
            emptyValueMessage: 'Please add description',
            validationValue: 'Please add valid description',
            validators: [],
            className: '',
            editable: true,
            count: '500 remaining',
            showontoggle: true,
            showRange: true,
          };
        });
        this.formService.informationRequestFormConfig = formFields;
        this.formData = this.formService.initForm(this.formService.formTypes.INFORMATION_REQUEST);
        this.isShowForm = true;
        console.log(formFields);
      } else {
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.INFORMATION_REQUEST_FORM_FAILED_ERROR_MSG);
      }
    });
  }

  /**
   * This is for submitting the form data
   * @param event :Form Data
   */
  getFormData(event) {
    console.log(event);
    if (event) {
      const formData = event.value;
      const bodyData = {
        questions: this.formService.informationRequestFormConfig.map(eachField => {
          return {
            question: eachField.label,
            answer: formData[eachField.key]
          };
        })
      };
      console.log(bodyData, 'ZZZZ');
      const payload: IPostMethodPayload = {
        type: HTTPMethods.POST,
        url: API_URLS.INFORMATION_REQUEST,
        pathVariables: [this.documentDetails.patientId, this.appointmentId, this.requestId],
        isDeveloper: true,
        body: bodyData,
      };
      this.http.makeHttpRequest(payload).subscribe((res) => {
        if (this.http.isSuccessfulResponse(res) && res) {
          this.observableHelperService.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.INFORMATION_REQUEST_SUCCESS_MSG);
          this.cancel();
        } else {
          let message = TOAST_MESSAGES.INFORMATION_REQUEST_ERROR_MSG;
          message = (res.error) ? res.error.message : message;
          this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
        }
      });
    }
  }

    // this is for re-triggering the Input of feedback component
    resetSaveFormParity() {
      window.setTimeout(() => {
        this.saveFormData = false;
      }, 0);
      return true;
    }

    /**
   * submit button clicked action
   */
  saveForm() {
    this.saveFormData = true;
    setTimeout(() => {
      if (document.getElementsByClassName('invalid-feedback')[0]) {
        document.getElementsByClassName('invalid-feedback')[0].scrollIntoView({ block: 'center' });
      }
    }, 2);
  }

   /**
   * back to the previous page
   */
    cancel() {
      if (this.formData && this.formData.formGroup) {
        this.formData.formGroup.reset();
      }
      this.router.navigate([ROUTE_PATHS.ADD_MyACCOUNT]);
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
}
