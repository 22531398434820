import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { AppointmentDetailComponent } from './appointment-detail/appointment-detail.component';
import {AppointmentWrapperComponent} from './appointment-wrapper/appointment-wrapper.component';
import { ActivateGuard } from '@app/common/services/activate.guard';
import { TmsFormsWrapperComponent } from './tms-forms-wrapper/tms-forms-wrapper.component';
import { PatientDocumentRequestDetailsComponent } from '../admin/patient-document-request-details/patient-document-request-details.component';
import { AdhdQuestionnaireComponent } from './adhd-questionnaire/adhd-questionnaire.component';
import { PhqQuestionnaireComponent } from './phq-questionnaire/phq-questionnaire.component';
import { RequestTherapistInfoComponent } from './request-therapist-info/request-therapist-info.component';
import { MedicationFeedbackComponent } from './medication-feedback/medication-feedback.component';
import { InformationRequestComponent } from './information-request/information-request.component';
import { SubsentenceConsentComponent } from './subsentence-consent/subsentence-consent.component';
import { SubsentenceFormsWrapperComponent } from './subsentence-forms-wrapper/subsentence-forms-wrapper.component';

const routes: Routes = [
  {
    path: ROUTE_PATHS.EMPTY,
    component: AppointmentWrapperComponent,
    // canActivate:[ActivateGuard]
  },
  {
    path: ROUTE_PATHS.DETAILS,
    component: AppointmentDetailComponent,
    canActivate: [ActivateGuard]
  },
  {
    path: `${ROUTE_PATHS.TMS_FORMS}/:id`,
    component: TmsFormsWrapperComponent,
    canActivate: [ActivateGuard]
  },
  {
    path: `pending/${ROUTE_PATHS.TMS_FORMS}/:id`,
    component: TmsFormsWrapperComponent,
    canActivate: [ActivateGuard]
  },
  {
    /**
     * canActivate: [ActivateGuard]: This guard for activating consumer side components
     * canActivate: [AdminGuard]: This guard for activating admin side components
     * PatientDocumentRequestDetailsComponent: This component is for uploading patient request documents and details
     */
    path: `${ROUTE_PATHS.REQUEST_DOCUMENT_DETAILS}/:${ROUTE_PATHS.ID}`,
    component: PatientDocumentRequestDetailsComponent,
    canActivate: [ActivateGuard]
  },
  {
    /**
     * canActivate: [ActivateGuard]: This guard for activating consumer side components
     * canActivate: [AdminGuard]: This guard for activating admin side components
     * AdhdQuestionnaireComponent: This component is for ADHD questionnaire
     */
     path: `${ROUTE_PATHS.ADHD_QUESTIONNAIRE}`,
     component: AdhdQuestionnaireComponent,
     canActivate: [ActivateGuard]
  },
  {
    /**
     * canActivate: [ActivateGuard]: This guard for activating consumer side components
     * canActivate: [AdminGuard]: This guard for activating admin side components
     * PhqQuestionnaireComponent: This component is for PHQ questionnaire
     */
     path: `${ROUTE_PATHS.PHQ_QUESTIONNAIRE}`,
     component: PhqQuestionnaireComponent,
     canActivate: [ActivateGuard]
  },
  {
    /**
     * canActivate: [ActivateGuard]: This guard for activating consumer side components
     * canActivate: [AdminGuard]: This guard for activating admin side components
     * PhqQuestionnaireComponent: This component is for PHQ questionnaire
     */
     path: `${ROUTE_PATHS.Subsentence_Wrapper}`,
     component: SubsentenceFormsWrapperComponent,
     canActivate: [ActivateGuard]
  },
  {
    /**
     * canActivate: [ActivateGuard]: This guard for activating consumer side components
     * canActivate: [AdminGuard]: This guard for activating admin side components
     * RequestTherapistInfoComponent: This component is for therapist information.
     */
     path: `${ROUTE_PATHS.REQUEST_THERAPIST_DETAILS}`,
     component: RequestTherapistInfoComponent,
     canActivate: [ActivateGuard]
  },
  {
    /**
     * canActivate: [ActivateGuard]: This guard for activating consumer side components
     * canActivate: [AdminGuard]: This guard for activating admin side components
     * MedicationFeedbackComponent: This component is for medication feedback.
     */
     path: `${ROUTE_PATHS.MEDICATION_FEEDBACK}`,
     component: MedicationFeedbackComponent,
     canActivate: [ActivateGuard]
  },
  {
    /**
     * canActivate: [ActivateGuard]: This guard for activating consumer side components
     * canActivate: [AdminGuard]: This guard for activating admin side components
     * InformationRequestComponent: This component is for information request for appointments.
     */
     path: `${ROUTE_PATHS.INFORMATION_REQUEST}`,
     component: InformationRequestComponent,
     canActivate: [ActivateGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
// tslint:disable-next-line: class-name
export class appointmentRoutingModule { }
