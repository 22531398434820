<!-- Filter layout -->
<div class="filter__main-wrapper">
    <!-- Filter title block -->
    <div class="filter__title-block">
        <!-- Filter title -->
        <div class="filter__title">
            Filters
        </div>
        <!--custom form start-->
        <form [formGroup]="formGroup" class="custom-form">
            <ng-container *ngFor="let field of formInputs;let i=index">
                <div class="form--fields" *ngIf="field.showontoggle == true" id="{{field.idName}}" [ngClass]="field.className != '' ? field.className : '' ">
                    <!--main header start-->
                    <div *ngIf="field.type == 'header' && field.showontoggle == true" class="label">
                        <span class="label">{{field.label}}</span><span class="line"></span>
                    </div>
                    <!--main header end-->
                    <div [ngSwitch]="field.controlType" class="form__field__wrapper {{field.controlType}}" *ngIf="(field.type != 'header') ">

                        <div class="label" *ngIf="field.label && field.showontoggle == true">{{field.label}}</div>
                        <div class="label" *ngIf="field.show">{{field.labelText}}</div>
                        <!--labelText-->
                        <div *ngSwitchCase="'labelValue'">
                            <label>{{field.value}}</label>
                        </div>
                        <!---->

                        <!--calendar field start-->
                        <ng-container *ngIf="field.showontoggle == true">
                            <div *ngSwitchCase="'calendar'" class="form__field calendar {{field.key}}">
                                <p-calendar [timeOnly]="field.isTime" [ngClass]="field.editable == false?'calendar-disable':''" [formControlName]="field.key" [monthNavigator]="true" [yearNavigator]="true" yearRange="{{todayDate.getFullYear()-90}}:{{todayDate.getFullYear() + 1}}" [dataType]="'date'"
                                    placeholder="{{field.placeholder}}" [disabled]="!field.editable" dateFormat="mm/dd/yy" (onClose)="dateChanged()">
                                </p-calendar>
                                <div *ngIf="(!formGroup.controls[field.key].value) &&
                  formSubmitAttempt" class="invalid-feedback">
                                    <div>{{ field.emptyValueMessage }}</div>
                                </div>
                                <div *ngIf="formGroup.controls[field.key].value &&
            isInvalid(formGroup.controls[field.key], formSubmitAttempt) && formSubmitAttempt" class="invalid-feedback">

                                    <div>{{ field.validationValue }}</div>
                                </div>

                            </div>
                        </ng-container>
                        <!--calendar field end-->

                        <!--Timer field start-->
                        <ng-container *ngIf="field.showontoggle == true">
                            <div *ngSwitchCase="'timer'" class="form__field calendar {{field.key}}">
                                <p-calendar inputId="timeonly" [timeOnly]="field.isTime" [ngClass]="field.editable == false?'calendar-disable':''" [formControlName]="field.key" placeholder="{{field.placeholder}}" [disabled]="!field.editable">
                                </p-calendar>
                                <div *ngIf="(!formGroup.controls[field.key].value) &&
                      formSubmitAttempt" class="invalid-feedback">
                                    <div>{{ field.emptyValueMessage }}</div>
                                </div>
                                <div *ngIf="formGroup.controls[field.key].value &&
                isInvalid(formGroup.controls[field.key], formSubmitAttempt) && formSubmitAttempt" class="invalid-feedback">

                                    <div>{{ field.validationValue }}</div>
                                </div>

                            </div>
                        </ng-container>
                        <!--Timer field end-->

                        <!--radio-btn field start-->
                        <ng-container *ngIf="field.showontoggle == true">
                            <div *ngSwitchCase="'custom-radio'" class="form__field custom-radio">
                                <div class="radio" [ngClass]="each.className != '' ? each.className : '' " *ngFor="let each of field.fields">
                                    <input id="{{field.key}} + {{each.value}}" type="radio" [name]="field.key" (change)="radioChnage(field)" [value]="each.value" [formControlName]="field.key" [attr.disabled]="!field.editable === true || each.disable === true || null">
                                    <label for="{{field.key}} + {{each.value}}" class="radio-label" [ngClass]="field.editable == false?'disable':''"><span
                                            [ngStyle]="{'width': each.width+'px', 'height': each.height+'px'}"
                                            class="icon--styles" *ngIf="each.img">
                                            <app-svg [name]="each.img"></app-svg>
                                        </span>{{ each.label }}</label>
                                </div>
                                <div *ngIf="(!formGroup.controls[field.key].value) &&
                  formSubmitAttempt" class="invalid-feedback">
                                    <div>{{ field.emptyValueMessage }}</div>
                                </div>
                            </div>
                        </ng-container>
                        <!--radio-btn field end-->
                        <div *ngSwitchCase="'hrLine'" class="hr__line"></div>

                        <!--checkbox-tik field start-->
                        <div *ngSwitchCase="'checkbox-tik'" class="form__field checkbox-tik">

                            <div class="checkbox-flex">
                                <div class="checkbox-tik">
                                    <input type="checkbox" id="{{field.key}}" [name]="field.key" [formControlName]="field.key" (change)="field.selected = !field.selected" [checked]="field.value" [attr.disabled]="!field.editable === true || null">
                                    <label for="{{field.key}}" [ngClass]="field.editable == false?'tik-disable':''"></label>
                                </div>
                                <div class="label">{{field.labeltext}}</div>
                            </div>
                            <div *ngIf="isInvalid(formGroup.controls[field.key],
                            formSubmitAttempt)" class="invalid-feedback">
                                {{ field.emptyValueMessage }}
                            </div>
                        </div>
                        <!--checkbox-tik field end-->

                        <!-- Grouping checkbox starts -->
                        <div *ngSwitchCase="'groupCheckbox'" class="filter-options">
                            <div class="check-wrapper">
                                <!--category start-->
                                <div class="overlay-label" [ngClass]="{'check__intermediate': field.isIntermediate}">
                                    <p-checkbox [value]="field.value" [ngModelOptions]="{standalone: true}" [(ngModel)]="field.isSelected" label="{{field.categoryLabel | titlecase}}" binary="true" (onChange)="categorySelect($event, i)" *ngIf="field.editable == undefined">
                                    </p-checkbox>
                                    <p-checkbox [value]="field.value" [ngModelOptions]="{standalone: true}" [(ngModel)]="field.isSelected" label="{{field.categoryLabel | titlecase}}" binary="true" (onChange)="categorySelect($event, i)" [disabled]="!field.editable" *ngIf="field.editable != undefined">
                                    </p-checkbox>
                                </div>
                                <!--category end-->

                                <!--tasks start-->
                                <div class="task__block">
                                    <div class="in-review-checkbox" *ngFor="let task of field.options; let taskID = index">
                                        <p-checkbox [value]="task.value" label="{{task.name | titlecase}}" [ngModelOptions]="{standalone: true}" [(ngModel)]="task.isSelected" binary="true" (onChange)="optionSelect($event, taskID, i)" *ngIf="task.editable == undefined">
                                        </p-checkbox>
                                        <p-checkbox [value]="task.value" label="{{task.name | titlecase}}" [ngModelOptions]="{standalone: true}" [(ngModel)]="task.isSelected" binary="true" (onChange)="optionSelect($event, taskID, i)" [disabled]="!task.editable" *ngIf="task.editable != undefined">
                                        </p-checkbox>
                                    </div>
                                </div>
                                <!--tasks end-->
                            </div>
                        </div>
                        <!-- Grouping checkbox ends here -->

                        <!-- action field starts -->
                        <div class="form__action">
                            <div *ngSwitchCase="'submitButton'" class="primary__button__wrapper">
                                <button class="cancel-btn" (click)="clearFormfields()" [type]="field.type">
                                    {{ field.cencelBtn }}
                                  </button>
                                <div class="cancel__button__wrapper">
                                    <button class="common-btn-style" (click)="actionField($event)" [type]="field.type">
                                        {{ field.text }}
                                    </button>
                                </div>

                            </div>
                        </div>
                        <!-- action field ends -->

                    </div>
                </div>
            </ng-container>
        </form>
        <!--custom form end-->
    </div>
</div>