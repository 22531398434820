import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { HttpService } from '@app/common/services/http.service';

@Component({
  selector: 'app-cancel-redirect',
  templateUrl: './cancel-redirect.component.html',
  styleUrls: ['./cancel-redirect.component.scss']
})
export class CancelRedirectComponent implements OnInit {
  showErrorMessage: boolean;
  errorMessage: string;
  invoiceId: any;
  invoiceType: any;

  constructor(private http: HttpService, private router: Router, private route: ActivatedRoute) { 
    let url = router.url;
    this.invoiceId = url.split('/').pop();
    let urlArr = url.split('/');
    this.invoiceType = urlArr[(urlArr.length - 2)];
  }

  ngOnInit() {
    this.cancelInvoice();
  }

  cancelInvoice() {
    const payloadData = {
      type: 'DELETE',
      url: 'invoiceCancel',
      isDeveloper: true,
      pathVariables: [this.invoiceId]
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.INVOICES}`]);
      } else {
        // Error
        this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.INVOICES}`]);
      }
    });
  }

}
