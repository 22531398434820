<!--custom form start-->
<form [formGroup]="formGroup" class="custom-form">
    <ng-container *ngFor="let field of formInputs;let i=index">


        <div class="form--fields" *ngIf="field && field.showontoggle == true" id="{{field.idName}}"
            [ngClass]="field.className != '' ? field.className : '' ">

            <!--main header start-->
            <div *ngIf="field.type == 'header' && field.showontoggle == true" class="main-heading">
                <span class="label">{{field.label}}</span><span class="line"></span>
            </div>
            <!--main header end-->

            <!--link start-->
            <div *ngIf="field.type == 'link'" class="links-other">
                <a class="label" href="{{field.href}}" target="_blank">{{field.link}}</a>
            </div>
            <!--link end-->

            <!--Note start-->
            <div *ngIf="field.type == 'note'" class="note--msg" [ngClass]="field.className ? 'check-text' : '' ">
                <div *ngIf="field.image"><img alt="" class="noteicon" src="../../../../assets/images/svgs/info.svg">
                </div>
                {{field.note}}
            </div>
            <!--Note end-->

            <div [ngSwitch]="field.controlType" class="form__field__wrapper {{field.controlType}}"
                *ngIf="(field.type != 'header') ">

                <div class="label" *ngIf="field.label && field.showontoggle == true || field.noLabel"
                    [ngClass]="field.noLabel ? 'no-label' : '' ">
                    {{field.label}}
                    <span class="subtitle">{{field.subtitle}}</span>
                    <span class="subtitle-msg" *ngIf="field.showMessage">{{field.Message}}</span>
                </div>
                <div class="label" *ngIf="field.show">{{field.labelText}}</div>
                <!--labelText-->
                <div *ngSwitchCase="'labelValue'">
                    <label>{{field.value}}</label>
                </div>
                <!---->
                <ng-container *ngSwitchCase="'formArray'">

                    <div [formArrayName]="field.key"
                        *ngFor="let arrayFormGroup of formGroup.get(field.key).controls; let i = index;">
                        <div [formGroupName]="i" class="custom-form">
                            <div *ngFor="let fieldInner of field.formInputs[i];let innerIndex=index"
                                [ngClass]="fieldInner.className != '' ? fieldInner.className : '' "
                                class="form--fields">
                                <!-- for the inner fields -->
                                <!--main header start-->
                                <div *ngIf="fieldInner.type == 'header' && fieldInner.showontoggle == true"
                                    class="main-heading innerHedding">
                                    <div>
                                        <span class="label">{{fieldInner.label}}</span><span class="line"></span>
                                    </div>
                                    <div *ngIf="fieldInner.showClose" [ngClass]="!fieldInner.editable?'view--mode':''"
                                        class="close-input-icon">
                                        <app-svg [name]="'cancel-appointment'" (click)="closeNew(field,i)"></app-svg>
                                        <div>Remove medicine</div>
                                    </div>
                                </div>
                                <!--main header end-->
                                <!-- stand alone close-icon start -->
                                <div *ngIf="fieldInner.type == 'close-icon' && fieldInner.showontoggle == true ">
                                    <div *ngIf="fieldInner.showClose" [ngClass]="!fieldInner.editable?'view--mode':''"
                                        class="close-input-icon close-field">
                                        <app-svg [name]="'cancel-appointment'" (click)="closeNew(field,i)"></app-svg>
                                        <div *ngIf="fieldInner.closeIconText !== 'Score History'">Remove medicine</div>
                                        <div *ngIf="fieldInner.closeIconText === 'Score History'">Remove row</div>
                                    </div>
                                </div>
                                <!-- stand alone close-icon end -->
                                <!--Note start-->
                                <div *ngIf="fieldInner.type == 'note'" class="note--msg">
                                    {{fieldInner.note}}
                                </div>
                                <!--Note end-->
                                <div [ngSwitch]="fieldInner.controlType"
                                    class="form__field__wrapper {{fieldInner.controlType}}"
                                    *ngIf="(fieldInner.type != 'header') ">

                                    <div class="label" *ngIf="fieldInner.label && fieldInner.showontoggle == true">
                                        {{fieldInner.label}}
                                        <span class="subtitle"> {{fieldInner.subtitle}} </span>
                                    </div>
                                    <div class="label" *ngIf="fieldInner.show">{{fieldInner.labelText}}</div>
                                    <!--labelText-->
                                    <div *ngSwitchCase="'labelValue'">
                                        <label>{{fieldInner.value}}</label>
                                    </div>
                                    <!---->
                                    <ng-container *ngIf="fieldInner.showontoggle == true">
                                        <!--upload image field start-->
                                        <div *ngSwitchCase="'upload-image'" class="form__field upload-image"
                                            (onChange)="taskSelect($event)">
                                            <!--file upload start-->
                                            <div class="file__upload-action">
                                                <label class="main-label">{{fieldInner.uploadlabel}}</label>
                                                <div class="browse_img"
                                                    *ngIf="!fieldInner.showImage && fieldInner.value !='' && fieldInner.value !=undefined && fieldInner.value !=null ">
                                                    <label for="{{fieldInner.key}}"
                                                        [ngClass]="fieldInner.editable ==false?'upload-disabe':''"
                                                        *ngIf="fieldInner.value && fieldInner.value.name"
                                                        class="btn">{{fieldInner.value.name}}</label>
                                                    <label for="{{fieldInner.key}}"
                                                        [ngClass]="fieldInner.editable ==false?'upload-disabe':''"
                                                        *ngIf="!(fieldInner.value && fieldInner.value.name)"
                                                        class="btn">{{getValue(fieldInner.value)}}</label>
                                                    <div class="close-icon"
                                                        [ngClass]="!fieldInner.editable || fieldInner.loading?'pointer__event':''">
                                                        <app-svg [name]="'plus-circle'"
                                                            (click)="showUpload($event,field,fieldInner)"></app-svg>
                                                    </div>
                                                    <img *ngIf="fieldInner.loading" class="file-loader"
                                                        src="assets/images/svgs/golden.svg" alt="l">
                                                </div>
                                                <div class="upload-btn"
                                                    *ngIf="fieldInner.showImage || fieldInner.value ==''">
                                                    <label for="{{fieldInner.key}}" class="upload_label"
                                                        [ngClass]="fieldInner.editable ==false?'upload-disabe':''">
                                                        <div>Upload</div>
                                                        <app-svg [name]="'upload'"></app-svg>
                                                    </label>
                                                    <input (focus)="focusFunction($event)"
                                                        (focusout)="focusOutFunction($event)" id="{{fieldInner.key}}"
                                                        style="display:none;" type="file"
                                                        (change)="imageUploadFormArray($event,fieldInner,i,field)"
                                                        [formControlName]="fieldInner.key"
                                                        [attr.disabled]="!fieldInner.editable === true || null">
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <!--upload image field end-->
                                    <!-- text field starts -->
                                    <ng-container *ngIf="fieldInner.showontoggle == true">
                                        <div *ngSwitchCase="'inputText'" class="form__field">
                                            <div class="input-and-close">
                                                <input class="text-input input"
                                                    (keypress)="onKeyPressField($event, fieldInner)"
                                                    (focus)="focusFunction($event)"
                                                    (focusout)="focusOutFunction($event)" [type]="fieldInner.type"
                                                    [attr.maxLength]="fieldInner.maxLength"
                                                    [formControlName]="fieldInner.key"
                                                    [ngClass]="{ disable: !fieldInner.editable }"
                                                    [placeholder]="fieldInner.placeholder"
                                                    [readonly]="!fieldInner.editable" [(ngModel)]="fieldInner.value" />
                                            </div>
                                            <!--text field validations start-->
                                            <div *ngIf="fieldInner.updateOn == 'blur'">
                                                <div *ngIf="(!getFormElementValue(formGroup, field, fieldInner, i)) && formGroup.get(field.key).controls[i].controls[fieldInner.key].status == 'INVALID' && !formSubmitAttempt"
                                                    class="invalid-feedback">
                                                    <div>{{ fieldInner.emptyValueMessage }}</div>
                                                </div>

                                                <div *ngIf="getFormElementValue(formGroup, field, fieldInner, i) && formGroup.get(field.key).controls[i].controls[fieldInner.key].status == 'INVALID' && !formSubmitAttempt"
                                                    class="invalid-feedback">
                                                    <div>{{ fieldInner.validationValue }}</div>
                                                </div>
                                            </div>
                                            <div>
                                                <div *ngIf="(!getFormElementValue(formGroup, field, fieldInner, i))  && isInvalid(formGroup.get(field.key).controls[i].controls[fieldInner.key], formSubmitAttempt) &&
                        formSubmitAttempt" class="invalid-feedback">
                                                    <div>{{ fieldInner.emptyValueMessage }}</div>
                                                </div>
                                            </div>
                                            <div *ngIf="getFormElementValue(formGroup, field, fieldInner, i) && 
                  isInvalid(formGroup.get(field.key).controls[i].controls[fieldInner.key], true)"
                                                class="invalid-feedback">

                                                <div>{{ fieldInner.validationValue }}</div>
                                            </div>
                                            <div class="help__text" *ngIf="fieldInner.helpText">{{fieldInner.helpText}}
                                            </div>
                                            <!--text field validations end-->

                                        </div>
                                    </ng-container>
                                    <!-- text field ends -->
                                    <!-- Text area starts-->
                                    <ng-container *ngIf="fieldInner.showontoggle == true">
                                        <div *ngSwitchCase="'textarea'" class="form__field text__area">
                                            <div class="add-delete-block" *ngIf="fieldInner.isDelete">
                                                <app-svg [name]="'delete'" class="delete"
                                                    [ngClass]="!fieldInner.editable?'pointer__event':''"
                                                    (click)="deleteTextAreaFormArray(fieldInner)"></app-svg>
                                            </div>
                                            <textarea (focus)="focusFunction($event)"
                                                (focusout)="focusOutFunction($event)" class="text-area input"
                                                type="fieldInner.type" [attr.maxLength]="fieldInner.maxLength"
                                                [formControlName]="fieldInner.key"
                                                [ngClass]="{ disable: !fieldInner.editable }"
                                                value="{{ fieldInner.value }}" [placeholder]="fieldInner.placeholder"
                                                (input)="textCounter(fieldInner, fieldInner.maxLength,formGroup, field, i)"
                                                [readonly]="!fieldInner.editable"
                                                [(ngModel)]="fieldInner.value"></textarea>
                                            <input disabled class="textarea-range-show" *ngIf="fieldInner.showRange"
                                                maxlength="3" size="3" value="{{fieldInner.count}}"
                                                id="{{field.key}}{{fieldInner.key}}{{i}}">

                                            <!--text area validation start-->
                                            <div *ngIf="fieldInner.updateOn == 'blur'">
                                                <div *ngIf="(!getFormElementValue(formGroup, field, fieldInner, i)) && !formSubmitAttempt"
                                                    class="invalid-feedback ">
                                                    <div>{{ fieldInner.emptyValueMessage }}</div>
                                                </div>
                                                <div *ngIf="getFormElementValue(formGroup, field, fieldInner, i) && formGroup.get(field.key).controls[i].controls[fieldInner.key].status == 'INVALID' && !formSubmitAttempt && formGroup.get(field.key).controls[i].controls[fieldInner.key].touched"
                                                    class="invalid-feedback">
                                                    <div>{{ fieldInner.validationValue }}</div>
                                                </div>
                                            </div>
                                            <div>
                                                <div *ngIf="(!getFormElementValue(formGroup, field, fieldInner, i)) &&
                        formSubmitAttempt && formGroup.get(field.key).controls[i].controls[fieldInner.key].status == 'INVALID'"
                                                    class="invalid-feedback">
                                                    <div>{{ fieldInner.emptyValueMessage }}</div>
                                                </div>
                                            </div>
                                            <div *ngIf="getFormElementValue(formGroup, field, fieldInner, i) &&
                  isInvalid(formGroup.get(field.key).controls[i].controls[fieldInner.key], true)"
                                                class="invalid-feedback">

                                                <div>{{ fieldInner.validationValue }}</div>
                                            </div>
                                            <div class="help__text" *ngIf="fieldInner.helpText">{{fieldInner.helpText}}
                                            </div>
                                            <!--text area validation end-->
                                        </div>
                                    </ng-container>
                                    <!-- Text area ends-->

                                    <!--add new operation start-->
                                    <ng-container *ngIf="fieldInner.showontoggle == true">
                                        <div *ngSwitchCase="'addnew'">
                                            <div class="form__field add-new-div">
                                                <div class="addnew__container">
                                                    <app-svg [name]="'plus-circle'"
                                                        [ngClass]="!fieldInner.editable?'pointer__event':''"
                                                        (click)="addNew(field,fieldInner.formtag)"></app-svg>
                                                    <div class="description">{{fieldInner.labeltext}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <!--add new operation end-->

                                    <!--calendar field start-->
                                    <ng-container *ngIf="fieldInner.showontoggle == true">
                                        <div *ngSwitchCase="'calendar'" class="form__field calendar"
                                            (onChange)="taskSelect($event)">
                                            <p-calendar [readonlyInput]="true" dateFormat="mm/dd/yy"
                                                [ngClass]="fieldInner.editable == false?'calendar-disable':''"
                                                [formControlName]="fieldInner.key" [monthNavigator]="true"
                                                [yearNavigator]="true"
                                                yearRange="{{todayDate.getFullYear()-90}}:{{todayDate.getFullYear()}}"
                                                (onSelect)="onSelectMethod($event, fieldInner.key)" [dataType]="'date'"
                                                placeholder="{{fieldInner.placeholder}}"
                                                [disabled]="!fieldInner.editable" [(ngModel)]="fieldInner.value"
                                                [maxDate]="(fieldInner.key == 'prescriptionStartDate' || fieldInner.key == 'prescriptionEndDate' )?'':todayDate">
                                            </p-calendar>
                                            <div *ngIf="isInvalid(formGroup.get(field.key).controls[i].controls[fieldInner.key], formSubmitAttempt) &&
                      formSubmitAttempt && formGroup.get(field.key).controls[i].controls[fieldInner.key].errors.required"
                                                class="invalid-feedback">
                                                <div>{{ fieldInner.emptyValueMessage }}</div>
                                            </div>
                                            <div *ngIf="getFormElementValue(formGroup, field, fieldInner, i) &&
                isInvalid(formGroup.get(field.key).controls[i].controls[fieldInner.key], formSubmitAttempt)"
                                                class="invalid-feedback">

                                                <div>{{ fieldInner.validationValue }}</div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <!--calendar field end-->

                                    <!--calendar eval field start - user can navigate to which ever date possible-->
                                    <!-- <ng-container *ngIf="field.showontoggle == true">
                                        <div *ngSwitchCase="'calendarEval'" class="form__field calendar"
                                            (onChange)="taskSelect($event)">
                                            <p-calendar [readonlyInput]="true" dateFormat="mm/dd/yy"
                                                [ngClass]="field.editable == false?'calendar-disable':''"
                                                [formControlName]="field.key" [monthNavigator]="true"
                                                [yearNavigator]="true"
                                                yearRange="{{todayDate.getFullYear()-90}}:{{todayDate.getFullYear()+90}}"
                                                (onSelect)="onSelectMethod($event, field.key)" [dataType]="'date'"
                                                placeholder="{{field.placeholder}}" [disabled]="!field.editable"
                                                [(ngModel)]="field.value">
                                            </p-calendar>
                                            <div *ngIf="(!formGroup.controls[field.key].value) &&
                    formSubmitAttempt && isInvalid(formGroup.controls[field.key],
                    formSubmitAttempt)" class="invalid-feedback">
                                                <div>{{ field.emptyValueMessage }}</div>
                                            </div>
                                            <div *ngIf="formGroup.controls[field.key].value &&
                isInvalid(formGroup.controls[field.key], formSubmitAttempt) && formSubmitAttempt && formGroup.get(field.key).errors.name"
                                                class="invalid-feedback">
                                                <div>{{ field.validationValue }}</div>
                                            </div>
                                            <div *ngIf="formGroup.controls[field.key].value &&
                        isInvalid(formGroup.controls[field.key], formSubmitAttempt) && formSubmitAttempt && formGroup.get(field.key).errors.date"
                                                class="invalid-feedback">

                                                <div>{{ field.validationValue }}</div>
                                            </div>
                                        </div>
                                    </ng-container> -->
                                    <!--calendar eval field end-->

                                    <!--checkbox-slider field start-->
                                    <ng-container *ngIf="fieldInner.showontoggle == true">
                                        <div *ngSwitchCase="'checkbox-slider'" class="form__field checkbox-slider"
                                            (onChange)="taskSelect($event)">
                                            <!--slider UI start-->
                                            <div class="checkbox-label">
                                                {{ fieldInner.checkboxlabel }}
                                            </div>

                                            <div class="slidebox"><input (focus)="focusFunction($event)"
                                                    (focusout)="focusOutFunction($event)"
                                                    id="{{field.key}}+{{fieldInner.key}}+{{i}}" type="checkbox"
                                                    name="{{fieldInner.key}}" (change)="getSliderValue(field,i)"
                                                    [formControlName]="fieldInner.key"
                                                    [attr.disabled]="!fieldInner.editable === true || null"
                                                    [(ngModel)]="fieldInner.value"><label
                                                    for="{{field.key}}+{{fieldInner.key}}+{{i}}"
                                                    [ngClass]="fieldInner.editable == false?'slider-disabe':''">
                                                    <div class="query">
                                                        <div class="query-yes">Yes</div>
                                                        <div class="query-no">No</div>
                                                    </div>
                                                </label></div>
                                            <!--slider UI end-->
                                            <div *ngIf="isInvalid(formGroup.get(field.key).controls[i].controls[fieldInner.key],
                          formSubmitAttempt)" class="invalid-feedback">
                                                {{ fieldInner.emptyValueMessage }}
                                            </div>
                                        </div>
                                    </ng-container>
                                    <!--checkbox-slider field end-->

                                    <!--radio-btn field start-->
                                    <ng-container *ngIf="fieldInner.showontoggle == true">
                                        <div *ngSwitchCase="'custom-radio'" class="form__field"
                                            (change)="taskSelect($event)">
                                            <div class="radio" *ngFor="let each of fieldInner.fields">
                                                <input (focus)="focusFunction($event)"
                                                    (focusout)="focusOutFunction($event)" id="{{each.value}}"
                                                    type="radio" [(ngModel)]="fieldInner.value" [name]="fieldInner.key"
                                                    [value]="each.value" [formControlName]="fieldInner.key"
                                                    [attr.disabled]="!fieldInner.editable === true || null"
                                                    (change)="selectedRadio(each,fieldInner,i,field)">
                                                <label for="{{each.value}}" class="radio-label"
                                                    [ngClass]="fieldInner.editable == false?'disable':''"
                                                    (change)="selectedRadio(each,fieldInner,i,field)"><span
                                                        [ngStyle]="{'width': each.width+'px', 'height': each.height+'px'}"
                                                        class="icon--styles" *ngIf="each.img">
                                                        <app-svg [name]="each.img"></app-svg>
                                                    </span>{{ each.label }}</label>
                                            </div>
                                            <div *ngIf="isInvalid(formGroup.get(field.key).controls[i].controls[fieldInner.key],
              formSubmitAttempt)" class="invalid-feedback">
                                                {{ fieldInner.emptyValueMessage }}
                                            </div>
                                        </div>
                                    </ng-container>
                                    <!--radio-btn field end-->
                                </div>

                            </div>
                        </div>
                    </div>
                </ng-container>
                <!-- text field starts -->
                <ng-container *ngIf="field.showontoggle == true">
                    <div *ngSwitchCase="'inputText'" class="form__field">
                        <div class="input-and-close">
                            <input (focus)="focusFunction($event)" (keypress)="onKeyPressField($event, field)"
                                (blur)="onBlurMethod(field)" (focusout)="focusOutFunction($event)"
                                class="text-input input" [type]="field.type" [attr.maxLength]="field.maxLength"
                                [formControlName]="field.key" [ngClass]="{ disable: !field.editable }"
                                [placeholder]="field.placeholder" [readonly]="!field.editable" [(ngModel)]="field.value"
                                (paste)="blockCopy($event,field)" (drop)="blockCopy($event,field)" />
                            <span *ngIf="field.placeholderVal" class="placeholder-right">{{field.placeholderVal}}</span>
                        </div>
                        <!--text field validations start-->
                        <div *ngIf="field.updateOn == 'blur'">
                            <div *ngIf="!(formGroup.controls[field.key].valid || formGroup.controls[field.key].value) && !formSubmitAttempt"
                                class="invalid-feedback">
                                <div>{{ field.emptyValueMessage }}</div>
                            </div>
                            <div *ngIf="formGroup.controls[field.key].value && formGroup.controls[field.key].status == 'INVALID' && !formSubmitAttempt"
                                class="invalid-feedback">
                                <div>{{ field.validationValue }}</div>
                            </div>
                        </div>
                        <div>
                            <div *ngIf="!(formGroup.controls[field.key].valid || formGroup.controls[field.key].value) &&
                  formSubmitAttempt" class="invalid-feedback">
                                <div>{{ field.emptyValueMessage }}</div>
                            </div>
                        </div>
                        <div *ngIf="formGroup.controls[field.key].value &&
            isInvalid(formGroup.controls[field.key], field.blur) && (formGroup.controls[field.key].errors.name && !formGroup.controls[field.key].errors.tname)"
                            class="invalid-feedback">
                            <div>{{ field.validationValue }}</div>
                        </div>
                        <div *ngIf="formGroup.controls[field.key].value &&
                        isInvalid(formGroup.controls[field.key], field.blur) && formGroup.controls[field.key].errors.tname"
                            class="invalid-feedback">
                            <div>{{ field.validationValue1 }}</div>
                        </div>
                        <div class="invalid-feedback"
                            *ngIf="formGroup.controls[field.key].value && isInvalid(formGroup.controls[field.key], field.blur) && 
                        (formGroup.controls[field.key].errors.passwordValidity && formGroup.controls[field.key].errors.name != true)">
                            {{field.matchMessage}}</div>
                        <div class="help__text" *ngIf="field.helpText">{{field.helpText}}</div>
                        <!--text field validations end-->

                    </div>
                </ng-container>
                <!-- text field ends -->
                <ng-container *ngIf="field.showontoggle == true">
                    <div *ngSwitchCase="'autoSearch'">
                        <div class="auto-search-field">
                            <!-- <p-autoComplete [(ngModel)]="selectedAutoSearchItem" emptyMessage="No results found"
                                [formControlName]="field.key" [suggestions]="filteredSearchList"
                                (completeMethod)="searchValues($event,field.key)" field="name"
                                [placeholder]="field.placeholder" (onSelect)="getTheSearchedPatientId($event)"
                                [disabled]="!field.editable">
                                <ng-template let-result pTemplate="item">
                                    <div class="result-item">
                                        <div>{{result.name}}</div>
                                    </div>
                                </ng-template>
                            </p-autoComplete> -->
                            <p-autoComplete [(ngModel)]="selectedAutoSearchItem" emptyMessage="No results found"
                                [formControlName]="field.key" [suggestions]="filteredSearchList" 
                                (completeMethod)="searchValues($event,field.key)" field="name"
                                [placeholder]="field.placeholder" (onSelect)="getTheSearchedPatientId($event)"
                                [disabled]="!field.editable" (keydown)="onKeydown($event, field.key)">
                                <ng-template let-result pTemplate="item">
                                    <div class="result-item">
                                        <div>{{result.name}}</div>
                                    </div>
                                </ng-template>
                            </p-autoComplete>
                            <div class="auto-search-icon" (click)="searchValues($event,field.key)"
                                [class.disable]="!field.editable === true">
                                <app-svg [name]="'search'"></app-svg>
                            </div>
                            <div *ngIf="isInvalid(formGroup.controls[field.key],
            formSubmitAttempt)" class="invalid-feedback">
                                {{ field.emptyValueMessage }}
                            </div>
                        </div>
                    </div>
                </ng-container>
                <!---->
                <ng-container *ngIf="field.showontoggle == true">
                    <div *ngSwitchCase="'lastVisit'">
                        <app-last-visit-block [lastVisitData]="field.value"></app-last-visit-block>
                    </div>
                </ng-container>
                <!---->
                <ng-container *ngSwitchCase="'formArray1'">

                    <div [formArrayName]="field.key"
                        *ngFor="let arrayFormGroup of formGroup.get(field.key).controls; let i = index;">
                        <div [formGroupName]="i" class="custom-form">
                            <div *ngFor="let fieldInner of field.formInputs[i];let innerIndex=index"
                                [ngClass]="fieldInner.className != '' ? fieldInner.className : '' "
                                class="form--fields">

                                <!--main header start-->
                                <div *ngIf="fieldInner.type == 'header' && fieldInner.showontoggle == true"
                                    class="main-heading innerHedding">
                                    <div>
                                        <span class="label">{{fieldInner.label}}</span><span class="line"></span>
                                    </div>
                                </div>
                                <!--main header end-->
                                <div [ngSwitch]="fieldInner.controlType"
                                    class="form__field__wrapper {{fieldInner.controlType}}"
                                    *ngIf="(fieldInner.type != 'header') ">

                                    <div class="label" *ngIf="fieldInner.label && fieldInner.showontoggle == true">
                                        {{fieldInner.label}}</div>
                                    <div class="label" *ngIf="fieldInner.show">{{fieldInner.labelText}}</div>
                                    <!--labelText-->
                                    <div *ngSwitchCase="'labelValue'">
                                        <label>{{fieldInner.value}}</label>
                                    </div>
                                    <!---->
                                    <ng-container *ngIf="fieldInner.showontoggle == true">
                                        <!--upload image field start-->
                                        <div *ngSwitchCase="'multiple-upload-image'" class="form__field upload-image"
                                            (onChange)="taskSelect($event)">
                                            <!--file upload start-->
                                            <div class="file__upload-action">
                                                <label class="main-label">{{fieldInner.uploadlabel}}</label>
                                                <div class="browse_img"
                                                    *ngIf="!fieldInner.showImage && fieldInner.value !='' && fieldInner.value !=undefined && fieldInner.value !=null ">

                                                    <label for="{{fieldInner.key}}"
                                                        [ngClass]="fieldInner.editable ==false?'upload-disabe':''"
                                                        *ngIf="fieldInner.value && fieldInner.value.name" class="btn">
                                                        <div class="upload-loader">
                                                            <img *ngIf="fieldInner.loading" class="file-loader"
                                                                src="assets/images/svgs/golden.svg" alt="l">
                                                        </div>
                                                    </label>
                                                    <label data="value" for="{{fieldInner.key}}"
                                                        [ngClass]="fieldInner.editable ==false?'upload-disabe':''"
                                                        *ngIf="!(fieldInner.value && fieldInner.value.name)"
                                                        class="btn">
                                                        <div class="mutiple-uploads-image">
                                                            <label for="{{fieldInner.key}}"
                                                                [ngClass]="fieldInner.editable ==false?'upload-disabe':''"
                                                                *ngIf="fieldInner.value && fieldInner.value.name"
                                                                class="btn">{{fieldInner.value.name | ellipsis: 25
                                                                }}</label>
                                                            <label for="{{fieldInner.key}}"
                                                                [ngClass]="fieldInner.editable ==false?'upload-disabe':''"
                                                                *ngIf="!(fieldInner.value && fieldInner.value.name)"
                                                                class="btn">{{getValue(fieldInner.value) | ellipsis:
                                                                25}}</label>
                                                            <div class="mutiple-uploads__document-actions">
                                                                <a [href]="fieldInner.value" target="_blank"
                                                                    title="View" class="view-icon">
                                                                    <app-svg [name]="'view-upload'"></app-svg>
                                                                </a>
                                                                <p title="Download">
                                                                    <app-svg [name]="'down-arrow'"></app-svg>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </label>
                                                    <div class="close-icon"
                                                        [ngClass]="!fieldInner.editable || fieldInner.loading?'pointer__event':''">
                                                        <app-svg [name]="'plus-circle'"
                                                            (click)="showUpload($event,field,fieldInner)"></app-svg>
                                                    </div>

                                                </div>
                                                <div class="upload-btn"
                                                    *ngIf="fieldInner.showImage || fieldInner.value ==''">
                                                    <label for="{{fieldInner.key}}" class="upload_label"
                                                        [ngClass]="fieldInner.editable ==false?'upload-disabe':''">
                                                        <div>{{fieldInner.uploadFileText}}</div>
                                                        <app-svg [name]="'upload'"></app-svg>
                                                    </label>
                                                    <input (focus)="focusFunction($event)"
                                                        (focusout)="focusOutFunction($event)" id="{{fieldInner.key}}"
                                                        style="display:none;" type="file"
                                                        (change)="imageUploadFormArray($event,fieldInner,i,field)"
                                                        [formControlName]="fieldInner.key"
                                                        [attr.disabled]="!fieldInner.editable === true || null">
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <!--upload image field end-->
                                </div>
                            </div>
                        </div>
                        <div *ngIf="formGroup.get(field.key).value && isInvalid(formGroup.get(field.key), true) && formGroup.get(field.key).errors.file"
                            class="invalid-feedback">
                            <div>{{ field.validationValue }}</div>
                        </div>
                        <div *ngIf="formGroup.get(field.key).value && isInvalid(formGroup.get(field.key), true) && formGroup.get(field.key).errors.empty"
                            class="invalid-feedback">
                            <div>{{ field.emptyValue }}</div>
                        </div>
                    </div>
                </ng-container>
                <!-- text field starts -->
                <!--add new operation start-->
                <ng-container *ngIf="field.showontoggle == true">
                    <div *ngSwitchCase="'addnewField'">
                        <div class="form__field add-new-div">
                            <div class="addnew__container">
                                <app-svg [name]="'plus-circle'" [ngClass]="!field.editable?'pointer__event':''"
                                    (click)="addNewTextArea(field,field.formtag)"></app-svg>
                                <div class="description">{{field.labeltext}}</div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <!--add new operation end-->

                <!-- Text area starts-->
                <ng-container *ngIf="field.showontoggle == true">
                    <div *ngSwitchCase="'textarea'" class="form__field text__area">
                        <div class="add-delete-block" *ngIf="field.isDelete">
                            <app-svg [name]="'delete'" class="delete" [ngClass]="!field.editable?'pointer__event':''"
                                (click)="deleteTextArea(field,i)"></app-svg>
                        </div>
                        <textarea (focus)="focusFunction($event)" (focusout)="focusOutFunction($event)"
                            class="text-area input" type="field.type" [attr.maxLength]="field.maxLength"
                            [formControlName]="field.key" [ngClass]="{ disable: !field.editable }"
                            value="{{ field.value }}" [placeholder]="field.placeholder"
                            (input)="textCounter(field, field.maxLength)" [readonly]="!field.editable"
                            [(ngModel)]="field.value"></textarea>
                        <input (focus)="focusFunction($event)" (focusout)="focusOutFunction($event)" disabled
                            class="textarea-range-show" *ngIf="field.showRange" maxlength="3" size="3"
                            value="{{field.count}}" id="{{field.key}}">

                        <!--text area validation start-->
                        <div *ngIf="field.updateOn == 'blur'">
                            <div *ngIf="(!formGroup.controls[field.key].value) && !formSubmitAttempt && isInvalid(formGroup.controls[field.key],
                            formSubmitAttempt)" class="invalid-feedback ">
                                <div>{{ field.emptyValueMessage }}</div>
                            </div>
                            <div *ngIf="formGroup.controls[field.key].value && formGroup.controls[field.key].status == 'INVALID' && !formSubmitAttempt && formGroup.controls[field.key].touched"
                                class="invalid-feedback">
                                <div>{{ field.validationValue }}</div>
                            </div>
                        </div>
                        <div>
                            <div *ngIf="(!formGroup.controls[field.key].value) &&
                  formSubmitAttempt && isInvalid(formGroup.controls[field.key],
                  formSubmitAttempt)" class="invalid-feedback">
                                <div>{{ field.emptyValueMessage }}</div>
                            </div>
                        </div>
                        <div *ngIf="formGroup.controls[field.key].value !='' && isInvalid(formGroup.controls[field.key], formSubmitAttempt) && formSubmitAttempt && formGroup.get(field.key).errors.name"
                            class="invalid-feedback">
                            <div>{{ field.validationValue }}</div>
                        </div>
                        <div class="help__text" *ngIf="field.helpText">{{field.helpText}}</div>
                        <!--text area validation end-->
                    </div>
                </ng-container>
                <!-- Text area ends-->

                <!--add new operation start-->
                <ng-container *ngIf="field.showontoggle == true">
                    <div *ngSwitchCase="'addnew'">
                        <div class="form__field add-new-div">
                            <div class="addnew__container">
                                <app-svg [name]="'plus-circle'" [ngClass]="!field.editable?'pointer__event':''"
                                    (click)="addNew(field,field.formtag)"></app-svg>
                                <div class="description">{{field.labeltext}}</div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <!--add new operation end-->

                <!-- password field starts -->
                <ng-container *ngIf="field.showontoggle == true">
                    <div *ngSwitchCase="'inputTextPassword'" class="form__field password-div">
                        <input (focus)="focusFunction($event)" (focusout)="focusOutFunction($event)"
                            class="text-input input" [(ngModel)]="field.value" [attr.disabled]="field.disabled"
                            [ngClass]="{}" [type]="field.type" [attr.maxLength]="field.maxLength"
                            [formControlName]="field.key" [placeholder]="field.placeholder" />

                        <div class="password-img" (click)="togglePassword(field,i)" *ngIf="(!field.isShowpassword)">
                            <app-svg [name]="field.labelImage"></app-svg>
                        </div>

                        <div *ngIf="!formGroup.controls[field.key].value && formSubmitAttempt" class="invalid-feedback">
                            <div>{{ field.emptyValueMessage }}</div>
                        </div>

                        <div *ngIf="formGroup.controls[field.key].value && isInvalid(formGroup.controls[field.key],
        formSubmitAttempt)" class="invalid-feedback">

                            <div *ngIf="formGroup.controls[field.key].errors.name"> {{ field.validationValue}}</div>
                            <div
                                *ngIf="formGroup.controls[field.key].errors.passwordValidity && formGroup.controls[field.key].errors.name != true">
                                {{field.matchMessage}}</div>
                        </div>


                        <div class="password-hide-img" (click)="togglePassword(field,i)" *ngIf="field.isShowpassword">
                            <app-svg [name]="field.labelImage"></app-svg>
                        </div>

                        <div class="forgot-pin-text" *ngIf="field.key == 'password'"
                            (click)="gotoForgotPin(field.path)">
                            Forgot Password ?
                        </div>
                    </div>
                    <!-- pasword field ends -->
                </ng-container>

                <!--primeng dropdown start-->
                <ng-container *ngIf="field.showontoggle == true">
                    <div *ngSwitchCase="'dropdown'" class="form__field dropdown input">
                        <p-dropdown [(ngModel)]="field.value" [options]="field.list" [placeholder]="field.placeholder"
                            [required]="field.required" [formControlName]="field.key" (onChange)="getDropValue(field,i)"
                            [disabled]="!field.editable">
                            <ng-template let-items pTemplate="item">
                                <div class="dropdown__items dropdown__header">
                                    <div class="table__icon__header" *ngIf="items.icon">
                                        <app-svg [name]="items.icon"></app-svg>
                                    </div>
                                    <div class="item__label">{{items.label}}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                        <div *ngIf="!formGroup.controls[field.key].value && isInvalid(formGroup.controls[field.key],
            formSubmitAttempt)" class="invalid-feedback">
                            {{ field.emptyValueMessage }}
                        </div>
                        <div *ngIf="formGroup.controls[field.key].value &&
                isInvalid(formGroup.controls[field.key], formSubmitAttempt) && formSubmitAttempt && formGroup.get(field.key).errors.name"
                            class="invalid-feedback">
                            <div>{{ field.validationValue }}</div>
                        </div>
                    </div>
                </ng-container>
                <!--primeng dropdown end-->

                <!--calendar field start-->
                <ng-container *ngIf="field.showontoggle == true">
                    <div *ngSwitchCase="'calendar'" class="form__field calendar" (onChange)="taskSelect($event)">
                        <p-calendar [readonlyInput]="true" dateFormat="mm/dd/yy"
                            [ngClass]="field.editable == false?'calendar-disable':''" [formControlName]="field.key"
                            [monthNavigator]="true" [yearNavigator]="true"
                            yearRange="{{todayDate.getFullYear()-90}}:{{todayDate.getFullYear()+90}}"
                            (onSelect)="onSelectMethod($event, field.key)" [dataType]="'date'"
                            placeholder="{{field.placeholder}}" [disabled]="!field.editable" [(ngModel)]="field.value"
                            [minDate]="field.minDate ? field.minDate : ((field.isFutureDate == true)?todayDate:null)"
                            [maxDate]="(field.isDob == true)?minDOB:(field.isFutureDate == true)?nextFourtyDays:todayDate">
                        </p-calendar>
                        <div *ngIf="(!formGroup.controls[field.key].value) &&
                      formSubmitAttempt && isInvalid(formGroup.controls[field.key],
                      formSubmitAttempt)" class="invalid-feedback">
                            <div>{{ field.emptyValueMessage }}</div>
                        </div>
                        <div *ngIf="formGroup.controls[field.key].value &&
                isInvalid(formGroup.controls[field.key], formSubmitAttempt) && formSubmitAttempt && formGroup.get(field.key).errors.name"
                            class="invalid-feedback">
                            <div>{{ field.validationValue }}</div>
                        </div>
                        <div *ngIf="formGroup.controls[field.key].value &&
                        isInvalid(formGroup.controls[field.key], formSubmitAttempt) && formSubmitAttempt && formGroup.get(field.key).errors.date"
                            class="invalid-feedback">

                            <div>{{ field.validationValue }}</div>
                        </div>
                    </div>
                </ng-container>
                <!--calendar field end-->

                <!--calendar eval field start - user can navigate to which ever date possible-->
                <ng-container *ngIf="field.showontoggle == true">
                    <div *ngSwitchCase="'calendarEval'" class="form__field calendar" (onChange)="taskSelect($event)">
                        <p-calendar [readonlyInput]="true" dateFormat="mm/dd/yy"
                            [ngClass]="field.editable == false?'calendar-disable':''" [formControlName]="field.key"
                            [monthNavigator]="true" [yearNavigator]="true"
                            yearRange="{{todayDate.getFullYear()-90}}:{{todayDate.getFullYear()+90}}"
                            (onSelect)="onSelectMethod($event, field.key)" [dataType]="'date'"
                            placeholder="{{field.placeholder}}" [disabled]="!field.editable" [(ngModel)]="field.value">
                        </p-calendar>
                        <div *ngIf="(!formGroup.controls[field.key].value) &&
                    formSubmitAttempt && isInvalid(formGroup.controls[field.key],
                    formSubmitAttempt)" class="invalid-feedback">
                            <div>{{ field.emptyValueMessage }}</div>
                        </div>
                        <div *ngIf="formGroup.controls[field.key].value &&
                isInvalid(formGroup.controls[field.key], formSubmitAttempt) && formSubmitAttempt && formGroup.get(field.key).errors.name"
                            class="invalid-feedback">
                            <div>{{ field.validationValue }}</div>
                        </div>
                        <div *ngIf="formGroup.controls[field.key].value &&
                        isInvalid(formGroup.controls[field.key], formSubmitAttempt) && formSubmitAttempt && formGroup.get(field.key).errors.date"
                            class="invalid-feedback">

                            <div>{{ field.validationValue }}</div>
                        </div>
                    </div>
                </ng-container>
                <!--calendar eval field end-->

                <!--checkbox-slider field start-->
                <ng-container *ngIf="field.showontoggle == true">
                    <div *ngSwitchCase="'checkbox-slider'" class="form__field checkbox-slider"
                        (onChange)="taskSelect($event)">
                        <!--slider UI start-->
                        <div class="checkbox-label">
                            {{ field.checkboxlabel }}
                        </div>

                        <div class="slidebox"><input (focus)="focusFunction($event)"
                                (focusout)="focusOutFunction($event)" id="{{field.key}}" type="checkbox"
                                name="{{field.key}}" (change)="getSliderValue(field,i)" [formControlName]="field.key"
                                [attr.disabled]="!field.editable === true || null" [(ngModel)]="field.value"><label
                                for="{{field.key}}" [ngClass]="field.editable == false?'slider-disabe':''">
                                <div class="query">
                                    <div class="query-yes">Yes</div>
                                    <div class="query-no">No</div>
                                </div>
                            </label></div>
                        <!--slider UI end-->
                        <div *ngIf="isInvalid(formGroup.controls[field.key],
            formSubmitAttempt)" class="invalid-feedback">
                            {{ field.emptyValueMessage }}
                        </div>
                    </div>
                </ng-container>
                <!--checkbox-slider field end-->

                <!--radio-btn field start-->
                <ng-container *ngIf="field.showontoggle == true">
                    <div *ngSwitchCase="'custom-radio'" class="form__field custom-radio" (change)="taskSelect($event)">
                        <div class="radio" [ngClass]="each.className != '' ? each.className : '' "
                            *ngFor="let each of field.fields">
                            <input (focus)="focusFunction($event)" (focusout)="focusOutFunction($event)"
                                id="{{field.key}} + {{each.value}}" type="radio" [(ngModel)]="field.value"
                                [name]="field.key" [value]="each.value" [formControlName]="field.key"
                                [attr.disabled]="!field.editable === true || each.disable === true || null"
                                (change)="selectedRadio(each,field,i)">
                            <label for="{{field.key}} + {{each.value}}" class="radio-label"
                                [ngClass]="field.editable == false?'disable apptType'+each.value:'apptType'+each.value"
                                (change)="selectedRadio(each,field,i)"><span
                                    [ngStyle]="{'width': each.width+'px', 'height': each.height+'px'}"
                                    class="icon--styles" *ngIf="each.img">
                                    <app-svg [name]="each.img"></app-svg>
                                </span>{{ each.label }}</label>
                        </div>
                        <div class="blinkmessage" *ngIf="field.isNoteShow"><span class="blink--txt">{{ field.note
                                }}</span></div>
                        <div *ngIf="isInvalid(formGroup.controls[field.key],
        formSubmitAttempt)" class="invalid-feedback">
                            {{ field.emptyValueMessage }}
                        </div>
                    </div>
                </ng-container>
                <!--radio-btn field end-->

                <!--upload image field start-->
                <div *ngSwitchCase="'upload-image'" class="form__field upload-image" (onChange)="taskSelect($event)">
                    <!--file upload start-->
                    <div class="file__upload-action">
                        <label class="main-label">{{field.uploadlabel}}</label>
                        <div class="browse_img"
                            *ngIf="!field.showImage && field.value !='' && field.value !=undefined && field.value !=null ">
                            <label for="{{field.key}}" [ngClass]="field.editable ==false?'upload-disabe':''"
                                *ngIf="field.value && field.value.name" class="btn">{{field.value.name}}</label>
                            <label for="{{field.key}}" [ngClass]="field.editable ==false?'upload-disabe':''"
                                *ngIf="!(field.value && field.value.name)" class="btn">{{getValue(field.value)}}</label>
                            <div class="close-icon" [ngClass]="!field.editable || field.loading?'pointer__event':''">
                                <app-svg [name]="'plus-circle'" (click)="showUpload($event,field,'')"></app-svg>
                            </div>
                            <img *ngIf="field.loading" class="file-loader" src="assets/images/svgs/golden.svg" alt="l">
                        </div>
                        <div class="upload-btn" *ngIf="field.showImage || field.value ==''">
                            <label for="{{field.key}}" class="upload_label"
                                [ngClass]="field.editable ==false?'upload-disabe':''">
                                <div>Upload</div>
                                <app-svg [name]="'upload'"></app-svg>
                            </label>
                            <input (focus)="focusFunction($event)" (focusout)="focusOutFunction($event)"
                                id="{{field.key}}" style="display:none;" type="file"
                                (change)="imageUpload($event,field,i)" [formControlName]="field.key"
                                [attr.disabled]="!field.editable === true || null">
                        </div>
                    </div>
                    <!--file upload end-->
                    <div *ngIf="isInvalid(formGroup.controls[field.key],
          formSubmitAttempt) && formSubmitAttempt && formGroup.get(field.key).errors.required"
                        class="invalid-feedback">
                        {{ field.emptyValueMessage }}
                    </div>
                    <div *ngIf="formGroup.get(field.key).value && isInvalid(formGroup.get(field.key), true) && formGroup.get(field.key).errors.file"
                        class="invalid-feedback">
                        <div>{{ field.validationValue }}</div>
                    </div>
                    <div *ngIf="formGroup.get(field.key).value && isInvalid(formGroup.get(field.key), true) && formGroup.get(field.key).errors.size && !formGroup.get(field.key).errors.file"
                        class="invalid-feedback">
                        <div>{{ field.validationSize }}</div>
                    </div>
                </div>
                <!--upload image field end-->

                <!--p-mobile number block stats here-->
                <ng-container *ngIf="field.showontoggle == true">
                    <div class="--mobile__block" *ngSwitchCase="'p-Input'">
                        <div class="mobile__field">
                            <p-inputMask class="input-mask" (onFocus)="focusFunction($event)"
                                (focusout)="focusOutFunction($event)" mask="{{field.mask}}"
                                (onComplete)="onInputChange(field)" [formControlName]="field.key" [required]
                                [(ngModel)]="field.value" placeholder="{{field.placeholder}}"
                                [disabled]="!field.editable === true || null">
                            </p-inputMask>
                        </div>
                        <div class="mobile__field__btmlabel">{{field.bottomText}}</div>
                        <div *ngIf="!formGroup.controls[field.key].value && isInvalid(formGroup.controls[field.key],formSubmitAttempt)"
                            class="invalid-feedback">
                            {{ field.emptyValueMessage }}
                        </div>
                        <div *ngIf="formGroup.controls[field.key].value && isInvalid(formGroup.controls[field.key], formSubmitAttempt) && formGroup.controls[field.key].errors.timeValidity"
                            class="invalid-feedback">
                            <div>{{ field.validationValue1 }}</div>
                        </div>
                        <div *ngIf="formGroup.controls[field.key].value && isInvalid(formGroup.controls[field.key], formSubmitAttempt) && !formGroup.controls[field.key].errors.timeValidity"
                            class="invalid-feedback">
                            <div>{{ field.validationValue }}</div>
                        </div>
                    </div>
                </ng-container>
                <!--p-mobile number ends here-->


                <!--rating block stats here-->
                <div class="rating--block" *ngSwitchCase="'rating'">
                    <div class="rating--bar">
                        <bar-rating (onFocus)="focusFunction($event)" (focusout)="focusOutFunction($event)"
                            [formControlName]="field.key" [required] [(rate)]="field.value" [(ngModel)]="field.value"
                            (rateChange)="getRatingValue($event)" [max]="field.max" [theme]="'horizontal'"></bar-rating>
                    </div>
                    <div class="rating--msg">
                        <!-- <div class="rating--low">Not at all likely</div> -->
                        <div class="rating--high" *ngFor="let item of counter(field.max);let i=index">{{i+1}}</div>
                    </div>
                    <div *ngIf="isInvalid(formGroup.controls[field.key],
        formSubmitAttempt)" class="invalid-feedback">
                        {{ field.emptyValueMessage }}
                    </div>
                </div>
                <!--rating block stats here-->

                <!--radio-rating field start-->
                <ng-container *ngIf="field.showontoggle == true">
                    <div *ngSwitchCase="'radio-rating'" class="form__field rating-radio" (change)="taskSelect($event)">
                        <div class="radio-rating-block">
                            <div class="radio" [ngClass]="each.className != '' ? each.className : '' "
                                *ngFor="let each of field.fields">
                                <input (focus)="focusFunction($event)" (focusout)="focusOutFunction($event)"
                                    id="{{field.key}} + {{each.value}}" type="radio" [(ngModel)]="field.value"
                                    [name]="field.key" [value]="each.value" [formControlName]="field.key"
                                    [attr.disabled]="!field.editable === true || each.disable === true || null">
                                <label for="{{field.key}} + {{each.value}}" class="radio-label"
                                    [ngClass]="field.editable == false?'disable apptType'+each.value:'apptType'+each.value"><span
                                        class="icon--styles">
                                    </span></label>
                            </div>
                        </div>
                        <div class="rating--msg">
                            <div class="radio-rating--low">Not at all likely</div>
                            <div class="radio-rating--high">Extremely likely</div>
                        </div>
                        <div *ngIf="isInvalid(formGroup.controls[field.key],
        formSubmitAttempt)" class="invalid-feedback">
                            {{ field.emptyValueMessage }}
                        </div>
                    </div>
                </ng-container>
                <!--radio-rating field end-->

                <!-- inline text with input field starts here -->
                <div *ngSwitchCase="'inlineText'" class="confirmation">
                    <input (focus)="focusFunction($event)" (focusout)="focusOutFunction($event)" class="name--field"
                        [attr.maxLength]="field.maxLength" [formControlName]="field.key" [type]="field.type"
                        [(ngModel)]="field.value" value="{{ field.value }}" (keypress)="onKeyPress($event, field)"
                        [attr.disabled]="!field.editable === true || null"
                        [placeholder]="field.placeholder" />{{field.confirmationMsg}}
                    <!--text field validations start-->
                    <div *ngIf="field.updateOn == 'blur'">
                        <div *ngIf="(!formGroup.controls[field.key].value) && !formSubmitAttempt"
                            class="invalid-feedback">
                            <div>{{ field.emptyValueMessage }}</div>
                        </div>
                        <div *ngIf="formGroup.controls[field.key].value && formGroup.controls[field.key].status == 'INVALID' && !formSubmitAttempt"
                            class="invalid-feedback">
                            <div>{{ field.validationValue }}</div>
                        </div>
                    </div>

                    <div class="error--msg">
                        <div *ngIf="(!formGroup.controls[field.key].value) &&
                  formSubmitAttempt" class="invalid-feedback">
                            <div>{{ field.emptyValueMessage }}</div>
                        </div>
                        <div *ngIf="formGroup.controls[field.key].value &&
                        isInvalid(formGroup.controls[field.key], formSubmitAttempt) && formGroup.controls[field.key].errors.timeValidity"
                            class="invalid-feedback">
                            <div>{{ field.validationValue1 }}</div>
                        </div>
                        <div *ngIf="formGroup.controls[field.key].value &&
              isInvalid(formGroup.controls[field.key], formSubmitAttempt) && !formGroup.controls[field.key].errors.timeValidity"
                            class="invalid-feedback">
                            <div>{{ field.validationValue }}</div>
                        </div>
                    </div>


                    <div class="help__text" *ngIf="field.helpText">{{field.helpText}}</div>
                    <!--text field validations end-->

                </div>
                <!-- signatur field ends -->

                <!-- Confirmation text field starts field starts -->
                <div *ngSwitchCase="'confirmation'" class="confirmation">
                    I<input (focus)="focusFunction($event)" (focusout)="focusOutFunction($event)" class="name--field"
                        [attr.maxLength]="field.maxLength" [formControlName]="field.key" [(ngModel)]="field.value"
                        value="{{ field.value }}" [attr.disabled]="!field.editable === true || null"
                        [placeholder]="field.placeholder" />{{field.confirmationMsg}}
                    <!--text field validations start-->
                    <div *ngIf="field.updateOn == 'blur'">
                        <div *ngIf="(!formGroup.controls[field.key].value) && !formSubmitAttempt"
                            class="invalid-feedback">
                            <div>{{ field.emptyValueMessage }}</div>
                        </div>
                        <div *ngIf="formGroup.controls[field.key].value && formGroup.controls[field.key].status == 'INVALID' && !formSubmitAttempt"
                            class="invalid-feedback">
                            <div>{{ field.validationValue }}</div>
                        </div>
                    </div>

                    <div class="error--msg">
                        <div *ngIf="(!formGroup.controls[field.key].value) &&
                  formSubmitAttempt" class="invalid-feedback">
                            <div>{{ field.emptyValueMessage }}</div>
                        </div>
                        <div *ngIf="formGroup.controls[field.key].value &&
              isInvalid(formGroup.controls[field.key], formSubmitAttempt)" class="invalid-feedback">
                            <div>{{ field.validationValue }}</div>
                        </div>
                    </div>


                    <div class="help__text" *ngIf="field.helpText">{{field.helpText}}</div>
                    <!--text field validations end-->

                </div>
                <!-- Confirmation text field ends -->

                <!-- Confirmation text field starts field starts -->
                <div *ngSwitchCase="'confirmationAre'" class="confirmation">
                    We here at<input (focus)="focusFunction($event)" (focusout)="focusOutFunction($event)"
                        class="name--field" [attr.maxLength]="field.maxLength" [formControlName]="field.key"
                        [(ngModel)]="field.value" value="{{ field.value }}"
                        [attr.disabled]="!field.editable === true || null"
                        [placeholder]="field.placeholder" />{{field.confirmationMsg}}
                    <!--text field validations start-->
                    <div *ngIf="field.updateOn == 'blur'">
                        <div *ngIf="(!formGroup.controls[field.key].value) && !formSubmitAttempt"
                            class="invalid-feedback">
                            <div>{{ field.emptyValueMessage }}</div>
                        </div>
                        <div *ngIf="formGroup.controls[field.key].value && formGroup.controls[field.key].status == 'INVALID' && !formSubmitAttempt"
                            class="invalid-feedback">
                            <div>{{ field.validationValue }}</div>
                        </div>
                    </div>

                    <div class="error--msg">
                        <div *ngIf="(!formGroup.controls[field.key].value) &&
                formSubmitAttempt" class="invalid-feedback">
                            <div>{{ field.emptyValueMessage }}</div>
                        </div>
                        <div *ngIf="formGroup.controls[field.key].value &&
                isInvalid(formGroup.controls[field.key], formSubmitAttempt)" class="invalid-feedback">
                            <div>{{ field.validationValue }}</div>
                        </div>
                    </div>


                    <div class="help__text" *ngIf="field.helpText">{{field.helpText}}</div>
                    <!--text field validations end-->

                </div>
                <!-- Confirmation text field ends -->
                <!-- signature field starts field starts -->
                <div *ngSwitchCase="'signature'" class="signature">
                    <input (focus)="focusFunction($event)" (focusout)="focusOutFunction($event)"
                        [ngClass]="{ disable: !field.editable }" [readonly]="!field.editable" class="signature--field"
                        [attr.maxLength]="field.maxLength" [formControlName]="field.key" value="{{ field.value }}"
                        [placeholder]="field.placeholder" />
                    <p>(Patient/Parent/Legal guardian) Signature</p>
                    <!--text field validations start-->
                    <div *ngIf="field.updateOn == 'blur'" class="error--msg">
                        <div *ngIf="(!formGroup.controls[field.key].value) && !formSubmitAttempt"
                            class="invalid-feedback">
                            <div>{{ field.emptyValueMessage }}</div>
                        </div>
                        <div *ngIf="formGroup.controls[field.key].value && formGroup.controls[field.key].status == 'INVALID' && !formSubmitAttempt"
                            class="invalid-feedback">
                            <div>{{ field.validationValue }}</div>
                        </div>
                    </div>

                    <div class="error--msg">
                        <div *ngIf="(!formGroup.controls[field.key].value) &&
                  formSubmitAttempt" class="invalid-feedback">
                            <div>{{ field.emptyValueMessage }}</div>
                        </div>
                        <div *ngIf="formGroup.controls[field.key].value &&
              isInvalid(formGroup.controls[field.key], formSubmitAttempt)" class="invalid-feedback">
                            <div>{{ field.validationValue }}</div>
                        </div>
                    </div>


                    <div class="help__text" *ngIf="field.helpText">{{field.helpText}}</div>
                    <!--text field validations end-->

                </div>
                <!-- signatur field ends -->

                <!--checkbox-tik field start-->
                <div *ngSwitchCase="'checkbox-tik'" class="form__field checkbox-tik" (onChange)="taskSelect($event)">

                    <div class="checkbox-flex">
                        <div class="checkbox-tik">
                            <input (focus)="focusFunction($event)" (focusout)="focusOutFunction($event)" type="checkbox"
                                id="{{field.key}}" [name]="field.key" [formControlName]="field.key"
                                (change)="field.selected = !field.selected; checkboxchange(field,i)"
                                [(ngModel)]="field.value" [checked]="field.value"
                                [attr.disabled]="!field.editable === true || null">
                            <label for="{{field.key}}" [ngClass]="field.editable == false?'tik-disable':''"></label>
                        </div>
                        <div>
                            <div class="checkbox-points agreement-info" *ngIf="field.list">
                                <div *ngFor="let item of field.list">
                                    <div class="agreement-info__header">{{item.label}}</div>
                                    <div>
                                        <ul class="agreement-info__text">
                                            <li *ngFor="let point of item.list">
                                                {{point}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="label {{field.editable ? '':'disable'}}">{{field.labeltext}}</div>
                        </div>
                    </div>

                    <div *ngIf="!formGroup.controls[field.key].value && field.emptyValueMessage && formSubmitAttempt"
                        class="invalid-feedback">
                        {{ field.emptyValueMessage }}
                    </div>
                </div>
                <!--checkbox-tik field end-->
                <!--checkbox-tik-one field start-->
                <div *ngSwitchCase="'checkbox-tik-one'" class="form__field checkbox-tik"
                    (onChange)="taskSelect($event)">

                    <div class="checkbox-flex">
                        <div class="checkbox-tik">
                            <input (focus)="focusFunction($event)" (focusout)="focusOutFunction($event)" type="checkbox"
                                id="{{field.key}}" [name]="field.key" [formControlName]="field.key"
                                (change)="field.selected = !field.selected; checkboxchangeOne(field,i)"
                                [(ngModel)]="field.value" [checked]="field.value"
                                [attr.disabled]="!field.editable === true || null">
                            <label for="{{field.key}}" [ngClass]="field.editable == false?'tik-disable':''"></label>

                        </div>
                        <div>
                            <div class="checkbox-points agreement-info" *ngIf="field.list">
                                <div *ngFor="let item of field.list">
                                    <div class="agreement-info__header">{{item.label}}</div>
                                    <div>
                                        <ul class="agreement-info__text">
                                            <li *ngFor="let point of item.list">
                                                {{point}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="label {{field.editable ? '':'disable'}}">{{field.labeltext}}</div>
                        </div>
                    </div>

                    <div *ngIf="!formGroup.controls[field.key].value && field.emptyValueMessage && formSubmitAttempt"
                        class="invalid-feedback">
                        {{ field.emptyValueMessage }}
                    </div>
                    <div *ngIf="field.showValidationMsg" class="invalid-feedback"> {{field.ValidationMsg}}</div>
                </div>
                <!--checkbox-tik-one field end-->
                <!--Timer field start-->
                <ng-container *ngIf="field.showontoggle == true">
                    <div *ngSwitchCase="'timer'" class="form__field calendar {{field.key}}">
                        <p-calendar inputId="{{field.key}}" [timeOnly]="field.isTime"
                            [ngClass]="field.editable == false?'calendar-disable':''" [formControlName]="field.key"
                            placeholder="{{field.placeholder}}" [disabled]="!field.editable"
                            (onSelect)="onSelectMethod($event, field.key)">
                        </p-calendar>
                        <div *ngIf="(!formGroup.controls[field.key].value) &&
                      formSubmitAttempt" class="invalid-feedback">
                            <div>{{ field.emptyValueMessage }}</div>
                        </div>
                        <div *ngIf="formGroup.controls[field.key].value &&
                isInvalid(formGroup.controls[field.key], formSubmitAttempt) && formSubmitAttempt && formGroup.get(field.key).errors.time"
                            class="invalid-feedback">

                            <div>{{ field.validationValue }}</div>
                        </div>

                    </div>
                </ng-container>
                <!--Timer field end-->
                <!--checkbox field start-->
                <div *ngSwitchCase="'checkbox'" class="form__field checkbox" (onChange)="taskSelect($event)">
                    <input (focus)="focusFunction($event)" (focusout)="focusOutFunction($event)" type="checkbox"
                        [formControlName]="field.key" [readonly]="!field.editable"
                        [(ngModel)]="formGroup.controls[field.key].value" />
                    <div *ngIf="!formGroup.controls[field.key].value && formSubmitAttempt" class="invalid-feedback">
                        {{ field.emptyValueMessage }}
                    </div>
                </div>
                <!--checkbox field end-->
                <ng-container>
                    <div *ngSwitchCase="'uploadsDocuments'" class="form__field uploads_documents">
                        <div class="employee__id-proofs-documents">
                            <ng-container *ngIf="field.value">
                                <div class="employee__id-document internal-uploads"
                                    *ngFor="let uploadsFiles of field.value; let i=index">
                                    <div class="upload-document__block">
                                        <p>{{uploadsFiles.file.name | ellipsis: 23}}</p>
                                        <p>.{{uploadsFiles.file.extenstion}}</p>
                                    </div>
                                    <div class="upload-close-btn" *ngIf="field.value.length !==1">
                                        <app-svg [name]="'cancel-appointment'"
                                            (click)="deleteFileUpload(uploadsFiles, field.isEditAndView)">
                                        </app-svg>
                                    </div>
                                    <div class="employee__id-document-actions">
                                        <a [href]="uploadsFiles.docUrl" target="_blank" title="View" class="view-icon">
                                            <app-svg [name]="'view-upload'"></app-svg>
                                        </a>
                                        <p class="down-arrow"
                                            (click)="donwloadFile(uploadsFiles.docUrl,uploadsFiles,false)"
                                            title="Download">
                                            <app-svg [name]="'down-arrow'"></app-svg>
                                        </p>
                                    </div>
                                </div>
                            </ng-container>
                            <div class="employee__id-document">
                                <app-multiple-uploads [fileTypes]="uploadFilesData"
                                    (uploads)="uploadFunctionCall($event)">
                                </app-multiple-uploads>
                            </div>
                        </div>
                        <div *ngIf="formGroup.controls[field.key].value &&
                isInvalid(formGroup.controls[field.key], formSubmitAttempt) && formSubmitAttempt && formGroup.get(field.key).errors"
                            class="invalid-feedback">
                            <div>{{ field.validationValue }}</div>
                        </div>
                    </div>
                </ng-container>
                <!-- Custome checboxes start-->
                <ng-container>
                    <div *ngSwitchCase="'custom-check'" class="form__field">
                        <div class="radio" *ngFor="let task of field.options; let taskID = index">
                            <p-checkbox [value]="task.value" label="{{task.name}}" [ngModelOptions]="{standalone: true}"
                                [(ngModel)]="task.isSelected" binary="true" (onChange)="getChckedvalue(field)"
                                [disabled]="!task.editable">
                            </p-checkbox>
                        </div>
                    </div>
                </ng-container>
                <!-- Custome checboxes end -->
                <div *ngSwitchCase="'groupCheckbox'" class="filter-options">
                    <div class="form-group-checkboxes">
                        <app-check-boxes-group [groupCheckBox]='field' [formFieldIndex]='i'
                            (categorySelectValues)="categorySelect($event)"
                            (optionSelectValue)="optionSelectEvent($event)">
                        </app-check-boxes-group>
                    </div>
                </div>
                <!--profile uplo-->
                <div *ngSwitchCase="'profile'">
                    <div class="upload__profile__div">
                        <div class="image__edit__div" *ngIf="!field.showImage">
                            <label for="file">
                                <app-svg class="" [name]="'profile-edit'"></app-svg>
                            </label>
                            <input style="visibility: hidden;" type="file" id="file"
                                accept="image/x-png,image/jpg,image/jpeg" (change)="profileUpload($event,field)">
                        </div>
                        <div *ngIf="field.showImage">
                            <label class="upload__profile__label" for="file">
                                <div class="profile_upload">
                                    <img id="profile" [src]="field.initialImage" alt="Profile">
                                    <app-svg [name]="'plus-circle-black'"></app-svg>
                                </div>
                            </label>
                            <input style="display: none;" type="file" id="file"
                                accept="image/x-png,image/jpg,image/jpeg" (change)="profileUpload($event,field)">
                        </div>
                        <div class="image__styles" *ngIf="!field.showImage">
                            <img class="logo__style" alt="logo" [src]="field.value">
                        </div>
                    </div>
                    <div>
                        <div *ngIf="!formGroup.controls[field.key].value && formSubmitAttempt" class="invalid-feedback">
                            {{ field.emptyValueMessage }}
                        </div>
                        <div *ngIf="formGroup.get(field.key).value && isInvalid(formGroup.get(field.key), true) && formGroup.get(field.key).errors.size"
                            class="invalid-feedback">
                            <div>{{ field.validationSize }}</div>
                        </div>
                    </div>
                </div>
                <!---->

                <!-- Date field starts -->
                <div *ngSwitchCase="'currentDate'" class="current-date">
                    <div>
                        {{field.value}}
                    </div>

                </div>
                <!-- Date field ends -->

                <!-- action field starts -->
                <div class="form__action">
                    <div *ngSwitchCase="'submitButton'" class="primary__button__wrapper">
                        <div class="cancel__button__wrapper" [ngClass]="field.className">
                            <button class="common-btn-style" (click)="actionField($event)" [type]="field.type">
                                {{ field.text }}
                            </button>
                        </div>
                    </div>
                </div>
                <!-- action field ends -->

                <!-- file upload section starts -->
                <div *ngSwitchCase="'documentUpload'" class="upload-section form__field__wrapper">
                    <div class="form__field multi_upload patient__upload__field">
                        <p-fileUpload name="patient_uploads[]" #fileUpload [auto]="true"
                            chooseLabel="Choose files to Upload"
                            accept=".jpg, .png, .gif, .jpeg, .tiff, .psd, .pdf, .esp, .ai, .indd, .raw, .heic, .heif"
                            maxFileSize="10000000" multiple="multiple" (onSelect)="onUpload($event,fileUpload, field)"
                            (onRemove)="filesRemoved($event,fileUpload, field)"
                            (onClear)="allFilesCleared($event, field)" fileLimit="5">
                            <ng-template pTemplate="content">
                                <p class="content-message">Or Drag and Drop them here
                                    <span class="helpTxt">(maximum 5 allowed)</span>
                                </p>
                            </ng-template>
                        </p-fileUpload>
                    </div>
                    <div *ngIf="isInvalid(formGroup.controls[field.key], formSubmitAttempt) && formSubmitAttempt"
                        class="invalid-feedback">
                        {{ field.emptyValueMessage }}
                    </div>
                    <div *ngIf="getFileNames(field).length" class="uploaded_documents">
                        <div class="label">Uploaded documents</div>
                        <div class="documents">
                            <div *ngFor="let task of getFileNames(field)" class="each_document">
                                {{task}}
                            </div>
                        </div>
                    </div>
                </div>
                <!-- file upload section starts -->

                <!-- action field starts -->
                <div class="form__action">
                    <div *ngSwitchCase="'cancelButton'" class="primary__button__wrapper">
                        <div class="cancel__button__wrapper" [ngClass]="field.className">
                            <button class="cancel-btn" [ngClass]="field.className" (click)="cancel($event)"
                                [type]="field.type">
                                {{ field.text }}
                            </button>
                        </div>
                    </div>
                </div>
                <!-- action field ends -->
            </div>
        </div>
    </ng-container>
</form>
<!--custom form end-->