<div class="email__template--section">
    <div class="email__template--info">
        <div>
            <img src="assets/images/pngs/logo.png" alt="Logo" class="email__template--logo" />
            <div class="email__template--main-info">
                <div class="email__template-sub-info">
                    <div class="email__template--highlight-text">Create a</div>
                    <div class="email__template--name pb-10">New Password</div>
                    <div class="need__help--content">
                        Hi there. Need some help? You can create a new password by clicking
                        the link below.
                    </div>
                    <a href="#" class="common-btn-style">Create Password</a>
                    <div class="pb-10">If you need assistance please email us at</div>
                    <div>
                        <a href="http://www.newteledoc.com/activateaccount">http://www.newteledoc.com/Createpassword</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-template-footer></app-template-footer>
</div>