import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';
import { CognitoService } from '@app/common/services/cognito.service';
import { API_URLS } from '@app/common/constants/api-urls.constant';
import { IBreadCrumbI, IformDataI, IGetMethodPayload, IPostMethodPayload } from '@app/common/constants/typeInterfaces.constants';
import { HTTPMethods, TOAST_STATUSES } from '@app/common/constants/util.constant';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { DataService } from '@app/common/services/data.service';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';

@Component({
  selector: 'app-subsentence-forms-wrapper',
  templateUrl: './subsentence-forms-wrapper.component.html',
  styleUrls: ['./subsentence-forms-wrapper.component.scss']
})
export class SubsentenceFormsWrapperComponent implements OnInit {
  currentStep = {
    label: 'Patient Agrement',
    id: 'tmsQuestionnaire',
    img: 'one',
    visited: true,
    notVisisted: false,
    completed: false,
    onPage: true
  };
  stepDetails = [
    {
      label: 'Pain Treatment with Opioid Medications',
      id: 'tmsQuestionnaire',
      img: 'HIPPA Form',
      visited: true,
      notVisisted: false,
      completed: false,
      onPage: true
    },
    {
      label: 'Controlled Substances Treatment Program Statement',
      id: 'hamQuestionnaire',
      img: 'HIPPA Form',
      visited: false,
      notVisisted: true,
      completed: false,
      onPage: false
    },
    {
      label: 'AGREEMENT FOR LONG TERM CONTROLLED SUBSTANCE PRESCRIPTIONS',
      id: 'agreeMentForm',
      img: 'HIPPA Form',
      visited: false,
      notVisisted: true,
      completed: false,
      onPage: false
    },
  ];
  currentFormIndex: number = 0;
  saveFormData: boolean = false;
  hideSubmit: boolean = false;
  jsonData: any = [
    {},
    {},
    {},
  ];
  isAdmin: boolean = false;
  appointmentDetails: any;
  appDetailsDisplay: boolean = false;
  documentDetails: Record<string, any>;
  appointmentId: any;
  requestId: any;
  patientId: any;
  patientsList: Array<any>;
  patientData: Array<any>;
  adhdRequests: Array<any>;
  patientName: any;
  constructor(   private router: Router,
    private observableHelperService: ObservableHelperService,
    private cognitoService: CognitoService,
    private http: HttpService,
    private dataService: DataService,
    private formService: CustomFormHandlerService,
    private route: ActivatedRoute,) {     this.route.queryParams.subscribe(queryParams =>{
      if(queryParams.requestId){
        this.requestId = queryParams.requestId;
        this.getRequestedDocumentDetails();
      }else if (queryParams.patientId){
        this.patientId = queryParams.patientId;
        this.getRequestId();
      }else{
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR,TOAST_MESSAGES.ADHD_REQUEST_NOT_FOUND_MSG);
        this.back();
      }
    })}
  /**
   * 
   * @param userId :User Id
   * This function is for getting list of patient
   */
   getPatientsList(userId){
    const payload:IGetMethodPayload = {
      type: HTTPMethods.GET,
      url: API_URLS.GET_PATIENT_LIST,
      isDeveloper: true,
      pathVariables: [userId]
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res) && res) {
        this.patientsList = res.data.patients;
        this.patientData = this.patientsList.filter(each => parseInt(each.patientId) === parseInt(this.patientId));
        if(this.patientData && this.patientData.length){
          this.adhdRequests = this.patientData[0].pendingDocumentRequest.filter(eachReq => eachReq.documentIds === '[23]');
          if(this.adhdRequests && this.adhdRequests.length){
            this.requestId = this.adhdRequests[0].requestId;
            this.getRequestedDocumentDetails();
          } else {
            this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.ADHD_REQUEST_NOT_FOUND);
            this.router.navigate([ROUTE_PATHS.ADD_MyACCOUNT]);
          }
        }
        console.log(this.patientData);
      }else{
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.PATIENT_LIST_ERROR_MSG);
      }
    });
  }
  // This function is for getting the request document details
  getRequestedDocumentDetails() {
    const payload:IGetMethodPayload = {
      type: HTTPMethods.GET,
      url: API_URLS.REQUEST_DOCUMENT_DETAILS,
      isDeveloper: true,
      pathVariables: [this.requestId],
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.documentDetails = res.data;
        this.patientName = `${this.documentDetails.firstName} ${this.documentDetails.lastName}`;
      } else {
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.DOCUMENT_DATA_REQUEST_ERROR_MSG);
      }
    });
  }

  ngOnInit() {
  }
  getRequestId(){
    this.cognitoService.getUser().then(user => {
      this.getPatientsList(user.userId);
    });
  }
  getActionText() {
    return this.currentFormIndex < (this.stepDetails.length - 1) ? 'Next' : this.isAdmin && !this.appointmentDetails.isOffline ? 'Send to Patient' : 'Submit';
  }
  getStepIndex(event) {
    this.stepIndicatorClicked(event);
  }
  stepIndicatorClicked(index: number): void {
    if (index < 3) { this.hideSubmit = false; }
    if (index > this.currentFormIndex) {
      this.saveFormData = true;
      this.saveForm();
    } else {
      this.scrollTop();
      this.stepDetails[this.currentFormIndex].onPage = false;
      this.currentFormIndex = index;
      this.currentStep = this.stepDetails[index];
      this.stepDetails.forEach((each, ind) => {
        if (ind >= this.currentFormIndex) {
          each.completed = false;
        }
      });
      this.stepDetails[index].onPage = true;
    }
  }

  forwardClicked() {
    this.saveForm();
  }
  backClicked() {
    this.back();
  }
  scrollTop() {
    window.scroll({
      top: 0,
      left: 0,
    });
  }
  getFormData(event) {
    if (event && !this.stepDetails[this.currentFormIndex].completed) {
      console.log(event);
      this.jsonData[this.currentFormIndex] = event;
      this.stepDetails[this.currentFormIndex].completed = true;
      this.stepDetails[this.currentFormIndex].onPage = false;
      this.saveFormData = false;
    } else {
      if (this.stepDetails[this.currentFormIndex].completed && this.currentFormIndex !== this.stepDetails.length - 1) {
        this.stepDetails[this.currentFormIndex].completed = false;
      }
    }
  }

  saveForm() {
    this.saveFormData = true;
    if (this.currentFormIndex === (this.stepDetails.length - 1)) {
      this.currentStep = this.stepDetails[this.currentFormIndex];
      this.scrollTop();
      window.setTimeout(() => {
        this.navigateToDetails();
      }, 10);
    } else {
      window.setTimeout(() => {
        if (this.stepDetails[this.currentFormIndex].completed) {
          this.currentFormIndex++;
          this.currentStep = this.stepDetails[this.currentFormIndex];
          this.stepDetails[this.currentFormIndex].onPage = true;
          this.stepDetails[this.currentFormIndex].notVisisted = false;
          this.stepDetails[this.currentFormIndex].visited = true;
          this.scrollTop();
        }
      }, 5);
      if (this.currentFormIndex === 2) { this.hideSubmit = true; }
    }
    setTimeout(() => {
      if (document.getElementsByClassName('invalid-feedback')[0]) {
        document.getElementsByClassName('invalid-feedback')[0].scrollIntoView({ block: 'center' });
      }
    }, 2);
  }
  navigateToDetails(){
    let finalData;
    finalData = {
      consentFor: this.patientName,
      consentSignature: this.jsonData[2].status.value.medication_signature
    }
    const payloadData = {
      type: 'POST',
      url: 'substanceForm',
      isDeveloper: true,
      body: finalData,
      pathVariables:[this.documentDetails.patientId,this.requestId],
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res) && res) {
        this.observableHelperService.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.SUBSTANCE_CONSENT_SUCCESS_MSG);
        this.router.navigate([ROUTE_PATHS.ADD_MyACCOUNT]);
      }else{
        let message = TOAST_MESSAGES.CONTROL_SBSTANCE_ERROR_MSG
        message = (res.error) ? res.error.message : message;
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
  setCurrentForm(index) {
    this.currentStep = this.stepDetails[index];
    this.stepDetails[index].completed = false;
    this.stepDetails[index].onPage = true;
  }
  cancel() {
    if (this.appDetailsDisplay) {
      this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.APPOINTMENTS}/${this.appointmentId}`]);
    } else {
      this.router.navigate([`${ROUTE_PATHS.ADD_MyACCOUNT}`]);
    }
    // this.resetServiceConfigData();
  }
  back() {
    if (this.currentFormIndex === 0) {
      this.scrollTop();
      this.stepDetails[this.currentFormIndex].onPage = false;
      this.currentFormIndex = 0;
      this.setCurrentForm(this.currentFormIndex);
    } else {
      this.stepDetails[this.currentFormIndex].onPage = false;
      this.currentFormIndex--;
      this.scrollTop();
      this.setCurrentForm(this.currentFormIndex);
    }
    this.hideSubmit = false;
  }
  resetSaveFormParity() {
    window.setTimeout(() => {
      this.saveFormData = false;
    }, 0);
    return true;
  }
}
