import { Component, OnInit } from '@angular/core';
import { WPAPIService } from '@app/common/services/wpapi.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';

@Component({
  selector: 'app-blog-content',
  templateUrl: './blog-content.component.html',
  styleUrls: ['./blog-content.component.scss']
})
export class BlogContentComponent implements OnInit {


  moreText = {
    moreTextLink: 'Read more'
  };

  colorClass = [
    'yellow',
    'pink',
    'purple',
    'green'
  ];
  activeClass = [
    'active'
  ];

  twoWordsString: any;
  remainWordsString: any;
  http: any;
  posts: any = [];
  page = null;
  pages = null;
  totalNumberOfPagesLength: any;
  constructor(private wpapi: WPAPIService, private router: Router, private activeRoute: ActivatedRoute) {
    this.page = this.activeRoute.snapshot.queryParamMap.get('page') ? this.activeRoute.snapshot.queryParamMap.get('page') : 1;
  }
  ngOnInit() {
    this.wpapi.posts(`per_page=4&page=${this.page}`).subscribe(posts => {
      // tslint:disable-next-line: radix
      this.pages = new Array(parseInt(posts.headers.get('X-WP-TotalPages')));
      this.totalNumberOfPagesLength = this.pages.length;
    });

    if (this.totalNumberOfPagesLength) {
      this.getPostsdata(this.page);
    } else {
      this.getPostsdata(this.page);
    }
  }
  updatedPages(page) {
    this.page = page;
    this.getPostsdata(page);
  }

  getPostsdata(pages) {
    let offset = (this.page - 1) * 4 + 1;
    if (this.page === 1) {
      offset = 1;
    }
    this.wpapi.posts(`per_page=4&page=${pages}&offset=${offset}`).subscribe(posts => {
      this.posts = posts.body;
    });
  }

  navigateDetailsPage(event, postid) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.router.navigate([`blog/details/${postid}`]);
  }
}
