<div class="email__template--section">
    <div class="email__template--info">
        <div>
            <img src="assets/images/pngs/logo.png" alt="logo" class="email__template--logo" />
            <div class="email__template--main-info">
                <div class="email__template-sub-info">
                    <div class="email__template--highlight-text">Cancelled Appointment</div>
                    <div class="email__template--name pb-10">Monday, July 6 at 2:30PM</div>
                    <div >Hi Vanka!</div>
                    <div class="need__help--content"> 
                        Your appointment with Dr. Seema Amin, OD, has been cancelled at your request. If this is an error, please call us at (855) 962-3621 or reply to this email.
                    </div>
                    <div class="appointment__details-section">
                        <div class="appointment__details-label">Appointment Details</div>
                        <div class="details-section">
                            <div class="appointment__details--picture">
                                <img alt="Docotor" src="assets/images/pngs/Doctor.png"/>
                            </div>
                            <div class="appointment-details">
                                <div class="appointment__details--name details-data">DR. SEEma Amin, OD</div>
                                <div class="appointment__details--date details-data details-flex">
                                    <div><app-svg [name]="'calendar-color'" class="calendar-icon details-icon"></app-svg></div>
                                    <div>06 March 2020</div>
                                </div>
                                <div class="appointment__details--time details-data details-flex">
                                    <div><app-svg [name]="'clock-color'" class="clock-icon details-icon"></app-svg></div>
                                    <div>07:30 AM</div>
                                </div>
                                <div class="appointment__details--place details-flex">
                                    <div><app-svg [name]="'office'" class="office-icon details-icon" ></app-svg></div>
                                    <div>Alexandria Office</div>
                                </div>
                            </div>
                            <div class="appointment__details--map">
                                <p-gmap #gmap (onMapReady)="setMap($event)" [options]="options" [overlays]="overlays" [style]="{'width':'100%','height':'100%'}" class="google-map"></p-gmap>
                            </div>
                        </div>
                    </div>
                    <div class="btn__heading--text">Would you like to reschedule appointment</div>
                    <a href="#" class="common-btn-style">Reschedule</a>
                    <div>
                        <div class="pb-10">Have questions? Check out our<span><a href="#"> Knowledge Base</a></span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-template-footer></app-template-footer>
</div>

