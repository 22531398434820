<section class="onboard-wrapper__section">
    <div class="image-heading">
        <!--image and heading-->
        <div class="heading">
            <span class="common-header">PATIENT CENTERED,</span><br>
            INNOVATIVE,<br>
            MENTAL HEALTH CARE
        </div>
        <div class="image">
            <img class="main-background" alt="login background" src="assets/images/pngs/login-background.png" loading="lazy"/>
            <img class="background-leaf" alt="leaf image" src = "assets/images/pngs/loginpage-leaf-1.webp" loading="lazy"/>
        </div>
    </div>
    <div class="onboard-form__container">
        <app-onboard-form-wrapper [isAppointment]="isAppointment"></app-onboard-form-wrapper>
    </div>
</section>

<app-common-popup (showHideCancelPopup)='cancelPopup($event)' *ngIf="showUnsubscribePopUp">
    <div class="popup-content-block">
        <div class="confirm-message">Are you sure you want to unsubscribe the email?</div>
        <div class="btn-actions">
            <div class="cancel-btn" (click)="hidePopup(false)">Cancel</div>
            <div class="common-btn-style" (click)="hidePopup(true)">Proceed</div>
        </div>
    </div>
</app-common-popup>