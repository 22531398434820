<section class="hippa__notice__form">
    <div class="container">
        <div class="hippa__description">
            {{hippaData.description1}}
        </div>
        <div>
            <ul class="">
                <li *ngFor="let list of hippaData.list">
                    {{list}}
                </li>
            </ul>
        </div>
        <div class="hippa__description">
            <span class="tms--click">{{hippaData.description2}} </span>
        </div>
        <div>
            <ul class="">
                <li *ngFor="let list of hippaData.list1">
                    {{list}}
                </li>
            </ul>
        </div>
        <div class="hippa__description">
            <span class="tms--click">{{hippaData.description3}} </span>
        </div>
        <div>
            <ul class="">
                <li *ngFor="let list of hippaData.list2">
                    {{list}}
                </li>
            </ul>
        </div>
        <div class="hippa__description">
            <span class="tms--click">{{hippaData.description4}} </span>
        </div>
        <div>
            <ul class="">
                <li *ngFor="let list of hippaData.list3">
                    {{list}}
                </li>
            </ul>
        </div>
        <div class="hippa__description">
            <span class="tms--click">{{hippaData.description5}} </span>
        </div>
        <div>
            <ul class="">
                <li *ngFor="let list of hippaData.list4">
                    {{list}}
                </li>
            </ul>
        </div>
        <div class="hippa__description">
            <span class="tms--click">{{hippaData.description6}} </span>
        </div>
        <div>
            <ul class="">
                <li *ngFor="let list of hippaData.list5">
                    {{list}}
                </li>
            </ul>
        </div>
        <div class="hippa__description">
            <span class="tms--click">{{hippaData.description7}} </span>
        </div>
        <div>
            <ul class="">
                <li *ngFor="let list of hippaData.list6">
                    {{list}}
                </li>
            </ul>
        </div>
    </div>
    <div>
        <app-custom-form [checkFormInfo]="saveFormData" (submitEvent)="getFormData($event)" [formData]="substanceForm"></app-custom-form>
    </div>
</section>
