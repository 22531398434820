<form [formGroup]="formGroup" class="form__container">
    <div class='svg__wrapper delete__service'>
        <app-svg [name]="'delete'" class="cursor__pointer del" (click)="deleteService()"></app-svg>
    </div>
    <div class="custom-form">
        <ng-container *ngFor="let field of formInputs;let i=index">
            <div class="form--fields" *ngIf="field.showontoggle == true" id="{{field.idName}}"
                [ngClass]="field.className != '' ? field.className : '' ">
                <div [ngSwitch]="field.controlType" class="form__field__wrapper {{field.controlType}}">

                    <div class="label" *ngIf="field.label && field.type !== 'header' && field.showontoggle == true">
                        {{field.label}}</div>

                    <div *ngSwitchCase="'labelValue'">
                        <label>{{field.value}}</label>
                    </div>
                    <!-- text field starts -->
                    <ng-container *ngIf="field.showontoggle == true">
                        <div *ngSwitchCase="'inputText'" class="form__field">
                            <div class="input-and-close" #target (clickOutside)="field.key==='treatment' && op.render && onClickedOutside($event,op);">
                                <input class="text-input input" [type]="field.type" [attr.maxLength]="field.maxLength"
                                    [formControlName]="field.key" [ngClass]="{ disable: !field.editable }"
                                    [placeholder]="field.placeholder" [readonly]="!field.editable" (keyup)="searchText($event,field,op,target)" />
                            </div>
                             <!--Overlay for service-list-->
                            <p-overlayPanel #op [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" [style]="{'width':'400px'}">
                                <div class="searchResultsDiv {{filteredArr.length <4?'noScroll':''}}">
                                    <div class="searchTextDiv" *ngFor="let service of filteredArr" (click)="serviceClick($event,service)">{{service.appointmentTypeName}}</div>
                                </div>
                            </p-overlayPanel>
                            <!--text field validations start-->
                            <div *ngIf="field.updateOn == 'blur'">
                                <div *ngIf="!(formGroup.controls[field.key].valid || formGroup.controls[field.key].value) && !formSubmitAttempt"
                                    class="invalid-feedback">
                                    <div>{{ field.emptyValueMessage }}</div>
                                </div>
                                <div *ngIf="formGroup.controls[field.key].value && formGroup.controls[field.key].status == 'INVALID' && !formSubmitAttempt"
                                    class="invalid-feedback">
                                    <div>{{ field.validationValue }}</div>
                                </div>
                            </div>
                            <div>
                                <div *ngIf="!(formGroup.controls[field.key].valid || formGroup.controls[field.key].value) &&
                      formSubmitAttempt" class="invalid-feedback">
                                    <div>{{ field.emptyValueMessage }}</div>
                                </div>
                            </div>
                            <div *ngIf="formGroup.controls[field.key].value !='' &&
                isInvalid(formGroup.controls[field.key], field.blur)" class="invalid-feedback">
                                <div>{{ field.validationValue }}</div>
                            </div>
                             <div *ngIf="formGroup.controls[field.key].value != '' && formGroup.controls[field.key].value == 0 && !formGroup.controls[field.key].valid && formSubmitAttempt" 
                             class="invalid-feedback">
                                <div>Please enter a value greater than 0</div>
                            </div>
                            <div *ngIf="formGroup.controls[field.key].value != '' && isNaN(formGroup.controls[field.key].value) && !formGroup.controls[field.key].valid && formSubmitAttempt" 
                            class="invalid-feedback">
                               <div>Please enter a numeric value</div>
                           </div>
                            <div class="help__text" *ngIf="field.helpText">{{field.helpText}}</div>
                            <!--text field validations end-->

                        </div>
                    </ng-container>
                    <!-- text field ends -->

                    <ng-container *ngIf="field.showontoggle == true && field.key === 'physician'">
                        <div *ngSwitchCase="'dropdown'" class="form__field dropdown input">
                                <p-dropdown [options]="physiciansList"
                                    [placeholder]="field.placeholder" [required]="field.required"
                                    [formControlName]="field.key" [disabled]="!field.editable">
                                    <ng-template let-items pTemplate="item">
                                        <div class="dropdown__items dropdown__header">
                                            <div class="table__icon__header" *ngIf="items.icon">
                                                <app-svg [name]="items.icon"></app-svg>
                                            </div>
                                            <div class="item__label">{{items.label}}</div>
                                        </div>
                                    </ng-template>
                                </p-dropdown>
                                <div *ngIf="!(formGroup.controls[field.key].valid || formGroup.controls[field.key].value) && formSubmitAttempt" class="invalid-feedback">
                                    {{ field.emptyValueMessage }}
                                </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div>
    <label class="schedule__subtitle">Schedule</label>
    <div class="custom-form">
        <ng-container *ngFor="let field of schedules;let i = index">
            <div class="schedule__fields">
                <div class="form__field__wrapper inputText">
                    <div class="form--fields">
                        <div class="label">
                            <label>Days</label>
                        </div>
                        <div class="form__field">
                            <div class="input-and-close">
                                <input class="text-input input" value="{{field.daysInString}}" type="text"
                                    [placeholder]="field.placeholder" [readonly]="true" />
                                <div class="input__svg">
                                    <app-svg [name]="'calendar'"></app-svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form__field__wrapper inputText">
                    <div class="form--fields">
                        <div class="label">
                            <label>Time</label>
                        </div>
                        <div class="form__field">
                            <div class="input-and-close">
                                <input class="text-input input"
                                    value="{{field.fromTime.toLocaleTimeString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true})}} - {{field.toTime.toLocaleTimeString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true})}}"
                                    type="text" [placeholder]="field.placeholder" [readonly]="true" />
                                <div class="input__svg">
                                    <app-svg [name]="'clock'"></app-svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class='svg__wrapper'>
                    <app-svg [name]="'edit'" class="cursor__pointer" (click)="editSchedule(i)"></app-svg>
                </div>
                <div class='svg__wrapper' *ngIf="schedules.length > 1">
                    <app-svg [name]="'delete'" class="cursor__pointer" (click)="deleteSchedule(i)"></app-svg>
                </div>
                <div class='svg__wrapper add'>
                    <app-svg [name]="'plus-circle'" class="cursor__pointer" (click)="addNewSchedule()"></app-svg>
                </div>
            </div>
        </ng-container>
    </div>
    <app-location-schedule-popup *ngIf="isOpenTiming == true" [isEditType]="isEditType"
        (showHideCancelPopup)="showHideCancelPopup($event)"
        [scheduleDetails]="isEditType ? schedules[editTimeIndex] : defaultTimeSchedule"
            (sendScheduleDetails)="sendScheduleDetails($event)" [schedules]="schedules" [popIndex]="editTimeIndex" [sessionDuration]="sessionDuration"></app-location-schedule-popup>
</form>
<app-common-popup (showHideCancelPopup)='cancelPopupShowHideStatusFun($event)' *ngIf="cancelPopupShowHide">
    <div class="popup-content-block" *ngIf="deletePopup">
        <div class="confirm-message">{{serviceForms.length==1?"Sorry service cannot be deleted. Each location should have atleast one service":"Deleting this service will cancel all their upcoming appointments. Are you sure you want to continue?"}}</div>
        <div class="btn-actions" *ngIf="serviceForms.length>1">
            <div class="common-btn-style yes-btn" (click)="popUpClick($event,false)">No</div>
            <div class="common-btn-style"
                (click)="popUpClick($event,true)">Yes</div>
        </div>
        <div class="btn-actions" *ngIf="serviceForms.length==1">
            <div class="common-btn-style yes-btn" (click)="popUpClick($event,false)">Ok</div>
        </div>
    </div>
    <div class="popup-content-block" *ngIf="sessionDurationFlag">
        <div class="confirm-message">Please provide the session duration value before creating/editing a schedule</div>
    </div>
</app-common-popup>