<section class="patient__list--section user__list--section">
    <div class="patient__list-item user" (click)="navigateDetailsPage(userData.userId)">
        <app-svg class="patients__icon" [name]="'user'"></app-svg>
        <div class="patients__list-text" title="{{userData.userName}}">{{userData.userName | titlecase |
            ellipsis: 25}}
        </div>
    </div>
    <div class="patient__list-item email" (click)="navigateDetailsPage(userData.userId)">
        <app-svg class="patients__icon" [name]="'mail'"></app-svg>
        <div class="patients__list-text" title="{{userData.email}}">{{userData.email | ellipsis: 35}}</div>
    </div>
    <div class="patient__list-item last-vist" (click)="navigateDetailsPage(userData.userId)">
        <app-svg class="patients__icon" [name]="'adminLastVisit'"></app-svg>
        <div class="patients__list-text">{{userData.createTime | dateFormat }}</div>
    </div>
    <div class="patient__list-item last-vist" (click)="navigateDetailsPage(userData.userId)">
        <div class="patients__list-text {{userData.status | lowercase}}">{{userData.status}}</div>
    </div>
    <div class="patient__list-item icons" *ngIf="adminPermissions.USER_EDIT && !userData.removedFlag">
        <!-- reset password starts here -->
        <div class="schedule"
            (click)="actionsTrigger('reset',userData)">
            <app-svg class="patients__icon" title="Reset Password" [name]="'reset-password'"></app-svg>
        </div>
        <!-- reset password ends here -->
        <!-- Delete starts here -->
        <div class="delete"
            (click)="actionsTrigger('delete',userData)">
            <app-svg class="patients__icon" title="Delete" [name]="'delete'"></app-svg>
        </div>
        <!-- Delete ends here -->
        
    </div>
</section>