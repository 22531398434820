import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MmtKnowMore } from '@app/common/services/mmt-know-more.service';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {

  moreText = {
    moreTextLink: 'Know More',
    // link: './blog'
  };

  headingData = {
    subHeading: '',
    mainHeading: '',
  };

  partnerDetail = [
    {
      class: 'medicaid',
      img: 'medicaid'
    },
    {
      class: 'medicare',
      img: 'medicare'
    },
    {
      class: 'aetna',
      img: 'aetna'
    },
    {
      class: 'bluecross',
      img: 'bluecross'
    },
    {
      class: 'cigna',
      img: 'cigna'
    },
    {
      class: 'unitedhealthcare',
      img: 'unitedhealthcare'
    },
    {
      class: 'tricare',
      img: 'tricare'
    }
  ];

  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 3,
    dots: true,
    infinited: true,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  };

  constructor(private router: Router, private activeRoute: ActivatedRoute, private mmtknowmore: MmtKnowMore) { }

  ngOnInit() {
  }

  navigateBlogPage(event) {
    this.router.navigate([`treatments`], { queryParams: { scroll: true } });
  }

}
