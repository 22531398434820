<div class="email__template--section">
    <div class="email__template--info">
        <div>
            <img src="assets/images/pngs/logo.png" alt="Logo" class="email__template--logo" />
            <div class="email__template--main-info">
                <div class="email__template-sub-info">
                    <div class="email__template--highlight-text">Add a</div>
                    <div class="email__template--name pb-10">New Patient</div>
                    <div class="need__help--content">
                        Hi there. Need some help? You can add a new patient by clicking
                        the link below.
                    </div>
                    <a href="#" class="common-btn-style">Add Patient</a>
                    <div class="pb-10">If you need assistance please email us at</div>
                    <div>
                        <a href="#">http://www.newteledoc.com/Addpatient</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-template-footer></app-template-footer>
</div>
