import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { TOAST_STATUSES } from '@app/common/constants/util.constant';
import { DataService } from '@app/common/services/data.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';

@Component({
  selector: 'app-location-list',
  templateUrl: './location-list.component.html',
  styleUrls: ['./location-list.component.scss']
})
export class LocationListComponent implements OnInit {

  @Input() locationData;
  @Input() adminPermissions;

  constructor(private observable: ObservableHelperService, private router: Router, private dataService: DataService) { }

  ngOnInit() {
    window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
  }
  editLocation(event, data) {
    event.stopPropagation();
    this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.EDIT_LOCATIONS}/${data.locationId}`]);
  }

  navigateDetailsPage(data) {
    this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.LOCATIONS}/${data.locationId}`]);
  }
}
