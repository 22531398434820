<section class="section-padding full-width">
    <div class="restrict-width padding-lr">
        <h1 style="
        text-align: center;
        font-size: 40px;
        font-weight: 700;
        color: #34495E;
        padding-top: 10%;
    ">COMING SOON...</h1>
    </div>
</section>
