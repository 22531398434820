import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { WPAPIService } from '@app/common/services/wpapi.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-blog-pagination',
  templateUrl: './blog-pagination.component.html',
  styleUrls: ['./blog-pagination.component.scss']
})
export class BlogPaginationComponent implements OnInit {
  pager: any = {};
  @Output() updatedpagesValues = new EventEmitter();

  activeClass = [
    'active'
  ];
  pageIntegerType: number;
  page = null;
  pages = null;
  totalNumberOfPagesLength: number;
  constructor(private wpapi: WPAPIService, private router: Router, private activeRoute: ActivatedRoute) {
    this.page = this.activeRoute.snapshot.queryParamMap.get('page') ? Number(this.activeRoute.snapshot.queryParamMap.get('page')) : 1;
  }

  ngOnInit() {
    this.wpapi.posts(`per_page=4&page=${this.page}`).subscribe(posts => {
      // tslint:disable-next-line: radix
      this.pages = new Array(parseInt(posts.headers.get('X-WP-TotalPages')));
      this.totalNumberOfPagesLength = this.pages.length;
      this.wpapi.posts('').subscribe(wpPosts => {
        if (Object.keys(wpPosts.body).length % 4 === 1) {
          this.totalNumberOfPagesLength = this.totalNumberOfPagesLength - 1;
          this.pages.length = this.pages.length - 1;
        }
      });
    });
  }
  getPgaesValue(values) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.page = values;
    this.updatedpagesValues.emit(this.page);
  }
  previous(values) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    if (this.page <= 1) {
      this.page = 1;
    } else {
      this.page = values;
      this.updatedpagesValues.emit(this.page);
    }
  }
  next(values) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    if (this.page >= this.totalNumberOfPagesLength) {
      this.page = this.totalNumberOfPagesLength;
    } else {
      this.page = values;
      this.updatedpagesValues.emit(this.page);
      console.log(this.page, 'Next');
    }
  }
}
