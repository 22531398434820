<main>
    <section *ngIf="showNotesForm" class="mednotes__section">

        <div class="close-icon">
            <app-svg [name]="'plus-circle'" (click)="navigateBack($event)"></app-svg>
        </div>

        <div class="form__container">
            <div class="background-image">
                <img alt="Background-leaf" src="assets/images/pngs/newpatientform-background.png">
            </div>

            <div *ngIf="true" class="patient-form">
                <div class="form-header"
                    *ngIf=" this.MednoteData.EvalType === 'annual' && this.MednoteData.noteType === 'Evaluation'">
                    Generate Annual Evaluation Note</div>
                <div class="form-header"
                    *ngIf="this.MednoteData.EvalType === 're' && this.MednoteData.noteType === 'Evaluation'"> Generate
                    New Evaluation Note</div>
                <div class="form-header" *ngIf=" this.MednoteData.noteType === 'Monthly Medication Management'">
                    Generate Monthly Medication Management Form</div>
                <div *ngFor="let formData of form;let i = index" class="formdata">
                    <!-- form -->
                    <app-custom-form [formData]="formData"
                        [checkFormInfo]="saveFormData ? resetSaveFormParity() : false"
                        (submitEvent)="getFormData($event)" (getDropValueList)="dropValue($event)"></app-custom-form>

                    <!-- Email coresspondance -->
                    <div class="document_download-wrapper"
                        *ngIf="documentDetails && documentDetails.length != 0 && documentDetails.status != 'Completed'">
                        <div class="verti">
                            <div class="label-heading">Email Correspondence: </div>
                            <!--  <span class="download" *ngIf="documentDetails.length != 0" (click)="downloadAll(documentDetails)">Download All</span> -->
                            <div class="value documents_wrapper">
                                <span class="document-block each_document" *ngFor="let document of documentDetails">
                                    <div class="each_document-name">
                                        <p class="">{{document.fileName}}</p>
                                    </div>
                                    <div class="each_document-actions">
                                        <a [href]="document.url" target="_blank" title="View" class="view-icon">
                                            <app-svg [name]="'view-upload'"></app-svg>
                                        </a>
                                        <p class="down-arrow" (click)="downloadImage(document.url)" title="Download">
                                            <app-svg [name]="'down-arrow'"></app-svg>
                                        </p>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="documentDetails.length === 0">
                        <div class="label-heading">Email Correspondence</div>
                        <div class="unavaliablity_message"> No documents avaliable</div>
                    </ng-container>
                </div>

                <app-common-popup (showHideCancelPopup)='cancelPopupShowHideStatusFun($event)'
                    *ngIf="cancelPopupShowHide">
                    <div class="popup-content-block">
                        <div class="details--block">
                            <div class="details--header">Your Mednote Details</div>
                            <div class="details--section">
                                <!--diagnosis-->
                                <div class="each--detail">
                                    <div class="svg--icon">
                                        <app-svg [name]="'treatement-orange'"></app-svg>
                                    </div>
                                    <div class="icon--label">
                                        <span> {{notePopup.Diagnosis}} </span>
                                    </div>
                                </div>
                                <!-- Insurance home-->
                                <div class="each--detail">
                                    <div class="svg--icon">
                                        <app-svg [name]="'shield'"></app-svg>
                                    </div>
                                    <div class="icon--label">
                                        <span> {{notePopup.Insurance}} </span>
                                    </div>
                                </div>
                                <!-- Evaluation Date-->
                                <div class="each--detail">
                                    <div class="svg--icon">
                                        <app-svg [name]="'calendar-color'"></app-svg>
                                    </div>
                                    <div class="icon--label">
                                        <span> {{notePopup.EvaluationDate}} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="details--header">Do you want to proceed?</div>
                        <div class="btn-actions">
                            <div class="cancel-btn" (click)="cancelPopup()">Cancel</div>
                            <div class="common-btn-style proceed-btn" (click)="submitNote()">Proceed</div>
                        </div>
                    </div>
                </app-common-popup>
                <!-- Buttons -->
                <div class="btn-actions">
                    <div class="cancel-btn" (click)="back()">Cancel</div>
                    <div class="common-btn-style" (click)="previewForm()"> Preview </div>
                    <div *ngIf="MednoteData.Operation=='CreateMednote'" class="common-btn-style" (click)="saveForm()">
                        Generate</div>
                    <div *ngIf="MednoteData.Operation=='EditMednote'" class="common-btn-style" (click)="saveForm()">
                        Save</div>
                </div>
            </div>

        </div>

    </section>

    <!-- Preview feature -->

    <section *ngIf="showPreview">
        <div class="preview-close-icon">
            <app-svg [name]="'plus-circle'" (click)="navigateBackToForm()"></app-svg>
        </div>
        <app-preview-mednotes [dataForPreview]="PreviewData" [noteType]="MednoteData.noteType"
            [medType]="MednoteData.Duration"> </app-preview-mednotes>
    </section>
</main>