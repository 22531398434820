<div class="block-patient__wrapper">
    <div class="block-patient__title">
        Block Patient
    </div>
    <!--custom form start-->
    <form [formGroup]="formGroup" class="custom-form">
        <ng-container *ngFor="let field of formInputs;let i=index">
            <div class="form--fields" *ngIf="field.showontoggle == true" id="{{field.idName}}" [ngClass]="field.className != '' ? field.className : '' ">
                <!--main header start-->
                <div *ngIf="field.type == 'header' && field.showontoggle == true" class="label">
                    <span class="label" [ngClass]="field.className">{{field.label | titlecase}} <p>{{field.label1}}</p></span><span class="line"></span>
                </div>
                <!--main header end-->
                <div [ngSwitch]="field.controlType" class="form__field__wrapper {{field.controlType}}" *ngIf="(field.type != 'header') ">

                    <div class="label" *ngIf="field.label && field.showontoggle == true">{{field.label}}</div>
                    <div class="label" *ngIf="field.show">{{field.labelText}}</div>
                    <!--labelText-->
                    <div *ngSwitchCase="'labelValue'">
                        <label>{{field.value}}</label>
                    </div>
                    <!-- Grouping checkbox starts -->
                    <div *ngSwitchCase="'groupCheckbox'" class="filter-options">
                        <div class="check-wrapper">
                            <!--category start-->
                            <div class="overlay-label" [ngClass]="{'check__intermediate': field.isIntermediate}">
                                <p-checkbox [value]="field.value" [ngModelOptions]="{standalone: true}" [(ngModel)]="field.isSelected" label="{{field.categoryLabel | titlecase}}" binary="true" (onChange)="categorySelect($event, i)">
                                </p-checkbox>
                            </div>
                            <!--category end-->

                            <!--tasks start-->
                            <div class="task__block">
                                <div class="in-review-checkbox" *ngFor="let task of field.options; let taskID = index">
                                    <p-checkbox [value]="task.value" label="{{task.name | titlecase}}" [ngModelOptions]="{standalone: true}" [(ngModel)]="task.isSelected" binary="true" (onChange)="optionSelect($event, taskID, i)">
                                    </p-checkbox>
                                </div>
                            </div>
                            <!--tasks end-->
                        </div>
                    </div>


                </div>
            </div>
        </ng-container>
    </form>
    <!--custom form end-->
</div>