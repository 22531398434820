import { Component, Input, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

@Component({
  selector: 'app-preview-mednotes',
  templateUrl: './preview-mednotes.component.html',
  styleUrls: ['./preview-mednotes.component.scss'],
})
export class PreviewMednotesComponent implements OnInit {
  @Input() dataForPreview: any
  @Input() noteType: string
  @Input() medType: string
  objectKeysMSE: string[] = []
  stringKeysMSE: string[] = []
  objectKeysRadio: string[] = []
  stringKeysRadio: string[] = []
  dob: string
  evaluationDate: string
  validReasons: any = [
    'Medication Review',
    'Initial Psychiatric Evaluation',
    'Diagnostic Assessment',
    'Chart Review',
    'Family Interview',
    'Hospital Record Review',
    'Lab Review',
    'Annual Psychiatric Evaluation',
  ]
  keys_symptoms = [
    'Depressive Symptoms',
    'Manic Symptoms',
    'Psychotic Symptoms',
    'PTSD Symptoms',
    'Anxiety Symptoms',
    'Attention Deficit Symptoms',
  ]
  selectedAxisI: string = ``
  Obs: any = {}
  constructor() {}

  ngOnInit() {
    this.medType = this.medType ? this.medType : ''
    // debugger;
    console.log('dataForPreview', this.dataForPreview)
    let dob =
      this.noteType === 'Monthly Medication Management'
        ? this.dataForPreview.dob.split('-')
        : this.dataForPreview.PatientInfo.dob.split('-')
    let evalDate = this.dataForPreview.evaluationDate.split('-')
    this.evaluationDate = `${evalDate[1]}/${evalDate[2]}/${evalDate[0]}` //MM-DD-YYYY format
    this.dob = `${dob[1]}/${dob[2]}/${dob[0]}` //MM-DD-YYYY format
    console.log('noteType', this.noteType, 'evaluationDate', this.evaluationDate, 'dob', this.dob)
    console.log(this.dataForPreview.MentalStatusExam)
    if (this.dataForPreview.MentalStatusExam && this.dataForPreview.MentalStatusExam) {
      for (let key in this.dataForPreview.MentalStatusExam) {
        if (
          typeof this.dataForPreview.MentalStatusExam[key] === 'object' &&
          this.dataForPreview.MentalStatusExam[key] !== null
        ) {
          this.objectKeysMSE.push(key)
        } else if (typeof this.dataForPreview.MentalStatusExam[key] === 'string') {
          this.stringKeysMSE.push(key)
        }
      }
    }
    if (this.dataForPreview.RadioButtonValue && this.dataForPreview.RadioButtonValue) {
      for (let key in this.dataForPreview.RadioButtonValue) {
        if (
          typeof this.dataForPreview.RadioButtonValue[key] === 'object' &&
          this.dataForPreview.RadioButtonValue[key] !== null
        ) {
          this.objectKeysRadio.push(key)
        } else if (typeof this.dataForPreview.RadioButtonValue[key] === 'string') {
          this.stringKeysRadio.push(key)
        }
      }
    }

    if (this.dataForPreview.Axis_I && this.dataForPreview.Axis_I_Secondary) {
      let comma = ''
      console.log('this.noteType', this.noteType)

      if (this.noteType === 'Evaluation') {
        const axisIEval_diagonsis = [
          'Anxiety Disorder (F41.9)',
          'Autism Spectrum Disorder (F84.0)',
          'BI-POLAR (F31.81)',
          'MDD (F33.2)',
          'MDD (F33.9)',
          'ADHD (F90.0)',
          'ODD (F91.3)',
          'Schizophrenia (F20.9)',
          'Adjustment Disorder (F43.23)',
          'EATING DISORDER (F50.9)',
          'OCD (F42.9)',
        ]
        for (let key of axisIEval_diagonsis) {
          if (this.dataForPreview.Axis_I[key] === true) {
            this.selectedAxisI = this.selectedAxisI + comma + `${key}`
            comma = `, `
          }
        }
        for (let key of axisIEval_diagonsis) {
          if (this.dataForPreview.Axis_I_Secondary[key] === true && !(this.dataForPreview.Axis_I[key] === true)) {
            this.selectedAxisI = this.selectedAxisI + comma + `${key}`
            comma = `, `
          }
        }
        console.log('this.selectedAxisI', this.selectedAxisI)
      } else if (this.noteType === 'Monthly Medication Management' && this.medType === '45 Minutes') {
        const axisI45_diagonsis = [
          'Anxiety Disorder (F41.9)',
          'BI-POLAR II (F31.81)',
          'MDD (F33.9)',
          'MDD (F33.2)',
          'ADHD (F90.0)',
          'OPPOSITIONAL DISORDER (F91.3)',
          'SCHIZOPHRENIFORM DISORDER (F20.81)',
          'ADJUSTMENT DISORDER (F43.20)',
          'EATING DISORDER (F50.9)',
          'OCD (F42.9)',
          'AUTISM (F84.0)',
        ]
        for (let key of axisI45_diagonsis) {
          //Primary
          if (this.dataForPreview.Axis_I[key] === true) {
            this.selectedAxisI = this.selectedAxisI + comma + `${key}`
            comma = `, `
          }
        }
        for (let key of axisI45_diagonsis) {
          //Secondary
          if (this.dataForPreview.Axis_I_Secondary[key] === true && !(this.dataForPreview.Axis_I[key] === true)) {
            this.selectedAxisI = this.selectedAxisI + comma + `${key}`
            comma = `, `
          }
        }
      } else if (this.noteType === 'Monthly Medication Management' && this.medType === '25 Minutes') {
        const axisI25_diagonsis = [
          'Anxiety Disorder (F41.9)',
          'BI-POLAR II (F31.81)',
          'MDD (F33.9)',
          'MDD (F33.2)',
          'ADHD (F90.0)',
          'OPPOSITIONAL DISORDER (F91.3)',
          'Schizoaffective disorder (F25.9)',
          'ADJUSTMENT DISORDER (F43.20)',
          'AUTISM (F84.0)',
          'EATING DISORDER (F50.9)',
          'OCD (F42.9)',
        ]
        for (let key of axisI25_diagonsis) {
          if (this.dataForPreview.Axis_I[key] === true) {
            this.selectedAxisI = this.selectedAxisI + comma + `${key}`
            comma = `, `
          }
        }
        for (let key of axisI25_diagonsis) {
          if (this.dataForPreview.Axis_I_Secondary[key] === true && !(this.dataForPreview.Axis_I[key] === true)) {
            this.selectedAxisI = this.selectedAxisI + comma + `${key}`
            comma = `, `
          }
        }
      }
    }

    if (this.noteType === 'Monthly Medication Management') {
      Object.assign(this.Obs, {
        BP: this.dataForPreview.Objective.Vitals.BP !== '' ? this.dataForPreview.Objective.Vitals.BP + ' mmHg' : '-',
      })
      Object.assign(this.Obs, {
        Pulse:
          this.dataForPreview.Objective.Vitals.Pulse !== '' ? this.dataForPreview.Objective.Vitals.Pulse + ' bpm' : '-',
      })
      Object.assign(this.Obs, {
        Height:
          this.dataForPreview.Objective.Vitals.Height !== '' ? this.dataForPreview.Objective.Vitals.Height + `"` : '-',
      })
      Object.assign(this.Obs, {
        Weight:
          this.dataForPreview.Objective.Vitals.Weight !== ''
            ? this.dataForPreview.Objective.Vitals.Weight + ' lbs'
            : '-',
      })
      // Object.assign(this.Obs, {PR:(this.dataForPreview.Objective.Vitals.PR !== '') ? this.dataForPreview.Objective.Vitals.PR : '-'})
    } else {
      Object.assign(this.Obs, {
        BP: this.dataForPreview.Observations.BP !== '' ? this.dataForPreview.Observations.BP + ' mmHg' : '-',
      })
      Object.assign(this.Obs, {
        Pulse: this.dataForPreview.Observations.Pulse !== '' ? this.dataForPreview.Observations.Pulse + ' bpm' : '-',
      })
      Object.assign(this.Obs, {
        Height: this.dataForPreview.Observations.height !== '' ? this.dataForPreview.Observations.height + `"` : '-',
      })
      Object.assign(this.Obs, {
        Weight: this.dataForPreview.Observations.weight !== '' ? this.dataForPreview.Observations.weight + ' lbs' : '-',
      })
    }
  }
}
