<!-- Appoinement List -->
<div class="appointments__list" *ngIf="showScreen">
    <!-- Appoinement List Date-->
    <div class="appointments__list-item date" (click)="navigateDetails(appointmentList)">
        <app-svg class="appointments__icon" [name]="appointmentList.calendarIcon"></app-svg>
        <p class="appointments__list-text">{{appointmentList.appointmentDate | dateFormat }}</p>
    </div>
    <!-- Appoinement List time-->
    <div class="appointments__list-item time" (click)="navigateDetails(appointmentList)">
        <app-svg class="appointments__icon" [name]="appointmentList.timeIcon"></app-svg>
        <p class="appointments__list-text" *ngIf="!appointmentList.appointmentTimeSlat">
            {{appointmentList.appointmentCurrentTime | customTime }}</p>
        <p class="appointments__list-text {{appointmentList.appointmentTimeSlat?'inprogress':''}}" *ngIf="appointmentList.appointmentTimeSlat">
            {{appointmentList.appointmentCurrentTime}}</p>
    </div>
    <!-- Appoinement List User-->
    <div class="appointments__list-item user" (click)="navigateDetails(appointmentList)">
        <app-svg class="appointments__icon" [name]="appointmentList.patientIcon"></app-svg>
        <p class="appointments__list-text" [title]="appointmentList.patientName | titlecase">
            {{appointmentList.patientName | titlecase | ellipsis:18}}
        </p>
    </div>
    <!-- Appoinement List Type-->
    <div class="appointments__list-item type" (click)="navigateDetails(appointmentList)">
        <app-svg class="appointments__icon" [ngClass]="appointmentList.appointmentIcon" [name]="appointmentList.appointmentIcon"></app-svg>
        <p class="appointments__list-text {{appointmentList.appointmentTypeId == 4 && (appointmentList.isTMSfill || appointmentList.pendingTMS) &&  (appointmentList.appointmentStatus == 'P' || appointmentList.appointmentStatus == 'A')  ? 'inprogress' : ''}}">{{appointmentList.appointmentTypeName}}</p>
    </div>
    <!-- Appoinement List Doctor-->
    <div class="appointments__list-item doctor" (click)="navigateDetails(appointmentList)">
        <app-svg class="appointments__icon" [name]="appointmentList.doctorIcon"></app-svg>
        <p class="appointments__list-text"><span [title]="appointmentList.doctorName | titlecase">{{appointmentList.doctorName | titlecase | ellipsis:23}}</span>
        </p>
    </div>
    <div class="appointment__list-icons-section">
        <div class="appointment__list-icons">
            <!-- PMP Icon -->
             <ng-container *ngIf="type !== 'history' && this.appointmentList.appointmentDate==this.today && this.appointmentList.pmp_sheet_data != null && this.appointmentList.pmp_sheet_data.hasOwnProperty('pmp_sheet_url')">
                <div class="appointments__list-item pmp__data" title="PMP" (click)="openPdf(pdfs.pmp, appointmentList)" *ngIf="appointmentList.pmp_sheet_data.pmp_sheet_url.length > 1">
                    <img src="assets/images/svgs/pmp.svg" alt="accordian icon" class="pmp__icon">
                </div>
             </ng-container> 
            
            <!-- Patient Data Icon -->
            <div class="appointments__list-item patient__data" title="Patient data" (click)="openPdf(pdfs.patientIntake, appointmentList)" *ngIf="appointmentList.patient_data_signeed_pdf !== null && appointmentList.patient_data_signeed_pdf.length > 1">
                <img src="assets/images/svgs/patient-data.svg" alt="patient icon" class="patient__icon">

            </div>
            <!-- Appoinement Data Icon-->
            <div class="appointments__list-item appointment__data" title="Appointment data" *ngIf="appointmentList.appoint_data_signeed_pdf !== null && appointmentList.appoint_data_signeed_pdf.length > 1">
                <img src="assets/images/svgs/appointment-data.svg" alt="appointment icon" class="appointment__data-icon" (click)="openPdf(pdfs.appointment, appointmentList)">

            </div>
            <!-- Mednotes Icon-->
            <ng-container *ngIf="((appointmentList.appointmentTypeId === 1 || appointmentList.appointmentTypeId === 4) && (appointmentList.notes_data_s3 != null && appointmentList.notes_data_s3.length > 1))">
                <div class="appointments__list-item mednotes__data" [title]="(appointmentList.appointmentTypeId === 1)? 'Med Notes' : 'TMS Notes'" (click)="openPdf(pdfs.medicationNotes, appointmentList)">
                <img src="assets/images/svgs/mednote.svg" alt="mednote icon" class="mednote__icon">

                </div>
            </ng-container>
        </div>
            <div class="appointments__list-item appoinments-action" *ngIf="(appointmentList.actions.length <= 2)">
                <div class="appointments__list-item generate-icons" *ngFor="let eachAction of appointmentList.actions">
                    <ng-container>
                        <div class="invoice-actions-list" (click)="moreOptionClick(eachAction, appointmentList)" [title]="eachAction.label">
                            <!-- <app-svg class="appointments__icon" [ngClass]="eachAction.iconName" [name]="eachAction.iconName"></app-svg> -->
                            <img src="assets/images/svgs/{{eachAction.iconName}}.svg" alt="block patient icon" class="appointments__icon" [ngClass]="eachAction.iconName">
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="appointments__list-item appoinments-action" *ngIf="(appointmentList.actions.length > 2)">
                <div class="appointments__list-item generate-icons">
                    <ng-container>
                        <div class="invoice-actions-list" (click)="moreOptionClick(appointmentList.actions[0], appointmentList)" [title]="appointmentList.actions[0].label">
                            <!-- <app-svg class="appointments__icon appointments-invoice-icon" [ngClass]="appointmentList.actions[0].iconName" [name]="appointmentList.actions[0].iconName"></app-svg> -->
                            <img src="assets/images/svgs/{{appointmentList.actions[0].iconName}}.svg" alt="admininvoice icon" class="appointments__icon" [ngClass]="appointmentList.actions[0].iconName">
                        </div>
                    </ng-container>
                </div>
                    <div class="appointments__list-item more-icon" (clickOutside)="op.hide()" (click)="op.show($event)" *ngIf="(appointmentList.actions.length > 2)">
                        <app-svg class="appointments__icon" [name]="'list-more'"></app-svg>
                        <!-- This will be uncommented after export build -->
                    </div>
                <p-overlayPanel #op  [showTransitionOptions]=" '100ms' " [hideTransitionOptions]=" '100ms' " [appendTo]=" 'body' ">
                    <ng-template pTemplate>
                        <div class="more_Actions {{appointmentList.actions.length >4 ? 'scroll' : 'noScroll'}}">
                            <ng-container *ngFor="let eachAction of appointmentList.actions| slice:1 ; let i=index ">
                                <div class="line "></div>
                                <div class="more__option" title={{eachAction.title}} (click)="moreOptionClick(eachAction, appointmentList)">{{eachAction.label | titlecase}}
                                </div>
                            </ng-container>
                        </div>
                    </ng-template>
                </p-overlayPanel>
            </div>
    </div>
</div>