<section class="telemedicine-consent--seciton">
    <div class="telemedicine-consent__block">
        <p-tabView>
            <p-tabPanel header="{{tab.headerLabel}}" *ngFor="let tab of tabContent1">
                <div *ngIf="tab.headerLabel === 'Patient Agreement' ">        
                    <app-custom-form [checkFormInfo]="saveFormData" [formData]="substanceForm1"></app-custom-form>
                </div>
                <div class="container" *ngFor="let data of tab.data">
                    <div *ngIf="data.description">
                        {{data.description}}
                    </div>
                    <div *ngIf="data.list">
                        <ul class="{{data.listtype}}">
                            <li *ngFor="let list of data.list">
                                {{list}}
                            </li>
                        </ul>
                    </div>
                </div>
                <div *ngIf="tab.headerLabel === 'Substances Treatment Program Statement' ">
                    <app-custom-form [checkFormInfo]="saveFormData" [formData]="substanceForm"></app-custom-form>
                </div>
            </p-tabPanel>
        </p-tabView>
        <div>
            <app-custom-form [checkFormInfo]="saveFormData" (submitEvent)="getFormData($event)" [formData]="substanceFormSing"></app-custom-form>
        </div>
    </div>
</section>
