<!-- pagination__block start -->
<ul class="pagination__block" *ngIf="pages">
  <li class="page-item">
    <a class="page-link"  aria-label="Previous" [routerLink]="['/blog']" [queryParams]="{page: page - 1}" [class.disable]="page==1" (click)="previous(page - 1)">
      <span aria-hidden="true">&laquo;</span>
      <span class="sr-only">Previous</span>
    </a>
  </li>
  <li class="page-item" *ngFor="let pageNumber of pages; let i = index" [class.active]="page==(i+1)">
      <a class="page-link state" [routerLink]="['/blog']" [queryParams]="{page: (i + 1)}" (click)="getPgaesValue((i + 1))" >{{ i + 1 }}</a>
  </li>
  <li class="page-item">
    <a class="page-link" aria-label="Next" [routerLink]="['/blog']" [queryParams]="{page: page + 1}" (click)="next(page + 1)" [class.disable]="page==totalNumberOfPagesLength">
      <span aria-hidden="true">&raquo;</span>
      <span class="sr-only">Next</span>
    </a>
  </li>
</ul>
