import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { TOAST_STATUSES, HTTPMethods } from '@app/common/constants/util.constant';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { CognitoService } from '@app/common/services/cognito.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '@app/common/services/http.service';
import { API_URLS } from '@app/common/constants/api-urls.constant';
import { formatDate } from '@angular/common';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';
import { AdminCogntioService } from '@app/common/services/admin-cogntio.service';
import { admin } from './../../../../environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  @Output() state = new EventEmitter<any>();

  @Input() forgotPasswordPath: string = 'fp';
  form: any;
  forgotPasswordForm: any;
  forgotPasswordPhase = 'pre';
  userName;
  params;
  adminMail = admin.email;
  userId: string;
  constructor(
    private formService: CustomFormHandlerService,
    private observableHelperService: ObservableHelperService,
    private cognitoService: CognitoService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpService,
    private adminCognito: AdminCogntioService
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0); // to scroll the page to top when user navigates
    // this.form = this.forgotPasswordPhase == 'pre' ? this.formService.initForm(this.formService.formTypes.FORGOTPASSWORD) : this.formService.initForm(this.formService.formTypes.RESENDMAIL);
    this.setFormFields(this.formService.formTypes.FORGOTPASSWORD);
    if(this.forgotPasswordPath == 'dob'){
      this.setFormFields(this.formService.formTypes.FORGOTDOBPASSWORD);
    }
    this.setFormFields(this.formService.formTypes.FORGOTPASSWORDWITHCODE, true);
    this.route.queryParams.subscribe(queryParams => {
      if (queryParams.forgotPwdCode && queryParams.username) {
        this.forgotPasswordPhase = 'verify';
        this.params = queryParams;
      }
      if (queryParams.emailVerifycode && queryParams.username && queryParams.id) {
        this.userId = queryParams.id;
        this.params = queryParams;
      }
    });


  }

  setFormFields(tag, code?) {
    if (code) {
      this.forgotPasswordForm = this.formService.initForm(tag);
    } else {
      this.form = this.formService.initForm(tag);
    }
  }
  resendCode(event) {
    this.userName = this.userName.toLowerCase();
    this.adminCognito.forgotPassword({ userName: this.userName }, (data) => {
      this.forgotPasswordPhase = 'post';
      this.setFormFields(this.formService.formTypes.RESENDMAIL);
      event.reset();
    });
  }

  resendVerificationCode(event) {
    this.adminCognito.resendConfirmationCode({ userName: this.userName.toLowerCase() }, () => {
      event.reset();
    });
  }


  btnClickEvent(event) {
    console.log(event.value);
    window.scrollTo(0, 0); // to scroll the page to top when view changes
    if (Object.keys(event.value).length === 0) {
      this.state.emit('reset_password');
      // this.ObservableHelperService.showToast(TOAST_STATUSES.SUCCESS, "Your email has been sent");
    } else {
      this.userName = event.value.userName.toLowerCase();
      this.adminCognito.forgotPassword(event.value, (data) => {
        this.forgotPasswordPhase = data.forgotPasswordPhase;
        this.form.formGroup.reset();
        this.form.formInputs.forEach(ele => ele.value = '');
        if (this.forgotPasswordPhase === 'post') {
          this.setFormFields(this.formService.formTypes.RESENDMAIL);
        } else {
          this.setFormFields(this.formService.formTypes.RESENDVERIFICATIONMAIL);
        }
        event.reset();
      });
    }
  }

  forgotPasswordSubmit(event) {
    let data: any = event.value;
    data.userName = this.params.username.toLowerCase();
    data.code = this.params.forgotPwdCode;
    this.cognitoService.forgotPasswordSubmit(data, () => {
      this.checkTypeOfUser(data.userName);
      this.forgotPasswordPhase = 'complete';
      this.state.emit('login');
      this.forgotPasswordForm.formGroup.reset();
      this.forgotPasswordForm.formInputs.forEach(ele => ele.value = '');
      this.router.navigate(['login']);
    });
  }
  ngOnDestroy() {
    this.forgotPasswordForm.formGroup.reset();
    this.forgotPasswordForm.formInputs.forEach(ele => ele.value = '');
    this.form.formGroup.reset();
    this.form.formInputs.forEach(ele => ele.value = '');
    this.userName = '';

  }

  checkTypeOfUser(userMail) {
    let bodyData = {
      email: userMail
    };
    const payload = {
      type: 'POST',
      url: 'checkIsAdmin',
      isDeveloper: true,
      body: bodyData,
      isAuthToken: true,
      dontAthourized: true
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        console.log('Response', res);
        let data = res.data;
        if (data && data.isAdmin) {
          this.router.navigate(['admin/login']);
        } else {
          this.router.navigate(['login']);
        }
      } else {
        console.log('Response', res);
        // this.observableHelperService.showToast(TOAST_STATUSES.ERROR, res.error.message || res);
      }
    });
  }

  /**
   * To submit the dob to validate the user.
   */
  submitDateofB(event) {
    let dob = formatDate(new Date(event.value.dob), 'yyyy-MM-dd', 'en');
    var body = {
      user_id: this.userId,
      primaryDOB: dob
  }
    const payload = {
      type: HTTPMethods.POST,
      url: API_URLS.USER_FORGOT_PASSWORD,
      isDeveloper: true,
      body: body,
      isAuthToken: true
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        if(this.params.username){
          this.sendResetPasswordMail();
        }
      } else {
        let message = TOAST_MESSAGES.WRONG_DOB
        message = (res.error) ? res.error.message : message;
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  /**
   * Submit new password.
   */
  sendResetPasswordMail() {
    let data = { userName: this.params.username };
    this.userName = data.userName;
    this.adminCognito.forgotPassword(data, (data) => {
      this.forgotPasswordPhase = data.forgotPasswordPhase;
      this.form.formGroup.reset();
      this.form.formInputs.forEach(ele => ele.value = '');
      if (this.forgotPasswordPhase === 'post') {
        this.setFormFields(this.formService.formTypes.RESENDMAIL);
      } else {
        this.setFormFields(this.formService.formTypes.RESENDVERIFICATIONMAIL);
      }
    });
  }
}
