import { Component, OnInit } from '@angular/core';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { Router, ActivatedRoute } from '@angular/router';
import { each } from 'jquery';
import { CognitoService } from '@app/common/services/cognito.service';
import { HttpService } from '@app/common/services/http.service';
import { element } from 'protractor';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  form: any;
  editform: any;
  name: any;
  saveFormData: boolean = false;
  showUserData: boolean = true;
  formName;
  email;
  user;
  showScreen;
  constructor(
    private formService: CustomFormHandlerService,
    private router: Router,
    private route: ActivatedRoute,
    private cognitoService: CognitoService,
    private http: HttpService
  ) { }

  ngOnInit() {
    let userLogedIn = JSON.parse(window.localStorage.getItem('isLogedIn'));
    if (!userLogedIn) {
      this.router.navigate(['/login']);
      return;
    }
    this.cognitoService.getUser().then(user => {
      this.user = user;
      if (user.firstName && user.lastName) {
        this.name = (user.firstName || '') + ' ' + (user.lastName || '');
      } else {
        this.name = '';
      }
      this.email = user.email;
      this.showScreen = true;
      if (!(user.firstName && user.lastName)) {
        if (document.getElementById('my_appointment')) {
          document.getElementById('my_appointment').style.display = 'none';
        }
        this.editClicked(true);
      } else {
        document.getElementById('my_appointment').style.display = 'flex';
      }
    });

    this.formService.userProfileFormInputsConfig.forEach(eachConfig => {
      if (eachConfig.isShowpassword) {
        eachConfig.showontoggle = false;
        eachConfig.isShowpassword = false;
        eachConfig.type = 'password';
        eachConfig.value = '';
      } else if (eachConfig.isShowpassword === false) {
        eachConfig.type = 'password';
      }
    });
    this.form = this.formService.initForm(this.formService.formTypes.USERPROFILE);
    this.editform = this.formService.initForm(this.formService.formTypes.USERPROFILEEDIT);
  }
  ngAfterViewChecked(): void {
    // Called after every check of the component's view. Applies to components only.
    // Add 'implements AfterViewChecked' to the class.
    if (document.getElementById('edit_profile')) {
      document.getElementById('edit_profile').style.display = 'none';
    }
  }

  showChangePwdFields() {
    this.formName = 'changPwd';
    this.formService.userProfileFormInputsConfig.forEach(eachInp => {
      if (!eachInp.showontoggle) {
        eachInp.showontoggle = true;
      } else if (eachInp.showontoggle) {
        eachInp.showontoggle = false;
      }
    });
    this.formService.userProfileFormEditInputsConfig.forEach(eachProfile => eachProfile.showontoggle = false);
    this.showUserData = true;
  }

  editClicked(showontoggle?) {
    this.formName = 'editUser';
    this.formService.userProfileFormEditInputsConfig.forEach(user => {
      user.value = this.user[user.key];
      if(!showontoggle){
        if(user.key === 'firstName' || user.key === 'lastName'){
          user.editable = false;
        }
      }else{
        if(user.key === 'firstName' || user.key === 'lastName'){
          user.editable = true;
        }
      }
      if (!user.showontoggle) {
        user.showontoggle = true;
        this.showUserData = false;
      } else if (user.showontoggle) {
        user.showontoggle = false;
        this.showUserData = true;
      }
      if (showontoggle) {
        user.showontoggle = true;
        this.showUserData = false;
      }
    });
    this.formService.userProfileFormInputsConfig.forEach(eachUser => eachUser.showontoggle = false);
  }
  saveForm() {

    this.saveFormData = true;
  }
  resetSaveFormParity() {
    window.setTimeout(() => {
      this.saveFormData = false;
    }, 0);

    return true;
  }
  getFormData(event) {
    console.log(event, 'resulttt');
    if (this.formName === 'changPwd') {
      this.cognitoService.changePassword(event.value, () => {
        this.edited();
        this.form.formGroup.reset();

      });
    } else {
      let userDetails = event.value;
      userDetails.user_id = this.user.userId;
      userDetails.email = userDetails.email.toLowerCase();
      userDetails.sub = this.user.userId;
      delete userDetails.confirmEmail;
      this.cognitoService.updateUser(userDetails, () => {
        let updateBody = {
          email: userDetails.email,
          user_id: userDetails.user_id
        }
        let paylod = {
          type: (this.name) ? 'PUT' : 'POST',
          body: (this.name) ? updateBody : userDetails,
          isDeveloper: true,
          url: (this.name) ? 'editPoc' : 'createUser',
          pathVariables: (this.name) ? [this.user.userId] : undefined
        };
        this.http.makeHttpRequest(paylod).subscribe(res => {
          this.edited();
          document.getElementById('my_appointment').style.display = 'flex';
          this.editform.formGroup.reset();
        });
      });
    }
  }
  edited() {
    this.router.navigate(['myaccount']);
    this.form.formInputs.forEach(inp => {
      inp.value = '';
      inp.showontoggle = false;
    });
    this.editform.formInputs.forEach(input => {
      input.value = '';
    });
  }
  navigateBack(event) {
    console.log(this.form.formInputs, 'form');

    if (event) {
      this.router.navigate(['myaccount']);
    }
    this.form.formInputs.forEach(eachInput => {
      eachInput.value = '';
      eachInput.showontoggle = false;
    });
    this.editform.formInputs.forEach(formInput => {
      formInput.value = '';
    });
  }

  ngOnDestroy() {
    if (this.editform && this.editform.formGroup) {
      this.editform.formGroup.reset();
    }
    if (this.form && this.form.formGroup) {
      this.form.formGroup.reset();
    }
    if (document.getElementById('edit_profile')) {
      document.getElementById('edit_profile').style.display = 'flex';
    }
  }

}
