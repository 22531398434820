<div class="">
    <div class="Complete-popup-main">
        <img class="onbaord-form-bg" alt="background-leaf" src="assets/images/pngs/onboard-card-bg.png" />
        <div class="Complete-popup-content">
            <div class="Complete-popup-title">
                {{popupTitle.label}}
            </div>
            <br>
            <br>
            <div>
                <textarea class="text-area input" type="textarea" [attr.maxLength]="150" value=""
                    placeholder="Enter details here (optional)" (input)="textCounter1($event)"
                    [(ngModel)]="adminComments"></textarea>
            </div>
            <div class="btn-actions">
                <div class="Complete-btn" (click)="showHideCancelPopup()">Cancel</div>
                <div class="common-btn-style" (click)="submitPopup()">Submit</div>
            </div>
        </div>
    </div>
</div>