import { Component, OnInit, Input } from '@angular/core';
import { element } from 'protractor';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';

@Component({
  selector: 'app-treatments-content',
  templateUrl: './treatments-content.component.html',
  styleUrls: ['./treatments-content.component.scss']
})
export class TreatmentsContentComponent implements OnInit {

  nextText = {
    moreTextLink: 'Next'
  };
  backText = {
    moreTextLink: 'Back'
  };

  headingData = {
    subHeading: 'TREATMENTS',
    mainHeading: 'WE OFFER',
  };
  treatmentCardMoreInfo=[
    {
      id:0,
      img:"assets/images/pngs/adhd.png",
      heading:"ADHD TREATMENT",
      color:"#DCB001",
      description:"ADHD Is a neurodevelopmental disorder that affects people across all age groups. Symptoms of ADHD can be bucketed into 3 categories: inattention, hyperactivity, and impulsivity. These symptoms cause complications in focus, prioritization, thoughtfulness, and timeliness. Resultantly, many individuals with ADHD may find it difficult to maintain productivity at work, school, etc. However, individuals can seek treatment to alleviate the burden of these symptoms. ",
    },
    {
      id:1,
      img:"assets/images/pngs/testosterone.png",
      heading:"TESTOSTERONE TREATMENT",
      color:"#EE7A49",
      description:"",
    },
    {
      id:2,
      img:"assets/images/pngs/marijuana.png",
      heading:"MEDICAL MARIJUANA TREATMENT",
      color:"#D56784",
      description:"",
    },
    {
      id:3,
      img:"assets/images/pngs/ketamine.png",
      heading:"KETAMINE THERAPY",
      color:"#D56784",
      description:"",
    },
    {
      id:4,
      img:"assets/images/pngs/antiAging.png",
      heading:"ANTI-AGING TREATMENT",
      color:"#3593BB",
      description:"",

    }
  ]
  imageCard = [
    {
      labelImg: 'jpgs/treatment1.jpg',
      cardIndex: 0,
      heading: 'Ketamine Treatment'
    },
    {
      labelImg: 'jpgs/treatment3.jpg',
      cardIndex: 1,
      heading: 'Medicinal Marijuana'
    },
    {
      labelImg: 'jpgs/treatment2.jpg',
      cardIndex: 2,
      heading: 'TMS'
    },
    {
      labelImg: 'jpgs/treatment4.jpg',
      cardIndex: 3,
      heading: 'Anti-aging'
    },
    {
      labelImg: 'jpgs/treatment5.jpg',
      cardIndex: 4,
      heading: 'Future of Mental Health Treatment'
    }
  ];

  showImageLabelCard = [];

  cardDetails = [
    {
      heading: 'Medicinal Marijuana',
      imagePath: 'jpgs/treatment3.jpg',
      labelImg: 'jpgs/treatment3.jpg',
      cardLabel: 'Medical Marijuana DC',
      description: 'Medical marijuana uses the marijuana plant or chemicals in it to treat diseases or conditions. The marijuana plant contains more than 100 different chemicals called cannabinoids. Each one has a different effect on the body. Delta-9-tetrahydrocannabinol (THC) and cannabidiol (CBD) are the main chemicals used in medicine. THC also produces the "high" people feel when they smoke marijuana or eat foods containing it. It’s used in the treatment of mental health conditions such as post-traumatic stress disorder (PTSD).',
      listHeading: 'Conditions Treated:',
      listArray: [
        'Chronic pain,',
        'Appetite stimulant,',
        'PTSD,',
        'Severe Anxiety'
      ]
    },
    {
      heading: 'Ketamine Treatment',
      imagePath: 'jpgs/treatment1.jpg',
      labelImg: 'jpgs/treatment1.jpg',
      cardLabel: 'Ketamine Treatment DC',
      // description: "Ketamine is a very versatile drug, from the ways that it can be administered to its varied clinical uses. It can be administered by many different routes: oral, topical, intramuscular injection, or intravenous. In addition to its use as an anesthetic, it has been used in emergency situations to aid in intubation (placement of a breathing tube). Ketamine has proven to be effective as an analgesic to treat pain in burn victims, and as a pain reliever for chronic pain and cancer pain. It's been effective as a medication for treating pre-procedure agitation in adults with intellectual disabilities and in pediatrics. In the postoperative setting, it is effective for agitation and post-anesthesia shivering. Part of the reason ketamine is so useful is that it has some unique and generally beneficial pharmacological properties. These include stimulation of the cardiovascular system, airway relaxation, and catalepsy (a trancelike state with diminished responsiveness). As you can see, ketamine has a variety of uses and properties.",
      description: 'Ketamine is a very versatile drug, from the ways that it can be administered to its varied clinical uses. It can be administered by many different routes: oral, topical, intramuscular injection, or intravenous. Ketamine has proven to be effective as an analgesic to treat pain in burn victims, and as a pain reliever for chronic pain and cancer pain. In the postoperative setting, it is effective for agitation and post-anesthesia shivering. Part of the reason ketamine is so useful is that it has some unique and generally beneficial pharmacological properties. These include stimulation of the cardiovascular system, airway relaxation, and catalepsy (a trancelike state with diminished responsiveness). As you can see, ketamine has a variety of uses and properties.',
      listHeading: 'So, how does it work? Let\'s look at that now:',
      listArray: [
        'Ketamine is a medication mainly used for starting and maintaining anesthesia.',
        'It induces a trance-like state while providing pain relief, sedation, and memory loss.',
        'Other uses include sedation in intensive care and treatment of pain and depression.',
        'Heart function, breathing, and airway reflexes generally remain functional.',
        'Effects typically begin within 5 minutes after Ketamine is given.',
        'Ketamine will improve BNF (BRAIN NERVE FACTOR) which is helpful for depression.',
        'It is effective for weight management and for rapid response for treatment of depression.',
        'Ketamine is effective for depression with weight related issues.'
      ]
    },
    {
      heading: 'TMS',
      imagePath: 'jpgs/treatment2.jpg',
      labelImg: 'jpgs/treatment2.jpg',
      cardLabel: 'Testosterone Treatment DC',
      description: 'Transcranial magnetic stimulation (TMS) is a safe and effective treatment that uses magnetic pulses to stimulate different areas of the brain. TMS is non-invasive (does not require surgery), non-systemic (does not affect the entire body), and well-tolerated. TMS therapy can be used alone or in conjunction with other treatment modalities including behavioral therapies and medication. During TMS treatment, patients recline in a treatment chair and remain awake and alert. A TMS magnet is positioned over the patient’s head to deliver pulses to specific areas of the brain. Patients report that the pulses feel like taps on the scalp. Each treatment session lasts about 30 minutes, after which patients can immediately return back to their daily routines. TMS is well tolerated. Sometimes patients experience mild scalp discomfort or mild headache during treatment. Most patients notice symptom improvement with TMS after 2 weeks of treatment (8-10 sessions).',
      listHeading: 'TMS Services:',
      listArray: [
        'Standard TMS: 36 treatment sessions of TMS, typically conducted over 9 weeks',
        'Express TMS: 3-minute TMS using theta-burst technology',
        'TMS plus  Ketamine: Combines TMS and  ketamine',
        'TMS plus  Nutrition: Combines TMS and  nutrition'
      ]
    },
    {
      heading: 'Anti-aging',
      imagePath: 'jpgs/treatment4.jpg',
      labelImg: 'jpgs/treatment4.jpg',
      cardLabel: 'Anti Aging Treatment',
      description: 'Aging affects not just our physical well being but our mental health as well. We all want to be able to perform at our peak states at any given time in life. At New Tele Doc we help you gain your peak condition back by using most modern & cutting edge therapies.',
      listHeading: 'Our anti-aging Programme Consists of:',
      listArray: [
        'Med Management',
        'Nootropics(Smart Drugs)',
        'Functional Medicine',
        'Bioidentical Hormone Therapy'
      ]
    },
    {
      heading: 'Future of Mental Health Treatment',
      imagePath: 'jpgs/treatment5.jpg',
      labelImg: 'jpgs/treatment5.jpg',
      cardLabel: 'mental health clinic',
      description: 'The global march toward cannabis legalization has seemingly awoken another long-dormant area of scientific interest: psychedelics as medicine. While the idea of using LSD, MDMA, and psilocybin (the main hallucinogenic compound in magic mushrooms) to treat mental health disorders is far from a trail-blazing concept, but now the development of such medicines is at hand & we are there to bring you the best of all these treatments with our advanced scientific approach.',
      listHeading: '',
      listArray: [

      ]
    }
  ];

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    infinited: true,
    autoplay: false,
    autoplaySpeed: 3000,
    adaptiveHeight: true,
    pauseOnHover: true,
    responsive: [
      // {
      //   breakpoint: 1280,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll: 1,
      //   }
      // },
      // {
      //   breakpoint: 1023,
      //   settings: {
      //     slidesToShow: 1,
      //     centerMode: true
      //   }
      // },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 3000,
          adaptiveHeight: false,
          pauseOnHover: false,
        }
      }
    ]
  };

  currentCardIndex: number = 0;
  previousCardIndex: number;

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.imageCard.forEach((img, index) => {
      if (index !== 0) {
        this.showImageLabelCard.push(img);
      }
    });
    this.route
      .queryParams
      .subscribe(params => {
        if (params['scroll']) {
          setTimeout(() => {
            if (document.getElementById('mmt-card')) {
              document.getElementById('mmt-card').scrollIntoView({ block: 'center' });
            }
          }, 2);
        }
      });
  }

   /**
 * This functionality for removing duplicates from an array
 * @param arr :array of objects
 * @param comp :string
  */
// this.getUnique(discoveryList,"status")
getUnique(arr, comp) {
  // store the comparison  values in array
  var unique = arr.map(e => e[comp])
    // store the indexes of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)
    // eliminate the false indexes & return unique objects
    .filter((e) => arr[e]).map(e => arr[e]);
  return unique;
}

  afterChange(e) {
    // console.log(e,'afterChange');
  }

  beforeChange(e) {
    this.currentCardIndex = e.nextSlide;
    this.previousCardIndex = e.currentSlide;
  }

  buttonActionClicked() {
    this.showImageLabelCard.forEach((labelElement, index) => {
      if (labelElement.cardIndex === this.currentCardIndex) {
        this.showImageLabelCard.splice(index, 1);
      }
    });
    this.showImageLabelCard.push(this.imageCard[this.previousCardIndex]);
    this.showImageLabelCard = this.getUnique( this.showImageLabelCard, 'heading');
  }
  cardClicked(i) {
    let clickedCardIndex = this.showImageLabelCard[i].cardIndex;
    this.showImageLabelCard = [];

    while (this.showImageLabelCard.length < 4) {
      clickedCardIndex++;
      if (clickedCardIndex >= this.imageCard.length) {
        clickedCardIndex = 0;
      }

      this.showImageLabelCard.push(this.imageCard[clickedCardIndex]);
    }
  }
  navigateEachTreatment(index){
    this.router.navigate([`${ROUTE_PATHS.EACH_TREATEMENT}`],{ queryParams: { heading: this.treatmentCardMoreInfo[index].heading,image:this.treatmentCardMoreInfo[index].img,color:this.treatmentCardMoreInfo[index].color,id:this.treatmentCardMoreInfo[index].id} });
  }
  navigateBookingpage() {
    this.scrollTop();
    //the below line was commented on sept 5 2023 because it was showing toast meassge of error even after working corrctly
    // this.router.navigate([`${ROUTE_PATHS.APPOINTMENT}`]);
    this.router.navigate(['login'])
  }
  scrollTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
}
