import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TreatmentsRoutingModule } from '@app/pages/treatments/treatments-routing.module';
import {TabViewModule} from 'primeng/tabview';

import { CommonAppModule } from '@app/common-app.module';
import { TreatmentsWrapperComponent } from './treatments-wrapper/treatments-wrapper.component';
import { TreatmentsContentComponent } from './treatments-content/treatments-content.component';
import { TreatmentsCardComponent } from './treatments-card/treatments-card.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';

const DECLARATION_COMPONENTS = [
  TreatmentsWrapperComponent,
    TreatmentsContentComponent,
    TreatmentsCardComponent,
];

@NgModule({
  declarations: [
    ...DECLARATION_COMPONENTS,
  ],
  imports: [
    CommonModule,
    TabViewModule,
    CommonAppModule,
    TreatmentsRoutingModule,
    SlickCarouselModule
  ],
  exports: []
})
export class TreatmentsModule { }
