import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile-box',
  templateUrl: './profile-box.component.html',
  styleUrls: ['./profile-box.component.scss']
})
export class ProfileBoxComponent implements OnInit {
  @Input() cardData = {
    name: '',
    status: '',
    picture: 'assets/images/jpgs/success-profile.jpg',
    isSelected: true
  };

  constructor() {
  }

  ngOnInit() {
  }

  cardSelected(event) {
    // if (event) {
    //   this.cardData.isSelected = !this.cardData.isSelected;
    // }
  }

}
