<section class="location__wrapper" (click)="navigateDetailsPage(locationData)">
    <div class="edit__location" (click)="editLocation($event, locationData)" *ngIf="adminPermissions.LOCATION_EDIT">
        <app-svg [name]="'adminEdit'"></app-svg>
    </div>
    <div class="location__header">
        <div class="location__name">{{locationData.locationName}}</div>
        <div class="location__address" *ngIf="locationData.address">{{locationData.address}}</div>
        <div class="location__type">{{locationData.locationType == 'online' ? 'Online':'Walk-in'}}</div>
    </div>
    <div class="location__info--block">
        <div class="location__bottom--block">
            <div>
                <app-svg [name]="'workingHours'"></app-svg>
            </div>
            <div class="location__each-info">
                <div class="title">Working hours</div>
                <div class="value" *ngIf="locationData.timings else elseBlock">
                    {{locationData.timings.startTime | customTime}} to {{locationData.timings.endTime |customTime}}
                </div>
            </div>
        </div>
        <div class="location__bottom--block">
            <div>
                <app-svg [name]="'EmployeesLocations'"></app-svg>
            </div>
            <div class="location__each-info">
                <div class="title">Employees in location</div>
                <div class="value" *ngIf="locationData.employeeCount  else elseBlock">
                    {{locationData.employeeCount.Count}}</div>
            </div>
        </div>
        <div class="location__bottom--block">
            <div>
                <app-svg [name]="'servicesProvidedLocation'"></app-svg>
            </div>
            <div class="location__each-info">
                <div class="title">Services provided in location</div>
                <div class="value" *ngIf="locationData.services && locationData.services.length != 0 ; else elseBlock">
                    <span *ngFor="let service of locationData.services;let ind = index">
                        <span *ngIf="service.appointmentTypeName else elseBlock">{{service.appointmentTypeName}}</span>
                        <span *ngIf="ind !== locationData.services.length-1"> | </span>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <ng-template #elseBlock>---</ng-template>
</section>