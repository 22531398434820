<div class="block-patient__wrapper">
    <div>
    <div *ngIf="!this.eachLocations">
        <div  class="filter-options">
            <div class="check-wrapper">
                <label class="data-display"><img alt="" class="noteicon" src="../../../../assets/images/svgs/info.svg">Did not selected any treatment in the previous page</label>
            </div>
        </div>
    </div>
<div *ngIf="this.eachLocations">
<ng-container *ngFor="let field of eachTreatmentLocations;let i=index">
    <div  class="filter-options">
        <div class="check-wrapper">
            <div class="overlay-label" [ngClass]="{'check__intermediate': field.appointmentTypeName}">
                <!-- <p-checkbox [value]="field.appointmentTypeName" [ngModelOptions]="{standalone: true}" [(ngModel)]="field.isSelected" label="{{field.appointmentTypeName | titlecase}}" binary="true" (onChange)="categorySelect($event, i)">
                </p-checkbox> -->
                <label>{{field.appointmentTypeName | titlecase}}</label>
            </div>

            <div class="task__block">
                <div class="in-review-checkbox" *ngFor="let task of field.locationName; let taskID = index">
                    <p-checkbox [value]="task.loctionId" label="{{task.loctionName | titlecase}}" [ngModelOptions]="{standalone: true}" [(ngModel)]="task.isSelected" binary="true" (onChange)="optionSelect($event, taskID, i)">
                    </p-checkbox>
                </div>
            </div>
        </div>
    </div>
</ng-container>
</div>
</div>
</div>