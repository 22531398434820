import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { RequestDocumentComponent } from './request-document/request-document.component';
import { ActivateGuard } from '@app/common/services/activate.guard';
const routes: Routes = [
  {
    path: '',
    component: RequestDocumentComponent,
    canActivate: [ActivateGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RequestDocumentRoutingModule { }
