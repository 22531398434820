import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { DataService } from '@app/common/services/data.service';
import { HttpService } from '@app/common/services/http.service';

@Component({
  selector: 'app-telemedicine-consent',
  templateUrl: './telemedicine-consent.component.html',
  styleUrls: ['./telemedicine-consent.component.scss']
})
export class TelemedicineConsentComponent implements OnInit {
  @Input() saveFormData;
  @Input() patientDataList;
  @Input() userName;
  @Output() submitEvent: EventEmitter<any> = new EventEmitter();
  tabContent = [
    {
      headerLabel: 'Introduction',
      data: [
        {
          description: 'Telemedicine involves the use of electronic communications to enable health care providers at different locations to share individual patient medical information for the purpose of improving patient care. Providers may include primary care practitioners, specialists, and/or subspecialists. The information may be used for diagnosis, therapy, follow-up and/or education, and may include any of the following:',
          list: [
            'Patient Medical Records',
            'Medical Images',
            'Live two-way audio and video',
            'Output data from medical devices and sound and video files'
          ]
        },
        {
          description: 'Electronic systems used will incorporate network and software security protocols to protect the confidentiality of patient identification and imaging data and will include measures to safeguard the data and to ensure its integrity against intentional or unintentional corruption. Expected Benefits:',
          list: [
            'Improved access to medical care by enabling a patient to remain in his/her physician’s office (or at remote site) while the physician obtains test results and consults from healthcare practitioners at distant/other sites',
            'More efficient medical evaluation and management',
            'Obtaining expertise of a distant specialist'
          ]
        }
      ]
    },
    {
      headerLabel: 'Possible Risks',
      data: [
        {
          description: 'As with any medical procedure, there are potential risks associated with the use of telemedicine. These risks include, but may not be limited to:',
          list: [
            'In rare cases, information transmitted may not be sufficient (e.g. poor resolution of images) to allow for appropriate medical decision making by the physician and consultant(s)',
            'Delays in medical evaluation and treatment could occur due to deficiencies or failures of the equipment',
            'In very rare instances, security protocols could fail, causing a breach of privacy of personal medical information'
          ]
        },
        {
          description: 'In rare cases, a lack of access to complete medical records may result in adverse drug interactions or allergic reactions or other judgement errors',
        }
      ]
    },
    {
      headerLabel: 'State Laws',
      data: [
        {
          description: 'On October 1, 2012, Maryland became the 13th state to require private sector insurance companies to pay for telehealth services in mandating that private payers cover telehealth services that are considered medically necessary and would otherwise be covered when provided face-to-face.'
        },
        {
          description: 'Maryland’s law defines telemedicine (or telehealth) as “interactive audio, video or other telecommunications or electronic technology... to deliver a health care service.” As such, the law does not apply to audio-only phone conversations, email messages or faxes between providers and patients.'
        },
        {
          description: 'The law requires that health insurers and managed care organizations (MCOs) provide coverage for health care services provided appropriately using telehealth technology, and that coverage cannot be denied because services were provided through telehealth rather than inperson. Insurers are not required to cover telehealth services if the health services would not be a covered benefit even if provided in person, or if the provider is out-of-network.'
        },
        {
          description: `“Telehealth means the use of telecommunications and information technologies for the exchange of
          information from one site to another, for the provision of health care to an individual from a provider
          through hardwire or Internet connection.”`
        },
        {
          description: `(Source: MD Health Occupations Annotated Sec. 2-101)`
        },
        {
          description: `Telemedicine means, as it relates to the delivery of health care services, the use of interactive audio,
          video, or other telecommunications or electronic technology:`
        },
        {
          description: `By a health care provider to deliver a health care service that is within the scope of practice of the
          health care provider at a site other than the site at which the patient is located; and That enables the
          patient to see and interact with the health care provider at the time the health care service is
          provided to the patient.`
        },
        {
          description: `(Source: Health General Code 15-105.2)`
        },
        {
          description: `Services by electronic means, including but not limited to telephone communication, the Internet,
          facsimile machines, and e-mail is considered telemedicine by the State of Maryland (see above).
          Thus, telemedicine is broadly defined as the use of information technology to deliver medical
          services and information from one location to another. If you and I chose to use information
          technology for some or all of your treatment, you need to understand that:`,
          list: [
            `You retain the option to withhold or withdraw consent at any time without affecting the right
            to future care or treatment or risking the loss or withdrawal of any program benefits to
            which you would otherwise be entitled.`,
            `All existing confidentiality protections are equally applicable.`,
            `Your access to all medical information transmitted during a telemedicine consultation is
            guaranteed, and copies of this information are available for a reasonable fee.`,
            `Dissemination of any of your identifiable images or information from the telemedicine
            interaction to researchers or other entities shall not occur without your consent.`,
            `There are potential risks, consequences, and benefits of telemedicine. Potential benefits
            include, but are not limited to improved communication capabilities, providing convenient
            access to up-to-date information, consultations, support, reduced costs, improved quality,
            change in the conditions of practice, improved access to therapy, better continuity of care,
            and reduction of lost work time and travel costs. Effective therapy is often facilitated when
            the therapist gathers within a session or a series of sessions, a multitude of observations,
            information, and experiences about the client. Therapists may make clinical assessments,
            diagnosis, and interventions based not only on direct verbal or auditory communications,
            written reports, and third person consultations, but also from direct visual and olfactory
            observations, information, and experiences.`

          ]
        },
        {
          description: `Risks may include, but are not limited to:`,
          list: [
            `Lack of reimbursement by your insurance company.`,
            `The technology dropping due to internet connections.`,
            `Delays due to connections or other technologies.`,
            `A breach of information that is beyond our control.`
          ]
        },
        {
          description: `Clinical risks will be discussed in more detail when we speak about telehealth, but may include:`,
          list: [
            `Discomfort with virtual face-to-face versus in-person treatment,`,
            `Difficulties interpreting non-verbal communication, and`,
            `Limited access to immediate resources if risk of self-harm or harm to others becomes
            apparent.`
          ]
        },
        {
          description: `Again, I will discuss the specifics of telehealth with you prior to proceeding with using technology as
          a resource and format for your psychotherapy.`
        }
      ]
    },
    {
      headerLabel: 'Appointment Process',
      data: [
        {
          listtype: 'numeric',
          list: [
            'Make an appointment.',
            'Consent forms received and signed',
            'Make payment for your appointment (You will receive a call the day prior to your appointment or the day of your appointment for payment)',
            'Meet via video with Dr. Prayaga',
            'Validation of medication and medication will be electronically submitted to the pharmacy.'
          ]
        },
        {
          description: 'If any of the above 5 steps are not completed, there will be a delay in your appointment as well as delay in electronically sending your prescription to your pharmacy.'
        },
        {
          description: 'Please keep in mind that you are financially responsible to pay for your telemedicine appointment. If all of the 5 steps have been completed and you still are experiencing issues, please contact the office manager at officemanager@newstressclinic.com'
        },
        {
          warning: 'Cancellation Policy: If you fail to provide a 24 – hour notification for cancellation or fail to connect for your appointment, you will be assessed a $25.00 cancellation fee/no show fee. If a client fails to show for 2 consecutive telemedicine appointments, then any further telemedicine appointments will not be honored and will be rejected by the office.'
        },
        {
          warning: 'For any reason, you are not able to fill the medication, you will need to pay an ADMIN fee within 10 days from the date of Appointment, if it is more than 10 days you will have to schedule another appointment.'
        }
      ]
    },
    {
      headerLabel: 'Terms and conditions',
      data: [
        {
          description: 'By signing this form, I understand the following;',
          list: [
            'I understand that the laws that protect privacy and the confidentiality of medical information also apply to telemedicine, and that no information obtained in the use of telemedicine which identifies me will be disclosed to researchers or other entities without my consent',
            'I understand that I have the right to withhold or withdraw my consent to the use of telemedicine in the course of my care at any time, without affecting my right to future care or treatment',
            'I understand that I have the right to inspect all information obtained and recorded in the course of telemedicine interaction, and may receive copies of this information for a reasonable fee',
            'I understand telemedicine sessions must be conducted in a safe and secure environment. The physician will not conduct sessions with individuals while they are operating a motor vehicle or other situations which the physician deems unsafe or dangerous. Due to confidentiality, we do ask that you select a private area for sessions.',
            'I understand that a variety of alternative methods of medical care may be available to me, and that I may choose one or more of these at any time. My physician has explained the alternatives to my satisfaction.',
            'I understand that telemedicine may involve electronic communication of my personal medical information to other medical practitioners who may be located in other areas, including out of state.',
            'I understand that it is my duty to inform my physician of electronic interactions regarding my care that I may have with other healthcare providers.',
            'I understand that I may expect the anticipated benefits from the use of telemedicine in my care, but that no results can be guaranteed or assured.',
            'I understand that the information about change in medication consultation must be done only during office visit, not through telemedicine appointment.',
            'I understand that in order to protect patients’ privacy and the integrity of the Telemedicine appointments, video and/or audio recording by either patients and/or Dr. Prayaga is prohibited during Telemedicine sessions.',
            'I understand that I will email to officemanager@newstressclinic.com if any concerns or experience technical glitches (unable to connect, poor connection, audio failure, etc.) during my Telemedicine appointment. Same day office appointments will be honored at no additional cost.',
            'I understand that three month supply of medications will not be provided. Medications will be filled once monthly only after Appointment.',
            'I agree to pay all administrative/processing fees including to and but not limited cancellations fees and documentation/medical records processing fees, etc.',
            'It is mandatory to inform the clinic if you received medications after Telemedicine appointment via email to drprayaga@newstressclinic.com (reason we request some pharmacies will have out-of-stock, auth is needed, pharmacy will not be able to find in the system in rare cases).',
            'I agree to pay $5 administrative fee if my pharmacy is out of stock or if there is any pharmacy change once meds have been sent.',
            'I acknowledge that medications are received via Email after the appointment.',
            'No refills will be issued during Telemedicine.',
            'Please note telemedicine is still cumbersome process for the provider due to careful documentation regarding what pharmacy to send checking PMP, pre-authorization for some medicines, request for 3 months medicine due to insurance requirements etc.so it\'s important for consumer to note video session could be very brief and clients can call office if telemedicine visit is unsatisfactory.',
            'Telemedicine is convenient tool for consumer at no extra cost, but clients are expected to acknowledge their visit, medicine they received by simple email to our office.',
            'Telemedicine is for consumer convenience but not for the provider.',
            'Please note that it may take up to 48-96 hrs to get the medicines filled after the Telemedicine session. It is also important to inform Dr.Prayaga if medications are not received within 96 hrs via Email.',
            'No pets are allowed in the office.',
            'Newstressclinic/ Dr.Prayaga are not responsible for pharmacy changes. Pharmacy has to be updated at least 2 hours before the appointment.',
            'For any reason the pharmacy is not able to fill the prescription, the amount for the visit will not be refunded.',
            'Dr.Prayaga requests drug screening at CLIA certified lab center. If any guidance is required office will assist.',
            'Dr.Prayaga requests to work with newteledoc for coordination of Care with PCP,therapist, or any other providers.'
          ]
        }
      ]
    }
  ];
  tabContent1 = [
    {
      headerLabel: 'Introduction',
      data: [
        {
          description: 'Telemedicine involves the use of electronic communications to enable health care providers at different locations to share individual patient medical information for the purpose of improving patient care. Providers may include primary care practitioners, specialists, and/or subspecialists. The information may be used for diagnosis, therapy, follow-up and/or education, and may include any of the following:',
          list: [
            'Patient Medical Records',
            'Medical Images',
            'Live two-way audio and video',
            'Output data from medical devices and sound and video files'
          ]
        },
        {
          description: 'Electronic systems used will incorporate network and software security protocols to protect the confidentiality of patient identification and imaging data and will include measures to safeguard the data and to ensure its integrity against intentional or unintentional corruption. Expected Benefits:',
          list: [
            'Improved access to medical care by enabling a patient to remain in his/her physician’s office (or at remote site) while the physician obtains test results and consults from healthcare practitioners at distant/other sites',
            'More efficient medical evaluation and management',
            'Obtaining expertise of a distant specialist'
          ]
        }
      ]
    },
    {
      headerLabel: 'Possible Risks',
      data: [
        {
          description: 'As with any medical procedure, there are potential risks associated with the use of telemedicine. These risks include, but may not be limited to:',
          list: [
            'In rare cases, information transmitted may not be sufficient (e.g. poor resolution of images) to allow for appropriate medical decision making by the physician and consultant(s)',
            'Delays in medical evaluation and treatment could occur due to deficiencies or failures of the equipment',
            'In very rare instances, security protocols could fail, causing a breach of privacy of personal medical information'
          ]
        },
        {
          description: 'In rare cases, a lack of access to complete medical records may result in adverse drug interactions or allergic reactions or other judgement errors',
        }
      ]
    },
    {
      headerLabel: 'State Laws',
      data: [
        {
            description: `“VA-DC law defines telemedicine (or telehealth) as “interactive audio, video or other telecommunications or electronic technology... to deliver a health care service.” As such, the law does not apply to audio-only phone conversations, email messages or faxes between providers and patients.”`
          },
          {
            description: `“The law requires that health insurers and managed care organizations (MCOs) provide coverage for health care services provided appropriately using telehealth technology, and that coverage cannot be denied because services were provided through telehealth rather than inperson. Insurers are not required to cover telehealth services if the health services would not be a covered benefit even if provided in person, or if the provider is out-of-network.”`
          },
        {
          description: `“Telehealth means the use of telecommunications and information technologies for the exchange of
          information from one site to another, for the provision of health care to an individual from a provider
          through hardwire or Internet connection.”`
        },
        {
          description: `Telemedicine means, as it relates to the delivery of health care services, the use of interactive audio,
          video, or other telecommunications or electronic technology:`
        },
        {
          description: `By a health care provider to deliver a health care service that is within the scope of practice of the
          health care provider at a site other than the site at which the patient is located; and That enables the
          patient to see and interact with the health care provider at the time the health care service is
          provided to the patient.`
        },
        {
          description: `Services by electronic means, including but not limited to telephone communication, the Internet,
          facsimile machines, and e-mail is considered telemedicine by the State of VA-DC (see above).
          Thus, telemedicine is broadly defined as the use of information technology to deliver medical
          services and information from one location to another. If you and I chose to use information
          technology for some or all of your treatment, you need to understand that:`,
          list: [
            `You retain the option to withhold or withdraw consent at any time without affecting the right
            to future care or treatment or risking the loss or withdrawal of any program benefits to
            which you would otherwise be entitled.`,
            `All existing confidentiality protections are equally applicable.`,
            `Your access to all medical information transmitted during a telemedicine consultation is
            guaranteed, and copies of this information are available for a reasonable fee.`,
            `Dissemination of any of your identifiable images or information from the telemedicine
            interaction to researchers or other entities shall not occur without your consent.`,
            `There are potential risks, consequences, and benefits of telemedicine. Potential benefits
            include, but are not limited to improved communication capabilities, providing convenient
            access to up-to-date information, consultations, support, reduced costs, improved quality,
            change in the conditions of practice, improved access to therapy, better continuity of care,
            and reduction of lost work time and travel costs. Effective therapy is often facilitated when
            the therapist gathers within a session or a series of sessions, a multitude of observations,
            information, and experiences about the client. Therapists may make clinical assessments,
            diagnosis, and interventions based not only on direct verbal or auditory communications,
            written reports, and third person consultations, but also from direct visual and olfactory
            observations, information, and experiences.`

          ]
        },
        {
          description: `Risks may include, but are not limited to:`,
          list: [
            `Lack of reimbursement by your insurance company.`,
            `The technology dropping due to internet connections.`,
            `Delays due to connections or other technologies.`,
            `A breach of information that is beyond our control.`
          ]
        },
        {
          description: `Clinical risks will be discussed in more detail when we speak about telehealth, but may include:`,
          list: [
            `Discomfort with virtual face-to-face versus in-person treatment,`,
            `Difficulties interpreting non-verbal communication, and`,
            `Limited access to immediate resources if risk of self-harm or harm to others becomes
            apparent.`
          ]
        },
        {
          description: `Again, I will discuss the specifics of telehealth with you prior to proceeding with using technology as
          a resource and format for your psychotherapy.`
        }
      ]
    },
    {
      headerLabel: 'Appointment Process',
      data: [
        {
          listtype: 'numeric',
          list: [
            'Make an appointment.',
            'Consent forms received and signed',
            'Make payment for your appointment (You will receive a call the day prior to your appointment or the day of your appointment for payment)',
            'Meet via video with Dr. Prayaga',
            'Validation of medication and medication will be electronically submitted to the pharmacy.'
          ]
        },
        {
          description: 'If any of the above 5 steps are not completed, there will be a delay in your appointment as well as delay in electronically sending your prescription to your pharmacy.'
        },
        {
          description: 'Please keep in mind that you are financially responsible to pay for your telemedicine appointment. If all of the 5 steps have been completed and you still are experiencing issues, please contact the office manager at officemanager@newstressclinic.com'
        },
        {
          warning: 'Cancellation Policy: If you fail to provide a 24 – hour notification for cancellation or fail to connect for your appointment, you will be assessed a $25.00 cancellation fee/no show fee. If a client fails to show for 2 consecutive telemedicine appointments, then any further telemedicine appointments will not be honored and will be rejected by the office.'
        },
        {
          warning: 'For any reason, you are not able to fill the medication, you will need to pay an ADMIN fee within 10 days from the date of Appointment, if it is more than 10 days you will have to schedule another appointment.'
        }
      ]
    },
    {
      headerLabel: 'Terms and conditions',
      data: [
        {
          description: 'By signing this form, I understand the following;',
          list: [
            'I understand that the laws that protect privacy and the confidentiality of medical information also apply to telemedicine, and that no information obtained in the use of telemedicine which identifies me will be disclosed to researchers or other entities without my consent',
            'I understand that I have the right to withhold or withdraw my consent to the use of telemedicine in the course of my care at any time, without affecting my right to future care or treatment',
            'I understand that I have the right to inspect all information obtained and recorded in the course of telemedicine interaction, and may receive copies of this information for a reasonable fee',
            'I understand telemedicine sessions must be conducted in a safe and secure environment. The physician will not conduct sessions with individuals while they are operating a motor vehicle or other situations which the physician deems unsafe or dangerous. Due to confidentiality, we do ask that you select a private area for sessions.',
            'I understand that a variety of alternative methods of medical care may be available to me, and that I may choose one or more of these at any time. My physician has explained the alternatives to my satisfaction.',
            'I understand that telemedicine may involve electronic communication of my personal medical information to other medical practitioners who may be located in other areas, including out of state.',
            'I understand that it is my duty to inform my physician of electronic interactions regarding my care that I may have with other healthcare providers.',
            'I understand that I may expect the anticipated benefits from the use of telemedicine in my care, but that no results can be guaranteed or assured.',
            'I understand that the information about change in medication consultation must be done only during office visit, not through telemedicine appointment.',
            'I understand that in order to protect patients’ privacy and the integrity of the Telemedicine appointments, video and/or audio recording by either patients and/or Dr. Prayaga is prohibited during Telemedicine sessions.',
            'I understand that I will email to officemanager@newstressclinic.com if any concerns or experience technical glitches (unable to connect, poor connection, audio failure, etc.) during my Telemedicine appointment. Same day office appointments will be honored at no additional cost.',
            'I understand that three month supply of medications will not be provided. Medications will be filled once monthly only after Appointment.',
            'I agree to pay all administrative/processing fees including to and but not limited cancellations fees and documentation/medical records processing fees, etc.',
            'If you experience technical glitches (unable to connect, poor connection, audio failure,etc.), same day office appointments will be honored at no additional cost.',
            'It is mandatory to inform the clinic if you received medications after Telemedicine appointment via email to drprayaga@newstressclinic.com (reason we request some pharmacies will have out-of-stock, auth is needed, pharmacy will not be able to find in the system in rare cases).',
            'I agree to pay $5 administrative fee if my pharmacy is out of stock or if there is any pharmacy change once meds have been sent.',
            'I acknowledge that medications are received via Email after the appointment.',
            'No refills will be issued during Telemedicine.',
            'Please note telemedicine is still cumbersome process for the provider due to careful documentation regarding what pharmacy to send checking PMP, pre-authorization for some medicines, request for 3 months medicine due to insurance requirements etc.so it\'s important for consumer to note video session could be very brief and clients can call office if telemedicine visit is unsatisfactory.',
            'Telemedicine is convenient tool for consumer at no extra cost, but clients are expected to acknowledge their visit, medicine they received by simple email to our office.',
            'Telemedicine is for consumer convenience but not for the provider.',
            'Please note that it may take up to 48-96 hrs to get the medicines filled after the Telemedicine session. It is also important to inform Dr.Prayaga if medications are not received within 96 hrs via Email.',
            'No pets are allowed in the office.',
            'Newstressclinic/ Dr.Prayaga are not responsible for pharmacy changes. Pharmacy has to be updated at least 2 hours before the appointment.',
            'For any reason the pharmacy is not able to fill the prescription, the amount for the visit will not be refunded.',
            'Dr.Prayaga requests drug screening at CLIA certified lab center. If any guidance is required office will assist.',
            'Dr.Prayaga requests to work with newteledoc for coordination of Care with PCP,therapist, or any other providers.'

          ]
        }
      ]
    }
  ];
  consentPoints = [
    `If the prescription/medication is lost or stolen due to any circumstances, you need to submit a
    police report and then schedule a follow up appointment. Until then medication will not be
    called in.`
  ];

  telemedicineForm: any;
  patientId: any;
  apptState: any;
  autoForm: any;
  autoFill: boolean;

  constructor(private formService: CustomFormHandlerService, private dataService: DataService, private http: HttpService) { }

  ngOnInit() {
    this.autoForm = this.formService.initForm(this.formService.formTypes.AUTOFILLTELEMEDECINEDETAILS);
    this.telemedicineForm =  this.formService.initForm(this.formService.formTypes.TELEMEDICINE);
    // this.getPateint(this.userName.userId);
    this.getPateint();
    if(this.dataService.previousAppointmentData == null){
      this.autoFill = false;
    }
    else{
      this.autoFill = true;
    }
  }
  getFormData(event) {
    if (event) {
      this.formService.telemedicineConsentFormConfig = this.assignFormValues(event, this.formService.telemedicineConsentFormConfig);
      this.dataService.setData(this.dataService.dataObject.TELEMEDICINE_CONSENT, this.formService.telemedicineConsentFormConfig);
      this.submitEvent.emit({status: event,  step: 5});
      console.log(this.telemedicineForm);
    }
  }
  getdata(event){
      if(!event.slider.value === true){
        this.telemedicineForm.formInputs[0].value= true;
        this.telemedicineForm.formInputs[1].value= true;
        this.telemedicineForm.formInputs[2].value= true;
        this.telemedicineForm.formInputs[3].value= true;
      }
      else{
        this.telemedicineForm.formInputs[0].value= false;
        this.telemedicineForm.formInputs[1].value= false;
        this.telemedicineForm.formInputs[2].value= false;
        this.telemedicineForm.formInputs[3].value= false;
      }
  }
  getPateint() {
    // let payload = {
    //   type: 'GET',
    //   url: 'getPatient',
    //   isDeveloper: true,
    //   pathVariables: [userId]
    // };
    // this.http.makeHttpRequest(payload).subscribe((res) => {
      // if (this.http.isSuccessfulResponse(res) && res) {
      if(this.patientDataList){
        this.patientId = this.dataService.data.appointmentDetail.appoinmentData[1].value;
        for(let i = 0; i<this.patientDataList.patients.length; i++){
          if(this.patientId==this.patientDataList.patients[i].patientId){
              this.apptState = JSON.parse(this.patientDataList.patients[i].patientBasicInfo).streetAddress.state;
          }
      }
      }
    // });
  }
  assignFormValues(formData, formConfig) {
    let keys = Object.keys(formData.value);
    formConfig.forEach((each, index) => {
      if (keys.includes(each.key)) {
        each.value = formData.value[each.key];
      }
    });
    return formConfig;
  }
}
