import { Component, OnInit, Input, ElementRef, ChangeDetectorRef, AfterContentChecked} from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { HttpService } from '@app/common/services/http.service';
import { TOAST_STATUSES } from '@app/common/constants/util.constant';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-create-location',
  templateUrl: './create-location.component.html',
  styleUrls: ['./create-location.component.scss']
})

export class CreateLocationComponent implements OnInit, AfterContentChecked {
  constructor(private formServices: CustomFormHandlerService, private http: HttpService,
              private HelperService: ObservableHelperService, private router: Router,
              private route: ActivatedRoute, private el: ElementRef, private ref: ChangeDetectorRef) {
    this.locationId = route.snapshot.params.id;
  }
  @Input() isCreate: any;
  locationId;
  locationImageUrl;
  formGroup: any = new FormGroup({});
  formInputs;
  form = [];
  formTags: any = [];
  serviceForms = [];
  formSubmitAttempt = false;
  defaultTimeSchedule = {};
  physiciansList = [];
  allServices: any = [];
  locationType = 'offline';
  ngAfterContentChecked(): void {
    this.ref.detectChanges();
  }

  ngOnInit() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    let fromTime = new Date();
    fromTime.setHours(6);
    fromTime.setMinutes(30);
    fromTime.setSeconds(0);
    let toTime = new Date();
    toTime.setHours(23);
    toTime.setMinutes(30);
    toTime.setSeconds(0);
    this.defaultTimeSchedule = { days: [0, 1, 2, 3, 4], daysInString: 'Mon - Fri', fromTime, toTime };
    const formData = this.formServices.initForm(this.formServices.formTypes.LOCATIONCREATION);
    formData.formInputs.forEach(obj => {
        obj.value = '';
        obj.showontoggle = true;
        obj.editable = true;
    });
    console.log(formData);
    this.formGroup = formData.formGroup;
    this.formInputs = formData.formInputs; // --reset input field values
    this.formTags.forEach((element) => {
      this.form.push(this.formServices.initForm(this.formServices.formTypes[element]));
    });
    this.getAllServices();
    this.getLocationTeatmentsList();
  }

  // getting all services at once for drop-down
  getAllServices() {
    const payloadData = {
      type: 'GET',
      url: 'defaultServices',
      isDeveloper: true,
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.allServices = [...res.data];
       // console.log(this.allServices);
      }
    });
  }

  // get appoinements list
  getLocationTeatmentsList() {
    const payloadData = {
      type: 'GET',
      url: 'filterData',
      isDeveloper: true,
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.physiciansList = res.data.doctor.map(ele => ({ label: ele.providerName, value: ele.providerId }));
        if (this.locationId) {
          this.getLocationDetails();
        } else {
          res.data.treatment.forEach(treatment => {
            this.addNewService(treatment);
          });
          console.log(this.serviceForms, ' -service Forms at last');
        }
      } else {
        this.HelperService.showToast(TOAST_STATUSES.ERROR, 'Error in getting location treatments');
      }
    });
  }
  multipleGroupByArray(dataArray, groupPropertyArray) {
    const groups = {};
    dataArray.forEach(item => {
      const group = JSON.stringify(groupPropertyArray(item));
      console.log(group);
      groups[group] = groups[group] || [];
      groups[group].push(item);
    });
    return Object.keys(groups).map((group) => {
      return groups[group];
    });
  }

  getTimingsForService(timings) {
    let ar = timings.map(obj => ({...obj, weekDay: obj.weekDay === 0 ? 6 : obj.weekDay - 1, isTaken: false})).sort((a, b) => a.beginTime.split(':')[0] - b.beginTime.split(':')[0]).sort((a, b) => {
        if (a.beginTime.split(':')[0] === b.beginTime.split(':')[0]) {
            return a.weekDay - b.weekDay;
        }
    }).sort((a, b) => {
      if (a.weekDay < b.weekDay) {
        return a.weekDay - b.weekDay;
      }
    });
    // ar=ar.map(obj=>({...obj,weekDay:obj.weekDay==0?6:obj.weekDay-1,isTaken:false}))
    // tslint:disable-next-line:one-variable-per-declaration
    let tempAr = [], finalArr = [];
    ar.forEach((obA, i) => {
      if (!obA.isTaken) {
          tempAr.push(obA.weekDay);
          // tslint:disable-next-line:no-unused-expression
          ar.length > 1 && ar.forEach((obB, j) => {
              if (i < j && !obB.isTaken) {
                  if (obA.beginTime === obB.beginTime && obA.endTime === obB.endTime) {
                      obB.isTaken = true;
                      tempAr.push(obB.weekDay);
                  }
              }
          });
          let fromTime = new Date();
          fromTime.setHours(obA.beginTime.split(':')[0]);
          fromTime.setMinutes(obA.beginTime.split(':')[1]);
          fromTime.setSeconds(obA.beginTime.split(':')[2]);
          let toTime = new Date();
          toTime.setHours(obA.endTime.split(':')[0]);
          toTime.setMinutes(obA.endTime.split(':')[1]);
          toTime.setSeconds(obA.endTime.split(':')[2]);
          let lastVal = tempAr[(tempAr.length - 1)];
          // tslint:disable-next-line:one-variable-per-declaration
          let newAr = [], same = [];
          // tslint:disable-next-line:no-shadowed-variable
          for (let i = 0; i <= lastVal; i++) {newAr.push(i); }
          newAr.forEach(eachVal => {
              if (tempAr.includes(eachVal)) {
                  same.push(eachVal);
                  if (eachVal === lastVal) {
                    finalArr.push({fromTime, toTime, days: [...same], daysInString: this.getDaysInString([...same])});
                  }
              } else {
                  // tslint:disable-next-line:no-unused-expression
                  same.length > 0 ? finalArr.push({fromTime, toTime, days: [...same], daysInString: this.getDaysInString([...same])}) : null;
                  same = [];
              }
          });
          tempAr = [], lastVal = '', newAr = [];
          obA.isTaken = true;
      }
    });
    return finalArr;
  }

  addNewService(serviceData?) {
    let formsList = this.serviceForms;
    let serviceFormGrp = this.formServices.toReactiveForm(this.formServices.createLocationServiceConfig);
    // console.log(serviceFormGrp);
    if (serviceData) {
      let uptoTime = new Date();
      if (serviceData.duration === 40 || serviceData.duration === 50) {
        uptoTime.setHours(23, 10, 0, 0);
        this.defaultTimeSchedule = {...this.defaultTimeSchedule, toTime: uptoTime};
      } else if (serviceData.duration === 45) {
        uptoTime.setHours(23, 0, 0, 0);
        this.defaultTimeSchedule = {...this.defaultTimeSchedule, toTime: uptoTime};
      } else {
        uptoTime.setHours(23, 30, 0, 0);
        this.defaultTimeSchedule = {...this.defaultTimeSchedule, toTime: uptoTime};
      }
      serviceFormGrp.patchValue({
        treatment: serviceData.appointmentTypeName,
        physician: serviceData.providerId ? this.physiciansList.find(ele => ele.value === serviceData.providerId).value : '',
        sessionDuration: serviceData.duration,
        bookingsPerSlot: serviceData.maxSimultaneousAppointments,
        intakesPerDay: serviceData.intakesPerDay,
        sessionInterval: serviceData.minInterval,
        appointmentTypeId: serviceData.appointmentTypeId,
        doxyUrl: serviceData.meetingLink ? serviceData.meetingLink : '',
        doxyPwd: serviceData.meetingCode ? serviceData.meetingCode : '',
        defaultTreatment: serviceData.appointmentTypeName,
        defaultTreatmentId: serviceData.appointmentTypeId
      });
      formsList.push({
        formGroup: serviceFormGrp,
        formInputs: this.formServices.createLocationServiceConfig,
        schedulesCount: 1, schedules: serviceData.timings && serviceData.timings.length ?
          this.getTimingsForService(serviceData.timings)
          : [this.defaultTimeSchedule]
      });
    } else {
      formsList.unshift({
        formGroup: serviceFormGrp,
        formInputs: this.formServices.createLocationServiceConfig,
        schedulesCount: 1, schedules: [this.defaultTimeSchedule]
      });
    }
    // let ar = ['medication management', 'psychological therapy', 'ketamine treatment', 'tms'];
    formsList = formsList.map(service => {
      service.formInputs = service.formInputs.map(form => {
        if (form.key === 'sessionDuration' || form.key === 'treatment') {
          return {...form, editable: service.formGroup.value.treatment === '' ? true : false};
        } else if (form.key === 'doxyUrl' || form.key === 'doxyPwd') {
          return {...form, showontoggle: this.locationType === 'online' ? true : false};
        } else {
          return {...form};
        }
      });
      return service;
    });
    if (this.locationType === 'online') {
      formsList.forEach(form => {
        form.formGroup.controls['doxyUrl'].setValidators([Validators.required]);
        form.formGroup.controls['doxyUrl'].updateValueAndValidity();
        form.formGroup.controls['doxyPwd'].setValidators([Validators.required]);
        form.formGroup.controls['doxyPwd'].updateValueAndValidity();
      });
    }
    this.serviceForms = formsList;
    this.formSubmitAttempt = false;
  }

  getLocationDetails() {
    const payloadData = {
      type: 'GET',
      url: 'getLocationDetails',
      pathVariables: [this.locationId],
      isDeveloper: true,
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.locationImageUrl = res.data.locationImageUrl;
        this.locationType = res.data.locationType;
        this.formGroup.patchValue({
          locName: res.data.locationName,
          locType: res.data.locationType,
          locUrl: res.data.locationType === 'offline' ? res.data.locationUrl : '',
          /*empinLoc: this.formGroup.value.employeeCount,*/
          address: res.data.locationType === 'offline' ? res.data.address : '',
          locationId: res.data.locationId
        });
        this.formGroup.updateValueAndValidity();
        console.log(this.formGroup);
        res.data.services.forEach(service => {
          this.addNewService(service);
        });
        if (this.locationType === 'online') {
          this.formGroup.controls['address'].clearValidators();
          this.formGroup.controls['address'].updateValueAndValidity();
          this.formInputs.forEach(form => {
            if (form.key === 'locType') {
              form.editable = false;
            } else if (form.key === 'locUrl' || form.key === 'address') {
              form.showontoggle = false;
              form.value = '';
            }
          });
        } else {
          this.formGroup.controls['address'].setValidators([Validators.required]);
          this.formGroup.controls['address'].updateValueAndValidity();
          this.formInputs.forEach(form => {
            if (form.key === 'locType') {
              form.editable = false;
            } else if (form.key === 'locUrl') {
              form.showontoggle = true;
              form.validators = [];
            } else if (form.key === 'address') {
              let valueLength = form.value ? form.value.split('').length : 0;
              form.showontoggle = true;
              form.validators = [Validators.required];
              form.count = `${form.maxLength - valueLength} remaining`;
            }
          });
        }
      } else {
        this.HelperService.showToast(TOAST_STATUSES.ERROR, 'Error in getting location details');
        this.router.navigate(['admin/locations']);
      }
    });
  }

  // validation for Text area
  valideTextArea(control: FormControl): any {
    if (control.value !== '') {
      var regex = /^[^.\s]/;
      var found = control.value.length > 0 && regex.test(control.value);
      return found ? null : { name: true };
    } else {
      return null;
    }
  }
  scrollToFirstInvalidControl() {
    let firstInvalidControl = this.el.nativeElement.querySelector('form .ng-invalid');
    if (firstInvalidControl === null) {
      firstInvalidControl = $(firstInvalidControl).find('form .ng-invalid');
    }
    let commonHeight = $('.common__header--section').outerHeight();
    let invalidTop = $(firstInvalidControl).offset().top - (commonHeight + 40);
    $('html,body').animate({ scrollTop: invalidTop }, 100, () => $('html,body').stop(true, false));
    firstInvalidControl.focus();
  }

  createLocation(event?) {
  //  console.log(this.serviceForms);
    let serviceFormGroup = true;
    this.serviceForms.forEach(service => {
      // tslint:disable-next-line:no-unused-expression
      !service.formGroup.valid ? serviceFormGroup = false : null;
    });
   // console.log(serviceFormGroup);
    if (this.formGroup.valid && serviceFormGroup) {
      // console.log('groups', this.formGroup, this.serviceForms);
      const body = {
        locationName: this.formGroup.value.locName,
        locationType: this.formGroup.value.locType,
        locationUrl: this.formGroup.value.locType === 'offline' ? this.formGroup.value.locUrl : '',
        // locationImageUrl: this.locationImageUrl,
        address: this.formGroup.value.locType === 'offline' ? this.formGroup.value.address : '',
        // employeeCount: Number(this.formGroup.value.empinLoc),
        services: this.serviceForms.map(service => {
          let timingArray = [];
          service.schedules.forEach(timing => {
            let fromTime: any = new Date(timing.fromTime);
            fromTime.setSeconds(0);
            fromTime = fromTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false });
            let toTime: any = new Date(timing.toTime);
            toTime.setSeconds(0);
            toTime = toTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false });
            // console.log(timing);
            timing.days.forEach((day) => {
              timingArray.push({
                beginTime: fromTime,
                endTime: toTime,
                weekDay: day === 6 ? 0 : day + 1
              });
            });
          });
          let serviceObj = {
            appointmentTypeName: service.formGroup.value.treatment,
            duration: Number(service.formGroup.value.sessionDuration),
            maxSimultaneousAppointments: Number(service.formGroup.value.bookingsPerSlot),
            minInterval: Number(service.formGroup.value.sessionInterval),
            intakesPerDay: Number(service.formGroup.value.intakesPerDay),
            providerId: service.formGroup.value.physician,
            providerName: this.physiciansList.find(ele => ele.value === service.formGroup.value.physician).label,
            meetingLink: this.formGroup.value.locType === 'online' ? service.formGroup.value.doxyUrl : '',
            meetingCode: this.formGroup.value.locType === 'online' && service.formGroup.value.doxyPwd ? service.formGroup.value.doxyPwd : '',
            timings: timingArray
          };
          serviceObj['appointmentTypeId'] = service.formGroup.value.defaultTreatmentId !== '' ?
            service.formGroup.value.defaultTreatmentId : null;
          return serviceObj;
        })
      };
      if (this.locationId) { body['locationId'] = this.locationId; } else { body['locationImageUrl'] = ''; }
      this.doCreateLocApi(body);
      this.formSubmitAttempt = false;
    } else {
      this.scrollToFirstInvalidControl();
      this.formSubmitAttempt = true;
    }
  }

  doCreateLocApi(body) {
    const payloadData = {
      type: !this.locationId ? 'POST' : 'PUT',
      url: !this.locationId ? 'createLocation' : 'editLocation',
      isDeveloper: true,
      body
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.HelperService.showToast(TOAST_STATUSES.SUCCESS, `${!this.locationId ? 'Created' : 'Edited'} location successfully`);
      // --reset input field values
        this.formGroup.reset();
        this.serviceForms.map(service => (service.formGroup.reset(), service.schedules = [this.defaultTimeSchedule]));
        this.router.navigate(['admin/locations']);
      } else {
        let ar = [];
        this.serviceForms.forEach(ser => {
          ar.push(ser.formGroup.value.treatment);
        });
        let resp = res.error.message;
        let resTreatment = resp.slice(0, (resp.indexOf('name') - 1));
        let overLap = false; let dupLocation = false;
        if (resp.includes('Overlapped')) { overLap = true; } else if (resp.includes('Duplicate')) { dupLocation = true; }
        if (ar.includes(resTreatment)) {
          this.HelperService.showToast(TOAST_STATUSES.ERROR, `Duplicate treatment name is not allowed. "${resTreatment}" name already exists. Please choose a selection from the provided list`);
        } else if (overLap) {
          let service;
          ar.forEach(treatment => {
            if (resp.includes(treatment)) {
              service = treatment;
            }
          });
          this.HelperService.showToast(TOAST_STATUSES.ERROR, `Overlapped timings are not allowed. Please update the timings for '${service}'`);
        } else if (dupLocation) {
          this.HelperService.showToast(TOAST_STATUSES.ERROR, 'Error in editing location. Location with this name already exists.');
        } else {
          this.HelperService.showToast(TOAST_STATUSES.ERROR, `Error in ${!this.locationId ? 'creating' : 'editing'} location`);
        }
      }
    });
  }

  // cancel locations
  cancelLocation($event) {
    this.router.navigate(['admin/locations']);
  }

   textCounter(field, maxlimit, formGroup?, fieldParent?, i?) {
    if (field.showRange) {
      var id;
      var countfield;
      if (formGroup && fieldParent) {
        id = `${fieldParent.key}${field.key}${i}`;
        countfield = document.getElementById(id) as HTMLInputElement;
      } else {
        countfield = document.getElementById(field.key) as HTMLInputElement;
      }
      let valuedata;
      if (formGroup) {
        valuedata = formGroup.get(fieldParent.key).controls[i].controls[field.key].value;
      } else {
        valuedata = this.formGroup.get(field.key).value;
      }
      //  valuedata = formGroup?this.formGroup.get(field.key).value:formGroup.get(fieldParent.key).controls[i].controls[field.key].value;
      let valueLength = valuedata.split('').length;
      if (valueLength > maxlimit) {
        valuedata = valuedata.substring(0, maxlimit);
        return false;
      } else {
        field.count = `${maxlimit - valueLength} remaining`;
        countfield.value = `${maxlimit - valueLength} remaining`;
      }
    }
  }

  /**
 * this function is used to check error for a given input control
 * @param control {FormControl} - form control to be validated
 * @param formSubmitted {boolean} - to validate the form on form submission
 */
  isInvalid(control, formSubmitted) {
    return ((control.invalid && formSubmitted && (control.touched || control.dirty)) ||
      (formSubmitted && control.invalid)
    );
  }

  // On selection of time at create/edit location on schedule popu-up
  sendTimeScheduleDetails(details, serviceIndex) {
    // console.log(details)
    let editIndex = details.editTimeIndex;
    // tslint:disable-next-line:one-variable-per-declaration
    let firstIndex, indexArr = [], mergedDays = [...details.days];
    this.serviceForms[serviceIndex].schedules.forEach((sched, i) => {
    let scheduleFromTime = sched.fromTime.toString().split(' ')[4];
    let scheduleToTime = sched.toTime.toString().split(' ')[4];
    let detailsFromTime = details.fromTime.toString().split(' ')[4];
    let detailsToTime = details.toTime.toString().split(' ')[4];
    // console.log(scheduleFromTime + ',' + detailsFromTime + '&&' + scheduleToTime + ',' + detailsToTime);
    if (scheduleFromTime === detailsFromTime && scheduleToTime === detailsToTime) {
        firstIndex === undefined ? firstIndex = i : indexArr.push(i);
        // tslint:disable-next-line:no-unused-expression
        i !== editIndex ? mergedDays = mergedDays.concat(sched.days) : null;
        console.log(firstIndex, ' - firstIndex');
      }
    });
    mergedDays.sort((a, b) => a - b);
    if (indexArr.length > 0) {
      for (let i = indexArr.length - 1; i >= 0; i--) { this.serviceForms[serviceIndex].schedules.splice(indexArr[i], 1); }
    }
    if (firstIndex >= 0) {
    this.schedulePush(details, mergedDays, serviceIndex, firstIndex);
    } else if (firstIndex === undefined && editIndex !== -1 && editIndex >= 0) {
    this.schedulePush(details, mergedDays, serviceIndex, editIndex);
    } else {
      this.schedulePush(details, mergedDays, serviceIndex, null);
    }
  }

  // for pushing schedules into serviceForm
  schedulePush(details, days, serviceIndex, index) {
    let ar = [...days];
    let lastVal = ar[ar.length - 1];
    let newArr = [];
    for (let i = 0; i <= lastVal; i++) { newArr.push(i); }
    let send = [];
    let Arr = [];
    newArr.map(val => {
      if (ar.includes(val)) {
        send.push(val);
        // tslint:disable-next-line:no-unused-expression
        val === lastVal ? Arr.push([...send]) : null;
      } else {
        // tslint:disable-next-line:no-unused-expression
        send.length > 0 ? Arr.push([...send]) : null;
        send = [];
      }
    });
    const schduleRecords = Arr.map(dayArr => this.getSchedulesForDisContinueDays(details, dayArr));
   // const schduleRecords = details.days.map((day) => this.getSchedulesForDisContinueDays(details, day));
    if (index != null) { // for edit
      this.serviceForms[serviceIndex].schedules.splice(index, 1, ...schduleRecords);
    } else { // new schedules
      this.serviceForms[serviceIndex].schedules.push(...schduleRecords);
    }
  }
  getDaysInString(days) {
    const daysStrigs = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    if (days.length && days.length > 1) {
      return `${daysStrigs[days[0]]} - ${daysStrigs[days[days.length - 1]]}`;
    } else {
      return daysStrigs[days[0]];
    }
  }
  getSchedulesForDisContinueDays(details, day) {
    return {
      days: [...day],
      daysInString: this.getDaysInString(day),
      fromTime: details.fromTime,
      toTime: details.toTime
    };
  }
  deleteLocationService(serviceIndex) {
    this.serviceForms.splice(serviceIndex, 1);
  }

  editServiceCard(details, index: number) {
    let formGroup = this.serviceForms[index].formGroup.value;
    this.serviceForms[index].formGroup.patchValue({
          treatment: details.service ? details.service.appointmentTypeName : formGroup.treatment,
          physician: details.service ? details.service.providerId : formGroup.physician,
          sessionDuration: details.service ? details.service.duration : formGroup.sessionDuration,
          bookingsPerSlot: details.service ? details.service.maxSimultaneousAppointments : formGroup.bookingsPerSlot,
          intakesPerDay: details.service ? details.service.intakesPerDay : formGroup.intakesPerDay,
          sessionInterval: details.service ? details.service.minInterval : formGroup.sessionInterval,
          doxyUrl: details.service && details.service.meetingLink ? details.service.meetingLink : '',
          doxyPwd: details.service && details.service.meetingCode ? details.service.meetingCode : '',
          defaultTreatment: details.service ? details.service.appointmentTypeName : formGroup.defaultTreatment,
          defaultTreatmentId: details.service ? details.service.appointmentTypeId : ''
      });
    let serviceForm = this.serviceForms[index].formInputs;
    serviceForm = serviceForm.map(form => {
        if (form.key === 'sessionDuration') {
          return {...form, editable: details.click ? false : true};
        } else {
          return form;
        }
      });
    this.serviceForms[index].formInputs = serviceForm;
  }

  // online/offline locationType based on radio selection
  selectedRadio(each) {
    let val = each.value;
    if (val === 'online') {
      this.locationType = val;
      this.formGroup.controls['address'].clearValidators();
      this.formGroup.controls['address'].updateValueAndValidity();
      this.formInputs.forEach(form => {
        if (form.key === 'locUrl' || form.key === 'address') {
          form.showontoggle = false;
          form.value = '';
          form.count = '150 remaining';
        }
      });
      this.serviceForms.forEach(service => {
        service.formInputs.forEach(form => {
          if (form.key === 'doxyUrl' || form.key === 'doxyPwd') {
            service.formGroup.controls[form.key].setValidators([Validators.required]);
            service.formGroup.controls[form.key].updateValueAndValidity();
            form.showontoggle = true;
          }
        });
      });
      console.log(this.serviceForms);
    } else {
      this.locationType = val;
      this.formGroup.controls['address'].setValidators([Validators.required]);
      this.formGroup.controls['address'].updateValueAndValidity();
      this.formInputs.forEach(form => {
        if (form.key === 'locUrl' || form.key === 'address') {
          form.showontoggle = true;
        }
      });
      this.serviceForms.forEach(service => {
        service.formInputs.forEach(form => {
          if (form.key === 'doxyUrl' || form.key === 'doxyPwd') {
            form.showontoggle = false;
            service.formGroup.controls[form.key].setValue('');
            service.formGroup.controls[form.key].patchValue('');
            service.formGroup.controls[form.key].clearValidators();
            service.formGroup.controls[form.key].updateValueAndValidity();
          }
        });
      });
      console.log(this.serviceForms);
    }
  }
}
