<section class="appointment__page__wrapper">
    <div class="close-icon">
        <app-svg [name]="'plus-circle'" (click)="cancel()"></app-svg>
    </div>
    <div class="stepper__block">
        <app-step-indicator [stepDetails]="stepDetails" (stepIndexClicked)="stepIndicatorClicked($event)"
            (forwardClicked)="forwardClicked()" (backClicked)="backClicked()" (sendStepIndex)="getStepIndex($event)"
            [currentStepIndex]="currentFormIndex"></app-step-indicator>
    </div>
    <div class="form--block" [ngSwitch]="currentStep.id">
        <div class="common-stepper-inner" *ngSwitchCase="'tmsQuestionnaire'" class="appointment__block">
            <ng-container>
                <app-subsentence-consent (submitEvent)="getFormData($event)" [patientName]="patientName" [saveFormData]="saveFormData  ? resetSaveFormParity() : false"> 
                </app-subsentence-consent>
            </ng-container>
        </div>
        <div class="common-stepper-inner" *ngSwitchCase="'hamQuestionnaire'" class="appointment__block">
            <ng-container>
                <app-subsentence-treatment (submitEvent)="getFormData($event)" [saveFormData]="saveFormData  ? resetSaveFormParity() : false">
                </app-subsentence-treatment>
            </ng-container>
        </div>
        <div class="common-stepper-inner" *ngSwitchCase="'agreeMentForm'" class="appointment__block">
            <ng-container>
                <app-subsentence-agreement (submitEvent)="getFormData($event)" [saveFormData]="saveFormData  ? resetSaveFormParity() : false">
                </app-subsentence-agreement>
            </ng-container>
        </div>
        
    </div>
    <div class="common-stepper-inner" class="btn-actions">
        <div class="cancel-btn" (click)="cancel()">Cancel</div>
        <div class="common-btn-style btn--inline" (click)="back()" *ngIf="currentFormIndex >0">Prev</div>
        <div class="common-btn-style btn--inline" (click)="saveForm()" *ngIf="!hideSubmit">{{getActionText()}}</div>
    </div>
</section>