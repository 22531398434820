<!-- provide section start -->
<section class="provide__section section-padding full-width">
    <div class="image__block provide-bg">
        <img class="background-leaf" src = "assets/images/pngs/provide-bg.png" alt="banner image" loading="lazy"/>
    </div>
    <!-- provide container start -->
    <div class="provide__container restrict-width padding-lr">
        <!-- home banner heading block start -->
        <h1 class="home__banner-heading-block">
            <h2 class="sub-heading">WE PROVIDE</h2>
            <div class="headings__block">
                <span class="orange-heading">NEW </span>
                <div class="blue-headings">
                    <span class="blue-heading">BREAKTHROUGH</span>
                    <span class="blue-heading">EXPERIENCE </span>
                    <span class="blue-heading">FEELING </span>
                    <span class="blue-heading">AND THERAPIES</span>
                </div>
                <div class="icon__block medium">
                    <img class="background-leaf3" src = "assets/images/pngs/background-leaf-3.png" alt="leaf icon" loading="lazy"/>
                </div>
            </div>
        </h1>
        <!-- home banner heading block end -->
        <app-treatment-card></app-treatment-card>
    </div>
    <!-- provide container end -->
</section>
<!-- provide section end -->
