import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReshedulingAppointmentRoutingModule } from './resheduling-appointment-routing.module';
import { ReshedulingWrapperComponent } from './resheduling-wrapper/resheduling-wrapper.component';
import { CommonAppModule } from '@app/common-app.module';


@NgModule({
  declarations: [ReshedulingWrapperComponent],
  imports: [
    CommonModule,
    CommonAppModule,
    ReshedulingAppointmentRoutingModule
  ]
})
export class ReshedulingAppointmentModule { }
