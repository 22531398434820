import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComingSoonRoutingModule } from '@app/pages/coming-soon/comingSoon-routing.module';
import {TabViewModule} from 'primeng/tabview';

import { CommonAppModule } from '@app/common-app.module';
import { ComingSoonWrapperComponent } from './coming-soon-wrapper/coming-soon-wrapper.component';

const DECLARATION_COMPONENTS = [
  ComingSoonWrapperComponent,
];

@NgModule({
  declarations: [
    ...DECLARATION_COMPONENTS,

  ],
  imports: [
    CommonModule,
    TabViewModule,
    CommonAppModule,
    ComingSoonRoutingModule,
  ],
  exports: []
})
export class ComingSoonModule { }
