<main>
    <section *ngIf="!showPreview" class="tmsnote__section">

        <div class="close-icon">
            <app-svg [name]="'plus-circle'" (click)="navigateBack($event)"></app-svg>
        </div>
    
        <div class="form__container">
            <div class="background-image">
                <img alt="Background-leaf" src="assets/images/pngs/newpatientform-background.png">
            </div>
    
            <div *ngIf="true" class="patient-form">
                <div class="form-header"> Generate TMS Note </div>
    
                <div *ngFor="let formData of form;let i = index" class="formdata">
                    <!-- form -->
                    <app-custom-form [formData]="formData"
                        [checkFormInfo]="saveFormData ? resetSaveFormParity() : false"
                        (submitEvent)="getFormData($event)" (getDropValueList)="dropValue($event)"></app-custom-form> 
                    <!-- <img src="assets/images/pngs/brain.png"> -->
                </div>
    
                <!-- Buttons -->
                <div class="btn-actions">
                    <div class="cancel-btn" (click)="back()">Cancel</div>
                    <div class="common-btn-style" (click)="previewForm()"> Preview </div>
                    <div class="common-btn-style" (click)="saveForm()" *ngIf="paramsData.OperationType === 'CreateNew'">
                        Generate</div>
                    <div class="common-btn-style" (click)="saveForm()" *ngIf="paramsData.OperationType === 'EditExisting'">
                        Save</div>
                </div>
    
            </div>
    
            <!-- <app-common-popup (showHideCancelPopup)='cancelPopupShowHideStatusFun($event)' *ngIf="showScorePopup">
                        <div class="popup-content-block">
                            <div class="popup-confirm-message" >
                                <p> Do you want to ShowScoreHistory click ShowScoreHistory </p>
                            </div>
                            <div class="btn-actions">
                                <div class="common-btn-style proceed-btn" (click)="startAgain()">ShowScoreHistory</div>
                                <div class="common-btn-style proceed-btn" (click)="proceedNote()">dont want ShowScoreHistory</div>
                            </div>
                        </div>
            </app-common-popup> -->
        </div>
    
    </section>
    <section *ngIf="showPreview">
        <div class="preview-close-icon">
            <app-svg [name]="'plus-circle'" (click)="navigateBackToForm()"></app-svg>
        </div>
        <!-- <app-preview-mednotes [dataForPreview]="PreviewData" [noteType]="MednoteData.noteType"
            [medType]="MednoteData.Duration"> </app-preview-mednotes> -->
        <app-preview-tms-notes [dataForPreview]="PreviewData"></app-preview-tms-notes>
    </section>
</main>