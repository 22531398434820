import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { PrivacyWrapperComponent } from './privacy-wrapper/privacy-wrapper.component';
import { WebsiteactiveGuard } from '@app/common/services/websiteactive.guard';

const routes: Routes = [
  {
    path: ROUTE_PATHS.EMPTY,
    component: PrivacyWrapperComponent,
    canActivate: [WebsiteactiveGuard]
  },
  {
    path: '**',
    component: PrivacyWrapperComponent,
    canActivate: [WebsiteactiveGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrivacyRoutingModule { }
