import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { API_URLS } from '@app/common/constants/api-urls.constant';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';
import { DEFAULT_PAGINATION_LIMIT, HTTPMethods, TOAST_STATUSES } from '@app/common/constants/util.constant';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { IAdminPermissionsI, IGetPermissionsPayloadI } from '@app/common/constants/typeInterfaces.constants';

@Component({
  selector: 'app-email-template-wrapper',
  templateUrl: './email-template-wrapper.component.html',
  styleUrls: ['./email-template-wrapper.component.scss']
})
export class EmailTemplateWrapperComponent implements OnInit {
  pageNumber: any = 1;
  pageLimit: any = DEFAULT_PAGINATION_LIMIT;
  // Bread crumb status
  breadCrumbData = {
    link: '/admin',
    linkText: 'Email Templates List'
  };
  // Empty List data
  emptyAppointmentsListMessages = {
    className: 'appointmentsList',
    icon: 'patient',
    message: 'There are no email templates'
  };
  INITIAL_PAYLOAD = {
    pagination: {
      page: this.pageNumber,
      limit: this.pageLimit
    },
  };
  emailTemplateList: any = [];
  paginationData: any = { limit: 10, currentPage: 1, totalPages: 1, data: this.emailTemplateList };
  emailTemplateListCount: any = 0;
  cancelPopupShowHide: boolean = false;
  inputChanged: Subject<string> = new Subject<string>();
  searchInput: any = '';
  emailSearchList: any = [];
  result: any = {};
  excludedEmailsList: any = [];
  showScreen: boolean = false;
  selectedTemplate: any;
  adminPermissions: IAdminPermissionsI = {
    TEMPLATE_EDIT: true,
  };
  permissionsDone: boolean = false;
  constructor(private observable: ObservableHelperService, private router: Router, private http: HttpService, private observableHelperService: ObservableHelperService) {
    this.inputChanged
      .pipe(debounceTime(1500))
      .pipe(distinctUntilChanged())
      .subscribe(newValue => {
        this.searchInput = newValue;
        this.getSearchEmails(this.searchInput);
      });
  }

  ngOnInit() {
    this.getAdminPermissions();
    this.getEmailTemplateList(this.INITIAL_PAYLOAD);
  }

  /**
   * To cancel the popup
   */
  cancel() {
    this.cancelPopupShowHide = false;
  }

  /**
   * Exclude PUT API call
   * This function is for to exclude the selected patients.
   */
  submit() {
    const payloadData = {
      type: HTTPMethods.PUT,
      url: API_URLS.EXCLUDE_PATIENTS,
      isDeveloper: true,
      body: {
        templateId: this.selectedTemplate.id,
        blockList: this.excludedEmailsList
      },
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.cancelPopupShowHide = false;
        this.getEmailTemplateList(this.INITIAL_PAYLOAD);
        this.observable.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.EXCLUDE_PATIENTS_MSG);
      } else {
        // Error
        let message = TOAST_MESSAGES.EXCLUDE_PATIENTS_ERROR_MSG;
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  /**
   * @param bodyData : payload data
   * This method is to get list of email templates
   */
  getEmailTemplateList(bodyData) {
    const payloadData = {
      type: HTTPMethods.POST,
      url: API_URLS.GET_EMAIL_TEMPLATE_LIST,
      isDeveloper: true,
      body: bodyData,
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.showScreen = true;
        this.emailTemplateListCount = res.data.pagination.totalItems;
        this.emailTemplateList = [...this.massageEmailTemplateList(res.data.list)];
        this.paginationData = this.massagePaginationData(res.data);
      } else {
        // Error
        let message = TOAST_MESSAGES.EMAIL_TEMPLATES_FETCH_ERROR_MSG;
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  /**
   * @param list :template list data
   * @returns :massaged list data
   */
  massageEmailTemplateList(list) {
    if (!list && !Array.isArray(list)) { return []; }
    return list.map(eachTemplate => {
      return {
        ...eachTemplate,
        excludeAction: 'exclude',
        editAction: 'edit'
      };
    });
  }

  /**
   * @param data :list data
   * @returns :massaged pagination data
   */
  massagePaginationData(data) {
    if (!data && typeof (data) !== 'object') {
      // Error
    }
    return {
      limit: data.pagination.limit,
      currentPage: data.pagination.page,
      totalPages: Math.ceil(data.pagination.totalItems / data.pagination.limit),
      data: data.list
    };
  }

  /**
   * @param status :popup status
   * This function is to show/hide the popup
   */
  cancelPopupShowHideStatusFun(status) {
    this.selectedTemplate = { ...status.selectedTemplate };
    this.excludedEmailsList = [];
    if(status.action == 'edit'){
      if(status.selectedTemplate.id == '13'){
        this.router.navigate([ROUTE_PATHS.ADMIN, ROUTE_PATHS.INFO_REQUEST_FORM, status.selectedTemplate.id], { queryParams: { edit: true  }});
      }else{
        this.router.navigate([`/${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.EMAIL_TEMPLATES}/${status.selectedTemplate.id}`], { queryParams: { edit: true  }});
      }
    } else {
      this.cancelPopupShowHide = status.cancelpopup;
      if (status.selectedTemplate) {
        this.excludedEmailsList = [...status.selectedTemplate.excludedEmails];
      }
    }
  }

  /**
   * @param emailId : patient email id
   * This method is for removing email ids
   */
  removeEmailId(emailId) {
    if (emailId) {
      this.excludedEmailsList = this.excludedEmailsList.filter(email => email !== emailId);
    }
  }

  /**
 *
 * @param e :selected email data
 */
  selectedEmail(e) {
    if (e) {
      if (!this.excludedEmailsList.includes(e.email)) {
        this.excludedEmailsList.unshift(e.email);
      } else {
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.EMAIL_ALREADY_EXCLUDED_ERROR_MSG);
      }
      this.result = {};
    }
  }


  /**
   *
   * @param e :enter string in search area
   */
  search(e) {
    if (e.query !== '') {
      this.inputChanged.next(e);
    }
  }


  /**
   * @param e :typed string
   * This function is for getting the email ids
   */
  getSearchEmails(e) {
    let bodyData = {
      email: e.query
    };
    let payload = {
      type: HTTPMethods.POST,
      url: API_URLS.SEARCH_MAIL_ADDRESS,
      isDeveloper: true,
      body: bodyData,
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      this.emailSearchList = [];
      if (this.http.isSuccessfulResponse(res) && res) {
        this.emailSearchList = this.filterExcludedEmails(res.data);
      } else {
        let message = TOAST_MESSAGES.EMAILS_DATA_FETCH_ERROR_MSG;
        message = (res.error) ? res.error.message : message;
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  /**
   * 
   * @param emailList :list emails
   * @returns :filtering email list from excluded emails
   */
  filterExcludedEmails(emailList) {
    if (!emailList && !Array.isArray(emailList)) { return []; }
    return emailList.filter(emailData => !this.excludedEmailsList.some(email => email === emailData.email));
  }

  /**
   * 
   * @param data :paginatio data
   * This function will set pagination data and do List APi call
   */
  pageChangedEvent(data) {
    this.INITIAL_PAYLOAD = {
      pagination: {
        page: data.currentPage,
        limit: data.limit,
      }
    };
    this.getEmailTemplateList(this.INITIAL_PAYLOAD);
  }


 /**
  * getting permissions based on user id
  */
 getAdminPermissions() {
  const payloadData: IGetPermissionsPayloadI = {
    type: HTTPMethods.GET,
    url: API_URLS.GET_PERMISSIONS,
    isDeveloper: true
  };
  this.http.makeHttpRequest(payloadData).subscribe((res) => {
    if (this.http.isSuccessfulResponse(res)) {
      let permissions: Array<string> = ['TEMPLATE_EDIT'];
      let permArr: Array<string> = [];
      res.data.permissions.forEach(obj => {
        permArr.push(obj.permissionName);
      });
      if (!permArr.includes('TEMPLATE_LIST')) {
        this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.ACCESS_PERMISSOIN_DENIED_MSG);
        this.router.navigate([`${ROUTE_PATHS.ADMIN}`]);
      }
      permissions.forEach(perm => {
        if (!permArr.includes(perm)) {
          this.adminPermissions[perm] = false;
        }
      });
    } else {
      // Error
      let message: string = (res.error) ? res.error.message : TOAST_MESSAGES.ACCESS_PERMISSION_ERROR_MSG;
      this.observable.showToast(TOAST_STATUSES.ERROR, message);
    }
    this.permissionsDone = true;
  });
}
}
