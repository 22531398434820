<!-- patient details -->
<div class="patient__page-wrapper">
    <div class="background-image">
        <img src="assets/images/pngs/newpatientform-background.png" alt="">
    </div>
    <div class="patient__content" *ngIf="isShowScreen && permissionsDone">
        <!-- header part -->
        <div class="patient__header">
            <app-breadcrumb [breadCrumbData]="breadCrumbData"></app-breadcrumb>
            <!-- Actions list -->
            <div class="patient__actions" [ngClass]="!patientsDetails.details.pointofContact || patientsDetails.details.pointofContact.removedFlag || patientsDetails.details.removedFlag?'breadcrumb_padding':''">
                <ng-container *ngIf="patientsDetails.details.pointofContact && !patientsDetails.details.pointofContact.removedFlag && !patientsDetails.details.removedFlag">
                    <ng-container *ngFor="let eachAction of patientsDetails.actions; let i=index ">
                        <div *ngIf="eachAction.show" class="patient__action" [ngClass]="eachAction.name | lowercase" (clickOutside)="op.hide()"
                           (click)="patientsDetailsActions(eachAction.id,eachAction.name,patientsDetails.details.patientId,patientsDetails.patientName,patientsDetails.details.blockStatus,op,$event)">
                            <app-svg [ngClass]="eachAction.className" [name]="eachAction.icon"></app-svg>
                            <div class="patient__action-name">
                                {{eachAction.name | titlecase}} </div>
                                <p-overlayPanel #op [showTransitionOptions]="'100ms'" [hideTransitionOptions]="'100ms'" [appendTo]="'body'">
                                    <div class="more__option"
                                    (click)='showEditForm()'>Edit patient profile</div>
                                    <div class="line"></div>
                                    <div class="more__option"
                                        (click)='showUserProfile()'>Edit point of contact
                                    </div>
                                </p-overlayPanel>
                        </div>
                    </ng-container>
                    <div class="delete patient__action" *ngIf="patientsDetails.details.status === 'pending' && adminPermissions.PATIENT_EDIT" (click)="deletePatientData()">
                        <app-svg class="patients__icon" title="Delete" [name]="'delete'"></app-svg>
                        <div class="patient__action-name">Delete</div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="patient__main-content {{adminPermissions.PATIENT_EDIT || adminPermissions.APPOINTMENT_CREATE ? '' : 'patientPadding'}}">
            <div class="patient__details-block">
                <div class="patient__main-title">
                    {{patientsDetails.patientName | titlecase}}
                </div>
                <div class="patient__offline-pending-status">
                    <p *ngIf="patientsDetails.patientOffLineStatus;else elseBlock">Offline</p>
                    <ng-template #elseBlock>
                        <p *ngIf=" !patientsDetails.details.removedFlag; else deleteBlock">{{patientsDetails.details.status | titlecase}}</p>
                    </ng-template>
                    <ng-template #deleteBlock>
                        <p class="deleted">
                            Deleted
                        </p>
                    </ng-template>
                </div>
                <!-- patient bank  details -->
                <div class="patient__module-details">
                    <div class="patient__addvanced-data">
                        <div class="patient__advanced-content">
                            <div class="patient__advanced-content-title">
                                <p class="title-text">Patient ID</p>
                                <div class="patient__advanced-content-data">
                                    {{patientsDetails.details.patientId}}
                                </div>
                            </div>
                        </div>
                        <div class="patient__advanced-content">
                            <div class="patient__advanced-content-title">
                                <p class="title-text">Date of Birth</p>
                                <div class="patient__advanced-content-data">
                                    {{patientsDetails.details.patientBasicInfo.dob | dateFormat }}
                                </div>
                            </div>
                        </div>
                        <div class="patient__advanced-content">
                            <div class="patient__advanced-content-title">
                                <p class="title-text">Gender</p>
                                <div class="patient__advanced-content-data">
                                    {{patientsDetails.details.patientBasicInfo.gender | titlecase}}
                                </div>
                            </div>
                        </div>
                        <div class="patient__advanced-content">
                            <div class="patient__advanced-content-title">
                                <p class="title-text">Height</p>
                                <div class="patient__advanced-content-data">{{patientsDetails.patientHeight}}</div>
                                <!-- <div class="patient__advanced-content-data"  *ngIf='patientsDetails.patientHeight == "---"'> -- </div> -->
                            </div>
                        </div>
                        <div class="patient__advanced-content">
                            <div class="patient__advanced-content-title">
                                <p class="title-text">Weight</p>
                                <div class="patient__advanced-content-data" *ngIf='patientsDetails.patientWeight != "---"'>{{patientsDetails.patientWeight}} lbs</div>
                                <div class="patient__advanced-content-data" *ngIf='patientsDetails.patientWeight == "---"'>---</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- patient bank  details -->
                <div class="patient__module-details">
                    <div class="patient__bank-title patient__block-sub-title">
                        Point of Contact
                    </div>
                    <div class="patient__addvanced-data">
                        <div class="patient__advanced-content">
                            <div class="patient__advanced-content-title">
                                <p class="title-text">Name</p>
                                <div class="patient__advanced-content-data">
                                    {{patientsDetails.patientPointofContactName | titlecase }}
                                </div>
                            </div>
                        </div>
                        <div class="patient__advanced-content">
                            <div class="patient__advanced-content-title">
                                <p class="title-text">Email ID</p>
                                <div class="patient__advanced-content-data">
                                    {{patientsDetails.patientPointofContactEmail | ellipsis:
                                        180}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- patient bank  details -->
                <div class="patient__module-details">
                    <div class="patient__bank-title patient__block-sub-title">
                        Patient Contact
                    </div>
                    <div class="patient__addvanced-data">
                        <div class="patient__advanced-content">
                            <div class="patient__advanced-content-title">
                                <p class="title-text">Mobile Number</p>
                                <div class="patient__advanced-content-data">
                                    {{patientsDetails.details.patientBasicInfo.phoneNumber}}
                                </div>
                            </div>
                        </div>
                        <div class="patient__advanced-content">
                            <div class="patient__advanced-content-title">
                                <p class="title-text">Email ID</p>
                                <div class="patient__advanced-content-data">
                                    {{patientsDetails.details.patientBasicInfo.email | ellipsis:
                                        180}}
                                </div>
                            </div>
                        </div>
                        <div class="patient__advanced-content">
                            <div class="patient__advanced-content-title">
                                <p class="title-text">Address</p>
                                <div class="patient__advanced-content-data">
                                    <p
                                        *ngIf="patientsDetails.details.patientBasicInfo.streetAddress.streetAddress1 ==='' ">
                                        {{patientsDetails.details.patientBasicInfo.streetAddress.streetAddress1 |
                                        titlecase | ellipsis: 180}}
                                    </p>
                                    <div class="patient__streetAddress">
                                        <p
                                            *ngIf="patientsDetails.details.patientBasicInfo.streetAddress.streetAddress1 !=='' ">
                                            {{patientsDetails.details.patientBasicInfo.streetAddress.streetAddress1 |
                                            titlecase}}
                                        </p>
                                        <p
                                            *ngIf="patientsDetails.details.patientBasicInfo.streetAddress.streetAddress2 !=='' ">
                                            {{patientsDetails.details.patientBasicInfo.streetAddress.streetAddress2}}
                                        </p>

                                    </div>
                                    <div class="patient__streetAddress">
                                        <p *ngIf="patientsDetails.details.patientBasicInfo.streetAddress.city !=='' ">
                                            {{patientsDetails.details.patientBasicInfo.streetAddress.city | titlecase}}
                                        </p>
                                        <p *ngIf="patientsDetails.details.patientBasicInfo.streetAddress.state !=='' ">
                                            {{patientsDetails.details.patientBasicInfo.streetAddress.state}}</p>
                                        <p
                                            *ngIf="patientsDetails.details.patientBasicInfo.streetAddress.zipCode !=='' ">
                                            {{patientsDetails.details.patientBasicInfo.streetAddress.zipCode}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- patient bank  details -->
                <div class="patient__module-details">
                    <div class="patient__bank-title patient__block-sub-title">
                        Insurance Details
                    </div>
                    <div class="patient__addvanced-data">
                        <div class="patient__advanced-content">
                            <div class="patient__advanced-content-title">
                                <p class="title-text">Policy Holder</p>
                                <div class="patient__advanced-content-data">
                                    {{patientsDetails.details.insuranceDetails.policyHolder.policyHolderName | titlecase
                                    | ellipsis : 180}}
                                </div>
                            </div>
                        </div>
                        <div class="patient__advanced-content">
                            <div class="patient__advanced-content-title">
                                <p class="title-text">Policy Number</p>
                                <div class="patient__advanced-content-data">
                                    {{patientsDetails.details.insuranceDetails.policyHolder.policyHolderMentalHealthNumber
                                    | ellipsis : 180}}
                                </div>
                            </div>
                        </div>
                        <div class="patient__advanced-content">
                            <div class="patient__advanced-content-title">
                                <p class="title-text">Date of Birth</p>
                                <div class="patient__advanced-content-data">
                                    {{patientsDetails.details.insuranceDetails.policyHolder.policyHolderDOB |
                                    dateFormat}}
                                </div>
                            </div>
                        </div>
                        <div class="patient__advanced-content">
                            <div class="patient__advanced-content-title">
                                <p class="title-text">Primary Insurance Health Provider</p>
                                <div *ngIf="patientsDetails.primaryInsuranceProvider.insuranceName !== 'Other'"
                                    class="patient__advanced-content-data"
                                    [title]="patientsDetails.primaryInsuranceProvider.insuranceName | titlecase">
                                    {{patientsDetails.primaryInsuranceProvider.insuranceName | ellipsis :
                                    240}}
                                </div>
                                <div class="patient__advanced-content-data"
                                    *ngIf="patientsDetails.primaryInsuranceProvider.insuranceName === 'Other'"
                                    [title]="patientsDetails.details.insuranceDetails.primaryHolderText | titlecase">
                                    Other ({{patientsDetails.details.insuranceDetails.primaryHolderText | titlecase | ellipsis
                                    : 240}})
                                </div>

                            </div>
                        </div>
                        <div class="patient__advanced-content">
                            <div class="patient__advanced-content-title">
                                <p class="title-text">Secondary Insurance Health Provider</p>
                                <div class="patient__advanced-content-data"
                                    *ngIf="patientsDetails.secondaryInsuranceProvider.insuranceName !== 'Other'"
                                    [title]="patientsDetails.secondaryInsuranceProvider.insuranceName | titlecase">
                                    {{patientsDetails.secondaryInsuranceProvider.insuranceName | ellipsis :
                                    240}}
                                </div>
                                <div *ngIf="patientsDetails.secondaryInsuranceProvider.insuranceName === 'Other'"
                                    class="patient__advanced-content-data"
                                    [title]="patientsDetails.details.insuranceDetails.secondaryHolderText | titlecase">
                                    Other ({{patientsDetails.details.insuranceDetails.secondaryHolderText | titlecase |
                                    ellipsis : 240}})
                                </div>
                            </div>
                        </div>
                        <div class="patient__advanced-content">
                            <div class="patient__advanced-content-title">
                                <p class="title-text">Primary Insurance Member Id</p>
                                <div class="patient__advanced-content-data">
                                    {{patientsDetails.details.insuranceDetails.policyHolder.policyHolderMemberId |
                                    ellipsis: 180}}
                                </div>
                            </div>
                        </div>
                        <div class="patient__advanced-content">
                            <div class="patient__advanced-content-title">
                                <p class="title-text">Secondary Insurance Member Id</p>
                                <div class="patient__advanced-content-data">
                                    {{patientsDetails.details.insuranceDetails.policyHolder.secondaryPolicyHolderMemberId
                                    | ellipsis: 180}}
                                </div>
                            </div>
                        </div>
                        <div class="patient__advanced-content">
                            <div class="patient__advanced-content-title">
                                <p class="title-text">Group Number</p>
                                <div class="patient__advanced-content-data">
                                    {{patientsDetails.details.insuranceDetails.policyHolder.policyHolderGroupNumber |
                                    ellipsis : 180}}
                                </div>
                            </div>
                        </div>
                        <div class="patient__advanced-content">
                            <div class="patient__advanced-content-title">
                                <p class="title-text">Policy Holder Employer</p>
                                <div class="patient__advanced-content-data">
                                    {{patientsDetails.details.insuranceDetails.policyHolder.policyHolderEmployer |
                                    titlecase | ellipsis : 180}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- patient bank  details -->
                <div class="patient__module-details">
                    <div class="patient__bank-title patient__block-sub-title">
                        Pharmacy Details
                    </div>
                    <div class="patient__addvanced-data">
                        <div class="patient__advanced-content">
                            <div class="patient__advanced-content-title">
                                <p class="title-text">Name</p>
                                <div class="patient__advanced-content-data">
                                    {{patientsDetails.details.patientMiscInfo.pharmacyData.pharmacyName | titlecase |
                                    ellipsis: 180}}
                                </div>
                            </div>
                        </div>
                        <div class="patient__advanced-content">
                            <div class="patient__advanced-content-title">
                                <p class="title-text">Phone Number</p>
                                <div class="patient__advanced-content-data">
                                    {{patientsDetails.details.patientMiscInfo.pharmacyAddress.phoneNumber | ellipsis:
                                    180}}
                                </div>
                            </div>
                        </div>
                        <div class="patient__advanced-content">
                            <div class="patient__advanced-content-title">
                                <p class="title-text">Email</p>
                                <div class="patient__advanced-content-data">
                                    {{patientsDetails.details.patientMiscInfo.pharmacyData.pharmacyEmailId | ellipsis:
                                    180}}
                                </div>
                            </div>
                        </div>
                        <div class="patient__advanced-content">
                            <div class="patient__advanced-content-title">
                                <p class="title-text">Address</p>
                                <div class="patient__advanced-content-data">
                                    <p
                                        *ngIf="patientsDetails.details.patientMiscInfo.pharmacyAddress.streetAddress1 ==='' ">
                                        {{patientsDetails.details.patientMiscInfo.pharmacyAddress.streetAddress1 |
                                        titlecase | ellipsis: 180}}
                                    </p>
                                    <div class="patient__streetAddress">
                                        <p
                                            *ngIf="patientsDetails.details.patientMiscInfo.pharmacyAddress.streetAddress1 !=='' ">
                                            {{patientsDetails.details.patientMiscInfo.pharmacyAddress.streetAddress1 |
                                            titlecase | ellipsis: 180}}
                                        </p>
                                        <p
                                            *ngIf="patientsDetails.details.patientMiscInfo.pharmacyAddress.streetAddress2 !=='' ">
                                            {{patientsDetails.details.patientMiscInfo.pharmacyAddress.streetAddress2}}
                                        </p>
                                    </div>
                                    <div class="patient__streetAddress">
                                        <p *ngIf="patientsDetails.details.patientMiscInfo.pharmacyAddress.city !=='' ">
                                            {{patientsDetails.details.patientMiscInfo.pharmacyAddress.city | titlecase}}
                                        </p>
                                        <p *ngIf="patientsDetails.details.patientMiscInfo.pharmacyAddress.state !=='' ">
                                            {{patientsDetails.details.patientMiscInfo.pharmacyAddress.state}}</p>
                                        <p
                                            *ngIf="patientsDetails.details.patientMiscInfo.pharmacyAddress.zipCode !=='' ">
                                            {{patientsDetails.details.patientMiscInfo.pharmacyAddress.zipCode}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- patient bank  details -->
                <div class="patient__module-details">
                    <div class="patient__addvanced-data">
                        <div class="patient__advanced-content">
                            <div class="patient__advanced-content-title">
                                <p class="title-text">Last Visit</p>
                                <div class="patient__advanced-content-data" *ngIf="patientsDetails.lastVisit">
                                    <div class="treatment__loaction">
                                        <p>{{patientsDetails.lastVisit.treatment | titlecase}}</p>
                                        <p>{{patientsDetails.lastVisit.location | titlecase}}</p>
                                    </div>
                                    <div class="time__date">
                                        <p>{{patientsDetails.lastVisit.date | dateFormat }}</p>
                                        <p>{{patientsDetails.lastVisit.time | customeTimeAmPm}}</p>
                                    </div>
                                </div>
                                <div class="patient__advanced-content-data" *ngIf="!patientsDetails.lastVisit">
                                    <div class="patient__advanced-content-data">
                                        ---
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="patient__advanced-content">
                            <div class="patient__advanced-content-title">
                                <p class="title-text">Past Due</p>
                                <div class="patient__advanced-content-data" *ngIf="patientsDetails.details.pastDue;else amountBlock">
                                    $ {{patientsDetails.details.pastDue}}
                                </div>
                                <ng-template #amountBlock>
                                    <div class="patient__advanced-content-data">
                                        ---
                                    </div>
                                </ng-template>
                                
                            </div>
                        </div>
                        <div class="patient__advanced-content">
                            <div class="patient__advanced-content-title">
                                <p class="title-text">Block Status</p>
                                <div class="patient__advanced-content-data"
                                    *ngIf="patientsDetails.details.blockStatus.length !== 0">
                                    <ng-container
                                        *ngFor="let blockedService of BlockedServices; let i=index">
                                        <div class="patient__streetAddress">
                                            <p>{{blockedService.name | titlecase}}</p>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="patient__advanced-content-data"
                                    *ngIf="patientsDetails.details.blockStatus.length === 0">
                                    ---
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- patient id proof  details -->
                <div class="patient__id-proofs">
                    <div class="patient__bank-title patient__block-sub-title">
                        Documents 
                    </div>
                    <p-accordion [multiple]="true">
                        <p-accordionTab header="Patient Documents" expandIcon="pi pi-fw pi-chevron-right" collapseIcon="pi pi-fw pi-chevron-down" [selected]="true" style="margin-top: 15px;">
                            <div class="patient__id-proofs-documents">
                                <div class="patient__id-document"
                                    *ngFor="let uploadsFiles of patientsDetails.uploads; let i=index">
                                    <div class="upload-document__block">
                                        <p>{{uploadsFiles.name | ellipsis: 23}}</p>
                                        <p>.{{uploadsFiles.extenstion}}</p>
                                    </div>
                                    <div class="patient__id-document-actions">
                                        <a [href]="uploadsFiles.file" target="_blank" title="View" class="view-icon">
                                            <app-svg [name]="'view-upload'"></app-svg>
                                        </a>
                                        <p class="down-arrow" (click)="donwloadFile(uploadsFiles.file, uploadsFiles, true)"
                                            title="Download">
                                            <app-svg [name]="'down-arrow'"></app-svg>
                                        </p>
                                    </div>
                                </div>
                                <div class="patient__id-document internal-uploads"
                                    *ngFor="let uploadsFiles of detailPageUpload; let i=index">
                                    <div class="upload-document__block" *ngIf="uploadsFiles && uploadsFiles.file">
                                        <p>{{uploadsFiles.file.name | ellipsis: 23}}</p>
                                        <p>.{{uploadsFiles.file.extenstion}}</p>
                                    </div>
                                    <div class="upload-close-btn" *ngIf="!uploadsFiles.isKetaminePdf">
                                        <app-svg [name]="'cancel-appointment'" (click)="deleteFileUpload(uploadsFiles)">
                                        </app-svg>
                                    </div>
                                    <div class="patient__id-document-actions">
                                        <a [href]="uploadsFiles.docUrl" target="_blank" title="View" class="view-icon">
                                            <app-svg [name]="'view-upload'"></app-svg>
                                        </a>
                                        <p class="down-arrow" (click)="donwloadFile(uploadsFiles.docUrl,uploadsFiles,false)"
                                            title="Download">
                                            <app-svg [name]="'down-arrow'"></app-svg>
                                        </p>
                                    </div>
                                </div>
                                <div class="patient__id-document {{adminPermissions.PATIENT_EDIT ? '' : 'uploadDisable'}}">
                                    <app-multiple-uploads [fileTypes]="uploadFilesData" (uploads)="uploadFunctionCall($event)">
                                    </app-multiple-uploads>
                                </div>
                            </div>
                        </p-accordionTab>
                        <p-accordionTab header="Requested Documents" expandIcon="pi pi-fw pi-chevron-right" collapseIcon="pi pi-fw pi-chevron-down" [selected]="true" style="margin-top: 15px;">
                            <div class="patient__id-proofs-documents" *ngIf="patientsDetails.requestedUploads.length">
                                <div class="patient__id-document"
                                    *ngFor="let uploadsFiles of patientsDetails.requestedUploads; let i=index">
                                    <div class="upload-document__block">
                                        <p>{{uploadsFiles.name | ellipsis: 23}}</p>
                                        <p>.{{uploadsFiles.extenstion}}</p>
                                    </div>
                                    <div class="patient__id-document-actions">
                                        <a [href]="uploadsFiles.file" target="_blank" title="View" class="view-icon">
                                            <app-svg [name]="'view-upload'"></app-svg>
                                        </a>
                                        <p class="down-arrow" (click)="donwloadFile(uploadsFiles.file, uploadsFiles, true)"
                                            title="Download">
                                            <app-svg [name]="'down-arrow'"></app-svg>
                                        </p>
                                    </div>
                                </div>
                                <!-- <div class="patient__id-document internal-uploads"
                                    *ngFor="let uploadsFiles of detailPageUpload; let i=index">
                                    <div class="upload-document__block" *ngIf="uploadsFiles && uploadsFiles.file">
                                        <p>{{uploadsFiles.file.name | ellipsis: 23}}</p>
                                        <p>.{{uploadsFiles.file.extenstion}}</p>
                                    </div>
                                    <div class="upload-close-btn">
                                        <app-svg [name]="'cancel-appointment'" (click)="deleteFileUpload(uploadsFiles)">
                                        </app-svg>
                                    </div>
                                    <div class="patient__id-document-actions">
                                        <a [href]="uploadsFiles.docUrl" target="_blank" title="View" class="view-icon">
                                            <app-svg [name]="'view-upload'"></app-svg>
                                        </a>
                                        <p class="down-arrow" (click)="donwloadFile(uploadsFiles.docUrl,uploadsFiles,false)"
                                            title="Download">
                                            <app-svg [name]="'down-arrow'"></app-svg>
                                        </p>
                                    </div>
                                </div> -->
                                <!-- <div class="patient__id-document {{adminPermissions.PATIENT_EDIT ? '' : 'uploadDisable'}}">
                                    <app-multiple-uploads [fileTypes]="uploadFilesData" (uploads)="uploadFunctionCall($event)">
                                    </app-multiple-uploads>
                                </div> -->
                            </div>
                            <div class="patient__id-proofs-documents empty" *ngIf="!patientsDetails.requestedUploads.length">
                                <p>No documents available</p>
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                </div> 
                <!-- patient documents ends -->
            </div>
            <!-- Appointment section starts -->
            <div class="patient__history-block" *ngIf="adminPermissions.APPOINTMENT_LIST">
                <div class="patient__main-title">
                <!-- Tabs Filter -->
                <app-tab-view [tabsData]='filtersTabText' (selectedTab)="selectedTab($event)"></app-tab-view>
                </div>
                <ng-container *ngIf="selectedTabIndex == 0">
                    <!-- Upcoming appointments starts here -->
                    <div class="appointment__list-block" *ngIf="patientListPerPage && patientListPerPage.length !== 0">
                        <ng-container *ngFor="let patientData of patientListPerPage; let i=index">
                            <app-patient-history-list [adminPermissions]="adminPermissions" [patientList]="patientData"
                                (patientTrigger)="invoiceListActions($event)">
                            </app-patient-history-list>
                        </ng-container>
                    </div>
                    <!-- Upcoming appointments ends here -->
                    <!-- Pagination component starts here -->
                    <app-pagination *ngIf="patientListPageCount > 10" [paginationRawData]="paginationData"
                        (pageChanged)="pageChangedEvent($event)">
                    </app-pagination>
                    <!-- Pagination component ends here -->
                    <div class="empty__list--wrapper" *ngIf="patientListPerPage && patientListPerPage.length === 0">
                        <app-empty-list-data [emptyMessageData]="patientListEmptyData">
                        </app-empty-list-data>
                    </div>
                </ng-container>
                <ng-container *ngIf="selectedTabIndex == 1">
                    <!-- Upcoming appointments starts here -->
                    <div class="appointment__list-block" *ngIf="documentRequests && documentRequests.length !== 0">
                        <ng-container *ngFor="let request of documentRequests; let i=index">
                            <app-patient-documents-request-list [adminPermissions]="adminPermissions" [requestData]="request"
                                (patientTrigger)="requestActions($event)">
                            </app-patient-documents-request-list>
                        </ng-container>
                    </div>
                    <!-- Upcoming appointments ends here -->
                    <!-- Pagination component starts here -->
                    <app-pagination *ngIf="documentRequestsCount > 10" [paginationRawData]="paginationData"
                        (pageChanged)="pageChangedEvent($event)">
                    </app-pagination>
                    <!-- Pagination component ends here -->
                    <div class="empty__list--wrapper" *ngIf="documentRequests && documentRequests.length === 0">
                        <app-empty-list-data [emptyMessageData]="documentsRequestEmptyData">
                        </app-empty-list-data>
                    </div>
                </ng-container>
            </div>
            <!-- Appointment section ends starts -->
        </div>
    </div>
</div>
<app-common-popup (showHideCancelPopup)='cancelPopupShowHideStatusFun($event)' *ngIf="cancelPopupShowHide">
    <div class="popup-content-block">
        <div class="confirm-message" *ngIf="popupMessage === 'archive'">Archiving this patient will cancel all their upcoming appointments. Are you sure you want to continue?</div>
        <div class="confirm-message" *ngIf="popupMessage !== 'archive'">Are you want to {{popupMessage}} this patient?</div>
        <div class="btn-actions">
            <div class="common-btn-style yes-btn" (click)="cancelPopupShowHideStatusFun($event,false)">No</div>
            <div class="common-btn-style"
                (click)="cancelPopupShowHideStatusFun($event,true);cancelPopupShowHide = false">Yes</div>
        </div>
    </div>
</app-common-popup>
<app-common-popup (showHideCancelPopup)='blockPatientPopupShowHideStatusFun($event)' *ngIf="blockPatientPopupShowHide" class="block-poc-popup">
    <div class="stepper__block">
        <app-step-indicator [stepDetails]="stepDetails" (stepIndexClicked)="stepIndicatorClicked($event)"
            (forwardClicked)="forwardClicked()" (backClicked)="backClicked()" (sendStepIndex)="getStepIndex($event)"
            [currentStepIndex]="currentFormIndex"></app-step-indicator>
    </div>
    <div class="form--block" [ngSwitch]="currentStep.id">
        <div class="common-stepper-inner" *ngSwitchCase="'selectedTreatment'" class="appointment__block">
            <ng-container>
                <app-block-patient (submitEvent)="blockPatientSubmited($event)" [filterInputs]="forminputs" [filterData]="rawFilterData" [saveFormData]="saveFormData "></app-block-patient>
            </ng-container>
        </div>
        <div class="common-stepper-inner" *ngSwitchCase="'selectedLocations'" class="appointment__block">
            <ng-container>
                <app-block-location (changeLocationEvent)="changedLocation($event)" [selectedTreatmentData]="selectTreatmentData">
                </app-block-location>
            </ng-container>
        </div>
    </div>
    <div class="common-stepper-inner" class="btn-actions">
        <div class="cancel-btn" (click)="cancel($event)">Cancel</div>
        <div class="common-btn-style btn--inline prev-btn" (click)="back()" *ngIf="currentFormIndex >0">Prev</div>
        <div class="common-btn-style btn--inline" (click)="saveForm()">{{getActionText()}}</div>
    </div>
</app-common-popup>

<!-- sideBar -->
<app-common-popup (showHideCancelPopup)='generateInvoicePopupShowHideStatusFun($event)' *ngIf="generateInvoicePopupShowHide">
    <app-generate-invoice (totalInvoiceAmount)='generateInvoice($event);generateInvoicePopupShowHideStatusFun($event)'>
    </app-generate-invoice>
</app-common-popup>
<app-common-popup (showHideCancelPopup)='makeAsPaidPopupShowHideStatusFun($event)' *ngIf="makeAsPaidPopupShowHide">
    <app-make-as-paid (makeAsPaidData)="generateInvoice($event)" (hidePopUp)="makeAsPaidPopupShowHideStatusFun($event)"></app-make-as-paid>
</app-common-popup>
<app-cancel-booking [isAdmin]="isAdmin" [cancelData]="cancelAppointmentData" (showHideCancelPopup)='cancelAppointmentPopupShowHideStatusFun($event)' *ngIf="cancelAppointmentPopupShowHide"></app-cancel-booking>
<!-- For documents request popup -->

<app-common-popup class="doc-request-popup" (showHideCancelPopup)='documentsRequestPopupStatusFun($event)' *ngIf="displayDocumentsRequestPopup && documentsRequest.length">
    <app-patient-documents-request [documentsRequest]="documentsRequest" [patientId]="patientId" (closePopup)="closeDocumentRequestPopup($event)"></app-patient-documents-request>
</app-common-popup>

<app-common-popup class="edit-poc-popup" (showHideCancelPopup)='editUserPopupStatusFun($event)' *ngIf="displayEditUserPopup">
    <app-user-profile-admin [data]="patientsDetails.patientPointofContact"
    (submitEvent)="submitUserProfie($event)"></app-user-profile-admin>
</app-common-popup>

<app-common-popup (showHideCancelPopup)='labDocumentsRequestPopupStatusFun($event)' *ngIf="displayLabDocumentsRequestPopup && labDocumentsRequest.length">
    <app-patient-lab-documents-request [patientId]="patientId" [documentsRequest]="labDocumentsRequest"  (closePopup)="closeLabDocumentRequestPopup($event)"></app-patient-lab-documents-request>
</app-common-popup>

<section class="paymentPage" >
    <form method="POST"  ngNoForm [action]="paymentRedirectUrl" id="formAuthorizeNetPage" name="formAuthorizeNetTestPage">
        <input type="hidden" id = "paymentvalue" name="token" [value]="paymentToken" />
        <!-- Continue to Authorize.net to Payment Page:  -->
        <button id="btnContinue">Click here to make payment</button>
    </form> 
</section>