<div class="appointment__list-page" *ngIf="permissionsDone">
    <div class="background-image">
        <img src="assets/images/pngs/newpatientform-background.png" alt="bg image">
    </div>
    <!-- Main Appointment List Page Wrapper -->
    <div class="appointment__list-main-wrapper padding-lr">
        <!-- Appointments List -->
        <div class="appointment__list-navigation-menu {{adminPermissions.INVOICE_CREATE ? '' : 'padding-btm'}}">
            <app-breadcrumb [breadCrumbData]="breadCrumbData"></app-breadcrumb>
            <!-- Primary action on Page -->
            <div class="appointment__list-primary-actions">
                <div class="common-btn-style" (click)="createNew()" *ngIf="adminPermissions.INVOICE_CREATE">
                    Create New
                </div>
            </div>
        </div>
        <!-- Filters section -->
        <div class="appointment__list-filetr-wrapper">
            <div class="appointment__list-filter-view">
                <!-- Advanced filters -->
                <div class="appointment__list-advanced-filters">
                    <div class="appointment__list-search-filter">
                        <app-list-search (searchText)="searchFilter($event)"
                            [searchPlaceHolderText]="inputPlaceHoderText"></app-list-search>
                    </div>
                    <div class="appointment__list-date-filter" (click)="filterInvoice()">
                        <app-svg [name]="'filter'"></app-svg>
                    </div>
                </div>
            </div>
        </div>
        <ng-container *ngIf="isShowScreen">
            <!-- Upcoming appointments starts here -->
            <div class="appointment__list-block" *ngIf="invoiceListPerPageCount && invoiceListPerPageCount.length !== 0">
                <ng-container *ngFor="let invoiceList of invoiceListPerPageCount; let i=index">
                    <app-invoice-list-data [adminPermissions]="adminPermissions" [invoiceList]="invoiceList" (invoiceListTrigger)="invoiceListClick($event)">
                    </app-invoice-list-data>
                </ng-container>
            </div>
            <!-- Upcoming appointments ends here -->
            <!-- Pagination component starts here -->
            <app-pagination *ngIf="invoiceListCount > 10" [paginationRawData]="paginationData"
                (pageChanged)="pageChangedEvent($event)">
            </app-pagination>
            <!-- Pagination component ends here -->
            <app-empty-list-data [emptyMessageData]="invoiveListEmptyData" *ngIf="invoiceListPerPageCount && invoiceListPerPageCount.length === 0">
            </app-empty-list-data>
        </ng-container>
    </div>
    <!-- sideBar -->
    <div class="appointment__list-side-bar">
        <p-sidebar [(visible)]="sideBarVisible" position="right" styleClass="p-sidebar-width"
            (onShow)="checkingSidebarState(true)" (onHide)="checkingSidebarState(false)" showCloseIcon=false>
            <app-filters [filterType]="'invoice'" [filterInputs]="forminputs"  [filterData]="rawFilterData" [isFilterClear]="isFilterClear"
                [isFilterPanelClosed]="sideBarVisible"
                (submitEvent)="filterDataEvent($event);checkingSidebarSetStatus(false)" (clearEvent)="clearEventCall($event)"></app-filters>
        </p-sidebar>
    </div>
    <!-- sideBar -->
</div>
<!-- sideBar -->
<app-common-popup (showHideCancelPopup)='generateInvoicePopupShowHideStatusFun($event)'
*ngIf="generateInvoicePopupShowHide">
<app-generate-invoice
    (totalInvoiceAmount)='generateInvoice($event);generateInvoicePopupShowHideStatusFun($event)' [amountGenerated]="amountGenerated" >
</app-generate-invoice>
</app-common-popup>
<app-common-popup (showHideCancelPopup)='makeAsPaidPopupShowHideStatusFun($event)' *ngIf="makeAsPaidPopupShowHide">
    <app-make-as-paid (makeAsPaidData)="generateInvoice($event);makeAsPaidPopupShowHideStatusFun($event)" (hidePopUp)="makeAsPaidPopupShowHideStatusFun($event)" [patientDetails]="patientInvoiceDetails"></app-make-as-paid>
</app-common-popup>
<app-common-popup (showHideCancelPopup)='createInvoicePopupShowHideStatus($event)' *ngIf="createInvoicePopupShowHide">
    <app-create-new-invoice (invoiceAction)="generateInvoice($event);createInvoicePopupShowHideStatus($event)"></app-create-new-invoice>
</app-common-popup>
<section class="paymentPage" >
    <form method="POST"  ngNoForm [action]="paymentRedirectUrl" id="formAuthorizeNetPage" name="formAuthorizeNetTestPage">
        <input type="hidden" id = "paymentvalue" name="token" [value]="paymentToken" />
        <!-- Continue to Authorize.net to Payment Page:  -->
        <button id="btnContinue">Click here to make payment</button>
    </form> 
</section>