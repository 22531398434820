<div>
  <div class="medical_summary-block">
    <div class="medical_sumary-form-block">
      <div class="form_field-block">
        <label for="name">Patient Name:</label>
        <input type="text" class="form_field-input" value="{{patientName}}"/>
      </div>
      <div class="form_field-block">
        <label for="name">DOB:</label>
        <input type="number" class="form_field-input" />
      </div>
      <div class="form_field-block">
        <label for="name">Sex</label>
        <input type="text" class="form_field-input" />
      </div>
      <div class="form_field-block">
        <label for="name">PCP:</label>
        <input type="text" class="form_field-input" />
      </div>
      <div class="form_field-block">
        <label for="name">Address:</label>
        <input type="textarea" class="form_field-input form_field-textarea" />
      </div>
    </div>
    <div class="active_medicines-block">
      <h3 class="section-heading">
        Active Medications
        <span class="line"></span>
      </h3>
      <div class="medical_sumary-form-block">
        <div class="form_field-block">
          <label for="name">Medicine Name:</label>
          <input type="text" class="form_field-input" />
        </div>
        <div class="form_field-block">
          <label for="name">Dosage and Direction:</label>
          <input type="text" class="form_field-input" />
        </div>
      </div>
    </div>
    <div class="active_medicines-block">
      <h3 class="section-heading">Notes</h3>
      <div class="notes_block"><span class="bold_text">BEGIN</span> - Office Visit</div>
      <div class="notes_block"><span class="bold_text">Chief Complaint:</span> Follow-up</div>
      <div class="notes_block"><span class="bold_text">Note Type:</span> Office Visit</div>
      <div class="notes_block">
        <span class="bold_text date-field-text">Date:</span>
        <input type="text" class="form_field-input" />
      </div>
      <div class="notes_block"><span class="bold_text">Created By:</span> Dr. RAMA PRAYAGA, MD (NPI: 1033121058)</div>
      <div class="notes_block"><span class="bold_text">Signed By:</span> Dr. RAMA PRAYAGA, MD (NPI: 1033121058)</div>
    </div>
    <div class="active_medicines-block">
      <h3 class="section-heading">Subjective:</h3>
      <div class="notes_block">Client has been a patient of my psychiatric practice since YEAR TO BE MENTION.</div>
      <div class="notes_block">Client has been diagnosed with Attention-deficit hyperactivity disorder.</div>
      <div class="notes_block">Client has not been hospitalized for Attention-deficit hyperactivity disorder.</div>
    </div>
    <div>
      <div class="notes_block">Medical History: F90.0(Attention-deficit hyperactivity disorder - ADHD)</div>
      <div class="notes_block">Allergies: NONE</div>
    </div>
    <div>
      <div class="notes_block bold_text">Medications:</div>
      <div class="notes_block">Came for Medication Management.</div>
    </div>
    <div>
      <div class="section-heading">Health Background:</div>
      <div class="notes_block bold_text">Social Data:</div>
      <div class="notes_block flex-column">
        <label for="">Occupation</label>
        <input type="text" value="N/A" class="form_field-input" />
      </div>
      <div class="notes_block flex-column">
        <label for="">Tobacco:</label>
        <input type="text" value="N/A" class="form_field-input" />
      </div>
      <div class="notes_block flex-column">
        <label for="">Alcohol:</label>
        <input type="text" value="N/A" class="form_field-input" />
      </div>
    </div>
    <div>
      <div class="notes_block bold_text">Exam:</div>
      <div class="notes_block flex-column">
        <label for="">General:</label>
        <input type="text" value="N/A" class="form_field-input" />
      </div>
      <div class="notes_block flex-column">
        <label for="">Ms:</label>
        <input type="text" value="N/A" class="form_field-input" />
      </div>
      <div class="notes_block flex-column">
        <label for="">Neuro:</label>
        <input type="text" value="N/A" class="form_field-input" />
      </div>
      <div class="notes_block flex-column">
        <label for="">Psych:</label>
        <input type="text" value="Follow-up " class="form_field-input" />
      </div>
    </div>
    <div>
      <div class="notes_block flex-column">
        <label for="">Assessment & Plan:</label>
        <input type="textarea" class="form_field-input form_field-textarea" />
      </div>
    </div>
    <div>
      <div class="section-heading">END-Office Visit</div>
      <div class="notes_block">
        <span class="bold_text"> Begin - </span>
        Patient Survey
      </div>
      <div class="notes_block">Note Title New Member Health Intake Survey</div>
      <div class="notes_block">
        <span class="bold_text"> Note Type: </span>
        Patient Survey
      </div>
      <div class="notes_block">
        <div class="bold_text">Date:</div>
        <input type="text" name="" id="" class="form_field-input" />
      </div>
      <div class="notes_block">
        <span class="bold_text"> Created By: </span>
        Dr. RAMA PRAYAGA, MD (NPI: 1033121058)
      </div>
      <div class="notes_block">
        <span class="bold_text"> Signed By: </span>
        Dr. RAMA PRAYAGA, MD (NPI: 1033121058)
      </div>
    </div>
    <ul class="list_points">
      <li class="notes_block">
        Do you have any of the following common health conditions? (select all that apply: Anxiety or panic disorder,
        Arthritis, Asthma, Cancer, COPD, Depression, Diabetes, Drug and/or alcohol dependence, heart disease, high blood
        pressure, kidney, liver) Other health conditions:
      </li>
      <li class="notes_block">
        Have you had any of the following major surgeries? (select all that apply: appendix removal, c-section, cardiac
        bypass, gallbladder removal, heart valve, joint replacement, spine surgery) Surgical history:
      </li>
      <li class="notes_block">
        In the past year, have you needed to stay in the hospital for an illness or unplanned surgery?
      </li>
      <li class="notes_block">Prescribed medications:</li>
      <li class="notes_block">Allergies:</li>
      <li class="notes_block">
        How many days per week do you exercise for 30 minutes or more (hard enough to make you sweat)?
      </li>
      <li class="notes_block">Have you ever smoked tobacco regularly?</li>
      <li class="notes_block">
        In the past 12 months, how often have you had 4 or more alcoholic drinks in a single day?
      </li>
      <li class="notes_block">
        In the past 12 months, how often have you used recreational drugs, or used prescription medication for
        non-medical reasons?
      </li>
      <li class="notes_block">Are you sexually active?</li>
      <li class="notes_block">
        First degree relative with any of the following? (select all that apply): Alcohol/drug addiction, Breast CA,
        Colon CA, MI/CVA, before age 50, Prostate CA, SCD, Melanoma
      </li>
      <li class="notes_block">
        Knowing your race and ethnicity helps us understand what health issues you might be more likely to have. How do
        you identify yourself?
      </li>
      <li class="notes_block">Is there anything else we should know?</li>
    </ul>
    <!-- <div class="letter_head-content font_12" style="margin-bottom: 110px; font-size: 12px">
      <div>
        <div>Sincerely,</div>
        <div>Rama Prayaga, M.D.</div>
        <div>05/23/2022</div>
      </div>
    </div> -->
    <div class="letter_header-footer" style="border-top: 2px solid black; max-width: 342px; position: relative">
      <div class="footer-heading font_bold" style="margin-top: 16px; font-weight: bold">Rama S. Prayaga, MD</div>
      <p class="font_12" style="max-width: 258px; margin-top: 10px; font-size: 12px">
        5194 Dawes Ave, Alexandria VA, 22311 1325 18 th St, NW, Suite 210 Washington, D.C. 20036 Ph:
        703-820-1900/202-386-6199, Fax: 866.528.6229
      </p>
    </div>
  </div>
</div>
