import { Component, OnInit } from '@angular/core';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { Router } from '@angular/router';

@Component({
  selector: 'app-services-content',
  templateUrl: './services-content.component.html',
  styleUrls: ['./services-content.component.scss']
})
export class ServicesContentComponent implements OnInit {

  headingData = {
    subHeading: 'We are proud to offer',
    mainHeading: 'Various services',
  };

  serviceCardData = [
    {
      heading: 'Wellness consultations',
      imgPath: 'jpgs/services-medical.jpg',
      cardLabel: 'mental health consultation washington dc',
      description: 'A procedure whereby, upon request by one healthcare provider, another healthcare provider reviews a patient\'s medical history, examines the patient and makes recommendations as to care and treatment. At New Tele Doc the medical consultation is a multidimensional process, focused on the doctor-patient relationship but is also a pivotal piece to provide support regarding the health-disease, suffering, and uncertainty that a patient has when their physical integrity is affected -human and emotional.',
      showBackgroundImg: false,
      classData: 'blue'
    },
    {
      heading: 'Psychiatric evaluations',
      imgPath: 'jpgs/services-psychiatric.jpg',
      cardLabel: 'psychological evaluations Washington DC',
      description: 'A systematic evaluation of the causes, the symptoms, the course and the consequences of a psychiatric disorder in order to formulate a diagnosis and a treatment plan, and to answer any questions the patient or referring specialist may have. If certain issues have been causing problems in your life and you aren\'t sure how to make the necessary changes, therapy can help. At New Tele Doc we help you to overcome an unhealthy cognitive, emotional, and behavioral pattern with the team work of licensed professional counselor (LPC) and psychiatrist.',
      showBackgroundImg: false,
      classData: 'pink reverse'
    },
    {
      heading: 'medication management',
      imgPath: 'jpgs/services-medication.jpg',
      cardLabel: 'services-medication medication management DC',
      description: 'Medication management is a service designed to help individuals manage their medications so they take them on time and avoid the dangers of incorrect medication administration. When someone neglects to take their medications properly because they forget or think they don\'t need it, the fact is, failing to do so can lead to serious consequences. Through medication management, individuals get the medications they need, on time, every single day.',
      showBackgroundImg: true,
      classData: 'green'
    },
    {
      heading: 'telemedicine appointment',
      imgPath: 'jpgs/services-telemedicine.jpg',
      cardLabel: 'services-telemedicine telehealth psychiatrist',
      description: 'A telemedicine appointment is just like a regular appointment but within the safety & comfort of your home. You will sit in front of a camera where you will be able to see yourself and our doctor on the screen once your session begins. These appointments are also called virtual visits. We ask you questions and work with you to develop a plan of care, providing the same quality of care you would get in a face-to-face visit. You can start your Virtual Visit using: A computer & webcam, or A smartphone or tablet (mobile device)',
      showBackgroundImg: false,
      classData: 'yellow reverse'
    },
    {
      heading: 'Disability Paperwork',
      imgPath: 'jpgs/services-paperwork.jpg',
      cardLabel: 'Schedule A Letter DC',
      description: 'We provide Disability paperwork, ESA paperwork, Schedule A letters, Accommodation letters...',
      showBackgroundImg: false,
      classData: 'blue'
    }
  ];

  constructor(private router: Router) { }

  ngOnInit() {
  }
  navigateBookingpage() {
    this.scrollTop();
    //the below line was commented on sept 5 2023 because it was showing toast meassge of error even after working corrctly
    // this.router.navigate([`${ROUTE_PATHS.APPOINTMENT}`]);
    this.router.navigate(['login'])
  }
  scrollTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
}
