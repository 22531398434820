import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';

@Component({
  selector: 'app-create-location-wrapper',
  templateUrl: './create-location-wrapper.component.html',
  styleUrls: ['./create-location-wrapper.component.scss']
})
export class CreateLocationWrapperComponent implements OnInit {
    // Bread crumb
    breadCrumbData = {
      link: '',
      linkText: 'Create Location'
    };

  constructor(private router: Router) { }
  isCreate = true;
  ngOnInit() {
    if (this.router.url.split('/').pop() !== ROUTE_PATHS.CREATE_LOCATION) {
      this.isCreate = false;
      this.breadCrumbData['linkText'] = 'Edit Location'; 
    }
  }

}
