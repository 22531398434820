import { ReturnStatement } from '@angular/compiler';
import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { convertDateToSpecifiedOffset, DEFAULT_PAGINATION_LIMIT, DEFAULT_TIME_ZONE_DIFF, TELEMEDICINE_MEDICATION, TOAST_STATUSES, HTTPMethods } from '@app/common/constants/util.constant';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { timestamp } from 'rxjs/operators';
import { getActionsList } from '../common/constants/appointment-actions';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';
import { IPostMethodPayload, IGetMethodPayload } from '@app/common/constants/typeInterfaces.constants';
import { API_URLS } from '@app/common/constants/api-urls.constant';
import { environment } from '@src/environments/environment';

// declare var stripe: any;

@Component({
  selector: 'app-patient-details',
  templateUrl: './patient-details.component.html',
  styleUrls: ['./patient-details.component.scss']
})
export class PatientDetailsComponent implements OnInit {
  paymentRedirectUrl=environment.paymentRedirectUrl;
  // Loader on page load or api call
  BlockedServices: any;
  isShowScreen = true;
  patientId: any; // Patient id
  patientsDetails: any; // Patiient details data
  patientListPerPage: any;  // Patien  Appointments history List data
  paginationData: any; // Pagination data
  outsideScroll = false; // Scroll status flag
  blockPatientPopupShowHide = false; // Block UI status flag
  blockPatientApplyStatus = false; // Block Status Flag
  archiveStatus: any; // ArchiveStatus
  patientNameId: any;
  rawFilterData: any;
  treatmentsCount: any; // getting the all treatments count
  pageNumber: any = 1;
  getFilterData: any;
  totalUpdatedData: any;
  currentFormIndex: number = 0;
  selectTreatmentData: any;
  sendFinalData: any;
  currentStep =     {
    label: 'Selected Treatment',
    id: 'selectedTreatment',
    img: 'HIPPA Form',
    visited: true,
    notVisisted: false,
    completed: false,
    onPage: true
  };
  stepDetails = [
    {
      label: 'Selected Treatment',
      id: 'selectedTreatment',
      img: 'HIPPA Form',
      visited: true,
      notVisisted: false,
      completed: false,
      onPage: true
    },
    {
      label: 'Selected Locations',
      id: 'selectedLocations',
      img: 'HIPPA Form',
      visited: false,
      notVisisted: true,
      completed: false,
      onPage: false
    }
  ];
  saveFormData: boolean = false;
  hideSubmit: boolean = false;
  detailPageUpload = [];
  pageLimit: any = DEFAULT_PAGINATION_LIMIT;
  showPatientDetailsAPI: boolean = false;
  updatedFilesCount = 0;
  uploadFilesData = {
    patientName: '',
    patientDOB: '',
    updatedFilesCount: this.updatedFilesCount,
  };
  cancelPopupShowHide: boolean = false;
  displayList: boolean = false;
  // Form inputs
  forminputs: any = [
    {
      type: 'header',
      label: 'text',
      label1: 'text',
      showontoggle: true,
      editable: true,
      key: 'patient_name_id',
      className: 'patient-id',
    },

    {
      type: 'groupCheckbox',
      categoryLabel: 'Treatment',
      controlType: 'groupCheckbox',
      key: 'treatment',
      required: true,
      value: [],
      isIntermediate: false,
      options: [
        {
          name: 'Medmanagement',
          value: 'Medmanagement',
          isSelected: false
        },
        {
          name: 'Tele Medicine',
          value: 'Tele Medicine',
          isSelected: false
        },
        {
          name: 'TMS',
          value: 'TMS',
          isSelected: false
        },
        {
          name: 'Therapy',
          value: 'Therapy',
          isSelected: false
        }
      ],
      validators: [],
      focus: true,
      valueable: true,
      isSelected: false,
      showontoggle: true,
    },
    {
      type: 'submit',
      placeholder: '',
      required: true,
      text: 'Save',
      key: '',
      value: '',
      controlType: 'submitButton',
      validators: [],
      callback: '',
      cencelBtn: '',
      showontoggle: true,
      disabled: true
    }
  ];
  // patient details actions
  patientDetailsActions: any = [
    {
      id: 0,
      name: 'Schedule',
      icon: 'schedule',
      className: 'reschedule',
      show: true,
      permissionName: 'APPOINTMENT_CREATE'
    },
    {
      id: 1,
      name: 'Edit',
      icon: 'edit',
      className: 'edit',
      show: true,
      permissionName: 'PATIENT_EDIT'
    },
    {
      id: 2,
      name: 'Block',
      icon: 'blocked',
      className: 'reset-password',
      show: true,
      permissionName: 'PATIENT_EDIT'
    },
    {
      id: 3,
      name: 'Archive',
      icon: 'archive',
      className: 'archive',
      show: true,
      permissionName: 'PATIENT_EDIT'
    },
    {
      id: 4,
      name: `Req Doc(s)`,
      icon: 'request-documents',
      className: 'documents',
      show: true,
      permissionName: 'PATIENT_EDIT'
    },
    {
      id: 5,
      name: `Req Lab Doc(s)`,
      icon: 'request-documents',
      className: 'documents',
      show: true,
      permissionName: 'PATIENT_EDIT'
    },
   

  ];
  // Empty list Messages
  patientListEmptyData = {
    className: 'appointmentsList',
    icon: 'appointment-empty-data',
    message: 'There are no appointments'
  };
  // Empty list Messages
  documentsRequestEmptyData = {
    className: 'appointmentsList',
    icon: 'request-documents',
    message: 'No Active Requests'
  };
  // pagination data
  breadCrumbData = {
    link: '/admin/patients',
    linkText: 'Patient Details'
  };
  // Intail Payload
  INITIAL_PAYLOAD = {
    pagination: {
      page: this.pageNumber,
      limit: this.pageLimit
    },
  };
  copayAmount: any;
  dueAmount: any;
  totalAmout: any;
  makeAsPaidPopupShowHide: boolean = false;
  isBeforeActions: boolean = false;
  generateInvoicePopupShowHide: boolean = false;
  displayDocumentsRequestPopup: boolean = false; // for documents request
  uploadUrlBody: any;
  popupMessage: string;
  action: string;
  cancelAppointmentData: any = {};
  cancelAppointmentPopupShowHide: boolean = false;
  isAdmin: boolean = true;
  amountGenerated: any;
  selectedApptId: any;
  invoiceStatus: string;
  appointmentListPayload: any = this.INITIAL_PAYLOAD;
  permissionsDone = false;
  adminPermissions = {
    PATIENT_EDIT: true,
    APPOINTMENT_CREATE: true,
    APPOINTMENT_LIST: true,
    INVOICE_EDIT: true,
    INVOICE_DETAILS: true
  };
  permissionsArr = [];
  filtersTabText = [{ id: 0, label: 'Appointment History', selected: true }, { id: 1, label: 'Documents History', selected: false }]; // Filers Tab text
  selectedTabIndex: any = 0; // Tab initail index value
  documentRequests: any = []; // document request raised by admin in a patient-level
  requestedDocuments: any = []; // documents uploaded by patient
  documentsRequest: any = [];
  displayEditUserPopup: boolean = false;
  displayLabDocumentsRequestPopup: boolean;
  labDocumentsRequest: any[];
  @ViewChild('op', { static: false }) editOverlay;
  patientListPageCount: any;
  documentRequestsCount: any;
  paymentToken: string;
  isMarkasPaid= false;

  constructor(private observable: ObservableHelperService, private http: HttpService, private router: Router, private formServices: CustomFormHandlerService) {
    this.patientId = router.url.split('/').pop();
  }

  @HostListener('window:scroll', ['$event'])
  scrollHandler(event) {
    if(this.editOverlay){
      this.editOverlay.hide();
    }
  }

  ngOnInit() {
    this.getAdminPermissions('first');
    // Seting outside scroll visible status
    this.checkingSidebarState(this.outsideScroll);
    // Get patient Details
    this.getPatientDetails();
  }

  // This is closing for block patient the popup
  blockPatientPopupShowHideStatusFun(status) {
    if(this.currentFormIndex != 0){
      this.selectTreatmentData = undefined;
      this.stepDetails[this.currentFormIndex].notVisisted = true;
      this.stepDetails[this.currentFormIndex].onPage = false;
      this.stepDetails[this.currentFormIndex].visited = false;
      this.currentFormIndex--;
      this.scrollTop();
      this.setCurrentForm(this.currentFormIndex);
    }
    this.blockPatientPopupShowHide = status.cancelpopup;
    this.checkingSidebarSetStatus(false);
  }
  // Checking the Slide bar is open or if open hide outside scroll
  checkingSidebarState(slideBarFlag) {
    if (slideBarFlag) {
      document.querySelector('html').style.overflow = 'hidden';
      document.querySelector('body').style.overflow = 'hidden';
    } else {
      document.querySelector('html').style.overflow = 'auto';
      document.querySelector('body').style.overflow = 'auto';
    }
  }
  // Checcking side bar staus
  checkingSidebarSetStatus(data) {
    this.outsideScroll = data;
    this.checkingSidebarState(data);
  }
  saveForm() {
    if(this.currentFormIndex == 1){
      if(this.totalUpdatedData != undefined){
          let sendData = [];
        this.totalUpdatedData.forEach(each => {
          let LocaitonIds =[];
          each.locationName.forEach(e =>{
            if(e.isSelected === true){
              LocaitonIds.push(e.loctionId)
              sendData.push({
                appointmentTypeId: each.appointmentTypeId,
                appointmentTypeName: each.appointmentTypeName,
                locationId: e.loctionId
              });
            }
          })
        });
        this.sendFinalData = {'treatment':sendData}
      }
      else{
        let totalUpdatedData1 = this.selectTreatmentData;
        let sendData = [];
        totalUpdatedData1.treatment.forEach(each => {
          each.locationId.forEach(e =>{
              sendData.push({
                appointmentTypeId: each.appointmentTypeId,
                appointmentTypeName: each.appointmentTypeName,
                locationId: e
              });
          })
        });
        this.sendFinalData = {'treatment':sendData};
      }
    }
    let rewSelectedData = []
    if(this.selectTreatmentData == undefined){
      this.rawFilterData.treatment.forEach(each => {
        if(each.isSelected){
          rewSelectedData.push(each)
        }
      });
      this.selectTreatmentData = {'treatment':rewSelectedData} ;
    }
 

    this.saveFormData = true;
    if (this.currentFormIndex === (this.stepDetails.length - 1)) {
      this.currentStep = this.stepDetails[this.currentFormIndex];
      this.scrollTop();
      // window.setTimeout(() => {
      //   this.navigateToDetails();
      // }, 10);
    } 
    else {
      window.setTimeout(() => {
        if (this.stepDetails[this.currentFormIndex]) {
          this.currentFormIndex++;
          this.currentStep = this.stepDetails[this.currentFormIndex];
          this.stepDetails[this.currentFormIndex].onPage = true;
          this.stepDetails[this.currentFormIndex].notVisisted = false;
          this.stepDetails[this.currentFormIndex].visited = true;
          this.scrollTop();
        }
      }, 5);
    }
    if(this.currentFormIndex==1){
      this.blockPatientSubmit(this.sendFinalData);
      }
  }

  // Get getPatientDetails API call
  getPatientDetails() {
    this.isShowScreen = this.blockPatientApplyStatus ? true : false;
    const payloadData = {
      type: 'GET',
      url: 'patientsDetails',
      isDeveloper: true,
      pathVariables: [this.patientId],
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.getTreatmentsDataLength(res.data);
      } else {
        let message = 'Failed to fetch patient details. Please try again';
        message = (res.error) ? res.error.message : message;
        if (message === 'Insufficient Privilege') {
          this.observable.showToast(TOAST_STATUSES.ERROR, 'Sorry! You do not have access to this page.');
          this.router.navigate(['/admin']);
        } else {
          this.observable.showToast(TOAST_STATUSES.ERROR, message);
          setTimeout(() => {
            this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.PATIENTS}`]);
          }, 1000);
        }
      }
    });
  }
  getStepIndex(event) {
    this.stepIndicatorClicked(event);
  }
  getActionText() {
    return this.currentFormIndex < (this.stepDetails.length - 1) ? 'Next' : 'Submit';
  }
  stepIndicatorClicked(index: number): void {
    if (index < 1) { this.hideSubmit = false; }
    if (index > this.currentFormIndex) {
      this.saveFormData = true;
      this.saveForm();
    } else {
      this.scrollTop();
      this.stepDetails[this.currentFormIndex].onPage = false;
      this.currentFormIndex = index;
      this.currentStep = this.stepDetails[index];
      this.stepDetails.forEach((each, ind) => {
        if (ind >= this.currentFormIndex) {
          each.completed = false;
        }
      });
      this.stepDetails[index].onPage = true;
    }
  }
  setCurrentForm(index) {
    this.currentStep = this.stepDetails[index];
    this.stepDetails[index].completed = false;
    this.stepDetails[index].onPage = true;
  }
  forwardClicked() {
    this.saveForm();
  }
  backClicked() {
    this.back();
  }
  scrollTop() {
    window.scroll({
      top: 0,
      left: 0,
    });
  }
  back() {
    if (this.currentFormIndex === 0) {
      this.scrollTop();
      this.stepDetails[this.currentFormIndex].onPage = false;
      this.currentFormIndex = 0;
      this.setCurrentForm(this.currentFormIndex);
    } else {
      this.stepDetails[this.currentFormIndex].onPage = false;
      this.currentFormIndex--;
      this.scrollTop();
      this.setCurrentForm(this.currentFormIndex);
    }
    this.hideSubmit = false;
    this.selectTreatmentData = undefined;
  }
  cancel(status){
    if(this.currentFormIndex != 0){
      this.selectTreatmentData = undefined;
      this.stepDetails[this.currentFormIndex].notVisisted = true;
      this.stepDetails[this.currentFormIndex].onPage = false;
      this.stepDetails[this.currentFormIndex].visited = false;
      this.currentFormIndex--;
      this.scrollTop();
      this.setCurrentForm(this.currentFormIndex);
    }
    this.blockPatientPopupShowHide = false;
    this.checkingSidebarSetStatus(false);
  }
  // Get All Treatments Data length
  getTreatmentsDataLength(patientData) {
    this.isShowScreen = this.blockPatientApplyStatus ? true : false;
    const payloadData = {
      type: 'GET',
      url: 'invoiceFilter',
      isDeveloper: true,
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.treatmentsCount = Object.keys(res.data.treatment).length;
        this.getFilterData = res.data;
        // this.getFilterData.treatment.unshift(TELEMEDICINE_MEDICATION);
        this.patientsDetails = patientData;
        this.patientsDetails.details.blockStatus = this.patientsDetails.details.blockStatus.map(each =>{
          return{
            ...each,
            name : each.appointmentTypeName,
          };
        });
        this.rawFilterData = this.massageFilterData(this.getFilterData, this.patientsDetails.details.blockStatus);
        this.patientsDetails = this.massagePatientDetails(patientData);
        this.BlockedServices = this.patientsDetails.details.blockStatus
        this.BlockedServices = this.BlockedServices.filter((obj, pos, arr) => {
          return arr.map(mapObj => mapObj.appointmentTypeId).indexOf(obj.appointmentTypeId) === pos;
        })
        this.uploadFilesData.patientName = this.patientsDetails.patientName;
        this.uploadFilesData.patientDOB = this.patientsDetails.details.patientBasicInfo.dob;
        this.detailPageUpload = this.patientsDetails.exatraUploads;
        this.uploadFilesData.updatedFilesCount = this.patientsDetails.exatraUploads.length;
        this.checkingSidebarSetStatus(false);
      } else {
        let message = 'Failed to get treatments details. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
      this.getInvoiceList(this.appointmentListPayload);
      this.isShowScreen = true;
      this.getAdminPermissions('second');
    });
  }

  massagePatientDetails(data) {
    if (!data && typeof (data) !== 'object') { return {}; }
    return data = {
      ...data,
      patientName: (!data.details.patientBasicInfo.firstName && !data.details.patientBasicInfo.lastName) ? '-' :
        (data.details.patientBasicInfo.firstName) + ' ' + (data.details.patientBasicInfo.lastName),
      patientHeight: data.details.patientBasicInfo.height ? data.details.patientBasicInfo.height.split("'")[0] + "' " + data.details.patientBasicInfo.height.split("'")[1] + '"' : '---',
      patientWeight: data.details.patientBasicInfo.weight ?  data.details.patientBasicInfo.weight : '---',
      uploads: this.getAllUploadsList(data),
      requestedUploads: this.getRequestedDocuments(data.details.requestedDocs),
      exatraUploads: this.messageUploads(data.details.extraUploads),
      pastDue: data.details.pastdue ? `$ + ${data.details.pastdue}` : '---',
      actions: this.getActions(data.details),
      patientPointofContactName: (!data.details.pointofContact) ? '---' : (data.details.pointofContact.firstName) + ' ' + (data.details.pointofContact.lastName),
      patientPointofContactEmail: (!data.details.pointofContact) ? '---' : (data.details.pointofContact.email),
      patientOffLineStatus: (data.details.isOffline === 1) ? true : false,
      pendingStatus: (data.details.status === 'pending') ? true : false,
      patientPointofContact: data.details.pointofContact? {
        firstName: (data.details.pointofContact && data.details.pointofContact.firstName) ? data.details.pointofContact.firstName : "",
        lastName: (data.details.pointofContact && data.details.pointofContact.lastName) ? data.details.pointofContact.lastName : "",
        mail: (data.details.pointofContact && data.details.pointofContact.email) ? data.details.pointofContact.email : "",
        id: (data.details.pointofContact && data.details.pointofContact.userId) ? data.details.pointofContact.userId : ""
     } : null
    };
  }
  messageUploads(data) {
    // Get all uploads list form API
    let uploadsList = [];
    let fileName;
    data.forEach(eachupload => {
      if (eachupload !== '') {
        let docObj = { ...eachupload };
        fileName = eachupload.docUrl.includes('__name__') ? eachupload.docUrl.substring(
          eachupload.docUrl.lastIndexOf('__name__') + 8,
          eachupload.docUrl.lastIndexOf('?')) : eachupload.docUrl.substring(eachupload.docUrl.lastIndexOf('/') + 1, eachupload.docUrl.lastIndexOf('?'));    
        docObj['file'] = this.splitFileName(fileName, eachupload.docUrl);
        docObj['isKetaminePdf'] = fileName.includes('KETAMINE_MONITORING_FORM') || fileName.includes('KETAMINE_ENROLLMENT_FORM') 
        uploadsList.push(docObj);
      }
    });
    return uploadsList;
  }

  // Massage the extraupload documents
  massageExtradocumentsURLs(uploadObj) {
    let uploads = [];
    uploadObj.forEach(eachDocument => {
      uploads.push(eachDocument.docUrl);
    });
    return uploads;
  }

  // get All actions list
  getActions(dataActions) {
    if (dataActions.blockStatus.length === this.treatmentsCount + 1) {
      this.patientDetailsActions[2].name = 'UnBlock';
    } else {
      this.patientDetailsActions[2].name = 'Block';
    }
    let archiveStatustext = dataActions.status.toLowerCase();
    if (archiveStatustext === 'archive' && this.patientsDetails.details.isOffline !== 1) {
      this.patientDetailsActions[0].show = false;
      this.patientDetailsActions[1].show = false;
      this.patientDetailsActions[2].show = false;
      this.patientDetailsActions[3].name = 'Activate';
      this.patientDetailsActions[3].icon = 'active';
      this.patientDetailsActions[3].className = 'active';
      this.patientDetailsActions[4].show = false;
      this.patientDetailsActions[5].show = false;
    } else if (archiveStatustext === 'active' && this.patientsDetails.details.isOffline !== 1) {
      this.patientDetailsActions[0].show = true;
      this.patientDetailsActions[1].show = true;
      this.patientDetailsActions[2].show = true;
      this.patientDetailsActions[3].name = 'Archive';
      this.patientDetailsActions[3].icon = 'archive';
      this.patientDetailsActions[4].show = true;
      this.patientDetailsActions[5].show = true;
    } else if (archiveStatustext === 'pending' && this.patientsDetails.details.isOffline !== 1) {
      this.patientDetailsActions[0].show = false;
      this.patientDetailsActions[2].show = false;
      this.patientDetailsActions[3].show = false;
      this.patientDetailsActions[4].show = false;
      this.patientDetailsActions[5].show = false;
    } else if (archiveStatustext === 'pending' && this.patientsDetails.details.isOffline === 1) {
      this.patientDetailsActions[0].show = false;
      this.patientDetailsActions[2].show = false;
      this.patientDetailsActions[3].show = false;
      this.patientDetailsActions[4].show = false;
      this.patientDetailsActions[5].show = false;
    } else if (archiveStatustext === 'active' && this.patientsDetails.details.isOffline === 1) {
      this.patientDetailsActions[0].show = true;
      this.patientDetailsActions[1].show = true;
      this.patientDetailsActions[2].show = true;
      this.patientDetailsActions[3].show = false;
      this.patientDetailsActions[4].show = false;
      this.patientDetailsActions[5].show = false;
    }
    return this.patientDetailsActions;
  }
  // This is for getting the document history list
  getDocumentList(bodyData) {
    const payload = {
      type: HTTPMethods.POST,
      url: 'documentList',
      isDeveloper: true,
      pathVariables: [this.patientId],
      body: bodyData
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {

        this.documentRequestsCount = res.data.pagination.totalItems;
        this.documentRequests = this.documentMassasgeData(res.data.list);

        this.paginationData = this.massagePaginationData(res.data);
      } else {
        this.observable.showToast(TOAST_STATUSES.ERROR, 'Error in getting appointment history list');
      }
    });
  }

  // This function is for massaging the documents history list.
  documentMassasgeData(data) {
    if (!data && !Array.isArray(data)) return [];
    return data.map(each => {
      return {
        ...each,
        creator: (!each.createdBy && each.createdBy !== 'ADMIN') ? each.createdByemp.firstName + ' ' + each.createdByemp.lastName : each.createdBy,
        statusLabel: this.getStatusLabel(each.status),
        statusIcon: this.getStatusIcon(each.status),
        totalDocuments: this.getTotalDocuments(each.documents)
      };
    });
  }

  // This function is for string concating all the documents name.
  getTotalDocuments(documents) {
    var string = '';
    if (documents.length) {
      documents.forEach((doc, ind) => {
        string = string + `${doc.documentName}${(ind !== documents.length - 1) ? ',' : ''} `;
      });
      return string;
    }
  }

  // This function is for changing the status as Labels
  getStatusLabel(status) {
    if (status && status === 'P') {
      return 'Pending';
    } else if (status && (status === 'C')) {
      return 'Completed';
    } else if (status && status === 'D') {
      return 'Cancelled';
    }else if (status && (status === 'PFR')) {
      return 'Pending For Review';
    } else if (status && status === 'RP') {
      return 'Review Pending';
    }else if (status && (status === 'PP')) {
      return 'Payment Pending';
    } else if (status && status === 'UP') {
      return 'Under Process';
    }else if (status && (status === 'DUP')) {
      return 'Document Under Process';
    } else if (status && status === 'PPR') {
      return 'Pending Patient Review';
    }
    else if (status && (status === 'IP')) {
      return 'Invoice Pending';
    }
    else if (status && (status === 'ENA')) {
      return 'Emplouyee Not Assigned';
    }
    else if (status && (status === 'RJ')) {
      return 'Rejected';
    }
    else if (status && (status === 'PC')) {
      return 'Payment Completed';
    }
    else if (status && (status === 'DURPF')) {
      return 'Document Under Review Pending For Approval';
    }
    else if (status && (status === 'PA')) {
      return 'Patient Approved Waiting For Dr Signature';
    }
    else if (status && (status === 'PCP')) {
      return 'Patient Comments Pending';
    }
  }

  // This function is for getting status based icons
  getStatusIcon(status) {
    if (status && status === 'P') {
      return 'inprogress';
    } else if (status && (status === 'C')) {
      return 'completed';
    } else if (status && status === 'D') {
      return 'canceled';
    }
  }
  // Patient history
  getInvoiceList(bodyData) {
    const payload = {
      type: 'POST',
      url: 'historyAppointment',
      isDeveloper: true,
      pathVariables: [this.patientId],
      body: bodyData,
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        console.log("historyAppointment", res);
        this.patientListPageCount = res.data.pagination.totalItems;
        this.patientListPerPage = this.massageAppointmentsData(res.data.list);

        this.paginationData = this.massagePaginationData(res.data);
      } else {
        this.observable.showToast(TOAST_STATUSES.ERROR, 'Error in getting appointment history list');
      }
    });
  }

  // This is a massaging function for Employee Data
  massageAppointmentsData(data) {
    if (!data && !Array.isArray(data)) { return []; }
    return data.map(each => {
      return {
        ...each,
        billAmount: (!each.invoice.copay && !each.invoice.pay) ? '-' : '$  ' + (each.invoice.copay + each.invoice.pay).toFixed(2),
        treatmentIcon: this.treatmentTypeSvgName(each.info.appointmentType.appointmentTypeName),
        // getActionsList(appointmentTypeName, appointmentStatus, invoiceStatus, page)
        actions: this.massageActions(each)
      };
    });
  }
  massageActions(each) {
    let actions = [];
    actions = getActionsList(each.info.appointmentType.appointmentTypeName, each.info.appointmentStatus, each.invoice.status, 'appointment_details');
    actions = actions.filter(eachAction => !(eachAction.id === 'block_patient' || eachAction.id === 'patient_details'));
    actions.push({
      id: 'reports',
      name: 'Reports',
      icon: 'reports',
      className: 'reports',
      show: true,
      permissionName: 'APPOINTMENT_DETAILS'
    },
      {
        id: 'documents',
        name: 'Documents',
        icon: 'documents',
        className: 'documents',
        show: true,
        permissionName: 'APPOINTMENT_DETAILS'
      });
    actions = actions.filter(icon => this.permissionsArr.includes(icon.permissionName));
    return actions.map(eachAction => {
      return {
        ...eachAction,
        name: (eachAction.label) ? eachAction.label : eachAction.name,
        icon: (eachAction.iconName) ? eachAction.iconName : eachAction.icon,
        className: (eachAction.iconName) ? eachAction.iconName : eachAction.icon,
      };
    });
  }
  // This will do massaging for pagination Data
  massagePaginationData(data) {
    if (!data && typeof (data) !== 'object') {
      // Error
    }
    return {
      limit: data.pagination.limit,
      currentPage: data.pagination.page,
      totalPages: Math.ceil(data.pagination.totalItems / data.pagination.limit),
      data: data.list

    };
  }
  // Pagination Change Event
  pageChangedEvent(data) {
    this.pageNumber = data.currentPage;
    this.pageLimit = data.limit;
    const payload = {
      pagination: {
        page: data.currentPage,
        limit: data.limit,
      }
    };
    this.appointmentListPayload = payload;
    if (this.selectedTabIndex === 0) {
      this.getInvoiceList(this.appointmentListPayload);
    } else if (this.selectedTabIndex === 1) {
      this.getDocumentList(this.appointmentListPayload);
    }
  }
  // Switch case for the appointment type icons
  treatmentTypeSvgName(appointmentType) {
    let appointmentTypeIconName = '';
    switch (appointmentType) {
      case 'Medication Management':
        appointmentTypeIconName = 'medication-management';
        break;
      case 'Ketamine Treatment':
        appointmentTypeIconName = 'ketamine-treatment';
        break;
      case 'TMS':
        appointmentTypeIconName = 'adminTms';
        break;
      case 'Psychological Therapy':
        appointmentTypeIconName = 'psychological-therapy';
        break;
    }
    return appointmentTypeIconName;
  }
  getRequestedDocuments(list) {
    let uploadsList = [];
    let fileName;
    list.forEach(eachupload => {
      if (eachupload !== '') {
        fileName = eachupload.includes('__name__') ? eachupload.substring(
          eachupload.lastIndexOf('__name__') + 8,
          eachupload.lastIndexOf('?')) : eachupload.substring(eachupload.lastIndexOf('/') + 1, eachupload.lastIndexOf('?'));
        uploadsList.push(this.splitFileName(fileName, eachupload));
      }
    });
    return uploadsList;
  }
  // Get all uploads list form API
  getAllUploadsList(data) {
    let uploadsList = [];
    let fileName;
    let uploadsKeysList = [data.details.insuranceDetails.policyHolder.secondaryPolicyHoldersUploadBack, data.details.insuranceDetails.policyHolder.secondaryPolicyHoldersUploadFront, ...data.details.medicalHistory.LabWorkDocsUpload, data.details.patientBasicInfo.idProofUploadImage1, data.details.patientBasicInfo.idProofUploadImage2, data.details.insuranceDetails.policyHolder.policyHoldersUploadBack,
    data.details.insuranceDetails.policyHolder.policyHoldersUploadFront, ...data.details.medicalHistory.therapistDocs, ...data.details.medicalHistory.prescriberDocs];
    uploadsKeysList.forEach(eachupload => {
      if (eachupload !== '') {
        fileName = eachupload.includes('__name__') ? eachupload.substring(
          eachupload.lastIndexOf('__name__') + 8,
          eachupload.lastIndexOf('?')) :  eachupload.substring(eachupload.lastIndexOf('/') + 1, eachupload.lastIndexOf('?'));
        uploadsList.push(this.splitFileName(fileName, eachupload));
      }
    });
    return uploadsList;
  }

  // Split function for the file name
  splitFileName(file, docURL) {
    let fileObj = {};
    let splitFileName = file.split('.');
    fileObj['name'] = splitFileName[0];
    fileObj['extenstion'] = splitFileName[splitFileName.length - 1];
    /* var uri = docURL;
    var totalLinkSplit  = uri.split('?');
    var urlSplit = totalLinkSplit[0].split('users/');
    var nameSplit = urlSplit[1].split('/');
    let userNameSplit = nameSplit[0].split('__');
    let patientNameSplit = nameSplit[1].split('__');
    let userNameDecoding =  decodeURI(decodeURI(userNameSplit[0]));
    let patientNameDecoding =  decodeURI(decodeURI(patientNameSplit[0]));
    let updatedLink = urlSplit[0] + 'users/' + userNameDecoding + '__' + userNameSplit[1] + '/' + patientNameDecoding + '__' + patientNameSplit[1] + '/' + nameSplit[2] + '?' + totalLinkSplit[1]; */

    fileObj['file'] = docURL;
    return fileObj;
  }
  // Delete patient data show popup
  deletePatientData() {
    this.cancelPopupShowHide = true;
    this.popupMessage = 'delete';
    this.action = 'delete';
    // this.patienId = patientData.patientId;
  }

  cancelPopupShowHideStatusFun(status, isShow?) {
    this.cancelPopupShowHide = status.cancelpopup;
    this.checkingSidebarSetStatus(false);
    if (this.action && this.action === 'delete' && isShow) {
      let patientData = {
        patientId: this.patientsDetails.details.patientId
      };
      this.deletePatient(patientData);
    } else if (this.action && this.action === 'activate' && isShow) {
      this.archiveAPICall();
    } else if (this.action && this.action === 'archive' && isShow) {
      this.archiveAPICall();
    }
  }

  // Deleting patient
  deletePatient(patientData) {
    if (patientData) {
      const payloadData = {
        type: 'DELETE',
        url: 'deletePatient',
        isDeveloper: true,
        body: patientData
      };
      this.http.makeHttpRequest(payloadData).subscribe((res) => {
        if (this.http.isSuccessfulResponse(res)) {

          let message = 'Successfully deleted the patient data.';
          this.observable.showToast(TOAST_STATUSES.SUCCESS, message);
          this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.PATIENTS}`]);
        } else {
          // Error
          let message = 'Failed to delete patient data. Please try again';
          message = (res.error) ? res.error.message : message;
          this.observable.showToast(TOAST_STATUSES.ERROR, message);
        }
      });
    }
  }

  // Patients Details actions  @anji
  patientsDetailsActions(actionId, name, patientId, patientName, blockedServicesList,op,event) {
    this.patientId = patientId;
    name = name.toLowerCase().replace(/ /g, '');
    if (actionId === 0) {
      this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.NEWAPPOINTMENT}`], { queryParams: { patientId: this.patientsDetails.details.patientId, patientEdit: true } });
    } else if (actionId === 1) {
      this.moreDisplay(event, op)
    } else if (actionId === 2 && (name === 'block' || name === 'unblock')) {
      this.checkingSidebarSetStatus(true);
      this.forminputs.forEach(each => {
        if (each.key === 'patient_name_id') {
          each.label = patientName;
          each.label1 = patientId;
        }
      });
      this.getTreatmentsData(blockedServicesList);
    } else if (actionId === 3 && (name === 'archive' || name === 'activate' || name === 'pending')) {
      this.checkingSidebarSetStatus(true);
      if (name !== 'pending') {
        this.archiveStatus = (name === 'activate') ? 'active' : name;
        this.cancelPopupShowHide = true;
        this.popupMessage = name;
        this.action = name;
      } else {
      }
    } else if (actionId === 4) {
      this.displayDocumentsRequestPopup = true;
      this.checkingSidebarState(true);
      this.getDocumentRequest();
    } else if (actionId === 5) {
      this.getLabDocumentRequest();
      this.displayLabDocumentsRequestPopup = true;
      this.checkingSidebarState(true);
    }
  }

  // This is for getting request documents
  getDocumentRequest() {
    this.documentsRequest = [];
    const payloadData = {
      type: 'GET',
      url: 'adminRequestDocuments',
      isDeveloper: true,
      pathVariables: ['DOCUMENT_REQUEST']
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (res) {
        this.documentsRequest = res.data;
      }
    });
  }
  // Admin get filterData API call
  getTreatmentsData(blockstatus) {
    this.rawFilterData = this.massageFilterData(this.getFilterData, blockstatus);
    this.blockPatientPopupShowHide = true;
    this.checkingSidebarSetStatus(true);
  }
  // Massage the filter data
  massageFilterData(data, blockstatus) {
    if (data && typeof (data) === 'object') {
      data.treatment = data.treatment.map(doc => {
        doc['isSelected'] = false;
        doc['locationId'] = (doc.locationId) ? doc.locationId : null;
        doc['name'] = doc.appointmentTypeName;
        let locationIds = [];
        blockstatus.forEach(each => {
          let locationsid;
          if (each.appointmentTypeId === doc.appointmentTypeId) {
            doc['isSelected'] = true;
            locationsid= (each.locationId) ? each.locationId : null;
            locationIds.push(locationsid);
          }
        });
        doc['locationId'] =locationIds;
        return {
          ...doc,
          value: doc.appointmentTypeId,
        };
      });
      return data;
    } else {
      // Error
    }
  }

  // Invoices action interactions
  invoiceListActions(data) {
    this.selectedApptId = data.patientData.appointmentId;
    if (data.actionID === 'send_reminder') {
      this.sendReminder(data);
    } else if (data.actionID === 'resend_invoice') {
      this.resendInvoice(data.invoice.id);
    } else if (data.actionID === 'reschedule_appointment') {
      this.router.navigate([`${ROUTE_PATHS.ADMIN}`], { queryParams: { appointmentId: data.patientData.appointmentId, patientId: data.patientData.patients.patientId, appointmentDate: data.patientData.appointmentDate, appointmentTime: data.patientData.appointmentTime, appointmentTypeId: data.patientData.appointmentType.appointmentTypeId, locationId: data.patientData.location.locationId, isReshedule: true, offline: data.patientData.patients.isOffline } });
    } else if (data.actionID === 'cancel_appointment') {
      this.cancelAppointmentPopupShowHide = true;
      this.cancelAppointmentData = {
        patient: data.patientData.patients,
        type: 'adminappointments',
        locationType: data.patientData.location.locationType,
        appointmentId: data.patientData.appointmentId,
        userId: data.patientData.patients.userId
      };
    } else if (data.actionID === 'mark_absent') {
      this.absentAppointmnet(data);
    } else if (data.actionID === 'invoice_details') {
      this.router.navigate([`admin/invoices/${data.invoice.invoiceType}/${data.invoice.id}`]);
    } else if (data.actionID === 'generate_invoice') {
      this.invoiceStatus = 'ungenerated';
      this.generateInvoicePopupShowHide = true;
      this.checkingSidebarSetStatus(true);
    } else if (data.actionID === 'reports') {
      this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.APPOINTMENTS}/${data.patientData.appointmentId}`], { queryParams: { scroll: true, id: 'reports' } });
    } else if (data.actionID === 'documents') {
      this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.APPOINTMENTS}/${data.patientData.appointmentId}`], { queryParams: { scroll: true, id: 'documentsList' } });
    }
  }

  resendInvoice(invoiceId) {
    let message = 'Successfully invoice has been re-sent to the customer.!';
    let payload = {
      type: 'GET',
      url: 'invoiceResend',
      isDeveloper: true,
      pathVariables: [invoiceId],
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {

        this.observable.showToast(TOAST_STATUSES.SUCCESS, message);
      } else {
        message = 'Failed to resend invoice the appointment. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
  cancelAppointmentPopupShowHideStatusFun(status) {
    this.cancelAppointmentPopupShowHide = status.cancelpopup;
    if (status.removeUser) {
      this.getInvoiceList(this.appointmentListPayload);
    }
    this.checkingSidebarState(false);
  }
  absentAppointmnet(appoinmnetData) {
    let currentDate = new Date();
    let diffTime: any = (currentDate.getUTCHours() - currentDate.getHours()) + ':' + (currentDate.getUTCMinutes() - currentDate.getMinutes());
    let absentBody = {
      userId: appoinmnetData.patientData.patients.userId,
      locationType: appoinmnetData.patientData.location.locationType,
      type: 'ABSENT',
      timeDiff: diffTime
    };
    let payload = {
      type: 'PUT',
      url: 'appointmentDetails',
      isDeveloper: true,
      pathVariables: [appoinmnetData.patientData.appointmentId],
      body: absentBody
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        let appointmentListBody = {
          pagination: {
            page: this.pageNumber,
            limit: this.pageLimit,
          }
        };
        this.getInvoiceList(appointmentListBody);
        this.observable.showToast(TOAST_STATUSES.SUCCESS, 'This appointment has been marked as absent');
      } else {
        let message = 'Failed to absent the appointment. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  sendReminder(appoinmnetData) {
    let appointmentId = appoinmnetData.patientData.appointmentId;
    let payload = {
      type: 'GET',
      url: 'appointmentDetails',
      isDeveloper: true,
      pathVariables: [appointmentId + '/reminder'],
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.observable.showToast(TOAST_STATUSES.SUCCESS, 'Appointment schedule reminder email has been sent to your registered email id. Please check');
      } else {
        let message = 'Failed to reminder the appointment. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
blockPatientSubmited(data){
  this.selectTreatmentData = data;
}


  // Block patient Save action
  blockPatientSubmit(data) {
    // Block API call
    if(this.currentFormIndex != 0){
      this.blockTheUserAPI(data);
      this.selectTreatmentData = undefined;
      this.totalUpdatedData = undefined;
      this.stepDetails[this.currentFormIndex].onPage = true;
      // this.currentFormIndex ;
      this.currentFormIndex--;
      this.setCurrentForm(this.currentFormIndex);
      this.blockPatientPopupShowHide = false;
    }
  }
  changedLocation(data){
    this.totalUpdatedData = data;
  }
  // Block API PUT Call
  blockTheUserAPI(bodyData) {
    const payloadData = {
      type: 'PUT',
      url: 'patientActions',
      isDeveloper: true,
      body: bodyData,
      pathVariables: [this.patientId],
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        if (Number(res.data.patientId) === this.patientId) {
          this.blockPatientApplyStatus = true;
          this.blockPatientPopupShowHide = false;
          this.getPatientDetails();
          this.observable.showToast(TOAST_STATUSES.SUCCESS, 'Updated the patient  ' + `${this.patientsDetails.patientName}` + ' blocked status');
        }

      } else {
        // Error
        let message = 'Failed to uplodate patient block status. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
  // Archive API call
  archiveAPICall() {
    const payloadData = {
      type: 'PUT',
      url: 'patientArchive',
      isDeveloper: true,
      pathVariables: [this.patientId, this.archiveStatus],
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        if (Number(res.data.patientId) === this.patientId) {
          this.blockPatientApplyStatus = true;
          this.getPatientDetails();
          this.observable.showToast(TOAST_STATUSES.SUCCESS, 'Updated the patient  ' + `${this.patientsDetails.patientName}` + ' ' + `${this.archiveStatus}` + ' status');

        }
      } else {
        let message = 'Failed to update patient archive status. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  // Download file
  donwloadFile(url, fileName, flag) {
    let fullFileName = '';
    if (flag === true) {
      fullFileName = fileName.name + '.' + fileName.extenstion;
    } else {
      fullFileName = fileName.file.name + '.' + fileName.file.extenstion;
    }
    fetch(url).then((t) => {
      return t.blob().then((b) => {
        var a = document.createElement('a');
        a.href = URL.createObjectURL(b);
        a.setAttribute('download', fullFileName);
        a.click();
      }
      );
    });
  }

  // generate invoice fun
  // This is closing for generate invoice the popup
  generateInvoicePopupShowHideStatusFun(status) {
    this.generateInvoicePopupShowHide = status.cancelpopup;
    this.checkingSidebarSetStatus(false);
  }
  // generateInvoice($event)
  generateInvoice(data) {
    if (data) {
      // tslint:disable-next-line:one-variable-per-declaration
      let method, callUrl, bodyData, pathVariable, message; let callAPI = false;
      data.values ? this.amountGenerated = {
        copay: data.values.copayAmt,
        due: data.values.dueAmt,
        pay: (data.values.copayAmt + data.values.dueAmt),
        comments: data.values.comments
        // tslint:disable-next-line:no-unused-expression
      } : null;
      if (data.btnType === 'sendCustomer' || (data.btnType === 'submit' && this.invoiceStatus && this.invoiceStatus === 'ungenerated')) {
        method = 'POST';
        callUrl = 'generateInvoice';
        data.btnType === 'sendCustomer' ?
          bodyData = {
            type: 'NEW',
            appointmentId: this.selectedApptId,
            status: 'unpaid',
            copay: this.amountGenerated.copay,
            // pay: this.amountGenerated.pay
            pay: this.amountGenerated.due,
            invoiceType: 'appt_invoice',
            comment: this.amountGenerated.comments
          } : bodyData = {
            type: 'NEW_PAID',
            appointmentId: this.selectedApptId,
            status: 'paid',
            copay: this.amountGenerated.copay,
            // pay: this.amountGenerated.pay,
            pay: this.amountGenerated.due,
            paymentMode: data.form.value.paymentMode,
            referenceId: data.form.value.referenceId,
            invoiceType: 'appt_invoice',
            comment: this.amountGenerated.comments
          };
        message = data.btnType === 'sendCustomer' ? 'Successfully invoice has been sent to the customer.!' : 'Invoice has been marked as Paid Successfully.!';
        // tslint:disable-next-line:no-unused-expression
        data.btnType === 'submit' ? this.makeAsPaidPopupShowHide = false : null;
        callAPI = true;
      } else if (data.btnType === 'makePayment') {
        
        // this.observable.showToast(TOAST_STATUSES.SUCCESS, 'This feature is coming out soon..!!');
        let payload = {
          type: 'POST',
          url: 'checkoutSession',
          isDeveloper: true,
          body: {
            type: 'NEW',
            appointmentId: this.selectedApptId,
            copay: this.amountGenerated.copay,
            pay: this.amountGenerated.due,
            invoiceType: 'appt_invoice'
          }
        };
        this.http.makeHttpRequest(payload).subscribe((res) => {
          if (res.error) {
            alert(res.error.message);
            return;
          } else {
            this.paymentToken = res.result.response.token
            let inputElement = document.getElementById("paymentvalue") as HTMLInputElement;
            // Set the value of the input element
            inputElement.value = this.paymentToken;
            // Get the form element
            let form = document.getElementById('formAuthorizeNetPage') as HTMLFormElement;
            form.method = 'POST';
            form.submit();
          }

          // stripe.redirectToCheckout({ sessionId: res.id });
        });
        this.checkingSidebarSetStatus(false);
      } else if (data.btnType === 'markAsPaid') {
        this.generateInvoicePopupShowHide = false;
        this.makeAsPaidPopupShowHide = true;
      }

      if (callAPI) {
        // API Call for all
        callAPI = false;
        const payload = {
          type: method,
          url: callUrl,
          isDeveloper: true,
          pathVariables: [pathVariable ? pathVariable : null],
          body: bodyData ? bodyData : null,
        };
        this.http.makeHttpRequest(payload).subscribe((res) => {
          if (this.http.isSuccessfulResponse(res)) {
            this.getPatientDetails();
            this.getInvoiceList(this.appointmentListPayload);
            this.observable.showToast(TOAST_STATUSES.SUCCESS, message);
          } else {

            this.observable.showToast(TOAST_STATUSES.ERROR, res.error.message);
          }
        });
      }
    }
  }

  // This is closing for make as paid popup
  makeAsPaidPopupShowHideStatusFun(status) {
    this.makeAsPaidPopupShowHide = status.cancelpopup;
  }

  // Invoice List icon action catching click
  invoiceListClick(data) {
    if (data.statusText === 'ungenerated') {
      this.generateInvoicePopupShowHide = true;
    } else if (data.statusText === 'markaspaid') {
      this.observable.showToast(TOAST_STATUSES.SUCCESS, 'This feature is coming out soon..!!');
    } else if (data.statusText === 'resend') {
      this.observable.showToast(TOAST_STATUSES.SUCCESS, 'This feature is coming out soon..!!');
    }
  }
  uploadFunctionCall(data) {
    this.uploadUrlBody = {
      uploadType: 'patient',
      refId: this.patientsDetails.details.patientId.toString(),
      docUrl: data
    };
    this.getUploadedFilesURL(data);
    const payloadData = {
      type: 'GET',
      url: 'adminRequestDetails',
      // isDeveloper: true,
      // pathVariables: [this.requestId],
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (res) {

      }
    });
  }
  getUploadedFilesURL(filed) {
    let payloadData;
    payloadData = {
      type: 'POST',
      url: 'uploadsFiles',
      isDeveloper: true,
      body: this.uploadUrlBody,

    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.detailPageUpload.push(res.data);
        this.detailPageUpload = this.messageUploads(this.detailPageUpload);
        if (res.data.docUrl !== '') {
          this.observable.showToast(TOAST_STATUSES.SUCCESS, 'File has been successfully uploaded.');
        }
      } else {
        let message = 'Failed to update uploading files . Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
  deleteFileUpload(data) {
    this.checkingSidebarSetStatus(true);
    let bodyData = {
      uploadType: 'patient',
      refId: this.patientsDetails.details.patientId.toString(),
      uploadId: data.uploadId
    };
    let payloadData = {
      type: 'DELETE',
      url: 'uploadFileDelete',
      isDeveloper: true,
      body: bodyData,
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.detailPageUpload = this.messageUploads(this.deleteUpload(data));
        this.observable.showToast(TOAST_STATUSES.SUCCESS, res.data);
        this.checkingSidebarSetStatus(false);
      } else {
        let message = 'Failed to delete uploading files . Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
  deleteUpload(data) {
    let uploads = [];
    this.detailPageUpload.forEach((each, index) => {
      if (each.uploadId !== data.uploadId) {
        uploads.push(each);
      }
    });
    return uploads;
  }

  // getting permissions based on user id
  getAdminPermissions(callTime) {
    const payloadData = {
      type: 'GET',
      url: 'getPermissions',
      isDeveloper: true
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        let tempPerms = ['APPOINTMENT_CREATE', 'APPOINTMENT_LIST', 'PATIENT_EDIT', 'INVOICE_EDIT', 'INVOICE_DETAILS'];
        res.data.permissions.forEach(obj => {
          this.permissionsArr.push(obj.permissionName);
        });
        if (this.permissionsArr.includes('INVOICE_MARKASPAID')){
          this.isMarkasPaid = true;
        }
        else{
          this.isMarkasPaid = false
        }
        if (!this.permissionsArr.includes('PATIENT_DETAILS')) {
          this.observable.showToast(TOAST_STATUSES.ERROR, 'Sorry! You do not have access to this page.');
          this.router.navigate(['/admin']);
        }
        if (this.patientsDetails) {
          this.patientsDetails.actions.forEach(action => {
            if (!this.permissionsArr.includes(action.permissionName)) {
              action.show = false;
            }
          });
        }
        tempPerms.forEach(perm => {
          if (!this.permissionsArr.includes(perm)) {
            this.adminPermissions[perm] = false;
          }
        });
      } else {
        // Error
        let message = (res.error) ? res.error.message : 'Error in getting permissions';

      }
      if (callTime === 'second') { this.permissionsDone = true; }
    });
  }
  // For displaying patient records popup
  documentsRequestPopupStatusFun(status) {
    this.displayDocumentsRequestPopup = status.cancelpopup;
    this.checkingSidebarState(status.cancelpopup);
  }
  closeDocumentRequestPopup(status) {
    this.displayDocumentsRequestPopup = status.isPopup;
    this.checkingSidebarState(status.isPopup);
    if (status.apiStatus) {

      this.getDocumentList(this.appointmentListPayload);
    }
  }
  // for tab selection
  // This  will assign the which tab is selected
  selectedTab(tab) {
    if (tab && typeof (tab) === 'object') {
      this.filtersTabText.forEach(each => {
        each.selected = false;
        if (each.id === tab.id) {
          each.selected = true;
        }
      });
      this.selectedTabIndex = tab.id;
      this.pageNumber = 1;
      this.INITIAL_PAYLOAD.pagination.page = this.pageNumber;
      this.appointmentListPayload = this.INITIAL_PAYLOAD;
      if (this.selectedTabIndex === 0) {
        this.getInvoiceList(this.appointmentListPayload);
      } else if (this.selectedTabIndex === 1) {
        this.getDocumentList(this.appointmentListPayload);
      }

    }
  }
  requestActions(...actions) {
    console.log(actions);
  }

  /**
   * Navigate to user profile edit - admin side
   */
  showEditForm(){
    this.editOverlay.hide();
    this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.CREAT_PATIENT}`], { queryParams: { id: this.patientsDetails.details.patientId, edit: true, userId: this.patientsDetails.details.userId, patientName: this.patientsDetails.details.patientBasicInfo.firstName, lastName: this.patientsDetails.details.patientBasicInfo.lastName, status: this.patientsDetails.details.status, isOffile: this.patientsDetails.details.isOffline } });
  }

  /**
   * To show the user profile edit form. - admin side
   */
  showUserProfile(){
    if(this.patientsDetails.patientPointofContact != null){
    this.displayEditUserPopup = true;
    this.editOverlay.hide();
    this.checkingSidebarState(true);
    }else {
      this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.POC_DOESNT_EXIST);
    }
  }

   /**
   * For displaying edit request popup - admin side
   * @param status 
   */
  editUserPopupStatusFun(status) {
    if(this.patientsDetails.patientPointofContact != null){
      this.displayEditUserPopup = status.cancelpopup;
      this.checkingSidebarState(status.cancelpopup);
    } else {
      this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.POC_DOESNT_EXIST);
    }
  }

   /**
   * For displaying edit request popup - admin side
   * @param status 
   */
  closeEditUserPopup(status) {
    this.displayEditUserPopup = status.isPopup;
    this.checkingSidebarState(status.isPopup);
  }

  /**
   * To close the profile edit popup after action. - admin side
   * @param event submit event
   */
  submitUserProfie(event){
    if(event == "success"){
      this.getPatientDetails();
    } 
    this.closeEditUserPopup(true);
  }

  moreDisplay(e, op) {
    op.show(e);
  }

  /**
    * Send mail to patient reg medicine.
    */
   sendMedicineInfoMail() {
    let payload: IPostMethodPayload = {
      type: 'POST',
      url: 'insuranceEmail',
      isDeveloper: true,
      body: {
        patientId: this.patientId,
        emailType: "MEDICATION_MAIL",
        appointmentId: null,
        patientIdList: []
      }
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.observable.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.MEDS_NOTIFICATION_SUCCESS);
      } else {
        let message = TOAST_MESSAGES.MEDS_NOTIFICATION_FAIL;
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  /**
   * This is for getting request documents
   */
  getLabDocumentRequest() {
    this.labDocumentsRequest = [];
    const payloadData: IGetMethodPayload = {
      type: HTTPMethods.GET,
      url: API_URLS.ADMIN_REQ_DOC,
      isDeveloper: true,
      pathVariables: ['LAB_REQUEST']
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (res) {
        this.labDocumentsRequest = res.data;
      }
    });
  }

  /**
   * Close lab doc popup.
   * @param status 
   */
  closeLabDocumentRequestPopup(status) {
    this.displayLabDocumentsRequestPopup = status.isPopup;
    this.checkingSidebarState(status.isPopup);
    if (status.apiStatus) {
      this.getDocumentList(this.appointmentListPayload);
    }
  }

  /**
   * Show/close lab doc popup.
   * @param status 
   */
  labDocumentsRequestPopupStatusFun(status) {
    this.displayLabDocumentsRequestPopup = status.cancelpopup;
    this.checkingSidebarState(status.cancelpopup);
  }
}
