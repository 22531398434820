import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyAccountRoutingModule } from './my-account-routing.module';
import { WebFaqsComponent } from './web-faqs/web-faqs.component';
import { CommonAppModule } from '@app/common-app.module';


@NgModule({
  declarations: [WebFaqsComponent],
  imports: [
    CommonModule,
    CommonAppModule,
    MyAccountRoutingModule
  ]
})
export class MyAccountModule { }
