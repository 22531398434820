export const medicalDetails = [
    {
        heading: 'ADD, ADHD',
        description: 'Attention-deficit/hyperactivity disorder (ADHD) is one of the most common mental disorders affecting children. ADHD also affects many adults. Symptoms of ADHD include inattention, hyperactivity, and impulsivity.  ADHD is diagnosed as one of three types: inattentive type, hyperactive/impulsive type or combined type. Diagnosis is based on the symptoms that have occurred over the past six months using a rating scale and patient interview/consultation.'
    },
    {
        heading: 'Migraines',
        description: 'Using TMS, the number of days a patient experiences may be reduced by one third per month.'
    },
    {
        heading: 'Chronic fatigue syndrome',
        description: 'A disease characterized by profound fatigue, sleep abnormalities, pain, and other symptoms that are made worse by exertion.We use a combination of cutting edge to help you improve the condition such as Combination of Ketamine & TMS, Med-Management, and Telemedicine'
    },
    {
        heading: 'Depression',
        description: 'We treat patients with major depressive disorder or clinical depression.  Depression is a serious mood disorder that may cause severe symptoms that affect how you feel, think, and handle daily activities, such as sleeping, eating, or working. To be diagnosed with depression, the symptoms must be present for at least two weeks.  Dr. Prayaga treats depression with a multifaceted approach:  medication management, cognitive behavior therapy, TMS and ketamine treatment.'
    },
    {
        heading: 'Bipolar Disorder',
        description: 'A disorder characterized by depressive lows and manic highs. Treatment includes a combination of medication management and psychotherapy.'
    },
    {
        heading: 'Weight Loss',
        description: 'We prescribe vitamin b12 injection, careful consideration of phentermine, metformin and exercise regimen.'
    },
    {
        heading: 'Narcolepsy',
        description: 'Often called daytime sleepiness, this disorder may cause sudden sleepiness, loss of muscle tone and hallucination.  Methods of treatment vary and range from using under the care of a psychiatrist stimulant to using antidepressants.'
    }
];
