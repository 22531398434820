import { Component, OnInit } from '@angular/core';
import { medicalDetails } from '@app/common/constants/medical-details.constant';

@Component({
  selector: 'app-medical',
  templateUrl: './medical.component.html',
  styleUrls: ['./medical.component.scss']
})
export class MedicalComponent implements OnInit {

  headingData = {
    subHeading: 'WE AID YOU IN',
    mainHeading: 'TREATMENT OF MEDICAL CONDITIONS SUCH AS',
  };

  moreText = {
    moreTextLink: 'Know more'
  };

  currentShowingIndexData: any = null;

  medicalDetailConfig: any = [
    {
      class: 'green',
      img: 'green-bg'
    },
    {
      class: 'yellow',
      img: 'yellow-bg'
    },
    {
      class: 'pink',
      img: 'pink-bg'
    },
    {
      class: 'blue',
      img: 'blue-bg'
    },
    {
      class: 'yellow2',
      img: 'yellow-bg'
    },
    {
      class: 'purple',
      img: 'purple-bg'
    }
  ];

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    // infinited: true,
    // autoplay: true,
    // autoplaySpeed: 1500
  };

  constructor() { }

  ngOnInit() {
    this.medicalDetailConfig.forEach((element, index) => {
      element['data'] = medicalDetails[index + 1];
    });

    this.currentShowingIndexData = medicalDetails[0];
  }

  medicalCardClicked(index) {
    if (window.innerWidth > 1023) {
      let temp = this.medicalDetailConfig[index].data;
      this.medicalDetailConfig[index].data = this.currentShowingIndexData;
      this.currentShowingIndexData = temp;
    }
  }

}
