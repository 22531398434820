import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-aboutus-meet',
  templateUrl: './aboutus-meet.component.html',
  styleUrls: ['./aboutus-meet.component.scss']
})
export class AboutusMeetComponent implements OnInit {

  headingData = {
    subHeading: 'meet',
    mainHeading: 'Rama Prayaga, MD',
  };
  moreText = {
    moreTextLink: 'Know more'
  };

  constructor(private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.route
      .queryParams
      .subscribe(params => {
        if (params['scroll']) {
          // this.scrollToMeet();
          console.log(document.getElementById('meet-doctor').offsetTop, 'this is meet doctor position');
          this.scroll(document.getElementById('meet-doctor'));
          // location.href = "aboutus#meet-doctor";
        }
      });
  }

  scroll(el: HTMLElement) {
    setTimeout(() => {
      window.scroll(0, this.findPos(document.getElementById('meet-doctor')));
    }, 1000);
  }
  findPos(obj: any) {
    var curtop = 0;
    if (obj.offsetParent) {
      do { curtop += obj.offsetTop; }
      while (obj === obj.offsetParent);
      return curtop;
    }
  }
}
