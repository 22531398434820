import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RatingModule} from 'primeng/rating';
import { appointmentRoutingModule } from '@app/pages/AppointmentBooking/appointment-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonAppModule } from '@app/common-app.module';
import { AppointmentDetailComponent } from './appointment-detail/appointment-detail.component';
import { AppointmentWrapperComponent } from './appointment-wrapper/appointment-wrapper.component';
import { MetalHealthDetailsComponent } from './metal-health-details/metal-health-details.component';
import { MedicationConsentComponent } from './medication-consent/medication-consent.component';
import { TelemedicineConsentComponent } from './telemedicine-consent/telemedicine-consent.component';
import { FeedbackComponent } from './feedback/feedback.component';
import {TabViewModule} from 'primeng/tabview';
import { BarRatingModule } from 'ngx-bar-rating';
import { FileUploadModule } from 'primeng/fileupload';
import { HippaNoticeFormComponent } from './hippa-notice-form/hippa-notice-form.component';
import { TmsFormsWrapperComponent } from './tms-forms-wrapper/tms-forms-wrapper.component';
import { TmsQuestionnaireComponent } from './tms-questionnaire/tms-questionnaire.component';
import { HamQuestionnaireComponent } from './ham-questionnaire/ham-questionnaire.component';
import { GadQuestionnaireComponent } from './gad-questionnaire/gad-questionnaire.component';
import { PhqQuestionnaireComponent } from './phq-questionnaire/phq-questionnaire.component';
import { PatientDocumentRequestDetailsComponent } from '../admin/patient-document-request-details/patient-document-request-details.component';
import { KetamineEnrollmentComponent } from './ketamine-enrollment/ketamine-enrollment.component';
import { KetamineMonitoringComponent } from './ketamine-monitoring/ketamine-monitoring.component';
import { AdhdQuestionnaireComponent } from './adhd-questionnaire/adhd-questionnaire.component';
import { RequestTherapistInfoComponent } from './request-therapist-info/request-therapist-info.component';
import { CalendarModule } from 'primeng/calendar';
import { MedicationFeedbackComponent } from './medication-feedback/medication-feedback.component';
import { InformationRequestComponent } from './information-request/information-request.component';
import { SubsentenceConsentComponent } from './subsentence-consent/subsentence-consent.component';
import { SubsentenceFormsWrapperComponent } from './subsentence-forms-wrapper/subsentence-forms-wrapper.component';
import { SubsentenceTreatmentComponent } from './subsentence-treatment/subsentence-treatment.component';
import { SubsentenceAgreementComponent } from './subsentence-agreement/subsentence-agreement.component';
import { SubstanceConsentComponent } from './substance-consent/substance-consent.component';
import { InfnFormComponent } from './infn-form/infn-form.component';

const DECLARATION_COMPONENTS = [
  // AppointmentDetailComponent,
  AppointmentWrapperComponent,
  MetalHealthDetailsComponent,
  MedicationConsentComponent,
  TelemedicineConsentComponent,
  FeedbackComponent,
  HippaNoticeFormComponent,
];

@NgModule({
  declarations: [
    ...DECLARATION_COMPONENTS,
    TmsFormsWrapperComponent,
    TmsQuestionnaireComponent,
    HamQuestionnaireComponent,
    GadQuestionnaireComponent,
    PhqQuestionnaireComponent,
    PatientDocumentRequestDetailsComponent,
    KetamineEnrollmentComponent,
    KetamineMonitoringComponent,
    AdhdQuestionnaireComponent,
    PhqQuestionnaireComponent,
    RequestTherapistInfoComponent,
    MedicationFeedbackComponent,
    InformationRequestComponent,
    SubsentenceConsentComponent,
    SubsentenceFormsWrapperComponent,
    SubsentenceTreatmentComponent,
    SubsentenceAgreementComponent,
    SubstanceConsentComponent,
    InfnFormComponent,
  ],
  imports: [
    CommonModule,
    CommonAppModule,
    appointmentRoutingModule,
    TabViewModule,
    RatingModule,
    FormsModule,
    BarRatingModule,
    FileUploadModule,
    ReactiveFormsModule,
    CalendarModule
  ],
  exports: []
})
// tslint:disable-next-line: class-name
export class appointmentModule { }
