import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { ActivateGuard } from '@app/common/services/activate.guard';
import { ReshedulingWrapperComponent } from './resheduling-wrapper/resheduling-wrapper.component';


const routes: Routes = [
  {
    path: `:${ROUTE_PATHS.APPOINTMENT_ID}/${ROUTE_PATHS.RESCHEDULE}`,
    component: ReshedulingWrapperComponent,
    canActivate: [ActivateGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReshedulingAppointmentRoutingModule { }
