<section class="profile__box--wrapper" (click)="cardSelected($event)" [ngClass]="cardData.isSelected?'card__selected':''">
    <div class="box__block">
        <div class="profile__block">
            <div class="box__block--picture">
                <img src="{{cardData.picture}}" alt="Profile-pic" srcset="">
            </div>
            <div class="box__block--profile">
               <div class="box__block--name">{{cardData.name | titlecase}}</div> 
               <div class="box__block--available">{{cardData.status | titlecase}}</div>
            </div>
        </div>
        <!-- <div class="profile__active">
            <app-svg *ngIf="cardData.isSelected" [name]="'checkbox-white'"></app-svg>
        </div> -->
    </div>
</section>