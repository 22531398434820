<section class="ph__questionnaire__section" [ngClass]="this.requestId?'phqform': ''" >
    <div class="close-icon">
        <div class="close__icon" (click)="back()" *ngIf="this.requestId">
            <app-svg name='plus-circle'></app-svg>
        </div>
    </div>
    <div class="header__wrapper">
        {{header}}
    </div>
       <app-custom-form [formData]="formData" (sliderValue)="getSliderValue($event)" [checkFormInfo]="saveFormData"
    (submitEvent)="getFormData($event)"></app-custom-form>
    <div class="common-stepper-inner" class="btn-actions">
        <div class="cancel-btn" (click)="back()" *ngIf="this.requestId">Cancel</div>
        <div class="common-btn-style btn--inline" (click)="saveForm()" *ngIf="this.requestId">submit</div>
    </div>
</section>