<div class="patients-list" *ngIf="listOfPatients">
    <div class="patients-list-main">
        <div class="patients-list-content">
            <div class="patients-list-title">
                My Patients
            </div>
            <div class="add-patient-block">
                <div class="add-patient" (click)="redirectingToAddPatientForm()">
                    <app-svg [name]="'plus-circle'"></app-svg>
                    <p>Add new</p>
                </div>
                <div class="underline"></div>
            </div>
            <div class="patients-block" >
                <div class="patient-card" *ngFor="let card of listOfPatients">
                    <div class="patient-profile">
                        <app-svg [name]="'user'"></app-svg>
                        <p>{{card.firstName}} {{card.lastName}}</p>
                    </div>
                    <div class="patient-actions">
                        <div class="patient-action-block" (click)="viewOrEditMode(card.patientId,'view')">
                            <app-svg [name]="'view'" class="view"></app-svg>
                            View
                        </div>
                        <div class="patient-action-block" (click)="viewOrEditMode(card.patientId,'edit')">
                            <app-svg [name]="'edit'" class="edit"></app-svg>
                            Edit
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="close" (click)="showHideMyAccountPopupStatusChange()">
            <app-svg [name]="'cancel-appointment'"></app-svg>
        </div>
        <img class="onbaord-form-bg" alt="background-leaf" src="assets/images/pngs/onboard-card-bg.png" />
    </div>
</div>