<section class="patient__main-wrapper" *ngIf="isShowScreen && permissionsDone">
    <!--Right side background leaf starts here -->
    <div class="background-image">
        <img src="assets/images/pngs/newpatientform-background.png" alt="bg image">
    </div>
    <!-- Right side background leaf ends here -->
    <!-- Main section starts here -->
    <div class="patient__wrapper content__wrapper">
        <!-- Breadcrumb section starts here -->
        <div class="patient__list-navigation-menu">
            <app-breadcrumb [breadCrumbData]="breadCrumbData"></app-breadcrumb>
            <!-- Primary action on Page -->
        </div>
        <!-- Breadcrumb section ends here -->
        <!-- Advanced filters -->
        <div class="patient__list-advanced-filters">
            <div class="patient__list-search-filter">
                <!--  [isOpenSearch] = "isOpenSearch" -->
                <app-list-search [defSearchValue]="searchInputText" [searchPlaceHolderText]="searchPlaceHolderText" (searchText)="searchFilter($event)"></app-list-search>
            </div>
        </div>
        <div class="patient__list--block">
            <ng-container *ngIf="userList.length !== 0">
                <div class="patient__list--wrapper">
                    <div class="each__patient" *ngFor="let user of userList">
                        <app-user-list [adminPermissions]="adminPermissions" [userData]="user" (userActionEmit)="userActionEmit($event)"></app-user-list>
                    </div>
                </div>
            </ng-container>
            
            <!-- Pagination component starts here -->
            <ng-container>
                <app-pagination [paginationRawData]="paginationData" (pageChanged)="pageChangedEvent($event)" *ngIf="userListCount > 10"></app-pagination>
            </ng-container>
            
            <!-- Pagination component ends here -->
            <!-- Empty list Starts here -->
            <app-empty-list-data [emptyMessageData]="emptyAppointmentsListMessages" *ngIf="userList.length === 0"></app-empty-list-data>
            <!-- Empty list ends here -->
        </div>
    </div>
    <!-- Main section ends here -->
</section>

<app-common-popup class="delete-popup" (showHideCancelPopup)='cancelPopupShowHideStatusFun($event)' *ngIf="popupShowHide">
    <div class="popup-content-block">
        <div class="confirm-message">{{popupMessage}}</div>
        <div class="btn-actions">
            <div class="common-btn-style yes-btn" (click)="cancelPopupShowHideStatusFun($event,false)">No</div>
            <div class="common-btn-style" (click)="cancelPopupShowHideStatusFun($event,true);popupShowHide = false">Yes</div>
        </div>
    </div>
</app-common-popup>