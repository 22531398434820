<!-- treatments section start -->
<section class="treatments__section full-width">
  <!-- treatments container start -->
  <div class="treatments__container restrict-width padding-lr">
    <app-section-header [headingData]="headingData"></app-section-header>
    <!-- treatments cards block start -->
    <div class="card-container up">
      <img class="image-size" src="assets/images/pngs/adhdLeaf.png" />
      <div class="box"  (click)="navigateEachTreatment(0)">
        <img class="imagecard" src="assets/images/jpgs/cardd.jpg" />
        <div>
          <div class="text-heading">ADHD Treatment</div>
          <div class="text-internal">
            ADHD Is a neurodevelopmental disorder that affects people across all age groups. Symptoms of ADHD can be
            bucketed into 3 categories: inattention, hyperactivity, and impulsivity. These symptoms cause complications
            in focus, prioritization, thoughtfulness, and timeliness. Resultantly, many individuals with ADHD may find
            it difficult to maintain productivity at work, school, etc. However, individuals can seek treatment to
            alleviate the burden of these symptoms.
          </div>
          <div class="card-box">
            <div class="location-text">
              <img class="locationImage" src="assets/images/jpgs/Frame.png" />
              Virginia/Washington DC
            </div>
            <div class="know-more">
              Know more
              <img class="arrowKnowmore" src="assets/images/jpgs/kmore.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-container testosterone-position" >
      <div class="boxleft orange" (click)="navigateEachTreatment(1)">
        <img class="imagecard" src="assets/images/jpgs/img2.jpg" />
        <div>
          <div class="text-heading orangeText">Testosterone Treatment </div>
          <div class="text-internal">
            Low Testosterone is a condition in which the blood levels of testosterone are below expected benchmarks.
            Among males, low testosterone can lead to a plethora of symptoms, such as low sex drive, erectile
            dysfunction (ED) problems, reduced muscle mass, fatigue, and emotional irregularities. Similarly, women face
            significant hormonal issues, specifically with the production of progesterone, which can impact one’s
            fertility.
          </div>
          <div class="card-box">
            <div class="location-text">
              <img class="locationImage" src="assets/images/jpgs/Frame.png" />
              Virginia/Washington DC
            </div>
            <div class="know-more" >
              Know more
              <img class="arrowKnowmore" src="assets/images/jpgs/kmore.png" />
            </div>
          </div>
        </div>
      </div>
      <img class="left" src="assets/images/pngs/testosteroneLeaf.png" />
      </div>

    <div class="card-container marijuana-position" >
      <img class="image-size" src="assets/images/jpgs/leavered.jpg" />
      <div class="box red" (click)="navigateEachTreatment(2)">
        <img class="imagecard" src="assets/images/jpgs/Bitmap.jpg" />
        <div>
          <div class="text-heading redText">Marijuana treatment</div>
          <div class="text-internal">
            Medical Marijuana uses the marijuana plant or the chemicals within it to treat diseases or conditions. The
            marijuana plant contains more than 100 different chemicals called cannabinoids. Each one has a different
            effect on the body. Delta-9- Tetrahydrocannabinol (THC) and cannabidiol (CBD) are the main chemicals used in
            medicine. THC also produces the “high” people feel when they smoke marijuana or eat foods containing it.
          </div>
          <div class="card-box">
            <div class="location-text">
              <img class="locationImage" src="assets/images/jpgs/Frame.png" />
              Virginia/Maryland
            </div>
            <div class="know-more">
              Know more
              <img class="arrowKnowmore" src="assets/images/jpgs/kmore.png" />
            </div>
          </div>
        </div>
      </div>
      </div>

    <div class="card-container antiaging-position" >
      <div class="boxleft blue" (click)="navigateEachTreatment(4)">
        <img class="imagecard" src="assets/images/jpgs/image7.jpg" />
        <div>
          <div class="text-heading blueText">Anti-aging Treatment</div>
          <div class="text-internal">
            Aging affects not just our physical well-being but our mental health as well. We all want to be able to
            perform at our peak states at any given time in life. At New TeleDoc we help you regain your peak condition
            by using modern and cutting-edge therapies.
          </div>
          <div class="card-box">
            <div class="location-text">
              <img class="locationImage" src="assets/images/jpgs/Frame.png" />Virginia/Washington DC
            </div>
            <div class="know-more" >
              Know more
              <img class="arrowKnowmore" src="assets/images/jpgs/kmore.png" />
            </div>
          </div>
        </div>
      </div>
      <img class="left" src="assets/images/jpgs/leavegreen.png" />
      </div>

    <div class="card-container space ketamine-position" >
      <img class="image-size" src="assets/images/pngs/ketamineLeaf.png" />
      <div class="box purple" (click)="navigateEachTreatment(3)">
        <img class="imagecard" src="assets/images/jpgs/image4.jpg" />
        <div>
          <div class="text-heading redText">KETAMINE THERAPY</div>
          <div class="text-internal">
            Ketamine Therapy incorporates the use of the versatile drug ketamine, as the name implies. The drug can be
            administered in various ways for clinical use: orally, topically, through intramuscular injection, or
            intravenously. Ketamine has proven to be effective as an analgesic. Furthermore, it can be used to treat
            psychological disorders such as PTSD, Anxiety, and Depression. Ketamine’s effectiveness is derived from, in
            part, its beneficial pharmacological properties. These include stimulation of the cardiovascular system,
            airway relaxation, and catalepsy (a trancelike state with diminished responsiveness)
          </div>
          <div class="card-box">
            <div class="location-text">
              <img class="locationImage" src="assets/images/jpgs/Frame.png" />Virginia/Washington DC
            </div>
            <div class="know-more" >
              Know more
              <img class="arrowKnowmore" src="assets/images/jpgs/kmore.png" />
            </div>
          </div>
        </div>
      <!-- </div> -->
      </div>
    </div>
    <!-- treatments cards block end -->
    <!-- book now block start -->
    <div class="book__now-block">
      <div class="orange-heading">
        Dr. Prayaga, as a part of his holistic approach coordinates care with local hospital, community service boards
        and therapists.
      </div>
      <div class="common-btn-style">
        <div (click)="navigateBookingpage()">Book an Appointment</div>
      </div>
    </div>
    <!-- book now block end -->
  </div>
  <!-- treatments container end -->
</section>
<!-- treatments section end -->
