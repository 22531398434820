<!-- treatments card block start -->
<div class="treatments__card-block">
    <!-- treatments card carousel start -->
    <div class="treatments__card-carousel">
        <!-- left block start -->
        <div class="treatments__left-block">
            <h2 class="carousel-heading m-d-show">{{cardData.heading}}</h2>
            <div class="image__block">
                <img src="assets/images/{{cardData.imagePath}}" alt="image">
            </div>
        </div>
        <!-- left block end -->
        <!-- right block start -->
        <div class="treatments__right-block">
            <h2 class="carousel-heading m-d-hide">{{cardData.heading}}</h2>
            <div class="carousel__content-block" >
                <div [ngClass]="showFullDesciption ? 'more' : 'less' ">
                    <p >{{cardData.description}}</p>
                    <h3>{{cardData.listHeading}}</h3>
                    <ul *ngFor="let list of cardData.listArray">
                        <li>{{list}}</li>
                    </ul>
                </div>
                <app-know-more [moreText]="showFullDesciption ? lessText : moreText" [show]="true" class="m-d-show" [actionBased]="true" (actionTriggered)="readMore($event)"></app-know-more>
            </div>
        </div>
        <!-- right block end -->
    </div>
    <!-- treatments card carousel end -->
</div>
<!-- treatments card block end -->