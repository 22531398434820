// this component is to edit the lab documents.

import { Component, OnInit } from '@angular/core'
import { ObservableHelperService } from '@app/common/services/observable-helper.service'
import { ActivatedRoute, Router } from '@angular/router'
import { HttpService } from '@app/common/services/http.service'
import { Location } from '@angular/common'
import { TOAST_STATUSES, HTTPMethods } from '@app/common/constants/util.constant'
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant'
import { IGetMethodPayload, IPutMethodPayload } from '@app/common/constants/typeInterfaces.constants'
import { API_URLS } from '@app/common/constants/api-urls.constant'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'

@Component({
  selector: 'app-lab-document-edit',
  templateUrl: './lab-document-edit.component.html',
  styleUrls: ['./lab-document-edit.component.scss'],
})
export class LabDocumentEditComponent implements OnInit {
  labDocId: number = 8
  templateHtlm: SafeHtml = ''
  breadCrumbData = {
    link: '',
    linkText: 'Edit Lab Document',
  }
  showScreen: boolean = false
  isViewDoc: boolean = false

  constructor(
    private observable: ObservableHelperService,
    private http: HttpService,
    private router: Router,
    private sanitized: DomSanitizer,
    private location: Location,
    private route: ActivatedRoute
  ) {
    this.labDocId = Number.parseInt(router.url.split('/').pop())
    route.queryParams.subscribe((res: any) => {
      if (res && res.isView) {
        this.isViewDoc = res.isView
      }
    })
  }

  ngOnInit() {
    this.getLabDocDetails()
  }

  /**
   * Get document details.
   */
  getLabDocDetails() {
    const payloadData: IGetMethodPayload = {
      type: HTTPMethods.GET,
      url: API_URLS.LAB_DOC_DETAILS,
      pathVariables: [this.labDocId],
      isDeveloper: true,
    }
    let breadCrumbText=''
    this.isViewDoc ? breadCrumbText = "View Document" : breadCrumbText =  'Edit Document'
    this.http.makeHttpRequest(payloadData).subscribe(res => {
      if (this.http.isSuccessfulResponse(res)) {
        this.breadCrumbData.linkText =
          breadCrumbText + (res.data.documentName ? ' - ' + res.data.documentName : '')
        this.templateHtlm = this.sanitized.bypassSecurityTrustHtml(res.data.PDFTemplate)
        this.editContent()
        this.showScreen = true
      } else {
        let message = TOAST_MESSAGES.LAB_DOC_FAIL
        message = res.error ? res.error.message : message
        this.observable.showToast(TOAST_STATUSES.ERROR, message)
      }
    })
  }

  /**
   * This is for getting updated HTML template
   */
  getEditedTemplate() {
    let editableDivs: any = document.getElementsByClassName('editable')
    for (let i = 0; i < editableDivs.length; i++) {
      editableDivs[i].setAttribute('contenteditable', false)
    }
    let editedHtml = document.getElementById('content-edit').innerHTML
    return (
      '<html>' +
      editedHtml.substring(0, editedHtml.indexOf('<style>')) +
      '{{styles}}' +
      editedHtml.substring(editedHtml.indexOf('</style>') + 8) +
      '</html>'
    )
  }

  /**
   * This funciton is set contenteditable true for editable div's and set contenteditable false for non-editable div's
   */
  editContent() {
    setTimeout(() => {
      let editableDivs: any = document.getElementsByClassName('editable')
      if (this.isViewDoc) {
        for (let i = 0; i < editableDivs.length; i++) {
          editableDivs[i].setAttribute('contenteditable', false)
        }
      } else {
        for (let i = 0; i < editableDivs.length; i++) {
          editableDivs[i].setAttribute('contenteditable', true)
          editableDivs[i].setAttribute('data-gramm', false);
          editableDivs[i].setAttribute('data-gramm_editor', false);
          editableDivs[i].setAttribute('data-enable-grammarly', false);
        }
      }

      let notEditableDivs: any = document.getElementsByClassName('non-editable')
      for (let i = 0; i < notEditableDivs.length; i++) {
        notEditableDivs[i].setAttribute('contenteditable', false)
      }
    }, 1000)
  }

  /**
   * Save edited document.
   */
  saveForm() {
    let body = {
      documentId: this.labDocId,
      PDFTemplate: this.getEditedTemplate(),
    }
    const payloadData: IPutMethodPayload = {
      type: HTTPMethods.PUT,
      url: API_URLS.UPDATE_LAB_DOC,
      isDeveloper: true,
      body: body,
    }
    this.http.makeHttpRequest(payloadData).subscribe(res => {
      if (this.http.isSuccessfulResponse(res)) {
        this.observable.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.LAB_DOC_UPDATE_SUCCESS)
        setTimeout(() => {
          this.navigateBack()
        }, 3000)
      } else {
        let message = TOAST_MESSAGES.LAB_DOC_UPDATE_FAIL
        message = res.error ? res.error.message : message
        this.observable.showToast(TOAST_STATUSES.ERROR, message)
      }
    })
  }

  /**
   * Navigating Back to Patient list page.
   * @param event
   */
  navigateBack() {
    window.close()
  }
}
