import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CognitoService } from '@app/common/services/cognito.service';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { DataService } from '@app/common/services/data.service';
import { HttpService } from '@app/common/services/http.service';

@Component({
  selector: 'app-substance-consent',
  templateUrl: './substance-consent.component.html',
  styleUrls: ['./substance-consent.component.scss']
})
export class SubstanceConsentComponent implements OnInit {
  @Input() saveFormData;
  substanceForm: any;
  @Output() submitEvent: EventEmitter<any> = new EventEmitter();
  @Input() patientDataList;
  tabContent1 = [
    {
      headerLabel: 'Patient Agreement',
      data: [
        {
      description: 'By signing this form, I understand the following;',
      list: [
        `I will keep (and be on time for) all my scheduled appointments with the doctor and other members of the treatment team.`,
        `I will participate in all other types of treatment that I am asked to participate in.`,
        `I will take my medication as instructed and not change the way I take it without first talking to the doctor or other member of the treatment team.`,
        `I will not call between appointments, or at night or on the weekends looking for refills. I understand that prescriptions will be filled only during scheduled office visits with the treatment team.`,
        `I will make sure I have an appointment for refills. If I am having trouble making an appointment, I will tell a member of the treatment team immediately.`,
        `I will treat the staff at the office respectfully at all times. I understand that if I am disrespectful to staff or disrupt the care of other patients my treatment will be stopped.`,
        `I will not sell this medicine or share it with others. I understand that if I do, my treatment will be stopped.`,
        `I will sign a release form to let the doctor speak to all other doctors or providers that I see.`,
        `I will tell the doctor all other medicines that I take, and let him/her know right away if I have a prescription for a new medicine.`,
        `I will not get any opioid pain medicines or other medicines that can be addictive such as benzodiazepines (klonopin, xanax, valium) or stimulants (ritalin, amphetamine) without telling a member of the treatment team before I fill that prescription. I understand that the only exception to this is if I need pain medicine for an emergency at night or on the weekends.`,
        `I will not use illegal drugs such as heroin, cocaine, marijuana, or amphetamines. I understand that if I do, my treatment may be stopped.`,
        `I will come in for drug testing and counting of my pills within 24 hours of being called. I understand that I must make sure the office has current contact information in order to reach me, and that any missed tests will be considered positive for drugs.`,
        `I will keep up to date with any bills from the office and tell the doctor or member of the treatment team immediately if I lose my insurance or can't pay for treatment anymore.`,
        `I understand that I may lose my right to treatment in this office if I break any part of this agreement.`,
       ],
      },]
    },
    {
      headerLabel: 'Substances Treatment Program Statement',
    },
    {
      headerLabel: 'Term Controlled Substance Prescriptions',
      data:[
      {    
      description: 'I have been told that:',
      list: [
        `If I drink alcohol or use street drugs, I may not be able to think clearly and I could become sleepy and risk personal injury.`,
        `I may get addicted to this medicine.`,
        `If I or anyone in my family has a history of drug or alcohol problems, there is a higher chance of addiction`,
        `If I need to stop this medicine, I must do it slowly or I may get very sick.`,
       ],
      },
      {
       description: 'I agree to the following',
       list: [
        `I am responsible for my medicines. I will not share, sell, or trade my medicine. I will not take anyone else’s medicine.`,
         `I will not increase my medicine until I speak with my doctor or nurse.`,
         `My medicine may not be replaced if it is lost, stolen, or used up sooner than prescribed.`,
         `I will keep all appointments set up by my doctor (e.g., primary care, physical therapy, mental health, substance abuse treatment, pain management) `,
         `I will bring the pill bottles with any remaining pills of this medicine to each clinic visit. `,
         `I agree to give a blood or urine sample, if asked, to test for drug use. `
        ],
      },
      {
        description: 'Refills',
        list: [
         `Refills will be made only during regular office hours—Monday through Friday, 11:00AM-5:30 PM and Saturday  9:00AM-12PM .
         No refills on nights, holidays, or weekends. I must call at least three (3) working days ahead
         (M-F) to ask for a refill of my medicine. No exceptions will be made. I will not go to
         Primary Care for my refill until I am called by the nurse.`,
          `I must keep track of my medications. No early or emergency refills may be made.`,
         ],
        },
        {
         description: 'Prescriptions from Other Doctors',
         list: [
          `If I see another doctor who gives me a controlled substance medicine (for example, a
            dentist, a doctor from the Emergency Room or another hospital, etc.) I must bring this
            medicine to New Stress Clinic in the original bottle, even if there are no pills left.`,
          ],
        },
        {
          description: 'Privacy',
          list: [
           `While I am taking this medicine, my doctor may need to contact other doctors or family
           members to get information about my care and/or use of this medicine. I will be asked to
           sign a release at that time.`,
           ],
          },
          {
           description: 'Termination of Agreement',
           list: [
            `If I break any of the rules, or if my doctor decides that this medicine is hurting me more than
            helping me, this medicine may be stopped by my doctor in a safe way.`,
            `I have talked about this agreement with my doctor and I understand the above rules. `
            ],
          },
          {
             description: 'Provider Responsibilities',
             list: [
              `As your doctor, I agree to perform regular checks to see how well the medicine is working.`,
              `I agree to provide primary care for you even if you are no longer getting controlled medicines
              from me. `
              ],
            
          }
      ]
    },
  ];
  hippaForm: any;
  substanceFormSing: any;
  substanceForm1: any;
  patientName: string;
  patientId: any;
  userDetails: any;
  constructor(private http: HttpService, private cognitoService: CognitoService,private formService:CustomFormHandlerService,private dataService: DataService) { }

  ngOnInit() {
    // this.hippaForm =  this.formService.initForm(this.formService.formTypes.HIPPA);
    this.substanceForm1 = this.formService.initForm(this.formService.formTypes.SUB_CONSENT)
    this.substanceForm = this.formService.initForm(this.formService.formTypes.SUBSENTANCE_AGREEMENT)
    this.formService.subSetenceAgreementConsentFormConfig[0].value = 'New Stress Clinic',
    this.substanceFormSing = this.formService.initForm(this.formService.formTypes.SUBSENTANCE_TREATEMENT);
    this.cognitoService.getUser().then(user => {
      this.userDetails = user;
      this.getPateint(this.userDetails.userId);
    })

  }
  getPateint(userId) {


    if(this.patientDataList){
      console.log('patientDataList',this.patientDataList);

        let appointmentFormData = this.dataService.getData(this.dataService.dataObject.APPOINTMENT_DETAIL);
        this.patientId = (appointmentFormData) ? appointmentFormData.appoinmentData[1].value : '';
        this.patientName = this.getPatienName(this.patientDataList.patients);
        this.formService.subConsentFormConfig[0].value = this.patientName;
      }
    // });
  }
  getPatienName(patientList) {
    let patient = patientList.find(each => each.patientId === this.patientId);
    return `${patient.firstName} ${patient.lastName}`;
  }
  getFormData(event) {
    if (event) {
     
      this.formService.subSentenceTreatmentConsentFormConfig = this.assignFormValues(event, this.formService.subSentenceTreatmentConsentFormConfig);
      this.dataService.setData(this.dataService.dataObject.HIPPA, this.formService.subSentenceTreatmentConsentFormConfig);
      this.formService.signaturValues[0] = this.formService.subSentenceTreatmentConsentFormConfig[1].value;
      this.submitEvent.emit({status: event});
    }
  }
    assignFormValues(formData, formConfig) {
    let keys = Object.keys(formData.value);
    formConfig.forEach((each, index) => {
      if (keys.includes(each.key)) {
        each.value = formData.value[each.key];
      }
    });
    return formConfig;
  }

}
