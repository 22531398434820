import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';

@Component({
  selector: 'app-appointments-cards-list',
  templateUrl: './appointments-cards-list.component.html',
  styleUrls: ['./appointments-cards-list.component.scss']
})
export class AppointmentsCardsListComponent implements OnInit {
  @Input() upcomingAppointments: any;
  @Input() removeLocation;
  @Input() bookButtonFlag = false;
  @Input() gridType;
  @Output() emptySlotEvent: EventEmitter<any> = new EventEmitter();
  constructor(private router: Router) { }

  ngOnInit() {
    console.log(this.upcomingAppointments);
  }

  redirection(id) {
    id = Number(id);
    this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.APPOINTMENTS}/${id}`]);
  }

  bookSlot(slot){
    this.emptySlotEvent.emit(slot);
  }

    // Switch case for the appointment type icons
    getingAppointmentTypeSvgName(appointmentType) {
      let appointmentTypeIconName = '';
      switch (appointmentType) {
        case 'Medication Management':
          appointmentTypeIconName = 'medication-management';
          break;
        case 'Ketamine Treatment':
          appointmentTypeIconName = 'ketamine-treatment';
          break;
        case 'TMS':
          appointmentTypeIconName = 'adminTms';
          break;
        case 'Psychological Therapy':
          appointmentTypeIconName = 'psychological-therapy';
          break;
      }
      return appointmentTypeIconName;
    }

    createNewAppointment(){
    this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.NEWAPPOINTMENT}`]);
    }
    fullName(data){
      return data.firstName + ' ' + data.lastName
    }
    filterAppointments(data){
    this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.APPOINTMENTS}`], { queryParams: { type: 'upcoming', filter: 'true', appoinmnetData: data.date } });
    }
}
