<!-- blog section start -->
<section class="blog__section full-width">
    <!-- bg image block start -->
    <div class="bg-image__block">
        <img src="assets/images/pngs/blog-details-bg.png" alt="bg image">
    </div>
    <!-- bg image block end -->
    <!-- blog container start -->
    <div class="blog__container restrict-width padding-lr">
        <app-know-more [moreText]="backText" (navigateBackPage) = "navigateBackPage($event)" [actionBased]="true" [show]="true">
        </app-know-more>
        <!-- blog cards block start -->
        <div class="blog__cards-block" *ngIf="posts">
            <!-- blog card block start -->
            <div class="blog__card-block {{ colorClass[i] }}" *ngFor="let post of posts; let i = index">
                <!-- blog card left block start -->
                <div class="blog__card-left-block">
                    <div class="image__block {{ colorClass[i] }}">
                        <img *ngIf="post" [src]="post.featured_image_src" alt="blog image">
                    </div>
                </div>
                <!-- blog card left block end -->
                <!-- blog card right block start -->
                <div class="blog__card-right-block">
                    <h3 class="blog__heading">
                        {{ post.title.rendered }}
                    </h3>
                    <div class="blog__social-block">
                        <p class="blog__post-date">{{ post.date | date:'d MMMM, y' }}</p>
                        <!-- social icons block start -->
                        <div class="social__icons-block">
                        Share:&nbsp;&nbsp;
                            <a href="https://www.facebook.com/sharer/sharer.php?u={{getUrl()}}" target="_blank" class="social__icon-block facebook" rel="noopener noreferrer">
                                <app-svg [name]="'facebook'"></app-svg>
                            </a>
                            <a href="https://www.linkedin.com/sharing/share-offsite/?url={{getUrl()}}"target="_blank" rel="noopener noreferrer" class="social__icon-block linkedin" >
                                <app-svg [name]="'linkedin'"></app-svg>
                            </a>
                            <a href="http://twitter.com/share?url={{getUrl()}}" rel="noopener noreferrer" class="social__icon-block twitter" target="_blank">
                                <app-svg [name]="'twitter'"></app-svg>
                            </a>
                        </div>
                        <!-- social icons block end -->
                    </div>
                    <div class="blog__description-block">
                        <p class="blog__description" [innerHTML]="post.content.rendered"></p>
                        <h3 class="blog__description blog__edited">Edited by: {{ post.author }} {{ post.modified | date:'dd/MM/yyyy' }}</h3>
                        <h3 class="blog__description blog__edited">Edited and Proofread by: {{ post.date | date:'dd/MM/yyyy' }}</h3>
                    </div>
                </div>
                <!-- blog card right block end -->
            </div>
            <!-- blog card block end -->
        </div>
        <!-- blog cards block end -->
    </div>
    <!-- blog container end -->
</section>
<!-- blog section end -->