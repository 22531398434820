<section class="mediaction_feedback-wrapper appointment__page__wrapper">
    <div class="close-icon">
        <app-svg [name]="'plus-circle'" (click)="cancel()"></app-svg>
    </div>
    <div class="feedback-header">
        Medication Feedback
    </div>
    <div class="medication__form--block">
        <app-custom-form [checkFormInfo]="saveFormData  ? resetSaveFormParity() : false" (submitEvent)="getFormData($event)" [formData]="formData">
        </app-custom-form>
    </div>
    <div class="common-stepper-inner" class="btn-actions medication-feedback-btn-sec">
        <div class="cancel-btn" (click)="cancel()">Cancel</div>
        <div class="common-btn-style btn--inline" (click)="saveForm()">Submit</div>
    </div>
</section>