import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';

@Component({
  selector: 'app-each-treatment',
  templateUrl: './each-treatment.component.html',
  styleUrls: ['./each-treatment.component.scss']
})
export class EachTreatmentComponent implements OnInit {
  heading:string;
  img:string;
  themeColor:string;
  id:number;
  constructor(private route: ActivatedRoute, private router: Router) { 
    this.route.queryParams
    .subscribe(params => {
      console.log(params.id);
      this.heading=params.heading;
      this.img=params.image;
      this.themeColor=params.color;
      this.id=params.id;
    }
  );
  }

  ngOnInit() {
  
  }
  navigateBookingpage() {
    this.scrollTop();
    //the below line was commented on sept 5 2023 because it was showing toast meassge of error even after working corrctly
    // this.router.navigate([`${ROUTE_PATHS.APPOINTMENT}`]);
    this.router.navigate(['login'])
  }
  navigateHome(){
    this.router.navigate([`${ROUTE_PATHS.EMPTY}`]);
  }
  navigateTreatment(){
    this.router.navigate([`${ROUTE_PATHS.TREATMENTS}`]);
  }
  scrollTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

}
