<section class="contact-us">
    <div class="cancel-popup">
        <div class="cancel-popup-main">
            <img class="onbaord-form-bg" src="assets/images/pngs/onboard-card-bg.png" />
            <div class="cancel-popup-content">
                <div class="cancel-popup-title">
                    Let’s talk            </div>
                <div class="cancel-reason"  id="hubspotForm">
                    <app-custom-form [formData]="form" [checkFormInfo]="saveFormData ? resetSaveFormParity() : false" (submitEvent)="getFormData($event)"></app-custom-form>
                </div>
                <div class="notes-title">
                    <p>Note : Email only medication related questions to <span class="highlight-text">Dr.Prayaga</span>. 
                        All other admin questions has to be directed to <span class="highlight-text">scheduling@newstressclinic.com</span>.
                        Pharmacy related questions has to be directed to <span class="highlight-text">officemanager@newstressclinic.com</span>. </p>
                </div>
                <div class="btn-actions">
                    <div class="cancel-btn" (click)="showHideCancelPopupStatusChange()">Cancel</div>
                    <div class="common-btn-style" (click)="saveForm()">Send</div>
                </div>
            </div>
            <div class="close">
                <app-svg [name]="'cancel-appointment'" (click)="showHideCancelPopupStatusChange()"></app-svg>
            </div>
        </div>
    </div>
</section>
