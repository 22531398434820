<!-- meet section start -->
<section class="meet__section full-width" id="meet-doctor">
    <div class="image__block banner-bg">
        <img class="background-leaf" src = "assets/images/pngs/services-bg.png" alt="banner image" loading="lazy"/>
    </div>
    <!-- meet container start -->
    <div class="meet__container restrict-width padding-lr">
        <!-- meet left block start -->
        <div class="meet__left-block">
            <div class="image__block">
                <img class="prayaga-image" src = "assets/images/webp/prayaga.webp" alt="prayaga image" loading="lazy"/>
            </div>
        </div>
        <!-- meet left block end -->
        <!-- meet right block start -->
        <div class="meet__right-block">
            <div class="icon__block medium m-show">
                <img class="background-leaf3" src = "assets/images/pngs/background-leaf-3.png" alt="leaf icon" loading="lazy"/>
            </div>
            <app-section-header [headingData]="headingData"></app-section-header>
            <div class="image__block m-d-show">
                <img class="prayaga-image" src = "assets/images/webp/prayaga.webp" alt="prayaga image" loading="lazy"/>
            </div>
            <!-- meet description block start -->
            <div class="meet__description-block">
                <div class="icon__block medium m-d-hide">
                    <img class="background-leaf3" src = "assets/images/pngs/background-leaf-3.png" alt="leaf icon"/>
                </div>
                <p class="meet__description">
                    <!-- Newteledoc, Inc. is led by Dr. Rama Prayaga and his professional and highly qualified Staff. Dr.prayaga is a psychiatrist licensed in multiple states. Dr. Prayaga has practiced medicine, specializing in Child and Adult psychiatry, for 25 years completion as child and Adolescent psychiatry from Georgetown University and completing his residency at New Jersey's Mt. Sinai Hospital. Newteledoc, Inc. has offices located in Washington, DC and Alexandria and Tyson’s Corner, Virginia. -->
                    “Newteledoc, Inc. is led by Dr. Rama Prayaga and his professional and highly qualified Staff. Dr.Prayaga is a psychiatrist licensed in multiple states. Dr. Prayaga has 25 years of experience practiced medicine, specializing in Child Psychiatry and Adolescent Psychiatry at Georgetown University and completing his residency at New York’s Mt. Sinai Hospital. Newteledoc, Inc. has offices located in Washington, DC and Alexandria and Tyson’s Corner, Virginia.
                </p>
                <app-know-more [moreText]="moreText" [show]="false"></app-know-more>
            </div>
            <!-- meet description block end -->
        </div>
        <!-- meet right block end -->
    </div>
    <!-- meet container end -->
</section>
<!-- meet section end -->