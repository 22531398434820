<section class="patient__list--section">
    <div class="patient__list-item user" (click)="navigateDetailsPage(patientData.patientId)">
        <app-svg class="patients__icon" [name]="'user'"></app-svg>
        <div class="patients__list-text" title="{{patientData.patientName}}">{{patientData.patientName | titlecase |
            ellipsis: 18}}
        </div>
    </div>
    <div class="patient__list-item patient-id" (click)="navigateDetailsPage(patientData.patientId)">
        <app-svg class="patients__icon" [name]="'adminPatientDetails'"></app-svg>
        <div class="patients__list-text">{{patientData.patientId }}</div>
    </div>
    <div class="patient__list-item mobile" (click)="navigateDetailsPage(patientData.patientId)">
        <app-svg class="patients__icon" [name]="'mobile'"></app-svg>
        <div class="patients__list-text">{{patientData.phone}}</div>
    </div>
    <div class="patient__list-item email" (click)="navigateDetailsPage(patientData.patientId)">
        <app-svg class="patients__icon" [name]="'mail'"></app-svg>
        <div class="patients__list-text" title="{{patientData.email}}">{{patientData.email | ellipsis: 18}}</div>
    </div>
    <div class="patient__list-item last-vist" (click)="navigateDetailsPage(patientData.patientId)">
        <app-svg class="patients__icon" [name]="'adminLastVisit'"></app-svg>
        <div class="patients__list-text">{{patientData.lastVisit | dateFormat }}</div>
    </div>
    <div class="patient__list-item icons" *ngIf="!dontShowActions">
        <!-- Schedule starts here -->
        <div class="edit" *ngIf="patientData.patientRemovedFlag === 0 && patientData.status === 'pending' && adminPermissions.PATIENT_EDIT">           
            <app-svg class="patients__icon" title="Edit" [name]="'edit'" (click)="moreDisplay($event, overlay)" (clickOutside)="overlay.hide()"></app-svg>
            <p-overlayPanel #overlay [showTransitionOptions]="'100ms'" [hideTransitionOptions]="'100ms'" [appendTo]="'body'">
                <div class="line"></div>
                <div class="more__option"
                (click)='moreOptionClick($event,patientData.editText,patientData)'>Edit patient profile</div>
                <div class="line"></div>
                <div class="more__option"
                    (click)='moreOptionClick($event,"poc",patientData)'>Edit point of contact
                </div>
            </p-overlayPanel>
        </div>
        <!-- Schedule ends here -->
        <!-- Schedule starts here -->
        <div class="delete" *ngIf="patientData.patientRemovedFlag === 0 && patientData.status === 'pending' && adminPermissions.PATIENT_EDIT"
            (click)="deletePatientData($event,patientData)">
            <app-svg class="patients__icon" title="Delete" [name]="'delete'"></app-svg>
        </div>
        <!-- Schedule ends here -->
        <!-- Schedule starts here -->
        <div class="schedule"
            *ngIf="patientData.patientRemovedFlag === 0 && patientData.status !== 'pending' &&  patientData.status === 'active' && adminPermissions.APPOINTMENT_CREATE"
            (click)="scheduleNavigate($event,patientData)">
            <app-svg class="patients__icon" title="Schedule" [name]="'calendar-color'"></app-svg>
        </div>
        <!-- Schedule ends here -->
        <!-- Active starts here -->
        <div class="active"
            *ngIf="patientData.patientRemovedFlag === 0 && patientData.status !== 'pending' &&  patientData.status === 'archive' && adminPermissions.PATIENT_EDIT"
            (click)="activatePatientData($event,patientData.archiveStatus,patientData)">
            <app-svg class="patients__icon" title="Activate" [name]="'active-inherit'"></app-svg>
        </div>
        <!-- Active ends here -->
        <!-- Appoinement more Icon -->
        <div class="more-icon"
            *ngIf="patientData.patientRemovedFlag === 0 && patientData.status !== 'pending' && patientData.status === 'active' && adminPermissions.PATIENT_EDIT"  (clickOutside)="op.hide()"
            (click)="moreDisplay($event, op)">
            <app-svg class="patients__icon" title="More" [name]="'list-more'"></app-svg>
            <!-- This will be uncommented after export build -->
            <p-overlayPanel #op [showTransitionOptions]="'100ms'" [hideTransitionOptions]="'100ms'" [appendTo]="'body'">
                <div class="scroll">
                    <div class="line"></div>
                    <div class="more__option" *ngIf="patientData.patientRemovedFlag === 0 && patientData.status === 'active'"
                        (click)='moreOptionClick($event,patientData.editText,patientData)'>Edit patient profile</div>
                    <div class="line"></div>
                    <div class="more__option" *ngIf="patientData.patientRemovedFlag === 0 && patientData.status === 'active'"
                        (click)='moreOptionClick($event,"poc",patientData)'>Edit point of contact</div>
                    <div class="line"></div>
                    <div class="more__option" *ngIf="patientData.patientRemovedFlag === 0 && patientData.status === 'active'"
                        (click)='moreOptionClick($event,patientData.blockedStatus,patientData)'>{{patientData.blockedStatus}}
                    </div>
                    <div class="line" *ngIf="patientData.patientRemovedFlag === 0 && patientData.status === 'active' && patientData.isOffline !== 1"></div>
                    <div class="more__option" *ngIf="patientData.status === 'active' && patientData.isOffline !== 1" [ngClass]="patientData.status"
                        (click)='moreOptionClick($event,patientData.archiveStatus,patientData)'>{{patientData.archiveStatus}}
                    </div>
                    <div class="line" *ngIf="patientData.patientRemovedFlag === 0 && patientData.status === 'active' && patientData.isOffline !== 1"></div>
                    <div class="more__option" *ngIf="patientData.status === 'active' && patientData.isOffline !== 1" [ngClass]="patientData.status"
                        (click)='moreOptionClick($event,"docs",patientData)'>Request Docs
                    </div>
                    <div class="line" *ngIf="patientData.patientRemovedFlag === 0 && patientData.status === 'active' && patientData.isOffline !== 1"></div>
                    <div class="more__option" *ngIf="patientData.status === 'active' && patientData.isOffline !== 1" [ngClass]="patientData.status"
                        (click)='moreOptionClick($event,"labdocs",patientData)'>Request Lab Docs
                    </div>
                    <!-- 23/6/2022 -->
                    <div class="line" *ngIf="patientData.patientRemovedFlag === 0 && patientData.status === 'active' && patientData.isOffline !== 1"></div>
                    <div class="more__option" *ngIf="patientData.status === 'active' && patientData.isOffline !== 1" [ngClass]="patientData.status"
                        (click)='moreOptionClick($event, "generateDocs", patientData)'>Generate Docs
                    </div>
                    <!-- 23/6/2022 -->
                    <!-- mednotes -->
                    <div class="line"></div>
                    <div class="more__option" *ngIf="patientData.status === 'active'" [ngClass]="patientData.status" (click)='moreOptionClick($event, "appointmentListMM", patientData)' > Generate Mednotes</div>
                    <!--  tms notes-->
                    <div class="line"></div>
                    <div class="more__option" *ngIf="patientData.status === 'active'" [ngClass]="patientData.status" (click)='moreOptionClick($event, "tmsNote", patientData)' > Generate TMS Notes</div>
                </div>
            </p-overlayPanel>
        </div>
    </div>
    <div class="patient__list-item icons" *ngIf="dontShowActions">
        <div class="delete" *ngIf="patientData.patientRemovedFlag === 0 && adminPermissions.PATIENT_EDIT"
        (click)="patientDelete(patientData)">
        <app-svg class="patients__icon" title="Delete" [name]="'delete'"></app-svg>
    </div>
    </div>
</section>
<app-common-popup (showHideCancelPopup)='cancelPopupShowHideStatusFun($event)' *ngIf="cancelPopupShowHide">
    <div class="popup-content-block">
        <div class="confirm-message" *ngIf="popupMessage === 'archive'">Archiving this patient will cancel all their upcoming appointments. Are you sure you want to continue?</div>
        <!-- 23/6/2022 -->
        <div  *ngIf="popupMessage === 'generateDocs'">
            <div class="popup-box-heading">Send Generate Doc </div>
            <div class="popup-box-scroll">
                <div class="generate-popup-radio">
                    <div class="custom__radio-button"  *ngFor="let register of labRegisterDoc">
                        <input type="radio" id="{{register.labRegisterId}}" name="generate" (click)="labGenerateSelectedValue(register)">
                        <label for="{{register.labRegisterId}}" class="radio-label"><span class="icon--styles">
                        </span>{{register.documentName}}</label>
                    </div>
                    
                </div>
                <!-- date and amount fileds -->
                <div class="disp_flex" *ngIf="showDatePriceFields" >
                <form [formGroup]="paymentReciepts">
                    <div *ngFor="let i of counter();let id=index" class="form-field">
                            <div class="form-item">
                                <div class="input__lable" *ngIf="id == 0">
                                    Date
                                </div>
                                <div class="input__value" id="asdfgh">
                                    <p-calendar dateFormat="(dd M yy)" dateFormat="mm/dd/yy" readonlyInput=true [dataType]="'date'"
                                        placeholder="Please select date" [formControlName]="'date'+(id+1)" (onSelect)="dateformatter();">
                                    </p-calendar>
                                </div>
                                <div *ngIf="!paymentReciepts.get('date'+(id+1)).value && formSubmit"
                                    class="invalid-feedback">
                                    <div>Please enter date</div>
                                </div>
                            </div>
                            <div class="form-item">
                                <div class="input__lable" *ngIf="id == 0">
                                    Copay Amount
                                </div>
                                <div class="input__value price_input">
                                    <input placeholder="0" type="number" [formControlName]="'amount'+(id+1)">
                                </div>
                                <div *ngIf="!paymentReciepts.get('amount'+(id+1)).value && formSubmit"
                                    class="invalid-feedback">
                                    <div>Please enter amount</div>
                                </div>
                            </div>
                            <div class="form-item">
                                <div class="input__lable" *ngIf="id == 0">
                                    CPT code
                                </div>
                                <div class="input__value">
                                    <input placeholder="0" type="number" [formControlName]="'code'+(id+1)">
                                </div>
                            </div>
                            <div class="close-icon" *ngIf="id >= 1">
                                <app-svg [name]="'plus-circle'" (click)="remove(id+1)"></app-svg>
                            </div>
                    </div>
                    <div class="addnew__container" [class.disable]="counter().length > 4">
                            <app-svg [name]="'plus-circle'" (click)="addNew()"></app-svg>
                            <div class="description">Add new</div>
                        </div>
                </form>
                </div>
                <!-- date and amount fileds -->

                <div *ngIf="showPdf" class="generate-doc-selected-file" >
                    <div class="document-section" *ngFor="let value of labRegisterDoc">
                        <div *ngIf="value.isSelected" class="generate-doc-sec">
                            {{value.documentName ? value.documentName : 'N.A.'}}
                            <div class="patient__id-document-actions" >
                                <a (click)="viewFile(value.documentId)"   target="_blank" title="View" class="view-icon">
                                    <app-svg [name]="'view-upload'"></app-svg>
                                    
                                </a>
                                <!-- <p class="down-arrow" (click)="donwloadGenFile(value)"  title="Download">
                                    <app-svg [name]="'down-arrow'"></app-svg>
                                </p> -->
                                <a (click)="editFile(value.documentId)" target="_blank" title="Edit" class="edit-icon">
                                    <app-svg [name]="'edit-new'"></app-svg>
                                    
                                </a>
                                <!-- <a class="edit-icon"  title="Edit">
                                    <app-svg title="Edit" [name]="'edit-new'"></app-svg>
                                </a> -->
                            </div>
                        </div>
                    
                    </div>
                </div>
            </div>
        </div>

        <!-- 23/6/2022 -->
        <div class="confirm-message" *ngIf="!(popupMessage === 'unblock' || popupMessage === 'archive' || popupMessage === 'generateDocs' || popupMessage === 'generateMednotes' || popupMessage === 'typeOfDiagonsis' || popupMessage === 'appointmentListMM' || popupMessage === 'MednoteList' || popupMessage === 'noTmsNote' || popupMessage === 'tmsNote' || popupMessage === 'DeleteTmsNote' || popupMessage === 'DeleteMedNote' )">Are you want to {{popupMessage}} this patient? yes</div>
        <div class="confirm-message" *ngIf="popupMessage === 'unblock' && blockedServices.length > 0 && blockedServices.length !== treatmentsCount+1">
            <p>This patient has been blocked below mentioned treatments</p>
            <ul>
                <li *ngFor="let treatment of blockedServices">{{treatment.name}}</li>
            </ul>
        </div>
        <div class="confirm-message" *ngIf="popupMessage === 'unblock' && blockedServices.length === treatmentsCount+1">
            <p>This patient has been blocked for all the treatments. <br>Please unblock minimum 1 treatment to continue booking an appointment.</p>
        </div>
        <!-- 23/6/2022 -->
        <div class="btn-actions generate-bottom-btns" *ngIf="popupMessage == 'generateDocs'">
            <div class="cancel-btn" (click)="cancelPopupShowHideStatusFun($event,false)">Cancel</div>
            <div class="common-btn-style"
                (click)="postGenerateRequestId()">
                {{btnSubmitText}}
            </div>
         
        </div>
        <!-- 23/6/2022 -->
        <div class="btn-actions" *ngIf="popupMessage !== 'unblock' && popupMessage !== 'generateDocs' && popupMessage !== 'generateMednotes' && popupMessage !== 'typeOfDiagonsis' && popupMessage !== 'appointmentListMM' && popupMessage !== 'MednoteList' && popupMessage !== 'noTmsNote' && popupMessage !== 'tmsNote' && popupMessage !== 'DeleteTmsNote' && popupMessage !== 'DeleteMedNote' ">
            <div class="common-btn-style yes-btn" (click)="cancelPopupShowHideStatusFun($event,false)">No</div>
            <div class="common-btn-style"
                (click)="cancelPopupShowHideStatusFun($event,true);cancelPopupShowHide = false">Yes</div>
        </div>
        <div class="btn-actions" *ngIf="popupMessage === 'unblock' && blockedServices.length > 0 && blockedServices.length !== treatmentsCount+1">
            <div class="common-btn-style yes-btn" (click)="cancelPopupShowHideStatusFun($event,false)">Cancel</div>
            <div class="common-btn-style"
                (click)="cancelPopupShowHideStatusFun($event,true);cancelPopupShowHide = false">Proceed</div>
        </div>
        <div class="btn-actions" *ngIf="popupMessage === 'unblock' && blockedServices.length === treatmentsCount+1">
            <div class="common-btn-style yes-btn" (click)="cancelPopupShowHideStatusFun($event,false)">Cancel</div>
            <div class="common-btn-style"
                (click)="cancelPopupShowHideStatusFun($event,true);cancelPopupShowHide = false">Unblock</div>
        </div>

        <!-- mednotes -->

        <div *ngIf="popupMessage === 'appointmentListMM'" >
            <div class="popup-box-heading" *ngIf="apptRecordMsg.length === 0 && !(apptRecordMsg.length === 0) " > Generate Mednotes </div>

            <ng-container *ngIf="apptArrayType === 'array'">
                <div class="popup-box-subheading appt" > Choose Appointment </div>
                <div class="app-list-container">
                    <ng-container *ngFor="let appt of apptArray; let i=index"  >
                        <div class="popup-box-apptList" (click)="mednote(appt)" >
                            <!-- treatment type -->
                            <div class="popup-box-apptList-item treatment">
                                <app-svg class="apptList-item__icon" [name]="'treatement-orange'"></app-svg>
                                <div class="apptList-item__text">{{appt.appointmentType.appointmentTypeName}}</div>
                            </div>
                            <!-- appointment location -->
                            <div class="popup-box-apptList-item">
                                    <app-svg *ngIf="appt.location.locationType === 'online'" class="apptList-item__icon" [name]="'online-video_icon'"></app-svg>
                                    <app-svg *ngIf="appt.location.locationType === 'offline'" class="apptList-item__icon" [name]="'home'"></app-svg>
                                    <div class="apptList-item__text">{{appt.location.locationName}}</div>
                            </div>
                            <!-- appointment date -->
                            <div class="popup-box-apptList-item">
                                <app-svg class="apptList-item__icon" [name]="'calendar-color'"></app-svg>
                                <div class="apptList-item__text">{{appt.appointmentDate | dateFormat }}</div>
                            </div>
                            <!-- appointment time -->
                            <div class="popup-box-apptList-item apptTime">
                                <app-svg class="apptList-item__icon" [name]="'clock-color'"></app-svg>
                                <div class="apptList-item__text">{{appt.appointmentTime | customTime}}</div>
                            </div>
                            <!-- appointment status -->
                            <div class="popup-box-apptList-item">
                                <app-svg class="apptList-item__icon" [name]="'reschedule'"></app-svg>
                                <div class="apptList-item__text">{{appt.apptStatus }}</div>
                            </div>
                        </div>
                    </ng-container>
                </div>
               
            </ng-container>
            
            <div *ngIf="apptArrayType === 'object'" class="confirm-message">
                There is no {{apptRecordMsg}}appointment record for this patient
            </div>
        </div>

        <div *ngIf="popupMessage === 'MednoteList'" >
            <div class="popup-box-heading"  > Edit Mednote </div>

            <ng-container *ngIf="showMednoteList" >
                <div class="popup-box-subheading appt" > Choose Mednote </div>
                <div class="app-list-mednote-container">
                <ng-container *ngFor="let mednote of previousMednoteList; let i=index" >
                    <div class="popup-box-apptList"  >
                        <!-- Diagonsis type -->
                        <div class="popup-box-apptList-item treatment">
                            <app-svg class="apptList-item__icon" [name]="'treatement-orange'"></app-svg>
                            <div class="apptList-item__text">{{mednote.diagnosis}}</div>
                        </div>
                        <!-- mednote type -->
                        <div class="popup-box-apptList-item">
                                <app-svg class="apptList-item__icon" [name]="'home'"></app-svg>
                                <div class="apptList-item__text" *ngIf="mednote.mednoteType === 'MEDNOTE25' || mednote.mednoteType === 'MEDNOTE45'"> Monthly Medication Note</div>
                                <div class="apptList-item__text" *ngIf="mednote.mednoteType === 'EVALUATION'"> Evaluation Note </div>
                        </div>
                        <!-- mednote duration -->
                        <div class="popup-box-apptList-item">
                            <app-svg class="apptList-item__icon" [name]="'clock-color'"></app-svg>
                            <div class="apptList-item__text"  *ngIf="mednote.mednoteType === 'MEDNOTE25'"> 25 Minutes</div>
                            <div class="apptList-item__text"  *ngIf="mednote.mednoteType === 'MEDNOTE45'"> 45 Minutes</div>
                            <div class="apptList-item__text"  *ngIf="mednote.mednoteType === 'EVALUATION'"> EVALUATION</div>
                        </div>
                        <!-- creation date -->
                        <div class="popup-box-apptList-item apptTime">
                            <app-svg class="apptList-item__icon" [name]="'calendar-color'"></app-svg>
                            <div class="apptList-item__text">{{mednote.createTime }}</div>
                        </div>
                        <!-- Employee name -->
                        <div class="popup-box-apptList-item treatment">
                            <app-svg class="apptList-item__icon" [name]="'employees-color'"></app-svg>
                            <div class="apptList-item__text" > {{(mednote.empName) ? mednote.empName : '-' }} </div>
                        </div> 
                        <!-- Edit -->
                        <div class="patient__list-item icons">
                            <div class="edit popupBox-icons"><app-svg class="patients__icon" title="Edit" [name]="'edit'" (click)="editPerviousMednote(mednote)"></app-svg></div>
                        </div>
                        <!-- Delete -->
                        <div class="patient__list-item icons">
                            <div class="edit popupBox-icons"><app-svg class="patients__icon" title="Delete" [name]="'delete'" (click)="showDeleteMedNotePopup(mednote)"></app-svg></div>
                        </div>
                    </div>
                </ng-container>
            </div>
            </ng-container>

            <div *ngIf="previousMednoteList.length=== 0" class="confirm-message">
                {{MednoteRecordMsg}}
            </div>
        </div>
        <div *ngIf="popupMessage === 'generateMednotes'"> 
            <div class="popup-box-heading" > Generate Mednotes </div>
            <div class="mednote-popup-radio">
                <div class="custom__radio-button"  *ngFor="let mednote of mednotesType">
                    <input type="radio" id="{{mednote.id}}" name="mednote" (click)="generateMednotes(mednote)" >
                    <label for="{{mednote.id}}" class="radio-label"> <span class="icon--styles"></span> {{mednote.name}} </label>
                </div>
            </div>
        </div>

        <div *ngIf="popupMessage === 'typeOfDiagonsis' ">
            <div class="mednote-box-heading"> Generate Mednotes </div>
            <div class="Mednote-type-heading" > For {{mednoteTypePopup}}</div>
            <!-- Diagnosis -->
            <div class="popup-box-subheading" > Choose Diagnosis </div>
            <div class="mednote-popup-radio">
                <div class="custom__radio-button"  *ngFor="let diagnosis of diagonsisType">
                    <input type="radio" id="{{diagnosis.id}}" name="diagnosis" value="diagnosis.name" (click)="getDiagonsis(diagnosis)">
                    <label for="{{diagnosis.id}}" class="radio-label"> <span class="icon--styles"></span> {{diagnosis.name}} </label>
                </div>
                <div class="err-container"> <span *ngIf="diagnosisErr" class="err-message" >Please select diagnosis type</span></div>
            </div>
            <!-- Duration of Visit -->
            <div class="popup-box-subheading" *ngIf="popUpDuration"> Choose Duration of Visit</div>
            <div *ngIf="popUpDuration" class="mednote-popup-radio">
                <div class="custom__radio-button"  *ngFor="let duration of durationOfVisit">
                    <input type="radio" id="{{duration.id}}" name="duration" value="duration.name" (click)="getDuration(duration)">
                    <label for="{{duration.id}}" class="radio-label"> <span class="icon--styles"></span> {{duration.name}} </label>
                </div>
                <div class="err-container"> <span *ngIf="durationErr" class="err-message" >Please select duration of visit</span></div>
            </div>
            <!-- Evalution Type -->
            <div class="popup-box-subheading" *ngIf="popUpEvalutionType" > Choose Evalution Type</div>
            <div *ngIf="popUpEvalutionType" class="mednote-popup-radio">
                <div class="custom__radio-button"  *ngFor="let eval of evalType">
                    <input type="radio" id="{{eval.id}}" name="duration" value="eval.name" (click)="getEvalType(eval)">
                    <label for="{{eval.id}}" class="radio-label"> <span class="icon--styles"></span> {{eval.name}}</label>
                </div>
                <div class="err-container"> <span *ngIf="evalTypeErr" class="err-message" >Please select type of evaluation </span></div>
            </div>

            <div class="btn-actions" *ngIf="popupMessage === 'typeOfDiagonsis'">
                <div class="common-btn-style yes-btn" (click)="cancelPopupShowHideStatusFun($event,false)" > Cancel </div>
                <div class="common-btn-style yes-btn" *ngIf="totalMedNotes" (click)="notesList()" > Edit </div>
                <div *ngIf="!totalMedNotes"  class="common-btn-style" (click)="createNewMednote()"> Create New</div>

            </div>
        </div>

        <div *ngIf="popupMessage === 'DeleteMedNote'">
            <div class="confirm-message" *ngIf="deleteNoteData.mednoteType === 'MEDNOTE45' || deleteNoteData.mednoteType === 'MEDNOTE25'">
                Do you want to delete monthly medication management {{deleteNoteData.mednoteType.slice(-2)}} minutes note for this patient ?
            </div>
            <div class="confirm-message" *ngIf="deleteNoteData.mednoteType === 'EVALUATION' ">
                Do you want to delete evaluation note for this patient ?
            </div>
            <div class="btn-actions">
                <div  class="common-btn-style" (click)="deleteMednote(deleteNoteData)"> Yes </div>
            </div> 
        </div>

        <!-- mednotes -->

        <!-- tms note-->
        <div *ngIf="popupMessage === 'noTmsNote'" >
            <div class="confirm-message" >
                <p>There is no record of TMS notes for this patient. Do you want to create TMS note ? </p>
            </div>
            <div class="btn-actions">
                <div  class="common-btn-style" (click)="createNewTmsNote()"> Create New TMS Notes</div>
            </div>        
        </div>
        
        <div *ngIf="popupMessage === 'tmsNote'" >
            <div class="popup-box-heading" >
                TMS Notes List
            </div>
            <div class="app-list-container">
                <ng-container *ngFor="let note of tmsNotes; let i=index" >
                    <div class="popup-box-apptList"  ><!-- (click)="editTmsnote(note)" -->
                        
                        <!-- <div class="popup-box-apptList-item treatment">
                            <app-svg class="apptList-item__icon" [name]="'home'" ></app-svg>
                            <div class="apptList-item__text">{{note.IsTmsNumber}}</div>
                        </div> -->
                        <!-- tms type -->
                        <div class="popup-box-apptList-item treatment">
                                <app-svg class="apptList-item__icon" [name]="'treatement-orange'"></app-svg>
                                <div class="apptList-item__text" > {{note.tmsType}} </div>
                        </div>
                        <!-- date of appt -->
                        <div class="popup-box-apptList-item apptTime">
                            <app-svg class="apptList-item__icon" [name]="'calendar-color'"></app-svg>
                            <div class="apptList-item__text"> {{note.appointmentDate | dateFormat }}</div>
                        </div>
                        <!-- Employee id -->
                        <div class="popup-box-apptList-item treatment">
                            <app-svg class="apptList-item__icon" [name]="'employees-color'"></app-svg>
                            <div class="apptList-item__text" > {{(note.empName) ? note.empName : '-' }} </div>
                        </div>
                        <!-- Edit -->
                        <div class="patient__list-item icons">
                            <div class="edit popupBox-icons"><app-svg class="patients__icon" title="Edit" [name]="'edit'" (click)="editTmsnote(note)"></app-svg></div>
                        </div>
                        <!-- Delete -->
                        <div class="patient__list-item icons">
                            <div class="edit popupBox-icons"><app-svg class="patients__icon" title="Delete" [name]="'delete'" (click)="showDeleteNotePopup(note)"></app-svg></div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="btn-actions">
                <div  class="common-btn-style" (click)="createNewTmsNote()"> Create New TMS Notes</div>
            </div>  
        </div>

        <div *ngIf="popupMessage === 'DeleteTmsNote'">
            <div class="confirm-message" >
                Do you want to delete {{deleteNoteData.tmsType.toLowerCase()}} note for this patient ?
            </div>
            <div class="btn-actions">
                <div  class="common-btn-style" (click)="deleteTmsnote(deleteNoteData)"> Yes </div>
            </div> 
        </div>
        <!-- tms note-->
    </div>
</app-common-popup>

