<div class="profile-component">
    <img class="onbaord-form-bg" alt="" src="assets/images/pngs/onboard-card-bg.png" />
    <div class="profile-wrapper">
        <div class="profile-main-section">
            <div class="user-and-edit">
                <div class="account-user-name">Point of contact</div>
            </div>
            <div class="poc_username_section">
                <div class="label">Name</div>
                <div class="patient__advanced-content-data">{{data.firstName}} {{data.lastName}}</div>
            </div>
            <div class="edit-fields-form">

                <div class="note">A verification email will be sent to the below id. </div>
                <app-custom-form [formData]="editform" [checkFormInfo]="saveFormData ? resetSaveFormParity() : false"
                    (submitEvent)="getFormData($event)"></app-custom-form>
            </div>
            <div class="btn-actions">
                <div class="cancel-btn" (click)="navigateBack($event)">Cancel</div>
                <div class="common-btn-style btn--inline" (click)="saveForm()">Save</div>
            </div>
        </div>
    </div>
</div>