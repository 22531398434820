<section class="card_details_container">
  <div class="breadcrumb">
    <div (click)=" navigateHome()" class="HomeBreadCrumb" > Home > </div>
    <div (click)=" navigateTreatment()" class="HomeBreadCrumb">Treatments > </div>
    {{heading | titlecase}}
  </div>
  <div class="divider"></div>
  <div class="container-img">
    <div>
      <img class="imageBack" src="assets/images/jpgs/background.jpg" alt="each-treatment-2" />
    </div>
    <img class="image" src={{img}} alt="each-treatment-1" />
    <div [ngStyle]="{borderColor:themeColor}" class="img-bottom"></div>
  </div>
  <!-- <div style='background-image: url("assets/images/background.jpg");' class="container-Img">
    <div>
      <img class="image" src={{img}} alt="each-treatment-1" />
      <div [ngStyle]="{borderColor:themeColor}" class="img-bottom"></div>
    </div>
  </div> -->
  <div class="container_inside">
    <div>
      <div [ngStyle]="{color:themeColor}" class="text">{{heading}}</div>
      <!-- ADHD -->
      <div *ngIf="id==0" class="container">
        <span class="headText">ADHD</span> is a neurodevelopmental disorder that affects people across all age groups. Symptoms of ADHD can be
        bucketed into 3 categories: inattention, hyperactivity, and impulsivity. These symptoms cause complications in
        focus, prioritization, thoughtfulness, and timeliness. Resultantly, many individuals with ADHD may find it
        difficult to maintain productivity at work, school, etc. However, individuals can seek treatment to alleviate
        the burden of these symptoms. 

        <div class="headText padd">Why Choose Us?</div>
        New TeleDoc offers a uniquely advantageous approach to ADHD Treatment which equips patients with an aggressive
        plan of action to address the complications of the disorder. 
        <div>
          <ul style="list-style-type: disc" class="cont">
            <li><span class="headText">Same-day treatment,</span> 7 days a week</li>
            <li>
              We offer a <span class="headText"> Holistic ADHD Treatment</span> approach that incorporates an optimal
              mix of services
            </li>
            <li><span class="headText"> Personalized treatment plans</span> tailored for each patient </li>
            <li>
              Highly specialized and<span class="headText"> Proprietary </span>ADHD Treatment that includes the use of
              several treatment modalities including<span class="headText"> Adderall, Testosterone, and HCG </span>
            </li>
          </ul>
        </div>
        <div class="service">
          <span class="headText"> Services Offered by New TeleDoc: </span>
          <div>
            <ul style="list-style-type: disc" class="cont">
              <li>ADHD Testing for Adults and Children</li>
              <li>Virginia ADHD Treatment/ Telehealth Washington DC ADHD Treatments </li>
              <li>Drug Testing Services</li>
              <li>ADHD Medication Management</li>
              <li>Education on Best Practices</li>
              <li>TMS Services</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- Testosterone -->
      <div *ngIf="id==1" class="container">
        <span class="headText">Low Testosterone </span>is a condition in which the blood levels of testosterone are below expected benchmarks. Among males, low testosterone can lead to a plethora of symptoms, such as low sex drive, erectile dysfunction (ED) problems, reduced muscle mass, fatigue, and emotional 
        irregularities. Similarly, women face significant hormonal issues, specifically with production of <span class="headText">progesterone</span>, which can impact one’s fertility. 

        <div class="headText padd">Why Choose Us?</div>
        At New TeleDoc, we understand that mental health issues associated with hormonal imbalances have a significant psychological impact on individuals, such as erectile dysfunction, andropause, pre-menopause, menopause, and post-menopause. New TeleDoc offers a solution to individuals facing symptoms of hormonal imbalance that stands-out from other mental health clinics.
        <div>
          <ul style="list-style-type: disc" class="cont">
            <li>Swift and Accurate Diagnosis for Patients </li>
            <li>
              Focus on improvement of energy, ADHD symptoms, and personal motivation

            </li>
            <li>Dr. Prayaga proactively addresses patient side effects associated with hormonal imbalance</li>
            <li>
              Proprietary Use of Injections as a Treatment Modality
            </li>
          </ul>
        </div>
        <div class="service">
          <span class="headText"> Services Offered by New TeleDoc: </span>
          <div>
            <ul style="list-style-type: disc" class="cont">
              <li>Testosterone Injections</li>
              <li>HCG Injections (also used to induce sustained efficacy of Testosterone)</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- Marijuana-->
      <div *ngIf="id==2" class="container">
        <span class="headText">Medical Marijuana</span> uses the marijuana plant or the chemicals within it to treat diseases or conditions. The marijuana plant contains more than 100 different chemicals called cannabinoids. Each one has a different effect on the body. Delta-9- Tetrahydrocannabinol (THC) and cannabidiol (CBD) are the main chemicals used in medicine. THC also produces the “high” people feel when they smoke marijuana or eat foods containing it. 


        <div class="headText padd">Why Choose Us?</div>
        Rejecting the traditionalist view of rehabilitative psychiatric treatment, New Teledoc openly embraces the new-age treatment applications of medical marijuana. Our clinic offers a robust patient-friendly approach to Medical Marijuana treatments.
        <div>
          <ul style="list-style-type: disc" class="cont">
            <li>Rapid Diagnosis Turn-around Times</li>
            <li>
              Judgement-Free Testing Process
            </li>
            <li>Same-Day Appointment Scheduling</li>
          </ul>
        </div>
        <div class="service">
          <span class="headText"> Services Offered by New TeleDoc: </span>
          <div>
            <ul style="list-style-type: disc" class="cont">
              <li>Medical Marijuana Evaluation and Certification </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- Ketamine -->
      <div *ngIf="id==3" class="container">
        <span class="headText">Ketamine Therapy</span> incorporates the use of the versatile drug ketamine, as the name implies. The drug can be administered in various ways for clinical use: orally, topically, through intramuscular injection, or intravenously. Ketamine has proven to be effective as an analgesic. Furthermore, it can be used to treat psychological disorders such as <span class="headText">PTSD, Anxiety, and Depression.</span> Ketamine’s effectiveness is derived from, in part, its beneficial pharmacological properties. These include stimulation of the cardiovascular system, airway relaxation, and catalepsy (a trancelike state with diminished responsiveness). 

        <div class="headText padd">Why Choose Us?</div>
        Objectively, there are a scarce number of clinics within the Washington DC/Virginia area that are certified and appropriately trained to diagnose and administer “psychedelic” treatments. New TeleDoc is not only able to offer ketamine treatments to our patient base but provides a world-class ketamine therapy program rivaling some of the premier clinics nation-wide. 
        <div>
          <ul style="list-style-type: disc" class="cont">
            <li><span class="headText">Proprietary</span> and <span class="headText">Pain-Free</span> Ketamine Treatment</li>
            <li>
              <span class="headText">Fast-Acting</span> Treatment; Soothing Effects within 5 Minutes

            </li>
            <li>Allows for Improved <span class="headText">Brain Nerve Factor</span>/ (BNF) to support Neurogenesis</li>
  
          </ul>
        </div>
        <div class="service">
          <span class="headText"> Services Offered by New TeleDoc: </span>
          <div>
            <ul style="list-style-type: disc" class="cont">
              <li>Post-Traumatic Stress Disorder (PTSD) Recovery</li>
              <li>Alcohol Withdrawal Treatment</li>
              <li>Suboxone Withdrawal Treatment</li>
              <li>Smoking Cessation Treatment</li>
            </ul>
          </div>
        </div>
      </div>
      <!--Anti-Aging-->
      <div *ngIf="id==4" class="container">
        <span class="headText">Aging</span> affects not just our physical well-being but our mental health as well. From a physical standpoint, aging can lead to decreased physical strength/mobility, chronic disease, sensory changes, and changes in sleep patterns for men and women. The mental consequences that older men/women face can also be severe: ranging from minor social isolation to extreme cognitive decline. 

        <div class="headText padd">Why Choose Us?</div>
        We all want to be able to perform at our peak states at any given time in life. At New TeleDoc we help you gain your peak condition back by using modern and cutting-edge therapies.
        <div>
          <ul style="list-style-type: disc" class="cont">
            <li><span class="headText">Quick Diagnosis</span> Turnaround Time </li>
            <li>
              <span class="headText">Proprietary</span> Usage of Reverse-Aging Medication

            </li>
            <li><span class="headText">Bariatric Certification</span> for Weight Loss</li>
            <li><span class="headText">Modern-Day Perspective</span> on Hormonal Medicines</li>
          </ul>
        </div>
        <div class="service">
          <span class="headText"> Services Offered by New TeleDoc: </span>
          <div>
            <ul style="list-style-type: disc" class="cont">
              <li>NAD+ Injections</li>
              <li>Weight Loss Injections</li>
              <ul style="list-style-type: circle" class="cont">
                <li>Semaglutide Injections</li>
                <li>Tirzepatide Injections</li>
              </ul>
              <li>B-12 Injections</li>
              <li>Testosterone/HCG Treatment</li>
              <li>Naltrexone Treatment</li>
              <li>Smoking Cessation Treatment</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div >
      <div class="location-container">
        <img class="location-width" src="assets/images/jpgs/location.jpg" />
        <div class="location-text" *ngIf="id==2">Virginia/Maryland</div>
        <div class="location-text" *ngIf="id==3">Virginia/Washington DC</div>
        <div class="location-text" *ngIf="id==4">Virginia/Washington DC</div>
        <div class="location-text" *ngIf="id==1">Virginia/Washington DC</div>
        <div class="location-text" *ngIf="id==0">Virginia/Washington DC</div>
      </div>
      <div class="common-btn-style1">
        <div (click)="navigateBookingpage()">Book Now</div>
    </div>
    </div>
  </div>

  <div class="book__now-block">
    <div class="orange-heading">Start your online consultation with us</div>
    <div class="common-btn-style">
        <div (click)="navigateBookingpage()">Book Now</div>
    </div>
</div>
</section>
