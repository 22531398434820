// Appointment List Actions
export const appointmentActionsList = {
  telemedicine: {
    pending_payment: [
      {
        label: 'Resend Invoice', permissionName: 'INVOICE_EDIT',
        iconName: 'cancel',
        id: 'resend_invoice',
        colorIconName: 'generate-invoice-color'
      },
      {
        label: 'Invoice Details', permissionName: 'INVOICE_DETAILS',
        id: 'invoice_details',
        iconName: 'Invoice-slick',
        colorIconName: 'Invoice-color'
      },
      {
        label: 'Appointment details', permissionName: 'APPOINTMENT_DETAILS',
        iconName: 'patient-details',
        id: 'appointment_details',
        colorIconName: 'patient-details-color'

      },
      {
        label: 'Reschedule Appointment', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'cancel-appointment-normal',
        id: 'reschedule_appointment',
        colorIconName: 'mixedReschedule'
      },
      {
        label: 'Mark Absent', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'mark-absent',
        id: 'mark_absent',
        colorIconName: 'mark-absent-color'
      },
      {
        label: 'Cancel Appointment', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'cancel-appointment-normal',
        id: 'cancel_appointment',
        colorIconName: 'cancel-appointment-color'
      },
      {
        label: 'Block Patient', permissionName: 'PATIENT_EDIT',
        iconName: 'block-patient',
        id: 'block_patient',
        colorIconName: 'block-patient-color'
      },
      {
        label: 'Patient Details', permissionName: 'PATIENT_DETAILS',
        iconName: 'patient-details',
        id: 'patient_details',
        colorIconName: 'patientDetails'
      }
    ],
    confirmed: [
      {
        label: 'Send Reminder', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'remainder',
        id: 'send_reminder',
        colorIconName: 'sendReminder'
      },
      {
        label: 'Appointment details', permissionName: 'APPOINTMENT_DETAILS',
        iconName: 'patient-details',
        id: 'appointment_details',
        colorIconName: 'patient-details-color'
      },
      {
        label: 'Reschedule Appointment', permissionName: 'APPOINTMENT_EDIT',
        iconName: '',
        id: 'reschedule_appointment',
        colorIconName: 'mixedReschedule'
      },
      {
        label: 'Cancel Appointment', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'cancel-appointment-normal',
        id: 'cancel_appointment',
        colorIconName: 'cancel-appointment-color'
      },
      {
        label: 'Mark Absent', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'mark-absent',
        id: 'mark_absent',
        colorIconName: 'mark-absent-color'
      },
      {
        label: 'Block Patient', permissionName: 'PATIENT_EDIT',
        iconName: 'block-patient',
        id: 'block_patient',
        colorIconName: 'block-patient-color'
      },
      {
        label: 'Patient Details', permissionName: 'PATIENT_DETAILS',
        iconName: 'patient-details',
        id: 'patient_details',
        colorIconName: 'patientDetails'
      }
    ],
    pending: [
      {
        label: 'Send Reminder', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'remainder',
        id: 'send_reminder',
        colorIconName: 'sendReminder'
      },
      {
        label: 'Appointment details', permissionName: 'APPOINTMENT_DETAILS',
        iconName: 'patient-details',
        id: 'appointment_details',
        colorIconName: 'patient-details-color'
      },
      {
        label: 'Reschedule Appointment', permissionName: 'APPOINTMENT_EDIT',
        iconName: '',
        id: 'reschedule_appointment',
        colorIconName: 'mixedReschedule'
      },
      {
        label: 'Cancel Appointment', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'cancel-appointment-normal',
        id: 'cancel_appointment',
        colorIconName: 'cancel-appointment-color'
      },
      {
        label: 'Mark Absent', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'mark-absent',
        id: 'mark_absent',
        colorIconName: 'mark-absent-color'
      },
      {
        label: 'Block Patient', permissionName: 'PATIENT_EDIT',
        iconName: 'block-patient',
        id: 'block_patient',
        colorIconName: 'block-patient-color'
      },
      {
        label: 'Patient Details', permissionName: 'PATIENT_DETAILS',
        iconName: 'patient-details',
        id: 'patient_details',
        colorIconName: 'patientDetails'
      }
    ],
    appointment_completed: [
      {
        label: 'Invoice Details', permissionName: 'INVOICE_DETAILS',
        id: 'invoice_details',
        iconName: 'Invoice-slick',
        colorIconName: 'Invoice-color'
      },
      {
        label: 'Appointment details', permissionName: 'APPOINTMENT_DETAILS',
        iconName: 'patient-details',
        id: 'appointment_details',
        colorIconName: 'patient-details-color'
      },

      {
        label: 'Block Patient', permissionName: 'PATIENT_EDIT',
        iconName: 'block-patient',
        id: 'block_patient',
        colorIconName: 'block-patient-color'
      },
      {
        label: 'Patient Details', permissionName: 'PATIENT_DETAILS',
        iconName: 'patient-details',
        id: 'patient_details',
        colorIconName: 'patientDetails'
      }
    ],
    mark_absent: [
      {
        label: 'Appointment details', permissionName: 'APPOINTMENT_DETAILS',
        iconName: 'patient-details',
        id: 'appointment_details',
        colorIconName: 'patient-details-color'
      },
      {
        label: 'Block Patient', permissionName: 'PATIENT_EDIT',
        iconName: 'block-patient',
        id: 'block_patient',
        colorIconName: 'block-patient-color'
      },
      {
        label: 'Patient Details', permissionName: 'PATIENT_DETAILS',
        iconName: 'patient-details',
        id: 'patient_details',
        colorIconName: 'patientDetails'
      }
    ],
    cancel_appointment: [
      {
        label: 'Block Patient', permissionName: 'PATIENT_EDIT',
        iconName: 'block-patient',
        id: 'block_patient',
        colorIconName: 'block-patient-color'
      },
      {
        label: 'Patient Details', permissionName: 'PATIENT_DETAILS',
        iconName: 'patient-details',
        id: 'patient_details',
        colorIconName: 'patientDetails'
      }
    ]
  },
  medManagementInOffice: {
    pending_payment: [
      {
        label: 'Resend Invoice', permissionName: 'INVOICE_EDIT',
        iconName: 'cancel',
        id: 'resend_invoice',
        colorIconName: 'generate-invoice-color'
      },
      {
        label: 'Invoice Details', permissionName: 'INVOICE_DETAILS',
        id: 'invoice_details',
        iconName: 'Invoice-slick',
        colorIconName: 'Invoice-color'
      },
      {
        label: 'Appointment details', permissionName: 'APPOINTMENT_DETAILS',
        iconName: 'patient-details',
        id: 'appointment_details',
        colorIconName: 'patient-details-color'

      },
      {
        label: 'Reschedule Appointment', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'cancel-appointment-normal',
        id: 'reschedule_appointment',
        colorIconName: 'mixedReschedule'
      },
      {
        label: 'Mark Absent', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'mark-absent',
        id: 'mark_absent',
        colorIconName: 'mark-absent-color'
      },
      {
        label: 'Cancel Appointment', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'cancel-appointment-normal',
        id: 'cancel_appointment',
        colorIconName: 'cancel-appointment-color'
      },
      {
        label: 'Block Patient', permissionName: 'PATIENT_EDIT',
        iconName: 'block-patient',
        id: 'block_patient',
        colorIconName: 'block-patient-color'
      },
      {
        label: 'Patient Details', permissionName: 'PATIENT_DETAILS',
        iconName: 'patient-details',
        id: 'patient_details',
        colorIconName: 'patientDetails'
      }
    ],
    confirmed: [
      {
        label: 'Send Reminder', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'remainder',
        id: 'send_reminder',
        colorIconName: 'sendReminder'
      },
      {
        label: 'Appointment details', permissionName: 'APPOINTMENT_DETAILS',
        iconName: 'patient-details',
        id: 'appointment_details',
        colorIconName: 'patient-details-color'
      },
      {
        label: 'Reschedule Appointment', permissionName: 'APPOINTMENT_EDIT',
        iconName: '',
        id: 'reschedule_appointment',
        colorIconName: 'mixedReschedule'
      },
      {
        label: 'Cancel Appointment', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'cancel-appointment-normal',
        id: 'cancel_appointment',
        colorIconName: 'cancel-appointment-color'
      },
      {
        label: 'Mark Absent', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'mark-absent',
        id: 'mark_absent',
        colorIconName: 'mark-absent-color'
      },
      {
        label: 'Block Patient', permissionName: 'PATIENT_EDIT',
        iconName: 'block-patient',
        id: 'block_patient',
        colorIconName: 'block-patient-color'
      },
      {
        label: 'Patient Details', permissionName: 'PATIENT_DETAILS',
        iconName: 'patient-details',
        id: 'patient_details',
        colorIconName: 'patientDetails'
      }
    ],
    pending: [
      {
        label: 'Send Reminder', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'remainder',
        id: 'send_reminder',
        colorIconName: 'sendReminder'
      },
      {
        label: 'Appointment details', permissionName: 'APPOINTMENT_DETAILS',
        iconName: 'patient-details',
        id: 'appointment_details',
        colorIconName: 'patient-details-color'
      },
      {
        label: 'Reschedule Appointment', permissionName: 'APPOINTMENT_EDIT',
        iconName: '',
        id: 'reschedule_appointment',
        colorIconName: 'mixedReschedule'
      },
      {
        label: 'Cancel Appointment', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'cancel-appointment-normal',
        id: 'cancel_appointment',
        colorIconName: 'cancel-appointment-color'
      },
      {
        label: 'Mark Absent', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'mark-absent',
        id: 'mark_absent',
        colorIconName: 'mark-absent-color'
      },
      {
        label: 'Block Patient', permissionName: 'PATIENT_EDIT',
        iconName: 'block-patient',
        id: 'block_patient',
        colorIconName: 'block-patient-color'
      },
      {
        label: 'Patient Details', permissionName: 'PATIENT_DETAILS',
        iconName: 'patient-details',
        id: 'patient_details',
        colorIconName: 'patientDetails'
      }
    ],
    appointment_completed: [
  
      {
        label: 'Appointment details', permissionName: 'APPOINTMENT_DETAILS',
        iconName: 'patient-details',
        id: 'appointment_details',
        colorIconName: 'patient-details-color'
      },

      {
        label: 'Block Patient', permissionName: 'PATIENT_EDIT',
        iconName: 'block-patient',
        id: 'block_patient',
        colorIconName: 'block-patient-color'
      },
      {
        label: 'Patient Details', permissionName: 'PATIENT_DETAILS',
        iconName: 'patient-details',
        id: 'patient_details',
        colorIconName: 'patientDetails'
      }
    ],
    invoice_generated: [
      {
        label: 'Invoice Details', permissionName: 'INVOICE_DETAILS',
        id: 'invoice_details',
        iconName: 'Invoice-slick',
        colorIconName: 'Invoice-color'
      },
      {
        label: 'Patient Details', permissionName: 'PATIENT_DETAILS',
        iconName: 'patient-details',
        id: 'patient_details',
        colorIconName: 'patientDetails'
      },
      {
        label: 'Block Patient', permissionName: 'PATIENT_EDIT',
        iconName: 'block-patient',
        id: 'block_patient',
        colorIconName: 'block-patient-color'
      }
    ],
    mark_absent: [
      {
        label: 'Appointment details', permissionName: 'APPOINTMENT_DETAILS',
        iconName: 'patient-details',
        id: 'appointment_details',
        colorIconName: 'patient-details-color'
      },
      {
        label: 'Block Patient', permissionName: 'PATIENT_EDIT',
        iconName: 'block-patient',
        id: 'block_patient',
        colorIconName: 'block-patient-color'
      },
      {
        label: 'Patient Details', permissionName: 'PATIENT_DETAILS',
        iconName: 'patient-details',
        id: 'patient_details',
        colorIconName: 'patientDetails'
      }
    ],
    cancel_appointment: [
      {
        label: 'Block Patient', permissionName: 'PATIENT_EDIT',
        iconName: 'block-patient',
        id: 'block_patient',
        colorIconName: 'block-patient-color'
      },
      {
        label: 'Patient Details', permissionName: 'PATIENT_DETAILS',
        iconName: 'patient-details',
        id: 'patient_details',
        colorIconName: 'patientDetails'
      }
    ]
  },
  TMSInOffice: {
    pending_payment: [
      {
        label: 'Resend Invoice', permissionName: 'INVOICE_EDIT',
        iconName: 'cancel',
        id: 'resend_invoice',
        colorIconName: 'generate-invoice-color'
      },
      {
        label: 'Invoice Details', permissionName: 'INVOICE_DETAILS',
        id: 'invoice_details',
        iconName: 'Invoice-slick',
        colorIconName: 'Invoice-color'
      },
      {
        label: 'Appointment details', permissionName: 'APPOINTMENT_DETAILS',
        iconName: 'patient-details',
        id: 'appointment_details',
        colorIconName: 'patient-details-color'

      },
      {
        label: 'Reschedule Appointment', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'cancel-appointment-normal',
        id: 'reschedule_appointment',
        colorIconName: 'mixedReschedule'
      },
      {
        label: 'Mark Absent', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'mark-absent',
        id: 'mark_absent',
        colorIconName: 'mark-absent-color'
      },
      {
        label: 'Cancel Appointment', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'cancel-appointment-normal',
        id: 'cancel_appointment',
        colorIconName: 'cancel-appointment-color'
      },
      {
        label: 'Block Patient', permissionName: 'PATIENT_EDIT',
        iconName: 'block-patient',
        id: 'block_patient',
        colorIconName: 'block-patient-color'
      },
      {
        label: 'Patient Details', permissionName: 'PATIENT_DETAILS',
        iconName: 'patient-details',
        id: 'patient_details',
        colorIconName: 'patientDetails'
      }
    ],
    confirmed: [
      {
        label: 'Send Reminder', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'remainder',
        id: 'send_reminder',
        colorIconName: 'sendReminder'
      },
      {
        label: 'Appointment details', permissionName: 'APPOINTMENT_DETAILS',
        iconName: 'patient-details',
        id: 'appointment_details',
        colorIconName: 'patient-details-color'
      },
      {
        label: 'Reschedule Appointment', permissionName: 'APPOINTMENT_EDIT',
        iconName: '',
        id: 'reschedule_appointment',
        colorIconName: 'mixedReschedule'
      },
      {
        label: 'Cancel Appointment', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'cancel-appointment-normal',
        id: 'cancel_appointment',
        colorIconName: 'cancel-appointment-color'
      },
      {
        label: 'Mark Absent', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'mark-absent',
        id: 'mark_absent',
        colorIconName: 'mark-absent-color'
      },
      {
        label: 'Block Patient', permissionName: 'PATIENT_EDIT',
        iconName: 'block-patient',
        id: 'block_patient',
        colorIconName: 'block-patient-color'
      },
      {
        label: 'Patient Details', permissionName: 'PATIENT_DETAILS',
        iconName: 'patient-details',
        id: 'patient_details',
        colorIconName: 'patientDetails'
      }
    ],
    appointment_completed: [
   
      {
        label: 'Appointment details', permissionName: 'APPOINTMENT_DETAILS',
        iconName: 'patient-details',
        id: 'appointment_details',
        colorIconName: 'patient-details-color'
      },

      {
        label: 'Block Patient', permissionName: 'PATIENT_EDIT',
        iconName: 'block-patient',
        id: 'block_patient',
        colorIconName: 'block-patient-color'
      },
      {
        label: 'Patient Details', permissionName: 'PATIENT_DETAILS',
        iconName: 'patient-details',
        id: 'patient_details',
        colorIconName: 'patientDetails'
      }
    ],
    pending: [
      {
        label: 'Send Reminder', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'remainder',
        id: 'send_reminder',
        colorIconName: 'sendReminder'
      },
      {
        label: 'Generate Invoice', permissionName: 'INVOICE_EDIT',
        iconName: 'admininvoice',
        id: 'generate_invoice',
        colorIconName: 'generate-invoice-color'
      },
      {
        label: 'Appointment details', permissionName: 'APPOINTMENT_DETAILS',
        iconName: 'patient-details',
        id: 'appointment_details',
        colorIconName: 'patient-details-color'
      },
      {
        label: 'Reschedule Appointment', permissionName: 'APPOINTMENT_EDIT',
        iconName: '',
        id: 'reschedule_appointment',
        colorIconName: 'mixedReschedule'
      },
      {
        label: 'Cancel Appointment', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'cancel-appointment-normal',
        id: 'cancel_appointment',
        colorIconName: 'cancel-appointment-color'
      },
      {
        label: 'Mark Absent', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'mark-absent',
        id: 'mark_absent',
        colorIconName: 'mark-absent-color'
      },
      {
        label: 'Block Patient', permissionName: 'PATIENT_EDIT',
        iconName: 'block-patient',
        id: 'block_patient',
        colorIconName: 'block-patient-color'
      },
      {
        label: 'Patient Details', permissionName: 'PATIENT_DETAILS',
        iconName: 'patient-details',
        id: 'patient_details',
        colorIconName: 'patientDetails'
      }
    ],
    invoice_generated: [
      {
        label: 'Invoice Details', permissionName: 'INVOICE_DETAILS',
        id: 'invoice_details',
        iconName: 'Invoice-slick',
        colorIconName: 'Invoice-color'
      },
      {
        label: 'Patient Details', permissionName: 'PATIENT_DETAILS',
        iconName: 'patient-details',
        id: 'patient_details',
        colorIconName: 'patientDetails'
      },
      {
        label: 'Block Patient', permissionName: 'PATIENT_EDIT',
        iconName: 'block-patient',
        id: 'block_patient',
        colorIconName: 'block-patient-color'
      }
    ],
    mark_absent: [
      {
        label: 'Appointment details', permissionName: 'APPOINTMENT_DETAILS',
        iconName: 'patient-details',
        id: 'appointment_details',
        colorIconName: 'patient-details-color'
      },
      {
        label: 'Block Patient', permissionName: 'PATIENT_EDIT',
        iconName: 'block-patient',
        id: 'block_patient',
        colorIconName: 'block-patient-color'
      },
      {
        label: 'Patient Details', permissionName: 'PATIENT_DETAILS',
        iconName: 'patient-details',
        id: 'patient_details',
        colorIconName: 'patientDetails'
      }
    ],
    cancel_appointment: [
      {
        label: 'Block Patient', permissionName: 'PATIENT_EDIT',
        iconName: 'block-patient',
        id: 'block_patient',
        colorIconName: 'block-patient-color'
      },
      {
        label: 'Patient Details', permissionName: 'PATIENT_DETAILS',
        iconName: 'patient-details',
        id: 'patient_details',
        colorIconName: 'patientDetails'
      }
    ]
  },
  KetamineInoffice: {
    pending_payment: [
      {
        label: 'Resend Invoice', permissionName: 'INVOICE_EDIT',
        iconName: 'cancel',
        id: 'resend_invoice',
        colorIconName: 'generate-invoice-color'
      },
      {
        label: 'Invoice Details', permissionName: 'INVOICE_DETAILS',
        id: 'invoice_details',
        iconName: 'Invoice-slick',
        colorIconName: 'Invoice-color'
      },
      {
        label: 'Appointment details', permissionName: 'APPOINTMENT_DETAILS',
        iconName: 'patient-details',
        id: 'appointment_details',
        colorIconName: 'patient-details-color'

      },
      {
        label: 'Reschedule Appointment', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'cancel-appointment-normal',
        id: 'reschedule_appointment',
        colorIconName: 'mixedReschedule'
      },
      {
        label: 'Mark Absent', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'mark-absent',
        id: 'mark_absent',
        colorIconName: 'mark-absent-color'
      },
      {
        label: 'Cancel Appointment', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'cancel-appointment-normal',
        id: 'cancel_appointment',
        colorIconName: 'cancel-appointment-color'
      },
      {
        label: 'Block Patient', permissionName: 'PATIENT_EDIT',
        iconName: 'block-patient',
        id: 'block_patient',
        colorIconName: 'block-patient-color'
      },
      {
        label: 'Patient Details', permissionName: 'PATIENT_DETAILS',
        iconName: 'patient-details',
        id: 'patient_details',
        colorIconName: 'patientDetails'
      }
    ],
    confirmed: [
      {
        label: 'Send Reminder', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'remainder',
        id: 'send_reminder',
        colorIconName: 'sendReminder'
      },
      {
        label: 'Appointment details', permissionName: 'APPOINTMENT_DETAILS',
        iconName: 'patient-details',
        id: 'appointment_details',
        colorIconName: 'patient-details-color'
      },
      {
        label: 'Reschedule Appointment', permissionName: 'APPOINTMENT_EDIT',
        iconName: '',
        id: 'reschedule_appointment',
        colorIconName: 'mixedReschedule'
      },
      {
        label: 'Cancel Appointment', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'cancel-appointment-normal',
        id: 'cancel_appointment',
        colorIconName: 'cancel-appointment-color'
      },
      {
        label: 'Mark Absent', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'mark-absent',
        id: 'mark_absent',
        colorIconName: 'mark-absent-color'
      },
      {
        label: 'Block Patient', permissionName: 'PATIENT_EDIT',
        iconName: 'block-patient',
        id: 'block_patient',
        colorIconName: 'block-patient-color'
      },
      {
        label: 'Patient Details', permissionName: 'PATIENT_DETAILS',
        iconName: 'patient-details',
        id: 'patient_details',
        colorIconName: 'patientDetails'
      }
    ],
    appointment_completed: [
      // {
      //   label: 'Generate Invoice', permissionName: 'INVOICE_EDIT',
      //   iconName: 'admininvoice',
      //   id: 'generate_invoice',
      //   colorIconName: 'generate-invoice-color'
      // },
      {
        label: 'Appointment details', permissionName: 'APPOINTMENT_DETAILS',
        iconName: 'patient-details',
        id: 'appointment_details',
        colorIconName: 'patient-details-color'
      },

      {
        label: 'Block Patient', permissionName: 'PATIENT_EDIT',
        iconName: 'block-patient',
        id: 'block_patient',
        colorIconName: 'block-patient-color'
      },
      {
        label: 'Patient Details', permissionName: 'PATIENT_DETAILS',
        iconName: 'patient-details',
        id: 'patient_details',
        colorIconName: 'patientDetails'
      }
    ],
    pending: [
      {
        label: 'Send Reminder', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'remainder',
        id: 'send_reminder',
        colorIconName: 'sendReminder'
      },
      {
        label: 'Appointment details', permissionName: 'APPOINTMENT_DETAILS',
        iconName: 'patient-details',
        id: 'appointment_details',
        colorIconName: 'patient-details-color'
      },
      {
        label: 'Reschedule Appointment', permissionName: 'APPOINTMENT_EDIT',
        iconName: '',
        id: 'reschedule_appointment',
        colorIconName: 'mixedReschedule'
      },
      {
        label: 'Cancel Appointment', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'cancel-appointment-normal',
        id: 'cancel_appointment',
        colorIconName: 'cancel-appointment-color'
      },
      {
        label: 'Mark Absent', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'mark-absent',
        id: 'mark_absent',
        colorIconName: 'mark-absent-color'
      },
      {
        label: 'Block Patient', permissionName: 'PATIENT_EDIT',
        iconName: 'block-patient',
        id: 'block_patient',
        colorIconName: 'block-patient-color'
      },
      {
        label: 'Patient Details', permissionName: 'PATIENT_DETAILS',
        iconName: 'patient-details',
        id: 'patient_details',
        colorIconName: 'patientDetails'
      }
    ],
    invoice_generated: [
      {
        label: 'Invoice Details', permissionName: 'INVOICE_DETAILS',
        id: 'invoice_details',
        iconName: 'Invoice-slick',
        colorIconName: 'Invoice-color'
      },
      {
        label: 'Patient Details', permissionName: 'PATIENT_DETAILS',
        iconName: 'patient-details',
        id: 'patient_details',
        colorIconName: 'patientDetails'
      },
      {
        label: 'Block Patient', permissionName: 'PATIENT_EDIT',
        iconName: 'block-patient',
        id: 'block_patient',
        colorIconName: 'block-patient-color'
      }
    ],
    mark_absent: [
      {
        label: 'Appointment details', permissionName: 'APPOINTMENT_DETAILS',
        iconName: 'patient-details',
        id: 'appointment_details',
        colorIconName: 'patient-details-color'
      },
      {
        label: 'Block Patient', permissionName: 'PATIENT_EDIT',
        iconName: 'block-patient',
        id: 'block_patient',
        colorIconName: 'block-patient-color'
      },
      {
        label: 'Patient Details', permissionName: 'PATIENT_DETAILS',
        iconName: 'patient-details',
        id: 'patient_details',
        colorIconName: 'patientDetails'
      }
    ],
    cancel_appointment: [
      {
        label: 'Block Patient', permissionName: 'PATIENT_EDIT',
        iconName: 'block-patient',
        id: 'block_patient',
        colorIconName: 'block-patient-color'
      },
      {
        label: 'Patient Details', permissionName: 'PATIENT_DETAILS',
        iconName: 'patient-details',
        id: 'patient_details',
        colorIconName: 'patientDetails'
      }
    ]
  },
  defaultOffice: {
    pending_payment: [
      {
        label: 'Resend Invoice', permissionName: 'INVOICE_EDIT',
        iconName: 'cancel',
        id: 'resend_invoice',
        colorIconName: 'generate-invoice-color'
      },
      {
        label: 'Invoice Details', permissionName: 'INVOICE_DETAILS',
        id: 'invoice_details',
        iconName: 'Invoice-slick',
        colorIconName: 'Invoice-color'
      },
      {
        label: 'Appointment details', permissionName: 'APPOINTMENT_DETAILS',
        iconName: 'patient-details',
        id: 'appointment_details',
        colorIconName: 'patient-details-color'

      },
      {
        label: 'Reschedule Appointment', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'cancel-appointment-normal',
        id: 'reschedule_appointment',
        colorIconName: 'mixedReschedule'
      },
      {
        label: 'Mark Absent', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'mark-absent',
        id: 'mark_absent',
        colorIconName: 'mark-absent-color'
      },
      {
        label: 'Cancel Appointment', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'cancel-appointment-normal',
        id: 'cancel_appointment',
        colorIconName: 'cancel-appointment-color'
      },
      {
        label: 'Block Patient', permissionName: 'PATIENT_EDIT',
        iconName: 'block-patient',
        id: 'block_patient',
        colorIconName: 'block-patient-color'
      },
      {
        label: 'Patient Details', permissionName: 'PATIENT_DETAILS',
        iconName: 'patient-details',
        id: 'patient_details',
        colorIconName: 'patientDetails'
      }
    ],
    confirmed: [
      {
        label: 'Send Reminder', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'remainder',
        id: 'send_reminder',
        colorIconName: 'sendReminder'
      },
      {
        label: 'Appointment details', permissionName: 'APPOINTMENT_DETAILS',
        iconName: 'patient-details',
        id: 'appointment_details',
        colorIconName: 'patient-details-color'
      },
      {
        label: 'Reschedule Appointment', permissionName: 'APPOINTMENT_EDIT',
        iconName: '',
        id: 'reschedule_appointment',
        colorIconName: 'mixedReschedule'
      },
      {
        label: 'Cancel Appointment', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'cancel-appointment-normal',
        id: 'cancel_appointment',
        colorIconName: 'cancel-appointment-color'
      },
      {
        label: 'Mark Absent', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'mark-absent',
        id: 'mark_absent',
        colorIconName: 'mark-absent-color'
      },
      {
        label: 'Block Patient', permissionName: 'PATIENT_EDIT',
        iconName: 'block-patient',
        id: 'block_patient',
        colorIconName: 'block-patient-color'
      },
      {
        label: 'Patient Details', permissionName: 'PATIENT_DETAILS',
        iconName: 'patient-details',
        id: 'patient_details',
        colorIconName: 'patientDetails'
      }
    ],
    appointment_completed: [
      // {
      //   label: 'Generate Invoice', permissionName: 'INVOICE_EDIT',
      //   iconName: 'admininvoice',
      //   id: 'generate_invoice',
      //   colorIconName: 'generate-invoice-color'
      // },
      {
        label: 'Appointment details', permissionName: 'APPOINTMENT_DETAILS',
        iconName: 'patient-details',
        id: 'appointment_details',
        colorIconName: 'patient-details-color'
      },

      {
        label: 'Block Patient', permissionName: 'PATIENT_EDIT',
        iconName: 'block-patient',
        id: 'block_patient',
        colorIconName: 'block-patient-color'
      },
      {
        label: 'Patient Details', permissionName: 'PATIENT_DETAILS',
        iconName: 'patient-details',
        id: 'patient_details',
        colorIconName: 'patientDetails'
      }
    ],
    pending: [
      {
        label: 'Send Reminder', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'remainder',
        id: 'send_reminder',
        colorIconName: 'sendReminder'
      },
      {
        label: 'Appointment details', permissionName: 'APPOINTMENT_DETAILS',
        iconName: 'patient-details',
        id: 'appointment_details',
        colorIconName: 'patient-details-color'
      },
      {
        label: 'Reschedule Appointment', permissionName: 'APPOINTMENT_EDIT',
        iconName: '',
        id: 'reschedule_appointment',
        colorIconName: 'mixedReschedule'
      },
      {
        label: 'Cancel Appointment', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'cancel-appointment-normal',
        id: 'cancel_appointment',
        colorIconName: 'cancel-appointment-color'
      },
      {
        label: 'Mark Absent', permissionName: 'APPOINTMENT_EDIT',
        iconName: 'mark-absent',
        id: 'mark_absent',
        colorIconName: 'mark-absent-color'
      },
      {
        label: 'Block Patient', permissionName: 'PATIENT_EDIT',
        iconName: 'block-patient',
        id: 'block_patient',
        colorIconName: 'block-patient-color'
      },
      {
        label: 'Patient Details', permissionName: 'PATIENT_DETAILS',
        iconName: 'patient-details',
        id: 'patient_details',
        colorIconName: 'patientDetails'
      }
    ],
    invoice_generated: [
      {
        label: 'Invoice Details', permissionName: 'INVOICE_DETAILS',
        id: 'invoice_details',
        iconName: 'Invoice-slick',
        colorIconName: 'Invoice-color'
      },
      {
        label: 'Appointment details', permissionName: 'APPOINTMENT_DETAILS',
        iconName: 'patient-details',
        id: 'appointment_details',
        colorIconName: 'patient-details-color'
      },
      {
        label: 'Patient Details', permissionName: 'PATIENT_DETAILS',
        iconName: 'patient-details',
        id: 'patient_details',
        colorIconName: 'patientDetails'
      },
      {
        label: 'Block Patient', permissionName: 'PATIENT_EDIT',
        iconName: 'block-patient',
        id: 'block_patient',
        colorIconName: 'block-patient-color'
      }
    ],
    mark_absent: [
      {
        label: 'Appointment details', permissionName: 'APPOINTMENT_DETAILS',
        iconName: 'patient-details',
        id: 'appointment_details',
        colorIconName: 'patient-details-color'
      },
      {
        label: 'Block Patient', permissionName: 'PATIENT_EDIT',
        iconName: 'block-patient',
        id: 'block_patient',
        colorIconName: 'block-patient-color'
      },
      {
        label: 'Patient Details', permissionName: 'PATIENT_DETAILS',
        iconName: 'patient-details',
        id: 'patient_details',
        colorIconName: 'patientDetails'
      }
    ],
    cancel_appointment: [
      {
        label: 'Block Patient', permissionName: 'PATIENT_EDIT',
        iconName: 'block-patient',
        id: 'block_patient',
        colorIconName: 'block-patient-color'
      },
      {
        label: 'Patient Details', permissionName: 'PATIENT_DETAILS',
        iconName: 'patient-details',
        id: 'patient_details',
        colorIconName: 'patientDetails'
      }
    ]
  },
};

export function getActionsList(trementType, appointmentStatus, invoiceStatus, pageName) {
  let actionList = [];
  let treatment = getingAppointmentType(trementType);
  let status = getAppointmentStatus(appointmentStatus, invoiceStatus);
  for (const key in appointmentActionsList[treatment]) {
    if (Object.prototype.hasOwnProperty.call(appointmentActionsList[treatment], key)) {
      if (key === status) {
        actionList = appointmentActionsList[treatment][key].filter(each => each.id !== pageName);
      }
    }
  }
  return actionList;
}

function getingAppointmentType(appointmentType) {
  let treatmentType = '';
  switch (appointmentType) {
    case 'Medication Management':
      treatmentType = 'medManagementInOffice';
      break;
    case 'Ketamine Treatment':
      treatmentType = 'KetamineInoffice';
      break;
    case 'TMS':
      treatmentType = 'TMSInOffice';
      break;
    case 'Psychological Therapy':
      treatmentType = 'telemedicine';
      break;
    default:
      treatmentType = 'defaultOffice';
      break;
  }
  return treatmentType;
}
function getAppointmentStatus(appoinmnetStatus, invoiceStatus) {
  let status = '';
  if (appoinmnetStatus === 'P' && (invoiceStatus === 'ungenerated' || invoiceStatus === 'paid')) {
    status = 'pending';
  } else if (appoinmnetStatus === 'P' && invoiceStatus === 'unpaid') {
    status = 'pending_payment';
  } else if (appoinmnetStatus === 'A' && invoiceStatus === 'ungenerated') {
    status = 'confirmed';
  } else if (appoinmnetStatus === 'A' && invoiceStatus === 'unpaid') {
    status = 'pending_payment';
  } else if (appoinmnetStatus === 'A' && invoiceStatus === 'paid') {
    status = 'appointment_completed';
  } else if (appoinmnetStatus === 'B') {
    status = 'mark_absent';
  } else if (appoinmnetStatus === 'D') {
    status = 'cancel_appointment';
  }
  return status;
}

function getAppointmentStatu(appoinmnetStatus, invoiceStatus) {
  let status = '';
  switch (appoinmnetStatus) {
    case 'N':
      status = 'pending_payment';
      break;
    case 'P':
      status = 'pending';
      break;
    case 'A':
      status = 'confirmed';
      break;
    case 'C':
      status = 'appointment_completed';
      break;
    case 'B':
      status = 'mark_absent';
      break;
    case 'I':
      status = 'invoice_generated';
      break;
    case 'D':
      status = 'cancel_appointment';
      break;
  }
  return status;
}
