import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';
import { TOAST_STATUSES } from '@app/common/constants/util.constant';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';

@Component({
  selector: 'app-patient-document-request',
  templateUrl: './patient-document-request.component.html',
  styleUrls: ['./patient-document-request.component.scss']
})
export class PatientDocumentRequestComponent implements OnInit {
  requestId: any;
  documentDetails: any;
  breadCrumbData = {
    link: '',
    linkText: 'Document Request Details'
  };
  displayMarkAsComplete: boolean = false;
  popupData: any;

  constructor(private router: Router, private route: ActivatedRoute, private http: HttpService, private observable: ObservableHelperService, private httpClient: HttpClient) {
    this.route.params.subscribe(params => {
      this.requestId = params.id;
      this.getRequestedDocumentDetails();
    });
  }


  ngOnInit() {
  }

  // This function is for getting the request document details
  getRequestedDocumentDetails() {
    const payload = {
      type: 'GET',
      url: 'adminRequestDetails',
      isDeveloper: true,
      pathVariables: [this.requestId],
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        console.log(res);
        this.documentDetails = res.data;
        this.documentDetails.documentURLs = this.massageDocumentUrls(this.documentDetails.documentURLs);
        this.documentDetails.adminDocumentURLs = this.massageDocumentUrls(this.documentDetails.adminDocument);
        console.log(this.documentDetails.documentURLs);
        this.documentDetails.status = this.getStatusLabel(this.documentDetails.status);
        this.documentDetails.statusLabel = 'Completed Date';
        if (this.documentDetails.status === 'Cancelled') {
          this.documentDetails.statusLabel = 'Cancelled Date';
        }
      } else {
        this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.DOCUMENT_DATA_REQUEST_ERROR_MSG);
      }
    });
  }

  /**
   *
   * @param docUrls list of document urls
   * @returns list with file name and url
   * This function is for massaging the document urls
   */
  massageDocumentUrls(docUrls) {
    if (!docUrls && !Array.isArray(docUrls)) { return []; }
    return docUrls.map(eachUrl => {
      return {
        url: eachUrl,
        fileName: eachUrl.includes('__name__') ? 
        eachUrl.substring(eachUrl.lastIndexOf('__name__') + 8, eachUrl.indexOf('?')) :
        eachUrl.substring(eachUrl.lastIndexOf('/') + 1, eachUrl.lastIndexOf('?'))
      };
    }
    );
  }

  /**
   * This function is for elobrating status to display in UI
   * @param status //P,D,C
   * @returns //Pending,Cancelled,Complete
   */
  getStatusLabel(status) {
    if (status && status === 'P') {
      return 'Pending';
    } else if (status && (status === 'C')) {
      return 'Completed';
    } else if (status && status === 'D') {
      return 'Cancelled';
    }
  }

  // This method for closing the popup
  documentsRequestPopupStatusFun(status) {
    this.displayMarkAsComplete = status.cancelpopup;
    if (status.apiStatus) {
      this.getRequestedDocumentDetails();
    }
  }

  // This function is for mark as complete popup data
  goToMarkAsComplete() {
    this.displayMarkAsComplete = true;
    this.popupData = {
      label: 'Mark Request As Complete',
      type: 'markAsRequestComplete',
      requestId: this.requestId
    };
  }

  // This function is for Cancel request popup data
  cancelRequest() {
    this.displayMarkAsComplete = true;
    this.popupData = {
      label: 'Cancel Request',
      type: 'cancelRequest',
      requestId: this.requestId
    };
  }

  // This method is for sending reminder to patient
  sendRemainder() {
    const payload = {
      type: 'GET',
      url: 'sendReminder',
      isDeveloper: true,
      pathVariables: [this.requestId],
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.observable.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.SUCCESSFULL_REMIDNDER_SENT_MSG);
      } else {
        this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.SEND_REMINDER_ERROR_MSG);
      }
    });

  }
  /**
   *
   * @param urls :list of document urls
   * This function is for sending document urls to the download funciton
   */
  downloadAll(urls) {
    if (urls && urls.length) {
      urls.forEach(doc => {
        this.downloadImage(doc.url);
      });
    }
  }

  // This function is for downloading the documents
  downloadImage(img) {
    const imgUrl = img;
    const imgName = imgUrl.includes('__name__') ?  
    imgUrl.substring(imgUrl.lastIndexOf('__name__') + 8, imgUrl.indexOf('?')) :
    imgUrl.substring(imgUrl.lastIndexOf('/') + 1, imgUrl.lastIndexOf('?'))
    this.httpClient.get(imgUrl, { responseType: 'blob' as 'json' })
      .subscribe((res: any) => {
        const file = new Blob([res], { type: res.type });

        // IE
        if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
          (window.navigator as any).msSaveOrOpenBlob(file);
          return;
        }
        

        const blob = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = blob;
        link.download = imgName;
        // Version link.click() to work at firefox
        link.dispatchEvent(new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        }));

        setTimeout(() => { // firefox
          window.URL.revokeObjectURL(blob);
          link.remove();
        }, 100);
      });
  }

}
