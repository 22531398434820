<section class="location__list-page">
    <div class="background-image">
        <img src="assets/images/pngs/newpatientform-background.png" alt="bg image">
    </div>
    <!-- Main Appointment List Page Wrapper -->
    <div class="location__list-main-wrapper" *ngIf="permissionsDone">
        <!-- Appointments List -->
        <div class="location__list-navigation-menu">
            <app-breadcrumb [breadCrumbData]="breadCrumbData"></app-breadcrumb>
            <!-- Primary action on Page -->
            <div class="location__list-primary-actions" *ngIf="adminPermissions.LOCATION_CREATE">
                <div class="common-btn-style" (click)="addNew()">
                    Create
                </div>
            </div>
        </div>
        <div class="location__list--block">
            <div class="each__location" *ngFor="let location of locationList;let i = index">
                <app-location-list [locationData]="location" [adminPermissions]="adminPermissions"></app-location-list>
            </div>
        </div>
        <!-- Empty list Starts here -->
        <app-empty-list-data [emptyMessageData]="emptyDataObj" *ngIf="false"></app-empty-list-data>
        <!-- Empty list ends here -->
    </div>
</section>