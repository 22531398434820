<div class="form__container">
    <form [formGroup]="formGroup" class="location-creation__form">
        <div class="custom-form">
            <ng-container *ngFor="let field of formInputs;let i=index">
                <div class="form--fields" *ngIf="field.showontoggle == true" id="{{field.idName}}"
                    [ngClass]="field.className != '' ? field.className : '' ">
                    <div [ngSwitch]="field.controlType" class="form__field__wrapper {{field.controlType}}">

                        <!--main header start-->
                        <div *ngIf="field.type == 'header' && field.showontoggle == true" class="main-heading">
                            <span class="label">{{field.label}}</span>
                        </div>
                        <!--main header end-->

                        <div class="label" *ngIf="field.label && field.type !== 'header' && field.showontoggle == true">
                            {{field.label}}</div>

                        <div *ngSwitchCase="'labelValue'">
                            <label>{{field.value}}</label>
                        </div>
                        <!-- text field starts -->
                        <ng-container *ngIf="field.showontoggle == true">
                            <div *ngSwitchCase="'inputText'" class="form__field">
                                <div class="input-and-close">
                                    <input class="text-input {{field.key!='locName'?'url':''}}" [type]="field.type"
                                        [attr.maxLength]="field.maxLength" [formControlName]="field.key"
                                        [ngClass]="{ disable: !field.editable }" [placeholder]="field.placeholder"
                                        [readonly]="!field.editable" [(ngModel)]="field.value" />
                                </div>
                                <!--text field validations start-->
                                <div *ngIf="field.updateOn == 'blur'">
                                    <div *ngIf="!(formGroup.controls[field.key].valid || formGroup.controls[field.key].value) && !formSubmitAttempt"
                                        class="invalid-feedback">
                                        <div>{{ field.emptyValueMessage }}</div>
                                    </div>
                                    <div *ngIf="formGroup.controls[field.key].value && formGroup.controls[field.key].status == 'INVALID' && !formSubmitAttempt"
                                        class="invalid-feedback">
                                        <div>{{ field.validationValue }}</div>
                                    </div>
                                </div>
                                <div>
                                    <div *ngIf="!(formGroup.controls[field.key].valid || formGroup.controls[field.key].value) &&
                      formSubmitAttempt" class="invalid-feedback">
                                        <div>{{ field.emptyValueMessage }}</div>
                                    </div>
                                </div>
                                <div *ngIf="formGroup.controls[field.key].value !='' && formGroup.controls[field.key].status == 'INVALID' && formSubmitAttempt" class="invalid-feedback">
                                    <div>{{ field.validationValue }}</div>
                                </div>
                                <div class="help__text" *ngIf="field.helpText">{{field.helpText}}</div>
                                <!--text field validations end-->

                            </div>
                        </ng-container>
                        <!-- text field ends -->
                        
                       <!--radio-btn field start-->
                       <ng-container *ngIf="field.showontoggle == true">
                        <div *ngSwitchCase="'custom-radio'" class="form__field">
                            <div class="form__field radio">
                                <div class="radio" *ngFor="let each of field.fields" (change)="selectedRadio(each)">
                                    <input id="{{each.value}}" type="radio" [(ngModel)]="field.value" [name]="field.key"
                                        [value]="each.value" [formControlName]="field.key" [attr.disabled]="!field.editable === true || null">
                                    <label for="{{each.value}}" class="radio-label"
                                        [ngClass]="field.editable == false?'disable':''"><span
                                            [ngStyle]="{'width': each.width+'px', 'height': each.height+'px'}"
                                            class="icon--styles" *ngIf="each.img">
                                            <app-svg [name]="each.img"></app-svg>
                                        </span>{{ each.label }}</label>
                                </div>
                            </div>
                            <div *ngIf="field.value == '' && formSubmitAttempt" class="invalid-feedback">
                                <div>{{ field.emptyValueMessage }}</div>
                            </div>
                        </div>
                    </ng-container>
                    <!--radio-btn field end-->

                        <!-- Text area starts-->
                        <ng-container *ngIf="field.showontoggle == true">
                            <div *ngSwitchCase="'textarea'" class="form__field text__area">
                                <textarea  
                                    class="text-area input" type="field.type" [attr.maxLength]="field.maxLength"
                                    [formControlName]="field.key" [ngClass]="{ disable: !field.editable }"
                                    value="{{ field.value }}" [placeholder]="field.placeholder"
                                    (input)="textCounter(field, field.maxLength)" [readonly]="!field.editable"
                                    [(ngModel)]="field.value"></textarea>
                                    <input disabled
                                        class="textarea-range-show" *ngIf="field.showRange && !formGroup.controls[field.key].invalid" maxlength="3" size="3"
                                        value="{{field.count}}" id="{{field.key}}">
                                    <input disabled
                                        class="textarea-range-show" *ngIf="field.showRange && formGroup.controls[field.key].value==='' &&
                                        !formSubmitAttempt" maxlength="3" size="3"
                                        value="{{field.count}}" id="{{field.key}}">

                                <!--text area validation start-->
                                <div *ngIf="field.updateOn == 'blur'">
                                    <div *ngIf="(!formGroup.controls[field.key].value) && !formSubmitAttempt"
                                        class="invalid-feedback ">
                                        <div>{{ field.emptyValueMessage }}</div>
                                    </div>
                                    <div *ngIf="formGroup.controls[field.key].value && formGroup.controls[field.key].status == 'INVALID' && !formSubmitAttempt && formGroup.controls[field.key].touched"
                                        class="invalid-feedback">
                                        <div>{{ field.validationValue }}</div>
                                    </div>
                                </div>
                                <div>
                                    <div *ngIf="(!formGroup.controls[field.key].value) &&
                      formSubmitAttempt" class="invalid-feedback">
                                        <div>{{ field.emptyValueMessage }}</div>
                                    </div>
                                </div>
                                <!-- <div *ngIf="formGroup.controls[field.key].value !='' && formGroup.controls[field.key].invalid && formSubmitAttempt" class="invalid-feedback">
                                    <div>{{ field.validationValue }}</div>
                                </div> -->
                                <div class="help__text" *ngIf="field.helpText">{{field.helpText}}</div>
                                <!--text area validation end-->
                            </div>
                        </ng-container>
                        <!-- text area ends -->
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="custom-form">
            <div class="main-heading">
                <span class="label">Services Provided</span>
            </div>
            <div class="add__service_svg">
                <app-svg [name]="'add-new'" class="cursor__pointer" (click)="addNewService()"></app-svg>
            </div>
            <div (click)="addNewService()"><span class="label add-new">Add New</span></div>
        </div>
    </form>
    <div *ngFor="let serviceForm of serviceForms;let serviceIndex=index" class="locationService">
        <app-location-service-card [formGroup]="serviceForm.formGroup" [formInputs]="serviceForm.formInputs"
        [schedulesCount]="serviceForm.schedulesCount" [schedules]="serviceForm.schedules"
        (sendTimeScheduleDetails)="sendTimeScheduleDetails($event, serviceIndex)" [physiciansList]="physiciansList"
        (deleteLocationService)="deleteLocationService(serviceIndex)" [formSubmitAttempt]="formSubmitAttempt" [serviceForms]="serviceForms" [allServices]="allServices" (prePopulate)="editServiceCard($event,serviceIndex)">
        </app-location-service-card>
    </div>
    <div class="button__wrapper">
        <div class="cancel__button__wrapper" (click)="cancelLocation($event)">
            <button class="cancel-btn">
                Cancel
            </button>
        </div>
        <div class="create__button">
            <button class="common-btn-style" (click)="createLocation($event)">
                {{isCreate ? 'Create' : 'Save'}}
            </button>
        </div>
    </div>
</div>