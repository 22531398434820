import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { ActivateGuard } from '@app/common/services/activate.guard';
import { AppointmentsComponent } from './appointments/appointments.component';


import { EmployeeListComponent } from './employee-list/employee-list.component';
import { EmployeeDetailsComponent } from './employee-details/employee-details.component';
import { NewEmployeeComponent } from './new-employee/new-employee.component';
import { AdminGuard } from '@app/common/services/admin.guard';
import { PatientWrapperComponent } from './patient-wrapper/patient-wrapper.component';
import { LocationWrapperComponent } from './location-wrapper/location-wrapper.component';
import { AppointmentDetailsWrapperComponent } from './appointment-details-wrapper/appointment-details-wrapper.component';
import { LocationDetailsWrapperComponent } from './location-details-wrapper/location-details-wrapper.component';
import { InvoiceDetailsWrapperComponent } from './invoice-details-wrapper/invoice-details-wrapper.component';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { PatientDetailsComponent } from './patient-details/patient-details.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { AdminProfileComponent } from './admin-profile/admin-profile.component';
import { AdminCreatePatientComponent } from './admin-create-patient/admin-create-patient.component';
import { CreateAppointmentComponent } from './create-appointment/create-appointment.component';
import { CreateLocationWrapperComponent } from './create-location-wrapper/create-location-wrapper.component';
import { EditAdminProfileComponent } from './edit-admin-profile/edit-admin-profile.component';
import { AdminChangePasswordComponent } from './admin-change-password/admin-change-password.component';
import { InvoiceDetailsNotGeneratedComponent } from './invoice-details-not-generated/invoice-details-not-generated.component';
import { CancelRedirectComponent } from './common/cancel-redirect/cancel-redirect.component';
import { TmsFormsWrapperComponent } from '../AppointmentBooking/tms-forms-wrapper/tms-forms-wrapper.component';
import { PatientDocumentRequestComponent } from './patient-document-request/patient-document-request.component';
import { PatientDocumentRequestDetailsComponent } from './patient-document-request-details/patient-document-request-details.component';
import { EmailTemplatesListComponent } from './email-templates-list/email-templates-list.component';
import { EmailTemplateWrapperComponent } from './email-template-wrapper/email-template-wrapper.component';
import { EmailTemplateDetailsComponent } from './email-template-details/email-template-details.component';
import { EmailTemplateEditComponent } from './email-template-edit/email-template-edit.component';
import { AdminKetamineMonitoringFormComponent } from './admin-ketamine-monitoring-form/admin-ketamine-monitoring-form.component';
import { DocumentListComponent } from './document-list/document-list.component';
import { MultiplePatientEmailComponent } from './multiple-patient-email/multiple-patient-email.component';
import { LabDocumentEditComponent } from './lab-document-edit/lab-document-edit.component';
import { UserListWrapperComponent } from './user-list-wrapper/user-list-wrapper.component';
import { UserDetailsWrapperComponent } from './user-details-wrapper/user-details-wrapper.component';
import { InfoFormEditComponent } from './info-form-edit/info-form-edit.component';
import { MedicalSummaryFormComponent } from '@app/common/components/medical-summary-form/medical-summary-form.component';
import { PatientDocumentListComponent } from './patient-document-list/patient-document-list.component';
import { PatientDocumentDetailsComponent } from './patient-document-details/patient-document-details.component';
import { MednotesFormComponent } from './mednotes-form/mednotes-form.component'; //mednotes
import { PreviewMednotesComponent } from './preview-mednotes/preview-mednotes.component'; //mednotes-preview
import { TmsNotesComponent } from './tms-notes/tms-notes.component';
import { PreviewTmsNotesComponent } from './preview-tms-notes/preview-tms-notes.component'
const routes: Routes = [
  {
    path: ROUTE_PATHS.EMPTY,
    component: AdminComponent,
    canActivate: [AdminGuard]
  },
  {
    path: ROUTE_PATHS.APPOINTMENTS,
    component: AppointmentsComponent,
    canActivate: [AdminGuard]
  },
  {
    path: 'generate-pdf-doc',
    component: MedicalSummaryFormComponent,
    canActivate: [AdminGuard],
  },
  //mednotes
  {
    path: 'mednotes-form',
    component: MednotesFormComponent,
    canActivate: [AdminGuard],
  },
  //to preview mednotes before generating
  {
    path: 'mednotes-preview',
    component: PreviewMednotesComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'tms-notes',
    component: TmsNotesComponent,
    canActivate: [AdminGuard]
  },
  {
    path: 'tms-notes-preview',
    component: PreviewTmsNotesComponent,
    canActivate: [AdminGuard]
  },
  {
    path: `${ROUTE_PATHS.TMS_FORMS}/:id`,
    component: TmsFormsWrapperComponent,
    canActivate: [AdminGuard]
  },
  {
    path: `${ROUTE_PATHS.TMS_FORMS}/:status/:id`,
    component: TmsFormsWrapperComponent,
    canActivate: [AdminGuard]
  },
  {
    path: `${ROUTE_PATHS.APPOINTMENTS}/:${ROUTE_PATHS.APPOINTMENT_ID}`,
    component: AppointmentDetailsWrapperComponent,
    canActivate: [AdminGuard]
  },
  {
    path: `${ROUTE_PATHS.PATIENTS}`,
    component: PatientWrapperComponent,
    canActivate: [AdminGuard]
  },
  {
    path: `${ROUTE_PATHS.LOCATIONS}`,
    component: LocationWrapperComponent,
    canActivate: [AdminGuard]
  },
  {
    path: `${ROUTE_PATHS.LOCATIONS}/:${ROUTE_PATHS.ID}`,
    component: LocationDetailsWrapperComponent,
    canActivate: [AdminGuard]
  },
  {
    path: ROUTE_PATHS.EMPLOYEE,
    component: EmployeeListComponent,
    canActivate: [AdminGuard]

  },
  {
    path: `${ROUTE_PATHS.EMPLOYEE}/:${ROUTE_PATHS.ID}`,
    component: EmployeeDetailsComponent,
    canActivate: [AdminGuard]

  },
  {
    path: ROUTE_PATHS.EMPLOYEE_CREATION,
    component: NewEmployeeComponent,
    canActivate: [AdminGuard]
  },
  {
    path: `${ROUTE_PATHS.INVOICES}/:${ROUTE_PATHS.INV_TYPE}/:${ROUTE_PATHS.ID}`,
    component: InvoiceDetailsWrapperComponent,
    canActivate: [AdminGuard]
  },
  {
    path: ROUTE_PATHS.INVOICES,
    component: InvoiceListComponent,
    canActivate: [AdminGuard]
  },
  {
    path: `${ROUTE_PATHS.PATIENTS}/:${ROUTE_PATHS.ID}`,
    component: PatientDetailsComponent,
    canActivate: [AdminGuard]
  },
  {
    path: `${ROUTE_PATHS.CREAT_PATIENT}`,
    component: AdminCreatePatientComponent,
    canActivate: [AdminGuard]
  },
  {
    path: ROUTE_PATHS.NEWAPPOINTMENT,
    component: CreateAppointmentComponent,
    canActivate: [AdminGuard]
  },
  {
    path: ROUTE_PATHS.NOTIFICATIONS,
    component: NotificationsComponent,
    canActivate: [AdminGuard]
  },
  {
    path: ROUTE_PATHS.CREATE_LOCATION,
    component: CreateLocationWrapperComponent,
    canActivate: [AdminGuard]
  },
  {
    path: `${ROUTE_PATHS.EDIT_LOCATIONS}/:${ROUTE_PATHS.ID}`,
    component: CreateLocationWrapperComponent,
    canActivate: [AdminGuard]
  },
  {
    path: ROUTE_PATHS.ADMIN_PROFILE,
    component: AdminProfileComponent,
    canActivate: [AdminGuard]
  },
  {
    path: ROUTE_PATHS.PROFILE_EDIT,
    component: EditAdminProfileComponent,
    canActivate: [AdminGuard]
  },
  {
    path: ROUTE_PATHS.ADMIN_CHANGE_PWD,
    component: AdminChangePasswordComponent,
    canActivate: [AdminGuard]
  },
  {
    path: `${ROUTE_PATHS.INVOICES}/${ROUTE_PATHS.NOT_GENERATED}/:${ROUTE_PATHS.INV_TYPE}/:${ROUTE_PATHS.ID}`,
    component: InvoiceDetailsNotGeneratedComponent,
    canActivate: [AdminGuard]
  },
  {
    path: `${ROUTE_PATHS.INVOICES}/cancel-redirect/:${ROUTE_PATHS.INV_TYPE}/:${ROUTE_PATHS.ID}`,
    component: CancelRedirectComponent,
    canActivate: [AdminGuard]
  },
  {
    path: `${ROUTE_PATHS.PATIENTS}/${ROUTE_PATHS.REQUESTED_DOCUMENTS}/:${ROUTE_PATHS.ID}`,
    component: PatientDocumentRequestComponent,
    canActivate: [AdminGuard]
  },
  {
    path: `${ROUTE_PATHS.PATIENTS}/${ROUTE_PATHS.REQUESTED_DETAILS}/:${ROUTE_PATHS.ID}`,
    component: PatientDocumentDetailsComponent,
    canActivate: [AdminGuard]
  },
  // This is for Email template list component
  {
    path: `${ROUTE_PATHS.EMAIL_TEMPLATES}`,
    component: EmailTemplateWrapperComponent,
    canActivate: [AdminGuard]
  },
  /**
   * This is for email template details page component
   */
  {
    path: `${ROUTE_PATHS.EMAIL_TEMPLATES}/${ROUTE_PATHS.DETAILS}/:${ROUTE_PATHS.ID}`,
    component: EmailTemplateDetailsComponent,
  },
  /**
   * This is for email template details edit component
   */
  {
    path: `${ROUTE_PATHS.EMAIL_TEMPLATES}/:${ROUTE_PATHS.ID}`,
    component: EmailTemplateEditComponent,
    canActivate: [AdminGuard]
  },

  /**
   * This is for admin ketamine monitoring form component
   */
   {
    path: `${ROUTE_PATHS.ADMIN_KETAMINE_MONITORING_FORM}/:${ROUTE_PATHS.ID}`,
    component: AdminKetamineMonitoringFormComponent,
    canActivate: [AdminGuard]
  },
  /**
   * This is for request document listing screen.
   */
  {
    path: `${ROUTE_PATHS.REQUEST_DOCUMENTS}`,
    component: DocumentListComponent,
    canActivate: [AdminGuard]
  },
    /**
   * This is for patient request document listing screen.
   */
     {
      path: `${ROUTE_PATHS.PATIENT_REQU_DOCUMENTS}`,
      component: PatientDocumentListComponent,
      canActivate: [AdminGuard]
    },
  /**
   * This is for request document listing screen.
   */
  {
    path: `${ROUTE_PATHS.SEND_MAIL}`,
    component: MultiplePatientEmailComponent,
    canActivate: [AdminGuard]
  },
  /**
   * This is for lab document edit screen.
   */
  {
    path: `${ROUTE_PATHS.LAB_DOC_EDIT}/:${ROUTE_PATHS.ID}`,
    component: LabDocumentEditComponent,
    canActivate: [AdminGuard]
  },

  /**
   * This is for user list wrapper.
   */
   {
    path: `${ROUTE_PATHS.USER_LIST}`,
    component: UserListWrapperComponent,
    canActivate: [AdminGuard]
  },

   /**
   * This is for lab document edit screen.
   */
    {
      path: `${ROUTE_PATHS.USER_LIST}/:${ROUTE_PATHS.ID}`,
      component: UserDetailsWrapperComponent,
      canActivate: [AdminGuard]
    },

    /**
   * This is for information form edit screen.
   */
     {
      path: `${ROUTE_PATHS.INFO_REQUEST_FORM}/:${ROUTE_PATHS.ID}`,
      component: InfoFormEditComponent,
      canActivate: [AdminGuard]
    },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
