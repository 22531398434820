<div class="confirmation-img-heading" *ngIf="showErrorMessage">
    <div class="confirmation-heading">
        {{errorMessage}}
    </div>
 </div>

 <section class="paymentPage" *ngIf="showPaymentButton" >
    <div class="content__block--wrapper">
        <div class="left__side--wrapper">
            <span *ngIf=" showInvoiceDetails.invoiceType === 'appt_invoice'">
                <div class="patient__details--block">
                    <div class="each__block">
                        <div class="details__lable">
                            Invoice Status
                        </div>
                        <div class="details__value">
                            {{showInvoiceDetails.invoiceStatus | titlecase}}
                        </div>
                    </div>
                    <div class="each__block">
                        <div class="details__lable">
                            Invoice ID
                        </div>
                        <div class="details__value">
                            {{showInvoiceDetails.invoiceId}}
                        </div>
                    </div>
                    
                    <div class="each__block">
                        <div class="details__lable">
                            Create Time
                        </div>
                        <div class="details__value">
                            <span class="time_data">
                                {{showInvoiceDetails.createTime | dateFormat}}
                            </span>
                            <span class="time_data">
                                {{showInvoiceDetails.createTime | date:'shortTime'}}
                            </span>
                        </div>
                    </div>
                    <div class="each__block">
                        <div class="details__lable">
                            Comment
                        </div>
                        <div class="details__value">
                            {{(showInvoiceDetails.comment) ? showInvoiceDetails.comment : '-'}}
                        </div>
                    </div>
                </div>
                <!-- Appointment details ends here -->
            </span>
            <span *ngIf="showInvoiceDetails.invoiceType === 'patient_invoice'">
                <div class="patient__details--block">
                    <div class="each__block">
                        <div class="details__lable">
                            Invoice Status
                        </div>
                        <div class="details__value">
                            {{showInvoiceDetails.invoiceStatus | titlecase}} 
                        </div>
                    </div>
                    <div class="each__block">
                        <div class="details__lable">
                            Invoice ID
                        </div>
                        <div class="details__value">
                            {{showInvoiceDetails.invoiceId}} 
                        </div>
                    </div>
                    
                    <div class="each__block">
                        <div class="details__lable">
                            Create Time
                        </div>
                        <div class="details__value">
                            <span class="time_data">
                                {{showInvoiceDetails.createTime | dateFormat}}
                            </span>
                            <span class="time_data">
                                {{showInvoiceDetails.createTime | date:'shortTime'}}
                            </span>
                        </div>
                    </div>
                    <div class="each__block">
                        <div class="details__lable">
                            Service
                        </div>
                        <div class="details__value">
                            {{showInvoiceDetails.service | titlecase}}
                        </div>
                    </div>
                </div>
                <!-- Appointment details ends here -->
            </span>
            <span *ngIf="showInvoiceDetails.invoiceType === 'document_request_invoice'">
                <div class="patient__details--block">
                    <div class="each__block">
                        <div class="details__lable">
                            Invoice Status
                        </div>
                        <div class="details__value">
                            {{showInvoiceDetails.invoiceStatus | titlecase}} 
                        </div>
                    </div>
                    <div class="each__block">
                        <div class="details__lable">
                            Invoice ID
                        </div>
                        <div class="details__value">
                            {{showInvoiceDetails.invoiceId}}
                        </div>
                    </div>
                    
                    <div class="each__block">
                        <div class="details__lable">
                            Create Time
                        </div>
                        <div class="details__value">
                            <span class="time_data">
                                {{showInvoiceDetails.createTime | dateFormat}}
                            </span>
                            <span class="time_data">
                                {{showInvoiceDetails.createTime | date:'shortTime'}}
                            </span>
                        </div>
                    </div>
                    <div class="each__block">
                        <div class="details__lable">
                            Comment
                        </div>
                        <div class="details__value">
                            {{showInvoiceDetails.comment}} 
                        </div>
                    </div>
                </div>
                <!-- Appointment details ends here -->
            </span>

            <!-- showing the amount to be paid -->
            <ng-container >
                <div class="send__to--customer-block" *ngIf="showInvoiceDetails.invoiceType == 'appt_invoice'">
                    <div class="copay__block lable">Copay Amount <span class="value">${{showInvoiceDetails.copay}}</span></div>
                    <div class="due__block lable">Due Amount <span class="value">${{showInvoiceDetails.pay}}</span></div>
                    <div class="vr__line"></div>
                    <div class="total__block lable">Total Amount <span class="value">${{showInvoiceDetails.copay + showInvoiceDetails.pay}}</span></div>
                </div>
                <div class="send__to--customer-block" *ngIf="showInvoiceDetails.invoiceType === 'document_request_invoice'">
                    <div class="copay__block lable">Copay Amount <span class="value">${{showInvoiceDetails.copay}}</span></div>
                    <div class="due__block lable">Due Amount <span class="value">${{showInvoiceDetails.pay}}</span></div>
                    <div class="vr__line"></div>
                    <div class="total__block lable">Total Amount <span class="value">${{showInvoiceDetails.copay + showInvoiceDetails.pay}}</span></div>
                </div>
                
                <div class="send__to--customer-block patient" *ngIf="showInvoiceDetails.invoiceType == 'patient_invoice'">
                    <div class="total__block lable patient">Amount <span class="value">${{showInvoiceDetails.pay}}</span></div>
                </div>
            </ng-container>
            
            
        </div>
        <!-- Left side details ends here -->
    </div>
    <form method="POST"  ngNoForm [action]="paymentRedirectUrl" id="formAuthorizeNetTestPage" name="formAuthorizeNetTestPage">
        <input type="hidden" name="token" [value]="paymentToken" />
        <button id="btnContinue">Click here to make payment</button>
    </form> 
 </section>
 