<!-- get-in-touch section start -->
<section class="get-in-touch__section section-padding full-width" id="contact-us">
    <!-- get-in-touch container start -->
    <div class="get-in-touch__container restrict-width padding-lr">
        <!-- map section start -->
        <div class="map__section" id="contactUs">
            <!-- get-in-touch left block start -->
            <div class="get-in-touch__left-block">
                <app-section-header [headingData]="headingData"></app-section-header>
                <a href="tel:+877-202-8202" class="icon__text-block">
                    <div class="icon__block phone">
                        <app-svg [name]="'phone'"></app-svg>
                    </div>
                    <span>877-202-8202</span>
                 </a>
                <a href="tel:+8665286229" class="icon__text-block">
                    <div class="icon__block fax">
                        <app-svg [name]="'fax'"></app-svg>
                    </div>
                    <span>866 - 528 - 6229</span>
                 </a>
                <a href="mailto:officemanager@newstressclinic.com" class="icon__text-block">
                    <div class="icon__block mail">
                        <app-svg [name]="'mail'"></app-svg>
                    </div>
                    <span>officemanager@newstressclinic.com</span>
                 </a>
            </div>
            <!-- get-in-touch left block end -->
            <!-- get-in-touch right block start -->
            <div class="get-in-touch__right-block">
                <div class="map__block">
                    <div class="image__block">
                        <img src="assets/images/webp/map.webp" alt="map image" loading="lazy">
                    </div>
                    <a href="https://goo.gl/maps/bBdnnKFH3EjTFTLR8" rel="noopener noreferrer" class="icon__block location1" target="_blank" title="Alexandria Office 5194">
                        <app-svg [name]="'location'"></app-svg>
                    </a>
                    <a href="https://goo.gl/maps/C4YHpxqDh6iE7Ptn6" rel="noopener noreferrer" class="icon__block location2" target="_blank" title="Washington D.C Office 1325">
                        <app-svg [name]="'location'"></app-svg>
                    </a>
                    <a href="https://goo.gl/maps/bgsATQ9dFvENuiZ36" rel="noopener noreferrer" class="icon__block location3" target="_blank" title="Tysons Corner Office 8206">
                        <app-svg [name]="'location'"></app-svg>
                    </a>
                    <a href="https://goo.gl/maps/jYWswpkHczvD7Ve67" rel="noopener noreferrer" class="icon__block location4" target="_blank" title="Great Falls Office 9700">
                        <app-svg [name]="'location'"></app-svg>
                    </a>
                </div>
            </div>
            <!-- get-in-touch right block end -->
        </div>
        <!-- map section end -->
        <!-- locations section start -->
        <div class="locations__section">
            <div class="sub-heading">Our Locations</div>
            <div class="address__container desktop" *ngIf="locations.length>4">
                <ngx-slick-carousel class="carousel" [config]="slideConfig">
                    <div *ngFor="let location of locations" ngxSlickItem class="slide">
                        <!-- address block start -->
                        <a href="{{location.locationUrl}}" rel="noopener noreferrer" class="address__block {{location.locationUrl==null||location.locationUrl==''||location.locationUrl=='test'||location.locationType=='online'?'disable':''}}" target="_blank">
                            <!-- address icon block start -->
                            <div class="icon__block address">
                                <app-svg [name]="'address'"></app-svg>
                            </div>
                            <!-- address icon block end -->
                            <div class="address__description-block">
                                <div class="address__content name">{{location.locationName}}</div>
                                <div class="address__content" *ngIf="location.locationType=='offline'">{{location.address}}</div>
                                <div class="address__day-time" *ngIf="location.weekTimes.startingTime != null">
                                    <div class="day__block">
                                        <span class="day">{{location.weekDays}}</span>
                                    </div>
                                    <div class="time__block">
                                        <span class="time">{{location.weekTimes.startingTime | customTime}} - {{location.weekTimes.endingTime | customTime}}</span>
                                    </div>
                                </div>
                                <div class="address__day-time" *ngIf="location.weekEndTimes.startingTime != null">
                                    <div class="day__block">
                                        <span class="day">{{location.weekendDays}}</span>
                                    </div>
                                    <div class="time__block">
                                        <span class="time">{{location.weekEndTimes.startingTime | customTime}} - {{location.weekEndTimes.endingTime | customTime}}</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <!-- address block end -->
                    </div>
                </ngx-slick-carousel>
            </div>
            
            <div class="address__container {{locations.length>4 ? 'mobile':''}}">
                    <div *ngFor="let location of locations">
                        <!-- address block start -->
                        <a href="{{location.locationUrl}}" rel="noopener noreferrer" class="address__block {{location.locationUrl==null||location.locationUrl==''||location.locationUrl=='test'||location.locationType=='online'?'disable':''}}" target="_blank">
                            <!-- address icon block start -->
                            <div class="icon__block address">
                                <app-svg [name]="'address'"></app-svg>
                            </div>
                            <!-- address icon block end -->
                            <div class="address__description-block">
                                <div class="address__content name">{{location.locationName}}</div>
                                <div class="address__content" *ngIf="location.locationType=='offline'">{{location.address}}</div>
                                <div class="address__day-time" *ngIf="location.weekTimes.startingTime != null">
                                    <div class="day__block">
                                        <span class="day">{{location.weekDays}}</span>
                                    </div>
                                    <div class="time__block">
                                        <span class="time">{{location.weekTimes.startingTime | customTime}} - {{location.weekTimes.endingTime | customTime}}</span>
                                    </div>
                                </div>
                                <div class="address__day-time" *ngIf="location.weekEndTimes.startingTime != null">
                                    <div class="day__block">
                                        <span class="day">{{location.weekendDays}}</span>
                                    </div>
                                    <div class="time__block">
                                        <span class="time">{{location.weekEndTimes.startingTime | customTime}} - {{location.weekEndTimes.endingTime | customTime}}</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <!-- address block end -->
                    </div>
            </div>
        </div>
        <!-- locations section end -->
        <!-- book now block start -->
        <div class="book__now-block">
            <div class="orange-heading">Start your online consultation with us</div>
            <div class="common-btn-style">
                <div (click)="navigateBookingpage()">Book Now</div>
            </div>
        </div>
        <!-- book now block end -->
    </div>
    <!-- get-in-touch container end -->
</section>
<!-- get-in-touch section end -->