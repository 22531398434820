import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';
import { TOAST_STATUSES } from '@app/common/constants/util.constant';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';

@Component({
  selector: 'app-patient-mark-as-complete',
  templateUrl: './patient-mark-as-complete.component.html',
  styleUrls: ['./patient-mark-as-complete.component.scss']
})
export class PatientMarkAsCompleteComponent implements OnInit {
  @Input() popupTitle;
  @Output() closePopup = new EventEmitter<any>();
  adminComments: any = '';
  remainingValue1: number;
  constructor(private http: HttpService, private observable: ObservableHelperService) { }

  ngOnInit() {
  }
  showHideCancelPopup() {
    this.closePopup.emit({ closePopup: false, apiStatus: false });
  }
  textCounter1(event) {
    console.log(event.target.value.length);
    this.remainingValue1 = 150 - (event.target.value.length);
  }
  submitPopup() {
    const payloadData = {
      type: 'POST',
      url: 'adminRequest',
      isDeveloper: true,
      pathVariables: [this.popupTitle.requestId],
      body: {
        adminComments: this.adminComments,
        status: (this.popupTitle.type === 'markAsRequestComplete') ? 'C' : 'D'
      }
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.closePopup.emit({ closePopup: false, apiStatus: true });
        if (this.popupTitle.type === 'markAsRequestComplete') {
          this.observable.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.COMPLETED_DOCUMENT_REQUEST_MSG);
        } else {
          this.observable.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.CANCEL_DOCUMENT_REQUEST_MSG);
        }
      } else {
        if (this.popupTitle.type === 'markAsRequestComplete') {
          this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.COMPLETED_DOCUMENT_REQUEST_ERROR_MSG);
        } else {
          this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.CANCEL_REQUEST_ERROR_MSG);
        }
      }
    });
  }
}
