<div class="patients-list" *ngIf="requestList">
    <div class="patients-list-main">
        <div class="patients-list-content">
            <div class="patients-list-title">
                Pending Requests
            </div>
            <div class="patients-block" >
                <div class="patient-card" *ngFor="let card of requestList">
                    <div class="patient-profile date">
                        <app-svg [name]="'calendar'"></app-svg>
                        <p>{{card.date | dateFormat }}</p>&nbsp;|&nbsp;
                        <p>{{card.time | customeTimeAmPm}}</p>
                    </div>
                    <div class="patient-profile document">
                        <app-svg [name]="'detail'"></app-svg>
                        <p *ngIf="card.requestType==='LAB_REQUEST'">Lab Document Request</p>
                        <p *ngIf="card.documentIds != '[1]' && card.documentIds != '[9]' && card.documentIds != '[14]' && card.documentIds != '[15]' && card.documentIds != '[16]' && card.documentIds != '[23]' && card.requestType==='DOCUMENT_REQUEST'">Document Request</p>
                        <p *ngIf="card.documentIds == '[1]' && card.requestType==='DOCUMENT_REQUEST'">ADHD questionnaire</p>
                        <p *ngIf="card.documentIds == '[14]' && card.requestType==='DOCUMENT_REQUEST'">Medication Feedback</p>
                        <p *ngIf="card.documentIds == '[9]' && card.requestType==='DOCUMENT_REQUEST'">Therapist details request.</p>
                        <p *ngIf="card.documentIds == '[15]' && card.requestType==='DOCUMENT_REQUEST'">INFN Req</p>
                        <p *ngIf="card.documentIds == '[16]' && card.requestType==='DOCUMENT_REQUEST'">PHQ questionnaire</p>
                        <p *ngIf="card.documentIds == '[23]' && card.requestType==='DOCUMENT_REQUEST'">Control Substance consent </p>

                    </div>
                    <div class="patient-actions">
                        <div class="patient-action-block" (click)="viewDocumentDetailsPage(card)">
                            Click Here
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="close" (click)="showHideMyAccountPopupStatusChange()">
            <app-svg [name]="'cancel-appointment'"></app-svg>
        </div>
        <img class="onbaord-form-bg" alt="background-leaf" src="assets/images/pngs/onboard-card-bg.png" />
    </div>
</div>