<!-- success card block start -->
<div class="success__card-block">
    <!-- card bg block start -->
    <div class="success__card-bg">
        <img src="assets/images/pngs/success-bg.png" alt="background image" loading="lazy">
    </div>
    <!-- card bg block end -->
    <!-- success card icon start -->
    <div class="success__card-icon">
        <img src="assets/images/svgs/profile.svg" alt="profile image" loading="lazy">
    </div>
    <!-- success card icon end -->
    <p class="success__card-description">
        {{successCardData.comment}}
        <span class="icon__block quotes">
            <app-svg [name]="'quotes'"></app-svg>
        </span>
    </p>
    <h3 class="success__card-name">
        {{successCardData.profileName}}
    </h3>
</div>
<!-- success card block end -->