<section class="popup-content-block">
    <div class="generate__invoice--header">Generate Invoice</div>
    <div class="amount__block">
        <form  [formGroup]="generateForm">
            <div class="form__group">
                <label>
                    <div class="input__lable">
                        Copay Amount
                    </div>
                    <div class="input__value">
                        <input [(ngModel)]="copayAmount" placeholder="0" (keypress)="onKeyPressMethod($event)"
                            (keyup)="onKeyUpMethod()" type="number" formControlName="copayAmt">
                    </div>
                    <div class="invalid-msg" *ngIf="formSubmitAttempt && !generateForm.controls.copayAmt.valid">Please enter
                        amount</div>
                </label>
                <label>
                    <div class="input__lable">
                        Due Amount
                    </div>
                    <div class="input__value">
                        <input [(ngModel)]="dueAmount" placeholder="0" (keypress)="onKeyPressMethod($event)"
                            (keyup)="onKeyUpMethod()" type="number" formControlName="dueAmt">
                    </div>
                    <div class="invalid-msg" *ngIf="formSubmitAttempt && !generateForm.controls.dueAmt.valid">Please enter
                        amount</div>
                </label>
            </div>
            <div class="total__block">Total Payable Amount <span class="total__amount">${{totalAmount}}</span></div>
            <div class="invalid-msg total"><span *ngIf="formSubmitAttempt && totalZero">Total Payable Amount should be
                    greater than 0 before making any action</span></div>
            <label class="comments">
                <div class="input__lable">Comments</div>
                <textarea placeholder="Enter comments" formControlName="comments"
                        maxlength="500"></textarea>
                        <div></div>
            </label>
        </form>
    </div>
    <div class="btn-actions">
        <div class="common-btn-style" (click)="actionField($event,'sendCustomer')">Send to Customer</div>
        <div class="common-btn-style" (click)="actionField($event,'makePayment')">Make Payment</div>
        <div class="common-btn-style" (click)="actionField($event,'markAsPaid')">Mark as Paid</div>
    </div>
</section>