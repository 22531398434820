import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { convertDateToSpecifiedOffset, DEFAULT_TIME_ZONE_DIFF } from '@app/common/constants/util.constant';
@Component({
  selector: 'app-dashboard-calendar',
  templateUrl: './dashboard-calendar.component.html',
  styleUrls: ['./dashboard-calendar.component.scss']
})
export class DashboardCalendarComponent implements OnInit {

  @Input() adminPermissions;
  @Input() set viewBy(value: any) {
    if (value) {
      this.viewByRadioBtn = value;
    }
  }
  @Input() set treatments(value: any) {
    if (value) {
      this.appointmentsType = value;
      if (this.viewByRadioBtn === 'day') {
        this.getDayWiseGridData();
        this.getTimeLables();
      } else {
        this.getWeekWiseGridData();
        this.getTimeLables();
      }
    }
  }
  @Input() timestamp;
  constructor() {
    setInterval(() => {
      this.currentTime = new Date(convertDateToSpecifiedOffset(DEFAULT_TIME_ZONE_DIFF, new Date()));
      this.currentTime = this.currentTimeLine(this.currentTime);
      if (document.getElementById('daywise__timeline')) {
        let daywiseTimeline = document.getElementById('daywise__timeline');
        daywiseTimeline.style.top = `${this.calculateTimeDifference() * 7.6}px`;
      }
      if (document.getElementById('weekwise__timeline')) {
        let weekwiseTimeline = document.getElementById('weekwise__timeline');
        weekwiseTimeline.style.top = `${this.calculateTimeDifference() * 1.95}px`;
      }
    }, 1);
  }

  calendarStartTime: '06:30:00';
  calendarEndTime: '23:59:00';
  dayWiseSlotWidth = 278;
  daywiseSlotHeight = 38;
  weekWiseSlotWidth = 117;
  weekWiseSlotHeight = 117;
  weekWiseGridData = [
    {
      date: '',
      slots: [],
    },
    {
      date: '',
      slots: [],
    },
    {
      date: '',
      slots: [],
    },
    {
      date: '',
      slots: [],
    },
    {
      date: '',
      slots: [],
    },
    {
      date: '',
      slots: [],
    },
    {
      date: '',
      slots: [],
    },
  ];
  currentTime: any = new Date();
  viewByRadioBtn: any = 'day';
  timeLabels: any = [];
  appointmentsType: any = [];
  column: any;
  isShow: boolean = false;
  @Output() calendarSlotEvent: EventEmitter<any> = new EventEmitter();
  weektimeLabels: any[];

  // to calculate time diffrence
  calculateTimeDifference() {
    let startTime = new Date(convertDateToSpecifiedOffset(DEFAULT_TIME_ZONE_DIFF, new Date()));
    startTime = new Date(startTime.setHours(6, 30, 0, 0));
    let endTime = new Date(convertDateToSpecifiedOffset(DEFAULT_TIME_ZONE_DIFF, new Date()));
    let diff = endTime.getTime() - startTime.getTime();
    let msec = diff;
    let mm = Math.floor(msec / 1000 / 60);
    return mm;
  }

  // Called after every check of the component's view. Applies to components only.
  // Add 'implements AfterViewChecked' to the class.
  ngOnInit() {
    this.currentTime = this.currentTimeLine(this.currentTime);
  }

  getTimeLables() {
    if (this.viewByRadioBtn === 'day') {
      this.timeLabels = this.getTimeRange(30, this.dayWiseSlotWidth, this.daywiseSlotHeight, false);
    } else {
      this.weektimeLabels = this.getTimeRange(60, this.weekWiseSlotWidth, this.weekWiseSlotHeight, true);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getTimeLables();
    let val = 92.7 / this.column;
    let str = ``;
    for (let index = 0; index < this.column; index++) {
      str = str + ' ' + val + '%';
    }
    if (document.getElementById('dayWiselabel')) {
      document.getElementById('dayWiselabel').style.gridTemplateColumns = `7.3% ${str}`;
    }
    if (document.getElementById('dayWiseCalendar')) {
      document.getElementById('dayWiseCalendar').style.gridTemplateColumns = `7.3% ${str}`;
    }

  }
  ngAfterViewInit(): void {
    let val = 92.7 / this.column;
    let str = ``;
    for (let index = 0; index < this.column; index++) {
      str = str + ' ' + val + '%';
    }
    if (document.getElementById('dayWiseCalendar')) {
      document.getElementById('dayWiseCalendar').style.gridTemplateColumns = `7.3% ${str}`;
    }
  }
  getTimeInterval() {
    var x = 60; // minutes interval
    var times = []; // time array
    var tt = 6.5 * 60; // start time
    // loop to increment the time and push results in array
    for (var i = 0; tt <= 24 * 60; i++) {
      var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
      var mm = (tt % 60); // getting minutes of the hour in 0-55 format
      times[i] = ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2) + ':00'; // pushing data in array in [00:00 - 12:00 AM/PM format]
      tt = tt + x;
    }
    return times;
  }

  addMinutes(timestr, minutes, allowEnd?) {
    const pieces = timestr.split(':');
    let hrs = Number(pieces[0]);
    let mins = Number(pieces[1]);
    let netmins = minutes % 60;
    let nethours = Math.trunc(minutes / 60);

    mins += netmins;
    if (mins >= 60) {
      mins %= 60;
      hrs += nethours + 1;
    } else {
      hrs += nethours;
    }
    if (hrs >= 24) {
      if (allowEnd && hrs === 24) {
        hrs = 24;
      } else {
        hrs %= 24;
      }
    }
    pieces[0] = hrs + '';
    pieces[1] = mins + '';

    return pieces[0].padStart(2, '0') + ':' + pieces[1].padStart(2, '0') + ':' + pieces[2];
  }
  // Week wise grid data massaging
  getWeekWiseGridData() {
    this.isShow = false;
    let weekTimeInterval = [];
    let filteredSlots = [];
    this.weekWiseGridData = [{
      date: '',
      slots: [],
    },
    {
      date: '',
      slots: [],
    },
    {
      date: '',
      slots: [],
    },
    {
      date: '',
      slots: [],
    },
    {
      date: '',
      slots: [],
    },
    {
      date: '',
      slots: [],
    },
    {
      date: '',
      slots: [],
    }];
    weekTimeInterval = this.getTimeInterval();
    this.appointmentsType.forEach((eachDate, i) => {
      this.weekWiseGridData[i]['date'] = eachDate.date;
      let dateCount = [];
      weekTimeInterval.forEach((eachTimeLable) => {
        let treatmentCount = [];
        eachDate.treatments.forEach((eachTreatment) => {
          let appointmentCount = 0;
          // tslint:disable-next-line:no-shadowed-variable
          let available: boolean;
          let availableCount = 0;
          let blockedAppointmentsCount = 0;
          filteredSlots = eachTreatment.slots.filter(eachSlot => eachSlot.time >= eachTimeLable && eachSlot.time < this.addMinutes(eachTimeLable, 60));
          filteredSlots.forEach(eachFilteredSlot => {
            if (eachFilteredSlot.available === true) {
              availableCount += eachFilteredSlot.maxApptCount;
              appointmentCount += eachFilteredSlot.list.length;
            } else if (eachFilteredSlot.available === false && eachFilteredSlot.list.lenght !== 0) {
              blockedAppointmentsCount += eachFilteredSlot.list.length;
            }
          });
          // tslint:disable-next-line:prefer-for-of
          for (let index = 0; index < filteredSlots.length; index++) {
            if (filteredSlots[index].available) {
              available = filteredSlots[index].available;
              break;
            } else {
              available = filteredSlots[index].available;
            }
          }
          treatmentCount.push({
            appointmentName: eachTreatment.appointmentName,
            appointmentTypeId: eachTreatment.appointmentTypeId,
            available,
            bookedCount: availableCount + blockedAppointmentsCount,
            appointmentCount: appointmentCount + blockedAppointmentsCount,
            filteredSlots,
          });
        });
        let available;
        // tslint:disable-next-line:prefer-for-of
        for (let index = 0; index < treatmentCount.length; index++) {
          if (treatmentCount[index].available) {
            available = treatmentCount[index].available;
            break;
          } else {
            available = treatmentCount[index].available;
          }
        }
        dateCount.push({
          treatmentCount,
          eachTimeLableEnd: this.timeAmPmConvertion(this.addMinutesToTime(eachTimeLable, 60)),
          eachTimeLable: this.timeAmPmConvertion(eachTimeLable),
          available,
          type: 'week',
          date: this.weekWiseGridData[i].date,
          slotWidth: `${this.weekWiseSlotWidth}px`,
          slotHeight: `${this.weekWiseSlotHeight}px`,
        });
      });
      this.weekWiseGridData[i]['slots'].push(dateCount);
    });
    console.log(this.weekWiseGridData);
    this.isShow = true;
  }


  weekSlotStatus(rowData) {
    if (rowData && rowData.treatmentCount) {
      return rowData.treatmentCount.every(treat => treat.bookedCount === treat.appointmentCount);
    }
  }

  // daywise grid data massaging
  getDayWiseGridData() {
    this.appointmentsType.forEach((eachDate, i) => {
      this.column = 0;
      eachDate.treatments.forEach((eachTreatment, j) => {
        this.column++;
        eachTreatment.slots = this.getTimeRange(eachTreatment.duration, this.dayWiseSlotWidth, this.daywiseSlotHeight, false, eachTreatment.appointmentTypeId, eachTreatment, eachDate);
      });
    });
    this.isShow = true;
  }

  // This functionality will set the current time line
  currentTimeLine(date) {
    let hours: any = date.getHours();
    let minute: any = date.getMinutes();
    hours = hours === 0 ? 12 : hours; // if it is 0, then make it 12
    let ampm = 'AM';
    ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours > 12 ? hours - 12 : hours; // if more than 12, reduce 12 and set am/pm flag
    hours = ('' + hours).slice(-2); // pad with 0
    minute = ('0' + date.getMinutes()).slice(-2); // pad with 0
    let time = `${hours}:${minute} ${ampm}`;
    return time;
  }

  // this function will generate grid boxes
  getTimeRange(duration, width, height, isWeek, appointmentId?, treatment?, appointment?) {
    let timeStart: any = '06:30:00';
    let timeEnd: any = '23:30:00';
    var start = new Date(); // get a date object
    var end = new Date();
    start.setHours(timeStart.split(':')[0], timeStart.split(':')[1], 0, 0);
    end.setHours(timeEnd.split(':')[0], timeEnd.split(':')[1], 0, 0); // reassign it to today's midnight
    var interval = duration; // duration
    var timeArr = [];
    let i = 0;
    while (start < end) {
      let slotObj = {
        available: false,
        list: [],
        unavailabilityGrpId: '',
        maxApptCount: '',
        time: formatDate(start, 'HH:mm:ss', 'en-US')
      };
      if (treatment && treatment.slots && treatment.slots.find(eachTime => eachTime.time === slotObj.time)) {
        slotObj = treatment.slots.find(eachTime => eachTime.time === slotObj.time);
      }
      let slotWidth = `${width}px`;
      let slotHeight = (!isWeek) ? `${Math.floor(height * (duration / 5))}px` : `${height}px`;
      let slotData = {
        ...slotObj,
        slotHeight,
        status: '',
        date: (appointment) ? appointment.date : '',
        formatTime: '',
        appointmentName: (treatment) ? treatment.appointmentName : '',
        id: '',
        img: '',
        type: 'day',
        appointmentTypeId: appointmentId,
        isClass: (slotObj && slotObj['available'] === false && slotObj['unavailabilityGrpId'] === '') ? 'weekBookedSlots' : ''
      };
      let time = this.currentTimeLine(start);
      slotData.formatTime = time;
      slotData.id = time;
      slotData.img = this.addSvgs(time);
      // tslint:disable-next-line:radix
      if (duration > 30 && parseInt(time.split(' ')[0].split(':')[0]) === 11 && parseInt(time.split(' ')[0].split(':')[1]) >= 1 && time.split(' ')[1] === 'PM' && !isWeek && !slotData.available) {
        // tslint:disable-next-line:radix
        let actualDuration = 30 - parseInt(time.split(' ')[0].split(':')[1]);
        slotData.slotHeight = `${Math.floor(38 * (actualDuration / 5))}px`;
        console.log(slotData.slotHeight);
      }
      if (duration === 20 && time === '11:50 PM' && !isWeek) {
        slotData.slotHeight = `${Math.floor((38 * (duration / 5)) / 2)}px`;
      }
      timeArr.push(slotData);
      start.setMinutes(start.getMinutes() + interval); // increment by 5 minutes
      i++;
    }
    timeArr = timeArr.map(eachSlot => {
      return {...eachSlot, available: this.adminPermissions.APPOINTMENT_CREATE ? eachSlot.available : false, isClass: this.adminPermissions.APPOINTMENT_CREATE ? eachSlot.isClass : 'weekBookedSlots' };
    });
    return timeArr;
  }

  // Svgs for Time labels
  addSvgs(time) {
    if (this.viewByRadioBtn === 'day') {
      if (time === '6:30 AM') {
        return 'Morning';
      } else if (time === '12:00 PM') {
        return 'Mid-day';
      } else if (time === '4:00 PM') {
        return 'Afternoon';
      } else if (time === '6:00 PM') {
        return 'Evening';
      } else if (time === '8:00 PM') {
        return 'Night';
      } else {
        return '';
      }
    } else {
      if (time === '6:30 AM') {
        return 'Morning';
      } else if (time === '12:30 PM') {
        return 'Mid-day';
      } else if (time === '4:30 PM') {
        return 'Afternoon';
      } else if (time === '6:30 PM') {
        return 'Evening';
      } else if (time === '8:30 PM') {
        return 'Night';
      } else {
        return '';
      }
    }
  }
  slotClicked(rowData) {
    if (rowData) {
      this.calendarSlotEvent.emit(rowData);
    }
  }

  timeAmPmConvertion(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    var index = time.indexOf(time[3]);
    if (index !== -1) {
      time.splice(index, 1);
    }
    return time.join(''); // return adjusted time or original string
  }
  D(J) { return (J < 10 ? '0' : '') + J; }
  addMinutesToTime(time, minsToAdd) {

    var piece = time.split(':');

    var mins = piece[0] * 60 + +piece[1] + +minsToAdd;

    // tslint:disable-next-line:no-bitwise
    return this.D(mins % (24 * 60) / 60 | 0) + ':' + this.D(mins % 60);
  }

  getBookedValue(rowData) {
    return rowData.maxApptCount < rowData.list.length ? rowData.list.length : rowData.maxApptCount;
  }

}
