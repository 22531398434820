import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';

import { ActivateGuard } from '@app/common/services/activate.guard';
import { UserProfileComponent } from './user-profile/user-profile.component';




const routes: Routes = [
  {
    path: ROUTE_PATHS.EMPTY,
    component: UserProfileComponent,
    // canActivate: [ActivateGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserProfilePageRoutingModule { }
