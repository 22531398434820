import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';
import { API_URLS } from '@app/common/constants/api-urls.constant';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { TOAST_STATUSES, DEFAULT_PAGINATION_LIMIT, HTTPMethods } from '@app/common/constants/util.constant';
import { environment } from '@src/environments/environment';

// declare var stripe: any;
@Component({
  selector: 'app-patient-document-details',
  templateUrl: './patient-document-details.component.html',
  styleUrls: ['./patient-document-details.component.scss']
})
export class PatientDocumentDetailsComponent implements OnInit {
  paymentRedirectUrl=environment.paymentRedirectUrl;
  requestId: any;
  documentDetails: any;
  makeAsPaidPopupShowHide: boolean = false;
  isDocs: boolean = false;
  isAssign: boolean = false;
  makeAsPaidPopupShow: boolean = false;
  patientsDetails: any; // Patiient details data
  detailPageUpload: any;
  reportsDocFiles: any[];
  showPopup: boolean = false;
  uploadUrlBody: any;
  remainingValue: number = 350;
  otherValue: any = '';
  otherValue2: any = '';
  appointmentForForm: any;
  appointmentForFormConfig: any = [     {
  type: 'dropdown',
  placeholder: 'Select',
  label: '',
  required: true,
  key: 'primaryholder',
  labelImage: '',
  value: '',
  list: [],
  controlType: 'dropdown',
  editable: true,
  emptyValueMessage: 'Please select patient',
  validatorsData: ['required'],
  validators: [],
  className: 'drop--value',
  focus: true,
  showontoggle: true,
}
  ]
  showOtherError: boolean = false;
  updatedFilesCount = 0;
  uploadFilesData = {
    patientName: '',
    patientDOB: '',
    updatedFilesCount: this.updatedFilesCount,
  };
  breadCrumbData = {
    link: '',
    linkText: 'Document Request Details'
  };
  displayMarkAsComplete: boolean = false;
  generateInvoicePopupShowHide: boolean = false;
  popupData: any;
  amountGenerated: { copay: any; due: any; pay: any; comment: any; };
  invoiceStatus: any;
  employeID: any;
  empId: any;
  empValue: any;
  empFirstName: any;
  paymentToken: string;

  constructor(private router: Router, private route: ActivatedRoute, private http: HttpService, private observable: ObservableHelperService, private httpClient: HttpClient, private formService: CustomFormHandlerService,) {
    this.route.params.subscribe(params => {
      this.requestId = params.id;
      this.getRequestedDocumentDetails();
    });
  }


  ngOnInit() {
    this.appointmentForForm = this.formService.createNewForm(this.appointmentForFormConfig);
    this.getEmployeList()
  }

  // This function is for getting the request document details
  getRequestedDocumentDetails() {
    const payload = {
      type: HTTPMethods.GET,
      url: API_URLS.PATIENT_REQUEST_DOCUMENTS,
      isDeveloper: true,
      pathVariables: [this.requestId],
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.documentDetails = res.data;
        this. patientsDetails =res.data;
        this.documentDetails.documentURLs = this.massageDocumentUrls(this.documentDetails.documentURLs);
        this.documentDetails.adminDocumentURLs = this.massageDocumentUrls(this.documentDetails.adminDocument);
        this.documentDetails.signedAdminDocumentURLS = this.massageDocumentUrls(this.documentDetails.adminDocURLs);
        this.documentDetails.rejectedAdminDocumentURLS = this.massageDocumentUrls(this.documentDetails.adminDocument1);
        this.uploadFilesData.patientName = this.documentDetails.lastName;
        this.uploadFilesData.patientDOB = this.documentDetails.dob;
        this.detailPageUpload = [];
        this.appointmentForFormConfig[0].value = this.documentDetails.empId;
        this.employeID = this.documentDetails.empId;
        this.empId = this.documentDetails.empId;
        this.uploadFilesData.updatedFilesCount = 0;
        this.documentDetails.status = this.getStatusLabel(this.documentDetails.status);
        this.documentDetails.adminStatus = this.getStatusLabel(this.documentDetails.adminStatus);
        this.documentDetails.statusLabel = 'Completed Date';
        if (this.documentDetails.status === 'Cancelled') {
          this.documentDetails.statusLabel = 'Cancelled Date';
        }
      } else {
        this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.DOCUMENT_DATA_REQUEST_ERROR_MSG);
      }
    });
  }
  getEmployeList() {
    const payload = {
      type: HTTPMethods.GET,
      url: API_URLS.EMPLOYE_LIST,
      isDeveloper: true,
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.appointmentForFormConfig[0].list= res.data;
      }
    });
  }
  popUpClick(){
    this.showPopup = false;
  }
  showPopupFun(){
    this.showPopup = false;
  }
  rejectChanges(){
    this.showPopup = true;
  }
  rejectAction(){
    let bodyData = {
      requestId: this.patientsDetails.requestId.toString(),
      empId: this.employeID,
      adminComments: this.otherValue2
    };
    let payloadData = {
      type: HTTPMethods.POST,
      url: API_URLS.REJECT_DOC,
      isDeveloper: true,
      body: bodyData,
    };

    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res))  {
        this.observable.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.REJECT_DATA_SUCCESS)
        this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.PATIENT_REQU_DOCUMENTS}`]);
      }
      else {
        this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.REJECT_DATA_ERROR);
      }
    });
  }
  conform(){
    let bodyData = {
      requestId: this.patientsDetails.requestId.toString(),
      empId: this.employeID,
      adminComments: this.otherValue
    };
    let payloadData = {
      type: HTTPMethods.POST,
      url: API_URLS.CONFORM_DOC,
      isDeveloper: true,
      body: bodyData,
    };

    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res))  {
        this.observable.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.CONFIM_DOCS_CONTENT);
        this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.PATIENT_REQU_DOCUMENTS}`]);
      }
      else {
        this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.SEND_CHNAGES_DATA);
      }
    });
  }
  conformComments(){
    if(this.employeID.length === 0){
      this.isAssign = true;
    }
    else{
      this.isAssign = false;
    let bodyData = {
      empFirstName:this.empFirstName,
      requestId: this.patientsDetails.requestId.toString(),
      empId: this.employeID,
      adminComments: this.otherValue
    };
    let payloadData = {
      type: HTTPMethods.POST,
      url: API_URLS.CONFORM_DOC,
      isDeveloper: true,
      body: bodyData,
    };

    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res))  {
        this.observable.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.CONFIRM_DOCS);
        this.getRequestedDocumentDetails();
      }
      else {
        this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.SEND_CHNAGES_DATA);
      }
    });
  }
  }
  uploadDocs(){
    if(this.detailPageUpload.length === 0){
      this.isDocs =  true;
    }
    else{
      let bodyData = {
        requestId: this.patientsDetails.requestId.toString(),
      };
      let payloadData = {
        type: HTTPMethods.POST,
        url: API_URLS.UPLOAD_DOCS,
        isDeveloper: true,
        body: bodyData,
      };

      this.http.makeHttpRequest(payloadData).subscribe((res) => {
        if (this.http.isSuccessfulResponse(res))  {
          this.observable.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.UPLOADS_DOCS)
          this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.PATIENT_REQU_DOCUMENTS}`]);
        }
        else {
          this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.SEND_CHNAGES_DATA);
        }
      });
    }
  }
  getDropValueList(event){
    this.empFirstName = event.locationValue.label;
    this.employeID = event.locationValue.value;
    this.empValue =  event.locationValue.value;
    this.isAssign = false;
  }
  uploadFunctionCall(data) {
    this.uploadUrlBody = {
      uploadType: 'patient_request',
      refId: this.patientsDetails.patientId.toString(),
      docUrl: data,
      patientDocReqId: this.patientsDetails.requestId.toString()
    };
    this.getUploadedFilesURL(data);
    const payloadData = {
      type: HTTPMethods.GET,
      url: API_URLS.REQUEST_DOCUMENT_DETAILS,
      // isDeveloper: true,
      // pathVariables: [this.requestId],
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (res) {
      }
    });
  }
  getUploadedFilesURL(filed) {
    let payloadData;
    payloadData = {
      type: HTTPMethods.POST,
      url: API_URLS.UPLOADS_FILES,
      isDeveloper: true,
      body: this.uploadUrlBody,

    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.detailPageUpload.push(res.data);
        this.detailPageUpload = this.messageUploads(this.detailPageUpload);
        this.isDocs = false;
        if (res.data.docUrl !== '') {
          this.observable.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.SUCCESSFULL_UPLOAD);
        }
      } else {
        let message = TOAST_MESSAGES.UPDATAE_FILES_ERROR;
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
  donwloadFile(url, fileName, flag) {
    let fullFileName = '';
    if (flag === true) {
      fullFileName = fileName.name + '.' + fileName.extenstion;
    } else {
      fullFileName = fileName.file.name + '.' + fileName.file.extenstion;
    }
    // tslint:disable-next-line:only-arrow-functions
    fetch(url).then(function(t) {
      return t.blob().then((b) => {
        var a = document.createElement('a');
        a.href = URL.createObjectURL(b);
        a.setAttribute('download', fullFileName);
        a.click();
      }
      );
    });
  }
  deleteFileUpload(data) {
    let bodyData = {
      uploadType: 'patient_request',
      refId: this.patientsDetails.patientId.toString(),
      uploadId: data.uploadId,
      patientDocReqId: this.patientsDetails.requestId.toString()
    };
    let payloadData = {
      type: HTTPMethods.DELETE,
      url: API_URLS.UPLAOD_FILE_DELETE,
      isDeveloper: true,
      body: bodyData,
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.detailPageUpload = this.messageUploads(this.deleteUpload(data));
        this.observable.showToast(TOAST_STATUSES.SUCCESS, res.data);
      } else {
        let message = TOAST_MESSAGES.DELETE_FILES_ERROR;
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
  deleteUpload(data) {
    let uploads = [];
    this.detailPageUpload.forEach((each, index) => {
      if (each.uploadId !== data.uploadId) {
        uploads.push(each);
      }
    });
    return uploads;
  }
  /**
   *
   * @param docUrls list of document urls
   * @returns list with file name and url
   * This function is for massaging the document urls
   */
  massageDocumentUrls(docUrls) {
    if (!docUrls && !Array.isArray(docUrls)) { return []; }
    return docUrls.map(eachUrl => {
      return {
        url: eachUrl,
        fileName: eachUrl.includes('__name__') ? 
        eachUrl.substring(eachUrl.lastIndexOf('__name__') + 8, eachUrl.indexOf('?')) :
        eachUrl.substring(eachUrl.lastIndexOf('/') + 1, eachUrl.lastIndexOf('?'))
      };
    }
    );
  }
  messageUploads(data) {
    // Get all uploads list form API
    let uploadsList = [];
    let fileName;
    data.forEach(eachupload => {
      if (eachupload !== '') {
        let docObj = { ...eachupload };
        fileName = eachupload.docUrl.includes('__name__') ? eachupload.docUrl.substring(
          eachupload.docUrl.lastIndexOf('__name__') + 8,
          eachupload.docUrl.lastIndexOf('?')) : eachupload.docUrl.substring(eachupload.docUrl.lastIndexOf('/') + 1, eachupload.docUrl.lastIndexOf('?'));    
        docObj['file'] = this.splitFileName(fileName, eachupload.docUrl);
        docObj['isKetaminePdf'] = fileName.includes('KETAMINE_MONITORING_FORM') || fileName.includes('KETAMINE_ENROLLMENT_FORM') 
        uploadsList.push(docObj);
      }
    });
    return uploadsList;
  }
  splitFileName(file, docURL) {
    let fileObj = {};
    let splitFileName = file.split('.');
    fileObj['name'] = splitFileName[0];
    fileObj['extenstion'] = splitFileName[splitFileName.length - 1];


    fileObj['file'] = docURL;
    return fileObj;
  }
  /**
   * This function is for elobrating status to display in UI
   * @param status //P,D,C
   * @returns //Pending,Cancelled,Complete
   */
  getStatusLabel(status) {
    if (status && status === 'P') {
      return 'Pending';
    } else if (status && (status === 'C')) {
      return 'Completed';
    } else if (status && status === 'D') {
      return 'Cancelled';
    }else if (status && (status === 'PFR')) {
      return 'Pending For Review';
    } else if (status && status === 'RP') {
      return 'Review Pending';
    }else if (status && (status === 'PP')) {
      return 'Payment Pending';
    } else if (status && status === 'UP') {
      return 'Under Process';
    }else if (status && (status === 'DUP')) {
      return 'Document Under Process';
    } else if (status && status === 'PPR') {
      return 'Pending Patient Review';
    }
    else if (status && (status === 'IP')) {
      return 'Invoice Pending';
    }
    else if (status && (status === 'ENA')) {
      return 'Emplouyee Not Assigned';
    }
    else if (status && (status === 'RJ')) {
      return 'Rejected';
    }
    else if (status && (status === 'PC')) {
      return 'Payment Completed';
    }
    else if (status && (status === 'DURPF')) {
      return 'Document Under Review Pending For Approval';
    }
    else if (status && (status === 'PA')) {
      return 'Patient Approved Waiting For Dr Signature';
    }
    else if (status && (status === 'PCP')) {
      return 'Patient Comments Pending';
    }
    else if (status && (status === 'AE')) {
      return 'Assign Employee';
    }
  }

  // This method for closing the popup
  documentsRequestPopupStatusFun(status) {
    this.displayMarkAsComplete = status.cancelpopup;
    if (status.apiStatus) {
      this.getRequestedDocumentDetails();
    }
  }

  // This function is for mark as complete popup data
  goToMarkAsComplete() {
    this.displayMarkAsComplete = true;
    this.popupData = {
      label: 'Mark Request As Complete',
      type: 'markAsRequestComplete',
      requestId: this.requestId
    };
  }

  // This function is for Cancel request popup data
  cancelRequest() {
    this.displayMarkAsComplete = true;
    this.popupData = {
      label: 'Cancel Request',
      type: 'cancelRequest',
      requestId: this.requestId
    };
  }

  // This method is for sending reminder to patient
  sendRemainder() {
    const payload = {
      type: HTTPMethods.GET,
      url: API_URLS.SEND_REMINDER,
      isDeveloper: true,
      pathVariables: [this.requestId],
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.observable.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.SUCCESSFULL_REMIDNDER_SENT_MSG);
      } else {
        this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.SEND_REMINDER_ERROR_MSG);
      }
    });

  }
  /**
   *
   * @param urls :list of document urls
   * This function is for sending document urls to the download funciton
   */
  downloadAll(urls) {
    if (urls && urls.length) {
      urls.forEach(doc => {
        this.downloadImage(doc.url);
      });
    }
  }

  // This function is for downloading the documents
  downloadImage(img) {
    const imgUrl = img;
    const imgName = imgUrl.includes('__name__') ?  
    imgUrl.substring(imgUrl.lastIndexOf('__name__') + 8, imgUrl.indexOf('?')) :
    imgUrl.substring(imgUrl.lastIndexOf('/') + 1, imgUrl.lastIndexOf('?'))
    this.httpClient.get(imgUrl, { responseType: 'blob' as 'json' })
      .subscribe((res: any) => {
        const file = new Blob([res], { type: res.type });

        const blob = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = blob;
        link.download = imgName;
        // Version link.click() to work at firefox
        link.dispatchEvent(new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        }));

        setTimeout(() => { // firefox
          window.URL.revokeObjectURL(blob);
          link.remove();
        }, 100);
      });
  }
  appointmentsStatusActions() {
    this.generateInvoicePopupShowHide = true;
  }
  generateInvoicePopupShowHideStatusFun(status) {
    this.generateInvoicePopupShowHide = status.cancelpopup;
    // this.checkingSidebarSetStatus(false);
  }
  resendInvoice() {
    let payload = {
      type: HTTPMethods.GET,
      url: API_URLS.INVOICE_RESEND,
      isDeveloper: true,
      pathVariables: [this.documentDetails.invoiceId]
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.observable.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.INVOICE_RE_SENT);
      } else {
        let message = (res.error) ? res.error.message : TOAST_MESSAGES.RESEND_INVOICE;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
  markAsPaid(){
    this.makeAsPaidPopupShow = true;
  }
  markAsInvoice(data){
    let bodyData = {
      status: 'paid',
      paymentMode: data.form.value.paymentMode,
      referenceId: data.form.value.referenceId,
      invoiceType: 'document_request_invoice',
      requestId: this.patientsDetails.requestId.toString(),
    };
    let pathVariable = this.patientsDetails.invoiceId;
    let payloadData = {
      type: HTTPMethods.PUT,
      url: API_URLS.INVOICE_MARK_AS_PAID,
      isDeveloper: true,
      pathVariables: [pathVariable ? pathVariable : null],
      body: bodyData,
    };

    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.observable.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.CONFIRM_PAYMENT);
        // this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.PATIENT_REQU_DOCUMENTS}`]);
        this.getRequestedDocumentDetails();
        this.makeAsPaidPopupShow = false;
      }
      else {
        let message = (res.error) ? res.error.message : TOAST_MESSAGES.RESEND_INVOICE;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
        this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.PATIENT_REQU_DOCUMENTS}`]);
      }
    });
  }
  generateInvoice(data) {
    if (data) {
      // tslint:disable-next-line:one-variable-per-declaration
      let method, callUrl, bodyData, pathVariable, message; let callAPI = false;
      data.values ? this.amountGenerated = {
        copay: data.values.copayAmt,
        due: data.values.dueAmt,
        pay: (data.values.copayAmt + data.values.dueAmt),
        comment: data.values.comments
      // tslint:disable-next-line:no-unused-expression
      } : null;

      if (data.btnType === 'sendCustomer' || (data.btnType === 'submit')) {
        method = 'POST';
        callUrl = API_URLS.GENERATE_INVOICE_REQUEST;
        data.btnType === 'sendCustomer' ?
          bodyData = {
            type: 'NEW',
            requestId: this.documentDetails.requestId,
            status: 'unpaid',
            copay: this.amountGenerated.copay,
            // pay: this.amountGenerated.pay
            pay: this.amountGenerated.due,
            invoiceType: 'document_request_invoice',
            comment: this.amountGenerated.comment
          } : bodyData = {
            type: 'NEW_PAID',
            requestId: this.documentDetails.requestId,
            status: 'paid',
            copay: this.amountGenerated.copay,
            // pay: this.amountGenerated.pay,
            pay: this.amountGenerated.due,
            paymentMode: data.form.value.paymentMode,
            referenceId: data.form.value.referenceId,
            invoiceType: 'document_request_invoice',
            comment: this.amountGenerated.comment
          };
        message = data.btnType === 'sendCustomer' ? TOAST_MESSAGES.INVOICE_CUSTOMER : TOAST_MESSAGES.INVOICE_MARKED;
        // tslint:disable-next-line:no-unused-expression
        data.btnType === 'submit' ? this.makeAsPaidPopupShowHide = false : null;
        callAPI = true;
      } else if (data.btnType === 'makePayment') {
        // this.observable.showToast(TOAST_STATUSES.SUCCESS, 'This feature is coming out soon..!!');
        let payload = {
          type: HTTPMethods.POST,
          url: API_URLS.CHECKOUT_SESSION,
          isDeveloper: true,
          body: {
            type: 'NEW',
            requestId: this.documentDetails.requestId,
            copay: this.amountGenerated.copay,
            pay: this.amountGenerated.due,
            invoiceType: 'document_request_invoice'
          }
        };
        this.http.makeHttpRequest(payload).subscribe((res) => {
          if (res.error) {
            alert(res.error.message);
            return;
          } else {
            this.paymentToken = res.result.response.token
            let inputElement = document.getElementById("paymentvalue") as HTMLInputElement;
            // Set the value of the input element
            inputElement.value = this.paymentToken;
            // Get the form element
            let form = document.getElementById('formAuthorizeNetPage') as HTMLFormElement;
            form.method = 'POST';
            form.submit();
          }
          // stripe.redirectToCheckout({ sessionId: res.id });
        });
        // this.checkingSidebarSetStatus(false);
      } else if (data.btnType === 'markAsPaid') {
        this.generateInvoicePopupShowHide = false;
        this.makeAsPaidPopupShowHide = true;
      }

      if (callAPI) {
        // API Call for all
        callAPI = false;
        const payload = {
          type: method,
          url: callUrl,
          isDeveloper: true,
          pathVariables: [pathVariable ? pathVariable : null],
          body: bodyData ? bodyData : null,
        };
        this.http.makeHttpRequest(payload).subscribe((res) => {
          if (this.http.isSuccessfulResponse(res)) {
            // this.getAppointmentDetails();
            this.observable.showToast(TOAST_STATUSES.SUCCESS, message);
            // this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.PATIENT_REQU_DOCUMENTS}`]);
            this.getRequestedDocumentDetails();
          } else {
            this.observable.showToast(TOAST_STATUSES.ERROR, res.error.message);
          }
        });
      }
    }
  }
  makeAsPaidPopupShowHideStatusFun(status) {
    this.makeAsPaidPopupShowHide = status.cancelpopup;
  }
  makeAsPaidPopupShowHideStatus(status){
    this.makeAsPaidPopupShow = status.cancelpopup;
  }
  textCounter(event) {
    this.remainingValue = 350 - (event.target.value.length);
    this.showOtherError = false;
  }
}
