<!-- consultation section start -->
<section class="consultation__section section-padding full-width">
    <!-- consultation container start -->
    <div class="consultation__container restrict-width padding-lr">
        <app-section-header [headingData]="headingData"></app-section-header>
        <!-- booking slot section start -->
        <div class="booking-slot__section">
            <!-- booking-slot__tabs-section start -->
            <!-- booking-slot__tabs-section end -->
        </div>

        <p-tabView (onChange)="handleChange($event)">
            <p-tabPanel [header]="tab.label" *ngFor="let tab of headerNavItems; let i = index" [selected]="i == tabIndex">
                <div class="booking-slot__buttons-block" *ngIf="isShow">
                        <ng-container *ngFor="let location of tab.content">
                            <div class="each-treatment" *ngIf="location.calendar && location.calendar.length">
                                    <div class="treatment" >
                                        {{location.appointmentTypeName}}
                                    </div>
                                    <div class="slots--block"> 
                                        <div class="slots" *ngFor="let slot of location.calendar| slice:0:5">
                                            <app-booking-slot [metaData]='location' [timeSlots]='slot'></app-booking-slot>
                                        </div>
                                    </div>
                            </div>
                        </ng-container>
                        
                        <ng-container *ngIf="checkSlots(tab)">
                                    <div class="notavailable--msg">
                                       Today there are no available slots for this location</div>
                        </ng-container>
                </div>
            </p-tabPanel>
        </p-tabView>
        <!-- booking slot section end -->
        <div class="view-more__button-block" (click)="naviagateLoginPage()">
            <app-know-more [moreText]="moreText"></app-know-more>
        </div>
    </div>
    <!-- consultation container end -->
</section>
<!-- consultation section end -->