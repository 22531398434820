// This component is for updating the email template details.

import { Component, OnInit } from '@angular/core';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { TOAST_STATUSES, HTTPMethods, emailTemplateTypeEnums } from '@app/common/constants/util.constant';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { API_URLS } from '@app/common/constants/api-urls.constant';
import { HttpService } from '@app/common/services/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EMAIL_EDIT_KEYS } from '@app/common/constants/form-keys.constant';
import { DomSanitizer } from '@angular/platform-browser';
import { IEmailTemplateDetails, IEmailSearchResult, IGetMethodPayload, IPutMethodPayload, IPostMethodPayload, IEmailSearchBody, IGetPermissionsPayloadI } from '@app/common/constants/typeInterfaces.constants';

@Component({
  selector: 'app-email-template-edit',
  templateUrl: './email-template-edit.component.html',
  styleUrls: ['./email-template-edit.component.scss']
})

export class EmailTemplateEditComponent implements OnInit {
  cancelPopupShowHide: boolean;
  inputChanged: Subject<string> = new Subject<string>();
  searchInput: string = '';
  formSubmitAttempt: boolean;
  emailTemplateId: string;
  templateData: IEmailTemplateDetails;
  forceFieldBlur: boolean = false;
  summaryText: string = ""
  formGroup: Record<string, any>;
  formInputs: Array<Record<string, any>>;
  emailSearchList: Array<string> = [];
  result: IEmailSearchResult = {};
  excludedEmailsList: Array<string> = [];
  permissionsDone: boolean = false;

  constructor(private formService: CustomFormHandlerService,
    private formBuilder: FormBuilder,
    private observableHelperService: ObservableHelperService,
    private http: HttpService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private sanitized: DomSanitizer
  ) {
    this.inputChanged
      .pipe(debounceTime(1500))
      .pipe(distinctUntilChanged())
      .subscribe(newValue => {
        this.searchInput = newValue;
        this.getSearchEmails(this.searchInput);
      });

    // get the id of the email template.
    this.activeRoute.queryParams.subscribe(params => {
      this.emailTemplateId = this.router.url.split('/').pop().split("?")[0];
    });
  }

  breadCrumbData = {
    link: '/admin/' + `${ROUTE_PATHS.EMAIL_TEMPLATES}`,
    linkText: 'Edit '
  };

  ngOnInit() {
    this.getAdminPermissions();
    this.initForm(this.formService.emailTemplateEditConfig);
    this.formService.emailTemplateEditConfig[5].showontoggle = false;
    this.formService.emailTemplateEditConfig[6].showontoggle = false;
    this.formInputs = this.formService.emailTemplateEditConfig;
    this.getEmailTemplateData();
  }

  getEmailTemplateData() {
    const payloadData: IGetMethodPayload = {
      type: HTTPMethods.GET,
      url: API_URLS.EMAIL_TEMPLATE_DETAILS,
      pathVariables: [this.emailTemplateId],
      isDeveloper: true
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.templateData = res.data;
        this.setInitValues();
        this.setFormValidations();
        this.templateData.htmlTemplate = this.sanitized.bypassSecurityTrustHtml(this.templateData.htmlTemplate);
        this.editContent();
      } else {
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.GET_EMAIL_TEMPLATE_DETAILS_FAIL);
      }
    });
  }

  setFormValidations() {
    this.breadCrumbData.linkText = this.breadCrumbData.linkText + this.templateData.emailName;
    this.formInputs = this.formService.emailTemplateEditConfig;
    this.excludedEmailsList = this.templateData.excludedEmails;
    // this.setValidations(4);
    // this.setValidations(5);
    // this.setValidationSendingTime();
    // this.showOrHideSendingTime(true)
    if(!this.templateData.sendingTime && this.emailTemplateId != emailTemplateTypeEnums.infoRequest){
      this.clearValidations(2);
      this.clearValidations(3);
      this.showOrHideSendingTime(false);
    }
    if(!this.templateData.sendingTime && this.emailTemplateId == emailTemplateTypeEnums.infoRequest){
      this.clearValidations(4);
    }
    if(!this.templateData.repeatFrequency){
      this.clearValidations(6);
    }
    if(!this.templateData.frequency){
      this.clearValidations(5);
    }
    if (this.emailTemplateId == emailTemplateTypeEnums.tmsFollowUp) {
      this.clearValidations(6);
      this.setValidations(5);
    } else if (this.emailTemplateId == emailTemplateTypeEnums.adhd || this.emailTemplateId == emailTemplateTypeEnums.vitals) {
      this.clearValidations(5);
      this.setValidations(6);
    } else if (this.emailTemplateId == emailTemplateTypeEnums.ktmFeedback) {
      this.clearValidations(5);
      this.clearValidations(6);
    } else if (this.emailTemplateId == emailTemplateTypeEnums.medicinePres) {
      this.clearValidations(5);
      this.clearValidations(6);
      this.clearValidations(2);
      this.clearValidations(3);
      this.showOrHideSendingTime(false);
    } else if (this.emailTemplateId == emailTemplateTypeEnums.therapistRemainder) {
      this.clearValidations(5);
      this.clearValidations(6);
    } else if (this.emailTemplateId == emailTemplateTypeEnums.therapistInfo) {
      this.clearValidations(5);
    } else if (this.emailTemplateId == emailTemplateTypeEnums.benefits) {
      this.clearValidations(5);
      this.clearValidations(6);
      if(!this.templateData.sendingTime){
        this.clearValidations(2);
        this.clearValidations(3);
        this.showOrHideSendingTime(false);
      }
    }
  }

  /**
   * Set initial values of the form.
   */
  setInitValues() {
    this.formService.emailTemplateEditConfig[0].value = this.templateData.subject;
    this.formService.emailTemplateEditConfig[2].value = ((this.templateData.sendingTime % 12) || 12).toString();
    this.formService.emailTemplateEditConfig[3].value = this.templateData.sendingTime >= 12 ? 'pm' : 'am';
    if (this.emailTemplateId == emailTemplateTypeEnums.tmsFollowUp) {
      this.formService.emailTemplateEditConfig[5].showontoggle = true;
      this.formService.emailTemplateEditConfig[5].value = this.templateData.frequency.toString();
    }
    if (this.emailTemplateId == emailTemplateTypeEnums.adhd || this.emailTemplateId == emailTemplateTypeEnums.phq  || this.emailTemplateId == emailTemplateTypeEnums.vitals ||  this.emailTemplateId == emailTemplateTypeEnums.therapistInfo) {
      this.formService.emailTemplateEditConfig[6].showontoggle = true;
      this.formService.emailTemplateEditConfig[6].value = this.templateData.repeatFrequency.toString();
    }
    if(this.emailTemplateId == emailTemplateTypeEnums.infoRequest) {
      this.clearValidations(2);
      this.clearValidations(3);
      this.showOrHideSendingTime(false);
      this.setValidations(4)
      this.formService.emailTemplateEditConfig[4].showontoggle = true;
      this.formService.emailTemplateEditConfig[4].value = this.templateData.sendingTime.toString();
    } else {
      this.showOrHideSendingTime(true);
      this.setValidationSendingTime();
      this.clearValidations(4);
      this.formService.emailTemplateEditConfig[4].showontoggle = false;
    }
    this.formService.emailTemplateEditConfig[1].value = this.templateData.triggerCondition;
  }

  /**
   * Init form
   * @param formType FormConfig
   */
  initForm(formType: Array<Record<string, any>>) {
    // LoginForm
    this.formGroup = this.toReactiveForm(formType);
    this.formInputs = formType;
  }

  /**
  * To convert form data to reactive form
  // tslint:disable-next-line: jsdoc-format
  * @param inputs  - array of input fields
  */
  toReactiveForm(inputs: Array<{}>) {
    let group = {};
    Array.prototype.forEach.call(inputs, (input, i) => {
      if (input.type !== 'header') {
        if (input.controlType === 'formGroup') {
          group[input.key] = this.toReactiveForm(input.formInputs);
        } else if (input.controlType === 'submitButton') {
          // Don't add to form Group
        } else {
          group[input.key] = [(input.value === undefined || input.value === null) ? '' : input.value, input.validators];
        }
      }
    });
    return this.formBuilder.group(group);
  }

  /**
   * TO hide/show scroll of the body
   * @param status scroll visibility
   */
  toggleScroll(status: boolean) {
    document.body.style.overflow = (status) ? 'hidden' : 'scroll';
  }

  /**
   * To hide the popup
   * @param status popup visibility
   */
  cancelPopupShowHideStatusFun(status: Record<string, any>) {
    this.cancelPopupShowHide = status.cancelpopup;
    this.toggleScroll(this.cancelPopupShowHide);
  }

  /**
   * Show more excluded emails.
   */
  moreUsersClicked() {
    this.cancelPopupShowHide = true;
    this.toggleScroll(this.cancelPopupShowHide);
  }

  /**
   * this function is used to check error for a given input control
   * @param control {FormControl} - form control to be validated
   * @param formSubmitted {boolean} - to validate the form on form submission
   */
  isInvalid(control: Record<string, any>, formSubmitted: boolean) {
    return ((control.invalid && (formSubmitted || this.forceFieldBlur) && (control.touched || control.dirty)) ||
      ((formSubmitted || this.forceFieldBlur) && control.invalid)
    );
  }

  /**
   * To create the trigger condition.
   * @param frequency email frequency
   */
  createSummaryText(frequency: string) {
    this.summaryText = this.templateData.triggerCondition.replace(/\d+/g, frequency);
    this.formService.emailTemplateEditConfig[1].value = this.summaryText;
    this.formInputs = this.formService.emailTemplateEditConfig;
  }

  /**
   * Add selected email to excluded emails
   * @param e :selected email data
   */
  selectedEmail(e: Record<string, any>) {
    if (e) {
      if (!this.excludedEmailsList.includes(e.email)) {
        this.excludedEmailsList.unshift(e.email);
      } else {
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.EMAIL_ALREADY_EXCLUDED_ERROR_MSG);
      }
      this.result = {};
    }
  }

  /**
   * search email
   * @param e :enter string in search area
   */
  search(e) {
    if(e.key === 'Enter') {
      if (e.query !== '') {
        this.inputChanged.next(e);
      }
    }
}



  /**
   * @param e :typed string
   * This function is for getting the email ids
   */
  getSearchEmails(e: any) {
    let bodyData: IEmailSearchBody = {
      email: e.target.value
    };
    let payload: IPostMethodPayload = {
      type: HTTPMethods.POST,
      url: API_URLS.SEARCH_MAIL_ADDRESS,
      isDeveloper: true,
      body: bodyData,
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      this.emailSearchList = [];
      if (this.http.isSuccessfulResponse(res) && res) {
        this.emailSearchList = this.filterExcludedEmails(res.data);
      } else {
        let message = TOAST_MESSAGES.EMAILS_DATA_FETCH_ERROR_MSG;
        message = (res.error) ? res.error.message : message;
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  /**
   * To filter the excluded emails
   * @param emailList :list emails
   * @returns :filtering email list from excluded emails
   */
  filterExcludedEmails(emailList: any[]) {
    if (!emailList && !Array.isArray(emailList)) { return []; }
    return emailList.filter(emailData => !this.excludedEmailsList.some(email => email === emailData.email));
  }

  /**
   * Form Submit event
   * @param event Click event
   */
  actionField(event?: Record<string, any>) {
    setTimeout(() => {
      if (document.getElementsByClassName('invalid-feedback')[0]) {
        document.getElementsByClassName('invalid-feedback')[0].scrollIntoView({ block: 'center' });
      }
    }, 2);
    this.formSubmitAttempt = true;
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      this.formSubmitAttempt = false;
      this.saveEmailTemplateChanges(this.formGroup);
    } else {
      this.forceFieldBlur = true;
    }
  }

  /**
   * Form cancel action
   * @param event click event
   */
  clearFormfields(): void {
    this.formGroup.reset();
    this.formSubmitAttempt = false;
    this.router.navigate(['/admin/' + `${ROUTE_PATHS.EMAIL_TEMPLATES}`]);
  }

  /**
   * TO remove validation for hidden fields
   * @param i index of the field
   */
  clearValidations(i: number) {
    this.formGroup.controls[this.formInputs[i].key].clearValidators();
    this.formGroup.controls[this.formInputs[i].key].updateValueAndValidity();
    this.formInputs[i].value = '';
    this.formInputs[i].validators = [];
  }

  /**
   * @param emailId : patient email id
   * This method is for removing email ids
   */
  removeEmailId(emailId: string) {
    if (emailId) {
      this.excludedEmailsList = this.excludedEmailsList.filter(email => email !== emailId);
    }
    if (this.excludedEmailsList.length <= 0 && this.cancelPopupShowHide) {
      this.cancelPopupShowHideStatusFun({cancelpopup: false});
    }
  }

  /**
   * Update email template details.
   * @param data Form Data
   */
  saveEmailTemplateChanges(data: Record<string, any>) {
    var formData = data.value;
    var body = this.setUpdateBody(formData);

    const payloadData: IPutMethodPayload = {
      type: HTTPMethods.PUT,
      url: API_URLS.EMAIL_TEMPLATE_DETAILS,
      pathVariables: [this.emailTemplateId],
      isDeveloper: true,
      body
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.formGroup.reset();
        this.router.navigate([ROUTE_PATHS.ADMIN, ROUTE_PATHS.EMAIL_TEMPLATES]);
        this.observableHelperService.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.UPDATE_EMAIL_TEMPLATE);
      } else {
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.UPDATE_EMAIL_TEMPLATE_FAIL);
      }
    });
  }

  /**
   * Input field value updated.
   * @param field Input field
   * @param event click event
   */
  OnInputValueChange(field: Record<string, any>) {
    field.value = (field.value).replace(/^0*/g, '');
    if (!(this.isInvalid(this.formGroup.controls[field.key], true))) {
      this.createSummaryText(field.value);
    }
  }

  /**
   * Create the payload for updating the email template.
   * @param formData FormData
   */
  setUpdateBody(formData: Record<string, any>) {
    return {
      triggerCondition: formData[EMAIL_EDIT_KEYS.TEMPLATE_EDIT_SUMMARY],
      htmlTemplate: this.getEditedTemplate(),
      subject: formData[EMAIL_EDIT_KEYS.TEMPLATE_EDIT_HEADER],
      repeatFrequency: formData[EMAIL_EDIT_KEYS.TEMPLATE_EDIT_REP_FREQUENCY] ? Number(formData[EMAIL_EDIT_KEYS.TEMPLATE_EDIT_REP_FREQUENCY]) : null,
      frequency: formData[EMAIL_EDIT_KEYS.TEMPLATE_EDIT_FREQUENCY] ? Number(formData[EMAIL_EDIT_KEYS.TEMPLATE_EDIT_FREQUENCY]) : null,
      sendingTime: (this.emailTemplateId == emailTemplateTypeEnums.infoRequest)? formData[EMAIL_EDIT_KEYS.TEMPLATE_INFO_SEND_TIME] :this.formatTime(formData[EMAIL_EDIT_KEYS.TEMPLATE_EDIT_TIME], formData[EMAIL_EDIT_KEYS.TEMPLATE_EDIT_TIME_FORMAT]),
      excludedEmails: this.excludedEmailsList
    }
  }

  /**
   * To convert 12hr format value to 24hr format
   * @param value Time value
   * @param format AM or PM
   */
  formatTime(value: string, format: string): number {
    if(value == '') return null;
    let time = (Number(value) % 12) || 12;
    if (format == 'pm' && time != 12) {
      time = (time == 12) ? 12 : (Number(value) + 12) % 24;
    }
    return time;
  }

  /**
   * This funciton is set contenteditable true for editable div's and set contenteditable false for non-editable div's
   */
  editContent() {
    setTimeout(() => {
      let editableDivs: any = document.getElementsByClassName('editable');
      for (let i = 0; i < editableDivs.length; i++) {
        editableDivs[i].setAttribute('contenteditable', true);
      }
      let notEditableDivs: any = document.getElementsByClassName('non-editable');
      for (let i = 0; i < notEditableDivs.length; i++) {
        notEditableDivs[i].setAttribute('contenteditable', false);
      }
    }, 1000);
  }

  /**
   * This is for getting updated HTML template
   */
  getEditedTemplate() {
    let editableDivs: any = document.getElementsByClassName('editable');
    for (let i = 0; i < editableDivs.length; i++) {
      editableDivs[i].setAttribute('contenteditable', false);
    }
    return document.getElementById('content-edit').innerHTML;
  }

  /**
  * getting permissions based on user id
  */
 getAdminPermissions() {
  const payloadData: IGetPermissionsPayloadI = {
    type: HTTPMethods.GET,
    url: API_URLS.GET_PERMISSIONS,
    isDeveloper: true
  };
  this.http.makeHttpRequest(payloadData).subscribe((res) => {
    if (this.http.isSuccessfulResponse(res)) {
      let permArr: Array<string> = [];
      res.data.permissions.forEach(obj => {
        permArr.push(obj.permissionName);
      });
      if (!permArr.includes('TEMPLATE_EDIT')) {
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.ACCESS_PERMISSOIN_DENIED_MSG);
        this.router.navigate([`${ROUTE_PATHS.ADMIN}`]);
      }
    } else {
      // Error
      let message: string = (res.error) ? res.error.message : TOAST_MESSAGES.ACCESS_PERMISSION_ERROR_MSG;
      this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
    }
    this.permissionsDone = true;
  });
}

  /** set validarions to the form field.
   * @param index index of the form field
   */
  setValidations(index: number){
    this.formGroup.controls[this.formInputs[index].key].setValidators([Validators.required, this.formService.valideteFrequencyValue]);
  }

  /**
   * on value change in the input.
   * @param event click event
   */
  onValueChange(event: any){
    if ( event.key == 'e') {
      event.preventDefault();
    }
  }

  /**
   * To show or hide sending time
   * @param flag true /false
   */
  showOrHideSendingTime(flag: boolean){
    this.formService.emailTemplateEditConfig[2].showontoggle = flag;
    this.formService.emailTemplateEditConfig[3].showontoggle = flag;
  }

  /**
   * set validations for sending time.
   */
  setValidationSendingTime(){
    this.formGroup.controls[this.formInputs[2].key].setValidators([Validators.required, this.formService.validateTimeValue]);
    this.formGroup.controls[this.formInputs[3].key].setValidators([Validators.required]);
  }
}


