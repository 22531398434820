<!-- Employee details -->
<div class="employee__page-wrapper">
    <div class="background-image">
        <img src="assets/images/pngs/newpatientform-background.png" alt="">
    </div>
    <div class="employee__content" *ngIf="isShowScreen">
        <!-- header part -->
        <div class="employee__header {{adminPermissions.EMPLOYEE_EDIT ? '' : 'padding-btm'}}">
            <app-breadcrumb [breadCrumbData]="breadCrumbData"></app-breadcrumb>
            <!-- Actions list -->
            <div class="employee__actions" *ngIf="adminPermissions.EMPLOYEE_EDIT">
                <ng-container *ngFor="let eachAction of employeeDetailsActions; let i=index">
                <div class="employee__action" (click)="employeeActionsClick(eachAction.id, eachAction.icon)" *ngIf="eachAction.display">
                    <app-svg [ngClass]="eachAction.className" [name]="eachAction.icon"></app-svg>
                    <div class="employee__action-name">
                        {{eachAction.name}}
                    </div>
                </div>
            </ng-container>
            </div>
        </div>
        <!-- Employee basic information -->
        <div class="employee__profile">
            <div class="employee__profile-pic">
                <img src={{employeeData.employeeProfileImagLink}} alt="">
            </div>
            <div class="employee__profile-details">
                <div class="employee__profile-name profile-name">
                    {{employeeData.employeeName | titlecase}}
                </div>
                <div class="employee__profile-id-designation">
                    <!-- <div class="employee__profile-id">
                        {{employeeData.employeeDetails.empId}}
                    </div> -->
                    <div class="employee__profile-id">
                        {{employeeData.employeeDetails.designation | titlecase}}
                    </div>
                </div>
                <div class="employee__profile-contacts">
                    <div class="employee__profile-contact phone">
                        <app-svg class="contact-icon" [name]="'adminMobile'"></app-svg>
                        <p>{{employeeData.employeeDetails.mobile}}</p>
                    </div>
                    <div class="employee__profile-contact mail">
                        <app-svg class="contact-icon" [name]="'mail'"></app-svg>
                        <p>{{employeeData.employeeDetails.email}}</p>
                    </div>
                </div>

            </div>
        </div>
        <!-- Employee basic address  -->
        <div class="employee__addvanced-data">
            <div class="employee__advanced-content location">

                <div class="employee__advanced-content-title">
                    <p class="title-text">Working Location</p>
                    <p class="employee__advanced-content-data location">
                        <ng-container
                            *ngFor="let location of employeeData.employeeDetails.workingLocation; let i=index">
                            <p class="employee__location-title"> {{location | titlecase }}
                            </p>
                        </ng-container>
                    </p>
                </div>
            </div>
            <div class="employee__advanced-content resident">
                <div class="employee__advanced-content-title">
                    <p class="title-text">Resident</p>
                    <div class="employee__advanced-content-data">
                        {{employeeData.employeeDetails.country | uppercase}}
                    </div>
                </div>
            </div>
            <div class="employee__advanced-content address">
                <div class="employee__advanced-content-title">
                    <p class="title-text">Mailing Address</p>
                    <div class="employee__advanced-content-data">
                        {{employeeData.employeeDetails.address  | titlecase}}
                    </div>
                </div>
            </div>
            <div class="employee__advanced-content address">
                <div class="employee__advanced-content-title">
                    <p class="title-text">Date of Birth</p>
                    <div class="employee__advanced-content-data">
                        {{employeeData.employeeDOB | dateFormat}}
                    </div>
                </div>
            </div>
            <div class="employee__advanced-content security-number">
                <div class="employee__advanced-content-title">
                    <p class="title-text">{{employeeData.employeeSecurityName1}}</p>
                    <div class="employee__advanced-content-data">
                        {{employeeData.employeeSecurityNumber | ellipsis : 24}}
                    </div>
                </div>
            </div>
            <div class="employee__advanced-content driving-license">
                <div class="employee__advanced-content-title">
                    <p class="title-text">
                        {{employeeData.employeeSecurityName2}}</p>
                    <div class="employee__advanced-content-data">
                        {{employeeData.employeeDrivingLicenseNumbber | ellipsis : 24}}
                    </div>
                </div>
            </div>
            <div class="employee__advanced-content activity">
                <div class="employee__advanced-content-title">
                    <p class="title-text">Activity</p>
                    <div class="employee__advanced-content-data">
                        {{employeeData.employeeloginActivity}}
                    </div>
                </div>
            </div>
        </div>
        <!-- Employee bank  details -->
        <div class="employee__bank-details">
            <div class="employee__bank-title employee__profile-name">
                Bank Details
            </div>
            <div class="employee__addvanced-data">
                <div class="employee__advanced-content">
                    <div class="employee__advanced-content-title">
                        <p class="title-text">Account Number</p>
                        <div class="employee__advanced-content-data">
                            {{employeeData.employeeDetails.bankDetails.accNo}}
                        </div>
                    </div>
                </div>
                <div class="employee__advanced-content">
                    <div class="employee__advanced-content-title">
                        <p class="title-text">Account Holder</p>
                        <div class="employee__advanced-content-data">
                            {{employeeData.employeeDetails.bankDetails.accHolderName | titlecase}}
                        </div>
                    </div>
                </div>
                <div class="employee__advanced-content">
                    <div class="employee__advanced-content-title">
                        <p class="title-text">Bank Name</p>
                        <div class="employee__advanced-content-data">
                            {{employeeData.employeeDetails.bankDetails.bankName | titlecase}}
                        </div>
                    </div>
                </div>
                <div class="employee__advanced-content">
                    <div class="employee__advanced-content-title">
                        <p class="title-text">Branch</p>
                        <div class="employee__advanced-content-data">
                            {{employeeData.employeeDetails.bankDetails.branchName | titlecase}}
                        </div>
                    </div>
                </div>
                <div class="employee__advanced-content">
                    <div class="employee__advanced-content-title">
                        <p class="title-text">IFSC Code</p>
                        <div class="employee__advanced-content-data">
                            {{employeeData.employeeDetails.bankDetails.IFSC | uppercase}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
       <!-- patient id proof  details -->
       <div class="employee__id-proofs">
        <div class="employee__bank-title employee__block-sub-title title-text">
            Documents (ID proof)
        </div>
        <div class="employee__id-proofs-documents">
            <div class="employee__id-document internal-uploads"
                *ngFor="let uploadsFiles of employeeData.employeeIDProfs; let i=index">
                <div class="upload-document__block" *ngIf="uploadsFiles && uploadsFiles.file">
                    <p>{{uploadsFiles.file.name | ellipsis: 23}}</p>
                    <p>.{{uploadsFiles.file.extenstion}}</p>
                </div>
                <div class="upload-close-btn" *ngIf="employeeData.employeeIDProfs.length">
                    <app-svg [name]="'cancel-appointment'" (click)="deleteFileUpload(uploadsFiles)">
                    </app-svg>
                </div>
                <div class="employee__id-document-actions">
                    <a [href]="uploadsFiles.docUrl" target="_blank" title="View" class="view-icon">
                        <app-svg [name]="'view-upload'"></app-svg>
                    </a>
                    <p class="down-arrow" (click)="donwloadFile(uploadsFiles.docUrl,uploadsFiles,false)"
                        title="Download">
                        <app-svg [name]="'down-arrow'"></app-svg>
                    </p>
                </div>
            </div>
            <div class="employee__id-document {{adminPermissions.EMPLOYEE_EDIT ? '' : 'disable'}}">
                <app-multiple-uploads [fileTypes]="uploadFilesData" (uploads)="uploadFunctionCall($event)">
                </app-multiple-uploads>
            </div>
        </div>
    </div>
    <!-- patient permissions -->
        <div class="employee__id-proofs" *ngIf="employeeData.employeePermissions.APPOINTMENTS || employeeData.employeePermissions.PATIENTS || employeeData.employeePermissions.LOCATIONS || employeeData.employeePermissions.EMPLOYEES || employeeData.employeePermissions.INVOICES">
            <div class="employee__bank-title employee__profile-name">
                Permissions
            </div>

            <div class="employee__permissions-list">
                <div class="employee__permission-block" *ngIf="employeeData.employeePermissions.APPOINTMENTS">
                    <div class="employee__permission-name">
                        Appointments
                    </div>
                    <div class="employee__permission-list">
                        <ng-container
                            *ngFor="let employeePermission of employeeData.employeePermissions.APPOINTMENTS; let i=index">
                            <div class="employee__permission-type">
                                {{employeePermission.permission_name | titlecase}}
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="employee__permission-block" *ngIf="employeeData.employeePermissions.PATIENTS">
                    <div class="employee__permission-name">
                        Patients
                    </div>
                    <div class="employee__permission-list">
                        <ng-container
                            *ngFor="let employeePermission of employeeData.employeePermissions.PATIENTS; let i=index">
                            <div class="employee__permission-type">
                                {{employeePermission.permission_name | titlecase}}
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="employee__permission-block" *ngIf="employeeData.employeePermissions.LOCATIONS">
                    <div class="employee__permission-name">
                        Locations
                    </div>
                    <div class="employee__permission-list">
                        <ng-container
                            *ngFor="let employeePermission of employeeData.employeePermissions.LOCATIONS; let i=index">
                            <div class="employee__permission-type">
                                {{employeePermission.permission_name | titlecase}}
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="employee__permission-block" *ngIf="employeeData.employeePermissions.EMPLOYEES">
                    <div class="employee__permission-name">
                        Employees
                    </div>
                    <div class="employee__permission-list">
                        <ng-container
                            *ngFor="let employeePermission of employeeData.employeePermissions.EMPLOYEES; let i=index">
                            <div class="employee__permission-type">
                                {{employeePermission.permission_name | titlecase}}
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="employee__permission-block" *ngIf="employeeData.employeePermissions.INVOICES">
                    <div class="employee__permission-name">
                        Invoices
                    </div>
                    <div class="employee__permission-list">
                        <ng-container
                            *ngFor="let employeePermission of employeeData.employeePermissions.INVOICES; let i=index">
                            <div class="employee__permission-type">
                                {{employeePermission.permission_name | titlecase}}
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="employee__permission-block" *ngIf="employeeData.employeePermissions.EMAIL_TEMPLATES">
                    <div class="employee__permission-name">
                        Email Templates
                    </div>
                    <div class="employee__permission-list">
                        <ng-container
                            *ngFor="let employeePermission of employeeData.employeePermissions.EMAIL_TEMPLATES; let i=index">
                            <div class="employee__permission-type">
                                {{employeePermission.permission_name | titlecase}}
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="employee__permission-block" *ngIf="employeeData.employeePermissions.REQUEST_DOCUMENTS">
                    <div class="employee__permission-name">
                        Request Documents
                    </div>
                    <div class="employee__permission-list">
                        <ng-container
                            *ngFor="let employeePermission of employeeData.employeePermissions.REQUEST_DOCUMENTS; let i=index">
                            <div class="employee__permission-type">
                                {{employeePermission.permission_name | titlecase}}
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="employee__permission-block" *ngIf="employeeData.employeePermissions.PATIENT_REQUEST_DOCUMENTS">
                    <div class="employee__permission-name">
                        Patient Request Documents
                    </div>
                    <div class="employee__permission-list">
                        <ng-container
                            *ngFor="let employeePermission of employeeData.employeePermissions.PATIENT_REQUEST_DOCUMENTS; let i=index">
                            <div class="employee__permission-type">
                                {{employeePermission.permission_name | titlecase}}
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="employee__permission-block" *ngIf="employeeData.employeePermissions.MANAGE_USER_ACCOUNTS">
                    <div class="employee__permission-name">
                        Manage User Accounts
                    </div>
                    <div class="employee__permission-list">
                        <ng-container
                            *ngFor="let employeePermission of employeeData.employeePermissions.MANAGE_USER_ACCOUNTS; let i=index">
                            <div class="employee__permission-type">
                                {{employeePermission.permission_name | titlecase}}
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-common-popup (showHideCancelPopup)='cancelPopupShowHideStatusFun($event)' *ngIf="cancelPopupShowHide">
    <div class="popup-content-block">
        <div class="confirm-message">Are you want to {{popUpText}} this employee?</div>
        <div class="btn-actions">
            <div class="common-btn-style yes-btn" (click)="popUpClick($event,false)">No</div>
            <div class="common-btn-style"
                (click)="popUpClick($event,true)">Yes</div>
        </div>
    </div>
</app-common-popup>