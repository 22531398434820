<section class="dropdown__wrapper--section">
    <!-- lef-right btn section starts here -->
    <div class="btn-action date-action">
        <div [ngClass]=" backwardEnable ?'back':'back--enable'" (click)="selectBackDate()">
            <app-svg [name]="'left-arrow'"></app-svg>
        </div>
        <div [ngClass]="forwardDisable ?'forward--disabled':'forward'" (click)="selectForwardDate()">
            <app-svg [name]="'right-arrow'"></app-svg>
        </div>
    </div>
    <!-- lef-right btn section ends here -->

    <!-- calendar section starts here -->
    <div class="calendar__section">
        <p-calendar [(ngModel)]="currentDate"  dateFormat="(dd M yy)" [selectionMode]="calendarViewType" dateFormat = "mm/dd/yy" readonlyInput = true (onShow) = 'showTypeCalendar($event,true)' (onClose) = 'showTypeCalendar($event,false)' (onSelect)="select($event)"  (onClickOutside) = 'showTypeCalendar($event,false)' [dataType]="'date'" placeholder="Please select date" [minDate]="minDateValue" [maxDate]="maxDateValue">
        </p-calendar>
        <div class="radio__btn--block" *ngIf="isTypeOfCalendar" (click)="viewByDivClick($event)">
            <div class="view--by">View by:</div>
            <div class="radio" *ngFor="let each of typeOfCalendar" (change)="selectedRadio(each,$event)">
                <input id="{{each.value}}" type="radio" [(ngModel)]="selectedCalendarType" [value]="each.value">
                <label for="{{each.value}}" class="radio-label">{{ each.label }}</label>
            </div>
        </div>
    </div>
    <!-- calendar section ends here -->

    <!-- location section starts here -->
    <div class="loctions__section" *ngIf="!isLocationShow">
        <p-dropdown [options]="locationList" (onChange)="getLocationValue($event)">
            <ng-template let-items pTemplate="item">
                <div class="dropdown__items dropdown__header">
                    <div class="table__icon__header" *ngIf="items.icon">
                        <app-svg [name]="items.icon"></app-svg>
                    </div>
                    <div class="item__label">{{items.label}}</div>
                </div>
            </ng-template>
        </p-dropdown>
    </div>
    <!-- location section ends here -->

     <!-- doctors section starts here -->
     <div class="doctors__section">
        <p-dropdown [options]="doctorsList" [(ngModel)]="selectedDoctor" (onChange)="getDoctor($event)">
            <ng-template let-items pTemplate="item">
                <div class="dropdown__items dropdown__header">
                    <div class="table__icon__header" *ngIf="items.icon">
                        <app-svg [name]="items.icon"></app-svg>
                    </div>
                    <div class="item__label">{{items.label}}</div>
                </div>
            </ng-template>
        </p-dropdown>
    </div>
    <!-- doctors section ends here -->


</section>