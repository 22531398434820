import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebsiteactiveGuard implements CanActivate {
  constructor(private router: Router, private activatedRoute: ActivatedRoute) { }
  userLogedIn: any;
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.userLogedIn = JSON.parse(window.localStorage.getItem('isLogedIn'));
    console.log('urltree', window.location.pathname);
    console.log('next');

    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    const templateId = urlParams.get('templateId');
    if(email && templateId){
      window.localStorage.setItem('unsub', 'true');
      window.localStorage.setItem('templateId', templateId);
      window.localStorage.setItem('email', email);
    }

    let websiteLinks = ['/privacy', '/terms', '/faqs'];
    if (!this.userLogedIn || websiteLinks.indexOf(window.location.pathname) !== -1) {
      return true;
    } else {
      this.router.navigate(['myaccount']);
    }
  }

}
