import { Component, OnInit } from '@angular/core';
import { WPAPIService } from '@app/common/services/wpapi.service';
import { Router, ActivatedRoute } from '@angular/router';
import { element } from 'protractor';
import { BlogToHeader } from '@app/common/services/blog-to-header.service';
@Component({
  selector: 'app-home-blog',
  templateUrl: './home-blog.component.html',
  styleUrls: ['./home-blog.component.scss']
})
export class HomeBlogComponent implements OnInit {

  headingData = {
    subHeading: 'Our Blog',
    mainHeading: '',
  };

  moreText = {
    moreTextLink: 'View more',
    // link: './blog'
  };

  colorClass = [
    'blue',
    'yellow',
    'pink',
    'purple',
    'green'
  ];

  posts: any = null;
  constructor(private wpapi: WPAPIService, private router: Router, private blogtoheader: BlogToHeader) { }

  ngOnInit() {
    this.wpapi.posts('per_page=3').subscribe(posts => {
      this.posts = posts.body;
    });
  }

  navigateBlogPage(event) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    // this.router.navigate([`blog`])
    this.blogtoheader.sendMessage('blog');
  }

  navigateDetailsPage(event, postid) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.router.navigate([`blog/details/${postid}`]);
  }

}
