<ng-template #assignuser>
    <div class="pre-header__actions">
        <div class="nav__label" *ngFor="let item of headerNavItems;let i = index"
            [ngClass]="item.selected  && !isInnerPage() ? 'nav-selected' : ''" (click)="navIconSelected(i)">
            {{ item.label | uppercase }}
        </div>
    </div>
</ng-template>

<!--Header section start-->
<section class="common__header--section">
    <div class="common__header--container {{headerShadow}}">
        <div class="header-image" (click)="openLandingPage()">
            <img class="web" alt="" src="{{headerImagePath}}" />
            <img class="mobile" alt="" src="{{headerMobileImagePath}}" />
        </div>

        <div class="header-nav-items">
            <ng-container *ngIf="isShowHeader && !userLogedIn">
                <ng-container *ngTemplateOutlet="assignuser">
                </ng-container>
            </ng-container>

            <div class="post-header__actions" *ngIf="isShowHeader && userLogedIn && !isAdmin">
                <div class="my-account__container">
                    <ng-container>
                        <div class="pre-header__actions">
                            <div class="nav__label post--login" id="{{item.id}}"
                                *ngFor="let item of postHumbergMenu;let i = index"
                                [ngClass]="item.selected  && !isInnerPage() ? 'nav-selected' : ''"
                                (click)="dropdownActionClicked(item.id)">
                                {{ item.label | uppercase }}
                            </div>
                        </div>
                        <!-- <div class="nav__label" *ngFor="let item of postHumbergMenu;let i = index" [ngClass]="item.selected ? 'nav-selected' : ''" (click)="dropdownActionClicked(item.id)">
                            {{ item.label | uppercase }}
                        </div> -->
                    </ng-container>
                    <!-- <div class="my-account__label nav__label" (click)="headerPopupAction(); $event.stopPropagation()">
                        <div class="header-name"> MY PROFILE</div>
                        <div class="dropdown-icon" [ngClass]="popupAction ? 'rotate-dropdown' : '' ">
                            <app-svg [name]="'dropdown'"></app-svg>
                        </div>
                    </div> -->

                    <!-- <div class="my-account__popup" [ngClass]="popupAction ? 'show' : 'hide' " (clickOutside)="popupAction = false">
                        <div *ngFor="let link of headerDropdownAction" (click)="dropdownActionClicked(link.id);popupAction = false">
                            {{link.label}}
                        </div>
                    </div> -->

                </div>

                <!-- <div id="book_appointment--btn" class="book-appointment common-btn-style" (click)="navigateToBooking($event)">
                    Book Appointment
                </div> -->
            </div>
        </div>

        <!-- Admin header code starts here -->
        <div *ngIf="isShowHeader && userLogedIn && isAdmin">
            <div class="admin-account__container">
                <ng-container>
                    <div class="post-header__actions">
                        <div class="nav__icons" *ngFor="let item of adminHeaderItems;let i = index"
                            (click)="adminHeaderActions(item,$event)">
                            <!-- {{ item.label | uppercase }} -->
                            <ng-container *ngIf="item.id != 'profile' else elseBlock">
                                <app-svg [name]="item.icon" *ngIf="item.id == 'search' && !ListSearchComponent"></app-svg>
                                <div class="search-filter" *ngIf="item.id == 'search' && ListSearchComponent" (clickOutside)="onClickedOutside($event,op);">
                                    <div class="search-filter__block" #target>
                                        <app-svg [name]="'search'" class="inside-search"></app-svg>
                                        <input type="text" name="searchValue" [ngModel]='searchInput' (keyup)='inputTextEnter($event,op);' class="input__search" placeholder="Search by Patient, Invoice, Appt." autocomplete="off" id="header-Search"  autofocus>
                                        <app-svg [name]="'erase'" class="close__icon" (click)="clear()" *ngIf="!textEmpty"></app-svg>
                                    </div>
                                </div>
                                <app-svg [name]="item.icon" *ngIf="item.id != 'search'"></app-svg>
                            </ng-container>
                            <ng-template #elseBlock>
                                <div class="profile__block">
                                    <img src={{item.path}} alt="Profile-pic" srcset="">
                                </div>
                            </ng-template>
                            <div class="header__badge {{item.badgeCount >= 100 ? 'font':''}}" *ngIf="item.badgeCount > 0">{{item.badgeCount>100 ? '100+' : item.badgeCount}}</div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <!--Overlay for global-search-patients-list-->
            <p-overlayPanel #op [showTransitionOptions]="'100ms'" [hideTransitionOptions]="'100ms'" [style]="{'width':'583px','margin-left':'27px'}">
                    <app-global-search-input [FilteredObj]="FilteredObj" [SearchText]="searchInput" [Overlay]="overlay"></app-global-search-input>
            </p-overlayPanel>
        </div>
        <!-- Admin header code ends here -->

        <div class="hamburger-nav" (click)="showMobileHeaderMenu()">
            <app-svg [name]="'hamburger'"></app-svg>
        </div>

    </div>
</section>
<div class="hamburger-nav-items" *ngIf="isShowHeader && showMobileMenu && !isAdmin">
    <!-- <div class="nav-item-container">
    <app-svg [name]="'Oval'"></app-svg>
  </div> -->
    <div class="close-menu-icon" (click)="closeMobileHeaderMenu()">
        <app-svg [name]="'close-menu'"></app-svg>
    </div>
    <div class="header-pre-nav-data">
        <ng-container *ngIf="!userLogedIn">
            <ng-container *ngTemplateOutlet="assignuser">
            </ng-container>
        </ng-container>

        <ng-container *ngIf="userLogedIn">
            <div class="nav__label" *ngFor="let item of postHumbergMenu;let i = index"
                [ngClass]="item.selected ? 'nav-selected' : ''" (click)="dropdownActionClicked(item.id)">
                {{ item.label | uppercase }}
            </div>
        </ng-container>


    </div>

    <img class="oval-1" alt="" src="assets/images/pngs/Oval.png" />
    <img [ngClass]="userLogedIn?'post-background__leaf--1':'background__leaf--1'" alt=""
        src="assets/images/pngs/loginpage-leaf-1.webp" loading="lazy"/>
    <img [ngClass]="userLogedIn?'post-background__leaf--2':'background__leaf--2'" alt=""
        src="assets/images/pngs/header-leaf.png" loading="lazy"/>
</div>
<app-my-patient-form-overlay (showHideMyAccountPopup)='myAccountPopupShowHideStatusFun($event)'
    *ngIf="this.myAccountPopupShowHide"></app-my-patient-form-overlay>
<!--Header section end-->

<!--Admin sideBar starts -->
<div class="common__header-side-bar">
    <p-sidebar [(visible)]="sideBarVisible" position="right" styleClass="p-sidebar-width"
        (onShow)="checkingSidebarState(true)" (onHide)="checkingSidebarState(false)" showCloseIcon=false>
        <div class="admin__menu--humberg-block">
            <div class="each__menu {{!each.permission ? 'disable' : ''}}" [ngClass]="each.id === 'adminLogout'?'logout--styles':''"
                *ngFor="let each of adminHumbergMenu" (click)="selectedHumbergMenu(each)">
                <app-svg [name]="'leaf'" *ngIf="each.isSelected && each.id !== 'adminLogout'"></app-svg>
                <div class="menu__lable" [ngClass]="each.isSelected ?'selected__lable':''">{{each.label}}</div>
                <app-svg [name]="'logout-arrow'" *ngIf="each.id === 'adminLogout'"></app-svg>
            </div>
            <div class="background-image">
                <img src="assets/images/svgs/bg-leaf.svg" alt="bg image">
            </div>
        </div>
    </p-sidebar>
</div>
<!--Admin sideBar  ends -->