import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';
import { TOAST_STATUSES } from '@app/common/constants/util.constant';
import { CognitoService } from '@app/common/services/cognito.service';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { FileUpload } from 'primeng/fileupload';
@Component({
  selector: 'app-patient-document-request-details',
  templateUrl: './patient-document-request-details.component.html',
  styleUrls: ['./patient-document-request-details.component.scss']
})
export class PatientDocumentRequestDetailsComponent implements OnInit {
  remainingValue: number = 150;
  showOtherError: boolean;
  requestId: any;
  documentDetails: any;
  uploadedFiles: any;
  documentUrl: any = [];
  otherValue: any = '';
  userDetails: any;
  documentUrls: any = [];
  fileValidation: boolean;

  constructor(private httpClient: HttpClient, private cognitoService: CognitoService, private router: Router, private route: ActivatedRoute, private http: HttpService, private observable: ObservableHelperService) {
    this.route.params.subscribe(params => {
      this.requestId = params.id || 6;
      this.getRequestedDocumentDetails();
    });
  }

  ngOnInit() {
    this.cognitoService.getUser().then(data => {
      this.userDetails = data;
    });
  }


  /**
   *
   * @param event :text area entered value
   * This function is for decreament of entered text count
   * Maximum 150 character user can enter
   * example: if user enters 'Hello world'
   * 150-11= 139 remaining
   */
  textCounter(event) {
    console.log(event.target.value.length);
    this.remainingValue = 150 - (event.target.value.length);
    this.showOtherError = false;
  }

  // This function is for getting the request document details
  getRequestedDocumentDetails() {
    const payload = {
      type: 'GET',
      url: 'adminRequestDetails',
      isDeveloper: true,
      pathVariables: [this.requestId],
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        console.log(res);
        this.documentDetails = res.data;
        this.documentDetails.status = this.getStatusLabel(this.documentDetails.status);
        this.documentDetails.adminDocumentURLs = this.massageDocumentUrls(this.documentDetails.adminDocument);
      } else {
        this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.DOCUMENT_DATA_REQUEST_ERROR_MSG);
      }
    });
  }

  // This function is for sending document urls to the download funciton
  downloadAll() {
    this.documentDetails.adminDocumentURLs.forEach(doc => {
      this.downloadImage(doc.url);
    });
  }
  /**
   *
   * @param img :Image url
   * This funciton is for downloading the single document
   */
  downloadImage(img) {
    const imgUrl = img;
    const imgName = imgUrl.substring(imgUrl.lastIndexOf('__name__') + 8, imgUrl.indexOf('?'));
    this.httpClient.get(imgUrl, { responseType: 'blob' as 'json' })
      .subscribe((res: any) => {
        const file = new Blob([res], { type: res.type });

        // IE
        if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
          (window.navigator as any).msSaveOrOpenBlob(file);
          return;
        }
        

        const blob = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = blob;
        link.download = imgName;
        // Version link.click() to work at firefox
        link.dispatchEvent(new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        }));

        setTimeout(() => { // firefox
          window.URL.revokeObjectURL(blob);
          link.remove();
        }, 100);
      });
  }

  /**
   * This function is for massaging the document urls
   * @param docUrls :list of document urls
   * @returns will return list with url and filename
   */
  massageDocumentUrls(docUrls) {
    if (!docUrls && !Array.isArray(docUrls)) { return []; }
    return docUrls.map(eachUrl => {
      return {
        url: eachUrl,
        fileName: eachUrl.includes('__name__') ?
        eachUrl.substring(eachUrl.lastIndexOf('__name__') + 8, eachUrl.indexOf('?')) :
        eachUrl.substring(eachUrl.lastIndexOf('/') + 1, eachUrl.lastIndexOf('?'))
      };
    }
    );
  }

  /**
 * This function is for elobrating status to display in UI
 * @param status //P,D,C
 * @returns //Pending,Cancelled,Complete
 */
  getStatusLabel(status) {
    if (status && status === 'P') {
      return 'Pending';
    } else if (status && (status === 'C')) {
      return 'Completed';
    } else if (status && status === 'D') {
      return 'Cancelled';
    }
  }

  // This function is for close the page
  closePage() {
    this.router.navigate([`${ROUTE_PATHS.ADD_MyACCOUNT}`]);
  }

  // This function is for submiting the final form
  sumbitForm() {
    if (this.uploadedFiles && this.uploadedFiles.length && this.documentUrls && this.documentUrls.length && this.fileValidation) {
      const payloadData = {
        type: 'PUT',
        url: 'uploadAdminRequestDocument',
        isDeveloper: true,
        pathVariables: [this.requestId],
        body: {
          requestId: this.requestId,
          patientComments: this.otherValue,
          documentURLs: this.documentUrls
        }
      };
      this.http.makeHttpRequest(payloadData).subscribe((res) => {
        if (this.http.isSuccessfulResponse(res)) {
          this.closePage();
          this.observable.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.SUCCESSFULL_UPLOAD_MSG);
        } else {
          this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.UPLOAD_ERROR_MSG);
        }
      });
    } else {
      if (!this.uploadedFiles) {
        this.uploadedFiles = [];
      }
    }
  }
  /**
     *
     * @param event uploaded files
     * @param remove flag for removing files
     * This funciton will do all the file validations like file extensions,file size,file name,maximum files
     *
     */
  onUpload(event, uploader: FileUpload, remove?) {
    let isRemove = remove || false;
    console.log(event);
    this.uploadedFiles = event.currentFiles;
    let extentions = ['jpg', 'png', 'gif', 'jpeg', 'tiff', 'psd', 'pdf', 'esp', 'ai', 'indd', 'raw', 'heic', 'heif'];
    let regex: any = /[!@#$%^&*()+\-=\[\]{};':"\\|,.~`<>\/?]+/;
    /**
     * event.currentFiles.length <= 5:-This if condition checks length of the files <= 5
     */
    if (event.currentFiles.length <= 5) {
      let extentionFlag = this.uploadedFiles.every(element => extentions.includes(element.name.includes('.') ? element.name.split('.').pop().toLowerCase() : 'apk'));
      /**
       * extentionFlag:This if condition will check whether uploaded file is valid extention or not
       */
      if (extentionFlag) {
        let isValidFileName = this.uploadedFiles.every(element => !regex.test((element.name.includes('.') && element.name.split('.').length === 2) ? element.name.split('.')[0] : element.name));
        /**
         * isValidFileName:This if condition will check whether uploaded file names has special characters or not except underscore(_)
         */
        if (isValidFileName || this.uploadedFiles.length === 0) {
          let fileSizeFlag = this.uploadedFiles.every(element => element.size <= 10000000);
          /**
           * fileSizeFlag: This if condition will check wheter uploaded file size less than 10MB or not
           */
          if (fileSizeFlag) {
            this.fileUpload();
          } else {
            this.fileValidation = false;
            if (!isRemove) {
              this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.MAX_FILE_SIZE_ERROR_MSG);
            }
          }
        } else {
          for (let index = event.currentFiles.length - 1; index >= 0; index--) {
            if (event.currentFiles.length && event.currentFiles[index] && event.currentFiles[index].name) {
              if (regex.test((event.currentFiles[index].name.includes('.') && event.currentFiles[index].name.split('.').length === 2) ? event.currentFiles[index].name.split('.')[0] : event.currentFiles[index].name)) {
                uploader.remove(event, index);
              }
            }
          }
          if (!isRemove) {
            this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.INVALID_FILENAME_ERROR_MSG);
          }
        }
      } else {
        this.fileValidation = false;
        if (!isRemove) {
          this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.INVALID_EXTENSION_ERROR_MSG);
        }
      }
    } else {
      for (let len = event.currentFiles.length; len > 5; len--) {
        uploader.remove(event, len - 1);
      }
      if (!isRemove) {
        this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.MAX_FILE_LIMIT_ERROR_MSG);
      }
    }
    if (!isRemove) {
      if (event.files.length) {
        let uploadedFiles = [];
        for (let file of event.files) {
          uploadedFiles.push(file);
        }
        let extentionFlag = uploadedFiles.every(element => extentions.includes(element.name.includes('.') ? element.name.split('.').pop().toLowerCase() : 'apk'));
        if (!extentionFlag) {
          this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.INVALID_EXTENSION_ERROR_MSG);
        } else if (!uploadedFiles.every(element => element.size <= 10000000)) {
          this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.MAX_FILE_SIZE_ERROR_MSG);
        }
      }
    }
  }

  /**
   *
   * @param event remove file
   * This function will do removing of files which are added.
   */
  filesRemoved(event, uploader) {
    if (event.file) {
      this.uploadedFiles = this.uploadedFiles.filter(each => each.name !== event.file.name);
    }
    let fileEvent = {
      currentFiles: this.uploadedFiles
    };
    this.onUpload(fileEvent, uploader, true);
  }

  // This funciton will upload the uploaded all files into s3 bucket
  fileUpload() {
    this.fileValidation = true;
    var getUploadUrls;
    var filesData = [];
    let isUpdatedFiles = this.uploadedFiles.every(element => this.documentUrls.some(url => url.indexOf(element.name)));
    if (this.uploadedFiles.length !== this.documentUrls.length || !isUpdatedFiles) {
      this.uploadedFiles.every(element => this.documentUrls.includes(element.name));
      this.uploadedFiles.forEach((element, ind) => {
        let obj = {
          key: 'patientRequestDoc' + ind,
          name: this.accentedtoNormalFun(element.name),
          contentType: element.type || element.name.split('.').pop().toLowerCase()
        };
        filesData.push(obj);
      });
      getUploadUrls = {
        type: 'POST',
        url: 'getUploadUrl',
        isDeveloper: true,
        body: {
          fileNames: filesData,
          userId: this.userDetails.userId,
          userName: 'temp-new-tele-doc/' + this.accentedtoNormalFun(this.userDetails.firstName) + this.accentedtoNormalFun(this.userDetails.lastName),
          patientName: this.accentedtoNormalFun(this.documentDetails.firstName) + this.accentedtoNormalFun(this.documentDetails.lastName) + '__' + this.documentDetails.dob
        },
      };
      this.http.makeHttpRequest(getUploadUrls).subscribe(res => {
        this.documentUrls = [];
        const urls = res;
        if (res && typeof (res) === 'object') {
          for (const key in res) {
            if (Object.prototype.hasOwnProperty.call(res, key)) {
              this.documentUrls.push(res[key]);
            }
          }
        } else {
          this.documentUrls = [];
        }
        console.log(this.documentUrls);
        filesData.forEach(eachFile => {
          let file = this.uploadedFiles.filter(each => this.accentedtoNormalFun(each.name) === eachFile.name);
          let uploadPayload = {
            type: 'PUT',
            url: urls[eachFile.key],
            body: file[0],
            isExternal: true,
            contentType: eachFile.contentType || eachFile.name.split('.').pop().toLowerCase()
          };
          this.http.makeHttpRequest(uploadPayload).subscribe(response => {
            console.log(response);
          });
        });
      });
    }
  }



  /* for accented letter change convention function */
  accentedtoNormalFun(name) {
    let originalText = name;
    let result = originalText.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    return result;
  }
  // This function will remove all files which are uploaded.
  allFilesCleared(event) {
    console.log(event);
  }

  /**
   * Download files for mobile resolution.
   * @param img url of the image to download.
   */
  downloadImageMobile(img) {
    var width = window.matchMedia('(max-width: 767px)');
    if (width.matches) {
      this.downloadImage(img);
    }
  }
}

