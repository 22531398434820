<section class="appointment-detail" *ngIf="isShow">
    <div class="main-heading">Hello {{userDetails.firstName}} {{userDetails.lastName}}! Welcome Back!</div>
    <img class="appointment-details-leaf" alt="appointment-details"
        src="assets/images/pngs/appointment-details-leaf.png">
    <div class="confirmation-img-heading" *ngIf="toastData.visibility">
        <app-svg [name]="toastData.svg"></app-svg>
        <div [ngClass]="toastData.status =='cancelled'?'cancel--msg':''" class="confirmation-heading">{{toastData.data}}
        </div>
    </div>
    <div class="message_wrapper"><span class="message" id="message">*{{noteMessage}}</span></div>
    <div class="message_wrapper_m">
        <div class="message-m">*{{noteMessage}}</div>
        <div class="message-text-m" id="message-m">*{{noteMessage}}</div>
    </div>
    <ng-container *ngIf="patientsList && patientsList.length > 0">
        <div class="card-label active-appointment-label" >
            <span>
                My Patients
            </span>
            <div class="add--new" (click)="redirectingToAddPatientForm()">
                <div class="svg">
                    <app-svg [name]="'plus-circle'"></app-svg>
                </div>
                <div>
                    Add New
                </div>
            </div>
        </div>
        <ng-container *ngFor="let patient of patientsList">
            <app-patient-card [patientDetails]='patient' [patientTotalLocations]='patientTotalLocations'></app-patient-card>
        </ng-container>
    </ng-container>

    <ng-container>
        <div class="card-label active-appointment-label">Upcoming Appointments
        </div>
        <div *ngIf="upComingAppointments.length == 0" class="account-des-next">
            There are no upcoming appointments.To book your appointment.<span class="appointment-link"><a
                    (click)="redirectingToBookAppointment()">Click here</a></span>
        </div>
        <ng-container *ngFor="let card of upComingAppointments;let i=index">
            <app-appointment-detail-card (cancelAppointment)="cancelAppointment($event)" [appointmentDetails]="card" [patients]="patientsList">
            </app-appointment-detail-card>
        </ng-container>
    </ng-container>
    <!-- <ng-container *ngIf="pastAppointments.length > 0">
        <div class="card-label active-appointment-label">
                Recent Appointments
        </div>
        <ng-container *ngFor="let card of pastAppointments| slice:0:displayListCount;let i=index">
            <app-appointment-detail-card (cancelAppointment)="cancelAppointment($event)" [appointmentDetails]="card">
            </app-appointment-detail-card>
        </ng-container>
        <div class="view--label" *ngIf="pastAppointments.length > 2" (click)="viewMore(pastAppointments)">
            {{view}}
        </div>
    </ng-container> -->

</section>