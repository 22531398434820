<div class="document__list-page">
    <div class="background-image">
        <img src="assets/images/pngs/newpatientform-background.png" alt="bg image">
    </div>
    <!-- Main Document List Page Wrapper -->
    <div class="document__list-main-wrapper padding-lr" *ngIf="isShowScreen && permissionsDone">
        <!-- Documents List -->
        <div class="document__list-navigation-menu">
            <app-breadcrumb [breadCrumbData]="breadCrumbData"></app-breadcrumb>
        </div>
        <!-- Filters section -->
        <!-- <div class="document__list-filetr-wrapper"> -->
            <!-- Advance filter view  -->
            <!-- <div class="document__list-filter-view"> -->
                <!-- Advanced filters -->
                <!-- <div class="document__list-advanced-filters">
                    <div class="document__list-search-filter">
                        <app-list-search [defSearchValue]="searchInputText" [searchPlaceHolderText]="searchPlaceHolderText" (searchText)="searchFilter($event)"></app-list-search>
                    </div>
                    <div class="document__list-date-filter" (click)="filterAppointments()">
                        <app-svg [name]="'filter'"></app-svg>
                    </div>
                </div>
            </div> -->
            <!-- Advance filter view -->
        <!-- </div> -->
        <!-- Filter section end -->
        <ng-container>
            <!-- Document List starts here -->
            <div class="document__list-block" *ngIf="documentList.length != 0 && permissionsDone">
                <ng-container *ngFor="let document of documentList; let i=index">
                    <app-patient-documents-request-list [adminPermissions]="adminPermissions" [requestData]="document" [patientRequest]="'true'">
                    </app-patient-documents-request-list>
                </ng-container>
            </div>
            <!-- Document List ends here -->
            <!-- Pagination component starts here -->
            <!-- Pagination component ends here -->
            <!-- Empty list Starts here -->
            <app-empty-list-data [emptyMessageData]="emptyAppointmentsListMessages" *ngIf="documentList.length === 0 && isShowScreen"></app-empty-list-data>
            <!-- Empty list ends here -->
        </ng-container>
    </div>
</div>