// This component is for lab document requests.

import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { HttpService } from '@app/common/services/http.service';
import { TOAST_STATUSES, HTTPMethods } from '@app/common/constants/util.constant';
import { CognitoService } from '@app/common/services/cognito.service';
import { FileUpload } from 'primeng/fileupload';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';
import { API_URLS } from '@app/common/constants/api-urls.constant';
import { Router } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';

@Component({
  selector: 'app-patient-lab-documents-request',
  templateUrl: './patient-lab-documents-request.component.html',
  styleUrls: ['./patient-lab-documents-request.component.scss']
})
export class PatientLabDocumentsRequestComponent implements OnInit {

  remainingValue1: number = 150;
  documentUrls: Array<string> = [];
  fileValidation: boolean;
  userDetails: Record<string, any>;
  documentsArr: Array<any> = [];
  documentsArrCopy: Array<any> = [];
  showSelectionError: boolean = false;
  showOther: boolean = false;
  showOtherError: boolean = false;
  remainingValue: number = 150;
  otherValue: string = '';
  adminComments: string = '';
  documentsSelected: Array<any> = [];
  uploadedFiles: Array<any> = [];
  selectedFiles: Array<any> = [];
  codeRegex: RegExp = /^[a-zA-Z0-9]+$/;
  nonEmptyRegex: RegExp = /^(?!\s*$).+/;
  uploads: Array<any> = [];

  constructor(private cognitoService: CognitoService, private http: HttpService, private observable: ObservableHelperService, private router: Router,) { }

  @Input() patientId;
  @Output() closePopup = new EventEmitter<any>();
  @Input() set documentsRequest(value: any) {
    this.documentsArr = this.massageDocumentsData(value);
  }

  ngOnInit() {
    this.cognitoService.getUser().then(data => {
      this.userDetails = data;
    });
  }

  /**
   * this function is to massage the document data.
   * @param data 
   */
  massageDocumentsData(data) {
    if (!data && !Array.isArray(data)) { return []; }
    let tempList = data.map(eachDoc => {
      return {
        ...eachDoc,
        id: eachDoc.documentId,
        documentLabel: eachDoc.documentName,
        isSelected: false,
        uploads: this.getAllUploadsList(eachDoc.attachments)
      };
    });

    let ind = []
    let uploads = [];
    tempList.forEach(element => {
      if (element.documentLabel !== 'Others') { ind.push(element); }
      element.attachments ? uploads.push(...element.attachments) : ''
    });
    let other = tempList.find(ele => ele.documentLabel == 'Others')
    let finalList = ind;
    finalList.push(other);
    this.uploads = this.getAllUploadsList(uploads)
    return finalList;
  }

  /**
   * This funciton will do all the file validations like file extensions,file size,file name,maximum files
   * @param event uploaded files
   * @param remove flag for removing files
   */
  onUpload(event, uploader: FileUpload, remove?) {
    let isRemove = remove || false;
    this.uploadedFiles = event.currentFiles;
    let extentions = ['jpg', 'png', 'gif', 'jpeg', 'tiff', 'psd', 'pdf', 'esp', 'ai', 'indd', 'raw', 'heic', 'heif'];
    let regex: any = /[!@#$%^&*()+\-=\[\]{};':"\\|,.~`<>\/?]+/;
    /**
     * event.currentFiles.length <= 5:-This if condition checks length of the files <= 5
     */
    if (event.currentFiles.length <= 5) {
      let extentionFlag = this.uploadedFiles.every(element => extentions.includes(element.name.includes('.') ? element.name.split('.').pop().toLowerCase() : 'apk'));
      /**
       * extentionFlag:This if condition will check whether uploaded file is valid extention or not
       */
      if (extentionFlag) {
        let isValidFileName = this.uploadedFiles.every(element => !regex.test((element.name.includes('.') && element.name.split('.').length === 2) ? element.name.split('.')[0] : element.name));
        /**
         * isValidFileName:This if condition will check whether uploaded file names has special characters or not except underscore(_)
         */
        if (isValidFileName || this.uploadedFiles.length === 0) {
          let fileSizeFlag = this.uploadedFiles.every(element => element.size <= 10000000);
          /**
           * fileSizeFlag: This if condition will check wheter uploaded file size less than 10MB or not
           */
          if (fileSizeFlag) {
            this.fileUpload();
          } else {
            this.fileValidation = false;
            if (!isRemove) {
              this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.MAX_FILE_SIZE_ERROR_MSG);
            }
          }
        } else {
          for (let index = event.currentFiles.length - 1; index >= 0; index--) {
            if (event.currentFiles.length && event.currentFiles[index] && event.currentFiles[index].name) {
              if (regex.test((event.currentFiles[index].name.includes('.') && event.currentFiles[index].name.split('.').length === 2) ? event.currentFiles[index].name.split('.')[0] : event.currentFiles[index].name)) {
                uploader.remove(event, index);
              }
            }
          }
          if (!isRemove) {
            this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.INVALID_FILENAME_ERROR_MSG);
          }
        }
      } else {
        this.fileValidation = false;
        if (!isRemove) {
          this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.INVALID_EXTENSION_ERROR_MSG);
        }
      }
    } else {
      for (let len = event.currentFiles.length; len > 5; len--) {
        uploader.remove(event, len - 1);
      }
      if (!isRemove) {
        this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.MAX_FILE_LIMIT_ERROR_MSG);
      }
    }
    if (!isRemove) {
      if (event.files.length) {
        let uploadedFiles = [];
        for (let file of event.files) {
          uploadedFiles.push(file);
        }
        let extentionFlag = uploadedFiles.every(element => extentions.includes(element.name.includes('.') ? element.name.split('.').pop().toLowerCase() : 'apk'));
        if (!extentionFlag) {
          this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.INVALID_EXTENSION_ERROR_MSG);
        } else if (!uploadedFiles.every(element => element.size <= 10000000)) {
          this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.MAX_FILE_SIZE_ERROR_MSG);
        }
      }
    }
  }

  /**
   * This function will do removing of files which are added.
   * @param event remove file
   */
  filesRemoved(event, uploader) {
    if (event.file) {
      this.uploadedFiles = this.uploadedFiles.filter(each => each.name !== event.file.name);
    }
    let fileEvent = {
      currentFiles: this.uploadedFiles
    };
    this.onUpload(fileEvent, uploader, true);
  }

  /**
   * This funciton will upload the uploaded all files into s3 bucket.
   */
  fileUpload() {
    this.fileValidation = true;
    var getUploadUrls;
    var filesData = [];
    let isUpdatedFiles = this.uploadedFiles.every(element => this.documentUrls.some(url => url.indexOf(element.name)));
    if (this.uploadedFiles.length !== this.documentUrls.length || !isUpdatedFiles) {
      this.uploadedFiles.every(element => this.documentUrls.includes(element.name));
      this.uploadedFiles.forEach((element, ind) => {
        let obj = {
          key: 'patientRequestDoc' + ind,
          name: this.accentedtoNormalFun(element.name),
          contentType: element.type || element.name.split('.').pop().toLowerCase()
        };
        filesData.push(obj);
      });
      getUploadUrls = {
        type: HTTPMethods.POST,
        url: API_URLS.GET_UPLOAD_URL,
        isDeveloper: true,
        body: {
          fileNames: filesData,
          userId: this.userDetails.userId,
          adminRequest: true
        },
      };
      this.http.makeHttpRequest(getUploadUrls).subscribe(res => {
        this.documentUrls = [];
        const urls = res;
        if (res && typeof (res) === 'object') {
          for (const key in res) {
            if (Object.prototype.hasOwnProperty.call(res, key)) {
              this.documentUrls.push(res[key]);
            }
          }
        } else {
          this.documentUrls = [];
        }
        filesData.forEach(eachFile => {
          let file = this.uploadedFiles.filter(each => this.accentedtoNormalFun(each.name) === eachFile.name);
          let uploadPayload = {
            type: HTTPMethods.PUT,
            url: urls[eachFile.key],
            body: file[0],
            isExternal: true,
            contentType: eachFile.contentType || eachFile.name.split('.').pop().toLowerCase()
          };
          this.http.makeHttpRequest(uploadPayload).subscribe(response => {
          });
        });
      });
    }
  }

  /**
   * for accented letter change convention function
   * @param name 
   */
  accentedtoNormalFun(name) {
    let originalText = name;
    let result = originalText.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    return result;
  }

  /**
   * This function will remove all files which are uploaded.
   * @param event 
   */
  allFilesCleared(event) {
    this.selectedFiles = [];
  }

  /**
   * This function will set validation if no documents are selected.
   * @param event 
   * @param doc 
   */
  handleDocuments(event, doc) {
    if (event.checked) {
      this.showSelectionError = false;
      // tslint:disable-next-line:no-unused-expression
      doc.documentLabel === 'Others' ? this.showOther = true : null;
    } else {
      doc.documentLabel === 'Others' ? (this.showOther = false, this.showOtherError = false, this.otherValue = '') : null;
      this.remainingValue = 150;
    }
    this.documentsSelected = this.documentsArr.filter(doc => doc.isSelected);
  }

  /**
   * This function will do decrement of entered text count.
   * @param event 
   */
  textCounter(event) {
    this.remainingValue = 150 - (event.target.value.length);
    this.showOtherError = false;
  }

  /**
   * This function will do decrement of entered text count
   * @param event 
   */
  textCounter1(event) {
    this.remainingValue1 = 150 - (event.target.value.length);
  }

  /**
   * This function will do final submition of form.
   */
  submit() {
    if (!this.documentsSelected.length) {
      this.showSelectionError = true;
    } else if (this.showOther && !this.nonEmptyRegex.test(this.otherValue)) {
      this.showOtherError = true;
    } else {
      console.log(this.documentsSelected, this.otherValue);
    }
    // below condition is atleast one document needs select and if other is selected comment must be required
    if ((this.documentsSelected.length && !this.showOther) || (this.documentsSelected && this.showOther && this.nonEmptyRegex.test(this.otherValue))) {
      if ((this.uploadedFiles && this.uploadedFiles.length && this.fileValidation) || (this.uploadedFiles.length === 0)) {
        let documents = [];
        this.documentsSelected.forEach(doc => {
          documents.push(doc.id);
        });
        const payloadData = {
          type: HTTPMethods.POST,
          url: API_URLS.ADD_ADMIN_REQUEST,
          isDeveloper: true,
          body: {
            patientId: this.patientId,
            documentIds: documents,
            adminComments: this.adminComments,
            otherComment: this.otherValue,
            adminDocument: this.documentUrls,
          }
        };
        this.http.makeHttpRequest(payloadData).subscribe((res) => {
          if (res && this.http.isSuccessfulResponse(res)) {
            this.closePopup.emit({ isPopup: false, apiStatus: true });
            this.observable.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.REQUEST_SENT_TO_PATIENT_MSG);
          } else {
            this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.REQUEST_SENT_TO_PATIENT_ERROR_MSG);
          }
        });
      }

    }
    if (document.getElementsByClassName('invalid-feedback')[0]) {
      document.getElementsByClassName('days_wrapper')[0].scrollIntoView({ block: 'center' });
    }
  }

  /**
   * This function is for cancel the popup
   */
  cancel() {
    this.closePopup.emit({ isPopup: false, apiStatus: false });
  }

  /**
   * Massage file data.
   * @param data 
   */
  getAllUploadsList(data) {
    let uploadsList = [];
    let fileName;
    if(data){
      data.forEach(eachupload => {
        if (eachupload !== '' && eachupload) {
          fileName = eachupload.includes('__name__') ? eachupload.substring(
            eachupload.lastIndexOf('__name__') + 8,
            eachupload.lastIndexOf('?')) :
            eachupload.substring(eachupload.lastIndexOf('/') + 1, eachupload.lastIndexOf('?'))
          uploadsList.push(this.splitFileName(fileName, eachupload));
        }
      });
    }
    return uploadsList;
  }

  /**
   * Format file data.
   * @param file 
   * @param docURL 
   */
  splitFileName(file, docURL) {
    let fileObj = {};
    let splitFileName = file.split('.');
    fileObj['name'] = splitFileName[0];
    fileObj['extenstion'] = splitFileName[splitFileName.length - 1];
    fileObj['file'] = docURL;
    return fileObj;
  }

  /**
   * Validate entered code.
   * @param code 
   */
  validateCode(code) {
    return this.codeRegex.test(code);
  }

  /**
   * Download file
   * @param url 
   * @param fileName 
   * @param flag 
   */
  donwloadFile(url, fileName, flag) {
    let fullFileName = '';
    if (flag === true) {
      fullFileName = fileName.name + '.' + fileName.extenstion;
    } else {
      fullFileName = fileName.file.name + '.' + fileName.file.extenstion;
    }
    fetch(url).then((t) => {
      return t.blob().then((b) => {
        var a = document.createElement('a');
        a.href = URL.createObjectURL(b);
        a.setAttribute('download', fullFileName);
        a.click();
      }
      );
    });
  }

  /**
   * Edit document.
   * @param uploadFile 
   * @param index 
   */
  editFile(index){
    document.querySelector('html').style.overflow = 'auto';
    // this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.LAB_DOC_EDIT}/${index}`]);
    window.open(`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.LAB_DOC_EDIT}/${index}`, '_blank');
  }
}
