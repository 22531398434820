import { Component, OnInit, ViewChild } from '@angular/core';
import { formatDate } from '@angular/common';
import { DEFAULT_PAGINATION_LIMIT, TOAST_STATUSES, convertDateToSpecifiedOffset, DEFAULT_TIME_ZONE_DIFF, TELEMEDICINE_MEDICATION, HTTPMethods } from '@app/common/constants/util.constant';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { adminAppointmentsTabs } from '../common/constants/tabs';
import { getActionsList } from '../common/constants/appointment-actions';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import * as XLSX from 'xlsx';
import { API_URLS } from '@app/common/constants/api-urls.constant';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';
import { environment } from '@src/environments/environment';
//declare var stripe: any;

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.scss']
})
export class AppointmentsComponent implements OnInit {
  paymentRedirectUrl=environment.paymentRedirectUrl;
  radioValue = 'today';
  sideBarVisible = false; // Side bar dsiplay value
  appointmentsTotalListPerPage = []; // Appointment total list initial
  // currentTime = formatDate(new Date(new Date(new Date().setMinutes(new Date().getMinutes() - 30))), 'yyyy-MM-dd HH:mm:ss', 'en');
  // Get the starting time today
  getTodaysStartingTime = formatDate(new Date(new Date(new Date().setHours(0, 0, 0))), 'yyyy-MM-ddTHH:mm:ss', 'en');
  // Get the end time today
  getTodaysEndingTime = formatDate(new Date(new Date(new Date().setHours(23, 59, 59))), 'yyyy-MM-ddTHH:mm:ss', 'en');
  // Next 7 days  date
  nextWeek = formatDate(new Date(new Date(new Date().setDate(new Date().getDate() + 6))), 'yyyy-MM-dd', 'en');
  viewByFilterShowHide = false; // View by filter show and Hide
  getQueryParameter: any; // Get Query Parameter
  filtersTabText = []; // Filers Tab text
  currentPage = 1; // Current page number
  showAPILoader = false;
  // Bread crumb Data
  breadCrumbData = {
    link: '/admin',
    linkText: 'Appointments'
  };
  cancelPopupShowHide: boolean = false;
  paginationData: any; // Pagination data
  searchInputText = ''; // Search result data
  selectedTabIndex: any = 0; // Tab initail index value
  isShowScreen = false; // While call api hiding the layout
  pageLimit = DEFAULT_PAGINATION_LIMIT;
  filterApplyFlag = false; // Cheking the filter is applying or not
  appyliedFilterObj = {};
  // Initail  playload value
  INITIAL_PAYLOAD = {
    pagination: {
      page: 1,
      limit: DEFAULT_PAGINATION_LIMIT
    },
    search: this.searchInputText,
  };
  emptyAppointmentsListMessages: any; // Empty List data
  appointmentTimeSlatClass = false; // AppointmentSlat Inprogress and next 5min flag
  viewByFilterDataFlag = true; // Initail view by filetr flag
  listType = 'upcomming';
  rawFilterData: any;
  isFilterData = false;
  rawExportData: any;
  pageClickedData: { pagination: { page: any; limit: any; }; };
  isFilterClear = false;
  copayAmount: any;
  dueAmount: any;
  totalAmout: any;
  makeAsPaidPopupShowHide: boolean = false;
  isBeforeActions: boolean = false;
  generateInvoicePopupShowHide: boolean = false;
  blockPatientPopupShowHide = false; // Block UI status flag
  blockPatientApplyStatus = false; // Block Status Flag
  patientId: number;
  cancelAppointmentData: any;
  isAdmin: boolean = true;
  // Form inputs
  forminputs: any = [
    {
      type: 'header',
      label: 'text',
      label1: 'text',
      showontoggle: true,
      editable: true,
      key: 'patient_name_id',
      className: 'patient-id',
    },

    {
      type: 'groupCheckbox',
      categoryLabel: 'Treatment',
      controlType: 'groupCheckbox',
      key: 'treatment',
      required: true,
      value: [],
      isIntermediate: false,
      options: [
        {
          name: 'Medmanagement',
          value: 'Medmanagement',
          isSelected: false
        },
        {
          name: 'Tele Medicine',
          value: 'Tele Medicine',
          isSelected: false
        },
        {
          name: 'TMS',
          value: 'TMS',
          isSelected: false
        },
        {
          name: 'Therapy',
          value: 'Therapy',
          isSelected: false
        }
      ],
      validators: [],
      focus: true,
      valueable: true,
      isSelected: false,
      showontoggle: true,
    },
    {
      type: 'submit',
      placeholder: '',
      required: true,
      text: 'Save',
      key: '',
      value: '',
      controlType: 'submitButton',
      validators: [],
      callback: '',
      cencelBtn: '',
      showontoggle: true,
      disabled: true
    }
  ];
  treatmentsCount: number;
  getFilterData: any;
  rawTreatmentFilterData: any;
  timestamp: any;
  selectedApptId: any;
  amountGenerated: any;
  invoiceStatus: string;
  appointmentListCount = 0;
  currentTime: any;
  permissionsDone = false;
  adminPermissions = {
    APPOINTMENT_CREATE: true,
    APPOINTMENT_EDIT: true,
    PATIENT_EDIT: true,
    PATIENT_DETAILS: true,
    INVOICE_EDIT: true,
    INVOICE_DETAILS: true,
  };
  permissionsArr = [];
  initialRedirection: boolean = false;
  file: any;
  arrayBuffer: any;
  filelist: any[];
  pmpHeader: any = ['MEDS', 'PMP','PT SEEN Y/N','patientId']
  verificationHeaders: any = ['Benefits', 'Copay', 'VERIFIED','patientId']
  authorizationHeaders : any = ['AUTH STATUS','AUTH END DATE','Name', 'DOB']
  medicationHeaders : any = ['Location','Appointment Type','Name','Notes','INS Name','INS ID','DOB','Benefits','Copay','Mode of Payment','VERIFIED','MEDS','PMP','PT SEEN Y/N','AUTH STATUS','AUTH END DATE','MEDS Uploaded By','MEDS Verified By','Appointment Time']
  pmpValue:any;
  tmsAuthValue: any;
  verifValue: any;
  medicationsValue:any;
  @ViewChild('op', { static: false }) importOverlay;
  @ViewChild('pop', { static: false }) exportOverlay;
  exportList = [
    {
      name: 'Medications Sheet',
      value: 'medicationsExcelSheet'
    },
    {
      name: 'Final Medications Sheet',
      value: 'finalMedicationsExcelSheet'
    },
    {
      name: 'PMP Sheet',
      value: 'pmpExcelSheet'
    },
    {
      name: 'Verification Sheet',
      value: 'verificationExcelSheet'
    },
    {
      name: 'TMS Authorization Sheet',
      value: 'tmsAuthExcelSheet'
    },
  ]
  missedColumns: any;
  missedColumnsPopupFlag: any = false;
  selectMedSheetData: any = false;
  selectImportSheetData: any = false;
  radioValueForMedicationSheet: string = ''; 
  clickedElement= "";
  currentlySelectedExportItem: any;
  paymentToken: string;
  isMarkasPaid= false;
  pmpPopupShowHide: boolean = false
  pmpSheetData:any = {}
  pmpData:any
  pmpMeds:any

  constructor(private observable: ObservableHelperService, private http: HttpService,
              private router: Router, private activeRoute: ActivatedRoute) {
    this.nextWeek = `${this.nextWeek}T23:59:59`;
    this.getAdminPermissions();
    this.activeRoute.queryParams.subscribe(params => {
      if(params.search){
        this.searchInputText = params.search;
        this.initialRedirection = false;
      }
      this.intailRedirection(this.router.url);
      this.getQueryParameter = params;
      this.setCurrentTabisSelected();
      this.isFilterData = false;
      if(params.type){
        this.getTreatmentsDataLength();
        this.emptyAppointmentsListMessages = this.emptyMessagedata(this.getQueryParameter.type);
        this.currentTime = new Date(convertDateToSpecifiedOffset(DEFAULT_TIME_ZONE_DIFF, new Date()));
      }
    });
  }

  ngOnInit() {
    this.INITIAL_PAYLOAD.search = this.searchInputText
    this.checkingSidebarState(this.sideBarVisible);
    document.querySelector('html').style.overflow = 'auto';
    document.querySelector('body').style.overflow = 'auto';
    this.getAppointmentFilterData();
  }


  // scroll to top
  scrollTop() {
    window.scroll({
      top: 0,
      left: 0,
      // behavior: 'smooth'
    });
  }
  // Initail redirection
  intailRedirection(data) {
    if ((data === '/admin/appointments' || !data.includes('type'))) {
      this.scrollTop();
      if(this.searchInputText === ''){
        this.router.navigate(['/admin/appointments'], { queryParams: { type: 'upcoming' } });
      } else {
        this.router.navigate(['/admin/appointments'], { queryParams: { type: 'all' , search: this.searchInputText} });
        this.selectedTabIndex = 2;
      }
      this.initialRedirection = true;
    }
  }



  // Admin get filterData API call
  getAppointmentFilterData() {
    const payloadData = {
      type: 'GET',
      url: 'filterData',
      isDeveloper: true,
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.rawFilterData = this.massageFilterData(res.data);
      } else {
        // Error
      }
    });
  }

  massageFilterData(data) {
    if (data && typeof (data) === 'object') {
      data.doctor = data.doctor.map(doc => {
        return {
          ...doc,
          name: doc.providerName,
          value: doc.providerId,
          isSelected: false
        };
      });
      data.location = data.location.map(loc => {
        return {
          ...loc,
          name: loc.locationName,
          value: loc.locationId,
          isSelected: false
        };
      });
      data.treatment = data.treatment.map(treat => {
        return {
          ...treat,
          name: treat.appointmentTypeName,
          value: treat.appointmentTypeId,
          isSelected: false
        };
      });
      data.viewby.unshift(
        {
          id: 'All',
          name: 'All',
          label: 'All',
          value: 'All'
        }
      );
      data.viewby = data.viewby.map(view => {
        return {
          ...view,
          label: view.name,
          value: view.id
        };
      });
      return data;
    } else {
      // Error
    }
  }

  //  Get timeZone
  getTimeZone() {
    var offset = new Date().getTimezoneOffset();
    var o = Math.abs(offset);
    return (offset < 0 ? '+' : '-') + ('00' + Math.floor(o / 60)).slice(-2) + ':' + ('00' + (o % 60)).slice(-2);
  }
  setCheckElement(sheetvalue){
    this.clickedElement=sheetvalue;
  }
  openTypeFile(){
    if(this.clickedElement=="PMP"){
      document.getElementById("pmp-file-import").click();
    }
    if(this.clickedElement=="Verification"){
      document.getElementById("verification-file-import").click();
    }
    if(this.clickedElement=="TMS Authorization"){
      document.getElementById("tms-auth-file-import").click();
    }
  }

  // Admin Appointment API call
  getAppointmentsList(bodyData) {
    this.isShowScreen = this.showAPILoader ? true : false;
    const payload = {
      type: 'POST',
      url: 'adminappointment',
      isDeveloper: true,
      body: bodyData,
      // params: (this.isFilterData) ? {} : { type: this.listType }
      params: { type: this.listType }
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.timestamp = res.data.timestamp;
        this.appointmentsTotalListPerPage = this.massageAppointmentsData(res.data.list);
        // this.appointmentsTotalListPerPage = this.sortAppointmentByTime(this.appointmentsTotalListPerPage);
        this.appointmentListCount = res.data.pagination.totalItems;
        this.paginationData = this.massagePaginationData(res.data);
      } else {
        let message = 'Failed to fetch Appointments list data. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
      this.isShowScreen = true;
    });
  }

  // This will sort the appointments
  sortAppointmentByTime(data) {
    if (!data && !Array.isArray(data)) { return []; }
    return data.sort((a, b) => {
      let aDate = a.appointmentDate;
      let bDate = b.appointmentDate;
      let aTime = a.appointmentTime;
      let bTime = b.appointmentTime;
      if (aDate === bDate) {
        return (aTime < bTime) ? -1 : (aTime > bTime) ? 1 : 0;
      } else {
        return (aDate < bDate) ? -1 : 1;
      }
    });
  }

  // This will do export funcationality as PDF
  exportDataFunction(data) {
    // let resp = JSON.parse(data);
    let todayData = formatDate(new Date(new Date()), 'MM-dd-yyyyTHH:mm:ss', 'en');
    const bytes = new Uint8Array(data.data.length);
    data.data.forEach((val, index) => {
      bytes[index] = val;
    });
    const downloadUrl = new Blob([bytes.buffer]);
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(downloadUrl);
    let filename = `${todayData}.xlsx`;
    // var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
    link.download = filename;
    link.click();
  }

  // This will do massaging for pagination Data
  massagePaginationData(data) {
    if (!data && typeof (data) !== 'object') {
      // Error
    }
    return {
      limit: data.pagination.limit,
      currentPage: data.pagination.page,
      totalPages: Math.ceil(data.pagination.totalItems / data.pagination.limit),
      data: data.list
    };
  }

  // This is a massaging function for Appointment Data
  massageAppointmentsData(data) {
    if (!data && !Array.isArray(data)) { return []; }
    return data.map(each => {
      return {
        ...each,
        doctorName: (each.doctor !== null && each.doctor.name) ? (each.doctor.name) : '-',
        patientName: ((each.patient.firstName && each.patient.lastName) ? each.patient.firstName + ' ' + each.patient.lastName : '-'),
        calendarIcon: 'calendar',
        timeIcon: 'clock',
        patientIcon: 'user',
        doctorIcon: 'doctor',
        appointmentIcon: this.getingAppointmentTypeSvgName(each.appointmentTypeName),
        actions: this.actions(each),
        appointmentCurrentTime: this.getAppointslatStatus(each),
        appointmentTimeSlat: this.appointmentTimeSlatClass,
        timestamp: this.timestamp,
        appointmentTypeName: /*each.appointmentTypeName */ this.AppointmentNameForming(each),
        isTMSClass: this.AppointmentNameForming(each, 'bool')
      };
    });
  }



  massageAppointmentsSearchData(data) {
    if (!data && !Array.isArray(data) && data.search.lenght === 0) { return data.list; }
    // return search: data.search;
  }
  pageChangedEvent(data) {
    this.currentPage = data.currentPage;
    this.pageLimit = data.limit;
    this.pageClickedData = {
      pagination: {
        page: data.currentPage,
        limit: data.limit,
      }
    };
    this.isFilterData = false;
    this.tabsDateFilters(this.pageClickedData);
    window.scroll(0, 0);
  }


  // Checking the Slide bar is open or if open hide outside scroll
  checkingSidebarState(slideBarFlag) {
    if (slideBarFlag) {
      document.querySelector('html').style.overflow = 'hidden';
      document.querySelector('body').style.overflow = 'hidden';
    } else {
      document.querySelector('html').style.overflow = 'auto';
      document.querySelector('body').style.overflow = 'auto';
    }
  }
  checkingSidebarSetStatus(data) {
    this.sideBarVisible = data;
    this.checkingSidebarState(data);
  }

  // This  will assign the which tab is selected
  selectedTab(tab) {
    if (tab && typeof (tab) === 'object') {
      this.filtersTabText.forEach(each => {
        if (each.id === tab.id) {
          if(this.searchInputText === ''){
            this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.APPOINTMENTS}`], { queryParams: { type: each.routingLabel } });
          } else {
            this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.APPOINTMENTS}`], { queryParams: { type: each.routingLabel, search: this.searchInputText  } });
          }
        }
      });
      this.selectedTabIndex = tab.id;
      this.listType = tab.routingLabel;
    }
  }


  // This will navigate to create New appointment page
  createNew() {
    this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.NEWAPPOINTMENT}`]);
  }



  // This will do filter functionality
  filterAppointments() {
    this.sideBarVisible = !this.sideBarVisible;
    this.checkingSidebarState(this.sideBarVisible);
    let filterSidePanel = document.querySelectorAll('.p-sidebar-width')[1];
    filterSidePanel.scrollTo(0, 0);
  }

  setCurrentTabisSelected() {
    this.filtersTabText = [];
    this.listType = this.getQueryParameter.type;
    adminAppointmentsTabs.forEach(data => {
      if (data.routingLabel === this.getQueryParameter.type) {
        data.selected = true;
      } else {
        data.selected = false;
      }
      this.filtersTabText.push(data);
    });
  }

  // geting the appointment data by query paramerters
  tabsDateFilters(paginationData, isExport?) {
    if (this.getQueryParameter.type === 'upcoming') {
      let dateString = this.getQueryParameter.appoinmnetData;
      let dateObject: any = new Date(dateString);
      this.getTodaysStartingTime = this.getQueryParameter.filter === 'true' ? formatDate(new Date(dateObject), 'yyyy-MM-ddTHH:mm:ss', 'en') : this.getTodaysStartingTime;
      this.getTodaysEndingTime = this.getQueryParameter.filter === 'true' ? formatDate(new Date(this.setTimeToDateObj(dateObject, '11:59 PM')), 'yyyy-MM-ddTHH:mm:ss', 'en') : this.getTodaysEndingTime;
      this.viewByFilterShowHide = true;
      const upcoming = {
        filter: this.filterApplyFlag ? this.appyliedFilterObj['filter'] : {
          viewby: ['appointmentDate'],
          time: {
            startTime: this.getTodaysStartingTime,
            endTime: this.viewByFilterDataFlag ? this.getTodaysEndingTime : this.nextWeek,
          },
        },
        ...paginationData,
        search: this.searchInputText,
        timezone: this.getTimeZone().toString()
      };
      if (isExport) {
        this.getExportXlsx(upcoming);
      } else {
        this.getAppointmentsList(upcoming);
      }

    } else if (this.getQueryParameter.type === 'history') {
      this.viewByFilterShowHide = false;
      const history = {
        filter: this.filterApplyFlag ? this.appyliedFilterObj['filter'] : {},
        ...paginationData,
        search: this.searchInputText,
        timezone: this.getTimeZone().toString()

      };
      if (isExport) {
        this.getExportXlsx(history);
      } else {
        this.getAppointmentsList(history);
      }
    } else if (this.getQueryParameter.type === 'all') {
      this.viewByFilterShowHide = false;
      const all = {
        filter: this.filterApplyFlag ? this.appyliedFilterObj['filter'] : {},
        ...paginationData,
        search: this.searchInputText,
        timezone: this.getTimeZone().toString()
      };
      if (isExport) {
        this.getExportXlsx(all);
      } else {
        this.getAppointmentsList(all);
      }
    }
  }
  // Export API cal
  exportAsXLSX() {
    this.exportOverlay.hide();
    this.tabsDateFilters(this.INITIAL_PAYLOAD, true);
  }

  getExportXlsx(exportBody) {
    this.isShowScreen = false;
    const payloadData = {
      type: 'POST',
      url: 'exportData',
      isDeveloper: true,
      body: exportBody,
      // params: (this.isFilterData) ? {} : { type: this.listType }
      params: { type: this.listType }
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.observable.showToast(TOAST_STATUSES.SUCCESS, 'Link has been shared successfully');
        // this.exportDataFunction(res.data);
      } else {
        // Error
        this.observable.showToast(TOAST_STATUSES.WARNING, 'Unable to export the file. Please try again later');
      }
      this.isShowScreen = true;
    });
  }
  // Get getPatientDetails API call
  getPatientDetails() {
    const payloadData = {
      type: 'GET',
      url: 'patientsDetails',
      isDeveloper: true,
      pathVariables: [this.patientId],
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.getTreatmentsData(res.data.details.blockStatus);
        // this.getTreatmentsDataLength(res.data);
      } else {
        let message = 'Failed to fetch patient details. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
  // get more options list data
  getOptionsData(data) {
    this.selectedApptId = data.appoinmnetData.appointmentId;
    if (data.option.id === 'generate_invoice') {
      this.invoiceStatus = 'ungenerated';
      this.generateInvoicePopupShowHide = true;
    } else if (data.option.id === 'block_patient') {
      // this.blockPatientPopupShowHide = true;
      this.patientId = data.appoinmnetData.patient.patientId;
      this.forminputs.forEach(each => {
        if (each.key === 'patient_name_id') {
          each.label = `${data.appoinmnetData.patient.firstName} ${data.appoinmnetData.patient.lastName}`;
          each.label1 = data.appoinmnetData.patient.patientId;
        }
      });
      this.getPatientDetails();
    } else if (data.option.id === 'cancel_appointment') {
      this.cancelAppointmentData = {
        patient: data.appoinmnetData.patient,
        type: 'adminappointments',
        appointmentId: data.appoinmnetData.appointmentId,
        locationType: data.appoinmnetData.locationType,
        userId: data.appoinmnetData.patient.userId
      };
      this.cancelPopupShowHide = true;
    } else if (data.option.id === 'mark_absent') {
      this.absentAppointmnet(data.appoinmnetData);
    } else if (data.option.id === 'send_reminder') {
      this.sendReminder(data.appoinmnetData);
    } else if (data.option === 'pmp-popUp') { 
      this.pmpPopupShowHide = true
      if(data.appoinmnetData.hasOwnProperty('pmp_sheet_data')){
        this.pmpSheetData = data.appoinmnetData.pmp_sheet_data
        this.pmpData = (this.pmpSheetData.hasOwnProperty('PMP') && this.pmpSheetData.PMP) ? this.pmpSheetData.PMP : '';
        this.pmpMeds = (this.pmpSheetData.hasOwnProperty('MEDS') && this.pmpSheetData.MEDS) ? this.pmpSheetData.MEDS : '';
      } else {
        this.pmpData = '';
        this.pmpMeds = ''
      }
    }else {
      this.observable.showToast(TOAST_STATUSES.SUCCESS, 'This feature is coming out soon..!!');
    }
  }

  // Search filter api function
  searchFilter(data) {
    this.isFilterData = false;
    if (typeof data === 'string' && (data.replace(/\s/g, '').length > 0 || data == '')) {
      this.searchInputText = data;
      const searchPayload = {
        pagination: {
          page: 1,
          limit: DEFAULT_PAGINATION_LIMIT
        },
        search: this.searchInputText,
      };
      // this.tabsDateFilters(searchPayload);
      if(this.searchInputText === ''){
        this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.APPOINTMENTS}`], { queryParams: { type: this.listType } });
      } else {
        this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.APPOINTMENTS}`], { queryParams: { type: this.listType, search: this.searchInputText  } });
      }
    }
  }

  // Switch case for the appointment type icons
  getingAppointmentTypeSvgName(appointmentType) {
    let appointmentTypeIconName = '';
    switch (appointmentType) {
      case 'Medication Management':
        appointmentTypeIconName = 'medication-management';
        break;
      case 'Ketamine Treatment':
        appointmentTypeIconName = 'ketamine-treatment';
        break;
      case 'TMS':
        appointmentTypeIconName = 'adminTms';
        break;
      case 'Psychological Therapy':
        appointmentTypeIconName = 'psychological-therapy';
        break;
      default:
        appointmentTypeIconName = 'treatement';
        break;
    }
    return appointmentTypeIconName;
  }



  // Switch case for the appointment type icons
  emptyMessagedata(appointmentType) {
    let emptyDataObj = {};
    switch (appointmentType) {
      case 'upcoming':
        emptyDataObj = {
          className: 'appointmentsList',
          icon: 'appointment-empty-data',
          message: 'There are no upcoming appointments'
        };
        break;
      case 'history':
        emptyDataObj = {
          className: 'appointmentsList',
          icon: 'appointment-empty-data',
          message: 'There are no previous appointments'
        };
        break;
      case 'all':
        emptyDataObj = {
          className: 'appointmentsList',
          icon: 'appointment-empty-data',
          message: 'There are no appointments'
        };
        break;
    }
    return emptyDataObj;
  }

  // Get inprogress and next in 5min
  getAppointslatStatus(appInfo) {
    let currentDate = formatDate(this.currentTime, 'yyyy-MM-ddTHH:mm:ss', 'en');
    let curFullDate = formatDate(this.currentTime, 'yyyy-MM-dd', 'en');
    let getdate = formatDate(new Date(appInfo.appointmentDate + 'T' + appInfo.appointmentTime), 'yyyy-MM-ddTHH:mm:ss', 'en');
    let nextFiveMinits = formatDate(new Date(this.currentTime).setMinutes(new Date(this.currentTime).getMinutes() + 5),
      'yyyy-MM-ddTHH:mm:ss', 'en');
    let apptCompleteTime = formatDate(new Date(getdate).setMinutes(new Date(getdate).getMinutes() + appInfo.appointmentDuration),
    'yyyy-MM-ddTHH:mm:ss', 'en');
    // tslint:disable-next-line:one-variable-per-declaration
    let appStatus = appInfo.appointmentStatus;
    if (currentDate >= getdate && currentDate <= apptCompleteTime && appInfo.appointmentDate === curFullDate && (appStatus === 'A' || appStatus === 'P')) {
      this.appointmentTimeSlatClass = true;
      return `In Progress`;
    }
    if (getdate >= currentDate && getdate <= nextFiveMinits && (appStatus === 'A' || appStatus === 'P')) {
      this.appointmentTimeSlatClass = true;
      let getMin = new Date(getdate).getMinutes();
      getMin  = getMin === 0 ? 60 : getMin;
      let currentMin = new Date(currentDate).getMinutes();
      let differ = getMin - currentMin;
      return `In Next 0${differ} min`;
    } else {
      this.appointmentTimeSlatClass = false;
      return appInfo.appointmentTime;
    }
  }
  // View By filters
  viewByFilters(data) {
    setTimeout(() => {
      this.isFilterClear = false;
    }, 0);
    this.isFilterClear = true;
    if (data === 'nextWeek') {
      this.viewByFilterDataFlag = false;
    } else {
      this.viewByFilterDataFlag = true;
    }
    this.filterApplyFlag = false;
    this.radioValue = data;
    this.isFilterData = false;
    this.tabsDateFilters(this.INITIAL_PAYLOAD);
  }

  // This emit filter form Data
  filterDataEvent(data) {
    this.appyliedFilterObj = {
      filter: data,
      search: this.searchInputText,
      pagination: {
        page: 1,
        limit: DEFAULT_PAGINATION_LIMIT
      },
    };
    this.radioValue = '';
    this.filterApplyFlag = true;
    this.isFilterData = true;
    this.getAppointmentsList(this.appyliedFilterObj);
  }
  clearEventCall(data) {
  }

  // generate invoice fun
  // This is closing for generate invoice the popup
  generateInvoicePopupShowHideStatusFun(status) {
    this.generateInvoicePopupShowHide = status.cancelpopup;
    this.checkingSidebarSetStatus(false);
  }
  // generateInvoice($event)
  generateInvoice(data) {
    if (data) {
      // tslint:disable-next-line:one-variable-per-declaration
      let method, callUrl, bodyData, pathVariable, message; let callAPI = false;
      data.values ? this.amountGenerated = {
        copay: data.values.copayAmt,
        due: data.values.dueAmt,
        pay: (data.values.copayAmt + data.values.dueAmt),
        comment: data.values.comments
      // tslint:disable-next-line:no-unused-expression
      } : null;
      if (data.btnType === 'sendCustomer' || (data.btnType === 'submit' && this.invoiceStatus && this.invoiceStatus === 'ungenerated')) {
        method = 'POST';
        callUrl = 'generateInvoice';
        data.btnType === 'sendCustomer' ?
          bodyData = {
            type: 'NEW',
            appointmentId: this.selectedApptId,
            status: 'unpaid',
            copay: this.amountGenerated.copay,
            // pay: this.amountGenerated.pay
            pay: this.amountGenerated.due,
            invoiceType: 'appt_invoice',
            comment: this.amountGenerated.comment
          } : bodyData = {
            type: 'NEW_PAID',
            appointmentId: this.selectedApptId,
            status: 'paid',
            copay: this.amountGenerated.copay,
            // pay: this.amountGenerated.pay,
            pay: this.amountGenerated.due,
            paymentMode: data.form.value.paymentMode,
            referenceId: data.form.value.referenceId,
            invoiceType: 'appt_invoice',
            comment: this.amountGenerated.comment
          };
        message = data.btnType === 'sendCustomer' ? 'Invoice has been sent to the customer successfully' : 'Invoice has been marked as paid successfully.';
        // tslint:disable-next-line:no-unused-expression
        data.btnType === 'submit' ? this.makeAsPaidPopupShowHide = false : null;
        callAPI = true;
      } else if (data.btnType === 'makePayment') {
        // this.observable.showToast(TOAST_STATUSES.SUCCESS, 'This feature is coming out soon..!!');
        let payload = {
          type: 'POST',
          url: 'checkoutSession',
          isDeveloper: true,
          body: {
            type: 'NEW',
            appointmentId: this.selectedApptId,
            copay: this.amountGenerated.copay,
            pay: this.amountGenerated.due,
            invoiceType: 'appt_invoice'
          }
        };
        this.http.makeHttpRequest(payload).subscribe((res) => {
          if (res.error) {
            alert(res.error.message);
            return;
          } else {
            this.paymentToken = res.result.response.token
            let inputElement = document.getElementById("paymentvalue") as HTMLInputElement;
            // Set the value of the input element
            inputElement.value = this.paymentToken;
            // Get the form element
            let form = document.getElementById('formAuthorizeNetPage') as HTMLFormElement;
            form.method = 'POST';
            form.submit();
          }
          // stripe.redirectToCheckout({ sessionId: res.id });
        });
        this.checkingSidebarSetStatus(false);
      } else if (data.btnType === 'markAsPaid') {
        this.generateInvoicePopupShowHide = false;
        this.makeAsPaidPopupShowHide = true;
      }

      if (callAPI) {
        // API Call for all
        callAPI = false;
        const payload = {
          type: method,
          url: callUrl,
          isDeveloper: true,
          pathVariables: [pathVariable ? pathVariable : null],
          body: bodyData ? bodyData : null,
        };
        this.http.makeHttpRequest(payload).subscribe((res) => {
          if (this.http.isSuccessfulResponse(res)) {
            this.getAppointmentsList(this.appyliedFilterObj);
            this.observable.showToast(TOAST_STATUSES.SUCCESS, message);
          } else {
            this.observable.showToast(TOAST_STATUSES.ERROR, res.error.message);
          }
        });
      }
    }
  }

  // This is closing for make as paid popup
  makeAsPaidPopupShowHideStatusFun(status) {
    this.makeAsPaidPopupShowHide = status.cancelpopup;
  }

  // Invoice List icon action catching click
  invoiceListClick(data) {
    if (data.statusText === 'ungenerated') {
      this.generateInvoicePopupShowHide = true;
    } else if (data.statusText === 'markaspaid') {
      this.observable.showToast(TOAST_STATUSES.SUCCESS, 'This feature is coming out soon..!!');
    } else if (data.statusText === 'resend') {
      this.observable.showToast(TOAST_STATUSES.SUCCESS, 'This feature is coming out soon..!!');
    }
  }
  // This is closing for block patient the popup
  blockPatientPopupShowHideStatusFun(status) {
    this.blockPatientPopupShowHide = status.cancelpopup;
    this.checkingSidebarSetStatus(false);
  }
  // Block patient Save action
  blockPatientSubmit(data) {
    // Block API call
    this.blockTheUserAPI(data);
  }
  // Block API PUT Call
  blockTheUserAPI(bodyData) {
    const payloadData = {
      type: 'PUT',
      url: 'patientActions',
      isDeveloper: true,
      body: bodyData,
      pathVariables: [this.patientId],
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        if (Number(res.data.patientId) === this.patientId) {
          this.blockPatientApplyStatus = true;
          this.blockPatientPopupShowHide = false;
          this.tabsDateFilters(this.INITIAL_PAYLOAD);
          this.observable.showToast(TOAST_STATUSES.SUCCESS, 'Updated the patient blocked status');
        }
      } else {
        // Error
        let message = 'Failed to uplodate patient block status. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
  // Get All Treatments Data length
  getTreatmentsDataLength() {
    const payloadData = {
      type: 'GET',
      url: 'invoiceFilter',
      isDeveloper: true,
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.treatmentsCount = Object.keys(res.data.treatment).length;
        this.tabsDateFilters(this.INITIAL_PAYLOAD);
        this.getFilterData = res.data;
        this.getFilterData.treatment.unshift(TELEMEDICINE_MEDICATION);
        this.checkingSidebarState(false);
      } else {
        // Error
        let message = 'Failed to fetch patient lits data. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
      // this.isShowScreen = true;
    });
  }

  // Admin get filterData API call
  getTreatmentsData(blockstatus) {
    this.rawTreatmentFilterData = this.massageTreatmentsFilterData(this.getFilterData, blockstatus);
    this.blockPatientPopupShowHide = true;
    // this.checkingSidebarSetStatus(true);
  }
  // Massage the filter data
  massageTreatmentsFilterData(data, blockstatus) {
    if (data && typeof (data) === 'object') {
      data.treatment = data.treatment.map(doc => {
        doc['isSelected'] = false;
        doc['locationId'] = (doc.locationId) ? doc.locationId : null;
        doc['name'] = (doc.appointmentTypeId === TELEMEDICINE_MEDICATION.appointmentTypeId && doc.locationId === TELEMEDICINE_MEDICATION.locationId) ? `${doc.appointmentTypeName} Online` : (doc.appointmentTypeId === TELEMEDICINE_MEDICATION.appointmentTypeId && doc.locationId !== TELEMEDICINE_MEDICATION.locationId) ? `${doc.appointmentTypeName} In Office` : doc.appointmentTypeName;
        blockstatus.forEach(each => {
          if (each.appointmentTypeId === doc.appointmentTypeId && each.locationId === doc.locationId) {
            doc['isSelected'] = true;
          }
        });
        return {
          ...doc,
          value: doc.appointmentTypeId,
        };
      });
      return data;
    } else {
      // Error
    }
  }
  cancelPopupShowHideStatusFun(status) {
    this.cancelPopupShowHide = status.cancelpopup;
    this.checkingSidebarState(this.cancelPopupShowHide);
    this.pageClickedData = {
      pagination: {
        page: this.currentPage,
        limit: this.pageLimit,
      }
    };
    this.showAPILoader = true;
    this.tabsDateFilters(this.pageClickedData);
  }

  pmpPopupShowHideStatusFun(status){
    this.pmpPopupShowHide = status.cancelpopup
  }

  absentAppointmnet(appoinmnetData) {
    let currentDate = new Date();
    let diffTime: any = (currentDate.getUTCHours() - currentDate.getHours()) + ':' + (currentDate.getUTCMinutes() - currentDate.getMinutes());
    let absentBody = {
      userId: appoinmnetData.patient.userId,
      locationType: appoinmnetData.locationType,
      type: 'ABSENT',
      timeDiff: diffTime
    };
    let payload = {
      type: 'PUT',
      url: 'appointmentDetails',
      isDeveloper: true,
      pathVariables: [appoinmnetData.appointmentId],
      body: absentBody
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.pageClickedData = {
          pagination: {
            page: this.currentPage,
            limit: this.pageLimit,
          }
        };
        this.showAPILoader = true;
        this.tabsDateFilters(this.pageClickedData);
        this.observable.showToast(TOAST_STATUSES.SUCCESS, 'This appointment has been marked as absent');
      } else {
        let message = 'Failed to absent the appointment. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  sendReminder(appoinmnetData) {
    let payload = {
      type: 'GET',
      url: 'appointmentDetails',
      isDeveloper: true,
      pathVariables: [appoinmnetData.appointmentId + '/reminder'],
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.pageClickedData = {
          pagination: {
            page: this.currentPage,
            limit: this.pageLimit,
          }
        };
        this.showAPILoader = true;
        this.tabsDateFilters(this.pageClickedData);
        this.observable.showToast(TOAST_STATUSES.SUCCESS, 'Appointment schedule reminder email has been sent to your registered email id. Please check');
      } else {
        let message = 'Failed to reminder the appointment. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
  setTimeToDateObj(date, time) {
    let finalDate = date;
    let s = time;
    let parts = s.match(/(\d+)\:(\d+) (\w+)/);
    let hours = /am/i.test(parts[3]) ? parseInt(parts[1], 10) : parseInt(parts[1], 10) + 12;
    let minutes = parseInt(parts[2], 10);
    finalDate.setHours(hours);
    finalDate.setMinutes(minutes);

    return finalDate;
  }

  // reforming actions
  actions(appInfo) {
    let adminActions = [];
    adminActions = getActionsList(appInfo.appointmentTypeName, appInfo.appointmentStatus, appInfo.invoiceStatus, 'appointment_details');
    adminActions = adminActions.filter(icon => this.permissionsArr.includes(icon.permissionName));
    return adminActions;
  }
  // getting permissions based on user id
  getAdminPermissions(stat?) {
    const payloadData = {
      type: 'GET',
      url: 'getPermissions',
      isDeveloper: true
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        let tempPerms = ['APPOINTMENT_CREATE', 'APPOINTMENT_EDIT', 'PATIENT_EDIT', 'PATIENT_DETAILS', 'INVOICE_EDIT', 'INVOICE_DETAILS'];
        res.data.permissions.forEach(obj => {
            this.permissionsArr.push(obj.permissionName);
        });
        if (this.permissionsArr.includes('INVOICE_MARKASPAID')){
          this.isMarkasPaid = true;
        }
        else{
          this.isMarkasPaid = false
        }
        if (!this.permissionsArr.includes('APPOINTMENT_LIST')) {
          this.observable.showToast(TOAST_STATUSES.ERROR, 'Sorry! You do not have access to this page.');
          this.router.navigate(['/admin']);
        }
        tempPerms.forEach(perm => {
          if (!this.permissionsArr.includes(perm)) {
            this.adminPermissions[perm] = false;
          }
        });
      } else {
        // Error
        let message = (res.error) ? res.error.message : 'Error in getting permissions';
      }
    });
    this.permissionsDone = true;
  }
  // Forming TMS Pending message
  AppointmentNameForming(appInfo, status?) {
    if ((appInfo.appointmentStatus === 'A' || appInfo.appointmentStatus === 'P') && appInfo.appointmentTypeId === 4 && appInfo.isTMSfill !== undefined && appInfo.isTMSfill && !appInfo.pendingTMS) {
      return status ? true : `${appInfo.appointmentTypeName} (TMS Forms Pending)`;
    } else if ((appInfo.appointmentStatus === 'A' || appInfo.appointmentStatus === 'P') && appInfo.appointmentTypeId === 4 && appInfo.pendingTMS) {
      return status ? true : `${appInfo.appointmentTypeName} (TMS Forms Pending Approval)`;
    } else {
      return status ? false : appInfo.appointmentTypeName;
    }
  }
  // excel validation
  
  addfile(event, type) {
    if(type != 'medicationsExcelSheet') this.selectImportSheetData = true;
    this.importOverlay.hide();
    let file = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    if(this.validateFileName(file)){
      fileReader.onload = (e) => {
        this.arrayBuffer = fileReader.result;
        var data = new Uint8Array(this.arrayBuffer);
        var arr = new Array();
        for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
        var bstr = arr.join("");
        var workbook = XLSX.read(bstr, { type: "binary" });
        var first_sheet_name = workbook.SheetNames[0];
        var worksheet = workbook.Sheets[first_sheet_name];
        // var arraylist = XLSX.utils.sheet_to_json(worksheet, { raw: true });
        let sheetData = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
          defval: '',
          blankrows: true
        });
        let twosGroup =''
        let threesGroup = ''
        let otherGroup = ''
        let edgeCaseGroup = [];
        let list = this.validateFile(sheetData, type, file)
        list.forEach((item:any, i)=>{
          if (i % 2 === 0 && i % 3 !== 0) {
            twosGroup = item + ', ' + twosGroup
          } else if (i % 3 === 0 && i % 2 !== 0) {
            threesGroup = item + ', ' + threesGroup
          } else if (i % 3 !== 0 && i % 2 !== 0) {
            otherGroup = item + ', ' + otherGroup
          } else {
            if(list.length <= 3) {
              threesGroup = item + ', ' + threesGroup
            } else {
              edgeCaseGroup.push(item)
            }
          }
        })
        if(edgeCaseGroup.length) {
          edgeCaseGroup.forEach((x,i) =>{
            i = i+1
            if(i % 2 == 0){
              twosGroup = x + ', ' + twosGroup
            } else if(i % 3 == 0){
              threesGroup = x + ', ' + threesGroup
            } else {
              otherGroup = x + ', ' + otherGroup
            }
          })
        }
        this.missedColumns = (list.length)?(list.length === 1)?list:[otherGroup.substring(0,otherGroup.length-2), twosGroup.substring(0,twosGroup.length-2), threesGroup.substring(0,threesGroup.length-2)]:[];
        if(!list.length){
          this.postFile(file, type)
        } else {
          this.missedColumnsPopupFlag=true;
          this.resetSheetData();
          this.selectImportSheetData=false;
        }
      }
    }
    else{
       this.resetSheetData();
      return
    } 
  } 
  validateFileName(file) {
    let filename = file ? file['name'].split('.')[0] : null;
    let regex: any = /[!@#$%^&*()+\-=\[\]{};':"\\|,.`~<>\/?]+/;
    let fileNameContainsSpecialChar = regex.test(filename);
    if (file.name.split('.').pop() != 'xlsx') {
      this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.PLEASE_UPLOAD_XL_SHEET);
      return false;
    }
    if (file && fileNameContainsSpecialChar) {
      this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.INVALID_FILENAME_ERROR_MSG);
      return false;
    }
    return true;
  }

    

  /**
   * Post the selected file.
   * @param file 
   * @param uploadType 
   */
  postFile(file, uploadType) {
    let type;
    if (!file.type) {
      type = file.name.split('.').pop().toLowerCase();
    }
    let getUploadUrls = {
      type: HTTPMethods.POST,
      url: API_URLS.GET_UPLOAD_URL,
      isDeveloper: true,
      body: {
        fileNames: [{
          key: uploadType,
          name: this.accentedtoNormalFun(file.name),
          contentType: file.type || type,
          timestamp: new Date(convertDateToSpecifiedOffset(DEFAULT_TIME_ZONE_DIFF, new Date())).getTime(),
          day: 'medicationsExcelSheet' ? 'today' : this.radioValueForMedicationSheet == 'todaysheet' ? 'today' : 'tomorrow' 
        }],
        sheets: true
      },
    };

    this.http.makeHttpRequest(getUploadUrls).subscribe(res => {
      let uploadPayload = {
        type: 'PUT',
        url: res[uploadType],
        body: file,
        isExternal: true,
        contentType: file.type || file.name.split('.').pop().toLowerCase()
      };
      this.http.makeHttpRequest(uploadPayload).subscribe(response => {
        let replacePayload = {
          type: HTTPMethods.POST,
          url: API_URLS.REPLACE_FILE,
          isDeveloper: true,
          body: {
            sheetType: uploadType,
            sheetUrl: res[uploadType],
            day: uploadType == 'medicationsExcelSheet' ? 'today' : this.radioValueForMedicationSheet == 'todaysheet' ? 'today' : 'tomorrow' 
          }
        };
        this.http.makeHttpRequest(replacePayload).subscribe(resp => {
          this.observable.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.SHEET_UPLOAD_SUCCESS);
          this.tabsDateFilters(this.INITIAL_PAYLOAD);
          this.selectImportSheetData=false;
          this.resetSheetData();
        });
      });
    });
  }

  missedColumnsPopup(status, isShow?) {
    this.missedColumnsPopupFlag = status.cancelpopup;
  }

  /**
   * Validate file to have specified fields.
   * @param sheetData file rows.
   * @param type file type.
   */
  validateFile(sheetData, type, file){
    let found = true;
    let list = [];


    if(type == 'pmpExcelSheet'){
      list = this.pmpHeader;
    } else if(type == 'tmsAuthExcelSheet'){
      list = this.authorizationHeaders;
    } else if(type == 'verificationExcelSheet'){
      list = this.verificationHeaders;
    }else if(type == 'medicationsExcelSheet'){
      list = this.medicationHeaders;
    }
    let itemList = sheetData[0].map(ele => {
      return ele.toLowerCase();
    })
    let missingList = []
    list.forEach((item: string) => {
      if(!itemList.includes(item.toLowerCase())){
        found = false;
        missingList.push(item)
      }
    })
    return missingList;
  }

  resetSheetData(){
    this.pmpValue = '';
    this.tmsAuthValue = '';
    this.verifValue = '';
    this.medicationsValue = ''
  }

  /**
   * Export file.
   * @param item 
   */
  importFile(){
    this.selectImportSheetData = true;
    this.radioValueForMedicationSheet= "todaysheet"
  }

  clickExportItem(item:any) {
    this.currentlySelectedExportItem = item;
    let sheetList = ["medicationsExcelSheet","pmpExcelSheet","verificationExcelSheet","tmsAuthExcelSheet"]
    if(sheetList.includes(item.value)) {      
      this.radioValueForMedicationSheet = 'todaysheet';
    }
    this.selectMedSheetData = true;
    this.exportOverlay.hide();
    if(item.value === 'finalMedicationsExcelSheet') {
      this.selectMedSheetData = false;
     this.exportFile(item)
   } 
    // if(item.value === "pmpExcelSheet","verificationExcelSheet","tmsAuthExcelSheet") this.radioValueForMedicationSheet="todaysheet"
    //pop up open
  }

  popUpFunction(day?: any) {
    if(this.currentlySelectedExportItem.value === 'medicationsExcelSheet'){
      this.selectMedSheetDataPopup(this.radioValueForMedicationSheet);
    }
    this.exportFile(this.currentlySelectedExportItem)
  }

  exportFile(item){
    if(item.value === 'medicationsExcelSheet') {
      
      this.radioValueForMedicationSheet = 'todaysheet';
      this.selectMedSheetData = true;
    } else {
      let exportFilePayload = {
        type: HTTPMethods.POST,
        url: API_URLS.EXPORT_FILE,
        isDeveloper: true,
        body: {
          sheetType: item.value,
          day :  this.radioValueForMedicationSheet == "todaysheet"? 'today' :  'tomorrow'
        },
        params: {day:  this.radioValueForMedicationSheet == "todaysheet"? 'today' :  'tomorrow'}

      };
      this.http.makeHttpRequest(exportFilePayload).subscribe(response => {
        if (this.http.isSuccessfulResponse(response)) {
          this.observable.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.FILE_EXPORT_SUCCESS);
          this.selectMedSheetData = false;
          let url: string = response.data[item.value]
          if(url == '' || url == null){
            this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.MAIL_GENERATED_SUCCESSFULLY_FOR_TOMORROW);
          } else {
            let name = url.substring(
              url.lastIndexOf('__name__') + 8,
              url.lastIndexOf('?')
            );
              fetch(response.data[item.value]).then((t) => {
                return t.blob().then((b) => {
                  var a = document.createElement('a');
                  a.href = URL.createObjectURL(b);
                  a.setAttribute('download', name);
                  a.click();
                });
              }).then((t)=>console.log(t,'this is t')).
              catch((err:any)=>console.log(err, 'this is the error'));
          }
        } else {
          this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.FILE_EXPORT_FAIL);
        }
      });
    }
  }
  selectMedSheetDataPopup(mode){
      let exportFilePayload = {
        type: HTTPMethods.POST,
        url: API_URLS.MEDICATION_EXPORT,
        isDeveloper: true,
        body: {
        },
        params: {day:  this.radioValueForMedicationSheet == "todaysheet"? 'today' : 'tomorrow'}
      };
      this.http.makeHttpRequest(exportFilePayload).subscribe(response => {
        if (this.http.isSuccessfulResponse(response)) {
          this.observable.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.FILE_EXPORT_SUCCESS);
          this.selectMedSheetData = false;
        } else {
          this.observable.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.FILE_EXPORT_FAIL);
        }
      });
  }
  /**
   * for accented letter change convention function
   * @param name 
   */
  accentedtoNormalFun(name) {
    let originalText = name;
    let result = originalText.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    return result;
  }
}
