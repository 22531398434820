<div class="notification__list-page" *ngIf="permissionsDone">
    <div class="background-image">
        <img src="assets/images/pngs/newpatientform-background.png" alt="bg image">
    </div>
    <!-- Main notification List Page Wrapper -->
    <div class="notification__list-main-wrapper padding-lr">
        <!-- notifications List -->
        <div class="notification__list-navigation-menu">
            <app-breadcrumb [breadCrumbData]="breadCrumbData"></app-breadcrumb>
            <!-- Primary action on Page -->
        </div>
        <!-- Filters section -->
        <div class="notification__list-filetr-wrapper">
            <!-- Tabs Filter -->
            <app-tab-view [tabsData]='filtersTabText.slice(0, 2)' (selectedTab)="selectedTab($event)"></app-tab-view>
            <!-- Advance filter view  -->
            <div class="notification__list-filter-view">
                <div class="notification__list-filter-view-buttons">
                    <!--radio-btn field start-->
                    <!-- Radio buttons -->
                    <p class="notification__list-filter-view-title">Status:</p>
                    <div class="form__field">
                        <div class="custom__radio-button">
                            <input type="radio" id="all" value="all" name="notification" (change)="viewByFilters('all')" [(ngModel)]="radioValue">
                            <label for="all" class="radio-label"><span class="icon--styles">
                                </span>All</label>
                        </div>
                    </div>
                    <div class="form__field">
                        <div class="custom__radio-button">
                            <input type="radio" id="read" value="read" name="notification" (change)="viewByFilters('read')" [(ngModel)]="radioValue">
                            <label for="read" class="radio-label"><span class="icon--styles">
                                </span>Read</label>
                        </div>
                    </div>
                    <div class="form__field">
                        <div class="custom__radio-button">
                            <input type="radio" id="unread" value="unread" name="notification" (change)="viewByFilters('unread')" [(ngModel)]="radioValue">
                            <label for="unread" class="radio-label"><span class="icon--styles">
                                </span>Unread</label>
                        </div>
                    </div>
                </div>
                <!-- Advanced filters -->
            </div>
            <!-- Advance filter view -->
        </div>
        <!-- Filter section end -->
        <ng-container *ngIf="isShowScreen">
            <!-- Upcoming notifications starts here -->
            <div class="notification__list-block">
                <ng-container *ngFor=" let notification of notificationsTotalListPerPage">
                    <app-notification-card [notificationData]="notification" (redirectTo)="notificationsClick($event)" [permissions]="adminPermissions"></app-notification-card>
                </ng-container>
            </div>
            <!-- Upcoming notifications ends here -->
            <!-- Pagination component starts here -->
            <app-pagination [paginationRawData]="paginationData" (pageChanged)="pageChangedEvent($event)" *ngIf="notCount > 10"></app-pagination>
            <!-- Pagination component ends here -->
            <!-- Empty list Starts here -->
            <app-empty-list-data [emptyMessageData]="emptyNotificationsListMessages" *ngIf="notificationsTotalListPerPage.length === 0"></app-empty-list-data>
            <!-- Empty list ends here -->
        </ng-container>
    </div>

</div>