import { Component, OnInit } from '@angular/core';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { Router, ActivatedRoute } from '@angular/router';
import { each } from 'jquery';
import { CognitoService } from '@app/common/services/cognito.service';
import { HttpService } from '@app/common/services/http.service';
import { element } from 'protractor';

@Component({
  selector: 'app-admin-change-password',
  templateUrl: './admin-change-password.component.html',
  styleUrls: ['./admin-change-password.component.scss']
})
export class AdminChangePasswordComponent implements OnInit {
   // pagination data
   breadCrumbData = {
    link: '/admin/admin-profile',
    linkText: 'Change Password'
  };
  form: any;
  name: any;
  saveFormData: boolean = false;
  showUserData: boolean = true;
  formName;
  email;
  user;
  showScreen;
  constructor(
    private formService: CustomFormHandlerService,
    private router: Router,
    private route: ActivatedRoute,
    private cognitoService: CognitoService,
    private http: HttpService
  ) { }

  ngOnInit() {
    let userLogedIn = JSON.parse(window.localStorage.getItem('isLogedIn'));
    if (!userLogedIn) {
      this.router.navigate(['admin/login']);
      return;
    }
    this.cognitoService.getUser().then(user => {
      this.user = user;
      if (user.firstName && user.lastName) {
        this.name = (user.firstName || '') + ' ' + (user.lastName || '');
      } else {
        this.name = '';
      }
      this.email = user.email;
      this.showScreen = true;
      /*if (!(user.firstName && user.lastName)) {
        if (document.getElementById('my_appointment')) {
          document.getElementById('my_appointment').style.display = 'none';
        }
      } else {
        document.getElementById('my_appointment').style.display = 'flex';
      }*/
    });

    this.formService.userProfileFormInputsConfig.forEach(eachConfig => {
        eachConfig.showontoggle = true;
        eachConfig.isShowpassword = false;
        eachConfig.type = 'password';
        eachConfig.value = '';
    });
    this.form = this.formService.initForm(this.formService.formTypes.USERPROFILE);
  }
  ngAfterViewChecked(): void {
  
  }

  saveForm() {

    this.saveFormData = true;
  }
  resetSaveFormParity() {
    window.setTimeout(() => {
      this.saveFormData = false;
    }, 0);

    return true;
  }
  getFormData(event) {
      this.cognitoService.changePassword(event.value, () => {
        this.edited();
        this.form.formGroup.reset();
      })
  }
  edited() {
    this.cognitoService.signOut(() => {
      window.localStorage.clear();
      this.router.navigate(['admin/login']);
    })
    this.form.formInputs.forEach(inp => {
      inp.value = '';
      inp.showontoggle = false;
    });
  }
  navigateBack(event) {
    console.log(this.form.formInputs, 'form');

    if (event) {
      this.router.navigate(['/admin/admin-profile']);
    }
    this.form.formInputs.forEach(eachInput => {
      eachInput.value = '';
      eachInput.showontoggle = false;
    });
  }

  ngOnDestroy() {
    if (this.form && this.form.formGroup) {
      this.form.formGroup.reset();
    }
}
}

