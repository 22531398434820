import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { HttpClient } from '@angular/common/http';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant'
import { ROUTE_PATHS } from '@app/common/constants/routing.constant'
import { HTTPMethods, TOAST_STATUSES } from '@app/common/constants/util.constant'
import { formatDate } from '@angular/common';
import { API_URLS } from '@app/common/constants/api-urls.constant';
import { cloneDeep } from 'lodash';
import { Validators } from '@angular/forms';
@Component({
  selector: 'app-tms-notes',
  templateUrl: './tms-notes.component.html',
  styleUrls: ['./tms-notes.component.scss']
})
export class TmsNotesComponent implements OnInit {
  Tms_Form_Tags: any = ['TMS_NOTES'];
  saveFormData: boolean = false
  form= [];
  paramsData: any = {}
  patientData:any = {}
  PreviewData: any = {}
  formDiagnosis = ['Depression', 'Anxiety', ]
  selectedButton: string;
  showScorePopup: boolean = false
  showPreview: boolean = false
  editNoteData: any = {};
  previousNoteData: any = {};
  restart: boolean =false;
  // restart:boolean = false;

  constructor(
    private formService: CustomFormHandlerService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpService,
    private observable: ObservableHelperService,
    private httpClient: HttpClient,
    private cd: ChangeDetectorRef
  ) { 
    route.queryParams.subscribe(res => {
      // this.patientId = res.patienID
      this.paramsData = res
      console.log("queryParams", this.paramsData);
    })
  }

  ngOnInit() {
    // this.showScorePopup = true;
    console.log("showScorePopup", this.showScorePopup, (this.paramsData.previousTmsNoteNumber === '36' ));
    if(!this.showScorePopup){
    if (this.paramsData.OperationType === 'EditExisting'){
      this.getTmsNote()
    } else if (this.paramsData.OperationType === 'CreateNew' && this.paramsData.previousTmsNoteNumber !== '0'){
      this.getAllTmsNotes()
    }
    if(this.paramsData.previousTmsNoteNumber == '0'){
    this.getPatientDetails()
    }
  }
    
  }

  loadForm(){
    if ((this.paramsData.OperationType === 'CreateNew' && this.paramsData.showScoreSheetFields === 'false')  ){ //&& !(this.restart === true)
      //this.removeScoreSheetFields()
    } else if (this.paramsData.OperationType === 'EditExisting' && this.editNoteData.hasOwnProperty('ScoryHistoryReport')){
      if (this.editNoteData.ScoryHistoryReport.length === 0){
//this.removeScoreSheetFields()
        let scoreValue = this.massageScoreHistoryCardData( this.editNoteData.ScoryHistoryReport )
        let scoreHistoryIndex = this.formService.tmsNoteFormConfig.findIndex( obj => obj['key'] === 'scoreHistoryTable' )
        this.formService.tmsNoteFormConfig[scoreHistoryIndex-1].value=true
        this.formService.tmsNoteFormConfig[scoreHistoryIndex].showontoggle=false
        this.formService.tmsNoteFormConfig[scoreHistoryIndex+1].showontoggle=false
      } else {
        let scoreValue = this.massageScoreHistoryCardData( this.editNoteData.ScoryHistoryReport )
        let scoreHistoryIndex = this.formService.tmsNoteFormConfig.findIndex( obj => obj['key'] === 'scoreHistoryTable' )
        
        this.formService.tmsNoteFormConfig[scoreHistoryIndex].formInputs = []
        for (let i = scoreValue.length - 1; i >= 0; i--) {
          this.formService.tmsNoteFormConfig[scoreHistoryIndex].formInputs.unshift(scoreValue[i])
        }
        if(scoreValue.length>0){
          this.formService.tmsNoteFormConfig[scoreHistoryIndex-1].value=false
          this.formService.tmsNoteFormConfig[scoreHistoryIndex].showontoggle=true
          this.formService.tmsNoteFormConfig[scoreHistoryIndex+1].showontoggle=true

        }
        else{
          this.formService.tmsNoteFormConfig[scoreHistoryIndex-1].value=true
          this.formService.tmsNoteFormConfig[scoreHistoryIndex].showontoggle=false
          this.formService.tmsNoteFormConfig[scoreHistoryIndex+1].showontoggle=false
        }
      }
    } 
    this.Tms_Form_Tags.forEach(element => {
      this.form.push(this.formService.initForm(this.formService.formTypes[element]))
    })
    console.log("this.formService.tmsNoteFormConfig", this.formService.tmsNoteFormConfig);
    
  }

  getPatientDetails() {
    const payloadData = {
      type: 'GET',
      url: API_URLS.PATIENT_DETAILS,
      isDeveloper: true,
      pathVariables: [this.paramsData.PatientID],
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        console.log("patientsDetails API respones", res);
        this.patientData = res.data
        console.log("this.patientData", this.patientData);
        if(this.paramsData.OperationType === 'CreateNew' ) { 
          
          this.loadForm()
          if (this.paramsData.previousTmsNoteNumber === '0' || this.restart=== true) { 
            this.prefillData() 
          
          } else { 
            this.prefillPreviousNoteData(this.previousNoteData) 
          }
        }
        else{
          this.prefillFormEdit(this.editNoteData)
        }
      } else {
        let message = 'Failed to fetch patient details. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  getAllTmsNotes(){
    const payloadOne = {
      type: 'GET',
      url: API_URLS.ALL_TMS_NOTES,
      isDeveloper: true,
      pathVariables: [this.paramsData.PatientID],
    }
    this.http.makeHttpRequest(payloadOne).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        console.log("getAllTmsNotes - res", res);
        if (res.data.tmsnots.length !== 0){
          let noteObj = res.data.tmsnots[res.data.tmsnots.length - 1]
          this.previousNoteData = this.flattenObject(JSON.parse(noteObj.tmsNotesData))
          this.getPatientDetails()
        }
      } else {
        let message = "Falied to get patient's all TMS notes . Please try again"
        message = res.error ? res.error.message : message
        this.observable.showToast(TOAST_STATUSES.ERROR, message)
        return
      }
    })
  }

  getTmsNote(){
    let patientId = this.paramsData.PatientID;
    let userId = this.paramsData.userID;
    let tmsnoteid = this.paramsData.tmsNoteID;
    const payloadData = {
      type: 'GET',
      url: API_URLS.TMS_NOTE_DETAILS,
      isDeveloper: true,
      // isAuthToken: true,
      pathVariables: [ userId, patientId, tmsnoteid],
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        console.log("tms note details API respones", res);
        this.editNoteData = this.flattenObject(JSON.parse(res.data.tmsMednote[0].tmsNotesData))
        console.log("editNoteData", this.editNoteData);
        this.loadForm()
        this.getPatientDetails()
        // this.prefillFormEdit(this.editNoteData)
      } else {
        let message = `Failed to fetch patient's tms note details. Please try again`;
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  removeScoreSheetFields(){
    let scoreHeaderIndex = this.form[0].formInputs.findIndex(obj => obj['label'] === 'TMS THERAPY PATIENT SCORE REPORT SHEET')
    if(scoreHeaderIndex >0){
      this.form[0].formInputs.splice(scoreHeaderIndex, 1)
    let scorefieldIndex = this.form[0].formInputs.findIndex(obj => obj['key'] === 'scoreHistoryTable')
    this.form[0].formInputs.splice(scorefieldIndex, 1)
    let scoreAddNewIndex = this.form[0].formInputs.findIndex(obj => obj['key'] === 'scoreTableRowAddNew')
    this.form[0].formInputs.splice(scoreAddNewIndex, 1)
    }
    console.log(this.form[0].formInputs,'step1');
  }

  prefillFormEdit(objectData){
    console.log("hello 1", objectData);
    let dia = objectData['Diagnosis'].split('/').map(element => element.trim());
    let height = objectData["Observations.height"].split(`'`)
    this.form[0].formInputs.forEach((element: any) => {
      if (objectData.hasOwnProperty(element.editAccessKey)) {
        element.value = objectData[element.editAccessKey]
      }
      if(element.key === 'tmsTherapyVisit'){ 
      element.label = (objectData.AppointmentType === 'Initial TMS Note') ? 'TMS THERAPY INITIAL VISIT' : 'TMS THERAPY FOLLOW UP VISIT'}
      if(element.key === 'tmsTherapyVisit'){ 
       if(objectData.AppointmentType === 'Follow Up TMS Note - Theta-burst'){
      element.label = 'FOLLOW UP TMS NOTE - THETA-BURST'
       }
      }
      if(element.key === 'socialSecurityNumber' ) { element.value=objectData.socialSecurityNumber}
      if (element.key === 'ptId') { element.value=objectData.ptID}
      if (element.key === 'dob') { element.value = formatDate((this.patientData.details.dob), 'MM/dd/yyyy', 'en'),this.patientData.details.dob }
      if (element.key === 'AppointmnetDate') {
        let evalDate = objectData["apptDate"].split('-')
        element.value = `${evalDate[1]}/${evalDate[2]}/${evalDate[0]}` //MM-DD-YYYY format
      }
      if(element.key === 'duration'){ 
        element.value = this.durationOfTherapy(objectData.durationOfTherapy)
      }
      if(dia.includes(element.key)){
        element.value = true;
      } 
      // if (element.key === 'ptId') { element.value = "XXX-XX-" + this.patientData.details.patientBasicInfo.socialSecurityNumber.slice(-4)}
      if (element.key === 'heightFt') {
        element.value = height.length !== 0 ? height[0] : ''
      }
      if (element.key === 'heightInch') {
        element.value = height.length !== 0 ? height[1] : ''
      }
    })
  }

 durationOfTherapy(duration: string) {
  const match = duration.match(/^(\d+)/);
  if (match) {
      return parseInt(match[1], 10);
  }
  return null;
}
  prefillPreviousNoteData(data){
    console.log("prefillPreviousNoteData fucntion", data);
    const tmsNoteType: string[] = ["Initial TMS Therapy", "Follow Up TMS Therapy", "Follow Up TMS Note - Theta-Burst"];
    let streetAddress = this.patientData.details.patientBasicInfo.streetAddress
    let height = data['Observations.height'] ? data['Observations.height'].split(`'`) : []
    this.form[0].formInputs.forEach((element: any) => {
      // prefilling from intake - start
      if (element.key === 'AppointmnetDate') { element.value = (this.paramsData.latestTmsApptDate !== '') ? formatDate((this.paramsData.latestTmsApptDate), 'MM/dd/yyyy', 'en') : formatDate(new Date(), 'MM/dd/yyyy', 'en')}
      if (element.key === 'patientName') { element.value = this.patientData.details.firstName +' '+ this.patientData.details.lastName}
      if (element.key === 'dob') {   element.value = formatDate((this.patientData.details.dob), 'MM/dd/yyyy', 'en'),this.patientData.details.dob}
      if (element.key === 'age') { element.value = this.calculateAge(this.patientData.details.dob)}
      if (element.key === 'patientGender') { 
        let textGender = this.patientData.details.patientBasicInfo.gender
        element.value = textGender.charAt(0).toUpperCase() + textGender.slice(1).toLowerCase()
        // this.patientData.details.patientBasicInfo.gender.toLowerCase()
      }
      if (element.key === 'socialSecurityNumber' ) { 
        element.value = (this.patientData.details.patientBasicInfo.socialSecurityNumber) ? "XXX-XX-" + this.patientData.details.patientBasicInfo.socialSecurityNumber.slice(-4) : "XXX-XX-XXXX"}
      if (element.key === 'primaryHolderText') { element.value = this.patientData.primaryInsuranceProvider.insuranceName}
      if (element.key === 'primaryInsuranceId') { element.value = this.patientData.details.insuranceDetails.policyHolder.policyHolderMemberId}
      if (element.key === 'Address') { element.value = streetAddress.streetAddress1 + ', ' + streetAddress.city + ', '+ streetAddress.state + ', ' + streetAddress.zipCode}
      if (element.key === 'phoneNumber') { element.value = this.patientData.details.phoneNumber}
      if (element.key === 'knownAllergies') { element.value = this.patientData.details.medicalHistory.knownAllergies}
      if (element.key === 'ptId') { element.value = (this.patientData.details.patientBasicInfo.socialSecurityNumber) ? "XXX-XX-" + this.patientData.details.patientBasicInfo.socialSecurityNumber.slice(-4) : "XXX-XX-XXXX"}
      // prefilling from intake - end

      // start - Prefilling from TMS Template
      if (element.key === 'npi') { element.value = '#1033121058'}
      if (element.key === 'PhysicianName') { element.value = 'Rama S. Prayaga, M.D.'}
      if (element.key === 'addressPhysician') { element.value = '5194 DAWES AVENUE ALEXANDRIA, VA 22311'}
      if (element.key === 'phoneNumbePhysician') { element.value = '703-820-1900'}
      if(element.key === 'tmsTherapyVisit'){ 
        element.label = (this.paramsData.tmsType === 'Initial TMS Note') ? 'TMS THERAPY INITIAL VISIT' : 'TMS THERAPY FOLLOW UP VISIT'}
      if (element.key === 'tmsType') { element.value = this.paramsData.tmsType}
      if (element.key === 'Depression') { element.value = true}
      if (element.key === 'duration') { element.value = (this.paramsData.tmsType === 'Initial TMS Note') ? 55 : 45}
      // end - Prefilling from TMS Template

      //Prefilling from Previous note - start
      if (element.key === 'purposeOfVisit') { 
        // debugger
        for (let noteType of tmsNoteType) {
          const regex = new RegExp(noteType, 'i');
          if (regex.test(data.purposeOfVisit)) {
            element.value = data.purposeOfVisit.replace(regex, 'Follow Up TMS Therapy');
            break
          } else {
            element.value = data.purposeOfVisit
          }
        }
        // element.value = data.purposeOfVisit
      }
      if (element.key === 'heightFt') { element.value = height.length !== 0 ? height[0] : ''}
      if (element.key === 'heightInch') { element.value = height.length !== 0 ? height[1] : ''}
      if (element.key === 'weight') { element.value = data['Observations.weight']}
      if (element.key === 'bloodPressure') { element.value = data['Observations.BP']      }
      if (element.key === 'pulse') { element.value = data['Observations.Pulse'] }
      if (element.key === 'addObservations') { element.value = data.addObservations }
      if (element.key === 'TMSTherapyProcedure') { element.value = (data.TMSTherapyProcedure) ? data.TMSTherapyProcedure : "The Patient is seated. The patient's head was inspected to ensure that the patient had no metallic items in the patient's hair or the proximity of the Stimulation Coil." + '\n'+ "The motor strip was identified with noted movement of the middle finger and thumb of the Rt hand at 60% Power." + '\n' + "The patient tolerated TMS Therapy without incident. No adverse reactions. The coil was positioned well at the beginning of the therapy session.  No face/eyebrow twitching."}
      //patient's measurements
      if (element.key === 'bridgeMask') { element.value = data['patientMeasurements.bridgeToMask']}
      if (element.key === 'bridgeInoin') { element.value = data['patientMeasurements.bridgeToInoin']}
      if (element.key === 'earToear') { element.value = data['patientMeasurements.earToEar']}
      if (element.key === 'mapping') { element.value = data['patientMeasurements.mapping']}
      if (element.key === 'mappingHertz') { element.value = data['patientMeasurements.mappingHertz']}
      if (element.key === 'tmsBegun') { element.value = data['patientMeasurements.tmsBegun']}
      if (element.key === 'tmsBegunProtocol') { element.value = data['patientMeasurements.tmsBegunProtocol']}
      if (element.key === 'tmsProtocolat') { element.value = data['patientMeasurements.tmsProtocolat']}
      if (element.key === 'recommendations') { element.value = data.recommendations}
      if (element.key === 'when') { element.value = data.when}
      if (element.key === 'returnVisit') { element.value = data.returnVisit}
      //Prefilling from Previous note - end
    })
  }
  flattenObject(ob) {
    let result = {}
    for (let key in ob) {
      if (typeof ob[key] === 'object' && ob[key] !== null && !Array.isArray(ob[key])) {
        let temp = this.flattenObject(ob[key])
        for (let key2 in temp) {
          result[key + '.' + key2] = temp[key2]
        }
      } else {
        result[key] = ob[key]
        if (ob[key] === null) {
          result[key] = null
        }
      }
    }
    return result
  }

  prefillData() {
    let streetAddress = this.patientData.details.patientBasicInfo.streetAddress
    let height = this.patientData.details.patientBasicInfo.height ? this.patientData.details.patientBasicInfo.height.split(`'`) : []
    console.log("streetAddress", streetAddress, );
    
    this.form[0].formInputs.forEach((element: any) => {
      // prefilling from intake
      if (element.key === 'AppointmnetDate') { element.value = (this.paramsData.latestTmsApptDate !== '') ? formatDate((this.paramsData.latestTmsApptDate), 'MM/dd/yyyy', 'en') : formatDate(new Date(), 'MM/dd/yyyy', 'en')}
      if (element.key === 'patientName') { element.value = this.patientData.details.firstName +' '+ this.patientData.details.lastName}
      if (element.key === 'dob') { element.value = formatDate((this.patientData.details.dob), 'MM/dd/yyyy', 'en'),this.patientData.details.dob}
      if (element.key === 'age') { element.value = this.calculateAge(this.patientData.details.dob)}
      if (element.key === 'patientGender') {
        let textGender = this.patientData.details.patientBasicInfo.gender
        element.value = textGender.charAt(0).toUpperCase() + textGender.slice(1).toLowerCase()
        //this.patientData.details.patientBasicInfo.gender.toLowerCase()
      }
      if (element.key === 'socialSecurityNumber' )  {
        element.value = (this.patientData.details.patientBasicInfo.socialSecurityNumber) ? "XXX-XX-" + this.patientData.details.patientBasicInfo.socialSecurityNumber.slice(-4) : "XXX-XX-XXXX"}
      if (element.key === 'primaryHolderText') { element.value = this.patientData.primaryInsuranceProvider.insuranceName}
      if (element.key === 'primaryInsuranceId') { element.value = this.patientData.details.insuranceDetails.policyHolder.policyHolderMemberId}
      if (element.key === 'Address') { element.value = streetAddress.streetAddress1 + ', ' + streetAddress.city + ', '+ streetAddress.state + ', ' + streetAddress.zipCode}
      if (element.key === 'phoneNumber') { element.value = this.patientData.details.phoneNumber}
      if (element.key === 'knownAllergies') { element.value = this.patientData.details.medicalHistory.knownAllergies}
      if (element.key === 'heightFt') { element.value = height.length !== 0 ? height[0] : ''}
      if (element.key === 'heightInch') { element.value = height.length !== 0 ? height[1] : ''}
      if (element.key === 'ptId') { 
        element.value = (this.patientData.details.patientBasicInfo.socialSecurityNumber) ? "XXX-XX-" + this.patientData.details.patientBasicInfo.socialSecurityNumber.slice(-4) : "XXX-XX-XXXX"}
      if (element.key === 'weight') { element.value = this.patientData.details.patientBasicInfo.weight}

      //prefilling form TMS Template
      if (element.key === 'npi') { element.value = '#1033121058' }
      if (element.key === 'PhysicianName') { element.value = 'Rama S. Prayaga, M.D.'}
      if (element.key === 'addressPhysician') { element.value = '5194 DAWES AVENUE ALEXANDRIA, VA 22311'}
      if (element.key === 'phoneNumbePhysician') { element.value = '703-820-1900'}
      if(element.key === 'tmsTherapyVisit'){         
        element.label = (this.paramsData.tmsType === 'Initial TMS Note') ? 'TMS THERAPY INITIAL VISIT' : 'TMS THERAPY FOLLOW UP VISIT'}
      if(element.key === 'tmsTherapyVisit') {
        if(this.restart===true){
          element.label ='Initial TMS Note'
        }
      }
      if (element.key === 'purposeOfVisit') { element.value = (this.paramsData.tmsType === 'Initial TMS Note')
        ? 
          'This is a ' + this.calculateAge(this.patientData.details.dob) +' -year-old '+ this.patientData.details.patientBasicInfo.gender.toLowerCase() +` who presents for an Initial TMS Therapy.` 
        : 
          'This is a ' + this.calculateAge(this.patientData.details.dob) +' -year-old '+ this.patientData.details.patientBasicInfo.gender.toLowerCase() +` who presents for a Follow Up TMS Therapy.`}
      if (element.key === 'tmsType') { element.value = this.paramsData.tmsType}
      if (element.key === 'tmsType') { 
        if(this.restart === true){
        element.value = 'Initial TMS Note'
      }
    }
     
      if (element.key === 'addObservations') { element.value = `Alert and oriented to person, place, time, and purpose. Ambulatory Appears In No Acute Distress. Mood: Normal, Affect – Congruent to mod. No EPS.`}
      if (element.key === 'TMSTherapyProcedure') { element.value = 
        // (this.paramsData.tmsType === 'Initial TMS Note') 
      // ? 
        "The Patient is seated. The patient's head was inspected to ensure that the patient had no metallic items in the patient's hair or the proximity of the Stimulation Coil." + '\n'+ "The motor strip was identified with noted movement of the middle finger and thumb of the Rt hand at 60% Power." + '\n' + "The patient tolerated TMS Therapy without incident. No adverse reactions. The coil was positioned well at the beginning of the therapy session.  No face/eyebrow twitching."
      // :
      //   "The Patient is seated. His head was inspected to ensure that he had no metallic items in his hair or in the proximity of the Stimulation Coil. \n The motor strip was identified with noted movement of the middle finger and thumb of the Rt hand at 60 Hz. No change to TMS Therapy. \n Pt tolerated TMS Therapy without incident. No adverse reactions." 
      }
      if (element.key === 'recommendations') { element.value = "TMS Therapy Management regimen. Patient is expected to get benefits.  Follow-up in the clinic daily for 6 weeks.Counseling services are recommended if needed to help adjust patient's anxious moments.Monitor progress using the Self Report (PHQ-9) over the course of treatment. Meds:Patient is advised to continue taking the prescribed meds."
        // "TMS Therapy Management regimen. Patient is expected to get benefits. Follow-up in the clinic daily for 6 weeks." + '\n' + "Counseling services are recommended if needed to help adjust patient's anxious moments."  + '\n' + "Monitor progress using the Self Report (PHQ-9) over the course of treatment." + '\n' + "Patient is advised to continue taking his/her meds. "
      }
      if (element.key === 'duration') { element.value = (this.paramsData.tmsType === 'Initial TMS Note') ? 55 : 45 }
      if (element.key === 'when') { element.value = '5 day for next 6 weeks'}
      if (element.key === 'Depression') { element.value = true}
      if (element.key === 'returnVisit') { element.value = 'Yes'}

      
      // if (element.key === '') { element.value = }
      // if (element.key === '') { element.value = }
    })
  }

  dropValue(event){
    console.log("event", event);
    let choosenValue = event.value
    const tmsNoteType: string[] = ["Initial TMS Therapy", "Follow Up TMS Therapy", "Follow Up TMS Note - Theta-Burst"];
    // on change of appointment type the following fields content will also change: duration, tms therapy visit header, and purpose of visit 
    if (choosenValue === 'Initial TMS Note' || choosenValue === 'Follow Up TMS Note'){
      this.form[0].formInputs.forEach((element: any) => {
        if(element.key === 'tmsTherapyVisit'){ 
          element.label = (choosenValue === 'Initial TMS Note') ? 'TMS THERAPY INITIAL VISIT' : 'TMS THERAPY FOLLOW UP VISIT'
          console.log(element.label,'element.labelelement.label')
        }
        if (element.key === 'duration') { element.value = (choosenValue === 'Initial TMS Note') ? 55 : 45}
        if (element.key === 'purposeOfVisit') { 
          if (choosenValue === 'Initial TMS Note') {
              // 'This is a ' + this.calculateAge(this.patientData.details.dob) +' -year-old '+ this.patientData.details.patientBasicInfo.gender.toLowerCase() +` who presents for an Initial TMS Therapy.` 
              for (let noteType of tmsNoteType) {
                const regex = new RegExp(noteType, 'i');
                if (regex.test(element.value)) {
                  element.value = element.value.replace(regex, 'Initial TMS Therapy');
                }
              }
          } else {
              // 'This is a ' + this.calculateAge(this.patientData.details.dob) +' -year-old '+ this.patientData.details.patientBasicInfo.gender.toLowerCase() +` who presents for a Follow Up TMS Therapy.`
              for (let noteType of tmsNoteType) {
                const regex = new RegExp(noteType, 'i');
                if (regex.test(element.value)) {
                  element.value = element.value.replace(regex, 'Follow Up TMS Therapy');
                }
              }
          }
        }
      })
    } else if (choosenValue === 'Follow Up TMS Note - Theta-burst'){
      this.form[0].formInputs.forEach((element: any) => {
        if (element.key === 'duration') { element.value = 3}
        if(element.key === 'tmsTherapyVisit'){ element.label = 'FOLLOW UP TMS NOTE - THETA-BURST' }
        if (element.key === 'purposeOfVisit') { 
          for (let noteType of tmsNoteType) {
            const regex = new RegExp(noteType, 'i');
            if (regex.test(element.value)) {
              element.value = element.value.replace(regex, 'Follow Up TMS Note - Theta-Burst');
            }
          }
          // element.value = 'This is a ' + this.calculateAge(this.patientData.details.dob) +' -year-old '+ this.patientData.details.patientBasicInfo.gender.toLowerCase() +` who presents for a Follow Up TMS Note - Theta-Burst.` 
        }
      })
    }
    
  }
  //on submitting the form this function get triggered
  getFormData(event) {
    console.log("getFormData function is triggered", event.value);
    let formData = event.value;
    let scoreHistory = [];
    let tmsnotecount
    let isRestart
  
    if(this.paramsData.OperationType === 'CreateNew'){
      tmsnotecount= parseInt(this.paramsData.previousTmsNoteNumber) + 1;
    }
    else{
      tmsnotecount = this.editNoteData.tmsnotecount
    }
    if(this.restart=== true){
      isRestart= 1;
      tmsnotecount=1;
    }
    else{
      isRestart=2
    }
    if (formData.hasOwnProperty('scoreHistoryTable')) {
      if(formData.showscorehistory=== true){
        formData.scoreHistoryTable=[];
        this.editNoteData.ScoryHistoryReport=[]
      }
     formData.scoreHistoryTable.map(obj => {
      
      if (obj.hasOwnProperty('undefined')) {
          delete obj.undefined;
      }
      console.log(obj.score.length,'obj.score.lenght')
      let scoreEvalur = JSON.stringify(obj.scoreEvaluationDate)
      if(obj.score.length > 0 && obj.evaluationName.length > 0 && scoreEvalur.length >4 ){
      if (obj.hasOwnProperty('evaluationName') && obj.hasOwnProperty('score') && obj.hasOwnProperty('scoreEvaluationDate')) {
        scoreHistory.push({ evaluationName: obj.evaluationName, score: obj.score, scoreEvaluationDate: formatDate((obj.scoreEvaluationDate), 'MM-dd-yyyy', 'en') }); // formatDate(new Date(obj.scoreEvaluationDate), 'yyyy-MM-dd', 'en')
      }
    }
      console.log("scoreHistoryeachtime", scoreHistory);

    }) } else{ scoreHistory = []} ;
    console.log("scoreHistory", scoreHistory, "formData.scoreHistoryTable", formData.scoreHistoryTable);
    console.log("if condition", (formData.hasOwnProperty('scoreHistoryTable')));
    
    
    let diagnosis: string = '';
    for(let key in formData){
      this.formDiagnosis.forEach(element => {
        if (formData.hasOwnProperty(key) && key === element ){
          if ( formData[key] === true ) { diagnosis = (diagnosis !== '') ?  diagnosis + '/ ' + element : element;}
        }
      })
    }
    console.log(formData.TMSTherapyProcedure,'formData.TMSTherapyProcedure');
    
    let TMSTherapyProcedureObject = formData.TMSTherapyProcedure.split('.')
    .map(sentence => sentence.trim())
    .filter(sentence => sentence.length > 0);;
    let RecommendationsObject = formData.recommendations.split('.')
    .map(sentence => sentence.trim())
    .filter(sentence => sentence.length > 0);;
    let tmsBegunHz;
    if(formData.tmsType === 'Initial TMS Note'){
      tmsBegunHz = formData.tmsBegunProtocol + 'Hz'
    }
    else {
      tmsBegunHz= formData.tmsBegunProtocol + '%' + ' protocal' 
    }
    console.log(formData.tmsBegunProtocol,'fsdfsd');

    console.log(TMSTherapyProcedureObject,'TherapyProcedure');
    console.log(RecommendationsObject,'Recommendations');
    this.paramsData.previousTmsNoteNumber === '0'
    let lastIndex = diagnosis.lastIndexOf(',');
    let formattedTMSData = {
      tmsnotecount:1,
      isRestart:isRestart,
      apptDate: formatDate((formData.AppointmnetDate), 'yyyy-MM-dd', 'en'),
      PatientName: formData.patientName,
      patientDob: formatDate((formData.dob), 'yyyy-MM-dd', 'en'),
      patientAge: formData.age,
      patientGender: formData.patientGender,
      ptID: formData.socialSecurityNumber, //formData.ptId,
      socialSecurityNumber: formData.socialSecurityNumber,
      primaryInsuranceName: formData.primaryHolderText,
      primaryInsuranceMemberId: formData.primaryInsuranceId,
      address: formData.Address,
      phoneNumber: formData.phoneNumber,
      knownAllergies: formData.knownAllergies,
      Diagnosis: diagnosis,
      AppointmentType: formData.tmsType,
      NPI : formData.npi,
      PhysicianName: formData.PhysicianName,
      PhysicianAddress: formData.addressPhysician,
      PhysicianPhoneNumber: formData.phoneNumbePhysician,
      purposeOfVisit: formData.purposeOfVisit,
      Observations: {
        height:formData.heightFt !== '' || formData.heightInch !== ''
          ? (formData.heightFt ? formData.heightFt : '0') +
            `'` +
            (formData.heightInch ? formData.heightInch : '0')
          : '',
        weight: formData.weight,
        BP: formData.bloodPressure,
        Pulse: formData.pulse,
      },
      addObservations: formData.addObservations,
      TMSTherapyProcedure: formData.TMSTherapyProcedure,
      TMSTherapyProcedureObject: TMSTherapyProcedureObject,
      patientMeasurements: {
        bridgeToMask: formData.bridgeMask,
        bridgeToInoin: formData.bridgeInoin,
        mid: (formData.bridgeInoin !== '') ? (parseInt(formData.bridgeInoin) / 2).toString() : '',
        earToEar: formData.earToear,
        cz:(formData.earToear !== '') ? (parseInt(formData.earToear) / 10).toString() : '',
        mapping: formData.mapping,
        mappingHertz: formData.mappingHertz,
        tmsBegun: formData.tmsBegun,
        tmsBegunProtocol: formData.tmsBegunProtocol, 
        tmsBegunHz: tmsBegunHz,
      },
      durationOfTherapy: formData.duration + ' Minutes',
      recommendations: formData.recommendations,
      RecommendationsObject: RecommendationsObject,
      returnVisit: formData.returnVisit,
      when: formData.when,
      ScoryHistoryReport: scoreHistory,
      patientMeasurementsMagstismFigure: {
        nasionToInoin: formData.bridgeInoin,
        tragusToTragus:formData.earToear,
        cz: (formData.bridgeInoin !== '') ? (parseInt(formData.bridgeInoin) / 2).toString() : '',
        cthree: (formData.earToear !== '') ? (parseInt(formData.earToear) / 10).toString() : '',
        mt: formData.mapping,
        mappingHertz: formData.mappingHertz,
        tmsBegun: formData.tmsBegun,
        tmsBegunProtocol: formData.tmsBegunProtocol,
        tmsProtocolat: formData.tmsProtocolat
      }
    }
    console.log("formattedTMSData", this.selectedButton, formattedTMSData);
    if(this.selectedButton === 'GeneratePDF'){
      this.addTMSNotes(formattedTMSData)
    } else if (this.selectedButton === 'Preview'){
      this.showPreview = true
      this.PreviewData = formattedTMSData
    }
  }

  addTMSNotes(formData){
    let patientId = this.paramsData.PatientID;
    let userId = this.patientData.details.userId;
    let data = formData
    let tmsNoteId = (this.paramsData.OperationType === 'EditExisting') ? this.paramsData.tmsNoteID : ''
    const Payload = {
      type: (this.paramsData.OperationType === 'CreateNew') ? 'POST' : 'PUT',
      url: (this.paramsData.OperationType === 'CreateNew') ? API_URLS.ADD_TMSNOTE : API_URLS.EDIT_TMSNOTE,
      isDeveloper: true,
      pathVariables:(this.paramsData.OperationType === 'CreateNew') ? [ userId, patientId] : [userId, patientId, tmsNoteId], 
      body: data,
    }
    console.log("payload", Payload);
    
    this.http.makeHttpRequest(Payload).subscribe(res => {
      if (this.http.isSuccessfulResponse(res)) {
        this.observable.showToast(
          TOAST_STATUSES.SUCCESS,
          (this.paramsData.OperationType === 'CreateNew') ? TOAST_MESSAGES.TMSNOTE_GENERATED : TOAST_MESSAGES.TMSNOTE_SAVED
        )
        this.back()
        return
      } else {
        let message = "Falied to generate patient's TMS note. Please try again"
        message = res.error ? res.error.message : message
        this.observable.showToast(TOAST_STATUSES.ERROR, message)
        return
      }
    })
  }
  massageScoreHistoryCardData(Data) {
    let scoreHistoryRows = []
    let listOFrows = Data
    let rowGroup = []
    console.log("listOFrows", listOFrows);
    
    for (let i = 0; i < listOFrows.length; i++) {
      let icon = {
        type: 'close-icon',
        showontoggle: true,
        showClose: true,
        editable: i === 0 ? false : true,
        closeIconText: 'Score History'
      }
      let evalDate = {
        type: 'calendar',
        placeholder: 'Enter evaluation date',
        label: 'Evaluation Date',
        required: false,
        key: 'scoreEvaluationDate',
        // isDob: false,
        labelImage: 'user',
        value: formatDate((listOFrows[i].scoreEvaluationDate), 'MM/dd/yyyy', 'en'),
        // isFutureDate: true,
        controlType: 'calendar',
        emptyValueMessage: 'Please select a valid date',
        validationValue: 'Please select valid date',
        validators: [],
        editable: true,
        focus: true,
        showontoggle: true,
        className: 'side__by__side',
      }
      let evalName = {
        type: 'text',
        placeholder: 'Please enter of evaluation name',
        label: 'Name Of Evaluation',
        required: false,
        key: `evaluationName`,
        value: listOFrows[i].evaluationName,
        controlType: 'inputText',
        editable: true,
        className: 'side__by__side placeholder_right',
        emptyValueMessage: 'Please enter Evaluation name',
        validationValue: 'Please enter valid Evaluation name',
        validators: [],
        focus: true,
        showontoggle: true,
      }
      let score = {
        type: 'text',
        placeholder: 'Score',
        label: 'Score',
        required: false,
        key: `score`,
        value: listOFrows[i].score,
        controlType: 'inputText',
        emptyValueMessage: 'Please enter score',
        validationValue: 'Please enter valid Score',
        validators: [],
        className: 'side__by__side placeholder_right',
        editable: true,
        showontoggle: true,
      }
      rowGroup = [icon, evalDate, evalName, score]

      scoreHistoryRows = [...scoreHistoryRows, rowGroup]
    }

    return scoreHistoryRows
  }

  navigateBackToForm(){
    this.showPreview = false
  }
  navigateBack(event) { 
    this.form[0].formInputs.forEach(ele => ele.value = '');
    this.cd.detectChanges()
    let scoreHistoryIndex = this.formService.tmsNoteFormConfig.findIndex( obj => obj['key'] === 'scoreHistoryTable' )
    this.formService.tmsNoteFormConfig[scoreHistoryIndex-1].value=false 
    this.formService.tmsNoteFormConfig[scoreHistoryIndex].showontoggle=true
    this.formService.tmsNoteFormConfig[scoreHistoryIndex+1].showontoggle=true
    this.formService.tmsNoteFormConfig[scoreHistoryIndex].formInputs = [[
      {
        type: 'close-icon',
        showontoggle: true,
        showClose: true,
        editable: false,
        closeIconText: 'Score History'
      },
      {
        type: 'calendar',
        placeholder: 'Enter evaluation date',
        label: 'Evaluation Date',
        required: false,
        key: 'scoreEvaluationDate',
        isDob: false,
        labelImage: 'user',
        value: '',
        isFutureDate: true,
        controlType: 'calendar',
        emptyValueMessage: 'Please select a valid date',
        validationValue: 'Please select valid date',
        validators: [],
        editable: true,
        focus: true,
        showontoggle: true,
        className: 'side__by__side',
      },
      {
        type: 'text',
        placeholder: 'Enter of evaluation name',
        label: 'Name of Evaluation',
        required: false,
        key: 'evaluationName',
        value: 'PHQ-9',
        controlType: 'inputText',
        editable: true,
        className: 'side__by__side placeholder_right',
        emptyValueMessage: 'Please enter Evaluation name',
        validationValue: 'Please enter valid Evaluation name',
        validators: [],
        focus: true,
        showontoggle: true,
      },
      {
        type: 'text',
        placeholder: 'Enter score',
        label: 'Score',
        required: false,
        key: 'score',
        value: '',
        controlType: 'inputText',
        emptyValueMessage: 'Please enter score',
        validationValue: 'Please enter valid Score',
        validators: [],
        className: 'side__by__side placeholder_right',
        editable: true,
        showontoggle: true,
      }]
    ];
    if (event) {
      this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.PATIENTS}`])
    }
  }

  resetSaveFormParity() {
    window.setTimeout(() => {
      this.saveFormData = false
    }, 0)
    return true
  }

  back() {
    this.form[0].formInputs.forEach(ele => ele.value = '');
    // (this.formService.tmsNoteFormConfig);
    let scoreHistoryIndex = this.formService.tmsNoteFormConfig.findIndex( obj => obj['key'] === 'scoreHistoryTable' )
    this.formService.tmsNoteFormConfig[scoreHistoryIndex-1].value=false 
    this.formService.tmsNoteFormConfig[scoreHistoryIndex].showontoggle=true
    this.formService.tmsNoteFormConfig[scoreHistoryIndex+1].showontoggle=true
    this.formService.tmsNoteFormConfig[scoreHistoryIndex].formInputs = [[
      {
        type: 'close-icon',
        showontoggle: true,
        showClose: true,
        editable: false,
        closeIconText: 'Score History'
      },
      {
        type: 'calendar',
        placeholder: 'Enter evaluation date',
        label: 'Evaluation Date',
        required: false,
        key: 'scoreEvaluationDate',
        isDob: false,
        labelImage: 'user',
        value: '',
        isFutureDate: true,
        controlType: 'calendar',
        emptyValueMessage: 'Please select a valid date',
        validationValue: 'Please select valid date',
        validators: [],
        editable: true,
        focus: true,
        showontoggle: true,
        className: 'side__by__side',
      },
      {
        type: 'text',
        placeholder: 'Enter of evaluation name',
        label: 'Name of Evaluation',
        required: false,
        key: 'evaluationName',
        value: 'PHQ-9',
        controlType: 'inputText',
        editable: true,
        className: 'side__by__side placeholder_right',
        emptyValueMessage: 'Please enter Evaluation name',
        validationValue: 'Please enter valid Evaluation name',
        validators: [],
        focus: true,
        showontoggle: true,
      },
      {
        type: 'text',
        placeholder: 'Enter score',
        label: 'Score',
        required: false,
        key: 'score',
        value: '',
        controlType: 'inputText',
        emptyValueMessage: 'Please enter score',
        validationValue: 'Please enter valid Score',
        validators: [],
        className: 'side__by__side placeholder_right',
        editable: true,
        showontoggle: true,
      }]
    ];
    this.cd.detectChanges()
   
    this.router.navigate([ROUTE_PATHS.ADMIN, ROUTE_PATHS.PATIENTS])
  }

  previewForm() {
    this.selectedButton = 'Preview'
    this.saveFormData = true
    this.getInvalidFormElements()
  }

  saveForm() {
    this.selectedButton = 'GeneratePDF'
    this.saveFormData = true
    this.getInvalidFormElements()
  }

  proceedNote(){
    this.showScorePopup = false;
    this.getAllTmsNotes()
    //this.getPatientDetails()
  }

  startAgain(){
    this.showScorePopup = false
    // this.form[0].formGroup.reset()
    // this.cd.detectChanges()
    this.restart = true;
    this.getPatientDetails()
    //this.ngOnInit()
  }

  showScorePopupStatusFun(event){
    this.showScorePopup = event.cancelpopup
    console.log("showScorePopup", this.showScorePopup);
    
  }
  getInvalidFormElements() {
    let invalidFormElementArray = document.querySelectorAll('.invalid-feedback')
    if (invalidFormElementArray.length !== 0) {
      invalidFormElementArray[0].scrollIntoView({ block: 'center' })
    }
  }
  
  calculateAge(dob: any): any {
    const DOB = new Date(dob);
    const currentDate = new Date();
  
    let age = currentDate.getFullYear() - DOB.getFullYear();
    const currentMonth = currentDate.getMonth();
    const birthMonth = DOB.getMonth();
  
    if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDate.getDate() < DOB.getDate())) {
      age--;
    }
  
    return age;
  }
  
}