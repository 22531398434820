import { Component, OnInit, AfterViewInit, NgZone } from '@angular/core';
import { HttpService } from '@app/common/services/http.service';
import { type, isArray } from 'jquery';
import { container } from 'aws-amplify';
import { isatty } from 'tty';
import { Router } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { convertDateToSpecifiedOffset, DEFAULT_TIME_ZONE_DIFF, TOAST_STATUSES } from '@app/common/constants/util.constant';

@Component({
  selector: 'app-consultation',
  templateUrl: './consultation.component.html',
  styleUrls: ['./consultation.component.scss']
})
export class ConsultationComponent implements OnInit, AfterViewInit {

  headingData = {
    subHeading: 'NEED A CONSULTATION?',
    mainHeading: 'Here are all the open slots for today',
  };

  moreText = {
    moreTextLink: 'View more'
  };
  tabIndex = 0;
  headerNavItems = [
    {
      label: 'Alexandria Office',
      id: 'ALX',
      selected: true,
      content: [
        {
          treatmentType: 'Medication Management',
          slots: []
        },
        {
          treatmentType: 'TMS',
          slots: []
        },
        {
          treatmentType: 'Ketamine Treatment',
          slots: []
        }
      ]
    },
    {
      label: 'Tyson Corner Office',
      id: 'TYC',
      selected: false,
      content: [
        {
          treatmentType: 'Medication Management',
          slots: []
        }
      ]
    },
    {
      label: 'Washington DC Office',
      id: 'WDC',
      selected: false,
      content: [
        {
          treatmentType: 'Medication Management',
          slots: []
        },
        {
          treatmentType: 'TMS',
          slots: []
        },
        {
          treatmentType: 'Ketamine Treatment',
          slots: []
        }
      ]
    },
    {
      label: 'Great Falls Office',
      id: 'GFS',
      selected: false,
      content: [
        {
          treatmentType: 'Medication Management',
          slots: []
        }
      ]
    },
    {
      label: 'Telemedicine',
      id: 'TEL',
      selected: false,
      content: [
        {
          treatmentType: 'Medication Management',
          slots: []
        },
        {
          treatmentType: 'Psychological Therapy',
          slots: []
        }
      ]
    }
  ];

  slideConfig = {
    slidesToShow: 7,
    slidesToScroll: 7,
    dots: true,
    infinited: true,
    autoplay: true,
    autoplaySpeed: 1500,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      }
    ]
  };
  availabiltyData: any = [];
  locaionId: any = 'ALX';
  appointmentTypeIds = [1, 2, 3, 4];
  metaData: any;
  isShow: boolean = false;
  isLocationDataLoaded: boolean = false;
  timeStamp: any;

  constructor(private http: HttpService, private router: Router, private observable: ObservableHelperService, private ngZone: NgZone) { }

  ngOnInit() {
    // this.getLocationsData();
  }


  getMultipleAppointmentData() {
      this.appointmentTypeIds.forEach(eachappointment => {
        this.getAvailabiltySlots(eachappointment['appointmentTypeId']);
      });
 
  }

  /**
   * This function is called after the view is initialized.
   * 
   * Sets up an IntersectionObserver to trigger fetching location data when '.consultation__section' enters the viewport.
   * 
   * Ensures the function `getLocationsData()` is only called when the section becomes visible.
   */

  ngAfterViewInit() {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !this.isLocationDataLoaded) {
        this.getLocationsData();
        this.isLocationDataLoaded = true;
      }
    });

    const target = document.querySelector('.consultation__section');
    if (target) {
      observer.observe(target);
    }

    const slider = document.querySelector('.ui-tabview-nav') as HTMLElement;
    let isDown = false;
    let startX;
    let scrollLeft;
  
    // Attaching mouse and touch events for slider interaction
    ['mousedown', 'touchstart'].forEach((event) => {
      slider.addEventListener(event, (e: any) => {
        isDown = true;
        let pageXval = e.pageX ? e.pageX : e.touches[0].pageX;
        startX = pageXval - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      });
    });
  
    ['mouseleave', 'mouseup', 'touchend'].forEach((event) => {
      slider.addEventListener(event, () => {
        isDown = false;
      });
    });
  
    ['mousemove', 'touchmove'].forEach((event) => {
      slider.addEventListener(event, (e: any) => {
        if (!isDown) { return; }
        e.preventDefault();
        let pageXval = e.pageX ? e.pageX : e.touches[0].pageX;
        const x = pageXval - slider.offsetLeft;
        const walk = (x - startX) * 3; // scroll-fast
        slider.scrollLeft = scrollLeft - walk;
        console.log(walk);
      });
    });
  }
  

  /**
* Api call to get shedularData
*/
  getLocationsData() {
    let params = {
      metadata: true
    };
    console.log(params);
    let payload = {
      type: 'GET',
      url: 'availability',
      isDeveloper: true,
      params,
      isAuthToken: true
      // body: data
    };
    this.isShow = false;
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res) && res) {
        console.log(res.data, 'avalabityData');
        this.metaData = res.data.metadata;
        this.headerNavItems = this.massageLocationsData(res.data.metadata.locations);
        let appointmentTypeIds = [];
        this.availabiltyData = [];
        this.locaionId = this.headerNavItems[0].id;
        this.metaData.appointmentTypes.forEach(appointmentType => {
        if (appointmentType.locations.includes(this.locaionId)) {
          appointmentTypeIds.push(appointmentType);
        }
      });
        this.appointmentTypeIds = appointmentTypeIds;
        this.getMultipleAppointmentData();
        this.isShow = true;
      } else {
        let message = 'Failed to fetch availability data. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
  massageLocationsData(locations) {
    if (!locations && !Array.isArray(location)) { return []; }
    return locations.map((loc, index) => {
      return {
        ...loc,
        id: loc.locationId,
        label: loc.locationName,
        selected: (index === 0) ? true : false,
        content: []
      };
    });
  }
  /**
 * Api call to get shedularData
 */
  getAvailabiltySlots(typeId) {
    let params = {
      appointmentTypeId: typeId,
      locationId: this.locaionId,
      beginTime: (typeId !== 4) ? '06:30:00' : '07:00:00',
      endTime: '23:30:00',
      // startDate: this.formatDate(),
      days: 1,
      metadata: true
    };
    console.log(params);
    let payload = {
      type: 'GET',
      url: 'availability',
      isDeveloper: true,
      params,
      isAuthToken: true
      // body: data
    };
    this.isShow = false;
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res) && res) {
        console.log(res.data, 'avalabityData');
        this.timeStamp = res.data.timestamp;
        this.availabiltyData.push(res.data.availability);
        this.getUnique(this.availabiltyData, 'appointmentTypeId');
        this.metaData = res.data.metadata;
        this.massageLocationData(this.availabiltyData);
        this.massageTypeData();
        let currentDate = new Date(convertDateToSpecifiedOffset(DEFAULT_TIME_ZONE_DIFF, this.timeStamp));
        currentDate = new Date(currentDate.setHours(currentDate.getHours() + 1));
        this.headerNavItems.forEach(each => {
          each.content.forEach(item => {
            item['calendar'] = (item.slots[0]) ? item.slots[0]['calendar'][0].slots.filter(slot => (slot.available === true && (new Date().setHours(slot.time.split(':')[0], slot.time.split(':')[1], slot.time.split(':')[2], 0) > currentDate.valueOf()))) : [];
            item['date'] = (item.slots[0]) ? item.slots[0]['calendar'][0].date : '';
          });
        });
        this.sortAppointmentType();
        console.log(this.availabiltyData);
        this.isShow = true;
      } else {
        let message = 'Failed to fetch availability data. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  // this function will sort the ketamine treatment as last appointmentType
  sortAppointmentType() {
    this.headerNavItems = this.headerNavItems.map(each => {
      return {
        ...each,
        content: each.content.sort((a, b) => {
          let aData = a['appointmentTypeId'];
          let bData = b['appointmentTypeId'];
          if (bData !== 3) {
            return 11;
          } else {
            return -1;
          }
        })
      };
    });
  }
  tmsSlotsModifications() {
    this.headerNavItems.forEach(each => {
      each.content.forEach(item => {
        if (item['appointmentTypeId'] === 4) {
          item['calendar'] = item['calendar'].map(slot => {
            return {
              ...slot,
              time: (slot.time >= '12:00:00') ? this.tenMinutesLess(slot.time) : slot.time
            };
          });
        }
      });
    });
  }
  tenMinutesLess(time) {
    if (time) {
      var d = new Date('2020-09-10' + 'T' + time);
      d = new Date(d.getTime() - 10 * 60000);
      let hh = (d.getHours() < 12) ? `0${d.getHours()}` : d.getHours();
      let mm = (d.getMinutes() < 10) ? `0${d.getMinutes()}` : d.getMinutes();
      let sec = (d.getSeconds() < 10) ? `0${d.getSeconds()}` : d.getSeconds();
      console.log(`${hh}:${mm}:${sec}`);
      return `${hh}:${mm}:${sec}`;
    }
  }

  /**
* This functionality for removing duplicates from an array
* @param arr :array of objects
* @param comp :string
 */
  // this.getUnique(discoveryList,"status")
  getUnique(arr, comp) {
    // store the comparison  values in array
    var unique = arr.map(e => e[comp])
      // store the indexes of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the false indexes & return unique objects
      .filter((e) => arr[e]).map(e => arr[e]);
    return unique;
  }
  formatDate() {
    var d = new Date();
    var month = '' + (d.getMonth() + 1);
    var day = '' + d.getDate();
    var year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }
  checkSlots(tab) {
    if (tab) {
      return tab.content.every(eachType => {
        if (eachType.calendar && eachType.calendar.length === 0) {
          return true;
        }
      });
    }
  }
  naviagateLoginPage() {
    this.router.navigate([`${ROUTE_PATHS.ONBOARD}`]);
  }
  massageLocationData(data) {
    if (data && typeof (data) === 'object') {
      this.headerNavItems.forEach(each => {
        each.content = this.metaData.appointmentTypes.filter(apptType => apptType.locations.includes(each.id));
      });
      console.log(this.headerNavItems);
    }
  }

  massageTypeData() {
    this.headerNavItems.map(each => {
      each['content'] = this.extractSlots(each.content);
    });
  }
  extractSlots(content) {
    if (!content && Array.isArray(content)) { return []; }
    return content.map(appType => {
      return {
        ...appType,
        locationId: this.locaionId,
        slots: this.availabiltyData.filter(typeApp => typeApp.appointmentTypeId === appType.appointmentTypeId)
      };
    });
  }

  handleChange(event) {
    if (event) {
      this.tabIndex = event.index;
      let appointmentTypeIds = [];
      this.availabiltyData = [];
      this.locaionId = this.headerNavItems[event.index].id;
      this.metaData.appointmentTypes.forEach(appointmentType => {
        if (appointmentType.locations.includes(this.locaionId)) {
          appointmentTypeIds.push(appointmentType);
        }
      });
      this.appointmentTypeIds = appointmentTypeIds;
      this.getMultipleAppointmentData();
      console.log(this.headerNavItems);
    }

  }

}
