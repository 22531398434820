import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';

@Component({
  selector: 'app-treatments-wrapper',
  templateUrl: './treatments-wrapper.component.html',
  styleUrls: ['./treatments-wrapper.component.scss']
})
export class TreatmentsWrapperComponent implements OnInit {

  bannerData = {
    bannerClass: 'treatments__banner-section',
    orangeHeading: 'Our treatments',
    blueHeading1: 'are effective for',
    blueHeading2: 'a wide variety',
    blueHeading3: 'of conditions',
    bannerDescription: 'At New Tele Doc we always strive to bring our patients the best possible treatments that will help them to deal with their conditions & bring a positive change. Our treatments are highly advanced, evidence-based & patient-centric.',
    bannerImg: 'jpgs/treatments-banner.jpg'
  };

  constructor(private observable: ObservableHelperService, private meta: Meta, private titleService: Title,private router: Router) {

  }

  ngOnInit() {
    const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.setAttribute('href', 'https://newteledoc.com/treatments');
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          canonicalLink.setAttribute('href', 'https://newteledoc.com/treatments');
        }
      });
    }
    this.meta.addTag({ name: 'description', content: "Customers can learn about treatments that are available to aid in the rehabilitation of patients’ well-being, including Testosterone Treatment DC, ED Treatment DC, ADHD Treatment DC, Weight Loss Reduction DC, Weight Management, Anti-Aging Treatment DC,Medical Marijuana Virginia, Ketamine Treatment DC, Testosterone Injectable DC, etc."});
    this.meta.updateTag({ name: 'title', content: 'Patient FAQ - New TeleDoc Washington DC Psychiatrist' });
    this.titleService.setTitle('Patient FAQ - New TeleDoc Washington DC Psychiatrist');
    this.observable.setCurrentPage('treatments');
  }

}
