<section class="appointments__details--wrapper" *ngIf="appointmentDetails">
    <!--Right side background leaf starts here -->
    <div class="background-image">
        <img src="assets/images/pngs/newpatientform-background.png" alt="bg image">
    </div>
    <!-- Right side background leaf ends here -->

    <!-- Main section starts here -->
    <div class="appointments__details--main-wrapper content__wrapper">
        <!-- Breadcrumb section starts here -->
        <div class="appointment__list-navigation-menu">
            <app-breadcrumb [breadCrumbData]="breadCrumbData"></app-breadcrumb>
        </div>
        <!-- Breadcrumb section ends here -->
        <div class="content__block--wrapper">
            <!-- Left side details starts here -->
            <div class="left__side--wrapper">
                <div class="patient__name--block">
                    <div class="patient__name">{{appointmentDetails.patientBasicInfo.firstName | titlecase}}
                        {{appointmentDetails.patientBasicInfo.lastName | titlecase}}</div>
                    <div class="appointment__status" [ngClass]="appointmentDetails.appointmentInfo.status | lowercase ">
                        {{appointmentDetails.appointmentInfo.status | titlecase}} {{addTMSClass ? ' (TMS Forms Pending)': pendingTMS ? ' (TMS Forms Pending Approval)':''}}</div>
                </div>
                <!-- Appointment details starts here -->
                <div class="patient__details--block">
                    <div class="each__block">
                        <div class="details__lable">
                            Patient ID
                        </div>
                        <div class="details__value">
                            {{appointmentDetails.appointmentInfo.patientId}}
                        </div>
                    </div>
                    <div class="each__block">
                        <div class="details__lable">
                            Location
                        </div>
                        <div class="details__value">
                            {{appointmentDetails.appointmentInfo.location | titlecase}}
                        </div>
                    </div>
                    <div class="each__block">
                        <div class="details__lable">
                            Treatment
                        </div>
                        <div class="details__value">
                            {{appointmentDetails.appointmentInfo.treatment | titlecase}}
                        </div>
                    </div>
                    <div class="each__block">
                        <div class="details__lable">
                            Date
                        </div>
                        <div class="details__value">
                            {{appointmentDetails.appointmentInfo.appointmentDate | dateFormat}}
                        </div>
                    </div>
                    <div class="each__block">
                        <div class="details__lable">
                            Time Slot
                        </div>
                        <div class="details__value">
                            {{appointmentDetails.appointmentInfo.timeSlotFrom | customTime}} to
                            {{appointmentDetails.appointmentInfo.timeSlotTo | customTime}}
                        </div>
                    </div>
                </div>
                <!-- Appointment details ends here -->
                <!-- TMS Pending message -->
                <div class="warning--block pendging-svg tms" *ngIf="addTMSClass">
                    <app-svg [name]="'pending-alert'"></app-svg>
                    <span>
                        <span class="warning">Pending!&nbsp;</span>TMS Forms were pending for this patient. To fill please&nbsp;<span class="tms--click" (click)="navigateTmsForms(appointmentDetails.patientId)">click here</span> 
                    </span>
                </div>
                <!-- TMS Pending confirmation  -->
                <div class="warning--block pendging-svg tms" *ngIf="pendingTMS">
                    <app-svg [name]="'pending-alert'" class="pending-svg"></app-svg>
                    <span>
                        <span class="warning">Pending!&nbsp;</span>Patient approval required</span>
                    <span class="tms-svgs">
                        <app-svg [name]="'edit'" title="Edit" class="tms-svg edit" (click)="tmsClick(appointmentDetails.patientId, 'edit')"></app-svg>
                        <app-svg [name]="'review'" title="Review" class="tms-svg review" (click)="tmsClick(appointmentDetails.patientId, 'review')"></app-svg>
                        <app-svg [name]="'mail'" title="Resend to Patient" class="tms-svg" (click)="tmsClick(appointmentDetails.patientId, 'mail')"></app-svg>
                    </span>
                </div>
                <!-- TMS Pending message ends -->

                <!-- Doctors block starts here -->
                <div class="doctors__block">
                    <div class="side__lable">Doctor</div>
                    <div class="cards__block">
                        <div class="card__boxes">
                            <app-profile-box [cardData]="appointmentDetails.appointmentInfo.doctor"></app-profile-box>
                        </div>
                    </div>
                </div>
                <!-- Doctors block ends here -->
                 <!-- Update Ketamine Monitoring form starts here -->
                 <div class="monitoring__form" *ngIf="appointmentDetails.appointmentInfo.appointmenttypeId===3 && appointmentDetails.appointmentInfo.status != 'Mark as absent' && appointmentDetails.appointmentInfo.status != 'Cancelled' && ketamineFormSubmit !== 'noShow'">
                    <div class="side__lable">Patient Monitoring Form</div>
                    <div class="monitoring__action" (click)="openKetamineMonitoringForms()" *ngIf="ketamineFormSubmit == 'notFilled'">
                        <app-svg [name]="'edit'"></app-svg>
                        <div class="update__form">
                            Update Form
                        </div>
                    </div>
                    <div class="monitoring__download" *ngIf="ketamineFormSubmit == 'filled'">
                        <div class="appointments__id-document internal-uploads">
                                <div class="upload-document__block">
                                    <p>{{ketamineMonitoringFormFile.file.name | ellipsis: 23}}</p>
                                    <p>.{{ketamineMonitoringFormFile.file.extenstion}}</p>
                                </div>
                                <div class="appointments__id-document-actions">
                                    <a [href]="ketamineMonitoringFormFile.docUrl" target="_blank" title="View" class="view-icon">
                                        <app-svg [name]="'view-upload'"></app-svg>
                                    </a>
                                    <p class="down-arrow" (click)="donwloadFile(ketamineMonitoringFormFile.docUrl,ketamineMonitoringFormFile,false)"
                                        title="Download">
                                        <app-svg [name]="'down-arrow'"></app-svg>
                                    </p>
                                </div>
                            </div>
                    </div>
                </div>
                <!-- Update Ketamine Monitoring form ends here -->
                <!-- Documents block starts here -->
                <div class="documents__wrapper" id="reports">
                    <div class="appointments__id-proofs">
                        <div class="appointments__id-proofs-title side__lable ">
                            Reports
                        </div>
                        <div class="appointments__id-proofs-documents">
                            <div class="appointments__id-document internal-uploads"
                                *ngFor="let uploadsFiles of reportsDocFiles; let i=index">
                                <div class="upload-document__block">
                                    <p>{{uploadsFiles.file.name | ellipsis: 23}}</p>
                                    <p>.{{uploadsFiles.file.extenstion}}</p>
                                </div>
                                <div class="upload-close-btn">
                                    <app-svg [name]="'cancel-appointment'"
                                        (click)="deleteFileUpload(uploadsFiles, 'appointment_report')"></app-svg>
                                </div>
                                <div class="appointments__id-document-actions">
                                    <a [href]="uploadsFiles.docUrl" target="_blank" title="View" class="view-icon">
                                        <app-svg [name]="'view-upload'"></app-svg>
                                    </a>
                                    <p class="down-arrow" (click)="donwloadFile(uploadsFiles.docUrl,uploadsFiles,false)"
                                        title="Download">
                                        <app-svg [name]="'down-arrow'"></app-svg>
                                    </p>
                                </div>
                            </div>
                            <div class="appointments__id-document" [ngClass]="adminPermissions.APPOINTMENT_EDIT ? '':'disable'">
                                <app-multiple-uploads [fileTypes]="reportsDocFilesData"
                                    (uploads)="uploadReportsDoc($event)"></app-multiple-uploads>
                            </div>
                        </div>
                    </div>
                    <!-- patient permissions -->
                </div>
                <!-- Document block ends here -->
                <!-- Documents block starts here -->
                <div class="documents__wrapper" id="documentsList">
                    <div class="appointments__id-proofs">
                        <div class="appointments__id-proofs-title side__lable">
                            Documents
                        </div>
                        <div class="appointments__id-proofs-documents">
                            <div class="appointments__id-document"
                                *ngFor="let uploadsFiles of appointmentDetailsUploads; let i=index">
                                <div class="upload-document__block">
                                    <p>{{uploadsFiles.name | ellipsis: 23}}</p>
                                    <p>.{{uploadsFiles.extenstion}}</p>
                                </div>
                                <div class="appointments__id-document-actions">
                                    <a [href]="uploadsFiles.file" target="_blank" title="View" class="view-icon">
                                        <app-svg [name]="'view-upload'"></app-svg>
                                    </a>
                                    <p class="down-arrow" (click)="donwloadFile(uploadsFiles.file, uploadsFiles, true)"
                                        title="Download">
                                        <app-svg [name]="'down-arrow'"></app-svg>
                                    </p>
                                </div>
                            </div>
                            <div class="appointments__id-document internal-uploads"
                                *ngFor="let uploadsFiles of detailPageUpload; let i=index">
                                <div class="upload-document__block">
                                    <p>{{uploadsFiles.file.name | ellipsis: 23}}</p>
                                    <p>.{{uploadsFiles.file.extenstion}}</p>
                                </div>
                                <div class="upload-close-btn">
                                    <app-svg [name]="'cancel-appointment'"
                                        (click)="deleteFileUpload(uploadsFiles, 'appointment_doc')"></app-svg>
                                </div>
                                <div class="appointments__id-document-actions">
                                    <a [href]="uploadsFiles.docUrl" target="_blank" title="View" class="view-icon">
                                        <app-svg [name]="'view-upload'"></app-svg>
                                    </a>
                                    <p class="down-arrow" (click)="donwloadFile(uploadsFiles.docUrl,uploadsFiles,false)"
                                        title="Download">
                                        <app-svg [name]="'down-arrow'"></app-svg>
                                    </p>
                                </div>
                            </div>
                            <div class="appointments__id-document" [ngClass]="adminPermissions.APPOINTMENT_EDIT ? '':'disable'">
                                <app-multiple-uploads [fileTypes]="uploadFilesData" (uploads)="uploadDoc($event)">
                                </app-multiple-uploads>
                            </div>
                        </div>
                    </div>
                    <!-- patient permissions -->
                </div>
                <!-- Document block ends here -->

                <!-- Appointment actions block starts here -->
                <div class="actions__block">
                    <div class="each__action" (click)="appointmentsStatusActions(action)"
                        *ngFor="let action of showActions;let i=index">
                        <app-svg class="{{action.colorIconName}}" [name]="action.colorIconName"></app-svg>
                        <div class="actions__lable">{{action.label}}</div>
                    </div>
                    <div class="more__option" (clickOutside)="act.hide()" (click)="act.show($event)"
                        *ngIf="moreActions.length > 0">
                        <app-svg class="mixedmore" [name]="'mixedmore'"></app-svg>
                        <div class="actions__lable">More</div>
                        <p-overlayPanel #act [showTransitionOptions]="'100ms'" [hideTransitionOptions]="'100ms'"
                            [appendTo]="'body'">
                            <div class="each__option" *ngFor="let option of moreActions">
                                <div class="line"></div>
                                <div class="more__options" (click)="appointmentsStatusActions(option)">
                                    {{option.label}}</div>
                            </div>
                        </p-overlayPanel>
                    </div>
                </div>
                <!-- Appointment actions block starts here -->
            </div>
            <!-- Left side details ends here -->

            <!-- Right side details starts here -->
            <div class="right__side--wrapper">
                <div class="right__side--heading">
                    Here’s the <br> {{appointmentDetails.patientBasicInfo.firstName}}
                    {{appointmentDetails.patientBasicInfo.lastName}} Contact Details</div>
                <div class="contact__details">
                    <app-svg class="adminMobile" [name]="'adminMobile'"></app-svg>
                    <div class="contact__details--lable">
                        {{appointmentDetails.patientInfo.phone}}</div>
                </div>
                <div class="contact__details">
                    <app-svg class="adminMail" [name]="'adminMail'"></app-svg>
                    <div class="contact__details--lable">
                        {{appointmentDetails.patientBasicInfo.email}}</div>
                </div>
                <div class="sub__lable point__of--contact">Point of Contact</div>
                <div class="contact__details">
                    <app-svg class="adminUser" [name]="'adminUser'"></app-svg>
                    <div class="contact__details--lable">
                        {{appointmentDetails.pointOfContact.firstName}} {{appointmentDetails.pointOfContact.lastName}}
                    </div>
                </div>
                <div class="contact__details">
                    <app-svg class="adminMail" [name]="'adminMail'"></app-svg>
                    <div class="contact__details--lable">
                        {{appointmentDetails.pointOfContact.mail}}</div>
                </div>
                <div class="hr__line"></div>
                <div class="sub__lable">Last Visit</div>
                <div class="lable__value"  *ngIf="getLastVist(appointmentDetails.patientInfo.lastVisit) ;else lastVistBlock">{{appointmentDetails.patientInfo.lastVisit.treatment}} |
                    {{appointmentDetails.patientInfo.lastVisit.location}} |
                    {{appointmentDetails.patientInfo.lastVisit.date | dateFormat}} -
                    {{appointmentDetails.patientInfo.lastVisit.time | customTime}}</div>
                    <ng-template #lastVistBlock>
                        <div class="lable__value">---</div>
                            
                    </ng-template>
                <div class="sub__lable">Pending Invoice</div>
                <div class="lable__value" *ngIf="appointmentDetails.invoiceList.length>0;else invoiceBlock">
                    <span>{{appointmentDetails.invoiceList[0].invoiceId}} | ${{appointmentDetails.invoiceList[0].copay +  appointmentDetails.invoiceList[0].pay  | number : '1.2-2' }}</span>
                    <span class="more__class" (clickOutside)="op.hide()" (click)="op.show($event)" *ngIf="appointmentDetails.invoiceList.length>1"> +{{appointmentDetails.invoiceList.length-1}} more</span>
                </div>
                <ng-template #invoiceBlock>
                    <div class="lable__value">
                        ---
                    </div>
                </ng-template>
                
                <p-overlayPanel #op  [showTransitionOptions]=" '100ms' " [hideTransitionOptions]=" '100ms' " [appendTo]=" 'body' ">
                    <ng-template pTemplate>
                        <div class="each__option" *ngFor="let eachInvoice of appointmentDetails.invoiceList| slice:1 ; let i=index ">
                            <div class="line"></div>
                            <div  class="more__options">{{eachInvoice.invoiceId}} | ${{eachInvoice.copay +  eachInvoice.pay | number : '1.2-2'}}</div>
                        </div>
                    </ng-template>
                </p-overlayPanel>
                <div class="sub__lable">Block Status</div>
                <div class="lable__value block__status"> 
                    <ng-container *ngIf="appointmentDetails.patientInfo.blockStatus.length >0 ;else elseBlock">
                        <span
                            *ngFor="let status of BlockedServices;let i = index">{{status.name}}
                            <span *ngIf="i!==appointmentDetails.patientInfo.blockStatus.length-1">| </span></span>
                    </ng-container>
                    
                </div>
                <div class="hr__line"></div>
                <div class="polcy__block">
                    <div class="each-polcy__block">
                        <div class="sub__lable">Policy Holder</div>
                        <div class="lable__value">
                            {{appointmentDetails.policyHolder.policyHolderName | titlecase | ellipsis:18}}</div>
                    </div>
                    <div class="each-polcy__block">
                        <div class="sub__lable">Date of Birth</div>
                        <div class="lable__value"
                            *ngIf="appointmentDetails.policyHolder.policyHolderDOB;else elseBlock">
                            {{appointmentDetails.policyHolder.policyHolderDOB | date:'MM-dd-yyyy'}}</div>
                    </div>
                    <div class="each-polcy__block">
                        <div class="sub__lable">Primary Insurance Health Provider</div>
                        <div class="lable__value" title="{{appointmentDetails.policyHolder.primaryInsuranceProvider}}">
                            {{appointmentDetails.policyHolder.primaryInsuranceProvider}}</div>
                    </div>
                    <div class="each-polcy__block">
                        <div class="sub__lable">Secondary Insurance Health Provider</div>
                        <div class="lable__value"
                            title="{{appointmentDetails.policyHolder.secondaryInsuranceProvider}}">
                            {{appointmentDetails.policyHolder.secondaryInsuranceProvider}}</div>
                    </div>
                    <div class="each-polcy__block">
                        <div class="sub__lable">Primary Insurance Member Id</div>
                        <div class="lable__value">
                            <span
                                title="{{appointmentDetails.policyHolder.policyHolderMemberId}}">{{appointmentDetails.policyHolder.policyHolderMemberId
                                | titlecase | ellipsis:18}}</span>
                        </div>
                    </div>
                    <div class="each-polcy__block">
                        <div class="sub__lable">Secondary Insurance Member Id</div>
                        <div class="lable__value"><span
                                title="{{appointmentDetails.policyHolder.secondaryPolicyHolderMemberId}}">{{appointmentDetails.policyHolder.secondaryPolicyHolderMemberId
                                | titlecase | ellipsis:18}}</span>
                        </div>
                    </div>
                    <div class="each-polcy__block">
                        <div class="sub__lable">Policy Number</div>
                        <div class="lable__value">
                            {{appointmentDetails.policyHolder.policyNumber | ellipsis:18}}</div>
                    </div>
                    <div class="each-polcy__block">
                        <div class="sub__lable">Group Number</div>
                        <div class="lable__value">
                            {{appointmentDetails.policyHolder.policyHolderGroupNumber | titlecase | ellipsis:18}}</div>
                    </div>
                    <div class="each-polcy__block">
                        <div class="sub__lable">Policy Holder Employer</div>
                        <div class="lable__value" title="appointmentDetails.policyHolder.policyHolderEmployer">
                            {{appointmentDetails.policyHolder.policyHolderEmployer | titlecase | ellipsis:18}}
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #elseBlock>
                <span class="empty__block">
                    ---
                </span>
            </ng-template>
            <!-- Right side details ends here -->
        </div>
    </div>
    <!-- Main section ends here -->
</section>

<app-common-popup (showHideCancelPopup)='blockPatientPopupShowHideStatusFun($event)' *ngIf="blockPatientPopupShowHide">
    <app-block-patient (submitEvent)="blockPatientSubmit($event);blockPatientPopupShowHide=false"
        [filterInputs]="forminputs" [filterData]="rawFilterData"></app-block-patient>
</app-common-popup>


<!-- sideBar -->
<app-common-popup (showHideCancelPopup)='generateInvoicePopupShowHideStatusFun($event)' *ngIf="generateInvoicePopupShowHide">
    <app-generate-invoice (totalInvoiceAmount)='generateInvoice($event);generateInvoicePopupShowHideStatusFun($event)'>
    </app-generate-invoice>
</app-common-popup>
<app-common-popup (showHideCancelPopup)='makeAsPaidPopupShowHideStatusFun($event)' *ngIf="makeAsPaidPopupShowHide">
    <app-make-as-paid (makeAsPaidData)="generateInvoice($event)" (hidePopUp)="makeAsPaidPopupShowHideStatusFun($event)"></app-make-as-paid>
</app-common-popup>

<!-- Cancel the appointment -->
<app-cancel-booking [isAdmin]="isAdmin" [cancelData]="cancelAppointmentData" (showHideCancelPopup)='cancelPopupShowHideStatusFun($event)'
    *ngIf="cancelPopupShowHide"></app-cancel-booking>

<section class="paymentPage" >
    <form method="POST"  ngNoForm [action]="paymentRedirectUrl" id="formAuthorizeNetPage" name="formAuthorizeNetTestPage">
        <input type="hidden" id = "paymentvalue" name="token" [value]="paymentToken" />
        <!-- Continue to Authorize.net to Payment Page:  -->
        <button id="btnContinue">Click here to make payment</button>
    </form> 
</section>