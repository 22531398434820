<!-- patient bank  details -->
<div class="patient__module-details">
    <div class="patient__advanced-content">
        <div class="patient__advanced-content-title">
            <p class="title-text">Last Visit</p>
            <div class="patient__advanced-content-data" *ngIf="lastVisitData.lastVisit">
                <div class="treatment__loaction">
                    <span>{{lastVisitData.lastVisit.treatment | titlecase}} </span> <span *ngIf="lastVisitData.lastVisit.treatment">&nbsp;|&nbsp;</span>
                    <span>{{lastVisitData.lastVisit.location | titlecase}} </span> <span *ngIf="lastVisitData.lastVisit.location">&nbsp;|&nbsp;</span>
                    <span>{{lastVisitData.lastVisit.date | dateFormat }} </span> <span *ngIf="lastVisitData.lastVisit.date">&nbsp;-&nbsp;</span>
                    <span>{{lastVisitData.lastVisit.time | customeTimeAmPm}} </span>
                </div>
            
            </div>
            <div class="patient__advanced-content-data" *ngIf="!lastVisitData.lastVisit">
                <div class="patient__advanced-content-data">
                    ---
                </div>
            </div>
        </div>
    </div>
    <div class="patient__addvanced-data">
        <div class="patient__advanced-content pastDue">
            <div class="patient__advanced-content-title">
                <p class="title-text">Past Due</p>
                <div class="patient__advanced-content-data" *ngIf="lastVisitData.details.pastDue;else amoutBlock">
                    $ {{lastVisitData.details.pastDue}}
                </div>
                <ng-template #amoutBlock>
                    <div class="patient__advanced-content-data">
                        ---
                    </div>
                </ng-template>
                
            </div>
        </div>
        <div class="patient__advanced-content block-status">
            <div class="patient__advanced-content-title">
                <p class="title-text">Block Status</p>
                <div class="patient__advanced-content-data" *ngIf="lastVisitData.details.blockStatus.length !== 0">
                    <ng-container *ngFor="let blockedService of this.BlockedServices; let i=index">
                        <div class="patient__streetAddress">
                            <span>{{blockedService.name | titlecase}}</span> <span *ngIf="i !== lastVisitData.details.blockStatus.length-1">&nbsp;|&nbsp;</span>
                        </div>
                    </ng-container>
                </div>
                <div class="patient__advanced-content-data" *ngIf="lastVisitData.details.blockStatus.length === 0">
                    ---
                </div>
            </div>
        </div>
    </div>
</div>
<!-- patient id proof  details -->