<!-- common footer start -->
<footer class="common__footer full-width" [ngClass]="{'remove__margin-top': !footerShow}">
    <!-- footer container start -->
    <div class="footer__container restrict-width padding-lr">
        <!-- footer nave links start -->
        <div class="footer__nav-links" *ngIf="footerShow">
            <div class="nav__label" *ngFor="let item of headerNavItems;let i = index" [ngClass]="item.selected ? 'nav-selected' : ''" (click)="footerLinkClicked(i)">
                {{ item.label | uppercase }}
            </div>
        </div>
        <!-- footer nave links end -->
        <!-- copy right section start -->
        <div class="copy__right-section" [ngClass]="{'remove__padding-top': !footerShow}">
            <!-- copy right block start -->
            <div class="copy__right-block">
                <div class="copyright">Copyright 2021</div>
                <div class="all-rights">All rights reserved</div>
            </div>
            <!-- copy right block end -->
            <!-- design brand block start -->
            <div class="design-brand sr-only" *ngIf="footerShow">
                <span>UX &amp; Designs by</span>
                <a href="http://www.divami.com/" rel="noopener noreferrer" target="_blank">
                    <img class="divami-logo" src="assets/images/svgs/divami-logo.svg" alt="Divami Design Labs" title="Divami Design Labs">
                </a>
            </div>
            <!-- design brand block end -->
            <!-- social icons block start -->
            <div class="social__icons-block" *ngIf="footerShow">
                <a href="https://www.facebook.com/rama.prayagas" class="social__icon-block facebook" target="_blank" rel="noopener noreferrer">
                    <app-svg [name]="'facebook'"></app-svg>
                </a>
                <!-- <a href="https://newstressclinic.com/login/" class="social__icon-block google-plus" target="_blank">
                    <app-svg [name]="'google-plus'"></app-svg>
                </a> -->
                <a href="https://www.linkedin.com/in/rama-prayaga-75121a1a8/" rel="noopener noreferrer" class="social__icon-block linkedin" target="_blank">
                    <app-svg [name]="'linkedin'"></app-svg>
                </a>
                <a href="https://twitter.com/ketaminedc" rel="noopener noreferrer" class="social__icon-block twitter" target="_blank">
                    <app-svg [name]="'twitter'"></app-svg>
                </a>
            </div>
            <!-- social icons block end -->
        </div>
        <!-- copy right section end -->
    </div>
    <!-- footer container end -->
    <!-- footer bottom section start -->
    <div class="footer__bottom-section hide">
        <div class="footer__bottom-container restrict-width padding-lr">
            <div class="icon__block info">
                <app-svg [name]="'info'"></app-svg>
            </div>
            <p>
                <span>If you are in crisis or any other person maybe in danger don’t use this site.</span>
                <a href="">These resources</a>
                <span>can provide you with immediate help.</span>
            </p>
        </div>
    </div>
    <!-- footer bottom section end -->
    <!-- <app-common-popup (showHideCancelPopup)='cancelPopupShowHideStatusFun($event)' *ngIf="cancelPopupShowHide">
        <div class="popup-content-block">
        </div>
    </app-common-popup> -->
    <app-contact-us (showHideCancelPopup)='cancelPopupShowHideStatusFun($event)' *ngIf="cancelPopupShowHide"></app-contact-us>
</footer>
<!-- common footer end -->