<div class="appointments__details--wrapper">
    <div class="content__block--wrapper">
        <!-- Left side details starts here -->
        <div class="left__side--wrapper">
            <!-- Appointment details starts here -->
            <div class="patient__details--title">
                Blocked Slot
            </div>
            <div class="patient__details--block">
                <div class="each__block">
                    <div class="details__lable">
                        Date
                    </div>
                    <div class="details__value">
                        {{blokedSlotInputData.beginDate | dateFormat}} To {{blokedSlotInputData.endDate | dateFormat}}
                    </div>
                </div>
                <div class="each__block">
                    <div class="details__lable">
                        Time Slot
                    </div>
                    <div class="details__value">
                        {{blokedSlotInputData.beginTime | customTime}} To {{blokedSlotInputData.endTime | customTime}}
                    </div>
                </div>
                <div class="each__block">
                    <div class="details__lable">
                        Treatments
                    </div>
                    <div class="details__tratments-block">
                        <div class="details__value" *ngFor="let treatment of blokedSlotInputData.treatments">
                            {{treatment.appointmentType.appointmentTypeName | titlecase}}
                        </div>
                    </div>
                </div>
            </div>
            <!-- Appointment details ends here -->

            <!-- Doctors block starts here -->
            <!-- <div class="doctors__block">
                <div class="side__lable">Doctors</div>
                <div class="cards__block">
                    <div class="card__boxes" *ngFor="let doctor of blokedSlotInputData.doctor">
                        <app-profile-box [cardData]="doctor"></app-profile-box>
                    </div>
                </div>
            </div> -->
            <!-- Doctors block ends here -->


            <!-- Bottom actions starts here -->
            <div class="actions__block">
                <div class="each__action" (click)="actionsClicked(action.actionId, blokedSlotInputData)" *ngFor="let action of actions;let i=index">
                    <app-svg class="{{action.icon}}" [name]="action.name"></app-svg>
                    <p>{{action.label}}</p>
                </div>
            </div>
            <!-- Bottom actions starts here -->
        </div>
    </div>
</div>