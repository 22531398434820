<div class="email__template--contact-info">
    <div class="contact__info--links">
        <a class="pr-20" href="#">Privacy</a>
        <a class="pr-20" href="#">Terms</a>
        <a href="#">Visit Website</a>
    </div>
    <div class="office__mail--section">
        <div>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas massa
            eros, dignissim sit
        </div>
        <div>
            For other queries contact us at
            <a href="mailto:officemanager@newstressclinic.com">officemanager@newstressclinic.com</a>
        </div>
    </div>
    <div class="social-media-section">
        <div>
            <a href="#">
                <app-svg [name]="'facebook'" class="social__media--icon"></app-svg>
            </a>
        </div>
        <div>
            <a href="#">
                <app-svg [name]="'google-plus'" class="social__media--icon"></app-svg>
            </a>
        </div>
        <div>
            <a href="#">
                <app-svg [name]="'linkedin'" class="social__media--icon"></app-svg>
            </a>
        </div>
        <div>
            <a href="#">
                <app-svg [name]="'twitter'" class="social__media--icon"></app-svg>
            </a>
        </div>
    </div>
</div>
