import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { CustomFormComponent } from '@app/common/components/custom-form/custom-form.component';
import { API_URLS } from '@app/common/constants/api-urls.constant';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';
import { HTTPMethods, NEWSTRESS_EMAIL_IDS, TOAST_STATUSES } from '@app/common/constants/util.constant';
import { CognitoService } from '@app/common/services/cognito.service';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { DataService } from '@app/common/services/data.service';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { INTAKE_FORM_KEYS } from '@app/common/constants/form-keys.constant';

@Component({
  selector: 'app-admin-create-patient',
  templateUrl: './admin-create-patient.component.html',
  styleUrls: ['./admin-create-patient.component.scss']
})
export class AdminCreatePatientComponent implements OnInit {
  formCompleted: any;
  addNewIndex: any = 0;

  formBuilder = new FormBuilder();
  @ViewChild('formElement', { static: true }) ele: ElementRef;
  childComponent = new CustomFormComponent(this.formService, this.formBuilder, this.http, this.cognitoService, this.observable, this.route, this.router);
  form: any = [];
  offlineFormTags: any = ['NEWUSER_BASICINFO', 'NEWUSER_INSURANCEDETAIL', 'NEWUSER_MEDICALHISTORY', 'NEWUSER_MISCELLAENOUS', 'NEWUSER_NEWPATIENTSUBMIT'];
  newUserFormTags: any = ['BASICINFO', 'INSURANCEDETAIL', 'MEDICALHISTORY', 'MISCELLAENOUS', 'NEWPATIENTSUBMIT'];
  // newUserFormTags: any = ['NEWUSER_BASICINFO', 'NEWUSER_INSURANCEDETAIL', 'NEWUSER_MEDICALHISTORY', 'NEWUSER_MISCELLAENOUS', 'NEWUSER_NEWPATIENTSUBMIT'];
  existingUserFormTags: any = ['EXISTINGUSER_BASICINFO', 'EXISTINGUSER_INSURANCEDETAIL', 'EXISTINGUSER_MEDICALHISTORY', 'EXISTINGUSER_MISCELLAENOUS', 'EXISTINGUSER_NEWPATIENTSUBMIT'];
  formPosArray: any = [];
  searchPlaceHolderText: string = 'Search by email';
  cancelPopupShowHide: boolean = false;
  stepindicatorArray: any = [];
  saveFormData: boolean = false;
  currentFormIndex: number = 0;
  isMobile: boolean = false;
  actionText = 'Save';
  offLineStatus: boolean = false;
  userId: any;
  editable: boolean = false;
  userIdNumber: any;
  editViewClicked: boolean = false;
  editViewData: any;
  checkFormInfo = { // get data and check data is valid or not
    basic_information: false,
    insurance_details: false,
    medical_history: false,
    miscellaenous: false,
    submit: false
  };
  showPharmacyStatePopup: boolean = false;
  allowedPharmacyStates: Array<string> = ['MD','VA','DC']
  searchInput = '';
  adminFormType = [
    {
      label: 'Create as a new user',
      value: 'newUser'
    },
    {
      label: 'Assign existing user',
      value: 'existingUser'
    },
    {
      label: 'Create offline patient',
      value: 'offlinePatient'
    }
  ];

  getFormInfo = { // get the form data not checking
    basic_information: false,
    insurance_details: false,
    medical_history: false,
    miscellaenous: false,
    submit: false
  };

  // terms and condition static data
  termsAndConditionData = {
    description: 'Dr. Prayaga provides the following services: Medication Management, Psychiatric Evaluations, Transcranial Magnetic Stimulation, and Telemedicine Services (current patient\'s only).  If you are seeking therapy, individual or marriage counseling, or substance abuse counseling, please reach out to your insurer for a list of physicians who provide such services. While Dr. Prayaga does not provide the above-mentioned services, he can coordinate care with your selected therapist or counselor in conjunction with medication management and/or Transcranial Magnetic Stimulation services.',
    listdescription: 'Please review the information listed below to assist you in preparing for your office visit.',
    list: [
      'Bring your identification card and insurance card.  We will make a copy of the documents for your file.',
      'Prescriptions renewals are conducted in person with the doctor every 28-30 days( i.e. no refills will be called in)',
      'Dr. Prayaga carefully reviews and monitors medications that are prescribed, therefore he avoids giving out 3-month supplies of medication.',
      'Please provide a courtesy 24-hour cancellation notification. It is the office’s policy to charge a $25.00 cancellation fee if the cancellation policy isn’t adhered to by patient\'s.',
      'For patient\'s requesting disability paperwork, the office is able to provide medical records or a discharge summary only.  The processing time for medical records or a discharge summary is 5 to 7 days upon receipt of the request.  All requests must be submitted to the office staff or receptionist.',
      // "Only service animals are permitted in the office.  No other animals are allowed in the office.",
      'Only service animals with accompanying a person while pat is being treated.'
    ]
  };
  // input for step indicator
  stepDetails = [
    {
      label: 'Basic Information',
      id: 'basic_information',
      img: 'content',
      visited: true,
      notVisisted: false,
      completed: false,
      onPage: true
    },
    {
      label: 'Insurance Details',
      id: 'insurance_details',
      img: 'shield',
      visited: false,
      notVisisted: true,
      completed: false,
      onPage: false
    },
    {
      label: 'Medical History',
      id: 'medical_history',
      img: 'heart',
      visited: false,
      notVisisted: true,
      completed: false,
      onPage: false
    },
    {
      label: 'Miscellaneous',
      id: 'miscellaenous',
      img: 'miscellaenous',
      visited: false,
      notVisisted: true,
      completed: false,
      onPage: false
    },
    {
      label: 'Submit',
      id: 'submit',
      img: 'submit',
      visited: false,
      notVisisted: true,
      completed: false,
      onPage: false
    }
  ];
  formBackUp: any = [
    [],
    [],
    [],
    [],
    []
  ];
  elementsHeight: any[];
  currentUrl: any;
  formData: any = {};
  finalFormData: any = {};
  providers: any = {};
  pharmacy: any;
  userDetails: any;
  patientDetails: any = {};
  formSubmited: boolean = false;
  editData: any;
  showForm = false;
  mySubscription: any;
  isEditAndView: boolean = false;
  showScreen;
  editCopy: any;
  showConfirmTerms: boolean = true;
  typeOfForm: string = 'newUser';
  formType: string = 'newUser';
  isSearchListVisble: boolean = false;
  emailSearchList: any = [];
  existingUser: any = [];
  newUser: any = [];
  inputChanged: Subject<string> = new Subject<string>();
  isValidEmail: boolean = true;
  isUserExisting: boolean = true;
  fileUploadProgress: number = 0;
  showDocListPopup: boolean = false;
  docList: Array<string> = [];
  mailText = 'must be a valid email';
  previousPatientData: any;
  stateList: any;

  constructor(
    private formService: CustomFormHandlerService,
    private cd: ChangeDetectorRef,
    private elRef: ElementRef,
    private observableHelperService: ObservableHelperService,
    private observable: ObservableHelperService,
    private route: ActivatedRoute,
    private http: HttpService,
    private router: Router,
    private dataService: DataService,
    private cognitoService: CognitoService) {
    route.queryParams.subscribe(params => {
      // Adding debounded time for the inputvalue
      this.userIdNumber = params.id;
      if (params.edit) {
        if (params.status === 'pending' && params.isOffile !== '1') {
          this.formType = 'newUser';
        } else if ((params.status === 'pending' && params.isOffile === '1') || (params.status === 'active' && params.isOffile === '1') || (params.status === 'active' && params.isOffile === '0')) {
          if (params.isOffile === '1') {
            this.offLineStatus = true;
          } else {
            this.offLineStatus = false;
          }
          this.formType = 'offlinePatient';
        }
      }
    });
    router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((e: any) => {
        this.currentUrl = e.url;
      });

    /* component refresh */
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.router.navigated = false;
      }
    });
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setPlatformInfo();
      document.addEventListener('DOMContentLoaded', () => {
        this.setPlatformInfo();
        var inputBox = document.querySelector('.safari #inputBox');
        if (inputBox) {
          inputBox.addEventListener('focus', (e) => {
            document.body.classList.add('keyboard');
            setTimeout(() => {
              window.scrollTo(0, 0);
            }, 200);
          });
          inputBox.addEventListener('blur', (e) => {
            document.body.classList.remove('keyboard');
          });
        }
      });
    }, 3000);
  }
  clearStepper() {
    this.stepDetails = this.stepDetails.map((each) => {
      return {
        ...each,
        visited: false,
        notVisisted: true,
        completed: false,
      };
    });
  }

  selectedEmail(e) {
    if (e) {
      this.searchInput = e.email;
      this.patientDetails.userId = e.userId;
      this.validateEmail(this.searchInput);
      if (this.isValidEmail) {
        this.isExistingUser();
      }
    }
  }
  search(e) {
    if(e.key === 'Enter'){
    if (e.query !== '') {
      this.searchInput = e.target.value;
      this.getSearchEmails(e);
    }
  }
  }
  isExistingUser() {
    let emails = [];
    if (this.searchInput !== '' && this.emailSearchList.length !== 0) {
      this.emailSearchList.forEach(each => {
        emails.push(each.email);
      });
      this.isUserExisting = emails.includes(this.searchInput);
    } else {
      this.isUserExisting = false;
    }
  }
  // This is selected Radio
  selectedRadio(form) {
    if (form) {
      this.resetFormValues(form);
      this.clearStepper();
      this.formBackUp = [[], [], [], [], []];
      this.formType = form.value;
      this.searchInput = '';
      if (this.formType === 'existingUser') {
        this.isValidEmail = true;
      }
      if (this.formType === 'offlinePatient') {
        this.offLineStatus = true;
      } else {
        this.offLineStatus = false;
      }
      this.initLoad();
    }
  }

  // This is Search input keyUp function
  inputValueChange(e) {
    this.inputChanged.next(e);
  }

  // validation for Email
  validateEmail(email): any {
    var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var found = regex.test(email);
    this.isValidEmail = found;
  }



  ngOnInit() {
    this.initLoad();
  }

  // This will Do API call for Search Emails
  getSearchEmails(e) {
    let bodyData = {
      email: e.target.value
    };
    let payload = {
      type: HTTPMethods.POST,
      url: API_URLS.SEARCH_MAIL_ADDRESS,
      isDeveloper: true,
      body: bodyData,
      // donotShowLoader: true,
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      this.emailSearchList = [];
      if (this.http.isSuccessfulResponse(res) && res) {
        this.emailSearchList = res.data.filter(data => data.removedFlag != 1);
        // this.emailSearchList = res.data;
        this.validateEmail(this.searchInput);
        if (this.isValidEmail) {
          this.isExistingUser();
        }
      } else {
        let message = 'Failed to fetch emails data. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  // This will do form loading after cognitoService call
  initLoad() {
    this.cognitoService.getUser().then(user => {
      this.userDetails = user;
      this.route
        .queryParams
        .subscribe(params => {
          if (Object.keys(params).length === 0) {
            setTimeout(() => {
              this.showForm = true;
            }, 0);
          }
          this.userIdNumber = params.id;
          this.patientDetails['userId'] = params.userId;
          this.patientDetails['firstName'] = params.firstName;
          this.patientDetails['lastName'] = params.lastName;
          if (params.edit) {
            this.isEditAndView = true;
            this.editable = false;
          } else if (params.view) {
            this.isEditAndView = true;
            this.editable = true;
          } else if (params.newForm) {
            this.editable = false;
            /* resetting formInputs values for new patient form */
            this.formBackUp.map(element => {
              element.map(innerElement => {
                if (innerElement.type === 'formArray') {
                  innerElement.formInputs[0] = innerElement.formInputs[0].map(inputElement => {
                    switch (inputElement.type) {
                      case 'text':
                        inputElement.value = '';
                        break;
                      case 'textarea':
                        inputElement.value = '';
                        break;
                      case 'radio':
                        inputElement.value = '';
                        break;
                      case 'calendar':
                        inputElement.value = '';
                        break;
                      case 'checkbox-slider':
                        inputElement.value = false;
                        break;
                      default:
                        break;
                    }
                    return inputElement;
                  });
                }
                return innerElement;
              });
              return element;
            });
          }
        });
    });
    this.getStateList();
    this.getProvidersAndPharmacyDetails();
    this.scrollTop();
    this.focusFirstFieldInSafari();
    this.isMobile = window.innerWidth < 1024 ? true : false;
    document.querySelector('html').style.overflow = 'hidden';
    document.querySelector('body').style.overflow = 'hidden';
    if (this.formType === 'newUser') {
      this.newUser = [];
      this.newUserFormTags.forEach((element) => {
        this.newUser.push(this.formService.initForm(this.formService.formTypes[element]));
      });
      this.backUpFormFields(this.newUser);
    } else if (this.formType === 'existingUser') {
      this.existingUser = [];
      this.existingUserFormTags.forEach((element) => {
        this.existingUser.push(this.formService.initForm(this.formService.formTypes[element]));
      });
      this.backUpFormFields(this.existingUser);
    } else if (this.formType === 'offlinePatient') {
      this.form = [];
      if (this.offLineStatus !== false) {
        this.offlineFormTags.forEach((element) => {
          if (this.formService.formTypes[element].config === 'newUserBasicInformationFormConfig') {
            this.formService.newUserBasicInformationFormConfig.forEach(field => {
              if (field.key === 'firstName' || field.key === 'lastName') {
                field.validators = [];
                field.validators = [Validators.required, this.formService.validateName];
              } else if (field.key === 'email') {
                field.validators = [];
                field.validators = [this.formService.validateEmail];
              }
            });
          }
          if (this.formService.formTypes[element].config === 'newUserMedicalHistoryFormConfig') {
            this.formService.newUserMedicalHistoryFormConfig.forEach(field => {
              if (field.key === 'medications' || field.key === 'benzodiazepines') {
                field.formInputs[0].forEach(form => {
                  if (form.key === 'discontinuedReasion') {
                    form.required = true;
                    return false;
                  }
                });
              }
            });
          }
          this.form.push(this.formService.initForm(this.formService.formTypes[element]));
          setTimeout(() => {
            if (this.form) {
              let formField = ['idProofUploadImage1', 'idProofUploadImage2', 'email'];
              formField.forEach(field => {
                this.form[0].formGroup.controls[field].staus = 'VALID';
                if (field !== 'email') {
                  this.form[0].formGroup.controls[field].clearValidators();
                  this.form[0].formGroup.controls[field].updateValueAndValidity();
                }
                this.form[0].formGroup.controls[field].setErrors(null);
              });
              // tslint:disable-next-line:forin
              for (let key in this.form[1].formGroup.controls) {
                  this.form[1].formGroup.controls[key].staus = 'VALID';
                  this.form[1].formGroup.controls[key].clearValidators();
                  this.form[1].formGroup.controls[key].updateValueAndValidity();
                  this.form[1].formGroup.controls[key].setErrors(null);
                }
            }
          }, 200);
        });
        this.backUpFormFields(this.form);
        console.log(this.form);
      } else {
        this.offlineFormTags.forEach((element) => {
          this.form.push(this.formService.initForm(this.formService.formTypes[element]));
        });
        this.backUpFormFields(this.form);
      }
    }
    this.observableHelperService.getAddPatientClicked().subscribe((data) => {
      if (data) {
        this.saveFormData = false;
        this.form.forEach(eachForm => {
          eachForm.formGroup.reset();
          eachForm.formInputs.forEach(input => {
            if (input.value && input.validators) {
              input.value = '';
              input.validators = [];
              input.editable = true;
              input.selected = false;
              input.showImage = true;
            }
          });
        });
      }
    });
    this.focusFirstFieldInSafari();
  }
  backUpFormFields(form) {
    if (form) {
      ///      this.formBackUp = [...this.form.map(element => element.formInputs.map(ele => ele))]
      form.forEach((element, index) => {
        element.formInputs.forEach(ele => {
          /* following code makes deep copy of the formInputs and formArrays to backup */
          let obj = { ...ele };
          for (let key in ele) {
            if (Array.isArray(ele[key])) {
              obj[key] = [...ele[key]];
            }
          }
          this.formBackUp[index].push({ ...obj });
        });
      });

    }
  }
  getPatientDetails() {
    let payload = {
      type: 'GET',
      url: 'getOnePatient',
      isDeveloper: true,
      pathVariables: [this.userIdNumber, this.patientDetails.userId]
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res) && res) {
        this.formService.therapistEmailList = NEWSTRESS_EMAIL_IDS;
        if (res.data.pointofContact && res.data.pointofContact.email) {
        this.formService.therapistEmailList = [...this.formService.therapistEmailList, res.data.pointofContact.email];
        }
        this.formService.therapistEmailList = [...this.formService.therapistEmailList, ...res.data.allUserPatientEmails];
        let poc = res.data.pointofContact ? res.data.pointofContact : {};
        delete res.data.pointofContact;
        let pocData = {
          pocCreateTime: poc.createTime,
          pocEmail: poc.email,
          pocFirstName: poc.firstName,
          pocInvoiceId: poc.invoiceId,
          pocLastName: poc.lastName,
          pocUserId: poc.userId
        }
        res.data.pointofContact = pocData
        this.editCopy = this.massageEditCopy(res.data);
        this.editData = this.massageEditData(res.data);
        this.previousPatientData = {...this.editData};
        let state = this.stateList.find((ele: any) =>  ele.value == this.editCopy.patientBasicInfo.streetAddress.state )
        if(!state){
          let statename = this.formService.statesList.find((ele: any) => ele.value == this.editCopy.patientBasicInfo.streetAddress.state)
          this.stateList.push({value: res.data.patientBasicInfo.streetAddress.state, label:statename.label})
        }
        console.log(this.previousPatientData);
        if (this.editData) {
          if (this.editData) {
            this.massageAddMedinceData();
          }
          try {
            this.showForm = false;
            this.route
              .queryParams
              .subscribe(params => {
                if (params.edit) {
                  if (params.status === 'pending' && params.isOffile !== '1') {
                    this.newUser = this.editAndViewDataFn(this.newUser);
                  } else if ((params.status === 'pending' && params.isOffile === '1') || (params.status === 'active' && params.isOffile === '1') || (params.status === 'active' && params.isOffile === '0')) {
                    this.form = this.editAndViewDataFn(this.form);
                    if (params.isOffile === '1') {
                      this.form[0].formGroup.controls['email'].clearValidators();
                      this.form[0].formGroup.controls['email'].updateValueAndValidity();
                      this.form[0].formInputs.forEach(obj => {
                        if (obj.key === 'email') {
                          obj.required = false;
                          obj.validators = [];
                        }
                      });
                    }
                  }
                }

              });
          } catch (err) {
          }
          setTimeout(() => {
            this.showForm = true;
          }, 0);
          this.focusFirstFieldInSafari();
          this.stepDetails.forEach(each => each.visited = true);
        }
      } else {
        let message = 'Failed to fetch patient data. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
  massageEditCopy(data) {
    if (data && typeof (data) === 'object') {
      return {
        userId: data.userId,
        patientBasicInfo: data.patientBasicInfo,
        insuranceDetails: data.insuranceDetails,
        medicalHistory: data.medicalHistory,
        patientMiscInfo: data.patientMiscInfo,
        submitInfo: data.submitInfo
      };
    }

  }
  focusFirstFieldInSafari() {
  }
  massageAddMedinceData() {
    this.editData['dob'] = (this.editData['dob'] !== '') ? this.calendarDateFormate(this.editData['dob']) : '';
    this.editData['policyHolderDOB'] = (this.editData['policyHolderDOB'] !== '') ? this.calendarDateFormate(this.editData['policyHolderDOB']) : '';
    this.editData['lastTherapyDate'] = (this.editData['lastTherapyDate'] !== '') ? this.calendarDateFormate(this.editData['lastTherapyDate']) : '';
    this.editData['psychiatricSlider'] = !this.editData['psychiatric']['flag'],
      this.editData['medications'] = this.editData['psychiatric']['medications'],
      this.editData['benzodiazepinesSlider'] = !this.editData['benzodiazepines']['flag'],
      this.editData['benzodiazepines'] = this.editData['benzodiazepines']['medications'],
      this.editData['medicaladderallstimulants'] = !this.editData['medicalAdderallStimulants']['flag'],
      this.editData['medicaladderal'] = this.editData['medicalAdderallStimulants']['medications'],
      this.editData['disabilityBenefits'] = !this.editData['disabilityBenefits'],
      this.editData['drugAlcoholAbuse'] = !this.editData['drugAlcoholAbuse'],
      this.editData['alcoholDrugIn3Months'] = !this.editData['alcoholDrugIn3Months'],
      this.editData['selfMedicate'] = !this.editData['selfMedicate'],
      this.editData['legalIssues'] = !this.editData['legalIssues'],
      this.editData['inTherapy'] = !this.editData['inTherapy'],
      this.editData['isTherapistEmailId'] = !this.editData['isTherapistEmailId'],
      this.editData['miscellaenousKnownAllergies'] = this.editData['miscKnownAllergies1'],

      this.editData['anyMedicalDisorders'] = this.editData['anyMedicalDisorders'] !== undefined ? !this.editData['anyMedicalDisorders'] : false,
      this.editData['anyMedicalDisordersExplain'] = this.editData['anyMedicalDisordersExplain'] ? this.editData['anyMedicalDisordersExplain'] : '' ,
      this.editData['pastHistoryofAlchohol'] = this.editData['pastHistoryofAlchohol'] !== undefined ? !this.editData['pastHistoryofAlchohol'] : false,
      this.editData['pastHistoryofAlchoholExplain'] = this.editData['pastHistoryofAlchoholExplain'] ? this.editData['pastHistoryofAlchoholExplain']:'',
      this.editData['nonPsychMeds'] = this.editData['nonPsychMeds'] !== undefined ? !this.editData['nonPsychMeds'] : false,
      this.editData['nonPsychMedsExplain'] = this.editData['nonPsychMedsExplain'] ?  this.editData['nonPsychMedsExplain'] : '',
      this.editData['anyOpiods'] = this.editData['anyOpiods'] !== undefined ? !this.editData['anyOpiods'] : false,
      this.editData['anyOpiodsExplain'] = this.editData['anyOpiodsExplain'] ? this.editData['anyOpiodsExplain'] : '',
      this.editData['patientHospitalized'] = this.editData['patientHospitalized'] !== undefined ? !this.editData['patientHospitalized'] : false,
      this.editData['patientHospitalizedReason'] = this.editData['patientHospitalizedReason'] ? this.editData['patientHospitalizedReason'] : '',
      this.editData['nameOfHospital'] = this.editData['nameOfHospital'] ? this.editData['nameOfHospital'] : '',
      this.editData['patientHospitalizedDate'] = this.editData['patientHospitalizedDate'] ? this.calendarDateFormate(this.editData['patientHospitalizedDate']) : '',
      this.editData['previousPrescriber'] = this.editData['previousPrescriber'] !== undefined ? !this.editData['previousPrescriber'] : false,
      this.editData['prescriberName'] = this.editData['prescriberName'] ? this.editData['prescriberName'] : '',
      this.editData['prescriberAddress'] = this.editData['prescriberAddress'] ? this.editData['prescriberAddress'] : '',
      this.editData['prescriberEmail'] = this.editData['prescriberEmail'] ? this.editData['prescriberEmail'] : '',
      this.editData['prescriberPhone'] = this.editData['prescriberPhone'] ? this.editData['prescriberPhone'] : '',
      this.editData['sendTherapist'] = this.editData['patientHaveTherapistNotes'] === false ? true : false, 
      this.editData['sendPrescriber'] = this.editData['patientHavePrescriberNotes'] === false ? true : false,
      this.editData['patientHaveTherapistNotes'] = !this.editData['inTherapy'] ? (this.editData['patientHaveTherapistNotes'] !== undefined ? !this.editData['patientHaveTherapistNotes'] : "") : false,
      this.editData['patientHavePrescriberNotes'] = !this.editData['previousPrescriber'] ? (this.editData['patientHavePrescriberNotes'] !== undefined ? !this.editData['patientHavePrescriberNotes'] : "") : false,
      this.editData['prescriberDocs'] = this.editData['prescriberDocs'] ? this.editData['prescriberDocs'] : [],
      this.editData['therapistDocs'] = this.editData['therapistDocs'] ? this.editData['therapistDocs'] : [], 
      // tslint:disable-next-line: radix
      this.editData['primaryInsuranceProvider'] = (this.editData['primaryInsuranceProvider'] === 'NaN' || this.editData['primaryInsuranceProvider'] === 'undefined') ? '' : parseInt(this.editData['primaryInsuranceProvider']),
      // tslint:disable-next-line: radix
      this.editData['secondaryInsuranceProvider'] = (this.editData['secondaryInsuranceProvider'] === 'NaN' || this.editData['secondaryInsuranceProvider'] === 'undefined') ? '' : parseInt(this.editData['secondaryInsuranceProvider']);
  }

  massageEditData(data) {
    let editData = {};
    for (let key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        let value = data[key];
        if (typeof (value) === 'object') {
          for (let innerkey in value) {
            if (Object.prototype.hasOwnProperty.call(value, innerkey)) {
              let innerValue = value[innerkey];
              if ((typeof (innerValue) === 'object') && !(innerkey === 'psychiatric' || innerkey === 'benzodiazepines' || innerkey === 'medicalAdderallStimulants')) {
                if (innerkey === 'pharmacyAddress') {
                  editData['pharmacyAddress1'] = innerValue['streetAddress1'],
                    editData['pharmacyAddress2'] = innerValue['streetAddress2'],
                    editData['pharmacyCity'] = innerValue['city'],
                    editData['pharmacyState'] = innerValue['state'],
                    editData['pharmacyPhoneNo'] = innerValue['phoneNumber'],
                    editData['pharmacyZipcode'] = innerValue['zipCode'];
                } else {
                  for (let inner2Key in innerValue) {
                    if (Object.prototype.hasOwnProperty.call(innerValue, inner2Key)) {
                      editData[inner2Key] = innerValue[inner2Key];
                    }
                  }
                }
                if(innerkey === INTAKE_FORM_KEYS.THERAPIST_DOCS || innerkey === INTAKE_FORM_KEYS.PRESCRIBER_DOCS || innerkey === INTAKE_FORM_KEYS.LAB_WORKS_DOCS){
                  editData[innerkey] = innerValue;
                }
              } else if(innerkey == 'height') {
                var height = innerValue.split("'");
                editData['heightFt'] = height[0];
                editData['heightInch'] = height[1];
              } else {
                editData[innerkey] = innerValue;
              }
            }
          }
        } else {
          editData[key] = value;
        }
      }
    }
    return editData;
  }
  calendarDateFormate(date) {
    if(date){
      let dates = date.split('-');
      return dates[1] + '/' + dates[2] + '/' + dates[0];
    } else {
      return '';
    }
  }
  ngAfterContentChecked() {
    this.getFormElements();
    let stepPosition = document.querySelectorAll('.cell-container');
    stepPosition.forEach((element) => {
      this.stepindicatorArray.push(element.getBoundingClientRect());
    });
    window.scrollTo(0, 0);
  }


  getProvidersAndPharmacyDetails() {
    let payload = {
      type: 'GET',
      url: 'reference',
      isDeveloper: true,
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      this.showScreen = true;
      if (this.http.isSuccessfulResponse(res) && res) {
        this.massageProvidersAndPharmacyDetails(res.data);
        let configData = {
          insuranceCongfig: '',
          miscellaenousConfig: ''
        };
        if (this.formType === 'newUser') {
          configData.insuranceCongfig = 'insuranceDetailFormConfig';
          configData.miscellaenousConfig = 'miscellaenousFormConfig';
        } else if (this.formType === 'existingUser') {
          configData.insuranceCongfig = 'existingUserInsuranceDetailFormConfig';
          configData.miscellaenousConfig = 'existingUserMiscellaenousFormConfig';
        } else if (this.formType === 'offlinePatient') {
          configData.insuranceCongfig = 'newUserInsuranceDetailFormConfig';
          configData.miscellaenousConfig = 'newUserMiscellaenousFormConfig';
        }
        this.formService[configData.insuranceCongfig].forEach(each => {
          if (each.key === 'primaryInsuranceProvider') {
            each.list = this.providers.primaryInsurance;
          } else if (each.key === 'secondaryInsuranceProvider') {
            each.list = this.providers.secondaryInsurance.reverse(); // here the secondary insurance list is reversed 
          }
        });
        this.formService[configData.miscellaenousConfig].forEach(each => {
          if (each.key === 'pharmacyName') {
            each.list = this.pharmacy;
          }
        });
      } else {
        let message = 'Failed to fetch insurance data. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
      }
      if (this.userIdNumber) {
        this.getPatientDetails();
      }

    });
  }

  /**
   * Get states list.
   */
  getStateList(){
    let payload = {
      type: 'GET',
      url: 'states',
      isDeveloper: true,
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res) && res) {
        this.stateList = res.data;
        console.log(res,"state list response")
        let config = ''
        if (this.formType === 'newUser') {
          config = 'basicInformationFormConfig';
        } else if (this.formType === 'existingUser') {
          config = 'existingUserBasicInformationFormConfig';
        } else if (this.formType === 'offlinePatient') {
          config = 'newUserBasicInformationFormConfig';
        }
        this.formService[config].forEach(each => {
          if (each.key === 'state') {
            each.list = this.stateList;
          }
        });
      } else {
        let message = TOAST_MESSAGES.STATES_FAIL;
        message = (res.error) ? res.error.message : message;
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  focusInput(event) {
    let isMobile = window.innerWidth < 1024 ? true : false;
    if (event && isMobile) {
      let inputField = event.target;
      if (document.querySelectorAll('.safari').length !== 0) {
        inputField.scrollIntoView();
        let container = document.getElementById('app__component--id');
        let newPatientContainer: any = document.querySelectorAll('.btn-actions')[0];
        newPatientContainer.style.paddingBottom = '270px';
      }
    } else {
    }
  }
  focusOutInput(event) {
    let isMobile = window.innerWidth < 1024 ? true : false;
    if (event && isMobile) {
      let inputField = event.target;
      if (document.querySelectorAll('.safari').length !== 0) {
        let container = document.getElementById('app__component--id');
        let newPatientContainer: any = document.querySelectorAll('.btn-actions')[0];
        newPatientContainer.style.paddingBottom = '50px';
      }
    }
  }
  setPlatformInfo() {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') !== -1) {
      if (ua.indexOf('chrome') > -1) {
        document.body.classList.add('chrome');
      } else {
        document.body.classList.add('safari');
      }
    }
  }
  formatDate(date) {
    if (!date || date === '') {
      return '';
    } else {
      var d = new Date(date);
      var month = '' + (d.getMonth() + 1);
      var day = '' + d.getDate();
      var year = d.getFullYear();

      if (month.length < 2) {
        month = '0' + month;
      }
      if (day.length < 2) {
        day = '0' + day;
      }

      return [year, month, day].join('-');
    }
  }

  massageProvidersAndPharmacyDetails(data) {
    this.pharmacy = data.pharmacy.map(each => {
      return {
        ...each,
        label: each.pharmacyName,
        value: each.pharmacyName.toLowerCase(),
      };
    });
    let primary = [];
    let secondary = [];
    data.insurance.forEach(insurance => {
      let dropDown = {
        ...insurance,
        label: insurance.insuranceName,
        value: insurance.insuranceId,
      };
      if (insurance.primaryFlag === 1) {
        primary.push(dropDown);
      }
      if (insurance.secondaryFlag === 1) {
        secondary.push(dropDown);
      }
    });

    this.providers = {
      primaryInsurance: primary,
      secondaryInsurance: secondary,
    };
  }
  getFormElements() {
    let patientFormElementArray = document.querySelectorAll('.formdata');
    this.formPosArray = [];
    this.elementsHeight = [];
    patientFormElementArray.forEach((element: any) => {
      this.formPosArray.push(element.getBoundingClientRect());
    });
    let sum = 0;
    this.formPosArray.forEach(element => {
      sum = sum + element.height;
      this.elementsHeight.push(sum);
    });
  }
  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    let scrollTop = event.target.scrollTop;

    if (scrollTop >= 0 && scrollTop <= this.elementsHeight[0]) {
      this.setStepindicator(0);
    } else if (scrollTop > this.elementsHeight[0] && scrollTop <= this.elementsHeight[1]) {
      this.setStepindicator(1);
    } else if (scrollTop > this.elementsHeight[1] && scrollTop <= this.elementsHeight[2]) {
      this.setStepindicator(2);
    } else if (scrollTop > this.elementsHeight[2] && scrollTop <= this.elementsHeight[3]) {
      this.setStepindicator(3);
    } else if (scrollTop > this.elementsHeight[3] && scrollTop <= this.elementsHeight[4]) {
      this.setStepindicator(4);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobile = window.innerWidth < 1024 ? true : false;
  }
  sliderValue(event) {
    this.getFormElements();
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    this.resetFormValues();
    document.querySelector('html').style.overflow = 'auto';
    document.querySelector('body').style.overflow = 'auto';
    /* component refresh */
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }

  setStepindicator(index: number): void {
    this.resetOnpageIndicator(index);
    var stepContainer = document.querySelector('.step__indicator');
    if (index === 0) {
      stepContainer.scrollTop = 0;
    } else {
      stepContainer.scrollTop = this.elementsHeight[index - 1];
    }
  }

  stepIndicatorClicked(index: number): void {
    this.stepDetails.forEach((element, eIndex) => {
      element.onPage = false;
      if (eIndex <= index) {
        element.visited = true;
      }
    });
    this.stepDetails[index].onPage = true;
    if (!this.isMobile) {
      var formContainer = document.querySelector('.patient-form');
      if (index === 0) {
        formContainer.scrollTop = 0;
      } else {
        formContainer.scrollTop = this.elementsHeight[index - 1] + 1;
      }
    }

  }

  resetOnpageIndicator(index) {
    this.stepDetails.forEach((element, ind) => {
      element.onPage = false;
      this.getFormInfo[element.id] = false;
    });
    this.stepDetails[index].onPage = true;
    this.stepDetails[index].notVisisted = false;
    this.stepDetails[index].visited = true;
    this.getFormInfo[this.stepDetails[index].id] = true;
  }

  // Getting form data when form is invalid
  inValidSubmitEvent(event, index) {
    if (this.stepDetails[index].completed) {
      this.stepDetails[index].completed = false;
    }
  }

  // Getting form data when submit button clicked
  getFormData(event, index) {
    if (event && !this.stepDetails[index].completed) {
      this.scrollTop();
      if (this.formType === 'newUser') {
        this.newUser[index].formGroup = event;
      } else if (this.formType === 'existingUser') {
        this.existingUser[index].formGroup = event;
      } else if (this.formType === 'offlinePatient') {
        this.form[index].formGroup = event;
      }
      this.stepDetails[index].completed = true;
      this.focusFirstFieldInSafari();
    }
  }

  // Massaging POST,PUT call body data
  massageFormData(forms) {
    let uploadKeys = ['idProofUploadImage1', 'idProofUploadImage2', 'policyHoldersUploadFront', 'policyHoldersUploadBack', 'secondaryPolicyHoldersUploadFront', 'secondaryPolicyHoldersUploadBack'];
    forms.forEach(element => {
      let values = element.formGroup.value;
      let inputValues = element.formInputs;
      for (let key in values) {
        if (uploadKeys.indexOf(key) === -1) {
          this.formData[key] = values[key];
        } else {
          let value;
          inputValues.some(field => {
            if (field.key === key) {
              value = field.value;
              return true;
            }
          });
          this.formData[key] = value;
        }

      }
    });
    this.finalFormData = {
      userId: this.patientDetails.userId,
      userMail: (this.formType === 'existingUser' && !(this.patientDetails && this.patientDetails.userId)) ? this.searchInput : undefined,
      patientBasicInfo: {
        firstName: this.accentedtoNormalFun(this.formData.firstName),
        lastName: this.accentedtoNormalFun(this.formData.lastName),
        socialSecurityNumber: this.formData.socialSecurityNumber,
        dob: this.formatDate(this.formData.dob),
        gender: this.formData.gender,
        userRelationToPatient: this.formData.userRelationToPatient,
        email: (this.formData.email === '') ? undefined : this.formData.email,
        emergencyContactName: this.formData.emergencyContactName,
        emergencyContactNumber: this.formData.emergencyContactNumber,
        relationWithPatient: this.formData.relationWithPatient,
        emergencyContactAddress: this.formData.emergencyContactAddress,
        idProofUploadImage1: this.formData.idProofUploadImage1,
        idProofUploadImage2: this.formData.idProofUploadImage2,
        phoneNumber: this.formData.phoneNumber,
        streetAddress: {
          streetAddress1: this.formData.streetAddress1,
          streetAddress2: this.formData.streetAddress2,
          city: this.formData.city,
          zipCode: this.formData.zipCode,
          phoneNumber: this.formData.phoneNumber,
          state: this.formData.state
        },
        weight: this.formData.weight,
        height: this.formData.heightFt + '\'' + this.formData.heightInch,
      },
      insuranceDetails: {
        sameAsPatient: this.formData.sameAsPatient != "" && this.formData.sameAsPatient != undefined ? this.formData.sameAsPatient : false,
        primaryInsuranceProvider: `${this.formData.primaryInsuranceProvider}` ?  `${this.formData.primaryInsuranceProvider}` : '',
        secondaryInsuranceProvider: `${this.formData.secondaryInsuranceProvider}` ?  `${this.formData.secondaryInsuranceProvider}` : '',
        primaryHolderText: this.formData.primaryHolderText,
        secondaryHolderText: this.formData.secondaryHolderText,
        policyHolder:
        {
          policyHolderName: this.formData.policyHolderName,
          policyHolderDOB: this.formatDate(this.formData.policyHolderDOB),
          policyHolderMemberId: this.formData.policyHolderMemberId,
          secondaryPolicyHolderMemberId: this.formData.secondaryPolicyHolderMemberId,
          policyHoldersUploadFront: this.formData.policyHoldersUploadFront,
          policyHoldersUploadBack: this.formData.policyHoldersUploadBack,
          secondaryPolicyHoldersUploadFront: this.formData.secondaryPolicyHoldersUploadFront,
          secondaryPolicyHoldersUploadBack: this.formData.secondaryPolicyHoldersUploadBack,
          policyHolderGroupNumber: this.formData.policyHolderGroupNumber,
          policyHolderMentalHealthNumber: this.formData.policyHolderMentalHealthNumber,
          policyHolderEmployer: this.formData.policyHolderEmployer,
        }
      },
      medicalHistory: {
        inTherapy: !this.formData.inTherapy,
        isTherapistEmailId:!this.formData.inTherapy ? !this.formData.isTherapistEmailId: false,
        therapistName: !this.formData.inTherapy ? this.formData.therapistName : '',
        lastTherapyDate: !this.formData.inTherapy ?  this.formatDate(this.formData.lastTherapyDate) : '',
        therapistMail: !this.formData.inTherapy && !this.formData.isTherapistEmailId ? this.formData.therapistMail : '',
        
        patientHaveTherapistNotes: !this.formData.inTherapy ? !this.formData.patientHaveTherapistNotes : false,
        therapistDocs: !this.formData.patientHaveTherapistNotes ? ((this.formData.therapistDocs != '' && this.formData.therapistDocs != null) ? this.formData.therapistDocs : []) : [],
        psychiatric: {
          flag: !this.formData.psychiatricSlider,
          medications: (!this.formData.psychiatricSlider) ? this.formArrayCalendarMassage(this.formData.medications) : null
        },
        benzodiazepines: {
          flag: !this.formData.benzodiazepinesSlider,
          medications: (!this.formData.benzodiazepinesSlider) ? this.formArrayCalendarMassage(this.formData.benzodiazepines) : null,
        },
        medicalAdderallStimulants: {
          flag: !this.formData.medicaladderallstimulants,
          medications: (!this.formData.medicaladderallstimulants) ? this.formArrayCalendarMassage(this.formData.medicaladderal) : null
        },
        LabWorkDocsUpload: (this.formData.LabWorkDocsUpload) ? this.formData.LabWorkDocsUpload : [],
        knownAllergies: this.formData.knownAllergies,
        disabilityBenefits: !this.formData.disabilityBenefits,
        drugAlcoholAbuse: !this.formData.drugAlcoholAbuse,
        alcoholDrugIn3Months: !this.formData.alcoholDrugIn3Months,
        selfMedicate: !this.formData.selfMedicate,
        medicalKnownAllergies: this.formData.medicalKnownAllergies,

        anyMedicalDisorders: !this.formData.anyMedicalDisorders,
        anyMedicalDisordersExplain: this.formData.anyMedicalDisordersExplain,
        pastHistoryofAlchohol: !this.formData.pastHistoryofAlchohol,
        pastHistoryofAlchoholExplain: this.formData.pastHistoryofAlchoholExplain,
        nonPsychMeds: !this.formData.nonPsychMeds,
        nonPsychMedsExplain: this.formData.nonPsychMedsExplain,
        anyOpiods: !this.formData.anyOpiods,
        anyOpiodsExplain: this.formData.anyOpiodsExplain,
        patientHospitalized: !this.formData.patientHospitalized,
        patientHospitalizedReason: this.formData.patientHospitalizedReason,
        nameOfHospital: this.formData.nameOfHospital,
        patientHospitalizedDate: this.formatDate(this.formData.patientHospitalizedDate),
        previousPrescriber: !this.formData.previousPrescriber,
        prescriberName: !this.formData.previousPrescriber ? this.formData.prescriberName : '',
        prescriberAddress: !this.formData.previousPrescriber ? this.formData.prescriberAddress : '',
        prescriberEmail: !this.formData.previousPrescriber ? this.formData.prescriberEmail : '',
        prescriberPhone: !this.formData.previousPrescriber ? this.formData.prescriberPhone : '',
        patientHavePrescriberNotes: !this.formData.previousPrescriber ? !this.formData.patientHavePrescriberNotes : false,
        prescriberDocs: !this.formData.patientHavePrescriberNotes ? ((this.formData.prescriberDocs != "" && this.formData.prescriberDocs != null) ? this.formData.prescriberDocs :[]) : [],
      },
      patientMiscInfo: {
        legalIssues: !this.formData.legalIssues,
        pharmacyAddress: {
          streetAddress1: this.formData.pharmacyAddress1,
          streetAddress2: this.formData.pharmacyAddress2,
          city: this.formData.pharmacyCity,
          state: this.formData.pharmacyState,
          phoneNumber: this.formData.pharmacyPhoneNo,
          zipCode: this.formData.pharmacyZipcode
        },
        pharmacyData: {
          pharmacyName: this.formData.pharmacyName,
          pharmacyNameText: this.formData.pharmacyNameText,
          pharmacyEmailId: this.formData.pharmacyEmailId,
          pharmacyFaxNo: this.formData.pharmacyFaxNo,
        },
        miscKnownAllergies1: this.formData.miscellaenousKnownAllergies,
        knowDoctor: this.formData.knowDoctor,
      },
      submitInfo: {
        iAcknowledge: this.formData.iAcknowledge,
        iAccept: this.formData.iAccept,
        patientSign: this.formData.patientSign ? this.formData.patientSign : ''
      }
    };
  }

  formArrayCalendarMassage(data) {
    if (data && Array.isArray(data)) {
      data.forEach(each => {
        for (const key in each) {
          if (Object.prototype.hasOwnProperty.call(each, key)) {
            if (key === 'prescriptionStartDate' || key === 'prescriptionEndDate' || key === 'lastPrescriptionDate') {
              each[key] = this.formatDate(each[key]);
            }
          }
        }
      });
      return data;
    }
  }

  // Reseting the forms Values in this functionality
  resetFormValues(formType?) {
    if (this.formType === 'newUser') {
      this.newUserFormTags.forEach((element, index) => {
        this.formService[this.formService.formTypes[element].config] = this.formBackUp[index];
        if (element === 'MEDICALHISTORY') {
          this.formService[this.formService.formTypes[element].config].forEach(form => {
            if (form.controlType === 'formArray') {
              form.formInputs.forEach(eachArr => {
                eachArr.forEach(obj => {
                  if (obj.key === 'discontinued') {
                    obj.value = false;
                  } else {
                    obj.value = '';
                  }
                });
              });
            }
          });
        }
      });
    } else if (this.formType === 'existingUser') {
      this.existingUserFormTags.forEach((element, index) => {
        this.formService[this.formService.formTypes[element].config] = this.formBackUp[index];
        if (element === 'EXISTINGUSER_MEDICALHISTORY') {
          this.formService[this.formService.formTypes[element].config].forEach(form => {
            if (form.controlType === 'formArray') {
              form.formInputs.forEach(eachArr => {
                eachArr.forEach(obj => {
                  if (obj.key === 'discontinued') {
                    obj.value = false;
                  } else {
                    obj.value = '';
                  }
                });
              });
            }
          });
        }
      });
    } else if (this.formType === 'offlinePatient') {
      this.offlineFormTags.forEach((element, index) => {
        this.formService[this.formService.formTypes[element].config] = this.formBackUp[index];
        if (element === 'NEWUSER_MEDICALHISTORY') {
          this.formService[this.formService.formTypes[element].config].forEach(form => {
            if (form.controlType === 'formArray') {
              form.formInputs.forEach(eachArr => {
                eachArr.forEach(obj => {
                  if (obj.key === 'discontinued') {
                    obj.value = false;
                  } else {
                    obj.value = '';
                  }
                });
              });
            }
          });
        }
      });
    }
  }

  // Navigating Back to Patient list page
  navigateBack(event) {
    if (event) {
      this.resetFormValues();
      this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.PATIENTS}`]);
    }
  }

  // Previous button functionality
  prevForm() {
    if (this.currentFormIndex === 0) {
      this.scrollTop();
      this.stepDetails[this.currentFormIndex].onPage = false;
      this.currentFormIndex = 0;
      this.setCurrentForm(this.currentFormIndex);
      this.focusFirstFieldInSafari();
    } else {
      this.stepDetails[this.currentFormIndex].onPage = false;
      this.currentFormIndex--;
      this.scrollTop();
      this.setCurrentForm(this.currentFormIndex);
      this.focusFirstFieldInSafari();
    }
  }

  // Setting the current stepper details
  setCurrentForm(index) {
    this.stepDetails[index].completed = false;
    this.stepDetails[index].onPage = true;
  }

  // Form scroll to Top when the page is loaded from another page
  scrollTop() {
    if (document.getElementsByClassName('new-pateint__section')[0]) {
      document.getElementsByClassName('new-pateint__section')[0].scrollTo(0, 0);
    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  // Mobile forward button clicked actions and form saving
  forwardClicked() {
    this.saveForm();
  }

  // Mobile back button clicked actions and form saving
  backClicked() {
    this.prevForm();
  }


  saveForm() {
    this.validateEmail(this.searchInput);
    setTimeout(() => {
      if (document.getElementsByClassName('invalid-feedback')[0]) {
        document.getElementsByClassName('invalid-feedback')[0].scrollIntoView({ block: 'center' });
      }
    }, 2);
    if ((this.formType === 'existingUser' && this.isValidEmail) || this.formType !== 'existingUser') {
      if (!this.isMobile) {
        this.saveFormData = true;
      } else {
        this.saveFormData = true;
        if (this.currentFormIndex === (this.stepDetails.length - 1)) {
          this.scrollTop();
          this.focusFirstFieldInSafari();
        } else {
          window.setTimeout(() => {
            if (this.stepDetails[this.currentFormIndex].completed || window.location.href.split('&')[1] === 'view=true') {
              this.currentFormIndex++;
              this.stepDetails[this.currentFormIndex].onPage = true;
              this.stepDetails[this.currentFormIndex].notVisisted = false;
              this.stepDetails[this.currentFormIndex].visited = true;
              this.scrollTop();
              this.focusFirstFieldInSafari();
            }
          }, 5);
        }
      }
      setTimeout(() => {
        var isBelowThreshold = (currentValue) => currentValue.completed === true;
        if (this.stepDetails.every(isBelowThreshold)) {
          if (this.formType === 'newUser') {
            this.massageFormData(this.newUser);
          } else if (this.formType === 'existingUser') {
            this.massageFormData(this.existingUser);
          } else if (this.formType === 'offlinePatient') {
            this.massageFormData(this.form);
          }
          this.uploadFiles(() => {
            if (this.isEditAndView) {
              if (JSON.stringify(this.finalFormData) === JSON.stringify(this.editCopy)) {
                this.cancelPopupShowHide = true;
              } else {
                this.createPatient();
              }
            } else {
              this.createPatient();
            }
          });
        }
        setTimeout(() => {
          if (document.getElementsByClassName('invalid-feedback')[0]) {
            document.getElementsByClassName('invalid-feedback')[0].scrollIntoView({ block: 'center' });
          }
        }, 2);
      }, 10);
    }
  }

  // This will do Upload API call when one file is uploaded
  uploadFiles(callback) {
    let uploadFiles = {
      // LabWorkDocsUpload: this.finalFormData.medicalHistory.LabWorkDocsUpload,
      idProofUploadImage1: this.finalFormData.patientBasicInfo.idProofUploadImage1,
      idProofUploadImage2: this.finalFormData.patientBasicInfo.idProofUploadImage2,
      policyHoldersUploadFront: this.finalFormData.insuranceDetails.policyHolder.policyHoldersUploadFront,
      policyHoldersUploadBack: this.finalFormData.insuranceDetails.policyHolder.policyHoldersUploadBack,
      secondaryPolicyHoldersUploadFront: this.finalFormData.insuranceDetails.policyHolder.secondaryPolicyHoldersUploadFront,
      secondaryPolicyHoldersUploadBack: this.finalFormData.insuranceDetails.policyHolder.secondaryPolicyHoldersUploadBack
    };
    Object.keys(uploadFiles).forEach(key => {
      if (uploadFiles[key] && uploadFiles[key].name) {

      } else {
        delete uploadFiles[key];
      }
    });
    let fileNames = Object.keys(uploadFiles).map(key => {
      let type;
      if (!uploadFiles[key].type) {
        type = uploadFiles[key].name.split('.').pop().toLowerCase();
      }
      return { key, contentType: uploadFiles[key].type || type, name: uploadFiles[key].name };
    });
    if (fileNames && fileNames.length) {
      this.observableHelperService.toggleThrobber(true);
      let getUploadUrls = {
        type: 'POST',
        url: 'getUploadUrl',
        isDeveloper: true,
        donotShowLoader: true,
        body: {
          fileNames,
          userId: this.patientDetails.userId,
          userName: 'temp-new-tele-doc/' + this.accentedtoNormalFun(this.patientDetails.firstName) + this.accentedtoNormalFun(this.patientDetails.lastName),
          patientName: this.accentedtoNormalFun(this.finalFormData.patientBasicInfo.firstName) + this.accentedtoNormalFun(this.finalFormData.patientBasicInfo.lastName) + '__' + this.finalFormData.patientBasicInfo.dob
        },
      };

      this.http.makeHttpRequest(getUploadUrls).subscribe(res => {
        let filesUploaded = 0;

        Object.keys(res).forEach(key => {
          let data;
          if (key === 'LabWorkDocsUpload') {
            data = this.finalFormData.medicalHistory.LabWorkDocsUpload;
          } else if (key === 'idProofUploadImage1' || key === 'idProofUploadImage2') {
            data = this.finalFormData.patientBasicInfo[key];
          } else {
            data = this.finalFormData.insuranceDetails.policyHolder[key];
          }
          if (data && data.name) {
            let uploadPayload = {

              type: 'PUT',
              url: res[key],
              body: data,
              isExternal: true,
              donotShowLoader: true,
              contentType: data.type || data.name.split('.').pop().toLowerCase()
            };
            this.http.makeHttpRequest(uploadPayload).subscribe(response => {
              if (key === 'LabWorkDocsUpload') {
                this.finalFormData.medicalHistory.LabWorkDocsUpload = res[key];
              } else if (key === 'idProofUploadImage1' || key === 'idProofUploadImage2') {
                this.finalFormData.patientBasicInfo[key] = res[key];
              } else {
                this.finalFormData.insuranceDetails.policyHolder[key] = res[key];
              }
              filesUploaded++;

              if (filesUploaded === fileNames.length) {
                callback();
              }

            });
          }
        });
      });
    } else {

      callback();
    }
  }

  // This will return 'Next','Save' Texts based on steppper index in mobile
  getActionText() {
    if (this.isMobile) {
      return this.currentFormIndex < (this.stepDetails.length - 1) ? 'Next' : 'Save';
    } else {
      return 'Save';
    }
  }

  // Toggling the saveFormData once form is submited
  resetSaveFormParity() {
    window.setTimeout(() => {
      this.saveFormData = false;
    }, 0);

    return true;
  }


  // This function will get index of clicked stepper and saving the form
  getStepIndex(index) {
    if (index > this.currentFormIndex) {

      this.saveFormData = true;
      this.saveForm();
    } else {
      this.scrollTop();
      this.focusFirstFieldInSafari();
      this.stepDetails[this.currentFormIndex].onPage = false;
      this.currentFormIndex = index;
      this.stepDetails.forEach((each, ind) => {
        if (ind >= this.currentFormIndex) {
          each.completed = false;
        }
      });
      this.stepDetails[index].onPage = true;
    }

  }

  // This is radio button functionality
  getRadioButtonSelectValue(formData, value) {

    formData.fields.map((data, inedx) => {
      data.checked = false;
      data.checked = data.value === value;
    });
  }
  // This will do AddNew functionality
  addNew(data) {
  }
  // This will do the assigning form values to the form inputs
  editAndViewDataFn(data) {
    let nonEditableFields = ['firstName', 'lastName', 'dob']; // 'socialSecurityNumber'
    let newlyAddedFields = ['heightFt', 'heightInch', 'weight', 'emergencyContactAddress', 'anyMedicalDisorders', 'anyMedicalDisordersExplain', 'pastHistoryofAlchohol', 'pastHistoryofAlchoholExplain', 'nonPsychMeds', 'nonPsychMedsExplain', 'anyOpiods', 'anyOpiodsExplain', 'patientHospitalized', 'patientHospitalizedReason', 'nameOfHospital', 'patientHospitalizedDate', 'previousPrescriber', 'prescriberName', 'prescriberAddress', 'prescriberEmail', 'therapistMail', 'prescriberPhone', 'sendTherapist', 'sendPrescriber', 'patientHaveTherapistNotes', 'patientHavePrescriberNotes', 'prescriberDocs', 'therapistDocs'];
    data.map((value, index) => {
      let inputs = value.formInputs;
      let editKeys = Object.keys(this.editData);
      inputs.forEach((element, id) => {
        if (element.controlType === 'formArray') {
          if (this.editData[element.key] && Array.isArray(this.editData[element.key])) {
            if (this.editData[element.key].length > 1) {
              for (let i = 0; i < this.editData[element.key].length - 1; i++) {
                this.childComponent.addNew(inputs[id + 1], inputs[id + 1].formtag);
              }
            }
            let formInputs = element.formInputs;
            formInputs.forEach((innerElement, ind) => {
              innerElement.forEach((innerObj, innerInd) => {
                if (innerObj.type === 'textarea' && this.editData[element.key][ind][innerObj.key].length > 0) {
                  let valuedata = this.editData[element.key][ind][innerObj.key];
                  let valueLength = valuedata.split('').length;
                  if (valueLength > innerObj.maxLength) {
                    valuedata = valuedata.substring(0, innerObj.maxLength);
                    return false;
                  } else {
                    innerObj.count = `${innerObj.maxLength - valueLength} remaining`;
                  }
                }
                if (innerObj.type === 'calendar') {
                  if (this.editData[element.key][ind][innerObj.key] !== '') {
                    innerObj.value = this.calendarDateFormate(this.editData[element.key][ind][innerObj.key]);
                    // console.log(innerObj.value);
                  } else {
                    innerObj.value = '';
                  }
                } else {
                  innerObj.value = this.editData[element.key][ind][innerObj.key];
                }
                innerObj.editable = !this.editable;
                if (innerObj.key === 'prescriptionEndDate') {
                  let radioValue = innerElement[innerInd - 2]['value'];
                  if (radioValue.toLowerCase().includes('current')) {
                    innerObj.editable = false;
                  }
                }
              });
            });
            element.formInputs = formInputs;
          }
        } else {
          if(element.key === 'firstName' || element.key === 'lastName'){
            value.formGroup.controls[element.key].clearValidators();
            value.formGroup.controls[element.key].setValidators([Validators.required,this.formService.validateNameUpdated]);
            value.formGroup.controls[element.key].updateValueAndValidity();
          }
          element.value = this.editData[element.key] ? this.editData[element.key] : '';
          element.selected = element.value;
          element.showImage = false;
          element.editable = (nonEditableFields.includes(element.key)) ? false : !this.editable;
          if (element.key === 'iAcknowledge' || element.key === 'iAccept' || (element.key === INTAKE_FORM_KEYS.PATIENT_SIGN && !this.offLineStatus)) {
            element.editable = false;
          }
          if(element.key === INTAKE_FORM_KEYS.PATIENT_SIGN && !this.offLineStatus){
            element.validators = [];
            value.formGroup.controls[INTAKE_FORM_KEYS.PATIENT_SIGN].status = "VALID";
            value.formGroup.controls[INTAKE_FORM_KEYS.PATIENT_SIGN].clearValidators();
            value.formGroup.controls[INTAKE_FORM_KEYS.PATIENT_SIGN].updateValueAndValidity();
            value.formGroup.controls[INTAKE_FORM_KEYS.PATIENT_SIGN].setErrors(null);
          }
          let uploadKeys = ['LabWorkDocsUpload', 'idProofUploadImage1', 'idProofUploadImage2', 'policyHoldersUploadFront', 'policyHoldersUploadBack', 'secondaryPolicyHoldersUploadFront', 'secondaryPolicyHoldersUploadBack'];
          if(uploadKeys.includes(element.key) && !this.offLineStatus){
            element.validators = [];
            element.required = false;
            value.formGroup.controls[element.key].status = "VALID";
            value.formGroup.controls[element.key].clearValidators();
            value.formGroup.controls[element.key].updateValueAndValidity();
            value.formGroup.controls[element.key].setErrors(null);
          }
        }
        if (element.key === 'firstName' || element.key === 'lastName') {
          element.validators = [];
          element.validators = [Validators.required];
        }
        if (element.type === 'dropdown') {
          this.childComponent.getDropValue(element, id, inputs, value.formGroup);
        }
        if (element.type === 'textarea' && element.showRange && this.editData[element.key].length > 0) {
          let valuedata = this.editData[element.key];
          let valueLength = valuedata.split('').length;
          // let countfield = <HTMLInputElement>document.getElementById(element.key)
          element.count = `${element.maxLength - valueLength} remaining`;
          // countfield.value = `${element.maxLength - valueLength} remaining`;
        }
        if (element.isToggle) {
          if (element.value) {
            this.childComponent.getSliderValue(element, id, inputs, value.formGroup);
          }
        }
        if (newlyAddedFields.includes(element.key) && (this.editData[element.key] === '' || this.editData[element.key] === undefined || this.editData[element.key] === null || !this.editData[element.key].length) ) {
            // value.formGroup.controls[element.key].status = "VALID";
            if (element.key === 'prescriberDocs' || element.key === 'therapistDocs') {
              value.formGroup.controls[element.key].clearValidators([this.formService.validateFilesUpload]);
            }
            console.log(element.key);
            value.formGroup.controls[element.key].clearValidators([Validators.required]);
            value.formGroup.controls[element.key].updateValueAndValidity();
          }
      });
      value.formInputs = inputs;
      let controls = value.formGroup.controls;
      for (let formData in controls) {
        if (formData === 'medications' || formData === 'benzodiazepines' || formData === 'medicaladderal') {
          /*if (this.editData[formData] != null) {
            controls[formData].setValue(this.editData[formData]);
            controls[formData].patchValue(this.editData[formData]);
          }*/
          return null;
        } else {
          controls[formData].setValue(this.editData[formData]);
          controls[formData].patchValue(this.editData[formData]);
        }
      }
      value.formGroup.controls = controls;

      return { ...value };
    });
    return data;
  }

  /**
  *
  * @param form
  */
  createPatient() {
    // Check if any file upload is in progress.
    if (this.fileUploadProgress <= 0) {
      let type: string;
      let url: string;
      if (this.isEditAndView) {
        type = HTTPMethods.PUT;
        url = API_URLS.ADMIN_EDIT_PATIENT;
      } else {
        url = API_URLS.ADMIN_CREATE_PATIENT;
        type = HTTPMethods.POST;
      }
      let currentDate = new Date().toLocaleString().split(', ');
      this.finalFormData.timestampDate = currentDate[0];
      this.finalFormData.timestamp = currentDate[1].substring(0, currentDate[1].length - 6) + currentDate[1].slice(-3);
      this.finalFormData.formType = !(this.isEditAndView) ? this.formType : undefined;
      this.finalFormData.status = (this.isEditAndView) ? this.editData.status : undefined;
      let payload = {
        type,
        url,
        body: this.finalFormData,
        isDeveloper: true,
        pathVariables: [this.userIdNumber]
      };
      this.http.makeHttpRequest(payload).subscribe((res) => {
        if (this.http.isSuccessfulResponse(res) && res) {
          this.resetFormValues();
          if (payload.type === 'POST') {
            this.observableHelperService.showToast(TOAST_STATUSES.SUCCESS, 'Successfully created patient');
          } else if (payload.type === 'PUT') {
            this.observableHelperService.showToast(TOAST_STATUSES.SUCCESS, 'Successfully updated Patient data');
          }
          this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.PATIENTS}`]);
        } else {
          let message = 'Patient Creation Failed';
          if (this.isEditAndView) {
            message = 'Failed to update Patient data';
          }
          message = (res.error) ? res.error.message : message;
          if(res.error && res.error.message){
            if(res.error.message.includes(INTAKE_FORM_KEYS.THERAPIST_EMAIL) && document.querySelectorAll('.temail__scroll')[0]){
              document.querySelectorAll('.temail__scroll')[0].scrollIntoView({ block: 'center' });
              if(res.error.message.includes(this.mailText)) message = TOAST_MESSAGES.INVALID_THERAPIST_MAIL
            }
            else if(res.error.message.includes(INTAKE_FORM_KEYS.PREV_PRESCRIBER_EMAIL) && document.querySelectorAll('.pemail__scroll')[0]){
              document.querySelectorAll('.pemail__scroll')[0].scrollIntoView({ block: 'center' });
              if(res.error.message.includes(this.mailText)) message = TOAST_MESSAGES.INVALID_PRESCRIBER_MAIL
            }
            else if (res.error && document.querySelectorAll('.email__scroll')[0]) {
              document.querySelectorAll('.email__scroll')[0].scrollIntoView({ block: 'center' });
            }
          }
          this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
        }
      });
    } else {
      this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.FILE_UPLOAD_INPROGRESS_MSG);
    }
  }

  cancelPopupShowHideStatusFun(status) {
    this.cancelPopupShowHide = status.cancelpopup;
  }

  closeConfirmTerms(event) {
    this.showConfirmTerms = false;
  }/* for accented letter change convention function */
  accentedtoNormalFun(name) {
    let originalText = name;
    let result = originalText.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    return result;
  }

  /**
   * File upload start or end.
   * @param event file upload start or finish value - true/false
   */
  isFileUploadPending(event){
    if(event)
    this.fileUploadProgress += 1;
    else this.fileUploadProgress -= 1;
  }


  /**
   * on dropdown value change
   * @param field dropdown field.
   */
  onDropdownChange(field){
    if(field.key == 'pharmacyState' && !this.allowedPharmacyStates.includes(field.value)){
      this.showPharmacyStatePopup = true;
    }
  }

  /**
   * To hide/show the popup
   * @param status popup visibility
   */
  cancelPopup(status) {
    this.showPharmacyStatePopup = status.cancelpopup;
  }

  /**
   * Show/hide uploaded doc list.
   * @param status 
   */
  cancelDocListPopup(status) {
    this.showDocListPopup = status.cancelpopup;
  }

  /**
   * Show uploaded doc list.
   * @param docList 
   */
  showDocs(docList: Array<string>){
    this.docList = docList;
    this.showDocListPopup = true;
  }
}
