import { Component, OnInit } from '@angular/core';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { DataService } from '@app/common/services/data.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { CognitoService } from '@app/common/services/cognito.service';
import { HttpService } from '@app/common/services/http.service';
import { TOAST_STATUSES } from '@app/common/constants/util.constant';

@Component({
  selector: 'app-resheduling-wrapper',
  templateUrl: './resheduling-wrapper.component.html',
  styleUrls: ['./resheduling-wrapper.component.scss']
})
export class ReshedulingWrapperComponent implements OnInit {
  saveFormData: boolean = false;
  isReshedule = true;
  rescheduleForm: any;
  appointemntId;
  user;
  availabiltyData;
  rescheduleFormData;
  pageSlots: number;
  dateIndex: number;
  dateSlotsVisible;
  primaryLocId;
  preferredLocId;
  primaryLocType;
  preferredLocType;
  constructor(
    private formService: CustomFormHandlerService,
    private router: Router,
    private dataservice: DataService,
    private route: ActivatedRoute,
    private observable: ObservableHelperService,
    private cognitoService: CognitoService,
    private http: HttpService,
  ) { }
  ngOnInit() {
    this.cognitoService.getUser().then(user => {
      console.log('user Details', user);
      this.user = user;
      this.route.params.subscribe(params => {
        console.log(params, 'route params');
        if (params && params.appointemntId) {
          this.appointemntId = params.appointemntId;
          this.getAppointment();
        }
      });
    });
    this.formService.resheduleFormConfig.forEach(each => {
      each.value = '';
      // tslint:disable-next-line:no-unused-expression
      if (each.key === 'reshedule_details') { each.count = '150 remaining'; }
    });
  }
  changeStepper(event) {
    console.log(event);
  }
  cancel() {
    this.router.navigate([`${ROUTE_PATHS.ADD_MyACCOUNT}`]);
  }
  getFormData(event) {
    console.log(event);


    if (event) {
      let currentDate = new Date();
      let diffTime: any = (currentDate.getUTCHours() - currentDate.getHours()) + ':' + (currentDate.getUTCMinutes() - currentDate.getMinutes());
      let date = event.slot.date.getDate();
      let month = event.slot.date.getMonth() + 1;
      if (date < 10) {
        date = '0' + date;
      }
      if (month < 10) {
        month = '0' + month;
      }
      if (this.primaryLocId === event.office.value.preffered_location) {
        this.preferredLocType = this.primaryLocType;
      } else {
        this.preferredLocType = event.officeType;
      }
      let year = event.slot.date.getFullYear();
      let rechecduleData = {
        userId: this.user.userId,
        type: 'RESCHEDULE',
        locationId: event.office.value.preffered_location,
        locationType: this.preferredLocType,
        appointmentDate: year + '-' + month + '-' + date,
        appointmentTime: event.slot.time,
        reason: {
          reason: event.reason.value.reason_for_reschedule,
          details: event.reason.value.reshedule_details
        },
        timeDiff: diffTime
      };
      let payload = {
        type: 'PUT',
        url: 'editAappointment',
        isDeveloper: true,
        pathVariables: [this.appointemntId],
        body: rechecduleData
      };
      this.http.makeHttpRequest(payload).subscribe((res) => {
        if (this.http.isSuccessfulResponse(res) && res) {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
          this.dataservice.clearData();
          this.dataservice.setData(this.dataservice.dataObject.RESCHEDULE_DATA, event);
          this.router.navigate([`${ROUTE_PATHS.ADD_MyACCOUNT}`]);
          // this.formService.resheduleFormConfig.forEach(each => {
          //   each.value = '';
          //   // tslint:disable-next-line:no-unused-expression
          //   if (each.key === 'reshedule_details') { each.count = '150 remaining'; }
          // });
          if (this.preferredLocType === 'online' && this.primaryLocType !== this.preferredLocType) {
            this.observable.showConfirmation('rescheduledConfirm', 'Thanks for rescheduling. To proceed further, please confirm your appointment.');
          } else {
            this.observable.showConfirmation('rescheduled', ' Your appointment has been rescheduled!!');
          }
        } else {
          let message = 'Failed to rescheduled an appointment. Please try again';
          message = (res.error) ? res.error.message : message;
          this.observable.showToast(TOAST_STATUSES.ERROR, message);
        }
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      });
    }
  }

  getAppointment() {
    let payload = {
      type: 'GET',
      url: 'getSingleAppointment',
      isDeveloper: true,
      pathVariables: [this.appointemntId, this.user.userId]
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res) && res) {
        console.log('appointment data', res.data);
        this.primaryLocId = res.data.locationId;
        this.primaryLocType = res.data.location.locationType;
        this.getAvailabiltySlots(res.data);
      } else {
        let message = 'Failed to fetch appointment data. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
  customizeSchedular(innerWidth, days) {
    let width = innerWidth;
    if (width > 1024) {
      this.pageSlots = 4;
      this.dateIndex = days / this.pageSlots;
      this.dateSlotsVisible = 8;
    } else if (width <= 1024 && width > 768) {
      this.pageSlots = 6;
      this.dateIndex = (days === 40) ? Math.floor(days / this.pageSlots) + 1 : Math.floor(days / this.pageSlots) + 2;
      this.dateSlotsVisible = 6;

    } else if (width <= 786 && width > 550) {
      this.pageSlots = 5;
      this.dateIndex = (days === 40) ? Math.floor(days / this.pageSlots) + 1 : Math.floor(days / this.pageSlots) + 3;
      this.dateSlotsVisible = 5;
    } else if (width <= 550) {
      this.pageSlots = 4;
      this.dateIndex = (days === 40) ? Math.floor(days / this.pageSlots) + 2 : Math.floor(days / this.pageSlots) + 4;
      this.dateSlotsVisible = 4;
    }
  }
  getAvailabiltySlots(data) {
    let time = Number(data.appointmentTime.slice(0, 2));
    let beginTime;
    let endTime;
    let value;
    if (time >= 6 && time <= 11) {
      beginTime = '06:30:00';
      endTime = '11:55:00';
      value = 1;
    } else if (time >= 12 && time <= 15) {
      beginTime = '12:00:00';
      endTime = '15:55:00';
      value = 2;

    } else if (time >= 16 && time <= 17) {
      beginTime = '16:00:00';
      endTime = '17:55:00';
      value = 3;
    } else if (time >= 18 && time <= 19) {
      beginTime = '18:00:00';
      endTime = '19:55:00';
      value = 4;
    } else if (time >= 20 && time <= 24) {
      beginTime = '20:00:00';
      endTime = '23:30:00';
      value = 5;
    }
    this.rescheduleFormData = {
      locationId: data.locationId,
      appointmentTypeId: data.appointmentTypeId,
      slot: value,
      appointmentTime: data.appointmentTime,
      patient: data.patient
    };
    let todaydate: any = new Date().getDate();
    let todaymonth: any = new Date().getMonth() + 1;
    if (todaydate < 10) {
      todaydate = '0' + todaydate;
    }
    if (todaymonth < 10) {
      todaymonth = '0' + todaymonth;
    }
    let year = new Date().getFullYear();

    let params = {
      appointmentTypeId: data.appointmentTypeId,
      locationId: data.locationId,
      beginTime,
      endTime,
      // startDate: year + '-' + todaymonth + '-' + todaydate,
      days: (data.appointmentTypeId === 3 || data.appointmentTypeId === 4) ? 64 : 40,
      metadata: true
    };
    // console.log('start date in specific version', params.startDate);
    let payload = {
      type: 'GET',
      url: 'availability',
      isDeveloper: true,
      params
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res) && res) {
        let availability = res.data.availability.calendar;
        this.customizeSchedular(window.innerWidth, availability.length);
        let dateIndex;
        let timeIndex;
        let timeSlots;

        availability.forEach((row, index) => {
          if (row.date === data.appointmentDate) {
            dateIndex = index;
            row.slots.forEach((slot, slotIndex) => {
              if (slot.time === data.appointmentTime) {
                timeIndex = slotIndex;
              }
            });
            timeSlots = row.slots.length;
          }
        });
        if (dateIndex === (availability.length - 1 - (Math.floor(availability.length / (this.dateSlotsVisible - 1)) - 1))) {
          dateIndex = dateIndex - (this.dateSlotsVisible - 1);
        }
        let dateStart: any = dateIndex === this.dateSlotsVisible - 1 ? 0 : (dateIndex - (dateIndex % (this.dateSlotsVisible - 1))) / (this.dateSlotsVisible - 1);
        if (timeSlots > 9) {
          timeSlots = 9;
        }
        timeIndex = (timeIndex - (timeIndex % (timeSlots))) / (timeSlots);
        let appointmentDate: any = this.getLocalDate(data.appointmentDate);
        dateIndex = this.dateSlotsVisible === 3 && Math.ceil(dateIndex / this.dateSlotsVisible) !== dateIndex / this.dateSlotsVisible ? Math.ceil(dateIndex / this.dateSlotsVisible) : Math.ceil(dateIndex / this.dateSlotsVisible) - 1;
        if (availability.length - 1 === dateStart * (this.dateSlotsVisible - 1)) {
          dateStart = dateStart - 1;
        }

        let startedReschedule = (availability[dateStart * (this.dateSlotsVisible - 1)].date);
        let shedularData: any = {
          dateArray: this.getLocalDate(startedReschedule),
          dateIndex: dateStart,
          selectedSlot: {
            date: appointmentDate,
            status: 'selected',
            time: data.appointmentTime
          },
          timeIndex
        };
        console.log('reschedular start date', startedReschedule, shedularData.dateArray, (availability[dateStart * (this.dateSlotsVisible - 1)].date), );

        if (!this.dataservice.data[this.dataservice.dataObject.APPOINTMENT_DETAIL]) {
          this.dataservice.data[this.dataservice.dataObject.APPOINTMENT_DETAIL] = {};
        }
        this.dataservice.data[this.dataservice.dataObject.APPOINTMENT_DETAIL].shedularData = shedularData;
        this.availabiltyData = res.data;
        console.log(res.data, 'avalabityData');
      } else {
        let message = 'Failed to fetch availability data. Please try again';
        message = (res.error) ? res.error.message : message;
        this.observable.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  getLocalDate(date) {
    let newDate: any = new Date(date);
    let offset = new Date().getTimezoneOffset();
    newDate = new Date(newDate.getTime() + (offset * 60 * 1000));
    return newDate;
  }

  saveForm() {
    this.saveFormData = true;
    setTimeout(() => {
      if (document.getElementsByClassName('invalid-feedback')[0]) {
        document.getElementsByClassName('invalid-feedback')[0].scrollIntoView({ block: 'center' });
      }
    }, 2);
  }
  resetSaveFormParity() {
    window.setTimeout(() => {
      this.saveFormData = false;
    }, 0);
    return true;
  }


}
