import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { HttpService } from '@app/common/services/http.service';
import { TOAST_STATUSES } from '@app/common/constants/util.constant';
import { CustomFormComponent } from '@app/common/components/custom-form/custom-form.component';
import { CognitoService } from '@app/common/services/cognito.service';
import { DOCUMENT, formatDate } from '@angular/common';

@Component({
  selector: 'app-edit-admin-profile',
  templateUrl: './edit-admin-profile.component.html',
  styleUrls: ['./edit-admin-profile.component.scss']
})
export class EditAdminProfileComponent implements OnInit {
  breadCrumbData = {
    link: '/admin/admin-profile',
    linkText: 'Edit Profile'
  };
  formGroup: any; // Form group
  formInputs: any; // Form inputs
  defaultFormInputs: any; // Form inputs
  isEditAndView = false; // Edit mode
  finalFormData: any = {}; // Sending final obj when post call
  employeeUserID: any; // geting employee id number
  sendFiledByCountry: any = {}; // Send fileds to back end based user select us or india
  allUploads: any = {};
  editData: any; // Edit data obj with controls
  getEmpId: any; // featching user id  based on routing
  editable: boolean = false; // Editable data flag
  showDiv: boolean = false;
  uploadsList = [];
  profileLink: any;
  childComponent = new CustomFormComponent(this.formServices, this.formBuilder, this.http, this.cognitoService, this.observableHelperService, this.route, this.router);

  constructor(@Inject(DOCUMENT) private document: Document, private formBuilder: FormBuilder, private formServices: CustomFormHandlerService,
              private HelperService: ObservableHelperService, private route: ActivatedRoute, private http: HttpService, private cognitoService: CognitoService, private router: Router, private observableHelperService: ObservableHelperService, private el: ElementRef) {
    this.isEditAndView = (this.route.snapshot.queryParams.edit === 'true') ? true : false;
    this.getEmpId = this.route.snapshot.queryParams.id;
    this.formInputs = this.formServices.initForm(this.formServices.formTypes.EMPCREATION);
    this.formInputs.formInputs = this.formInputs.formInputs.filter(obj => obj.key !== 'empId' && obj.label !== 'Permissions' && obj.type !== 'groupCheckbox');
  }
  // This will do form submission
  ngOnInit() {
    this.getPermistionsList();
    this.getLocationsList();
  }
  /**
  *
  * @param form
  */
  createEmployee() {

    let payload = {
      type: 'PUT',
      url: 'adminProfileEdit',
      body: this.finalFormData,
      isDeveloper: true,
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res) && res) {
        // this.resetFormValues();

          this.HelperService.showToast(TOAST_STATUSES.SUCCESS, 'Successfully updated employee data');
          this.resetcheckBoxesAnduploads();
          setTimeout(() => {
          this.router.navigate(['/admin/employee']);
        }, 1000);
      } else {
        let message = res.message ? res.message : 'Employee Creation Failed';
        this.HelperService.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  // This will do form sumbission call
  employeeFormSubmit(event?) {
    console.log('entered');
    this.sendFiledByCountry = this.getCountry(event.controls['country'].value, event.controls);
    this.allUploads = (this.isEditAndView !== true) ? { uploads: this.setUploads(event.controls['uploads'].value), picture: this.profileLink, empId: '', ...this.sendFiledByCountry } : {};
    console.log(this.sendFiledByCountry);
    this.sendingData(event.controls);
    console.log(this.finalFormData);
    this.createEmployee();
  }
  inValidSubmitEvent(event) {
    this.scrollToFirstInvalidControl();
  }

  scrollToFirstInvalidControl() {
    let firstInvalidControl = this.el.nativeElement.querySelector('form .ng-invalid');
    if (firstInvalidControl === null) {
      firstInvalidControl = document.getElementsByClassName('invalid-feedback');
      console.log(firstInvalidControl);
      firstInvalidControl[0].scrollIntoView({ block: 'center' });
    } else {
      let commonHeight = $('.common__header--section').outerHeight();
      let invalidTop = $(firstInvalidControl).offset().top - (commonHeight + 40);
      $('html,body').animate({ scrollTop: invalidTop }, 100, () => $('html,body').stop(true, false));
      firstInvalidControl.focus();
    }
  }

  // This will do if category selected all sub options will be slected and vice-versa
  categorySelect(event, index) {
    this.formInputs[index].isSelected = event.checked;
    this.formInputs[index].isIntermediate = event.checked;
    this.formInputs[index].options = this.formInputs[index].options.map((each) => {
      return {
        ...each,
        isSelected: this.formInputs[index].isSelected,
      };
    });
    this.formGroup.controls[this.formInputs[index].key].setValue(this.setGroupcheck(this.formInputs[index]));
  }

  // This set formGroup values which is selected
  setGroupcheck(config) {
    let checkedGroup = [];
    if (config && typeof (config) === 'object') {
      config.options.forEach(each => {
        if (each.isSelected === true) {
          checkedGroup.push(each.id);
        }
      });
    }
    return checkedGroup;
  }

  // This will do if option selected or unselected category will be unselected
  optionSelect(event, taskId, categoryId) {
    this.formInputs[categoryId].options[taskId].isSelected = event.checked;
    let optionsCount = this.formInputs[categoryId].options.length;
    let count = 0;
    this.formInputs[categoryId].options.forEach((each) => {
      if (each.isSelected === true) {
        count++;
      }
    });
    if (count > 0 && count < optionsCount) {
      this.formInputs[categoryId].isIntermediate = true;
    } else {
      this.formInputs[categoryId].isIntermediate = false;
    }
    if (count === optionsCount) {
      this.formInputs[categoryId].isSelected = true;
      this.formInputs[categoryId].isIntermediate = false;
    } else {
      this.formInputs[categoryId].isSelected = false;
    }
    this.formGroup.controls[this.formInputs[categoryId].key].setValue(this.setGroupcheck(this.formInputs[categoryId]));
  }


  // Show upload
  showUpload(event, field?) {
    field.value = '';
    this.formGroup.controls[field.key].value = '';
    field.showImage = true;
  }
  sendingData(data) {
    this.finalFormData = {
      mobile: data.mobile.value,
      address: data.address.value,
      zip: data.zip.value,
    };
  }

  setUploads(data) {
    let uploads = [];
    data.forEach(eachUploads => {
      uploads.push(eachUploads.docUrl);
    });
    return uploads;
  }

  // get country name form the resident in dropdown
  getCountry(data, formData) {
    let valueByCountry;
    if (data.toLowerCase() === 'india') {
      return valueByCountry = {
        pan: formData.pan.value,
        aadhar: formData.aadhar.value,
      };
    } else if (data.toLowerCase() === 'us') {
      if (this.isEditAndView) {
        return valueByCountry = {
          drivingLicense: formData.drivingLicense.value,
        };
      } else {
        return valueByCountry = {
          ssn: formData.ssn.value,
          drivingLicense: formData.drivingLicense.value,
        };
      }
    }
  }

  // Get the permisstion list
  getpermissions(permission1, permission2, permission3, permission4) {
    let finalPermissions = [];
    finalPermissions = finalPermissions.concat(permission1, permission2, permission3, permission4);
    console.log(finalPermissions);
    return finalPermissions = finalPermissions.filter((item, index, inputArray) => inputArray.indexOf(item) === index);
  }



  // get Employee data based on ID
  getEmpDataById(id) {
    const payloadData = {
      type: 'GET',
      url: 'adminProfile',
      isDeveloper: true,
      pathVariables: [id]
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      // this.getPermistionsList();
      if (this.http.isSuccessfulResponse(res)) {
        // console.log('Response', res);
        this.uploadsList = this.massageUploads(res.data.employeeDetails.extraUploads.filter(obj => obj.uploadType === 'employee'));
        this.editData = this.massageEditData(res.data);
        this.employeeUserID = res.data.employeeDetails.empId;
        console.log(this.formInputs);
        this.formInputs = this.setEditData(this.formInputs);
        this.showDiv = true;
      } else {
        console.log('Response', res);
        this.HelperService.showToast(TOAST_STATUSES.ERROR, 'Error in getting employee details');
      }
    });
  }

  // Massaging geting data
  massageEditData(data) {
    let editData = { picture: data.employeeDetails.extraUploads.filter(obj => obj.uploadType === 'profile') };
    for (let key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        let value = data[key];
        if (typeof (value) === 'object' && !Array.isArray(value)) {
          for (let innerkey in value) {
            if (Object.prototype.hasOwnProperty.call(value, innerkey)) {
              let innerValue = value[innerkey];
              if ((typeof (innerValue) === 'object' && !Array.isArray(innerValue))) {
                for (let inner2Key in innerValue) {
                  if (Object.prototype.hasOwnProperty.call(innerValue, inner2Key)) {
                    editData[inner2Key] = innerValue[inner2Key];
                  }
                }
              } else {
                editData[innerkey] = innerValue;
                if (innerkey === 'dob') {
                  let date = formatDate(new Date(new Date(innerValue)), 'yyyy-MM-dd', 'en');
                  editData[innerkey] = this.calendarDateFormate(date);
                }
                if (innerkey === 'extraUploads') {
                  editData[innerkey] = this.uploadsList;
                }
              }
            }
          }
        } else {
          editData[key] = value;
        }
      }
    }
    console.log('massed Edit data', editData);
    return editData;
  }

  // Seting the editdata to all fields
  setEditData(data) {
    var EditableFields = ['mobile', 'zip', 'address', 'uploads', 'picture', ];
    let inputs = data.formInputs;
    let country = this.editData['country'];
    let editKeys = Object.keys(this.editData);
    inputs.forEach((element, ind) => {
      element.value = this.editData[element.key];
      element.selected = true;
      element.showontoggle = true;
      element.showImage = false;
      element.editable = (EditableFields.includes(element.key)) ? true : false;
      if (element.type === 'calendar') {
        if (this.editData[element.key] !== '') {
          element.value = this.editData[element.key];
        } else {
          element.value = '';
        }
      }
      if (element.type === 'dropdown') {
        this.childComponent.getDropValue(element, ind, inputs, data.formGroup);
      }
      if (element.key === 'uploads') {
        element.isEditAndView = true;
        element.value = this.editData['extraUploads'];
      }
      if (element.key === 'workingLocation') {
        this.assignCheckboxes(this.editData[element.key], element);
      }
      if (element.key === 'country') {
        element.editable = false;
      }
      if (element.key === 'scheduling' || element.key === 'reporting' || element.key === 'feedbackhistory' || element.key === 'claimsbilling') {
        this.editPermissions(element);
        this.selectCategory(element);
      }
      if (element.key === 'empId') {
        element.showontoggle = false;
        element.validators = [];
      }
      if (element.controlType === 'profile') {
        element.initialImage = this.editData[element.key][0].docUrl;
        element.value = this.editData[element.key][0].docUrl;
        this.profileLink = element.value;
        element.isEditAndView = true;
      }
      if (element.key === 'zip' || element.key === 'ssn' || element.key === 'drivingLicense' || element.key === 'aadhar' || element.key === 'pan') {
        element.editable = (element.key !== 'zip') ? false : true;
      }
      {
        if (country && country.toLowerCase() === 'india') {
          if (element.key === 'zip') {
            element.label = 'PIN Code';
            element.emptyValueMessage = 'Please enter pin code';
            element.validationValue = 'Please enter a valid 6 digit pin code';
            element.validators = [Validators.required, this.formServices.validatePIN];
          }
          if (element.key === 'drivingLicense' || element.key === 'ssn') {
            element.showontoggle = false;
            element.value = '';
            element.validators = [];
          }
        }
        if (country && country.toLowerCase() === 'us') {
          if (element.key === 'ssn') {
            element.placeholder = element.value;
          }
          if (element.key === 'zip') {
            element.label = 'ZIP Code';
            element.emptyValueMessage = 'Please enter zip code';
            element.validationValue = 'Please enter a valid 5 digit zip code';
            element.validators = [Validators.required, this.formServices.validateZIP];
          }
          if (element.key === 'aadhar' || element.key === 'pan') {
            element.showontoggle = false;
            element.value = '';
            element.validators = [];
          }
        }
      }
    });
    data.formInputs = inputs;
    let controls = data.formGroup.controls;
    // tslint:disable-next-line: forin
    for (let formData in controls) {
      if (formData === 'country' && country) {
        controls['zip'].clearValidators();
        if (country.toLowerCase() === 'india') {
          controls['drivingLicense'].clearValidators();
          controls['aadhar'].setValidators([Validators.required, this.formServices.validateAadhar]);
          controls['pan'].setValidators([Validators.required, this.formServices.validatePan]);
          controls['aadhar'].updateValueAndValidity();
          controls['pan'].updateValueAndValidity();
          controls['zip'].setValidators([Validators.required, this.formServices.validatePIN]);
          controls['zip'].updateValueAndValidity();
        }
        if (country.toLowerCase() === 'us') {
          controls['aadhar'].clearValidators();
          controls['pan'].clearValidators();
          controls['drivingLicense'].setValidators([Validators.required, this.formServices.validateAplhaNumeric]);
          controls['drivingLicense'].updateValueAndValidity();
          controls['zip'].setValidators([Validators.required, this.formServices.validateZIP]);
          controls['zip'].updateValueAndValidity();
        }
      }
      if (formData === 'picture') {
        controls[formData].setErrors(null);
        controls[formData].clearValidators();
        controls[formData].updateValueAndValidity();
        controls[formData].setValue(this.editData[formData]);
        controls[formData].patchValue(this.editData[formData]);
      }
      if (formData === 'empId') {
        controls[formData].clearValidators();
        controls[formData].updateValueAndValidity();
        controls[formData].setValue(this.editData['internalId']);
        controls[formData].patchValue(this.editData['internalId']);
      }
      if (formData === 'uploads') {
        controls[formData].setValue(this.editData['extraUploads']);
        controls[formData].patchValue(this.editData['extraUploads']);
      }
    }
    data.formGroup.controls = controls;
    console.log(data);
    return { ...data };
  }
  calendarDateFormate(date) {
    let dates = date.split('-');
    return dates[1] + '/' + dates[2] + '/' + dates[0];
  }
  editPermissions(field) {
    field.value = [];
    let permissions = this.editData['employeePermissions'];
    field.options.forEach(element => {
      permissions.forEach(permissionsobj => {
        if (permissionsobj.permission_id === element.id) {
          element.isSelected = true;
          field.value.push(element.id);
          this.formInputs.formGroup.controls[field.key].setValue(field.value);
          this.formInputs.formGroup.controls[field.key].patchValue(field.value);
        }
      });
    });
  }

  // Select category value
  selectCategory(ele) {
    ele.isSelected = false;
    var eleLenght = ele.options.length;
    let index = 0;
    ele.options.forEach(option => {
      if (option.isSelected === true) {
        index++;
      }
    });
    if (eleLenght === index) {
      ele.isSelected = true;
    }
  }

  // Assign checkboxes values
  assignCheckboxes(data, field) {
    // console.log("data - "+data)
    // console.log("field - "+JSON.stringify(field))
    field.value = [];
    data.forEach(name => {
      field.options.forEach(obj => {
        if (name.toLowerCase() === obj.name.toLowerCase()) {
          // console.log(name, obj.name)
          field.value.push(obj.id);
          obj.isSelected = true;
          this.formInputs.formGroup.controls[field.key].setValue(field.value);
          this.formInputs.formGroup.controls[field.key].patchValue(field.value);
        }
      });
    });
  }

  // Cancel button actions
  cancelButtonEvent(event) {
    this.resetcheckBoxesAnduploads();
    this.router.navigate(['/admin/employee']);
  }

  // Reaseting all checkboxes on this page
  resetcheckBoxesAnduploads() {
    this.formInputs.formInputs.forEach(eachFiled => {
      if (eachFiled.type === 'groupCheckbox') {
        eachFiled.isSelected = false;
        eachFiled.options.forEach(option => {
          option.isSelected = false;
        });
      }
      if (eachFiled.key === 'workingLocation') {
        eachFiled.options.forEach(option => {
          option.isSelected = false;
        });
        eachFiled.options[0].isSelected = true;
      }
      if (eachFiled.type === 'profile') {
        eachFiled.initialImage = 'assets/images/pngs/empty-profile-picture.png';
        eachFiled.showImage = true;
        this.formInputs.formGroup.controls[eachFiled.key].value = '';
      }
    });
  }

  // sending permission names to the options([]) of each permissionCategory
  // by getting from API
  getFilterPermistionsList(data) {
    this.formInputs.formInputs.forEach(form => {
      if (form.type === 'groupCheckbox') {
        form.options = [];
        data.forEach(obj => {
          if (form.categoryLabel.toLowerCase() === obj.permissionCategory.toLowerCase()) {
            obj.editable = true;
            obj.isSelected = false;
            obj.permissionName = obj.permissionName.split('_').join(' ');
            form.options.push(obj);
          }
        });
      }
    });
  }

  getFilterLocationsList(data) {
    this.formInputs.formInputs.forEach(field => {
      if (field.key === 'workingLocation') {
        field.options = [];
        data.forEach(eachloaction => {
          let locationObj = {};
          locationObj['id'] = eachloaction.locationId,
            locationObj['name'] = eachloaction.locationName,
            locationObj['isSelected'] = false,
            locationObj['editable'] = false,
            field.options.push(locationObj);
        });
        if (this.isEditAndView !== true) {
          field.options[0].isSelected = true;
          field.value = [field.options[0].id];
          this.formInputs.formGroup.controls[field.key].setValue(field.value);
          this.formInputs.formGroup.controls[field.key].patchValue(field.value);
        }
      }
    });
  }
  // Get permmistions API Call
  getPermistionsList() {
    const payloadData = {
      type: 'GET',
      url: 'permissions',
      isDeveloper: true,
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        // console.log('Response of permistions', res);
        this.getFilterPermistionsList(res.data.Permissions);
        console.log(this.formInputs);
      } else {
        console.log('Response', res);
        this.HelperService.showToast(TOAST_STATUSES.ERROR, 'Error in getting permistions list');
      }
    });
  }
  // Get permmistions API Call
  getLocationsList() {
    const payloadData = {
      type: 'GET',
      url: 'employeeLocations',
      isDeveloper: true,
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        // console.log('Response of locations List', res);
        this.getFilterLocationsList(res.data.Locations);
        if (this.isEditAndView) {
          this.getEmpDataById(this.getEmpId);
        }
        // console.log(this.formInputs);
      } else {
        console.log('Response', res);
        this.HelperService.showToast(TOAST_STATUSES.ERROR, 'Error in getting locations List list');
      }
    });
  }
  // getting profile pic from custom-comp and uploading into DB
  uploads(data: any) {
    // tslint:disable-next-line:no-unused-expression
    data.innerField.key === 'uploadDocument' ? (data.innerField.initialImage = data.value, data.innerField.value = data.value, data.innerField.key = 'uploadDocument' + (data.innerField.index)) : null;
    console.log(data);
    console.log(this.formInputs);
    this.profileLink = data.value;
    if (this.isEditAndView) {
      let payload = {
        type: 'POST',
        url: 'uploadsFiles',
        body: {
          uploadType: data.innerField.key === 'picture' ? 'profile' : 'employee',
          refId: this.getEmpId,
          docUrl: data.value
        },
        isDeveloper: true,
      };
      this.http.makeHttpRequest(payload).subscribe((res) => {
        if (this.http.isSuccessfulResponse(res)) {
          // tslint:disable-next-line:no-unused-expression
          data.innerField.key === 'uploadDocument' ? data.innerField.uploadId = res.data.uploadId : null;
          this.observableHelperService.showToast(TOAST_STATUSES.SUCCESS, 'Successfully uploaded');
        } else {
          let message = 'Failed to update uploading files . Please try again';
          message = (res.error) ? res.error.message : message;
          this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
        }
        this.router.navigate(['/admin/admin-profile']);
      });
    }
  }

  massageUploads(data) {
    // Get all uploads list form API
    let uploadsList = [];
    let fileName;
    data.forEach(eachupload => {
      if (eachupload !== '') {
        let docObj = { ...eachupload };
        fileName = eachupload.docUrl.substring(
          eachupload.docUrl.lastIndexOf('__name__') + 8,
          eachupload.docUrl.lastIndexOf('?')
        );
        docObj['file'] = this.splitFileName(fileName, eachupload.docUrl);
        uploadsList.push(docObj);
      }
    });
    return uploadsList;
  }
  // Split function for the file name
  splitFileName(file, docURL) {
    let fileObj = {};
    let splitFileName = file.split('.');
    fileObj['name'] = splitFileName[0];
    fileObj['extenstion'] = splitFileName[splitFileName.length - 1];
    fileObj['file'] = docURL;
    return fileObj;
  }

  employeeUploadsDocuments(data) {
    var uploadUrlBody = {
      uploadType: data.type,
      refId: this.employeeUserID.toString(),
      docUrl: data.docUrl
    };
    let payloadData;
    payloadData = {
      type: 'POST',
      url: 'uploadsFiles',
      isDeveloper: true,
      body: uploadUrlBody,

    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {

        if (data.type === 'employee') {
          let fileName;
          let docObj = { ...res.data };
          fileName = res.data.docUrl.substring(
          res.data.docUrl.lastIndexOf('__name__') + 8,
          res.data.docUrl.lastIndexOf('?')
        );
          docObj['file'] = this.splitFileName(fileName, res.data.docUrl);
          this.uploadsList.push(docObj);
        }
        this.HelperService.showToast(TOAST_STATUSES.SUCCESS, 'Successfully uploaded file');
      } else {
        let message = 'Failed to update uploading files . Please try again';
        message = (res.error) ? res.error.message : message;
        this.HelperService.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
}
