import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { DEFAULT_PAGINATION_LIMIT, TELEMEDICINE_MEDICATION, TOAST_STATUSES } from '@app/common/constants/util.constant';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';

@Component({
  selector: 'app-create-appointment',
  templateUrl: './create-appointment.component.html',
  styleUrls: ['./create-appointment.component.scss']
})
export class CreateAppointmentComponent implements OnInit {
  // Bread crumb
  breadCrumbData = {
    link: '/admin/appointments',
    linkText: 'Create New Appointment'
  };
  outsideScroll = true;
  formInputs: any; // Form inputs
  patientUserId: any; // Patient user id
  searchInputText = ''; // auto search initial value
  patientsList: any; // Patients list
  totalListOfItems = DEFAULT_PAGINATION_LIMIT;
  searchFieldName: any; // Seach query
  searchResultList: any; // Final patients list
  isshowScreen: boolean = false;
  appointmentTypeId = 1; // Initial Appointments id
  queryParams: any; // Query Paramas
  dropDownTimevalue = this.formatDate(); // Getting todays date and assigning to dropdownvalue
  locationId = 'ALX'; // Initial location id value
  locationType = 'offline'; // Initial location id value
  locationName = 'Alexandria Office'; // Initial location id value
  isPatientEdit = false; // If user comming from patient module
  isAdminEdit = false; // If user comming from admin page
  appointmentTime = ''; // Appointment time form url
  offLinePatientStatus = false;
  INITIAL_PAYLOAD = {
    search: this.searchInputText,
    notType: 'pending'
  };
  patientId: any; // Patient id
  patientNeedModify: any; // Patient modify status
  patientsDetails: any; // Patient details
  // Availability initial data
  params = {
    beginTime: (this.appointmentTypeId !== 4) ? '06:30:00' : '07:00:00',
    endTime: '23:30:00',
    startDate: this.dropDownTimevalue,
    days: 1,
    metadata: true,
    appointmentTypeId: this.appointmentTypeId,
    locationId: this.locationId
  };
  clearAutoSelect: string;
  cancelPopupShowHide: boolean = false;
  cancelPopupShowHideTele: boolean = false;
  action: string;
  treatmentsCount: any;
  blockedServices: any;
  forminputs: any = [
    {
      type: 'header',
      label: 'text',
      label1: 'text',
      showontoggle: true,
      editable: true,
      key: 'patient_name_id',
      className: 'patient-id',
    },

    {
      type: 'groupCheckbox',
      categoryLabel: 'Treatment',
      controlType: 'groupCheckbox',
      key: 'treatment',
      required: true,
      value: [],
      isIntermediate: false,
      options: [
        {
          name: 'Medmanagement',
          value: 'Medmanagement',
          isSelected: false
        },
        {
          name: 'Tele Medicine',
          value: 'Tele Medicine',
          isSelected: false
        },
        {
          name: 'TMS',
          value: 'TMS',
          isSelected: false
        },
        {
          name: 'Therapy',
          value: 'Therapy',
          isSelected: false
        }
      ],
      validators: [],
      focus: true,
      valueable: true,
      isSelected: false,
      showontoggle: true,
    },
    {
      type: 'submit',
      placeholder: '',
      required: true,
      text: 'Save',
      key: '',
      value: '',
      controlType: 'submitButton',
      validators: [],
      callback: '',
      cencelBtn: '',
      showontoggle: true,
    }
  ];
  blockPatientPopupShowHide: boolean;
  rawFilterData: any;
  getFilterData: any;
  body: { userId: any; patientId: any; appointmentTypeId: any; locationId: any; appointmentDate: string; appointmentTime: any; timestampDate: any; timestamp: any; timeDiff: any; };
  metaData: any;
  appointmentTypeName: any;
  clearData: boolean = false;
  fromData: any;
  patientName: string;
  lastValidMessage: any;
  patientNeedProfileUpdate: boolean = false;
  // End of the valibles
  constructor(private formServices: CustomFormHandlerService, private http: HttpService, private HelperService: ObservableHelperService, private route: ActivatedRoute, private router: Router) {
    // Query params
    this.queryParams = route.queryParams;
  }

  ngOnInit() {
    this.formInputs = this.formServices.initForm(this.formServices.formTypes.CREATEAPPOINTMENT);
    this.checkingSidebarState(this.outsideScroll);
    // Checking the params status
    if (this.queryParams.value.patientEdit === 'true') {
      this.isPatientEdit = true;
      this.appendPatientId(this.queryParams.value.patientId);
      this.getsAvailabilityData(this.params);
    } else if (this.queryParams.value.adminEdit === 'true') {
      this.appendDashBoardData(this.queryParams.value);
      this.isAdminEdit = true;
      this.appointmentTime = this.queryParams.value.appointmentTime;
    } else {
      this.getsAvailabilityData(this.params);
      // this.getSearchedPatientList(this.INITIAL_PAYLOAD);
    }
    this.getTreatmentsDataLength();
  }

  ngOnDestroy(): void {
    this.formInputs.formInputs.forEach(field => {
      field.editable = true;
      if (field.controlType === 'lastVisit') {
        field.value = '';
        field.showontoggle = false;
      }
    });
  }
  // Checking the Slide bar is open or if open hide outside scroll
  checkingSidebarState(slideBarFlag) {
    if (slideBarFlag) {
      document.querySelector('html').style.overflow = 'hidden';
      document.querySelector('body').style.overflow = 'hidden';
    } else {
      document.querySelector('html').style.overflow = 'auto';
      document.querySelector('body').style.overflow = 'auto';
    }
  }
  // Checcking side bar staus
  checkingSidebarSetStatus(data) {
    this.outsideScroll = data;
    this.checkingSidebarState(data);
  }
  // If your comming from dashboard
  appendDashBoardData(data) {
    this.dropDownTimevalue = data.appointmentDate;
    this.appointmentTypeId = Number(data.appointmentTypeId);
    this.locationId = data.locationId;
    let dataBody = {
      beginTime: (this.appointmentTypeId !== 4) ? '06:30:00' : '07:00:00',
      endTime: '23:30:00',
      startDate: this.dropDownTimevalue,
      days: 1,
      metadata: true,
      appointmentTypeId: this.appointmentTypeId,
      locationId: this.locationId
    };
    this.getsAvailabilityData(dataBody);
  }

  // If user comming from patient module
  appendPatientId(id) {
    this.getPatientDetails(id);
  }

  // Formating todays date
  formatDate() {
    var d = new Date();
    var month = '' + (d.getMonth() + 1);
    var day = '' + d.getDate();
    var year = d.getFullYear();
    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('-');
  }

  // Get permmistions API Call
  getsAvailabilityData(params) {
    const payloadData = {
      type: 'GET',
      url: 'availability',
      isDeveloper: true,
      params
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.metaData = res.data.metadata;
        this.metaData.locations.every(loc => loc.locationId.toLowerCase() === this.locationId.toLowerCase() ? (this.locationType = loc.locationType, this.locationName = loc.locationName, false) : true );
        this.setValuesFields(res.data.metadata);
        this.massageTimeSlats(res.data);
        this.isshowScreen = true;
        this.checkingSidebarSetStatus(false);
      } else {
        this.HelperService.showToast(TOAST_STATUSES.ERROR, 'Error in getting availability data');
      }
    });
  }

  // Setting locations and trements data for the form fields and form controls
  setValuesFields(data) {
    this.formInputs.formInputs.forEach(field => {
      if (field.key === 'location') {
        field.value = this.locationId;
        data.appointmentTypes.forEach(appointmentTypes => {
          if (appointmentTypes.appointmentTypeId === this.appointmentTypeId) {
            field.fields = [];
            appointmentTypes.locations.forEach(eachLoaction => {
              let locationObj = {};
              locationObj['label'] = this.getLoactionNames(eachLoaction, data.locations),
                locationObj['value'] = eachLoaction,
                locationObj['disable'] = false
              field.fields.push(locationObj);
            });
          }
        });
      }
      if (field.key === 'treatments') {
        field.fields = [];
        field.value = this.appointmentTypeId;
        data.appointmentTypes.forEach(eachloaction => {
          if (eachloaction.appointmentTypeId !== 2) {
            let locationObj = {};
            locationObj['label'] = eachloaction.appointmentTypeName,
              locationObj['value'] = eachloaction.appointmentTypeId,
              locationObj['checked'] = false,
              field.fields.push(locationObj);
          }
        });
      }
      if (field.key === 'date' && this.isAdminEdit === true) {
        field.value = this.calendarDateFormate(this.dropDownTimevalue);
      }
    });
    this.isAdminEdit = false;
    this.offLineStatus();
  }

  // Calender date formate
  calendarDateFormate(date) {
    let dates = date.split('-');
    return dates[1] + '/' + dates[2] + '/' + dates[0];
  }

  timeAmPmConvertion(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    var index = time.indexOf(time[3]);
    if (index !== -1) {
      time.splice(index, 1);
    }
    return time.join(''); // return adjusted time or original string
  }
  D(J) { return (J < 10 ? '0' : '') + J; }
  addMinutes(time, minsToAdd) {

    var piece = time.split(':');

    var mins = piece[0] * 60 + +piece[1] + +minsToAdd;

    return this.D(mins % (24 * 60) / 60 | 0) + ':' + this.D(mins % 60);
  }

  // Massaging the time slots data
  massageTimeSlats(data) {
    let appointmentTime = '';
    if (data) {
      let allSlats = [];
      let slots = data.availability.calendar[0].slots;
      var timeDuration;
      var startTime;
      var endTime;
      data.metadata.appointmentTypes.forEach(eachAppointment => {
        if (eachAppointment.appointmentTypeId === this.appointmentTypeId) {
          timeDuration = eachAppointment.duration;
        }
      });
      slots.forEach(eachSlot => {
        let slotObj = {};
        if (eachSlot.available === true) {
          startTime = this.timeAmPmConvertion(eachSlot.time);
          endTime = this.timeAmPmConvertion(this.addMinutes(eachSlot.time, timeDuration));
          let optionLabel = startTime + '  ' + '-' + '  ' + endTime + '(' + ' ' + timeDuration + 'min )';
          slotObj['label'] = optionLabel;
          slotObj['value'] = eachSlot.time;
          allSlats.push(slotObj);
          if (eachSlot.time === this.appointmentTime) {
            appointmentTime = startTime;
            this.appointmentTime = '';
          }
        }
      });
      if (allSlats.length > 0) {
        this.formInputs.formInputs.forEach(field => {
          if (field.key === 'timeSlots') {
            field.list = [];
            field.list = allSlats;
            if (appointmentTime !== '') {
              field.value = appointmentTime;
              appointmentTime = '';
            } else {
              field.value = field.list[0].value;
            }
            if (this.formInputs.formGroup.controls['date'].value !== '') {
              this.formInputs.formGroup.controls['date'].setErrors(null);
            } else {
              this.formInputs.formGroup.controls['date'].setErrors({ name: true });
            }
            this.formInputs.formGroup.controls['timeSlots'].setErrors(null);
          }
        });
      } else if (allSlats.length === 0) {
        this.formInputs.formInputs.forEach(field => {
          if (field.key === 'timeSlots') {
            field.list = [];
            let slotObj = {};
            slotObj['label'] = 'There are no available slots. Please select another date';
            slotObj['value'] = '';
            slotObj['disabled'] = true;
            field.list = [slotObj];
            this.formInputs.formGroup.controls['timeSlots'].setErrors({ name: true });
            this.formInputs.formGroup.controls['date'].setErrors({ name: true });
          }
        });
      }
    }
  }

  // Shedule appointment final data
  appointmentFormSubmit(data) {
    this.fromData = data;
    let currentDate: any = new Date();
    let timeDiff: any = (currentDate.getUTCHours() - currentDate.getHours()) + ':' + (currentDate.getUTCMinutes() - currentDate.getMinutes());
    currentDate = currentDate.toLocaleString().split(', ');
    let timestampDate = currentDate[0];
    let timestamp = currentDate[1].substring(0, currentDate[1].length - 6) + currentDate[1].slice(-3);
    this.body = {
      userId: this.patientUserId,
      patientId: this.patientId,
      appointmentTypeId: data.value.treatments,
      locationId: data.value.location,
      appointmentDate: formatDate(new Date(data.value.date), 'yyyy-MM-dd', 'en'),
      appointmentTime: data.value.timeSlots,
      timestampDate,
      timestamp,
      timeDiff
    };
    if(this.patientNeedProfileUpdate){
      this.action = 'profileUpdate';
      this.cancelPopupShowHide = true;
    } else if (this.patientNeedModify) {
      this.action = 'modifyShow';
      this.cancelPopupShowHide = true;
    } else {
      this.checkLastVist(this.body);
    }
  }

  checkLastVist(bodyData) {
    const payloadData = {
      type: 'POST',
      url: 'adminLastValid',
      isDeveloper: true,
      body: bodyData,
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      // if (this.http.isSuccessfulResponse(res)) {
      if (res.status === 'success') {
        this.action = 'unblock';
        this.blockedServices = this.patientsDetails.details.blockStatus.map(each => each.name);
        this.appointmentTypeName = this.metaData.appointmentTypes.filter(apptType => apptType.appointmentTypeId === this.appointmentTypeId);
        this.appointmentTypeName = this.appointmentTypeName.map(each =>{
          return{
            ...each,
            name : (each.appointmentTypeId === TELEMEDICINE_MEDICATION.appointmentTypeId && bodyData.locationId === TELEMEDICINE_MEDICATION.locationId) ? `${each.appointmentTypeName} Online` : (each.appointmentTypeId === TELEMEDICINE_MEDICATION.appointmentTypeId && bodyData.locationId !== TELEMEDICINE_MEDICATION.locationId) ? `${each.appointmentTypeName} In Office` : each.appointmentTypeName
          };
        });
        if (this.blockedServices.length === 0 || !this.blockedServices.includes(this.appointmentTypeName[0].name)) {
          this.createAppontment(this.body);
        } else {
          this.cancelPopupShowHide = true;
        }
      } else {
        this.lastValidMessage = res.error.message + ' Do you want to proceed?';
        this.action = 'lastValid';
        this.cancelPopupShowHide = true;
      }
      // }
    });
  }
  // Cancel button actions
  cancelButtonEvent(data) {
    this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.APPOINTMENTS}`]);
  }

  // auto search event
  searchReslutEvent(data) {
    this.searchInputText = data.event.target.value;
    this.searchFieldName = data.key;
    let body = {
      search: this.searchInputText,
      notType: 'pending'
    };
    if (!(this.searchInputText === '' || this.searchInputText === undefined || this.searchInputText === null)) {
      this.getSearchedPatientList(body);
    }
  }

  // Shedule api call
  sendSheduleData() {
    let bodyData;
    const payloadData = {
      type: 'POST',
      url: 'patientList',
      isDeveloper: true,
      body: bodyData,
      appointmentTypeId: this.appointmentTypeId,
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {

      } else {
        // Error
        this.HelperService.showToast(TOAST_STATUSES.ERROR, 'Error in getting Sheduling data');
      }
    });
  }

  // get searched patients
  getSearchedPatientList(bodyData) {
    const payloadData = {
      type: 'POST',
      url: 'adminPatientSearch',
      isDeveloper: true,
      body: bodyData,
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.patientsList = this.massagePatientList(res.data);
        this.flterData(this.searchInputText);
      } else {
        this.HelperService.showToast(TOAST_STATUSES.ERROR, 'Error in getting patientList data');
      }
    });
  }

  // MassagePatientList  Data
  massagePatientList(data) {
    if (!data && !Array.isArray(data)) { return []; }
    return data.filter(each => each.removedFlag === 0).map(each => {
      return {
        nameOnly: (!each.firstName && !each.lastName) ? '-' :
          (each.firstName) + ' ' + (each.lastName),
        name: (!each.firstName && !each.lastName && !each.patientId) ? '-' :
          (each.firstName) + ' ' + (each.lastName) + '(' + each.patientId + ')',
        id: each.patientId,
        status: each.status,
      };
    });
  }

  // Setting data to auto search
  flterData(searchQuery) {
    let filtered: any[] = [];
    let query = searchQuery;
    this.formInputs.formInputs.forEach(field => {
      if (field.key === this.searchFieldName) {
        field.results = this.patientsList;
        field.results.forEach((eachResult, index) => {
          let searchResult = eachResult;
          filtered.push(searchResult);
          let matchText = searchResult.nameOnly.toLowerCase();
          if (matchText.indexOf(query.toLowerCase()) === 0 && this.isPatientEdit) {
            field.value = searchResult.name;
            this.formInputs.formGroup.controls[field.key].setValue(field.value);
            this.formInputs.formGroup.controls[field.key].patchValue(field.value);
          }
        });
      }
    });
    this.searchResultList = filtered;
  }

  // get the patient data on patient dropdown options
  getPatientId(data) {
    this.getPatientDetails(data);
  }

  // Get All Treatments Data length
  getTreatmentsDataLength() {
    const payloadData = {
      type: 'GET',
      url: 'invoiceFilter',
      isDeveloper: true,
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.treatmentsCount = res.data.treatment.length;
        this.getFilterData = res.data;
        this.getFilterData.treatment.unshift(TELEMEDICINE_MEDICATION);
      } else {
        // Error
        let message = 'Failed to fetch filter data. Please try again';
        message = (res.error) ? res.error.message : message;
        this.HelperService.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  // Get getPatientDetails API call
  getPatientDetails(id) {
    const payloadData = {
      type: 'GET',
      url: 'patientsDetails',
      isDeveloper: true,
      pathVariables: [id]
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.patientsDetails = this.massagePatientDetails(res.data);
        this.patientsDetails.details.blockStatus = this.patientsDetails.details.blockStatus.map(each => {
          return{
            ...each,
            name : (each.appointmentTypeId === TELEMEDICINE_MEDICATION.appointmentTypeId && each.locationId === TELEMEDICINE_MEDICATION.locationId) ? `${each.appointmentTypeName} Online` : (each.appointmentTypeId === TELEMEDICINE_MEDICATION.appointmentTypeId && each.locationId !== TELEMEDICINE_MEDICATION.locationId) ? `${each.appointmentTypeName}` : each.appointmentTypeName,
          };
        });
        this.patientUserId = this.patientsDetails.details.userId;
        this.patientNeedModify = this.patientsDetails.details.longTimeUpdate;
        this.patientNeedProfileUpdate = this.patientsDetails.details.isInactive;
        this.patientId = this.patientsDetails.details.patientId;
        this.patientName = (!this.patientsDetails.details.firstName && !this.patientsDetails.details.lastName) ? '-' : (this.patientsDetails.details.firstName) + ' ' + (this.patientsDetails.details.lastName) + ' ';
        let dataBody = {
          appointmentTypeId: this.appointmentTypeId,
          locationId: this.locationId,
          startDate: this.dropDownTimevalue,
          beginTime: (this.appointmentTypeId !== 4) ? '06:30:00' : '07:00:00',
          endTime: '23:30:00',
          days: 1,
          metadata: true,
        };
        this.getsAvailabilityData(dataBody);
        this.sendLastVisistData(this.patientsDetails);
        if (this.patientsDetails.details.status === 'archive') {
          this.cancelPopupShowHide = true;
          this.action = 'active';
        }
        if (this.isPatientEdit) {
          setTimeout(() => {
            this.isshowScreen = true;
            this.formInputs.formInputs.forEach(field => {
              if (field.key === 'patientSearch') {
                field.value = {
                  id: this.patientsDetails.details.patientId, // mandatory
                  name: (!this.patientsDetails.details.firstName && !this.patientsDetails.details.lastName) ? '-' : (this.patientsDetails.details.firstName) + ' ' + (this.patientsDetails.details.lastName) + '(' + this.patientsDetails.details.patientId + ')',
                  status: this.patientsDetails.details.status,
                  nameOnly: (!this.patientsDetails.details.firstName && !this.patientsDetails.details.lastName) ? '-' : (this.patientsDetails.details.firstName) + ' ' + (this.patientsDetails.details.lastName),
                };
                this.formInputs.formGroup.controls[field.key].setValue({
                  id: this.patientsDetails.details.patientId, // mandatory
                  name: (!this.patientsDetails.details.firstName && !this.patientsDetails.details.lastName) ? '-' : (this.patientsDetails.details.firstName) + ' ' + (this.patientsDetails.details.lastName) + '(' + this.patientsDetails.details.patientId + ')',
                  status: this.patientsDetails.details.status,
                  nameOnly: (!this.patientsDetails.details.firstName && !this.patientsDetails.details.lastName) ? '-' : (this.patientsDetails.details.firstName) + ' ' + (this.patientsDetails.details.lastName),
                });
                this.formInputs.formGroup.controls[field.key].patchValue({
                  id: this.patientsDetails.details.patientId, // mandatory
                  name: (!this.patientsDetails.details.firstName && !this.patientsDetails.details.lastName) ? '-' : (this.patientsDetails.details.firstName) + ' ' + (this.patientsDetails.details.lastName) + '(' + this.patientsDetails.details.patientId + ')',
                  status: this.patientsDetails.details.status,
                  nameOnly: (!this.patientsDetails.details.firstName && !this.patientsDetails.details.lastName) ? '-' : (this.patientsDetails.details.firstName) + ' ' + (this.patientsDetails.details.lastName),
                });
                field.editable = false;
              }
            });
          }, 1000);
        }
      } else {
        // Error
        this.HelperService.showToast(TOAST_STATUSES.ERROR, 'Error in getting  patientsDetails data');
      }
      this.isPatientEdit = false;
    });
  }
  massagePatientDetails(data) {
    if (!data && typeof (data) !== 'object') { return {}; }
    return data = {
      ...data,
      pastDue: data.details.pastdue ? `$ + ${data.details.pastdue}` : '---',
    };
  }

  blockPatientPopupShowHideStatusFun(status) {
    this.blockPatientPopupShowHide = status.cancelpopup;
    this.checkingSidebarSetStatus(false);
  }
  cancelPopupShowHideTeleStatusFun(status) {
    this.cancelPopupShowHideTele = status.cancelpopup;
    // Availability initial data
    this.params = {
      beginTime: (this.appointmentTypeId !== 4) ? '06:30:00' : '07:00:00',
      endTime: '23:30:00',
      startDate: this.dropDownTimevalue,
      days: 1,
      metadata: true,
      appointmentTypeId: this.appointmentTypeId,
      locationId: 'ALX',
    };
    this.getsAvailabilityData(this.params);
    if (this.queryParams.value.patientEdit !== 'true') {
      this.formInputs.formGroup.controls['patientSearch'].setValue('');
      this.formInputs.formGroup.controls['patientSearch'].patchValue('');
      this.formInputs.formGroup.controls['patientSearch'].setErrors({ required: true });
    }
  }
  cancelPopupShowHideStatusFun(status, show?, label?) {
    this.cancelPopupShowHide = status.cancelpopup;
    this.checkingSidebarSetStatus(false);
    if (show && label === 'unblock') {
      this.forminputs.forEach(each => {
        if (each.key === 'patient_name_id') {
          each.label = `${this.patientsDetails.details.firstName} ${this.patientsDetails.details.lastName}`;
          each.label1 = `${this.patientsDetails.details.patientId}`;
        }
      });
      this.getTreatmentsData(this.patientsDetails.details.blockStatus);
    } else if (show && label === 'proceed') {
      this.createAppontment(this.body);
    } else if (show && label === 'lastValid') {
      this.proceedAction();
    } else if (this.action === 'active') {
      this.formInputs.formInputs.forEach(field => {
        if (field.key === 'patientSearch') {
          field.value = '';
          this.formInputs.formGroup.controls[field.key].setValue('');
          this.formInputs.formGroup.controls[field.key].patchValue('');
          this.formInputs.formGroup.controls['patientSearch'].setErrors({ required: true });
        }
      });
    } else if (show && (label === 'modifyShow' || label == 'profileUpdate')) {
      this.checkLastVist(this.body);
    }
    if (this.offLinePatientStatus === true) {
      this.offLinePatientStatus = false;
      this.formInputs.formInputs.forEach(field => {
        if (field.key === 'patientSearch') {
          field.value = '';
          this.formInputs.formGroup.controls[field.key].setValue('');
          this.formInputs.formGroup.controls[field.key].patchValue('');
        }
      });
    }
  }


  proceedAction() {
    this.blockedServices = this.patientsDetails.details.blockStatus.map(each => each.name);
    this.appointmentTypeName = this.metaData.appointmentTypes.filter(apptType => apptType.appointmentTypeId === this.appointmentTypeId);
    this.appointmentTypeName = this.appointmentTypeName.map(each => {
      return {
        ...each,
        name: (each.appointmentTypeId === TELEMEDICINE_MEDICATION.appointmentTypeId && this.locationId === TELEMEDICINE_MEDICATION.locationId) ? `${each.appointmentTypeName} Online` : (each.appointmentTypeId === TELEMEDICINE_MEDICATION.appointmentTypeId && this.locationId !== TELEMEDICINE_MEDICATION.locationId) ? `${each.appointmentTypeName} In Office` : each.appointmentTypeName
      };
    });
    if (this.blockedServices.length === 0 || !this.blockedServices.includes(this.appointmentTypeName[0].name)) {
      this.createAppontment(this.body);
    } else {
      this.action = 'unblock';
      this.cancelPopupShowHide = true;
    }
  }
  // Setting data to last visit component
  sendLastVisistData(patientDetails) {
    if (patientDetails) {
      this.formServices.newAppointmentConfig.forEach(input => {
        if (input.controlType === 'lastVisit' && patientDetails.details.status !== 'archive') {
          input.value = patientDetails;
          input.showontoggle = true;
        } else if (input.controlType === 'lastVisit') {
          input.value = '';
          input.showontoggle = false;
        }
      });
    }
  }


  // Archive API call
  archiveAPICall() {
    const payloadData = {
      type: 'PUT',
      url: 'patientArchive',
      isDeveloper: true,
      pathVariables: [this.patientId, 'active'],
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.HelperService.showToast(TOAST_STATUSES.SUCCESS, 'The patient has been activated successfully.');
        this.getPatientDetails(res.data.patientId);
      } else {
        let message = 'Failed to fetch patient data. Please try again';
        message = (res.error) ? res.error.message : message;
        this.HelperService.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }
  // radio buttons actions
  treatmentEvent(data) {
    if (data.field.key === 'treatments') {
      this.appointmentTypeId = data.radio.value;
      this.locationId = 'ALX';
    } else if (data.field.key === 'location') {
      this.locationId = data.radio.value;
    }
    let dataBody = {
      appointmentTypeId: this.appointmentTypeId,
      locationId: this.locationId,
      startDate: this.dropDownTimevalue,
      beginTime: (this.appointmentTypeId !== 4) ? '06:30:00' : '07:00:00',
      endTime: '23:30:00',
      days: 1,
      metadata: true,
    };
    this.getsAvailabilityData(dataBody);
  }

  // Click event on date dropdown
  getdropDownData(data) {
    this.dropDownTimevalue = formatDate(new Date(data.value), 'yyyy-MM-dd', 'en');
    let dataBody = {
      beginTime: (this.appointmentTypeId !== 4) ? '06:30:00' : '07:00:00',
      endTime: '23:30:00',
      startDate: this.dropDownTimevalue,
      days: 1,
      metadata: true,
      appointmentTypeId: this.appointmentTypeId,
      locationId: this.locationId
    };
    this.getsAvailabilityData(dataBody);
  }

  // Setting location data
  getLoactionNames(data, locations) {
    let locationLabel = '';
    locations.forEach(location => {
      if (location.locationId === data) {
        locationLabel = location.locationName;
      }
    });
    return locationLabel;
  }


  // Admin get filterData API call
  getTreatmentsData(blockstatus) {
    this.rawFilterData = this.massageFilterData(this.getFilterData, blockstatus);
    this.blockPatientPopupShowHide = true;
    this.checkingSidebarSetStatus(true);
  }
  // Massage the filter data
  massageFilterData(data, blockstatus) {
    if (data && typeof (data) === 'object') {
      data.treatment = data.treatment.map(doc => {
        doc['isSelected'] = false;
        doc['locationId'] = (doc.locationId) ? doc.locationId : null;
        doc['name'] = (doc.appointmentTypeId === TELEMEDICINE_MEDICATION.appointmentTypeId && doc.locationId === TELEMEDICINE_MEDICATION.locationId) ? `${doc.appointmentTypeName} Online` : (doc.appointmentTypeId === TELEMEDICINE_MEDICATION.appointmentTypeId && doc.locationId !== TELEMEDICINE_MEDICATION.locationId) ? `${doc.appointmentTypeName} In Office` : doc.appointmentTypeName;
        blockstatus.forEach(each => {
          if (each.appointmentTypeId === doc.appointmentTypeId && each.locationId === doc.locationId) {
            doc['isSelected'] = true;
          }
        });
        return {
          ...doc,
          value: doc.appointmentTypeId,
        };
      });
      return data;
    } else {
      // Error
    }
  }

  // Block patient Save action
  blockPatientSubmit(data) {
    // Block API call
    this.blockTheUserAPI(data);
    this.blockPatientPopupShowHide = false;
  }

  // Block API PUT Call
  blockTheUserAPI(bodyData) {
    const payloadData = {
      type: 'PUT',
      url: 'patientActions',
      isDeveloper: true,
      body: bodyData,
      pathVariables: [this.patientId],
    };
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.checkingSidebarSetStatus(false);
        this.formInputs.formInputs.forEach(field => {
          if (field.key === 'patientSearch') {
            field.value = '';
            this.formInputs.formGroup.controls[field.key].setValue('');
            this.formInputs.formGroup.controls[field.key].patchValue('');
            this.formInputs.formGroup.controls['patientSearch'].setErrors({ required: true });
            field.editable = true;
          }
        });
        this.getSearchedPatientList(this.INITIAL_PAYLOAD);
        this.HelperService.showToast(TOAST_STATUSES.SUCCESS, 'Updated the patient blocked status. Please try again');
      } else {
        // Error
        let message = 'Failed to send patient block status . Please try again';
        message = (res.error) ? res.error.message : message;
        this.HelperService.showToast(TOAST_STATUSES.ERROR, message);
      }
    }
    );
  }

  // Create new appointment
  createAppontment(bodyData) {
    const payloadData = {
      type: 'POST',
      url: 'createAppointment',
      isDeveloper: true,
      body: bodyData
    };
    this.checkingSidebarSetStatus(false);
    this.http.makeHttpRequest(payloadData).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.HelperService.showToast(TOAST_STATUSES.SUCCESS, 'Created new appointment successfully');
        this.formInputs.formGroup.reset();
        this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.APPOINTMENTS}`]);
      } else {
        // Error
        let message = (res.error) ? res.error.message : 'Error in creating new appointment';
        this.HelperService.showToast(TOAST_STATUSES.ERROR, message);
      }
    });
  }

  setLocationsData() {
    if (this.metaData) {
      this.formInputs.formInputs.forEach(field => {
        if (field.key === 'location') {
          field.value = this.locationId;
          this.metaData.appointmentTypes.forEach(appointmentTypes => {
            if (appointmentTypes.appointmentTypeId === this.appointmentTypeId) {
              field.fields = [];
              appointmentTypes.locations.forEach(eachLoaction => {
                let locationObj = {};
                locationObj['label'] = this.getLoactionNames(eachLoaction, this.metaData.locations),
                  locationObj['value'] = eachLoaction,
                  locationObj['disable'] = false,
                  field.fields.push(locationObj);
              });
            }
          });
        }
      })
      console.log(this.formInputs.formInputs);
    }
  }

  offLineStatus() {
    if (this.patientsDetails) {
      if (this.patientsDetails.details.isOffline === 1 && this.formInputs.formGroup.controls['patientSearch'].value !== '') {
        this.formInputs.formInputs.forEach((field, i) => {
          if (field.key === 'location') {
            if (this.locationType === 'online') {
              this.cancelPopupShowHideTele = true;
              this.offLinePatientStatus = true;
              field.fields.forEach(fieldOption => {
                if (fieldOption.value === this.locationId) {
                  fieldOption.disable = true;
                }
              });
              if (field.value === this.locationId) {
                field.value = field.fields[0].value;
              }
              this.formInputs.formGroup.controls['location'].setValue(field.value);
              this.formInputs.formGroup.controls['location'].patchValue(field.value);
              this.locationId = field.value;
            } else {
              this.locationId = field.value;
            }
          }
        });

      } else {
        this.formInputs.formGroup.controls['location'].setValue(this.locationId);
        this.formInputs.formGroup.controls['location'].patchValue(this.locationId);
        this.offLinePatientStatus = false;
      }
    }
  }
}