import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { API_URLS } from '@app/common/constants/api-urls.constant';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';
import { IGetMethodPayload, IPostMethodPayload, IPutMethodPayload } from '@app/common/constants/typeInterfaces.constants';
import { HTTPMethods, TOAST_STATUSES } from '@app/common/constants/util.constant';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
  action: string;
  userId: any;
  popupMessage: string;
  popupShowHide: boolean = false;

  constructor(private router: Router, private http: HttpService, private observable: ObservableHelperService) { }
  @Input() userData;
  @Input() adminPermissions;
  @Output() userActionEmit = new EventEmitter<any>();

  ngOnInit() {
  }

  /**
   * This is for navigating  user details page
   * @param id : User id
   */
  navigateDetailsPage(id) {
    this.router.navigate([`${ROUTE_PATHS.ADMIN}/${ROUTE_PATHS.USER_LIST}/${id}`]);
  }

  /**
   * This will pass the trigger action data to the parent component
   * @param action :clicked action
   * @param userData : user Data
   */
  actionsTrigger(action, userData) {
    this.userActionEmit.emit({ action, userData });
  }



}
