<div class="email-template__wrapper" *ngIf="templateData && permissionsDone">
    <div class="email-template__navigation-menu">
        <app-breadcrumb [breadCrumbData]="breadCrumbData"></app-breadcrumb>
    </div>
    <div class="email__template__form--wrapper">
        <!--custom form start-->
        <form [formGroup]="formGroup" class="custom-form">
            <ng-container *ngFor="let field of formInputs;let i=index">
                <div [ngSwitch]="field.controlType"  [ngClass]="field.showontoggle? field.controlType : ''">
                    <div class="text__area-label" *ngIf="field.label && field.showontoggle" [ngClass]="{ labelEmpty: field.emptyLabel }">{{field.label}}</div>

                    <!-- Input field start -->
                    <div *ngSwitchCase="'inputField'" class="form__field text__area inputFieldInner {{field.type}}">

                        <input class="input-field text-field__value" type="{{field.type}}" [attr.maxLength]="field.maxLength"
                             [ngClass]="{ 'disable textBorder': !field.editable }" [formControlName]="field.key"
                            [(ngModel)]="field.value" [readonly]="!field.editable"
                           />

                        <!--text area validation start-->
                        <div *ngIf="field.updateOn == 'blur'">
                            <div *ngIf="(!formGroup.controls[field.key].value) && !formSubmitAttempt"
                                class="invalid-feedback ">
                                <div>{{ field.emptyValueMessage }}</div>
                            </div>
                            <div *ngIf="formGroup.controls[field.key].value && formGroup.controls[field.key].status == 'INVALID' && !formSubmitAttempt && formGroup.controls[field.key].touched"
                                class="invalid-feedback">
                                <div>{{ field.validationValue }}</div>
                            </div>
                        </div>
                        <div>
                            <div *ngIf="(!formGroup.controls[field.key].value) && formSubmitAttempt" class="invalid-feedback">
                                <div>{{ field.emptyValueMessage }}</div>
                            </div>
                        </div>
                        <div *ngIf="formGroup.controls[field.key].value !='' && isInvalid(formGroup.controls[field.key], formSubmitAttempt)" class="invalid-feedback">
                            <div>{{ field.validationValue }}</div>
                        </div>
                        <div class="help__text" *ngIf="field.helpText">{{field.helpText}}</div>
                        <!--text area validation end-->
                    </div>
                    <!-- Input field end -->

                      <!-- Input field start -->
                      <div *ngSwitchCase="'textarea'" class="form__field text__area textareaFieldInner {{field.type}}">

                        <textarea class="input-field text-field__value" [attr.maxLength]="field.maxLength"
                             [ngClass]="{ 'disable textBorder': !field.editable }" [formControlName]="field.key"
                            [(ngModel)]="field.value" [readonly]="!field.editable"
                           ></textarea>

                        <!--text area validation start-->
                        <div *ngIf="field.updateOn == 'blur'">
                            <div *ngIf="(!formGroup.controls[field.key].value) && !formSubmitAttempt"
                                class="invalid-feedback ">
                                <div>{{ field.emptyValueMessage }}</div>
                            </div>
                            <div *ngIf="formGroup.controls[field.key].value && formGroup.controls[field.key].status == 'INVALID' && !formSubmitAttempt && formGroup.controls[field.key].touched"
                                class="invalid-feedback">
                                <div>{{ field.validationValue }}</div>
                            </div>
                        </div>
                        <div>
                            <div *ngIf="(!formGroup.controls[field.key].value) && formSubmitAttempt" class="invalid-feedback">
                                <div>{{ field.emptyValueMessage }}</div>
                            </div>
                        </div>
                        <div *ngIf="formGroup.controls[field.key].value !='' && isInvalid(formGroup.controls[field.key], formSubmitAttempt)" class="invalid-feedback">
                            <div>{{ field.validationValue }}</div>
                        </div>
                        <div class="help__text" *ngIf="field.helpText">{{field.helpText}}</div>
                        <!--text area validation end-->
                    </div>
                    <!-- Input field end -->
                    
                    <!-- Number Input field start -->
                    <ng-container *ngIf="field.showontoggle == true">
                    <div *ngSwitchCase="'inputFieldNumber'" class="form__field inputFieldNumber">
                        <div class="input-number_wrapper">
                            <input class="input-field input-field-number text-field__value" [type]="field.type" [attr.maxLength]="field.maxLength"
                            [formControlName]="field.key" [ngClass]="{ disable: !field.editable }" 
                            value="{{ field.value }}" [placeholder]="field.placeholder" type="{{field.type}}"
                            [readonly]="!field.editable" (keypress)="onValueChange($event)"
                            [(ngModel)]="field.value" (input)="OnInputValueChange(field)"  />
                        <div class="input-number_wrapper__label">{{field.units}}</div>
                        </div>

                        <!--text area validation start-->
                        <div class="error-message" *ngIf="field.updateOn == 'blur'">
                            <div *ngIf="(!formGroup.controls[field.key].value) && !formSubmitAttempt"
                                class="invalid-feedback ">
                                <div>{{ field.emptyValueMessage }}</div>
                            </div>
                            <div *ngIf="formGroup.controls[field.key].value && formGroup.controls[field.key].status == 'INVALID' && !formSubmitAttempt && formGroup.controls[field.key].touched"
                                class="invalid-feedback">
                                <div>{{ field.validationValue }}</div>
                            </div>
                        </div>
                        <div class="error-message">
                            <div *ngIf="(!formGroup.controls[field.key].value) &&formSubmitAttempt" class="invalid-feedback">
                                <div>{{ field.emptyValueMessage }}</div>
                            </div>
                        </div>
                        <div *ngIf="formGroup.controls[field.key].value !='' && isInvalid(formGroup.controls[field.key], formSubmitAttempt)" class="invalid-feedback error-message">
                            <div>{{ field.validationValue }}</div>
                        </div>
                        <div class="help__text" *ngIf="field.helpText">{{field.helpText}}</div>
                        <!--text area validation end-->
                    </div>
                    </ng-container>
                    <!-- Number Input field end -->

                    <!-- Time input start -->
                    <div *ngSwitchCase="'timeInput'">
                        <div *ngIf="field.showontoggle" class="form__field inputFieldNumber">
                            <div>
                                <input class="input-field input-field-number text-field__value" type="{{field.type}}" [attr.maxLength]="field.maxLength"
                                [formControlName]="field.key" [ngClass]="{ disable: !field.editable }" 
                                value="{{ field.value }}" [placeholder]="field.placeholder"
                                [readonly]="!field.editable" (keypress)="onValueChange($event)"
                                [(ngModel)]="field.value"/>
                            </div>
    
                            <!--text area validation start-->
                            <div *ngIf="field.updateOn == 'blur'">
                                <div *ngIf="(!formGroup.controls[field.key].value) && !formSubmitAttempt"
                                    class="invalid-feedback ">
                                    <div>{{ field.emptyValueMessage }}</div>
                                </div>
                                <div *ngIf="formGroup.controls[field.key].value && formGroup.controls[field.key].status == 'INVALID' && !formSubmitAttempt && formGroup.controls[field.key].touched"
                                    class="invalid-feedback">
                                    <div>{{ field.validationValue }}</div>
                                </div>
                            </div>
                            <div>
                                <div *ngIf="(!formGroup.controls[field.key].value) &&formSubmitAttempt" class="invalid-feedback">
                                    <div>{{ field.emptyValueMessage }}</div>
                                </div>
                            </div>
                            <div *ngIf="formGroup.controls[field.key].value !='' && isInvalid(formGroup.controls[field.key], formSubmitAttempt)" class="invalid-feedback">
                                <div>{{ field.validationValue }}</div>
                            </div>
                            <div class="help__text" *ngIf="field.helpText">{{field.helpText}}</div>
                            <!--text area validation end-->
                        </div>
                    </div>
                    <!-- Time input end -->

                    <!-- Dropdown section start-->
                    <div *ngSwitchCase="'dropdown'">
                        <div class="form__field dropdown input" *ngIf="field.showontoggle == true">
                            <p-dropdown [(ngModel)]="field.value" [options]="field.list" [placeholder]="field.placeholder"
                            [required]="field.required" [formControlName]="field.key"
                            [disabled]="!field.editable">
                            <ng-template let-items pTemplate="item">
                                <div class="dropdown__items dropdown__header">
                                    <div class="table__icon__header" *ngIf="items.icon">
                                        <app-svg [name]="items.icon"></app-svg>
                                    </div>
                                    <div class="item__label">{{items.label}}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                        <div *ngIf="isInvalid(formGroup.controls[field.key],formSubmitAttempt)" class="invalid-feedback">
                            {{ field.emptyValueMessage }}
                        </div>
                        </div>
                    </div>
                    <!-- Dropdown section end-->

                </div>
            </ng-container>
        </form>
        <!--custom form end-->

        <!-- Exclude user section starts -->
        <div class="exclude-patient--wrapper" *ngIf="!templateData.isEmailExcludable">
            <div class="popup__heading">Exclude Patients</div>
            <div class="search-filter__block">
                <app-svg [name]="'search'" class="inside-search"></app-svg>
                <p-autoComplete field="email" [(ngModel)]="result" [suggestions]="emailSearchList"
                    (onSelect)="selectedEmail($event)" emptyMessage="No data found." (keyup)="search($event)">
                    <ng-template let-result pTemplate="item">
                        <div class="result-item">
                            <div>{{result.email}}</div>
                        </div>
                    </ng-template>
                </p-autoComplete>
            </div>
            <!-- list section starts -->
            <div class="mail__content__wrapper d-flex" *ngIf="excludedEmailsList.length>0">
                <div class="each-email-id" *ngFor="let emailId of excludedEmailsList.slice(0,3)">
                    <span class="email-id">{{emailId}}</span>
                    <app-svg [name]="'cancel-appointment'" (click)="removeEmailId(emailId)"></app-svg>
                </div>
                <div *ngIf="excludedEmailsList.length>3" class="mail-content__more" (click)="moreUsersClicked()">
                +{{excludedEmailsList.length - 3}} More</div>
                
            </div>
            <div class="empty-text" *ngIf="excludedEmailsList.length==0">No Patients added</div>
            <!-- list section ends -->
        </div>
        <!-- Exclude user section ends -->

        <!-- Content edit starts here -->
        <div class="editable_content-label">Click below to edit content</div>
        <div class="content__preview" id="content-edit" [innerHTML]="templateData.htmlTemplate"></div>
        <!-- Content edit ends here -->

        <!-- action field starts -->
        <div class="form__action">
            <div class="primary__button__wrapper">
                <button class="cancel-btn" (click)="clearFormfields()" [type]="'submit'">
                    Cancel
                </button>
                <div class="cancel__button__wrapper">
                    <button class="common-btn-style" (click)="actionField($event)" [type]="'submit'">
                        Submit
                    </button>
                </div>

            </div>
        </div>
        <!-- action field ends -->
    </div>
</div>

<app-common-popup (showHideCancelPopup)='cancelPopupShowHideStatusFun($event)' *ngIf="cancelPopupShowHide">
    <div class="popup__wrapper exclude-patient--wrapper">
        <div class="popup__heading">Excluded Patients</div>
        <div class="mail__content__wrapper" *ngIf="excludedEmailsList.length>0">
            <div class="each-email-id"
                *ngFor="let emailId of excludedEmailsList">
                <span class="email-id">{{emailId}}</span>
                <app-svg [name]="'cancel-appointment'" (click)="removeEmailId(emailId)"></app-svg>
            </div>
        </div>

    </div>
</app-common-popup>