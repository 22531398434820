<section class="location__details--view-section" *ngIf="isShowScreen">
    <div class="address__details--block">
        <div class="address--details" *ngIf="locationDetails.locationType == 'offline'">
            <app-svg [name]="'location-black'"></app-svg>
            <div class="">
                <div class="lable">Address</div>
                <div class="value">{{locationDetails.address!=null?locationDetails.address:'---'}}</div>
            </div>
        </div>
        <div class="address--details">
            <app-svg class="workingHours" [name]="'workingHours'"></app-svg>
            <div class="">
                <div class="lable">Working hours</div>
                <div *ngIf="locationWeekTimes.startingTime!=null" class="value" style="margin-bottom:2px;" >{{weekDays}}: {{locationWeekTimes.startingTime | customTime}} - {{locationWeekTimes.endingTime | customTime}}</div>
                <div *ngIf="locationWeekendTimes.startingTime!=null" class="value">{{weekendDays}}: {{locationWeekendTimes.startingTime | customTime}} - {{locationWeekendTimes.endingTime | customTime}}</div>
            </div>
        </div>
        <div class="address--details">
            <app-svg [name]="'EmployeesLocations'"></app-svg>
            <div class="">
                <div class="lable">Employees in location</div>
                <div class="value" *ngIf="locationDetails.employeeCount">{{locationDetails.employeeCount.Count | number:'2.0-0'}}</div>
                <div class="value" *ngIf="!locationDetails.employeeCount">---</div>
            </div>
        </div>
    </div>
    <div class="service__provided--header">Services Provided</div>
    <div class="service__provided--block">
        <div class="each__provider" *ngFor="let eachService of locationDetailsData">
            <div class="service--header">
                <div class="each__icon">
                    <app-svg class="{{eachService.svg}}" [name]="eachService.svg"></app-svg>
                </div>
                <div class="header__lable">{{eachService.appointmentTypeName}}</div>
            </div>
            <div class="service__details--block">
                <div class="">
                    <div class="lable">Assigned Physician</div>
                    <div class="value">{{eachService.provider.providerName}}</div>
                </div>
                <div class="">
                    <div class="lable">Session Duration</div>
                    <div class="value">{{eachService.duration}} min</div>
                </div>
                <div class="">
                    <div class="lable">Bookings Per Slot</div>
                    <div class="value">{{eachService.maxSimultaneousAppointments}}</div>
                </div>
                <div class="">
                    <div class="lable">Intakes Per Day</div>
                    <div class="value">{{eachService.intakesPerDay}}</div>
                </div>
                <div class="schedule__block--section">
                    <div class="lable">Schedule</div>
                    <div class="schedule__block--wrapper">
                        <div class="value schedule__block" *ngFor="let timing of eachService.timings">
                            <div class="calendar">
                                <app-svg [name]="'calendar'"></app-svg>
                                {{timing.daysInString}}
                            </div>
                            <div class="clock">
                                <app-svg [name]="'clock'"></app-svg>
                                {{timing.fromTime | customTime}} - {{timing.toTime | customTime}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>