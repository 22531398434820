<!--The content below is only a placeholder and can be replaced.-->
<app-common-header></app-common-header>
<section class="app__component" id="app__component--id">
	<router-outlet (activate)="onActivate($event)"></router-outlet>
</section>
<app-toast ></app-toast>
<!-- Throbber component starts -->
<app-throbber></app-throbber>
<!-- Throbber component ends -->

<!--scroll to top start-->
<app-scroll-to-top *ngIf="toggleFooter && !userLogedIn"></app-scroll-to-top>
<!--scroll to top end-->
<app-common-footer *ngIf="toggleFooter"></app-common-footer>
<app-copyright-footer *ngIf="webAppFooter"></app-copyright-footer>