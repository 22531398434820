<section class="telemedicine-consent--seciton">
    <div>            
        <app-custom-form 
        (submitEvent)="getFormData($event)" [formData]="autoForm" (sliderValue)="getdata($event)" *ngIf="autoFill"></app-custom-form></div>
    <div class="telemedicine-consent__block" *ngIf="this.apptState == 'MD'">
        <p-tabView>
            <p-tabPanel header="{{tab.headerLabel}}" *ngFor="let tab of tabContent">
                <div class="container" *ngFor="let data of tab.data">
                    <div *ngIf="data.description">
                        {{data.description}}
                    </div>
                    <div *ngIf="data.list">
                        <ul class="{{data.listtype}}">
                            <li *ngFor="let list of data.list">
                                {{list}}
                            </li>
                        </ul>
                    </div>
                    <div *ngIf="data.warning">
                      {{data.warning}}
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>
        <div class="consent--points" *ngFor="let points of consentPoints">
            {{points}}
        </div>
    </div>
    <div class="telemedicine-consent__block" *ngIf="this.apptState !== 'MD'">
        <p-tabView>
            <p-tabPanel header="{{tab.headerLabel}}" *ngFor="let tab of tabContent1">
                <div class="container" *ngFor="let data of tab.data">
                    <div *ngIf="data.description">
                        {{data.description}}
                    </div>
                    <div *ngIf="data.list">
                        <ul class="{{data.listtype}}">
                            <li *ngFor="let list of data.list">
                                {{list}}
                            </li>
                        </ul>
                    </div>
                    <div *ngIf="data.warning">
                      {{data.warning}}
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>
        <div class="consent--points" *ngFor="let points of consentPoints">
            {{points}}
        </div>
    </div>

    <div class="form__fields__block">
        <app-custom-form [checkFormInfo]="saveFormData"
        (submitEvent)="getFormData($event)" [formData]="telemedicineForm"></app-custom-form>
    </div>
</section>