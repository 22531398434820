<section class="adhd__questionnaire__section" *ngIf="documentDetails">
    <div class="close__icon" (click)="back()">
        <app-svg name='plus-circle'></app-svg>
    </div>
    <div class="header__wrapper">
        {{header}}
    </div>
    <div class="patient__details--section">
        <div class="patient__details">
            <div class="label">Patient Name</div>
            <div class="value">{{documentDetails.firstName}} {{documentDetails.lastName}}</div>
        </div>
        <div class="date__block">
            <div class="label">Today’s Date</div>
            <div class="value">{{currentDate | dateFormat}}</div>
        </div>
    </div>
    <div class="instructions__block">
        <div class="instructions">{{instructions}}</div>
    </div>
    <div class="form__wrapper">
        <app-custom-form [formData]="formData" (submitEvent)="getFormData($event)" (cancelFormEvent)="back($event)"></app-custom-form>
    </div>
</section>