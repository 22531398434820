import { Component, OnInit } from '@angular/core';
import { WPAPIService } from '@app/common/services/wpapi.service';
import { Router, ActivatedRoute } from '@angular/router';

import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';


@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {

  backText = {
    moreTextLink: 'Back'
  };

  colorClass = [
    'blue',
    'yellow',
    'pink',
    'purple',
    'green'
  ];

  posts: any = null;
  constructor(library: FaIconLibrary, private wpapi: WPAPIService, private router: Router, private activated: ActivatedRoute) {

    library.addIconPacks(fab);
    this.activated.params.subscribe(param => {
      console.log('this is a param = ' , param['id']);
      this.id = param['id'];
    });
  }
  id = 62;
  ngOnInit() {
    this.wpapi.postDetails(this.id).subscribe(posts => {
      this.posts = [posts.body];
    });
  }
  navigateBackPage(event) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.router.navigate(['blog']);
  }
  getUrl() {
    return window.location.href;
  }
}
