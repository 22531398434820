<!-- terms section start -->
<section class="terms__section full-width">
    <!-- terms container start -->
    <div class="terms__container restrict-width padding-lr">
        <!-- terms cards block start -->
        <div class="terms__cards-block">
            <!-- terms heading block start -->
            <div class="terms__heading-block">
                <h2 class="terms__heading">Terms and Conditions</h2>
                <h3 class="terms__sub-heading">Terms of Use</h3>
            </div>
            <!-- terms heading block end -->
            <!-- terms card block start -->
            <div class="terms__card-block" *ngFor="let card of cardDetails"
                [ngClass]="card.expanded ? 'expanded' : ' '">
                <div class="terms__title-block" (click)="onClick(card)">
                    <div class="icon__block">
                        <img src="assets/images/svgs/{{ iconPath.iconPlus }}" alt="accordian icon">
                        <img src="assets/images/svgs/{{ iconPath.iconMinus }}" alt="accordian icon">
                    </div>
                    <h3 class="terms__title">{{ card.title }} </h3>
                </div>
                <div class="terms__description-block">
                    <div *ngIf="card.listHeading.length">
                        <h3 *ngFor="let heading of card.listHeading" [innerHTML]="heading"></h3>
                    </div>
                    <ul *ngIf="card.listArray.length">
                        <li *ngFor="let list of card.listArray" [innerHTML]="list"></li>
                    </ul>
                    <div *ngIf="card.descriptionHeading.length">
                        <h3 *ngFor="let heading of card.descriptionHeading" [innerHTML]="heading"
                            class="descriptionHeading"></h3>
                    </div>
                    <div *ngIf="card.description.length">
                        <p class="terms__description" *ngFor="let description of card.description"
                            [innerHTML]="description">
                        </p>
                    </div>
                </div>
            </div>
            <!-- terms card block end -->
        </div>
        <!-- terms cards block end -->
    </div>
    <!-- terms container end -->
</section>
<!-- terms section end -->