import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { DataService } from '@app/common/services/data.service';
import { Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-tms-questionnaire',
  templateUrl: './tms-questionnaire.component.html',
  styleUrls: ['./tms-questionnaire.component.scss']
})
export class TmsQuestionnaireComponent implements OnInit {

@Input() saveFormData;
@Input() isOffline;
@Input() filledTMSDetails;
@Input() isReview;
@Output() submitEvent: EventEmitter<any> = new EventEmitter();

formData: any;

constructor(private formService: CustomFormHandlerService, private dataService: DataService) { }

ngOnInit() {
  this.formData = this.formService.initForm(this.formService.formTypes.TMS_QUESTIONNAIRE);
  console.log(this.formData);
  // for checking isOffline from admin end if not by default keeping as true/ if true validation will be asked
  this.formData.formInputs.forEach(form => {
    if (this.filledTMSDetails && this.filledTMSDetails.tmsQuestionnaire && form.key !== 'iAcceptTermsConditions' && form.key) {
      Object.keys(this.filledTMSDetails.tmsQuestionnaire).every(key => {
        if (key.toLowerCase() === form.key.toLowerCase() && key !== 'isFaintingSpell_description' && key !== 'isPsychiatric_Neuroactive_description') {
          form.editable = this.isReview ? false : true;
          form.value = !this.filledTMSDetails.tmsQuestionnaire[key];
          return false;
        }
        return true;
      });
    }
    if (form.key === 'iAcceptTermsConditions') {
      if (this.isOffline) {
        form.editable = this.isReview ? false : true;
        form.required = true;
        form.value = this.filledTMSDetails ? this.filledTMSDetails.tmsQuestionnaire.iAcceptTermsConditions : false;
        form.emptyValueMessage = 'Please accept this to processed';
        this.formData.formGroup.controls[form.key].setValidators([Validators.requiredTrue, this.validateCheckBox]);
        this.formData.formGroup.controls[form.key].updateValueAndValidity();
      } else {
        form.editable = false;
        form.required = false;
        form.value = false;
        form.emptyValueMessage = '';
        this.formData.formGroup.controls[form.key].clearValidators();
        this.formData.formGroup.controls[form.key].updateValueAndValidity();
      }
    } else if (form.key === 'isFaintingSpell_description' || form.key === 'isPsychiatric_Neuroactive_description') {
        let value = this.filledTMSDetails ? this.filledTMSDetails.tmsQuestionnaire[form.key] : '';
        form.editable = this.isReview ? false : true;
        form.showontoggle = value !== undefined && value !== '' ? true : false;
        form.value = value !== '' ? value : '';
        form.count = value !== '' ? `${150 - value.length} remaining` : '150 remaining';
    }
  });
  // console.log(this.formData);
}

ngAfterViewInit(): void {
  let healthDetails = this.dataService.getData(this.dataService.dataObject.TMS_QUESTIONNAIRE);
}
getSliderValue(event) {
  console.log(event);
  let slider = event.slider;
  let index  = event.index;
}

getFormData(event) {
  console.log(event);
  console.log(this.formData);
  if (event) {
    this.formService.tmsQuestionnaireFromConfig = this.assignFormValues(event, this.formService.tmsQuestionnaireFromConfig);
    this.dataService.setData(this.dataService.dataObject.TMS_QUESTIONNAIRE, this.formService.tmsQuestionnaireFromConfig);
    this.submitEvent.emit(event);
  }
}

assignFormValues(formData, formConfig) {
  let keys = Object.keys(formData.value);
  formConfig.forEach((each, index) => {
    if (keys.includes(each.key)) {
      each.value = formData.value[each.key];
      each.validators = this.formData.formGroup.controls[each.key].validator;
    }
  });
  return formConfig;
}
// Validation for Checkbox
validateCheckBox(control: FormControl) {
  if (control.value) {
    var found = control.value;
    return found ? null : { checkbox: true };
  } else {
    return null;
  }
}

}
