<!--custom form start-->
<div class="multiple__uploads">
    <!--custom form start-->
    <form [formGroup]="formGroup" class="custom-form">
        <ng-container *ngFor="let field of formInputs;let i=index">
            <div class="form--fields" *ngIf="field.showontoggle == true" id="{{field.idName}}"
                [ngClass]="field.className != '' ? field.className : '' ">
                <!--main header start-->
                <div *ngIf="field.type == 'header' && field.showontoggle == true" class="label">
                    <span class="label" [ngClass]="field.className">{{field.label | titlecase}} <p>{{field.label1}}</p>
                        </span><span class="line"></span>
                </div>
                <!--main header end-->
                <div [ngSwitch]="field.controlType" class="form__field__wrapper {{field.controlType}}"
                    *ngIf="(field.type != 'header') ">

                    <div class="label" *ngIf="field.label && field.showontoggle == true">{{field.label}}</div>
                    <div class="label" *ngIf="field.show">{{field.labelText}}</div>
                    <!--labelText-->
                    <div *ngSwitchCase="'labelValue'">
                        <label>{{field.value}}</label>
                    </div>
                    <!--upload image field start-->
                    <div *ngSwitchCase="'upload-image'" class="form__field upload-image"
                        (onChange)="taskSelect($event)">
                        <!--file upload start-->
                        <input #fileUpload class="upload-input" (focus)="focusFunction($event)"
                            (focusout)="focusOutFunction($event)" id="{{field.key}}" type="file"
                            (change)="imageUpload($event,field,i)" [formControlName]="field.key"
                            [attr.disabled]="!field.editable === true || null"> <!-- [attr.accept]="extType" for pmp-->
                        <div class="file__upload-action">
                            <div class="upload-btn">
                                <label for="{{field.key}}" class="upload_label">
                                    <app-svg [name]="'upload'"></app-svg>
                                    <div>Upload</div>
                                </label>
                            </div>
                        </div>
                        <!--file upload end-->
                        <div *ngIf="formGroup.get(field.key).value && isInvalid(formGroup.get(field.key), true) && formGroup.get(field.key).errors.file"
                            class="invalid-feedback">
                            <div>{{ field.validationValue }}</div>
                        </div>
                    </div>
                    <!--upload image field end-->

                </div>
            </div>
        </ng-container>
    </form>
    <!--custom form end-->
</div>