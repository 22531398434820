import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin/admin.component';
import { CommonAppModule } from '@app/common-app.module';
import { MoreOptionsComponent } from './common/more-options/more-options.component';
import { TabViewComponent } from './common/tab-view/tab-view.component';
import { SidebarModule } from 'primeng/sidebar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AccordionModule } from 'primeng/accordion';
import { ClickOutsideModule } from 'ng-click-outside';
import { FileUploadModule } from 'primeng/fileupload';
import { AppointmentsComponent } from './appointments/appointments.component';
import { AppointmentsListComponent } from './appointments-list/appointments-list.component';

import { EmployeeListComponent } from './employee-list/employee-list.component';
import { EmployeeDetailsComponent } from './employee-details/employee-details.component';
import { NewEmployeeComponent } from './new-employee/new-employee.component';
import { CheckboxModule } from 'primeng/checkbox';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { ProfileBoxComponent } from './common/profile-box/profile-box.component';
import { AppointmentDetailsWrapperComponent } from './appointment-details-wrapper/appointment-details-wrapper.component';
import { LocationWrapperComponent } from './location-wrapper/location-wrapper.component';
import { PatientWrapperComponent } from './patient-wrapper/patient-wrapper.component';
import { LocationListComponent } from './location-list/location-list.component';
import { LocationDetailsComponent } from './location-details/location-details.component';
import { LocationDetailsWrapperComponent } from './location-details-wrapper/location-details-wrapper.component';
import { PatientListComponent } from './patient-list/patient-list.component';
import { MakeAsPaidComponent } from './make-as-paid/make-as-paid.component';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { BlockPatientComponent } from './block-patient/block-patient.component';
import { EmployeeDataListComponent } from './employee-data-list/employee-data-list.component';
import { InvoiceDetailsWrapperComponent } from './invoice-details-wrapper/invoice-details-wrapper.component';
import { GenerateInvoiceComponent } from './generate-invoice/generate-invoice.component';
import { InvoiceListDataComponent } from './invoice-list-data/invoice-list-data.component';
import { PatientDetailsComponent } from './patient-details/patient-details.component';
import { AdminCreatePatientComponent } from './admin-create-patient/admin-create-patient.component';
import { PatientHistoryListComponent } from './patient-history-list/patient-history-list.component';
import { CreateAppointmentComponent } from './create-appointment/create-appointment.component';
import { DashboardDropdownComponent } from './dashboard-dropdown/dashboard-dropdown.component';
import { DashBoardRightPanelComponent } from './dash-board-right-panel/dash-board-right-panel.component';
import { DashboardCalendarComponent } from './common/dashboard-calendar/dashboard-calendar.component';
import { PatientDetailsInDashboardComponent } from './patient-details-in-dashboard/patient-details-in-dashboard.component';
import { DashboardBlockSlotsDetailsComponent } from './dashboard-block-slots-details/dashboard-block-slots-details.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { CreateLocationWrapperComponent } from './create-location-wrapper/create-location-wrapper.component';
import { CreateLocationComponent } from './create-location/create-location.component';
import { LocationServiceCardComponent } from './location-service-card/location-service-card.component';
import { LocationSchedulePopupComponent } from './location-schedule-popup/location-schedule-popup.component';
import { AdminProfileComponent } from './admin-profile/admin-profile.component';
import { EditAdminProfileComponent } from './edit-admin-profile/edit-admin-profile.component';
import { AdminChangePasswordComponent } from './admin-change-password/admin-change-password.component';
import { AppointmentsCardsListComponent } from './appointments-cards-list/appointments-cards-list.component';
import { InvoiceDetailsNotGeneratedComponent } from './invoice-details-not-generated/invoice-details-not-generated.component';
import { CreateNewInvoiceComponent } from './common/create-new-invoice/create-new-invoice.component';
import { CancelRedirectComponent } from './common/cancel-redirect/cancel-redirect.component';
import { appointmentModule } from '../AppointmentBooking/appointment.module';
import { PatientDocumentRequestComponent } from './patient-document-request/patient-document-request.component';
import { PatientMarkAsCompleteComponent } from './patient-mark-as-complete/patient-mark-as-complete.component';
import { EmailTemplatesListComponent } from './email-templates-list/email-templates-list.component';
import { EmailTemplateWrapperComponent } from './email-template-wrapper/email-template-wrapper.component';
import { EmailTemplateDetailsComponent } from './email-template-details/email-template-details.component';
import { EmailTemplateEditComponent } from './email-template-edit/email-template-edit.component';
import { AdminKetamineMonitoringFormComponent } from './admin-ketamine-monitoring-form/admin-ketamine-monitoring-form.component';
import { DocumentListComponent } from './document-list/document-list.component';
import { MultiplePatientEmailComponent } from './multiple-patient-email/multiple-patient-email.component';
import { PatientLabDocumentsRequestComponent } from './patient-lab-documents-request/patient-lab-documents-request.component';
import { LabDocumentEditComponent } from './lab-document-edit/lab-document-edit.component';
import { UserListWrapperComponent } from './user-list-wrapper/user-list-wrapper.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserDetailsWrapperComponent } from './user-details-wrapper/user-details-wrapper.component';
import { InfoFormEditComponent } from './info-form-edit/info-form-edit.component';
import { BlockLocationComponent } from './block-location/block-location/block-location.component';
import { PatientDocumentListComponent } from './patient-document-list/patient-document-list.component';
import { PatientDocumentDetailsComponent } from './patient-document-details/patient-document-details.component';
import { MednotesFormComponent } from './mednotes-form/mednotes-form.component'; //mednotes
import { PreviewMednotesComponent } from './preview-mednotes/preview-mednotes.component'; //mednotes-preview
import { TmsNotesComponent } from './tms-notes/tms-notes.component';
import { PreviewTmsNotesComponent } from './preview-tms-notes/preview-tms-notes.component'; 




@NgModule({
  declarations: [AdminComponent, AppointmentsListComponent, AppointmentsComponent, MoreOptionsComponent, TabViewComponent, ProfileBoxComponent, AppointmentDetailsWrapperComponent, LocationWrapperComponent, PatientWrapperComponent, PatientListComponent, LocationListComponent, LocationDetailsWrapperComponent, LocationDetailsComponent, EmployeeListComponent, EmployeeDataListComponent, EmployeeDetailsComponent, NewEmployeeComponent, InvoiceDetailsWrapperComponent, GenerateInvoiceComponent, MakeAsPaidComponent, InvoiceListComponent, InvoiceListDataComponent, PatientDetailsComponent, PatientHistoryListComponent, AdminCreatePatientComponent, BlockPatientComponent, CreateAppointmentComponent, DashBoardRightPanelComponent, DashboardCalendarComponent, PatientDetailsInDashboardComponent, DashboardDropdownComponent, DashboardBlockSlotsDetailsComponent, NotificationsComponent, AdminProfileComponent, EditAdminProfileComponent, AdminChangePasswordComponent, CreateLocationWrapperComponent, CreateLocationComponent, LocationServiceCardComponent, LocationSchedulePopupComponent, AppointmentsCardsListComponent, InvoiceDetailsNotGeneratedComponent, CreateNewInvoiceComponent, CancelRedirectComponent, PatientDocumentRequestComponent, PatientMarkAsCompleteComponent, EmailTemplatesListComponent, EmailTemplateWrapperComponent, EmailTemplateEditComponent, EmailTemplateDetailsComponent, AdminKetamineMonitoringFormComponent, DocumentListComponent, MultiplePatientEmailComponent, PatientLabDocumentsRequestComponent, LabDocumentEditComponent, UserListWrapperComponent, UserListComponent, UserDetailsWrapperComponent, InfoFormEditComponent, BlockLocationComponent, PatientDocumentListComponent, PatientDocumentDetailsComponent, MednotesFormComponent, PreviewMednotesComponent, TmsNotesComponent, PreviewTmsNotesComponent],
  imports: [
    CommonModule,
    CommonAppModule,
    AdminRoutingModule,
    SidebarModule,
    ClickOutsideModule,
    FormsModule,
    AutoCompleteModule,
    CheckboxModule,
    OverlayPanelModule,
    AccordionModule,
    FileUploadModule,
    CalendarModule,
    DropdownModule,
    ReactiveFormsModule,
    appointmentModule
  ],
})
export class AdminModule { }
