<section class="invoice__details--wrapper" *ngIf="isShowScreen && permissionsDone">
    <!--Right side background leaf starts here -->
    <div class="background-image">
        <img src="assets/images/pngs/newpatientform-background.png" alt="bg image">
    </div>
    <!-- Right side background leaf ends here -->

    <!-- Main section starts here -->
    <div class="invoice__details--main-wrapper content__wrapper">
        <!-- Breadcrumb section starts here -->
        <div class="invoice__details-navigation-menu">
            <app-breadcrumb [breadCrumbData]="breadCrumbData"></app-breadcrumb>
        </div>
        <!-- Breadcrumb section ends here -->
        <div class="content__block--wrapper">
            <!-- Left side details starts here -->
            <div class="left__side--wrapper">
                <div class="patient__name--block">
                    <div class="patient__name">{{appointmentDetails.patientBasicInfo.firstName | titlecase}}
                        {{appointmentDetails.patientBasicInfo.lastName | titlecase}}</div>
                    <div class="invoice__status">{{appointmentDetails.appointmentInfo.invoiceStatus | titlecase}}</div>
                </div>
                <!-- Appointment details starts here -->
                <div class="patient__details--block">
                    <div class="each__block">
                        <div class="details__lable">
                            Patient ID
                        </div>
                        <div class="details__value">
                            {{appointmentDetails.appointmentInfo.patientId }}
                        </div>
                    </div>
                    <div class="each__block">
                        <div class="details__lable">
                            Location
                        </div>
                        <div class="details__value">
                            {{appointmentDetails.appointmentInfo.location | titlecase}}
                        </div>
                    </div>
                    <div class="each__block">
                        <div class="details__lable">
                            Treatment
                        </div>
                        <div class="details__value">
                            {{appointmentDetails.appointmentInfo.treatment | titlecase}}
                        </div>
                    </div>
                    <div class="each__block">
                        <div class="details__lable">
                            Appointment Status
                        </div>
                        <div class="details__value">
                            {{appointmentDetails.appointmentInfo.status | titlecase}}
                        </div>
                    </div>
                    <div class="each__block">
                        <div class="details__lable">
                            Date
                        </div>
                        <div class="details__value">
                            {{appointmentDetails.appointmentInfo.appointmentDate | dateFormat}}
                        </div>
                    </div>
                    <div class="each__block">
                        <div class="details__lable">
                            Time Slot
                        </div>
                        <div class="details__value">
                            {{appointmentDetails.appointmentInfo.timeSlotFrom | customTime}} to
                            {{appointmentDetails.appointmentInfo.timeSlotTo | customTime}}
                        </div>
                    </div>
                </div>
                <!-- Appointment details ends here -->

                <!-- Doctors block starts here -->
                <div class="doctors__block">
                    <div class="side__lable">Doctors</div>
                    <div class="cards__block">
                        <div class="card__boxes" *ngFor="let doctor of appointmentDetails.appointmentInfo.doctor">
                            <app-profile-box [cardData]="doctor"></app-profile-box>
                        </div>
                    </div>
                </div>
                <!-- Doctors block ends here -->

                <!-- Bottom actions starts here -->
                <ng-container *ngIf="!isBeforeActions">
                    <div class="actions__block">
                        <div class="each__action" (click)="actionsClicked(action)"
                            *ngFor="let action of invoiceDetailsActions;let i=index">
                            <app-svg class="{{action.icon}}" [name]="action.icon"></app-svg>
                            <div class="actions__lable">{{action.name}}</div>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="isBeforeActions">
                    <div class="send__to--customer-block">
                        <div class="copay__block lable">Copay Amount <span class="value">${{copayAmount}}</span></div>
                        <div class="due__block lable">Due Amount <span class="value">${{dueAmount}}</span></div>
                        <div class="vr__line"></div>
                        <div class="total__block lable">Total Amount <span class="value">${{totalAmout}}</span></div>
                    </div>
                </ng-container>

                <ng-container *ngIf="isBeforeActions && isAfterActions">
                    <div class="after__actions--block">
                        <div class="common-btn-style" *ngIf="invoiceDetails.locationName !== 'Telemedicine'" (click)="generateInvoice({btnType:'delete'})">Delete</div>
                        <div class="common-btn-style" (click)="generateInvoice({btnType:'makePayment'})">Make Payment</div>
                        <div class="common-btn-style" (click)="generateInvoice({btnType:'markAsPaid'})">Mark as Paid</div>
                        <div class="common-btn-style" (click)="generateInvoice({btnType:'resend'})">Resend to Customer</div>
                    </div>
                </ng-container>
                
                <!-- Bottom actions starts here -->
            </div>
            <!-- Left side details ends here -->
        </div>
    </div>
    <app-common-popup (showHideCancelPopup)='generateInvoicePopupShowHideStatusFun($event)'
        *ngIf="generateInvoicePopupShowHide">
        <app-generate-invoice
            (totalInvoiceAmount)='generateInvoice($event)' [amountGenerated]="amountGenerated"></app-generate-invoice>
    </app-common-popup>
    <app-common-popup (showHideCancelPopup)='makeAsPaidPopupShowHideStatusFun($event)' *ngIf="makeAsPaidPopupShowHide">
        <app-make-as-paid (makeAsPaidData)="generateInvoice($event)" (hidePopUp)="makeAsPaidPopupShowHideStatusFun($event)"></app-make-as-paid>
    </app-common-popup>
    <div class="paymentPage" >
        <form method="POST"  ngNoForm [action]="paymentRedirectUrl" id="formAuthorizeNetPage" name="formAuthorizeNetTestPage">
            <input type="hidden" id = "paymentvalue" name="token" [value]="paymentToken" />
            <!-- Continue to Authorize.net to Payment Page:  -->
            <button id="btnContinue">Click here to make payment</button>
        </form> 
    </div>
</section>