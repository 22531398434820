import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { CommonAppModule } from '@app/common-app.module';
import { UserProfilePageRoutingModule } from './user-profile-routing.module';



@NgModule({
  declarations: [UserProfileComponent],
  imports: [
    CommonModule,
    CommonAppModule,
    UserProfilePageRoutingModule
  ]
})
export class UserProfileModule { }
