<section class="hippa__notice__form">
    <div class="container">

    <div class="header__wrapper">
        {{header}}
    </div>
    <div>        <app-custom-form [checkFormInfo]="saveFormData" (submitEvent)="getFormData" [formData]="substanceForm"></app-custom-form></div>
        <div class="hippa__description">
            {{hippaData.description1}}
        </div>
        <div>
            <ul class="">
                <li *ngFor="let list of hippaData.list">
                    {{list}}
                </li>
            </ul>
        </div>
    </div>
    <div class="form__fields__block">

        <app-custom-form [checkFormInfo]="saveFormData"
        (submitEvent)="getFormData($event)" [formData]="hippaForm"></app-custom-form>
    </div>
</section>
