import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { API_URLS } from '@app/common/constants/api-urls.constant';
import { ROUTE_PATHS } from '@app/common/constants/routing.constant';
import { TOAST_MESSAGES } from '@app/common/constants/toast-messages.constant';
import { IformDataI, IGetMethodPayload, IPostMethodPayload } from '@app/common/constants/typeInterfaces.constants';
import { HTTPMethods, TOAST_STATUSES } from '@app/common/constants/util.constant';
import { CognitoService } from '@app/common/services/cognito.service';
import { CustomFormHandlerService } from '@app/common/services/custom-form-handler.service';
import { HttpService } from '@app/common/services/http.service';
import { ObservableHelperService } from '@app/common/services/observable-helper.service';

@Component({
  selector: 'app-medication-feedback',
  templateUrl: './medication-feedback.component.html',
  styleUrls: ['./medication-feedback.component.scss']
})
export class MedicationFeedbackComponent implements OnInit {
  saveFormData: boolean = false;
  requestId: string;
  patientId: any;
  documentDetails: any;
  patientsList: Array<any>;
  patientData: Array<any>;
  medicationFeedbackRequest: Array<any>;
  formData: IformDataI;
  constructor(private formService: CustomFormHandlerService, private http: HttpService, private router: Router, private route: ActivatedRoute, private observableHelperService: ObservableHelperService, private cognitoService: CognitoService) {
    this.route.queryParams.subscribe(queryParams => {
      if (queryParams.requestId) {
        this.requestId = queryParams.requestId;
        this.getRequestedDocumentDetails();
      } else if (queryParams.patientId) {
        this.patientId = queryParams.patientId;
        this.getRequestId();
      } else {
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.MEDICATION_FEEDBACK_NOT_FOUND_MSG);
        this.cancel();
      }
    });
   }

  ngOnInit() {
    this.formData = this.formService.initForm(this.formService.formTypes.MEDICATION_FEEDBACK);
  }

  /**
   * fetch user Id
   */
  getRequestId() {
    this.cognitoService.getUser().then(user => {
      this.getPatientsList(user.userId);
    });
  }

  // This function is for getting the request document details
  getRequestedDocumentDetails() {
    const payload: IGetMethodPayload = {
      type: HTTPMethods.GET,
      url: API_URLS.REQUEST_DOCUMENT_DETAILS,
      isDeveloper: true,
      pathVariables: [this.requestId],
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res)) {
        this.documentDetails = res.data;
      } else {
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.DOCUMENT_DATA_REQUEST_ERROR_MSG);
      }
    });
  }

   /**
   * @param userId :User Id
   * This function is for getting list of patient
   */
  getPatientsList(userId) {
    const payload: IGetMethodPayload = {
      type: HTTPMethods.GET,
      url: API_URLS.GET_PATIENT_LIST,
      isDeveloper: true,
      pathVariables: [userId]
    };
    this.http.makeHttpRequest(payload).subscribe((res) => {
      if (this.http.isSuccessfulResponse(res) && res) {
        this.patientsList = res.data.patients;
        this.patientData = this.patientsList.filter(each => parseInt(each.patientId, 10) === parseInt(this.patientId, 10));
        if (this.patientData && this.patientData.length) {
          this.medicationFeedbackRequest = this.patientData[0].pendingDocumentRequest.filter(eachReq => eachReq.documentIds === '[14]');
          if (this.medicationFeedbackRequest && this.medicationFeedbackRequest.length) {
            this.requestId = this.medicationFeedbackRequest[0].requestId;
            this.getRequestedDocumentDetails();
          } else {
            this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.MEDICATION_FEEDBACK_REQUEST_NOT_FOUND);
            this.router.navigate([ROUTE_PATHS.ADD_MyACCOUNT]);
          }
        } else {
          this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.MEDICATION_FEEDBACK_NOT_FOUND_MSG);
          this.cancel();
        }
        console.log(this.patientData);
      } else {
        this.observableHelperService.showToast(TOAST_STATUSES.ERROR, TOAST_MESSAGES.PATIENT_LIST_ERROR_MSG);
      }
    });
  }

  // this is for re-triggering the Input of feedback component
  resetSaveFormParity() {
    window.setTimeout(() => {
      this.saveFormData = false;
    }, 0);
    return true;
  }

  /**
   * submit button clicked action
   */
  saveForm() {
    this.saveFormData = true;
    setTimeout(() => {
      if (document.getElementsByClassName('invalid-feedback')[0]) {
        document.getElementsByClassName('invalid-feedback')[0].scrollIntoView({ block: 'center' });
      }
    }, 2);
  }

  /**
   * back to the previous page
   */
  cancel() {
    if (this.formData && this.formData.formGroup) {
      this.formData.formGroup.reset();
    }
    this.router.navigate([ROUTE_PATHS.ADD_MyACCOUNT]);
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  getFormData(event) {
    if (event) {
      const formData = event.value;
      const bodyData = {
        timelinessInCalls: formData.timelinessInCalls,
        timelinessInEmails: formData.timelinessInEmails,
        issuesResolving: formData.issuesResolving,
        useOfOnlinePortal: formData.useOfOnlinePortal,
        obtainingAppointment: formData.obtainingAppointment,
        helpInAddressing: formData.helpInAddressing,
        professionalismOfStaff: formData.professionalismOfStaff,
        interactionsWithEmployee: formData.interactionsWithEmployee,
        recommendOurServices: formData.recommendOurServices,
        customerCommunications: formData.customerCommunications,
        overallSatisfaction: formData.overallSatisfaction,
        experienceSatisfactions: formData.experienceSatisfactions
      };
      console.log(bodyData, 'ZZZZ');
      const payload: IPostMethodPayload = {
        type: HTTPMethods.POST,
        url: API_URLS.MEDICATION_FEEDBACK,
        pathVariables: [this.documentDetails.patientId, this.requestId],
        isDeveloper: true,
        body: bodyData,
      };
      this.http.makeHttpRequest(payload).subscribe((res) => {
        if (this.http.isSuccessfulResponse(res) && res) {
          this.observableHelperService.showToast(TOAST_STATUSES.SUCCESS, TOAST_MESSAGES.MEDICATION_FEEDBACK_SUCCESS_MSG);
          this.cancel();
        } else {
          let message = TOAST_MESSAGES.MEDICATION_FEEDBACK_ERROR_MSG;
          message = (res.error) ? res.error.message : message;
          this.observableHelperService.showToast(TOAST_STATUSES.ERROR, message);
        }
      });
    }
  }
}
